<script lang="ts">
import TimeSelect from '@/components/form/TimeSelect.vue';
import BarChart from '@/components/kpiCharts/BarChart.vue';
import WidgetCard from '@/components/layout/widget/WidgetCard.vue';
import KpiNumber, {
  KpiGrowthUnitValue,
} from '@/components/widget/kpiNumber/KpiNumber.vue';
import {
  useUnitConversion,
  UseUnitConversionReturnType,
} from '@/composables/conversion';
import { DateRange } from '@/utils/types/date';
import { ROUTE_ASSET_MAP } from '@/utils/workData/utilMap';
import { Component, Inject, Prop, Vue } from 'vue-property-decorator';
import KpiInfoExpanded from './kpiInfo/KpiInfoExpanded.vue';

@Component({
  name: 'KpiBarChart',
  components: {
    WidgetCard,
    TimeSelect,
    KpiNumber,
    BarChart,
    KpiInfoExpanded,
  },
})
export default class extends Vue {
  @Prop({ required: true }) itemColor!: string;
  @Prop({ required: true }) kpiUnit!: string;
  @Prop({ default: [] }) chartData!: any;
  /**
   * List of KPI values to display, including their growth percentage.
   * Values are expected to be in non-user-converted (metric) units.
   */
  @Prop({ default: [] }) kpiNumberList!: KpiGrowthUnitValue<
    number | undefined
  >[];
  @Prop() loading?: boolean;

  routeAssetMap = ROUTE_ASSET_MAP;
  assetRelatedData: any = {
    route: '',
    pageCode: '',
    widgetContainerId: '',
    title: '',
    assetTypeCode: '',
  };
  @Inject() expanded!: boolean;
  isFleet: boolean = false; //determine whether the kpi is fleet kpi
  fleetType: string = 'date';
  fleetTypeList: any = [
    {
      label: 'KPI.overTime',
      value: 'date',
    },
    {
      label: 'KPI.perAsset',
      value: 'asset',
    },
  ];
  unitConversion!: UseUnitConversionReturnType;

  created() {
    this.unitConversion = useUnitConversion();
    //if kpi param exists, it is expanded type
    this.isFleet = this.$route.params.id ? false : true;
    if (this.$route.name) {
      this.assetRelatedData = this.routeAssetMap.get(this.$route.name);
    }
  }

  dateRangeChange(dateRange: DateRange) {
    this.$emit('send-date', dateRange);
  }

  changeFleetType(tab: any) {
    this.$emit('send-fleet-type', tab);
  }

  triggerTooltip(row: any) {
    (this.$refs.canvasChart as any).triggerTooltip(row);
  }

  get convertedKpi(): KpiGrowthUnitValue<number | undefined>[] {
    // Note: the growth percentage itself isn't converted, because the ratio
    // wouldn't change (kg divided by kg has same ratio as pounds divided by pounds).
    // The only place where this wouldn't work, is for units with an offset,
    // but that would only be temperature, and basically any unit with an offset
    // is unlikely to have a sensible growth percentage.
    return this.kpiNumberList.map((item) =>
      this.unitConversion.currentUserConvertUnitValue(item)
    );
  }
}
</script>

<template>
  <WidgetCard :loading="loading" :expandable="true">
    <div class="filters-container">
      <TimeSelect
        :expanded="expanded"
        @select="dateRangeChange"
        :customizable="true"
      />
      <el-radio-group
        v-if="isFleet && expanded"
        v-model="fleetType"
        class="radio_area"
        @change="changeFleetType"
      >
        <el-radio-button
          v-for="item in fleetTypeList"
          :label="item.value"
          :key="item.value"
          >{{ $t(item.label) }}</el-radio-button
        >
      </el-radio-group>
    </div>
    <div class="text_info" v-show="!expanded">
      <KpiNumber v-for="item in convertedKpi" :kpi="item" />
    </div>
    <div class="card-container">
      <div class="card-container-item">
        <BarChart
          ref="canvasChart"
          :chartData="chartData"
          :height="expanded ? '600px' : '320px'"
          :itemColor="itemColor"
          :yAxisUnit="kpiUnit"
        />
      </div>
      <div v-if="expanded" class="table">
        <KpiInfoExpanded
          @hover-table="triggerTooltip"
          :kpiNumberList="convertedKpi"
        />
      </div>
    </div>
  </WidgetCard>
</template>

<style scoped>
.filters-container {
  padding: 6px 28px;
  display: flex;
  flex-wrap: wrap;
  border-bottom: 1px solid #dddddd;
  gap: 8px;
}

.card-container {
  display: flex;
}

.card-container-item {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
}

.table {
  min-width: 30%;
  max-width: 50%;
}

@media (max-width: 1130px) {
  .card-container {
    flex-direction: column;
  }

  .table {
    max-width: 100%;
  }
}

.text_info {
  padding-left: 15px;
}

.radio_area {
  max-height: 38px;
}

.radio_area
  :deep(.el-radio-button__orig-radio:checked + .el-radio-button__inner) {
  background: #373e41;
  border-color: #373e41;
  color: #ffffff;
  box-shadow: -1px 0 0 0 #373e41;
}
</style>
