<script lang="ts" setup>
import { useLogin } from '@/auth/token';
import { LoginStatus, useLoginStatus } from '@/auth/user';
import ErrorPage from '@/components/layout/MessagePage.vue';
import { useRouter } from '@/composables/router';

// If the user somehow got to this page, but isn't logged out (anymore), let's
// redirect to home.
const loginStatus = useLoginStatus();
if (loginStatus.value !== LoginStatus.LoggedOut) {
  const router = useRouter();
  router.push('/');
}

const login = useLogin();
</script>

<template>
  <ErrorPage>
    <h2>{{ $t('pageLoggedOut.title') }}</h2>
    <p>{{ $t('pageLoggedOut.body') }}</p>
    <el-button @click="login()" type="info" class="button">{{
      $t('common.login')
    }}</el-button>
  </ErrorPage>
</template>

<style scoped>
.button {
  margin-top: 1em;
}
</style>
