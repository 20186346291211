<script lang="ts">
import { createOrganization, getOrgById, updateOrg } from '@/api/organizations';
import {
  addServiceRegionsToOrganization,
  getNotAllocatedAccessibleServiceRegions,
  NotAllocatedAccessibleServiceRegionsResponse,
  ServiceRegion,
  ServiceRegionCode,
} from '@/api/serviceRegions';
import NewCard from '@/components/cusCard/NewCard.vue';
import i18n from '@/lang';
import { useServiceRegionsQuery } from '@/query/serviceRegion';
import { PageModule } from '@/store/modules/page';
import { UserModule } from '@/store/modules/user';
import {
  customFailedMessage,
  promptFailedBox,
  promptSuccessBox,
} from '@/utils/prompt';
import { ACTIVATION_STATUS } from '@/utils/workData/lookuptable';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import NewHelpdeskOrganization from './components/NewHelpdeskOrganization.vue';
import { HelpdeskOrganizationModel } from './models/helpdeskOrganizationModel';

@Component({
  name: 'AddnewHelpdeskOrganization',
  components: {
    NewCard,
    NewHelpdeskOrganization,
  },
})
export default class extends Vue {
  @Prop() orgId!: string;

  /** Local variables */
  disabledTreeSel: boolean = false;
  saveOrCreate: boolean = false;
  organizationViewIsLoading: boolean = false;
  activeName: string = 'first';
  orgForm: HelpdeskOrganizationModel = {
    name: '',
    parentId: UserModule.organizationId,
    serviceRegions: [],
    street: '',
    district: '',
    province: '',
    city: '',
    country: '',
    zipCode: '',
    note: '',
    timezone: 'Europe/Amsterdam',
    activationStatus: ACTIVATION_STATUS.Activated,
  };

  errorInfos = [
    {
      code: '',
      field: '',
      message: '',
    },
  ];

  initialServiceRegions: string[] = [];
  serviceRegionsList: NotAllocatedAccessibleServiceRegionsResponse[] = [];
  serviceRegionsQuery!: ReturnType<typeof useServiceRegionsQuery>;

  created() {
    this.serviceRegionsQuery = useServiceRegionsQuery();
    this.fetchNotAllocatedAccessibleServiceRegions();
    if (this.orgId) {
      this.saveOrCreate = true;
      this.fetchOrganizationDetailsById();
    }
  }

  /** Fetch not allocated accessible service regions, therefore the user could choose only not used regions within company */
  async fetchNotAllocatedAccessibleServiceRegions() {
    const response = await getNotAllocatedAccessibleServiceRegions();
    this.serviceRegionsList = response.data;
  }

  /**
   * Handle scenarios when initial codes are removed
   *  - add them to selection list if not exist
   * @param currentSelection
   */
  @Watch('orgForm.serviceRegions', { deep: true })
  handleServiceRegionForInitialCodes(
    currentSelection: NotAllocatedAccessibleServiceRegionsResponse[]
  ) {
    if (
      currentSelection &&
      this.initialServiceRegions &&
      currentSelection.length === 0 &&
      this.initialServiceRegions.length > 0
    ) {
      this.initialServiceRegions.forEach((code: string) => {
        if (
          this.serviceRegionsQuery.data.value &&
          !this.serviceRegionsList.some(
            (el: NotAllocatedAccessibleServiceRegionsResponse) =>
              el.serviceRegionCode === code
          )
        ) {
          const itemToAdd: ServiceRegion[] =
            this.serviceRegionsQuery.data.value.filter(
              (codeFromAll: ServiceRegion) =>
                codeFromAll.serviceRegionCode === code
            );
          this.serviceRegionsList.unshift(...itemToAdd);
        }
      });
      return;
    }
  }

  handleClickTab(tab: any, event: any): void {}

  handleInput() {
    this.errorInfos = [];
  }

  /**
   * Handle organization operations: update, create
   */
  async handleOrganizationFinalStep() {
    try {
      this.organizationViewIsLoading = true;
      const org = {
        name: this.orgForm.name,
        street: this.orgForm.street,
        district: this.orgForm.district,
        province: this.orgForm.province,
        city: this.orgForm.city,
        country: this.orgForm.country,
        zipCode: this.orgForm.zipCode,
        companyId: UserModule.companyId,
        parentId: this.orgForm.parentId,
        activationStatus: ACTIVATION_STATUS.Activated,
        note: this.orgForm.note,
        timezone: this.orgForm.timezone,
      };

      if (this.orgId) {
        await updateOrg(this.orgId, this.orgForm).then((res: any) => {
          if (!res) {
            customFailedMessage(
              this.$t('common.errorWithFetchingData') as string
            );
            return;
          }

          if (res.code === 200) {
            this.errorInfos = [];
            this.handleUpdatingAccessibleServiceRegions(
              this.orgId,
              this.orgForm.serviceRegions,
              this.$t('common.save') as string
            );

            return;
          }

          promptFailedBox(this.$t('common.save') as string);
          this.errorInfos = res.data.errors;
        });

        return;
      }

      await createOrganization(org).then((res: any) => {
        if (!res) {
          customFailedMessage(
            this.$t('common.errorWithFetchingData') as string
          );
          return;
        }

        if (res.code === 200) {
          let newOrgId = res.data.id;
          this.errorInfos = [];
          this.handleUpdatingAccessibleServiceRegions(
            newOrgId,
            this.orgForm.serviceRegions,
            this.$t('common.create') as string
          );

          return;
        }

        if (res.code === 400) {
          const fieldErrorCode = res.data.errors[0].code;
          if (fieldErrorCode === 'ApiErrorMaxOrganizationsExceeded') {
            customFailedMessage(
              this.$t('organizationModule.maximumOrgsExceeded') as string
            );
          }

          if (fieldErrorCode === 'ApiErrorSubscriptionPackageType') {
            customFailedMessage(
              this.$t('organizationModule.missingOrgSysFeature') as string
            );
          }

          this.errorInfos = res.data.errors;
          return;
        }

        promptFailedBox(this.$t('common.creation') as string);
        this.errorInfos = res.data.errors;
      });
    } catch (error) {
      console.log(error);
    } finally {
      this.organizationViewIsLoading = false;
    }
  }

  /**
   * Handle received serviceRegions, could be initial loaded codes or object from v-model selection
   * @param orgId
   * @param serviceRegions
   * @param successMessage
   */
  handleUpdatingAccessibleServiceRegions(
    orgId: string,
    serviceRegions: NotAllocatedAccessibleServiceRegionsResponse[] | string[],
    successMessage: string
  ) {
    const serviceRegionsCode: string[] = [];
    serviceRegions?.forEach(
      (item: NotAllocatedAccessibleServiceRegionsResponse | string) => {
        if (typeof item === 'string' && !item.includes('_')) {
          const code = this.serviceRegionsQuery.data.value?.find(
            (itemFromAll: ServiceRegion) =>
              itemFromAll.serviceRegionName === item
          )?.serviceRegionCode;

          if (code) {
            serviceRegionsCode.push(code);
          }
        } else if (typeof item === 'object') {
          serviceRegionsCode.push(item.serviceRegionCode);
        }
      }
    );
    addServiceRegionsToOrganization(orgId, {
      accessibleServiceRegions: serviceRegionsCode,
    }).then(() => {
      this.showSuccessBoxAndGoToOrgTab(successMessage);
    });
  }

  showSuccessBoxAndGoToOrgTab(message: string) {
    promptSuccessBox(message);
    this.$router.push('/administration/index?activeName=orgTab');
  }

  async fetchOrganizationDetailsById() {
    try {
      const { data } = await getOrgById(this.orgId);
      this.orgForm = {
        name: data.name,
        parentId: data.parentId,
        street: data.street,
        district: data.district,
        province: data.province,
        city: data.city,
        country: data.country,
        zipCode: data.zipCode,
        note: data.note,
        timezone: data.timezone,
        serviceRegions: [],
        activationStatus: data.activationStatus,
      };
      this.initialServiceRegions = data.accessibleServiceRegions ?? [];
      data.accessibleServiceRegions?.forEach(
        (serviceRegion: ServiceRegionCode) => {
          this.orgForm.serviceRegions.push(this.$t(serviceRegion).toString());
        }
      );
      this.disabledTreeSel = true;
      const title = i18n.t('common.editInterpolated', {
        name: data.name,
      });
      PageModule.setTitle(title);
    } catch (error) {
      console.log(error);
      customFailedMessage(this.$t('common.errorWithFetchingData') as string);
    } finally {
    }
  }
}
</script>

<template>
  <NewCard v-loading="organizationViewIsLoading">
    <el-tabs
      v-model="activeName"
      @tab-clicl="handleClickTab"
      style="margin: 0px 20px"
      class="org-tabs"
    >
      <el-tab-pane
        v-bind:label="$t('organizationModule.general')"
        name="first"
        style="overflow: auto"
      >
        <NewHelpdeskOrganization
          :errorInfos="errorInfos"
          :disabledTreeSel="disabledTreeSel"
          :orgForm="orgForm"
          :saveOrCreate="saveOrCreate"
          :serviceRegionsList="serviceRegionsList"
          :orgId="orgId"
          @handle-input="handleInput"
          @save-helpdesk-org="handleOrganizationFinalStep"
        />
      </el-tab-pane>
    </el-tabs>
  </NewCard>
</template>

<style lang="scss" scoped></style>
