<script lang="ts">
import { Vue } from 'vue-property-decorator';

export default class extends Vue {
  created() {}
}
</script>

<template>
  <div class="vertical_line">
    <div class="circle_icon">
      <img src="@/assets/imgs/divide_link.png" alt="" />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.vertical_line {
  height: 100%;
  border-left: 1px solid #707070;
  position: absolute;
  .circle_icon {
    position: absolute;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background: #c1c1c1;
    top: 50%;
    border: 10px solid #fff;
    box-sizing: content-box;
    margin-top: -25px;
    margin-left: -25px;
    img {
      position: absolute;
      width: 15px;
      height: 15px;
      top: 50%;
      left: 50%;
      margin-top: -7.5px;
      margin-left: -7.5px;
    }
  }
}
</style>
