<script setup lang="ts">
import { Breakpoint, useMaxWindowSize } from '@/composables/window';

const props = defineProps<{ show: boolean; message: string }>();
const emit = defineEmits<{ (e: 'close'): void }>();
const isScreenSizeSmall = useMaxWindowSize(Breakpoint.Small);
</script>

<template>
  <el-dialog
    :visible="props.show"
    :show-close="false"
    center
    :width="isScreenSizeSmall ? '90%' : '25%'"
    @close="emit('close')"
    append-to-body
  >
    <div style="text-align: center">
      <div class="message">{{ props.message }}</div>
      <el-button type="info" @click="emit('close')">
        {{ $t('ok') }}
      </el-button>
    </div>
  </el-dialog>
</template>

<style lang="scss" scoped>
:deep(.el-dialog) {
  border-radius: 10px;
}

:deep(.el-dialog__header) {
  padding: 0;
}

:deep(.el-button) {
  width: 140px;
}

.message {
  font-weight: bold;
  word-break: normal;
  font-size: 18px;
  margin-bottom: 33px;
}
</style>
