import { runInMainContextAsync } from '@/mainInstance';
import { UseQueryReturnType } from '@tanstack/vue-query';
import { Ref, ref } from 'vue';

// This file contains helpers to make life with Vue Class Components easier,
// i.e. to transition to Vue 3 SFC / Composition API.
// It can be removed once all Vue Class Components (`@Component` and friends)
// are gone.

/**
 * Return the original value as-is, without actual unref, but strip its Ref.
 *
 * This is useful to assign a (computed/normal) Ref to a vue-class-component
 * property, while keeping the property 'subscribed' to the ref.
 *
 * For the 'inverse operation', use `computed()`, see below.
 *
 * @example
 * ```ts
 * // Example function that returns a ref
 * function useFoo(): Ref<number> {
 *     // dummy implementation
 *     return ref(0);
 * }
 *
 * function observeBar(barRef: Ref<number>): void {
 *     // Note: 'normal' unref here
 *     watchEffect(() => console.log("bar", unref(barRef)));
 * }
 *
 * @Component
 * export default class extends Vue {
 *     foo!: number;
 *     bar: number = 42;
 *     created() {
 *         // this.foo will automatically update whenever the ref from useFoo() updates
 *         this.foo = fakeUnref(useFoo());
 *
 *         // Turns 'this.bar' into a Ref
 *         const barRef = computed(() => this.bar);
 *         observeBar(barRef);
 *     }
 * ```
 */
export function fakeUnref<T>(value: Ref<T>): T {
  return value as unknown as T;
}

/**
 * Return an undefined value wrapped in a ref.
 *
 * Useful to initialize properties in a vue-class-component property,
 * as otherwise the property will not be reactive. Prevents having to
 * use `null` as a placeholder instead.
 *
 * @example
 * ```ts
 * @Component
 * export default class extends Vue {
 *     foo: number = initializeReactive();
 *
 *     created() {
 *         // Make sure to 'actually' assign it something, like
 *         this.foo = 42;
 *         // or
 *         this.foo = fakeUnref(useFoo());
 *     }
 * ```
 */
export function initializeReactive(): any {
  return ref();
}

/**
 * Decorator to initialize fields on a Vue Class Component
 * such that they become reactive.
 *
 * Useful to initialize fields that would typically be assigned
 * in the constructor, but need to defer that initialization to the
 * `created()` lifecycle hook.
 *
 * The field will behave as if it was initialized with `undefined`,
 * similar to how it would behave
 *
 * @example
 * ```ts
 * @Component
 * export default class extends Vue {
 *     // Note: no need to assign a 'dummy' value here, nor to
 *     // add `null` to the type.
 *     @InitializeReactive
 *     foo: number | undefined;
 *
 *     // Note: for 'required' fields, make sure to add the `!`
 *     // and don't forget to assign a value in `created()`.
 *     @InitializeReactive
 *     bar!: number;
 *
 *     created() {
 *         // Make sure to 'actually' assign it something, like
 *         this.bar = 42;
 *         // or
 *         this.bar = fakeUnref(useBar());
 *     }
 * ```
 */
export function InitializeReactive(
  target: any,
  propertyKey: string | symbol
): void {
  // By assigning it a Ref<undefined>, the property is considered
  // 'defined' and will be made reactive, even though the value
  // contained in it is actually `undefined`.
  Object.defineProperty(target, propertyKey, {
    value: ref(),
    writable: true,
    enumerable: true,
    configurable: true,
  });
}

/**
 * Work-around that enables using a 'normal' useXXXQuery function just once,
 * outside `<stript setup>` / `created()`.
 *
 * Should only be used as a quick fix to replace an existing imperative API
 * call with a query, to at least benefit from caching.
 *
 * Do NOT use this method in new code, use the useXXXQuery() in `<script setup>`
 * as usual instead, to benefit from updated query information, etc. etc.
 *
 * Conceptually, this function is similar to `queryClient.fetch(...)`, but allows
 * using useXXXQuery functions, instead of 'manually' extracting the query out
 * of that.
 */
export async function executeQueryOnce<
  TFunction extends (...args: any[]) => UseQueryReturnType<TData, TError>,
  TData,
  TError
>(
  queryFunction: TFunction,
  ...args: Parameters<typeof queryFunction>
): Promise<TData> {
  const result = await runInMainContextAsync(async () =>
    queryFunction(...args).suspense()
  );
  return result.data!;
}
