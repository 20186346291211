<script setup lang="ts">
import { inject } from 'vue';
import ExpandedPayloadMonitoring from './ExpandedPayloadMonitoring.vue';
import SmallPayloadMonitoring from './SmallPayloadMonitoring.vue';

const isInExpandedMode = inject('expanded') ?? false;
</script>

<template>
  <ExpandedPayloadMonitoring v-if="isInExpandedMode" />
  <SmallPayloadMonitoring v-else />
</template>
