<script lang="ts">
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { UserModule } from '@/store/modules/user';
import Pagination from '@/components/pagination/Pagination.vue';
import Sortable from 'sortablejs';
import { ALARM_CONFIG_TIME_UNITS_list } from '@/utils/workData/lookuptable';
import { Kpi } from '../models/kpi.model';
import { customWarningMessage } from '@/utils/prompt';
import { InvalidAlarm } from '../models/invalidAlarm';
import { AlarmTableData } from '../models/alarmTableData';

@Component({
  name: 'AlarmConfigTable',
  components: {
    Pagination,
  },
})
export default class extends Vue {
  @Prop() tableList!: AlarmTableData[];
  @Prop({ default: true }) showPage!: boolean;
  @Prop() treeProp!: any;
  @Prop() cols!: [];
  @Prop() total!: number;
  @Prop() viewPagePath!: string;
  @Prop() actionTable!: string;
  @Prop() maxHeight!: string;
  @Prop() warningMessageListAndLastCalled!: {
    time: number;
    list: string[];
  }[];

  /** Local variables */
  list: AlarmTableData[] = [...this.tableList];
  tableComponentKey: string = new Date().toString();
  dropCols: any = [];
  timeUnitList: any = ALARM_CONFIG_TIME_UNITS_list;
  pageSize = UserModule.gridPageSize;
  listQuery = {
    page: 1,
    limit: UserModule.gridPageSize,
  };
  routesWithEditModelDisabled: any = [
    'alarmConfigurationViewPage',
    'Administration',
  ];

  inputWarningThresholdClass: string = 'input-warning-threshold';
  inputAlarmThresholdClass: string = 'input-alarm-threshold';
  inputWarningIntervalClass: string = 'input-warning-interval';
  inputAlarmIntervalClass: string = 'input-alarm-interval';
  isInvalidAlarmAndWarning: boolean = false;
  invalidColor: string = '#EA8827';
  invalidTextColor: string = `color: ${this.invalidColor}`;
  invalidBorder: string = `border: 1px solid ${this.invalidColor}`;
  isAlreadyWarningMessage: boolean = false;
  maxValueForKg: number = 999;
  maxValueForTonnes: number = 999.0;

  @Watch('tableList')
  onListChange() {
    this.list = this.tableList ? [...this.tableList] : [];
  }

  rowChanged(currentRow: Kpi, event: any, showWarningMessage?: boolean): void {
    this.$emit('add-changed-alarm', currentRow);
    this.validateRow(currentRow, event, showWarningMessage);
  }

  validateRow(currentRow: Kpi, event: any, showWarningMessage?: boolean): void {
    const {
      isInvalid: missingWarningInterval,
      isInvalidWithoutTreshold: warningIntervalWithoutWarningTreshold,
    } = this.isInvalidInterval(
      currentRow.warningIntervalValue,
      currentRow.warningIntervalUnit,
      this.inputWarningIntervalClass,
      currentRow.id,
      !!currentRow.warningLow || !!currentRow.warningHigh
    );
    const {
      isInvalid: missingAlarmInterval,
      isInvalidWithoutTreshold: alarmIntervalWithoutAlarmTreshold,
    } = this.isInvalidInterval(
      currentRow.alarmIntervalValue,
      currentRow.alarmIntervalUnit,
      this.inputAlarmIntervalClass,
      currentRow.id,
      !!currentRow.alarmLow || !!currentRow.alarmHigh
    );

    let isInvalidWarningThreshold: boolean = false;
    let isInvalidAlarmThreshold: boolean = false;

    if (currentRow.warningLow && currentRow.warningHigh) {
      isInvalidWarningThreshold = this.checkIsInvalidThreshold(
        currentRow.warningLow,
        currentRow.warningHigh,
        event,
        this.inputWarningThresholdClass,
        currentRow.id
      );
    } else if (!this.isInvalidAlarmAndWarning) {
      this.removeValidateThresholdBorder(
        this.inputWarningThresholdClass,
        currentRow.id
      );
    }

    if (currentRow.alarmLow && currentRow.alarmHigh) {
      isInvalidAlarmThreshold = this.checkIsInvalidThreshold(
        currentRow.alarmLow,
        currentRow.alarmHigh,
        event,
        this.inputAlarmThresholdClass,
        currentRow.id
      );
    } else if (!this.isInvalidAlarmAndWarning) {
      this.removeValidateThresholdBorder(
        this.inputAlarmThresholdClass,
        currentRow.id
      );
    }

    const invalidThresholdValues: boolean =
      isInvalidWarningThreshold || isInvalidAlarmThreshold;
    if (
      currentRow.warningLow ||
      currentRow.warningHigh ||
      currentRow.alarmLow ||
      currentRow.alarmHigh
    ) {
      this.isInvalidAlarmAndWarning =
        this.checkIfInvalidAlarmsLimitsWithWarningValues(
          currentRow,
          event,
          invalidThresholdValues
        );
    } else {
      this.isInvalidAlarmAndWarning = false;
    }

    if (
      missingWarningInterval ||
      missingAlarmInterval ||
      warningIntervalWithoutWarningTreshold ||
      alarmIntervalWithoutAlarmTreshold ||
      invalidThresholdValues ||
      this.isInvalidAlarmAndWarning
    ) {
      const invalidAlarm: InvalidAlarm = {
        id: currentRow.id,
        missingWarningInterval,
        missingAlarmInterval,
        warningIntervalWithoutWarningTreshold,
        alarmIntervalWithoutAlarmTreshold,
        isInvalidWarningThreshold,
        isInvalidAlarmThreshold,
        isInvalidAlarmAndWarning: this.isInvalidAlarmAndWarning,
      };

      this.$emit('add-invalid-alarm', invalidAlarm);

      if (
        showWarningMessage &&
        (invalidThresholdValues || this.isInvalidAlarmAndWarning)
      ) {
        const warningValidateMessage: string[] = [];

        if (invalidThresholdValues) {
          warningValidateMessage.push(
            this.$t('alarmConfig.thresholdErrorMessage').toString()
          );
        }

        if (this.isInvalidAlarmAndWarning) {
          warningValidateMessage.push(
            this.$t('alarmConfig.thresholdsRangeErrorMessage').toString()
          );
        }

        if (!!this.warningMessageListAndLastCalled.length) {
          this.warningMessageListAndLastCalled.pop();
        }
        this.warningMessageListAndLastCalled.push({
          time: Date.now(),
          list: warningValidateMessage,
        });

        customWarningMessage(warningValidateMessage.join(' '));
      }
    } else {
      this.$emit('remove-invalid-alarm', currentRow.id);
      this.removeValidateThresholdBorder(
        this.inputWarningThresholdClass,
        currentRow.id
      );
      this.removeValidateThresholdBorder(
        this.inputAlarmThresholdClass,
        currentRow.id
      );
    }
  }

  checkIfInvalidAlarmsLimitsWithWarningValues(
    currentRow: Kpi,
    event: any,
    isThresholdInvalid: boolean
  ) {
    let isInvalid: boolean = false;

    const warningLow = parseInt(currentRow.warningLow);
    const warningHigh = parseInt(currentRow.warningHigh);

    const alarmLow = parseInt(currentRow.alarmLow);
    const alarmHigh = parseInt(currentRow.alarmHigh);

    if (
      !isNaN(warningLow) &&
      !isNaN(warningHigh) &&
      !isNaN(alarmLow) &&
      !isNaN(alarmHigh) &&
      (alarmLow >= warningLow ||
        alarmHigh <= warningHigh ||
        alarmHigh <= warningLow ||
        alarmLow >= warningHigh)
    ) {
      isInvalid = true;
    } else if (
      isNaN(warningLow) &&
      !isNaN(warningHigh) &&
      !isNaN(alarmLow) &&
      !isNaN(alarmHigh) &&
      (alarmHigh <= warningHigh || alarmLow >= warningHigh)
    ) {
      isInvalid = true;
    } else if (
      !isNaN(warningLow) &&
      isNaN(warningHigh) &&
      !isNaN(alarmLow) &&
      !isNaN(alarmHigh) &&
      (alarmHigh <= warningLow || alarmLow >= warningLow)
    ) {
      isInvalid = true;
    } else if (
      !isNaN(warningLow) &&
      !isNaN(warningHigh) &&
      isNaN(alarmLow) &&
      !isNaN(alarmHigh) &&
      (alarmHigh <= warningLow || alarmHigh <= warningHigh)
    ) {
      isInvalid = true;
    } else if (
      !isNaN(warningLow) &&
      !isNaN(warningHigh) &&
      !isNaN(alarmLow) &&
      isNaN(alarmHigh) &&
      (alarmLow >= warningLow || alarmLow >= warningHigh)
    ) {
      isInvalid = true;
    } else if (
      !isNaN(warningLow) &&
      isNaN(warningHigh) &&
      !isNaN(alarmLow) &&
      isNaN(alarmHigh) &&
      alarmLow >= warningLow
    ) {
      isInvalid = true;
    } else if (
      isNaN(warningLow) &&
      !isNaN(warningHigh) &&
      isNaN(alarmLow) &&
      !isNaN(alarmHigh) &&
      alarmHigh <= warningHigh
    ) {
      isInvalid = true;
    } else if (
      !isNaN(warningLow) &&
      isNaN(warningHigh) &&
      isNaN(alarmLow) &&
      !isNaN(alarmHigh) &&
      alarmHigh <= warningLow
    ) {
      isInvalid = true;
    } else if (
      isNaN(warningLow) &&
      !isNaN(warningHigh) &&
      !isNaN(alarmLow) &&
      isNaN(alarmHigh) &&
      alarmLow >= warningHigh
    ) {
      isInvalid = true;
    }

    if (!!event) {
      const cssClass: string = event.target.classList.contains(
        this.inputWarningThresholdClass
      )
        ? this.inputWarningThresholdClass
        : this.inputAlarmThresholdClass;

      if (isInvalid) {
        event.target.setAttribute(
          'style',
          `${this.invalidTextColor}; ${this.invalidBorder};`
        );
      } else if (!isThresholdInvalid) {
        this.removeValidateThresholdBorder(cssClass, currentRow.id);
      }
    }

    return isInvalid;
  }

  isInvalidInterval(
    intervalValue: string,
    unit: string,
    inputClassName: string,
    rowId: string,
    isLowOrHigh: boolean
  ): {
    isInvalid: boolean;
    isInvalidWithoutTreshold: boolean;
  } {
    const isIntervalValueInvalid: boolean =
      !intervalValue && (isLowOrHigh || !!unit);
    const isUnitInvalid: boolean = !unit && (isLowOrHigh || !!intervalValue);

    const isIntervalValueInvalidWithoutTreshold: boolean =
      (isLowOrHigh && !intervalValue) || (!isLowOrHigh && !!intervalValue);
    const isUnitInvalidWithoutTreshold: boolean =
      (isLowOrHigh && !unit) || (!isLowOrHigh && !!unit);

    const intervalValueInput: Element = document.getElementsByClassName(
      `${inputClassName}-value ${rowId}`
    )[0];
    const intervalUnitInput = document.getElementsByClassName(
      `${inputClassName}-unit ${rowId}`
    )[0].firstElementChild?.firstElementChild;

    if (isIntervalValueInvalid || isIntervalValueInvalidWithoutTreshold) {
      intervalValueInput.setAttribute(
        'style',
        `${this.invalidTextColor}; ${this.invalidBorder};`
      );
    }

    if (isUnitInvalid || isUnitInvalidWithoutTreshold) {
      intervalUnitInput?.setAttribute(
        'style',
        `${this.invalidBorder} !important;`
      );
    }

    if (!isUnitInvalid && !isUnitInvalidWithoutTreshold) {
      intervalUnitInput?.removeAttribute('style');
    }

    if (!isIntervalValueInvalid && !isIntervalValueInvalidWithoutTreshold) {
      intervalValueInput.removeAttribute('style');
    }

    return {
      isInvalid: isIntervalValueInvalid || isUnitInvalid,
      isInvalidWithoutTreshold:
        isIntervalValueInvalidWithoutTreshold || isUnitInvalidWithoutTreshold,
    };
  }

  removeIntervalValidationsBorder(inputClassName: string, rowId: string) {
    const intervalValueInput: Element = document.getElementsByClassName(
      `${inputClassName}-value ${rowId}`
    )[0];
    const intervalUnitInput = document.getElementsByClassName(
      `${inputClassName}-unit ${rowId}`
    )[0].firstElementChild?.firstElementChild;

    intervalValueInput.removeAttribute('style');
    intervalUnitInput?.removeAttribute('style');
  }

  checkIsInvalidThreshold(
    low: string,
    high: string,
    event: any,
    inputClassName: string,
    rowId: string
  ): boolean {
    const isValid: boolean = this.isValidLowerWithHigherValue(low, high);

    if (!isValid) {
      if (!!event) {
        event.target.setAttribute(
          'style',
          `${this.invalidTextColor}; ${this.invalidBorder};`
        );
      }

      return true;
    } else if (!this.isInvalidAlarmAndWarning) {
      this.removeValidateThresholdBorder(inputClassName, rowId);
    }

    return false;
  }

  removeValidateThresholdBorder(inputClassName: string, rowId: string) {
    const warningsThresholdInputs: HTMLCollectionOf<Element> =
      document.getElementsByClassName(`${inputClassName} ${rowId}`);
    for (let i = 0; i < warningsThresholdInputs.length; i++) {
      warningsThresholdInputs[i].removeAttribute('style');
    }
  }

  isValidLowerWithHigherValue(lowValue: string, highValue: string): boolean {
    return parseInt(lowValue) < parseInt(highValue);
  }

  get activeCols() {
    this.dropCols = this.cols.filter((item: any) => {
      return item.visible;
    });

    this.tableComponentKey = new Date().toString();

    return this.dropCols;
  }

  mounted() {
    this.columnDrop();
  }

  /**
    all the action buttons call the same function, the event name depends on the item param
    * @param item
    * @param row
    */
  handleActionBtnClick(item: string, row: any) {
    this.$emit(item, row);
  }

  handleRowClick(row: any, column: any, event: any) {
    if (
      row.hasOwnProperty('gatewayId') &&
      row.gatewayId &&
      !row.hasOwnProperty('installTime')
    ) {
      this.$router.push('register-connecting/view/' + row.id);
      return;
    }

    if (this.viewPagePath) {
      this.$router.push(this.viewPagePath + '/' + row.id);
    }
  }

  handleSortChange(val: any) {
    let sortType = '';

    if (val.order === 'ascending') {
      sortType = 'ASC';
    } else if (val.order === 'descending') {
      sortType = 'DESC';
    }

    this.$emit('handle-sort-change', val.prop, sortType);
  }

  getList(val: any) {
    this.$emit('handle-page', val.page, this.pageSize);
  }

  columnDrop() {
    var that = this;
    const theader: any = document.querySelector('.el-table__header-wrapper tr');
    Sortable.create(theader, {
      animation: 180,
      delay: 0,
      onEnd: (evt: any) => {
        let oldMoveItem = this.dropCols[evt.oldIndex];
        let newMoveItem = this.dropCols[evt.newIndex];

        let oldItemIndex = this.cols.findIndex((item: any) => {
          return item.prop === oldMoveItem.prop;
        });
        let newItemIndex = this.cols.findIndex((item: any) => {
          return item.prop === newMoveItem.prop;
        });
        this.cols.splice(newItemIndex, 0, this.cols.splice(oldItemIndex, 1)[0]);
      },
    });
  }

  get checkIfAlarmConfigIsInEditMode(): boolean {
    return !this.routesWithEditModelDisabled.includes(this.$route.name);
  }

  /** Validate input values to be only number + delete key */
  acceptOnlyNumericValues(event: KeyboardEvent) {
    const acceptedFloatChar: string[] = ['.'];
    const acceptedDeleteKeys: string[] = ['Backspace', 'Delete'];
    if (
      (acceptedDeleteKeys.some((p) => p == event.key) ||
        acceptedFloatChar.some((p) => p == event.key)) &&
      (event.target as HTMLInputElement).value.length > 0
    ) {
      return;
    }

    const regexp = new RegExp(/[0-9]/);
    if (
      !regexp.test(event.key) ||
      parseInt((event.target as HTMLInputElement).value) > 999 ||
      (event.target as HTMLInputElement).value.length > 2
    ) {
      event.preventDefault();
    }
  }
}
</script>

<template>
  <div>
    <el-table
      :data="list"
      :tree-props="treeProp"
      row-key="id"
      :max-height="maxHeight"
      :row-style="{ height: '40px' }"
      :cell-style="{ padding: '7px 0px' }"
      @row-click="handleRowClick"
      @sort-change="handleSortChange"
    >
      <el-table-column
        v-for="(col, index) in activeCols"
        :key="index + '_' + tableComponentKey"
        :prop="col.prop"
        min-width="180"
        :sortable="col.sortable"
        :width="col.width ? col.width : 'auto'"
        show-overflow-tooltip
      >
        <template v-slot:header>
          <div class="table-header">
            <span style="white-space: nowrap">{{ $t(col.label) }}</span>
          </div>
        </template>

        <!-- translate language -->
        <template v-slot="slotProps" v-if="col.multiLang">
          <span>{{ $t(slotProps.row[col.prop]) }}</span>
        </template>
        <!-- action parts -->
        <template v-slot="slotProps" v-else-if="col.prop === 'action'">
          <el-button
            v-for="(item, index) in slotProps.row[col.prop]"
            :key="index"
            @click.stop="handleActionBtnClick(item, slotProps.row)"
            type="text"
            size="small"
            >{{ $t(item) }}</el-button
          >
        </template>
        <template v-slot="slotProps" v-else-if="col.prop === 'unit'">
          <span>{{ $t(slotProps.row[col.prop]) }}</span>
        </template>

        <template v-slot="slotProps" v-else-if="col.prop === 'warningInterval'">
          <div class="input-parent-container">
            <input
              v-if="checkIfAlarmConfigIsInEditMode && slotProps.row.isEditable"
              id="warning_interval_value_edit"
              class="input-element input-warning-interval-value"
              :class="slotProps.row.id"
              v-model="slotProps.row.warningIntervalValue"
              @keydown="acceptOnlyNumericValues"
              @change="rowChanged(slotProps.row)"
            />
            <div
              v-else
              class="value-in-edit-mode"
              id="warning_interval_value_view"
            >
              {{ slotProps.row.warningIntervalValue }}
            </div>
            <el-select
              v-if="checkIfAlarmConfigIsInEditMode && slotProps.row.isEditable"
              id="warning_interval_time_unit_value_edit"
              class="dropdown-input-element input-warning-interval-unit"
              :class="slotProps.row.id"
              v-model="slotProps.row.warningIntervalUnit"
              :placeholder="$t('common.pleaseSelect')"
              clearable
              @change="rowChanged(slotProps.row)"
            >
              <el-option
                v-for="(item, index) in timeUnitList"
                :id="'warning_time_unit_item_' + index"
                :key="index"
                :label="$t(item.value)"
                :value="item.value"
              />
            </el-select>
            <div
              v-else
              id="warning_interval_time_unit_value_view"
              class="interval-value-in-edit-mode"
            >
              {{ $t(slotProps.row.warningIntervalUnit) }}
            </div>
          </div>
        </template>

        <template v-slot="slotProps" v-else-if="col.prop === 'alarmInterval'">
          <div class="input-parent-container">
            <input
              id="alarm_interval_value_edit"
              v-if="checkIfAlarmConfigIsInEditMode && slotProps.row.isEditable"
              class="input-element input-alarm-interval-value"
              :class="slotProps.row.id"
              v-model="slotProps.row.alarmIntervalValue"
              @keydown="acceptOnlyNumericValues"
              @change="rowChanged(slotProps.row)"
            />
            <div
              v-else
              id="alarm_interval_value_view"
              class="value-in-edit-mode"
            >
              {{ slotProps.row.alarmIntervalValue }}
            </div>
            <el-select
              id="alarm_interval_time_unit_value_edit"
              v-if="checkIfAlarmConfigIsInEditMode && slotProps.row.isEditable"
              class="dropdown-input-element input-alarm-interval-unit"
              :class="slotProps.row.id"
              v-model="slotProps.row.alarmIntervalUnit"
              :placeholder="$t('common.pleaseSelect')"
              clearable
              @change="rowChanged(slotProps.row)"
            >
              <el-option
                v-for="(item, index) in timeUnitList"
                :id="'alarm_time_unit_item_' + index"
                :key="index"
                :label="$t(item.value)"
                :value="item.value"
              />
            </el-select>
            <div
              v-else
              id="alarm_interval_time_unit_value_view"
              class="interval-value-in-edit-mode"
            >
              {{ $t(slotProps.row.alarmIntervalUnit) }}
            </div>
          </div>
        </template>

        <template
          v-slot="slotProps"
          v-else-if="col.prop === 'warningThreshold'"
        >
          <div class="input-parent-container">
            <p class="lower-input-naming">{{ $t('alarmConfig.lower') }}</p>
            <input
              id="warning_low_threshold_edit"
              v-if="checkIfAlarmConfigIsInEditMode && slotProps.row.isEditable"
              class="input-element input-warning-threshold"
              :class="slotProps.row.id"
              v-model="slotProps.row.warningLow"
              @keydown="acceptOnlyNumericValues"
              @change="rowChanged(slotProps.row, $event, true)"
            />
            <div
              v-else
              id="warning_low_threshold_view"
              class="value-in-edit-mode"
            >
              {{ slotProps.row.warningLow }}
            </div>
            <p class="upper-input-naming">{{ $t('alarmConfig.upper') }}</p>
            <input
              id="warning_high_threshold_edit"
              v-if="checkIfAlarmConfigIsInEditMode && slotProps.row.isEditable"
              class="input-element input-warning-threshold"
              :class="slotProps.row.id"
              v-model="slotProps.row.warningHigh"
              @keydown="acceptOnlyNumericValues"
              @change="rowChanged(slotProps.row, $event, true)"
            />
            <div
              v-else
              id="warning_high_threshold_view"
              class="value-in-edit-mode"
            >
              {{ slotProps.row.warningHigh }}
            </div>
          </div>
        </template>

        <template v-slot="slotProps" v-else-if="col.prop === 'alarmThreshold'">
          <div class="input-parent-container">
            <p class="lower-input-naming">{{ $t('alarmConfig.lower') }}</p>
            <input
              id="alarms_low_threshold_edit"
              v-if="checkIfAlarmConfigIsInEditMode && slotProps.row.isEditable"
              class="input-element input-alarm-threshold"
              :class="slotProps.row.id"
              v-model="slotProps.row.alarmLow"
              @keydown="acceptOnlyNumericValues"
              @change="rowChanged(slotProps.row, $event, true)"
            />
            <div
              v-else
              id="alarms_low_threshold_view"
              class="value-in-edit-mode"
            >
              {{ slotProps.row.alarmLow }}
            </div>
            <p class="upper-input-naming">
              {{ $t('alarmConfig.upper') }}
            </p>
            <input
              id="alarms_high_threshold_view"
              v-if="checkIfAlarmConfigIsInEditMode && slotProps.row.isEditable"
              class="input-element input-alarm-threshold"
              :class="slotProps.row.id"
              v-model="slotProps.row.alarmHigh"
              @keydown="acceptOnlyNumericValues"
              @change="rowChanged(slotProps.row, $event, true)"
            />
            <div
              v-else
              id="alarms_high_threshold_view"
              class="value-in-edit-mode"
            >
              {{ slotProps.row.alarmHigh }}
            </div>
          </div>
        </template>

        <template v-slot="slotProps" v-else-if="col.prop === 'isLocked'">
          <el-switch
            id="is_locked_parent_btn"
            class="el-switch-component"
            v-if="checkIfAlarmConfigIsInEditMode && slotProps.row.isEditable"
            v-model="slotProps.row.isLocked"
            active-color="var(--Main)"
            inactive-color="#D5D5D5"
            @change="rowChanged(slotProps.row, undefined, true)"
          />
          <div v-else id="is_locked_info" class="value-in-edit-mode">
            {{
              slotProps.row.isLocked
                ? $t('alarmConfig.on')
                : $t('alarmConfig.off')
            }}
          </div>
        </template>
      </el-table-column>
    </el-table>

    <!-- v-show="total > 0" -->
    <Pagination
      id="pagination_component"
      v-show="showPage && total > pageSize"
      :total="total"
      :page.sync="listQuery.page"
      :limt.sync="listQuery.limit"
      @pagination="getList"
    />
  </div>
</template>

<style scoped>
.el-switch-component:hover {
  border: 1px solid gray;
  border-radius: 10px;
  padding: 1px;
}

.input-parent-container {
  display: flex;
  width: 200px;
  align-items: center;
}

.input-element {
  border: 0.5px solid #707070;
  border-radius: 3px;
  width: 40px;
  height: 25px;
  text-align: center;
}

.input-element:focus {
  border-style: none;
}

.lower-input-naming {
  margin: 0 4px 0 0;
}

.upper-input-naming {
  margin: 0 2px 0 2px;
}

.value-in-edit-mode {
  text-align: center;
  font-weight: bold;
  width: 40px;
  /* padding: 0 4px 0 4px; */
  background: #ececec;
  border-radius: 5px;
  min-height: 25px;
  overflow: hidden;
}

.interval-value-in-edit-mode {
  margin-left: 5px;
  text-align: center;
  font-weight: bold;
  width: 130px;
  padding: 0 4px 0 4px;
  background: #ececec;
  border-radius: 5px;
  min-height: 25px;
}

.dropdown-input-element :deep(.el-input__inner) {
  font-family: var(--font-Roboto-Medium);
  font-size: 14px;
  width: 130px;
  height: 25px;
  margin-left: 5px;
}

.dropdown-input-element :deep(.el-select-dropdown__item) {
  font-size: 14px;
}

.dropdown-input-element :deep(.el-input__suffix) {
  margin-top: 4px;
}

.dropdown-input-element :deep(.is-reverse) {
  margin-top: -8px;
}
</style>

<style scoped>
.connect-btn {
  border-radius: 6px;
}

.connect-btn:hover {
  color: white;
  background-color: #5f6567 !important;
}

.el-table tbody tr:hover > td {
  /* background-color: rgb(var(--Main), 0.6) !important; */
  background-color: var(--Main) !important;
  /* opacity: 0.5; */
  /* cursor: pointer; */
}

.el-table :deep(.cell) {
  white-space: pre;
}

.el-table th {
  border-top: 1px solid #dddddd;
  border-bottom: 1px solid #dddddd !important;
}

.el-table td {
  border-bottom: 1px solid #dddddd !important;
}

.el-table {
  font-size: 14px !important;
  font-family: var(--fontRobotoRegular);
  line-height: 16px;
  color: #373e41 !important;
  opacity: 1;
}

.el-table thead {
  font-size: 14px !important;
  font-family: var(--fontRobotoMedium);
  line-height: 16px;
  color: #373e41 !important;
  opacity: 1;
}
</style>

<style lang="scss" scoped>
.input-element {
  font-family: $font-Roboto-Medium;
  font-size: 14px;
}

.table-header {
  display: inline-block;
  margin-right: 12px;
}

.module-access {
  margin-left: 4px;
  margin-right: 4px;
}

.module-access-item {
  width: 17px;
  height: 17px;
}

.dropDownMenu-item {
  line-height: 25px;
  text-indent: 10px;
  cursor: pointer;
  padding-left: 10px;
  padding-top: 5px;
}

.dropDownMenu-item:hover {
  background-color: var(--Main);
}

.dm-font {
  font-size: 14px;
  font-family: $font-Roboto-Medium;
  line-height: 16px;
  color: #363636;
}

.el-input {
  width: 300px;
}

.el-dropdown .el-select {
  margin-right: 40px;
}

.util-table-input :deep(.el-input__inner) {
  height: $inputHeight;
  width: 300px;
  // width: 31vw;
  border: 1px solid #707070 !important;
  font-size: 1.285714rem;
  font-family: $font-Roboto-Medium;
  line-height: 1.357143rem;
  color: #373e41;
  opacity: 1;
}

.util-table-select :deep(.el-input__inner) {
  height: $inputHeight;
  width: 200px;
  // width: 31vw;
  border: 1px solid #707070 !important;
  font-size: 1.285714rem;
  font-family: $font-Roboto-Medium;
  line-height: 1.357143rem;
  color: #373e41;
  opacity: 1;
}

:deep(.el-button) {
  border: 1px solid #707070;
  padding: 4px 24px;
}
:deep(.el-button--text) {
  color: #707070;
}

:deep(.el-table .sort-caret.ascending) {
  border-bottom-color: #000000;
}
:deep(.el-table .sort-caret.descending) {
  border-top-color: #000000;
}
:deep(.el-table .ascending .sort-caret.ascending) {
  border-bottom-color: var(--Main);
}
:deep(.el-table .descending .sort-caret.descending) {
  border-top-color: var(--Main);
}
</style>
