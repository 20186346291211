import { Value } from '@/api/value';
import { KPI_UNIT } from './unitDefinitions';

export interface UnitValue<T> extends Value<T> {
  unit: KPI_UNIT;
}

export function toUnitValue<V extends Value<any>>(
  value: V,
  unit: KPI_UNIT
): V & UnitValue<V['v']>;
export function toUnitValue<T extends number | undefined>(
  value: T,
  unit: KPI_UNIT
): UnitValue<T>;
export function toUnitValue<T extends Value<number>>(
  value: number | T,
  unit: KPI_UNIT
): T & UnitValue<any> {
  if (typeof value === 'number' || value === undefined) {
    return { v: value, unit } as T & UnitValue<any>;
  }
  if (
    !value ||
    typeof value !== 'object' ||
    (typeof value.v !== 'number' && value.v !== undefined)
  ) {
    throw new Error('cannot convert non-numeric value to UnitValue');
  }
  return { ...value, unit };
}
