<script setup lang="ts">
import {
  getOrganizationAssetsHierarchyLegacy,
  OrganizationAssetsHierarchyResponseLegacy,
} from '@/api/assets';
import { UUID } from '@/api/common';
import { Event, EventResponse, getEvents } from '@/api/event';
import { Asset } from '@/api/geofenceTypes';
import { IncludedWidget } from '@/api/widgets';
import { useActiveContext } from '@/auth/context';
import { useLoggedInUser } from '@/auth/user';
import MultiSelectDropDown from '@/components/form/MultiSelectDropDown.vue';
import TimeSelect from '@/components/form/TimeSelect.vue';
import Card from '@/components/layout/widget/Card.vue';
import LeafletMap from '@/components/leafletMap/LeafletMap.vue';
import KpiSafetyTable from '@/components/table/KpiSafetyTable.vue';
import { useRoute } from '@/composables/router';
import { Filter, FilterOperator } from '@/model/queryParameters/QueryParameter';
import { UserModule } from '@/store/modules/user';
import { extractAssetsFromOrganizationHierarchyLegacy } from '@/utils/assets';
import { AssetType } from '@/utils/assetTypes';
import { isDesignatedCompany } from '@/utils/companyService';
import { DateRange } from '@/utils/types/date';
import { Option } from '@/utils/types/option';
import { EVENT_CODES } from '@/utils/workData/lookuptable';
import { inject, onMounted, Ref, ref, unref } from 'vue';

interface MultiAssetSelectList {
  id: string;
  name: string;
  selected: boolean;
}

export interface KpiSafetyTableEntity {
  id: string;
  assetId: string;
  buckling: string;
  overload: string;
  safeState: string;
  sideloadDanger: string;
  sideloadWarning: string;
}

const widget = inject<Ref<IncludedWidget>>('widget');

const zoom = ref<number>(12);
const center = ref<number[]>([0, 0]);
const selectedId = ref<string>('');
const tableData = ref<KpiSafetyTableEntity[]>([]);
const contentIsLoading = ref<boolean>(false);
const multiAssetSelectIsLoading = ref<boolean>(false);
const events = ref<Event[]>([]);
const bounds = ref<number[][]>([]);
const context = useActiveContext();
const loggedInUser = useLoggedInUser();
const route = useRoute();

/** Used for multi asset select dropdown */
const multiAssetSelectList = ref<Option[]>([]);
const multiAssetsCurrentSelections = ref<UUID[]>([]);
const multiAssetsCurrentSelectionsChangedFromDefault = ref<boolean>(false);

/** Pre loading setup constants */
const currentDateRange = ref<DateRange>();
const organizationHierarchyAssets = ref<Asset[]>();

const map = ref<LeafletMap>();

onMounted(async () => {
  prepareLoadingSetup();
});

/**
 * Prepare setup with default values
 * Need to have organizations hierarchy of the selected customer as top wrapper info layer
 * For multiple filter combinations
 */
async function prepareLoadingSetup(): Promise<void> {
  try {
    contentIsLoading.value = true;
    multiAssetSelectIsLoading.value = true;

    /** Populate initial organization hierarchy */
    const organizationHierarchyResponse = await fetchOrganizations();

    /** Extract assets from current organization hierarchy and populate multi asset dropdown list */
    organizationHierarchyAssets.value =
      extractAssetsFromOrganizationHierarchyLegacy(
        organizationHierarchyResponse
      );
    populateMultiAssetList(organizationHierarchyAssets.value!);

    handleEvents();
  } catch (error) {
    console.log(error);
  } finally {
    multiAssetSelectIsLoading.value = false;
  }
}

/**
 * Fetch events independently from loading setup to be triggered from date selection also
 */
async function handleEvents(): Promise<void> {
  try {
    if (map.value) {
      map.value!.fixMapSize();
    }

    /** After each asset is selected, make no specific previous asset selection on the map */
    selectedId.value = '';

    /** If user unselect all assets, do not make API request, instead clean up in the UI all data */
    if (
      !multiAssetsCurrentSelections.value ||
      multiAssetsCurrentSelections.value.length === 0
    ) {
      events.value = [];
      tableData.value = [];
      bounds.value = [];
      return;
    }

    const eventsResponse: EventResponse = await fetchEvents(
      currentDateRange.value!
    );
    events.value = eventsResponse.events;
    generateData(multiAssetsCurrentSelections.value, events.value);
  } catch (error) {
    console.log(error);
  } finally {
    contentIsLoading.value = false;
  }
}

/**
 * Populate asset list of multi selection dropdown > visible on UI
 * Add selected assets into current selection costant used to fetch data from API > API
 */
function populateMultiAssetList(assets: Asset[]): void {
  if (!assets) return;
  multiAssetSelectList.value = [];

  /**
   * Automatically make asset selected by the following logic:
   * When URL has the asset id (single asset side) make it selected as default from entire list
   * When URL has not asset id (fleet side) make all assets as beeing selected (multi asset will not be displayed in this case)
   */
  assets.forEach((asset: Asset) => {
    multiAssetSelectList.value.push({
      key: asset.id,
      label: asset.companyAssetId,
    });
  });

  const routeId = unref(route).params.id;

  if (routeId) {
    multiAssetsCurrentSelections.value = [routeId];
  } else {
    multiAssetsCurrentSelections.value = multiAssetSelectList.value.map(
      (asset) => asset.key
    );
  }
}

/**
 * Handle date range event changes
 * @param dateRange
 */
function handleTimeFilter(dateRange: DateRange): void {
  contentIsLoading.value = true;
  currentDateRange.value = dateRange;
  handleEvents();
}

/**
 * Filter data by DOM event
 * Will be displayed only those assets that are selected
 */
function filterMultiAssetsSelection(assetsSelection: UUID[]) {
  /**
   * Case: fleet safety side
   * If the user interacted with dropdown asset list after default loading, mark this behavior to true
   * Reference used to request by asset ids istead of default loading: organizations ids
   */
  multiAssetsCurrentSelectionsChangedFromDefault.value = true;

  contentIsLoading.value = true;
  multiAssetsCurrentSelections.value = assetsSelection;
  handleEvents();
}

/**
 * Highlight asset's events from selected table asset into map element
 * @param id
 */
function selectedAssetFromTable(id: string): void {
  selectedId.value = id;
}

/**
 * Fetch events by specific query parameters
 * Ass a wrapper above all the final request will be made for simple customer or impersonated customer
 * When URL has asset id request events by asset id
 * Whel URL doesn't have asset id request events by all organizations hierarchy ids
 * @param dateRange
 */
async function fetchEvents(dateRange: DateRange): Promise<EventResponse> {
  const orgIds = unref(context).organizationIds;

  const assetsFilter: Filter[] = unref(route).params.id
    ? [
        {
          name: 'assetId',
          operator:
            multiAssetsCurrentSelections.value &&
            multiAssetsCurrentSelections.value.length > 1
              ? FilterOperator.IN
              : FilterOperator.EQUAL,
          value: multiAssetsCurrentSelections.value,
        },
      ]
    : orgIds && !multiAssetsCurrentSelectionsChangedFromDefault.value
    ? [
        {
          name: 'organizationId',
          operator: FilterOperator.IN,
          value: orgIds,
        },
      ]
    : multiAssetsCurrentSelectionsChangedFromDefault.value /** When user changes selected assets in fleet safety widget after default setup */ &&
      !unref(route).params.id
    ? [
        {
          name: 'assetId',
          operator:
            multiAssetsCurrentSelections.value &&
            multiAssetsCurrentSelections.value.length > 1
              ? FilterOperator.IN
              : FilterOperator.EQUAL,
          value: multiAssetsCurrentSelections.value,
        },
      ]
    : [];
  const requestData = {
    filters: [
      ...assetsFilter,
      {
        name: 'timestamp',
        operator: FilterOperator.BETWEEN,
        value: [dateRange.start, dateRange.endExclusive],
      },
      {
        name: 'eventTypeCode',
        operator: FilterOperator.IN,
        value: Object.values(EVENT_CODES),
      },
      {
        name: 'i18nCode',
        operator: FilterOperator.EQUAL,
        value: [UserModule.i18nCode],
      },
    ],
    sorters: [],
    pagination: {
      page: 1,
      size: 10000,
    },
    timezone: unref(context)?.primaryOrgTimeZone,
  };
  const user = unref(loggedInUser);
  return user && isDesignatedCompany(user.companyType)
    ? await getEvents(requestData, unref(context))
    : await getEvents(requestData);
}

/**
 * Fetch organizations hierarchy
 */
async function fetchOrganizations(): Promise<OrganizationAssetsHierarchyResponseLegacy> {
  return getOrganizationAssetsHierarchyLegacy(
    AssetType.TippingVehicle,
    unref(context).organization?.id,
    unref(context)
  );
}

/**
 * Generate data tot be displayed in the table
 * @param assetIDs
 * @param data
 */
function generateData(assetIDs: UUID[], events: Event[]): void {
  if (!events || !assetIDs) {
    bounds.value = [];
    return;
  }
  tableData.value = [];
  for (let assetID of assetIDs) {
    const dataSafestate = events.filter(
      (event: Event) =>
        event.eventTypeCode === EVENT_CODES.safety && event.assetId === assetID
    );
    const dataBuckling = events.filter(
      (event: Event) =>
        event.eventTypeCode === EVENT_CODES.buckling &&
        event.assetId === assetID
    );
    const dataSideloadwarning = events.filter(
      (event: Event) =>
        event.eventTypeCode === EVENT_CODES.sideloadWarning &&
        event.assetId === assetID
    );
    const dataSideloadDanger = events.filter(
      (event: Event) =>
        event.eventTypeCode === EVENT_CODES.sideloadDanger &&
        event.assetId === assetID
    );
    const dataOverload = events.filter(
      (event: Event) =>
        event.eventTypeCode === EVENT_CODES.overload &&
        event.assetId === assetID
    );

    bounds.value = [];

    bounds.value = addBounds(bounds.value, dataSafestate);
    bounds.value = addBounds(bounds.value, dataBuckling);
    bounds.value = addBounds(bounds.value, dataSideloadwarning);
    bounds.value = addBounds(bounds.value, dataSideloadDanger);
    bounds.value = addBounds(bounds.value, dataOverload);

    const getText = (array: unknown[]) =>
      `${array.length} (${
        events.length > 0
          ? Math.round((100 / events.length) * array.length)
          : '-'
      }%)`;

    const assetOption = multiAssetSelectList.value.find(
      (assetOpt) => assetOpt.key === assetID
    );

    if (!assetOption) {
      throw new Error('Asset Option not found');
    }

    let assetEvents = {
      assetId: assetOption.label,
      safeState: getText(dataSafestate),
      sideloadDanger: getText(dataSideloadDanger),
      sideloadWarning: getText(dataSideloadwarning),
      overload: getText(dataOverload),
      buckling: getText(dataBuckling),
      id: assetID,
    };

    tableData.value.push(assetEvents);
  }
}

/**
 * Add bounds
 * @param bounds
 * @param events
 */
function addBounds(bounds: any, events: Event[]) {
  const newBounds = [...bounds];
  for (let event of events) {
    let newMarker = [event.location.lat, event.location.lgt];
    newBounds.push(newMarker);
  }
  return newBounds;
}
</script>

<template>
  <Card class="fleet-safety-widget-container">
    <template #header>
      <span class="widget-header-title">
        {{ widget ? $t(widget.code.toString()) : '' }}
      </span>
      <div class="header-actions">
        <MultiSelectDropDown
          v-loading="multiAssetSelectIsLoading"
          :filter-label="$t('selectAssets')"
          :options="multiAssetSelectList"
          :selected-options="multiAssetsCurrentSelections"
          @change="filterMultiAssetsSelection"
        />
        <TimeSelect
          :expanded="true"
          @select="handleTimeFilter"
          :customizable="true"
        />
      </div>
    </template>
    <div class="container" v-loading="contentIsLoading">
      <div class="left-side-map-element">
        <LeafletMap
          ref="map"
          :zoom="zoom"
          :events="events"
          :center="center"
          :bounds="bounds"
          :selectedAsset="selectedId"
        />
      </div>
      <div clas="kpi-safety-table-container">
        <KpiSafetyTable
          class="kpi-safety-table"
          :tableData="tableData"
          @select="selectedAssetFromTable"
        />
      </div>
    </div>
  </Card>
</template>

<style lang="scss" scoped>
.header-actions {
  display: flex;
  gap: 1rem;
  margin-bottom: 1.5rem;
}

.container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  max-width: 100%;
  max-height: 100%;
  margin: 0;
  height: 640px;
}

.leftwidget {
  max-width: 20%;
  min-width: 30rem;
  margin: 0;
  display: flex;
  flex-direction: column;
  width: 20%;
  background: white;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  border: 0.5px solid white;
  overflow: auto;
}

.left-side-map-element {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.search-box {
  width: 100%;
  background: white;
  border: 2px solid white;
  border-top-left-radius: 10px;
  position: center;
  padding: 2% 4% 2% 4%;
  display: auto;
}

.srcbox {
  border: 1px solid rgb(166, 166, 166);
  padding: 1.5% 0% 1.5% 1.5%;
  border-radius: 5px;
}

.assetsTable {
  overflow: auto;
}

.tableInner {
  position: fixed;
}

.mod-input {
  width: 90%;
  border: 0.5px solid white;
}

.mod-input:focus {
  outline: none;
}

.svg-icon {
  width: 16px;
  height: 16px;
  color: inherit;
  stroke: currentColor;
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
}

.svg-fill {
  fill: currentColor;
  stroke: none;
}

.svg-up {
  transform: rotate(0deg);
}

.svg-right {
  transform: rotate(90deg);
}

.svg-down {
  transform: rotate(180deg);
}

.svg-left {
  transform: rotate(-90deg);
}

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-thumb {
  background: rgb(199, 195, 195);
}

::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0.2);
}

.box {
  width: 400px;

  .top {
    text-align: center;
  }

  .left {
    float: left;
    width: 110px;
  }

  .right {
    float: right;
    width: 110px;
  }

  .bottom {
    clear: both;
    text-align: center;
  }

  .item {
    margin: 4px;
  }

  .left .el-tooltip__popper,
  .right .el-tooltip__popper {
    padding: 8px 10px;
  }

  .el-button {
    width: 110px;
  }
}

.container {
  overflow: hidden;
}

@media screen and (max-height: 767px) {
  .fleet-safety-widget-container {
    max-height: 65vh;
  }

  .left-side-map-element {
    max-height: 49.5vh;
  }
}

@media screen and (min-height: 768px) and (max-height: 1023px) {
  .fleet-safety-widget-container {
    max-height: 72vh;
  }

  .left-side-map-element {
    max-height: 60vh;
  }
}

.kpi-safety-table-container {
  padding: 20px;
}

.kpi-safety-table {
  height: 60vh;
  overflow: auto;
}
</style>
