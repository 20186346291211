import { api } from '@/utils/api';
import { UnitSystem } from '@/utils/units/systemConversionDefinitions';
import { KPI_UNIT, Quantity } from '@/utils/units/unitDefinitions';

export interface Unit {
  code: KPI_UNIT;
  name: string;
  symbol: string;

  /**
   * It's `undefined` if unit is not convertible (e.g. percentage, text, json)
   */
  factor: number | undefined;

  offset: number | undefined;

  /**
   * Currently if the UnitSystem is Metric we receive `undefined`.
   */
  unitSystem: UnitSystem | undefined;
  quantityKind: Quantity;
}

export async function getUnits(
  unitCode?: KPI_UNIT,
  unitSystem?: UnitSystem
): Promise<Unit[]> {
  return await api().get<Unit[]>('/units', {
    params: {
      unitCode,
      unitSystem,
    },
  });
}
