import { UUID } from '@/api/common';
import { AssetType } from '@/utils/assetTypes';
import { isDefined } from '@/utils/collections';
import { Location, Route } from 'vue-router';
import { overviewRouteNames } from '../modules/assets';
import { overviewOperationalDashboardRouteNames } from '../modules/operationalDashboard';

export function singleAssetOverviewLocation(
  assetType: AssetType,
  assetUUID: UUID,
  query?: Location['query']
): Location {
  const routeName = overviewRouteNames[assetType]?.single;
  if (!routeName) {
    throw new Error(`cannot determine route name for asset type ${assetType}`);
  }
  return {
    name: routeName,
    params: { id: assetUUID },
    query,
  };
}

export function operationalDashboardSingleAssetOverviewLocation(
  assetType: AssetType,
  assetUUID: UUID,
  query?: Location['query']
): Location {
  const routeName = overviewOperationalDashboardRouteNames[assetType]?.single;
  if (!routeName) {
    throw new Error(`cannot determine route name for asset type ${assetType}`);
  }
  return {
    name: routeName,
    params: { id: assetUUID },
    query,
  };
}

export function hasOperationalOverviewRoutesForAssetType(
  assetType: AssetType
): boolean {
  return overviewOperationalDashboardRouteNames[assetType] !== undefined;
}

export function hasNormalOverviewRoutesForAssetType(
  assetType: AssetType
): boolean {
  return overviewRouteNames[assetType] !== undefined;
}

export function isSingleAssetViewRoute(route: Route) {
  return (
    isNormalSingleAssetViewRoute(route) ||
    isOperationalSingleAssetViewRoute(route)
  );
}

export function isNormalSingleAssetViewRoute(route: Route) {
  return Object.values(overviewRouteNames)
    .filter(isDefined)
    .some((routeNames) => routeNames.single === route.name);
}

export function isOperationalSingleAssetViewRoute(route: Route) {
  return Object.values(overviewOperationalDashboardRouteNames)
    .filter(isDefined)
    .some((routeNames) => routeNames.single === route.name);
}

export function isOperationalFleetViewRoute(route: Route) {
  return Object.values(overviewOperationalDashboardRouteNames)
    .filter(isDefined)
    .some((routeNames) => routeNames.all === route.name);
}
