import globalStore from '@/globalStore';
import { FilterKeys } from '../types';
import { UnitMapping } from './systemConversionTypes';
import { convertRaw } from './unitConversionTypes';
import { KPI_UNIT } from './unitDefinitions';
import { toUnitValue, UnitValue } from './unitValue';

/**
 * Convert value to another unit.
 *
 * If the input value already has the correct unit, it is
 * returned as-is.
 */
export function convertUnitValue<T extends UnitValue<number | undefined>>(
  unitValue: T,
  toUnit: KPI_UNIT
): T {
  if (unitValue.unit === toUnit) {
    return unitValue;
  }

  const fromConversion = globalStore.state.units[unitValue.unit];
  if (!fromConversion) {
    throw new Error(
      `cannot convert from ${unitValue.unit}: missing unit conversion`
    );
  }

  const toConversion = globalStore.state.units[toUnit];
  if (!toConversion) {
    throw new Error(`cannot convert to ${toUnit}: missing unit conversion`);
  }

  return {
    ...unitValue,
    v: convertRaw(unitValue.v, fromConversion, toConversion),
    unit: toUnit,
  };
}

/**
 * Return user's preferred unit based on given metric unit.
 *
 * If no explicit mapping (custom unit) exists, the existing
 * unit is returned.
 */
export function userPreferredUnit(
  fromMetricUnit: KPI_UNIT,
  unitMapping: UnitMapping
): KPI_UNIT {
  return unitMapping[fromMetricUnit] ?? fromMetricUnit;
}

export function hasCustomMapping(
  metricUnit: KPI_UNIT,
  unitMapping: UnitMapping
): boolean {
  return unitMapping[metricUnit] !== undefined;
}

/**
 * Convert a UnitValue to a user's preferred unit.
 *
 * If no explicit mapping (custom unit) exists, the value
 * is returned as-is.
 */
export function userConvertUnitValue<T extends UnitValue<number | undefined>>(
  unitValue: T,
  unitMapping: UnitMapping
): T {
  if (!unitValue) {
    return unitValue;
  }
  const toUnit = unitMapping[unitValue.unit];
  if (!toUnit) {
    return unitValue;
  }
  return convertUnitValue(unitValue, toUnit);
}

/**
 * Convert a raw number from one unit to another.
 */
export function convertRawNumber(
  value: number,
  fromUnit: KPI_UNIT,
  toUnit: KPI_UNIT
): number {
  return convertUnitValue(toUnitValue(value, fromUnit), toUnit).v;
}

/**
 * Converts `keys` of `object` from `fromUnit` to `toUnit`.
 *
 * This function expects to receive an object where the unit is passed
 * out-of-band (i.e. is not one of the keys of the object).
 *
 * @see {@link convertUnitValueMany} for the variant where there is an
 * explicit `.unit` property in the object.
 */
export function convertRawNumberMany<T extends object>(
  object: T,
  keys: FilterKeys<T, number | undefined>[],
  fromUnit: KPI_UNIT,
  toUnit: KPI_UNIT
): T {
  const convertedValues: Partial<Record<(typeof keys)[number], number>> = {};

  for (const key of keys) {
    const value = object[key] as unknown as number;
    convertedValues[key] = convertRawNumber(value, fromUnit, toUnit);
  }

  return {
    ...object,
    ...convertedValues,
  };
}

/**
 * Converts `keys` of `object` from existing object's unit to `toUnit`.
 *
 * This function expects to receive an object with a `.unit` property,
 * but possibly without a `.v`.
 *
 * @see {@link convertUnitValueMany} for the variant where object is
 * a `UnitValue` with additional properties.
 */
export function convertUnitObjectMany<T extends { unit: KPI_UNIT }>(
  object: T,
  keys: FilterKeys<T, number | undefined>[],
  toUnit: KPI_UNIT
): T {
  return {
    ...convertRawNumberMany(object, keys, object.unit, toUnit),
    unit: toUnit,
  };
}

/**
 * Converts `v` and any additional `keys` of `object` from existing object's unit to `toUnit`.
 */
export function convertUnitValueMany<T extends UnitValue<number | undefined>>(
  object: T,
  additionalKeys: FilterKeys<T, number | undefined>[],
  toUnit: KPI_UNIT
): T {
  const keys = ['v' as FilterKeys<T, number | undefined>, ...additionalKeys];
  return convertUnitObjectMany(object, keys, toUnit);
}
