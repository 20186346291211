<script lang="ts" setup>
import { CommonResult } from '@/api/commonResult';
import {
  getMaintenanceAppointmentsList,
  MaintenanceAppointment,
} from '@/api/maintenance';
import { useActiveContext } from '@/auth/context';
import WidgetCard from '@/components/layout/widget/WidgetCard.vue';
import MaintenanceModal from '@/components/modal/MaintenanceModal.vue';
import MaintenancePlanListTable from '@/components/table/MaintenancePlanListTable.vue';
import { AsyncValue, useAsync } from '@/composables/async';
import {
  FilterOperator,
  Pagination,
  QueryParameter,
  SorterOrder,
} from '@/model/queryParameters/QueryParameter';
import { ASSET_MAINTENANCE_ITEM_COLS } from '@/utils/workData/maintenanceConf';
import { MaintenanceModalTriggerSource } from '@/views/maintenance/planner';
import { computed, ref, unref, watchEffect } from 'vue';
import { DEFAULT_STATUSES, useSelectedMaintenanceStatuses } from './status';

/** Notice: Initial "Maintenance Plan List", this component (table view with maintenance monitor
column) Widget receives the auth code for displaying from IoT > APP-BE:
"WIDGET_MAINTEN_PLAN_LIST", but has been translated to "Maintenance
Overview", therefore there are different codes for permission, but on the UI,
the translation is the same. Proposal for better UX improvements */

/** Table */
const context = useActiveContext();
const scheduledAppointmentsTableData = ref<MaintenanceAppointment[]>([]);
const { ref: selectedMaintenanceStatuses } = useSelectedMaintenanceStatuses();
const forceUpdateMaintenanceAppointments = ref(0);

/** Modal */
const isModalVisible = ref<boolean>(false);
const maintenanceModalTitle = ref<string>('');
const selectedAppointment = ref<MaintenanceAppointment>();
const modalTriggerSource = ref<any>(MaintenanceModalTriggerSource.Edit);

/**
 * Get scheduled appointments query parameters
 */
function getScheduledAppointmentsQueryParameters(): QueryParameter {
  const pagination: Pagination = { page: 1, size: 100000 };
  const selectedOrganization = unref(context.value).organization;
  const orgId = selectedOrganization?.id;
  const selectedStatuses = Array.from(unref(selectedMaintenanceStatuses));
  const queryParameters: QueryParameter = {
    filters: [
      ...(selectedStatuses.length
        ? [
            {
              name: 'planStatus',
              operator: FilterOperator.IN,
              value: selectedStatuses,
            },
          ]
        : []),
      ...(orgId
        ? [
            {
              name: 'organizationId',
              operator: FilterOperator.IN,
              value: [orgId],
            },
          ]
        : []),
    ],
    sorters: [
      {
        field: 'createdOn',
        order: SorterOrder.DESC,
      },
    ],
    pagination: pagination,
    // First timezone is used for 'normal' users, second one is for designated users
    timezone:
      selectedOrganization?.timezone ?? unref(context).primaryOrgTimeZone,
  };
  return queryParameters;
}

/**
 * Get API response for scheduled appointments
 */
const scheduledAppointmentsAPIResponse = useAsync(
  computed(async (): Promise<CommonResult<MaintenanceAppointment[]>> => {
    unref(
      forceUpdateMaintenanceAppointments
    ); /** Force update appointments table */
    const response: CommonResult<MaintenanceAppointment[]> =
      await getMaintenanceAppointmentsList(
        getScheduledAppointmentsQueryParameters()
      );
    return response;
  })
);

/**
 * Handle scenarios for apoi response and return expected data
 */
const scheduledAppointmentsListInfo = computed(
  (): AsyncValue<CommonResult<MaintenanceAppointment[]>> => {
    const appointmentsList = unref(scheduledAppointmentsAPIResponse);
    if (appointmentsList.loading || appointmentsList.error) {
      return {
        loading: appointmentsList.loading,
        error: appointmentsList.error,
        data: undefined,
      };
    }
    if (appointmentsList.data === undefined) {
      return {
        loading: false,
        error: new Error('Invalid api response, data must be present.'),
        data: undefined,
      };
    }

    return {
      loading: false,
      error: undefined,
      data: appointmentsList.data,
    };
  }
);

/**
 * Populate table data if has no data
 */
watchEffect(() => {
  scheduledAppointmentsTableData.value = [];
  const scheduledAppointments = unref(scheduledAppointmentsListInfo);
  if (unref(scheduledAppointmentsTableData).length === 0) {
    if (scheduledAppointments.loading || !!scheduledAppointments.error) {
      scheduledAppointmentsTableData.value = [];
      return;
    }
    const appointmentsResponse = unref(scheduledAppointmentsListInfo).data
      ?.data;
    if (!appointmentsResponse) {
      scheduledAppointmentsTableData.value = [];
      return;
    }
    scheduledAppointmentsTableData.value = appointmentsResponse;
  }
});

/**
 * Get widget header additional info as status codes
 * Return undefined to not display all of the possible statuses
 * only when at least one or all statuses length -1 are selected
 */
function getFiltingInformation(): string[] | undefined {
  const selectedStatuses = Array.from(unref(selectedMaintenanceStatuses));
  return selectedStatuses.length === DEFAULT_STATUSES.size
    ? undefined
    : selectedStatuses;
}

/**
 * Cancel modal
 */
function handleModalCancel(): void {
  isModalVisible.value = false;
  maintenanceModalTitle.value = '';
}

/**
 * Force reload appointments
 */
function handleReloadAppointmentsTable(): void {
  forceUpdateMaintenanceAppointments.value =
    forceUpdateMaintenanceAppointments.value + 1;
  handleModalCancel();
}

/**
 * Set clicked appointment from table to the modal
 * @param appointment
 */
function handleAppointmentClicked(appointment: MaintenanceAppointment) {
  maintenanceModalTitle.value =
    appointment.companyAssetId +
    '\u00A0 - \u00A0' +
    appointment.maintenanceItemName;
  selectedAppointment.value = appointment;
  isModalVisible.value = true;
}
</script>

<template>
  <WidgetCard
    class="maintenace-plan-list-page"
    v-loading="scheduledAppointmentsListInfo.loading"
    :headerAdditionalInfo="getFiltingInformation()"
  >
    <MaintenancePlanListTable
      ref="maintenanceOverviewPlanListTable"
      class="maintenance-plan-list"
      :cols="ASSET_MAINTENANCE_ITEM_COLS"
      :tableList="scheduledAppointmentsTableData"
      :pageTotal="0"
      max-height="340"
      @appointment-clicked="handleAppointmentClicked"
    />

    <MaintenanceModal
      v-if="isModalVisible"
      class="appointment-modal"
      :isModalVisible="isModalVisible"
      :title="maintenanceModalTitle"
      :appointment="selectedAppointment"
      :modalTriggerSource="modalTriggerSource"
      @close="handleModalCancel"
      @reload="handleReloadAppointmentsTable"
    />
  </WidgetCard>
</template>

<style scoped>
.maintenace-plan-list-page :deep(.el-table) {
  margin-left: 15px;
}
</style>

<style lang="scss" scoped>
.appointment-modal :deep(.el-dialog) {
  width: 650px !important;
}
</style>
