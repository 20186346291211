<script setup lang="ts">
import { getAssetInfoById } from '@/api/assetsMgmt';
import { CommonResult } from '@/api/commonResult';
import {
  DEFAULT_MAINTENANCE_DAYS_RANGE,
  getPerformedAppointments,
  PerformedAppointmentsResponse,
  PerformedMaintenanceAppointments,
} from '@/api/maintenance';
import { useActiveContext } from '@/auth/context';
import BaseCard from '@/components/cusCard/BaseCard.vue';
import MultiAssetTypeSelectVue, {
  AssetTypeSelectItem,
} from '@/components/form/MultiAssetTypeSelect.vue';
import MultiMaintenanceItemSelect, {
  MaintenanceSelectItem,
} from '@/components/form/MultiMaintenanceItemSelect.vue';
import MultiProductModelSelect, {
  ProductModelSelectItem,
} from '@/components/form/MultiProductModelSelect.vue';
import TimeSelect from '@/components/form/TimeSelect.vue';
import HistoryTable from '@/components/table/HistoryTable.vue';
import { useAsync } from '@/composables/async';
import { useRoute } from '@/composables/router';
import i18n from '@/lang';
import {
  Filter,
  FilterOperator,
  Pagination,
  QueryParameter,
  Sorter,
  SorterOrder,
} from '@/model/queryParameters/QueryParameter';
import { UserModule } from '@/store/modules/user';
import { DEFAULT_DATE_RANGE, LOCALDATE_FORMAT } from '@/utils/time';
import { DateRange } from '@/utils/types/date';
import { ASSET_MAINTENANCE_HISTORY_COLS } from '@/utils/workData/maintenanceConf';
import moment from 'moment';
import { computed, onMounted, ref, unref } from 'vue';
import ViewMaintenanceHistoryModal from './components/ViewMaintenanceHistoryModal.vue';

const context = useActiveContext();

const assetIdSearchInput = ref<string>('');
const dateRange = ref<DateRange>(DEFAULT_DATE_RANGE);
const pageSize = UserModule.gridPageSize;
const total = ref<number>(0);
const currentPage = ref<number>(1);
const modalValues = ref<PerformedMaintenanceAppointments | undefined>();
const sorter = ref<Sorter | undefined>();

const route = useRoute();

const urlQueryParams = unref(route).query;

/**
 * Check if URL has query params of asset id, then inject into search imput field the company asset id value
 */
onMounted(async () => {
  if (urlQueryParams.hasOwnProperty('assetId')) {
    const response = await getAssetInfoById(urlQueryParams.assetId.toString());
    if (response?.data && response.data?.companyAssetId.length) {
      assetIdSearchInput.value = response.data?.companyAssetId;
    }
  }
});

/**
 * Prepare query parameters to request performed appointments
 */
function getPerformedAppointmentsQueryParameters(
  pagination: Pagination,
  filters: Filter[] = [],
  sorters: Sorter[] = []
): QueryParameter {
  const selectedOrganization = unref(context).organization;
  const organizationId = selectedOrganization?.id;

  const timeFilter = [
    {
      name: 'appointmentTime',
      operator: FilterOperator.BETWEEN,
      value: [unref(dateRange).start, unref(dateRange).endExclusive],
    },
  ];
  const queryParameters: QueryParameter = {
    filters: !organizationId
      ? [...filters, ...timeFilter]
      : [
          ...filters,
          ...timeFilter,
          {
            name: 'organizationId',
            operator: FilterOperator.EQUAL,
            value: [organizationId],
          },
        ],
    sorters,
    pagination: pagination,
    // First timezone is used for 'normal' users, second one is for designated users
    timezone:
      selectedOrganization?.timezone ?? unref(context).primaryOrgTimeZone,
  };

  return queryParameters;
}

const performedAppointmentsListAPIResponse = useAsync(
  computed(async (): Promise<CommonResult<PerformedAppointmentsResponse>> => {
    const response = await getPerformedAppointments(
      getPerformedAppointmentsQueryParameters({
        page: 1,
        size: 100000,
      })
    );

    return response;
  })
);

const performedAppointmentsListAPIResponsePaginated = useAsync(
  computed(async (): Promise<CommonResult<PerformedAppointmentsResponse>> => {
    const maintenanceListValue = unref(maintenanceList);
    const assetTypeListValue = unref(assetTypeList);
    const productModelListValue = unref(productModelList);
    const assetIdSearchInputValue = unref(assetIdSearchInput);
    const sorterObject = unref(sorter);
    const filters = [];
    if (assetIdSearchInputValue && assetIdSearchInputValue.length > 0) {
      filters.push({
        name: 'companyAssetId',
        operator: FilterOperator.LIKE,
        value: [assetIdSearchInputValue],
      });
    } else if (
      assetTypeListValue &&
      assetTypeListValue.data &&
      assetTypeListValue.data.length > 0
    ) {
      filters.push({
        name: 'assetType',
        operator: FilterOperator.IN,
        value: [...assetTypeListValue.data.map((el) => el.assetType)],
      });
    }
    const filteredProductModelList = productModelListValue?.data?.filter(
      (el) => el.selected
    );
    if (filteredProductModelList && filteredProductModelList.length > 0) {
      filters.push({
        name: 'productModel',
        operator: FilterOperator.IN,
        value: [...filteredProductModelList.map((el) => el.productModelId)],
      });
    }
    const filteredSelectedMaintenanceList = maintenanceListValue?.data?.filter(
      (el) => el.selected
    );
    if (
      filteredSelectedMaintenanceList &&
      filteredSelectedMaintenanceList.length > 0
    ) {
      filters.push({
        name: 'maintenanceItemId',
        operator: FilterOperator.IN,
        value: [
          ...filteredSelectedMaintenanceList.map((el) => el.maintenanceItemId),
        ],
      });
    }
    const currentPageValue = unref(currentPage);
    const sorters = sorterObject
      ? [
          {
            field: sorterObject.field,
            order: sorterObject.order,
          },
        ]
      : [];
    const response = await getPerformedAppointments(
      getPerformedAppointmentsQueryParameters(
        {
          page: currentPageValue,
          size: pageSize,
        },
        filters,
        sorters
      )
    );

    return response;
  })
);

const assetTypeList = useAsync(
  computed(() => {
    const performedAppointmentsListAPIResponseValue = unref(
      performedAppointmentsListAPIResponse
    );
    const performedMaintenanceAppointments =
      performedAppointmentsListAPIResponseValue.data?.data
        .performedMaintenanceAppointments;
    if (performedMaintenanceAppointments?.length === 0) {
      return undefined;
    }
    return performedMaintenanceAppointments?.reduce((accumulator, current) => {
      if (!accumulator.find((item) => item.assetType === current.assetType)) {
        accumulator.push({
          assetType: current.assetType,
          selected: true,
        });
      }
      return accumulator;
    }, [] as AssetTypeSelectItem[]);
  })
);

const productModelList = useAsync(
  computed(() => {
    const assetTypeListValue = unref(assetTypeList);
    const performedAppointmentsListAPIResponseValue = unref(
      performedAppointmentsListAPIResponse
    );
    const performedMaintenanceAppointments =
      performedAppointmentsListAPIResponseValue.data?.data
        .performedMaintenanceAppointments;
    if (
      (performedMaintenanceAppointments &&
        performedMaintenanceAppointments.length === 0) ||
      !assetTypeListValue
    ) {
      return undefined;
    }

    return performedMaintenanceAppointments?.reduce((accumulator, current) => {
      if (
        !accumulator.find(
          (item) => item.productModel === current.productModel
        ) &&
        assetTypeListValue.data?.some(
          (at) => current.assetType === at.assetType && at.selected
        )
      ) {
        accumulator.push({
          productModel: current.productModel,
          productModelId: current.productModel,
          selected: true,
        });
      }
      return accumulator;
    }, [] as ProductModelSelectItem[]);
  })
);

const maintenanceList = useAsync(
  computed(() => {
    const assetTypeListValue = unref(assetTypeList);
    const productModelListValue = unref(productModelList);
    const performedAppointmentsListAPIResponseValue = unref(
      performedAppointmentsListAPIResponse
    );
    const performedMaintenanceAppointmentData =
      performedAppointmentsListAPIResponseValue.data?.data;
    const performedMaintenanceAppointments =
      performedMaintenanceAppointmentData?.performedMaintenanceAppointments;
    if (
      (performedMaintenanceAppointments &&
        performedMaintenanceAppointments.length === 0) ||
      !assetTypeListValue ||
      !productModelListValue
    ) {
      return undefined;
    }

    return performedMaintenanceAppointments?.reduce((accumulator, current) => {
      if (
        !accumulator.find(
          (item) => item.maintenanceItemId === current.maintenanceItemId
        ) &&
        assetTypeListValue.data?.some(
          (at) => current.assetType === at.assetType && at.selected
        ) &&
        productModelListValue.data?.some(
          (at) => current.productModel === at.productModel && at.selected
        )
      ) {
        accumulator.push({
          maintenanceItemName: current.maintenanceItemName,
          maintenanceItemId: current.maintenanceItemId,
          selected: true,
        });
      }
      return accumulator;
    }, [] as MaintenanceSelectItem[]);
  })
);

const filteredMaintenanceList = computed(
  (): PerformedMaintenanceAppointments[] | undefined => {
    const assetTypeListValue =
      unref(assetTypeList)?.data?.filter((el) => el.selected) || [];
    const productModelListValue =
      unref(productModelList)?.data?.filter((el) => el.selected) || [];
    const maintenanceListValue =
      unref(maintenanceList)?.data?.filter((el) => el.selected) || [];
    if (
      assetTypeListValue.length === 0 ||
      productModelListValue.length === 0 ||
      maintenanceListValue.length === 0
    ) {
      total.value = 0;
      currentPage.value = 1;
      return [];
    }

    const performedAppointmentsListAPIResponsePaginatedValue = unref(
      performedAppointmentsListAPIResponsePaginated
    );
    const performedMaintenanceAppointmentsData =
      performedAppointmentsListAPIResponsePaginatedValue.data?.data;
    const performedMaintenanceAppointments =
      performedMaintenanceAppointmentsData?.performedMaintenanceAppointments;
    if (
      (performedMaintenanceAppointments &&
        performedMaintenanceAppointments.length === 0) ||
      !assetTypeListValue
    ) {
      total.value = 0;
      currentPage.value = 1;
      return [];
    }
    total.value = performedMaintenanceAppointmentsData?.total || 0;

    return performedMaintenanceAppointments?.map((app) => ({
      ...app,
      appointmentTime: `${moment(app.appointmentTime).format(
        LOCALDATE_FORMAT
      )}${
        app.isAdjusted
          ? ` - ${i18n.tc('maintenance.maintenanceHistory.adjusted')}`
          : ''
      }`,
    }));
  }
);

/**
 * Handle Time Filter
 * @param dateRange
 */
function handleTimeFilter(range: DateRange) {
  dateRange.value = range;
}

/** Handle pagination */
function fetchMaintenanceListByPageSelection(page: number) {
  currentPage.value = page;
}

/**
 * Handle showing the view details modal
 */
function showDetailsModal(row: PerformedMaintenanceAppointments) {
  modalValues.value = row;
}

/**
 * Cancel modal
 */
function handleModalCancel(): void {
  modalValues.value = undefined;
}

/** Filter by sort event */
function fetchMaintenanceListBySortEvent(
  field: string,
  order: SorterOrder
): void {
  sorter.value = { field, order };
}
</script>

<template>
  <BaseCard
    ref="baseCardRef"
    class="history-body"
    :title="$t('AUTHRSC_PAGE_MAINT_HISTORY')"
    :backIconVisible="false"
    :showDialogBeforeBack="false"
    @handle-back="false"
    :buttons="[]"
  >
    <div class="history-header-operations">
      <el-input
        class="search-input"
        id="searchinput"
        :placeholder="$t('maintenance.searchByAssetId')"
        v-model="assetIdSearchInput"
        suffix-icon="el-icon-search"
        clearable
        :disabled="
          !performedAppointmentsListAPIResponse.data ||
          performedAppointmentsListAPIResponse.data.data
            .performedMaintenanceAppointments.length === 0
        "
      />

      <MultiAssetTypeSelectVue
        :assetTypeList="assetTypeList"
        :error="assetTypeList.error ? 'ApiFieldAssetTypeCode' : undefined"
        :disabled="
          !performedAppointmentsListAPIResponse.data ||
          performedAppointmentsListAPIResponse.data.data
            .performedMaintenanceAppointments.length === 0
        "
      />

      <MultiProductModelSelect
        v-show="
          !assetTypeList.data ||
          (assetTypeList.data && assetTypeList.data.every((at) => !at.selected))
        "
        :productModelList="{
          data: undefined,
          loading: false,
          error: undefined,
        }"
        :disabled="
          assetTypeList.data === undefined ||
          (assetTypeList.data && assetTypeList.data.every((at) => !at.selected))
        "
      />
      <MultiProductModelSelect
        v-show="
          assetTypeList.data && assetTypeList.data.some((at) => !!at.selected)
        "
        :productModelList="productModelList"
        :disabled="
          assetTypeList.data === undefined ||
          (assetTypeList.data && assetTypeList.data.every((at) => !at.selected))
        "
      />
      <MultiMaintenanceItemSelect
        v-show="
          !productModelList.data ||
          (productModelList.data &&
            productModelList.data.every((at) => !at.selected))
        "
        :maintenanceList="undefined"
        :disabled="
          assetTypeList.data === undefined ||
          assetTypeList.data.every((atl) => !atl.selected) ||
          productModelList.data === undefined ||
          productModelList.data.every((pml) => !pml.selected)
        "
      />
      <MultiMaintenanceItemSelect
        v-show="
          productModelList.data &&
          productModelList.data.some((at) => !!at.selected)
        "
        :maintenanceList="maintenanceList.data"
        :disabled="
          assetTypeList.data === undefined ||
          assetTypeList.data.every((atl) => !atl.selected) ||
          productModelList.data === undefined ||
          productModelList.data.every((pml) => !pml.selected)
        "
      />
      <TimeSelect
        :expanded="true"
        @select="handleTimeFilter"
        :customizable="true"
        :defaultLastDays="DEFAULT_MAINTENANCE_DAYS_RANGE"
      />
    </div>
    <div
      class="chart-container"
      v-loading="performedAppointmentsListAPIResponsePaginated.loading"
      :element-loading-text="$t('common.loading')"
    >
      <HistoryTable
        :tableData="filteredMaintenanceList || []"
        :pageSize="pageSize"
        :total="total"
        :cols="ASSET_MAINTENANCE_HISTORY_COLS"
        @handlePage="fetchMaintenanceListByPageSelection"
        @showDetailsModal="showDetailsModal"
        @handleSortChange="fetchMaintenanceListBySortEvent"
      />
    </div>
    <ViewMaintenanceHistoryModal
      v-if="!!modalValues"
      :isModalVisible="!!modalValues"
      :title="`${modalValues?.companyAssetId} - ${modalValues?.maintenanceItemName}`"
      :appointment="modalValues"
      @close="handleModalCancel"
    />
  </BaseCard>
</template>

<style lang="scss" scoped>
.history-header-operations {
  margin-bottom: 15px;
  flex-flow: wrap;
  display: flex;
}

.filter-select {
  width: 200px !important;
  margin: 5px 5px auto 0;
}

.time-select {
  margin: 5px 5px auto 0;
}
.search-input {
  width: 200px;
  margin: 5px 5px auto 0;
}

.filters-container {
  margin-top: 6px;
  padding: 0 28px;
  display: flex;
  flex-direction: row;
}

.chart-container {
  width: 100%;
  height: 100%;
  border-top: 1px solid #dddddd;
  overflow: hidden;

  .chart-wrapper {
    flex: 1;
  }

  .table {
    border-left: 1px solid #dddddd;
    margin-top: -1px;
  }
}

.no-table-data-block {
  display: flex;
  margin-top: 20px;

  .no-table-data-message {
    margin: auto;
  }
}

:deep(.base-card-body) {
  padding: 15px 20px 20px;
}

:deep(.el-table th) {
  border-bottom: 1px solid #dddddd !important;
}

:deep(.el-table td) {
  border-bottom: 1px solid #dddddd !important;
}
</style>
