/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon';
icon.register({
  report: {
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
    data: '<path fill="#373E41" d="M14 20H6a6.007 6.007 0 0 1-6-6V6a6.007 6.007 0 0 1 6-6h8a6.007 6.007 0 0 1 6 6v8a6.006 6.006 0 0 1-6 6ZM6 1a5.006 5.006 0 0 0-5 5v8a5.006 5.006 0 0 0 5 5h8a5.005 5.005 0 0 0 5-5V6a5.006 5.006 0 0 0-5-5H6Z"/><path fill="#373E41" d="M5 14.365a.792.792 0 0 1 .792-.792h8.634a.793.793 0 1 1 0 1.585H5.8a.794.794 0 0 1-.8-.793Zm10.221-4.61a.792.792 0 0 1-.792.792H5.8a.793.793 0 0 1 0-1.585h8.628a.792.792 0 0 1 .793.792v.001ZM5.8 6.585A.792.792 0 0 1 5.8 5h5.73a.793.793 0 0 1 0 1.585H5.8Z"/>',
  },
});
