import { QueryParameter } from '@/model/queryParameters/QueryParameter';
import { getRequest } from '@/utils/request';
import { DAY_COUNT_TYPE } from '@/utils/workData/lookuptable';
import { CommonResultPromise } from './commonResult';
import { ProductModel } from './products';
import { AssetType } from '@/utils/assetTypes';

export const DEFAULT_MAINTENANCE_DAYS_RANGE = {
  id: DAY_COUNT_TYPE.LastFifteenDays,
  value: 15,
};

export interface AppointmentBody {
  assetId: string;
  maintenanceItemId: string;
  appointmentTime: string;
  duration: string;
}

export interface UpdateAppointment {
  appointmentTime: string;
}

export interface ConductAppointmentRequestBody {
  appointmentTime: string;
  note: string;
  maintainedParts: MaintenanceItemPart[];
}

export interface PerformedAppointmentsResponse {
  performedMaintenanceAppointments: PerformedMaintenanceAppointments[];
  total: number;
  size: number;
}

export interface PerformedAppointmentById {
  appointmentTime: string;
  assetId: string;
  assetType: string;
  companyAssetId: string;
  id: string;
  maintainedParts: MaintenanceItemPart[];
  maintenanceItemId: string;
  maintenanceItemName: string;
  productModel: string;
  productModelId: string;
  timezone: string;
}

export interface MaintenanceHistoryDetails {
  date: string;
  numberOfAppointments: number;
}

export interface MaintenanceHistoryOverviewResponse {
  totalNumberOfAppointments: number;
  averageNumberOfAppointmentsPerDay: number;
  details: MaintenanceHistoryDetails[];
}

export interface PerformedMaintenanceAppointments {
  id: string;
  appointmentTime: string;
  assetId: string;
  maintenanceItemId: string;
  maintenanceItemName: string;
  productModel: string;
  companyAssetId: string;
  assetType: string;
  companyId: string;
  organizationId: string;
  timezone: string;
  isManuallyCreated: boolean;
  note: string;
  isAdjusted: boolean;
  status?: string;
  duration?: string;

  uuid?: string;
  maintenancePlanId?: string;
  organizationName?: string;
  appointmentDateTimeUtc?: string;
  appointmentDateUtc?: string;
  lastMaintenanceDate?: string;
  isConductedManually?: boolean;
  productModelId?: string;
  maintainedParts?: MaintenancePart[];
  dominantValueRepresentedDays?: number;
  rulesProgress?: MaintenanceRuleProgress[];
}

/**
 * Create new maintenance appointment
 * @param requestBody
 * @param request
 * @returns
 */
export function createNewMaintenanceAppointment(
  requestBody: AppointmentBody,
  request = getRequest()
): CommonResultPromise {
  return request.post<MaintenanceItemKPI[]>(
    '/maintenance/new-scheduled-appointment',
    requestBody
  );
}

/**
 * Fetch maintenance item kpis
 * @param requestBody
 * @param request
 * @returns MaintenanceItemKPI
 */
export function getMaintenanceItemKPIs(
  requestBody: QueryParameter,
  request = getRequest()
): CommonResultPromise<MaintenanceItemKPI[]> {
  return request.post<MaintenanceItemKPI[]>('/maintenance/kpis', requestBody);
}

/**
 * Create maintenance configuration item
 * @param requestBody
 * @param request
 * @returns CommonResultPromise<MaintenanceForm>
 */
export function createMaintenanceConfigItem(
  requestBody: any,
  request = getRequest()
): CommonResultPromise<MaintenanceForm> {
  return request.post<MaintenanceForm>('/maintenance/items', requestBody);
}

/**
 * Fetch  maintenance configuration items list
 * @param filter
 * @returns
 */
export function getMaintenanceItemsList(
  queryParameters: QueryParameter,
  request = getRequest()
): CommonResultPromise<MainteanceItemsListResponse> {
  return request.post<MainteanceItemsListResponse>(
    'maintenance/get-items',
    queryParameters
  );
}

/**
 * Fetch  maintenance filters list
 * @param filter
 * @returns
 */
export function getMaintenanceFiltersList(
  organizationId: string,
  queryParameters: QueryParameter,
  request = getRequest()
): CommonResultPromise<MaintenanceFilterItem[]> {
  return request.post<MaintenanceFilterItem[]>(
    `maintenance/items-applied/${organizationId}`,
    queryParameters
  );
}

/**
 * Fetch maintenance configuration item by id
 * @param id
 * @returns MainteananceItemById
 */
export function getMaintItemById(
  id: string,
  request = getRequest()
): CommonResultPromise<MainteananceItemById> {
  return request.get<MainteananceItemById>(`/maintenance/items/${id}`);
}

/**
 * Update maintenance item
 * @param id
 * @param data
 * @returns
 */
export function updateMaintenanceItem(
  id: string,
  requestBody: MaintenanceForm,
  request = getRequest()
): CommonResultPromise<void> {
  return request.put<void>(`/maintenance/items/${id}`, requestBody);
}

/**
 * Fetch maintenance appointments by queryParameters
 * @param companyId
 * @returns list of MaintenanceAppointment
 */
export function getMaintenanceAppointmentsList(
  queryParameters: QueryParameter,
  request = getRequest()
): CommonResultPromise<MaintenanceAppointment[]> {
  return request.post<MaintenanceAppointment[]>(
    `/maintenance/scheduled-appointments`,
    queryParameters
  );
}

/**
 * Update maintenance appointment by scheduledAppointmentId
 * @param scheduledAppointmentId
 * @param request
 * @returns
 */
export function updateMaintenanceAppointment(
  scheduledAppointmentId: string,
  requestBody: UpdateAppointment,
  request = getRequest()
): CommonResultPromise<UpdateAppointment> {
  return request.put<UpdateAppointment>(
    `/maintenance/scheduled-appointments/${scheduledAppointmentId}`,
    requestBody
  );
}

/**
 * Conduct maintenance appointment
 * @param scheduledAppointmentId
 * @param requestBody
 * @param request
 * @returns
 */
export function conductMaintenanceAppointment(
  scheduledAppointmentId: string,
  requestBody: UpdateAppointment,
  request = getRequest()
): CommonResultPromise<ConductAppointmentRequestBody> {
  return request.put<ConductAppointmentRequestBody>(
    `/maintenance/scheduled-appointments/${scheduledAppointmentId}/conduct`,
    requestBody
  );
}

/**
 * Get performed appointments
 * @param queryParameters
 * @param request
 * @returns
 */
export function getPerformedAppointments(
  queryParameters: QueryParameter,
  request = getRequest()
): CommonResultPromise<PerformedAppointmentsResponse> {
  return request.post<PerformedAppointmentsResponse>(
    `/maintenance/performed-appointments`,
    queryParameters
  );
}

/**
 * Fetch performed appointments by id
 * @param id
 * @returns PerformedAppointmentsById
 */
export function getPerformedAppointmentsById(
  id: string,
  request = getRequest()
): CommonResultPromise<PerformedAppointmentById> {
  return request.get<PerformedAppointmentById>(
    `/maintenance/performed-appointments/${id}`
  );
}

export function getMaintenanceItems(
  maintaincenItemId?: string | null,
  assetType?: string | null
): CommonResultPromise {
  const request = getRequest();
  const response = request.request({
    url: maintaincenItemId
      ? `/maintenanceItems/${maintaincenItemId}`
      : assetType
      ? `/maintenanceItems?assetType=${assetType}`
      : '/maintenanceItems',
    method: 'get',
  });
  return response;
}

export function createMaintenancePlan(data: any): CommonResultPromise {
  const request = getRequest();
  const response = request.request({
    url: '/maintenancePlans',
    method: 'post',
    data,
  });
  return response;
}

export function getMaintenancePlans(companyId: string): CommonResultPromise {
  const request = getRequest();
  const response = request.request({
    url: `/maintenancePlans?companyId=${companyId}`,
    method: 'get',
  });
  return response;
}

export function conductMaintenancePlan(
  data: any,
  maintenanceId: string
): CommonResultPromise {
  const request = getRequest();
  const response = request.request({
    url: `/maintenancePlans/${maintenanceId}/plan:conduct`,
    method: 'put',
    data,
  });
  return response;
}

export function getMaintenanceItemParts(
  maintenanceItemId: string
): CommonResultPromise {
  const request = getRequest();
  const response = request.request({
    url: `/maintenanceItems/${maintenanceItemId}`,
    method: 'get',
  });
  return response;
}

export function updateMaintenancePlan(
  maintenanceItemId: string,
  data: any
): CommonResultPromise {
  const request = getRequest();
  const response = request.request({
    url: `/maintenancePlans/${maintenanceItemId}`,
    method: 'put',
    data,
  });
  return response;
}

/**
 * Get product models
 * @param assetType
 * @param lifeCycle
 * @param request
 * @returns ProductModel
 */
export function getProductModels(
  assetType: string,
  lifeCycle: string,
  page: number,
  size: number,
  request = getRequest()
): CommonResultPromise<ProductModel[]> {
  return request.get<ProductModel[]>(
    `productModels?assetType=${assetType}&lifeCycle=${lifeCycle}&page=${page}&size=${size}`
  );
}

export function getProductModelById(id: string): CommonResultPromise {
  const request = getRequest();
  const response = request.request({
    url: `productModels/${id}`,
    method: 'get',
  });
  return response;
}

/**
 * Apply maintenance item by id
 * @param maintenanceItemId
 * @param request
 * @returns void
 */
export function applyMaintenanceItemById(
  maintenanceItemId: string,
  request = getRequest()
): CommonResultPromise<void> {
  return request.put<void>(`/maintenance/items/${maintenanceItemId}/apply`);
}

/**
 * Deprecate maintenance item by id
 * @param maintenanceItemId
 * @param request
 * @returns void
 */
export function deprecateMaintenanceItemById(
  maintenanceItemId: string,
  request = getRequest()
): CommonResultPromise<void> {
  return request.put<void>(`/maintenance/items/${maintenanceItemId}/deprecate`);
}

export function getMaintenanceHistories(
  companyId: string,
  page?: number | null,
  size?: number | null,
  assetDisplayID?: string | null,
  assetType?: string | null,
  maintenanceItemId?: string | null,
  orderBy?: string | null,
  order?: string | null
): CommonResultPromise {
  const request = getRequest();
  const response = request.request({
    url:
      assetDisplayID && !assetType && !maintenanceItemId
        ? `maintenanceHistories?companyId=${companyId}&page=${page}&size=${size}&assetDisplayID=${assetDisplayID}&orderBy=${orderBy}&order=${order}`
        : !assetDisplayID && assetType && maintenanceItemId
        ? `maintenanceHistories?companyId=${companyId}&page=${page}&size=${size}&assetType=${assetType}&itemId=${maintenanceItemId}&orderBy=${orderBy}&order=${order}`
        : !assetDisplayID && assetType && !maintenanceItemId
        ? `maintenanceHistories?companyId=${companyId}&page=${page}&size=${size}&assetType=${assetType}&orderBy=${orderBy}&order=${order}`
        : !assetDisplayID && !assetType && maintenanceItemId
        ? `maintenanceHistories?companyId=${companyId}&page=${page}&size=${size}&itemId=${maintenanceItemId}&orderBy=${orderBy}&order=${order}`
        : assetDisplayID && !assetType && maintenanceItemId
        ? `maintenanceHistories?companyId=${companyId}&page=${page}&size=${size}&assetDisplayID=${assetDisplayID}&itemId=${maintenanceItemId}&orderBy=${orderBy}&order=${order}`
        : assetDisplayID && assetType && !maintenanceItemId
        ? `maintenanceHistories?companyId=${companyId}&page=${page}&size=${size}&assetDisplayID=${assetDisplayID}&assetType=${assetType}&orderBy=${orderBy}&order=${order}`
        : assetDisplayID && assetType && maintenanceItemId
        ? `maintenanceHistories?companyId=${companyId}&page=${page}&size=${size}&assetDisplayID=${assetDisplayID}&assetType=${assetType}&itemId=${maintenanceItemId}&orderBy=${orderBy}&order=${order}`
        : companyId && page && size
        ? `maintenanceHistories?companyId=${companyId}&page=${page}&size=${size}&orderBy=${orderBy}&order=${order}`
        : 'maintenanceHistories',
    method: 'get',
  });
  return response;
}

export function getMaintenanceHistoriesStatistics(
  requestBody: QueryParameter,
  request = getRequest()
): CommonResultPromise<PerformedMaintenanceSummary> {
  return request.post<PerformedMaintenanceSummary>(
    `/maintenance/performed-appointments/summary`,
    requestBody
  );
}

export function getMaintenanceStatusOverview(
  requestBody: QueryParameter,
  request = getRequest()
): CommonResultPromise<MaintenanceOverview[]> {
  return request.post<MaintenanceOverview[]>(
    `/maintenance/status-overview`,
    requestBody
  );
}

export function getMaintenancePlansByCustomParams(
  customParams: string
): CommonResultPromise {
  const request = getRequest();
  const response = request.request({
    url: `/maintenancePlans${customParams}`,
    method: 'get',
  });
  return response;
}

export function getMaintenanceItemsByCustomParams(
  customParams: string
): CommonResultPromise {
  const request = getRequest();
  const response = request.request({
    url: `/maintenanceItems${customParams}`,
    method: 'get',
  });
  return response;
}

/** -------------------------- INTERFACES -------------------------- */
export interface MaintenanceForm {
  name: string;
  assetType?: string;
  productModelId?: string;
  maintenanceParts?: MaintenanceItemPart[];
  maintenanceRules?: MaintenanceItemRule[];
  isConductedManually: boolean;
  duration?: string;
}

export interface MaintenanceItemPart {
  name?: string;
  containedPartId: string;
  maintenanceOperationKind: string;
}

export interface MaintenanceItemRule {
  maintenanceKpiCode?: string;
  thresholdValue?: string;
  id?: string;
  name?: string;
  code?: string;
  kind?: string;
  assetTypeCode?: string;
  propertyCode?: string;
  unitCode?: string;
  isValueResettable?: boolean;
}

export interface MaintenanceItemKPI {
  id: string;
  name: string;
  code: string;
  kind: string;
  assetTypeCode: string;
  propertyCode: string;
  unitCode: string;
  isValueResettable: boolean;
  maintenanceKpiCode?: string;
  thresholdValue?: string;
}

export interface MainteanceItemsListResponse {
  maintenanceItems: MaintenanceListItem[];
  total: number;
  size: number;
  errors?: any[];
}

export interface MaintenanceListItem {
  id: string;
  name: string;
  assetType: AssetType;
  assetTypeCode: string;
  productModelId: string;
  productModel: string;
  status: string;
  duration: string;
  numberOfPartsToReplace: number;
  numberOfPartsToCheck: number;
  numberOfRules: number;
  isConductedManually: boolean;
  maintenanceParts?: MaintenanceItemPart[];
}

export interface MaintenanceFilterItem {
  assetType: string;
  duration: string;
  id: string;
  isConductedManually: boolean;
  name: string;
  productModel: string;
  productModelId: string;
}

export interface MainteananceItemById {
  id: string;
  name: string;
  assetType: string;
  productModelId: string;
  productModel: string;
  status: string;
  duration: string;
  maintenanceParts: MaintenanceItemPart[];
  maintenanceRules: MaintenanceRuleById[];
  isConductedManually: boolean;
  errors?: any[];
}

export interface MaintenanceRuleById {
  id: string;
  maintenanceKpiId: string;
  maintenanceKpiCode: string;
  thresholdValue: string;
}

export interface MaintenanceAppointment {
  uuid: string;
  companyId: string;
  maintenancePlanId: string;
  id: string;
  organizationId: string;
  organizationName: string;
  maintenanceItemId: string;
  maintenanceItemName: string;
  appointmentDateTimeUtc: string;
  appointmentDateUtc: string;
  appointmentTime: string;
  lastMaintenanceDate?: string;
  nextMaintenanceDate?: string;
  timezone: string;
  duration: string;
  status: string;
  isManuallyCreated: boolean;
  isConductedManually: boolean;
  companyAssetId: string;
  assetId: string;
  assetType: string;
  productModelId: string;
  maintainedParts: MaintenancePart[];
  productModel: string;
  dominantValueRepresentedDays: number;
  rulesProgress: MaintenanceRuleProgress[];
  createdOn?: string;
}

export interface MaintenancePart {
  containedPartId: string;
  containedPartName: string;
  maintenanceOperationKind: string;
}

export interface MaintenanceRuleProgress {
  id: string;
  kpiCode: string;
  valueRepresentedDays: number;
  startKpiValue: number;
  latestKpiValue: number;
  thresholdValue: number;
}

export interface MaintenanceOverview {
  maintenanceStatus: string;
  numberOfMaintenancePlans: number;
}

export interface PerformedMaintenanceSummary {
  summary: Summary[];
  maintenancePlansConductedPerDay: number;
  totalMaintenancePlansConducted: number;
}

export interface Summary {
  maintenanceDate: string;
  maintenancePlansConducted: number;
}

export enum MaintStatus {
  OK = 'MAINS_OK',
  CLOSE = 'MAINS_CLOSE',
  DUE = 'MAINS_DUE',
}
