performance.mark('mainLoading');

import App from '@/App.vue';
import '@/assets/font/font.scss';
import * as directives from '@/directives';
import '@/icons/components';
import i18n from '@/lang';
import '@/router/router-listener';
import store from '@/store';
import '@/styles/index.scss';
import * as filters from '@/utils/misc';
import { initializeRequestService } from '@/utils/request';
import '@riophae/vue-treeselect/dist/vue-treeselect.css';
import { VueQueryPlugin } from '@tanstack/vue-query';
import ElementUI from 'element-ui';
import Keycloak from 'keycloak-js';
import L from 'leaflet';
import markerRetinaIcon from 'leaflet/dist/images/marker-icon-2x.png';
import markerIcon from 'leaflet/dist/images/marker-icon.png';
import markerShadow from 'leaflet/dist/images/marker-shadow.png';
import 'leaflet/dist/leaflet.css';
import 'normalize.css';
import Vue, { DirectiveOptions } from 'vue';
import SvgIcon from 'vue-svgicon';
import { provideAuth } from './auth';
import { provideCheckVersionTimer } from './composables/version';
import { AppConfig } from './config';
import globalStore from './globalStore';
import { registerMainInstance } from './mainInstance';
import router from './router';
import { initializeApiService } from './utils/api';

//@ts-ignore
delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
  iconRetinaUrl: markerRetinaIcon,
  iconUrl: markerIcon,
  shadowUrl: markerShadow,
});

Vue.use(VueQueryPlugin);

/*======================================================================================*/
Vue.use(ElementUI, {
  size: 'small',
  i18n: (key: string, value: string) => i18n.t(key, value),
});

/*======================================================================================*/
Vue.use(SvgIcon, {
  tagName: 'svg-icon',
  defaultWidth: '1em',
  defaultHeight: '1em',
});

/*======================================================================================*/
// Register global directives
Object.keys(directives).forEach((key) => {
  // console.log(directives)
  // console.log((directives as  { [key: string]: DirectiveOptions})[key])
  // console.log("-------------------------------------");
  Vue.directive(key, (directives as { [key: string]: DirectiveOptions })[key]);
});

/*======================================================================================*/
// Register global filter functions
Object.keys(filters).forEach((key) => {
  Vue.filter(key, (filters as { [key: string]: Function })[key]);
});

/*======================================================================================*/
Vue.config.productionTip = false;

/**
 * Construct Vue app after token and app config have been obtained.
 */
export async function main(
  config: AppConfig,
  keycloak: Keycloak
): Promise<void> {
  initializeApiService(config.VUE_APP_BASE_API); // the 'new' style
  initializeRequestService(config.VUE_APP_BASE_API); // the 'old' style

  Vue.prototype.$envConfig = config;

  const mainInstance = new Vue({
    router,
    store,
    i18n,
    async created() {
      provideAuth(keycloak, {
        remainingTokenValiditySeconds: parseInt(
          Vue.prototype.$envConfig.VUE_APP_IDENTITY_SERVICE_REFRESH_TOKEN_TIME!
        ),
      });
      provideCheckVersionTimer();
      await globalStore.fetchUnits();
    },
    render: (h: any) => h(App),
  });

  registerMainInstance(mainInstance);
  mainInstance.$mount('#app');
}
