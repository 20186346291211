<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import TripTimeline from '@/components/trip/TripTimeline.vue';
import { Trip } from '@/utils/types/trip';

@Component({
  name: 'TimelineCell',
  components: {
    TripTimeline,
  },
})
export default class extends Vue {
  @Prop() row!: Trip;
}
</script>

<template>
  <div class="timeline-wrapper">
    <TripTimeline :trip="row" v-if="row" />
  </div>
</template>

<style scoped>
.timeline-wrapper:deep(.timeline) {
  margin-top: -1.5rem;
}
</style>
