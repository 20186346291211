<script setup lang="ts">
import { UUID } from '@/api/common';
import { ConfigurationParameterSetpointValue } from '@/api/configParams';
import { SimplePreset } from '@/api/preset';
import { useLoggedInUser } from '@/auth/user';
import WidgetDialog from '@/components/dialog/WidgetDialog.vue';
import { useUnitConversion } from '@/composables/conversion';
import { useMergedRouteMeta, useRoute, useRouter } from '@/composables/router';
// We need to import like this, else we can't use <i18n/> for interpolation.
// i18n from @/lang would overlap with global component <i18n/>
// READ MORE: https://kazupon.github.io/vue-i18n/guide/interpolation.html#basic-usage
import translationService from '@/lang';
import { useAssetInfoQuery } from '@/query/assets';
import {
  usePresetQuery,
  usePresetsQuery,
  useSetParameterConfigurationMutation,
} from '@/query/preset';
import { AssetType } from '@/utils/assetTypes';
import { isHyvaCompany } from '@/utils/companyService';
import { formatValue } from '@/utils/format';
import { customFailedMessage, customSuccessMessage } from '@/utils/prompt';
import { Option } from '@/utils/types/option';
import { AssetLifecycle } from '@/utils/workData/lookuptable';
import { computed, ref } from 'vue';
import PresetCard, { ParameterData } from './PresetCard.vue';

interface Preset extends SimplePreset {
  parameterData: ParameterData[];
}

const mergedRouteMeta = useMergedRouteMeta();

const assetType = computed((): AssetType => {
  const assetType = mergedRouteMeta?.value.assetType;
  if (!assetType) {
    throw new Error('Cannot determine asset type from route');
  }
  return assetType;
});

const loggedInUser = useLoggedInUser();

function formatPresetName(companyName: string, presetName: string): string {
  if (loggedInUser.value && isHyvaCompany(loggedInUser.value?.companyType)) {
    return `${companyName} - ${presetName}`;
  }

  return presetName;
}

const { data: presets } = usePresetsQuery(assetType.value, undefined, {
  select: (presets): Option[] => {
    const mappedPresets = presets.map(
      (preset): Option => ({
        key: preset.id,
        label: formatPresetName(preset.companyName, preset.name),
      })
    );

    mappedPresets.sort((a, b) => a.label.localeCompare(b.label));

    return mappedPresets;
  },
});

const { currentUserConvertUnitValue } = useUnitConversion();
const selectedPresetId = ref<UUID>();

const { data: selectedPreset } = usePresetQuery(selectedPresetId, {
  select: (preset): Preset => {
    return {
      ...preset,
      parameterData: preset.configurationParameters.map(
        (parameter): ParameterData => {
          const convertedUnitValue = currentUserConvertUnitValue({
            unit: parameter.unit,
            v:
              typeof parameter.value === 'string'
                ? parseFloat(parameter.value)
                : parameter.value,
          });

          return {
            name: parameter.propertyName,
            propertyCode: parameter.propertyCode,
            rawMetricValue:
              typeof parameter.value === 'string'
                ? parseFloat(parameter.value)
                : parameter.value,
            translatedUnit: translationService.t(convertedUnitValue.unit),
            value: formatValue(convertedUnitValue, { maxDecimals: 4 }),
          };
        }
      ),
    };
  },
});

function handlePresetChange(presetId: UUID) {
  selectedPresetId.value = presetId;
}

const route = useRoute();
const router = useRouter();
const isApplyDialogOpen = ref(false);

const { data: asset } = useAssetInfoQuery(route.value.params.id);

const applyMutation = useSetParameterConfigurationMutation(
  () => {
    isApplyDialogOpen.value = false;
    customSuccessMessage(translationService.t('preset.applySuccess'));
    router.back();
  },
  (err) => {
    console.error(err);
    customFailedMessage(translationService.t('preset.applyError'));
  }
);
async function applyParameterPreset() {
  try {
    if (typeof route.value.params.id !== 'string') {
      throw new Error('id has to be present');
    }

    if (selectedPreset.value === undefined) {
      throw new Error('selectedPreset has to be defined');
    }

    applyMutation.mutate({
      assetUUID: route.value.params.id,
      body: {
        configurationParameters: selectedPreset.value.parameterData.map(
          (parameter): ConfigurationParameterSetpointValue<number> => ({
            propertyCode: parameter.propertyCode,
            value: parameter.rawMetricValue,
          })
        ),
      },
      sendSelectedCustomer: asset.value?.status === AssetLifecycle.Assigned,
    });
  } catch (err) {
    console.error(err);
    customFailedMessage(translationService.t('preset.applyError'));
  }
}
</script>

<template>
  <div style="height: 100%">
    <PresetCard
      v-loading="presets === undefined"
      :dropdown-label="$t('preset.parameterPreset')"
      :dropdown-options="presets ?? []"
      :selected-option="selectedPresetId"
      :title="$t('preset.apply')"
      @dropdown-change="handlePresetChange"
      :data="selectedPreset?.parameterData"
    >
      <template #actionButton>
        <span class="header-apply-button">
          <el-button
            @click="isApplyDialogOpen = true"
            :disabled="selectedPreset === undefined"
          >
            {{ $t('common.apply') }}
          </el-button>
        </span>
      </template>
    </PresetCard>
    <WidgetDialog
      :visible="isApplyDialogOpen"
      :title="$t('preset.apply')"
      :confirmBtnName="$t('common.apply')"
      width="40%"
      @handle-cancel="isApplyDialogOpen = false"
      @handle-confirm="applyParameterPreset"
    >
      <div class="dialog-container">
        <i18n path="preset.applyConfirmation" tag="div" class="main-text">
          <span class="asset-name" place="asset">
            {{ asset?.companyAssetId ?? '' }}
          </span>
        </i18n>
      </div>
    </WidgetDialog>
  </div>
</template>

<style scoped lang="scss">
.header-apply-button {
  .el-button {
    color: white;
    font-size: 16px;
    font-weight: 500;
    background-color: #373e41;
    border-radius: 4px;
    padding: 10px 20px;
    border: 0px;
    cursor: pointer;
  }

  .el-button:hover {
    background-color: #5f6567;
    color: white;
  }

  .el-button:active {
    background-color: #5f6567;
    color: white;
  }
}

.dialog-container {
  display: flex;
  justify-content: center;
  text-align: center;
  padding: 57px 0;
  font-size: 22px;
}

.asset-name {
  color: #ffcd00;
  font-weight: bold;
}
</style>
