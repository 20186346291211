// !!! WARNING: DO NOT just add imports to this file!
//
// Any imports added here may mean that a large (webpack) bundle gets
// additionally included, which is wasted time just to decide to redirect
// to KeyCloak.
//
// !!! WARNING: DO NOT just add imports to this file!

export interface AppConfig {
  VUE_APP_BASE_API: string;
  VUE_APP_IDENTITY_SERVICE_URL: string;
  VUE_APP_IDENTITY_SERVICE_REALM: string;
  VUE_APP_IDENTITY_SERVICE_CLIENT_ID: string;
  VUE_APP_IDENTITY_SERVICE_REFRESH_TOKEN_TIME: string;
  VUE_APP_MAP_URL: string;
  VUE_APP_MOCK_API_URL: string;
}

export async function fetchConfig(): Promise<AppConfig> {
  const response = await fetch('/config.json');
  return response.json();
}
