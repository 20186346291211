<script lang="ts">
import {
  OrganizationAssetsHierarchyAsset,
  OrganizationAssetsHierarchyOrganization,
} from '@/api/assets';
import { UUID } from '@/api/common';
import { AssetsWithKpis } from '@/api/kpis';
import { ActiveContext, useActiveContext } from '@/auth/context';
import {
  SelectedCustomerInfo,
  useSelectedCustomerInfo,
} from '@/auth/selectedCustomer';
import { LoggedInUser, useLoggedInUser } from '@/auth/user';
import WidgetCard from '@/components/layout/widget/WidgetCard.vue';
import {
  useAllAssetsQuery,
  useOrganizationAssetsHierarchyQuery,
} from '@/query/assets';
import { useMultiAssetKpisQuery } from '@/query/kpis';
import {
  RouteNames,
  routeNameToAssetTypeMappping,
} from '@/router/modules/assets';
import { hasSystemFeature } from '@/store/modules/permission';
import {
  extractAssetsFromOrganizationHierarchies,
  filterOrganizationHierarchiesAssets,
} from '@/utils/assets';
import { AssetType } from '@/utils/assetTypes';
import { flatMapDeep, isDefined, mapTree } from '@/utils/collections';
import { mapOrgSummariesToOrganizationAssetsHierarchyOrganization } from '@/utils/org';
import { stripPrefix } from '@/utils/string';
import {
  fakeUnref,
  InitializeReactive,
} from '@/utils/vueClassComponentHelpers';
import {
  ASSET_TYPE_HOME_STATUS_CLAIM,
  KPI_FIELDS,
} from '@/utils/workData/lookuptable';
import {
  getOperationalStatuses,
  isOperationalStatus,
  OperationalStatus,
  operationalStatusColorMapping,
  OPERATIONAL_STATUS_ICONS,
  OPERATIONAL_STATUS_PREFIX,
} from '@/utils/workData/operationalStatus';
import AssetsWidgetTable, {
  FleetStatusAsset,
  FleetStatusKpis,
  FleetStatusOrganization,
} from '@/views/assets/components/AssetsWidgetTable.vue';
import PieChart, {
  PieChartElement,
  PieChartSelection,
} from '@/views/assets/components/PieChart.vue';
import { EChartOption } from 'echarts';
import { computed, unref } from 'vue';
import { Component, Prop, Vue } from 'vue-property-decorator';

function hierarchyOrgsToFleetStatusOrg(
  hierarchyOrg: OrganizationAssetsHierarchyOrganization[],
  kpis: AssetsWithKpis<FleetStatusKpis>
): FleetStatusOrganization[] {
  return hierarchyOrg.map((org) => hierarchyOrgToFleetStatusOrg(org, kpis));
}

function hierarchyOrgToFleetStatusOrg(
  hierarchyOrg: OrganizationAssetsHierarchyOrganization,
  kpis: AssetsWithKpis<FleetStatusKpis>
): FleetStatusOrganization {
  const fleetStatusOrg: FleetStatusOrganization = {
    ...hierarchyOrg,
    suborganizations: hierarchyOrg.suborganizations.map((subOrg) =>
      hierarchyOrgToFleetStatusOrg(subOrg, kpis)
    ),
    assets: [], // filled in below
  };
  // Assets needs to be assigned as a separate step, because we need to put the
  // parent (organization) relation in.
  fleetStatusOrg.assets = hierarchyOrg.assets.map(
    (asset): FleetStatusAsset => ({
      ...asset,
      organization: fleetStatusOrg,
      operationalStatus: kpis[asset.id]?.[KPI_FIELDS.OperationalStatus].v,
      lastCommunicationTime:
        kpis[asset.id]?.[KPI_FIELDS.LastCommunicationTime].v,
    })
  );
  return fleetStatusOrg;
}

// Removes values that are not valid OperationalStatuses.
function sanitizeOperationalStatuses(
  statusStrings: string[]
): OperationalStatus[] {
  return statusStrings
    .map(
      (status) => `${OPERATIONAL_STATUS_PREFIX}${status.toUpperCase().trim()}`
    )
    .filter(isOperationalStatus);
}

const components = {
  WidgetCard,
  PieChart,
  AssetsWidgetTable,
} as const;

/**
 * Operational Status overview widget.
 *
 * Used as a 'standalone' widget on Fleet Overview page (i.e. asset-type specific),
 * or embedded into Expanded Map (on Home page, Fleet overview or Single Asset overview.)
 */
@Component({
  name: 'FleetStatus',
  components,
})
export default class FleetStatus extends Vue {
  static components: typeof components;

  $props!: {
    /**
     * Show search box (default true).
     */
    allowFiltering?: FleetStatus['allowFiltering'];

    /**
     * Show only asset(s) in the given list.
     *
     * If no filter is given, all assets in the current organization's hierarchy are shown.
     * If an empty list is given, no assets will be shown.
     */
    assetIdFilter?: FleetStatus['assetIdFilter'];

    /**
     * Always show as a list of assets without their organization (default false).
     */
    listMode?: AssetsWidgetTable['listMode'];
  };

  @Prop({
    default: true,
  })
  allowFiltering!: boolean;

  @Prop({
    required: false,
  })
  assetIdFilter: UUID[] | undefined;

  @Prop({
    default: false,
  })
  listMode!: boolean;

  unfilteredRawAssetsHierarchyQuery!: ReturnType<
    typeof useOrganizationAssetsHierarchyQuery
  >;
  assetKpisQuery!: ReturnType<typeof useMultiAssetKpisQuery<FleetStatusKpis>>;
  allAssetsQuery!: ReturnType<typeof useAllAssetsQuery>;

  @InitializeReactive
  loggedInUser: LoggedInUser | undefined;

  @InitializeReactive
  context!: ActiveContext;

  selectedCustomerInfo!: SelectedCustomerInfo;

  // TODO Probably no longer needed
  /**
   * Cache of legend translation back to operational status, because
   * our PieChart doesn't plot key/value pairs, only the (human-readable)
   * value.
   */
  legendTranslations: Record<string, OperationalStatus> = {};

  created() {
    this.context = fakeUnref(useActiveContext());
    this.loggedInUser = fakeUnref(useLoggedInUser());
    this.selectedCustomerInfo = useSelectedCustomerInfo();

    // Optionally, fetch all assets in the system, if the user is Operational Support
    // and selected "All" from the customer dropdown
    this.allAssetsQuery = useAllAssetsQuery(
      computed(() => this.assetTypeFromRoute),
      computed(() => this.isEveryCustomerSelected)
    );

    this.unfilteredRawAssetsHierarchyQuery =
      useOrganizationAssetsHierarchyQuery(
        computed(() => this.assetTypeFromRoute),
        computed(() => !this.isEveryCustomerSelected)
      );

    // Fetch KPIs for each asset that we need to show
    this.assetKpisQuery = useMultiAssetKpisQuery(
      computed(() => this.assetIds),
      [KPI_FIELDS.OperationalStatus, KPI_FIELDS.LastCommunicationTime],
      {
        refetchInterval: 60 * 1000,
      }
    );
  }

  get assetIds(): UUID[] | undefined {
    const hierarchies = unref(this.filteredRawHierarchy);
    if (!hierarchies) {
      return undefined;
    }

    return extractAssetsFromOrganizationHierarchies(hierarchies).map(
      (asset) => asset.id
    );
  }

  /**
   * For Operational Support users (in Hyva company), compute hierarchy based on
   * the companies that were fetched at login and attach (all) their assets to them.
   */
  get operationalSupportFullHierarchy():
    | OrganizationAssetsHierarchyOrganization[]
    | undefined {
    if (
      !this.allAssetsQuery.data.value ||
      !this.selectedCustomerInfo.companies.value.data
    ) {
      return undefined;
    }

    const orgSummaries = this.selectedCustomerInfo.companies.value.data
      .map((company) => company.primaryOrganization)
      .filter(isDefined);

    orgSummaries.unshift({
      organizationId: '',
      organizationName: this.$t('assetsModule.unassigned'),
      organizations: [],
    });

    const orgIdToAssets: Map<UUID, OrganizationAssetsHierarchyAsset[]> =
      new Map();

    for (const asset of this.allAssetsQuery.data.value) {
      // Assign the asset to the 'special' organisation if it's unassigned
      const orgId = asset.organizationId ?? '';
      const orgAssets = orgIdToAssets.get(orgId) ?? [];
      orgAssets.push({
        id: asset.id,
        assetType: asset.assetType,
        companyAssetId: asset.companyAssetId,
      });
      orgIdToAssets.set(orgId, orgAssets);
    }

    const unassignedOrg = orgIdToAssets.get('');

    if (unassignedOrg !== undefined && unassignedOrg.length === 0) {
      orgIdToAssets.delete('');
    }

    return mapOrgSummariesToOrganizationAssetsHierarchyOrganization(
      orgSummaries,
      orgIdToAssets
    );
  }

  get canSeeAllAssets(): boolean {
    return (
      this.loggedInUser?.canImpersonate === true &&
      this.loggedInUser.mustImpersonate === false
    );
  }

  get isEveryCustomerSelected(): boolean {
    return this.canSeeAllAssets && this.context.selectedCompanyId === undefined;
  }

  /**
   * Determine where widget is currently being used, and filter to show
   * only the respective assets.
   */
  // TODO Move this responsibility to src/widgets/fleet/FleetStatus.vue and src/widgets/home/expanded/MapExpanded
  get assetTypeFromRoute(): AssetType | undefined {
    const routeName = this.$route.name! as RouteNames;
    return routeNameToAssetTypeMappping[routeName];
  }

  // TODO Move this logic out of this widget, such that src/widgets/fleet/FleetStatus.vue
  // and src/widgets/home/expanded/MapExpanded can pass it in explicitly.
  get applicableAssetTypes(): AssetType[] {
    const assetType = this.assetTypeFromRoute;

    // If we're rendered on an AssetType-specific page (e.g. Fleet overview),
    // that's the asset type we need.
    if (assetType) {
      return [assetType];
    }

    // If we're rendered on a more generic page (e.g. Home > Expanded Map),
    // we need to look at the asset types this user has access to.
    // TODO Move this computation to loggedInUser? (And re-use it in AssetStatus.vue)
    const applicableAssetTypes = Object.values(ASSET_TYPE_HOME_STATUS_CLAIM)
      .filter(({ type, claim }) => {
        if (!this.loggedInUser) {
          return false;
        }
        const hasMenu = this.loggedInUser.moduleAndPageCodes.includes(claim);
        const hasAssetTypeFeature = hasSystemFeature(
          this.loggedInUser.subscriptions,
          [null, type]
        );
        return hasMenu && hasAssetTypeFeature;
      })
      .map((claimConfig) => claimConfig.type);

    return applicableAssetTypes;
  }

  /**
   * Full assets hierarchy filtered down to just the asset ID(s) and
   * asset types requested to be shown.
   */
  get filteredRawHierarchy():
    | OrganizationAssetsHierarchyOrganization[]
    | undefined {
    const hierarchy = this.isEveryCustomerSelected
      ? this.operationalSupportFullHierarchy
      : [unref(this.unfilteredRawAssetsHierarchyQuery.data)].filter(isDefined);
    const idFilter = this.assetIdFilter;
    if (!hierarchy || !idFilter) {
      return hierarchy;
    }

    return filterOrganizationHierarchiesAssets(
      hierarchy,
      (asset) =>
        idFilter.includes(asset.id) &&
        this.applicableAssetTypes.includes(asset.assetType)
    );
  }

  /**
   * Organization + assets hierarchy, each asset augmented with status KPIs.
   */
  get fleetStatusHierarchy(): FleetStatusOrganization[] | undefined {
    const hierarchy = unref(this.filteredRawHierarchy);
    if (!hierarchy) {
      return undefined;
    }
    return hierarchyOrgsToFleetStatusOrg(
      hierarchy,
      unref(this.assetKpisQuery.data) ?? {}
    );
  }

  /**
   * Filter fleet status hierarchy to only the selected operational status.
   */
  get filteredFleetStatusHierarchy(): FleetStatusOrganization[] | undefined {
    if (!this.fleetStatusHierarchy) {
      return undefined;
    }

    return this.fleetStatusHierarchy
      .map((org) =>
        mapTree(org, 'suborganizations', (org) => {
          const assets = org.assets.filter(
            (asset) =>
              !this.selectedOperationalStatuses ||
              this.selectedOperationalStatuses.some(
                (p) => p === asset.operationalStatus
              )
          );
          // Prune orgs that have no assets and no suborganizations (that have assets)
          if (assets.length === 0 && org.suborganizations.length === 0) {
            return undefined;
          }
          // Assets are pointing to their org, but we're creating a new org (with different assets in it),
          // so we need to update each asset's parent.
          const newOrg = {
            ...org,
          };
          newOrg.assets = assets.map((asset) => ({
            ...asset,
            organization: newOrg,
          }));
          return newOrg;
        })
      )
      .filter(isDefined);
  }

  /**
   * Flattened hierarchy to a list of assets (with KPIs).
   */
  get fleetStatusAssets(): FleetStatusAsset[] | undefined {
    const hierarchy = unref(this.filteredFleetStatusHierarchy);
    if (!hierarchy) {
      return undefined;
    }

    return hierarchy.flatMap((org) =>
      flatMapDeep(org, 'suborganizations', (suborg) => suborg.assets)
    );
  }

  /**
   * List of applicable operational statuses for the currently active asset type(s).
   */
  get operationalStatuses(): OperationalStatus[] {
    return getOperationalStatuses(this.applicableAssetTypes);
  }

  /**
   * Mapping of each applicable operational status to the number of assets matching that status.
   */
  get operationalStatusCounts(): Partial<Record<OperationalStatus, number>> {
    const fleetStatusAssets = this.fleetStatusAssets;
    if (!fleetStatusAssets) {
      return {};
    }
    return Object.fromEntries(
      this.operationalStatuses.map((operationalStatus) => [
        operationalStatus,
        fleetStatusAssets.filter(
          (asset) => asset.operationalStatus === operationalStatus
        ).length,
      ])
    );
  }

  /**
   * Currently selected operational statuses.
   */
  get selectedOperationalStatuses(): OperationalStatus[] | undefined {
    const statusString = this.$route.query.status;

    if (statusString === null || statusString === undefined) {
      return this.operationalStatuses;
    }

    if (statusString === '') {
      return [];
    }

    const statuses = Array.isArray(statusString)
      ? statusString.filter(isDefined)
      : statusString.split(',');
    const sanitizedStatuses = sanitizeOperationalStatuses(statuses);

    if (sanitizedStatuses.length !== statuses.length) {
      this.$router.replace({
        query: {
          ...this.$route.query,
          status: sanitizedStatuses
            .map((status) => stripPrefix(status, OPERATIONAL_STATUS_PREFIX))
            .join(','),
        },
      });
    }

    return sanitizedStatuses;
  }

  /**
   * Color to use for each operational status, in correct order.
   * Must match with the order in pieChartData / pieChartCategories.
   */
  get pieChartColors(): string[] {
    return this.operationalStatuses.map(
      (operationalStatus) => operationalStatusColorMapping[operationalStatus]
    );
  }

  /**
   * Translated names of applicable operational statuses for pie chart's legend.
   */
  get pieChartCategories(): EChartOption.Legend.LegendDataObject[] {
    this.legendTranslations = Object.fromEntries(
      this.operationalStatuses.map((operationalStatus) => [
        this.$t(operationalStatus) as string,
        operationalStatus,
      ])
    );

    return Object.entries(this.legendTranslations).map((item) => ({
      name: item[0],
      icon: 'image://' + OPERATIONAL_STATUS_ICONS[item[1]],
    }));
  }

  /**
   * Convert applicable operational status counts to pie chart's format.
   */
  get pieChartData(): PieChartElement[] {
    return this.operationalStatuses
      .map((operationalStatus): PieChartElement | undefined => ({
        name: this.$t(operationalStatus) as string,
        value: this.operationalStatusCounts[operationalStatus] ?? 0,
      }))
      .filter(isDefined);
  }

  /**
   * Determine which operational status should be shown in the
   * piechart, and enabled/disabled in the legend.
   *
   * Based on currently selected operational status.
   */
  get pieChartSelection(): PieChartSelection {
    const selectedStatuses = this.selectedOperationalStatuses;
    return Object.fromEntries(
      this.operationalStatuses.map((operationalStatus) => [
        this.$t(operationalStatus),
        !selectedStatuses ||
          selectedStatuses.some((status) => status == operationalStatus),
      ])
    );
  }

  /**
   * Total number of assets in hierarchy.
   *
   * Filtered by asset type and assetIdFilter, but not on operational
   * status or text filter.
   */
  get total(): number {
    return this.fleetStatusAssets?.length ?? 0;
  }

  handleSelectAsset(asset: FleetStatusAsset): void {
    this.$emit('select-asset', asset.id, asset.assetType);
  }

  handleLegendClick(selectedStatusText: string): void {
    let newSelectedStatus: OperationalStatus | undefined =
      this.legendTranslations[selectedStatusText];

    const currentSelectedStatuses = new Set(this.selectedOperationalStatuses);

    if (currentSelectedStatuses.has(newSelectedStatus)) {
      currentSelectedStatuses.delete(newSelectedStatus);
    } else {
      currentSelectedStatuses.add(newSelectedStatus);
    }

    const isEveryStatusSelected =
      currentSelectedStatuses?.size === this.operationalStatuses.length;

    this.$emit('operational-status-change', currentSelectedStatuses);

    this.$router.replace({
      query: {
        ...this.$route.query,
        status: isEveryStatusSelected
          ? undefined
          : [...currentSelectedStatuses]
              ?.map((status) => stripPrefix(status, OPERATIONAL_STATUS_PREFIX))
              ?.join(','),
      },
    });
  }
}
</script>

<template>
  <WidgetCard :loading="assetKpisQuery.isPending.value">
    <div style="overflow: auto">
      <div>
        <div class="d-flex ai-center pie-chart-header">
          <div>
            <span class="fs-fa fs-xl status-vehicle"
              >{{ $t('assetsModule.totalAssets') }}:</span
            >
          </div>
          <div>
            <span class="fs-fa status-value">{{ total }}</span>
          </div>
        </div>
        <PieChart
          :v-if="
            /* Piechart displays a 'ring' when data is all zeroes, let's not even show that ring while we're loading */
            assetKpisQuery.isPending.value
          "
          :colors="pieChartColors"
          :categories="pieChartCategories"
          :data="pieChartData"
          :selected-categories="pieChartSelection"
          :allow-filtering="allowFiltering"
          height="12rem"
          @legend-click="handleLegendClick"
        />
        <div class="border-line" />
      </div>
      <AssetsWidgetTable
        :hierarchies="filteredFleetStatusHierarchy"
        :allowFiltering="allowFiltering"
        :list-mode="listMode"
        :ordered-operational-statuses="operationalStatuses"
        @select-asset="handleSelectAsset"
      />
    </div>
  </WidgetCard>
</template>

<style lang="scss" scoped>
/* Scrollbar slider */
:deep(::-webkit-scrollbar-thumb) {
  background-color: #a1a3a9;
  border-radius: 5px;
}

:deep(::-webkit-scrollbar) {
  width: 10px;
  height: 10px;
}

.pie-chart-header {
  padding: 0.357143rem 2.142857rem 0;
}

.status-vehicle {
  font-weight: 400;
  line-height: 1.5rem; //21px
}

.status-value {
  font-size: 1.857143rem; //26px
  font-weight: bold;
  line-height: 2.142857rem; //30px
  padding-left: 0.714286rem;
}

.border-line {
  margin: 0.714286rem;
  border-bottom: 1px solid #dddddd;
}
</style>
