<script lang="ts">
import {
  AssetDeploymentLog,
  SystemDeploymentAsset,
} from '@/api/deploymentDetails';
import { HISTORY_DETAILS_COLS } from '@/utils/workData/lookuptable';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { calculateAssetDeploymentStatus } from './assetDeployment';

interface TableCols {
  label: string;
  prop: string;
}

@Component({
  name: 'AssetStatusTable',
})
export default class extends Vue {
  @Prop({}) tableData!: SystemDeploymentAsset;
  @Prop({}) cols!: TableCols[];

  /** Local variables */
  detailsCols = HISTORY_DETAILS_COLS;

  /**
   * Get deploy details
   * @param row
   */
  getDeplyDetails(row: SystemDeploymentAsset) {
    this.$emit('selectAsset', row.assetDeploymentId);
  }

  /**
   * Get final asset deployment status by certain conditions
   */
  finalAssetDeploymentStatus(item: AssetDeploymentLog) {
    return (
      calculateAssetDeploymentStatus(item) ??
      this.$t(`asds.${item.assetDeployStatus}`)
    );
  }
}
</script>

<template>
  <el-table :data="tableData" :cols="cols" @row-click="getDeplyDetails">
    <el-table-column
      v-for="col in cols"
      :key="col.label"
      :prop="col.prop"
      :label="col.label"
      :sortable="true"
      width="180"
    >
      <template v-slot:header>
        <span style="white-space: nowrap">{{ $t(col.label) }}</span>
      </template>
      <template
        v-slot="slotProps"
        v-if="col.prop === detailsCols.HDC_DEPLOY_STATUS"
      >
        <span :class="['tag', slotProps.row[col.prop]]">
          {{ $t(`${finalAssetDeploymentStatus(slotProps.row)}`) }}
        </span>
      </template>
      <template
        v-slot="slotProps"
        v-else-if="col.prop === 'deploymentDuration'"
      >
        {{ slotProps.row[col.prop] ?? '-' }}
      </template>
    </el-table-column>
  </el-table>
</template>

<style lang="scss" scoped>
th .cell {
  display: flex !important;
  flex-direction: row;
}

.tag {
  font-family: Roboto;
  font-weight: 500;
  font-size: 12px;
  line-height: 14.06px;
  padding: 5px;
  border-radius: 5px;
  color: #373e41;
  display: inline-block;
  width: 80px;
  text-align: center;
}

.ASDS_WAITING {
  background: #f2c94c;
}

.ASDS_POSTPONED {
  background: #ffbb54;
}

.ASDS_DEPLOYING {
  background: #7be3d1;
}

.ASDS_ROLLING_BACK {
  background: #56ccf2;
}

.ASDS_ON_HOLD {
  background: #a5a6f6;
}

.ASDS_SUCCESS {
  background: #6fcf97;
}

.ASDS_REVERTED {
  background: #88e2ff;
}

.ASDS_CANCELED {
  background: #e0e0e0;
}

.ASDS_CANCELLED {
  background: #e0e0e0;
}

.ASDS_FAILED {
  background: #f2994a;
}

.ASDS_WAITING {
  background: #f5cb22;
}

.ASDS_INSTALLING {
  background: #22cbf5;
}

.ASDDS_POSTPONED {
  background: #ffbb54;
}

.ASDDS_DEPLOYING {
  background: #7be3d1;
}

.ASDDS_ROLLING_BACK {
  background: #56ccf2;
}

.ASDDS_ON_HOLD {
  background: #a5a6f6;
}

.ASDDS_SUCCESS {
  background: #6fcf97;
}

.ASDDS_REVERTED {
  background: #88e2ff;
}

.ASDDS_CANCELED {
  background: #e0e0e0;
}

.ASDDS_CANCELLED {
  background: #e0e0e0;
}

.ASDDS_FAILED {
  background: #f2994a;
}

.ASDDS_WAITING {
  background: #f5cb22;
}

.ASDDS_INSTALLING {
  background: #22cbf5;
}
</style>
