var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.contentIsLoading),expression:"contentIsLoading"}],staticClass:"packages-container"},[_vm._l((_vm.companySubscriptions),function(item,index){return _c('div',{key:index},[_c('div',{staticClass:"package-item"},[_c('div',{staticClass:"module-header"},[_vm._v(" "+_vm._s(item.subscriptionPackageType === _vm.packageType.CompanyType ? _vm.$t('subscriptionPackages.customerPackage') : _vm.$t('subscriptionPackages.assetPackage'))+" "+_vm._s(item.subscriptionPackageType === _vm.packageType.CompanyType ? '' : index)+" ")]),_c('div',{staticClass:"package-body"},[(item.subscriptionPackageType === _vm.packageType.CompanyType)?_c('div',{staticClass:"selection-area"},[_c('cus-form-item',{attrs:{"title":_vm.$t('subscriptionPackages.packageName'),"required":true}},[_c('el-select',{attrs:{"id":'packagenameforcustomerpackageinput' + index,"placeholder":_vm.$t('subscriptionPackages.selectSubscriptionPackage'),"disabled":_vm.source === 'view',"filterable":""},on:{"change":function($event){return _vm.handleFeaturesForASpecificPackage(index)}},model:{value:(item.subscriptionPackageId),callback:function ($$v) {_vm.$set(item, "subscriptionPackageId", $$v)},expression:"item.subscriptionPackageId"}},_vm._l((_vm.subscriptionPackages.companyType),function(item){return _c('el-option',{key:item.id,attrs:{"label":item.name,"value":item.id}})}),1)],1)],1):_vm._e(),(item.subscriptionPackageType === _vm.packageType.AssetType)?_c('div',{staticClass:"selection-area"},[_c('cus-form-item',{attrs:{"title":_vm.$t('subscriptionPackages.assetType'),"required":true}},[_c('el-select',{attrs:{"id":'assettypeforassetpackageinput' + index,"placeholder":_vm.$t('maintenance.selectAssetType'),"disabled":_vm.source === 'view',"filterable":""},on:{"change":function($event){return _vm.handleSubscriptionTypePerAssetType(
                  index,
                  item.subscriptionPackageAssetType
                )}},model:{value:(item.subscriptionPackageAssetType),callback:function ($$v) {_vm.$set(item, "subscriptionPackageAssetType", $$v)},expression:"item.subscriptionPackageAssetType"}},_vm._l((_vm.assetTypeList),function(assetType){return _c('el-option',{directives:[{name:"show",rawName:"v-show",value:(
                  !_vm.companySubscriptions.find(
                    /* @ts-expect-error TODO Wrong type */
                    (sub) => sub.subscriptionPackageAssetType == assetType.id
                  )
                ),expression:"\n                  !companySubscriptions.find(\n                    /* @ts-expect-error TODO Wrong type */\n                    (sub) => sub.subscriptionPackageAssetType == assetType.id\n                  )\n                "}],key:assetType.id,attrs:{"label":_vm.$t(assetType.id),"value":assetType.id}})}),1)],1),_c('cus-form-item',{attrs:{"title":_vm.$t('subscriptionPackages.packageName'),"required":true}},[_c('el-select',{attrs:{"id":'packagenameforassetpackageinput' + index,"disabled":!item.subscriptionPackageAssetType || _vm.source === 'view',"title":!item.subscriptionPackageAssetType
                  ? _vm.$t('subscriptionPackages.selectFirstAnAssetType')
                  : '',"placeholder":_vm.$t('subscriptionPackages.selectSubscriptionPackage'),"filterable":""},on:{"change":function($event){return _vm.handleFeaturesForASpecificPackage(index)}},model:{value:(item.subscriptionPackageId),callback:function ($$v) {_vm.$set(item, "subscriptionPackageId", $$v)},expression:"item.subscriptionPackageId"}},_vm._l((item.subscriptionPackagesPerAssetType),function(subItem){return _c('el-option',{key:subItem.id,attrs:{"label":subItem.name,"value":subItem.id}})}),1)],1)],1):_vm._e(),_c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.featuresTableIsLoading),expression:"featuresTableIsLoading"}],staticClass:"package-content"},[_c('features-table',{attrs:{"tableList":item.systemFeatures,"cols":_vm.cols,"isEditable":_vm.source != 'view',"featuresHaveErrors":_vm.featuresHaveErrors,"featuresWithInputType":_vm.featuresWithInputType,"featuresWithDateType":_vm.featuresWithDateType,"featuresWithBooleanType":_vm.featuresWithBooleanType}})],1)])])])}),(_vm.source != 'view')?_c('div',{staticClass:"last-package-item"},[_c('div',{staticClass:"add-new-package-btn"},[(_vm.companySubscriptions.length > 0)?_c('div',{staticClass:"btn-container",on:{"click":_vm.handleAddNewPackage}},[_c('i',{staticClass:"el-icon-circle-plus"}),_vm._v(" "+_vm._s(_vm.$t('subscriptionPackages.addNewAssetPackage'))+" ")]):_c('div',{staticClass:"btn-container",on:{"click":_vm.handleAddNewPackage}},[_c('i',{staticClass:"el-icon-circle-plus"}),_vm._v(" "+_vm._s(_vm.$t('subscriptionPackages.addNewCompanyPackage'))+" ")])])]):_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }