import { Value } from '@/api/value';
import i18n from '@/lang';
import { formatNumber } from './number';
import { UnitValue } from './units/unitValue';

export enum UndefinedRendering {
  DashDash = '--',
  Empty = ' ', // TypeScript complains if a member's value is an empty string
  NA = 'N/A',
  HashNA = '#N/A',
}

export interface FormatSetting {
  /**
   * Defines the number of decimals. This can be overriden by `maxDecimals` that can strip trailing zeros.
   */
  numberOfDecimals?: number;
  /**
   * Defines what to return if `v` is undefined. Won't return the unit neither.
   */
  undefinedAs?: UndefinedRendering;
  /**
   * Rounds the given (finite) number to at most the given number of digits.
   * * @example
   *
   * ```ts
   * formatValue({v: 4.12}, {maxDecimals: 3}) -> "4.12"
   * formatValue({v: 4.12}, {maxDecimals: 1}) -> "4.1"
   * ```
   */
  maxDecimals?: number;
}

/**
 * Format a Value's number using the given format settings.
 */
export function formatValue(
  value: Value<number | undefined>,
  formatSettings?: FormatSetting
): string {
  if (
    formatSettings?.maxDecimals !== undefined &&
    formatSettings?.numberOfDecimals !== undefined
  ) {
    throw new Error("Can't set both maxDecimals and numberOfDecimals.");
  }

  if (
    (value.v === undefined || value.v === null) &&
    formatSettings?.undefinedAs !== undefined
  ) {
    switch (formatSettings.undefinedAs) {
      case UndefinedRendering.Empty:
        return '';
      default:
        return formatSettings.undefinedAs;
    }
  }

  const formattedNumber = formatNumber(
    value.v,
    formatSettings?.numberOfDecimals ?? formatSettings?.maxDecimals ?? 1
  );

  return formatSettings?.maxDecimals === undefined
    ? formattedNumber
    : parseFloat(formattedNumber)
        .toFixed(formatSettings.maxDecimals)
        .replace(/0+$/, '')
        .replace(/\.$/, '');
}

/**
 * Format a UnitValue's number using the given format settings and append the unit.
 */
export function formatUnitValue(
  value: UnitValue<number | undefined>,
  formatSettings: FormatSetting = { undefinedAs: UndefinedRendering.DashDash }
): string {
  if (
    formatSettings.maxDecimals !== undefined &&
    formatSettings.numberOfDecimals !== undefined
  ) {
    throw new Error("Can't set both maxDecimals and numberOfDecimals.");
  }

  if (
    (value.v === undefined || value.v === null) &&
    formatSettings.undefinedAs
  ) {
    switch (formatSettings.undefinedAs) {
      case UndefinedRendering.Empty:
        return '';
      case UndefinedRendering.DashDash:
        return `${formatSettings.undefinedAs} ${i18n.t(value.unit)}`;
      default:
        return formatSettings.undefinedAs;
    }
  }

  return `${formatValue(value, formatSettings)} ${i18n.t(value.unit)}`;
}
