import { ALL_MODULE_CODES, Module } from '@/api/users';
import { DESIGNATED_COMPANY_TYPES } from '@/utils/companyService';
import { ALL_CLAIMS_CODES, COMPANY_TYPE } from '@/utils/workData/lookuptable';

/**
 * Specify which company types are allowed to see a specific module/page.
 * If the code isn't mentioned, it's allowed.
 * If the code is mentioned, the company type has to be in the list.
 */
const allowedCompanyTypesPerResourceCode: Partial<
  Record<ALL_MODULE_CODES, COMPANY_TYPE[]>
> = {
  [ALL_CLAIMS_CODES.AUTHRSC_MOD_ASSETS_MGMT]: [
    ...DESIGNATED_COMPANY_TYPES,
    COMPANY_TYPE.Hyva,
    COMPANY_TYPE.HyvaRegionalSupport,
  ],
  [ALL_CLAIMS_CODES.AUTHRSC_PAGE_CUST_ALARM_CONFIGURATION]: [
    COMPANY_TYPE.Customer,
  ],
  [ALL_CLAIMS_CODES.AUTHRSC_PAGE_GEOFENCES]: [COMPANY_TYPE.Customer],
  [ALL_CLAIMS_CODES.AUTHRSC_MOD_ALARM_CONFIGURATION]: [
    COMPANY_TYPE.Customer,
    COMPANY_TYPE.Hyva,
  ],
};

/**
 * Filter out module/page codes from the given list of
 * modules, based on company type.
 */
export function filterModulesForCompanyType(
  modules: Module[],
  companyType: COMPANY_TYPE
): Module[] {
  return modules.filter((module) => {
    const allowedCompanyTypes = allowedCompanyTypesPerResourceCode[module.code];
    if (!allowedCompanyTypes) {
      // If the page isn't mentioned in the list, it's OK by default
      return true;
    }
    // Otherwise, the requested company type must be in the list
    return allowedCompanyTypes.includes(companyType);
  });
}
