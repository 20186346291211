import { Route } from 'vue-router';
import { AssetRoutePathName, EntityType } from './workData/lookuptable';
import { ROUTE_ASSET_MAP } from './workData/utilMap';
import { AssetType } from './assetTypes';

export function determineEntityType(route: Route): EntityType {
  switch (route.name) {
    case AssetRoutePathName.MOBILE_COMPACTORS:
    case AssetRoutePathName.STATIC_COMPACTORS:
    case AssetRoutePathName.TIPPING_VEHICLES:
    case AssetRoutePathName.ALBA_STATIC_COMPACTORS:
    case AssetRoutePathName.REFUSE_COLLECTION_VEHICLES:
    case AssetRoutePathName.GENERIC_ASSETS:
      return EntityType.FLEET;
    case AssetRoutePathName.MOBILE_COMPACTOR_INFO:
    case AssetRoutePathName.STATIC_COMPACTOR_INFO:
    case AssetRoutePathName.TIPPING_VEHICLE_INFO:
    case AssetRoutePathName.ALBA_STATIC_COMPACTOR_INFO:
    case AssetRoutePathName.REFUSE_COLLECTION_VEHICLE_INFO:
    case AssetRoutePathName.GENERIC_ASSET_INFO:
      return EntityType.ASSET;
    default:
      throw new Error(`Invalid asset route name '${route.name}'`);
  }
}

export function determineAssetType(route: Route): AssetType {
  const assetType =
    !!route.name && ROUTE_ASSET_MAP.get(route.name)?.assetTypeCode;
  if (!assetType) {
    throw new Error('Asset type is empty');
  }

  return assetType;
}
