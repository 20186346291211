<script lang="ts">
import { getOrgById, Organization } from '@/api/organizations';
import { NotAllocatedAccessibleServiceRegionsResponse } from '@/api/serviceRegions';
import { getTimezones, TimezoneResponse } from '@/api/timezone';
import { ErrorType } from '@/api/types';
import { flattenOrganizations } from '@/auth/context';
import CommonBtn from '@/components/button/CommonBtn.vue';
import CusFormItem from '@/components/form/CusFormItem.vue';
import { UserModule } from '@/store/modules/user';
import { filterDeactivatedOrg } from '@/utils/index';
import { customFailedMessage } from '@/utils/prompt';
import {
  ACTIVATION_STATUS,
  COUNTRY_TYPE_LIST,
  ERROR_CODE_LIST,
} from '@/utils/workData/lookuptable';
import Treeselect from '@riophae/vue-treeselect';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { HelpdeskOrganizationModel } from '../models/helpdeskOrganizationModel';

@Component({
  name: 'NewHelpeskOrganization',
  components: {
    Treeselect,
    'cus-form-item': CusFormItem,
    'common-btn': CommonBtn,
  },
})
export default class extends Vue {
  @Prop({ required: true }) orgForm!: HelpdeskOrganizationModel;
  @Prop({ required: true }) disabledTreeSel!: boolean;
  @Prop({ required: false }) saveOrCreate!: boolean;
  @Prop() errorInfos!: ErrorType[];
  @Prop() serviceRegionsList!: NotAllocatedAccessibleServiceRegionsResponse[];
  @Prop() orgId!: string;

  orgInfos: Organization[] = [];
  countries = COUNTRY_TYPE_LIST;
  errorCode = ERROR_CODE_LIST;
  timeZoneOptions: TimezoneResponse[] = [];
  isOrganizationDetailsLoading: boolean = true;
  allOrganizations: Organization = {
    id: '',
    companyId: '',
    parentId: '',
    name: '',
    activationStatus: ACTIVATION_STATUS.Activated,
    assetsCount: 0,
    usersCount: 0,
    subOrganizationCount: 0,
    timezone: '',
    children: [],
    note: '',
    serviceRegion: '',
    accessibleServiceRegions: [],
    hyvaRegion: '',
    street: '',
    district: '',
    province: '',
    city: '',
    country: '',
    additionalInfo: '',
    zipCode: '',
    isDeleted: false,
    parentName: '',
  };

  validateParentId = (rule: any, value: any, callback: any) => {
    if (this.orgForm.parentId) {
      callback();
    } else {
      callback(new Error(`${this.$t('organizationModule.electOrg')}`));
    }
  };

  get inlineMsg() {
    return false;
  }

  get rules() {
    const rules = {
      name: [
        {
          required: true,
          message: this.$t('organizationModule.tipInputOrgName'),
          trigger: 'change',
        },
      ],
      country: [
        {
          required: true,
          message: this.$t('organizationModule.tipInputCountry'),
          trigger: 'blur',
        },
      ],
      timezone: [
        {
          required: true,
          message: this.$t('organizationModule.tipInputTimeZone'),
          trigger: 'blur',
        },
      ],
    };

    return rules;
  }

  handleSelect() {
    (this.$refs.orgForm as any).validateField('parentId');
  }

  handleTreeselectOpen() {
    let treeselectDom: any = document.getElementsByClassName(
      'vue-treeselect__control-arrow-container'
    );
    treeselectDom[0].setAttribute('style', 'transform: rotateZ(-90deg)');
  }

  handleTreeselectClose() {
    let treeselectDom: any = document.getElementsByClassName(
      'vue-treeselect__control-arrow-container'
    );
    treeselectDom[0].setAttribute('style', 'transform: rotateZ(90deg)');
  }

  get errNameInfo() {
    let errInfo: string = '';
    if (
      this.errorInfos.find((item) => item.field === this.errorCode.name)
        ?.code === 'ApiErrorFieldDuplicate'
    ) {
      errInfo = `${this.$t('organizationModule.duplicateName')}`;
    }

    return errInfo;
  }

  handleInput() {
    this.$emit('handle-input');
  }

  saveHelpdeskOrg() {
    (this.$refs.orgForm as any).validate((valid: any) => {
      if (valid) {
        this.$emit('save-helpdesk-org');
      }
    });
  }

  async fetchOrgMgmtInfos() {
    try {
      this.isOrganizationDetailsLoading = true;
      const organizationResponse = await getOrgById(UserModule.organizationId);
      this.allOrganizations = organizationResponse.data;
      this.orgInfos = filterDeactivatedOrg([this.allOrganizations]);
    } catch (error) {
      console.log(error);
      customFailedMessage(this.$t('common.errorWithFetchingData') as string);
    } finally {
      this.isOrganizationDetailsLoading = false;
    }
  }

  customKey(node: any) {
    if (node.children && !node.children.length) {
      delete node.children;
    }
    return {
      id: node.id,
      label: node.name,
      children: node.children,
    };
  }

  created() {
    getTimezones().then(({ data }) => (this.timeZoneOptions = data));
    this.fetchOrgMgmtInfos();
  }

  /**
   * Get current organization parent name for subdivision of read only field
   */
  get currentOrgParentName(): string {
    const flatOrgs: Organization[] = flattenOrganizations(
      this.allOrganizations
    );
    const finalOrg = flatOrgs.find(
      (org: Organization) => org.id === this.orgId
    );
    return finalOrg?.parentName || '';
  }
}
</script>

<template>
  <div
    v-loading.lock="isOrganizationDetailsLoading"
    :element-loading-text="$t('userModule.loadingOrganizationData')"
  >
    <el-form
      class="new-account"
      ref="orgForm"
      :model="orgForm"
      :rules="rules"
      :inline-message="inlineMsg"
    >
      <cus-form-item
        :title="'organizationModule.organizationName'"
        :errContent="errNameInfo"
      >
        <el-form-item prop="name">
          <el-input
            id="new_helpdesk_org_input_name"
            v-model="orgForm.name"
            @input="handleInput"
          ></el-input>
        </el-form-item>
      </cus-form-item>

      <cus-form-item
        v-if="!disabledTreeSel"
        :title="'organizationModule.subdivisionOf'"
        :required="true"
      >
        <el-form-item prop="parentId">
          <treeselect
            id="new_helpdesk_org_tree_select"
            :options="orgInfos"
            v-model="orgForm.parentId"
            :normalizer="customKey"
            :disabled="disabledTreeSel"
            v-bind:placeholder="$t('organizationModule.selectSubdivisionOf')"
            @input="handleSelect"
            @open="handleTreeselectOpen"
            @close="handleTreeselectClose"
            :clearable="false"
          />
        </el-form-item>
      </cus-form-item>

      <cus-form-item v-else :title="'organizationModule.subdivisionOf'">
        <el-form-item prop="parentName">
          <el-input
            id="new_helpdesk_org_tree_select"
            v-model="currentOrgParentName"
            :disabled="true"
          ></el-input>
        </el-form-item>
      </cus-form-item>

      <cus-form-item
        :title="'organizationModule.serviceRegions'"
        :required="false"
      >
        <el-form-item prop="serviceRegions">
          <el-select
            class="new-helpdesk-org-multiple-select"
            v-model="orgForm.serviceRegions"
            :popper-append-to-body="false"
            multiple
            value-key="serviceRegionCode"
            filterable
            clearable
          >
            <el-option
              v-for="region in serviceRegionsList"
              :key="region.id"
              :label="region.serviceRegionName"
              :value="region"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </cus-form-item>

      <cus-form-item :title="'customerModule.street'" :required="false">
        <el-form-item>
          <el-input
            id="new_helpdesk_org_input_street"
            v-model="orgForm.street"
            v-bind:placeholder="$t('customerModule.inputStreet')"
          ></el-input>
        </el-form-item>
      </cus-form-item>

      <cus-form-item :title="'customerModule.district'" :required="false">
        <el-form-item>
          <el-input
            id="new_helpdesk_org_input_district"
            v-model="orgForm.district"
            v-bind:placeholder="$t('customerModule.inputDistrict')"
          ></el-input>
        </el-form-item>
      </cus-form-item>

      <cus-form-item :title="'customerModule.province'" :required="false">
        <el-form-item>
          <el-input
            id="new_helpdesk_org_input_province"
            v-model="orgForm.province"
            v-bind:placeholder="$t('customerModule.inputProvince')"
          ></el-input>
        </el-form-item>
      </cus-form-item>

      <cus-form-item :title="'customerModule.city'" :required="false">
        <el-form-item>
          <el-input
            id="new_helpdesk_org_input_city"
            v-model="orgForm.city"
            v-bind:placeholder="$t('customerModule.inputCity')"
          ></el-input>
        </el-form-item>
      </cus-form-item>

      <cus-form-item :title="'customerModule.country'" :required="false">
        <el-form-item prop="country">
          <el-select
            id="new_helpdesk_org_select_country"
            v-model="orgForm.country"
            v-bind:placeholder="$t('customerModule.pleaseSelect')"
            filterable
          >
            <el-option
              v-for="item in countries"
              :key="item.id"
              :label="$t(item.id)"
              :value="item.id"
            />
          </el-select>
        </el-form-item>
      </cus-form-item>

      <cus-form-item :title="'customerModule.postCode'" :required="false">
        <el-form-item>
          <el-input
            id="new_helpdesk_org_input_zipCode"
            v-model="orgForm.zipCode"
          ></el-input>
        </el-form-item>
      </cus-form-item>

      <cus-form-item :title="'customerModule.note'" :required="false">
        <el-form-item>
          <el-input
            id="new_helpdesk_org_input_note"
            v-model="orgForm.note"
          ></el-input>
        </el-form-item>
      </cus-form-item>

      <cus-form-item :title="'organizationModule.timezone'">
        <el-form-item prop="timezone">
          <el-select
            id="new_helpdesk_org_select_timeZone"
            v-model="orgForm.timezone"
            v-bind:placeholder="$t('customerModule.pleaseSelect')"
            filterable
          >
            <el-option
              v-for="item in timeZoneOptions"
              :key="item.id"
              :label="item.fullName"
              :value="item.fullName"
            />
          </el-select>
        </el-form-item>
      </cus-form-item>

      <cus-form-item
        :title="'organizationModule.activationStatus'"
        :required="false"
      >
        <el-form-item>
          <span>{{ $t(orgForm.activationStatus) }}</span>
        </el-form-item>
      </cus-form-item>

      <el-form-item>
        <div class="form-footer">
          <common-btn
            id="new_helpdesk_org_btn_create"
            :content="saveOrCreate ? $t('common.save') : $t('common.create')"
            @handle-btn="saveHelpdeskOrg"
          />
        </div>
      </el-form-item>
    </el-form>
  </div>
</template>

<style lang="scss" scoped></style>
