import { ActiveContext } from '@/auth/context';
import {
  Filter,
  FilterOperator,
  QueryParameter,
} from '@/model/queryParameters/QueryParameter';
import { AssetType } from '@/utils/assetTypes';
import { getRequest } from '@/utils/request';
import { HealthSearchType } from '@/utils/types/health';
import { LANGUAGE, WarningType } from '@/utils/workData/lookuptable';
import { UUID } from './common';
import { CommonResultPromise } from './commonResult';

export interface HealthFactorDefinition {
  id: UUID;
  code: string;
  name: string;
  isErrorFlag: boolean;
  assetType: AssetType;
  enabled: boolean;
  limitAlarmHigh: string;
  limitAlarmLow: string;
  limitWarningHigh: string;
  limitWarningLow: string;
  limitNormal: string;
}

export function getHealthStatusFactorDefinitions(
  assetId: string,
  context: ActiveContext,
  language?: string,
  requestData?: QueryParameter,
  request = getRequest()
): CommonResultPromise<HealthFactorDefinitionResponse[]> {
  const response = request.post<HealthFactorDefinitionResponse[]>(
    `health-status/asset/${assetId}/factor-conditions`,
    requestData,
    {
      params: {
        i18nCode: language,
        selectedCustomer: context?.impersonatedCompanyId,
      },
    }
  );
  return response;
}

export function getHealthStatusGroupedByAssetType(
  assetType: AssetType,
  context: ActiveContext,
  requestData?: QueryParameter,
  language?: LANGUAGE,
  request = getRequest()
): CommonResultPromise<HealthStatusGroupByAssetTypeResponse> {
  const response = request.post<HealthStatusGroupByAssetTypeResponse>(
    `health-status/asset-type/${assetType}/grouped-by-status`,
    requestData,
    {
      params: {
        i18nCode: language,
        selectedCustomer: context?.impersonatedCompanyId,
      },
    }
  );
  return response;
}

export function getHealthFactorDefinitions(
  data: any,
  language?: LANGUAGE
): CommonResultPromise {
  const request = getRequest();
  const response = request.request({
    url: `health-status/factor-definitions`,
    method: 'post',
    data: data,
    params: {
      i18nCode: language,
    },
  });
  return response;
}

export interface FetchHealthFactorDefinitionsParams {
  assetTypes?: AssetType[];
  language?: LANGUAGE;
}

export async function retrieveHealthFactorDefinitions(
  parameters?: FetchHealthFactorDefinitionsParams
): Promise<HealthFactorDefinition[]> {
  const filters: Filter[] = [
    ...(parameters?.assetTypes
      ? [
          {
            name: HealthSearchType.assetType,
            operator: FilterOperator.IN,
            value: parameters?.assetTypes,
          },
        ]
      : []),
  ];
  const request = getRequest();
  const response = await request.post<HealthFactorDefinition[]>(
    `/health-status/factor-definitions`,
    {
      filters,
      params: {
        i18nCode: parameters?.language,
      },
    }
  );

  if (response.code !== 200 || !response.data) {
    throw new Error(
      'invalid response, expected code 200 and data to be defined'
    );
  }

  return response.data;
}

export function putHealthFactorDefinitions(data: any): CommonResultPromise {
  const request = getRequest();
  const response = request.request({
    url: `health-status/factor-definitions`,
    method: 'put',
    data: data,
  });
  return response;
}

export interface HealthStatusGroupByAssetTypeResponse {
  assetsByStatus: {
    assets: {
      assetId: string;
      assetDataCollectionId: string;
      companyAssetId: string;
      productModelPicturePath: string;
      productModelCode: string;
      productModelNumber: string;
      organizationId: string;
      organizationName: string;
      healthStatusLastChangeTimestamp: string;
    }[];
    status: string;
  }[];
  totalAssetCount: number;
}

export interface HealthFactorDefinitionResponse {
  code?: string;
  isErrorFlag?: boolean;
  healthCondition?: WarningType;
  healthConditionDescription?: string;
  lastChangeTimestamp?: string;
  limitCheck?: string;
  name?: string;
}
