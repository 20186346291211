import { distinct } from '@/utils/ref';
import { useWindowSize } from '@vueuse/core';
import { computed, ComputedRef, Ref } from 'vue';

export enum Breakpoint {
  Small = 'sm',
  Large = 'lg',
}

export interface WindowDimension {
  width: number;
  height: number;
  breakpoint: Breakpoint;
}

/**
 * If the returned breakpoint is 'sm' it means that we are on a small screen (MOST of the time mobile or iPad), 'lg' is for laptop/monitors.
 * This is a simplified version of https://m2.material.io/design/layout/responsive-layout-grid.html#breakpoints
 * 'sm' = [0, 768]px
 * 'lg' = [769, Inf)px
 */
export function useWindowDimension(): ComputedRef<WindowDimension> {
  const { width, height } = useWindowSize();

  return computed(
    (): WindowDimension => ({
      // In the future more breakpoints (e.g. 'xs', 'md', 'xl') can be added for granularity
      breakpoint: width.value < 769 ? Breakpoint.Small : Breakpoint.Large,
      width: width.value,
      height: height.value,
    })
  );
}

/**
 * Determines if the window width is below the specified breakpoint.
 * It's equivalent to CSS media queries `@media (max-width: [n]px) { }`
 */
export function useMaxWindowSize(breakpoint: Breakpoint): Ref<boolean> {
  const { width } = useWindowSize();

  return distinct((): boolean => {
    switch (breakpoint) {
      case Breakpoint.Small:
        return width.value < 769;
      case Breakpoint.Large:
        return width.value >= 769;
      default:
        return false;
    }
  });
}
