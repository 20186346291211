import { api } from '@/utils/api';
import { AssetType } from '@/utils/assetTypes';
import { getRequest } from '@/utils/request';
import {
  ApplicabilityType,
  COMPANY_TYPE,
  PACKAGE_TYPE,
  SYSTEM_FEATURES,
  TimeUnit,
  VALUE_TYPE,
} from '@/utils/workData/lookuptable';
import { UUID } from './common';
import { CommonResultPromise } from './commonResult';

export const DEFAULT_SUBSCRIPTION_PACKAGES_REQUEST_SIZE: number = 10000;

export type Subscription = CompanyTypeSubscription | AssetTypeSubscription;

export interface SubscriptionPackage {
  id: UUID;
  name: string;
  note: string;
  type: PACKAGE_TYPE;
  assetType: AssetType;
  code: string;
  featuresCount: number;
}

export interface FetchSubscriptionPackageResponse {
  count: number;
  total: number;
  subscriptionPackages: SubscriptionPackage[];
}

export interface BaseSubscription {
  subscriptionPackageType: PACKAGE_TYPE;
  subscriptionPackageId: UUID;
  subscriptionPackageCode: string;
  subscriptionPackageName: string;
  systemFeatures: SystemFeature[];
}

export interface CompanyTypeSubscription extends BaseSubscription {
  subscriptionPackageType: PACKAGE_TYPE.CompanyType;
  subscriptionPackageCompanyType: COMPANY_TYPE;
}

export interface AssetTypeSubscription extends BaseSubscription {
  subscriptionPackageType: PACKAGE_TYPE.AssetType;
  subscriptionPackageAssetType: AssetType;
}

export interface FetchSystemFeatureResponse {
  id: UUID;
  name: string;
  applicabilityType: ApplicabilityType;
  code: SYSTEM_FEATURES;
  subSystemContext: string;
  valueType: VALUE_TYPE;
}

export interface SystemFeature {
  id: UUID;
  code: SYSTEM_FEATURES;
  valueType: VALUE_TYPE;
  value: string; // Yes, always encoded as a string, even for numbers/booleans
  unit?: TimeUnit; // E.g. UNIT_YEAR
}

export function isAssetTypeSubscription(
  subscription: Subscription
): subscription is AssetTypeSubscription {
  return subscription.subscriptionPackageType === PACKAGE_TYPE.AssetType;
}

export function isCompanyTypeSubscription(
  subscription: Subscription
): subscription is CompanyTypeSubscription {
  return subscription.subscriptionPackageType === PACKAGE_TYPE.CompanyType;
}

export async function getSubscriptionPackages(
  page?: number,
  size?: number
): Promise<FetchSubscriptionPackageResponse> {
  return api().get<FetchSubscriptionPackageResponse>('/subscriptionPackages', {
    params: { page, size },
  });
}

export function getSubscriptionPackageById(
  packageId: string
): CommonResultPromise {
  const request = getRequest();
  const response = request.request({
    url: `/subscriptionPackages/${packageId}`,
    method: 'get',
  });
  return response;
}

export async function getSystemFeatures(): Promise<
  FetchSystemFeatureResponse[]
> {
  return api().get<FetchSystemFeatureResponse[]>('/systemFeatures');
}

export function createSubscriptionPackage(data: any): CommonResultPromise {
  const request = getRequest();
  const response = request.request({
    url: '/subscriptionPackages',
    method: 'post',
    data: data,
  });
  return response;
}

export function updateSubscriptionPackageById(
  packageId: string,
  data: any
): CommonResultPromise {
  const request = getRequest();
  const response = request.request({
    url: `/subscriptionPackages/${packageId}`,
    method: 'put',
    data: data,
  });
  return response;
}

export function deleteSubscriptionPackageById(
  packageId: string
): CommonResultPromise {
  const request = getRequest();
  const response = request.request({
    url: `/subscriptionPackages/${packageId}`,
    method: 'delete',
  });
  return response;
}

export function createCompanySubscriptions(
  companyId: string,
  data: any
): CommonResultPromise {
  const request = getRequest();
  const response = request.request({
    url: `/companySubscription/${companyId}`,
    method: 'post',
    data: data,
  });
  return response;
}

export function updateCompanySubscriptions(
  companyId: string,
  data: any
): CommonResultPromise {
  const request = getRequest();
  const response = request.request({
    url: `/companySubscription/${companyId}`,
    method: 'put',
    data: data,
  });
  return response;
}

export function getCompanySubscriptions(
  companyId: string,
  assetType?: string
): CommonResultPromise<Subscription[]> {
  const request = getRequest();
  const response = request.get(`/companySubscription/${companyId}`, {
    params: {
      assetType,
    },
  });
  return response;
}

export function fetchCompanySubscriptions(
  companyId: UUID,
  assetType?: string
): Promise<Subscription[]> {
  return api().get(`/companySubscription/${companyId}`, {
    params: {
      assetType,
    },
  });
}
