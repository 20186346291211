import {
  VuexModule,
  Module,
  Action,
  Mutation,
  getModule,
} from 'vuex-module-decorators';
import store from '@/store';
import { getTripTrack } from '@/api/trip';
import Vue from 'vue';
import { Trip, TripTrack } from '@/utils/types/trip';

interface ITripState {
  isPlaying: boolean;
  progress: number;
  duration: number;
  speedMultiplier: number;
  seekOffset: number;
  startTickTimestamp: number;
  track: Array<TripTrack>;
}

@Module({ dynamic: true, store, name: 'trip' })
class TripPlayer extends VuexModule {
  /** Private members */
  private isPlaying = false;

  private progress = 0;

  private duration = 0;

  private speedMultiplier = 1;

  private seekOffset = 0;

  private startTickTimestamp: number | null = null;

  private track: TripTrack[] = [];

  private tripId: string | null = null;

  /** Getters */
  get IS_PLAYING(): boolean {
    return this.isPlaying;
  }

  get TRACK(): TripTrack[] {
    return this.track;
  }

  get PROGRESS(): number {
    return this.progress;
  }

  get DURATION(): number {
    return this.duration;
  }

  /** Mutations */
  @Mutation
  private SET_IS_PLAYING(isPlaying: boolean) {
    this.isPlaying = isPlaying;
  }

  @Mutation
  private SET_START_TICK_TIMESTAMP(startTickTimestamp: number | null) {
    this.startTickTimestamp = startTickTimestamp;
  }

  @Mutation
  private SET_DURATION(duration: number) {
    this.duration = duration;
  }

  @Mutation
  private SET_PROGRESS(progress: number) {
    this.progress = progress;
  }

  @Mutation
  private SET_SPEED_MULTIPLIER(speedMultiplier: number) {
    this.speedMultiplier = speedMultiplier;
  }

  @Mutation
  private SET_TRIP_TRACK(track: Array<TripTrack>) {
    Vue.set(this, 'track', track);
  }

  @Mutation
  private SET_SEEK_OFFSET(seekOffset: number) {
    this.seekOffset = seekOffset;
  }

  @Mutation
  private SET_TRIP_ID(tripId: string) {
    this.tripId = tripId;
  }

  /** Actions */
  @Action
  private async nextTick(timestamp: number): Promise<void> {
    return new Promise<void>(async (resolve) => {
      if (!this.isPlaying) {
        resolve();
        return;
      }

      if (this.startTickTimestamp === null) {
        this.SET_START_TICK_TIMESTAMP(timestamp);
      }

      const progress =
        Math.floor((timestamp - (this.startTickTimestamp || 0)) / 1000) *
          this.speedMultiplier +
        this.seekOffset;
      this.SET_PROGRESS(Math.min(this.duration, progress));

      await new Promise((resolve) => setTimeout(resolve, 1000));

      if (this.progress < this.duration) {
        window.requestAnimationFrame(this.nextTick);
      } else {
        this.SET_IS_PLAYING(false);
      }

      resolve();
    });
  }

  @Action
  public async play(): Promise<void> {
    return new Promise<void>((resolve) => {
      this.SET_START_TICK_TIMESTAMP(null);
      this.SET_IS_PLAYING(true);
      window.requestAnimationFrame(this.nextTick);

      resolve();
    });
  }

  @Action
  public async pause(): Promise<void> {
    return new Promise<void>((resolve) => {
      this.SET_IS_PLAYING(false);
      this.SET_SEEK_OFFSET(this.progress);

      resolve();
    });
  }

  @Action
  public async updateProgress(progress: number): Promise<void> {
    return new Promise<void>((resolve) => {
      this.SET_PROGRESS(progress);
      this.SET_SEEK_OFFSET(progress);

      resolve();
    });
  }

  @Action
  public async updateSpeed(speedMultiplier: number): Promise<void> {
    return new Promise<void>(async (resolve) => {
      const playing = this.isPlaying;

      await this.pause();

      this.SET_SPEED_MULTIPLIER(speedMultiplier);

      if (playing) {
        await this.play();
      }

      resolve();
    });
  }

  @Action
  public async loadTrip(trip: Trip): Promise<void> {
    this.SET_TRIP_ID(trip.id);

    return new Promise<void>(async (resolve, reject) => {
      const { data: track } = await getTripTrack(trip.id);
      const duration: number =
        (trip.endTime ?? new Date()).getTime() - trip.startTime.getTime();

      if (this.tripId !== trip.id) {
        return reject();
      }

      this.SET_DURATION(duration > 0 ? duration / 1000 : 0);
      this.SET_TRIP_TRACK(track);
      await this.updateProgress(duration);

      resolve();
    });
  }
}

export const TripModule = getModule(TripPlayer);
