<script lang="ts">
import { LOCALDATE_FORMAT } from '@/utils/time';
import { CalendarCell } from '@progress/kendo-vue-dateinputs';
import moment from 'moment';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'CustomCalendarCell',
  props: {
    dataItem: {
      type: Number,
      required: false,
    },
    isWeekend: {
      type: Boolean,
      required: false,
    },
    isFocused: {
      type: Boolean,
      required: false,
    },
    isSelected: {
      type: Boolean,
      required: false,
    },
    isToday: {
      type: Boolean,
      required: false,
    },
    value: {
      type: Date,
      required: false,
    },
    formattedValue: {
      type: String,
      required: false,
    },
    appointmentsDates: {
      type: Array,
      required: false,
    },
  },
  components: {
    'calendar-cell': CalendarCell,
  },
  emits: {
    click: null,
  },
  computed: {
    cellStyle: function () {
      return {
        cursor: 'pointer',
        opacity: this.isWeekend ? 0.5 : 1,
        fontWeight: this.isToday ? '900' : '',
      };
    },
    cellClass: function () {
      return {
        'k-state-selected': this.isSelected,
        'k-state-focused': this.isFocused,
      };
    },
    dotClass: function () {
      /* @ts-expect-error TODO Wrong type */
      if (this.$parent?.activeView === 3) {
        return {
          'dot-full': this.appointmentsDates?.some((ad) => {
            /* @ts-expect-error TODO Unknown type */
            return ad.startsWith(
              moment(this.value).format(LOCALDATE_FORMAT).substring(0, 3)
            );
          }),
        };
        /* @ts-expect-error TODO Wrong type */
      } else if (this.$parent?.activeView === 2) {
        return {
          'dot-full': this.appointmentsDates?.some((ad) => {
            /* @ts-expect-error TODO Unknown type */
            return ad.startsWith(
              moment(this.value).format(LOCALDATE_FORMAT).substring(0, 4)
            );
          }),
        };
        /* @ts-expect-error TODO Wrong type */
      } else if (this.$parent?.activeView === 1) {
        return {
          'dot-full': this.appointmentsDates?.some((ad) => {
            /* @ts-expect-error TODO Unknown type */
            return ad.startsWith(
              moment(this.value).format(LOCALDATE_FORMAT).substring(0, 7)
            );
          }),
        };
      }
      return {
        'dot-full': this.appointmentsDates?.includes(
          moment(this.value).format(LOCALDATE_FORMAT)
        ),
      };
    },
  },
  methods: {
    handleClick: function (e: Event) {
      this.$emit('click', this.value, e);
    },
  },
});
</script>

<template>
  <calendar-cell
    :is-selected="isSelected"
    @click="handleClick"
    :style="cellStyle"
    :class="['k-calendar-td', cellClass]"
  >
    {{ formattedValue }}
    <span class="dot-cell" :class="dotClass"></span>
  </calendar-cell>
</template>

<style scoped>
.k-link {
  position: relative;
  display: block;
}

.k-link:hover {
  color: black !important;
}

.dot-full {
  position: absolute;
  margin-left: 0.5px;
  margin-top: 23px;
  border-radius: 50%;
  height: 5px;
  width: 5px;
  background: #da0d04;
  z-index: 10000;
}

.k-state-selected :deep(.k-link) {
  background-color: var(--Main) !important;
}
</style>
