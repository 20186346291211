/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon';
icon.register({
  EVNSEV_INFORMATION: {
    width: 19,
    height: 19,
    viewBox: '0 0 19 19',
    data: '<svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M9.035 0.5C6.65085 0.499928 4.36331 1.44221 2.67091 3.12149C0.978519 4.80078 0.0184716 7.08092 0 9.465C0.0170182 11.856 0.974382 14.1442 2.6651 15.8349C4.35581 17.5256 6.64403 18.483 9.035 18.5C11.4219 18.5 13.7111 17.5518 15.399 15.864C17.0868 14.1761 18.035 11.8869 18.035 9.5C18.035 7.11305 17.0868 4.82387 15.399 3.13604C13.7111 1.44821 11.4219 0.5 9.035 0.5V0.5ZM10.09 14.281C10.0816 14.555 9.96676 14.815 9.76993 15.0059C9.57309 15.1968 9.30968 15.3035 9.0355 15.3035C8.76132 15.3035 8.49791 15.1968 8.30107 15.0059C8.10424 14.815 7.98944 14.555 7.981 14.281V8.938C7.98944 8.66395 8.10424 8.40395 8.30107 8.21308C8.49791 8.02222 8.76132 7.91548 9.0355 7.91548C9.30968 7.91548 9.57309 8.02222 9.76993 8.21308C9.96676 8.40395 10.0816 8.66395 10.09 8.938V14.281ZM9.035 5.773C8.8263 5.773 8.62228 5.7111 8.44877 5.59513C8.27525 5.47916 8.14003 5.31433 8.06021 5.1215C7.98039 4.92867 7.95956 4.71649 8.00035 4.51181C8.04113 4.30713 8.14171 4.11915 8.28936 3.97165C8.437 3.82414 8.62508 3.72374 8.82979 3.68315C9.03451 3.64256 9.24667 3.66359 9.43942 3.74359C9.63218 3.8236 9.79688 3.95898 9.91269 4.1326C10.0285 4.30623 10.0902 4.5103 10.09 4.719C10.0895 4.99855 9.97812 5.26648 9.78035 5.46406C9.58259 5.66164 9.31455 5.77274 9.035 5.773Z" fill="#6494E2"/></svg>',
  },
});

icon.register({
  EVNSEV_WARNING: {
    width: 17,
    height: 20,
    viewBox: '0 0 17 20',
    data: '<svg width="17" height="20" viewBox="0 0 17 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M15.7618 13.5815C15.1848 13.0948 14.7211 12.4878 14.4033 11.803C14.0855 11.1183 13.9211 10.3724 13.9218 9.6175V7.4615C13.9194 6.1609 13.4492 4.90456 12.5971 3.92195C11.7451 2.93933 10.5679 2.29602 9.28079 2.1095V1.2735C9.28079 1.06836 9.19929 0.871612 9.05423 0.726553C8.90917 0.581494 8.71243 0.5 8.50729 0.5C8.30214 0.5 8.1054 0.581494 7.96034 0.726553C7.81528 0.871612 7.73379 1.06836 7.73379 1.2735V2.1085C6.44663 2.29502 5.26952 2.93833 4.41744 3.92095C3.56536 4.90356 3.09519 6.1599 3.09279 7.4605V9.6175C3.09307 10.3739 2.92789 11.1212 2.60882 11.807C2.28976 12.4928 1.82454 13.1005 1.24579 13.5875C1.03565 13.7673 0.885665 14.0072 0.816037 14.2749C0.74641 14.5426 0.760486 14.8251 0.856366 15.0845C0.952247 15.344 1.12533 15.5678 1.35229 15.7258C1.57926 15.8839 1.84921 15.9686 2.12579 15.9685H14.8878C15.1654 15.9688 15.4364 15.8837 15.664 15.7248C15.8916 15.5659 16.0648 15.3408 16.1601 15.0801C16.2554 14.8193 16.2682 14.5356 16.1968 14.2673C16.1254 13.9991 15.9731 13.7593 15.7608 13.5805L15.7618 13.5815Z" fill="#F79B3F"/><path d="M8.50775 19.0625C9.17604 19.0617 9.82363 18.8305 10.3413 18.4079C10.859 17.9853 11.2152 17.3971 11.3498 16.7425H5.66675C5.80042 17.3974 6.1562 17.9859 6.67389 18.4087C7.19157 18.8314 7.83938 19.0624 8.50775 19.0625Z" fill="#F79B3F"/></svg>',
  },
});

icon.register({
  EVNSEV_ALARM: {
    width: 18,
    height: 16,
    viewBox: '0 0 18 16',
    data: '<svg width="18" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M17.4625 13.8406L9.73349 1.02264C9.63754 0.863196 9.50198 0.731279 9.33999 0.639702C9.178 0.548125 8.99507 0.5 8.80899 0.5C8.6229 0.5 8.43998 0.548125 8.27799 0.639702C8.11599 0.731279 7.98044 0.863196 7.88449 1.02264L0.155487 13.8406C0.0565737 14.0043 0.00295354 14.1914 0.000118561 14.3826C-0.00271642 14.5739 0.0453357 14.7625 0.139353 14.929C0.23337 15.0956 0.369973 15.2342 0.53517 15.3306C0.700367 15.427 0.888219 15.4777 1.07949 15.4776H16.5385C16.7298 15.4777 16.9176 15.427 17.0828 15.3306C17.248 15.2342 17.3846 15.0956 17.4786 14.929C17.5726 14.7625 17.6207 14.5739 17.6179 14.3826C17.615 14.1914 17.5614 14.0043 17.4625 13.8406ZM8.81449 4.93563C8.91464 4.92325 9.01627 4.93174 9.11298 4.96057C9.20969 4.9894 9.29938 5.03794 9.37641 5.10313C9.45344 5.16832 9.51614 5.24876 9.56055 5.33937C9.60497 5.42998 9.63014 5.52881 9.63449 5.62963C9.63449 6.98363 9.47549 8.92963 9.47549 10.2846C9.47549 10.6376 9.08849 10.7846 8.81549 10.7846C8.45149 10.7846 8.14349 10.6366 8.14349 10.2846C8.14349 8.93063 7.98449 6.98463 7.98449 5.62963C7.98449 5.18463 8.34849 4.93563 8.81449 4.93563ZM8.82549 13.3236C8.65178 13.3258 8.48134 13.2763 8.33585 13.1814C8.19035 13.0865 8.07636 12.9504 8.00837 12.7906C7.94038 12.6307 7.92145 12.4542 7.954 12.2836C7.98655 12.1129 8.0691 11.9558 8.19116 11.8322C8.31322 11.7086 8.46928 11.6241 8.6395 11.5894C8.80972 11.5547 8.98642 11.5714 9.14713 11.6373C9.30784 11.7033 9.4453 11.8155 9.54206 11.9598C9.63881 12.1041 9.69047 12.2739 9.69049 12.4476C9.68672 12.6766 9.59482 12.8954 9.4339 13.0583C9.27298 13.2213 9.05542 13.316 8.82649 13.3226L8.82549 13.3236Z" fill="#BE442D"/></svg>',
  },
});
