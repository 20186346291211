<script lang="ts" setup>
import { computed } from 'vue';
import { RouteConfig } from 'vue-router';

const props = defineProps<{
  route: RouteConfig;
  basePath: string;
  collapsed: boolean;
}>();
const emit = defineEmits<{
  (e: 'collapse'): void;
}>();

const alwaysShowRootMenu = computed(() =>
  Boolean(props.route.meta?.alwaysShow)
);
const childrenCount = computed(() => {
  if (!props.route.children) {
    return 0;
  }

  const children = props.route.children.filter((c) => !c.meta?.hidden);

  return children.length;
});
const onlyChild = computed(() => {
  if (childrenCount.value > 1) {
    return;
  }

  const child = props.route.children?.find((c) => !c.meta?.hidden);

  return (
    child ?? {
      ...props.route,
      path: '',
    }
  );
});

const sortedChildren = computed(() => {
  const children: RouteConfig[] = props.route.children ?? [];
  return sortAscending(children);
});

function sortAscending(children: RouteConfig[]): RouteConfig[] {
  return children.sort((a: RouteConfig, b: RouteConfig) => {
    if (!a.name || !b.name) {
      return 0;
    }
    return a.name.localeCompare(b.name);
  });
}

function resolvePath(path: string) {
  return `${props.basePath}${
    props.basePath.endsWith('/') || !path ? '' : '/'
  }${path}`;
}
</script>

<template>
  <div v-if="!route.meta?.hidden">
    <template v-if="!alwaysShowRootMenu && onlyChild && !onlyChild.children">
      <el-menu-item
        v-if="onlyChild.meta"
        :index="resolvePath(onlyChild.path)"
        @click="emit('collapse')"
      >
        <svg-icon
          v-if="onlyChild.meta.icon"
          :name="onlyChild.meta.icon"
          class="nav-icon"
          scale="1.05"
        />
        <span>{{ $t(onlyChild.meta.title) }}</span>
      </el-menu-item>
    </template>
    <el-submenu v-else :index="resolvePath(props.route.path)">
      <template slot="title">
        <svg-icon
          v-if="props.route.meta?.icon"
          :name="props.route.meta.icon"
          class="nav-icon"
          scale="1.05"
        />
        <span style="margin-right: 1.5rem">{{
          $t(props.route.meta?.title)
        }}</span>
      </template>
      <template v-if="props.route.children">
        <SidebarItem
          v-for="(child, index) in sortedChildren"
          :key="index"
          :route="child"
          :base-path="resolvePath(child.path)"
          :collapsed="props.collapsed"
          @collapse="emit('collapse')"
        />
      </template>
    </el-submenu>
  </div>
</template>

<style lang="scss" scoped>
.nav-icon {
  margin-right: 1.5rem;
}

:deep(.el-submenu__title):hover {
  background-color: var(--DropdownHover);
}

:deep(.el-submenu__icon-arrow) {
  // Element-UI has set `margin-top: -7px` to center the icon - but that's not right in our case.
  // Having a flexbox, with `align-items: center` doesn't work either, because it ruins the animation when we close the sidebar.
  margin-top: -3px;
}

.el-menu-item {
  &:hover,
  &:focus {
    background-color: var(--DropdownHover);
  }

  &.is-active {
    color: inherit;
    background-color: var(--Main);
  }
}
</style>
