import { isAssetTypeSubscription } from '@/api/subscriptionPackages';
import { GenericWidget, IncludedWidget } from '@/api/widgets';
import {
  equalOrContainsAny,
  equalOrContainsAnyClaim,
} from '@/store/modules/permission';
import { UserModule } from '@/store/modules/user';
import Vue from 'vue';
import { WidgetCodeItem, WIDGET_PERMISSIONS_MAP } from './workData/utilMap';
import { WidgetKey } from './workData/widgetCodeMap';

export const NUMBER_OF_GRID_COLUMNS = 12;

export enum RowSizeType {
  Fixed,
  Dynamic,
}

export function generateRowSizeTypes(
  items: { y: number; h: number; isDynamicHeight: boolean }[]
): RowSizeType[] {
  const numberOfGridRows = items.reduce((soFar, widget) => {
    return Math.max(soFar, widget.y + widget.h);
  }, 0);
  const autoRows = Array(numberOfGridRows).fill(false);
  for (const widget of items) {
    for (let i = 0; i < widget.h; i++) {
      autoRows[widget.y + i] = widget.isDynamicHeight
        ? RowSizeType.Dynamic
        : RowSizeType.Fixed;
    }
  }
  return autoRows;
}

export function filterWidgetsByPermissions<
  T extends { pageCode: string; code: WidgetKey }
>(widgets: T[]) {
  return widgets.filter((widget) => {
    const permissions = WIDGET_PERMISSIONS_MAP.get(widget.code);

    if (!permissions) {
      return true;
    }

    let subscriptions = UserModule.subscriptions;

    if (permissions.systemFeature?.assetType) {
      subscriptions = subscriptions
        .filter(isAssetTypeSubscription)
        .filter(
          (s) =>
            s.subscriptionPackageAssetType ===
            permissions.systemFeature?.assetType
        );
    }

    const systemFeatures = subscriptions
      .flatMap((s) => s.systemFeatures)
      .filter((s) => s.value === 'true')
      .map((s) => s.code);

    return (
      equalOrContainsAnyClaim(permissions.claims, UserModule.claims) &&
      equalOrContainsAny(permissions.systemFeature?.featureCode, systemFeatures)
    );
  });
}

export function isWidgetConfig(
  item: WidgetCodeItem | typeof Vue
): item is WidgetCodeItem {
  return Boolean((item as any)['*']);
}

export function calculateWidgetGridLayout(
  allWidgets: GenericWidget[],
  currentLayout: IncludedWidget[],
  newLayoutCodes: string[],
  options: { columns: number; columnWidth: number }
): IncludedWidget[] {
  if (newLayoutCodes.length < currentLayout.length) {
    return currentLayout.filter((w) => newLayoutCodes.includes(w.code));
  }

  const added = newLayoutCodes.find(
    (w) => !currentLayout.some((i) => w === i.code)
  );
  const widget = allWidgets.find((w) => w.code === added);

  if (!widget) {
    return currentLayout;
  }

  const candidate = { w: widget.w, h: widget.h };
  const occupants = currentLayout.map((o) => ({
    x: o.x,
    y: o.y,
    w: o.w,
    h: o.h,
  }));
  const targetPos = findAvailableGridSpace(candidate, occupants, {
    gridWidth: options.columns * options.columnWidth,
    columnWidth: options.columnWidth,
  });
  const newWidget: IncludedWidget = {
    ...widget,
    x: targetPos.x,
    y: targetPos.y,
    w: widget.w,
    minW: widget.minW,
    maxW: widget.maxW,
  };
  return [...currentLayout, newWidget];
}

export function scaleHorizontal<
  T extends { x: number; w: number; minW: number; maxW: number }
>(items: T[], currentWidth: number, newWidth: number): T[] {
  return items.map((item: T) => ({
    ...item,
    x: (item.x * newWidth) / currentWidth,
    w: (item.w * newWidth) / currentWidth,
    minW: (item.minW * newWidth) / currentWidth,
    maxW: (item.maxW * newWidth) / currentWidth,
  }));
}

export interface GridCandidate {
  w: number;
  h: number;
}

export interface GridArea {
  x: number;
  y: number;
  w: number;
  h: number;
}

export interface GridPosition {
  x: number;
  y: number;
}

export function findAvailableGridSpace(
  candidate: GridCandidate,
  occupants: GridArea[],
  options: { gridWidth: number; columnWidth?: number }
): GridPosition {
  const occupationGrid = getOccupationGrid(occupants, options);
  // Loop until y+1 so that a free line at the bottom is always available
  for (let y = 0; y < occupationGrid.height + 1; y++) {
    for (let x = 0; x < occupationGrid.width; x += options.columnWidth ?? 1) {
      const position = { x, y };
      if (checkFit(candidate, position, occupationGrid)) {
        return position;
      }
    }
  }
  return { x: 0, y: 0 };
}

/**
 * A grid data structure that stores whether a cell is occupied.
 */
class OccupationGrid {
  private data: boolean[][];
  constructor(readonly width: number, readonly height: number) {
    this.data = [];
    for (var y: number = 0; y < height; y++) {
      this.data[y] = [];
      for (var x: number = 0; x < width; x++) {
        this.data[y][x] = false;
      }
    }
  }
  isBelow = (pos: GridPosition) => pos.y >= this.height;
  isInside = (pos: GridPosition) =>
    pos.x >= 0 && pos.x < this.width && pos.y >= 0 && pos.y < this.height;
  isOccupied = (pos: GridPosition) => this.data[pos.y][pos.x];
  occupy = (pos: GridPosition) => {
    this.data[pos.y][pos.x] = true;
  };
}

function getOccupationGrid(
  occupants: GridArea[],
  options: { gridWidth: number }
): OccupationGrid {
  const requiredHeight = findHeight(occupants);
  const grid = new OccupationGrid(options.gridWidth, requiredHeight);
  occupants.forEach((o) => addOccupantToGrid(o, grid));
  return grid;
}

function findHeight(occupants: GridArea[]): number {
  return occupants.reduce(
    (runningMax, o) => Math.max(o.y + o.h, runningMax),
    0
  );
}

function addOccupantToGrid(occupant: GridArea, grid: OccupationGrid) {
  for (let h = 0; h < occupant.h; h++) {
    for (let w = 0; w < occupant.w; w++) {
      grid.occupy({ x: occupant.x + w, y: occupant.y + h });
    }
  }
}

function checkFit(
  candidate: GridCandidate,
  targetTopLeft: GridPosition,
  grid: OccupationGrid
): boolean {
  // Iterate over the size of the candidate, to see if all required cells are available
  for (let candidateY = 0; candidateY < candidate.h; candidateY++) {
    for (let candidateX = 0; candidateX < candidate.w; candidateX++) {
      const checkPos = {
        x: targetTopLeft.x + candidateX,
        y: targetTopLeft.y + candidateY,
      };
      if (grid.isBelow(checkPos)) {
        // Position is below grid, which can expand freely, so this fits
        return true;
      }
      if (!grid.isInside(checkPos)) {
        // Position is outside
        return false;
      }
      if (grid.isOccupied(checkPos)) {
        return false;
      }
    }
  }
  return true;
}
