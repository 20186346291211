<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
  name: 'CommFormInput',
})
export default class extends Vue {
  @Prop({ default: '' }) value!: string;
  @Prop() placeholder!: string;

  inputChange(val: string) {
    this.$emit('input', val);
  }

  get modelValue() {
    return this.value;
  }

  set modelValue(newValue: string) {
    this.$emit('el-input', newValue);
  }
}
</script>

<template>
  <div>
    <el-input
      class="mod-input"
      :value="value"
      @input="inputChange"
      :placeholder="placeholder"
    ></el-input>
  </div>
</template>

<style scoped>
.mod-input :deep(.el-select__caret) {
  color: #000;
  font-size: 20px;
}

.mod-input :deep(.el-input__inner) {
  height: 50px;
  /* width: 31vw; */
  width: 600px;
  border: 1px solid #707070 !important;
  font-size: 1.285714rem;
  font-family: var(--fontRobotoRegular);
  line-height: 1.357143rem;
  color: #373e41;
  opacity: 1;
}
</style>
