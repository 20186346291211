<script setup lang="ts">
import { formatNumber } from '@/utils/number';
import { FleetPerformanceTableData } from '@/widgets/fleet/expanded/FleetPerformanceExpanded.vue';

const props = withDefaults(
  defineProps<{
    tableData: FleetPerformanceTableData[];
    isSelected: (row: any) => boolean;
  }>(),
  {
    isSelected: () => () => false,
  }
);

function getRowClass({ row }: any) {
  return props.isSelected(row) ? 'highlighted' : undefined;
}
</script>

<template>
  <el-table :data="tableData" :row-class-name="getRowClass" height="100%">
    <el-table-column
      prop="assetId"
      :label="$t('fleetPerformance.assetId')"
      sortable
    />
    <el-table-column
      prop="trips"
      :label="$t('fleetPerformance.trips')"
      sortable
    />
    <el-table-column
      prop="loadPerHour"
      :label="`${$t('KPI.Payload')}/${$t('UNIT_HOUR')} (${$t(
        'tippingVehicles.assetOverview.units.UNIT_METRIC_TONNE'
      )})`"
      sortable
    >
      <template v-slot="props">
        {{ formatNumber(props.row.loadPerHour) }}
      </template>
    </el-table-column>
    <el-table-column
      prop="avgTripTime"
      :label="`${$t('fleetPerformance.avgTripTime')} (${$t(
        'common.min'
      ).toLowerCase()})`"
      sortable
    >
      <template v-slot="props">
        {{ formatNumber(props.row.avgTripTime) }}
      </template>
    </el-table-column>
  </el-table>
</template>

<style lang="scss" scoped>
:deep(.highlighted) {
  background-color: var(--DropdownHover);
}

.el-table .selected {
  background: #ffeb99;
}

@media (min-width: 1422px) {
  :deep(.el-table__cell) {
    border-top: 0px;
  }
}
</style>
