<script setup lang="ts">
import {
  AvailableSafetyEventType,
  getAvailableSafetyEvents,
  getTripEventsSummaryByTime,
  SafetyEventType,
} from '@/api/trip';
import { useActiveContext } from '@/auth/context';
import PeriodSelector from '@/components/form/PeriodSelector.vue';
import VerticalStackedBarChart from '@/components/kpiCharts/VerticalStackedBarChart.vue';
import WidgetCard from '@/components/layout/widget/WidgetCard.vue';
import { useAsync } from '@/composables/async';
import { useRoute } from '@/composables/router';
import i18n from '@/lang';
import {
  ASSET_SAFETY_CODE,
  expandedSingleAssetSafetyLocation,
  FLEET_SAFETY_CODE,
} from '@/router/links/safetyWidget';
import { UserModule } from '@/store/modules/user';
import { AssetType } from '@/utils/assetTypes';
import { never } from '@/utils/promise';
import {
  capitalizeFirstLetter,
  capitalizeFirstLetterOfTheWorldsInString,
} from '@/utils/string';
import { DEFAULT_PERIOD_RANGE } from '@/utils/time';
import { DatePeriod, PeriodRange } from '@/utils/types/date';
import { AggregationType } from '@/widgets/utils/Constants';
import {
  defaultSafetyWidgetTitle,
  TripEventSummaries,
} from '@/widgets/utils/tripSafety';
import { computed, ref, unref, watchEffect } from 'vue';
import { Location } from 'vue-router';

const route = useRoute();

const activeContext = useActiveContext();

const selectedPeriodRange = ref<PeriodRange>(DEFAULT_PERIOD_RANGE);

/* --------------- Available safety events by company type --------------- */

const availableSafetyEventTypes = useAsync(
  computed(
    async (): Promise<AvailableSafetyEventType[]> =>
      getAvailableSafetyEvents(UserModule.companyType, unref(activeContext))
  )
);

const selectedEventType = ref<SafetyEventType>();

// Select default event type after loading available event types for this company type.
watchEffect(() => {
  if (unref(selectedEventType) === undefined) {
    const defaultEventType = unref(availableSafetyEventTypes).data?.find(
      (availableEventType) => availableEventType.isDefault
    )?.eventTypeCode;
    if (defaultEventType) {
      selectedEventType.value = defaultEventType;
    }
  }
});

/* --------------- Trips: Events summary by time --------------- */

const tripEventSummariesByTime = useAsync(
  computed(async (): Promise<TripEventSummaries> => {
    const periodRange = unref(selectedPeriodRange);
    const eventType = unref(selectedEventType);

    if (
      !periodRange ||
      periodRange.datePeriod === undefined ||
      !eventType ||
      !unref(route)?.params.id
    ) {
      return never();
    }
    if (periodRange.datePeriod !== DatePeriod.DATP_DAY) {
      throw new Error(`Unsupported DatePeriod ${periodRange.datePeriod}`);
    }

    return {
      aggregationType: AggregationType.AggregateByTime,
      summaries: await getTripEventsSummaryByTime(
        {
          selectedDatePeriod: periodRange.datePeriod,
          eventTypeCode: eventType,
          datePeriodStartDate: periodRange.start,
          datePeriodEndDate: periodRange.endExclusive,
          assetUUIDs: route.value?.params.id!,
          assetTypeCode: AssetType.TippingVehicle,
        },
        unref(activeContext)
      ),
    };
  })
);

/* --------------- Handle Period selector as filter --------------- */

function handlePeriodFilter(newPeriodRange: PeriodRange): void {
  selectedPeriodRange.value = newPeriodRange;
}

/* --------------- ^ END of: Handle Period selector as filter ^ --------------- */

/* --------------- Default widget title ---------------  */

/**
 * Compute if current grouping scope (fleet/asset) has:
 * - one safety event then: Fleet/Asset <event> e.g: Fleet Overload for TruckOM customer type
 * - has more safety events then initial translation title: "Fleet/Asset Safety" e.g: ("Fleet Safety" or "Asset Safety")
 */
const defaultWidgetTitle = computed((): string => {
  const availableEvents = unref(availableSafetyEventTypes).data;
  const isAssetScope = unref(route)?.params?.id ? true : false;
  if (
    availableEvents &&
    availableEvents?.length === 1 &&
    availableEvents?.[0]?.eventTypeCode
  ) {
    const finalResult = defaultSafetyWidgetTitle(
      availableEvents?.[0]?.eventTypeCode,
      isAssetScope
    );

    return capitalizeFirstLetterOfTheWorldsInString(finalResult);
  }

  return isAssetScope ? i18n.tc(ASSET_SAFETY_CODE) : i18n.tc(FLEET_SAFETY_CODE);
});

const expandedLink = computed((): Location | undefined => {
  const selectedSafetyEvent = unref(selectedEventType);
  if (!selectedSafetyEvent) return undefined;

  const periodRange = unref(selectedPeriodRange);

  return expandedSingleAssetSafetyLocation(
    route.value?.params.id,
    selectedSafetyEvent,
    periodRange
  );
});
</script>

<template>
  <WidgetCard
    :default-title="defaultWidgetTitle"
    :title-is-loading="availableSafetyEventTypes.loading"
    :loading="
      availableSafetyEventTypes.loading || tripEventSummariesByTime.loading
    "
    :expandable="true"
    :expandedLink="expandedLink"
  >
    <div id="new-fleet-safety-widget" class="new-fleet-safety-widget">
      <div class="header-container">
        <el-select
          v-model="selectedEventType"
          class="select-safety-event-types header-item"
        >
          <el-option
            v-for="item in availableSafetyEventTypes.data ?? []"
            :key="item.eventTypeCode"
            :label="capitalizeFirstLetter($tc(item.eventTypeCode))"
            :value="item.eventTypeCode"
          />
        </el-select>
        <PeriodSelector
          class="header-item period-selector"
          :expanded="true"
          @select="handlePeriodFilter"
          :customizable="true"
          :dropDownIsDisabled="true"
        />
      </div>
      <div class="vertical-stacked-bar-chart-container">
        <VerticalStackedBarChart
          :safetyEvent="selectedEventType"
          :data="tripEventSummariesByTime.data"
          :aggregationType="AggregationType.AggregateByTime"
        />
      </div>
    </div>
  </WidgetCard>
</template>

<style lang="scss" scoped>
.new-fleet-safety-widget {
  height: 100%;
  overflow: hidden;

  .vertical-stacked-bar-chart-container {
    height: 380px;
    padding: 0 10px 0px 10px;
  }
}

.header-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 10px;
  padding: 4px 0 0 10px;

  .select-safety-event-types {
    width: 170px;
    margin-right: 5%;
  }

  .period-selector {
    align-items: center;
  }
}

.chart-range-buttons {
  display: flex;
}

@media screen and (max-width: 1756px) {
  .header-item {
    margin: 5px 0 5px 0;
  }
}
</style>
