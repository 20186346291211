<script lang="ts">
import { Geofence, GeofenceAsset, GeofencePosition } from '@/api/geofenceTypes';
import PointerIcon from '@/assets/imgs/mapPointerIcon.svg';
import MessageBox from '@/components/dialog/MessageBox.vue';
import LocationIcon from '@/icons/svg/location.svg';
import { AssetType } from '@/utils/assetTypes';
import { getBounds } from '@/utils/geofence';
import { Position } from '@/utils/types/geoposition';
import { Trip, TripEvent, TripTrack } from '@/utils/types/trip';
import { eventTypeIcons } from '@/utils/workData/lookuptable';
import L, {
  divIcon,
  DomEvent,
  DomUtil,
  icon,
  LatLng,
  LatLngBounds,
  marker,
  Polygon,
  Polyline,
  polyline,
  tooltip,
} from 'leaflet';
import 'leaflet-draw';
import 'leaflet-draw/dist/leaflet.draw-src.css';
import GeometryUtils from 'leaflet-geometryutil';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import {
  LControl,
  LControlZoom,
  LMap,
  LMarker,
  LPolyline,
  LTileLayer,
  LTooltip,
} from 'vue2-leaflet';
import LeafletMapAssets from './LeafletMapAssets.vue';
import LeafletMapEvents from './LeafletMapEvents.vue';

@Component({
  name: 'LeafletMap',
  components: {
    LMap,
    LMarker,
    LTileLayer,
    LTooltip,
    LPolyline,
    LControlZoom,
    LControl,
    LeafletMapAssets,
    LeafletMapEvents,
    MessageBox,
  },
})
export default class extends Vue {
  @Prop({ required: true }) zoom!: number;
  @Prop({ required: false }) center!: number[];
  @Prop() assets!: GeofenceAsset[];
  @Prop() events!: any[];
  @Prop() selectedAsset!: string;
  @Prop() selectedAssetId!: string;
  @Prop({ required: false }) bounds!: number[][] | undefined;
  @Prop() geofenceConfig!: {
    enableCreate: boolean;
    editOnly: boolean;
    showLifeCycleStatus: boolean;
  };
  @Prop() tripConfig!: {
    enableTrip: boolean;
  };
  @Prop() enableZoom?: boolean;

  routesWithDrawControl = ['createNewGeofence', 'editGeofence'];
  showLocationPermissionDeniedModal: boolean = false;
  locationIcon = LocationIcon;

  @Watch('selectedAssetId')
  handleSelectedAssetId(newId: string) {
    if (newId) {
      this.selectedAssetIdForMap = newId;
      this.clusteredAssets = this.assets.filter(
        (asset: GeofenceAsset) => asset.id !== newId
      );
      this.unclusteredSelectedAsset = this.assets.filter(
        (asset: GeofenceAsset) => asset.id === newId
      );
    }
  }

  @Watch('assets')
  handleAssetList(newAssetList: GeofenceAsset[]) {
    if (newAssetList) {
      this.clusteredAssets = newAssetList;
    }
  }

  clusteredAssets: GeofenceAsset[] = [];
  unclusteredSelectedAsset: GeofenceAsset[] = [];
  selectedAssetIdForMap: string = '';
  isLoading = true;
  coordinates: L.LatLng[] = [];
  url: string | undefined = Vue.prototype.$envConfig.VUE_APP_MAP_URL;
  geofenceFeatureGroup: L.FeatureGroup | null = null;
  tripFeatureGroup: L.FeatureGroup | null = null;
  options: L.MapOptions = {
    zoomControl: false,
    scrollWheelZoom: this.enableZoom ?? true,
  };
  colors = [
    {
      key: 'GFNLCL_ACTIVE',
      value: 'green',
    },
    {
      key: 'GFNLCL_DRAFT',
      value: 'orange',
    },
    {
      key: 'GFNLCL_DEPRECATED',
      value: 'gray',
    },
  ];
  defaultZoomForIMAP: number = 9; // used for outside of china to see at least the first level of details in zoomed map tile
  mapZoom: number = this.initializeMapZoom ?? this.defaultZoomForIMAP;
  iconSize = new L.Point(44, 44);
  // Anchoring is necessary to make sure that the icon is pointing to the same coordinate at every zoom level
  iconAnchor = new L.Point(this.iconSize.x / 2, this.iconSize.y * 1.15);

  $refs!: {
    map: LMap;
    mapAssets: LeafletMapAssets;
  };

  created() {
    this.clusteredAssets = this.assets;
  }

  async mounted() {
    if (this.tripConfig && this.tripConfig.enableTrip) {
      this.initializeTrip();
    }
  }

  /**
   * For create new geofence, when initializing IMAP for china, give a default zoom to see at least the tile level details
   * Otherwise use passed in zoom prop
   * @return number
   */
  get initializeMapZoom(): number {
    return this.checkIfIMAPisUsed && this.$route.name == 'createNewGeofence'
      ? this.defaultZoomForIMAP
      : this.zoom;
  }

  get checkIfIMAPisUsed(): boolean {
    return this.url && !this.url.includes('hereapi') ? true : false;
  }

  private initializeTrip() {
    this.tripFeatureGroup = new L.FeatureGroup().addTo(
      this.$refs.map.mapObject
    );
  }

  onMapReady() {
    this.fixMapSize();
  }

  /**
   * Fix the map rendering.
   * If you don't use this,
   * it will render gray squares.
   */
  async fixMapSize() {
    this.$refs.map.mapObject.invalidateSize();
  }

  onMyLocationClick() {
    this.$refs.map.mapObject.locate({ setView: true });
  }

  // Leaflet detects if the user declines the location permission, and fires a `locationerror` event
  onLocationPermissionDenied() {
    this.showLocationPermissionDeniedModal = true;
  }

  navigateToAssetLocation(latlng: L.LatLngExpression) {
    this.$refs.map.mapObject.flyTo(latlng, 16, { duration: 1.5 });
  }

  initializeGeofence(geofences: Geofence[]) {
    this.geofenceFeatureGroup?.clearLayers();

    this.geofenceFeatureGroup = new L.FeatureGroup().addTo(
      this.$refs.map.mapObject
    );

    const options: L.Control.DrawConstructorOptions = {
      position: 'topright',
      draw: {
        polygon: this.geofenceConfig.enableCreate
          ? { allowIntersection: false }
          : false,
        polyline: false,
        rectangle: false,
        circle: false,
        marker: false,
        circlemarker: false,
      },
      edit: this.geofenceConfig.enableCreate
        ? { featureGroup: this.geofenceFeatureGroup, remove: true }
        : undefined,
    };

    const drawControl = new L.Control.Draw(options);

    if (this.routesWithDrawControl.includes(this.$route.name!)) {
      this.$refs.map.mapObject.addControl(drawControl);
    }

    this.$refs.map.mapObject.on(L.Draw.Event.CREATED, (event) => {
      if (this.geofenceConfig.enableCreate)
        this.geofenceFeatureGroup!.clearLayers();

      this.geofenceFeatureGroup!.addLayer(event.layer);
      this.coordinates = event.layer.getLatLngs()[0];
    });

    if (geofences) this.renderGeofences(geofences);
  }

  setBoundsToGeofences() {
    if (this.geofenceFeatureGroup) {
      this.setBounds(this.geofenceFeatureGroup.getBounds());
    }
  }

  setBounds(bounds: LatLngBounds) {
    this.$refs.map?.setBounds(bounds);
  }

  renderSingleGeofence(geofence: Geofence) {
    this.geofenceFeatureGroup?.clearLayers();
    this.renderGeofence(geofence);
  }

  renderGeofences(geofences: Geofence[]) {
    this.geofenceFeatureGroup?.clearLayers();
    for (const geofence of geofences) {
      this.renderGeofence(geofence);
    }
  }

  private renderGeofence(geofence: Geofence) {
    const latlngs: LatLng[] = [];

    geofence.geofencePosition = geofence.geofencePosition.sort((a, b) =>
      a.sequenceNumber > b.sequenceNumber ? 1 : -1
    );

    for (const p of geofence.geofencePosition) {
      latlngs.push(new LatLng(p.geodeticLatitude, p.geodeticLongitude));
    }

    const found = this.colors.find((s) => s.key === geofence.lifeCycle);
    const color = found ? found.value : 'grey';
    const polygon = new Polygon(latlngs, { color });

    this.geofenceFeatureGroup?.addLayer(polygon);
  }

  getGeofenceCoordinates(): GeofencePosition[] {
    return this.coordinates.map((c, i) => {
      return {
        sequenceNumber: i + 1,
        geodeticLatitude: c.lat,
        geodeticLongitude: c.lng,
      };
    });
  }

  zoomToGeofence(geofence: Geofence) {
    this.$refs.map.mapObject.fitBounds(getBounds(geofence));
  }

  clearLayers(): void {
    this.tripFeatureGroup?.clearLayers();
  }

  renderLine(locations: TripTrack[]): Polyline {
    const latlngs = locations.map(
      (l) => new LatLng(l.location.lat, l.location.lgt)
    );

    const line = polyline(latlngs, { weight: 10 });
    const startIcon = divIcon({ className: 'trip-start' });
    const endIcon = divIcon({ className: 'trip-end' });
    const startMarker = marker(latlngs[0], { icon: startIcon });
    const endMarker = marker(latlngs[latlngs.length - 1], { icon: endIcon });

    this.tripFeatureGroup?.addLayer(startMarker);
    this.tripFeatureGroup?.addLayer(endMarker);
    this.tripFeatureGroup?.addLayer(line);

    this.$refs.map.mapObject.fitBounds(line.getBounds(), { padding: [75, 75] });

    return line;
  }

  renderTripTracker(location: Position, type: AssetType) {
    const tripTracker = this.createMarker(
      PointerIcon,
      new LatLng(location.lat, location.lgt)
    );
    this.tripFeatureGroup?.addLayer(tripTracker);

    return tripTracker;
  }

  renderEvents(
    polyline: Polyline,
    trip: Trip,
    onClick: (event: TripEvent) => void
  ) {
    for (const event of trip.events ?? []) {
      const icon = eventTypeIcons[event.eventType];

      if (!icon) {
        continue;
      }

      const { lat, lgt } = event.location;
      const eventMarker = this.createPopup(icon, new LatLng(lat, lgt), () =>
        onClick(event)
      );

      this.tripFeatureGroup?.addLayer(eventMarker);
    }
  }

  private createMarker(pointerIcon: string, location: LatLng) {
    const pointerIconObject = icon({
      iconUrl: pointerIcon,
      iconSize: this.iconSize,
      iconAnchor: this.iconAnchor,
    });

    return marker(location, { icon: pointerIconObject });
  }

  private createPopup(image: string, location: LatLng, onClick?: () => void) {
    const content = DomUtil.create('div', 'map-image');

    content.style.backgroundImage = `url(${image})`;

    if (onClick) {
      DomEvent.on(content, 'click', onClick);
    }

    return tooltip({
      direction: 'top',
      permanent: true,
      opacity: 100,
      className: 'map-tooltip',
    })
      .setContent(content)
      .setLatLng(location);
  }

  guessTrackerPosition(start: Position, end: Position, ratio: number) {
    return GeometryUtils.interpolateOnLine(
      this.$refs.map.mapObject,
      [
        {
          lat: start.lat,
          lng: start.lgt,
        },
        {
          lat: end.lat,
          lng: end.lgt,
        },
      ],
      ratio
    )?.latLng;
  }

  closeLocationPermissionDeniedDialog() {
    this.showLocationPermissionDeniedModal = false;
  }
}
</script>

<template>
  <div class="leaflet-wrapper">
    <l-map
      ref="map"
      :center="center"
      :zoom="mapZoom"
      :min-zoom="3"
      :max-zoom="20"
      :options="options"
      :bounds="bounds"
      @ready="onMapReady"
      @locationerror="onLocationPermissionDenied"
    >
      <l-tile-layer :url="url" :options="{ maxZoom: 20 }" />
      <LeafletMapAssets
        ref="mapAssets"
        :map="$refs.map"
        :assets="unclusteredSelectedAsset"
        :selectedAssetId="selectedAssetIdForMap"
        @navigateToAssetLocation="navigateToAssetLocation"
      />
      <LeafletMapAssets
        ref="mapAssets"
        :map="$refs.map"
        :assets="clusteredAssets"
      />
      <LeafletMapEvents
        ref="mapEvents"
        :map="$refs.map"
        :events="events"
        :selectedAsset="selectedAsset"
      />
      <l-control-zoom position="bottomright"></l-control-zoom>
      <l-control position="bottomright">
        <div class="map-location-button" @click="onMyLocationClick">
          <img :src="locationIcon" />
        </div>
      </l-control>
    </l-map>
    <MessageBox
      :show="showLocationPermissionDeniedModal"
      :message="$t('enableLocation')"
      @close="closeLocationPermissionDeniedDialog"
    />
  </div>
</template>

<style lang="scss" scoped>
$trip-icon-size: 1.8rem;
.map-location-button {
  border-radius: 2px;
  border: 2px solid rgba(0, 0, 0, 0.2);
  cursor: pointer;
  background-color: #fff;
  padding: 5px 3px 0px 3px;
}

.map-location-button:hover {
  background-color: #f4f4f4;
}

.leaflet-wrapper {
  width: 100%;
  height: 100%;

  :deep(.leaflet-popup-content-wrapper) {
    border-radius: 4px;
    box-shadow: 0px 0px 8px 1px #0000001a;
  }

  :deep(.leaflet-popup-content) {
    width: 250px;
    margin: 0;
  }

  :deep(.leaflet-div-icon) {
    border-radius: 100%;
  }

  :deep(.leaflet-popup-close-button) {
    display: none;
  }

  :deep(.marker-cluster) {
    background-color: #00000075;
    border-radius: 100%;
    opacity: 0.7;

    div {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      color: white;
      font-weight: bold;
    }
  }
}

:deep(.trip-start),
:deep(.trip-end) {
  width: $trip-icon-size !important;
  height: $trip-icon-size !important;
  margin-top: calc(-1 * ($trip-icon-size / 2)) !important;
  margin-left: calc(-1 * ($trip-icon-size / 2)) !important;
  border-radius: 100%;
  background-color: white;
  border: 0.5rem solid #fa3b43;
}

:deep(.trip-end) {
  border-color: #000000;
}

:deep(.map-tooltip) {
  pointer-events: auto !important;
}

:deep(.map-image) {
  width: 4rem;
  height: 2rem;
  background: no-repeat center;
  background-size: contain;
}
</style>
