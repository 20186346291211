<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
  name: 'fancy-info',
})
export default class extends Vue {
  @Prop({ required: true }) name!: string;
  @Prop({ required: true }) value!: string;
}
</script>

<template>
  <el-row class="info">
    <el-col :span="6"> {{ $t(name) }}: </el-col>
    <el-col :span="18" class="value">
      {{ value }}
    </el-col>
  </el-row>
</template>

<style lang="scss" scoped>
.info {
  margin-bottom: 16px;
}

.value {
  font-weight: bold;
}
</style>
