<script lang="ts" setup>
import ErrorPage from '@/components/layout/MessagePage.vue';

function reload() {
  location.reload();
}
</script>

<template>
  <ErrorPage>
    <h2>{{ $t('common.hyvaServiceCurrentlyUnavailable') }}.</h2>
    <p>{{ $t('common.pleaseTryAgainLater') }}.</p>
    <el-button @click="reload" type="info" class="button">{{
      $t('common.reload')
    }}</el-button>
  </ErrorPage>
</template>

<style scoped>
.button {
  margin-top: 1em;
}
</style>
