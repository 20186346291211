<script lang="ts">
import { formatNumber } from '@/utils/number';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { KpiGrowthUnitValue } from './KpiNumber.vue';

@Component({
  name: 'KpiNumberExpanded',
})
export default class extends Vue {
  @Prop({ required: true }) kpi!: KpiGrowthUnitValue<number | undefined>;

  formatNumber = formatNumber;
}
</script>

<template>
  <div class="form-item-container">
    <div class="form-title">
      <div class="show-title">{{ kpi.name }}({{ $t(kpi.unit) }})</div>
      <div class="show-value">{{ formatNumber(kpi.v) }}</div>
    </div>
    <div class="growth-number" v-if="kpi.growth != undefined">
      <span
        class="show-value"
        :class="kpi.growth > 0 ? 'increase_green' : 'decrease_red'"
      >
        <i
          v-show="kpi.growth != 0"
          :class="kpi.growth > 0 ? 'el-icon-top' : 'el-icon-bottom'"
        ></i>
        {{ Math.abs(kpi.growth) }}%
      </span>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.form-item-container {
  background: #f3f3f3;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border: 1px solid #dddddd;
  min-height: 48px;
  padding: 0 16px;
}
.growth-number {
  margin-left: auto;
  .show-value {
    display: flex;
    flex-basis: 33.3%;
  }
}
.decrease_red {
  color: #ed6964;
}
.increase_green {
  color: #66bc93;
}
.form-title {
  display: flex;
  flex-basis: 66.6%;
  align-items: center;
  justify-content: space-between;
}

.show-title {
  font-size: 16px;
  color: #373e41;
}
.show-value {
  font-weight: bold;
  line-height: 18px;
  color: #373e41;
}
</style>
