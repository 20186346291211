<script lang="ts">
import { getAssetOnBoardingById } from '@/api/assetsMgmt';
import GeneralInfo from '@/components/form/GeneralInfo.vue';
import NavPage from '@/components/nav/NavPage.vue';
import { PageModule } from '@/store/modules/page';
import { Component, Prop, Vue } from 'vue-property-decorator';
import ConnectionProgress from '../components/ConnectionProgress.vue';
import VerticalDivide from '../components/VerticalDivide.vue';
import { formatServerDate } from '../helper';

@Component({
  name: 'RegisteredAssetConnectView',
  components: {
    NavPage,
    'general-info': GeneralInfo,
    VerticalDivide,
    'connection-progress': ConnectionProgress,
  },
})
export default class extends Vue {
  @Prop({ required: true }) assetId!: string;

  /** Local variables */
  assetOnBoardingObj: any = {};
  title = '';
  connectAssetViewIsLoading: boolean = false;
  isConnectionProgressLoading: boolean = false;

  mounted() {
    this.fetchAssetDetail();
  }

  async fetchAssetDetail() {
    this.connectAssetViewIsLoading = true;
    this.isConnectionProgressLoading = true;
    await getAssetOnBoardingById(this.assetId).then((res) => {
      if (res.code === 200) {
        this.assetOnBoardingObj = res.data;
        this.assetOnBoardingObj.manufactureDate = formatServerDate(
          this.assetOnBoardingObj.manufactureDate
        );
        PageModule.setTitle(res.data.serialNumber);
      }

      this.isConnectionProgressLoading = false;
      this.connectAssetViewIsLoading = false;
    });
  }
}
</script>

<template>
  <NavPage
    v-loading="connectAssetViewIsLoading"
    :visible="true"
    :arrowBack="false"
    @arrow-click="$router.push('/asset-mgmt/index?activeName=connectedAssets')"
  >
    <div style="margin-left: 40px; margin-top: 44px">
      <el-row :gutter="20">
        <el-col :span="10">
          <general-info
            id="model_view_general_info_name"
            :name="'assetMgmt.assetSerialNumber'"
            :value="assetOnBoardingObj.serialNumber"
          />
          <general-info
            id="model_view_general_info_asset_data_collection"
            :name="'assetMgmt.AssetHIN'"
            :value="assetOnBoardingObj.assetDataCollectionId"
          />
          <general-info
            id="model_view_general_info_asset_type"
            :name="'assetMgmt.assetType'"
            :value="$t(assetOnBoardingObj.assetType)"
          />
          <general-info
            id="model_view_general_info_model_code"
            :name="'assetMgmt.prodModel'"
            :value="assetOnBoardingObj.productModelCode"
          />
          <general-info
            id="model_view_general_info_model"
            :name="'assetMgmt.modelNumber'"
            :value="assetOnBoardingObj.productModelNumber"
          />
          <general-info
            id="model_view_general_info_service_region"
            :name="'common.serviceRegion'"
            :value="assetOnBoardingObj.serviceRegion"
          />
          <general-info
            id="model_view_general_info_manufacture_date"
            :name="'assetMgmt.manufactureDate'"
            :value="assetOnBoardingObj.manufactureDate"
          />
          <general-info
            id="model_view_general_info_controller_id"
            :name="'assetMgmt.controllerId'"
            :value="assetOnBoardingObj.controllerId"
          />
        </el-col>
        <el-col :span="1" :offset="1">
          <VerticalDivide />
        </el-col>
        <el-col :span="10" :offset="3">
          <general-info
            id="model_view_general_info_gateway_id"
            :name="'assetMgmt.gatewayId'"
            :value="assetOnBoardingObj.gatewayId"
          />
          <!-- <general-info
                        id="model_view_general_gateway_type"
                        :name="'assetMgmt.gatewayType'"
                        :value="assetOnBoardingObj.gatewayType"
                    />
                    <general-info
                        id="model_view_general_gateway_model"
                        :name="'assetMgmt.gatewayModel'"
                        :value="assetOnBoardingObj.gatewayModel"
                    /> -->
          <general-info
            id="model_view_general_info_company_asset_id"
            :name="'assetMgmt.cusAssetId'"
            :value="assetOnBoardingObj.companyAssetId"
          />
          <general-info
            id="model_view_general_info_firmware_version"
            :name="'assetMgmt.assetVersion'"
            :value="assetOnBoardingObj.firmwareVersion"
          />
          <connection-progress
            v-if="!isConnectionProgressLoading"
            :assetOnBoardingId="assetId"
            :assetOnBoardingObj="assetOnBoardingObj"
          />
        </el-col>
      </el-row>
    </div>
  </NavPage>
</template>

<style lang="scss" scoped>
:deep(.form-item-container) {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 40px;
  .form-title {
    width: 270px;
    margin-right: 22px;
    text-align: left;
    font-family: $font-Roboto-Regular;
    color: #373e41;
  }
  .show-title {
    font-size: 18px;
  }
}
</style>
