<script lang="ts">
import {
  CustomizedKpiLimit,
  getCustomizedAlarmsDataByCustomUrl,
} from '@/api/alarmConfig';
import { getKpiData } from '@/api/assets';
import { KpiDataField } from '@/api/kpis';
import { getCompanySubscriptions } from '@/api/subscriptionPackages';
import { ValueLimitCheck } from '@/api/value';
import { ActiveContext, useActiveContext } from '@/auth/context';
import WidgetCard from '@/components/layout/widget/WidgetCard.vue';
import {
  useUnitConversion,
  UseUnitConversionReturnType,
} from '@/composables/conversion';
import { PageModule } from '@/store/modules/page';
import { UserModule } from '@/store/modules/user';
import { AssetType } from '@/utils/assetTypes';
import { realTimeKPIPostBody, valueLimitCheckToColor } from '@/utils/kpidata';
import { formatNumber } from '@/utils/number';
import {
  RealTimeKpiCodes,
  SYSTEM_FEATURES,
} from '@/utils/workData/lookuptable';
import { ROUTE_ASSET_MAP } from '@/utils/workData/utilMap';
import LiveMonitoringModal from '@/views/assets/components/LiveMonitoringModal.vue';
import { computed, Ref, unref } from 'vue';
import { Component, Vue } from 'vue-property-decorator';

interface KpiRepresentation {
  label: string;
  value: string;
  unit: string;
  limitCheck: ValueLimitCheck | undefined | null;
}

@Component({
  name: 'AssetRealTime',
  components: {
    WidgetCard,
    LiveMonitoringModal,
  },
})
export default class extends Vue {
  /** Local Variabled */
  realKpiWidgetIsLoading: boolean = false;
  kpiData: KpiDataField[] = [];
  timer: any = null;
  limitInformation: CustomizedKpiLimit[] = [];
  assetTypeCode!: AssetType;

  hasLiveMonitoringFeature: boolean = true;
  showLiveMonitoringModal: boolean = false;

  context!: Ref<ActiveContext>;
  companyAssetId = computed(
    () => PageModule.title[PageModule.title.length - 1]
  );

  realTimeKpiCodes = RealTimeKpiCodes;
  unitConversion!: UseUnitConversionReturnType;

  created() {
    this.context = useActiveContext();
    this.unitConversion = useUnitConversion();

    const assetTypeCode = ROUTE_ASSET_MAP.get(
      this.$route.name ?? ''
    )?.assetTypeCode;
    if (!assetTypeCode) {
      throw new Error('Unknown asset type');
    }
    this.assetTypeCode = assetTypeCode;
  }

  async mounted() {
    this.fecthKpi();
    this.getLimitsInfo();
    this.timer = setInterval(() => {
      this.fecthKpi();
    }, 600000);

    //check whether user could see the live Monitoring
    this.checkLiveMonitoringPackage();

    //check live Monitoring open state
    let modal = this.$refs.liveMonitoringModal as LiveMonitoringModal;
    if (modal.subscribeId) {
      await modal.sendUnsubscribeLiveMonitoringInform();
    }
  }

  beforeDestroy() {
    if (this.timer) {
      clearInterval(this.timer);
    }
  }

  //check whether user could see the live Monitoring
  async checkLiveMonitoringPackage() {
    this.hasLiveMonitoringFeature = false;
    const subscriptions = await this.fetchCompanySubscriptions();

    this.hasLiveMonitoringFeature = !!subscriptions
      .flatMap((subscription) => subscription.systemFeatures)
      .find(
        (feature) =>
          feature.code === SYSTEM_FEATURES.LiveMonitoring &&
          feature.value !== 'false'
      );
  }

  private async fetchCompanySubscriptions() {
    const res = await getCompanySubscriptions(UserModule.companyId);
    if (!res || res.code !== 200 || res.data.length === 0) {
      return [];
    }
    return res.data;
  }

  //click startLiveMonitoring button
  startLiveMonitoring() {
    this.showLiveMonitoringModal = true;
  }

  updateVisible(val: boolean) {
    this.showLiveMonitoringModal = val;
  }

  /**
   * Fetch kpi data
   */
  async fecthKpi() {
    try {
      this.realKpiWidgetIsLoading = true;
      const res = await getKpiData(
        realTimeKPIPostBody(this.$route.params.id, this.assetTypeCode),
        unref(this.context)
      );

      this.kpiData = res.data.details[0]!.fields;
    } catch (error) {
      console.log(error);
    } finally {
      this.realKpiWidgetIsLoading = false;
    }
  }

  get kpiRepresentations(): KpiRepresentation[] {
    return this.kpiData.map((kpi): KpiRepresentation => {
      if (kpi.values.length === 0) {
        throw new Error(`${kpi.code} values is empty`);
      }

      if (kpi.code === RealTimeKpiCodes.OilLevelLow) {
        return {
          label: this.$t(kpi.code),
          unit: '',
          value: this.$t(kpi.values[0].v),
          limitCheck: kpi.values[0].lc,
        };
      }

      const unitValue = this.unitConversion.currentUserConvertUnitValue({
        v: parseFloat(kpi.values[0].v),
        unit: kpi.unit,
      });

      return {
        label: this.$t(kpi.code),
        unit: this.$t(unitValue.unit),
        value: formatNumber(unitValue.v, 2),
        limitCheck: kpi.values[0].lc,
      };
    });
  }

  /**
   * Fetch customized limits by org id for the logged user
   */
  async getLimitsInfo() {
    try {
      const org = unref(this.context).organization;
      if (!org) {
        // Should never happen
        throw new Error('No organization selected');
      }
      const res = await getCustomizedAlarmsDataByCustomUrl(
        org.id,
        '?page=1&size=1000'
      );
      this.limitInformation = res.data.limits.filter(
        (item: CustomizedKpiLimit) => {
          return item.assetType === this.assetTypeCode;
        }
      );
    } catch (error) {
      console.log(error);
    } finally {
      this.realKpiWidgetIsLoading = false;
    }
  }

  /**
   * Determine limit color for the alarm property
   * @param values
   */
  determineLimitColor(lc: ValueLimitCheck | undefined | null): string {
    const resultColor = valueLimitCheckToColor(lc);
    return `color:${resultColor}`;
  }
}
</script>

<template>
  <WidgetCard
    :loading="realKpiWidgetIsLoading"
    :action-title="
      hasLiveMonitoringFeature
        ? $t('liveMonitoring.startLiveMonitoring')
        : undefined
    "
    @click="startLiveMonitoring"
  >
    <div class="kpi-container-wrapper">
      <div class="kpi-container">
        <div v-for="(kpi, index) in kpiRepresentations" :key="index">
          <div class="kpi-item">
            <div class="item-header">
              {{ $t(kpi.label) }}
            </div>
            <div class="item-content">
              <div class="value" :style="determineLimitColor(kpi.limitCheck)">
                {{ kpi.value }}
                <div class="unit">
                  {{ kpi.unit }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <LiveMonitoringModal
      ref="liveMonitoringModal"
      :companyAssetId="companyAssetId"
      :assetId="$route.params.id"
      :modalVisiable="showLiveMonitoringModal"
      :limitAlarmInfo="limitInformation"
      @updateVisible="updateVisible"
    />
  </WidgetCard>
</template>

<style scoped>
.kpi-container {
  display: grid;
  flex-direction: row;
  grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
  grid-gap: 10px;
  justify-content: left;
  padding: 28px 10px 0;
}

.kpi-item {
  min-width: 100px;
  height: 120px;
  border: 0.1rem solid #707070;
  border-radius: 6px;
  padding: 15px;
}

.item-header {
  font-size: 18px;
  font-family: var(--fontRobotoMedium);
  text-overflow: ellipsis;
  overflow: hidden;
  word-break: break-all;
  white-space: nowrap;
}

.item-content {
  font-size: 30px;
  margin: 6px 0 2px 0;
}

.value {
  display: flex;
  font-size: 44px;
  /* color: #4DAF70 */
}

.unit {
  margin-left: 8px;
  font-size: 24px;
  margin-top: 19px;
  /* color: #4DAF70 */
}

.metric-value-green {
  color: #4daf70;
}

.metric-value-red {
  color: #e04642;
}
</style>
