<script lang="ts">
import {
  AnyKpiLimit,
  getAlarmsDataByCustomUrl,
  getCustomizedAlarmsDataByCustomUrl,
} from '@/api/alarmConfig';
import { LoggedInUserRef, useLoggedInUser } from '@/auth/user';
import SelectTableHeader from '@/components/table/SelectTableHeader.vue';
import { UserModule } from '@/store/modules/user';
import { isCompanyTypeOf } from '@/utils/companyService';
import { customFailedMessage } from '@/utils/prompt';
import {
  ALARM_CONFIG_SELECTION_OPTIONS,
  ALARM_CONFIG_TABLE_COLUMNS,
} from '@/utils/workData/alarmConfig';
import { ASSET_TYPE_LIST, COMPANY_TYPE } from '@/utils/workData/lookuptable';
import { unref } from 'vue';
import { Component, Vue } from 'vue-property-decorator';
import AlarmConfigTable from './components/AlarmConfigTable.vue';
import { AlarmTableData } from './models/alarmTableData';

@Component({
  name: 'Alarm',
  components: {
    AlarmConfigTable,
    SelectTableHeader,
  },
})
export default class extends Vue {
  /** Local variables */
  alarmTableColumns = ALARM_CONFIG_TABLE_COLUMNS;
  assetTypeList = ASSET_TYPE_LIST;
  searchFieldOptions = ALARM_CONFIG_SELECTION_OPTIONS;
  loadingText: string = this.$t('customerModule.loadingAlarmData') as string;
  totalAlarms: number = 0;
  currentPage = 1;
  pageSize = UserModule.gridPageSize;
  alarmTableIsLoading: boolean = false;
  tableDataList: AlarmTableData[] = [];
  isColumnSelectionVisible: boolean = false;
  sortAndOrderData: any = {
    sortBy: null,
    order: null,
  };
  searchParams: any = {
    reference: null,
    value: null,
  };
  defaultSort: string = 'createdOn';
  defaultOrder: string = 'DESC';
  loggedInUser!: LoggedInUserRef;

  created() {
    this.loggedInUser = useLoggedInUser();
    this.prepareDefaultInitialization();
  }

  /** Prepare default initialization */
  prepareDefaultInitialization() {
    let finalUrlParamsForSearch: string = this.generateRequestUrlWithParams(
      this.currentPage,
      this.pageSize,
      null,
      this.defaultSort,
      this.defaultOrder
    );
    this.fetchRemoteAlarms(finalUrlParamsForSearch);
  }

  /** Fetch when search is triggered */
  fetchAlarmDataBySearchParams() {
    let finalUrlParamsForSearch: string = this.generateRequestUrlWithParams(
      this.currentPage,
      this.pageSize,
      this.searchParams,
      this.sortAndOrderData.sortBy,
      this.sortAndOrderData.order
    );
    this.fetchRemoteAlarms(finalUrlParamsForSearch);
  }

  /** Filter by sort event */
  fetchAlarmsDataBySortEvent(sortBy: any, order: any) {
    order != ''
      ? (this.sortAndOrderData.sortBy = sortBy)
      : (this.sortAndOrderData.sortBy = null);
    order != ''
      ? (this.sortAndOrderData.order = order)
      : (this.sortAndOrderData.order = null);
    let finalUrlParamsForSearch: string = this.generateRequestUrlWithParams(
      this.currentPage,
      this.pageSize,
      this.searchParams,
      this.sortAndOrderData.sortBy,
      this.sortAndOrderData.order
    );
    this.fetchRemoteAlarms(finalUrlParamsForSearch);
  }

  /** Handle pagination */
  fetchAlarmsDataByPageSelection(page: number, pageSize: number) {
    this.currentPage = page;
    let finalUrlParamsForSearch: string = this.generateRequestUrlWithParams(
      page,
      pageSize,
      this.searchParams,
      this.sortAndOrderData.sortBy,
      this.sortAndOrderData.order
    );
    this.fetchRemoteAlarms(finalUrlParamsForSearch);
  }

  /** Generate request URL by multiple factors */
  generateRequestUrlWithParams(
    pageNumber: any,
    pageSize: number,
    searchParams: any,
    sortBy: any,
    order: any
  ) {
    let finalUrl = '';
    let searchFieldName = searchParams ? searchParams.reference : null;
    let searchFieldValue: any = searchParams ? searchParams.value : null;

    pageNumber ? (finalUrl += `?page=${pageNumber}`) : 1;
    pageNumber ? (finalUrl += `&size=${pageSize}`) : this.pageSize;

    /** Scenarios covered: when value is boolean or when value is a string */
    if (searchFieldName === 'isLocked' && searchFieldValue) {
      switch (searchFieldValue) {
        case 'IS_LOCK_ACTIVATED':
          finalUrl += `&searchFieldName=${searchFieldName}&searchFieldValues=${true}`;
          break;
        case 'IS_LOCK_DEACTIVATED':
          finalUrl += `&searchFieldName=${searchFieldName}&searchFieldValues=${false}`;
          break;
      }
    } else if (searchFieldName && searchFieldValue) {
      finalUrl += `&searchFieldName=${searchFieldName}&searchFieldValues=${encodeURIComponent(
        searchFieldValue
      )}`;
    }

    if (sortBy && order) {
      finalUrl += `&sortBy=${sortBy}&order=${order}`;
    } else {
      finalUrl += `&sortBy=${this.defaultSort}&order=${this.defaultOrder}`; // default must be sorted by createdOn DESC !
    }

    return finalUrl;
  }

  fetchRemoteAlarms(finalUrlParamsForSearch: string) {
    if (isCompanyTypeOf([COMPANY_TYPE.Hyva])) {
      this.fetchRemoteAlarmsDataByCustomUrl(finalUrlParamsForSearch);
    } else {
      this.fetchRemoteCustomizedAlarmsDataByCustomUrl(finalUrlParamsForSearch);
    }
  }

  /** Remote fetch alarms bata by custom URL */
  async fetchRemoteAlarmsDataByCustomUrl(customUrl: any) {
    this.alarmTableIsLoading = true;
    await getAlarmsDataByCustomUrl(customUrl).then((res) => {
      if (!res) return;

      if (res.code && res.code === 200) {
        this.totalAlarms = res.data.total;
        this.tableDataList = res.data.limits.map(
          (item): AlarmTableData => ({ ...item, isEditable: false })
        );
      }

      if (res.code === 500)
        customFailedMessage(this.$t('common.serverError').toString());

      this.alarmTableIsLoading = false;
    });
  }

  async fetchRemoteCustomizedAlarmsDataByCustomUrl(customUrl: any) {
    this.alarmTableIsLoading = true;
    const user = unref(this.loggedInUser);
    if (!user) {
      // Should never happen
      throw new Error('No user logged in');
    }
    await getCustomizedAlarmsDataByCustomUrl(
      user.organization.id,
      customUrl
    ).then((res) => {
      if (!res) return;

      if (res.code && res.code === 200) {
        this.totalAlarms = res.data.total;
        this.tableDataList = res.data.limits.map(
          (item): AlarmTableData => ({ ...item, isEditable: false })
        );
      }

      if (res.code === 500)
        customFailedMessage(this.$t('common.serverError').toString());

      this.alarmTableIsLoading = false;
    });
  }
}
</script>

<template>
  <div
    class="app-container"
    id="alarm_config_main_container"
    style="overflow: hidden"
  >
    <div class="d-flex jc-start ai-center" style="margin-top: -8px">
      <div class="alarm-config-title" id="alarm_config_title">
        {{ $t('alarmConfig.title') }}
      </div>
      <div
        id="alarm_config_total_info"
        class="d-flex ai-center flex-g1 alarm-total-statistics"
      >
        <span style="margin-right: 5px"
          >{{ $t('alarmConfig.totalNumberOfData') }}:</span
        >
        <span v-loading="alarmTableIsLoading" id="alarm_config_total_value">
          {{ totalAlarms }}
        </span>
      </div>
      <div>
        <el-button
          id="alarm_configure_btn"
          v-permission="['AUTHRSC_ACTION_ALARM_CONFIGURE']"
          type="plain"
          @click="$router.push('/alarm-configuration/edit')"
        >
          <img class="configure-icon" src="@/icons/svg/configure-icon.svg" />
          {{ $t('alarmConfig.configure') }}
        </el-button>
      </div>
    </div>

    <div class="border-line"></div>

    <el-row>
      <SelectTableHeader
        id="alarm_config_selection_component"
        style="margin-bottom: 20px"
        :searchFieldOptions="searchFieldOptions"
        :cols="alarmTableColumns"
        :searchParams="searchParams"
        :isColumnSelectionVisible="isColumnSelectionVisible"
        @search-event="fetchAlarmDataBySearchParams"
      />
      <AlarmConfigTable
        id="alarm_config_index_table"
        v-loading="alarmTableIsLoading"
        :element-loading-text="loadingText"
        :tableList="tableDataList"
        :total="totalAlarms"
        :cols="alarmTableColumns"
        :viewPagePath="null"
        @handle-page="fetchAlarmsDataByPageSelection"
        @handle-sort-change="fetchAlarmsDataBySortEvent"
      />
    </el-row>
  </div>
</template>

<style scoped>
.configure-icon {
  height: 15px;
  margin: 0 5px -3px 0;
}
</style>

<style lang="scss" scoped>
.alarm-config-title {
  font-size: 20px;
  font-family: $font-Roboto-Medium;
  font-weight: 500;
  line-height: 24px;
  color: #373e41;
  margin-right: 16px;
}

.alarm-total-statistics {
  font-size: 16px;
  font-family: $font-Roboto-Medium;
  font-weight: 400;
  line-height: 19px;
  color: rgba(55, 62, 65, 0.6);
}

.border-line {
  margin: 0.857143rem -1.428571rem 20px;
  border-bottom: 1px solid #dddddd;
}

.el-select {
  margin-right: 40px;
}

.cust-select :deep(.el-input__inner) {
  height: $inputHeight;
  width: 200px;
  border: 1px solid #707070 !important;
  font-size: 1.285714rem;
  font-family: $font-Roboto-Medium;
  font-weight: 400;
  line-height: 1.357143rem;
  color: #373e41;
  opacity: 1;
}
</style>
