<script lang="ts">
import {
  getSubscriptionPackages,
  SubscriptionPackage,
} from '@/api/subscriptionPackages';
import { customFailedMessage } from '@/utils/prompt';
import { PACKAGE_TYPE_LIST } from '@/utils/workData/lookuptable';
import { SUBSCRIPTION_PACKAGES_COLS } from '@/utils/workData/subscriptionPackages';
import { Component, Vue } from 'vue-property-decorator';
import SubscriptionPackagesTable from './components/SubscriptionPackagesTable.vue';

@Component({
  name: 'SubscriptionPackagesIndex',
  components: {
    'subscription-table': SubscriptionPackagesTable,
  },
})
export default class extends Vue {
  /** Local variables */
  cols = SUBSCRIPTION_PACKAGES_COLS;
  allSubscriptions: SubscriptionPackage[] = [];
  processedSubscriptions: SubscriptionPackage[] = [];
  subscriptionPackagesIsLoading: boolean = false;
  packageTypes: any = [];
  total: number = 0;
  loadingText: string = this.$t('subsMgmt.loadingSubscriptionData') as string;

  created() {
    this.getSubscriptions(1, 15);
  }

  /**
   * Get subscription by page and size
   * @param pages
   * @param size
   */
  async getSubscriptions(page?: number, size?: number) {
    this.subscriptionPackagesIsLoading = true;
    try {
      const subscriptionPackageResponse = await getSubscriptionPackages(
        page,
        size
      );

      this.allSubscriptions = subscriptionPackageResponse.subscriptionPackages;
      this.processedSubscriptions =
        subscriptionPackageResponse.subscriptionPackages;
      this.subscriptionPackagesIsLoading = false;
      this.total = subscriptionPackageResponse.total;

      subscriptionPackageResponse.subscriptionPackages.forEach((item: any) => {
        let packageTypeObj = {
          id: item.id,
          type: item.type,
          translatedType: PACKAGE_TYPE_LIST.find(
            (type: any) => type.id === item.type
          )!.value,
        };

        if (!this.packageTypes.some((el: any) => el.type === item.type)) {
          this.packageTypes.push(packageTypeObj);
        }
      });
    } catch {
      customFailedMessage(
        this.$t('subscriptionPackages.errorWithFetchingData').toString()
      );
    }
  }

  /**
   * Event triggered on page selection from table
   */
  async handlePage(page: number, pageSize: number) {
    this.getSubscriptions(page, pageSize);
  }
}
</script>

<template>
  <div class="subscription-packages-container">
    <div class="page-title-container">
      <span class="page-title-content">{{
        $t('subscriptionPackages.title')
      }}</span>
    </div>
    <div
      class="body-container"
      v-loading="subscriptionPackagesIsLoading"
      :element-loading-text="loadingText"
    >
      <subscription-table
        :tableList="processedSubscriptions"
        :cols="cols"
        :total="total"
        :packageTypes="packageTypes"
        :viewPagePath="'/subscription-packages/view-package/'"
        @search-filter-pagination="getSubscriptions"
        @handle-page="handlePage"
      />
    </div>
  </div>
</template>

<style scoped>
.subscription-packages-container {
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0px 3px 6px #c1c1c1;
  height: 100%;
  width: 100%;
}

.page-title-container {
  display: flex;
  border-bottom: 0.5px solid #dddddd;
  align-items: center;
}

.page-title-content {
  margin: 20px 0 20px 40px;
  font-size: 1.428571rem;
  font-family: var(--fontRobotoMedium);
  line-height: 2rem;
  color: #373e41;
  opacity: 1;
}

.body-container {
  padding: 10px 20px 10px 20px;
}
</style>
