<script lang="ts">
import { resetPassword } from '@/api/users';
import CommonBtn from '@/components/button/CommonBtn.vue';
import CusFormItem from '@/components/form/CusFormItem.vue';
import GeneralInfo from '@/components/form/GeneralInfo.vue';
import { UserModule } from '@/store/modules/user';
import { promptFailedBox, promptSuccessBox } from '@/utils/prompt';
import { ERROR_CODE_LIST } from '@/utils/workData/lookuptable';
import { Component, Vue } from 'vue-property-decorator';

@Component({
  name: 'AccountSettings',
  components: {
    'general-info': GeneralInfo,
    'cus-form-item': CusFormItem,
    'common-btn': CommonBtn,
  },
})
export default class extends Vue {
  disabled: boolean = true;

  accountSettingForm = {
    oldPassword: '',
    newPassword: '',
  };

  userEmail: string = UserModule.email;
  userName: string = UserModule.username;

  confirmPwd: string = '';
  errorInfoFlag: boolean = false;

  errorInfos = [
    {
      code: '',
      field: '',
      message: '',
    },
  ];

  errorCode = ERROR_CODE_LIST;

  get errMessage() {
    let tmpErrInfo: string = '';
    // let errItemCode: string | undefined = this.errorInfos.find(item => item.field === this.errorCode.password)?.code

    if (
      this.errorInfos.find((item) => item.field === this.errorCode.password)
        ?.code === 'ApiErrorFieldInvalid'
    ) {
      tmpErrInfo = `${this.$t('userSetting.errPassword')}`;
    } else if (
      this.errorInfos.find((item) => item.field === this.errorCode.password)
        ?.code === 'ApiErrorSamePassword'
    ) {
      tmpErrInfo = `${this.$t('userSetting.samePassword')}`;
    } else if (
      this.errorInfos.find((item) => item.field === this.errorCode.password)
        ?.code === 'ApiErrorPasswordSameAsLastThree'
    ) {
      tmpErrInfo = `${this.$t('userSetting.sameAsLastThreePwd')}`;
    } else if (
      this.errorInfos.find((item) => item.field === 'newPassword')?.code ===
      'ApiErrorFieldInvalid'
    ) {
      tmpErrInfo = `${this.$t('userSetting.newPasswordFormat')}`;
    }

    return tmpErrInfo;
  }

  async saveAccountSetting() {
    if (this.confirmPwd !== this.accountSettingForm.newPassword) {
      this.errorInfoFlag = true;
    } else {
      this.errorInfoFlag = false;
      await resetPassword(UserModule.id, this.accountSettingForm).then(
        (res) => {
          if (res.code === 200) {
            this.errorInfos = [];
            promptSuccessBox(this.$t('common.save') as string);
          } else if (res.code === 400) {
            this.errorInfoFlag = true;
            promptFailedBox(this.$t('common.save') as string);
            this.errorInfos = res.data.errors;
          }
        }
      );
    }
  }

  newPwdInput(value: string) {
    this.errorInfos = [];
    if (this.confirmPwd && value !== this.confirmPwd) {
      this.errorInfoFlag = true;
    } else {
      this.errorInfoFlag = false;
    }

    this.isDisableBtn();
  }

  comparePwd(value: string) {
    this.errorInfos = [];
    if (value !== this.accountSettingForm.newPassword) {
      this.errorInfoFlag = true;
    } else {
      this.errorInfoFlag = false;
    }
  }

  isDisableBtn() {
    if (
      this.accountSettingForm.oldPassword &&
      this.accountSettingForm.newPassword &&
      this.confirmPwd
    ) {
      this.disabled = false;
    } else {
      this.disabled = true;
    }
  }
}
</script>

<template>
  <div class="account-settings">
    <div class="account-container">
      <div class="account-title">
        <span id="user_setting_account_span_accountName" class="account-name"
          >{{ $t('userSetting.accountName') }}:</span
        >
      </div>
      <div>
        <span
          id="user_setting_account_span_accountEmail"
          class="account-value"
          >{{ userEmail }}</span
        >
      </div>
    </div>
    <div class="reset-pwd">
      <span id="user_setting_account_span_resetPwd">{{
        $t('userSetting.resetPassword')
      }}</span>
    </div>

    <el-form id="user_setting_account_form">
      <cus-form-item :title="'userSetting.oldPwd'" :required="false">
        <el-form-item>
          <el-input
            id="user_setting_account_input_oldPwd"
            v-model="accountSettingForm.oldPassword"
            :placeholder="$t('userSetting.inputOldPwd')"
            show-password
            @input="isDisableBtn"
          />
        </el-form-item>
      </cus-form-item>
      <cus-form-item :title="'userSetting.newPwd'" :required="false">
        <el-form-item>
          <el-input
            id="user_setting_account_input_newPwd"
            v-model="accountSettingForm.newPassword"
            :placeholder="$t('userSetting.inputNewPwd')"
            show-password
            @input="newPwdInput"
          />
        </el-form-item>
      </cus-form-item>
      <cus-form-item :title="'userSetting.confirm'" :required="false">
        <el-form-item>
          <el-input
            id="user_setting_account_input_confirmPwd"
            v-model="confirmPwd"
            :placeholder="$t('userSetting.inputConfirmPwd')"
            show-password
            @input="isDisableBtn"
            @change="comparePwd"
          />
        </el-form-item>
      </cus-form-item>
      <el-form-item style="margin-left: 160px; margin-top: 0px">
        <div v-if="errorInfoFlag">
          <span
            id="user_setting_account_span_errCodeMessage"
            class="err-info"
            v-if="errMessage"
            >* {{ errMessage }}</span
          >
          <span id="user_setting_account_span_diffPwd" class="err-info" v-else
            >* {{ $t('userSetting.diffPassword') }}</span
          >
        </div>
      </el-form-item>
      <el-form-item>
        <div class="form-footer">
          <common-btn
            id="user_setting_account_btn_save"
            :content="'common.save'"
            :disabled="disabled"
            @handle-btn="saveAccountSetting"
          />
        </div>
      </el-form-item>
    </el-form>

    <div class="pwd-rules">
      <div id="user_setting_account_pwdRuleInfo">
        {{ $t('userSetting.pwdRuleInfo') }}:
      </div>
      <div id="user_setting_account_pwdRule1">
        - {{ $t('userSetting.pwdRuleInfo1') }}
      </div>
      <div id="user_setting_account_pwdRule2">
        - {{ $t('userSetting.pwdRuleInfo2') }}
      </div>
      <div id="user_setting_account_pwdRule3">
        - {{ $t('userSetting.pwdRuleInfo3') }}
      </div>
    </div>
  </div>
</template>

<style scoped>
.account-settings {
  margin-left: 10px;
}

.form-item-container :deep(.form-title) {
  margin-right: -60px;
}

.user-setting-form {
  margin-left: 10px;
}

.form-footer {
  margin-left: 320px;
}

.form-footer :deep(.common-button) {
  width: 200px;
}
</style>

<style lang="scss" scoped>
.account-container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 20px;
  margin-left: 20px;

  font-size: 1.285714rem; //18px
  font-family: $font-Roboto-Medium;
  line-height: 1.5rem; //21px
  opacity: 1;

  .account-title {
    flex-shrink: 0;
    // width: 200px;
    margin-right: 10px;
    text-align: right;

    .account-name {
      color: #8e8e8e;
    }
  }

  .account-value {
    color: #373e41;
  }
}

.pwd-rules {
  color: #707070;
  display: block;
  font-family: $font-Roboto-Regular;
  font-size: 12px;
  font-weight: 400;
  margin-top: 40px;
  // margin-left: calc(#{$inputTitleWidth} + 20px);
  margin-left: 120px;
}

.reset-pwd {
  margin-top: 40px;
  // margin-left: 50px;
  font-size: 1rem;
  font-family: $font-Roboto-Medium;
  line-height: 16px;
  color: #373e41;
  opacity: 1;
}

.dialog-header-title {
  font-size: 24px;
  font-family: $font-Roboto-Medium;
  color: #373e41;
  margin: 10px 0;
}

.dialog-body-value {
  font-size: 20px;
  font-family: $font-Roboto-Regular;
  color: #373e41;
  word-break: keep-all;
  line-height: 1.5;
}

.dialog-body-contact {
  font-size: 20px;
  font-family: $font-Roboto-Regular;
  color: #373e41;
  margin: 30px 0;
  word-break: keep-all;
}

.contact-email {
  font-size: 20px;
  font-family: $font-Roboto-Medium;
  color: #0ab143;
}

.dialog-footer-value {
  font-size: 20px;
  font-family: $font-Roboto-Regular;
  color: #373e41;
  margin-bottom: 30px;
}
</style>
