<script lang="ts">
import { CustomizedKpiLimit } from '@/api/alarmConfig';
import {
  getKpiData,
  liveMonitoringFrenquency,
  subscribeLiveMonitoring,
  unsubscribeLiveMonitoring,
} from '@/api/assets';
import { KpiDataField } from '@/api/kpis';
import TimeInterval from '@/components/timeInterval/TimeInterval.vue';
import { AssetType } from '@/utils/assetTypes';
import { realTimeKPIPostBody } from '@/utils/kpidata';
import { customFailedMessage } from '@/utils/prompt';
import { ROUTE_ASSET_MAP } from '@/utils/workData/utilMap';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import LiveMonitoringLineCharts from './LiveMonitoringLineCharts.vue';

@Component({
  name: 'LiveMonitoringModal',
  components: {
    LiveMonitoringLineCharts,
    TimeInterval,
  },
})
export default class extends Vue {
  @Prop({ default: '1500px' }) popupWidth!: string;
  @Prop({ default: '5vh' }) popupTop!: string;
  @Prop({ required: true }) modalVisiable!: boolean;
  @Prop({ default: false }) hideFootBtns!: boolean;
  @Prop({ required: true }) companyAssetId!: string;
  @Prop({ required: true }) assetId!: string;
  @Prop({ required: true }) limitAlarmInfo!: CustomizedKpiLimit[];

  allTypeChartData: KpiDataField[] = [];
  isRendering: boolean = true; //true: lineCharts render; false: lineCharts stop rendering but the data is still pushed
  timer: any = null;
  refreshSeconds: number = 5;
  subscribeDuration: number = 300;
  tokenRefreshThreshold: number = 0.9; // refresh when 90% of the subscription duration elapsed
  subscribeTimer: any = null;
  subscribeId: string | null = null;
  assetTypeCode!: AssetType;

  created() {
    const assetTypeCode = ROUTE_ASSET_MAP.get(
      this.$route.name ?? ''
    )?.assetTypeCode;
    if (!assetTypeCode) {
      throw new Error('Unknown asset type');
    }
    this.assetTypeCode = assetTypeCode;
  }

  @Watch('visible')
  async switchTimer(newData: boolean) {
    if (newData) {
      await Promise.all([
        this.setSubscribeTimer(),
        this.getRefreshTime(),
        this.fecthRealTimeKpi(),
      ]);

      this.timer = setInterval(() => {
        this.fecthRealTimeKpi();
      }, this.refreshSeconds * 1000);

      //start to timing
      this.$nextTick(() => {
        (this.$refs.timeInterval as any).startTime();
      });
    } else {
      // when close modal, all the data should reset and cleat timeinterval
      await this.resetDataAndClearTimer();
    }
  }

  get visible() {
    return this.modalVisiable;
  }

  set visible(val) {
    this.$emit('updateVisible', val);
  }

  async beforeDestroy() {
    if (this.visible) {
      await this.resetDataAndClearTimer();
    }
  }

  //get all the realtime kp data
  async fecthRealTimeKpi() {
    const res = await getKpiData(
      realTimeKPIPostBody(this.$route.params.id, this.assetTypeCode)
    );
    if (res?.code === 200) {
      this.allTypeChartData = res.data.details[0].fields;
    } else {
      customFailedMessage(this.$t('KPI.errorFetchingKpisData').toString());
    }
  }

  exportDatatoCSV() {
    (this.$refs.liveMonitoring as any).exportCsvFile();
  }

  toggleRendering() {
    this.isRendering = !this.isRendering;
    this.$nextTick(() => {
      if (this.isRendering) {
        (this.$refs.timeInterval as any).startTime();
      } else {
        (this.$refs.timeInterval as any).pauseTime();
      }
    });
  }

  quitModal() {
    this.visible = false;
  }

  //send the notification to BE so that they will get data from Thingsboard
  async sendSubscribeLiveMonitoring() {
    let submitData: any = {
      subscriptionExpireDurationInSeconds: this.subscribeDuration,
    };
    if (this.subscribeId) {
      submitData.id = this.subscribeId;
    }

    const res = await subscribeLiveMonitoring(this.assetId, submitData);
    if (res && res.code === 200) {
      this.subscribeDuration = res.data.subscriptionExpireDurationInSeconds;
      this.subscribeId = res.data.id;
      return;
    }
    customFailedMessage(
      this.$t('liveMonitoring.errorSubscribeLiveMonitoring').toString()
    );
  }

  //get refresh secondes
  async getRefreshTime() {
    const res = await liveMonitoringFrenquency();
    if (res && res.code === 200) {
      this.refreshSeconds = res.data.frequencyInSeconds;
      return;
    }
    customFailedMessage(
      this.$t('liveMonitoring.errorSubscribeLiveMonitoring').toString()
    );
  }

  //set subscription timer
  setSubscribeTimer() {
    this.sendSubscribeLiveMonitoring();
    this.subscribeTimer = setInterval(() => {
      this.sendSubscribeLiveMonitoring();
    }, this.subscribeDuration * this.tokenRefreshThreshold * 1000);
  }

  //unsubscription live Monitoring
  async sendUnsubscribeLiveMonitoringInform() {
    if (!this.subscribeId) {
      return;
    }

    let submitData: any = {
      id: this.subscribeId,
    };

    const res = await unsubscribeLiveMonitoring(this.assetId, submitData);
    if (res && res.code === 200) {
      this.subscribeId = null;
      return;
    }
    customFailedMessage(
      this.$t('liveMonitoring.errorUnsubscribeLiveMonitoring').toString()
    );
  }

  //reset all the data and clear the timer
  async resetDataAndClearTimer() {
    clearInterval(this.subscribeTimer);
    clearInterval(this.timer);
    this.allTypeChartData = [];
    this.isRendering = true;
    await this.sendUnsubscribeLiveMonitoringInform();
  }
}
</script>

<template>
  <div>
    <el-dialog
      :visible.sync="visible"
      class="cust-dialog"
      :width="popupWidth"
      :top="popupTop"
      :title="companyAssetId"
      :destroy-on-close="true"
    >
      <div class="d-flex ai-center" style="flex-direction: column">
        <div class="parent_content_box">
          <LiveMonitoringLineCharts
            ref="liveMonitoring"
            :companyAssetId="companyAssetId"
            :allChartData="allTypeChartData"
            :isRendering="isRendering"
            :limitAlarmInfo="limitAlarmInfo"
            :refreshSeconds="refreshSeconds"
          />
        </div>

        <div slot="footer" class="foot-area">
          <p class="time">
            <TimeInterval ref="timeInterval" />
          </p>
          <div class="btn_area">
            <div class="back_btn" @click="exportDatatoCSV">
              <span>{{ $t('KPI.exportData') }}</span>
            </div>
            <div class="back_btn black_btn" @click="quitModal">
              <span>{{ $t('KPI.quit') }}</span>
            </div>
            <div class="back_btn black_btn" @click="toggleRendering">
              <span v-show="isRendering">{{ $t('KPI.stop') }}</span>
              <span v-show="!isRendering">{{ $t('KPI.resume') }}</span>
            </div>
          </div>
          <p class="tip">
            {{ $t('KPI.refreshFrequency') }}: {{ refreshSeconds }}s
          </p>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<style lang="scss" scoped>
.foot-area {
  height: 80px;
  font-family: $font-Roboto-Medium;
  font-size: 16px;
  position: relative;
  width: 100%;
  .time {
    position: absolute;
    left: 30px;
    top: 20px;
    margin: 0;
  }

  .btn_area {
    position: absolute;
    display: flex;
    left: 50%;
    width: 700px;
    top: 10px;
    transform: translateX(-350px);
    .back_btn {
      margin-right: 33px;
      height: 38px;
      border: 1px solid #373e41;
      border-radius: 4px;
      cursor: pointer;
      width: 198px;
      text-align: center;
      line-height: 36px;
      color: #373e41;
      margin: 0 18px;
    }
    .black_btn {
      color: #fff;
      background: #373e41;
    }
  }

  .tip {
    position: absolute;
    right: 30px;
    top: 20px;
    margin: 0;
    font-size: 16px;
  }
}

:deep(.el-dialog__title) {
  color: #373e41;
  line-height: 22px;
  font-size: 24px;
  color: #303133;
  line-height: 60px;
  text-align: center;
  width: 100%;
}
.cust-dialog :deep(.el-dialog__header) {
  background-color: var(--Main) !important;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 60px;
  padding: 0;
}

.cust-dialog :deep(.el-dialog__title) {
  font-size: 20px;
  font-family: $font-Roboto-Medium;
  line-height: 19px;
}

:deep(.el-dialog__headerbtn) {
  font-size: 26px;
  color: #373d41;
  position: absolute;
  top: 16px;
  right: 34px;
}
.new-cust-dialog :deep(.el-dialog__headerbtn:hover .el-dialog__close) {
  color: #5f6567;
}

$fontSizeBox: 1.2em;

.parent_content_box {
  width: 100%;
  padding-bottom: 20px;
}
</style>
