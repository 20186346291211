import {
  VuexModule,
  Module,
  Action,
  Mutation,
  getModule,
} from 'vuex-module-decorators';
import store from '@/store';

export interface ICustMgmt {
  custForm: any;
  companySubscriptions: any;
  reStore: boolean;
  isSave: boolean;
  logoFile: any;
  templateId: string;
  custTemplateId: string;
  customerId: string;
}

@Module({ dynamic: true, store, name: 'custMgmt' })
class CustMgmt extends VuexModule implements ICustMgmt {
  public custForm = {};
  public companySubscriptions = [];
  public reStore = false;
  public logoFile = {};
  public templateId = '';
  public custTemplateId = '';
  public customerId = '';
  public isSave = false;

  @Mutation
  private SET_CUSTMGMT(custForm: any) {
    this.custForm = custForm;
  }

  @Mutation
  private SET_COMSUBSP(companySubscriptions: any) {
    this.companySubscriptions = companySubscriptions;
  }

  @Mutation
  private SET_RESTORE(val: boolean) {
    this.reStore = val;
  }

  @Mutation
  private SET_LOGOFILE(file: any) {
    this.logoFile = file;
  }

  @Mutation
  private SET_TEMPLATEID(templateId: string) {
    this.templateId = templateId;
  }

  @Mutation
  private SET_CUSTOMERID(customerId: string) {
    this.customerId = customerId;
  }

  @Mutation
  private SET_ISSAVE(isSave: boolean) {
    this.isSave = isSave;
  }

  @Mutation
  private SET_CUSTTEMPLATEID(id: string) {
    this.custTemplateId = id;
  }

  @Mutation
  private CLEAR_DATA() {
    this.custForm = {};
    this.companySubscriptions = [];
    this.logoFile = {};
  }

  @Action
  public setCustMgmt(custForm: any) {
    this.SET_CUSTMGMT(custForm);
  }

  @Action
  public setComSubsp(companySubscriptions: any) {
    this.SET_COMSUBSP(companySubscriptions);
  }

  @Action
  public setReStore(val: boolean) {
    this.SET_RESTORE(val);
  }

  @Action
  public setLogoFile(file: any) {
    this.SET_LOGOFILE(file);
  }

  @Action
  public setTemplateId(templateId: string) {
    this.SET_TEMPLATEID(templateId);
  }

  @Action
  public setCustomerId(customerId: string) {
    this.SET_CUSTOMERID(customerId);
  }

  @Action
  public setIsSave(isSave: boolean) {
    this.SET_ISSAVE(isSave);
  }

  @Action
  public setCustTemplateId(id: string) {
    this.SET_CUSTTEMPLATEID(id);
  }

  @Action
  public clearData() {
    this.CLEAR_DATA();
  }
}

export const CustMgmtModule = getModule(CustMgmt);
