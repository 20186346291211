<script lang="ts">
import { UserModule } from '@/store/modules/user';
import { hasTableHorizontalScrollbar } from '@/utils/table';
import { TableAction } from '@/utils/types/columnCustomizationTypes';
import { InitializeReactive } from '@/utils/vueClassComponentHelpers';
import { useResizeObserver, UseResizeObserverReturn } from '@vueuse/core';
import Sortable from 'sortablejs';
import { computed } from 'vue';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

@Component({
  name: 'SubscribeTemplateTable',
})
export default class extends Vue {
  $refs!: {
    tableRef: HTMLDivElement;
  };

  @Prop() tableList!: any[];
  @Prop() treeProp!: any;
  @Prop() cols!: [];
  @Prop() viewPagePath!: string;
  @Prop() actionTable!: string;

  /** Local variables */
  list: any = [...this.tableList];
  tableComponentKey: string = new Date().toString();
  dropCols: any = [];
  pageSize = UserModule.gridPageSize;
  listQuery = {
    page: 1,
    limit: UserModule.gridPageSize,
  };
  routesWithEditModelDisabled: any = [
    'alarmConfigurationViewPage',
    'Administration',
  ];

  resizeObserver!: UseResizeObserverReturn;

  @InitializeReactive
  tableRef!: HTMLDivElement;

  @InitializeReactive
  tableHeight: number = 0;

  @Watch('tableList', {
    deep: true,
  })
  onListChange() {
    this.list = [...this.tableList];
  }

  created() {
    this.resizeObserver = useResizeObserver(
      computed(() => this.tableRef),
      () => {
        const hasScrollbar = hasTableHorizontalScrollbar();
        this.tableHeight =
          this.tableRef.getBoundingClientRect().height -
          (hasScrollbar ? 10 : 0);
      }
    );
  }

  mounted() {
    this.columnDrop();
    this.tableRef = this.$refs.tableRef;
  }

  get activeCols() {
    this.dropCols = this.cols.filter((item: any) => {
      return item.visible;
    });

    this.tableComponentKey = new Date().toString();

    return this.dropCols;
  }
  /**
    all the action buttons call the same function, the event name depends on the item param
    * @param item
    * @param row
    */
  handleActionBtnClick(item: TableAction, row: any) {
    this.$emit(item.onClickEmitterName ?? item.name, item, row);
  }

  handleRowClick(row: any, column: any, event: any) {
    if (
      row.hasOwnProperty('gatewayId') &&
      row.gatewayId &&
      !row.hasOwnProperty('installTime')
    ) {
      this.$router.push('register-connecting/view/' + row.id);
      return;
    }

    if (this.viewPagePath) {
      this.$router.push(this.viewPagePath + '/' + row.id);
    }
  }

  handleSortChange(val: any) {
    let sortType = '';

    if (val.order === 'ascending') {
      sortType = 'ASC';
    } else if (val.order === 'descending') {
      sortType = 'DESC';
    }

    this.$emit('handle-sort-change', val.prop, sortType);
  }

  columnDrop() {
    var that = this;
    const theader: any = document.querySelector('.el-table__header-wrapper tr');
    Sortable.create(theader, {
      animation: 180,
      delay: 0,
      onEnd: (evt: any) => {
        let oldMoveItem = this.dropCols[evt.oldIndex];
        let newMoveItem = this.dropCols[evt.newIndex];

        let oldItemIndex = this.cols.findIndex((item: any) => {
          return item.prop === oldMoveItem.prop;
        });
        let newItemIndex = this.cols.findIndex((item: any) => {
          return item.prop === newMoveItem.prop;
        });
        this.cols.splice(newItemIndex, 0, this.cols.splice(oldItemIndex, 1)[0]);
      },
    });
  }

  get checkIfAlarmConfigIsInEditMode(): boolean {
    return !this.routesWithEditModelDisabled.includes(this.$route.name);
  }

  /** Validate input values to be only number + delete key */
  acceptOnlyNumericValues(event: any) {
    const char = String.fromCharCode(event.keyCode);
    if (!/[0-9|\b|/]/.test(char)) {
      event.preventDefault();
    }
  }
}
</script>

<template>
  <!-- gytodo opitimize this Component -->
  <div ref="tableRef" style="flex-grow: 1">
    <el-table
      :data="list"
      :tree-props="treeProp"
      row-key="id"
      :height="tableHeight"
      :row-style="{ height: '40px' }"
      :cell-style="{ padding: '7px 0px' }"
      @row-click="handleRowClick"
      @sort-change="handleSortChange"
    >
      <el-table-column
        v-for="(col, index) in activeCols"
        :key="index + '_' + tableComponentKey"
        :prop="col.prop"
        min-width="180"
        :sortable="col.sortable"
        :width="col.width ? col.width : 'auto'"
      >
        <template v-slot:header>
          <div class="table-header">
            <span style="white-space: nowrap">{{
              $t(activeCols[index]?.label)
            }}</span>
          </div>
        </template>

        <!-- translate language -->
        <template v-slot="slotProps" v-if="col.multiLang">
          <span>{{ $t(slotProps.row[col.prop]) }}</span>
        </template>
        <!-- format date info -->
        <template
          v-slot="slotProps"
          v-else-if="col.dateFormat === 'date' || col.dateFormat === 'datetime'"
        >
          <span v-if="slotProps.row[col.prop]">{{
            slotProps.row[col.prop] | formatTimer(col.dateFormat)
          }}</span>
        </template>
        <!-- action parts -->
        <template v-slot="slotProps" v-else-if="col.prop === 'action'">
          <div class="report-action-column">
            <el-button
              id="modify_report_subscription_button"
              v-permission="['AUTHRSC_ACTION_SUBSCRIBED_REPORTS_UPDATE']"
              class="report-action-btn"
              v-for="(item, index) in slotProps.row[col.prop]"
              :key="index"
              @click="handleActionBtnClick(item, slotProps.row)"
              type="text"
              size="small"
            >
              {{ $t(item.name) }}
            </el-button>
          </div>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<style scoped>
.report-action-column :deep(.report-action-btn) {
  border-color: #707070;
  border-radius: 5px;
}

.report-action-column :deep(.report-action-btn:hover) {
  background-color: #c0bfbf;
}

.el-table :deep(.el-table__body-wrapper) {
  overflow-y: auto;
}

.report-action-column {
  display: flex;
  font-family: var(--font-Roboto-Medium);
}

.el-table tbody tr:hover > td {
  /* background-color: rgb(var(--Main), 0.6) !important; */
  background-color: var(--Main) !important;
  /* opacity: 0.5; */
  /* cursor: pointer; */
}

.el-table :deep(.cell) {
  /* white-space: pre; */
  display: flex;
  align-items: center;
}

.el-table th {
  border-top: 1px solid #dddddd;
  border-bottom: 1px solid #dddddd !important;
}

.el-table td {
  border-bottom: 1px solid #dddddd !important;
}

.el-table {
  font-size: 14px !important;
  font-family: var(--fontRobotoRegular);
  line-height: 16px;
  color: #373e41 !important;
  opacity: 1;
}

.el-table thead {
  font-size: 14px !important;
  font-family: var(--fontRobotoMedium);
  line-height: 16px;
  color: #373e41 !important;
  opacity: 1;
}
</style>

<style lang="scss" scoped>
.table-header {
  display: inline-block;
  margin-right: 12px;
}

.el-input {
  width: 300px;
}

.el-select {
  margin-right: 40px;
}

:deep(.el-button) {
  border: 1px solid #707070;
  padding: 4px 24px;
}
:deep(.el-button--text) {
  color: #707070;
}

:deep(.el-table .sort-caret.ascending) {
  border-bottom-color: #000000;
}
:deep(.el-table .sort-caret.descending) {
  border-top-color: #000000;
}
:deep(.el-table .ascending .sort-caret.ascending) {
  border-bottom-color: var(--Main);
}
:deep(.el-table .descending .sort-caret.descending) {
  border-top-color: var(--Main);
}
</style>
