var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('el-table',{attrs:{"data":_vm.list,"row-key":"id","row-style":{ height: '40px' },"cell-style":{ padding: '7px 0px' },"row-class-name":_vm.tableRowClassName},on:{"selection-change":_vm.handleSelectionChange,"sort-change":_vm.handleSortChange}},[(_vm.showCheckedColumn)?_c('el-table-column',{attrs:{"type":"selection","reserve-selection":_vm.showCheckedColumn,"width":"55"}}):_vm._e(),_vm._l((_vm.activeCols),function(col,index){return _c('el-table-column',{key:`col_${index}`,attrs:{"prop":_vm.dragCols[index].prop,"min-width":col.width ? col.width : '180',"show-overflow-tooltip":"","sortable":_vm.dragCols[index].sortable},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('div',{staticClass:"table-header"},[_c('span',{staticStyle:{"white-space":"nowrap"}},[_vm._v(_vm._s(_vm.$t(col.label)))])])]},proxy:true},(col.multiLang)?{key:"default",fn:function(slotProps){return [_c('span',[_vm._v(_vm._s(_vm.$t(slotProps.row[col.prop])))])]}}:(col.dateFormat === 'date' || col.dateFormat === 'datetime')?{key:"default",fn:function(slotProps){return [_c('span',[_vm._v(_vm._s(_vm._f("formatTimer")(slotProps.row[col.prop],col.dateFormat)))])]}}:null],null,true)})})],2),_c('Pagination',{directives:[{name:"show",rawName:"v-show",value:(
      /* @ts-expect-error TODO Wrong type */
      _vm.showPage && parseInt(_vm.total) > parseInt(_vm.pageSize)
    ),expression:"\n      /* @ts-expect-error TODO Wrong type */\n      showPage && parseInt(total) > parseInt(pageSize)\n    "}],attrs:{"total":_vm.total,"page":_vm.listQuery.page,"limt":_vm.listQuery.limit},on:{"update:page":function($event){return _vm.$set(_vm.listQuery, "page", $event)},"update:limt":function($event){return _vm.$set(_vm.listQuery, "limit", $event)},"pagination":_vm.getList}}),_c('el-popover',{ref:"popover",attrs:{"placement":"bottom","trigger":"click"}},_vm._l((_vm.cols),function(item,index){return _c('el-checkbox',{key:index,staticStyle:{"display":"block","color":"#373e41 !important"},attrs:{"label":/* @ts-expect-error TODO Wrong type */
        _vm.$t(item.label),"disabled":/* @ts-expect-error TODO Wrong type */
        item.required},model:{value:(
        /* @ts-expect-error TODO Wrong type */
        item.visible
      ),callback:function ($$v) {_vm.$set(/* @ts-expect-error TODO Wrong type */
        item, "visible", $$v)},expression:"\n        /* @ts-expect-error TODO Wrong type */\n        item.visible\n      "}})}),1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }