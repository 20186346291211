import { useLoggedInUser } from '@/auth/user';
import { AssetType } from '@/utils/assetTypes';
import { MaybeRef } from '@/utils/ref';
import {
  FvtAccessControlOption,
  SYSTEM_FEATURES,
} from '@/utils/workData/lookuptable';
import { computed, ComputedRef, unref } from 'vue';

export function useBooleanSystemFeature(
  systemFeature: SYSTEM_FEATURES,
  assetType?: MaybeRef<AssetType | undefined>
): ComputedRef<boolean> {
  const loggedInUser = useLoggedInUser();

  return computed(
    () =>
      loggedInUser.value?.systemFeatures[systemFeature]?.[
        unref(assetType) ?? AssetType.All
      ]?.value === 'true'
  );
}

export function useAccessControlSystemFeature(
  systemFeature: SYSTEM_FEATURES,
  assetType?: MaybeRef<AssetType | undefined>
): ComputedRef<FvtAccessControlOption> {
  const loggedInUser = useLoggedInUser();

  return computed(() => {
    const systeamFeatureValue =
      loggedInUser.value?.systemFeatures[systemFeature]?.[
        unref(assetType) ?? AssetType.All
      ]?.value;

    if (systeamFeatureValue === undefined) {
      return FvtAccessControlOption.NoAccess;
    }

    return systeamFeatureValue as FvtAccessControlOption;
  });
}
