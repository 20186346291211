<script lang="ts">
import { getKpiData } from '@/api/assets';
import { KpiDataField, KpiDataRequest } from '@/api/kpis';
import { ActiveContext, useActiveContext } from '@/auth/context';
import FleetPerformanceScatterChart from '@/components/fleetPerformance/FleetPerformanceScatterChart.vue';
import { FleetPerformanceChartData } from '@/components/fleetPerformance/models/FleetPerformance';
import TimeSelect from '@/components/form/TimeSelect.vue';
import WidgetCard from '@/components/layout/widget/WidgetCard.vue';
import {
  useUnitConversion,
  UseUnitConversionReturnType,
} from '@/composables/conversion';
import { AssetType } from '@/utils/assetTypes';
import { DEFAULT_DATE_RANGE } from '@/utils/time';
import { DateRange } from '@/utils/types/date';
import { KPI_UNIT } from '@/utils/units/unitDefinitions';
import { KPI_FIELDS } from '@/utils/workData/lookuptable';
import FleetPerformanceExpanded from '@/widgets/fleet/expanded/FleetPerformanceExpanded.vue';
import moment from 'moment';
import { Ref, unref } from 'vue';
import { Component, Inject, Vue } from 'vue-property-decorator';
import { generateFleetPerformanceChartData } from '../utils/handleWidgetOperations';

@Component({
  name: 'FleetPerformance',
  components: {
    FleetPerformanceExpanded,
    FleetPerformanceScatterChart,
    TimeSelect,
    WidgetCard,
  },
})
export default class extends Vue {
  @Inject() expanded!: boolean;

  /** Local variables */
  isLoading: boolean = true;
  period: number = 7;
  context!: Ref<ActiveContext>;
  requestPayload: KpiDataRequest = {
    metadata: {
      filter: {
        assetTypeCode: AssetType.TippingVehicle,
        // organizationIds filled in later
      },
      selection: {
        startDate: DEFAULT_DATE_RANGE.start,
        endDate: DEFAULT_DATE_RANGE.endExclusive,
        dataBucketDimension: 'DBDIM_ASSET',
      },
    },
    details: [
      {
        entity: 'ENTT_ASSET_TYPE',
        fields: [
          {
            code: KPI_FIELDS.TippingPayload,
            unit: KPI_UNIT.MetricTonne,
          },
        ],
      },
    ],
  };
  chartData: FleetPerformanceChartData[] = [];
  unitConversion!: UseUnitConversionReturnType;

  created() {
    this.unitConversion = useUnitConversion();
    this.context = useActiveContext();
  }

  /**
   * Handle time selection
   * - set period as days difference between start and inclusive end date of the range
   */
  handleTimeFilter(dateRange: DateRange) {
    this.period = Math.abs(
      moment(dateRange.endExclusive).diff(dateRange.start, 'days')
    );
    this.requestPayload.metadata.selection!.startDate = dateRange.start;
    this.requestPayload.metadata.selection!.endDate = dateRange.endExclusive;
    this.getData();
  }

  /**
   * Get kpi-data remote from API
   */
  async getData() {
    try {
      this.isLoading = true;
      this.requestPayload.metadata.timezone = unref(
        this.context
      )?.primaryOrgTimeZone;
      this.requestPayload.metadata.filter.organizationIds = unref(
        this.context
      ).organizationIds;
      const response = await getKpiData(
        this.requestPayload,
        unref(this.context)
      );
      if (
        response.hasOwnProperty('data') &&
        response?.data?.details?.length > 0
      ) {
        this.handleChartData(response.data.details[0].fields);
      }
    } catch (err) {
      console.error(err);
    } finally {
      this.isLoading = false;
    }
  }

  /**
   * Handle fleet performance chart data
   */
  handleChartData(responseToBeProcessed: KpiDataField[]): void {
    const totalData = generateFleetPerformanceChartData(
      responseToBeProcessed,
      this.period,
      this.unitConversion.currentUserConvertNumber
    );
    this.chartData = totalData;
  }
}
</script>

<template>
  <WidgetCard v-if="!expanded" :loading="isLoading" :expandable="true">
    <div style="padding: 10px">
      <TimeSelect
        :expanded="true"
        @select="handleTimeFilter"
        :customizable="true"
      />
    </div>
    <div class="no-data-message" v-if="chartData.length == 0 && !isLoading">
      {{ $t('common.noAvailableDataToProcessForThisWidget') }}
    </div>
    <div v-else class="performance-container">
      <FleetPerformanceScatterChart :data="chartData" :topBottomOnly="5" />
    </div>
  </WidgetCard>
  <FleetPerformanceExpanded v-else />
</template>

<style scoped>
.no-data-message {
  font-weight: 500;
  margin: 0 0 0 20px;
}
</style>

<style lang="scss" scoped>
.time-selector {
  padding: 16px 0px 0px 28px;
}

.safety-container {
  width: fit-content;
  display: flex;
  flex-direction: column;
  padding: 18px 30px 18px 20px;
}

.performance-container {
  display: flex;
  justify-content: center;
  position: relative;
  height: 85%;

  @media (max-width: 769px) {
    height: 380px;
  }
}
</style>
