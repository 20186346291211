<script lang="ts">
import { UserModule } from '@/store/modules/user';
import { scrollTo } from '@/utils/scrollTo';
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
  name: 'Pagination',
})
export default class extends Vue {
  @Prop({ required: true }) total!: number;
  @Prop({ default: 1 }) page!: number;
  @Prop({ default: UserModule.gridPageSize }) limit!: number;
  @Prop({ default: () => [5, 10, 30, 50] }) pageSizes!: number[];
  @Prop({ default: 'prev, pager, next' }) layout!: string;
  @Prop({ default: true }) background!: boolean;
  @Prop({ default: true }) autoScroll!: boolean;
  @Prop({ default: false }) hidden!: boolean;

  get currentPage() {
    return this.page;
  }

  set currentPage(value) {
    this.$emit('update:page', value);
  }

  get pageSize() {
    return this.limit;
  }

  set pageSize(value) {
    this.$emit('update:limit', value);
  }

  handleSizeChange(value: number) {
    this.$emit('pagination', { page: this.currentPage, limit: value });
    if (this.autoScroll) {
      scrollTo(0, 800);
    }
  }

  handleCurrentChange(value: number) {
    this.$emit('pagination', { page: value, limit: this.pageSize });
    if (this.autoScroll) {
      scrollTo(0, 800);
    }
  }
}
</script>

<template>
  <div :class="{ hidden: hidden }" class="pagination-container">
    <el-pagination
      :background="background"
      :current-page.sync="currentPage"
      :page-size="limit"
      :layout="layout"
      :page-sizes="pageSizes"
      :total="total"
      v-bind="$attrs"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
    />
  </div>
</template>

<style lang="scss" scoped>
.pagination-container {
  background: #fff;
  padding: 16px 16px 0px;
  text-align: center;
}

.pagination-container.hidden {
  display: none;
}
</style>
