import { Geofence } from '@/api/geofenceTypes';
import { latLng, latLngBounds } from 'leaflet';

export function getBounds(geofence: Geofence) {
  return latLngBounds(
    geofence.geofencePosition.map((x) =>
      latLng(x.geodeticLatitude, x.geodeticLongitude)
    )
  );
}
