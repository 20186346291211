import type Keycloak from 'keycloak-js';
import { provideContexts } from './context';
import { provideSelectedCustomerInfo } from './selectedCustomer';
import { provideUserConsentState } from './termsAndConditions';
import { provideToken, ProvideTokenOptions } from './token';
import { provideLoggedInUser } from './user';

/**
 * Set up authentication by providing the token, and logged-in user and security context.
 */
export function provideAuth(
  keycloak: Keycloak,
  options: ProvideTokenOptions
): void {
  const tokenInfoRef = provideToken(keycloak, options);
  provideLoggedInUser(tokenInfoRef);
  provideSelectedCustomerInfo();
  provideContexts();
  provideUserConsentState();
}
