<script lang="ts">
import { ActiveContext, useActiveContext } from '@/auth/context';
import NavPage from '@/components/nav/NavPage.vue';
import { UserModule } from '@/store/modules/user';
import { ALL_CLAIMS_CODES, COMPANY_TYPE } from '@/utils/workData/lookuptable';
import { Ref, unref } from 'vue';
import { Component, Vue } from 'vue-property-decorator';
import AssignedAssets from './assigned/AssignedAssets.vue';
import ConnectedAssets from './connect/ConnectedAssets.vue';
import RegisteredAssets from './register/RegisteredAssets.vue';

@Component({
  name: 'AssetManagement',
  components: {
    NavPage,
    RegisteredAssets,
    ConnectedAssets,
    AssignedAssets,
  },
})
export default class extends Vue {
  ALL_CLAIMS_CODES = ALL_CLAIMS_CODES;
  /** Local variables */
  activeName: string = 'registerAssets';
  previousTabIndex: number = 0;
  allowAssignAsset: boolean = true;
  context!: Ref<ActiveContext>;

  /** Create initialization - need to set all btn to empty array on this index page */
  created() {
    this.context = useActiveContext();
    this.allowAssignAsset = ![
      COMPANY_TYPE.BodyBuilder.toString(),
      COMPANY_TYPE.Dealer.toString(),
    ].includes(UserModule.companyType);
    this.showDefaultTab();
  }

  /** Handle tab click */
  handleTabClick() {
    if (
      this.$route.name != this.activeName &&
      this.activeName === 'registeredAssets'
    )
      this.$router.push('/asset-mgmt/registeredAssets');
    if (
      this.$route.name != this.activeName &&
      this.activeName === 'connectedAssets'
    )
      this.$router.push('/asset-mgmt/connectedAssets');
    if (
      this.$route.name != this.activeName &&
      this.activeName === 'assignedAssets'
    )
      this.$router.push('/asset-mgmt/assignedAssets');
  }

  /** Handle display tabs */
  showDefaultTab() {
    if (this.$route.name) {
      this.activeName = this.$route.name as string;
      return;
    }
    this.activeName = 'registeredAssets';
  }

  checkClaim(claim: ALL_CLAIMS_CODES) {
    return unref(this.context).claims.hasClaim(claim);
  }
}
</script>

<template>
  <NavPage :visible="false">
    <template #button v-if="activeName === 'registeredAssets'">
      <el-button
        v-if="checkClaim(ALL_CLAIMS_CODES.AUTHRSC_ACTION_ASSET_REGISTER)"
        type="plain"
        @click="$router.push('/asset-mgmt/registering-asset')"
      >
        <i class="el-icon-plus common-icon" />{{
          $t('assetMgmt.registerAsset')
        }}
      </el-button>
    </template>

    <template #button v-else-if="activeName === 'connectedAssets'">
      <div>
        <el-button
          v-if="checkClaim(ALL_CLAIMS_CODES.AUTHRSC_ACTION_ASSET_ASSIGN)"
          type="plain"
          @click="$router.push('/asset-mgmt/assign-asset')"
        >
          <i class="el-icon-plus common-icon" />{{
            $t('assetMgmt.assignAsset')
          }}
        </el-button>
      </div>
    </template>
    <el-tabs
      id="prod_cata_tabs"
      v-model="activeName"
      @tab-click="handleTabClick"
      style="padding: 0 20px"
    >
      <el-tab-pane
        id="asset_mgmt_reg_asset"
        name="registeredAssets"
        :label="$t('assetMgmt.registerAssets')"
      >
        <RegisteredAssets v-if="activeName === 'registeredAssets'" />
      </el-tab-pane>
      <el-tab-pane
        id="asset_mgmt_connect_asset"
        name="connectedAssets"
        :label="$t('assetMgmt.connectedAssets')"
      >
        <ConnectedAssets v-if="activeName === 'connectedAssets'" />
      </el-tab-pane>
      <el-tab-pane
        id="asset_mgmt_assign_asset"
        name="assignedAssets"
        :label="$t('assetMgmt.assignedAssets')"
      >
        <AssignedAssets v-if="activeName === 'assignedAssets'" />
      </el-tab-pane>
    </el-tabs>
  </NavPage>
</template>

<style lang="scss" scoped>
.user-setting-form {
  margin: 0 20px;
}
</style>
