import { getRequest } from '@/utils/request';
import { CommonResultPromise } from './commonResult';

export function getSystemDeployment(
  id: string
): CommonResultPromise<SystemDeployment> {
  const request = getRequest();
  const response = request.get<SystemDeployment>(`/system-deployment/${id}`);
  return response;
}

export function getDeploymentForSpecifiedAsset(
  id: string,
  request = getRequest()
): CommonResultPromise<AssetDeploymentLog> {
  const response = request.get<AssetDeploymentLog>(
    `/system-deployments/logs?assetDeploymentId=${id}`
  );
  return response;
}

export function cancelSystemReleaseDeployment(
  deploymentId: string
): CommonResultPromise<InterruptedSystemDeploymentResponse> {
  const request = getRequest();
  const response = request.put<InterruptedSystemDeploymentResponse>(
    `/system-deployments/${deploymentId}/cancel`
  );
  return response;
}

export function holdSystemReleaseDeployment(
  deploymentId: string
): CommonResultPromise<InterruptedSystemDeploymentResponse> {
  const request = getRequest();
  const response = request.put<InterruptedSystemDeploymentResponse>(
    `/system-deployments/${deploymentId}/hold`
  );
  return response;
}

export function resumeSystemReleaseDeployment(
  deploymentId: string
): CommonResultPromise<InterruptedSystemDeploymentResponse> {
  const request = getRequest();
  const response = request.put<InterruptedSystemDeploymentResponse>(
    `/system-deployments/${deploymentId}/resume`
  );
  return response;
}

export function holdAssetReleaseDeployment(
  id: string
): CommonResultPromise<InterruptedAssetDeploymentResponse> {
  const request = getRequest();
  const response = request.put<InterruptedAssetDeploymentResponse>(
    `/system-deployments/assets/${id}/hold`
  );
  return response;
}

export function cancelAssetReleaseDeployment(
  id: string
): CommonResultPromise<InterruptedAssetDeploymentResponse> {
  const request = getRequest();
  const response = request.put<InterruptedAssetDeploymentResponse>(
    `/system-deployments/assets/${id}/cancel`
  );
  return response;
}

export function resumeAssetReleaseDeployment(
  id: string
): CommonResultPromise<InterruptedAssetDeploymentResponse> {
  const request = getRequest();
  const response = request.put<InterruptedAssetDeploymentResponse>(
    `system-deployments/assets/${id}/resume`
  );
  return response;
}

export interface SystemDeployment {
  noOfCancelledDeploys: number;
  noOfFailedDeploys: number;
  noOfInvolvedAssets: number;
  noOfRevertedDeploys: number;
  noOfSuccessDeploys: number;
  systemReleaseDeploymentEndTime: string;
  systemReleaseDeploymentStartTime: string;
  systemReleaseDeploymentStatus: string;
  systemDeploymentId: string;
  systemReleaseId: string;
  companyId: string;
  companyName: string;
  assets: SystemDeploymentAsset[];
}

export interface SystemDeploymentAsset {
  assetDeploymentId: string;
  companyAssetId: string;
  deployStatus: string;
  deploymentEndTime: string;
  deploymentStartTime: string;
  organizationId: string;
  organizationName: string;
  deploymentControl: string;
  deploymentDuration?: string; // used for caculation in FE on column Time Spent
  assetDeployStatus?: string; // used for same internal logic calculation for status
}

export interface InterruptedAssetDeploymentResponse {
  deploymentControl: string;
}

export interface InterruptedSystemDeploymentResponse {
  systemReleaseDeploymentStatus: string;
}

export interface AssetDeploymentLog {
  assetDeployStatus: string;
  assetType: string;
  deploymentControl: string;
  deploymentDuration: number;
  deploymentStartTime: string;
  deploymentEndTime?: string;
  organizationId: string;
  organizationName: string;
  partUpdateProgress: PartUpdateProgress[];
  productModelCode: string;
  productModelNumber: string;
  systemReleaseIdBeforeDeployment: string;
  companyAssetId?: string;
}

export interface PartUpdateProgress {
  containedPartCode: string;
  containedPartName: string;
  deployStartTime: string;
  deployEndTime?: string;
  obuInstance: string;
  partReleaseDeploymentDuration: number;
  partReleaseDeploymentStatus: string;
  releaseBeforeDeployId: string;
}
