<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import { RELEASES_MANAGEMENT_COLS } from '@/utils/workData/releasesManagementCols';
import PureTable from '@/components/table/PureTable.vue';
import moment from 'moment';
import {
  getFilteredReleases,
  SystemReleaseResponse,
  SystemRelease,
} from '@/api/systemReleaseManagement';
import {
  GENERAL_QUERY_OPERATORS,
  PROPER_ASSET_TYPE,
  SYSTEM_RELEASE_MATURITY_LEVEL,
  SYSTEM_RELEASE_STATUS,
} from '@/utils/workData/lookuptable';
import { UserModule } from '@/store/modules/user';
import Debouncer from '@/utils/debounce';

@Component({
  name: 'releasesManagement',
  components: {
    PureTable,
  },
})
export default class extends Vue {
  /** Local variables */
  cols = RELEASES_MANAGEMENT_COLS.map((item) => ({
    ...item,
    label: this.$t(item.label),
  }));
  pageTotal = 1;
  data: SystemRelease[] = [];
  searchField: string = '';
  numberOfReleases: number = 0;
  releaseManagementPageIsLoading: boolean = false;
  filterList: {
    field: string;
    operator: GENERAL_QUERY_OPERATORS;
    label?: string;
    dropdownEnum?: any;
  }[] = [
    {
      field: 'systemReleaseId',
      operator: GENERAL_QUERY_OPERATORS.Like,
      label: 'releasesManagement.productSystemReleaseId',
    },
    {
      field: 'maturityLevel',
      operator: GENERAL_QUERY_OPERATORS.Equal,
      dropdownEnum: SYSTEM_RELEASE_MATURITY_LEVEL,
    },
    {
      field: 'releaseStatus',
      operator: GENERAL_QUERY_OPERATORS.Equal,
      dropdownEnum: SYSTEM_RELEASE_STATUS,
    },
    {
      field: 'assetType',
      operator: GENERAL_QUERY_OPERATORS.Like,
      dropdownEnum: PROPER_ASSET_TYPE,
    },
    {
      field: 'productModelCode',
      operator: GENERAL_QUERY_OPERATORS.Like,
      label: 'releasesManagement.productModel',
    },
    {
      field: 'productModelNumber',
      operator: GENERAL_QUERY_OPERATORS.Like,
      label: 'releasesManagement.modelNumber',
    },
    {
      field: 'defaultRelease',
      operator: GENERAL_QUERY_OPERATORS.Equal,
      dropdownEnum: { Yes: 'true', No: 'false' },
    },
  ];
  filterEnum: any = null;
  pagination = {
    page: 1,
    size: UserModule.gridPageSize,
  };
  sorter = [];
  filter = {
    name: this.filterList[0].field,
    operator: this.filterList[0].operator,
    value: [''],
  };
  appliedFilters: {
    filters: any[];
    sorters: any[];
    pagination: { page: number; size: number };
  } = {
    filters: [],
    sorters: this.sorter,
    pagination: this.pagination,
  };

  created() {
    this.getData();
  }

  /**
   * Handle search filter event
   */
  handleSearchFilterSubmit(): void {
    if (this.searchField.length < 1) {
      this.handleClear();
      return;
    }
    Debouncer.debounce(this.submitSearch);
  }

  /**
   * Submit search event
   */
  submitSearch(): void {
    if (this.searchField.length > 0 && this.filter.name.length > 0) {
      this.filter.value[0] = this.searchField;
      this.appliedFilters.filters = [this.filter];
      this.getData();
    }
  }

  /**
   * Cleanup
   */
  handleClear(): void {
    this.appliedFilters.filters = [];
    this.getData();
  }

  /**
   * Handle search input
   */
  handleSearchInput(): void {}

  /**
   * Handle search when input is empty
   * @param value
   */
  handleInputEmpty(value: string): void {
    if (!value) this.handleSearchInput();
  }

  /**
   * Handle page event
   * @param page
   */
  handlePage(page: number): void {
    this.pagination.page = page;
    this.getData();
  }

  /**
   * Handle sort change
   * @param sortField
   * @param sortOrder
   */
  async handleSortChange(sortField: string, sortOrder: string): Promise<void> {
    this.appliedFilters.sorters = [];
    if (sortOrder) {
      this.appliedFilters.sorters.push({
        field: sortField,
        order: sortOrder,
      });
    }
    await this.getData();
  }

  /**
   * Handle filter event
   */
  handleReleaseFilter(): void {
    this.searchField = '';
    this.filter.operator =
      this.filterList.find((f) => f.field === this.filter.name)?.operator ??
      GENERAL_QUERY_OPERATORS.Like;
    this.filter.value = [];
    this.filterEnum = this.filterList.find(
      (e) => e.field === this.filter.name
    )?.dropdownEnum;
    this.handleClear();
  }

  /**
   * Handle get data from API
   */
  async getData(): Promise<void> {
    try {
      this.releaseManagementPageIsLoading = true;
      const response = await getFilteredReleases(this.appliedFilters);
      this.pageTotal = response.data.total;
      for (let i in response.data.systemReleases) {
        response.data.systemReleases[i].deploymentDuration = moment
          .utc(
            response.data.systemReleases[i].deploymentDurationMinutes *
              1000 *
              60
          )
          .format('HH:mm:ss');
        if (response.data.systemReleases[i].defaultRelease)
          response.data.systemReleases[i].defaultRelease =
            this.$t('userModule.yes').toString();
        if (!response.data.systemReleases[i].defaultRelease)
          response.data.systemReleases[i].defaultRelease =
            this.$t('userModule.no').toString();
      }
      this.data = response.data.systemReleases.map((item) => ({
        ...item,
        assetType: this.$t(item.assetType),
        releaseStatus: this.$t(item.releaseStatus),
        maturityLevel: this.$t(item.maturityLevel),
      }));
      this.numberOfReleases = response.data.total;
    } catch (err) {
      console.log(err);
    } finally {
      this.releaseManagementPageIsLoading = false;
    }
  }

  /**
   * Change route to add new release page
   */
  addNewRelease(): void {
    this.$router.push(`/release/new`);
  }
}
</script>

<template>
  <div class="table-container">
    <div class="page-title-container">
      <span class="page-content-title">{{
        $t('releaseManagementModule.title')
      }}</span>
      <p>{{ $t('releaseManagementModule.total') }}: {{ numberOfReleases }}</p>
    </div>
    <div class="filter-container">
      <div class="d-flex ai-center search-select-container">
        <el-select
          class="search-select-field"
          id="releaseFilter"
          v-model="filter.name"
          @change="handleReleaseFilter"
          filterable
        >
          <el-option
            v-for="item in filterList"
            :key="item.label"
            :label="$t(item.label || `releasesManagement.${item.field}`)"
            :value="item.field"
          />
        </el-select>
        <el-select
          v-if="filterEnum"
          @change="handleSearchFilterSubmit"
          v-model="searchField"
          class="search-input-content"
        >
          <el-option
            class="filter-select-option"
            v-for="item of filterEnum"
            :key="item"
            :label="$t(item)"
            :value="item"
          />
        </el-select>
        <el-input
          v-else
          id="input-search-value"
          class="search-input-content"
          :placeholder="$t('common.inputKeywordToSearch')"
          v-model="searchField"
          @clear="handleClear"
          @input="handleInputEmpty"
          @keyup.native="handleSearchFilterSubmit"
          clearable
        >
          <i
            slot="suffix"
            class="el-icon-search"
            @click="handleSearchFilterSubmit"
          />
        </el-input>
      </div>
      <el-button
        class="new-release-button"
        id="newRelease"
        v-permission="['AUTHRSC_ACTION_RELEASE_ACTIVATE']"
        type="plain"
        @click="addNewRelease"
      >
        <img class="add-icon" src="@/icons/svg/addRelease.svg" />
        {{ $t('releaseManagementModule.newRelease') }}
      </el-button>
    </div>
    <PureTable
      v-loading="releaseManagementPageIsLoading"
      class="release-table"
      :tableList="data"
      :total="pageTotal"
      :cols="cols"
      :viewPagePath="'/release-view'"
      :showTableHeaderOptions="false"
      @handle-page="handlePage"
      @handle-sort-change="handleSortChange"
    />
  </div>
</template>

<style lang="scss" scoped>
.page-title-container {
  display: flex;
  padding: 0 0 18px 0;
  flex-direction: row;
  border-bottom: 1px solid #dddddd;

  p {
    margin: auto auto auto 10px;
    opacity: 0.4;
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
  }
}

.new-release-button {
  margin: auto 0 auto auto;
}

.add-icon {
  margin: auto 15px auto auto;
}

.filter-container {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  padding: 18px 0;
}

.filter-relese {
  margin: 0;
}

.search-input-content {
  width: 400px;
}

.table-container {
  width: 100%;
  padding: 16px;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 3px 6px #c1c1c1;
  display: flex;
  flex-direction: column;
}

.search-select-container {
  :deep(.el-input__inner) {
    height: 40px;
    border: 1px solid #818181;
  }

  .search-select-field :deep(.el-input__inner) {
    width: 252px;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    border-right: transparent !important;
  }

  .search-select-field {
    margin: 0;
  }

  .search-input-content :deep(.el-input__inner) {
    width: 270px;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
  }

  .search-input-content :deep(.el-input__suffix) {
    line-height: 40px;
    color: rgba(0, 0, 0, 0.6);
    font-size: 18px;
    cursor: pointer;
    width: 282px;
  }
}
</style>
