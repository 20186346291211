<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
  name: 'CommonBtn',
})
export default class extends Vue {
  @Prop({ default: 'info' }) type!: string;
  @Prop() content!: string;
  @Prop({ default: false }) disabled!: boolean;

  handleBtn() {
    this.$emit('handle-btn');
  }
}
</script>

<template>
  <el-button
    class="common-button"
    :disabled="disabled"
    :type="type"
    @click="handleBtn"
  >
    {{ $t(content) }}
  </el-button>
</template>

<style lang="scss" scoped>
.common-button {
  width: $formButtonWidth;
  height: 40px;
  font-size: 1.285714rem;
}

@media (max-width: 768px) {
  .common-button {
    width: 250px;
  }
}
</style>
