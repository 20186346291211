<script lang="ts">
import { CustomerModel, getCustomersByCustomUrl } from '@/api/customer';
import PureTable from '@/components/table/PureTable.vue';
import SelectTableHeader from '@/components/table/SelectTableHeader.vue';
import { CustMgmtModule } from '@/store/modules/custMgmt';
import { UserModule } from '@/store/modules/user';
import { customFailedMessage } from '@/utils/prompt';
import {
  CUSTOMER_COLS,
  CUST_SELECTION_OPTIONS,
} from '@/utils/workData/customerMgmt';
import { COMPANY_TYPE_LIST } from '@/utils/workData/lookuptable';
import { Component, Vue } from 'vue-property-decorator';

export interface TableData extends CustomerModel {
  translatedServiceRegion: string;
  translatedHyvaRegion: string;
}

@Component({
  name: 'CustomerManagement',
  components: {
    SelectTableHeader,
    PureTable,
  },
})
export default class extends Vue {
  /** Local variables */
  customerTableColumns = CUSTOMER_COLS.map((item) => ({
    ...item,
    label: this.$t(item.label),
  }));
  companyTypes = COMPANY_TYPE_LIST;
  searchFieldOptions = CUST_SELECTION_OPTIONS;
  loadingText: string = this.$t('customerModule.loadingCustomerData') as string;
  total: number = 0;
  currentPage = 1;
  pageSize = UserModule.gridPageSize;
  customerTableIsLoading: boolean = false;
  listLoading: boolean = false;
  list: TableData[] = [];
  companyType: string = '';
  sortAndOrderData: any = {
    sortBy: null,
    order: null,
  };
  searchParams: any = {
    reference: null,
    value: null,
  };
  defaultSort: string = 'createdOn';
  defaultOrder: string = 'DESC';

  created() {
    this.prepareDefaultInitialization();
  }

  /** Prepare default initialization */
  prepareDefaultInitialization() {
    let finalUrlParamsForSearch: string = this.generateRequestUrlWithParams(
      this.currentPage,
      this.pageSize,
      null,
      this.defaultSort,
      this.defaultOrder
    );
    this.fetchCustomersRemoteByCustomUrl(finalUrlParamsForSearch);
    CustMgmtModule.setReStore(false);
    CustMgmtModule.clearData();
  }

  /** Fetch when search is triggered */
  fetchCompaniesBySearchParams() {
    let finalUrlParamsForSearch: string = this.generateRequestUrlWithParams(
      this.currentPage,
      this.pageSize,
      this.searchParams,
      this.sortAndOrderData.sortBy,
      this.sortAndOrderData.order
    );
    this.fetchCustomersRemoteByCustomUrl(finalUrlParamsForSearch);
  }

  /** Filter by sort event */
  fetchCompaniesBySortEvent(sortBy: any, order: any) {
    order != ''
      ? (this.sortAndOrderData.sortBy = sortBy)
      : (this.sortAndOrderData.sortBy = null);
    order != ''
      ? (this.sortAndOrderData.order = order)
      : (this.sortAndOrderData.order = null);
    let finalUrlParamsForSearch: string = this.generateRequestUrlWithParams(
      this.currentPage,
      this.pageSize,
      this.searchParams,
      this.sortAndOrderData.sortBy,
      this.sortAndOrderData.order
    );
    this.fetchCustomersRemoteByCustomUrl(finalUrlParamsForSearch);
  }

  /** Handle pagination */
  fetchCompaniesByPageSelection(page: number, pageSize: number) {
    this.currentPage = page;
    let finalUrlParamsForSearch: string = this.generateRequestUrlWithParams(
      page,
      pageSize,
      this.searchParams,
      this.sortAndOrderData.sortBy,
      this.sortAndOrderData.order
    );
    this.fetchCustomersRemoteByCustomUrl(finalUrlParamsForSearch);
  }

  /** Generate request URL by multiple factors */
  generateRequestUrlWithParams(
    pageNumber: any,
    pageSize: number,
    searchParams: any,
    sortBy: any,
    order: any
  ) {
    let searchFieldName = searchParams ? searchParams.reference : null;
    let searchFieldValue = searchParams ? searchParams.value : null;
    let finalUrl = '';

    pageNumber ? (finalUrl += `?page=${pageNumber}`) : 1;
    pageNumber ? (finalUrl += `&size=${pageSize}`) : this.pageSize;

    if (searchFieldName && searchFieldValue) {
      finalUrl += `&searchFieldName=${searchFieldName}&searchFieldValues=${encodeURIComponent(
        searchFieldValue
      )}`;
    }

    if (sortBy && order) {
      finalUrl += `&sortBy=${sortBy}&order=${order}`;
    } else {
      finalUrl += `&sortBy=${this.defaultSort}&order=${this.defaultOrder}`; // default must be sorted by createdOn DESC !
    }

    return finalUrl;
  }

  /** Remote fetch companies by custom URL */
  async fetchCustomersRemoteByCustomUrl(customUrl: any) {
    this.customerTableIsLoading = true;
    await getCustomersByCustomUrl(customUrl).then((res) => {
      if (!res) return;

      if (res.code && res.code === 200) {
        this.total = res.data.total;
        this.list = res.data.companies.map((item) => ({
          ...item,
          companyType: this.$t(item.companyType),
          activationStatus: this.$t(item.activationStatus),
          translatedServiceRegion: this.$t(item.serviceRegion),
          translatedHyvaRegion: this.$t(item.hyvaRegion),
        }));
      }

      if (res.code === 500)
        customFailedMessage(this.$t('common.serverError').toString());

      this.customerTableIsLoading = false;
    });
  }
}
</script>

<template>
  <div class="app-container" style="overflow: hidden">
    <div class="d-flex jc-start ai-center" style="margin-top: -8px">
      <div class="cust-mgmt-title">
        {{ $t('customerModule.custsMgmt') }}
      </div>
      <div
        id="cust_home_custTotalInfo"
        class="d-flex ai-center flex-g1 cust-total-statistics"
      >
        <span style="margin-right: 5px"
          >{{ $t('customerModule.customerTotalInfo') }}:</span
        >
        <span v-loading="customerTableIsLoading"> {{ total }} </span>
      </div>
      <div>
        <el-button
          id="cust_home_btn_create"
          v-permission="['AUTHRSC_ACTION_COMPANY_CREATE']"
          type="plain"
          @click="$router.push({ name: 'CustomerManagementCreate' })"
        >
          <i class="el-icon-plus common-icon" />{{
            $t('customerModule.createNewCustomer')
          }}</el-button
        >
      </div>
      <!-- <div style="margin-left: 20px">
                <el-button type="info">
                    <svg-icon
                        :name="`export`"
                        style="margin-right: 5px"
                    />{{$t('customerModule.exportReport')}}
                </el-button>
            </div> -->
    </div>

    <div class="border-line"></div>

    <el-row>
      <SelectTableHeader
        style="margin-bottom: 20px"
        :searchFieldOptions="searchFieldOptions"
        :cols="customerTableColumns"
        :searchParams="searchParams"
        @search-event="fetchCompaniesBySearchParams"
      />
      <PureTable
        v-loading="customerTableIsLoading"
        :element-loading-text="loadingText"
        :tableList="list"
        :total="total"
        :cols="customerTableColumns"
        :viewPagePath="'view'"
        @handle-page="fetchCompaniesByPageSelection"
        @handle-sort-change="fetchCompaniesBySortEvent"
      />
    </el-row>
  </div>
</template>

<style lang="scss" scoped>
.cust-mgmt-title {
  font-size: 20px;
  font-family: $font-Roboto-Medium;
  line-height: 24px;
  color: #373e41;
  margin-right: 16px;
}

.cust-total-statistics {
  font-size: 16px;
  font-family: $font-Roboto-Regular;
  line-height: 19px;
  color: rgba(55, 62, 65, 0.6);
}

.border-line {
  margin: 0.857143rem -1.428571rem 20px;
  border-bottom: 1px solid #dddddd;
}

.el-select {
  margin-right: 40px;
}

.cust-select :deep(.el-input__inner) {
  height: $inputHeight;
  width: 200px;
  border: 1px solid #707070 !important;
  font-size: 1.285714rem;
  font-family: $font-Roboto-Regular;
  line-height: 1.357143rem;
  color: #373e41;
  opacity: 1;
}
</style>
