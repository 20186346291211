<script setup lang="ts">
import WidgetDialog from '@/components/dialog/WidgetDialog.vue';

defineProps<{ companyAssetId: string | undefined }>();
const emit = defineEmits<{
  (e: 'cancel'): void;
  (e: 'confirm'): void;
}>();
</script>

<template>
  <WidgetDialog
    :visible="companyAssetId !== undefined"
    :title="$t('subsMgmt.terminateSubscription')"
    :confirmBtnName="$t('subsMgmt.terminateBtn')"
    width="40%"
    @handle-cancel="emit('cancel')"
    @handle-confirm="emit('confirm')"
  >
    <div class="container">
      <img src="@/icons/svg/deactive.svg" style="width: 48px" />
      <i18n
        path="subsMgmt.terminateOrderModalMessage"
        tag="div"
        class="main-text"
      >
        <span class="company-asset-id-text" place="assetId">{{
          companyAssetId
        }}</span>
      </i18n>
    </div>
  </WidgetDialog>
</template>

<style scoped lang="scss">
.container {
  text-align: center;
  margin-top: 30px;
  word-break: normal;
}

.main-text {
  font-size: 20px;
  margin-top: 28px;
  margin-bottom: 87px;
  color: #373e41;
}

.company-asset-id-text {
  font-weight: bold;
  color: #ffcd00;
}
</style>
