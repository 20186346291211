import { getHyvaRegions, HyvaRegion } from '@/api/hyvaRegion';
import { useQuery, UseQueryReturnType } from '@tanstack/vue-query';
import { computed } from 'vue';
import { NO_BACKGROUND_FETCH_OPTIONS } from './common';

const queryKeys = {
  hyvaRegions: () =>
    ({
      query: 'hyva-regions',
    } as const),
};

export function useHyvaRegionsQuery(): UseQueryReturnType<HyvaRegion[], Error> {
  return useQuery({
    ...NO_BACKGROUND_FETCH_OPTIONS,
    staleTime: 60 * 1000 * 10,
    queryKey: [computed(() => queryKeys.hyvaRegions())],
    queryFn: () => getHyvaRegions(),
  });
}
