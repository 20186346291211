import { UUID } from '@/api/common';
import { Organization } from '@/api/organizations';
import { useMergedRouteMeta, useRoute } from '@/composables/router';
import { mapDeep } from '@/utils/collections';
import { distinct, MutableRef } from '@/utils/ref';
import { injectLocal, provideLocal } from '@vueuse/core';
import { computed, InjectionKey, ref, Ref, unref } from 'vue';
import { Route } from 'vue-router';
import { Claims } from './claims';
import { ContextType } from './contextType';
import { useSelectedCustomerInfo } from './selectedCustomer';
import { useLoggedInUser } from './user';

export interface ActiveContext {
  /**
   * Selected company UUID.
   *
   * This value will be set only if it differs from the logged in user's company.
   * It can only be used by specific types of users, e.g. Hyva Admin, helpdesk, BB.
   */
  readonly selectedCompanyId: UUID | undefined;

  /**
   * Impersonated company UUID.
   *
   * This will be the same as `selectedCompanyId`, but will only be set for users
   * can and must use the impersonation mechanism in order to access data from this
   * other customer.
   */
  readonly impersonatedCompanyId: UUID | undefined;

  /**
   * Selected organization, if an organization is actually selected.
   */
  readonly organization: Organization | undefined;

  readonly isSubOrgSelection: boolean;

  /**
   * List of selected organization UUID's to be used for filtering
   * assets within the current company (logged in user's company or
   * selectedCustomer).
   * If undefined, no specific filtering on organization IDs should
   * be performed.
   *
   * Derived by flattening the tree of the selected organization.
   */
  readonly organizationIds: string[] | undefined;

  /**
   * Currently active claims (typically from logged in user).
   */
  readonly claims: Claims;

  /**
   * Current time zone of the primary organization for the selected customer
   */
  readonly primaryOrgTimeZone: string | undefined;
}

interface Contexts {
  readonly selectedOrganization: MutableRef<string | undefined>;
  readonly activeContext: Ref<ActiveContext>;
}

const contextsKey: InjectionKey<Contexts> = Symbol('contexts');
const selectedOrganizationStorageKey = 'selectedOrganization';

/**
 * Instantiate new Contexts (for logged in user and selected customer),
 * and inject them for any child components to be used.
 *
 * This must be called somewhere near the root of the application, at least
 * 'above' any child component that wants to make use of contexts.
 */
export function provideContexts(): void {
  function makeStoredRef(storageKey: string): MutableRef<string | undefined> {
    // TODO Use useSessionStorage from vueuse
    const storedValue = sessionStorage.getItem(storageKey) ?? undefined;
    const theRef = ref(storedValue);
    return {
      ref: distinct(theRef, undefined),
      update: (newValue) => {
        theRef.value = newValue;
        if (newValue === undefined) {
          sessionStorage.removeItem(storageKey);
        } else {
          sessionStorage.setItem(storageKey, newValue);
        }
      },
    };
  }

  const contextType = useContextTypeFromRoute();
  const selectedCustomerInfo = useSelectedCustomerInfo();
  const loggedInUserRef = useLoggedInUser();

  const activeContext = computed((): ActiveContext => {
    const activeType = unref(contextType);
    const loggedIn = unref(loggedInUserRef);
    if (!loggedIn) {
      // TODO Ideally, just return undefined, or a 'special' empty object
      return {
        impersonatedCompanyId: undefined,
        selectedCompanyId: undefined,
        isSubOrgSelection: false,
        organization: undefined,
        organizationIds: undefined,
        claims: new Claims([]),
        primaryOrgTimeZone: undefined,
      };
    }
    let selectedOrg: Organization | undefined;
    switch (activeType) {
      case ContextType.LoggedInUser:
        selectedOrg = loggedIn.organization;
        break;
      case ContextType.SelectedCustomer: {
        const selectedCustomer = unref(selectedCustomerInfo.selectedCustomer);
        const selectedOrganizationId = unref(contexts.selectedOrganization.ref);
        if (selectedCustomer?.organization) {
          selectedOrg = selectedCustomer.organization;
        } else if (selectedOrganizationId) {
          const flattened = flattenOrganizations(loggedIn.organization);
          selectedOrg = flattened.find(
            (org) => org.id === selectedOrganizationId
          );
        } else {
          selectedOrg = undefined;
        }
        break;
      }
    }
    const selectedCompanyId =
      loggedIn.companyId === selectedOrg?.companyId
        ? undefined
        : selectedOrg?.companyId;
    return {
      selectedCompanyId,
      impersonatedCompanyId: loggedIn.mustImpersonate
        ? selectedCompanyId
        : undefined,
      organization: selectedOrg,
      isSubOrgSelection: selectedOrg?.parentId !== undefined,
      organizationIds: selectedOrg
        ? mapDeep(selectedOrg, 'children', (org) => org.id)
        : undefined,
      claims: loggedIn.claims,
      primaryOrgTimeZone:
        selectedOrg?.timezone ?? loggedIn.organization.timezone,
    };
  });

  const contexts: Contexts = {
    selectedOrganization: makeStoredRef(selectedOrganizationStorageKey),
    activeContext,
  };
  provideLocal(contextsKey, contexts);
}

/**
 * Obtain the default context to use for requests within this part of the application,
 * based on the `context` key in the route's meta.
 */
export function useContextTypeFromRoute(
  route: Route | Ref<Route> = useRoute()
): Ref<ContextType> {
  const routeMetaRef = useMergedRouteMeta(route);
  return distinct(
    () => unref(routeMetaRef).context ?? ContextType.LoggedInUser
  );
}

/**
 * Obtain reference to the contexts provided by a parent component.
 */
function useContexts(): Contexts {
  const contexts = injectLocal(contextsKey);
  if (!contexts) {
    throw new Error(
      'No Contexts provided, use `provideContexts()` somewhere near the root of the app'
    );
  }
  return contexts;
}

/**
 * Use the active request context.
 *
 * Use the 'default' context based on whatever the route indicates this should be
 * in this part of the application.
 *
 * The returned ref updates whenever the user context changes, and/or the route changes to
 * a different default context.
 *
 * WARNING: Never mutate the returned context directly!
 */
export function useActiveContext(): Ref<Readonly<ActiveContext>> {
  return useContexts().activeContext;
}

/**
 * Obtain Ref of Selected Organization context.
 *
 * Useful for e.g. Customer users to select a child organization.
 */
export function useSelectedOrganization(): MutableRef<string | undefined> {
  const contexts = useContexts();
  return contexts.selectedOrganization;
}

// TODO Move these to more appropriate places
/**
 * Filter given set of organizations down to given list of ids.
 */
export function filterOrganizations(
  organizations: Organization[],
  ids: string[]
): Organization[] {
  return organizations.filter((org) => ids.includes(org.id));
}

// TODO Move these to more appropriate place and use new map helpers
/**
 * Return a 'flat' array of all organizations passed in.
 *
 * Note that the resulting list still contains children properties with their child organizations.
 */
export function flattenOrganizations(
  organizations: Organization | Organization[]
): Organization[] {
  if (!Array.isArray(organizations)) {
    organizations = [organizations];
  }
  return organizations.flatMap((org) => [
    org,
    ...flattenOrganizations(org?.children),
  ]);
}
