<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
  name: 'RenewSubscriptionModal',
})
export default class extends Vue {
  @Prop({ default: false }) visible!: boolean;
  @Prop({ required: true }) title!: string;
  @Prop() activateOrDeactivate!: string;
  @Prop({ required: true }) modalContent!: string;

  closeDialog() {
    this.$emit('handle-cancel');
  }

  handleDialogCancel() {
    this.$emit('handle-cancel');
  }

  handleRenewSubscription() {
    this.$emit('handle-renew-subscription');
  }
}
</script>

<template>
  <div class="stop-subscription-modal-container">
    <el-dialog
      :visible="visible"
      class="subscribed-report-modal"
      :width="'600px'"
      :top="'25vh'"
      :title="$t(title)"
      @close="closeDialog"
    >
      <div class="d-flex ai-center" style="flex-direction: column">
        <div
          class="stop-modal-content"
          style="margin: 10px 30px 30px; font: 400 20px/36px Roboto"
          v-html="modalContent"
        ></div>
        <div style="margin: 10px 0px">
          <el-button
            class="deactivate-btn"
            style="margin-right: 30px"
            type="plain"
            @click="handleDialogCancel"
            >{{ $t('customerModule.cancel') }}</el-button
          >
          <el-button
            class="deactivate-btn"
            type="info"
            @click="handleRenewSubscription"
            >{{ $t('report.resumeSubscription') }}</el-button
          >
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<style scoped>
.stop-subscription-modal-container :deep(.stop-modal-content) {
  text-align: center;
  word-break: break-word;
}

.stop-subscription-modal-container :deep(.highlight-modal-info) {
  color: var(--Main) !important;
}

.subscribed-report-modal :deep(.el-dialog__title) {
  font-size: 20px;
  font-family: var(--fontRobotoMedium);
  line-height: 19px;
  color: #373e41;
  margin-left: 35%;
}

.subscribed-report-modal :deep(.el-dialog__headerbtn) {
  top: 10px;
  font-size: 30px;
  color: #373d41;
  position: static;
}
</style>

<style lang="scss" scoped>
.subscribed-report-modal :deep(.el-dialog__header) {
  padding: 0px;
  padding-bottom: 0px;
  background-color: var(--Main) !important;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 20px;
  padding-right: 16px;
  height: 44px;
}

.el-button--plain:hover {
  color: #ffffff;
  border-color: #5f6567;
  background-color: #5f6567;
}

.subscribed-report-modal :deep(.el-dialog__headerbtn:hover .el-dialog__close) {
  color: #5f6567;
}

.deactivate-btn {
  width: 190px;
  margin-right: 30px;
  font-family: $font-Roboto-Medium;
  font-size: 16px !important;
  border: 1px solid #373e41;
}
</style>
