var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"percentage"},[(
      _setup.formattedPercentageNumber !== undefined &&
      _setup.isStringNumberZero(_setup.formattedPercentageNumber)
    )?_c('span',{staticClass:"neutral"},[_c('span',{staticClass:"value"},[_vm._v(" "+_vm._s(_setup.formattedPercentageNumber.replace('-', ''))+"% ")])]):(_setup.props.growthPercentage && _setup.props.growthPercentage > 0)?_c('span',{class:{
      negative: _setup.props.isPositiveGrowthBad,
      positive: !_setup.props.isPositiveGrowthBad,
    }},[(_setup.props.isPositiveGrowthBad)?_c('img',{attrs:{"src":require("@/icons/svg/arrowUpRed.svg")}}):_c('img',{attrs:{"src":require("@/icons/svg/arrowUp.svg")}}),_c('span',{staticClass:"value"},[_vm._v(" "+_vm._s(_setup.formattedPercentageNumber)+"% ")])]):(_setup.props.growthPercentage && _setup.props.growthPercentage < 0)?_c('span',{class:{
      negative: !_setup.props.isPositiveGrowthBad,
      positive: _setup.props.isPositiveGrowthBad,
    }},[(_setup.props.isPositiveGrowthBad)?_c('img',{attrs:{"src":require("@/icons/svg/arrowDownGreen.svg")}}):_c('img',{attrs:{"src":require("@/icons/svg/arrowDown.svg")}}),_c('span',{staticClass:"value"},[_vm._v(" "+_vm._s(_setup.formattedPercentageNumber)+"% ")])]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }