<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import { USER_COLS } from '@/utils/workData/userMgmt';

@Component({
  name: 'TableContainer',
})
export default class extends Vue {
  filter: string = '';
  cols = USER_COLS;

  currentCol: string = 'firstName';

  handleMouseEvent(e: any) {
    // var dropDownMenu: any = document.querySelector("#dropDownMenu");
    const slotValue: any = this.$slots.default;
    const dropdownId: string = slotValue[0].context.activeName;

    var dropDownMenu: any = dropdownId
      ? document.querySelector('#' + slotValue[0].context.activeName)
      : document.querySelector('#dropDownMenu');
    var dropButton: any = document.getElementById('dropButton');

    if (dropDownMenu) {
      if (dropButton.id === e.target.id) {
        dropDownMenu.style.display = 'block';
      } else {
        dropDownMenu.style.display = 'none';
      }
    }
  }
}
</script>

<template>
  <div class="app-container" @click="handleMouseEvent">
    <slot></slot>
  </div>
</template>
