<script lang="ts">
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import GeneralInfo from '@/components/form/GeneralInfo.vue';
import { ACTIVATION_STATUS_LIST } from '@/utils/workData/lookuptable';

@Component({
  name: 'UserInfo',
  components: {
    'general-info': GeneralInfo,
  },
})
export default class extends Vue {
  @Prop({ required: true }) userInfo!: any;

  activationStatus: any = [];

  @Watch('userInfo', { deep: true })
  handleLookupTypes(newValue: any) {
    if (newValue) {
      this.convert();
    }
    return newValue;
  }

  convert() {
    if (this.userInfo.activationStatus) {
      this.activationStatus.push(...ACTIVATION_STATUS_LIST);
      this.activationStatus = this.activationStatus.filter((item: any) => {
        this.userInfo.activationStatus.includes(item.id);
      });
    }

    this.activationStatus = ACTIVATION_STATUS_LIST.filter(
      (item) => item.id == this.userInfo.activationStatus
    )[0].id;
  }
}
</script>

<template>
  <div>
    <general-info
      :name="'userModule.userName'"
      :value="userInfo.username"
    ></general-info>
    <general-info
      :name="'userModule.firstName'"
      :value="userInfo.firstName"
    ></general-info>
    <general-info
      :name="'userModule.lastName'"
      :value="userInfo.lastName"
    ></general-info>
    <general-info
      :name="'userModule.language'"
      :value="userInfo.i18nCode"
    ></general-info>
    <general-info
      :name="'userModule.email'"
      :value="userInfo.email"
    ></general-info>
    <general-info
      :name="'userModule.userRole'"
      :value="userInfo.roleName"
    ></general-info>
    <general-info
      :name="'userModule.organizationName'"
      :value="userInfo.organizationName"
    ></general-info>
    <general-info
      :name="'userModule.activationStatus'"
      :value="userInfo.activationStatus"
    ></general-info>
    <general-info
      :name="'userModule.mobilePhone'"
      :value="userInfo.mobilePhone"
    ></general-info>
    <general-info
      :name="'userModule.note'"
      :value="userInfo.note"
    ></general-info>
  </div>
</template>

<style lang="scss" scoped></style>
