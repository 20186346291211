<script lang="ts" setup>
import hyvaLogo from '@/assets/imgs/hyva.svg';
import ICPNumber from '@/components/layout/ICPNumber.vue';
</script>

<template>
  <el-container>
    <el-header :height="'48px'">
      <div class="topbar">
        <div class="header">
          <img :src="hyvaLogo" class="logo lg" />
          <div class="lg">OneEMS</div>
        </div>
      </div>
    </el-header>
    <el-container class="main-container">
      <el-main class="content">
        <slot></slot>
      </el-main>
    </el-container>
    <el-footer :height="'60px'">
      <div class="footer">
        <ICPNumber class="bottom-bar" />
      </div>
    </el-footer>
  </el-container>
</template>

<style lang="scss" scoped>
.bottom-bar {
  padding-left: 20px;
}
.content {
  flex: 1;
  text-align: center;
}

.header,
.footer {
  height: 100%;
  display: flex;
}

.top-bar {
  height: 48px;
}
.footer-bar {
  height: 60px;
}

.topbar {
  display: flex;
  align-items: center;
  gap: 1rem;
  padding: 0 1rem;

  @media (min-width: 769px) {
    padding-left: 0;
  }
}

.el-header {
  padding: 0;
  color: white;
  background-color: #373e41;
}

.el-footer {
  padding: 0;
  color: white;
  background-color: #000000;
  z-index: 3000;
}

.el-main {
  min-height: calc(100vh - 7.5rem);
}

.lg {
  display: none;
  align-items: center;

  @media (min-width: 769px) {
    display: flex;
  }
}

.logo {
  height: 100%;
}
</style>
