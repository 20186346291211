import Vue from 'vue';
import { ALL_CLAIMS_CODES, SYSTEM_FEATURES } from './lookuptable';

import { AuthorizableResourceCode } from '@/api/users';
import { RouteNames } from '@/router/modules/assets';
import { OperationalDashboardRouteNames } from '@/router/modules/operationalDashboard';
import { AssetType } from '../assetTypes';
import { WidgetKey } from './widgetCodeMap';

export interface WidgetCodeItem {
  '*': typeof Vue;
  [page: `AUTHRSC_PAGE_${string}`]: typeof Vue;
}

export const FULLPAGE_EXPANDED_WIDGETS = [
  'WIDGET_TIPPING_TARGET',
  'WIDGET_TIPPING_FLEET_TARGET',
  'WIDGET_TIPPING_PERFORMANCE',
  'WIDGET_EVENTS',
  'WIDGET_OPERATION_LOG',
];

export interface PageLayoutItem {
  column: number;
  w: string;
  h: string;
}

export const PAGE_LAYOUT_MAP = new Map<string, PageLayoutItem>([
  [ALL_CLAIMS_CODES.AUTHRSC_PAGE_HOME, { column: 4, w: '24.56%', h: '180px' }],

  [
    ALL_CLAIMS_CODES.AUTHRSC_PAGE_MCOMPACTOR_HOME,
    { column: 3, w: '33%', h: '243px' },
  ],
  [
    ALL_CLAIMS_CODES.AUTHRSC_PAGE_MCOMPACTOR_KPIS,
    { column: 3, w: '33%', h: '248px' },
  ],
  [
    ALL_CLAIMS_CODES.AUTHRSC_PAGE_MCOMPACTOR_ASSETINFO,
    { column: 3, w: '33%', h: '210px' },
  ],
  [
    ALL_CLAIMS_CODES.AUTHRSC_PAGE_MCOMPACTOR_MAINT,
    { column: 3, w: '33%', h: '180px' },
  ],
  [
    ALL_CLAIMS_CODES.AUTHRSC_PAGE_MCOMPACTOR_PARAMS_CONFIG,
    { column: 3, w: '33%', h: '180px' },
  ],
  [
    ALL_CLAIMS_CODES.AUTHRSC_PAGE_MCOMPACTOR_SPEC,
    { column: 3, w: '33%', h: '180px' },
  ],

  [
    ALL_CLAIMS_CODES.AUTHRSC_PAGE_SCOMPACTOR_HOME,
    { column: 3, w: '33%', h: '243px' },
  ],
  [
    ALL_CLAIMS_CODES.AUTHRSC_PAGE_SCOMPACTOR_KPIS,
    { column: 3, w: '33%', h: '248px' },
  ],
  [
    ALL_CLAIMS_CODES.AUTHRSC_PAGE_SCOMPACTOR_ASSETINFO,
    { column: 3, w: '33%', h: '210px' },
  ],
  [
    ALL_CLAIMS_CODES.AUTHRSC_PAGE_SCOMPACTOR_MAINT,
    { column: 3, w: '33%', h: '180px' },
  ],
  [
    ALL_CLAIMS_CODES.AUTHRSC_PAGE_SCOMPACTOR_PARAMS_CONFIG,
    { column: 3, w: '33%', h: '180px' },
  ],
  [
    ALL_CLAIMS_CODES.AUTHRSC_PAGE_SCOMPACTOR_SPEC,
    { column: 3, w: '33%', h: '180px' },
  ],

  [
    ALL_CLAIMS_CODES.AUTHRSC_PAGE_ALBA_SCOMPACTOR_HOME,
    { column: 3, w: '33%', h: '243px' },
  ],
  [
    ALL_CLAIMS_CODES.AUTHRSC_PAGE_ALBA_SCOMPACTOR_KPIS,
    { column: 3, w: '33%', h: '248px' },
  ],
  [
    ALL_CLAIMS_CODES.AUTHRSC_PAGE_ALBA_SCOMPACTOR_ASSETINFO,
    { column: 3, w: '33%', h: '210px' },
  ],
  [
    ALL_CLAIMS_CODES.AUTHRSC_PAGE_ALBA_SCOMPACTOR_MAINT,
    { column: 3, w: '33%', h: '180px' },
  ],
  [
    ALL_CLAIMS_CODES.AUTHRSC_PAGE_ALBA_SCOMPACTOR_SPEC,
    { column: 3, w: '33%', h: '180px' },
  ],

  [
    ALL_CLAIMS_CODES.AUTHRSC_PAGE_TIPPING_VEHICLES_HOME,
    { column: 3, w: '33%', h: '243px' },
  ],
  [
    ALL_CLAIMS_CODES.AUTHRSC_PAGE_TIPPING_VEHICLES_KPIS,
    { column: 3, w: '33%', h: '248px' },
  ],
  [
    ALL_CLAIMS_CODES.AUTHRSC_PAGE_TIPPING_VEHICLES_ASSETINFO,
    { column: 3, w: '33%', h: '210px' },
  ],
  [
    ALL_CLAIMS_CODES.AUTHRSC_PAGE_TIPPING_VEHICLES_MAINT,
    { column: 3, w: '33%', h: '180px' },
  ],
  [
    ALL_CLAIMS_CODES.AUTHRSC_PAGE_TIPPING_VEHICLES_SPEC,
    { column: 3, w: '33%', h: '180px' },
  ],
  [
    ALL_CLAIMS_CODES.AUTHRSC_PAGE_TIPPING_VEHICLES_PARAMS_CONFIG,
    { column: 3, w: '33%', h: '180px' },
  ],

  [ALL_CLAIMS_CODES.AUTHRSC_PAGE_RCV_HOME, { column: 3, w: '33%', h: '243px' }],
  [ALL_CLAIMS_CODES.AUTHRSC_PAGE_RCV_KPIS, { column: 3, w: '33%', h: '248px' }],
  [
    ALL_CLAIMS_CODES.AUTHRSC_PAGE_RCV_ASSETINFO,
    { column: 3, w: '33%', h: '210px' },
  ],
  [
    ALL_CLAIMS_CODES.AUTHRSC_PAGE_RCV_MAINT,
    { column: 3, w: '33%', h: '180px' },
  ],
  [ALL_CLAIMS_CODES.AUTHRSC_PAGE_RCV_SPEC, { column: 3, w: '33%', h: '180px' }],
  [
    ALL_CLAIMS_CODES.AUTHRSC_PAGE_RCV_PARAMS_CONFIG,
    { column: 3, w: '33%', h: '180px' },
  ],
  [
    ALL_CLAIMS_CODES.AUTHRSC_PAGE_MAINT_OVERVIEW,
    { column: 3, w: '33%', h: '180px' },
  ],
  [
    ALL_CLAIMS_CODES.AUTHRSC_PAGE_GENERIC_ASSET_HOME,
    { column: 3, w: '33%', h: '243px' },
  ],
  [
    ALL_CLAIMS_CODES.AUTHRSC_PAGE_GENERIC_ASSET_ASSETINFO,
    { column: 3, w: '33%', h: '210px' },
  ],
  [
    ALL_CLAIMS_CODES.AUTHRSC_PAGE_GENERIC_ASSET_PARAMS_CONFIG,
    { column: 3, w: '33%', h: '180px' },
  ],
]);

export interface RouteAssetItem {
  route: string;
  routeName: string;
  pageCode: string;
  widgetContainerId: string;
  title: string;
  assetTypeCode: AssetType;
}

export const ROUTE_ASSET_MAP = new Map<string, RouteAssetItem>([
  [
    'MobileCompactors',
    {
      route: 'mobile-compactors',
      //TODO: check why this routeName is not identical to assets.ts/RouteNames.MobileCompactors and then replace with that
      routeName: 'MobileCompactor',
      pageCode: ALL_CLAIMS_CODES.AUTHRSC_PAGE_MCOMPACTOR_HOME,
      widgetContainerId: 'asset-mc-home-container',
      title: 'route.mobileCompactors',
      assetTypeCode: AssetType.MobileCompactor,
    },
  ],
  [
    'MobileCompactorInfo',
    {
      route: 'mobile-compactors',
      //TODO: check why this routeName is not identical to assets.ts/RouteNames.MobileCompactors and then replace with that
      routeName: 'MobileCompactor',
      pageCode: ALL_CLAIMS_CODES.AUTHRSC_MOD_MCOMPACTOR,
      widgetContainerId: '',
      title: 'assets.mobileCompactors',
      assetTypeCode: AssetType.MobileCompactor,
    },
  ],
  [
    'StaticCompactors',
    {
      route: 'static-compactors',
      //TODO: check why this routeName is not identical to assets.ts/RouteNames.StaticCompactors and then replace with that
      routeName: 'StaticCompactor',
      pageCode: ALL_CLAIMS_CODES.AUTHRSC_PAGE_SCOMPACTOR_HOME,
      widgetContainerId: 'asset-sc-home-container',
      title: 'route.staticCompactors',
      assetTypeCode: AssetType.StaticCompactor,
    },
  ],
  [
    'StaticCompactorInfo',
    {
      route: 'static-compactors',
      //TODO: check why this routeName is not identical to assets.ts/RouteNames.StaticCompactors and then replace with that
      routeName: 'StaticCompactor',
      pageCode: ALL_CLAIMS_CODES.AUTHRSC_MOD_SCOMPACTOR,
      widgetContainerId: '',
      title: 'assets.staticCompactors',
      assetTypeCode: AssetType.StaticCompactor,
    },
  ],
  [
    'AlbaStaticCompactors',
    {
      route: 'alba-static-compactors',
      //TODO: check why this routeName is not identical to assets.ts/RouteNames.AlbaStaticCompactors and then replace with that
      routeName: 'AlbaStaticCompactor',
      pageCode: ALL_CLAIMS_CODES.AUTHRSC_PAGE_ALBA_SCOMPACTOR_HOME,
      widgetContainerId: 'asset-alba-sc-home-container',
      title: 'route.albaStaticCompactors',
      assetTypeCode: AssetType.AlbaStaticCompactor,
    },
  ],
  [
    'AlbaStaticCompactorInfo',
    {
      route: 'alba-static-compactors',
      //TODO: check why this routeName is not identical to assets.ts/RouteNames.AlbaStaticCompactors and then replace with that
      routeName: 'AlbaStaticCompactor',
      pageCode: ALL_CLAIMS_CODES.AUTHRSC_MOD_ALBA_SCOMPACTOR,
      widgetContainerId: '',
      title: 'assets.albaStaticCompactors',
      assetTypeCode: AssetType.AlbaStaticCompactor,
    },
  ],
  [
    'TippingVehicles',
    {
      route: 'tipping-vehicles',
      routeName: RouteNames.TippingVehicles,
      pageCode: ALL_CLAIMS_CODES.AUTHRSC_PAGE_TIPPING_VEHICLES_HOME,
      widgetContainerId: 'asset-tv-home-container',
      title: 'route.tipping',
      assetTypeCode: AssetType.TippingVehicle,
    },
  ],
  [
    'TippingVehiclesInfo',
    {
      route: 'tipping-vehicles',
      routeName: RouteNames.TippingVehicles,
      pageCode: ALL_CLAIMS_CODES.AUTHRSC_MOD_TIPPING_VEHICLES,
      widgetContainerId: '',
      title: 'route.tippingVehiclesInfo',
      assetTypeCode: AssetType.TippingVehicle,
    },
  ],
  [
    'RefuseCollectionVehicles',
    {
      route: 'refuse-collection-vehicles',
      routeName: RouteNames.RefuseCollectionVehicles,
      pageCode: ALL_CLAIMS_CODES.AUTHRSC_PAGE_RCV_HOME,
      widgetContainerId: 'asset-tv-home-container',
      title: 'route.refuseCollectionVehicles',
      assetTypeCode: AssetType.RefuseCollectionVehicle,
    },
  ],
  [
    'RefuseCollectionVehicleInfo',
    {
      route: 'refuse-collection-vehicles',
      routeName: RouteNames.RefuseCollectionVehicles,
      pageCode: ALL_CLAIMS_CODES.AUTHRSC_MOD_RCV,
      widgetContainerId: '',
      title: 'route.refuseCollectionVehiclesInfo',
      assetTypeCode: AssetType.RefuseCollectionVehicle,
    },
  ],
  [
    'GenericAssets',
    {
      route: 'generic-assets',
      routeName: RouteNames.GenericAssets,
      pageCode: ALL_CLAIMS_CODES.AUTHRSC_PAGE_GENERIC_ASSET_HOME,
      widgetContainerId: 'asset-tv-home-container',
      title: 'route.genericAssets',
      assetTypeCode: AssetType.GenericAsset,
    },
  ],
  [
    'GenericAssetInfo',
    {
      route: 'generic-assets',
      routeName: RouteNames.GenericAssets,
      pageCode: ALL_CLAIMS_CODES.AUTHRSC_MOD_GENERIC_ASSET,
      widgetContainerId: '',
      title: 'route.genericAssetInfo',
      assetTypeCode: AssetType.GenericAsset,
    },
  ],
  [
    'OperationalDashboardTippingVehicles',
    {
      route: 'tipping-vehicles',
      routeName: OperationalDashboardRouteNames.TippingVehicles,
      pageCode: ALL_CLAIMS_CODES.AUTHRSC_MOD_OPER_TIPPING_VEHICLES,
      widgetContainerId: '',
      title: 'route.operationalDashboardTippingVehicles',
      assetTypeCode: AssetType.TippingVehicle,
    },
  ],
]);

export interface WidgetPermissionItem {
  claims?: AuthorizableResourceCode | AuthorizableResourceCode[];
  systemFeature?: { featureCode: SYSTEM_FEATURES; assetType?: AssetType };
}

export const WIDGET_PERMISSIONS_MAP = new Map<WidgetKey, WidgetPermissionItem>([
  [
    WidgetKey.Events,
    {
      systemFeature: {
        featureCode: SYSTEM_FEATURES.Events,
      },
    },
  ],
  [
    WidgetKey.OperationLog,
    {
      systemFeature: {
        featureCode: SYSTEM_FEATURES.Events,
      },
    },
  ],
]);
