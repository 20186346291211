<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import moment from 'moment';
import { Trip, TripActivity, TripEvent } from '@/utils/types/trip';
import { subActivityColors } from '@/utils/workData/lookuptable';
import { formatTimer } from '@/utils/misc';

@Component({
  name: 'TripTimeline',
})
export default class extends Vue {
  @Prop() trip!: Trip;
  @Prop({ default: false }) compact!: boolean;

  get formattedStart() {
    return formatTimer(moment(this.trip.startTime), 'time');
  }

  get formattedEnd() {
    return formatTimer(moment(this.trip.endTime), 'time');
  }

  get activities() {
    if (!this.trip.summary) return;
    return [...this.trip.summary].sort((a, b) => {
      return (
        new Date(a.subActivityStartTimeStamp).getTime() -
        new Date(b.subActivityStartTimeStamp).getTime()
      );
    });
  }

  get events() {
    if (!this.trip.events) return [];
    return this.trip.events.filter((e) => e.id);
  }

  getPercentageFromTime(date: Date) {
    const time = new Date(date);
    const startTime = this.trip.startTime;
    const endTime = this.trip.endTime ?? new Date();

    return (
      (100 / (endTime.getTime() - startTime.getTime())) *
      (time.getTime() - startTime.getTime())
    );
  }

  getActivityStyle(activity: TripActivity, index: number) {
    const start = Math.max(
      0,
      this.getPercentageFromTime(activity.subActivityStartTimeStamp)
    );
    const end =
      index < this.activities!.length - 1
        ? Math.min(
            100,
            this.getPercentageFromTime(
              this.activities![index + 1].subActivityStartTimeStamp
            )
          )
        : 100;

    return {
      left: `${start}%`,
      width: `${end - start}%`,
      backgroundColor: subActivityColors[activity.subActivityKind],
    };
  }

  getActivityTime(activity: TripActivity, index: number) {
    if (
      !moment(this.trip.endTime).isAfter(activity.subActivityStartTimeStamp)
    ) {
      return;
    }

    const next = index + 1;
    const end =
      this.activities!.length > next
        ? this.activities![next].subActivityStartTimeStamp
        : this.trip.endTime;

    return `${moment(end).diff(
      activity.subActivityStartTimeStamp,
      'minutes'
    )} ${this.$t('UNIT_MINUTE_SHORT')}`;
  }

  getEventStyle(event: TripEvent) {
    return {
      left: `${Math.max(0, this.getPercentageFromTime(event.eventTimestamp))}%`,
      backgroundColor: event.eventType.includes('WARNING')
        ? '#ffa500'
        : '#de453f',
    };
  }
}
</script>

<template>
  <div
    :class="`timeline${compact ? ' compact' : ''}`"
    :data-start="formattedStart"
    :data-end="formattedEnd"
  >
    <div
      v-for="(activity, index) of activities"
      :key="`activity-${index}`"
      class="activity"
      :style="getActivityStyle(activity, index)"
      :data-time="getActivityTime(activity, index)"
    />
    <el-tooltip v-for="(event, index) of events" :key="`event-${index}`">
      <div slot="content">
        <strong>
          {{ event.eventName }}
        </strong>
        <br />
        {{ event.eventDescription }}
      </div>
      <div class="event" :style="getEventStyle(event)" />
    </el-tooltip>
  </div>
</template>

<style lang="scss" scoped>
.timeline {
  position: relative;
  height: 2.5rem;
  padding-top: 1rem;

  &::before,
  &::after {
    display: inline-block;
    position: absolute;
    top: calc(100% + 1rem);
    margin-top: 0.5rem;
    font-size: 0.75rem;
    font-weight: bold;
  }

  &::before {
    content: attr(data-start);
  }

  &::after {
    content: attr(data-end);
    right: 0;
  }

  &.compact {
    height: 1rem;
    margin: 0 3.5rem;
    padding-top: 0;

    &::before,
    &::after {
      top: 0;
      margin-top: 0;
      font-size: 1rem;
      font-weight: normal;
    }

    &::before {
      right: 100%;
      margin-right: 0.75rem;
    }

    &::after {
      left: 100%;
      margin-left: 0.75rem;
    }

    .activity::before {
      display: none;
    }
  }
}

.activity,
.event {
  position: absolute;
  height: 100%;
}

.activity::before {
  position: absolute;
  content: attr(data-time);
  top: -1rem;
  font-size: 0.65rem;
  font-weight: 600;
  white-space: nowrap;
  opacity: 0;
  transition: opacity 0.25s ease;
}

.activity:hover::before {
  opacity: 1;
}

.event {
  width: 4px;
  transform: translateX(-2px);
}
</style>
