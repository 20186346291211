import { render, staticRenderFns } from "./Details.vue?vue&type=template&id=33b8ea9b&scoped=true"
import script from "./Details.vue?vue&type=script&lang=ts"
export * from "./Details.vue?vue&type=script&lang=ts"
import style0 from "./Details.vue?vue&type=style&index=0&id=33b8ea9b&prod&scoped=true&lang=css"
import style1 from "./Details.vue?vue&type=style&index=1&id=33b8ea9b&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "33b8ea9b",
  null
  
)

export default component.exports