import { UUID } from '@/api/common';
import { SafetyEventType } from '@/api/trip';
import { PeriodRange } from '@/utils/types/date';
import { ALL_CLAIMS_CODES } from '@/utils/workData/lookuptable';
import { AggregationType } from '@/widgets/utils/Constants';

import { RouteNames } from '@/router/modules/assets';
import { AssetType } from '@/utils/assetTypes';
import { Location, Route } from 'vue-router';
import { singleAssetOverviewLocation } from './assetOverview';

export const ASSET_SAFETY_CODE = 'WIDGET_TIPPING_NEW_SAFETY';
export const NEW_FLEET_SAFETY_CODE = 'WIDGET_TIPPING_NEW_FLEET_SAFETY';
export const SAFETY_EVENT_ROUTE_QUERY_PARAM = 'safetyEvent';
export const START_DATE_ROUTE_QUERY_PARAM = 'startDate';
export const END_DATE_ROUTE_QUERY_PARAM = 'endDate';
export const DATE_PERIOD_ROUTE_QUERY_PARAM = 'datePeriod';
export const AGGREGATION_TYPE_ROUTE_QUERY_PARAM = 'aggregationType';

export function expandedFleetSafetyLocation(
  selectedSafetyEvent: SafetyEventType,
  periodRange: PeriodRange,
  aggregationType: AggregationType
): Location {
  return {
    name: RouteNames.TippingVehicles,
    query: {
      widget: NEW_FLEET_SAFETY_CODE,
      [SAFETY_EVENT_ROUTE_QUERY_PARAM]: selectedSafetyEvent,
      [START_DATE_ROUTE_QUERY_PARAM]: periodRange.start,
      [END_DATE_ROUTE_QUERY_PARAM]: periodRange.end,
      [AGGREGATION_TYPE_ROUTE_QUERY_PARAM]: aggregationType,
    },
  };
}

export function expandedSingleAssetSafetyLocation(
  assetUUID: UUID,
  selectedSafetyEvent: SafetyEventType,
  periodRange: PeriodRange,
  from?: Route
): Location {
  // TODO Split this further into page and expanded widget functions
  return singleAssetOverviewLocation(AssetType.TippingVehicle, assetUUID, {
    widget: ASSET_SAFETY_CODE,
    tab: ALL_CLAIMS_CODES.AUTHRSC_PAGE_TIPPING_VEHICLES_KPIS,
    [SAFETY_EVENT_ROUTE_QUERY_PARAM]: selectedSafetyEvent,
    [START_DATE_ROUTE_QUERY_PARAM]: periodRange.start,
    [END_DATE_ROUTE_QUERY_PARAM]: periodRange.end,
    from: from ? from.fullPath : undefined,
  });
}
