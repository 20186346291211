<script lang="ts" setup>
import { formatToPattern } from '@/utils/misc';
import { MAINTENANCE_SCHEDULER_FORMAT } from '@/utils/time';
import {
  PERFORMED_MAINTENANCE_STATUS,
  SchedulerData,
} from '@/views/maintenance/planner';
import '@progress/kendo-theme-default/dist/all.css';
import moment from 'moment';
import { MaintenanceScheduler } from './scheduler';

const props = defineProps<{
  currentDate: Date;
  scheduledAppointments?: SchedulerData[];
}>();

const performedMaintenanceStatus = PERFORMED_MAINTENANCE_STATUS;

const emit = defineEmits<{
  (e: 'edit', data: MaintenanceScheduler): void;
}>();

function formatDayHeader(date: string): string {
  return formatToPattern(moment(date), MAINTENANCE_SCHEDULER_FORMAT);
}

const handleAppointmentClickEvent = (appointment: MaintenanceScheduler) => {
  if (!appointment.status || appointment.status === performedMaintenanceStatus)
    return;
  emit('edit', appointment);
};
</script>

<template>
  <div>
    <div class="big-calendar-container">
      <div v-for="(item, index) in scheduledAppointments" :key="index">
        <div
          class="big-calendar-day"
          :class="[item.isSelected ? 'selected-day' : '']"
        >
          <div class="day-title">{{ formatDayHeader(item.day) }}</div>
          <div
            class="items-container"
            :title="
              appointment.status === performedMaintenanceStatus
                ? $tc('maintenance.maintenancePlanner.alreadyConducted')
                : ''
            "
            :class="[
              appointment.status === 'MAINS_OK'
                ? 'appointment-ok'
                : appointment.status === 'MAINS_CLOSE'
                ? 'appointment-close'
                : appointment.status === performedMaintenanceStatus
                ? 'appointment-performed'
                : 'appointment-due',
            ]"
            v-for="(appointment, appointmentIndex) in item.appointments"
            :key="appointmentIndex"
            @click="handleAppointmentClickEvent(appointment)"
          >
            <div class="item-first-line">{{ appointment.companyAssetId }}</div>
            <div class="item-second-line">
              {{ appointment.maintenanceItemName }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.big-calendar-container {
  min-height: 65vh;
  height: auto;
  display: grid;
  grid-template-columns: repeat(7, 1fr);
}

.big-calendar-day {
  height: 100%;
  background-color: white;
  border: 0.1px solid #aaa9a9;
  min-width: 100px;
}

.day-title {
  padding: 10px 0 10px 14px;
  border-bottom: 0.1px solid #aaa9a9;
  font-weight: bold;
}

.items-container {
  list-style-type: none;
  padding: 10px;
  margin: 10px;
  border-radius: 10px;
}

.selected-day {
  background-color: #ffeb99;
}

.appointment-performed {
  background-color: #d2d2d2;
}

.item-first-line {
  font-weight: bold;
  font-size: 15px;
  word-wrap: break-word;
}
</style>

<style lang="scss" scoped>
@media (max-width: 1600px) {
  .items-container {
    width: 110px !important;
  }
}

@media (max-width: 1600px) {
  .big-calendar-day {
    width: 130px !important;
  }
}

@media (min-width: 1600px) {
  .big-calendar-day {
    width: 185px !important;
  }
}

.appointment-ok {
  background-color: $status-ok-color;
}

.appointment-close {
  background-color: $status-close-color;
}

.appointment-due {
  background-color: $status-due-color;
}

.item-second-line {
  word-break: break-word;
}
</style>
