<script lang="ts">
import { getPolicies, Policies } from '@/api/policies';
import { getTimezones, TimezoneResponse } from '@/api/timezone';
import { makeDefaultUserSettings, UserSettings } from '@/api/users';
import {
  LoggedInUser,
  UpdateUserSettingsFunction,
  useLoggedInUser,
  useUpdateUserSettings,
} from '@/auth/user';
import CommonBtn from '@/components/button/CommonBtn.vue';
import CusFormItem from '@/components/form/CusFormItem.vue';
import i18n from '@/lang';
import { FilterOperator } from '@/model/queryParameters/QueryParameter';
import { UserModule } from '@/store/modules/user';
import { promptFailedBox, promptSuccessBox } from '@/utils/prompt';
import { UNIT_SYSTEMS } from '@/utils/units/systemConversionDefinitions';
import {
  fakeUnref,
  InitializeReactive,
} from '@/utils/vueClassComponentHelpers';
import { LANG_LIST, PAGE_SIZE_LIST } from '@/utils/workData/lookuptable';
import { Component, Vue, Watch } from 'vue-property-decorator';

interface SettingForm {
  i18nCode: string;
  timeZone: string;
  gridPageSize: string;
}

@Component({
  name: 'ViewSettings',
  components: {
    'cus-form-item': CusFormItem,
    'common-btn': CommonBtn,
  },
})
export default class extends Vue {
  langOptions = LANG_LIST;
  timeZoneOptions: TimezoneResponse[] = [];
  pageSizeOptions = PAGE_SIZE_LIST;

  @InitializeReactive
  viewSettingForm!: UserSettings;

  initialSettingsForm?: UserSettings;
  saveButtonIsDisabled: boolean = true;
  viewSettingsIsLoading: boolean = false;

  UNIT_SYSTEMS = UNIT_SYSTEMS;

  @InitializeReactive
  policies: Policies | undefined = undefined;
  updateUserSettings!: UpdateUserSettingsFunction;

  @InitializeReactive
  loggedInUser: LoggedInUser | undefined;

  /**
   * Check changes to input form compared with initial one to modify the state of the save button
   */
  @Watch('viewSettingForm', { deep: true })
  handleUserInputChange() {
    this.saveButtonIsDisabled = !Object.keys(this.viewSettingForm).some(
      (field) =>
        this.viewSettingForm[field as keyof SettingForm] !=
        this.initialSettingsForm?.[field as keyof SettingForm]
    );
  }

  async saveViewSettings() {
    try {
      this.viewSettingsIsLoading = true;
      this.handleTimeZoneChange();
      await this.updateUserSettings(this.viewSettingForm);
      this.updateFormFromUser();
      promptSuccessBox(this.$t('common.save') as string);
    } catch (error) {
      console.log(error);
      promptFailedBox(this.$t('common.save') as string);
    } finally {
      this.viewSettingsIsLoading = false;
      this.getPolicies();
    }
  }

  /** If user input has change the time zone from initial one, update current logged in session state */
  handleTimeZoneChange(): void {
    if (this.viewSettingForm.timeZone != UserModule.timeZone) {
      // TODO Replace with query mutation
      UserModule.UpdateTimeZone(this.viewSettingForm.timeZone!);
    }
  }

  created() {
    this.loggedInUser = fakeUnref(useLoggedInUser());
    this.updateUserSettings = useUpdateUserSettings();
    this.updateFormFromUser();
    getTimezones().then(({ data }) => (this.timeZoneOptions = data));
    this.getPolicies();
  }

  updateFormFromUser() {
    this.viewSettingForm = {
      ...makeDefaultUserSettings(),
      ...this.loggedInUser?.settings,
    };
    this.initialSettingsForm = { ...this.viewSettingForm };
  }

  async getPolicies() {
    this.policies = await getPolicies({
      filters: [
        {
          name: 'policyRegionCode',
          operator: FilterOperator.EQUAL,
          value: [UserModule.policyRegion],
        },
        {
          name: 'companyType',
          operator: FilterOperator.EQUAL,
          value: [UserModule.companyType],
        },
        {
          name: 'i18nCode',
          operator: FilterOperator.EQUAL,
          value: [i18n.locale],
        },
      ],
    });
  }
}
</script>

<template>
  <div class="view-settings" v-loading="viewSettingsIsLoading">
    <el-form id="user_setting_view_form">
      <cus-form-item :title="'userSetting.language'" :required="false">
        <el-select
          id="user_setting_view_select_language"
          v-model="viewSettingForm.i18nCode"
          v-bind:placeholder="$t('customerModule.pleaseSelect')"
          filterable
        >
          <el-option
            v-for="item in langOptions"
            :key="item.id"
            :label="item.value"
            :value="item.id"
          />
        </el-select>
      </cus-form-item>
      <cus-form-item :title="'userSetting.timeZone'" :required="false">
        <el-select
          id="user_setting_view_select_timeZone"
          v-model="viewSettingForm.timeZone"
          v-bind:placeholder="$t('customerModule.pleaseSelect')"
          filterable
        >
          <el-option
            v-for="item in timeZoneOptions"
            :key="item.id"
            :label="item.fullName"
            :value="item.fullName"
          />
        </el-select>
      </cus-form-item>
      <cus-form-item :title="'userSetting.gridPageSize'" :required="false">
        <el-select
          id="user_setting_view_select_gridPageSize"
          v-model="viewSettingForm.gridPageSize"
          v-bind:placeholder="$t('customerModule.pleaseSelect')"
          filterable
        >
          <el-option
            v-for="item in pageSizeOptions"
            :key="item.id"
            :label="item.id"
            :value="item.id"
          />
        </el-select>
      </cus-form-item>
      <cus-form-item :title="'userSetting.unitSystem'" :required="false">
        <el-select
          v-model="viewSettingForm.unitSystem"
          v-bind:placeholder="$t('customerModule.pleaseSelect')"
          filterable
        >
          <el-option
            v-for="item in UNIT_SYSTEMS"
            :label="$t(item)"
            :value="item"
          />
        </el-select>
      </cus-form-item>
      <el-form-item>
        <div class="form-footer">
          <common-btn
            id="user_setting_view_btn_save"
            :content="'common.save'"
            @handle-btn="saveViewSettings"
            :disabled="saveButtonIsDisabled"
          />
        </div>
      </el-form-item>
    </el-form>
    <div class="spacer"></div>
    <div
      v-for="(policy, policyType) in policies ?? {}"
      :key="policyType"
      :id="policyType"
      class="module-page-checkbox"
    >
      <p
        v-html="
          $t(`policies.${policyType}`, {
            url: policy?.url,
          })
        "
        class="statements"
      />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.view-settings {
  margin-left: 10px;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.form-footer {
  margin-left: 350px;
}

.form-footer :deep(.common-button) {
  width: 200px;
}

.spacer {
  flex-grow: 1;
}

.statements {
  font-size: 14px;
  font-family: $font-Roboto-Medium;
  font-weight: 400;
  line-height: 19px;
  max-width: 800px;
  color: rgba(55, 62, 65, 0.6);

  & :deep(a) {
    color: #337ab7;

    &:hover {
      color: rgb(32, 160, 255);
    }
  }
}
</style>
