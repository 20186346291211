export const RELEASES_MANAGEMENT_COLS = [
  {
    label: 'releasesManagement.productSystemReleaseId',
    prop: 'systemReleaseId',
    required: true,
    visible: true,
    sortable: 'custom',
  },
  {
    label: 'releasesManagement.productModel',
    prop: 'productModelCode',
    required: true,
    visible: true,
    sortable: 'custom',
  },
  {
    label: 'releasesManagement.modelNumber',
    prop: 'productModelNumber',
    required: true,
    visible: true,
    multiLang: true,
    sortable: 'custom',
  },
  {
    label: 'releasesManagement.assetType',
    prop: 'assetType',
    required: true,
    multiLang: true,
    visible: true,
    sortable: 'custom',
  },
  {
    label: 'releasesManagement.releaseStatus',
    prop: 'releaseStatus',
    multiLang: true,
    required: true,
    visible: true,
    sortable: 'custom',
  },
  {
    label: 'releasesManagement.maturityLevel',
    prop: 'maturityLevel',
    multiLang: true,
    required: true,
    visible: true,
    sortable: 'custom',
  },
  {
    label: 'releasesManagement.defaultRelease',
    prop: 'defaultRelease',
    required: true,
    visible: true,
    sortable: 'custom',
  },
  {
    label: 'releasesManagement.deploymentDuration',
    prop: 'deploymentDuration',
    required: true,
    visible: true,
    sortable: 'custom',
  },
  {
    label: 'releasesManagement.noOfCustomers',
    prop: 'numberOfLinkedCustomers',
    required: true,
    visible: true,
    sortable: 'custom',
  },
  {
    label: 'releasesManagement.createdTime',
    prop: 'manifestCreationTime',
    required: true,
    visible: true,
    sortable: 'custom',
    dateFormat: 'datetime',
  },
];
