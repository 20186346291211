import { render, staticRenderFns } from "./RegisteredAssets.vue?vue&type=template&id=6a8c229e&scoped=true"
import script from "./RegisteredAssets.vue?vue&type=script&lang=ts"
export * from "./RegisteredAssets.vue?vue&type=script&lang=ts"
import style0 from "./RegisteredAssets.vue?vue&type=style&index=0&id=6a8c229e&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6a8c229e",
  null
  
)

export default component.exports