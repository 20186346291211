<script lang="ts">
import { formatServerDate } from '@/views/assetManagement/helper';
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';

@Component({
  name: 'KpiTable',
  components: {},
})
export default class extends Vue {
  @Prop() tableList!: any[];
  @Prop() cols!: [];

  list: any = [...this.tableList];

  @Watch('tableList')
  onListChange(value: any) {
    this.list = [...this.tableList];
  }

  tableComponentKey: string = new Date().toString();
  dropCols: any = [];

  mounted() {}

  hoverRow(row: any, column: any) {
    let xAxiValue = row.hasOwnProperty('date') ? row.date : row.asset;
    this.$emit('hover-table', xAxiValue);
  }

  get activeCols() {
    this.dropCols = this.cols.filter((item: any) => {
      return item.visible;
    });

    this.tableComponentKey = new Date().toString();

    return this.dropCols;
  }

  getDate(date: string) {
    return formatServerDate(date);
  }
}
</script>

<template>
  <div>
    <el-table
      :data="list"
      row-key="id"
      :row-style="{ height: '40px' }"
      :cell-style="{ padding: '7px 0' }"
      @cell-mouse-enter="hoverRow"
    >
      <el-table-column
        v-for="(col, index) in activeCols"
        :key="`col_${index}_${col.label}_${tableComponentKey}`"
        :prop="col.prop"
        :min-width="col.width ? col.width : '85'"
        show-overflow-tooltip
        :sortable="col.sortable"
      >
        <template v-slot:header>
          <div class="d-flex jc-between table-header">
            <span style="white-space: keep-all"
              >{{ $t(col.label) }}
              <span v-show="col.unit">({{ $t(col.unit) }})</span>
            </span>
          </div>
        </template>
        <!-- format date info -->
        <template v-slot="slotProps" v-if="col.prop === 'date'">
          <span>{{ getDate(slotProps.row[col.prop]) }}</span>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<style>
.el-table tbody tr:hover > td {
  /* background-color: rgb(var(--Main), 0.6) !important; */
  background-color: var(--Main) !important;
  /* opacity: 0.5; */
  /* cursor: pointer; */
}

.el-table th {
  border-top: 1px solid #dddddd;
  border-bottom: 1px solid #dddddd !important;
}

.el-table td {
  border-bottom: 1px solid #dddddd !important;
}

.el-table {
  font-size: 14px !important;
  font-family: var(--fontRobotoRegular);
  line-height: 16px;
  color: #373e41 !important;
  opacity: 1;
}

.el-table thead {
  font-size: 14px !important;
  font-family: var(--fontRobotoMedium);
  line-height: 16px;
  color: #373e41 !important;
  opacity: 1;
}
</style>

<style lang="scss" scoped>
.table-header {
  display: inline-block;
  font-size: 16px;
  font-weight: 500;
}

::deep(.el-table th > .cell) {
  display: flex;
  align-items: center;
}

:deep(.el-button--text) {
  color: #707070;
}

:deep(.el-table .sort-caret.ascending) {
  border-bottom-color: #000000;
}
:deep(.el-table .sort-caret.descending) {
  border-top-color: #000000;
}
:deep(.el-table .ascending .sort-caret.ascending) {
  border-bottom-color: var(--Main);
}
:deep(.el-table .descending .sort-caret.descending) {
  border-top-color: var(--Main);
}
</style>
