<script lang="ts">
import { getAssetOnBoardingById } from '@/api/assetsMgmt';
import GeneralInfo from '@/components/form/GeneralInfo.vue';
import NavPage from '@/components/nav/NavPage.vue';
import { PageModule } from '@/store/modules/page';
import {
  AssetField,
  AssetFieldStateType,
  AssetFieldToState,
  AssetStage,
  AssetType,
  getAssetFieldStates,
} from '@/utils/assetTypes';
import { customFailedMessage } from '@/utils/prompt';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { formatServerDate } from '../helper';

@Component({
  name: 'RegisterAssetView',
  components: {
    NavPage,
    'general-info': GeneralInfo,
  },
})
export default class extends Vue {
  @Prop({ required: true }) assetId!: string;

  /** Local variables */

  assetFieldStateType = AssetFieldStateType;
  registerAssetInfo: any = {};
  title = '';
  registerAssetViewIsLoading: boolean = false;

  mounted() {
    this.fetchAssetDetail();
  }

  async fetchAssetDetail() {
    this.registerAssetViewIsLoading = true;
    await getAssetOnBoardingById(this.assetId).then((res) => {
      if (res.code === 200) {
        this.registerAssetInfo = res.data;
        this.registerAssetInfo.manufactureDate = formatServerDate(
          res.data.manufactureDate
        );
        PageModule.setTitle(
          this.formFieldState[AssetField.SerialNumber].type ===
            AssetFieldStateType.NotApplicable
            ? res.data.assetDataCollectionId
            : `${res.data.assetDataCollectionId}/${res.data.serialNumber}`
        );
      } else {
        customFailedMessage(this.$t('common.errorWithFetchingData') as string);
        return;
      }

      this.registerAssetViewIsLoading = false;
    });
  }

  get formFieldState(): AssetFieldToState {
    const assetType = this.registerAssetInfo.assetType as AssetType;

    return getAssetFieldStates(AssetStage.Register, assetType);
  }

  gotoEditRegisteredAsset() {
    this.$router.push(`/asset-mgmt/register/edit/${this.assetId}`);
  }
}
</script>

<template>
  <NavPage
    v-loading="registerAssetViewIsLoading"
    :visible="true"
    :arrowBack="false"
    @arrow-click="$router.push('/asset-mgmt/index?activeName=registerAssets')"
  >
    <template #button>
      <el-button
        id="edit_registered_asset"
        type="plain"
        v-permission="['AUTHRSC_ACTION_REGISTERED_ASSET_UPDATE']"
        @click="gotoEditRegisteredAsset"
      >
        <i class="el-icon-edit common-icon" />{{ $t('common.edit') }}
      </el-button>
    </template>

    <div style="margin-left: 40px; margin-top: 44px">
      <el-row :gutter="20">
        <el-col :span="10">
          <general-info
            id="register_detail_view_asset_HIN"
            :name="'assetMgmt.AssetHIN'"
            :value="registerAssetInfo.assetDataCollectionId"
          />
          <general-info
            id="register_detail_view_info_asset_type"
            :name="'assetMgmt.assetType'"
            :value="$t(registerAssetInfo.assetType)"
          />
          <general-info
            id="register_detail_view_info_model_code"
            :name="'assetMgmt.prodModel'"
            :value="registerAssetInfo.productModelCode"
          />
          <general-info
            id="register_detail_view_info_model_number"
            :name="'assetMgmt.modelNumber'"
            :value="registerAssetInfo.productModelNumber"
          />
          <general-info
            v-if="
              formFieldState.SerialNumber.type !==
              assetFieldStateType.NotApplicable
            "
            id="register_detail_view_serial_number"
            :name="'assetMgmt.assetSerialNumber'"
            :value="registerAssetInfo.serialNumber"
          />
          <general-info
            v-if="formFieldState.VIN.type !== assetFieldStateType.NotApplicable"
            :name="'assetMgmt.VIN'"
            :value="registerAssetInfo.vinNumber"
          />
          <general-info
            v-if="
              formFieldState.AssetId.type !== assetFieldStateType.NotApplicable
            "
            :name="'assetMgmt.preliminaryAssetId'"
            :value="registerAssetInfo.preliminaryAssetId"
          />
          <general-info
            id="register_detail_view_info_service_region"
            :name="'common.serviceRegion'"
            :value="registerAssetInfo.serviceRegion"
          />
          <general-info
            id="register_detail_view_info_service_region"
            :name="'common.hyvaRegion'"
            :value="registerAssetInfo.hyvaRegion"
          />
          <general-info
            id="register_detail_view_info_manufacture_date"
            :name="'assetMgmt.manufactureDate'"
            :value="registerAssetInfo.manufactureDate"
          />
          <general-info
            id="register_detail_view_info_controller_id"
            :name="'assetMgmt.controllerId'"
            :value="registerAssetInfo.controllerId"
          />
        </el-col>
      </el-row>
    </div>
  </NavPage>
</template>

<style lang="scss" scoped>
:deep(.form-item-container) {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 40px;
  .form-title {
    width: 270px;
    margin-right: 22px;
    text-align: left;
    font-family: $font-Roboto-Regular;
    color: #373e41;
  }
  .show-title {
    font-size: 18px;
  }
}
</style>
