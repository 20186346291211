<script setup lang="ts">
import i18n from '@/lang';
import echarts, { EChartOption, ECharts } from 'echarts';
import { ref, unref, nextTick, onUnmounted, computed, watchEffect } from 'vue';
import { FleetPerformanceChartData } from './models/FleetPerformance';

const props = defineProps<{
  data: FleetPerformanceChartData[];
  /**
   * Sets whether to show only the top and bottom N assets based on their rank.
   */
  topBottomOnly?: number;
}>();

const chartRef = ref<HTMLDivElement | HTMLCanvasElement>();
const chartInstance = ref<ECharts | null>();

onUnmounted(() => {
  disposeChart();
});

function getFleetPerformanceAverageValue(
  data: FleetPerformanceChartData[]
): number {
  return (
    data.reduce((partialSum, p) => partialSum + p.tonPerHour, 0) / data.length
  );
}

const average = computed(() => getFleetPerformanceAverageValue(props.data));

const topNBottomNAssets = computed(
  (): FleetPerformanceChartData[] | undefined => {
    if (!props.topBottomOnly || props.data.length <= 2 * props.topBottomOnly) {
      return undefined;
    }

    return props.data
      .slice(0, props.topBottomOnly)
      .concat(props.data.slice(-props.topBottomOnly!));
  }
);

const shouldTopBottomBracketsBeShown = computed(
  () => !!topNBottomNAssets.value
);

const chartData = computed(() =>
  topNBottomNAssets.value ? topNBottomNAssets.value : props.data
);

const TOP = 30;
const BOTTOM = 60;
const RIGHT = 40;

const BRACKET_ORIGIN = 0;
const BRACKET_FORK_LENGTH_PX = 10;
const BRACKET_VERTICAL_LENGTH_PX = 140;

const LABEL_MARGIN = 28;
const LABEL_HEIGHT = 12;
const LABEL_BRACKET_GAP = 20;

const chartBracketPoints: number[][] = [
  [BRACKET_FORK_LENGTH_PX, BRACKET_ORIGIN],
  [BRACKET_ORIGIN, BRACKET_ORIGIN],
  [BRACKET_ORIGIN, BRACKET_VERTICAL_LENGTH_PX],
  [BRACKET_FORK_LENGTH_PX, BRACKET_VERTICAL_LENGTH_PX],
];

const chartBracketStyle = {
  stroke: '#8a8e90',
  lineWidth: 2,
};

const options = computed((): EChartOption<EChartOption.Series> => {
  const grid: EChartOption.Grid = {
    left: shouldTopBottomBracketsBeShown.value
      ? LABEL_MARGIN + LABEL_HEIGHT + LABEL_BRACKET_GAP + BRACKET_FORK_LENGTH_PX
      : LABEL_MARGIN + LABEL_HEIGHT,
    top: TOP,
    bottom: BOTTOM,
    right: RIGHT,
  };

  return {
    grid,
    series: [
      {
        type: 'scatter',
        encode: { x: 'tonPerHour', y: 'rank' },
        label: {
          show: true,
          color: 'black',
          position: 'left',
          fontSize: 12,
          formatter: function (params: any) {
            return chartData.value?.[params.dataIndex].assetName;
          },
        },
        markLine: {
          silent: true,
          data: [
            {
              xAxis: average.value,
            },
          ],
          lineStyle: {
            color: '#EA6D01',
          },
          label: {
            show: true,
            position: 'end',
            formatter: i18n.t('fleetPerformance.fleetAverage'),
          },
          symbol: ['none', 'none'],
        }, //the average line style
      },
    ],
    dataset: {
      source: chartData.value,
    },
    xAxis: {
      type: 'value',
      name: `${i18n.t('KPI.Payload')}/${i18n.t('UNIT_HOUR')} (${i18n.t(
        'tippingVehicles.assetOverview.units.UNIT_METRIC_TONNE'
      )})`,
      nameLocation: 'center',
      nameGap: 30,
      axisLine: {
        lineStyle: {
          color: '#DDDDDD',
        },
      },
      nameTextStyle: {
        color: '#8a8e90',
        fontWeight: 'bold',
      },
      axisLabel: {
        fontSize: 14,
        color: '#adb0b2',
      },
      axisTick: {
        show: false,
      },
    },
    yAxis: {
      type: 'category',
      splitLine: {
        show: shouldTopBottomBracketsBeShown.value,
        interval: props.topBottomOnly! - 1,
      },
      axisLine: {
        show: true,
        lineStyle: {
          color: '#D9D9D9',
        },
      },
      axisTick: {
        show: true,
        lineStyle: {
          color: '#D9D9D9',
        },
      },
      axisLabel: {
        show: false,
      },
    },
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'shadow',
      },
      position: 'top',
      formatter: function (params: any) {
        const dataItem = chartData.value?.[params[0].dataIndex];

        if (!dataItem) {
          return '';
        }

        // prettier-ignore
        return `
            <div style="padding: 8px">
              <div style="color: #B6B6B6; margin-bottom: 4px;">${dataItem.assetName}</div>
              <div style="color: #fff;">
                <span style="font-size: 16px">${dataItem.tonPerHour.toFixed(2)}</span>
                <span style="font-size: 14px; font-weight: lighter;">${i18n.t("UNIT_METRIC_TONNE_P_HOUR")}</span>
              </div>
              <div style="color: #fff; font-weight: lighter;">
                <span style="font-size: 14px">${i18n.t('tippingVehicles.performance.rank')}</span>
                <span style="font-size: 14px">${dataItem.rank}</span>
              </div>
            </div>`;
      },
    },
    visualMap: [
      {
        show: false,
        type: 'piecewise',
        pieces: [
          { max: average.value, color: '#F7781B' },
          { min: average.value, color: '#95F019' },
        ],
        dimension: 'tonPerHour',
        outOfRange: {
          color: '#F7781B',
        },
      },
    ],
    graphic: !shouldTopBottomBracketsBeShown.value
      ? undefined
      : [
          {
            type: 'group',
            top: TOP,
            left: LABEL_MARGIN,
            height: BRACKET_VERTICAL_LENGTH_PX,
            children: [
              {
                type: 'text',
                top: 'middle',
                rotation: Math.PI / 2,
                style: {
                  text: i18n.t('fleetPerformance.top_N', [
                    props.topBottomOnly!.toString(),
                  ]),
                  fill: '#8a8e90',
                },
                cursor: 'default',
              },
              {
                type: 'polyline',
                left: LABEL_BRACKET_GAP,
                shape: {
                  points: chartBracketPoints,
                },
                style: chartBracketStyle,
                cursor: 'default',
              },
            ],
          },
          {
            type: 'group',
            bottom: BOTTOM,
            left: LABEL_MARGIN,
            height: BRACKET_VERTICAL_LENGTH_PX,
            children: [
              {
                type: 'text',
                bottom: 'middle',
                rotation: Math.PI / 2,
                style: {
                  text: i18n.t('fleetPerformance.bottom_N', [
                    props.topBottomOnly!.toString(),
                  ]),
                  fill: '#8a8e90',
                },
                cursor: 'default',
              },
              {
                type: 'polyline',
                left: LABEL_BRACKET_GAP,
                shape: {
                  points: chartBracketPoints,
                },
                style: chartBracketStyle,
                cursor: 'default',
              },
            ],
          },
        ],
  };
});

watchEffect(() => {
  const chartDOMElement = unref(chartRef);
  if (!chartDOMElement) {
    return;
  }

  const chartOptions = options.value;
  nextTick(() => {
    initChart(chartDOMElement, chartOptions);
  });
});

function initChart(
  chartDOMElement: HTMLDivElement | HTMLCanvasElement,
  chartOptions: EChartOption<EChartOption.Series>
) {
  chartInstance.value = echarts.init(chartDOMElement);
  const instanceChart = unref(chartInstance);
  if (instanceChart) {
    instanceChart.setOption(chartOptions);

    window.addEventListener('resize', resizeChart);
    resizeChart();
  }
}

function resizeChart() {
  if (unref(chartInstance)) {
    unref(chartInstance)!.resize();
  }
}

function disposeChart() {
  if (chartInstance.value) {
    chartInstance.value.dispose();
    chartInstance.value = null;
    window.removeEventListener('resize', resizeChart);
  }
}
</script>

<template>
  <div ref="chartRef" style="width: 100%; height: 100%" />
</template>

<style scoped lang="scss"></style>
