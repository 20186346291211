<script lang="ts">
import { formatNumber } from '@/utils/number';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { KpiGrowthUnitValue } from './KpiNumber.vue';

export interface MultiKpiGrowthUnitValue<T> extends KpiGrowthUnitValue<T> {
  avgValue?: number;
}

@Component({
  name: 'expand-multikpi-number',
})
export default class extends Vue {
  @Prop() kpi!: MultiKpiGrowthUnitValue<number | undefined>;

  formatNumber = formatNumber;
}
</script>

<template>
  <div class="form-item-container">
    <div class="form-title" style="width: 50%">
      <div class="show-title">{{ kpi.name }}({{ $t(kpi.unit) }})</div>
      <div class="show-value">{{ formatNumber(kpi.v) }}</div>
    </div>
    <div class="form-title">
      <div class="show-title">{{ $t('KPI.average') }}({{ $t(kpi.unit) }})</div>
      <div class="show-value">{{ formatNumber(kpi.avgValue) }}</div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.form-item-container {
  display: flex;
  justify-content: space-between;

  background: #f3f3f3;
  border: 1px solid #dddddd;
  padding: 0 16px;
}

.form-title {
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.show-title {
  font-size: 16px;
  color: #373e41;
  margin-right: 10px;
}
.show-value {
  font-weight: bold;
  line-height: 18px;
  color: #373e41;
  line-height: 48px;
}
</style>
