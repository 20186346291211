<script lang="ts">
import NewCard from '@/components/cusCard/NewCard.vue';
import { UserModule } from '@/store/modules/user';
import { ALL_CLAIMS_CODES } from '@/utils/workData/lookuptable';
import { Component, Vue } from 'vue-property-decorator';
import AccountSettings from './components/AccountSettings.vue';
import UserProfile from './components/UserProfile.vue';
import ViewSettings from './components/ViewSettings.vue';

@Component({
  name: 'userSetting',
  components: {
    'new-card': NewCard,
    'user-profile': UserProfile,
    'account-settings': AccountSettings,
    'view-settings': ViewSettings,
  },
})
export default class extends Vue {
  ALL_CLAIMS_CODES = ALL_CLAIMS_CODES;
  claims = UserModule.claims;
  helpcenterUrl = Vue.prototype.$envConfig.VUE_APP_HELP_CENTER_URL;

  selectActiveTab(selectedTab: { name: string }) {
    this.$router.push({
      query: { ...this.$route.query, activeName: selectedTab.name },
    });
  }

  get activeTab(): string {
    return (this.$route.query.activeName as string | null) ?? 'myProfile';
  }
}
</script>

<template>
  <new-card id="user_setting_new_card" :visible="false">
    <el-tabs
      id="user_setting_tabs"
      :value="activeTab"
      @tab-click="selectActiveTab"
    >
      <el-tab-pane
        id="user_setting_pane_profile"
        name="myProfile"
        v-if="claims.hasClaim(ALL_CLAIMS_CODES.AUTHRSC_PAGE_MY_PROFILE)"
        :label="$t('userSetting.userProfile')"
      >
        <user-profile />
      </el-tab-pane>
      <el-tab-pane
        id="user_setting_pane_account"
        name="accountSettings"
        v-if="claims.hasClaim(ALL_CLAIMS_CODES.AUTHRSC_PAGE_ACCOUNT_SETTINGS)"
        :label="$t('userSetting.accountSettings')"
      >
        <account-settings />
      </el-tab-pane>
      <el-tab-pane
        id="user_setting_pane_view"
        name="viewSettings"
        v-if="claims.hasClaim(ALL_CLAIMS_CODES.AUTHRSC_PAGE_VIEW_SETTINGS)"
        :label="$t('userSetting.viewSettings')"
      >
        <view-settings />
      </el-tab-pane>
      <el-tab-pane
        id="user_setting_pane_helpcenter"
        name="helpCenter"
        v-if="claims.hasClaim(ALL_CLAIMS_CODES.AUTHRSC_PAGE_HELPCENTER)"
        :label="$t('userSetting.helpcenter')"
      >
        <div
          v-html="
            $t('userSetting.helpcenterContent', {
              helpcenterUrl,
            })
          "
          class="help-center"
        ></div>
      </el-tab-pane>
    </el-tabs>
  </new-card>
</template>

<style lang="scss" scoped>
.user-setting-form {
  margin: 0 20px;
}

#user_setting_tabs {
  padding: 0 20px;
}

#user_setting_tabs,
:deep(#user_setting_tabs) > div.el-tabs__content,
:deep(#user_setting_pane_view) {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.help-center {
  :deep(ul > li::marker) {
    font-size: 12px;
  }

  :deep(h3) {
    margin-bottom: 32px;
  }

  :deep(li) {
    margin-bottom: 16px;
  }

  :deep(ul) {
    margin-bottom: 32px;
  }
}
</style>
