<script setup lang="ts">
import { getKpiData } from '@/api/assets';
import { UUID } from '@/api/common';
import {
  KpiDataField,
  KpiDataRequest,
  KpiDataResponse,
  KpiDataValue,
} from '@/api/kpis';
import { useActiveContext } from '@/auth/context';
import MultiSelectDropDown from '@/components/form/MultiSelectDropDown.vue';
import TimeSelect from '@/components/form/TimeSelect.vue';
import GrowthPercentage from '@/components/general/GrowthPercentage.vue';
import BarChart from '@/components/kpiCharts/BarChart.vue';
import WidgetCard from '@/components/layout/widget/WidgetCard.vue';
import KpiTargetTable, {
  KpiTargetTableData,
} from '@/components/table/KpiTargetTable.vue';
import { useAsync } from '@/composables/async';
import { useUnitConversion } from '@/composables/conversion';
import { showNotificationOnError } from '@/composables/error';
import { useRoute } from '@/composables/router';
import i18n from '@/lang';
import { useOrganizationAssetsHierarchyQuery } from '@/query/assets';
import { extractAssetsFromOrganizationHierarchy } from '@/utils/assets';
import { AssetType } from '@/utils/assetTypes';
import { formatValue } from '@/utils/format';
import { calculatePercentageChange } from '@/utils/math';
import { formatNumber } from '@/utils/number';
import { never } from '@/utils/promise';
import { customFailedMessage } from '@/utils/prompt';
import {
  DEFAULT_DATE_RANGE,
  getPreviousRange,
  isSameDateRange,
} from '@/utils/time';
import { DateRange } from '@/utils/types/date';
import { Option } from '@/utils/types/option';
import { KPI_UNIT } from '@/utils/units/unitDefinitions';
import { toUnitValue } from '@/utils/units/unitValue';
import { KPI_FIELDS } from '@/utils/workData/lookuptable';
import {
  AggregationType,
  aggregationTypeToBucket,
} from '@/widgets/utils/Constants';
import { computed, ref, unref, watchEffect } from 'vue';

interface Cycle {
  dateRange: DateRange;
  totalAssets: number;
  totalTrips: number;
  totalTripsGrowth?: number;
  payloadPerTrip: number;
  payloadPerTripGrowth?: number;
  totalPayload: number;
  totalPayloadGrowth?: number;
}

interface CyclicalData {
  currentCycle: Cycle;
  previousCycle: Cycle;
}

type ChartData = string[][];

const aggregationButtons = [
  {
    id: AggregationType.AggregateByTime,
    label: i18n.t('newFleetSafety.time'),
  },
  {
    id: AggregationType.AggregateByAsset,
    label: i18n.t('newFleetSafety.asset'),
  },
];

const route = useRoute();
const context = useActiveContext();

const dateRange = ref<DateRange>(DEFAULT_DATE_RANGE);
const selectedAggregationType = ref<AggregationType>(
  AggregationType.AggregateByTime
);

const { data, isLoading: orgAssetsIsLoading } =
  useOrganizationAssetsHierarchyQuery(AssetType.TippingVehicle);

const assetOptions = computed((): Option[] => {
  if (!data.value) {
    return [];
  }
  return extractAssetsFromOrganizationHierarchy(data.value).map((asset) => ({
    key: asset.id,
    label: asset.companyAssetId,
  }));
});

const selectedAssetIDs = ref<UUID[]>([]);

function getKpiRequestBody(
  dateRange: DateRange,
  aggregationType: AggregationType,
  useEndExclusiveInDateRange: boolean = true
): KpiDataRequest {
  return {
    metadata: {
      filter: { assetIds: selectedAssetIDs.value },
      selection: {
        startDate: dateRange.start,
        endDate: useEndExclusiveInDateRange
          ? dateRange.endExclusive
          : dateRange.end,
        dataBucketDimension: aggregationTypeToBucket[aggregationType],
      },
    },
    details: [
      {
        entity: 'ENTT_ASSET',
        fields: [
          {
            code: 'KPI.TippingPayload',
            unit: 'UNIT_METRIC_TONNE',
            needGrowthPercentage: false,
          },
          {
            code: 'KPI.TripCount',
            unit: 'UNIT_COUNT',
            needGrowthPercentage: false,
          },
        ],
      },
    ],
  };
}

function aggregateCycleData(
  kpiDataFields: KpiDataField[],
  dateRange: DateRange
): Cycle {
  const tippingPayload = kpiDataFields.find(
    (kpi) => kpi.code === KPI_FIELDS.TippingPayload
  );
  const tripCount = kpiDataFields.find(
    (kpi) => kpi.code === KPI_FIELDS.TripCount
  );

  if (tippingPayload === undefined) {
    throw new Error('Tipping Payload KPI not found');
  }
  if (tripCount === undefined) {
    throw new Error('Trip Count KPI not found');
  }

  const cycle: Cycle = {
    dateRange: dateRange,
    totalPayload: tippingPayload.values.reduce(
      (acc, currentItem) => acc + parseFloat(currentItem.v),
      0
    ),
    payloadPerTrip: 0,
    totalAssets: 0,
    totalTrips: 0,
  };

  for (const asset of tripCount.values) {
    const parsedValue = parseInt(asset.v, 10);
    cycle.totalTrips += parsedValue;

    if (parsedValue !== 0) {
      cycle.totalAssets++;
    }
  }

  cycle.payloadPerTrip = cycle.totalPayload / cycle.totalTrips;

  return cycle;
}

function convertCycleData(cycle: Cycle): Cycle {
  return {
    ...cycle,
    payloadPerTrip: currentUserConvertUnitValue({
      v: cycle.payloadPerTrip,
      unit: KPI_UNIT.MetricTonne,
    }).v,
    totalPayload: currentUserConvertUnitValue({
      v: cycle.totalPayload,
      unit: KPI_UNIT.MetricTonne,
    }).v,
  };
}

const chartAndTableKpiData = useAsync(
  computed(async (): Promise<KpiDataResponse | undefined> => {
    if (selectedAssetIDs.value.length === 0) {
      return undefined;
    }

    const kpiRequestBody = getKpiRequestBody(
      dateRange.value,
      selectedAggregationType.value
    );
    const response = await getKpiData(kpiRequestBody, unref(context));

    return response.data;
  })
);

const previousCycle = useAsync(
  computed(async (): Promise<Cycle | undefined> => {
    if (selectedAssetIDs.value.length === 0) {
      return undefined;
    }

    const prevRange = getPreviousRange(dateRange.value);
    const kpiRequestBody = getKpiRequestBody(
      prevRange,
      AggregationType.AggregateByAsset
    );
    const response = await getKpiData(kpiRequestBody, unref(context));

    return convertCycleData(
      aggregateCycleData(response.data.details[0].fields, prevRange)
    );
  }),
  { clearOnRefresh: true }
);

const currentCycle = useAsync(
  computed(async (): Promise<Cycle | undefined> => {
    if (selectedAssetIDs.value.length === 0) {
      return undefined;
    }

    const kpiRequestBody = getKpiRequestBody(
      dateRange.value,
      AggregationType.AggregateByAsset
    );
    const response = await getKpiData(kpiRequestBody, unref(context));

    return convertCycleData(
      aggregateCycleData(response.data.details[0].fields, dateRange.value)
    );
  }),
  { clearOnRefresh: true }
);

const cycleData = useAsync(
  computed(async (): Promise<CyclicalData | undefined> => {
    if (selectedAssetIDs.value.length === 0) {
      return undefined;
    }

    const prevCycle = previousCycle.value.data;
    const currCycle = currentCycle.value.data;

    if (prevCycle === undefined || currCycle === undefined) {
      return never();
    }

    return {
      previousCycle: prevCycle,
      currentCycle: {
        ...currCycle,
        payloadPerTripGrowth: calculatePercentageChange(
          prevCycle.payloadPerTrip,
          currCycle.payloadPerTrip
        ),
        totalPayloadGrowth: calculatePercentageChange(
          prevCycle.totalPayload,
          currCycle.totalPayload
        ),
        totalTripsGrowth: calculatePercentageChange(
          prevCycle.totalTrips,
          currCycle.totalTrips
        ),
      },
    };
  })
);

watchEffect(() => {
  if (unref(route).params.id) {
    selectedAssetIDs.value = [unref(route).params.id];
  } else {
    selectedAssetIDs.value = assetOptions.value.map((asset) => asset.key);
  }
});

const { currentUserConvertUnitValue, currentUserPreferredUnit } =
  useUnitConversion();

const fleetTargetsChartData = useAsync(
  computed(async (): Promise<ChartData> => {
    if (chartAndTableKpiData.value.loading) {
      return never();
    }

    if (chartAndTableKpiData.value.error) {
      throw chartAndTableKpiData.value.error;
    }

    const unit = KPI_UNIT.MetricTonne;

    if (selectedAssetIDs.value.length === 0) {
      return [];
    }

    let kpiDataValueList: KpiDataValue[];

    kpiDataValueList =
      chartAndTableKpiData.value.data?.details[0]?.fields?.find(
        (kpiDataField) => kpiDataField.code === 'KPI.TippingPayload'
      )?.values ?? [];

    return kpiDataValueList
      .map((item) => ({
        ...item,
        v: item.v
          ? currentUserConvertUnitValue(toUnitValue(parseFloat(item.v), unit)).v
          : 0,
      }))
      .map((el) => [
        selectedAggregationType.value === AggregationType.AggregateByTime
          ? `${el.ts}`
          : `${el.id}`,
        formatValue({ v: el.v }, { numberOfDecimals: 2 }),
      ]);
  })
);

showNotificationOnError(fleetTargetsChartData, 'common.errorWithFetchingData');

function mapTableEntriesByAssetAggregation(
  options: Option<string>[],
  tippingPayload: KpiDataField,
  tripCount: KpiDataField
): KpiTargetTableData[] {
  return options.map((asset) => {
    const tippingPayloadValue = tippingPayload?.values.find(
      (value: KpiDataValue) => value.k === asset.key
    );

    const trip = tripCount.values.find(
      (value: KpiDataValue) => value.k === asset.key
    );

    if (
      !tippingPayloadValue ||
      tippingPayloadValue.v == null ||
      !trip ||
      trip.v == null
    ) {
      throw new Error('Invalid api response, v must be present.');
    }

    const convertedPayload = currentUserConvertUnitValue(
      toUnitValue(parseFloat(tippingPayloadValue.v), tippingPayload.unit)
    );

    const payloadPerTrip = convertedPayload.v / parseFloat(trip?.v);

    return {
      id: asset.key,
      assetId: asset.label,
      payload: convertedPayload.v,
      trip: parseFloat(trip?.v),
      payloadPerTrip: isNaN(payloadPerTrip) ? -Infinity : payloadPerTrip,
    };
  });
}

function mapTableEntriesByTimeAggregation(
  tippingPayload: KpiDataField,
  tripCount: KpiDataField
): KpiTargetTableData[] {
  const dateToTableData: Map<string, KpiTargetTableData> = new Map<
    string,
    KpiTargetTableData
  >();

  for (const item of tippingPayload.values) {
    if (!item.ts) {
      throw new Error('ts defined when time aggregation is selected');
    }

    const convertedPayload = item.v
      ? currentUserConvertUnitValue(
          toUnitValue(parseFloat(item.v), tippingPayload.unit)
        )
      : { v: 0 };

    dateToTableData.set(item.ts, {
      id: item.ts,
      assetId: undefined,
      date: item.ts,
      payload: convertedPayload.v,
      payloadPerTrip: 0,
      trip: 0,
    });
  }

  for (const item of tripCount.values) {
    if (!item.ts) {
      throw new Error('ts has to be defined when time aggregation is selected');
    }

    const existingDataPoint = dateToTableData.get(item.ts);

    if (existingDataPoint === undefined) {
      throw new Error('Trip Count has to exist if there is Trip Payload');
    }

    const payloadPerTrip = existingDataPoint.payload / parseFloat(item.v);

    dateToTableData.set(item.ts, {
      ...existingDataPoint,
      trip: item.v ? parseFloat(item.v) : 0,
      payloadPerTrip: isNaN(payloadPerTrip) ? -Infinity : payloadPerTrip,
    });
  }

  return [...dateToTableData.values()];
}

const filteredTableData = useAsync(
  computed(async (): Promise<KpiTargetTableData[]> => {
    try {
      if (chartAndTableKpiData.value.loading) {
        return never();
      }

      if (chartAndTableKpiData.value.data === undefined) {
        return [];
      }

      const tippingPayload =
        chartAndTableKpiData.value.data?.details[0].fields.find(
          (dataField: KpiDataField) =>
            dataField.code === KPI_FIELDS.TippingPayload
        );

      if (tippingPayload === undefined) {
        throw new Error('Tipping Payload not found');
      }

      const tripCount = chartAndTableKpiData.value.data?.details[0].fields.find(
        (dataField: KpiDataField) => dataField.code === KPI_FIELDS.TripCount
      );

      if (tripCount === undefined) {
        throw new Error('Trip Count not found');
      }

      const selectedAssets = assetOptions.value.filter((asset) =>
        selectedAssetIDs.value.includes(asset.key)
      );

      const finalTableData =
        selectedAggregationType.value === AggregationType.AggregateByTime
          ? mapTableEntriesByTimeAggregation(tippingPayload, tripCount)
          : mapTableEntriesByAssetAggregation(
              selectedAssets,
              tippingPayload,
              tripCount
            );

      return finalTableData;
    } catch (e) {
      console.error(e);
      customFailedMessage(i18n.t('common.errorWithFetchingData'));
      throw new Error('Aggregating table data failed');
    }
  })
);

function handleTimeFilter(range: DateRange) {
  // TimeSelect always emits the first range, even though it's same as our default
  if (!isSameDateRange(range, unref(dateRange))) {
    dateRange.value = range;
  }
}

function onAssetSelection(selectedOptions: string[]) {
  selectedAssetIDs.value = selectedOptions;
}
</script>

<template>
  <WidgetCard>
    <template #actions>
      <MultiSelectDropDown
        v-loading="orgAssetsIsLoading"
        :filter-label="$t('selectAssets')"
        :options="assetOptions"
        :selected-options="selectedAssetIDs"
        @change="onAssetSelection"
      />
      <TimeSelect
        :expanded="true"
        @select="handleTimeFilter"
        :customizable="true"
      />
      <el-radio-group
        fill="gray"
        v-model="selectedAggregationType"
        size="small"
        class="radio_area"
      >
        <el-radio-button
          border
          style="margin: 0"
          v-for="option in aggregationButtons"
          :label="option.id"
          :key="option.id"
        >
          {{ option.label }}
        </el-radio-button>
      </el-radio-group>
    </template>
    <div class="body-wrapper">
      <div class="card-container">
        <div class="card-item">
          <BarChart
            v-if="
              fleetTargetsChartData.loading ||
              !!fleetTargetsChartData.data?.length
            "
            v-loading="fleetTargetsChartData.loading"
            :chartData="fleetTargetsChartData.data"
            height="100%"
            :itemColor="'#589E65'"
            :yAxisUnit="$t('metricUnits.ton')"
            :xAxisLabelRotation="
              selectedAggregationType === AggregationType.AggregateByAsset
                ? 25
                : 0
            "
          />
          <div class="no-table-data-block" v-else>
            <div class="no-table-data-message">{{ $t('common.noData') }}</div>
          </div>
        </div>
        <div class="card-item">
          <KpiTargetTable
            v-loading="filteredTableData.loading"
            :element-loading-text="$t('common.loading')"
            :tableData="filteredTableData.data ?? []"
            :aggregation-type="selectedAggregationType"
          />
        </div>
      </div>

      <div v-loading="cycleData.loading" class="cycle-container">
        <div></div>
        <div class="header-1 cycle-title">
          {{ $t('common.totalAssets') }}
        </div>
        <div class="header-2 cycle-title">
          {{ $t('tippingVehicles.target.totalTrips') }}
        </div>
        <div class="header-3 cycle-title">
          {{
            $t('kpiTarget.table.avgPayloadPerTrip', {
              unit: $t(currentUserPreferredUnit(KPI_UNIT.MetricTonne)),
            })
          }}
        </div>
        <div class="header-4 cycle-title">
          {{
            $t('common.totalPayload', {
              unit: $t(currentUserPreferredUnit(KPI_UNIT.MetricTonne)),
            })
          }}
        </div>
        <div class="cell-1 cycle-value cycle-description">
          <div>{{ $t('tippingVehicles.target.currentCycle') }}:</div>
          <div class="cycle-date">
            {{
              cycleData.data
                ? `${cycleData.data?.currentCycle.dateRange.start} — ${cycleData.data?.currentCycle.dateRange.end}`
                : ''
            }}
          </div>
        </div>
        <div class="cell-2 cycle-value">
          {{ cycleData.data?.currentCycle.totalAssets }}
        </div>
        <div class="cell-3 cycle-value cycle-percentage-item">
          {{ cycleData.data?.currentCycle.totalTrips }}
          <GrowthPercentage
            class="growth-percentage"
            :growthPercentage="cycleData.data?.currentCycle.totalTripsGrowth"
          />
        </div>
        <div class="cell-4 cycle-value cycle-percentage-item">
          {{
            formatNumber(cycleData.data?.currentCycle.payloadPerTrip, 2, '0')
          }}
          <GrowthPercentage
            class="growth-percentage"
            :growthPercentage="
              cycleData.data?.currentCycle.payloadPerTripGrowth
            "
          />
        </div>
        <div class="cell-5 cycle-value cycle-percentage-item">
          {{ formatNumber(cycleData.data?.currentCycle.totalPayload, 2, '0') }}
          <GrowthPercentage
            class="growth-percentage"
            :growthPercentage="cycleData.data?.currentCycle.totalPayloadGrowth"
          />
        </div>
        <div class="cell-1 cycle-value cycle-description">
          <div>{{ $t('tippingVehicles.target.lastCycle') }}:</div>
          <div class="cycle-date">
            {{
              cycleData.data
                ? `${cycleData.data?.previousCycle.dateRange.start} — ${cycleData.data?.previousCycle.dateRange.end}`
                : ''
            }}
          </div>
        </div>
        <div class="cell-2 cycle-value">
          {{ cycleData.data?.previousCycle.totalAssets }}
        </div>
        <div class="cell-3 cycle-value cycle-percentage-item">
          {{ cycleData.data?.previousCycle.totalTrips }}
        </div>
        <div class="cell-4 cycle-value cycle-percentage-item">
          {{
            formatNumber(cycleData.data?.previousCycle.payloadPerTrip, 2, '0')
          }}
        </div>
        <div class="cell-5 cycle-value cycle-percentage-item">
          {{ formatNumber(cycleData.data?.previousCycle.totalPayload, 2, '0') }}
        </div>
      </div>
    </div>
  </WidgetCard>
</template>

<style lang="scss" scoped>
.body-wrapper {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.growth-percentage {
  font-size: 16px;
}

.card-container {
  display: flex;
  flex-wrap: wrap;
  flex-grow: 1;
  border-bottom: 1px solid #dddddd;
}

.cycle-container {
  padding: 11px 37px;
  display: grid;
  grid-template-areas:
    'empty  header-1 header-2 header-3 header-4'
    'cell-1 cell-2 cell-3 cell-4 cell-5';
  align-items: center;
  row-gap: 21px;
}

.cycle-date {
  color: #373e41;
  font-weight: 400;
  font-size: 14px;
}

.cycle-description {
  display: grid;
  grid-template-columns: 125px auto;
  align-items: center;
  width: 100%;
}

.cycle-percentage-item {
  display: flex;
  gap: 6px;
}

.header-1 {
  grid-area: header-1;
}

.header-2 {
  grid-area: header-2;
}

.header-3 {
  grid-area: header-3;
}

.header-4 {
  grid-area: header-4;
}

.cycle-title {
  color: #373e41;
  font-size: 16px;
  font-weight: 400;
  width: 100%;
}

.cycle-value {
  font-weight: bold;
  color: #373e41;
  font-size: 16px;
}

@media (min-width: 1422px) {
  .card-item:first-child {
    width: 60%;
    border-right: 1px solid #dddddd;
  }

  .card-item:last-child {
    width: 40%;
  }
}

@media (max-width: 1422px) {
  .card-item {
    width: 100%;
  }

  .card-item:first-child {
    height: 560px;
  }

  .card-item:last-child {
    height: 50%;
  }
}

.no-table-data-block {
  display: flex;
  margin-top: 20px;

  .no-table-data-message {
    margin: auto;
  }
}
</style>
