import { CommonResult } from '@/api/commonResult';
import axios, {
  AxiosInterceptorManager,
  AxiosRequestConfig,
  AxiosResponse,
} from 'axios';
import { Message } from 'element-ui';
import { stringify } from 'qs';

/**
 * AxiosInstanceCustom was created to accomodate a discrepancy introduced in previous versions of the code.
 * The service response interceptor transforms the result from an AxiosResponse<CommonResult<any>> to a
 * CommonResult<any>, and transforms errors back into a 'normal' response. This is inconvenient in practice.
 *
 * To prevent having to rewrite all current usages of the axios instance, this interface is defined as an in-between
 * step to at least allow TypeScript to correctly catch errors.
 *
 * @deprecated For new code, use the functions provided by `@/utils/api` instead.
 */
// prettier-ignore
export interface AxiosInstanceCustom {
  interceptors: {
    request: AxiosInterceptorManager<AxiosRequestConfig>;
    response: AxiosInterceptorManager<AxiosResponse>;
  };
  request<T = any, R = CommonResult<T>, D = any>(config: AxiosRequestConfig<D>): Promise<R>;
  get<T = any, R = CommonResult<T>, D = any>(url: string, config?: AxiosRequestConfig<D>): Promise<R>;
  delete<T = any, R = CommonResult<T>, D = any>(url: string, config?: AxiosRequestConfig<D>): Promise<R>;
  head<T = any, R = CommonResult<T>, D = any>(url: string, config?: AxiosRequestConfig<D>): Promise<R>;
  options<T = any, R = CommonResult<T>, D = any>(url: string, config?: AxiosRequestConfig<D>): Promise<R>;
  post<T = any, R = CommonResult<T>, D = any>(url: string, data?: D, config?: AxiosRequestConfig<D>): Promise<R>;
  put<T = any, R = CommonResult<T>, D = any>(url: string, data?: D, config?: AxiosRequestConfig<D>): Promise<R>;
  patch<T = any, R = CommonResult<T>, D = any>(url: string, data?: D, config?: AxiosRequestConfig<D>): Promise<R>;
  postForm<T = any, R = CommonResult<T>, D = any>(url: string, data?: D, config?: AxiosRequestConfig<D>): Promise<R>;
  putForm<T = any, R = CommonResult<T>, D = any>(url: string, data?: D, config?: AxiosRequestConfig<D>): Promise<R>;
  patchForm<T = any, R = CommonResult<T>, D = any>(url: string, data?: D, config?: AxiosRequestConfig<D>): Promise<R>;
}

let service: AxiosInstanceCustom = axios.create({});
let serviceToken: string | undefined;

export function setRequestToken(token: string | undefined): void {
  serviceToken = token;
}

export const initializeRequestService = (baseUrl: string) => {
  service = axios.create({
    baseURL: baseUrl, // url = base url + request url
    // timeout: 5000
    // withCredentials: true // send cookies when cross-domain requests
    paramsSerializer: (params) => stringify(params, { indices: false }),
  });

  // Request interceptors
  service.interceptors.request.use(
    (config) => {
      // Add Authorization Bearer Token header to every request, you can add other custom headers here
      if (serviceToken) {
        config.headers!['Authorization'] = 'Bearer ' + serviceToken;
      }
      return config;
    },
    (error) => {
      Promise.reject(error);
    }
  );

  // Response interceptors
  service.interceptors.response.use(
    (response: AxiosResponse<CommonResult<any>>) => {
      const res = response.data;

      if (res.code && res.code.toString().indexOf('2') !== 0) {
        Message({
          message: res.message || 'Error',
          type: 'error',
          duration: 5 * 1000,
        });
        return Promise.reject(new Error(res.message || 'Error'));
      } else {
        return response.data;
        // return response
      }
    },
    (error) => {
      // TODO-JW: When is this code-path taken?

      // console.log("errrrrrrror")
      // console.log(error.response)
      // Message({
      //   message: error.message,
      //   type: 'error',
      //   duration: 5 * 1000
      // })
      // return Promise.reject(error)
      if (error.response) return error.response.data;
    }
  );
};

export const getRequest = () => {
  if (!service) throw new Error('Service is not initialized');
  return service;
};
