export const COMPACTOR_PARTS_LIST = [
  {
    label: 'prodCata.containedPart',
    prop: 'name',
    required: true,
    visible: true,
    sortable: true,
  },
  {
    label: 'prodCata.partMaterialId',
    prop: 'partMaterialNumber',
    required: true,
    visible: true,
    sortable: true,
  },
  {
    label: 'prodCata.partMaterialName',
    prop: 'partMaterialName',
    required: true,
    visible: true,
    multiLang: true,
    sortable: true,
  },
  {
    label: 'prodCata.maintainable',
    prop: 'isMaintainable',
    required: true,
    visible: true,
    multiLang: true,
    sortable: true,
  },
  {
    label: 'prodCata.lifetime',
    prop: 'lifeTime',
    required: true,
    visible: true,
    multiLang: true,
    sortable: true,
  },
];
