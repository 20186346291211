<script lang="ts">
import {
  AccessibleAssetsObjectModel,
  getAccessibleAssets,
  removeCustomerAssets,
} from '@/api/accessibleAssets';
import { getCustomerById } from '@/api/customer';
import { getOrganizations } from '@/api/organizations';
import NewCard from '@/components/cusCard/NewCard.vue';
import PureTable from '@/components/table/PureTable.vue';
import SelectTableHeader from '@/components/table/SelectTableHeader.vue';
import {
  addFilter,
  Filter,
  FilterOperator,
  Pagination,
  QueryParameter,
  Sorter,
  SorterOrder,
} from '@/model/queryParameters/QueryParameter';
import { UserModule } from '@/store/modules/user';
import { CREATED_ON } from '@/utils/constants';
import { customFailedMessage, customSuccessMessage } from '@/utils/prompt';
import {
  ASSIGNED_ASSETS_SEARCH_OPTION_COLS,
  CUSTOMER_MANAGEMENT_DESIGNATED_USER_ASSETS_COLS,
} from '@/utils/workData/assetsMgmt';
import { ACTIVATION_STATUS } from '@/utils/workData/lookuptable';
import { Component, Vue } from 'vue-property-decorator';

interface TableRowData extends AccessibleAssetsObjectModel {
  translatedAssetType: string;
  translatedStatus: string;
  translatedServiceRegion: string;
}

@Component({
  name: 'RemoveAccess',
  components: {
    NewCard,
    PureTable,
    SelectTableHeader,
  },
})
export default class extends Vue {
  isViewModeLoading: boolean = false;
  assetTotal: number = 0;
  assetList: TableRowData[] = [];
  pageSize: number = UserModule.gridPageSize;
  customerId: string = this.$route.params.customerId;
  assetSortAndOrderData: any = {
    sortBy: null,
    order: null,
  };
  assetSearchParams: any = {
    reference: null,
    value: null,
  };
  customerInfo: any = {};
  title: string = '';
  logo: any = '';
  activateOrDeactivate: string = '';
  orgTotal: number = 0;
  orgList: any[] = [];
  selectedAssetes: string[] = [];
  selectedOrganizationId: string = '';
  currentPage = 1;
  assetCols = CUSTOMER_MANAGEMENT_DESIGNATED_USER_ASSETS_COLS.map((item) => ({
    ...item,
    label: this.$t(item.label),
  }));
  organization: string = '';
  assetsSearchFields: any = ASSIGNED_ASSETS_SEARCH_OPTION_COLS;
  customerType: string = '';
  assetsLoadingText: any = this.$t('assetsModule.assetsAreLoading');
  isAssetTableLoading: boolean = false;

  created() {
    this.prepareDefaultInitialization();
  }

  /** Prepare default initialization */
  prepareDefaultInitialization() {
    this.customerId && this.fetchCustomerInfo();
    this.fetchCustomerOrganizationsInfos();
  }

  /** Handle fetch assets */
  handleFetchAssets() {
    if (this.assetSearchParams.value == null) {
      this.currentPage = 1;
      this.pageSize = UserModule.gridPageSize;
      this.assetSortAndOrderData.sortBy = null;
    }
    this.fetchAccessibleAssets(
      this.selectedOrganizationId,
      this.currentPage,
      this.pageSize
    );
  }

  /** Fetch accessible assets */
  async fetchAccessibleAssets(
    organizationId: string,
    page: number,
    pageSize: number
  ) {
    if (!this.selectedOrganizationId) {
      return;
    }
    try {
      this.isAssetTableLoading = true;
      const queryParameter: QueryParameter =
        this.prepareQueryParameterForAccessibleAssets(page, pageSize);
      const res = await getAccessibleAssets(queryParameter, organizationId);

      if (!res || res.code !== 200) {
        customFailedMessage(
          this.$t('subscriptionPackages.errorWithFetchingData').toString()
        );
        return;
      }

      this.assetTotal = res.data.total;
      this.assetList = res.data.assets.map(
        (item): TableRowData => ({
          ...item,
          translatedServiceRegion: this.$t(item.serviceRegion),
          translatedAssetType: this.$t(item.assetType),
          translatedStatus: this.$t(item.assetStatus),
          companyAssetId: item.companyAssetId || item.preliminaryAssetId,
        })
      );
    } catch (error) {
      console.log(error);
    } finally {
      this.isAssetTableLoading = false;
    }
  }

  /** Handle query params by user interaction */
  prepareQueryParameterForAccessibleAssets(
    page: number,
    pageSize: number
  ): QueryParameter {
    const sorter: Sorter =
      this.assetSortAndOrderData.sortBy != null
        ? {
            field: this.assetSortAndOrderData.sortBy,
            order: this.assetSortAndOrderData.order,
          }
        : { field: CREATED_ON, order: SorterOrder.DESC };
    const pagination: Pagination = { page: page, size: pageSize };
    let queryParameter: QueryParameter = {
      filters: [],
      sorters: [sorter],
      pagination: pagination,
    };
    if (
      this.assetSearchParams.value != null &&
      this.assetSearchParams.value != ''
    ) {
      const operator: FilterOperator = this.assetSearchParams.operator
        ? this.assetSearchParams.operator
        : FilterOperator.LIKE;
      const filter: Filter = {
        name: this.assetSearchParams.reference,
        operator: operator,
        value: [this.assetSearchParams.value],
      };
      queryParameter = addFilter(queryParameter, filter);
    }
    return queryParameter;
  }

  /** Fetch customer info */
  async fetchCustomerInfo() {
    this.isViewModeLoading = true;
    await getCustomerById(this.customerId).then((res) => {
      if (!res) {
        customFailedMessage(
          this.$t('subscriptionPackages.errorWithFetchingData').toString()
        );
        return;
      }

      if (res.code === 200 && Object.keys(res.data).length > 0) {
        this.customerInfo = res.data;
        this.title = this.customerInfo.name;
        this.logo = `${Vue.prototype.$envConfig.VUE_APP_BASE_API}/companies/${this.customerId}/logo`;

        if (
          this.customerInfo.activationStatus === ACTIVATION_STATUS.Deactivated
        ) {
          this.activateOrDeactivate = 'activate';
        } else {
          this.activateOrDeactivate = 'deactivate';
        }

        this.isViewModeLoading = false;
        return;
      }

      this.isViewModeLoading = false;
      customFailedMessage(
        this.$t('subscriptionPackages.errorWithFetchingData').toString()
      );
    });
  }

  /** Fetch customer organization info */
  async fetchCustomerOrganizationsInfos() {
    await getOrganizations(this.customerId).then((res) => {
      if (!res) {
        customFailedMessage(
          this.$t('subscriptionPackages.errorWithFetchingData').toString()
        );
        return;
      }

      if (res.code === 200) {
        this.orgTotal = res.data.length;
        this.orgList = this.findOrganizations(res.data[0]);
      }
    });
  }

  /** Find organization */
  findOrganizations(organization: any): any[] {
    return [
      organization,
      ...organization.children.flatMap((sub: any) =>
        this.findOrganizations(sub)
      ),
    ];
  }

  /** Handle table pagination */
  handleTablePagination(page: number, pageSize: number) {
    this.currentPage = page;
    this.pageSize = page;
    this.fetchAccessibleAssets(this.selectedOrganizationId, page, pageSize);
  }

  /** Filter by sort event */
  fetchAssignedAssetsDataBySortEvent(sortBy: any, order: any) {
    if (sortBy === 'organization') sortBy = 'organizationName';
    order != ''
      ? (this.assetSortAndOrderData.sortBy = sortBy)
      : (this.assetSortAndOrderData.sortBy = null);
    order != ''
      ? (this.assetSortAndOrderData.order = order)
      : (this.assetSortAndOrderData.order = null);
    this.fetchAccessibleAssets(
      this.selectedOrganizationId,
      this.currentPage,
      this.pageSize
    );
  }

  /** Organization change */
  async organizationChange(organizationId: string) {
    this.selectedOrganizationId = organizationId;
    await this.fetchAccessibleAssets(
      organizationId,
      this.currentPage,
      this.pageSize
    );
  }

  /** Handle update accessible assets */
  handleUpdateSelectedAssets(assets: string[]) {
    this.selectedAssetes = assets;
  }

  /** Handle remove accessible assets */
  async handleRemoveAssets() {
    if (!this.organization) {
      customFailedMessage(
        this.$t('customerModule.selectOrgAndAssetsToBeRemoved').toString()
      );
      return;
    }
    if (!this.selectedAssetes.length) {
      customFailedMessage(
        this.$t('customerModule.removeAccessibleAssets').toString()
      );
      return;
    }
    const res = await removeCustomerAssets(
      this.selectedAssetes,
      this.selectedOrganizationId
    );

    if (!res || res.code !== 200) {
      customFailedMessage(
        this.$t('customerModule.removeAccessError').toString()
      );
      return;
    }

    customSuccessMessage(
      this.$t('customerModule.operationSuccessful').toString()
    );
    this.$router.push({
      name: 'CustomerManagementView',
      query: { tab: 'assets' },
    });
    await this.fetchAccessibleAssets(
      this.selectedOrganizationId,
      this.currentPage,
      this.pageSize
    );
  }
}
</script>

<template>
  <NewCard
    v-loading="isViewModeLoading"
    id="view_cust_info_new_card"
    :title="
      customerId
        ? `${$t('customerModule.removeAccess')} ${title}`
        : $t('customerModule.removeAccess')
    "
    :createdOrEdit="customerId"
    :visibleEdit="false"
    :visibleDeactivate="false"
    :visible="true"
    :activateOrDeactivate="activateOrDeactivate"
  >
    <div class="page-content">
      <div class="row">
        <h3>{{ $t('customerModule.selectOrgRemove') }}</h3>
        <el-select
          v-model="organization"
          placeholder="Select"
          @change="organizationChange"
        >
          <el-option
            v-for="item in orgList"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
        <el-button
          class="el-button el-button--plain el-button--medium"
          v-on:click="handleRemoveAssets"
          v-if="
            $router.currentRoute.path.includes('/customer-management/view/')
          "
        >
          {{ $t('common.removeAccess') }}
        </el-button>
      </div>
      <h3>
        {{ $t('customerModule.slectedRemove') }}: {{ selectedAssetes.length }}
      </h3>
      <SelectTableHeader
        style="margin: 20px 0"
        :searchFieldOptions="assetsSearchFields"
        :cols="assetCols"
        :searchParams="assetSearchParams"
        :selectedAssetes="selectedAssetes"
        :customerType="customerType"
        @search-event="handleFetchAssets"
      />
      <el-row>
        <PureTable
          v-loading="isAssetTableLoading"
          :element-loading-text="assetsLoadingText"
          :tableList="assetList"
          :total="assetTotal"
          :cols="assetCols"
          :selectable="true"
          :enableRowSelection="true"
          @updateSelectedAssets="handleUpdateSelectedAssets"
          @handle-page="handleTablePagination"
          @handle-sort-change="fetchAssignedAssetsDataBySortEvent"
        />
      </el-row>
    </div>
  </NewCard>
</template>

<style lang="scss">
.page-content {
  padding: 24px;

  h2 {
    color: #373e41;
  }
}

.row {
  display: flex;
  flex-direction: row;

  .el-select {
    margin: auto auto auto 12px;
  }

  .el-button {
    background: #373e41;
    color: white;
    margin: auto 0 auto auto;
  }
}
</style>
