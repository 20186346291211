<script setup lang="ts">
import { AssetResponse } from '@/api/assetsMgmt';
import { getTimezones } from '@/api/timezone';
import CusFormItem from '@/components/form/CusFormItem.vue';
import { useAsync } from '@/composables/async';
import i18n from '@/lang';
import { useHyvaRegionsQuery } from '@/query/hyvaRegion';
import { useServiceRegionsQuery } from '@/query/serviceRegion';
import {
  AssetField,
  AssetFieldStateType,
  AssetStage,
  getAssetFieldStates,
} from '@/utils/assetTypes';
import { disabledFutureDate } from '@/utils/date';
import { distinct } from '@/utils/ref';
import { computed, ref, watch } from 'vue';

const props = defineProps<{ modelValue: AssetResponse }>();

const emit = defineEmits<{
  (e: 'update:modelValue', value: AssetResponse): void;
  (e: 'is-valid', value: boolean): void;
}>();

const assetForm = ref<AssetResponse>({ ...props.modelValue });
const assetFormRef = ref<any>(null);

watch(
  () => props.modelValue,
  () => {
    assetForm.value = { ...props.modelValue };
  },
  { deep: true }
);

const editedAsset = distinct(
  () => {
    return { ...assetForm.value };
  },
  undefined,
  { deep: true }
);

watch(
  editedAsset,
  async () => {
    emit('update:modelValue', editedAsset.value);

    try {
      const isFormValid = await assetFormRef.value?.validate();
      emit('is-valid', isFormValid);
    } catch (e) {
      emit('is-valid', false);
    }
  },
  { deep: true }
);

const formFieldState = getAssetFieldStates(
  AssetStage.Connect,
  props.modelValue.assetType
);

function validateMaxLength(
  field: AssetField,
  value: string | undefined,
  callback: Function
) {
  if (
    formFieldState[field] &&
    formFieldState[field].maxLength !== undefined &&
    value &&
    value.length > formFieldState[field]!.maxLength!
  ) {
    callback(
      new Error(
        i18n.t('assetMgmt.maxLengthValidation', {
          characterCount: formFieldState[field]!.maxLength!,
        })
      )
    );
  }

  callback();
}

const rules = {
  companyAssetId: [
    {
      required:
        formFieldState[AssetField.AssetId].type ===
        AssetFieldStateType.Required,
      message: i18n.t('common.mandatoryField'),
      trigger: 'change',
    },
    {
      validator: (rule: any, value: string, callback: Function) =>
        validateMaxLength(AssetField.AssetId, value, callback),
      trigger: 'change',
    },
  ],
  vinNumber: [
    {
      required:
        formFieldState[AssetField.VIN].type === AssetFieldStateType.Required,
      message: i18n.t('common.mandatoryField'),
      trigger: 'change',
    },
    {
      validator: (rule: any, value: string, callback: Function) =>
        validateMaxLength(AssetField.VIN, value, callback),
      trigger: 'change',
    },
  ],
  timezone: [
    {
      required: true,
      message: i18n.t('common.mandatoryField'),
      trigger: 'change',
    },
  ],
  serviceRegion: [
    {
      required: true,
      message: i18n.t('common.mandatoryField'),
      trigger: 'change',
    },
  ],
};

const { data: serviceRegions, isLoading: isServiceRegionsLoading } =
  useServiceRegionsQuery();
const { data: hyvaRegions, isLoading: isHyvaRegionsLoading } =
  useHyvaRegionsQuery();

const timezones = useAsync(
  computed(async () => {
    return (await getTimezones()).data;
  })
);

function onServiceRegionChange(selectedValue: string) {
  const selectedRegion = serviceRegions.value?.find(
    (servReg) => servReg.serviceRegionCode === selectedValue
  );

  if (selectedRegion === undefined) {
    throw new Error('Selected Region not found');
  }

  assetForm.value.hyvaRegion = selectedRegion.hyvaRegionCode;
}
</script>

<template>
  <el-form
    ref="assetFormRef"
    style="padding: 0 32px"
    :model="assetForm"
    :rules="rules"
  >
    <CusFormItem :title="$t('assetMgmt.AssetHIN')" :required="false">
      <el-form-item prop="assetDataCollectionId">
        <span>{{ assetForm.assetDataCollectionId }}</span>
      </el-form-item>
    </CusFormItem>

    <CusFormItem :title="$t('assetMgmt.assetType')" :required="false">
      <el-form-item prop="assetType">
        <span>{{ $t(assetForm.assetType) }}</span>
      </el-form-item>
    </CusFormItem>

    <CusFormItem :title="$t('assetMgmt.prodModel')" :required="false">
      <el-form-item prop="prodModel">
        <span>{{ assetForm.productModelCode }}</span>
      </el-form-item>
    </CusFormItem>

    <CusFormItem :title="$t('assetMgmt.modelNumber')" :required="false">
      <el-form-item prop="productModelNumber">
        <span>{{ assetForm.productModelNumber }}</span>
      </el-form-item>
    </CusFormItem>

    <CusFormItem
      v-if="formFieldState.VIN.type !== AssetFieldStateType.NotApplicable"
      :required="formFieldState.VIN.type === AssetFieldStateType.Required"
      :title="$t('assetMgmt.VIN')"
    >
      <el-form-item prop="vinNumber">
        <el-input
          v-model="assetForm.vinNumber"
          v-bind:placeholder="$t('assetMgmt.inputVIN')"
          clearable
        />
      </el-form-item>
    </CusFormItem>
    <CusFormItem
      v-if="formFieldState.AssetId.type !== AssetFieldStateType.NotApplicable"
      :required="formFieldState.AssetId.type === AssetFieldStateType.Required"
      :title="$t('assetMgmt.assetId')"
    >
      <el-form-item prop="companyAssetId">
        <el-input
          v-model="assetForm.companyAssetId"
          v-bind:placeholder="$t('assetMgmt.assetId')"
          clearable
        />
      </el-form-item>
    </CusFormItem>
    <CusFormItem :title="'common.serviceRegion'" :required="true">
      <el-form-item prop="serviceRegion">
        <el-select
          v-loading="isServiceRegionsLoading"
          v-model="assetForm.serviceRegion"
          v-bind:placeholder="$t('customerModule.pleaseSelect')"
          filterable
          @change="onServiceRegionChange"
        >
          <el-option
            v-for="item in serviceRegions"
            :key="item.serviceRegionCode"
            :label="$t(item.serviceRegionCode)"
            :value="item.serviceRegionCode"
          />
        </el-select>
      </el-form-item>
    </CusFormItem>
    <CusFormItem
      v-loading="isHyvaRegionsLoading"
      :title="'common.hyvaRegion'"
      :required="false"
    >
      <el-form-item prop="hyvaRegion">
        <el-select
          v-model="assetForm.hyvaRegion"
          v-bind:placeholder="''"
          filterable
          disabled
        >
          <el-option
            v-for="item in hyvaRegions"
            :key="item.code"
            :label="$t(item.code)"
            :value="item.code"
          />
        </el-select>
      </el-form-item>
    </CusFormItem>
    <CusFormItem :title="$t('assetMgmt.manufacterDate')" :required="false">
      <el-form-item prop="manufactureDate" class="date-picker">
        <el-date-picker
          v-model="assetForm.manufactureDate"
          type="date"
          v-bind:placeholder="$t('assetMgmt.selectManufactureDate')"
          clearable
          :picker-options="{
            disabledDate: disabledFutureDate,
          }"
        />
      </el-form-item>
    </CusFormItem>
    <CusFormItem :title="$t('assetMgmt.timeZone')">
      <el-form-item prop="timezone">
        <el-select
          v-model="assetForm.timezone"
          v-bind:placeholder="$t('assetMgmt.selectTimeZone')"
          clearable
          filterable
        >
          <el-option
            v-for="timezone of timezones.data"
            :key="timezone.fullName"
            :value="timezone.fullName"
          />
        </el-select>
      </el-form-item>
    </CusFormItem>
    <CusFormItem :title="$t('assetMgmt.controllerId')" :required="false">
      <el-form-item prop="controllerId">
        <el-input
          v-model="assetForm.controllerId"
          v-bind:placeholder="$t('assetMgmt.inputControllerId')"
          clearable
        />
      </el-form-item>
    </CusFormItem>
  </el-form>
</template>

<style lang="scss" scoped>
.el-date-editor.el-input__inner {
  width: 500px;
}

.el-date-editor {
  .el-form-item__content {
    width: 320px !important;
    margin-right: 0;

    .el-date-editor {
      width: 320px;
    }
  }
}

:deep(.el-input--medium .el-input__inner) {
  width: 320px !important;
}

.long_item :deep(.form-title) {
  width: 260px;
  height: 40px;
  line-height: 40px;
}

.el-date-editor.el-input,
.el-date-editor.el-input__inner {
  width: 320px;
}

:deep(.el-select) {
  width: 320px !important;
}

.el-input--suffix {
  width: 320px !important;
}
</style>
