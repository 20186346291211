<script lang="ts">
import { getKpiData } from '@/api/assets';
import { ActiveContext, useActiveContext } from '@/auth/context';
import { KpiGrowthUnitValue } from '@/components/widget/kpiNumber/KpiNumber.vue';
import { MultiKpiGrowthUnitValue } from '@/components/widget/kpiNumber/KpiNumberMultiExpanded.vue';
import {
  useUnitConversion,
  UseUnitConversionReturnType,
} from '@/composables/conversion';
import { determineEntityType } from '@/utils/assetRouteChecker';
import {
  calculateTotalAndAverageData,
  collateExpandTableData,
} from '@/utils/kpidata';
import { customFailedMessage } from '@/utils/prompt';
import { DEFAULT_DATE_RANGE } from '@/utils/time';
import { DateRange } from '@/utils/types/date';
import { EntityType } from '@/utils/workData/lookuptable';
import { ROUTE_ASSET_MAP } from '@/utils/workData/utilMap';
import { Ref, unref } from 'vue';
import {
  Component,
  Inject,
  ProvideReactive,
  Vue,
} from 'vue-property-decorator';
import KpiMultiBarChart from '../../../components/widget/KpiMultiBarCharts.vue';

@Component({
  name: 'AssetWorkingHours',
  components: {
    KpiMultiBarChart,
  },
})
export default class extends Vue {
  dateRange = DEFAULT_DATE_RANGE;
  radioList: any = [
    {
      code: 'KPI.WorkingHours',
      transCode: 'MotorWorking',
      transChartCode: 'motorWorking',
      tableHeadLabel: 'KPI.motorWorking',
      tableProp: 'workingHours',
      text: this.$t('KPI.motorWorking'),
      color: '#0F608B',
      markLineColor: '#EA6C00',
      markLineText: 'KPI.aveLineWorkingHours',
      unit: 'UNIT_HOUR',
    },
    {
      code: 'KPI.CompactingHours',
      transCode: 'Compacting',
      transChartCode: 'compacting',
      tableHeadLabel: 'KPI.compacting',
      tableProp: 'compacting',
      text: this.$t('KPI.compacting'),
      color: '#8BD0F5',
      markLineColor: '#5C33AE',
      markLineText: 'KPI.aveLineCompactingHours',
      unit: 'UNIT_HOUR',
    },
  ];
  kpiType: string = 'KPI.WorkingHours';
  totalNumber: number | undefined = undefined;
  averageNumber: number | undefined = undefined;
  KpiChartIsLoading: boolean = false;
  chartData: any = [];
  allCategoryData: any = [];
  unitCode = 'UNIT_HOUR';
  routeAssetMap = ROUTE_ASSET_MAP;
  entityType: EntityType = determineEntityType(this.$route);

  @ProvideReactive('tableList') tableList: any = [];
  @ProvideReactive('cols') cols: any = [];

  @Inject() expanded!: boolean;
  context!: Ref<ActiveContext>;
  unitConversion!: UseUnitConversionReturnType;

  created() {
    this.unitConversion = useUnitConversion();
    this.context = useActiveContext();
    if (this.$route.name && this.$route.name === 'MobileCompactorInfo') {
      this.radioList.splice(1, 0, {
        code: 'KPI.LiftingHours',
        transCode: 'Lifting',
        transChartCode: 'lifting',
        tableHeadLabel: 'KPI.lifting',
        tableProp: 'lifting',
        text: this.$t('KPI.lifting'),
        color: '#4292BB',
        markLineColor: '#AF2983',
        markLineText: 'KPI.aveLineLiftingHours',
        unit: 'UNIT_HOUR',
      });
    }

    if (
      this.$route.name &&
      (this.$route.name === 'StaticCompactorInfo' ||
        this.$route.name === 'AlbaStaticCompactorInfo' ||
        this.$route.name === 'RefuseCollectionVehicleInfo') &&
      this.radioList.length === 3
    ) {
      this.radioList.splice(1, 1);
    }
  }

  //compute kpiNumberList: it depend on the radio group value
  get kpiNumberList(): KpiGrowthUnitValue<number | undefined>[] {
    let filterRadioList = this.radioList.filter((item: any) => {
      return item.code === this.kpiType;
    });
    this.watchKpiTypeAndTimeSelect();
    let kpiNumberListFormat: KpiGrowthUnitValue<number | undefined>[] = [
      {
        name: this.$t(`KPI.overall${filterRadioList[0].transCode}Hours`),
        v: this.totalNumber,
        unit: filterRadioList[0].unit,
        growth: undefined,
        code: undefined,
      },
      {
        name: this.$t(`KPI.ave${filterRadioList[0].transCode}Hours`),
        v: this.averageNumber,
        unit: filterRadioList[0].unit,
        growth: undefined,
        code: undefined,
      },
    ];

    return kpiNumberListFormat;
  }

  //in expand page, all the total and average should be calculated
  get multiKpiNumberList(): MultiKpiGrowthUnitValue<number | undefined>[] {
    let multiKpiNumberListFormat: MultiKpiGrowthUnitValue<
      number | undefined
    >[] = [];
    this.radioList.forEach((item: any) => {
      let oneCategoryData = this.allCategoryData.filter((dataItem: any) => {
        return dataItem.code === item.code;
      });
      if (oneCategoryData.length > 0) {
        let { resultTotal, resultAverage } = calculateTotalAndAverageData(
          oneCategoryData[0]
        );
        multiKpiNumberListFormat.push({
          name: this.$t(`KPI.overall${item.transCode}Hours`),
          v: resultTotal,
          unit: item.unit,
          avgValue: resultAverage,
          code: item.code,
          growth: undefined,
        });
      }
    });

    return multiKpiNumberListFormat;
  }

  kpiTypeChange(kpiType: any) {
    this.kpiType = kpiType;
  }

  //series config computed
  get seriesConfig() {
    let seriesConfigList: any = [];
    this.radioList.forEach((item: any) => {
      seriesConfigList.push({
        type: 'bar',
        seriesLayoutBy: 'row',
        barWidth: 12,
        itemStyle: {
          color: item.color,
        },
        markLine: {
          silent: true,
          data: [{ type: 'average' }],
          label: {
            show: true,
            position: 'start',
            formatter: (data: any) => {
              let str: string =
                this.$t('KPI.average') +
                '\n' +
                this.$t(item.markLineText) +
                '\n' +
                data.value;
              return str;
            },
            color: item.markLineColor,
          },
          lineStyle: {
            type: 'dotted',
            color: item.markLineColor,
          },
          symbol: ['none', 'none'],
        },
      });
    });
    return seriesConfigList;
  }

  //if kpiType or time range changes, the info tabs should change and calculate again
  watchKpiTypeAndTimeSelect() {
    let oneCategoryData = this.allCategoryData.filter((item: any) => {
      return item.code === this.kpiType;
    });
    if (oneCategoryData.length > 0) {
      let { resultTotal, resultAverage } = calculateTotalAndAverageData(
        oneCategoryData[0]
      );
      this.totalNumber = resultTotal;
      this.averageNumber = resultAverage;
    }
  }

  dateRangeChange(dateRange: DateRange) {
    this.dateRange = dateRange;
    this.fecthKpi();
  }

  collateData(toHandleData: any) {
    // we need to collate the data format like what is shown below:
    // As Static Compactor has no Lifing, we cannot set them as cold code. All of it should obey the radioList key-values.
    // ['product', '2021-07-29', '2021-07-30', '2021-07-31', '2021-08-01'],
    // ['Motor Working', 43.3, 85.8, 93.7, 82.5],
    // ['Lifting', 83.1, 73.4, 55.1, 73.4],
    // ['Compacting', 86.4, 65.2, 82.5, 93.7],
    let productArray: any = ['product'];
    toHandleData[0].values.forEach((item: any) => {
      //collate the date in product array
      productArray.push(item.ts);
    });
    this.chartData.push(productArray);
    this.radioList.forEach((item: any) => {
      let oneTabArray: any = [];
      oneTabArray.push(this.$t(`KPI.${item.transChartCode}`));
      let oneTabDataArray = toHandleData.filter((dataItem: any) => {
        return dataItem.code === item.code;
      });
      oneTabDataArray[0].values.forEach((newDataItem: any) => {
        oneTabArray.push(
          newDataItem.v != null ? Number(newDataItem.v).toFixed(2) : '#N/A'
        );
      });
      this.chartData.push(oneTabArray);
    });
  }

  async fecthKpi() {
    this.KpiChartIsLoading = true;
    let submitFields: any = [];
    this.radioList.forEach((item: any) => {
      submitFields.push({
        code: item.code,
        unit: this.unitCode,
        needGrowthPercentage: 'false',
      });
    });
    let realTimeKpiPostData = {
      metadata: {
        filter: {
          assetId: this.$route.params.id,
        },
        selection: {
          startDate: this.dateRange.start,
          endDate: this.dateRange.endExclusive,
          dataBucketSize: 'DBSIZE_DAY',
        },
      },
      details: [
        {
          entity: this.entityType,
          fields: submitFields,
        },
      ],
    };

    await getKpiData(realTimeKpiPostData, unref(this.context)).then((res) => {
      this.KpiChartIsLoading = false;
      if (res.code === 200 && res.data.details[0].fields.length > 0) {
        this.chartData = [];
        this.allCategoryData = res.data.details[0].fields;
        this.collateData(res.data.details[0].fields);

        if (this.expanded) {
          //it's in the expanded page, FE should caculate the table data
          let { cols, tableList } = collateExpandTableData(
            res.data.details[0].fields,
            this.radioList,
            'date',
            this.unitConversion.currentUserPreferredUnit,
            this.unitConversion.currentUserConvertUnitValue
          );
          this.cols = cols;
          this.tableList = tableList;
        }
        return;
      } else {
        this.chartData = [];
      }
      customFailedMessage(this.$t('KPI.errorFetchingKpisData').toString());
    });
  }
}
</script>

<template>
  <KpiMultiBarChart
    id="kpi-working-hours"
    :loading="KpiChartIsLoading"
    itemColor="#48B8F4"
    :kpiUnit="unitCode"
    :kpiNumberList="kpiNumberList"
    :multiKpiNumberList="multiKpiNumberList"
    :chartData="chartData"
    :radioList="radioList"
    :seriesConfig="seriesConfig"
    @send-date="dateRangeChange"
    @send-type="kpiTypeChange"
  >
  </KpiMultiBarChart>
</template>

<style lang="scss" scoped></style>
