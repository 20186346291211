<script lang="ts">
import { Geofence } from '@/api/geofenceTypes';
import LeafletMap from '@/components/leafletMap/LeafletMap.vue';
import TripLegend from '@/components/trip/TripLegend.vue';
import TripTimeline from '@/components/trip/TripTimeline.vue';
import { TripModule } from '@/store/modules/trip';
import { formatTimer } from '@/utils/misc';
import { Trip } from '@/utils/types/trip';
import EventDetailsModal from '@/views/dashboard/components/EventDetailsModal.vue';
import { Marker, Polyline } from 'leaflet';
import moment from 'moment';
import { Moment } from 'moment/moment';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

@Component({
  name: 'TripPlayer',
  components: {
    TripLegend,
    TripTimeline,
    LeafletMap,
    EventDetailsModal,
  },
})
export default class extends Vue {
  /** Local variables */
  @Prop() trip!: Trip | null;
  @Prop({ default: [] }) geofences!: Geofence[];
  $refs!: {
    map: LeafletMap;
  };
  loadingTripPlayer: boolean = false;
  zoom = 10;
  tripProgress = 0;
  selectedSpeed = 1;
  speedValues = [
    {
      speed: 1,
      label: 'X 1',
    },
    {
      speed: 2,
      label: 'X 2',
    },
    {
      speed: 4,
      label: 'X 4',
    },
    {
      speed: 60,
      label: 'X 60',
    },
  ];
  startDate: Moment | null = null;
  polyline: Polyline | null = null;
  tracker: Marker | null = null;
  selectedEvent: string | null = null;

  get isPlaying() {
    return TripModule.IS_PLAYING;
  }

  get duration() {
    return TripModule.DURATION;
  }

  get progress() {
    return TripModule.PROGRESS;
  }

  get dialogVisible() {
    return Boolean(this.selectedEvent);
  }

  formatTooltip(value: number) {
    const date = this.startDate?.clone().add(value, 'seconds');

    if (!date) {
      return 'Unknown';
    }

    return formatTimer(date.toISOString(), 'time');
  }

  handlePlayPause() {
    if (this.isPlaying) {
      return TripModule.pause();
    }

    if (this.progress === this.duration) {
      TripModule.updateProgress(0);
    }

    TripModule.play();
  }

  handleSpeedChange() {
    TripModule.updateSpeed(this.selectedSpeed);
  }

  handleProgressChange() {
    TripModule.updateProgress(this.tripProgress);
  }

  handleCloseDialog() {
    this.selectedEvent = null;
  }

  @Watch('trip')
  private async onTripUpdate(trip: Trip) {
    this.$refs.map.clearLayers();

    try {
      this.loadingTripPlayer = true;
      await TripModule.loadTrip(trip);
      await TripModule.updateSpeed(this.selectedSpeed);

      const track = TripModule.TRACK;

      this.startDate = moment(trip.startTime);
      this.polyline = this.$refs.map.renderLine(track);
      this.$refs.map.renderEvents(
        this.polyline,
        trip,
        (event) => (this.selectedEvent = event.id)
      );
      this.tracker = this.$refs.map.renderTripTracker(
        track[track.length - 1].location,
        trip.assetType
      );
    } catch (e) {
    } finally {
      this.loadingTripPlayer = false;
    }
  }

  @Watch('progress')
  private onProgressUpdate(progress: number) {
    this.tripProgress = progress;

    if (!this.trip || !this.polyline || !this.tracker) {
      return;
    }

    const time = moment(this.trip.startTime).add(progress, 's');
    const index = TripModule.TRACK.findIndex((t) =>
      moment(t.timestamp).isAfter(time)
    );

    const start = TripModule.TRACK[index - 1];
    const end = TripModule.TRACK[index];

    if (!start || !end) return;

    const startTime = moment(start.timestamp);
    const endTime = moment(end.timestamp);
    const ratio =
      (1 / (endTime.unix() - startTime.unix())) *
      (time.unix() - startTime.unix());

    const position = this.$refs.map.guessTrackerPosition(
      start.location,
      end.location,
      ratio
    );

    if (position) {
      this.tracker.setLatLng(position);
    }
  }

  @Watch('geofences')
  private onGeofenceUpdate(geofences: Geofence[]) {
    this.$refs.map.initializeGeofence(geofences as any);
  }
}
</script>

<template>
  <div class="trip-player">
    <LeafletMap
      ref="map"
      class="trip-map"
      v-loading="loadingTripPlayer"
      :tripConfig="{ enableTrip: true }"
      :geofenceConfig="{ enableCreate: false, enableEdit: false }"
      :zoom="zoom"
    />
    <div v-if="trip" class="trip-controls">
      <el-button
        :class="`play-pause-button ${isPlaying ? 'pause' : 'play'}`"
        @click="handlePlayPause"
      />
      <el-select
        v-model="selectedSpeed"
        class="speed-select"
        @change="handleSpeedChange"
      >
        <el-option
          v-for="item in speedValues"
          :key="item.speed"
          :label="item.label"
          :value="item.speed"
        />
      </el-select>
      <div class="trip-timeline">
        <TripTimeline :trip="trip" :compact="true" />
        <el-slider
          ref="slider"
          class="trip-slider"
          v-model="tripProgress"
          :min="0"
          :max="duration"
          :step="1"
          :disabled="isPlaying"
          :format-tooltip="formatTooltip"
          @input="handleProgressChange"
        />
      </div>
      <TripLegend :type="trip.assetType" />
    </div>
    <EventDetailsModal
      class="event-details-modal"
      v-if="dialogVisible"
      :visible="dialogVisible"
      :eventId="selectedEvent"
      @quit-event-details-dialog="handleCloseDialog"
    />
  </div>
</template>

<style scoped>
.event-details-modal :deep(.el-dialog) {
  margin-bottom: 15vh;
}
</style>

<style lang="scss" scoped>
.trip-player {
  position: relative;

  & :deep(.el-slider__runway),
  & :deep(.el-slider__bar),
  & :deep(.el-slider__button) {
    height: 1rem;
    background-color: transparent;
  }

  & :deep(.el-input__inner) {
    width: 100%;
  }

  & :deep(.el-slider__button) {
    height: 1.75rem;
    margin-top: 0.75rem;
    background: url('@/assets/imgs/slider-button.svg') center no-repeat;
    background-size: contain;
    border: none;
    transform: none;
  }
}

.trip-map {
  width: 100%;
}

.trip-controls {
  position: absolute;
  display: grid;
  grid-template-columns: 1fr 3fr 32fr auto;
  gap: 1rem;
  top: 0;
  width: calc(100% - 2rem);
  margin: 1rem;
  padding: 0.5rem;
  border-radius: 0.5rem;
  z-index: 1000;
}

.play-pause-button {
  background-color: $trip-live-player-item-background-color;
  background-position: center;
  background-repeat: no-repeat;

  &.play {
    background-image: url('@/assets/imgs/play.svg');
  }

  &.pause {
    background-image: url('@/assets/imgs/pause.svg');
  }
}

.speed-select {
  background-color: $trip-live-player-item-background-color;
  width: 100%;
  margin: 0;
}

.speed-select :deep(.el-input__inner) {
  background-color: $trip-live-player-item-background-color;
  font-weight: bold;
}

.trip-timeline {
  background-color: $trip-live-player-item-background-color;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  :deep(.timeline) {
    z-index: 1;
  }
}

.trip-slider {
  margin: -2.25rem 3.5rem -1rem;
}
</style>
