import { KpiDataField, KpiDataValue } from '@/api/kpis';
import { FleetPerformanceChartData } from '@/components/fleetPerformance/models/FleetPerformance';
import { NumberConverter } from '@/composables/conversion';
import { NUMBER_OF_HOURS_IN_A_DAY } from '@/utils/date';

export function generateFleetPerformanceChartData(
  responseToBeProcessed: KpiDataField[],
  numberOfDays: number,
  convertNumber: NumberConverter
): FleetPerformanceChartData[] {
  const unit = responseToBeProcessed[0].unit;

  return responseToBeProcessed
    .flatMap((kpiResponse: KpiDataField) => kpiResponse.values)
    .map(
      (value: KpiDataValue): FleetPerformanceChartData => ({
        assetName: value.id!,
        tonPerHour: convertNumber(
          calculatePerHour(value.v, numberOfDays),
          unit
        ),
        rank: -1,
      })
    )
    .sort((a, b) => (a.tonPerHour > b.tonPerHour ? 1 : -1))
    .map(
      (p, idx, array): FleetPerformanceChartData => ({
        ...p,
        rank: array.length - idx,
      })
    );
}

function calculatePerHour(value: string, numberOfDays: number): number {
  return Number(value) / (numberOfDays * NUMBER_OF_HOURS_IN_A_DAY);
}
