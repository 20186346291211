<script lang="ts" setup>
import {
  CurrentAssetConfigurationParameter,
  postAssetConfigurationChangeSet,
} from '@/api/configParams';
import { useActiveContext } from '@/auth/context';
import { useLoggedInUser } from '@/auth/user';
import { ButtonProps } from '@/components/button/CardButton.vue';
import BaseCard from '@/components/cusCard/BaseCard.vue';
import { useUnitConversion } from '@/composables/conversion';
import { useRoute, useRouter } from '@/composables/router';
import { useAccessControlSystemFeature } from '@/composables/systemFeature';
import i18n from '@/lang';
import { useAssetInfoQuery } from '@/query/assets';
import { useMultiAssetKpisQuery } from '@/query/kpis';
import { useConfigurationParameterQuery } from '@/query/preset';
import { isOperationalSingleAssetViewRoute } from '@/router/links/assetOverview';
import {
  applyPresetLocation,
  createPresetLocation,
} from '@/router/links/paramConfig';
import {
  RouteNames,
  routeNameToAssetTypeMappping,
} from '@/router/modules/assets';
import { AssetType } from '@/utils/assetTypes';
import { isCompanyTypeOf } from '@/utils/companyService';
import { formatValue, UndefinedRendering } from '@/utils/format';
import { formatTimer } from '@/utils/misc';
import { customFailedMessage, customSuccessMessage } from '@/utils/prompt';
import { convertRawNumber } from '@/utils/units/conversion';
import {
  ALL_CLAIMS_CODES,
  AssetLifecycle,
  COMPANY_TYPE,
  FvtAccessControlOption,
  KPI_FIELDS,
  SYSTEM_FEATURES,
} from '@/utils/workData/lookuptable';
import ParameterConfigurationTable, {
  configureColumns,
  mainPageColumns,
  ParametersTableRow,
} from '@/views/parametersConfiguration/ParameterConfigurationTable.vue';
import { computed, reactive, ref, unref, watch } from 'vue';

const route = useRoute();
const context = useActiveContext();

interface ActionCode {
  updateParams: ALL_CLAIMS_CODES;
  createPreset: ALL_CLAIMS_CODES;
  applyPreset: ALL_CLAIMS_CODES;
}

const authMap: Map<ALL_CLAIMS_CODES, ActionCode> = new Map([
  [
    ALL_CLAIMS_CODES.AUTHRSC_PAGE_TIPPING_VEHICLES_PARAMS_CONFIG,
    {
      updateParams:
        ALL_CLAIMS_CODES.AUTHRSC_ACTION_TIPPING_VEHICLES_UPDATE_PARAMS,
      createPreset:
        ALL_CLAIMS_CODES.AUTHRSC_ACTION_TIPPING_VEHICLES_SAVE_PRESET,
      applyPreset:
        ALL_CLAIMS_CODES.AUTHRSC_ACTION_TIPPING_VEHICLES_APPLY_PRESET,
    },
  ],
  [
    ALL_CLAIMS_CODES.AUTHRSC_PAGE_SCOMPACTOR_PARAMS_CONFIG,
    {
      updateParams: ALL_CLAIMS_CODES.AUTHRSC_ACTION_SCOMPACTOR_UPDATE_PARAMS,
      createPreset: ALL_CLAIMS_CODES.AUTHRSC_ACTION_SCOMPACTOR_SAVE_PRESET,
      applyPreset: ALL_CLAIMS_CODES.AUTHRSC_ACTION_SCOMPACTOR_APPLY_PRESET,
    },
  ],
  [
    ALL_CLAIMS_CODES.AUTHRSC_PAGE_MCOMPACTOR_PARAMS_CONFIG,
    {
      updateParams: ALL_CLAIMS_CODES.AUTHRSC_ACTION_MCOMPACTOR_UPDATE_PARAMS,
      createPreset: ALL_CLAIMS_CODES.AUTHRSC_ACTION_MCOMPACTOR_SAVE_PRESET,
      applyPreset: ALL_CLAIMS_CODES.AUTHRSC_ACTION_MCOMPACTOR_APPLY_PRESET,
    },
  ],
  [
    ALL_CLAIMS_CODES.AUTHRSC_PAGE_ALBA_SCOMPACTOR_PARAMS_CONFIG,
    {
      updateParams:
        ALL_CLAIMS_CODES.AUTHRSC_ACTION_ALBA_SCOMPACTOR_UPDATE_PARAMS,
      createPreset: ALL_CLAIMS_CODES.AUTHRSC_ACTION_ALBA_SCOMPACTOR_SAVE_PRESET,
      applyPreset: ALL_CLAIMS_CODES.AUTHRSC_ACTION_ALBA_SCOMPACTOR_APPLY_PRESET,
    },
  ],
  [
    ALL_CLAIMS_CODES.AUTHRSC_PAGE_RCV_PARAMS_CONFIG,
    {
      updateParams: ALL_CLAIMS_CODES.AUTHRSC_ACTION_RCV_UPDATE_PARAMS,
      createPreset: ALL_CLAIMS_CODES.AUTHRSC_ACTION_RCV_SAVE_PRESET,
      applyPreset: ALL_CLAIMS_CODES.AUTHRSC_ACTION_RCV_APPLY_PRESET,
    },
  ],
  [
    ALL_CLAIMS_CODES.AUTHRSC_PAGE_GENERIC_ASSET_PARAMS_CONFIG,
    {
      updateParams: ALL_CLAIMS_CODES.AUTHRSC_ACTION_GENERIC_ASSET_UPDATE_PARAMS,
      createPreset: ALL_CLAIMS_CODES.AUTHRSC_ACTION_GENERIC_ASSET_SAVE_PRESET,
      applyPreset: ALL_CLAIMS_CODES.AUTHRSC_ACTION_GENERIC_ASSET_APPLY_PRESET,
    },
  ],
  [
    ALL_CLAIMS_CODES.AUTHRSC_PAGE_OPER_TIPPING_VEHICLES_PARAMS_CONFIG,
    {
      updateParams:
        ALL_CLAIMS_CODES.AUTHRSC_ACTION_OPER_TIPPING_VEHICLES_UPDATE_PARAMS,
      createPreset:
        ALL_CLAIMS_CODES.AUTHRSC_ACTION_OPER_TIPPING_VEHICLES_SAVE_PRESET,
      applyPreset:
        ALL_CLAIMS_CODES.AUTHRSC_ACTION_OPER_TIPPING_VEHICLES_APPLY_PRESET,
    },
  ],
]);

const router = useRouter();
const loggedInUser = useLoggedInUser();

const relevantRequiredActionCodes = computed((): ActionCode | undefined => {
  const tab = router.currentRoute.query.tab as ALL_CLAIMS_CODES;
  return authMap.get(tab);
});

const hasParametersAccess = computed((): boolean => {
  return (
    relevantRequiredActionCodes.value !== undefined &&
    loggedInUser.value?.claims.hasClaim(
      relevantRequiredActionCodes.value.updateParams
    ) === true
  );
});

const saving = ref(false);

const assetType = computed((): AssetType | undefined => {
  const routeName = route.value.name as RouteNames;
  return routeNameToAssetTypeMappping[routeName];
});

const parameterConfigurationEntitlement = useAccessControlSystemFeature(
  SYSTEM_FEATURES.ParameterConfig,
  assetType
);

const showCreatePreset = computed(() => {
  return (
    relevantRequiredActionCodes.value &&
    loggedInUser.value?.claims.hasClaim(
      relevantRequiredActionCodes.value.createPreset
    )
  );
});

const showApplyPreset = computed(() => {
  return (
    relevantRequiredActionCodes.value &&
    loggedInUser.value?.claims.hasClaim(
      relevantRequiredActionCodes.value.applyPreset
    )
  );
});

function handleCreateClick() {
  if (assetType.value === undefined) {
    throw new Error('Asset Type has to be defined');
  }

  if (route.value.params.id === undefined) {
    throw new Error('params.id has to be defined');
  }

  const isOperationalSupportUser = isOperationalSingleAssetViewRoute(
    route.value
  );

  router.push(
    createPresetLocation(
      assetType.value,
      route.value.params.id,
      isOperationalSupportUser
    )
  );
}

function handleApplyClick() {
  if (assetType.value === undefined) {
    throw new Error('Asset Type has to be defined');
  }

  if (route.value.params.id === undefined) {
    throw new Error('params.id has to be defined');
  }

  const isOperationalSupportUser = isOperationalSingleAssetViewRoute(
    route.value
  );

  router.push(
    applyPresetLocation(
      assetType.value,
      route.value.params.id,
      isOperationalSupportUser
    )
  );
}

const buttons = computed((): ButtonProps[] => {
  if (mode.value === Mode.View) {
    const buttons: ButtonProps[] = [];

    if (showApplyPreset.value === true) {
      buttons.push({
        title: i18n.t('preset.apply'),
        onClick: handleApplyClick,
      });
    }

    if (showCreatePreset.value === true) {
      buttons.push({
        title: i18n.t('preset.create'),
        onClick: handleCreateClick,
        icon: 'el-icon-plus common-icon',
      });
    }

    if (hasParametersAccess.value === true) {
      buttons.push({
        title: i18n.tc('paramConfig.refresh'),
        onClick: forceRefresh,
        disabled: currentAssetConfigParams.isLoading.value === true,
      });

      if (
        parameterConfigurationEntitlement.value ===
        FvtAccessControlOption.ReadWrite
      ) {
        buttons.push({
          title: i18n.tc('paramConfig.configure'),
          onClick: handleConfigure,
          disabled: false,
        });
      }

      return buttons;
    }

    return [];
  }

  return [
    {
      title: i18n.tc('paramConfig.cancel'),
      onClick: handleCancel,
      disabled: unref(saving),
    },
    {
      title: i18n.tc('paramConfig.apply'),
      onClick: handleApply,
      disabled:
        unref(saving) ||
        unref(editModeRows).filter((row) => !!row.newValue).length === 0,
    },
  ];
});

function handleConfigure(): void {
  enterEditMode();
}

function handleCancel(): void {
  enterViewMode();
}

function handleApply(): void {
  applyEditedParameters();
}

enum Mode {
  View,
  Edit,
}

const mode = ref<Mode>(Mode.View);
const columns = computed(() =>
  unref(mode) === Mode.Edit ? configureColumns : mainPageColumns
);

const { currentUserPreferredUnit } = useUnitConversion();

function mapToParametersTableRow(
  param: CurrentAssetConfigurationParameter<any>
): ParametersTableRow {
  const userUnit = currentUserPreferredUnit(param.unitCode);

  return {
    propertyCode: param.propertyCode,
    propertyName: param.propertyName,
    metricUnit: param.unitCode,
    unitCode: userUnit,
    inUseValue: param.inUseValue?.v
      ? formatValue(
          {
            v: convertRawNumber(
              Number(param.inUseValue?.v),
              param.unitCode,
              userUnit
            ),
          },
          {
            undefinedAs: UndefinedRendering.Empty,
            maxDecimals: 4,
          }
        )
      : undefined,
    configuredValue: param?.configuredValue?.v
      ? formatValue(
          {
            v: convertRawNumber(
              Number(param?.configuredValue?.v),
              param.unitCode,
              userUnit
            ),
          },
          {
            undefinedAs: UndefinedRendering.Empty,
            maxDecimals: 4,
          }
        )
      : undefined,
    lastChangedTime: param.inUseValue?.cts!,
    lastConfiguredTime: param?.configuredValue?.configuredTimestamp!,
    isWritable: param.isWritable,
    newValue: undefined,
    hasValidationError: false,
  };
}

const userRefresh = ref<boolean>(false);
async function forceRefresh(): Promise<void> {
  try {
    userRefresh.value = true;
    await lastCommunicationTimeQuery.refetch();
    await currentAssetConfigParams.refetch();
  } finally {
    userRefresh.value = false;
  }
}

const { data: asset } = useAssetInfoQuery(router.currentRoute.params.id);

const currentAssetConfigParams = useConfigurationParameterQuery(
  router.currentRoute.params.id,
  {
    select: (data): ParametersTableRow[] => {
      return data.configurationParameters.map(mapToParametersTableRow);
    },
    refetchInterval: 10 * 1000,
  },
  computed(() => asset.value !== undefined),
  computed(() => asset.value?.status === AssetLifecycle.Assigned)
);

watch(
  currentAssetConfigParams.error,
  () => {
    const err = currentAssetConfigParams.error.value;
    if (err === null) return;

    console.error(err);
    customFailedMessage(i18n.t('paramConfig.errors.loadingFailed'));
  },
  { deep: true }
);

const editModeRows = ref<ParametersTableRow[]>([]);

function enterViewMode() {
  mode.value = Mode.View;
}

function enterEditMode() {
  mode.value = Mode.Edit;
  editModeRows.value = reactive(
    JSON.parse(JSON.stringify(currentAssetConfigParams.data.value ?? []))
  );
}

async function applyEditedParameters() {
  const editedValues = unref(editModeRows).filter((row) => !!row.newValue);
  const haveErrors = editedValues.some((row) => row.hasValidationError);
  if (haveErrors) {
    customFailedMessage(i18n.tc('paramConfig.errors.invalidValues'));
    return;
  }
  try {
    saving.value = true;
    const response = await postAssetConfigurationChangeSet(
      router.currentRoute.params.id,
      {
        configurationParameters: editedValues.map((row) => ({
          propertyCode: row.propertyCode,
          value: convertRawNumber(
            parseFloat(row.newValue),
            row.unitCode,
            row.metricUnit
          ),
        })),
      },
      asset.value?.status === AssetLifecycle.Assigned
        ? context.value
        : undefined
    );

    customSuccessMessage(i18n.tc('paramConfig.successfullyApplied'));
    enterViewMode();
  } catch (err) {
    console.error(err);
    customFailedMessage(i18n.tc('paramConfig.errors.savingFailed'));
  } finally {
    saving.value = false;
    forceRefresh();
  }
}

const pageTitle = computed(() =>
  unref(mode) === Mode.Edit
    ? i18n.t('paramConfig.configureParams')
    : i18n.t(router.currentRoute.query.tab as string)
);

const routeAssetId = computed((): string | undefined =>
  typeof route.value.params.id === 'string' ? route.value.params.id : undefined
);

const lastCommunicationTimeQuery = useMultiAssetKpisQuery(
  routeAssetId.value ? [routeAssetId.value] : [],
  [KPI_FIELDS.LastCommunicationTime],
  {
    select(data): string {
      const lastCommTime =
        data[routeAssetId.value!][KPI_FIELDS.LastCommunicationTime].v;

      return formatTimer(lastCommTime as string, 'datetime');
    },
    refetchInterval: 10 * 1000,
  },
  computed(() => asset.value?.status === AssetLifecycle.Assigned)
);

/**
 * Allow last communication time field and value to be visible only for BB/Helpdesk company types
 */
const lastCommunicationTimeCanBeDisplayed = computed(() => {
  return isCompanyTypeOf([COMPANY_TYPE.BodyBuilder, COMPANY_TYPE.Helpdesk]);
});
</script>

<template>
  <BaseCard
    class="parameter-configuration-main-container"
    :title="pageTitle"
    :backIconVisible="mode === Mode.Edit"
    :buttons="buttons"
    :showDialogBeforeBack="true"
    @handle-back="handleCancel"
  >
    <div class="table-container">
      <!-- Only shows the loading spinner on the initial load and on refresh button click, not on the periodical background fetches -->
      <div
        v-if="lastCommunicationTimeCanBeDisplayed"
        v-loading="
          (userRefresh && lastCommunicationTimeQuery.isFetching.value) ||
          lastCommunicationTimeQuery.isLoading.value
        "
        class="communication-time-label"
      >
        {{ i18n.tc('assets.lastCommunicationTime') + ':' }}
        {{ lastCommunicationTimeQuery.data.value }}
      </div>

      <!-- Only shows the loading spinner on the initial load and on refresh button click, not on the periodical background fetches -->
      <ParameterConfigurationTable
        v-loading="
          (userRefresh && currentAssetConfigParams.isFetching.value) ||
          currentAssetConfigParams.isLoading.value
        "
        class="parameters-table"
        :tableData="
          mode === Mode.Edit
            ? editModeRows
            : currentAssetConfigParams.data.value ?? []
        "
        :columns="columns"
        :paginated="false"
        :page-size="1"
        :total="1"
      />
    </div>
  </BaseCard>
</template>

<style lang="scss" scoped>
.parameter-configuration-main-container {
  grid-area: 1 / 1 / span 1 / span 12;
  grid-column-start: 1;
  grid-column-end: span 12;
  margin-bottom: 15px;
  width: 100%;
  display: flex;
  flex-direction: column;
  min-height: 100%;
  height: 66vh;
  overflow: hidden;
}

.table-container {
  width: 100%;
  height: auto;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
}

.communication-time-label {
  font-family: var(--fontRobotoRegular, Roboto-Regular);
  font-weight: bold;
}

.communication-time-label {
  margin: 20px;
  font-family: var(--fontRobotoRegular, Roboto-Regular);
  font-weight: bold;
}

.parameters-table {
  margin-left: 10px;
}
</style>
