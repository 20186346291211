<script lang="ts">
import { ElTree } from 'element-ui/types/tree';
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
  name: 'TableContainer',
})
export default class extends Vue {
  @Prop() content!: any[];
  @Prop() filterIds!: string[];
  @Prop() titleName!: string;
  @Prop() titleValue!: string;

  unwatch: any = null;
  mounted() {
    this.unwatch = this.$watch('filterIds', (newValue, oldValue) => {
      const tree = this.$refs.tree as ElTree<HTMLElement, any>;
      tree.filter(true);
    });
  }

  beforeUnmount() {
    if (this.unwatch) {
      this.unwatch();
    }
  }

  filterTreeNode(value: any, node: any) {
    return this.filterIds.includes(node.id) == value;
  }
}
</script>

<template>
  <div class="container">
    <div class="header">
      <h4>{{ titleName }}</h4>
      <h4>{{ titleValue }}</h4>
    </div>
    <div class="tree-container">
      <el-tree
        :data="content"
        :filter-node-method="filterTreeNode"
        node-key="id"
        default-expand-all
        ref="tree"
      >
        <div class="custom-tree-node" slot-scope="{ node, data }">
          <span>{{
            /* @ts-expect-error TODO Wrong type */
            node.label
          }}</span>
          <span>{{
            /* @ts-expect-error TODO Wrong type */
            data.value
          }}</span>
        </div>
      </el-tree>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0 28px;
  border-bottom: 1px solid #cecaca;

  h4 {
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;
  }
}

.container {
  height: 300px;
  border: 1px solid #707070;
  border-radius: 8px;
  text-align: left;
  display: flex;
  flex-direction: column;
  margin: 0px;
  gap: 0px !important;
  padding: 0 0 18px 0;
}

.tree-container {
  overflow-y: scroll;
  padding: 18px;
}

.tree-container::-webkit-scrollbar {
  width: 1em;
}

.tree-container::-webkit-scrollbar-thumb {
  background-color: #cecaca;
  height: 20px;
}

.custom-tree-node {
  border-bottom: 1px solid #cecaca;
  width: 100%;
  display: flex;
  justify-content: space-between;
}
</style>
