import { UUID } from '@/api/common';
import { fetchOrgById, Organization } from '@/api/organizations';
import {
  fetchCompanySubscriptions,
  Subscription,
} from '@/api/subscriptionPackages';
import {
  ALL_MODULE_CODES,
  ALL_PAGE_CODES,
  fetchUserById,
  makeDefaultUserSettings,
  updateUserSetting,
  UserSettings,
} from '@/api/users';
import { useAsync } from '@/composables/async';
import { AssetType } from '@/utils/assetTypes';
import { isDesignatedCompany } from '@/utils/companyService';
import { distinct } from '@/utils/ref';
import {
  UnitSystem,
  UNIT_SYSTEM_MAPPINGS,
} from '@/utils/units/systemConversionDefinitions';
import { UnitMapping } from '@/utils/units/systemConversionTypes';
import {
  ACTIVATION_STATUS,
  COMPANY_TYPE,
  PACKAGE_TYPE,
  SYSTEM_FEATURES,
  THEME,
  UserRole,
  VALUE_TYPE,
} from '@/utils/workData/lookuptable';
import { injectLocal, provideLocal } from '@vueuse/core';
import { AxiosError } from 'axios';
import { computed, InjectionKey, ref, Ref, triggerRef, unref } from 'vue';
import { Claims } from './claims';
import { TokenInfo } from './token';

export interface SystemFeatureValue {
  value: string;
  valueType: VALUE_TYPE;
}

export type SystemFeatureMapping = Partial<
  Record<SYSTEM_FEATURES, Partial<Record<AssetType, SystemFeatureValue>>>
>;

/**
 * Convenience type for the `.ref` of `useLoggedInUser()`.
 */
export type LoggedInUserRef = Ref<LoggedInUser | undefined>;

/**
 * Information about the logged in user.
 */
export interface LoggedInUser {
  /**
   * User's UUID.
   */
  readonly id: UUID;

  /**
   * User's company UUID.
   */
  readonly companyId: UUID;

  /**
   * User's company type.
   */
  readonly companyType: COMPANY_TYPE;

  /**
   * User's organization and all child organizations.
   */
  readonly organization: Organization;

  /**
   * User's claims.
   */
  readonly claims: Claims;

  /**
   * Modules, sub-modules and pages that are enabled in the user's role.
   */
  readonly moduleAndPageCodes: Array<ALL_MODULE_CODES | ALL_PAGE_CODES>;

  /**
   * User's role code.
   *
   * Note: don't use the role code for access checking, use `.claims` instead.
   */
  readonly role: UserRole;

  /**
   * Theme assigned to user's company.
   */
  readonly theme: THEME;

  /**
   * User's username.
   */
  readonly username: string;

  /**
   * User's email address as stored in Identity service.
   *
   * Note: typically the same as email in token, but might be (temporarily)
   * out of sync.
   */
  readonly email: string;

  /**
   * Email verification status as stored in Identity service.
   *
   * Verification status in Identity service and Keycloak may (temporarily)
   * be out-of-sync.
   */
  readonly emailVerified: boolean;

  /**
   * List of available subscriptions for this user.
   */
  readonly subscriptions: Subscription[];

  /**
   * A mapping of the user's entitlements.
   * We can have the same System Feature with different values for different Asset Types.
   * If the System Feature is present in a Company Subscription Package Type, then the
   * asset's type is irrelevant, and we use `ASSTYP_ALL` as the key to the inner Record.
   */
  readonly systemFeatures: SystemFeatureMapping;

  /**
   * Metric units mapped to the user's equivalent preferred unit.
   *
   * If it's an empty dictionary (`{}`) user's preferred unit system is Metric. (no conversion is ever needed)
   */
  readonly unitMapping: UnitMapping;

  /**
   * User's activation status.
   *
   * Should always be ACTSTAT_ACTIVATED.
   */
  readonly activationStatus: ACTIVATION_STATUS;

  /**
   * User's policy region code.
   */
  readonly policyRegion: string;

  /**
   * User's settings.
   */
  readonly settings: UserSettings;

  /**
   * Hyva Admins have the option to see data from "All" companies, thus they do not necessarily need
   * to impersonate a company to use the plaform, meanwhile designated users always have an impersonated company
   */
  readonly canImpersonate: boolean;

  /**
   * Designated Users (such as Body Builders, Truck OEMs, Helpdesk) must impersonate a company to use the platform
   */
  readonly mustImpersonate: boolean;
}

export type UpdateUserSettingsFunction = (
  settings: Partial<UserSettings>
) => Promise<UserSettings>;

interface LoggedInUserState {
  status: Ref<LoginStatus>;
  loggedInUserRef: Ref<LoggedInUser | undefined>;
  refetchLoggedInUser: () => Promise<void>;
  updateSettings: UpdateUserSettingsFunction;
}

export enum LoginStatus {
  /**
   * We have a Keycloak token, and are now in the process of
   * obtaining all other required information about a user.
   */
  Pending = 'Pending',

  /**
   * We don't have a Keycloak token, and are thus logged out.
   */
  LoggedOut = 'LoggedOut',

  /**
   * We have a Keycloak token, and all required information about
   * a user: fully logged in.
   */
  LoggedIn = 'LoggedIn',

  /**
   * We have a Keycloak token, but some or all of the information
   * about the user was not found (but the backend did respond with
   * that explicit message, so it's not a generic backend failure).
   *
   * This should never happen, but could happen if for some reason
   * Keycloak and Identity microservice get out of sync (which did
   * happen in the past).
   */
  NotFound = 'NotFound',

  /**
   * A Keycloak token is available, but user information cannot be
   * obtained. Most likely a network connectivity or backend availability
   * issue.
   */
  Error = 'Error',
}

const loggedInUserKey: InjectionKey<LoggedInUserState> = Symbol('loggedInUser');

/**
 * Provide loggedInUser to the application.
 *
 * This must be called somewhere near the root of the application, at least
 * 'above' any child component that wants to make use of it.
 */
export function provideLoggedInUser(
  tokenRef: Ref<TokenInfo | undefined>
): void {
  const statusRef: Ref<LoginStatus> = ref(LoginStatus.Pending);

  const forceRefetchRef = ref();

  const loggedInUserComputed = computed(async () => {
    unref(forceRefetchRef);

    if (!tokenRef.value) {
      statusRef.value = LoginStatus.LoggedOut;
      return undefined;
    }

    try {
      const result = await buildLoggedInUser(tokenRef.value);
      statusRef.value = LoginStatus.LoggedIn;
      return result;
    } catch (err) {
      console.error('Fetching logged in user failed:', err);
      if (err instanceof AxiosError && err.response?.status === 404) {
        statusRef.value = LoginStatus.NotFound;
      } else {
        statusRef.value = LoginStatus.Error;
      }
      throw err;
    }
  });

  const loggedInUserAsync = useAsync(loggedInUserComputed);

  async function refetchLoggedInUser(): Promise<void> {
    triggerRef(forceRefetchRef);
    await loggedInUserComputed.value;
  }

  const state: LoggedInUserState = {
    status: distinct(statusRef),
    loggedInUserRef: distinct(() => unref(loggedInUserAsync).data),
    refetchLoggedInUser,
    updateSettings: async (settings) => {
      const loggedInUser = loggedInUserAsync.value.data;

      if (loggedInUser === undefined) {
        throw new Error('LoggedInUser not set');
      }

      const requestBody: UserSettings = {
        ...loggedInUser.settings,
        ...settings,
      };

      try {
        return await updateUserSetting(loggedInUser.id, requestBody);
      } finally {
        await refetchLoggedInUser();
      }
    },
  };
  provideLocal(loggedInUserKey, state);
}

function getLoggedInUserState(): LoggedInUserState {
  const loggedInUserState = injectLocal(loggedInUserKey);
  if (!loggedInUserState) {
    throw new Error(
      'No loggedInUser state provided, use `provideLoggedInUser()` somewhere near the root of the app'
    );
  }

  return loggedInUserState;
}

/**
 * Obtain a function that allows updating user settings.
 *
 * When the user settings are changed through that function,
 * logged-in user is automatically updated accordingly.
 *
 * The returned promise resolves when the settings are updated
 * in the backend, and the LoggedInUser is updated to reflect
 * these changes.
 */
export function useUpdateUserSettings(): UpdateUserSettingsFunction {
  const loggedInUserState = getLoggedInUserState();

  return loggedInUserState.updateSettings;
}

/**
 * Obtain a function that allows refetching of the logged in user
 * information. The returned promise resolves when refetching is
 * completed.
 *
 * This is useful in case backend changes are made to the logged in user,
 * and refetching is needed without a full logout / login cycle.
 *
 * Note: this does not need to be called when updating the user's
 * settings, use {@link useUpdateUserSettings} instead.
 */
export function useRefetchLoggedInUser(): () => Promise<void> {
  const loggedInUserState = getLoggedInUserState();

  return loggedInUserState.refetchLoggedInUser;
}

export function useLoginStatus(): Ref<LoginStatus> {
  const loggedInUserState = getLoggedInUserState();

  return loggedInUserState.status;
}

/**
 * Obtain Ref of LoggedInUser context.
 */
export function useLoggedInUser(): Ref<LoggedInUser | undefined> {
  const loggedInUserState = getLoggedInUserState();

  return loggedInUserState.loggedInUserRef;
}

function buildSystemFeatures(
  subscriptions: Subscription[]
): SystemFeatureMapping {
  const mapping: SystemFeatureMapping = {};

  subscriptions.forEach((subscription) => {
    const isAssetPackageType =
      subscription.subscriptionPackageType === PACKAGE_TYPE.AssetType;
    const assetType: AssetType = isAssetPackageType
      ? subscription.subscriptionPackageAssetType
      : AssetType.All;

    subscription.systemFeatures.forEach((systemFeature) => {
      if (mapping[systemFeature.code] === undefined) {
        mapping[systemFeature.code] = {};
      }

      mapping[systemFeature.code]![assetType] = {
        value: systemFeature.value,
        valueType: systemFeature.valueType,
      };
    });
  });

  return mapping;
}

async function buildLoggedInUser(tokenInfo: TokenInfo): Promise<LoggedInUser> {
  const [user, organization, subscriptions] = await Promise.all([
    fetchUserById(tokenInfo.userId),
    fetchOrgById(tokenInfo.organizationId),
    fetchCompanySubscriptions(tokenInfo.companyId),
  ]);

  // pageList contains all accessible module and page codes.
  // I.e. all AUTHRSC_MOD_* and AUTHRSC_PAGE_* codes that are enabled in the
  // user's role, AND reachable through any enabled widgets' pages.
  let moduleAndPageCodes = [
    ...user.pages.map((item) => item.code),
    ...user.modules.map((item) => item.code),
  ];

  const claims = new Claims(user.claims);

  // HyvaAdmin users don't have modules and pages defined, but they do have
  // all relevant claims. For backward compatibility, just fill the modules
  // and pages with any resource code that looks like it.
  // TODO BE should return proper modules/pages for all users, including admin
  if (moduleAndPageCodes.length === 0) {
    moduleAndPageCodes = claims.claims.filter(
      (code) =>
        code.startsWith('AUTHRSC_MOD_') || code.startsWith('AUTHRSC_PAGE_')
    );
  }

  // Strip out the unnecessary/duplicate fields from settings, keep the rest
  const {
    userId: _unused1,
    companyId: _unused2,
    ...onlySettings
  } = user.setting ?? makeDefaultUserSettings();

  // TODO Filter out deactivated orgs, and remove that filtering from all the other
  // places in the app

  return {
    id: user.id,
    companyId: user.companyId,
    companyType: user.companyType,
    organization: organization,
    moduleAndPageCodes: moduleAndPageCodes,
    claims,
    role: user.role,
    theme: user.theme,
    username: user.username,
    email: user.email,
    emailVerified: user.emailVerified,
    subscriptions: subscriptions ?? [],
    unitMapping:
      onlySettings.unitSystem === UnitSystem.Metric
        ? {}
        : UNIT_SYSTEM_MAPPINGS[onlySettings.unitSystem],
    systemFeatures: buildSystemFeatures(subscriptions),
    activationStatus: user.activationStatus,
    policyRegion: user.policyRegion,
    settings: {
      ...onlySettings,
      i18nCode: user.i18nCode,
    },
    canImpersonate:
      user.companyType === COMPANY_TYPE.Hyva ||
      isDesignatedCompany(user.companyType),
    mustImpersonate: isDesignatedCompany(user.companyType),
  };
}
