// standard icons
import active from '@/icons/svg/operationalStatus/standard/active.svg';
import inactive from '@/icons/svg/operationalStatus/standard/inactive.svg';
import inTransition from '@/icons/svg/operationalStatus/standard/inTransition.svg';
import maintenance from '@/icons/svg/operationalStatus/standard/maintenance.svg';
import motorOn from '@/icons/svg/operationalStatus/standard/motorOn.svg';
import offline from '@/icons/svg/operationalStatus/standard/offline.svg';
import parked from '@/icons/svg/operationalStatus/standard/parked.svg';
import poweredOff from '@/icons/svg/operationalStatus/standard/poweredOff.svg';
import stopped from '@/icons/svg/operationalStatus/standard/stop.svg';

// outlined icons
import outlinedActive from '@/icons/svg/operationalStatus/outlined/outlinedActive.svg';
import outlinedInactive from '@/icons/svg/operationalStatus/outlined/outlinedInactive.svg';
import outlinedInTransition from '@/icons/svg/operationalStatus/outlined/outlinedInTransition.svg';
import outlinedMaintenance from '@/icons/svg/operationalStatus/outlined/outlinedMaintenance.svg';
import outlinedOffline from '@/icons/svg/operationalStatus/outlined/outlinedOffline.svg';
import outlinedParked from '@/icons/svg/operationalStatus/outlined/outlinedParked.svg';
import outlinedStopped from '@/icons/svg/operationalStatus/outlined/outlinedStopped.svg';

// other icons
import unknownIcon from '@/icons/svg/unknown.svg';

// other imports
import { AssetType } from '@/utils/assetTypes';
import { objectKeys } from '../collections';

export interface OperationalStatusSchema {
  type: OperationalStatus;
  color: string;
  icon: any;
}

export const OPERATIONAL_STATUS_PREFIX = 'ASSSTAT_';

export enum OperationalStatus {
  Active = 'ASSSTAT_ACTIVE',
  Inactive = 'ASSSTAT_INACTIVE',
  InTransition = 'ASSSTAT_IN_TRANSITION',
  MotorOn = 'ASSSTAT_MOTOR_ON',
  Stopped = 'ASSSTAT_STOP',
  PoweredOff = 'ASSSTAT_POWERED_OFF',
  Parked = 'ASSSTAT_PARKED',
  Maintenance = 'ASSSTAT_MAINTENANCE',
  Offline = 'ASSSTAT_OFFLINE',
  Installed = 'ASSSTAT_INSTALLED', // TODO Doesn't exist, so remove?
}

export const OPERATIONAL_STATUS_ICONS: Record<OperationalStatus, any> = {
  // Note: the order in this list also determines the display order
  // of items in e.g. the Operational Status widget's legend AND
  // when e.g. sorting the assets hierarchy by their operational status.
  [OperationalStatus.Active]: active,
  [OperationalStatus.Offline]: offline,
  [OperationalStatus.PoweredOff]: poweredOff,
  [OperationalStatus.Parked]: parked,
  [OperationalStatus.Maintenance]: maintenance,
  [OperationalStatus.MotorOn]: motorOn,
  [OperationalStatus.Stopped]: stopped,
  [OperationalStatus.InTransition]: inTransition,
  [OperationalStatus.Inactive]: inactive,
  [OperationalStatus.Installed]: inactive, // TODO Get proper icon for this, or get rid of it completely
};

// In some cases the standard icons are used, in other the outlined ones
// E.g. on the homepage in the "Asset Status" widget the outlined icons are used
// while on the Map the normal ones
export const OPERATIONAL_STATUS_OUTLINED_ICONS: Record<OperationalStatus, any> =
  {
    [OperationalStatus.Active]: outlinedActive,
    [OperationalStatus.Offline]: outlinedOffline,
    [OperationalStatus.PoweredOff]: poweredOff,
    [OperationalStatus.Parked]: outlinedParked,
    [OperationalStatus.Maintenance]: outlinedMaintenance,
    [OperationalStatus.MotorOn]: outlinedActive,
    [OperationalStatus.Stopped]: outlinedStopped,
    [OperationalStatus.InTransition]: outlinedInTransition,
    [OperationalStatus.Inactive]: outlinedInactive,
    [OperationalStatus.Installed]: outlinedActive, // TODO Get proper icon for this, or get rid of it completely
  };

export function getOperationalStatusIcon(
  operationalStatus: OperationalStatus
): (typeof OPERATIONAL_STATUS_ICONS)[OperationalStatus] {
  return OPERATIONAL_STATUS_ICONS[operationalStatus] ?? unknownIcon;
}

/**
 * List of all possible operational statuses, in preferred order
 * of display.
 *
 * @see {@link getOperationalStatuses()}
 * @see {@link getOperationalStatusIndex()}
 */
const ALL_OPERATIONAL_STATUSES: OperationalStatus[] = objectKeys(
  OPERATIONAL_STATUS_ICONS
);

/**
 * List of applicable Operational Statuses per asset type.
 *
 * @see {@link getOperationalStatuses()}
 */
// @ts-expect-error TODO Add missing asset types
const operationalStatusesPerAssetType: Record<AssetType, OperationalStatus[]> =
  {
    [AssetType.MobileCompactor]: [
      OperationalStatus.MotorOn,
      OperationalStatus.Stopped,
      OperationalStatus.InTransition,
      OperationalStatus.Offline,
    ],
    [AssetType.StaticCompactor]: [
      OperationalStatus.MotorOn,
      OperationalStatus.Stopped,
      OperationalStatus.Offline,
    ],
    [AssetType.TippingVehicle]: [
      OperationalStatus.Active,
      OperationalStatus.PoweredOff,
      OperationalStatus.Offline,
    ],
    [AssetType.AlbaStaticCompactor]: [
      OperationalStatus.MotorOn,
      OperationalStatus.Stopped,
      OperationalStatus.Offline,
    ],
    [AssetType.RefuseCollectionVehicle]: [
      OperationalStatus.MotorOn,
      OperationalStatus.Stopped,
      OperationalStatus.Offline,
    ],
  };

export const operationalStatusColorMapping: Record<OperationalStatus, string> =
  {
    [OperationalStatus.MotorOn]: '#6494E2',
    [OperationalStatus.Stopped]: '#E8BA53',
    [OperationalStatus.Offline]: '#A3A3A3',
    [OperationalStatus.InTransition]: '#E89253',
    [OperationalStatus.Active]: '#6494E2',
    [OperationalStatus.Inactive]: '#E8BA53',
    [OperationalStatus.Parked]: '#E89253',
    [OperationalStatus.Maintenance]: '#CE6666',
    [OperationalStatus.Installed]: '#E8BA53',
    [OperationalStatus.PoweredOff]: '#373E41',
  };

/**
 * High value to ensure that unknown operational statuses are sorted to the bottom on
 * a default ascending sort.
 */
const UNKNOWN_OPERATIONAL_STATUS_INDEX = 1000;

/**
 * Mapping of an Operational Status to its sorting index.
 */
type OperationalStatusIndexes = Partial<Record<OperationalStatus, number>>;

const statusCache: WeakMap<OperationalStatus[], OperationalStatusIndexes> =
  new WeakMap();

/**
 * Compute a mapping from operational status code to its sorting index,
 * and (weakly) cache it for later re-use.
 */
function getOperationalStatusIndexes(
  orderedStatuses?: OperationalStatus[]
): OperationalStatusIndexes {
  orderedStatuses ??= ALL_OPERATIONAL_STATUSES;
  let cachedIndexes = statusCache.get(orderedStatuses);
  if (cachedIndexes) {
    return cachedIndexes;
  }
  cachedIndexes = Object.fromEntries(
    orderedStatuses.map((status, index) => [status, index])
  );
  statusCache.set(orderedStatuses, cachedIndexes);
  return cachedIndexes;
}

/**
 * Return the relative position of an operational status.
 * Useful for sorting according to operational status.
 *
 * If the status is undefined, a high number is returned to
 * ensure it is by default sorted to the bottom (when sorting ascending).
 *
 * Optionally pass in an explicit ordered list of statuses to use for comparison.
 */
export function getOperationalStatusIndex(
  operationalStatus: OperationalStatus | undefined,
  orderedStatuses?: OperationalStatus[]
): number {
  if (!operationalStatus) {
    return UNKNOWN_OPERATIONAL_STATUS_INDEX;
  }

  return (
    getOperationalStatusIndexes(orderedStatuses)[operationalStatus] ??
    UNKNOWN_OPERATIONAL_STATUS_INDEX
  );
}

/**
 * Sort compare function for increasing Operational Statuses in increasing
 * (visual) order.
 *
 * Optionally pass in an explicit ordered list of statuses to use for comparison.
 */
export function operationalStatusSorter(
  status1: OperationalStatus | undefined,
  status2: OperationalStatus | undefined,
  orderedStatuses?: OperationalStatus[]
): number {
  return (
    getOperationalStatusIndex(status1, orderedStatuses) -
    getOperationalStatusIndex(status2, orderedStatuses)
  );
}

/**
 * Determine applicable operational statuses for the given asset type(s).
 * If no asset types are given, a list of all operational statuses is returned.
 * If one or more asset types are given, the merged set of statuses applicable
 * to these asset types is returned.
 *
 * The returned list is sorted in correct order for display purposes.
 */
export function getOperationalStatuses(
  assetTypes: AssetType | AssetType[] | undefined
): OperationalStatus[] {
  // Return all possible statuses if we don't know what asset type is involved.
  if (!assetTypes || (Array.isArray(assetTypes) && assetTypes.length === 0)) {
    return ALL_OPERATIONAL_STATUSES;
  }

  // If a single asset type is involved, return the list of statuses exactly
  // in the order in which it is defined for that asset type.
  if (!Array.isArray(assetTypes) || assetTypes.length === 1) {
    const assetType = Array.isArray(assetTypes) ? assetTypes[0] : assetTypes;
    return (
      operationalStatusesPerAssetType[assetType] ?? ALL_OPERATIONAL_STATUSES
    );
  }

  // Otherwise, multiple asset types are involved.
  const applicableStatuses = new Set(
    assetTypes.flatMap(
      (assetType) =>
        operationalStatusesPerAssetType[assetType] ?? ALL_OPERATIONAL_STATUSES
    )
  );

  // We need a single 'overall ordering', so do that based on the default
  // (i.e. ALL_OPERATIONAL_STATUSES)
  const sortedStatuses = [...applicableStatuses.values()].sort(
    operationalStatusSorter
  );
  return sortedStatuses;
}

export function getOperationalStatusSchema(): OperationalStatusSchema[] {
  return Object.values(OperationalStatus).map(
    (status): OperationalStatusSchema => ({
      type: status,
      color: operationalStatusColorMapping[status],
      icon: OPERATIONAL_STATUS_OUTLINED_ICONS[status],
    })
  );
}

/**
 * Checks if the given string is a valid member of the OperationalStatus enum.
 */
export function isOperationalStatus(value: string): value is OperationalStatus {
  return Object.values(OperationalStatus).includes(value as OperationalStatus);
}
