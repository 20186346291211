<script lang="ts">
import { getKpiData } from '@/api/assets';
import { KpiDataField, KpiDataRequest, KpiDataValue } from '@/api/kpis';
import { ActiveContext, useActiveContext } from '@/auth/context';
import FleetPerformanceScatterChart from '@/components/fleetPerformance/FleetPerformanceScatterChart.vue';
import { FleetPerformanceChartData } from '@/components/fleetPerformance/models/FleetPerformance';
import MultiSelectDropDown from '@/components/form/MultiSelectDropDown.vue';
import TimeSelect from '@/components/form/TimeSelect.vue';
import WidgetCard from '@/components/layout/widget/WidgetCard.vue';
import KpiPerformanceTable from '@/components/table/KpiPerformanceTable.vue';
import {
  useUnitConversion,
  UseUnitConversionReturnType,
} from '@/composables/conversion';
import { AssetType } from '@/utils/assetTypes';
import { NUMBER_OF_HOURS_IN_A_DAY } from '@/utils/date';
import { findKpiDataValues } from '@/utils/kpidata';
import { DEFAULT_DATE_RANGE } from '@/utils/time';
import { DateRange } from '@/utils/types/date';
import { Option } from '@/utils/types/option';
import { KPI_UNIT } from '@/utils/units/unitDefinitions';
import { KPI_FIELDS } from '@/utils/workData/lookuptable';
import moment from 'moment';
import { Ref, unref } from 'vue';
import { Component, Vue } from 'vue-property-decorator';
import { generateFleetPerformanceChartData } from '../../utils/handleWidgetOperations';

export interface FleetPerformanceTableData {
  assetId: string;
  avgTripTime: number;
  loadPerHour: number;
  trips: number;
}

export type CompanyAssetIdToAsset = [string, KpiDataValue];

@Component({
  name: 'FleetPerformanceExpanded',
  components: {
    TimeSelect,
    WidgetCard,
    KpiPerformanceTable,
    FleetPerformanceScatterChart,
    MultiSelectDropDown,
  },
})
export default class extends Vue {
  /** Local variables */
  loading: boolean = true;
  tableData: FleetPerformanceTableData[] = [];
  chartData: FleetPerformanceChartData[] = [];
  route: string = '';
  period: number = 7;
  cachedTableData: any[] = [];
  cachedChartData: FleetPerformanceChartData[] = [];
  selected: string = '';
  context!: Ref<ActiveContext>;
  requestPayload: KpiDataRequest = {
    metadata: {
      filter: {
        assetTypeCode: AssetType.TippingVehicle,
        // organizationIds filled in later
      },
      selection: {
        startDate: DEFAULT_DATE_RANGE.start,
        endDate: DEFAULT_DATE_RANGE.endExclusive,
        dataBucketDimension: 'DBDIM_ASSET',
      },
    },
    details: [
      {
        entity: 'ENTT_ASSET_TYPE',
        fields: [
          {
            code: KPI_FIELDS.TippingPayload,
            unit: KPI_UNIT.MetricTonne,
          },
          {
            code: KPI_FIELDS.TripCount,
            unit: KPI_UNIT.UnitCount,
          },
        ],
      },
    ],
  };
  requestPayloadForTripTime: KpiDataRequest = {
    metadata: {
      filter: {
        assetIds: [] as string[],
      },
      selection: {
        startDate: DEFAULT_DATE_RANGE.start,
        endDate: DEFAULT_DATE_RANGE.endExclusive,
        dataBucketDimension: 'DBDIM_ASSET',
      },
    },
    details: [
      {
        entity: 'ENTT_ASSET',
        fields: [
          {
            code: KPI_FIELDS.TripTime,
          },
        ],
      },
    ],
  };
  unitConversion!: UseUnitConversionReturnType;

  allAssetOptions: Option[] = [];
  selectedAssetOptions: string[] = [];
  assetIdToName: Map<string, string> = new Map();

  created() {
    this.context = useActiveContext();
    this.unitConversion = useUnitConversion();
    this.getRoute();
  }

  /**
   * Get remote data from API for kpi and trip time
   */
  async getData(): Promise<void> {
    this.loading = true;
    try {
      this.requestPayload.metadata.filter.organizationIds = unref(
        this.context
      ).organizationIds;

      /** kpi data response */
      const response = await getKpiData(
        this.requestPayload,
        unref(this.context)
      );

      /**get assets for trip time request */
      this.requestPayloadForTripTime.metadata.filter.assetIds = this.getIds(
        response.data.details[0].fields
      );

      /** kpi response for trip time */
      const tripTimeResponse = await getKpiData(
        this.requestPayloadForTripTime,
        unref(this.context)
      );

      if (
        response.hasOwnProperty('data') &&
        response?.data?.details?.length > 0
      ) {
        this.handleChartData(response.data.details[0].fields);
      }
      this.tableData = this.generateTableData(
        response.data.details[0].fields,
        tripTimeResponse.data.details[0].fields
      );
    } catch (err) {
      console.error(err);
    } finally {
      this.loading = false;
    }
  }

  handleChartData(responseToBeProcessed: KpiDataField[]): void {
    const kpiDataValues = responseToBeProcessed.filter(
      (o: any) => o.code === KPI_FIELDS.TippingPayload
    );
    this.chartData = generateFleetPerformanceChartData(
      kpiDataValues,
      this.period,
      this.unitConversion.currentUserConvertNumber
    );
    this.cachedChartData = this.chartData;
  }

  generateTableData(
    response: KpiDataField[],
    tripTimeResponse: KpiDataField[]
  ): FleetPerformanceTableData[] {
    const tippingPayloadKpi = findKpiDataValues(
      response,
      KPI_FIELDS.TippingPayload
    );
    const tripCountKpiMap: Map<string, KpiDataValue> = new Map(
      findKpiDataValues(response, KPI_FIELDS.TripCount).map(
        (value): CompanyAssetIdToAsset => [value.id!, value]
      )
    );
    const tripTimeKpiMap: Map<string, KpiDataValue> = new Map(
      findKpiDataValues(tripTimeResponse, KPI_FIELDS.TripTime).map(
        (value): CompanyAssetIdToAsset => [value.id!, value]
      )
    );

    const tableData: FleetPerformanceTableData[] = [];
    const assetOptions: Option[] = [];

    for (const tippingPayload of tippingPayloadKpi) {
      const companyAssetId = tippingPayload.id;

      if (!companyAssetId) {
        throw new Error('Company Asset ID has to be set');
      }

      const numberOfTrips = parseInt(
        tripCountKpiMap.get(companyAssetId)?.v,
        10
      );

      const newTableEntry: FleetPerformanceTableData = {
        assetId: companyAssetId,
        loadPerHour: this.unitConversion.currentUserConvertNumber(
          parseFloat(tippingPayload.v) /
            (this.period * NUMBER_OF_HOURS_IN_A_DAY),
          KPI_UNIT.MetricTonne
        ),
        avgTripTime:
          numberOfTrips !== 0
            ? parseFloat(tripTimeKpiMap.get(companyAssetId)?.v) /
              (numberOfTrips * 60)
            : -Infinity,
        trips: numberOfTrips,
      };

      const assetOption: Option = {
        key: tippingPayload.id ?? '',
        label: tippingPayload.id ?? '',
      };
      assetOptions.push(assetOption);

      tableData.push(newTableEntry);
    }

    this.allAssetOptions = [...assetOptions];
    this.selectedAssetOptions = assetOptions.map((asset) => asset.key);
    this.allAssetOptions.forEach((asset) => {
      this.assetIdToName.set(asset.key, asset.label);
    });

    this.cachedTableData = tableData;
    return tableData;
  }

  isSelected({ assetId }: any): boolean {
    return this.selected === assetId;
  }

  handleTimeFilter(dateRange: DateRange): void {
    this.period = Math.abs(
      moment(dateRange.endExclusive).diff(dateRange.start, 'days')
    );
    this.requestPayload.metadata.selection!.startDate = dateRange.start;
    this.requestPayload.metadata.selection!.endDate = dateRange.endExclusive;
    this.requestPayloadForTripTime.metadata.selection!.startDate =
      dateRange.start;
    this.requestPayloadForTripTime.metadata.selection!.endDate =
      dateRange.endExclusive;
    this.getData();
  }

  getRoute(): void {
    if (this.$route.name === 'TippingVehiclesInfo') {
      this.route = `/assets/tipping-vehicles/expandkpi/${this.$route.params.id}/kpi-performance`;
      return;
    }
    this.route = '/assets/tipping-vehicles/expandkpi/kpi-performance';
  }

  /**
   * Filter table and chart data
   */
  filterData(): void {
    this.loading = true;

    /** filter table data */
    this.tableData = this.cachedTableData;
    this.tableData = this.tableData.filter(
      (tableAsset: FleetPerformanceTableData) =>
        this.selectedAssetOptions.find(
          (assetId) => tableAsset.assetId === assetId
        )
    );

    /** filter chart data */
    this.chartData = this.cachedChartData;
    this.chartData = this.chartData.filter((chartAsset) =>
      this.selectedAssetOptions.find(
        (assetId) => chartAsset.assetName === this.assetIdToName.get(assetId)
      )
    );

    this.loading = false;
  }

  getIds(response: KpiDataField[]): any {
    return response
      ?.find((kpiField: KpiDataField) => kpiField.code === KPI_FIELDS.TripCount)
      ?.values.map((kpiValue: KpiDataValue) => kpiValue.k);
  }

  onAssetSelectionChange(assetIds: string[]) {
    this.selectedAssetOptions = assetIds;
    this.filterData();
  }
}
</script>

<template>
  <WidgetCard v-loading="loading">
    <template #actions>
      <MultiSelectDropDown
        v-loading="allAssetOptions === undefined"
        :filter-label="$t('selectAssets')"
        :options="allAssetOptions"
        :selected-options="selectedAssetOptions"
        @change="onAssetSelectionChange"
      />
      <TimeSelect
        :expanded="true"
        @select="handleTimeFilter"
        :customizable="true"
      />
    </template>
    <div class="body-wrapper">
      <div class="card-container">
        <div class="card-item">
          <FleetPerformanceScatterChart :data="chartData" />
        </div>
        <div class="card-item">
          <KpiPerformanceTable
            v-loading="loading"
            class="table"
            :tableData="tableData"
            :is-selected="isSelected"
          />
        </div>
      </div>
    </div>
  </WidgetCard>
</template>

<style lang="scss" scoped>
.body-wrapper {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.card-container {
  display: flex;
  flex-wrap: wrap;
  flex-grow: 1;
}

@media (min-width: 1422px) {
  .card-item:first-child {
    width: 60%;
  }

  .card-item:last-child {
    width: 40%;
  }
}

@media (max-width: 1422px) {
  .card-item {
    width: 100%;
  }

  .card-item:first-child {
    height: 560px;
  }

  .card-item:last-child {
    height: 50%;
  }
}
</style>
