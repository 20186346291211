import {
  AuthorizableActionCode,
  AuthorizableResourceCode,
  Claim,
} from '@/api/users';

export const AUTHACT_ACCESS_GRANTED = 'AUTHACT_ACCESS_GRANTED';

export class Claims {
  private _claims: Map<AuthorizableResourceCode, Set<AuthorizableActionCode>>;
  private _resourceCodes: AuthorizableResourceCode[];

  constructor(claims: Claim[]) {
    // Convert the raw JSON structure from the server into a more
    // efficiently usable representation.
    this._claims = new Map();
    for (const rawClaim of claims) {
      const claim = new Set(rawClaim.actions);
      this._claims.set(rawClaim.resource, claim);
    }

    // Determine all claims having action code GRANTED.
    // (Nowadays, this should return all codes in `claims`, because the
    // concept of the action codes is phased out, and granted is the only
    // remaining one).
    this._resourceCodes = Array.from(this._claims.entries())
      .filter(([_code, actions]) => actions.has(AUTHACT_ACCESS_GRANTED))
      .map(([code]) => code);
  }

  public hasClaim(
    resource: AuthorizableResourceCode,
    action: AuthorizableActionCode = AUTHACT_ACCESS_GRANTED
  ): boolean {
    return this._claims.get(resource)?.has(action) ?? false;
  }

  public get claims(): AuthorizableResourceCode[] {
    return this._resourceCodes;
  }
}
