import { UserModule } from '@/store/modules/user';
import { COMPANY_TYPE } from './workData/lookuptable';

export const isCompanyTypeOf = (companyTypes: string[]): boolean => {
  return companyTypes.indexOf(UserModule.companyType) > -1;
};

export const isDesignatedCompany = (companyType: COMPANY_TYPE): boolean => {
  return DESIGNATED_COMPANY_TYPES.some((p) => p == companyType);
};

export const isHyvaCompany = (companyType: COMPANY_TYPE): boolean => {
  return HYVA_COMPANY_TYPES.some((p) => p == companyType);
};

export const HYVA_COMPANY_TYPES = [
  COMPANY_TYPE.Hyva,
  COMPANY_TYPE.HyvaRegionalSupport,
];

export const DESIGNATED_COMPANY_TYPES = [
  COMPANY_TYPE.Helpdesk,
  COMPANY_TYPE.BodyBuilder,
  COMPANY_TYPE.Dealer,
  COMPANY_TYPE.TruckOEM,
];
