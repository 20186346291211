var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"table-containter"},[_c('el-table',{attrs:{"data":_vm.tableData}},_vm._l((_vm.columns),function(column,index){return _c('el-table-column',{key:index,attrs:{"prop":column.prop,"label":column.label,"sortable":column.isSortable},scopedSlots:_vm._u([(column.isInputProp)?{key:"default",fn:function(slotProps){return [(slotProps.row.isWritable)?_c('input',{directives:[{name:"model",rawName:"v-model",value:(slotProps.row.newValue),expression:"slotProps.row.newValue"}],class:slotProps.row.hasValidationError
              ? 'input-element-validation'
              : 'input-element',domProps:{"value":(slotProps.row.newValue)},on:{"input":[function($event){if($event.target.composing)return;_vm.$set(slotProps.row, "newValue", $event.target.value)},function($event){slotProps.row.hasValidationError = _setup.hasValidationError(
              slotProps.row
            )}]}}):_vm._e()]}}:(
          column.dateFormat &&
          (column.dateFormat === 'date' || column.dateFormat === 'datetime')
        )?{key:"default",fn:function(slotProps){return [_c('span',[_vm._v(" "+_vm._s(_vm._f("formatTimer")(slotProps.row[column.prop],column.dateFormat))+" ")])]}}:(column.prop === 'unitCode')?{key:"default",fn:function(slotProps){return [_c('span',[_vm._v(" "+_vm._s(_setup.i18n.t(slotProps.row[column.prop]))+" ")])]}}:null],null,true)})}),1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }