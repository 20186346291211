<script lang="ts">
import { getSubscriptionPackageById } from '@/api/subscriptionPackages';
import CusFormItem from '@/components/form/CusFormItem.vue';
import FeaturesTable from '@/components/table/FeaturesTable.vue';
import { customFailedMessage } from '@/utils/prompt';
import {
  APPLICABILITY_PACKAGE_TYPE_PARITY_LIST,
  ASSET_TYPE_LIST,
  PACKAGE_TYPE,
  SYSTEM_FEATURES,
} from '@/utils/workData/lookuptable';
import { FEATURES_TABLE_COLS } from '@/utils/workData/subscriptionPackages';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

@Component({
  name: 'CustomerSubscriptionPackages',
  components: {
    'cus-form-item': CusFormItem,
    'features-table': FeaturesTable,
  },
})
export default class extends Vue {
  @Prop({ required: true }) companySubscriptions!: any;
  @Prop({ required: true }) subscriptionPackages!: any;
  @Prop({ default: false }) subscriptionTabIsLoading!: boolean;
  @Prop({ required: true }) source!: string;

  /** Local variables */
  isEditable: boolean = true;
  cols = FEATURES_TABLE_COLS;
  packageType = PACKAGE_TYPE;
  assetTypeList: any = ASSET_TYPE_LIST;
  packageApplicabilityTypeList: any = APPLICABILITY_PACKAGE_TYPE_PARITY_LIST;
  featuresTableIsLoading: boolean = false;
  featuresWithInputType: any = [
    SYSTEM_FEATURES.MaxUsersCount,
    SYSTEM_FEATURES.MaxOrgs,
  ]; // Features table > value col > all input type
  featuresWithDateType: any = [SYSTEM_FEATURES.Duration]; // Features table > value col > all data type
  featuresWithBooleanType: any = [
    // Features table > value col > all boolean type
    SYSTEM_FEATURES.NewReportTmlp,
    SYSTEM_FEATURES.NewMainPlan,
    SYSTEM_FEATURES.LiveMonitoring,
    SYSTEM_FEATURES.ConfigDashboard,
    SYSTEM_FEATURES.CustomDashboard,
    SYSTEM_FEATURES.BookmarkPage,
    SYSTEM_FEATURES.TripLive,
    SYSTEM_FEATURES.SafetyDashboard,
    SYSTEM_FEATURES.GraphType,
    SYSTEM_FEATURES.Notification,
    SYSTEM_FEATURES.PartsHistory,
    SYSTEM_FEATURES.UecDescription,
    SYSTEM_FEATURES.CreateUser,
    SYSTEM_FEATURES.HealthScore,
    SYSTEM_FEATURES.Ooe,
    SYSTEM_FEATURES.LiveImagery,
  ];
  featuresHaveErrors: any = {
    status: false,
    message: '',
  };
  contentIsLoading: boolean = false;

  /**
   * Aggregate subscription packages by asset/comp type per each company subscriptions (inner JOIN)
   * @parm watch subscriptionTabIsLoading from parent
   */
  @Watch('companySubscriptions', { deep: true })
  handleSubscriptionPackageForCompanySubs() {
    this.handleCompanySubscriptions();
  }

  @Watch('subscriptionPackages', { deep: true })
  handleSubscriptionPackages() {
    this.handleCompanySubscriptions();
  }

  handleCompanySubscriptions() {
    if (
      !this.companySubscriptions ||
      this.subscriptionPackages.companyType.length == 0
    ) {
      this.contentIsLoading = true;
      return;
    }
    if (this.source != 'new') {
      this.companySubscriptions.map((compSub: any) => {
        compSub.subscriptionPackagesPerAssetType =
          this.subscriptionPackages.assetType
            .filter((item: any) => {
              return (
                item.type === this.packageType.AssetType &&
                item.assetType === compSub.subscriptionPackageAssetType
              );
            })
            .map((chgAssetType: any) => {
              chgAssetType.subscriptionPackageName = chgAssetType.name;
              return chgAssetType;
            });
        return compSub;
      });
    }
    this.contentIsLoading = false;
  }

  /**
   * Add new package flow
   */
  handleAddNewPackage() {
    let packageType = '';

    if (
      this.companySubscriptions.length > 0 &&
      !this.companySubscriptions[this.companySubscriptions.length - 1]
        .subscriptionPackageId
    ) {
      customFailedMessage(
        this.$t('subscriptionPackages.fullFillThePreviousPackage').toString()
      );
      return;
    }

    if (this.companySubscriptions.length === 0) {
      packageType = this.packageType.CompanyType;
    } else {
      packageType = this.packageType.AssetType;
    }

    this.companySubscriptions.push({
      subscriptionPackageId: '',
      subscriptionPackageCode: '',
      subscriptionPackageType: packageType,
      subscriptionPackageName: '',
      subscriptionPackageAssetType: '',
      subscriptionPackageCompanyType: '',
      systemFeatures: [],
    });
  }

  /**
   * Event triggered for asset package when an asset type is choosed
   * @param index
   * @param assetType
   */
  handleSubscriptionTypePerAssetType(index: number, assetType: string) {
    this.companySubscriptions[index].subscriptionPackageId = '';
    this.companySubscriptions[index].systemFeatures = [];
    let packagePerAssetType = this.subscriptionPackages.assetType.filter(
      (item: any) => {
        return item.assetType === assetType;
      }
    );

    this.companySubscriptions[index].subscriptionPackagesPerAssetType =
      packagePerAssetType;
  }

  /**
   * Event triggered when choosing a package name then loading specific system features for it
   * @param index
   */
  async handleFeaturesForASpecificPackage(index: number) {
    this.featuresTableIsLoading = true;
    this.companySubscriptions[index].systemFeatures = [];
    this.featuresHaveErrors = {
      status: false,
      message: '',
    };

    let packageId = this.companySubscriptions[index].subscriptionPackageId;
    let systemFeatures: any = [];
    // retrieve features from a specific package
    let res: any = await getSubscriptionPackageById(packageId);
    if (!res || res.code != 200) {
      this.featuresTableIsLoading = false;
      customFailedMessage(
        this.$t('subscriptionPackages.errorWithFetchingData').toString()
      );
      return;
    }

    if (
      res.data.systemFeatures.length === 0 ||
      Object.keys(res.data.systemFeatures[0]).length === 0
    ) {
      this.featuresHaveErrors = {
        status: true,
        message: this.$t('subscriptionPackages.noFeaturesForThisPackageType'),
      };
      this.featuresTableIsLoading = false;
      return;
    }

    if (res.code && res.code === 200) {
      systemFeatures = res.data.systemFeatures.map((item: any) => {
        // if (this.featuresWithBooleanType.includes(item.code)) item.value = false;
        // if (this.featuresWithInputType.includes(item.code)) item.value = "1";
        // if (this.featuresWithDateType.includes(item.code)) item.value = 1;
        return item;
      });

      this.companySubscriptions[index].systemFeatures = systemFeatures;
      this.featuresTableIsLoading = false;
      return;
    }

    this.featuresTableIsLoading = false;
    customFailedMessage(
      this.$t('subscriptionPackages.errorWithFetchingData').toString()
    );
  }
}
</script>

<template>
  <div v-loading="contentIsLoading" class="packages-container">
    <div v-for="(item, index) in companySubscriptions" :key="index">
      <div class="package-item">
        <div class="module-header">
          {{
            item.subscriptionPackageType === packageType.CompanyType
              ? $t('subscriptionPackages.customerPackage')
              : $t('subscriptionPackages.assetPackage')
          }}
          {{
            item.subscriptionPackageType === packageType.CompanyType
              ? ''
              : index
          }}
        </div>
        <div class="package-body">
          <div
            class="selection-area"
            v-if="item.subscriptionPackageType === packageType.CompanyType"
          >
            <cus-form-item
              :title="$t('subscriptionPackages.packageName')"
              :required="true"
            >
              <el-select
                :id="'packagenameforcustomerpackageinput' + index"
                v-model="item.subscriptionPackageId"
                @change="handleFeaturesForASpecificPackage(index)"
                v-bind:placeholder="
                  $t('subscriptionPackages.selectSubscriptionPackage')
                "
                :disabled="source === 'view'"
                filterable
              >
                <el-option
                  v-for="item in subscriptionPackages.companyType"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                />
              </el-select>
            </cus-form-item>
          </div>
          <div
            class="selection-area"
            v-if="item.subscriptionPackageType === packageType.AssetType"
          >
            <cus-form-item
              :title="$t('subscriptionPackages.assetType')"
              :required="true"
            >
              <el-select
                :id="'assettypeforassetpackageinput' + index"
                v-model="item.subscriptionPackageAssetType"
                @change="
                  handleSubscriptionTypePerAssetType(
                    index,
                    item.subscriptionPackageAssetType
                  )
                "
                v-bind:placeholder="$t('maintenance.selectAssetType')"
                :disabled="source === 'view'"
                filterable
              >
                <el-option
                  v-for="assetType in assetTypeList"
                  v-show="
                    !companySubscriptions.find(
                      /* @ts-expect-error TODO Wrong type */
                      (sub) => sub.subscriptionPackageAssetType == assetType.id
                    )
                  "
                  :key="assetType.id"
                  :label="$t(assetType.id)"
                  :value="assetType.id"
                />
              </el-select>
            </cus-form-item>
            <cus-form-item
              :title="$t('subscriptionPackages.packageName')"
              :required="true"
            >
              <el-select
                :id="'packagenameforassetpackageinput' + index"
                v-model="item.subscriptionPackageId"
                @change="handleFeaturesForASpecificPackage(index)"
                :disabled="
                  !item.subscriptionPackageAssetType || source === 'view'
                "
                :title="
                  !item.subscriptionPackageAssetType
                    ? $t('subscriptionPackages.selectFirstAnAssetType')
                    : ''
                "
                v-bind:placeholder="
                  $t('subscriptionPackages.selectSubscriptionPackage')
                "
                filterable
              >
                <el-option
                  v-for="subItem in item.subscriptionPackagesPerAssetType"
                  :key="subItem.id"
                  :label="subItem.name"
                  :value="subItem.id"
                />
              </el-select>
            </cus-form-item>
          </div>
          <div v-loading="featuresTableIsLoading" class="package-content">
            <features-table
              :tableList="item.systemFeatures"
              :cols="cols"
              :isEditable="source != 'view'"
              :featuresHaveErrors="featuresHaveErrors"
              :featuresWithInputType="featuresWithInputType"
              :featuresWithDateType="featuresWithDateType"
              :featuresWithBooleanType="featuresWithBooleanType"
            />
          </div>
        </div>
      </div>
    </div>
    <div v-if="source != 'view'" class="last-package-item">
      <div class="add-new-package-btn">
        <div
          class="btn-container"
          v-if="companySubscriptions.length > 0"
          @click="handleAddNewPackage"
        >
          <i class="el-icon-circle-plus" />
          {{ $t('subscriptionPackages.addNewAssetPackage') }}
        </div>
        <div class="btn-container" @click="handleAddNewPackage" v-else>
          <i class="el-icon-circle-plus" />
          {{ $t('subscriptionPackages.addNewCompanyPackage') }}
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.selection-area :deep(.el-input__inner) {
  width: 300px;
}

.last-package-item {
  padding: 10px;
  text-align: center;
  height: 400px;
}

.add-new-package-btn {
  cursor: pointer;
  margin: 20px;
  color: #349d99;
  font-size: 20px;
  margin-top: min(35%, 200px);
}

.el-icon-circle-plus {
  height: 10px;
  width: 10px;
  margin-right: 10px;
}

.selection-area :deep(.form-item-container) {
  /* margin-left: -50px; */
  margin-left: 20px;
}
</style>

<style lang="scss">
.packages-container {
  display: grid;
  grid-template-columns: repeat(1, 1fr); // default 1 column in grid
  grid-gap: 2px;
  width: 100%;
  height: 100%;
}

.package-item {
  margin: 5px;
  width: 600px;
}

.package-body {
  border: 0.1rem solid #c1c1c1;
  border-radius: 10px;
  height: 400px;
  width: 600px;
  overflow: auto;
  overflow-x: hidden;
}

.package-body::-webkit-scrollbar {
  width: 1em;
  border-radius: 10px;
}

.package-body::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
}

.package-body::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  //   outline: 1px solid slategrey;
  border-radius: 10px;
}

.package-content {
  margin: 20px 10px 20px 10px;
}

/* Screen larger than 1024px? 2 column */
@media (min-width: 1024px) {
  .packages-container {
    grid-template-columns: repeat(2, 1fr);
  }
}

.module-header {
  color: #c1c1c1;
  margin: 10px;
  font-weight: bold;
  font-size: 18px;
}
</style>
