import { AxiosInstance } from 'axios';

import { getStatisticalKpiData } from '@/api/assets';
import { TemplateResponse } from '@/api/report';
import { KpisDataPayload } from '@/utils/kpidata';
import { ASSET_SCOPE_SINGLE_ASSET } from '@/utils/workData/reportMgmt';
import { UserModule } from '@/store/modules/user';

/**
 * Get Statistical Kpi Data from server. Used in view report details in Report Templates
 */
export const getKpisData = async ({
  assetScope,
  template,
  request,
  assetId,
  startDate,
  endDate,
}: {
  assetScope: string;
  template: TemplateResponse;
  request?: AxiosInstance;
  assetId: string;
  startDate: string;
  endDate: string;
}): Promise<KpisDataPayload> => {
  const isSingleAsset = assetScope === ASSET_SCOPE_SINGLE_ASSET;

  const statisticalKpiResponse = await getStatisticalKpiData(
    {
      metadata: {
        filter: isSingleAsset
          ? {
              assetIds: [assetId],
            }
          : {
              assetTypeCode: template.assetType,
              organizationId: assetId,
            },
        selection: {
          startDate,
          endDate,
          dataBucketSize: 'DBSIZE_DAY',
          ...(isSingleAsset && {
            dataBucketDimension: 'DBDIM_TIME',
          }),
        },
        timezone: UserModule.timeZone,
      },
      details: [
        {
          entity: isSingleAsset ? 'ENTT_ASSET' : 'ENTT_ASSET_TYPE',
          fields: template.kpis.map((kpi) => ({
            code: kpi.code,
            unit: kpi.unit,
          })),
        },
      ],
    },
    undefined,
    request
  );

  return {
    template,
    kpis: statisticalKpiResponse.data,
  };
};
