var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',_vm._l((_vm.applicableRealTimeKpis),function(metricConfig,index){return _c('div',{key:index},[(metricConfig.code === 'KPI.CurrentPayload')?_c('el-row',{staticClass:"kpi-box"},[_c('el-col',{attrs:{"span":3}},[_c('p',{staticClass:"kpi-name"},[_vm._v(_vm._s(_vm.$t('KPI.CurrentPayload')))]),_c('p',{staticClass:"kpi-value",style:({
            color: _vm.realTimeKPIColor(_vm.allRealTimeKPILastestData.payload),
          })},[_c('span',[_vm._v(_vm._s(_vm.realTimeKPIValues.payload))]),_vm._v(" "+_vm._s(_vm.$t(_vm.unitConversion.currentUserPreferredUnit(_vm.KPI_UNIT.MetricTonne)))+" ")])]),_c('el-col',{attrs:{"span":21}},[_c('LineChart',{ref:"payloadKpi",refInFor:true,attrs:{"height":"150px","limitWarningRange":_vm.payloadLimitWarningRange,"chartData":_vm.payloadChartData,"yAxisUnit":_vm.$t(_vm.unitConversion.currentUserPreferredUnit(_vm.KPI_UNIT.MetricTonne)),"isRendering":_vm.isRendering},on:{"send-xdata":_vm.getNewXData,"hide-tooltip":_vm.hideAllTooltip}})],1)],1):_vm._e(),(metricConfig.code === _vm.realTimeKpiCodes.CompactorFillingPercentage)?_c('el-row',{staticClass:"kpi-box"},[_c('el-col',{attrs:{"span":3}},[_c('p',{staticClass:"kpi-name"},[_vm._v(" "+_vm._s(_vm.$t(_vm.realTimeKpiCodes.CompactorFillingPercentage))+" ")]),_c('p',{staticClass:"kpi-value",style:({
            color: _vm.realTimeKPIColor(_vm.allRealTimeKPILastestData.filling),
          })},[_c('span',[_vm._v(_vm._s(_vm.realTimeKPIValues.filling))]),_vm._v(" "+_vm._s(_vm.$t(_vm.KPI_UNIT.Percentage))+" ")])]),_c('el-col',{attrs:{"span":21}},[_c('line-chart',{ref:"fillingKpi",refInFor:true,attrs:{"height":"150px","limitWarningRange":_vm.fillingLimitWarningRange,"chartData":_vm.fillingChartData,"yAxisUnit":_vm.$t('metricUnits.percentage'),"isPercentage":true,"isRendering":_vm.isRendering},on:{"send-xdata":_vm.getNewXData,"hide-tooltip":_vm.hideAllTooltip}})],1)],1):_vm._e(),(metricConfig.code === _vm.realTimeKpiCodes.OilTemperature)?_c('el-row',{staticClass:"kpi-box"},[_c('el-col',{attrs:{"span":3}},[_c('p',{staticClass:"kpi-name"},[_vm._v(_vm._s(_vm.$t(_vm.realTimeKpiCodes.OilTemperature)))]),_c('p',{staticClass:"kpi-value",style:({
            color: _vm.realTimeKPIColor(_vm.allRealTimeKPILastestData.oilTemperature),
          })},[_c('span',[_vm._v(_vm._s(_vm.realTimeKPIValues.oilTemperature))]),_vm._v(" "+_vm._s(_vm.$t( _vm.unitConversion.currentUserPreferredUnit(_vm.KPI_UNIT.DegreesCelsius) ))+" ")])]),_c('el-col',{attrs:{"span":21}},[_c('line-chart',{ref:"oilTemperatureKpi",refInFor:true,attrs:{"height":"150px","limitWarningRange":_vm.oilTemperatureLimitWarningRange,"chartData":_vm.oilTemperatureChartData,"yAxisUnit":_vm.$t(
              _vm.unitConversion.currentUserPreferredUnit(_vm.KPI_UNIT.DegreesCelsius)
            ),"isRendering":_vm.isRendering},on:{"send-xdata":_vm.getNewXData,"hide-tooltip":_vm.hideAllTooltip}})],1)],1):_vm._e(),(metricConfig.code === _vm.realTimeKpiCodes.HydraulicPressure)?_c('el-row',{staticClass:"kpi-box"},[_c('el-col',{attrs:{"span":3}},[_c('p',{staticClass:"kpi-name"},[_vm._v(_vm._s(_vm.$t(_vm.realTimeKpiCodes.HydraulicPressure)))]),_c('p',{staticClass:"kpi-value",style:({
            color: _vm.realTimeKPIColor(
              _vm.allRealTimeKPILastestData.hydraulicPressure
            ),
          })},[_c('span',[_vm._v(_vm._s(_vm.realTimeKPIValues.hydraulicPressure))]),_vm._v(" "+_vm._s(_vm.$t(_vm.unitConversion.currentUserPreferredUnit(_vm.KPI_UNIT.Bar)))+" ")])]),_c('el-col',{attrs:{"span":21}},[_c('line-chart',{ref:"hydraulicPressureKpi",refInFor:true,attrs:{"height":"150px","limitWarningRange":_vm.hydraulicPressureLimitWarningRange,"chartData":_vm.hydraulicPressureChartData,"yAxisUnit":_vm.$t(_vm.unitConversion.currentUserPreferredUnit(_vm.KPI_UNIT.Bar)),"isRendering":_vm.isRendering},on:{"send-xdata":_vm.getNewXData,"hide-tooltip":_vm.hideAllTooltip}})],1)],1):_vm._e(),(metricConfig.code === _vm.realTimeKpiCodes.OilLevelLow)?_c('el-row',{staticClass:"kpi-box"},[_c('el-col',{attrs:{"span":3}},[_c('p',{staticClass:"kpi-name"},[_vm._v(" "+_vm._s(_vm.$t('KPI.OilLevel') /* TODO Fix the key, it's missing 'Low' */)+" ")]),_c('p',{staticClass:"kpi-value",style:({
            color: _vm.realTimeKPIColor(_vm.allRealTimeKPILastestData.oilLevel),
          })},[_c('span',[_vm._v(_vm._s(_vm.realTimeKPIValues.oilLevel))])])]),_c('el-col',{attrs:{"span":21}},[_c('line-chart',{ref:"oilLevelKpi",refInFor:true,attrs:{"height":"150px","limitWarningRange":_vm.oilLevelLimitWarningRange,"chartData":_vm.oilLevelChartData,"isOilLevel":true,"isRendering":_vm.isRendering},on:{"send-xdata":_vm.getNewXData,"hide-tooltip":_vm.hideAllTooltip}})],1)],1):_vm._e(),(metricConfig.code === _vm.realTimeKpiCodes.OilFilterClogged)?_c('el-row',{staticClass:"kpi-box"},[_c('el-col',{attrs:{"span":3}},[_c('p',{staticClass:"kpi-name"},[_vm._v(" "+_vm._s(_vm.$t(_vm.realTimeKpiCodes.OilFilterClogged))+" ")]),_c('p',{staticClass:"kpi-value",style:({
            color: _vm.realTimeKPIColor(
              _vm.allRealTimeKPILastestData.oilFilterClogged
            ),
          })},[_c('span',[_vm._v(_vm._s(_vm.realTimeKPIValues.oilFilter))])])]),_c('el-col',{attrs:{"span":21}},[_c('line-chart',{ref:"oilFilterKpi",refInFor:true,attrs:{"height":"150px","limitWarningRange":_vm.oilFilterWarningRange,"chartData":_vm.oilFilterCloggedChartData,"isFlag":true,"isRendering":_vm.isRendering},on:{"send-xdata":_vm.getNewXData,"hide-tooltip":_vm.hideAllTooltip}})],1)],1):_vm._e()],1)}),0)
}
var staticRenderFns = []

export { render, staticRenderFns }