<script lang="ts">
import { deleteGeofence } from '@/api/geofence';
import {
  Geofence,
  GeofenceTrackingStrategy,
  TrackingDays,
} from '@/api/geofenceTypes';
import { sortWeekDays } from '@/utils';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { GeofenceStatusType, geofenceTypeColorMapping } from './data';

@Component({
  name: 'GeofenceCard',
})
export default class extends Vue {
  @Prop({ required: true }) geofenceId!: string;
  @Prop() geofence!: Geofence;
  @Prop() selectedId!: string;

  expanded: boolean = false;
  status: string = 'info';
  statuses = [
    {
      key: GeofenceStatusType.Active,
      value: 'success',
    },
    {
      key: GeofenceStatusType.Draft,
      value: 'warning',
    },
    {
      key: GeofenceStatusType.Deprecated,
      value: 'info',
    },
  ];
  statusType = GeofenceStatusType;

  created() {
    const status = this.statuses.find((s) => s.key === this.geofence.lifeCycle);
    this.status = status ? status.value : 'info';
    this.expanded = this.selectedId === this.geofenceId;
  }

  close() {
    this.$emit('delete');
  }

  editGeo() {
    this.$emit('edit');
  }

  updated() {
    this.expanded = this.selectedId === this.geofenceId;
  }

  get showTrackingDays() {
    return [
      GeofenceTrackingStrategy.AllDays,
      GeofenceTrackingStrategy.InsideTimeInterval,
      GeofenceTrackingStrategy.OutSideTimeInterval,
    ].includes(this.geofence.tracking!);
  }

  get showTrackingInterval() {
    return [
      GeofenceTrackingStrategy.InsideTimeInterval,
      GeofenceTrackingStrategy.OutSideTimeInterval,
    ].includes(this.geofence.tracking!);
  }

  get geofenceTrackingDays(): TrackingDays[] {
    return sortWeekDays(this.geofence.trackingDays!);
  }

  async deleteGeo() {
    await deleteGeofence(this.geofence.id);
  }

  get geofenceTypeStyle() {
    let color = geofenceTypeColorMapping.get(this.geofence.geofenceType);
    if (!color) return undefined;
    return { backgroundColor: color };
  }
}
</script>

<template>
  <div
    :geofenceId="geofenceId"
    :geofence="geofence"
    v-bind:selectedId="selectedId"
  >
    <el-card
      class="box-card"
      :class="expanded ? 'highlight' : ''"
      shadow="hover"
    >
      <div class="innerCard">
        <el-row class="headerCard" :gutter="20">
          <el-col :span="9">
            <h3>{{ geofence.name }}</h3>
          </el-col>
          <el-col :span="6">
            <el-tag :type="status">{{ $t(geofence.lifeCycle) }}</el-tag>
          </el-col>
          <el-col style="word-break: break-word" :span="6">{{
            geofence.organizationName
          }}</el-col>
          <el-col
            v-if="geofence.lifeCycle !== statusType.Deprecated"
            :span="3"
            style="padding: 2%"
          >
            <el-button
              id="edit_geofence_button"
              v-permission="['AUTHRSC_ACTION_GEOFENCE_UPDATE']"
              icon="el-icon-edit"
              plain
              size="medium"
              class="reshape"
              @click.stop="editGeo"
            >
            </el-button>
          </el-col>
          <el-col :span="3" v-if="geofence.lifeCycle !== statusType.Active">
            <el-button
              id="delete_geofence_button"
              v-permission="['AUTHRSC_ACTION_GEOFENCE_DELETE']"
              icon="el-icon-delete"
              plain
              size="medium"
              class="reshape"
              @click.stop="close"
            ></el-button>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="6">{{ $t('geofence.geofenceType') }}: </el-col>
          <el-col :span="16">
            <el-tag
              class="geofenceType"
              :style="geofenceTypeStyle"
              type="primary"
              >{{ $t(geofence.geofenceType) }}</el-tag
            >
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="6">{{ $t('ASSET_TYPE') }}:</el-col>
          <el-col
            :span="12"
            style="display: grid; width: auto; margin-left: -10px"
          >
            <el-col
              v-for="assetType in geofence.associatedAssetTypes"
              v-bind:key="assetType"
              :label="assetType"
              style="margin-bottom: 5px"
            >
              <el-tag type="info">{{ $t(assetType) }}</el-tag>
            </el-col>
          </el-col>
        </el-row>
      </div>
      <div class="expanded-card" v-if="expanded">
        <el-row :gutter="20">
          <el-col :span="6">{{ $t('geofence.allowTracking') }}: </el-col>
          <el-col :span="16">
            <el-tag type="info">
              {{
                /* @ts-expect-error TODO Wrong type */
                $t(geofence.tracking)
              }}
            </el-tag>
          </el-col>
        </el-row>
        <el-row
          :gutter="20"
          v-if="
            showTrackingInterval &&
            geofence.trackingEndTime &&
            geofence.trackingStartTime
          "
        >
          <el-col :span="6">{{ $t('geofence.timeTracking') }}: </el-col>
          <el-col :span="12">
            <el-tag type="info" style="margin-right: 2px">
              {{ geofence.trackingStartTime }}
            </el-tag>
            <el-tag type="info">{{ geofence.trackingEndTime }}</el-tag>
          </el-col>
        </el-row>
        <el-row :gutter="20" v-if="showTrackingDays">
          <el-col :span="6">{{ $t('geofence.daysAvailable') }}: </el-col>
          <el-col :span="18">
            <el-tag
              class="days"
              type="info"
              v-for="day in geofenceTrackingDays"
              v-bind:key="day"
              :label="day"
            >
              {{ $t(day.toString()) }}
            </el-tag>
          </el-col>
        </el-row>
      </div>
    </el-card>
  </div>
</template>

<style lang="scss" scoped>
.days {
  margin: 2px;
}

.el-col {
  border-radius: 4px;
}

.geofenceType {
  color: #ffffff;
  border: none;
}

.bg-purple-dark {
  background: #99a9bf;
}

.bg-purple {
  background: #d3dce6;
}

.bg-purple-light {
  background: #e5e9f2;
}

.grid-content {
  border-radius: 4px;
  min-height: 36px;
}

.row-bg {
  background-color: #f9fafc;
}

.reshape {
  padding: 10% 10% 10% 10%;
}

.headerCard {
  display: flex;
  justify-content: center;
  align-items: center;
}

.box-card.highlight {
  background: #e3e3e3;
}

.box-card:hover {
  background: darken(#e3e3e3, 5%);
}

.el-row {
  padding: 2px 0px 2px 0px;
}
</style>
