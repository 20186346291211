<script lang="ts">
import { GeofenceAsset } from '@/api/geofenceTypes';
import { getKpisForMultipleAssets } from '@/api/kpis';
import ArrowRight from '@/assets/imgs/maintenance/arrow_right.svg';
import PointerIcon from '@/assets/imgs/mapPointerIcon.svg';
import { ActiveContext, useActiveContext } from '@/auth/context';
import LeafletMapAssetContent from '@/components/leafletMap/LeafletMapAssetContent.vue';
import { getAssetStatusConfig } from '@/utils/assets';
import { AssetType } from '@/utils/assetTypes';
import { KPI_FIELDS } from '@/utils/workData/lookuptable';
import {
  getOperationalStatusSchema,
  OperationalStatus,
  OPERATIONAL_STATUS_ICONS,
} from '@/utils/workData/operationalStatus';
import L from 'leaflet';
import moment from 'moment';
import { Ref, unref } from 'vue';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import {
  LIcon,
  LMap,
  LMarker,
  LPopup,
  LTileLayer,
  LTooltip,
} from 'vue2-leaflet';
import Vue2LeafletMarkerCluster from 'vue2-leaflet-markercluster';

@Component({
  name: 'LeafletMapAssets',
  components: {
    LMap,
    LTileLayer,
    LMarker,
    LPopup,
    LTooltip,
    LIcon,
    LeafletMapAssetContent,
    'v-marker-cluster': Vue2LeafletMarkerCluster,
  },
})
export default class extends Vue {
  /** Local variables */
  @Prop() assets!: Array<GeofenceAsset>;
  @Prop() map!: LMap;
  @Prop() selectedAssetId!: string;
  currentAsset: { id: string; kpis: any[] } | null = null;

  // READ MORE: https://github.com/Leaflet/Leaflet.markercluster?tab=readme-ov-file#all-options
  clusterOptions = {
    showCoverageOnHover: false,
    spiderfyOnMaxZoom: false,
    removeOutsideVisibleBounds: true,
    animate: true,
    maxClusterRadius: 150,
    disableClusteringAtZoom: 20,
  };
  popupOptions = {
    offset: new L.Point(0, -80),
  };
  toolTipOptions = {
    direction: 'top',
    permanent: true,
    interactive: true,
    offset: new L.Point(0, -50),
    opacity: 100,
  };
  assetList = AssetType;
  color: string = '';
  context!: Ref<ActiveContext>;
  showGpsFix: boolean = false;
  iconSize = [44, 44];
  // Anchoring is necessary to make sure that the icon is pointing to the same coordinate at every zoom level
  iconAnchor = [this.iconSize[0] / 2, this.iconSize[1] * 1.15];

  created() {
    this.context = useActiveContext();
  }

  @Watch('selectedAssetId')
  handleData(newData: any) {
    if (newData) {
      this.navigateToAsset(newData);
    }
  }

  async fetchAssetDetails(asset: GeofenceAsset) {
    let doPopup = asset.popup || true;
    if (!doPopup) {
      return;
    }
    this.showGpsFix = asset.assetTypeCode != AssetType.StaticCompactor;

    const assetId = asset.id;

    let data: any = null;

    if (assetId) {
      const kpiResponse = await getKpisForMultipleAssets(
        {
          metadata: {
            filter: {
              assetIds: [assetId],
            },
          },
          details: [
            {
              fields: [
                { code: KPI_FIELDS.GpsStatus },
                { code: KPI_FIELDS.OperationalStatus },
                { code: KPI_FIELDS.LastCommunicationTime },
                { code: KPI_FIELDS.GeoLocation },
                { code: KPI_FIELDS.CurrentPayload },
              ],
            },
          ],
        },
        unref(this.context)
      );
      data = kpiResponse.data;
    }

    this.currentAsset = {
      id: assetId,
      kpis: data,
    };
  }

  openTooltip(mapObject: any) {
    mapObject.openTooltip();
  }

  closeTooltip(mapObject: any) {
    mapObject.closeTooltip();
  }

  async openPopup(asset: GeofenceAsset) {
    let doPopup = asset.popup || true;
    if (!doPopup) {
      return;
    }
    await this.fetchAssetDetails(asset);
    // @ts-ignore
    this.$refs[`marker-${asset.id}`][0].mapObject.openPopup();
  }

  formatTime(provided: Date) {
    return moment(provided).fromNow();
  }

  getStatusSource(status: OperationalStatus) {
    return OPERATIONAL_STATUS_ICONS[status];
  }

  getColorForIcons(asset: OperationalStatus) {
    return getAssetStatusConfig(asset, 'color', this.config);
  }

  getPointerIcon() {
    return PointerIcon;
  }

  getArrowRight() {
    return ArrowRight;
  }

  // todo(gilles.coolen) change URL based on asset type from real API.
  getAssetUrl(asset: GeofenceAsset) {
    let type = '';
    if (asset.assetTypeCode.toString() === this.assetList.MobileCompactor)
      type = 'mobile-compactors';
    if (asset.assetTypeCode.toString() === this.assetList.StaticCompactor)
      type = 'static-compactors';
    if (asset.assetTypeCode.toString() === this.assetList.TippingVehicle)
      type = 'tipping-vehicles';

    return `/assets/${type}/${asset.id}`;
  }

  navigateToAsset(id: string) {
    let selectedAsset = this.assets.find(
      (asset: GeofenceAsset) => asset.id === id
    );
    if (!selectedAsset) return;
    this.$emit('navigateToAssetLocation', {
      lat: selectedAsset.geodeticLatitude,
      lng: selectedAsset.geodeticLongitude,
    } as L.LatLngExpression);
    this.openPopup(selectedAsset as GeofenceAsset);
  }

  // TODO-TYPING: Add type for this config object. Be aware that there's more than one usage of it.
  config = {
    status: getOperationalStatusSchema(),
  };
}
</script>

<template>
  <v-marker-cluster :options="clusterOptions">
    <l-marker
      @click="fetchAssetDetails(asset)"
      v-for="asset in assets"
      :key="asset.id"
      :lat-lng="[asset.geodeticLatitude, asset.geodeticLongitude]"
      :ref="`marker-${asset.id}`"
    >
      <l-icon
        :iconUrl="getPointerIcon()"
        :iconSize="iconSize"
        :icon-anchor="iconAnchor"
      />
      <l-tooltip
        class="tooltip"
        :options="toolTipOptions"
        v-if="asset.popup !== false"
      >
        <span
          class="icon-container"
          :style="{
            backgroundColor: getColorForIcons(asset.operationalStatus),
          }"
        >
          <img class="icon" :src="getStatusSource(asset.operationalStatus)" />
        </span>
        <span class="name">{{ asset.assetDataCollectionId }}</span>
      </l-tooltip>
      <l-popup
        :options="popupOptions"
        class="asset-popup"
        v-if="asset.popup !== false"
      >
        <div class="header">
          <span
            class="icon-container"
            :style="{
              backgroundColor: getColorForIcons(asset.operationalStatus),
            }"
          >
            <img class="icon" :src="getStatusSource(asset.operationalStatus)" />
          </span>
          <span class="name">{{ asset.assetDataCollectionId }}</span>
          <router-link class="link" :to="getAssetUrl(asset)">
            <img class="link-icon" :src="getArrowRight()" />
          </router-link>
        </div>
        <LeafletMapAssetContent
          v-if="currentAsset"
          :assetId="currentAsset.id"
          :kpis="currentAsset.kpis"
          :showGpsFix="showGpsFix"
        />
      </l-popup>
    </l-marker>
  </v-marker-cluster>
</template>

<style lang="scss" scoped>
.icon-container {
  height: 24px;
  width: 24px;
  border-radius: 50%;
  display: flex;

  .icon {
    width: 20px;
    height: 20px;
    margin: auto;
  }
}

.tooltip {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  .name {
    margin-left: 0.5rem;
    font-weight: bold;
  }
}

.asset-popup {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;

  .header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0.5rem;
    border-bottom: 1px solid #e0e0e0;

    .name {
      margin-left: 0.5rem;
      color: #415068;
      font-weight: bold;
    }

    .link {
      width: 20px;
      height: 20px;

      .link-icon {
        width: 20px;
        height: 20px;
      }
    }
  }
}
</style>

<style lang="scss">
.leaflet-tooltip-top:before {
  border: none !important;
}

.leaflet-popup-tip-container {
  display: none;
}
</style>
