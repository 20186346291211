import { render, staticRenderFns } from "./NewCard.vue?vue&type=template&id=7833cc80&scoped=true"
import script from "./NewCard.vue?vue&type=script&setup=true&lang=ts"
export * from "./NewCard.vue?vue&type=script&setup=true&lang=ts"
import style0 from "./NewCard.vue?vue&type=style&index=0&id=7833cc80&prod&scoped=true&lang=css"
import style1 from "./NewCard.vue?vue&type=style&index=1&id=7833cc80&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7833cc80",
  null
  
)

export default component.exports