<script setup lang="ts">
import {
  Details,
  emitNewNotification,
  NotificationCode,
  Parameters,
} from '@/api/notifications';
import WidgetDialog from '@/components/dialog/WidgetDialog.vue';
import WidgetCard from '@/components/layout/widget/WidgetCard.vue';
import AddIcon from '@/icons/svg/add.svg';
import informationIcon from '@/icons/svg/information.svg';
import RemoveIcon from '@/icons/svg/remove.svg';
// We need to import like this, else we can't use <i18n/> for interpolation.
// i18n from @/lang would overlap with global component <i18n/>
// READ MORE: https://kazupon.github.io/vue-i18n/guide/interpolation.html#basic-usage
import translationService from '@/lang';
import {
  changeKeyWhileKeepingOrder,
  objectFromEntries,
  objectKeys,
} from '@/utils/collections';
import { customSuccessMessage } from '@/utils/prompt';
import { Language, LANGUAGE, LANG_LIST } from '@/utils/workData/lookuptable';
import { computed, ref } from 'vue';

interface NotificationFormModel {
  versionNumber: string;
  details: Details;
  type: NotificationCode;
}

const formNotification = ref<NotificationFormModel>({
  versionNumber: '',
  details: {
    'en-US': '',
  },
  type: NotificationCode.NewOneEMSVersionDeployed,
});
const notificationForm = ref<any>(null);

const rules = computed(() => {
  return {
    versionNumber: [
      {
        required:
          formNotification.value.type ===
          NotificationCode.NewOneEMSVersionDeployed,
        message: translationService.t(
          'notifications.inputApplicationVersionNumber'
        ),
        trigger: 'change',
      },
    ],
    ...objectFromEntries(
      LANG_LIST.map((lang): [string, any[]] => [
        `details.${lang.id}`,
        [
          {
            required: true,
            message: translationService.t('notifications.inputDetails'),
            trigger: 'change',
          },
        ],
      ])
    ),
  };
});

const notificationParameters = computed((): Parameters | undefined => {
  if (
    formNotification.value.type === NotificationCode.NewOneEMSVersionDeployed
  ) {
    return {
      'PARM.Version': {
        v: formNotification.value.versionNumber,
      },
    };
  }

  return undefined;
});

function validateFormFields(): boolean {
  const fieldProps: string[] = notificationForm.value.fields.map(
    (field: any) => field.prop
  );

  let hasErrors = false;

  notificationForm.value.validate(() => {
    fieldProps.forEach((field) => {
      notificationForm.value?.validateField(field, (errorMessage: string) => {
        if (errorMessage) {
          hasErrors = true;
        }

        return errorMessage;
      });
    });
  });

  return hasErrors;
}

async function sendReleaseNotification() {
  try {
    await emitNewNotification({
      notificationCode: formNotification.value.type,
      details: formNotification.value.details,
      parameters: notificationParameters.value,
    });
    notificationForm.value?.resetFields();
    customSuccessMessage(
      `${translationService.t('notifications.notificationSentSuccessfully')}`
    );
  } catch (error) {
    console.error(error);
  }
}

// Adds the first language found in `LANG_LIST` that is not selected in any of the dropdowns yet
function onAddLanguageClick() {
  const language = LANG_LIST.find(
    (lang) => formNotification.value.details[lang.id] === undefined
  );

  if (language === undefined) {
    throw new Error(
      "The Add Language element shouldn't render when every language is selected already."
    );
  }

  const languageDetails = { ...formNotification.value.details };
  languageDetails[language.id] = '';

  formNotification.value.details = languageDetails;
}

function onLanguageDetailRemoveClick(languageKey: LANGUAGE) {
  const languageDetails = { ...formNotification.value.details };
  delete languageDetails[languageKey];

  formNotification.value.details = languageDetails;
}

function onLanguageSelectChange(
  oldLanguageKey: LANGUAGE,
  newLanguageKey: LANGUAGE
) {
  // Example why this is necessary:
  // We have two dropdowns: 1. English, 2. Dutch; if we switch English to Chinese, we need to remove the English one and add the Chinese one,
  // but that would mean that we'd end up with 1. Dutch, 2. Chinese. For great UX we need to keep the order.
  formNotification.value.details = changeKeyWhileKeepingOrder(
    formNotification.value.details,
    oldLanguageKey,
    newLanguageKey
  );
}

const availableLanguagesPerDropdown = computed((): Map<string, Language[]> => {
  const addedLanguages = new Set(Object.keys(formNotification.value.details));

  // Only show the currently selected language in the dropdown AND
  // languages that are not selected in any other dropdown
  return new Map<string, Language[]>(
    LANG_LIST.map((item) => [
      item.id,
      [item, ...LANG_LIST.filter((lang) => !addedLanguages.has(lang.id))],
    ])
  );
});

// Don't show the Add Language button if every language is already selected
const showAddLanguageButton = computed(
  (): boolean =>
    LANG_LIST.length !== Object.keys(formNotification.value.details).length
);

const isConfirmationDialogOpen = ref<boolean>(false);

function onSendNotificationClick() {
  const hasErrors = validateFormFields();

  if (hasErrors) {
    return;
  }

  isConfirmationDialogOpen.value = true;
}

async function confirmNotification() {
  await sendReleaseNotification();
  isConfirmationDialogOpen.value = false;
}

const notificationUseCases = ref<NotificationCode[]>([
  NotificationCode.NewOneEMSVersionDeployed,
  NotificationCode.MaintenanceDowntimeUpdate,
]);
</script>

<template>
  <WidgetCard
    class="container"
    :default-title="$t('notifications.emitNewNotification')"
  >
    <div style="padding: 40px">
      <el-form
        ref="notificationForm"
        :model="formNotification"
        :rules="rules"
        :validate-on-rule-change="false"
      >
        <div class="input-container">
          <div class="label">
            {{ $t('notifications.useCases') }}:
            <span style="color: red">*</span>
          </div>

          <el-form-item prop="type">
            <el-select size="medium" v-model="formNotification.type">
              <el-option
                v-for="language in notificationUseCases"
                :label="$t('notificationTypes.' + language)"
                :value="language"
              />
            </el-select>
          </el-form-item>
        </div>

        <div
          v-if="
            formNotification.type === NotificationCode.NewOneEMSVersionDeployed
          "
          class="input-container"
        >
          <div class="label">
            {{ $t('notifications.newApplicationVersionNumber') }}:
            <span style="color: red">*</span>
          </div>

          <el-form-item prop="versionNumber">
            <el-input
              v-model="formNotification.versionNumber"
              size="medium"
              clearable
            />
          </el-form-item>
        </div>

        <div class="input-container">
          <div class="label" style="align-self: flex-start; margin-top: 10px">
            {{ $t('notifications.notificationDescription') }}:
            <span style="color: red">*</span>
          </div>

          <div>
            <div
              class="detail-block"
              v-for="key in objectKeys(formNotification.details)"
            >
              <el-form-item>
                <el-select
                  :value="key"
                  size="medium"
                  @change="onLanguageSelectChange(key, $event)"
                >
                  <el-option
                    v-for="language in availableLanguagesPerDropdown?.get(key)"
                    :label="language.value"
                    :value="language.id"
                  />
                </el-select>
              </el-form-item>

              <el-form-item :prop="'details.' + key">
                <el-input
                  type="textarea"
                  :rows="3"
                  :placeholder="$t('notifications.enterDetails')"
                  v-model="formNotification.details[key]"
                >
                </el-input>
              </el-form-item>

              <!-- Never show the remove icon for the first block -->
              <div v-if="objectKeys(formNotification.details)[0] !== key">
                <img
                  :src="RemoveIcon"
                  class="remove"
                  @click="onLanguageDetailRemoveClick(key)"
                />
              </div>
            </div>
          </div>
        </div>

        <div v-if="showAddLanguageButton" class="input-container">
          <div></div>
          <div class="add-language-container" @click="onAddLanguageClick">
            <img :src="AddIcon" />
            <div class="add-language">Add Language</div>
          </div>
        </div>
      </el-form>

      <el-button @click="onSendNotificationClick">
        {{ $t('notifications.sendNotificationToAllUsers') }}
      </el-button>

      <div class="information-container">
        <img :src="informationIcon" />
        <i18n
          path="notifications.notificationEmissionInformation"
          tag="div"
          for="notifications.sendNotificationToAllUsers"
          class="information"
        >
          <span style="font-weight: bold">{{
            $t('notifications.sendNotificationToAllUsers')
          }}</span>
        </i18n>
      </div>
    </div>
    <WidgetDialog
      :visible="isConfirmationDialogOpen"
      :title="$t('notifications.confirmationHeader')"
      :confirmBtnName="$t('true')"
      :cancelBtnName="$t('false')"
      :showCancelButton="true"
      @handle-cancel="isConfirmationDialogOpen = false"
      @handle-confirm="confirmNotification"
      width="45%"
    >
      <div class="confirmation-text">
        {{ $t('notifications.confirmation') }}
      </div>
    </WidgetDialog>
  </WidgetCard>
</template>

<style lang="scss" scoped>
.container {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.label {
  font-size: 18px;
  font-weight: 500;
  line-height: 21px;
}

.input-container {
  display: grid;
  grid-template-columns: clamp(200px, 35%, 300px) auto;
  align-items: center;
  gap: 30px;
  margin-bottom: 31px;

  @media (max-width: 1400px) {
    grid-template-columns: auto;
  }
}

.add-language-container {
  display: flex;
  align-items: center;
  gap: 23px;
  cursor: pointer;
  width: fit-content;
}

.add-language {
  color: #349d99;
  font-size: 20px;
  font-weight: 500;
}

.detail-block {
  display: flex;
  margin-bottom: 63px;

  @media (max-width: 1100px) {
    flex-wrap: wrap;
    gap: 10px;
  }
}

.remove {
  margin-left: 49px;
  cursor: pointer;
}

:deep(.el-input__inner) {
  width: 257px;
  height: 40px;
  border: 1px solid #373e41 !important;
  border-radius: 4px;
  padding: 8px 28px 8px 8px;
  font-size: 16px;
  font-weight: 500;
}

:deep(.el-textarea__inner) {
  border-radius: 11px;
  border: 1px solid #c0c0c0;
  width: 300px;
}

.el-form-item--small.el-form-item {
  margin-bottom: 0px;
}

.el-button {
  color: white;
  font-size: 16px;
  font-weight: 500;
  background-color: #373e41;
  border-radius: 4px;
  padding: 10px 20px;
  border: 0px;
  cursor: pointer;
  margin-bottom: 65px;
}

.el-button:hover {
  background-color: #5f6567;
  color: white;
}

.el-button:active {
  background-color: #5f6567;
  color: white;
}

.information-container {
  display: flex;
  align-items: center;
  gap: 20px;
  max-width: 600px;
}

.information {
  font-size: 12px;
  color: #707070;
}

.confirmation-text {
  color: #373e41;
  font-size: 20px;
  text-align: center;
  padding: 78px 0px;
}
</style>
