<script lang="ts" setup>
import ElDatePickerExtended from '@/external_components/date-picker';
import { disabledFutureDate } from '@/utils/date';
import {
  DEFAULT_DATE_RANGE,
  getNextRange,
  getRangePeriod,
  getStartOfDay,
  LOCALDATE_FORMAT,
} from '@/utils/time';
import { DateRange } from '@/utils/types/date';
import { CHART_DAY_COUNT, DAY_COUNT_TYPE } from '@/utils/workData/lookuptable';
import moment from 'moment';
import Vue, { computed, Ref, ref, watch } from 'vue';

const props = defineProps<{
  customizable?: boolean;
  defaultLastDays?: RangeOption;
}>();
const emit = defineEmits<{
  (e: 'select', data: DateRange): void;
}>();

type RangeOption =
  | {
      id: DAY_COUNT_TYPE;
      value: number;
    }
  | {
      id: 'CUSTOMIZE_DAYS';
      value: 'custom';
    };

type InternalRange = [Date, Date];

function toDateRange(internalRange: InternalRange): DateRange {
  const [start, end] = internalRange;

  const parsedStart = moment(start);
  const parsedEnd = moment(end);

  return {
    start: parsedStart.format(LOCALDATE_FORMAT),
    end: parsedEnd.format(LOCALDATE_FORMAT),
    endExclusive: parsedEnd.add(1, 'day').format(LOCALDATE_FORMAT),
  };
}

const CUSTOM_DAY_OPTION: RangeOption = {
  id: 'CUSTOMIZE_DAYS',
  value: 'custom',
};

const range: Ref<RangeOption['value']> = ref(
  props.defaultLastDays ? props.defaultLastDays.value : CHART_DAY_COUNT[0].value
);
const value: Ref<InternalRange> = ref<InternalRange>([
  moment(DEFAULT_DATE_RANGE.start).toDate(),
  moment(DEFAULT_DATE_RANGE.end).toDate(),
]);

function moveRange(direction: 'back' | 'forward') {
  if (direction === 'forward' && isNextPeriodArrowDisabled.value === true) {
    return;
  }

  const directionSign = direction === 'back' ? -1 : 1;

  const internalRange = toDateRange(value.value);
  const periodInDays = getRangePeriod(internalRange);
  value.value = [
    moment(internalRange.start)
      .add(periodInDays * directionSign, 'days')
      .toDate(),
    moment(internalRange.end)
      .add(periodInDays * directionSign, 'days')
      .toDate(),
  ];
  range.value = 'custom';
}

const isNextPeriodArrowDisabled = computed((): boolean => {
  const dateInterval = value.value;
  const nextDateRange = getNextRange(toDateRange(dateInterval));

  const now = new Date();

  return new Date(nextDateRange.start) > now;
});

const options = computed(() => [
  ...CHART_DAY_COUNT,
  ...(props.customizable || range.value === 'custom'
    ? [CUSTOM_DAY_OPTION]
    : []),
]);

watch(
  range,
  () => {
    const selectedRange = range.value;
    if (selectedRange === 'custom') {
      // Changing to a custom range, just keep the existing start/end
      return;
    }

    const startDate = getStartOfDay().subtract(selectedRange, 'days').toDate();
    const endDate = getStartOfDay().subtract(1, 'day').toDate();
    value.value = [startDate, endDate];
  },
  { immediate: true }
);

const display = computed(() => {
  const [start, end] = value.value;

  return `${moment(start).format('YYYY/MM/DD')} — ${moment(end).format(
    'YYYY/MM/DD'
  )}`;
});

const picker = ref<Vue>();
const customized = computed(() => range.value === 'custom');

function openDatePicker() {
  if (picker.value?.$el instanceof HTMLElement) {
    picker.value.$el.click();
  }
}

watch(value, (next) => emit('select', toDateRange(next)), { immediate: true });
</script>

<template>
  <div class="time-select">
    <el-select v-model="range" class="select">
      <el-option
        v-for="item in options"
        :key="item.id"
        :label="$t(item.id)"
        :value="item.value"
      />
    </el-select>
    <el-input
      :value="display"
      class="query"
      :class="customized ? 'customized' : undefined"
      :readonly="true"
    >
      <i
        slot="prefix"
        class="el-input__icon el-icon-arrow-left arrow"
        @click="moveRange('back')"
      />
      <i
        slot="suffix"
        class="el-input__icon el-icon-arrow-right arrow"
        :class="{ 'arrow-disabled': isNextPeriodArrowDisabled }"
        @click="moveRange('forward')"
      />
      <i
        v-if="customized && customizable"
        slot="suffix"
        class="el-input__icon el-icon-date"
        @click="openDatePicker"
      />
    </el-input>
    <ElDatePickerExtended
      v-model="value"
      ref="picker"
      type="daterange"
      :picker-options="{
        disabledDate: disabledFutureDate,
      }"
      class="picker"
    />
  </div>
</template>

<style lang="scss" scoped>
.time-select {
  position: relative;
  display: flex;
  margin: 0;

  // Width is determined by making sure the time selector fits on a widget
  // card with single column width, on 1920x1080 at 125% text scale.
  width: 406px;
  min-width: 406px;

  @media (max-width: 769px) {
    flex-direction: column;
    width: inherit;
    min-width: inherit;
  }
}

.el-select {
  margin: 0;
}

.select :deep(.el-input__inner) {
  @media (min-width: 769px) {
    width: 150px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-right: none !important;
  }
}

.query {
  & :deep(.el-input__inner) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    text-align: center;
  }

  &.customized :deep(.el-input__inner) {
    padding-right: 55px;
  }
}

.picker {
  position: absolute;
  visibility: hidden;
}

.arrow {
  color: #373e41;
  font-weight: bolder;
  font-size: 16px;
}

.arrow-disabled {
  opacity: 0.4;
  cursor: default;
}
</style>
