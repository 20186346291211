import {
  getUserConsents,
  updateUserConsent,
  UserConsents,
  UserConsentStatus,
  UserConsentStatuses,
} from '@/api/users';
import { AsyncValue, useAsync } from '@/composables/async';
import { distinct } from '@/utils/ref';
import { injectLocal, provideLocal } from '@vueuse/core';
import { computed, InjectionKey, Ref, ref, unref } from 'vue';
import { useTokenInfo } from './token';

export interface UserConsentState {
  readonly needsConsent: Ref<boolean>;
  readonly hasConsented: Ref<boolean>;
  readonly userConsents: Ref<AsyncValue<UserConsents>>;

  readonly agree: (userConsentStatuses: UserConsentStatuses) => Promise<void>;
}

const userConsentStateKey: InjectionKey<UserConsentState> =
  Symbol('userConsentStatus');

/**
 * Determine whether any required policy in the given list still needs
 * to be consented with.
 */
function needsConsent(userConsents: UserConsents): boolean {
  return Object.values(userConsents).some(
    (uc) => uc.consentStatus !== UserConsentStatus.CONSENTED
  );
}

export function useUserConsentState(): UserConsentState {
  const state = injectLocal(userConsentStateKey);
  if (!state) {
    throw new Error(
      'No UserConsentState state provided, use `provideUserConsentState()` somewhere near the root of the app'
    );
  }
  return state;
}

export function provideUserConsentState(): void {
  /**
   * Allow explicit refresh of current user consent statuses from server,
   * e.g. if they've changed because user just agreed to them.
   */
  const forceUpdateConsent = ref(0);

  const tokenInfo = useTokenInfo();

  const userConsents = useAsync(
    computed(async (): Promise<UserConsents | undefined> => {
      unref(forceUpdateConsent); // force refetch of required consents
      const token = unref(tokenInfo);
      if (!token) {
        return undefined;
      }
      return getUserConsents(token.userId);
    })
  );

  /**
   * Determine overall consent status based on list of required consent statuses
   * and the user's current response to each.
   *
   * If any of the policies still needs to be consented to, return false.
   * If all of the policies are consented to, return true.
   * If we don't know the user's consent status (yet), return undefined.
   */
  const userConsented = computed((): boolean | undefined => {
    const userConsentsValue = unref(userConsents);
    if (userConsentsValue.loading || userConsentsValue.error) {
      return undefined;
    }
    if (!userConsentsValue.data) {
      // User is still logging in, or logged out
      return undefined;
    }

    const haveConsent = !needsConsent(userConsentsValue.data);
    return haveConsent;
  });

  async function agree(
    userConsentStatuses: UserConsentStatuses
  ): Promise<void> {
    try {
      const token = unref(tokenInfo);
      if (!token) {
        throw new Error('user cannot be logged out');
      }
      await updateUserConsent(token.userId, userConsentStatuses);
    } finally {
      forceUpdateConsent.value = forceUpdateConsent.value + 1;
    }
  }

  provideLocal(userConsentStateKey, {
    userConsents,
    hasConsented: distinct(() => userConsented.value === true),
    needsConsent: distinct(() => userConsented.value === false),
    agree,
  });
}
