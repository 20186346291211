<script setup lang="ts">
import { ref } from 'vue';

defineExpose({
  startTime,
  pauseTime,
  endTime,
});

let hours = ref(0);
let minutes = ref(0);
let seconds = ref(0);
let intervalId: NodeJS.Timer | undefined = undefined;

function startTime() {
  intervalId = setInterval(() => {
    composeTimeText();
  }, 1000);
}

function pauseTime() {
  clearInterval(intervalId);
}

function endTime() {
  clearInterval(intervalId);
  hours.value = 0;
  minutes.value = 0;
  seconds.value = 0;
}

function composeTimeText() {
  if (seconds.value >= 0) {
    seconds.value = seconds.value + 1;
  }
  if (seconds.value >= 60) {
    seconds.value = 0;
    minutes.value = minutes.value + 1;
  }
  if (minutes.value >= 60) {
    minutes.value = 0;
    hours.value = hours.value + 1;
  }
}
</script>

<template>
  <div>
    <span style="font-size: 22px">
      {{ hours >= 10 ? hours : `0${hours}` }} :
      {{ minutes >= 10 ? minutes : `0${minutes}` }} :
      {{ seconds >= 10 ? seconds : `0${seconds}` }}
    </span>
  </div>
</template>

<style lang="scss" scoped></style>
