<script lang="ts">
import { getKpiData } from '@/api/assets';
import { CommonResult } from '@/api/commonResult';
import { retrieveHealthFactorDefinitions } from '@/api/healthStatus';
import { KpiDataResponse } from '@/api/kpis';
import mobileCompactor from '@/assets/imgs/home/mobile_compactor.png';
import staticCompactor from '@/assets/imgs/home/static_compactor.png';
import tippingVehicle from '@/assets/imgs/home/tipping_vehicle.png';
import { ActiveContext, useActiveContext } from '@/auth/context';
import WidgetCard from '@/components/layout/widget/WidgetCard.vue';
import { UserModule } from '@/store/modules/user';
import { getAssetStatusConfig } from '@/utils/assets';
import { AssetType } from '@/utils/assetTypes';
import { stripPrefix } from '@/utils/string';
import {
  ASSET_TYPE_HOME_STATUS_CLAIM,
  HealthStatus,
  SYSTEM_FEATURES,
  WarningType,
} from '@/utils/workData/lookuptable';
import {
  getOperationalStatuses,
  getOperationalStatusSchema,
  OperationalStatus,
  OperationalStatusSchema,
  OPERATIONAL_STATUS_ICONS,
  OPERATIONAL_STATUS_PREFIX,
} from '@/utils/workData/operationalStatus';
import HealthModal from '@/views/dashboard/components/HealthModal.vue';
import { Ref, unref } from 'vue';
import { Component, Vue } from 'vue-property-decorator';

type KpiData<T extends string> = Partial<
  Record<AssetType, Partial<Record<T, number>>>
>;

type HealthStatusFeatures = {
  type: AssetType;
  enabled: boolean;
}[];

type BaseConfig = {
  asset: BaseConfigAsset[];
  status: OperationalStatusSchema[];
  health: BaseConfigHealth[];
};

interface BaseAsset {
  type: AssetType;
  image: string;
  url: string;
}

interface BaseOperationalStatus {
  status: OperationalStatus;
  description: string | undefined;
  image: any;
}

interface BaseConfigAsset extends BaseAsset {
  operationalStatuses: BaseOperationalStatus[];
}

type BaseConfigHealth = {
  type: HealthStatus;
  color: string;
  warning: WarningType;
};

interface Modal {
  open: AssetType | undefined;
  title: string;
  warningType: WarningType;
}

@Component({
  name: 'AssetStatus',
  components: {
    WidgetCard,
    HealthModal,
  },
})
export default class extends Vue {
  /** Local variables */
  loading = true;
  operationalStatus: KpiData<OperationalStatus> | null = null;
  healthStatus: KpiData<HealthStatus> | null = null;
  modal: Modal = {
    open: AssetType.All,
    title: '',
    warningType: WarningType.Normal,
  };
  healthSystemFeatures: HealthStatusFeatures = [];
  context!: Ref<ActiveContext>;

  /**
   * Get assets count
   */
  get assetCount() {
    return Object.entries(this.operationalStatus ?? {}).reduce<
      Partial<Record<AssetType, number>>
    >(
      (acc, [key, value]) => ({
        ...acc,
        [key]: Object.values(value).reduce((a, c) => a + c, 0),
      }),
      {}
    );
  }

  async created() {
    this.context = useActiveContext();
    this.prepareDefaultInitialization();
  }

  async prepareDefaultInitialization(): Promise<void> {
    this.loading = true;

    // remove asset types for which the customer does not have access
    for (const { type, claim } of Object.values(ASSET_TYPE_HOME_STATUS_CLAIM)) {
      // TODO Move this computation to loggedInUser? See also applicableAssetTypes in src\components\operationalStatus\FleetStatus.vue
      const hasMenu = await UserModule.hasMenu(claim);
      const hasType = await UserModule.hasSystemFeature([null, type]);
      const index = this.config.asset.findIndex((asset) => asset.type === type);

      if ((!hasMenu || !hasType) && index !== -1) {
        this.config.asset.splice(index, 1);
      }
    }

    const response = await retrieveHealthFactorDefinitions({
      assetTypes: this.config.asset.map((item) => item.type),
    });

    const assetTypesWithAtLeastOneEnabledHealthFactors = new Set(
      response.filter((hf) => hf.enabled).map((hf) => hf.assetType)
    );

    // check for health features
    for (const { type } of this.config.asset) {
      const enabled =
        (await UserModule.hasSystemFeature([
          SYSTEM_FEATURES.HealthScore,
          type,
        ])) && assetTypesWithAtLeastOneEnabledHealthFactors.has(type);

      this.healthSystemFeatures.push({ type, enabled });
    }

    try {
      const [operationalStatus, healthStatus] = await this.getData();

      // endpoint will return 404 if no assets are present in thingsboard
      // in this case no error should be thrown, don't even bother parsing the data, it's all zeros
      if ((operationalStatus as any).code !== 404) {
        this.operationalStatus = this.parseData<OperationalStatus>(
          operationalStatus.data
        );
      }

      if ((healthStatus as any).code !== 404) {
        this.healthStatus = this.parseData<HealthStatus>(healthStatus.data);
      }
    } catch (e) {
      console.log(e);
    } finally {
      // these will be null if an exception was thrown or if response was 404
      if (!this.operationalStatus) {
        this.operationalStatus = {};
      }

      if (!this.healthStatus) {
        this.healthStatus = {};
      }

      this.loading = false;
    }
  }

  /**
   * Check if it has health feature
   * @param type
   */
  hasHealthFeature(type: AssetType): boolean | undefined {
    return this.healthSystemFeatures.find((e) => e.type === type)?.enabled;
  }

  openModal(type: AssetType, health: HealthStatus): void {
    const warning = this.config.health.find((h) => h.type === health)?.warning;

    this.modal = {
      open: type,
      title: `${this.$tc(type)} (${this.assetCount[type] || 0})`,
      warningType: warning ?? WarningType.Normal,
    };
  }

  /**
   * Open page
   * @param type
   * @param status
   */
  openAssetsPage(type: AssetType, status: OperationalStatus): void {
    const url = this.config.asset.find((a) => a.type === type)?.url;
    this.$router.push(
      `/assets/${url}?status=${stripPrefix(status, OPERATIONAL_STATUS_PREFIX)}`
    );
  }

  /**
   * Parse data
   * @param response
   */
  parseData<T extends string>(
    response: KpiDataResponse
  ): Partial<Record<AssetType, Partial<Record<T, number>>>> {
    if (
      !response.details ||
      !response.details.length ||
      !response.details[0].fields.length
    ) {
      throw new Error('no response details');
    }

    const { values } = response.details[0].fields[0];

    if (!values.length || Object.keys(values[0]).length !== 3) {
      throw new Error('no values in response');
    }

    const { v, 'KPI.AssetType': t, ...rest } = values[0];
    const key = Object.keys(rest)[0];

    return values.reduce<KpiData<T>>((acc, cur) => {
      const type = cur['KPI.AssetType'] as AssetType;
      const field = cur[key] as T;
      const value = cur.v ? parseInt(cur.v) : 0;

      return {
        ...acc,
        [type]: {
          ...acc[type],
          [field]: value,
        },
      };
    }, {});
  }

  /**
   * Fetch data remotly
   */
  getData(): Promise<
    [CommonResult<KpiDataResponse>, CommonResult<KpiDataResponse>]
  > {
    return Promise.all([
      getKpiData(
        this.getRequestBody('KPI.OperationalStatus'),
        unref(this.context)
      ),
      getKpiData(this.getRequestBody('KPI.HealthStatus'), unref(this.context)),
    ]);
  }

  /**
   * Get request body
   * @param kpiGroup
   */
  getRequestBody(kpiGroup: string) {
    return {
      metadata: {
        filter: {
          organizationIds: unref(this.context).organizationIds,
          groupBy: ['KPI.AssetType', kpiGroup],
        },
      },
      details: [
        {
          entity: 'ENTT_ASSET',
          fields: [
            {
              code: 'KPI.AssetId.count',
            },
          ],
        },
      ],
    };
  }

  /**
   * Retrieve status width
   * @param asset
   * @param type
   */
  getStatusWidth(asset: AssetType, type: OperationalStatus): string | number {
    const total = this.assetCount[asset];
    const count = this.operationalStatus?.[asset]?.[type];

    if (!total || !count) {
      return 0;
    }

    return `${(100 / total) * count}%`;
  }

  /**
   * Get status configuration
   * @param status
   * @param option
   */
  getStatusConfig(status: OperationalStatus, option: any): any {
    return getAssetStatusConfig(status, option, this.config);
  }

  assets: BaseAsset[] = [
    {
      type: AssetType.StaticCompactor,
      image: staticCompactor,
      url: 'static-compactors',
    },
    {
      type: AssetType.AlbaStaticCompactor,
      image: staticCompactor,
      url: 'alba-static-compactors',
    },
    {
      type: AssetType.RefuseCollectionVehicle,
      image: staticCompactor,
      url: 'refuse-collection-vehicles',
    },
    {
      type: AssetType.MobileCompactor,
      image: mobileCompactor,
      url: 'mobile-compactors',
    },
    {
      type: AssetType.TippingVehicle,
      image: tippingVehicle,
      url: 'tipping-vehicles',
    },
  ];

  config = {
    asset: this.assets.map((asset): BaseConfigAsset => {
      const statuses = getOperationalStatuses(asset.type);
      return {
        ...asset,
        operationalStatuses: statuses.map((status) => ({
          status: status,
          description: this.$t(`operationalStatusDescription.${status}`),
          image: OPERATIONAL_STATUS_ICONS[status],
        })),
      };
    }),
    health: [
      {
        type: HealthStatus.Good,
        color: '#4DAF70',
        warning: WarningType.Normal,
      },
      {
        type: HealthStatus.MinorDefects,
        color: '#E89253',
        warning: WarningType.Warning,
      },
      {
        type: HealthStatus.NeedsFix,
        color: '#CE6666',
        warning: WarningType.Alarm,
      },
    ],
    status: getOperationalStatusSchema(),
  };
}
</script>

<template>
  <WidgetCard :loading="loading">
    <div class="container">
      <template v-if="config.asset.length > 0">
        <div
          v-for="asset of config.asset"
          :key="asset.type"
          :class="
            hasHealthFeature(asset.type)
              ? 'wrapper'
              : 'wrapper-without-health-status'
          "
        >
          <div class="asset">
            <div class="asset-header">
              <span class="asset-name">
                {{ $t(asset.type) }}
              </span>
              <span class="asset-info">
                <el-tooltip popper-class="info-tooltip" placement="top">
                  <i class="el-icon-info"></i>
                  <div slot="content">
                    <div class="info-grid-container">
                      <div
                        v-for="(item, index) in asset.operationalStatuses"
                        :key="index"
                        class="info-text"
                        style="display: contents"
                      >
                        <div
                          class="info-grid-item"
                          :style="{ gridRowStart: `${index + 1}` }"
                        >
                          <img
                            :src="item.image"
                            :alt="`Icon of ${$t(item.status)}`"
                          />
                        </div>
                        <div
                          class="info-grid-item"
                          :style="{ gridRowStart: `${index + 1}` }"
                        >
                          {{ $t(item.status) }}:
                        </div>
                        <div
                          class="info-grid-item"
                          :style="{ gridRowStart: `${index + 1}` }"
                        >
                          {{ item.description }}
                        </div>
                      </div>
                    </div>
                  </div>
                </el-tooltip>
              </span>
            </div>
            <img :src="asset.image" class="asset-image" />
            <span class="asset-count">
              {{ assetCount[asset.type] || 0 }}
            </span>
          </div>
          <div class="divider" />
          <div class="health" v-if="hasHealthFeature(asset.type)">
            <button
              v-for="health in config.health"
              :key="health.type"
              class="health-item dotted"
              :style="{ '--dot-color': health.color }"
              @click="openModal(asset.type, health.type)"
            >
              {{ $t(health.type) }}
              <span class="health-value">
                {{
                  loading
                    ? 0 /* @ts-expect-error TODO Wrong type */
                    : (healthStatus[asset.type] &&
                        /* @ts-expect-error TODO Wrong type */
                        healthStatus[asset.type][health.type]) ||
                      0
                }}
              </span>
            </button>
          </div>
          <div v-if="hasHealthFeature(asset.type)" class="divider" />
          <div class="status">
            <div class="status-items">
              <div
                v-for="status in asset.operationalStatuses"
                :key="status.status"
                class="status-item dotted"
                :style="{
                  '--dot-color': getStatusConfig(status.status, 'color'),
                }"
              >
                <span class="status-value">
                  {{
                    loading
                      ? 0
                      : operationalStatus?.[asset.type]?.[status.status] ?? 0
                  }}
                </span>
                {{ $t(status.status) }}
              </div>
            </div>
            <div class="status-bar">
              <button
                v-for="status in asset.operationalStatuses"
                :key="status.status"
                class="status-block"
                :style="{
                  flexBasis: getStatusWidth(asset.type, status.status),
                  backgroundColor: getStatusConfig(status.status, 'color'),
                }"
                @click="openAssetsPage(asset.type, status.status)"
              >
                <span class="status-type">
                  <img
                    :src="getStatusConfig(status.status, 'icon')"
                    class="status-icon"
                  />
                  <span class="status-name">
                    {{ $t(status.status) }}
                  </span>
                  <span class="status-count">
                    {{
                      loading
                        ? 0
                        : operationalStatus?.[asset.type]?.[status.status] ?? 0
                    }}
                  </span>
                </span>
              </button>
            </div>
          </div>
          <HealthModal
            :title="modal.title"
            :modal-visable="modal.open === asset.type"
            :asset-type="asset.type"
            :warning-type="modal.warningType"
            @updateVisible="modal.open = undefined"
          />
        </div>
      </template>
      <template v-else-if="!loading">
        <span class="no-assets-text">{{
          $tc('assetMgmt.noAssetsAvailableInThisOrg')
        }}</span>
      </template>
    </div>
  </WidgetCard>
</template>

<style lang="scss" scoped>
%reset {
  appearance: none;
  border: none;
  background: none;
  text-align: start;
  cursor: pointer;
  padding: 0;
}

.container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  min-height: 5rem;

  @media (min-width: 769px) {
    padding: 1rem;
  }
}

.wrapper {
  display: grid;
  grid-template: 1fr / 1fr auto;
  gap: 1rem;
  padding-bottom: 1rem;

  &:not(:last-child) {
    border-bottom: 1px solid #e5e5e5;
  }
}

.wrapper-without-health-status {
  display: grid;
  grid-template: 1fr / 1fr auto;
  gap: 1rem;
  padding-bottom: 1rem;

  &:not(:last-child) {
    border-bottom: 1px solid #e5e5e5;
  }
}

.dotted::before {
  content: '';
  display: block;
  width: 1rem;
  height: 1rem;
  margin-right: 0.5rem;
  background-color: var(--dot-color);
  border-radius: 50%;
}

.asset {
  display: grid;
  grid-template: min-content 3rem / 5rem auto;
  gap: 0.5rem 1.5rem;
  align-items: center;

  &-header {
    display: flex;
    align-items: center;
    grid-column: span 2;

    @media (min-width: 1255px) {
      justify-content: space-between;
    }
  }

  &-name {
    font-weight: 500;
    margin-right: 0.5rem;
  }

  &-image {
    max-width: 100%;
    max-height: 100%;
  }

  &-count {
    font-size: 1.5rem;
    font-weight: 500;
  }
}

.info-grid-container {
  display: grid;
  gap: 10px;
}

.info-grid-item {
  display: flex;
  align-items: center;
}

.info-text {
  color: #373e41;
  font-size: 16px;
}

.health {
  display: flex;
  flex-direction: column;
  margin-top: 1rem;

  &-item {
    @extend %reset;
    display: grid;
    grid-template-columns: auto 1fr auto;
    align-items: center;
    justify-content: flex-start;
  }

  &-value {
    margin-left: 2rem;
    font-size: 1.25rem;
    font-weight: 500;
  }
}

.hidden {
  opacity: 0;
  pointer-events: none;
}

.status {
  grid-column: span 2;

  &-full {
    grid-column: span 4;
  }

  &-items {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    margin-bottom: 0.5rem;
  }

  &-item {
    display: flex;
    align-items: center;
  }

  &-value {
    margin-right: 0.5rem;
    font-weight: 500;
  }

  &-bar {
    display: flex;
    height: 0.75rem;
    background-color: #e5e5e5;
  }

  &-block {
    @extend %reset;
  }

  &-type {
    display: none;
  }

  &-name {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &-icon {
    display: none;
  }

  &-count {
    font-size: 1.25rem;
    font-weight: 500;
  }
}

.divider {
  display: none;
  width: 1px;
  background-color: #e5e5e5;
}

.big {
  & .container {
    gap: 1rem;
    justify-content: space-around;
    height: 100%;
  }

  & .wrapper {
    grid-template-columns: repeat(5, auto) 1fr;
    padding: 1rem;
    background-color: #f6f6f6;
    box-shadow: 0 0 0 1px inset #e5e5e5;
    border: none !important;
    border-radius: 0.5rem;
  }

  & .wrapper-without-health-status {
    grid-template-columns: repeat(3, auto) 1fr;
    padding: 1rem;
    background-color: #f6f6f6;
    box-shadow: 0 0 0 1px inset #e5e5e5;
    border: none !important;
    border-radius: 0.5rem;
  }

  & .asset {
    grid-template: auto 2rem / 3rem auto;
    width: 9rem;

    &-name {
      font-weight: 400;
    }
  }

  & .health {
    flex-direction: row;
    gap: 1rem;
    margin-top: 0;

    &-value {
      grid-column: span 2;
      margin: 0;
    }
  }

  & .status {
    &-items {
      display: none;
    }

    &-bar {
      gap: 0.25rem;
      height: 100%;
      background-color: transparent;
    }

    &-block {
      flex-basis: 100% !important;
    }

    &-type {
      display: grid;
      grid-template: 1fr / auto min-content;
      column-gap: 0.5rem;
      padding: 0.45rem;
      align-items: end;
      color: white;
    }

    &-icon {
      display: block;
      grid-column: span 2;
      width: 1.25rem;
    }
  }

  .divider {
    display: block;
  }

  .no-assets-text {
    text-align: center;
    font-size: 20px;
  }
}
</style>
