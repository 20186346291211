<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
  name: 'SelectCheckbox',
})
export default class extends Vue {
  @Prop() itemList!: string[];
  @Prop() defaultCheckedItems!: string[];

  checkedItems: string[] = [];

  created() {
    this.checkedItems = this.defaultCheckedItems;
  }

  handleAllChange() {
    this.checkedItems = ['All'];

    this.$emit('value-changed');
  }

  handleSubChange() {
    let index = this.checkedItems.findIndex((item: string) => item === 'All');

    if (index > -1) {
      this.checkedItems.splice(index, 1);
    }

    this.$emit('value-changed');
  }

  setCheckedItems(checkedItems: string[]): void {
    this.checkedItems = checkedItems;
  }

  getCheckedItems(): string[] {
    return this.checkedItems;
  }
}
</script>

<template>
  <div>
    <el-checkbox-group v-model="checkedItems" class="checkbox-items">
      <el-checkbox
        class="kpis-checkbox"
        :label="'All'"
        border
        @change="handleAllChange"
      >
        {{ $t('common.all') }}
      </el-checkbox>
      <el-checkbox
        class="kpis-checkbox"
        style="margin-left: 0px"
        v-for="item in itemList"
        :key="
          /* @ts-expect-error TODO Wrong type */
          item.id
        "
        :label="
          /* @ts-expect-error TODO Wrong type */
          item.code
        "
        border
        @change="handleSubChange"
      >
        {{
          /* @ts-expect-error TODO Wrong type */
          item.label
        }}
      </el-checkbox>
    </el-checkbox-group>
  </div>
</template>

<style lang="scss" scoped>
:deep(.el-checkbox__input.is-checked + .el-checkbox__label) {
  color: #373e41 !important;
}

:deep(.el-checkbox__input) {
  display: none;
}

.kpis-checkbox {
  width: 176px;
  height: 32px !important;
  border: 1px solid #818181;
  border-radius: 4px;
  text-align: center;

  font-size: 14px;
  font-family: $font-Roboto-Regular;
  line-height: 16px;
  color: #373e41;
}

.el-checkbox.is-bordered {
  margin-right: 0px;
  // margin: 0;
  max-width: calc(50% - 10px);
  min-width: calc(50% - 10px);
}
.checkbox-items {
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
}

.el-checkbox.is-bordered.is-checked {
  border-color: #818181;
  background-color: var(--Main);
}

:deep(.kpis-checkbox > .el-checkbox__label) {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: 100%;
}
</style>
