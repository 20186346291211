<script lang="ts">
import { Organization } from '@/api/organizations';
import GeneralInfo from '@/components/form/GeneralInfo.vue';
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
  name: 'OrgInfo',
  components: {
    'general-info': GeneralInfo,
  },
})
export default class extends Vue {
  @Prop() orgInfo!: Organization;
  @Prop() isHyvaHelpdeskAdmin!: boolean;
  @Prop() serviceRegions!: string;
}
</script>

<template>
  <div>
    <general-info
      :name="'organizationModule.organizationName'"
      :value="orgInfo.name"
    ></general-info>
    <general-info
      :name="'organizationModule.subdivisionOf'"
      :value="orgInfo.parentName ? orgInfo.parentName : '—'"
    ></general-info>
    <general-info
      :name="'organizationModule.usersCount'"
      v-if="!isHyvaHelpdeskAdmin"
      :value="orgInfo.usersCount"
    ></general-info>
    <general-info
      :name="'organizationModule.assetsCount'"
      v-if="!isHyvaHelpdeskAdmin"
      :value="orgInfo.assetsCount"
    ></general-info>
    <general-info
      :name="'common.serviceRegion'"
      v-if="!isHyvaHelpdeskAdmin"
      :value="$t(orgInfo.serviceRegion)"
    ></general-info>
    <general-info
      :name="'common.hyvaRegion'"
      v-if="!isHyvaHelpdeskAdmin"
      :value="$t(orgInfo.hyvaRegion)"
    ></general-info>
    <general-info
      :name="'customerModule.province'"
      :value="orgInfo.province"
    ></general-info>
    <general-info
      :name="'customerModule.city'"
      :value="orgInfo.city"
    ></general-info>
    <general-info
      :name="'customerModule.district'"
      :value="orgInfo.district"
    ></general-info>
    <general-info
      :name="'organizationModule.serviceRegions'"
      v-if="isHyvaHelpdeskAdmin"
      :value="serviceRegions"
    ></general-info>
    <general-info
      :name="'customerModule.street'"
      :value="orgInfo.street"
    ></general-info>
    <general-info
      :name="'customerModule.postCode'"
      :value="orgInfo.zipCode"
    ></general-info>
    <general-info
      :name="'customerModule.note'"
      :value="orgInfo.note"
    ></general-info>
    <general-info
      :name="'organizationModule.timezone'"
      :value="orgInfo.timezone ? orgInfo.timezone.replace('/', '_') : ''"
    ></general-info>
    <general-info
      :name="'organizationModule.activationStatus'"
      :value="orgInfo.activationStatus"
    ></general-info>
  </div>
</template>
