<script lang="ts">
import BarChart from '@/components/kpiCharts/BarChart.vue';
import KpiTable from '@/components/widget/kpiInfo/KpiTable.vue';
import {
  useUnitConversion,
  UseUnitConversionReturnType,
} from '@/composables/conversion';
import { formatNumber } from '@/utils/number';
import { KpiData } from '@/utils/types/columnCustomizationTypes';
import { toUnitValue } from '@/utils/units/unitValue';
import moment from 'moment';
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
  name: 'report',
  components: {
    BarChart,
    KpiTable,
  },
})
export default class extends Vue {
  @Prop({ required: true }) title!: string;
  @Prop({ required: true }) start!: Date;
  @Prop({ required: true }) end!: Date;
  @Prop({ default: () => [] }) dataRows!: unknown[];
  @Prop({ default: () => [] }) dataCols!: unknown[];
  @Prop({ default: () => [] }) kpis!: KpiData[];
  @Prop({ default: () => [] }) charts!: unknown[];
  @Prop({ required: true }) selectedAssetName!: string;

  reportTime = moment(new Date()).format('DD/MM/YYYY hh:mm A');
  unitConversion!: UseUnitConversionReturnType;

  created() {
    this.unitConversion = useUnitConversion();
  }

  /**
   * Get range for generated report
   * End date should be substracted with one day to see exactly of what user was selected from time and date selection
   * - Towards API the end date is +1 day because we need the full day inclusive of the selection
   */
  get range(): string {
    const formattedStart = moment(this.start).format('DD/MM/YYYY');
    const formattedEnd = moment(this.end)
      .subtract(1, 'days')
      .format('DD/MM/YYYY');

    return `${formattedStart} - ${formattedEnd}`;
  }

  get convertedKpis(): KpiData[] {
    return this.kpis.map((kpi) => {
      const convertedValue = this.unitConversion.currentUserConvertUnitValue(
        toUnitValue(parseFloat(kpi.value), kpi.unit)
      );

      return {
        code: kpi.code,
        unit: convertedValue.unit,
        value: formatNumber(convertedValue.v),
      };
    });
  }
}
</script>

<template>
  <div class="working-report">
    <div class="working-report-header">
      <span class="title"> {{ title }} - {{ selectedAssetName }}</span>
      <span>{{ $t('report.generateTime') }}: {{ reportTime }}</span>
    </div>
    <div class="working-report-body">
      <div class="working-report-block">
        <span class="title"
          >{{ $t('report.kpisSummaryFor') }}: {{ range }}</span
        >
        <div class="kpi-summary">
          <div
            v-for="kpi in convertedKpis"
            :key="kpi.code"
            class="kpi-summary-block"
          >
            {{ $t(kpi.code) }}
            <div>
              <span class="kpi-summary-value">{{ kpi.value }}</span>
              {{ $t(kpi.unit) }}
            </div>
          </div>
        </div>
      </div>
      <div
        v-for="(chart, index) in charts"
        :key="index"
        class="working-report-block"
      >
        <span class="title">{{
          /* @ts-expect-error TODO Wrong type */
          $t(chart?.name)
        }}</span>
        <BarChart
          :chart-data="
            /* @ts-expect-error TODO Wrong type */
            chart?.lines
          "
          item-color="#48B8F4"
          y-axis-unit=""
          height="280px"
        />
      </div>
      <div class="working-report-block">
        <span class="title">{{ $t('report.data') }}</span>
        <KpiTable :tableList="dataRows" :cols="dataCols" />
      </div>
    </div>
  </div>
</template>

<style scoped>
.working-report :deep(.table-header) {
  white-space: nowrap;
}
</style>

<style lang="scss" scoped>
.working-report {
  border: 1px solid #373e41;
  font-family: $font-Roboto-Regular;

  .title {
    margin-bottom: 1rem;
    font-family: $font-Roboto-Medium;
    font-size: 1.5rem;
  }

  &-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 4rem;
    padding: 0 2rem;
    color: white;
    background-color: #373e41;
    font-size: 1.25rem;

    & .title {
      margin: 0;
    }
  }

  &-body {
    padding: 2rem;
    color: #373e41;
  }

  &-block {
    display: flex;
    flex-direction: column;

    &:not(:last-child) {
      margin-bottom: 3rem;
    }
  }

  .kpi-summary {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 1.5rem;

    &-block {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 8.5rem;
      padding: 1rem 1rem 0.5rem;
      border: 1px solid #373e41;
      border-radius: 0.25rem;
      font-family: $font-Roboto-Medium;
      font-size: 1.25rem;
    }

    &-value {
      font-family: $font-Roboto-Bold;
      font-size: 2.5rem;
    }
  }
}
</style>
