<script lang="ts">
import {
  activateGeofence,
  deprecateGeofence,
  getGeofenceById,
  LifeCycle,
  updateGeofence,
} from '@/api/geofence';
import {
  GeofencePosition,
  GeofenceTrackingStrategy,
} from '@/api/geofenceTypes';
import BaseCard from '@/components/cusCard/BaseCard.vue';
import WidgetCard from '@/components/layout/widget/WidgetCard.vue';
import LeafletMap from '@/components/leafletMap/LeafletMap.vue';
import { sortWeekDays } from '@/utils';
import {
  customSuccessMessage,
  promptFailedBox,
  promptSuccessBox,
} from '@/utils/prompt';
import { formValidateArrayLength } from '@/utils/validate';
import { InitializeReactive } from '@/utils/vueClassComponentHelpers';
import { ALL_CLAIMS_CODES } from '@/utils/workData/lookuptable';
import Treeselect from '@riophae/vue-treeselect';
import { Component, Prop, Vue } from 'vue-property-decorator';
import GeofenceForm, { GeofenceFormModel } from './components/GeofenceForm.vue';

@Component({
  name: 'EditGeofence',
  components: {
    BaseCard,
    LeafletMap,
    Treeselect,
    WidgetCard,
    GeofenceForm,
  },
})
export default class extends Vue {
  @Prop({ required: true }) geofenceId!: string;

  ALL_CLAIMS_CODES = ALL_CLAIMS_CODES;
  LifeCycle = LifeCycle;

  @InitializeReactive
  geofenceForm?: GeofenceFormModel;
  allowTracking: boolean = false;
  geofencePosition: GeofencePosition[] = [];
  geofenceConfig = {
    enableCreate: false,
    editOnly: false,
  };
  center: number[] = [46.771211, 23.623634];
  zoom: number = 10;
  editGeofencePageIsLoading = true;

  rules = {
    name: [
      {
        required: true,
        message: this.$t('geofence.ApiErrorFieldInvalid.ApiFieldName'),
        trigger: 'change',
      },
    ],
    organizationId: [
      {
        required: true,
        message: this.$t(
          'geofence.ApiErrorFieldRequired.ApiFieldOrganizationId'
        ),
        trigger: 'blur',
      },
    ],
    geofenceType: [
      {
        required: true,
        message: this.$t('geofence.ApiErrorFieldInvalid.ApiFieldGeofenceType'),
        trigger: 'blur',
      },
    ],
    associatedAssetTypes: [
      {
        validator: (rule: any, value: any[] | undefined, callback: Function) =>
          formValidateArrayLength(
            value,
            callback,
            this.$t(
              'geofence.ApiErrorFieldRequired.ApiFieldAssociatedAssetTypes'
            ),
            1
          ),
        trigger: 'change',
      },
    ],
  };

  $refs!: {
    map: LeafletMap;
  };

  async created() {
    await this.fetchGeofence();
  }

  getGeoCoord(): void {
    // only if the shape has been updated, set the new coordinates
    // map does not return anything here if no new shape has been drawn
    if (this.$refs.map.getGeofenceCoordinates().length) {
      this.geofencePosition = this.$refs.map.getGeofenceCoordinates();
    }
  }

  getFinalGeofenceData() {
    if (this.geofenceForm === undefined) {
      throw new Error("geofenceForm shouldn't be undefined at this point.");
    }

    this.editGeofencePageIsLoading = true;
    this.getGeoCoord();

    const finalGeofenceData: any = {
      name: this.geofenceForm.name,
      organizationId: this.geofenceForm.organizationId,
      lifeCycle: this.geofenceForm.lifeCycle,
      associatedAssetTypes: this.geofenceForm.associatedAssetTypes,
      geofenceType: this.geofenceForm.geofenceType,
      tracking: !this.allowTracking
        ? GeofenceTrackingStrategy.Off
        : this.geofenceForm.tracking === GeofenceTrackingStrategy.Always
        ? GeofenceTrackingStrategy.Always
        : this.geofenceForm.trackingTime,
    };

    if (this.geofenceForm.lifeCycle !== LifeCycle.Active) {
      finalGeofenceData.geofencePosition = this.geofencePosition;
    }

    if (
      finalGeofenceData.tracking !== GeofenceTrackingStrategy.Off &&
      finalGeofenceData.tracking !== GeofenceTrackingStrategy.Always
    ) {
      finalGeofenceData.trackingDays = sortWeekDays(
        this.geofenceForm.trackingDays
      );

      if (finalGeofenceData.tracking !== GeofenceTrackingStrategy.AllDays) {
        finalGeofenceData.trackingStartTime =
          this.geofenceForm.trackingStartTime;
        finalGeofenceData.trackingEndTime = this.geofenceForm.trackingEndTime;
      }
    }

    return finalGeofenceData;
  }

  async saveGeofence(): Promise<void> {
    try {
      const finalGeofenceData = this.getFinalGeofenceData();

      const updateResponse = await updateGeofence(
        this.geofenceId,
        finalGeofenceData
      );
      if (!updateResponse) {
        customSuccessMessage(this.$t('common.errorWithFetchingData') as string);
        return;
      }

      if (updateResponse.data?.errors) {
        promptFailedBox(this.$t('common.update') as string);
        return;
      }

      promptSuccessBox(this.$t('common.update') as string);
      this.$router.push(
        `/administration/index?activeName=geofences&geofence=${this.geofenceId}`
      );
    } catch (error) {
    } finally {
      this.editGeofencePageIsLoading = false;
    }
  }

  /**
   * Deprecate geofence entity
   */
  async deprecateGeofence(): Promise<void> {
    try {
      this.editGeofencePageIsLoading = true;
      await deprecateGeofence(this.geofenceId).then((res) => {
        if (res.code === 200) {
          promptSuccessBox(this.$t('geofence.deprecate') as string);
          this.$router.push(
            `/administration/index?activeName=geofences&geofence=${this.geofenceId}`
          );
        } else {
          promptFailedBox(this.$t('geofence.deprecate') as string);
        }
      });
    } catch (error) {
      console.log(error);
    } finally {
      this.editGeofencePageIsLoading = false;
    }
  }

  /**
   * Activate geofence
   */
  async activateGeofence(): Promise<void> {
    try {
      this.editGeofencePageIsLoading = true;
      await activateGeofence(this.geofenceId).then((res) => {
        if (res.code === 200) {
          promptSuccessBox(this.$t('geofence.activate') as string);
          this.$router.push(
            `/administration/index?activeName=geofences&geofence=${this.geofenceId}`
          );
        } else {
          promptFailedBox(this.$t('geofence.activate') as string);
        }
      });
    } catch (error) {
    } finally {
      this.editGeofencePageIsLoading = false;
    }
  }

  async fetchGeofence(): Promise<void> {
    try {
      this.editGeofencePageIsLoading = true;
      const geofence = await getGeofenceById(this.geofenceId);

      const isTimePeriodTracking =
        geofence.tracking === GeofenceTrackingStrategy.OutSideTimeInterval ||
        geofence.tracking === GeofenceTrackingStrategy.InsideTimeInterval ||
        geofence.tracking === GeofenceTrackingStrategy.AllDays;

      this.geofenceForm = {
        name: geofence.name,
        associatedAssetTypes: geofence.associatedAssetTypes,
        lifeCycle: geofence.lifeCycle,
        geofenceType: geofence.geofenceType,
        organizationId: geofence.organizationId,
        tracking: isTimePeriodTracking
          ? GeofenceTrackingStrategy.TimePeriod
          : GeofenceTrackingStrategy.Always,
        trackingTime: isTimePeriodTracking ? geofence.tracking : undefined,
        trackingDays: geofence.trackingDays ?? [],
        trackingStartTime: geofence.trackingStartTime,
        trackingEndTime: geofence.trackingEndTime,
      };
      this.allowTracking = geofence.tracking !== GeofenceTrackingStrategy.Off;
      this.editGeofencePageIsLoading = false;
      this.geofenceConfig.enableCreate = [
        LifeCycle.Draft,
        LifeCycle.Active,
      ].includes(this.geofenceForm.lifeCycle);

      this.$refs.map?.initializeGeofence([geofence]);
      this.$refs.map?.setBoundsToGeofences();
    } catch (error) {
      console.log(error);
    } finally {
    }
  }
}
</script>

<template>
  <WidgetCard
    class="card"
    :loading="editGeofencePageIsLoading"
    :defaultTitle="$t('route.administration.editGeofence')"
    :showBackButton="true"
    :floatActionsRight="true"
  >
    <template #actions>
      <el-button
        id="activate_geofence_button"
        v-if="geofenceForm?.lifeCycle === LifeCycle.Draft"
        type="plain"
        @click="activateGeofence"
      >
        {{ $t('geofence.activateButton') }}
      </el-button>

      <el-button
        id="deprecate_geofence_button"
        v-permission="ALL_CLAIMS_CODES.AUTHRSC_ACTION_GEOFENCE_DEPRECATE"
        type="plain"
        v-if="geofenceForm?.lifeCycle === LifeCycle.Active"
        @click="deprecateGeofence"
      >
        {{ $t('geofence.deprecateButton') }}
      </el-button>

      <el-button
        id="save_geofence_changes_button"
        v-if="geofenceForm?.lifeCycle != LifeCycle.Deprecated"
        type="plain"
        @click="saveGeofence"
      >
        {{ $t('common.save') }}
      </el-button>
    </template>

    <div class="container">
      <div class="map">
        <LeafletMap
          ref="map"
          :center="center"
          :zoom="zoom"
          :geofenceConfig="geofenceConfig"
          :geofences="[geofenceForm]"
        />
      </div>

      <div v-loading="geofenceForm === undefined" class="fields">
        <GeofenceForm
          v-if="geofenceForm"
          :formModel="geofenceForm"
          :formRules="rules"
          :allowTracking="allowTracking"
          @toggleAllowTracking="allowTracking = !allowTracking"
        />
      </div>
    </div>
  </WidgetCard>
</template>

<style lang="scss" scoped>
.card {
  height: 100%;
  display: flex !important;
  flex-direction: column;
}

.container {
  height: 100%;
  display: flex;
  gap: 24px;
}

.map {
  flex-basis: 60%;
}

.fields {
  margin-top: 32px;
  margin-right: 24px;
  flex-basis: 40%;
}
</style>
