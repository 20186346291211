import { QueryParameter } from '@/model/queryParameters/QueryParameter';
import { api } from '@/utils/api';
import { getRequest } from '@/utils/request';
import { CommonResultPromise } from './commonResult';
import { HyvaRegionCode } from './hyvaRegion';

/**
 * Represent a Service Region code that has prefix `SRVCRGN_` (e.g. `SRVCRGN_CN_NM`, `SRVCRGN_CN_MO`)
 */
export type ServiceRegionCode = string;

export interface ServiceRegion {
  serviceRegionCode: ServiceRegionCode;
  serviceRegionName: string;
  hyvaRegionCode: HyvaRegionCode;
}

export function getServiceRegions(): Promise<ServiceRegion[]> {
  return api().post<ServiceRegion[], {}>('/service-regions', {});
}

export function getAccessibleServiceRegions(
  queryParameter: QueryParameter
): CommonResultPromise<AccessibleServiceRegionResponse[]> {
  const request = getRequest();
  const response = request.post<AccessibleServiceRegionResponse[]>(
    '/organizations/accessible-service-regions',
    queryParameter
  );
  return response;
}

export function addServiceRegionsToOrganization(
  orgId: string,
  data: any
): CommonResultPromise {
  const request = getRequest();
  const response = request.request({
    url: `/organizations/${orgId}/accessible-service-regions`,
    method: 'post',
    data,
  });
  return response;
}

export interface AccessibleServiceRegionResponse {
  accessibleServiceRegions: AccessibleServiceRegionsField[];
  companyId: string;
  organizationId: string;
}

export interface AccessibleServiceRegionsField {
  code: string;
  name: string;
}

/**
 * Get not allocated accessible service region withing company id hierarchy
 * - loggic handled by API (VAS)
 * - available only for helpdesk requester token
 * @returns
 */
export function getNotAllocatedAccessibleServiceRegions(): CommonResultPromise<
  NotAllocatedAccessibleServiceRegionsResponse[]
> {
  const request = getRequest();
  const response = request.get<NotAllocatedAccessibleServiceRegionsResponse[]>(
    '/organizations/not-allocated-accessible-service-regions'
  );
  return response;
}

export interface NotAllocatedAccessibleServiceRegionsResponse {
  id?: string;
  serviceRegionCode: string;
  serviceRegionName: string;
  deploymentRegionCode?: string;
}
