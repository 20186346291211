<script lang="ts">
import { Component, Prop, PropSync, Vue } from 'vue-property-decorator';
import AssignDialog from './AssignDialog.vue';

@Component({
  name: 'AssignNav',
  components: {
    AssignDialog,
  },
})
export default class extends Vue {
  @Prop({ default: 0 }) selectedNumber!: string | Number;
  @PropSync('operationState', { type: String }) syncedOperationState!: string;
  @Prop({ default: false }) disableBtn!: boolean;

  btnTextMap: any = {
    selecting: 'assetMgmt.next',
    filling: 'assetMgmt.assign',
    confirm: 'assetMgmt.confirm',
    succeed: 'assetMgmt.succeed',
  };
  visible: boolean = false;

  created() {}

  //the state goes next
  changeAssignState() {
    if (!this.disableBtn) {
      switch (this.syncedOperationState) {
        case 'selecting':
          this.syncedOperationState = 'filling';
          break;
        case 'filling':
          //send emit to parent to get formData
          this.$emit('validate-form-data');
          break;
        case 'confirm': //post api
          this.$emit('send-request');
          break;
        case 'succeed': //reset to main page
          this.$emit('reset-to-home');
          // this.$router.go(0)
          // window.location.reload()
          break;
        default:
          break;
      }
    }
  }

  updateState(newState: string) {
    this.syncedOperationState = newState;
  }

  //the state goes back
  backAssignState() {
    switch (this.syncedOperationState) {
      case 'selecting':
        break;
      case 'filling':
        this.syncedOperationState = 'selecting';
        //back to 'selelcting' state should recover the disabled btn
        this.$emit('reset-disable-assign', false);
        break;
      case 'confirm':
        this.syncedOperationState = 'filling';
        break;
      default:
        break;
    }
  }

  updateVisible(val: boolean) {
    this.visible = val;
  }

  resetPopupData() {
    this.visible = false;
  }

  submitPopupData() {
    this.visible = false;
    //go to router
    this.$router.push('/asset-mgmt/index?activeName=connectedAssets');
  }

  //the state goes home
  backToHome() {
    this.visible = true;
  }
}
</script>

<template>
  <div>
    <div
      class="nav_container d-flex ai-center jc-between"
      v-if="syncedOperationState != 'succeed'"
    >
      <div id="new_card_header_title" class="d-flex ai-center">
        <p v-if="syncedOperationState === 'confirm'">
          <span class="header-title">
            {{ $t('assetMgmt.pleaseConfirm') }}:</span
          >
        </p>
        <p v-else>
          <span class="header-title">
            <span>{{ selectedNumber }}</span>
            {{ $t('assetMgmt.assetsSelected') }}</span
          >
        </p>
      </div>
      <div class="btn_area">
        <div
          class="back_btn"
          v-show="
            /* @ts-expect-error TODO Wrong type */
            operationState != 'selecting'
          "
          @click="backAssignState"
        >
          <span>{{ $t('assetMgmt.back') }}</span>
        </div>
        <div
          class="clicking_btn"
          @click="changeAssignState"
          :class="disableBtn ? 'disabledBtn' : ''"
        >
          <span>{{ $t(btnTextMap[syncedOperationState]) }}</span>
        </div>
        <div class="close_btn" @click="backToHome">
          <i class="el-icon-close"></i>
        </div>
      </div>
    </div>

    <div class="new_nav_container" v-else>
      <p class="success_text">{{ $t('assetMgmt.successfullyAssigned') }}</p>
      <div class="new_btn_area">
        <div class="clicking_btn" @click="changeAssignState">
          <span>{{ $t(btnTextMap[syncedOperationState]) }}</span>
        </div>
      </div>
    </div>

    <AssignDialog
      title=""
      :modalVisiable="visible"
      popupWidth="800px"
      popupTop="25vh"
      @updateVisible="updateVisible"
      @resetPopupData="resetPopupData"
      @submitPopupData="submitPopupData"
    >
      <div class="dialog-header-title">
        <span>{{ $t('assetMgmt.modalWarning') }}</span>
      </div>
    </AssignDialog>
  </div>
</template>

<style lang="scss" scoped>
.nav_container {
  height: 80px;
  background: #edeff0;
  .header-title {
    margin-left: 21px;
  }
}

.btn_area {
  display: flex;
  .back_btn,
  .clicking_btn,
  .close_btn {
    margin-right: 33px;
    height: 38px;
    border: 1px solid #373e41;
    border-radius: 4px;
    cursor: pointer;
  }
  .back_btn {
    width: 198px;
    text-align: center;
    line-height: 36px;
    font-size: 16px;
    font-weight: bold;
  }
  .clicking_btn {
    background: #373e41;
    color: #ffffff;
    width: 198px;
    text-align: center;
    line-height: 36px;
    font-size: 16px;
    font-weight: bold;
    cursor: pointer;
  }
  .disabledBtn {
    background: #a2a2a2;
    border: 1px solid #a2a2a2;
    cursor: not-allowed;
  }
  .close_btn {
    width: 38px;
    i {
      font-size: 23px;
      line-height: 36px;
      width: 38px;
      text-align: center;
    }
  }
}

.footer_btn {
  margin-bottom: 70px;
  .back_btn,
  .clicking_btn {
    margin: 0 18px;
  }
}

.new_nav_container {
  height: 140px;
  background: #edeff0;
  text-align: center;
  position: relative;
  .new_btn_area {
    position: absolute;
    background: #373e41;
    color: #ffffff;
    width: 198px;
    text-align: center;
    line-height: 34px;
    font-size: 16px;
    font-weight: bold;
    right: 21px;
    top: 50px;
  }
  .success_text {
    color: #139d4d;
    font-size: 30px;
    line-height: 140px;
    margin: 0;
  }
}

.dialog-header-title {
  font-family: $font-Roboto-Regular;
  color: #373e41;
  font-size: 24px;
  text-align: center;
  margin: 40px 0 110px;
  width: 430px;
  line-height: 40px;
}
</style>
