import { UUID } from '@/api/common';
import { Organization } from '@/api/organizations';
import { Subscription } from '@/api/subscriptionPackages';
import {
  ALL_MODULE_CODES,
  ALL_PAGE_CODES,
  AuthorizableResourceCode,
} from '@/api/users';
import { Claims } from '@/auth/claims';
import { LoggedInUser } from '@/auth/user';
import store from '@/store';
import { AssetType } from '@/utils/assetTypes';
import {
  ALL_CLAIMS_CODES,
  COMPANY_TYPE,
  SYSTEM_FEATURES,
  THEME,
  UserRole,
} from '@/utils/workData/lookuptable';
import moment from 'moment-timezone';
import { settings } from 'nprogress';
import {
  Action,
  getModule,
  Module,
  Mutation,
  VuexModule,
} from 'vuex-module-decorators';
import { hasSystemFeature } from './permission';

export interface IUserState {
  id: string;
  username: string;
  avatar?: string;
  companyType: string;
  moduleAndPageCodes?: string[];
  role?: UserRole | '';
  email?: string;
  companyId: string;
  organizationId: string;
  i18nCode: string;
  theme: number;
  timeZone: string;
  datetimeFormat: string;
  gridPageSize: number;
  policyRegion: string;
}

@Module({ dynamic: true, store, name: 'user' })
class User extends VuexModule implements IUserState {
  public username = '';
  public id = '';
  public avatar = '';
  public email = '';

  /**
   * List of AUTHRSC_MOD_* and AUTHRSC_PAGE_* that are accessible to
   * the user.
   * I.e. they are enabled in both their role, and there is at least one
   * widget referring to that page (and thus the corresponding module, and
   * parent module, if any).
   */
  public moduleAndPageCodes: AuthorizableResourceCode[] = [];

  public companyId = '';
  public organizationId = '';
  /**
   * @deprecated Use `useLoggedInUser()` instead.
   */
  public organization: Organization = {} as any; // otherwise the getter isn't generated...
  public companyType: COMPANY_TYPE = COMPANY_TYPE.Customer;
  public claims = new Claims([]);
  public i18nCode = '';
  public theme: THEME = THEME.theme1;
  public timeZone = moment.tz.guess();
  public datetimeFormat = 'YYYY-MM-DD kk:mm:ss';
  public gridPageSize = 15;
  /**
   * Role code for this user.
   *
   * WARNING: Do NOT use roles for authorization, use claims instead.
   */
  public role: UserRole | '' = '';
  public subscriptions: Subscription[] = [];
  public activationStatus: string = '';
  public policyRegion: string = '';

  @Mutation
  private SET_USERNAME(username: string) {
    this.username = username;
  }

  @Mutation
  private SET_ID(id: string) {
    this.id = id;
  }

  @Mutation
  private SET_AVATAR(avatar: string) {
    this.avatar = avatar;
  }

  @Mutation
  private SET_ROLE(role: UserRole) {
    this.role = role;
  }

  @Mutation
  private SET_EMAIL(email: string) {
    this.email = email;
  }

  @Mutation
  private SET_MENUS(
    moduleAndPageCodes: Array<ALL_MODULE_CODES | ALL_PAGE_CODES>
  ) {
    this.moduleAndPageCodes = moduleAndPageCodes;
  }

  @Mutation
  private SET_COMPANYID(companyId: string) {
    this.companyId = companyId;
  }

  @Mutation
  private SET_ORGID(orgId: string) {
    this.organizationId = orgId;
  }

  @Mutation
  private SET_ORGANIZATION(org: Organization) {
    this.organization = org;
  }

  @Mutation
  private SET_COMPANYTYPE(companyType: COMPANY_TYPE) {
    this.companyType = companyType;
  }

  @Mutation
  private SET_CLAIMS(claims: Claims) {
    this.claims = claims;
  }

  @Mutation
  private SET_I18NCODE(i18nCode: string) {
    this.i18nCode = i18nCode;
  }

  @Mutation
  private SET_THEME(theme: number) {
    this.theme = theme;
  }

  @Mutation
  private SET_TIMEZONE(timeZone: string) {
    this.timeZone = timeZone;
  }

  @Mutation
  private SET_SUBSCRIPTIONS(subscriptions: Subscription[]) {
    this.subscriptions = subscriptions;
  }

  @Mutation
  private SET_ACTIVATION_STATUS(activationStatus: string) {
    this.activationStatus = activationStatus;
  }

  @Mutation
  private SET_POLICY_REGION(policyRegion: string) {
    this.policyRegion = policyRegion;
  }

  @Mutation
  private SET_DATEFORMAT(datetimeFormat: string) {
    this.datetimeFormat = datetimeFormat;
  }

  @Mutation
  private SET_PAGESIZE(pageSize: number) {
    this.gridPageSize = pageSize;
  }

  @Action
  public SetLang(i18nCode: string) {
    this.SET_I18NCODE(i18nCode);
  }

  @Action
  public setLoggedInUserId(userId: UUID) {
    this.SET_ID(userId);
  }

  @Action
  public setUserInfo(user: LoggedInUser | undefined): void {
    if (!user) {
      // TODO Implement 'proper' unsetting, although we should get rid of UserModule anyway
      this.SET_ID('');
      this.SET_ORGID('');
      this.SET_CLAIMS(new Claims([]));
      this.SET_MENUS([]);
      return;
    }
    this.SET_ID(user.id);
    this.SET_ROLE(user.role);
    this.SET_COMPANYID(user.companyId);
    this.SET_COMPANYTYPE(user.companyType);
    this.SET_ORGID(user.organization.id);
    this.SET_ORGANIZATION(user.organization);
    this.SET_MENUS(user.moduleAndPageCodes);
    this.SET_CLAIMS(user.claims);
    this.SET_I18NCODE(user.settings.i18nCode);
    this.SET_THEME(user.theme);
    this.SET_USERNAME(user.username);
    this.SET_EMAIL(user.email);
    this.SET_SUBSCRIPTIONS(user.subscriptions);
    this.SET_ACTIVATION_STATUS(user.activationStatus);
    this.SET_POLICY_REGION(user.policyRegion);

    if (settings) {
      this.SET_TIMEZONE(user.settings.timeZone);
      this.SET_DATEFORMAT(user.settings.datetimeFormat);
      this.SET_PAGESIZE(user.settings.gridPageSize);
    }
  }

  @Action
  // TODO move out
  public async hasMenu(resourceName: ALL_CLAIMS_CODES): Promise<boolean> {
    return this.moduleAndPageCodes.includes(resourceName);
  }

  @Action
  // TODO move out
  public async hasSystemFeature(
    requiredFeature: SYSTEM_FEATURES | [SYSTEM_FEATURES | null, AssetType]
  ): Promise<boolean> {
    return hasSystemFeature(this.subscriptions, requiredFeature);
  }

  @Action
  public UpdateUserName(nameUpdated: string) {
    this.SET_USERNAME(nameUpdated);
  }

  @Action
  public UpdateTimeZone(timeZone: string) {
    this.SET_TIMEZONE(timeZone);
  }
}

export const UserModule = getModule(User);
