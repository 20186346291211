export function min<T>(a: T, b: T): T {
  return a < b ? a : b;
}

export function max<T>(a: T, b: T): T {
  return a > b ? a : b;
}

export function sum(a: number, b: number): number {
  return a + b;
}

export function calculatePercentageChange(
  previousValue: number,
  newValue: number
): number | undefined {
  if (previousValue === 0) {
    return undefined;
  }

  return ((newValue - previousValue) / Math.abs(previousValue)) * 100;
}
