<script lang="ts">
import CreateNewReportSubscribtionModal from '@/views/report/components/CreateNewReportSubscribtionModal.vue';
import { Component, Prop, Vue } from 'vue-property-decorator';

import {
  getOrganizationAssetsHierarchyLegacy,
  OrganizationAssetsHierarchyResponseLegacy,
} from '@/api/assets';
import { getReportTemplate, TemplateResponse } from '@/api/report';
import { createReportSubscription } from '@/api/reportSubscription';
import BaseCard from '@/components/cusCard/BaseCard.vue';
import { customFailedMessage, customSuccessMessage } from '@/utils/prompt';
import { ACTIVATION_STATUS } from '@/utils/workData/lookuptable';
import {
  ASSET_SCOPE_ORGANIZATION,
  ASSET_SCOPE_SINGLE_ASSET,
} from '@/utils/workData/reportMgmt';

import { ActiveContext, useActiveContext } from '@/auth/context';
import { Ref, unref } from 'vue';
import ViewReportHeader from '../components/ViewReportHeader.vue';
import { setReportAdditionalData } from '../report';
import ReportView from './next/ReportView.vue';

type ReportFilters = {
  reportScope: string;
  reportScopeValue: string;
  startDate: string;
  endDate: string;
};

@Component({
  name: 'ViewReportTemplate',
  components: {
    BaseCard,
    ViewReportHeader,
    ReportView,
    CreateNewReportSubscribtionModal,
  },
})
export default class extends Vue {
  @Prop() reportTemplateId!: string;

  template: TemplateResponse | null = null;
  organizations: OrganizationAssetsHierarchyResponseLegacy[] = [];
  scope: string | null = null;
  filters: ReportFilters | null = null;
  subscribeVisible = false;
  generateReportTemplatePageIsLoading: boolean = false;
  isGeneratedReportVisible: boolean = false;
  selectedAssetName: string = '';
  context!: Ref<ActiveContext>;

  get scopeOptions() {
    switch (this.scope) {
      case ASSET_SCOPE_SINGLE_ASSET:
        return this.organizations
          .flatMap(({ organization }) => organization.assets)
          .map((asset) => ({
            // @ts-expect-error Refactor to new asset hierarchy
            id: asset.id,
            // @ts-expect-error Refactor to new asset hierarchy
            name: asset.companyAssetId,
          }));
      case ASSET_SCOPE_ORGANIZATION:
        return this.organizations.map(({ organization }) => ({
          id: organization.id,
          name: organization.name,
        }));
    }
    return [];
  }

  created() {
    this.context = useActiveContext();
    this.prepareDefaultInitialization();
  }

  /**
   * Prepare default initialization
   */
  async prepareDefaultInitialization(): Promise<void> {
    try {
      this.generateReportTemplatePageIsLoading = true;
      this.getData().then(([template, organizations]) => {
        this.template = template;
        this.organizations = organizations.filter(
          (entity: OrganizationAssetsHierarchyResponseLegacy) =>
            entity.organization.activationStatus == ACTIVATION_STATUS.Activated
        );
        this.generateReportTemplatePageIsLoading = false;
      });
    } catch (error) {
      console.log(error);
    } finally {
    }
  }

  /**
   * Get report template data and organizations hierarchy
   */
  async getData(): Promise<
    readonly [TemplateResponse, OrganizationAssetsHierarchyResponseLegacy[]]
  > {
    const { data: template } = await getReportTemplate(this.reportTemplateId);
    const hierarchy = await getOrganizationAssetsHierarchyLegacy(
      template.assetType,
      unref(this.context).organization?.id,
      unref(this.context)
    );

    return [
      template,
      this.flatHierarchy(hierarchy, (h) => h.organization.suborganizations),
    ] as const;
  }

  /**
   * Set filters for generated report
   * @param filters
   */
  setFilters(filters: ReportFilters): void {
    this.isGeneratedReportVisible = false;
    this.filters = filters;
    if (
      !Boolean(filters.reportScope) ||
      !Boolean(filters.reportScopeValue) ||
      !Boolean(filters.startDate) ||
      !Boolean(filters.endDate)
    ) {
      return;
    }

    this.$nextTick(() => {
      this.isGeneratedReportVisible = true;
    });
  }

  /**
   * Flat hierarchy
   * @param hierarchy
   * @param get
   */
  flatHierarchy<T, K>(hierarchy: T, get: (value: T) => T[]): T[] {
    return [
      hierarchy,
      ...get(hierarchy).flatMap((h) => this.flatHierarchy(h, get)),
    ];
  }

  /**
   * Create subscription report
   * @param data
   */
  async createSubscriptionReport(data: any): Promise<void> {
    try {
      setReportAdditionalData(data);
      const { code } = await createReportSubscription(data);

      if (code !== 200) {
        return customFailedMessage(this.$t('common.serverError') as string);
      }

      customSuccessMessage(
        this.$t('report.reportHasBeenSubscribedSuccessfully') as string
      );
    } catch (error) {
      console.log(error);
    } finally {
      this.subscribeVisible = false;
    }
  }

  selectedAssetNameChanged(assetName: string) {
    this.selectedAssetName = assetName;
  }
}
</script>

<template>
  <BaseCard
    class="generate-report-page-container"
    v-loading="generateReportTemplatePageIsLoading"
    :backIconVisible="true"
    :title="template?.name"
    :buttons="[]"
  >
    <ViewReportHeader
      class="header"
      @generate-report="setFilters"
      @asset-scope-changed="scope = $event"
      @report-scope-value-changed="setFilters"
      :report-scoped-value-list="scopeOptions"
      @subscribe-modal-visible-event="subscribeVisible = true"
      @selected-asset-name="selectedAssetNameChanged"
      :isGeneratedReportVisible="isGeneratedReportVisible"
      :template="template"
      :scope="filters?.reportScope"
      :scopeValue="filters?.reportScopeValue"
      :startDate="filters?.startDate"
      :endDate="filters?.endDate"
    />
    <ReportView
      v-if="template && isGeneratedReportVisible"
      :template="template"
      :scope="filters?.reportScope"
      :scopeValue="filters?.reportScopeValue"
      :shownValue="
        scopeOptions.find((el) => el.id === filters?.reportScopeValue)?.name
      "
      :start="filters?.startDate"
      :end="filters?.endDate"
      :selectedAssetName="selectedAssetName"
    />
    <div
      class="no-visible-report-container"
      v-if="!generateReportTemplatePageIsLoading && !isGeneratedReportVisible"
    >
      {{ $t('report.useFiltersToGenerateReport') }}
    </div>
    <CreateNewReportSubscribtionModal
      v-if="subscribeVisible"
      :visible.sync="subscribeVisible"
      :template-id="reportTemplateId"
      :organizations="organizations"
      :title="$tc('report.subscribeReport')"
      :isReportTemplateSelected="true"
      @handle-create-subscribed-report="createSubscriptionReport"
    />
  </BaseCard>
</template>

<style scoped lang="scss">
.generate-report-page-container {
  overflow: hidden;
}

.header {
  margin: 1.5rem 1.5rem 0;
}

.right-side-btn {
  height: 40px;
  margin-top: -3px;
}

.no-visible-report-container {
  margin-left: 15px;
  width: auto;
  position: absolute;
  top: 40%;
  padding: 10px;
  font-size: 20px;
  color: rgb(170, 170, 170);
  font-weight: bold;
  opacity: 0.8;
}
</style>
