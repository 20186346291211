<script lang="ts">
import { getRoles, Role } from '@/api/roles';
import {
  copyUserTemplateFromCompany,
  createUser,
  getModules,
  getUserById,
  updateUser,
} from '@/api/users';
import NewCard from '@/components/cusCard/NewCard.vue';
import { PageModule } from '@/store/modules/page';
import { UserModule } from '@/store/modules/user';
import {
  customFailedMessage,
  promptFailedBox,
  promptSuccessBox,
} from '@/utils/prompt';
import { ACTIVATION_STATUS } from '@/utils/workData/lookuptable';
import { UserForm } from '@/views/customerManagement/components/userManagement/UserForm';
import { Tree } from 'element-ui';
import { omit } from 'lodash';
import { Component, Prop, Vue } from 'vue-property-decorator';
import NewUser from './components/NewUser.vue';

type NewUserForm = Omit<
  UserForm,
  'id' | 'tenants' | 'username' | 'emailVerified'
>;

@Component({
  name: 'AddNewUser',
  components: {
    'new-card': NewCard,
    'new-user': NewUser,
    'el-tree': Tree,
  },
})
export default class extends Vue {
  @Prop() userId!: string;

  title: string = '';
  activeName: string = 'first';
  loadingModuleAccess: boolean = false;
  rolesSearchFieldName: string = 'companyType';
  page: number = 1;
  pageSize: number = 1000;

  userForm: NewUserForm = {
    email: '',
    role: '',
    claimsFtd: [''],
    claims: [],
    organizationId: null,
    companyId: UserModule.companyId,
    note: '',
    activationStatus: ACTIVATION_STATUS.Activated,
    i18nCode: '',
  };

  userRoles: Role[] = [];
  modulesInfo: any = [];
  defaultChecked: string[] = [];

  errorInfos = [
    {
      code: '',
      field: '',
      message: '',
    },
  ];

  changeModuleAccess(value: any) {
    let arr: string[] = [];
    if (value) {
      value.forEach((item: any) => {
        arr.push(item.resource);
      });

      this.userForm.claimsFtd = arr;
    } else {
      this.userForm.claimsFtd = [];
    }
  }

  handleInput() {
    this.errorInfos = [];
  }

  async copyUserTemplateFromCompany(userId: string) {
    await copyUserTemplateFromCompany(userId).then((res) => {
      if (res.code === 400) {
        promptFailedBox(this.$t('dashboardConf.templateNotExist') as string);
      }
    });
  }

  async saveNewInfos() {
    this.userForm.i18nCode = UserModule.i18nCode;
    const newValues = omit(this.userForm, 'claims');
    if (this.userId) {
      await updateUser(this.userId, newValues).then((res) => {
        if (!res) {
          customFailedMessage(
            this.$t('common.errorWithFetchingData') as string
          );
          return;
        }

        if (res.code === 200) {
          promptSuccessBox(this.$t('common.save') as string);
          this.errorInfos = [];
          this.$router.push('/administration/index');
          return;
        }

        promptFailedBox(this.$t('common.save') as string);
        this.errorInfos = res.data.errors;
      });
      return;
    }

    await createUser(newValues).then((res) => {
      if (!res) {
        customFailedMessage(this.$t('common.errorWithFetchingData') as string);
        return;
      }

      if (res.code === 200) {
        promptSuccessBox(this.$t('common.create') as string);
        this.errorInfos = [];
        this.$router.push('/administration/index');
        this.copyUserTemplateFromCompany(res.data.id);
        return;
      }

      if (
        res.code === 400 &&
        res.data.errors[0].code === 'ApiErrorMaxUsersExceeded'
      ) {
        customFailedMessage(
          this.$t('userModule.maximumUsersExceeded') as string
        );
        this.errorInfos = res.data.errors;
        return;
      }

      if (
        res.code === 400 &&
        res.data.errors.indexOf('ApiErrorFieldDuplicate')
      ) {
        customFailedMessage(this.$t('userModule.duplicateEmail') as string);
        return;
      }

      promptFailedBox(this.$t('common.creation') as string);
      this.errorInfos = res.data.errors;
    });
  }

  async fetch() {
    await getUserById(this.userId).then((res) => {
      if (!res) {
        customFailedMessage(this.$t('common.errorWithFetchingData') as string);
        return;
      }

      if (res.code === 200) {
        this.userForm = Object.assign({}, this.userForm, res.data);
        PageModule.setTitle(res.data.username);
        let arr: string[] = [];

        this.userForm.claims.forEach((item: any) => {
          arr.push(item.resource);
        });

        this.userForm.claimsFtd = arr;
        return;
      }

      customFailedMessage(this.$t('common.errorWithFetchingData') as string);
    });
  }

  async fetchModules() {
    this.loadingModuleAccess = true;
    await getModules().then((res) => {
      if (!res) {
        customFailedMessage(this.$t('common.errorWithFetchingData') as string);
        return;
      }

      if (res.code === 200) {
        this.modulesInfo = res.data;
        return;
      }

      customFailedMessage(this.$t('common.errorWithFetchingData') as string);
    });

    this.loadingModuleAccess = false;
  }

  async fetchRoles() {
    let finalUrl = `?searchFieldName=${this.rolesSearchFieldName}&searchFieldValues=${UserModule.companyType}&page=${this.page}&size=${this.pageSize}`;
    await getRoles(finalUrl).then((res) => {
      if (!res) {
        customFailedMessage(this.$t('common.errorWithFetchingData') as string);
        return;
      }

      if (res.code === 200) {
        this.userRoles = res.data.roles;
        return;
      }

      customFailedMessage(this.$t('common.errorWithFetchingData') as string);
    });
  }

  created() {
    this.fetchRoles();
    this.fetchModules();

    this.userId && this.fetch();
  }
}
</script>

<template>
  <new-card :createdOrEdit="false">
    <new-user
      v-loading="loadingModuleAccess"
      ref="newUser"
      :userForm="userForm"
      :roles="userRoles"
      :userId="userId"
      :authorizableResources="modulesInfo"
      :errorInfos="errorInfos"
      @handle-input="handleInput"
      @change-role="changeModuleAccess"
      @handle-saveInfos="saveNewInfos"
    />
  </new-card>
</template>

<style lang="scss" scoped></style>
