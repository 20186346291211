<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';

import { createGeofence, LifeCycle } from '@/api/geofence';
import {
  GeofencePosition,
  GeofenceTrackingStrategy,
  TrackingDays,
} from '@/api/geofenceTypes';
import WidgetCard from '@/components/layout/widget/WidgetCard.vue';
import LeafletMap from '@/components/leafletMap/LeafletMap.vue';
import { sortWeekDays } from '@/utils';
import { customFailedMessage, customSuccessMessage } from '@/utils/prompt';
import { formValidateArrayLength } from '@/utils/validate';
import Treeselect from '@riophae/vue-treeselect';
import GeofenceForm, { GeofenceFormModel } from './components/GeofenceForm.vue';

interface CreateRequestBody {
  name: string;
  organizationId: string;
  geofencePosition: GeofencePosition[];
  associatedAssetTypes: string[];
  geofenceType: string;
  tracking: string;
  trackingStartTime?: string;
  trackingEndTime?: string;
  trackingDays?: TrackingDays[];
}

@Component({
  name: 'CreateNewGeofence',
  components: {
    LeafletMap,
    Treeselect,
    WidgetCard,
    GeofenceForm,
  },
})
export default class extends Vue {
  geofenceForm: GeofenceFormModel = {
    name: '',
    organizationId: undefined,
    geofenceType: '',
    associatedAssetTypes: [],
    tracking: undefined,
    trackingTime: undefined,
    trackingStartTime: undefined,
    trackingEndTime: undefined,
    trackingDays: [],
    lifeCycle: LifeCycle.Draft,
  };
  allowTracking: boolean = false;

  geofenceConfig = {
    enableGeofence: true,
    enableCreate: true,
    editOnly: false,
  };
  center: number[] = [46.771211, 23.623634];
  zoom: number = 10;
  geofencePosition: GeofencePosition[] = [];

  createNewGeofencePageIsLoading: boolean = false;
  rules = {
    name: [
      {
        required: true,
        message: this.$t('geofence.ApiErrorFieldInvalid.ApiFieldName'),
        trigger: 'change',
      },
    ],
    organizationId: [
      {
        required: true,
        message: this.$t(
          'geofence.ApiErrorFieldRequired.ApiFieldOrganizationId'
        ),
        trigger: 'blur',
      },
    ],
    geofenceType: [
      {
        required: true,
        message: this.$t('geofence.ApiErrorFieldInvalid.ApiFieldGeofenceType'),
        trigger: 'blur',
      },
    ],
    associatedAssetTypes: [
      {
        validator: (rule: any, value: any[] | undefined, callback: Function) =>
          formValidateArrayLength(
            value,
            callback,
            this.$t(
              'geofence.ApiErrorFieldRequired.ApiFieldAssociatedAssetTypes'
            ),
            1
          ),
        trigger: 'change',
      },
    ],
  };

  $refs!: {
    map: LeafletMap;
  };

  mounted() {
    this.$refs.map.initializeGeofence([]);
  }

  getGeoCoord(): void {
    this.geofencePosition = this.$refs.map.getGeofenceCoordinates();
  }

  /**
   * Calculate tracking options
   */
  decideTracking(data: GeofenceFormModel): GeofenceFormModel {
    const modifiedData = { ...data };

    if (!this.allowTracking) {
      // remove all attributes because there is no tracking
      modifiedData.trackingStartTime = '';
      modifiedData.trackingEndTime = '';
      modifiedData.trackingDays = [];
      modifiedData.tracking = GeofenceTrackingStrategy.Off;
    } else if (modifiedData.tracking === GeofenceTrackingStrategy.Always) {
      modifiedData.trackingStartTime = '';
      modifiedData.trackingEndTime = '';
      modifiedData.trackingDays = [];
    } else if (modifiedData.tracking === GeofenceTrackingStrategy.TimePeriod) {
      if (modifiedData.trackingTime === GeofenceTrackingStrategy.AllDays) {
        modifiedData.trackingStartTime = '';
        modifiedData.trackingEndTime = '';
      }
      modifiedData.tracking = modifiedData.trackingTime;
    }

    return modifiedData;
  }

  getRequestBody(): CreateRequestBody {
    this.getGeoCoord();
    const finalRequestModel = this.decideTracking(this.geofenceForm);
    const requestBody: CreateRequestBody = {
      name: finalRequestModel.name,
      organizationId: finalRequestModel.organizationId!,
      geofencePosition: this.geofencePosition,
      associatedAssetTypes: finalRequestModel.associatedAssetTypes,
      geofenceType: finalRequestModel.geofenceType,
      tracking: finalRequestModel.tracking ?? '',
      trackingStartTime: finalRequestModel.trackingStartTime,
      trackingEndTime: finalRequestModel.trackingEndTime,
      trackingDays: sortWeekDays(finalRequestModel.trackingDays ?? []),
    };

    return requestBody;
  }

  async postGeofence(): Promise<void> {
    try {
      this.createNewGeofencePageIsLoading = true;
      const requestBody = this.getRequestBody();
      const res = await createGeofence(requestBody);

      if (!res) {
        customFailedMessage(this.$t('common.errorWithFetchingData') as string);
        return;
      }

      if (!res.data.id) {
        customFailedMessage(this.$t('geofence.createError') as string);
        return;
      }

      customSuccessMessage(this.$t('geofence.create') as string);
      this.$router.push(
        `/administration/index?activeName=geofences&geofence=${res.data.id}`
      );
    } catch (err: any) {
      console.error(err);
      throw new Error(err);
    } finally {
      this.createNewGeofencePageIsLoading = false;
    }
  }
}
</script>

<template>
  <WidgetCard
    class="card"
    :loading="createNewGeofencePageIsLoading"
    :defaultTitle="$t('route.administration.addNewGeofence')"
    :showBackButton="true"
    :floatActionsRight="true"
  >
    <template #actions>
      <el-button type="info" @click="postGeofence">
        {{ $t('geofence.createGeofenceButton') }}
      </el-button>
    </template>

    <div class="container">
      <div class="map">
        <LeafletMap
          ref="map"
          :center="center"
          :zoom="zoom"
          :geofenceConfig="geofenceConfig"
        />
      </div>

      <div class="fields">
        <GeofenceForm
          :formModel="geofenceForm"
          :formRules="rules"
          :allowTracking="allowTracking"
          @toggleAllowTracking="allowTracking = !allowTracking"
        />
      </div>
    </div>
  </WidgetCard>
</template>

<style lang="scss" scoped>
.card {
  height: 100%;
  display: flex !important;
  flex-direction: column;
}

.container {
  height: 100%;
  display: flex;
  gap: 24px;
}

.map {
  flex-basis: 60%;
}

.fields {
  margin-top: 32px;
  margin-right: 24px;
  flex-basis: 40%;
}
</style>
