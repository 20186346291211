<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import NavPage from '@/components/nav/NavPage.vue';
import { PROD_CATA_APPROVE_LIST } from '@/utils/workData/prodCata';
import PartsSelect from '../components/PartsSelect.vue';
import PartsTable from '../components/PartsTable.vue';
import { customSuccessMessage, customFailedMessage } from '@/utils/prompt';
import { PROD_ERROR_CODE_LIST } from '@/utils/workData/lookuptable';
import {
  getFilterPartMaterials,
  batchApprovePart,
  PartMaterial,
} from '@/api/products';
import { UserModule } from '@/store/modules/user';
import { ErrorType } from '@/api/types';

@Component({
  name: 'BatchApprove',
  components: {
    NavPage,
    PartsSelect,
    PartsTable,
  },
})
export default class extends Vue {
  /** Local variables */
  cols = PROD_CATA_APPROVE_LIST;
  tableList: PartMaterial[] = [];
  total: number = 0;
  chosenItems = 0;
  page: string = '1';
  pageSize: string = UserModule.gridPageSize.toString();
  selectForm = {
    partNameorId: '',
    partType: '',
    lifeCycle: 'LCL_DRAFT',
  };
  errorCode = PROD_ERROR_CODE_LIST;
  errorInfos: ErrorType[] = [
    {
      code: '',
      field: '',
      message: '',
    },
  ];
  sortAndOrderData: any = {
    sortBy: null,
    order: null,
  };
  tableIsLoading: boolean = false;

  created() {
    this.fetchPartsWarehouse();
  }

  get errNameInfo() {
    let errInfo: string = '';
    if (
      this.errorInfos.find((item) => item.field === this.errorCode.unknown)
        ?.code === 'ApiErrorFieldInvalid'
    ) {
      errInfo = `${this.$t('prodCata.approveWrongLife')}`;
    }
    return errInfo === '' ? this.errorInfos[0].message : errInfo;
  }

  updateChosenItem(count: number) {
    this.chosenItems = count;
  }

  //get partWarehouse Data
  /**
   * Get warehouse parts by draft status
   */
  async fetchPartsWarehouse() {
    try {
      this.tableIsLoading = true;
      const requestUrl = this.generateRequestUrlWithParams(
        this.page,
        this.pageSize,
        this.selectForm,
        this.sortAndOrderData
      );
      const res = await getFilterPartMaterials(requestUrl);
      this.total = res.data.length;
      this.tableList = res.data;
    } catch (error) {
      console.log(error);
    } finally {
      this.tableIsLoading = false;
    }
  }

  getFilterPartsWarehouse(sendData: any) {
    this.selectForm.partNameorId = sendData.partNameorId;
    this.selectForm.partType = sendData.partType;
    this.selectForm.lifeCycle = sendData.lifeCycle;
    this.fetchPartsWarehouse();
  }

  /**
   * Generate request URL by multiple factors
   * Mandatory to filter by lifeCycle of type LCL_DRAFT to retrive items that are available for approval
   * @param pageNumber
   * @param pageSize
   * @param selectForm
   * @param sortAndOrderData
   */
  generateRequestUrlWithParams(
    pageNumber: string,
    pageSize: string,
    selectForm: any,
    sortAndOrderData: any
  ): string {
    let finalUrl = '';
    selectForm.lifeCycle
      ? (finalUrl += `&lifeCycle=${selectForm.lifeCycle}`)
      : (finalUrl += '&lifeCycle=LCL_DRAFT');
    if (selectForm.partType) finalUrl += `&partType=${selectForm.partType}`;
    if (selectForm.partNameorId)
      finalUrl += `&nameOrNumber=${selectForm.partNameorId}`;
    pageNumber
      ? (finalUrl += `&page=${pageNumber}`)
      : (finalUrl += `?page=${1}`);
    pageNumber
      ? (finalUrl += `&size=${pageSize}`)
      : (finalUrl += `&size=${this.pageSize}`);
    if (sortAndOrderData.sortBy && sortAndOrderData.order)
      finalUrl += `&sortBy=${sortAndOrderData.sortBy}&order=${sortAndOrderData.order}`;
    if (finalUrl) finalUrl = '?' + finalUrl.slice(1);
    return finalUrl;
  }

  /**
   * Approve batch
   */
  async batchApprove() {
    let chosenItemId: any = [];
    this.tableList.forEach((item: any) => {
      if (item.chosen === true) {
        chosenItemId.push(item.id);
      } else return;
    });
    const res = await batchApprovePart({ partMaterialIds: chosenItemId });
    if (res.code === 200) {
      customSuccessMessage(this.$t('prodCata.batchApprovedSuccess') as string);
      this.errorInfos = [];
      this.fetchPartsWarehouse();
      return;
    }
    // customFailedMessage(this.$t('common.batchApprove') as string);
    // this.errorInfos = res.data.errors[0].message;
    this.errorInfos = [...res.data.errors[0]];
    customFailedMessage(this.errNameInfo as any);
  }
}
</script>

<template>
  <NavPage
    :arrowBack="false"
    :visible="true"
    @arrow-click="$router.push('/prod-cata/index?activeName=partsWarehouse')"
  >
    <template #button>
      <el-button
        id="prod_cata_batch_approve_button"
        type="plain"
        :disabled="chosenItems === 0"
        @click="batchApprove"
      >
        <!-- <i class="el-icon-plus common-icon" /> -->
        {{ $t('prodCata.approve') }}
      </el-button>
    </template>
    <div style="padding: 12px 40px 30px">
      <p class="total_items">
        {{ $t('prodCata.totalToBeApproved') }}:
        <span>{{ chosenItems }}</span>
      </p>
      <PartsSelect
        id="prod_cata_batch_filter"
        @send-filter-data="getFilterPartsWarehouse"
        :showLifeCycleSelect="false"
      />
      <div style="margin-top: 20px">
        <PartsTable
          id="parts_warehouse_table"
          v-loading="tableIsLoading"
          :tableList="tableList"
          :cols="cols"
          :total="total"
          @count-chosen="updateChosenItem"
        />
      </div>
    </div>
  </NavPage>
</template>

<style lang="scss" scoped>
.total_items {
  font-size: 16px;
  font-family: $font-Roboto-Bold;
  color: #373e41;
  line-height: 28px;
  margin-top: 0 span {
    font-size: 24px;
    line-height: 28px;
    opacity: 0.8;
    vertical-align: bottom;
  }
}
</style>
