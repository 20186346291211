<script lang="ts">
import NavPage from '@/components/nav/NavPage.vue';
import { UserModule } from '@/store/modules/user';
import { ALL_CLAIMS_CODES } from '@/utils/workData/lookuptable';
import ReportTemplate from '@/views/report/reportTemplate/ReportTemplate.vue';
import SubscribedReport from '@/views/report/subscribedReport/SubscribedReport.vue';
import { Component, Vue } from 'vue-property-decorator';

@Component({
  name: 'report',
  components: {
    NavPage,
    'report-template': ReportTemplate,
    'subscribed-report': SubscribedReport,
  },
})
export default class extends Vue {
  activeName: string = 'subscribedReport';

  hasSubscribedReportsAccess: boolean = false;
  hasReportTemplatesAccess: boolean = false;

  handleTabClick() {
    if (
      this.activeName === 'subscribedReport' &&
      this.$route.query.activeName !== 'subscribedReport' &&
      this.hasSubscribedReportsAccess
    ) {
      this.$router.push('/report/index?activeName=subscribedReport');
    } else if (
      this.activeName === 'reportTemplate' &&
      this.$route.query.activeName !== 'reportTemplate' &&
      this.hasReportTemplatesAccess
    ) {
      this.$router.push('/report/index?activeName=reportTemplate');
    }
  }

  showTabs() {
    if (this.$route.query.activeName) {
      this.activeName = this.$route.query.activeName as string;
    } else {
      this.activeName = this.hasSubscribedReportsAccess
        ? 'subscribedReport'
        : 'reportTemplate';
      this.handleTabClick();
    }
  }

  async mounted() {
    this.hasSubscribedReportsAccess = await UserModule.hasMenu(
      ALL_CLAIMS_CODES.AUTHRSC_PAGE_SUBSCRIBED_REPORTS
    );
    this.hasReportTemplatesAccess = await UserModule.hasMenu(
      ALL_CLAIMS_CODES.AUTHRSC_PAGE_REPORT_TEMPLATES
    );

    this.showTabs();
  }
}
</script>

<template>
  <NavPage :visible="false" :fitToParent="true">
    <div class="report-container">
      <el-tabs
        v-model="activeName"
        @tab-click="handleTabClick"
        style="height: 100%; display: flex; flex-direction: column"
      >
        <el-tab-pane
          :label="$t('report.subscribedReport')"
          name="subscribedReport"
          style="height: 100%"
          v-if="hasSubscribedReportsAccess"
        >
          <subscribed-report
            style="
              height: 100%;
              width: 100%;
              display: flex;
              flex-direction: column;
            "
            v-if="activeName === 'subscribedReport'"
          />
        </el-tab-pane>
        <el-tab-pane
          :label="$t('report.reportTemplates')"
          name="reportTemplate"
          style="height: 100%"
          v-if="hasReportTemplatesAccess"
        >
          <report-template
            style="height: 100%; display: flex; flex-direction: column"
            v-if="activeName === 'reportTemplate'"
          />
        </el-tab-pane>
      </el-tabs>
    </div>
  </NavPage>
</template>

<style lang="scss" scoped>
.report-container {
  padding: 0px 20px;
  height: 100%;
}
</style>
