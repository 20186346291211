<script lang="ts" setup>
import Vue from 'vue';

const icpNumber = Vue.prototype.$envConfig.ICP_NUMBER;
const icpUrl = Vue.prototype.$envConfig.ICP_URL;

function redirect() {
  window.location.href = icpUrl;
}
</script>

<template>
  <div class="number-holder">
    <div v-if="icpNumber" class="number" v-on:click="redirect">
      {{ icpNumber }}
    </div>
  </div>
</template>

<style lang="scss" scoped>
.number-holder {
  height: 100%;
  display: flex;
}

.number {
  text-align: center;
  width: 100%;
  margin-top: auto;
  margin-bottom: auto;
  cursor: pointer;
}
</style>
