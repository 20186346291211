import {
  ASDS_STATUS,
  ASDC_STATUS,
  ASDDS_STATUS,
} from '@/utils/workData/lookuptable';
import { AssetDeploymentLog } from '@/api/deploymentDetails';

export const assetLifecyclesWhenAssetDeploymentCanBeCancelled: string[] = [
  ASDS_STATUS.WAITING,
  ASDS_STATUS.POSTPONED,
  ASDS_STATUS.ON_HOLD,
];

export const assetsControlStatusesWhenAssetDeploymentCanBeCancelled: string[] =
  [ASDC_STATUS.DEPLOY, ASDC_STATUS.ON_HOLD];

/** Conditions for showing the asset deployment hold button */
export const statesWhenAssetDeploymentCanBeHold: string[] = [
  ASDS_STATUS.WAITING,
  ASDS_STATUS.POSTPONED,
];

export const assetsControlStatusesWhenAssetDeploymentCanBeHold: string[] = [
  ASDC_STATUS.DEPLOY,
];

/** Conditions for showing the asset deployment resume button */
export const statesWhenAssetDeploymentCanBeResume: string[] = [
  ASDS_STATUS.WAITING,
  ASDS_STATUS.POSTPONED,
  ASDS_STATUS.ON_HOLD,
];

export const assetsControlStatusesWhenAssetDeploymentCanBeResume: string[] = [
  ASDC_STATUS.ON_HOLD,
];

export function calculateAssetDeploymentStatus(
  item: AssetDeploymentLog
): string {
  if (
    assetLifecyclesWhenAssetDeploymentCanBeCancelled.includes(
      item.assetDeployStatus
    ) &&
    item.deploymentControl === ASDC_STATUS.CANCEL
  ) {
    return ASDDS_STATUS.CANCELLING;
  }
  if (
    !assetLifecyclesWhenAssetDeploymentCanBeCancelled.includes(
      item.assetDeployStatus
    ) &&
    item.deploymentControl === ASDC_STATUS.CANCEL
  ) {
    return retrieveEnumValue(item.assetDeployStatus);
  }
  if (item.deploymentControl === ASDC_STATUS.DEPLOY) {
    return retrieveEnumValue(item.assetDeployStatus);
  }
  if (
    statesWhenAssetDeploymentCanBeHold.includes(item.assetDeployStatus) &&
    item.deploymentControl === ASDC_STATUS.ON_HOLD
  ) {
    return ASDDS_STATUS.HOLDING;
  }
  if (
    !statesWhenAssetDeploymentCanBeHold.includes(item.assetDeployStatus) &&
    item.deploymentControl === ASDC_STATUS.ON_HOLD
  ) {
    return retrieveEnumValue(item.assetDeployStatus);
  }
  return '';
}

/**
 * Get enum value from ASDDS_STATUS enum
 * @param assetDeployStatus
 */
export function retrieveEnumValue(assetDeployStatus: string): string {
  /** Eliminate prefix ASDS_ and search same status from ASDDS enum */
  const assetDeploymentStatus: string = assetDeployStatus
    ?.substring(5, 20)
    .trim();
  return ASDDS_STATUS[
    Object.keys(ASDDS_STATUS).find(
      (key: string) => key === assetDeploymentStatus
    ) as keyof typeof ASDDS_STATUS
  ];
}
