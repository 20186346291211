export function parseNumberOrUndefined(value: any): number | undefined {
  return isProperNumber(value) ? Number(value) : undefined;
}

export function isProperNumber(value: any): value is number | string {
  return (
    isFinite(value) && value !== undefined && value !== null && value !== ''
  );
}

export function formatNumber(
  value: number | undefined,
  digits: number = 2,
  nonProperNumberReturn: string = '--'
): string {
  if (!isProperNumber(value)) {
    return nonProperNumberReturn;
  }
  return `${value.toFixed(digits)}`;
}

export function isStringNumberZero(value: string): boolean {
  const zeroPattern = /^(-|\+)?0(\.[0]+)?$/;

  return zeroPattern.test(value);
}
