<script lang="ts" setup>
import { getWidgetsByPageCode, IncludedWidget } from '@/api/widgets';
import { useLoggedInUser } from '@/auth/user';
import EditGridDialog from '@/components/layout/widget/EditWidgetGridDialog.vue';
import WidgetWrapper from '@/components/layout/widget/WidgetWrapper.vue';
import { useAsync } from '@/composables/async';
import { useRoute } from '@/composables/router';
import { Breakpoint, useMaxWindowSize } from '@/composables/window';
import {
  filterWidgetsByPermissions,
  generateRowSizeTypes,
  NUMBER_OF_GRID_COLUMNS,
  RowSizeType,
} from '@/utils/widget';
import { WidgetKey } from '@/utils/workData/widgetCodeMap';
import { computed, ref, unref, watch } from 'vue';

const props = defineProps<{
  pageCode: string;
  columns: number;
  rowHeight: string;
  editGridDialogVisible: boolean;
}>();
const emit = defineEmits<{
  (e: 'expand-widget', widget: IncludedWidget | undefined): void;
  (e: 'close-edit-grid-dialog'): void;
}>();

const loggedInUser = useLoggedInUser();
const widgets = useAsync(
  computed(async () => {
    const response = await getWidgetsByPageCode(
      props.pageCode,
      unref(loggedInUser)
    );
    const widgets = response.data;
    const filteredWidgets = filterWidgetsByPermissions(widgets);
    changeSelectedWidget(filteredWidgets[0].code);

    return filteredWidgets;
  })
);

const style = computed(() => {
  if (!widgets.value.data) {
    return;
  }

  const rowSizeTypes = generateRowSizeTypes(widgets.value.data);
  const fixedHeight = props.rowHeight ?? '15rem';
  const rowSizes = rowSizeTypes.map((type) =>
    type === RowSizeType.Dynamic ? 'auto' : fixedHeight
  );
  return {
    gridTemplateColumns: `repeat(${NUMBER_OF_GRID_COLUMNS}, minmax(0, 1fr))`,
    gridTemplateRows: rowSizes.join(' '),
  };
});

const route = useRoute();

watch(
  [route, widgets],
  ([nextRoute, nextWidgets]) => {
    const { widget: code } = nextRoute.query;
    const widget = nextWidgets.data?.find((w) => w.code === code);
    emit('expand-widget', widget);
  },
  { immediate: true }
);

const isScreenSizeSmall = useMaxWindowSize(Breakpoint.Small);

const selectedWidgetCode = ref<WidgetKey>();
const selectedWidget = computed((): IncludedWidget | undefined => {
  return widgets.value.data?.find(
    (widget) => widget.code === selectedWidgetCode.value
  );
});

function changeSelectedWidget(widgetCode: WidgetKey) {
  selectedWidgetCode.value = widgetCode;
}
</script>

<template>
  <div :class="{ 'full-page': isScreenSizeSmall }">
    <el-select :value="selectedWidgetCode" @change="changeSelectedWidget">
      <el-option
        v-for="widget of widgets.data"
        :key="widget.code"
        :value="widget.code"
        :label="$t(widget.code)"
      />
    </el-select>
    <div
      v-loading="widgets.loading"
      v-if="!isScreenSizeSmall"
      class="grid"
      :style="style"
    >
      <WidgetWrapper
        v-for="widget of widgets.data"
        :key="widget.code"
        :widget="widget"
        :pageCode="props.pageCode"
      />
    </div>
    <div
      class="widget-remaining-space"
      v-else-if="selectedWidget !== undefined"
    >
      <WidgetWrapper
        :key="selectedWidget.code"
        :widget="selectedWidget"
        :pageCode="props.pageCode"
      />
    </div>
    <EditGridDialog
      v-if="!widgets.loading && widgets.data"
      :visible="props.editGridDialogVisible"
      :page-code="props.pageCode"
      :columns="props.columns"
      :widgets="widgets.data"
    />
  </div>
</template>

<style lang="scss" scoped>
.el-select {
  width: 100%;
  margin: 1rem 0;

  @media (min-width: 769px) {
    display: none;
  }

  & :deep(.el-input__inner) {
    color: #ffffff;
    background-color: #373e41;
    font-weight: 500;
    border: none !important;
  }

  & :deep(.el-input__icon) {
    color: white !important;
  }
}

.full-page {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.widget-remaining-space {
  flex-grow: 1;
  display: flex;
}

.grid {
  display: flex;
  flex-direction: column;
  min-height: calc(100% - 3rem);

  @media (min-width: 769px) {
    display: grid;
    grid-gap: 1rem;
  }
}
</style>
