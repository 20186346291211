import { ref, watchEffect } from 'vue';

export function useMediaQuery(query: string) {
  const matches = ref(true);

  watchEffect((onInvalidate) => {
    const media = window.matchMedia(query);

    matches.value = media.matches;

    function onChange() {
      matches.value = media.matches;
    }

    media.addEventListener('change', onChange);

    onInvalidate(() => media.removeEventListener('change', onChange));
  });

  return matches;
}
