<script lang="ts">
import TripTimeline from '@/components/trip/TripTimeline.vue';
import FancyInfo from '@/views/assets/components/FancyInfo.vue';
import moment from 'moment';

import { Component, Inject, Vue } from 'vue-property-decorator';

import { getTrips, TripResponse } from '@/api/trip';
import { ActiveContext, useActiveContext } from '@/auth/context';
import WidgetCard from '@/components/layout/widget/WidgetCard.vue';
import TripLegend from '@/components/trip/TripLegend.vue';
import {
  useUnitConversion,
  UseUnitConversionReturnType,
} from '@/composables/conversion';
import {
  FilterOperator,
  SorterOrder,
} from '@/model/queryParameters/QueryParameter';
import { AssetType } from '@/utils/assetTypes';
import { formatUnitValue, UndefinedRendering } from '@/utils/format';
import { formatTimer } from '@/utils/misc';
import { formatDuration, formatTime, LOCALDATE_FORMAT } from '@/utils/time';
import { convertUnitValue } from '@/utils/units/conversion';
import { KPI_UNIT } from '@/utils/units/unitDefinitions';
import { toUnitValue } from '@/utils/units/unitValue';
import { InitializeReactive } from '@/utils/vueClassComponentHelpers';
import { TripStatus, TripSubActivity } from '@/utils/workData/lookuptable';
import { Ref, unref } from 'vue';

@Component({
  name: 'AssetTripLog',
  components: {
    WidgetCard,
    TripLegend,
    FancyInfo,
    TripTimeline,
  },
})
export default class extends Vue {
  @Inject() context!: Ref<ActiveContext>;

  @InitializeReactive
  trip?: TripResponse;
  isTripLoading: boolean = false;

  unitConversion!: UseUnitConversionReturnType;

  get properties(): Record<
    string,
    { value: string | number | boolean; unit: string }
  > {
    if (!this.trip || !this.trip.propertyDetails || !this.trip.propertyValues) {
      return {};
    }

    return this.trip.propertyDetails.reduce((acc, cur) => {
      const value =
        this.trip!.propertyValues[
          cur.code as keyof typeof this.trip.propertyValues
        ];
      if (value === null || value === undefined) {
        return acc;
      }

      return {
        ...acc,
        [cur.code]: {
          value: value,
          unit: cur.unit,
        },
      };
    }, {});
  }

  get start() {
    return formatTimer(moment(this.trip?.startPoint.timestamp), 'datetime');
  }

  get end() {
    return formatTimer(moment(this.trip?.endPoint.timestamp), 'datetime');
  }

  get time() {
    if (!this.trip) {
      return;
    }

    return formatDuration(
      this.trip.endPoint.timestamp,
      this.trip.startPoint.timestamp
    );
  }

  get tippingTime() {
    if (!this.trip) {
      return;
    }

    const activities = [...this.trip.tripSubActivities].sort((a, b) => {
      return (
        new Date(a.subActivityStartTimeStamp).getTime() -
        new Date(b.subActivityStartTimeStamp).getTime()
      );
    });

    const time = activities.reduce((acc, cur, i, arr) => {
      if (cur.subActivityKind !== TripSubActivity.Tipping) {
        return acc;
      }

      const next = arr[i + 1];
      const end = moment(
        next?.subActivityStartTimeStamp ?? this.trip?.endPoint.timestamp
      );
      const duration = moment.duration(end.diff(cur.subActivityStartTimeStamp));

      return acc + duration.asSeconds();
    }, 0);

    if (!time) {
      return;
    }

    return formatTime(time);
  }

  get timelineTrip() {
    if (!this.trip) {
      return;
    }

    return {
      startTime: new Date(this.trip.startPoint.timestamp),
      endTime: new Date(this.trip.endPoint.timestamp),
      summary: this.trip.tripSubActivities,
      events: this.trip.events,
    };
  }

  /**
   * View trip log more assets details by redirecting on trip live that will query trips only from last trip end date and -7 days as range
   */
  viewDetails(): void {
    if (this.trip) {
      let finalRoute = `/trip/index?assetType=${this.trip.assetType}&asset=${this.trip.companyAssetId}`;
      const tripEndDate = moment(this.trip.endPoint.timestamp).format(
        LOCALDATE_FORMAT
      );
      if (tripEndDate) finalRoute += `&tripEndDate=${tripEndDate}`;
      this.$router.push(finalRoute);
    }
  }

  created() {
    this.context = useActiveContext();
    this.unitConversion = useUnitConversion();
    this.getData();
  }

  async getData() {
    try {
      this.isTripLoading = true;
      const {
        data: { trips },
      } = await this.getTrips();

      this.trip = trips[0];
    } catch (error) {
      console.error(error);
    } finally {
      this.isTripLoading = false;
    }
  }

  getTrips() {
    return getTrips(
      {
        filters: [
          {
            name: 'assetId',
            operator: FilterOperator.IN,
            value: [this.$route.params.id],
          },
          {
            name: 'tripStatus',
            operator: FilterOperator.EQUAL,
            value: [TripStatus.Completed],
          },
        ],
        sorters: [
          {
            field: 'startPoint.timestamp',
            order: SorterOrder.DESC,
          },
        ],
        pagination: {
          page: 1,
          size: 1,
        },
      },
      unref(this.context)
    );
  }

  getConvertedProperty(): string {
    const property =
      this.trip?.assetType! === AssetType.TippingVehicle
        ? 'OPER.TippedPayload'
        : 'TRIP.Payload';
    const prop = this.properties[property];

    if (!prop) {
      return this.$t('common.noData') as string;
    }

    // Property values are currently returned as strings from the backend, even if they are numbers.
    // So transparently parse them back into a number here for now.
    if (typeof prop.value !== 'number' && typeof prop.value !== 'string') {
      throw new Error(`invalid value for property '${property}'`);
    }

    const beValue = toUnitValue(
      typeof prop.value === 'number' ? prop.value : parseFloat(prop.value),
      prop.unit as KPI_UNIT
    );
    // The backend typically sends the payload in kg, but we need to show it in ton (metric or short)
    const tonnes = convertUnitValue(beValue, KPI_UNIT.MetricTonne);
    const userValue = this.unitConversion.currentUserConvertUnitValue(tonnes);
    return formatUnitValue(userValue, {
      numberOfDecimals: 2,
      undefinedAs: UndefinedRendering.Empty,
    });
  }
}
</script>

<template>
  <WidgetCard
    :loading="isTripLoading"
    :action-title="$t('assets.more')"
    @click="viewDetails"
  >
    <div v-if="!trip && !isTripLoading" class="no-completed-trips">
      {{ $t('assets.noCompletedTrips') }}
    </div>
    <div v-else class="content">
      <span class="heading">{{ $t('lastTrip') }}</span>
      <FancyInfo class="field-item" name="assets.tripStart" :value="start" />
      <FancyInfo class="field-item" name="assets.tripEnd" :value="end" />
      <FancyInfo class="field-item" name="assets.tripTime" :value="time" />
      <FancyInfo
        class="field-item"
        name="assets.tripPayload"
        :value="getConvertedProperty()"
      />
      <FancyInfo
        class="field-item"
        v-if="tippingTime"
        name="assets.tripTippingTime"
        :value="tippingTime"
      />
      <TripLegend class="trip-legend" v-if="trip" :type="trip.assetType" />
      <div class="trip-timeline" v-if="timelineTrip">
        <TripTimeline :trip="timelineTrip" />
      </div>
    </div>
  </WidgetCard>
</template>

<style lang="scss" scoped>
.content {
  height: 100%;
  padding: 20px;
}

.heading {
  display: block;
  padding-bottom: 14px;
  font-size: 18px;
  font-weight: bold;
  opacity: 0.75;
}

.svg-icon {
  cursor: pointer;
}

.field-item {
  margin-top: 20px;
}

.trip-legend {
  margin-top: 30px;
}

.trip-timeline {
  margin-top: 30px;
}

.no-completed-trips {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
