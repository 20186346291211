import { ALL_CLAIMS_CODES } from '@/utils/workData/lookuptable';
import { RouteConfig } from 'vue-router';

const subsMgmtRoutes: RouteConfig = {
  path: '/subscription-management',
  component: () =>
    import(/* webpackChunkName: "layout" */ '@/components/layout/Layout.vue'),
  redirect: '/subscription-management/index',
  meta: {
    code: ALL_CLAIMS_CODES.AUTHRSC_MOD_SUBMNG,
  },
  children: [
    {
      path: 'index',
      component: () =>
        import(
          /* webpackChunkName: "subscriptionManagement" */ '@/views/subscriptionManagement/SubscriptionManagement.vue'
        ),
      name: 'subsMgmt',
      meta: {
        title: 'route.subsMgmt',
        icon: 'subsMgmt',
        claim: ALL_CLAIMS_CODES.AUTHRSC_PAGE_SUBMNG,
        code: ALL_CLAIMS_CODES.AUTHRSC_MOD_SUBMNG,
      },
    },
  ],
};

export default subsMgmtRoutes;
