export const dateFormat = 'YYYY/MM/DD';
import {
  getCompanySubscriptions,
  isAssetTypeSubscription,
} from '@/api/subscriptionPackages';
import { UserModule } from '@/store/modules/user';
import moment from 'moment';

export const reportTemplateName = 'reportTemplateName';

const organizationId = 'organizationId';
const lastTimeSent = 'lastTimeSent';

export const setSubscriptionDatesToUTC = function (data: any) {
  data.startDate = moment(data.startDate).utc(true).toDate();
  data.endDate = moment(data.endDate).utc(true).toDate();
};

export const setOrganizationId = function (data: any) {
  if (!data[organizationId]) {
    data[organizationId] = UserModule.organizationId;
  }
};

export const setReportAdditionalData = function (data: any) {
  setSubscriptionDatesToUTC(data);
  setOrganizationId(data);
};

/**
 * Fetch company subscriptions by logged in user company id
 * filter only subscription of type asset and return asset type code
 * e.g.: ["ASSTYP_", ....]
 * used for filters on subscribed reports list, report templates list and create new report template
 */
export async function getAssetTypesFromCompanySubscription(): Promise<
  string[] | undefined
> {
  try {
    const response = await getCompanySubscriptions(UserModule.companyId);
    return (
      response.data
        .filter(isAssetTypeSubscription)
        .map((subscription) => subscription.subscriptionPackageAssetType) ?? []
    );
  } catch (error) {
    console.log(error);
  } finally {
  }
}
