import { MaintStatus } from '@/api/maintenance';
import { MutableRef } from '@/utils/ref';
import { InjectionKey, inject, provide, ref } from 'vue';

export type SelectedMaintenanceStatuses = Set<MaintStatus>;

export interface MutableMaintenanceStatusRef<T> extends MutableRef<T> {
  isClickable: boolean;
}

export const selectedMaintenanceStatusesKey: InjectionKey<
  MutableMaintenanceStatusRef<SelectedMaintenanceStatuses>
> = Symbol('selectedMaintenanceStatuses');

/**
 * Obtain reference to the selected maintenance statuses.
 */
export function useSelectedMaintenanceStatuses(): MutableMaintenanceStatusRef<SelectedMaintenanceStatuses> {
  const selectedMaintenanceStatuses = inject(selectedMaintenanceStatusesKey);
  if (!selectedMaintenanceStatuses) {
    throw new Error(
      'No selectedMaintenanceStatuses provided, use `provideselectedMaintenanceStatuses()` somewhere near the root of the app'
    );
  }
  return selectedMaintenanceStatuses;
}

export const DEFAULT_STATUSES = new Set([
  MaintStatus.OK,
  MaintStatus.CLOSE,
  MaintStatus.DUE,
]);

export function provideSelectedMaintenanceStatuses(
  initialStatuses: SelectedMaintenanceStatuses = DEFAULT_STATUSES,
  isClickable: boolean = true
): void {
  const selectedMaintenanceStatuses = ref<SelectedMaintenanceStatuses>(
    new Set(initialStatuses) // explicit clone, to ensure no-one accidentally mutates the defaults
  );

  provide(selectedMaintenanceStatusesKey, {
    ref: selectedMaintenanceStatuses,
    update: (value) => (selectedMaintenanceStatuses.value = value),
    isClickable: isClickable,
  });
}
