<script lang="ts" setup>
import { getAllGeofencesByOrganisationId, LifeCycle } from '@/api/geofence';
import { Geofence } from '@/api/geofenceTypes';
import { getTripByUUID, TripByUUIDResponse } from '@/api/trip';
import { useActiveContext } from '@/auth/context';
import { useLoggedInUser } from '@/auth/user';
import BaseCard from '@/components/cusCard/BaseCard.vue';
import TripPlayer from '@/components/trip/TripPlayer.vue';
import { useAsync } from '@/composables/async';
import { useRoute } from '@/composables/router';
import i18n from '@/lang';
import { UserModule } from '@/store/modules/user';
import {
  FormatSetting,
  formatUnitValue,
  UndefinedRendering,
} from '@/utils/format';
import { formatTimer } from '@/utils/misc';
import { never } from '@/utils/promise';
import { capitalizeFirstLetterOfTheWorldsInString } from '@/utils/string';
import { calculateElapsedTime } from '@/utils/time';
import { TripStatus } from '@/utils/workData/lookuptable';
import {
  correctKpiUnit,
  getKpiProp,
  getTripKpis,
  TripPropertyLabelEnums,
  userConvertKpiUnitValues,
} from '@/views/trip/trip';
import moment from 'moment';
import { computed, unref } from 'vue';

import { useUnitConversion } from '@/composables/conversion';
import { kpiUnitValueToKpiData } from '@/utils/types/columnCustomizationTypes';

interface DataPoint {
  label: string;
  value?: string;
}

const route = useRoute();

const activeContext = useActiveContext();

/**
 * Get trip by UUID used for map element
 */
const tripByUUID = useAsync(
  computed(async (): Promise<TripByUUIDResponse> => {
    const tripUUID = unref(route)?.params?.id;
    if (tripUUID) {
      const trip: TripByUUIDResponse = await getTripByUUID(
        tripUUID,
        UserModule.i18nCode,
        unref(activeContext)
      );

      if (trip) {
        return prepareResponse(trip);
      }
    }

    return never();
  })
);

/**
 * Return trip details with data formatted accordingly for the page header section information
 */
const tripDetails = computed((): TripByUUIDResponse | undefined => {
  const trip = unref(tripByUUID).data;
  if (trip) {
    return {
      ...trip,
      startTime: trip.startTime
        ? parseTimeToDatePattern(trip.startTime, trip.timezone)
        : undefined,
      endTime: trip.endTime
        ? parseTimeToDatePattern(trip.endTime, trip.timezone)
        : undefined,
      tripTime: trip.tripTime ? parseTimeAsDuration(trip.tripTime) : undefined,
    };
  }

  return undefined;
});

const geofencesList = useAsync(
  computed(async (): Promise<Geofence[]> => {
    const trip = unref(tripByUUID).data;
    if (trip) {
      const geofences = await getAllGeofencesByOrganisationId(
        trip.organizationId,
        unref(activeContext)
      );

      return geofences.data.geofences.filter(
        (g) =>
          g.lifeCycle === LifeCycle.Active &&
          g.associatedAssetTypes.includes(trip.assetType)
      );
    }

    return never();
  })
);

function formatFinalValue(
  value: Date | string | number | boolean | undefined
): string {
  if (value === undefined) {
    return ``;
  }
  return `${value}`;
}

function parseTimeAsDuration(time: string | number): string {
  return calculateElapsedTime(time);
}

function parseTimeToDatePattern(
  receivedDate: string | Date,
  timezone: string | undefined
): string {
  return formatTimer(moment(receivedDate), 'datetime', timezone);
}

const loggedInUser = useLoggedInUser();

const UNIT_VALUE_FORMAT_SETTINGS: FormatSetting = {
  undefinedAs: UndefinedRendering.Empty,
};

const { currentUserConvertUnitValue } = useUnitConversion();

function prepareResponse(trip: TripByUUIDResponse): TripByUUIDResponse {
  if (loggedInUser.value === undefined) {
    throw new Error('loggedInUser is not set');
  }

  // Get Trip KPIs in Metric Units, with correct target unit (e.g. tonne instead of kg)
  const metricTripKpis = getTripKpis(trip).map(correctKpiUnit);

  // Convert to user's units
  const userTripKpis = userConvertKpiUnitValues(
    metricTripKpis,
    currentUserConvertUnitValue
  );

  const payload = getKpiProp(
    userTripKpis,
    TripPropertyLabelEnums.TippedPayload
  );
  const massLoaded = getKpiProp(
    userTripKpis,
    TripPropertyLabelEnums.BeforeTippingPayload
  );
  const leftOverPayload = getKpiProp(
    userTripKpis,
    TripPropertyLabelEnums.LeftOverPayload
  );
  const tripMileage = getKpiProp(userTripKpis, TripPropertyLabelEnums.Mileage);
  const massThreshold = getKpiProp(
    userTripKpis,
    TripPropertyLabelEnums.MassThreshold
  );
  const maxSpeed = getKpiProp(userTripKpis, TripPropertyLabelEnums.MaxSpeed);
  const maxCylinderPressure = getKpiProp(
    userTripKpis,
    TripPropertyLabelEnums.MaxPressureOilCylinder
  );

  const finalMassUnloaded = formatUnitValue(
    payload,
    UNIT_VALUE_FORMAT_SETTINGS
  );
  const finalMassLoaded = formatUnitValue(
    massLoaded,
    UNIT_VALUE_FORMAT_SETTINGS
  );
  const finalLeftOverPayload = formatUnitValue(
    leftOverPayload,
    UNIT_VALUE_FORMAT_SETTINGS
  );
  const finalTripMileage = formatUnitValue(
    tripMileage,
    UNIT_VALUE_FORMAT_SETTINGS
  );
  const finalMassThreshold = formatUnitValue(
    massThreshold,
    UNIT_VALUE_FORMAT_SETTINGS
  );
  const finalMaxSpeed = formatUnitValue(maxSpeed, UNIT_VALUE_FORMAT_SETTINGS);
  const finalMaximumCylinderPressure = formatUnitValue(
    maxCylinderPressure,
    UNIT_VALUE_FORMAT_SETTINGS
  );

  const endTime =
    trip.tripStatus === TripStatus.Underway
      ? undefined
      : trip.endPoint?.timestamp;
  const endPosition =
    trip.tripStatus === TripStatus.Underway ? undefined : trip.endPoint;

  // TODO Parse trip.propertyValue[x] into a UnitValue, then use 'normal' formatting here
  const finalStartTippingAngle = trip.propertyValues?.[
    TripPropertyLabelEnums.StartTippingAngle
  ]
    ? `${
        trip.propertyValues?.[
          TripPropertyLabelEnums.StartTippingAngle
        ] as string
      } ${i18n.tc(
        trip.propertyDetails?.find(
          (item) => item.code === TripPropertyLabelEnums.StartTippingAngle
        )?.unit!
      )}`
    : undefined;

  const finalMaximumSideloadAngle = trip.propertyValues?.[
    TripPropertyLabelEnums.MaxSideLoadAngle
  ]
    ? `${
        trip.propertyValues?.[TripPropertyLabelEnums.MaxSideLoadAngle] as string
      } ${i18n.tc(
        trip.propertyDetails?.find(
          (item) => item.code === TripPropertyLabelEnums.MaxSideLoadAngle
        )?.unit!
      )}`
    : undefined;

  return {
    id: trip.id,
    companyAssetId: trip.companyAssetId,
    assetType: trip.assetType,
    organizationId: trip.organizationId,
    organizationName: trip.organizationName,
    payload: payload,
    tripId: trip.tripId,
    tripStatus: trip.tripStatus,
    startTime: trip.startPoint?.timestamp
      ? new Date(trip.startPoint?.timestamp)
      : undefined,
    endTime: endTime ? new Date(endTime) : undefined,
    startPosition: trip.startPoint,
    endPosition: endPosition,
    tripTime: trip.tripTime,
    summary: trip.tripSubActivities,
    events: trip.events,
    kpiData: userTripKpis.map(kpiUnitValueToKpiData),
    timezone: trip.timezone,
    massLoaded: finalMassLoaded,
    massUnloaded: finalMassUnloaded,
    massLeftover: finalLeftOverPayload,
    tippingTime: (trip.propertyValues?.[
      TripPropertyLabelEnums.TippingTime
    ] as string)
      ? parseTimeAsDuration(
          trip.propertyValues?.[TripPropertyLabelEnums.TippingTime] as string
        )
      : undefined,

    sideloadWarning: trip.propertyValues?.[
      TripPropertyLabelEnums.HasSideLoadWarning
    ]
      ? capitalizeFirstLetterOfTheWorldsInString(
          trip.propertyValues?.[
            TripPropertyLabelEnums.HasSideLoadWarning
          ] as string
        )
      : undefined,

    sideloadDangerWarning: trip.propertyValues?.[
      TripPropertyLabelEnums.HasSideLoadDangerWarning
    ]
      ? capitalizeFirstLetterOfTheWorldsInString(
          trip.propertyValues?.[
            TripPropertyLabelEnums.HasSideLoadDangerWarning
          ] as string
        )
      : undefined,

    overloadWarning: trip.propertyValues?.[
      TripPropertyLabelEnums.HasOverloadWarning
    ]
      ? capitalizeFirstLetterOfTheWorldsInString(
          trip.propertyValues?.[
            TripPropertyLabelEnums.HasOverloadWarning
          ] as string
        )
      : undefined,

    bucklingWarning: trip.propertyValues?.[
      TripPropertyLabelEnums.HasBucklingWarning
    ]
      ? capitalizeFirstLetterOfTheWorldsInString(
          trip.propertyValues?.[
            TripPropertyLabelEnums.HasBucklingWarning
          ] as string
        )
      : undefined,
    uphillWarning: trip.propertyValues?.[
      TripPropertyLabelEnums.HasUphillWarning
    ]
      ? capitalizeFirstLetterOfTheWorldsInString(
          trip.propertyValues?.[
            TripPropertyLabelEnums.HasUphillWarning
          ] as string
        )
      : undefined,
    tripMileage: finalTripMileage,
    startTippingAngle: finalStartTippingAngle,
    maximumSideloadAngle: finalMaximumSideloadAngle,
    maximumCylinderPressure: finalMaximumCylinderPressure,
    massThreshold: finalMassThreshold,
    maxSpeed: finalMaxSpeed,
  };
}

const dataPoints = computed((): DataPoint[] => {
  const details = tripDetails.value;

  return [
    {
      label: i18n.tc('trip.assetId'),
      value: formatFinalValue(details?.companyAssetId),
    },
    {
      label: i18n.tc('trip.status'),
      value: i18n.t(formatFinalValue(details?.tripStatus)),
    },
    {
      label: i18n.tc('trip.startDateTime'),
      value: formatFinalValue(details?.startTime),
    },
    {
      label: i18n.tc('trip.endDateTime'),
      value: formatFinalValue(details?.endTime),
    },
    {
      label: i18n.tc('trip.duration'),
      value: formatFinalValue(details?.tripTime),
    },
    {
      label: i18n.tc('trip.tippingTime'),
      value: formatFinalValue(details?.tippingTime),
    },
    {
      label: i18n.tc('trip.massThreshold'),
      value: formatFinalValue(details?.massThreshold),
    },
    {
      label: i18n.tc('trip.tripMassLoaded'),
      value: formatFinalValue(details?.massLoaded),
    },
    {
      label: i18n.tc('trip.tripMassUnloaded'),
      value: formatFinalValue(details?.massUnloaded),
    },
    {
      label: i18n.tc('trip.tripMassLeftOver'),
      value: formatFinalValue(details?.massLeftover),
    },
    {
      label: i18n.tc('trip.tripMileage'),
      value: formatFinalValue(details?.tripMileage),
    },
    {
      label: i18n.t('trip.maxSpeed'),
      value: formatFinalValue(details?.maxSpeed),
    },
    {
      label: i18n.tc('trip.tippingAngleStart'),
      value: formatFinalValue(details?.startTippingAngle),
    },
    {
      label: i18n.tc('trip.maxSideloadAngle'),
      value: formatFinalValue(details?.maximumSideloadAngle),
    },
    {
      label: i18n.tc('trip.maximumCylinderPressure'),
      value: formatFinalValue(details?.maximumCylinderPressure),
    },
    {
      label: i18n.tc('trip.sideloadWarning'),
      value: formatFinalValue(details?.sideloadWarning),
    },
    {
      label: i18n.tc('trip.sideloadDangerWarning'),
      value: formatFinalValue(details?.sideloadDangerWarning),
    },
    {
      label: i18n.tc('trip.overloadWarning'),
      value: formatFinalValue(details?.overloadWarning),
    },
    {
      label: i18n.tc('trip.bucklingWarning'),
      value: formatFinalValue(details?.bucklingWarning),
    },
    {
      label: i18n.t('trip.uphillWarning'),
      value: formatFinalValue(details?.uphillWarning),
    },
  ];
});
</script>

<template>
  <BaseCard
    class="trip-expanded-details"
    :backIconVisible="true"
    :title="i18n.tc('trip.detailPage')"
    :buttons="[]"
  >
    <template #titleInformation>
      <div class="header-trip-id-breadcrumb">
        {{ '>>' }}
      </div>
      <div class="header-trip-id-label-with-value">
        {{ i18n.tc('trip.id') }}:
        {{ tripDetails?.tripId }}
      </div>
    </template>
    <div v-loading="tripByUUID.loading">
      <div class="grid-container">
        <div v-for="item in dataPoints" class="grid-cell">
          <div class="cell-content">
            <div class="cell-content-label">{{ i18n.tc(item.label) }}:</div>
            <div class="cell-content-value">
              {{ item.value }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <TripPlayer
      v-loading="geofencesList.loading"
      class="trip-player-element-wrapper"
      :trip="tripByUUID.data"
      :geofences="geofencesList.data"
    />
  </BaseCard>
</template>

<style lang="scss" scoped>
.trip-expanded-details {
  display: flex;
  flex-direction: column;
}

.header-trip-id-breadcrumb {
  font-weight: 100;
  margin-left: 10px;
}

.base-card :deep(.base-card-body) {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.header-trip-id-label-with-value {
  margin-left: 10px;
  font-family: Roboto;
  font-size: 16px;
  font-weight: bold;
}

.trip-player-element-wrapper {
  height: 100%;

  :deep(.leaflet-container) {
    height: 100%;
  }

  :deep(.el-loading-mask) {
    height: 100%;
  }
}

.grid-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  width: 100%;
  height: 100%;
}

.cell-content-value {
  font-weight: bold;
  margin-left: 4px;
}

.grid-cell:not(:last-child) {
  border-bottom: 1px solid #dddddd;
}

.cell-content:nth-child(5n - 4) {
  margin-left: 10px;
}

.cell-content {
  align-items: center;
  height: 45px;
  display: flex;
  font-size: 16px;
  font-family: Roboto;
  margin-left: 4px;
}

.cell-content-label {
  text-wrap: nowrap;
}
</style>
