<script lang="ts">
export enum ButtonType {
  Primary = 'primary',
  Success = 'success',
  Warning = 'warning',
  Danger = 'danger',
  Info = 'info',
  Text = 'text',
}
</script>

<script setup lang="ts">
export interface ButtonProps {
  /**
   * Title of the button, already needs to be translated.
   */
  title: string;
  onClick: () => void;
  icon?: string;
  type?: ButtonType;
  disabled?: boolean;
}
defineProps<ButtonProps>();
</script>

<template>
  <el-button @click="onClick" type="plain" :disabled="disabled">
    <i v-if="icon" :class="icon" />
    {{ title }}
  </el-button>
</template>

<style lang="scss" scoped>
.el-button--hover {
  color: #373e41;
}
</style>
