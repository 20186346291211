<script lang="ts">
import ResizeMixin from '@/components/charts/mixins/resize';
import { KPI_UNIT } from '@/utils/units/unitDefinitions';
import echarts from 'echarts';
import { cloneDeep } from 'lodash';
import moment from 'moment';
import { mixins } from 'vue-class-component';
import { Component, Prop, Watch } from 'vue-property-decorator';

@Component({
  name: 'MultiBarChart',
})
export default class extends mixins(ResizeMixin) {
  @Prop() id!: string;
  @Prop() className!: string;
  @Prop({ default: '100%' }) width!: string;
  @Prop({ default: '200px' }) height!: string;
  @Prop({ default: '#48B8F4' }) itemColor!: any;
  @Prop({ default: '#137AB1' }) emphasisColor!: any;
  @Prop({ required: true }) chartData!: any;
  @Prop({ required: true }) yAxisUnit!: KPI_UNIT;
  @Prop({ required: true }) seriesConfig!: any;

  chart: any = null;
  originalDateForTooltip: any = [];
  newChartDate: any = [];

  @Watch('chartData', { deep: true })
  handleData(newData: any) {
    if (newData) {
      this.collateAxisData();
      this.initChart();
    }
    return newData;
  }

  mounted() {
    this.$nextTick(() => {
      this.collateAxisData();
      this.initChart();
    });
  }

  collateAxisData() {
    this.originalDateForTooltip = cloneDeep(this.chartData[0]);
    this.newChartDate = cloneDeep(this.chartData);
    if (this.newChartDate[0] && this.newChartDate[0].length > 0) {
      this.newChartDate[0].forEach((item: any, index: number) => {
        if (item != 'product') {
          if (moment(item).isValid()) {
            this.newChartDate[0][index] = moment(item).format('MM/DD');
          } else this.newChartDate[0][index] = item;
        }
      });
    }
  }

  beforeDestroy() {
    if (!this.chart) {
      return;
    }

    this.chart.dispose();
    this.chart = null;
  }

  triggerTooltip(xAxiValue: any) {
    let dataIndex: number = -1;
    this.originalDateForTooltip.forEach((item: any, index: number) => {
      if (item === xAxiValue) {
        dataIndex = index - 1;
        return;
      }
    });
    this.chart.dispatchAction({
      type: 'showTip',
      seriesIndex: 0,
      dataIndex: dataIndex,
      position: (pt: any) => {
        return [pt[0], '50%'];
      },
    });
  }

  private initChart() {
    this.chart = echarts.init(this.$el as HTMLDivElement, 'light');
    this.chart.setOption({
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'shadow',
        },
        position: 'top',
        formatter: (datas: any) => {
          let dateHtml = `<p style="color: #B6B6B6; ; margin: 4px 16px 8px 16px">${
            this.originalDateForTooltip[datas[0].dataIndex + 1]
          }</p>`;
          let valueHtml = '';
          for (var i = 0; i < datas.length; i++) {
            valueHtml =
              valueHtml +
              `<p style="color: #fff; margin: 4px 16px;"> <span style="background:${
                datas[i].color
              }; width: 16px; height: 16px; display:inline-block; border-radius: 50%; border:1px solid #ffffff; vertical-align:middle"></span> <span style="font-size: 14px;  vertical-align:middle">${
                datas[i].seriesName
              }:     ${
                datas[i].value[datas[i].seriesIndex + 1]
              }</span><span style="margin-left:4px;  vertical-align:middle">${this.$t(
                this.yAxisUnit
              )}</span></p>`;
          }
          return dateHtml + valueHtml;
        },
        backgroundColor: 'rgba(55, 62, 65, 0.8)',
        textStyle: {
          color: '#fff',
        },
      },
      grid: {
        top: '20%',
        left: '120',
        bottom: '10%',
        right: '40',
      },
      legend: {
        type: 'scroll',
        show: true,
        top: '20px',
        height: '34%',
        orient: 'horizontal',
        align: 'left',
        itemWidth: 30,
        itemHeight: 10,
        itemGap: 27,
      },
      dataset: {
        source: this.newChartDate,
      },
      xAxis: {
        type: 'category',
        axisLabel: {
          fontSize: 14,
          color: '#C1C1C1',
        },
        axisLine: {
          lineStyle: {
            color: '#DDDDDD',
          },
        },
        splitLine: {
          show: false,
        },
      },
      yAxis: {
        type: 'value',
        name: this.$t(this.yAxisUnit),
        axisLine: {
          lineStyle: {
            color: '#DDDDDD',
          },
        },
        axisLabel: {
          fontSize: 14,
          color: '#C1C1C1',
        },
      },
      series: this.seriesConfig,
    });
  }
}
</script>

<template>
  <div :id="id" :class="className" :style="{ height: height, width: width }" />
</template>

<style lang="scss" scoped></style>
