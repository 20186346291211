<script lang="ts">
import { Component, Vue, Prop, Ref } from 'vue-property-decorator';
import { getTemplatesById, setDefaultTemplate } from '@/api/dashboardConf';
import { promptSuccessBox, promptFailedBox } from '@/utils/prompt';
import BaseCard from '@/components/cusCard/BaseCard.vue';
import ViewDashTemplate from '@/components/template/ViewDashTemplate.vue';
import { PageModule } from '@/store/modules/page';
import { COMPANY_TYPE } from '@/utils/workData/lookuptable';

@Component({
  name: 'ViewTemplate',
  components: {
    BaseCard,
    ViewDashTemplate,
  },
})
export default class extends Vue {
  @Prop() id!: string;
  @Ref('viewDashTemplate') viewDashTemplateRef!: ViewDashTemplate;

  isDefault: boolean = false;

  title: string = '';

  templateViewForm: any = {};

  async handleSetAsDefault() {
    await setDefaultTemplate(this.id, this.templateViewForm).then((res) => {
      if (res.code === 200) {
        promptSuccessBox(this.$t('common.set') as string);
        this.$router.push('/dashboard-conf/index');
      } else if (res.code === 400) {
        promptFailedBox(this.$t('common.set') as string);
      }
    });
  }

  handleEdit() {
    this.$router.push(`/dashboard-conf/edit/${this.id}`);
  }

  async fetchTemplateById() {
    const res = await getTemplatesById(this.id);
    PageModule.setTitle(res.data.name);
    this.isDefault = res.data.isDefault;

    this.templateViewForm = res.data;

    this.viewDashTemplateRef.fetchModules(this.id, res.data.customerType);
  }

  mounted() {
    this.fetchTemplateById();
  }
}
</script>

<template>
  <BaseCard
    id="add_dashbord_template_new_card"
    ref="baseCardRef"
    :title="templateViewForm.name"
    :backIconVisible="true"
    :showDialogBeforeBack="false"
    @handle-back="false"
    style="overflow: auto; overflow-x: hidden"
  >
    <template v-slot:rightHeader>
      <div>
        <el-button
          v-if="!isDefault"
          id="view_template_set_default_btn"
          v-permission="['AUTHRSC_ACTION_DASHBOARD_TMPL_DEFAULT']"
          style="margin-right: 10px"
          type="plain"
          @click="handleSetAsDefault"
        >
          <div class="d-flex ai-center jc-center">
            <div>
              <img
                style="margin-right: 5px"
                src="@/assets/imgs/dashboard-config/setAsDefault.svg"
              />
            </div>
            <div>
              {{ $t('common.setAsDefault') }}
            </div>
          </div>
        </el-button>
        <span v-else class="view-template-default-title">{{
          $t('dashboardConf.defalutTempalte')
        }}</span>
        <el-button
          id="view_template_edit_btn"
          v-permission="['AUTHRSC_ACTION_DASHBOARD_TMPL_UPDATE']"
          style="margin-right: 10px; width: 100px"
          type="plain"
          @click="handleEdit"
        >
          <div class="d-flex ai-center jc-center">
            <div>
              <img style="margin-right: 5px" src="@/assets/imgs/edit.svg" />
            </div>
            <div>
              {{ $t('common.edit') }}
            </div>
          </div>
        </el-button>
      </div>
    </template>

    <ViewDashTemplate ref="viewDashTemplate" :templateId="id" />
  </BaseCard>
</template>

<style lang="scss" scoped>
.view-template-default-title {
  font-size: 16px;
  font-family: $font-Roboto-Medium;
  line-height: 21px;
  color: #373e41;
  opacity: 1;
  margin-right: 50px;
}
</style>
