var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(_setup.Card,{ref:"container",class:_setup.containerClasses,style:(_setup.style)},[_c('template',{slot:"header"},[_vm._t("header",function(){return [_c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.titleIsLoading),expression:"titleIsLoading"}],staticClass:"widget-card-header-inner"},[(_setup.props.showBackButton === true)?_c('i',{staticClass:"el-icon-arrow-left",staticStyle:{"font-size":"25px","cursor":"pointer"},on:{"click":_setup.returnToPreviousPage}}):_vm._e(),(_vm.defaultTitle)?_c('div',{staticClass:"widget-header-title"},[_vm._v(" "+_vm._s(_vm.defaultTitle)+" ")]):(_setup.widget)?_c('div',{staticClass:"widget-header-title"},[_vm._v(" "+_vm._s(_vm.$te(_setup.widget.code) ? _vm.$t(_setup.widget.code) : _setup.widget.name)+" "),(_vm.headerAdditionalInfo)?_c('span',{staticClass:"widget-header-info"},[_vm._v(" "+_vm._s('(' + _setup.i18n.t('maintenance.filteredBy'))+" "),_vm._l((_vm.headerAdditionalInfo),function(item,index){return _c('span',{key:index,class:[
                item === _setup.MaintStatus.OK
                  ? 'status-ok'
                  : item === _setup.MaintStatus.CLOSE
                  ? 'status-close'
                  : 'status-due',
              ]},[_vm._v(" "+_vm._s(_setup.i18n.t(item) + (index + 1 < _vm.headerAdditionalInfo.length ? ', ' : ''))+" ")])}),_vm._v(" "+_vm._s(')')+" ")],2):_vm._e()]):_vm._e(),(_vm.$slots.actions)?_c('div',{staticClass:"actions-container",style:({
            marginLeft: _setup.props.floatActionsRight === true ? 'auto' : 0,
          })},[_vm._t("actions")],2):_vm._e(),(_setup.props.expandable && !_setup.expanded && _setup.expandedRoute)?_c('div',{staticStyle:{"display":"flex","margin-left":"auto"}},[_c('router-link',{style:(_vm.headerAdditionalInfo?.length ? 'padding: 7px' : ''),attrs:{"to":_setup.expandedRoute}},[_c('img',{attrs:{"src":require("@/assets/imgs/home/fullscreen.svg")}})])],1):_vm._e(),(_setup.props.actionTitle)?_c('el-button',{staticStyle:{"margin-left":"auto"},attrs:{"type":"info"},on:{"click":function($event){return _setup.emit('click')}}},[_vm._v(" "+_vm._s(_setup.props.actionTitle)+" ")]):_vm._e()],1)]})],2),_c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_setup.props.loading),expression:"props.loading"}],staticClass:"content",on:{"resize":_setup.submitResizeEvent}},[_vm._t("default")],2)],2)
}
var staticRenderFns = []

export { render, staticRenderFns }