<script lang="ts">
import { getTemplates } from '@/api/dashboardConf';
import ViewDashTemplate from '@/components/template/ViewDashTemplate.vue';
import { CustMgmtModule } from '@/store/modules/custMgmt';
import { generateUrl } from '@/utils/index';
import { COMPANY_TYPE } from '@/utils/workData/lookuptable';
import { Component, Prop, Ref, Vue, Watch } from 'vue-property-decorator';

@Component({
  name: 'dashboardTemplate',
  components: {
    'view-dash-template': ViewDashTemplate,
  },
})
export default class extends Vue {
  @Prop() customerId!: string;
  @Prop() customerType!: COMPANY_TYPE;
  @Prop() industry!: string;
  @Prop({ default: true }) isCreate!: boolean;
  @Prop({ default: false }) isView!: boolean;
  @Ref('viewDashTemplate') viewDashTemplateRef!: ViewDashTemplate;

  id: string = '';
  templateId: string = '';
  title: string = '';
  custTemplates: any = [];
  templateForm: any;

  changeTemplateName(templateId: string) {
    this.id = templateId;
    this.viewDashTemplateRef.fetchModules(templateId, this.customerType);
  }

  handleModifyTemplate() {
    this.$emit('temporary-save', true);

    CustMgmtModule.setTemplateId(this.templateId);
    CustMgmtModule.setCustomerId(this.customerId); //Not Null Only view or edit
    this.$router.push(
      `/customer-management/modify-template/${this.templateId}`
    );
  }

  get isModifyTemplateVisible(): boolean {
    return !this.isView && this.id.length > 0;
  }

  getCurrentTemplateId() {
    return this.id;
  }

  async fetchTemplateByCompanyId() {
    let filterParams = {
      companyId: this.customerId,
    };

    let paramsUrl: string = generateUrl(filterParams);

    await getTemplates(paramsUrl).then(async (res) => {
      if (res.code === 200 && res.data.length > 0) {
        let resData = res.data[0];
        this.id = resData.id;
        this.templateId = resData.id;
        this.title = resData.name;
        await this.viewDashTemplateRef.fetchModules(this.id, this.customerType);
      }
    });
  }

  async fetchAllTemplates(companyType: COMPANY_TYPE, industry: string) {
    let filterParams = {
      customerType: companyType,
      industry: industry,
    };

    let paramsUrl: string = generateUrl(filterParams);

    await getTemplates(paramsUrl).then((res) => {
      if (res.code === 200 && res.data.length > 0) {
        this.custTemplates = res.data;
        if (CustMgmtModule.templateId) {
          if (CustMgmtModule.custTemplateId) {
            this.id = CustMgmtModule.custTemplateId;
            this.templateId = CustMgmtModule.templateId;
          } else {
            this.id = CustMgmtModule.templateId;
            this.templateId = CustMgmtModule.templateId;
          }
        } else {
          this.id = res.data[0].id;
          this.templateId = res.data[0].id;
        }
        this.viewDashTemplateRef.fetchModules(this.id, companyType);
        CustMgmtModule.setCustTemplateId('');
        CustMgmtModule.setTemplateId('');
      }
    });
  }

  @Watch('customerType', { deep: true, immediate: true })
  onCustomerTypeChange() {
    // TODO: The component (CustomerManagementEdit.vue) that passes in the customerType is used
    //       at both create and edit customer form, and initially sets the customerType to "Customer".
    //       Afterwards there's a check that looks whether the component is used for editing, and if yes,
    //       then it fetches the company's data, and also sets the customerType. This component is not reactive at all,
    //       and it will still show the modules/templates for customerType "Customer" even if the fetched company is of different kind.
    //       Making a bigger change in that component might introduce regressions, thus leaving this comment here for the future: this component IS NOT reactive.
    this.fetchData();
  }

  fetchData() {
    if (this.customerId) {
      this.fetchTemplateByCompanyId();
    } else {
      this.fetchAllTemplates(this.customerType, this.industry);
    }
  }
}
</script>

<template>
  <div>
    <div class="d-flex jc-between ai-center" style="margin: 10px 10px">
      <div class="d-flex ai-center">
        <div class="dash-template-header-label">
          <span class="show-title"
            >{{ $t('dashboardTemplate.template') }}:</span
          >
        </div>

        <el-select
          v-if="isCreate"
          class="dash-template-select"
          v-model="templateId"
          :placeholder="$t('dashboardTemplate.selectTemplate')"
          @change="changeTemplateName"
          filterable
        >
          <el-option
            v-for="(item, index) in custTemplates"
            :key="index"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
        <div v-else>
          <span class="edit-dash-template-title">{{ title }}</span>
        </div>
      </div>
      <el-button
        v-if="isModifyTemplateVisible"
        id="new_card_header_btn_deactivate"
        type="plain"
        style="width: 150px"
        @click="handleModifyTemplate"
      >
        <div class="d-flex ai-center jc-center">
          <div>
            <img style="margin-right: 5px" src="@/assets/imgs/edit.svg" />
          </div>
          <div>
            {{ $t('dashboardTemplate.modifyTemplate') }}
          </div>
        </div>
      </el-button>
    </div>
    <view-dash-template
      ref="viewDashTemplate"
      :templateId="id"
      style="border-top: 1px solid #dddddd"
    />
  </div>
</template>

<style lang="scss" scoped>
.dash-template-header-label {
  margin-right: 20px;
}

.el-select {
  margin-right: 0px;
}

.dash-template-select :deep(.el-input__inner) {
  height: $inputHeight;
  width: 260px;
  // width: 31vw;
  border: 1px solid #707070 !important;
  font-size: 1.285714rem;
  font-family: $font-Roboto-Regular;
  line-height: 1.357143rem;
  color: #373e41;
  opacity: 1;
}

.edit-dash-template-title {
  font-size: 18px;
  font-family: $font-Roboto-Regular;
  line-height: 19px;
  color: #373e41;
  opacity: 1;
}
</style>
