import {
  OperationalStatus,
  OPERATIONAL_STATUS_ICONS,
} from './operationalStatus';

export const DEPLOYMENT_MANAGEMENT_COLS = [
  {
    label: 'deployManagementModule.deployments.filters.systemReleaseId',
    prop: 'systemReleaseId',
    required: true,
    visible: true,
    sortable: 'custom',
  },
  {
    label: 'deployManagementModule.deployments.filters.deploymentStartTime',
    prop: 'deploymentStartTime',
    dateFormat: 'datetime',
    required: true,
    visible: true,
    sortable: 'custom',
  },
  {
    label: 'deployManagementModule.deployments.filters.customerName',
    prop: 'companyName',
    required: true,
    visible: true,
    multiLang: true,
    sortable: 'custom',
  },
  {
    label: 'deployManagementModule.deployments.filters.noOfInvolvedAssets',
    prop: 'noOfInvolvedAssets',
    required: true,
    visible: true,
    sortable: 'custom',
  },
  {
    label: 'deployManagementModule.deployments.filters.finished',
    prop: 'progressPercentage',
    required: true,
    visible: true,
    sortable: 'custom',
  },
  {
    label: 'deployManagementModule.deployments.filters.deployByUser',
    prop: 'deploymentBy',
    required: false,
    visible: true,
    sortable: 'custom',
  },
  {
    label: 'deployManagementModule.deployments.filters.assetType',
    prop: 'assetType',
    required: false,
    multiLang: true,
    visible: true,
    sortable: 'custom',
  },
  {
    label: 'deployManagementModule.deployments.filters.productModel',
    prop: 'productModelCode',
    required: false,
    visible: true,
    sortable: 'custom',
  },
  {
    label: 'deployManagementModule.deployments.filters.modelNumber',
    prop: 'productModelNumber',
    required: false,
    visible: true,
    sortable: 'custom',
  },
];

export const NEW_DEPLOY_COLS = [
  {
    label: 'deployManagementModule.start.columns.customerAssetId',
    prop: 'companyAssetId',
    required: true,
    visible: true,
    sortable: 'custom',
  },
  {
    label: 'deployManagementModule.start.columns.organization',
    prop: 'organizationName',
    required: true,
    visible: true,
    sortable: 'custom',
  },
  {
    label: 'deployManagementModule.start.columns.lastCommunicationTime',
    prop: 'KPI_LastCommunicationTime',
    required: true,
    visible: true,
    dateFormat: 'datetime',
    sortable: false,
  },
  {
    label: 'deployManagementModule.start.columns.operationalStatus',
    prop: 'KPI_OperationalStatus',
    required: true,
    visible: true,
    sortable: 'custom',
    useIconSet: {
      [OperationalStatus.Offline]: {
        img: OPERATIONAL_STATUS_ICONS[OperationalStatus.Offline],
        title: 'kpiStatus.OFFLINE',
      },
      [OperationalStatus.Stopped]: {
        img: OPERATIONAL_STATUS_ICONS[OperationalStatus.Stopped],
        title: 'kpiStatus.STOP',
      },
      [OperationalStatus.MotorOn]: {
        img: OPERATIONAL_STATUS_ICONS[OperationalStatus.MotorOn],
        title: 'kpiStatus.MOTOR_ON',
      },
      [OperationalStatus.InTransition]: {
        img: OPERATIONAL_STATUS_ICONS[OperationalStatus.InTransition],
        title: 'kpiStatus.IN_TRANSITION',
      },
    },
  },
  {
    label: 'deployManagementModule.start.columns.productSystemReleaseId',
    prop: 'currentSystemReleaseId',
    required: true,
    visible: true,
    sortable: 'custom',
  },
  {
    label: 'deployManagementModule.start.columns.lastDeploymentTime',
    prop: 'lastDeploymentTime',
    required: true,
    visible: true,
    dateFormat: 'datetime',
    sortable: 'custom',
  },
];
