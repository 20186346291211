import { KpiUnitValue } from '@/api/kpis';
import { TripByUUIDResponse, TripProperties } from '@/api/trip';
import { UserUnitValueConverter } from '@/composables/conversion';
import i18n from '@/lang';
import { formatUnitValue, UndefinedRendering } from '@/utils/format';
import { formatTimer } from '@/utils/misc';
import { parseNumberOrUndefined } from '@/utils/number';
import { calculateElapsedTime } from '@/utils/time';
import { SearchType, Trip } from '@/utils/types/trip';
import { convertUnitValue } from '@/utils/units/conversion';
import { KPI_UNIT } from '@/utils/units/unitDefinitions';
import moment from 'moment';

export interface SearchFilter {
  name: SearchType;
  value: string;
}

export interface TripDetailProperty {
  label: string;
  value: string | Date | null | undefined;
}

export interface TripDetails {
  id: TripDetailProperty;
  startTime: TripDetailProperty;
  totalTime: TripDetailProperty;
  payload: TripDetailProperty;
}

export function getTripKpis(
  trip: TripProperties | TripByUUIDResponse
): KpiUnitValue<number | boolean | undefined>[] {
  const details = trip.propertyDetails || [];
  const values = trip.propertyValues || {};

  return details.map((detail): KpiUnitValue<number | boolean | undefined> => {
    const rawValue = values[detail.code];
    const value =
      detail.unit === KPI_UNIT.Boolean
        ? rawValue === 'true'
        : parseNumberOrUndefined(rawValue);
    return {
      code: detail.code,
      unit: detail.unit as KPI_UNIT,
      v: value,
    };
  });
}

export const enum TripPropertyLabelEnums {
  StartTippingAngle = 'OPER.StartTippingAngle',
  Mileage = 'TRIP.Mileage',
  MaxSideLoadAngle = 'OPER.MaxSideLoadAngle',
  IsKnockOffTriggered = 'OPER.IsKnockOffTriggered',
  MaxPressureOilCylinder = 'OPER.MaxPressureOilCylinder',
  HasSideLoadDangerWarning = 'TRIP.HasSideLoadDangerWarning',
  IsOverloaded = 'OPER.IsOverloaded',
  MaxPressureReturnFilter = 'OPER.MaxPressureReturnFilter',
  HasSafetyStateTriggeredWarning = 'TRIP.HasSafetyStateTriggeredWarning',
  LeftOverPayload = 'OPER.LeftOverPayload',
  TippingTime = 'OPER.TippingTime',
  HasBucklingWarning = 'TRIP.HasBucklingWarning',
  HasUphillWarning = 'TRIP.HasUphillWarning',
  BeforeTippingPayload = 'OPER.BeforeTippingPayload',
  OverloadPercentage = 'OPER.OverloadPercentage',
  HasOverloadWarning = 'TRIP.HasOverloadWarning',
  IsEmpty = 'OPER.IsEmpty',
  HasSideLoadWarning = 'TRIP.HasSideLoadWarning',
  TippedPayload = 'OPER.TippedPayload',
  MaxTippingAngle = 'OPER.MaxTippingAngle',
  AverageSpeed = 'TRIP.AverageSpeed',
  MassThreshold = 'OPER.MassThreshold',
  MaxSpeed = 'TRIP.MaxSpeed',
  TripPayload = 'TRIP.Payload',
}

/**
 * There are some KPIs that we get in a different unit from the TB than in what we want to show on the screen.
 *
 * This is a mapping of these KPIs to the metric unit that we need to convert them.
 */
const wrongKpiUnitToCorrectMetricUnit: Partial<
  Record<TripPropertyLabelEnums, KPI_UNIT>
> = {
  [TripPropertyLabelEnums.LeftOverPayload]: KPI_UNIT.MetricTonne,
  [TripPropertyLabelEnums.BeforeTippingPayload]: KPI_UNIT.MetricTonne,
  [TripPropertyLabelEnums.MassThreshold]: KPI_UNIT.MetricTonne,
  [TripPropertyLabelEnums.TippedPayload]: KPI_UNIT.MetricTonne,
  [TripPropertyLabelEnums.Mileage]: KPI_UNIT.Kilometer,
  [TripPropertyLabelEnums.TripPayload]: KPI_UNIT.MetricTonne,
};

export function getKpiProp(
  kpis: KpiUnitValue<number | boolean | undefined>[],
  propCode: string
): KpiUnitValue<number | undefined> {
  const kpi = kpis.find((k) => k.code === propCode);

  if (kpi === undefined) {
    console.info(`KPI ${propCode} not found.`);
    return { v: undefined, unit: KPI_UNIT.Text, code: '' };
  }

  if (typeof kpi.v !== 'number' && kpi.v !== undefined) {
    throw new Error(`Invalid KPI value, expected a number`);
  }

  return kpi as KpiUnitValue<number | undefined>;
}

export function userConvertKpiUnitValues(
  kpis: KpiUnitValue<number | boolean | undefined>[],
  unitValueConverter: UserUnitValueConverter
): KpiUnitValue<number | boolean | undefined>[] {
  return kpis.map((kpi) =>
    typeof kpi.v === 'number'
      ? unitValueConverter(kpi as KpiUnitValue<number>)
      : kpi
  );
}

/**
 * Some KPI's are received in a different unit than what the user
 * expects to see, e.g. kg instead of tonne.
 *
 * Convert such a value from the input unit to the desired (metric)
 * unit.
 */
export function correctKpiUnit<T extends number | boolean | undefined>(
  kpi: KpiUnitValue<T>
): KpiUnitValue<T> {
  if (typeof kpi.v !== 'number') {
    return kpi;
  }
  const targetUnit =
    wrongKpiUnitToCorrectMetricUnit[kpi.code as TripPropertyLabelEnums] ??
    kpi.unit;
  return convertUnitValue(
    kpi as KpiUnitValue<number>,
    targetUnit
  ) as KpiUnitValue<T>;
}

export function setupSelectedTrip(trip: Trip): TripDetails {
  return {
    id: {
      label: i18n.tc('trip.companyAssetId'),
      value: trip.companyAssetId,
    },
    startTime: {
      label: i18n.tc('trip.startTime'),
      value: formatTimer(moment(trip.startTime), 'datetime'),
    },
    totalTime: {
      label: i18n.tc('trip.duration'),
      value: calculateElapsedTime(trip.tripTime),
    },
    payload: {
      label: i18n.tc('trip.payload'),
      value: formatUnitValue(trip.payload, {
        undefinedAs: UndefinedRendering.DashDash,
      }),
    },
  };
}
