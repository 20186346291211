<script lang="ts">
import { getOrganizationAssetsHierarchyLegacy } from '@/api/assets';
import { UUID } from '@/api/common';
import { CommonResult } from '@/api/commonResult';
import {
  GeofenceResponse,
  getAllGeofencesByOrganisationId,
  LifeCycle,
} from '@/api/geofence';
import { Geofence } from '@/api/geofenceTypes';
import {
  fetchReportTemplatesByCustomParams,
  ReportTemplate,
} from '@/api/report';
import { isAssetTypeSubscription } from '@/api/subscriptionPackages';
import {
  getAssetsWithTrips,
  getTripAssetTypes,
  getTrips,
  TripAsset,
} from '@/api/trip';
import { ActiveContext, useActiveContext } from '@/auth/context';
import { LoggedInUser, useLoggedInUser } from '@/auth/user';
import MultiSelectDropDown from '@/components/form/MultiSelectDropDown.vue';
import TimeSelect from '@/components/form/TimeSelect.vue';
import Title from '@/components/layout/Title.vue';
import LeafletMap from '@/components/leafletMap/LeafletMap.vue';
import UtilTable from '@/components/table/UtilTable.vue';
import TripDetails from '@/components/trip/TripDetails.vue';
import TripPlayer from '@/components/trip/TripPlayer.vue';
import {
  useUnitConversion,
  UseUnitConversionReturnType,
} from '@/composables/conversion';
import { useBooleanSystemFeature } from '@/composables/systemFeature';
import ExportIcon from '@/icons/svg/export.svg';
import {
  Filter,
  FilterOperator,
  Pagination,
  QueryParameter,
  SorterOrder,
} from '@/model/queryParameters/QueryParameter';
import { ColumnCustomizationModule } from '@/store/modules/tableColumnCustomization';
import { UserModule } from '@/store/modules/user';
import { findAssets } from '@/utils/assets';
import { AssetType } from '@/utils/assetTypes';
import { deepCopy } from '@/utils/generic';
import { customFailedMessage, customWarningMessage } from '@/utils/prompt';
import { DEFAULT_DATE_RANGE, LOCALDATE_FORMAT } from '@/utils/time';
import {
  KpiData,
  kpiUnitValueToKpiData,
  TableColumn,
} from '@/utils/types/columnCustomizationTypes';
import { DateRange } from '@/utils/types/date';
import { Position } from '@/utils/types/geoposition';
import { Option } from '@/utils/types/option';
import { SearchType, Trip, TripActivity, TripEvent } from '@/utils/types/trip';
import { KPI_UNIT } from '@/utils/units/unitDefinitions';
import { UnitValue } from '@/utils/units/unitValue';
import {
  ALL_CLAIMS_CODES,
  SYSTEM_FEATURES,
  TripStatus,
  TRIP_PROPERTY_VALUES,
  UI_TABLE,
} from '@/utils/workData/lookuptable';
import { TRIPS_TABLE_CUSTOMIZATION } from '@/utils/workData/tableCustomization';
import ExportDailyTripLog from '@/views/trip/ExportDailyTripLog.vue';
import moment from 'moment';
import { ComputedRef, Ref, unref } from 'vue';
import { Component, Vue } from 'vue-property-decorator';
import {
  correctKpiUnit,
  getKpiProp,
  getTripKpis,
  SearchFilter,
  TripPropertyLabelEnums,
  userConvertKpiUnitValues,
} from './trip';

@Component({
  name: 'Trip',
  components: {
    Title,
    TripPlayer,
    LeafletMap,
    UtilTable,
    TripDetails,
    TimeSelect,
    ExportDailyTripLog,
    ExportIcon,
    MultiSelectDropDown,
  },
})
export default class extends Vue {
  /** Local variables */
  /** Table properties */
  total: number = 0;
  cols: TableColumn[] = [];
  $refs!: {
    /** References for the multi-asset filter and the leaflet map */
    assetFilterRef: any;
    tripsTableRef: any;
  };
  firstLoad = true; /** true until created => getData() is finished */
  tripTableIsLoading: boolean = false; /** true if getData() is running */
  tripPlayerMapIsLoading: boolean =
    false; /** true if selectTrips() is triggered */
  assetsLoading = true; /** select asset dropdown is loading */
  tripsTableData: Trip[] = [];
  assetType: AssetType | null = null;
  assetTypes: AssetType[] = [];
  geofences: Geofence[] = [];
  searchFieldTypes: SearchType[] = Object.values(SearchType);
  searchField: SearchFilter = {
    name: this.searchFieldTypes[0],
    value: '',
  };
  dateRange = DEFAULT_DATE_RANGE;
  selectedTrip: Trip | null = null;
  pagination: { page: number; size: number } = {
    page: 1,
    size: UserModule.gridPageSize,
  };
  tripTableRendering = TRIPS_TABLE_CUSTOMIZATION;
  tripStatuses = [
    TripStatus.Underway,
    TripStatus.Completed,
    TripStatus.Incomplete,
  ];
  noAssets: boolean = false;
  sortOrder: SorterOrder = SorterOrder.DESC;
  sortField: string = 'startPoint.timestamp';
  dropdownAssetList: Option[] = [];
  assetDropdownSelectedValues: UUID[] = [];
  context!: Ref<ActiveContext>;
  loggedInUser!: Ref<LoggedInUser | undefined>;
  isExportReportModalVisible: boolean = false;
  tippingVehicleAssetType: AssetType = AssetType.TippingVehicle;
  generalReportTemplate: ReportTemplate | undefined = undefined;
  globalReportEntitlement!: ComputedRef<boolean>;
  ExportIcon = ExportIcon;
  unitConversion!: UseUnitConversionReturnType;
  isExportOngoing: boolean = false;

  async created(): Promise<void> {
    this.unitConversion = useUnitConversion();
    this.tripTableIsLoading = true;
    this.tripPlayerMapIsLoading = true;
    this.globalReportEntitlement = useBooleanSystemFeature(
      SYSTEM_FEATURES.ExportGlobalReport
    );
    this.context = useActiveContext();
    this.loggedInUser = useLoggedInUser();

    /** wait for the subscriptions to be updated before retrieving the assets and their data */
    await this.getSubscriptions();
    await this.getAssetOptions();
    await this.getData();

    /**
     * only set firstLoad to false after all data has been collected
     * otherwise the form will create a rerender and extra API calls
     */
    this.firstLoad = false;

    this.trackReadAllowed = unref(this.context).claims.hasClaim(
      ALL_CLAIMS_CODES.AUTHRSC_PAGE_TRIP_LIVE
    );
  }

  mounted(): void {
    getOrganizationAssetsHierarchyLegacy(
      undefined,
      unref(this.context).organization?.id,
      unref(this.context)
    ).then((data) => {
      const assets = findAssets(data.organization);
      const tripTypes = this.assetTypes;
      const filtered = assets.reduce((acc, cur) => {
        if (!tripTypes.includes(cur.assetType)) {
          return acc;
        }

        const type = cur.assetType as AssetType;
        const count = acc.find((v) => v.type === type)?.count ?? 0;

        return [
          ...acc.filter((v) => v.type !== type),
          { type, count: count + 1 },
        ];
      }, [] as { type: AssetType; count: number }[]);

      filtered.sort((a, b) => a.type.localeCompare(b.type));

      this.assetTypes = filtered
        .sort((a, b) => b.count - a.count)
        .map((d) => d.type);
      this.assetType = this.assetTypes[0];

      this.getAssetOptions();
    });
  }
  trackReadAllowed: boolean = false;

  async handleSortChange(sortField: string, sortOrder: string): Promise<void> {
    if (sortOrder) {
      this.sortField = sortField;
      this.sortOrder = this.defineSortOrder(sortField, sortOrder);
    } else {
      /** default of sort is none */
      this.sortOrder = SorterOrder.DESC;
      this.sortField = 'startPoint.timestamp';
    }

    !this.firstLoad && (await this.getData());
  }

  /*
   * Trips can have NULL as their 'End Time' when their status is 'Underway',
   * in this case they need to be shown first.
   * KPI columns (such as Mileage, Avg. Speed etc.) can have null values as well,
   * when we sort by these values we want them shown at the end of the list
   */
  defineSortOrder(sortField: string, sortOrder: string): SorterOrder {
    if (sortField == 'endPoint.timestamp' && sortOrder == SorterOrder.ASC) {
      return SorterOrder.ASC_NULLS_LAST;
    } else if (sortField == 'endPoint.timestamp') {
      return SorterOrder.DESC;
    }

    return sortOrder == SorterOrder.ASC
      ? SorterOrder.ASC_NULLS_LAST
      : SorterOrder.DESC_NULLS_LAST;
  }

  async handleAssetsFilter(asset: AssetType): Promise<void | undefined> {
    if (!asset) return;
    this.tripPlayerMapIsLoading = true;
    this.selectedTrip = null;

    if (asset === this.tippingVehicleAssetType)
      await this.fetchReportTemplateRemotely();

    try {
      this.cols = await ColumnCustomizationModule.getTableColumns({
        code: UI_TABLE.Trip,
        assetType: asset,
      });
    } catch (e) {
      customFailedMessage(
        this.$t('tableColumnCustomization.getErrorMessage') as string
      );
      return;
    } finally {
      this.tripPlayerMapIsLoading = false;
    }

    /** Reset default sorters */
    this.sortOrder = SorterOrder.DESC;
    this.sortField = 'startPoint.timestamp';

    /** Reset pagination */
    this.pagination.page = 1;
    this.$nextTick(() => {
      this.$refs.tripsTableRef?.setPage(1);
    });

    if (this.firstLoad) {
      return;
    }
    await this.getAssets();
    await this.getData();
  }

  handleSearchFieldFilter(): void {
    this.searchField.value = '';
    this.handleSearchFieldForm();
  }

  async handleSearchFieldForm(): Promise<void> {
    if (this.firstLoad) return;
    await this.getAssets();
    await this.getData();
  }

  async handleTimeFilter(dateRange: DateRange): Promise<void> {
    this.dateRange = dateRange;

    if (this.firstLoad) return;

    await this.getAssets();
    await this.getData();
  }

  async getAssetOptions(): Promise<void> {
    const override: AssetType | undefined = this.$route.query.assetType as
      | AssetType
      | undefined;
    const tripEndDate: string | (string | null)[] | null =
      this.$route.query.tripEndDate || null;

    /**
     * When override: need to request between
     * end: endTripDate + 1 days to have end date inclusive in range
     * start: the end inslusive - 7 days as default date time picker required behavior
     */
    if (tripEndDate) {
      this.dateRange.start = moment(tripEndDate.toString())
        .subtract(7, 'day')
        .format(LOCALDATE_FORMAT);
      this.dateRange.endExclusive = moment(tripEndDate.toString())
        .add(1, 'day')
        .format(LOCALDATE_FORMAT);
    }

    if (override && this.assetTypes.includes(override)) {
      this.assetType = override;
    }

    if (this.assetType) {
      this.handleAssetsFilter(this.assetType);
    }
  }

  /**
   * Set of query parameter filters used between
   * Trip Live view and the exported report.
   */
  get commonFilters(): Filter[] {
    const filters: Filter[] = [];

    if (
      this.searchField.name !== 'tripStatus' ||
      this.searchField.value === ''
    ) {
      // DP-1736: Trip data should be filtered to not show Incomplete trips,
      // when trip status is NOT being used as a filter
      filters.push({
        name: 'tripStatus',
        operator: FilterOperator.NOT_EQUAL,
        value: [TripStatus.Incomplete],
      });
    }

    const orgIds = unref(this.context).organizationIds;
    if (orgIds) {
      filters.push({
        name: 'organizationId',
        operator: FilterOperator.IN,
        value: orgIds,
      });
    }

    if (this.assetDropdownSelectedValues.length > 0) {
      filters.push({
        name: 'assetId',
        operator: FilterOperator.IN,
        value: this.assetDropdownSelectedValues,
      });
    }

    // Search field
    if (this.searchField.value !== '') {
      filters.push({
        name: this.searchField.name,
        operator: FilterOperator.LIKE,
        value: [this.searchField.value],
      });
    }

    return filters;
  }

  get queryParameters(): QueryParameter {
    const filters: Filter[] = [
      ...this.commonFilters,
      {
        name: 'endPoint.timestamp',
        operator: FilterOperator.BETWEEN,
        value: [this.dateRange.start, this.dateRange.endExclusive],
      },
    ];

    // TODO AssetType currently not supported by ExportDailyTripLog, should be moved
    // to baseFilters some day
    filters.push({
      name: 'assetType',
      operator: FilterOperator.IN,
      value: [this.assetType!],
    });

    return {
      filters,
      sorters: [
        {
          field: this.sortField,
          order: this.sortOrder,
        },
      ],
      pagination: {
        page: this.pagination.page,
        size: this.pagination.size,
      },
    };
  }

  handleQueryParameters(): QueryParameter {
    this.$nextTick(() => {
      this.$refs.tripsTableRef?.setPage(this.pagination.page);
    });
    return this.queryParameters;
  }

  async prepareTripAssetsQueryParameters(): Promise<QueryParameter> {
    // `this.handleQueryParameters` returns the `queryParameters` getter. If we don't deepCopy on the line below,
    // in this function we will overwrite the getter's value, because objects are passed by reference, and
    // changing a property on `let queryParameters` will change it also on the getter.
    let queryParameters = deepCopy(this.handleQueryParameters());

    queryParameters.filters = queryParameters.filters!.filter(
      (filter: { name: string }) => filter.name !== 'assetId'
    );
    delete queryParameters.sorters;
    const finalPagination: Pagination = { page: 1, size: 10000 };
    queryParameters.pagination = finalPagination;
    return queryParameters;
  }

  async getAssets(): Promise<void> {
    this.assetsLoading = true;
    this.tripTableIsLoading = true;
    try {
      const res = await getAssetsWithTrips(
        await this.prepareTripAssetsQueryParameters(),
        unref(this.context)
      );

      this.dropdownAssetList = res.data.assets.map(
        (item: TripAsset): Option => {
          return {
            key: item.assetId,
            label: item.companyAssetId,
          };
        }
      );

      // Coming from another page an 'asset' query param can be specified to only show trips from that asset
      const queryParamAssetLabel: string | (string | null)[] | null =
        this.$route.query.asset || null;

      if (typeof queryParamAssetLabel === 'string') {
        // Look for the 'asset' from the query params, and set only that one to selected if it's present
        const queryParamAsset = this.dropdownAssetList.find(
          (asset) => asset.label === queryParamAssetLabel
        );

        if (queryParamAsset !== undefined) {
          this.assetDropdownSelectedValues = [queryParamAsset.key];
        }

        // Because, we don't track selected assets in the query params, we need to remove this initial selection
        // so it doesn't influence what happens later when this function is called again (e.g. at period change)
        // NOTE: This is not good, we should track the asset selection change (and even other filters) in the URL
        //       but this component is due a rewrite already, so it's no point in doing it now.
        const { asset, ...otherParams } = this.$route.query;
        this.$router.replace({
          query: otherParams,
        });
      } else {
        this.assetDropdownSelectedValues = this.dropdownAssetList.map(
          (asset) => asset.key
        );
      }
    } catch (e) {
      customFailedMessage(this.$t('common.errorWithFetchingData') as string);
      console.error(e);
    } finally {
      this.assetsLoading = false;
      this.tripTableIsLoading = false;
    }
  }

  async getSubscriptions(): Promise<void | undefined> {
    try {
      const assetTypes = await getTripAssetTypes();

      this.assetTypes =
        this.loggedInUser.value?.subscriptions
          .filter(isAssetTypeSubscription)
          .map((subscription) => subscription.subscriptionPackageAssetType)
          .filter((subscriptionAssetType) =>
            assetTypes.data.includes(subscriptionAssetType)
          ) ?? [];

      if (this.assetTypes.length == 0) {
        this.noAssets = true;
        return;
      }

      this.assetType = this.assetTypes[0];
    } catch (e) {
      console.error(e);
    }
  }

  async getData(): Promise<KpiData[] | undefined> {
    if (this.noAssets) return;

    if (this.assetDropdownSelectedValues.length == 0) {
      this.tripsTableData = [];
      return;
    }

    try {
      this.tripTableIsLoading = true;
      const {
        data: { total, trips },
      } = await getTrips(this.handleQueryParameters(), unref(this.context));
      this.total = total;

      if (
        this.pagination.page > 1 &&
        (this.pagination.page - 1) * UserModule.gridPageSize > total
      ) {
        this.handlePage(1);
        this.$nextTick(() => {
          this.$refs.tripsTableRef?.setPage(1);
        });
      }

      let data = trips.map((trip: any): Trip => {
        const endTime =
          trip.tripStatus === TripStatus.Underway
            ? undefined
            : trip.endPoint?.timestamp;
        const endPosition =
          trip.tripStatus === TripStatus.Underway
            ? ''
            : (trip.endPoint as Position);

        // Get Trip KPIs in Metric Units, with correct target unit (e.g. tonne instead of kg)
        const metricTripKpis = getTripKpis(trip).map(correctKpiUnit);

        // Convert to user's units
        const userTripKpis = userConvertKpiUnitValues(
          metricTripKpis,
          this.unitConversion.currentUserConvertUnitValue
        );

        const isIncompleteTrip = !trip.propertyDetails && !trip.propertyValues;

        const payload: UnitValue<number | undefined> = isIncompleteTrip
          ? {
              v: undefined,
              unit: this.unitConversion.currentUserPreferredUnit(
                KPI_UNIT.MetricTonne
              ),
            }
          : getKpiProp(
              userTripKpis,
              trip.assetType === AssetType.TippingVehicle
                ? TripPropertyLabelEnums.TippedPayload
                : TRIP_PROPERTY_VALUES.PAYLOAD
            );

        return {
          id: trip.id,
          companyAssetId: trip.companyAssetId,
          assetType: this.assetType as any,
          organizationId: trip.organizationId,
          organizationName: trip.organizationName,
          payload: payload,
          // @ts-expect-error Property doesn't exist on type, but is likely needed
          tripId: trip.tripId,
          tripStatus: trip.tripStatus as TripStatus,
          startTime: new Date(trip.startPoint.timestamp),
          startPosition: trip.startPoint as Position,
          endTime: endTime ? new Date(endTime) : undefined,
          endPosition: endPosition,
          tripTime: trip.tripTime,
          summary: trip.tripSubActivities as TripActivity[],
          events: trip.events as TripEvent[],
          kpiData: userTripKpis.map(kpiUnitValueToKpiData),
          timeZone:
            trip.timezone /** AHMAPP-5285 Add time conversion to the asset time zone received for start/end */,
        };
      });

      data = data.filter((t) => {
        return data.filter((t2) => t.id == t2.id).length == 1;
      });

      this.tripsTableData = data;
      /** Default selection of first item from the table */
      this.selectTrip(data[0]);
      this.$nextTick(() => {
        this.$refs.tripsTableRef?.highlightRow(data[0]);
      });
    } catch (e) {
      this.tripsTableData = [];
      customFailedMessage(this.$t('common.errorWithFetchingData') as string);
      console.error(e);
    } finally {
      this.tripTableIsLoading = false;
    }
  }

  handlePage(page: number): void {
    this.pagination.page = page;
    !this.firstLoad && this.getData();
  }

  async selectTrip(row: Trip): Promise<void> {
    this.geofences = [];
    this.selectedTrip = row;

    if (!this.trackReadAllowed) {
      return;
    }

    try {
      this.tripPlayerMapIsLoading = true;
      const handleGeofences = (data: CommonResult<GeofenceResponse>) => {
        this.geofences = data.data.geofences.filter(
          (g) =>
            g.lifeCycle === LifeCycle.Active &&
            g.associatedAssetTypes.includes(row.assetType)
        );
      };
      await getAllGeofencesByOrganisationId(
        row.organizationId,
        unref(this.context)
      ).then(handleGeofences);
    } catch (error) {
      console.log(error);
    } finally {
      this.tripPlayerMapIsLoading = false;
    }
  }

  async onColsChange(newCols: TableColumn[]): Promise<void> {
    try {
      await ColumnCustomizationModule.saveTable({
        code: UI_TABLE.Trip,
        assetType: this.assetType as AssetType,
        columns: newCols,
      });
    } catch (e) {
      customWarningMessage(
        this.$t('tableColumnCustomization.saveErrorMessage') as string
      );
    }
  }

  handleExport(): void {
    if (!this.isExportOngoing) {
      this.isExportReportModalVisible = true;
    }
  }

  generateReportTemplateRequestBody(): QueryParameter {
    return {
      filters: [
        {
          name: 'ASSET_TYPE',
          operator: FilterOperator.EQUAL,
          value: [this.tippingVehicleAssetType],
        },
      ],
      sorters: [
        {
          field: 'CREATED_ON',
          order: SorterOrder.DESC,
        },
      ],
      pagination: {
        page: 1,
        size: 15,
      },
    };
  }

  async fetchReportTemplateRemotely(): Promise<void> {
    try {
      const apiResponse = await fetchReportTemplatesByCustomParams(
        this.generateReportTemplateRequestBody()
      );

      if (
        apiResponse.code === 200 &&
        apiResponse.data.reportTemplates.length > 0
      ) {
        let resData = apiResponse.data.reportTemplates;

        this.generalReportTemplate = resData.find(
          (report: ReportTemplate) => report.isGlobal
        );
      }
    } catch (error) {
      throw new Error('There was an error fetching report templates');
    }
  }

  async changeSelectedAssets(assets: string[]) {
    this.assetDropdownSelectedValues = assets;

    // TODO: This is pretty bad, but can't do anything without a major rewrite.
    await this.getData();
  }
}
</script>

<template>
  <div class="trip-live-holder">
    <Title class="title" />
    <div class="table-container">
      <div class="table-filter-container">
        <!-- Subscription Asset Type -->
        <el-select
          class="filter-select thick-dropdown asset-type-selection"
          v-model="assetType"
          v-loading="!assetType"
          :disabled="!assetTypes"
          @change="handleAssetsFilter"
          filterable
          default-first-option
        >
          <el-option
            class="filter-select-option"
            v-for="asset in assetTypes"
            :key="asset"
            :label="$t(asset)"
            :value="asset"
          />
        </el-select>

        <!-- Search field -->
        <div class="search-container">
          <el-select
            class="filter-select search-by-reference"
            id="fields"
            v-model="searchField.name"
            @change="handleSearchFieldFilter"
            filterable
            default-first-option
          >
            <el-option
              class="filter-select-option"
              v-for="item in searchFieldTypes"
              :key="item"
              :label="$t('trip.' + item)"
              :value="item"
            />
          </el-select>
          <el-select
            v-if="searchField.name == 'tripStatus'"
            @change="handleSearchFieldForm"
            v-model="searchField.value"
            class="search-select-dropdown"
          >
            <el-option
              class="filter-select-option"
              v-for="item in tripStatuses"
              :key="item"
              :label="$t(item)"
              :value="item"
            />
          </el-select>
          <el-input
            v-else
            class="search-select-input"
            :placeholder="$t('common.inputKeywordToSearch')"
            v-model="searchField.value"
            @clear="handleSearchFieldForm"
            @keyup.enter.native="handleSearchFieldForm"
            clearable
          >
            <i
              slot="suffix"
              class="el-icon-search"
              @click="handleSearchFieldForm"
            />
          </el-input>
        </div>

        <MultiSelectDropDown
          class="multi-select-dropdown"
          v-loading="assetsLoading"
          :filter-label="$t('selectAssets')"
          :options="dropdownAssetList"
          :selected-options="assetDropdownSelectedValues"
          @change="changeSelectedAssets"
        />

        <!-- Time/Date Select -->
        <TimeSelect
          :expanded="true"
          @select="handleTimeFilter"
          :customizable="true"
        />

        <div class="export-and-column-container">
          <div
            v-if="
              assetType === tippingVehicleAssetType &&
              globalReportEntitlement.value
            "
            @click="handleExport"
            :class="{
              'export-icon-container': !isExportOngoing,
              'export-icon-container-disabled': isExportOngoing,
            }"
          >
            <el-tooltip
              :content="
                isExportOngoing ? $t('trip.exportOngoing') : $t('trip.export')
              "
              placement="top"
            >
              <img :src="ExportIcon" />
            </el-tooltip>
          </div>

          <!-- Table Column Filters -->
          <div class="column-button" v-popover:popover>
            <img src="@/assets/imgs/customer-management/column.svg" alt="" />
            <span>{{ $t('common.selectColumn') }}</span>
          </div>
          <el-popover ref="popover" placement="left" trigger="click">
            <el-checkbox
              class="column-checkbox"
              v-for="item in cols"
              :key="item.label"
              :label="$te(item.label) ? $t(item.label) : item.label"
              v-model="item.visible"
              :disabled="item.required"
            />
          </el-popover>
        </div>
      </div>

      <!-- Table -->
      <util-table
        v-loading="tripTableIsLoading"
        ref="tripsTableRef"
        class="trip-table"
        :tableList="tripsTableData"
        :pageTotal="total"
        :cols="cols"
        :showTableHeaderOptions="false"
        :customRendering="tripTableRendering"
        @cols-change="onColsChange"
        @handle-pagination="handlePage"
        @handle-sort-change="handleSortChange"
        @row-click="selectTrip"
        :type="assetType"
        max-height="258"
      />

      <!-- Trip details -->
      <TripDetails
        v-loading="tripTableIsLoading"
        :trip="selectedTrip && tripsTableData?.length > 0 ? selectedTrip : null"
        :expandedRoute="`/trip/expanded-trip-details/${selectedTrip?.id}`"
      />

      <!-- Map -->
      <TripPlayer
        v-loading="tripPlayerMapIsLoading || tripTableIsLoading"
        :trip="selectedTrip && tripsTableData?.length > 0 ? selectedTrip : null"
        :geofences="geofences && tripsTableData?.length > 0 ? geofences : []"
        v-if="trackReadAllowed"
      />
    </div>

    <ExportDailyTripLog
      v-if="generalReportTemplate"
      :visible.sync="isExportReportModalVisible"
      :title="$t('report.exportReport')"
      :reportTemplateData="generalReportTemplate"
      :filters="commonFilters"
      :dateRange="dateRange"
      :sortField="sortField"
      :sortOrder="sortOrder"
      @export-started="isExportOngoing = true"
      @export-finished="isExportOngoing = false"
    />
  </div>
</template>

<style lang="css" scoped>
.export-and-column-container {
  margin-left: auto;
  display: flex;
  align-items: center;
}

.export-icon-container {
  cursor: pointer;
  margin-right: 30px;
}

.export-icon-container-disabled {
  cursor: not-allowed;
  margin-right: 30px;
}

.trip-live-holder {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.trip-table :deep(.is-sortable) {
  margin: 0;
  padding: 0;
}

.trip-table :deep(.pagination-container) {
  /* Pagination is usually quite spacious, but we need the space, so reduce it */
  padding-top: 6px;
  margin-bottom: 0px;
}

.table-filter-container {
  gap: 8px 12px;
  margin: 10px 15px 3px 15px;
}

@media screen and (max-height: 767px) {
  .trip-table :deep(.legend-item) {
    height: 18px;
    line-height: 10px;
  }

  .trip-table :deep(.legend-item) {
    padding-top: 8px;
  }

  .asset-type-selection :deep(.el-input) {
    width: 165px;
  }

  .asset-type-selection :deep(.el-input__inner) {
    width: 165px !important;
  }

  .search-container :deep(.el-input__inner) {
    width: 190px !important;
  }

  .search-container :deep(.el-select) {
    width: 120px;
  }

  .table-container {
    height: 100%;

    :deep(.trip-map) {
      height: 100%;
    }
  }

  .trip-table :deep(.el-table__body-wrapper) {
    max-height: 135px !important;
  }
}
</style>

<style lang="scss" scoped>
.page-title-container {
  display: flex;
  align-items: center;
  height: 60px;
}

.table-container {
  width: 100%;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 3px 6px #c1c1c1;
  display: flex;
  flex-direction: column;
  height: 100%;

  .table-filter-container {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }

  .trip-table {
    margin: 5px 16px;
  }

  .trip-map {
    margin-top: 60px;
    height: 50vh;
    width: 100%;
  }

  .trip-player {
    height: 100%;
  }
}

.filter-select {
  margin: 0 !important;

  :deep(.el-input__inner) {
    width: 200px;
  }
}

/**
  * We use the :hover here, but the widget does not
  * correctly unset the .hover state on itself
  * which means that its background stays grey,
  * so we unset the background given by the widget ourselve
 */
.filter-select-option {
  &.hover {
    background-color: unset !important;
  }

  &:hover {
    background-color: var(--DropdownHover) !important;
  }
}

.search-container {
  display: flex;
  align-items: center;

  .filter-select :deep(.el-input__inner) {
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    border-right: transparent !important;
  }

  .search-select-input :deep(.el-input__inner) {
    width: 200px;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
  }

  .search-select-dropdown :deep(.el-input__inner) {
    border-right: inherit;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
  }

  .search-select-dropdown {
    margin-right: 0;
  }
}

.filter-select-assets-search-input {
  width: 210px;
  margin: 4px 20px;

  :deep(.el-input__inner) {
    width: 100%;
  }
}

:deep(.el-select__tags-text) {
  max-width: 85px;
}

.filter-select-assets-select-all,
.filter-select-assets-option {
  padding: 0 1rem !important; /** has to be important to override some other css */
  height: 34px; /** The dropdown widget sets the height fixed instead using the padding */
  display: flex;
  align-items: center;
  color: #606266;
  font-size: 16px;

  &:hover {
    cursor: pointer;
    background-color: var(--DropdownHover) !important;
  }

  span {
    color: #373e41;
    font-weight: 400;
  }
}

.filter-select-asset-divider {
  margin: 4px 0;
}

.el-icon-search {
  line-height: 40px;
  color: rgba(0, 0, 0, 0.6);
  font-size: 18px;
  cursor: pointer;
  margin-right: 8px;
  margin-top: -4px;
}

.el-switch-component {
  margin: auto 0 auto auto;
}

.column-button {
  cursor: pointer;
  font-size: 16px;
  font-family: $font-Roboto-Medium;
  line-height: 19px;
  color: #373e41;

  :hover {
    color: var(--Main);
  }

  > img {
    width: 14px;
    height: 14px;
    margin-right: 8px;
  }
}

.column-checkbox {
  display: block;
  color: #373e41 !important;
}

.el-select-dropdown__item.selected::after {
  display: none;
}

.title {
  margin-bottom: 0.5rem;
}

.multi-select-dropdown {
  :deep(.el-input) {
    width: 200px;
  }
}
</style>
