<script lang="ts">
import {
  getHealthStatusGroupedByAssetType,
  HealthStatusGroupByAssetTypeResponse,
} from '@/api/healthStatus';
import { ActiveContext, useActiveContext } from '@/auth/context';
import {
  FilterOperator,
  QueryParameter,
} from '@/model/queryParameters/QueryParameter';
import { AssetType } from '@/utils/assetTypes';
import { ORGANIZATION_ID } from '@/utils/constants';
import { HealthStatusAsset } from '@/utils/types/health';
import {
  assetTypeIcons,
  HEALTH_STATUS_SYN,
  WarningType,
  WARNING_TO_HEALTH_STATUS_COLOR,
} from '@/utils/workData/lookuptable';
import { Ref, unref } from 'vue';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

@Component({
  name: 'AssetHealthList',
})
export default class extends Vue {
  @Prop() selectedFilter!: WarningType;
  @Prop() assetType!: AssetType;

  data: HealthStatusGroupByAssetTypeResponse = {
    assetsByStatus: [],
    totalAssetCount: 0,
  };
  tableData: HealthStatusAsset[] = [];
  assetTypeIcon: string = '';
  context!: Ref<ActiveContext>;
  isHealthStatusLoading: boolean = false;

  async created() {
    this.context = useActiveContext();
    await this.getHealthStatus();
    this.updateConditional(this.selectedFilter);
  }

  @Watch('selectedFilter')
  onWarningChanged(val: WarningType, oldVal: WarningType) {
    this.updateConditional(val);
  }

  updateConditional(warning: WarningType) {
    if (this.data.hasOwnProperty('errors')) return;
    let status = HEALTH_STATUS_SYN[warning];
    this.tableData = this.data.assetsByStatus
      .filter((group: any) => {
        return group.status == status;
      })
      .flatMap((e: any) => e.assets)
      .sort(
        (a: HealthStatusAsset, b: HealthStatusAsset) =>
          new Date(b.healthStatusLastChangeTimestamp).getTime() -
          new Date(a.healthStatusLastChangeTimestamp).getTime()
      ) as HealthStatusAsset[];
  }

  /** Fetch heath status group by asset type */
  async getHealthStatus() {
    this.isHealthStatusLoading = true;
    const activeContext: ActiveContext = unref(this.context);
    const queryParameter: QueryParameter =
      this.buildQueryParameter(activeContext);
    const healthStatusResponse = await getHealthStatusGroupedByAssetType(
      this.assetType,
      activeContext,
      queryParameter
    );
    this.data = healthStatusResponse.data;
    this.assetTypeIcon = assetTypeIcons[this.assetType] as string;
    this.isHealthStatusLoading = false;
  }

  /** Build query params */
  buildQueryParameter(activeContext: ActiveContext): QueryParameter {
    return activeContext.organizationIds
      ? {
          filters: [
            {
              name: ORGANIZATION_ID,
              operator: FilterOperator.IN,
              value: activeContext.organizationIds,
            },
          ],
        }
      : { pagination: { page: 1, size: 1000 } };
  }

  getStyle(condition: WarningType) {
    return {
      backgroundColor: this.statusColorFromCondition(condition),
    };
  }

  statusColorFromCondition(warning: WarningType): string {
    return WARNING_TO_HEALTH_STATUS_COLOR[warning];
  }

  onRowClick(row: any, column: any, event: any) {
    this.$emit(
      'asset-selected',
      row.assetId,
      row.companyAssetId || row.assetDataCollectionId
    );
  }
}
</script>

<template>
  <el-table
    v-loading="isHealthStatusLoading"
    :element-loading-text="$t('healthManagementModule.healthStatusIsLoading')"
    class="health-table"
    :data="tableData"
    style="width: 100%"
    :show-header="false"
    v-on:row-click="onRowClick"
    :row-style="{ height: '3.5rem' }"
  >
    <el-table-column prop="healthCondition" width="30">
      <template>
        <div class="status-item-icon" :style="getStyle(selectedFilter)" />
      </template>
    </el-table-column>
    <el-table-column prop="assetType" width="40">
      <template>
        <img class="asset-type-icon" :src="assetTypeIcon" />
      </template>
    </el-table-column>
    <el-table-column prop="assetDataCollectionId" width="170">
      <template v-slot="slotProps">
        <div style="font-weight: bold">
          {{
            slotProps.row['companyAssetId'] ||
            slotProps.row['assetDataCollectionId']
          }}
        </div>
      </template>
    </el-table-column>
    <el-table-column prop="productModelCode" label="productModel" width="230">
      <template v-slot="slotProps">
        <span>{{
          slotProps.row['productModelCode'] +
          '/' +
          slotProps.row['productModelNumber']
        }}</span>
      </template>
    </el-table-column>
    <el-table-column prop="organizationName" width="120"> </el-table-column>
  </el-table>
</template>

<style lang="scss" scoped>
.health-table {
  padding: 1rem;
}

.asset-type-icon {
  width: 100%;
}
.status-item-icon {
  height: 16px;
  width: 16px;
  border-radius: 50%;
}
</style>
