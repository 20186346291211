<script lang="ts">
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import AssignTable from '../components/AssignTable.vue';
import { CONNECTED_ASSETS_COLS } from '@/utils/workData/assetsMgmt';

@Component({
  name: 'PrevAssignTable',
  components: {
    AssignTable,
  },
})
export default class extends Vue {
  @Prop({ default: [] }) selectedTableList!: any;
  @Prop({ default: '' }) chosenAssetType!: string;
  @Prop({ default: false }) errorTips!: boolean;

  cols = CONNECTED_ASSETS_COLS;
}
</script>

<template>
  <div style="position: relative">
    <p class="title">
      {{ $t('assetMgmt.assetsToBeAssigned') }}({{ selectedTableList.length }})
    </p>
    <p class="warning_tips" v-show="errorTips">
      {{ $t('assetMgmt.assignmentFail') }}:
      <span>{{ $t('assetMgmt.customerNoAssetType') }}</span>
      {{ $t(chosenAssetType) }}
    </p>
    <AssignTable
      :class="errorTips ? 'error_bg' : ''"
      id="assign_asset_table_prev"
      :tableList="selectedTableList"
      :cols="cols"
      :total="selectedTableList.length"
      :showCheckedColumn="false"
    />
  </div>
</template>

<style lang="scss" scoped>
.title {
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
  color: #373e41;
  margin: 26px 20px 60px;
}
.warning_tips {
  color: #ef6840;
  font-weight: bold;
  position: absolute;
  top: 25px;
  left: 20px;
  span {
    font-weight: normal;
  }
}
:deep(.error_bg .choosing-row) {
  background: #fcdcd3 !important;
}
</style>
