/**
 * Convert given value to an Error, if it isn't already one.
 *
 * This is mostly useful in a `catch` statement, where the
 * caught value is `unknown`.
 *
 * @example
 * ```ts
 * try {
 *     // Something that does something bad.
 *     throw new Error("boom");
 *     // Or even worse: throw "boom";
 * } catch(err) {
 *     const error = toError(err);
 *     // It's guaranteed that `error` is now indeed an Error object,
 *     // which simplifies further handling in follow-up code.
 * }
 *
 * ```
 */
export function toError(err: unknown): Error {
  try {
    return err instanceof Error ? err : new Error(`Unknown error: ${err}`);
  } catch {
    return new Error('Unknown error');
  }
}
