import { getServiceRegions, ServiceRegion } from '@/api/serviceRegions';
import { useQuery, UseQueryReturnType } from '@tanstack/vue-query';
import { computed } from 'vue';
import { NO_BACKGROUND_FETCH_OPTIONS } from './common';

const queryKeys = {
  serviceRegions: () =>
    ({
      query: 'service-regions',
    } as const),
};

export function useServiceRegionsQuery(): UseQueryReturnType<
  ServiceRegion[],
  Error
> {
  return useQuery({
    ...NO_BACKGROUND_FETCH_OPTIONS,
    staleTime: 60 * 1000 * 10,
    queryKey: [computed(() => queryKeys.serviceRegions())],
    queryFn: () => getServiceRegions(),
  });
}
