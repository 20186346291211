import { UnitSystemMappings } from './systemConversionTypes';
import { KPI_UNIT } from './unitDefinitions';

export enum UnitSystem {
  Metric = 'UNITSYS_METRIC',
  USCustomary = 'UNITSYS_USC',
  // Imperial = 'UNITSYS_IMPERIAL', // Not used yet
}

export const UNIT_SYSTEMS: UnitSystem[] = [
  UnitSystem.Metric,
  UnitSystem.USCustomary,
];

/**
 * Mapping of metric units to its corresponding unit in
 * each non-metric unit system.
 *
 * Any mapping *not* in this list will stay unconverted.
 * Any mapping *in* this list needs to have its corresponding
 * unit conversion factors to be defined.
 */
export const UNIT_SYSTEM_MAPPINGS: UnitSystemMappings = {
  [UnitSystem.USCustomary]: {
    [KPI_UNIT.DegreesCelsius]: KPI_UNIT.DegreesFahrenheit,
    [KPI_UNIT.Kilogram]: KPI_UNIT.Pound,
    [KPI_UNIT.MetricTonne]: KPI_UNIT.USTon,
    [KPI_UNIT.Bar]: KPI_UNIT.Psi,
    [KPI_UNIT.KilometerPerHour]: KPI_UNIT.MilePerHour,
    [KPI_UNIT.Meter]: KPI_UNIT.Inch,
    [KPI_UNIT.Millimeter]: KPI_UNIT.Inch,
    [KPI_UNIT.Kilometer]: KPI_UNIT.Mile,
    [KPI_UNIT.KilogramPerHour]: KPI_UNIT.PoundPerHour,
    [KPI_UNIT.MetricTonnePerHour]: KPI_UNIT.USTonPerHour,
    [KPI_UNIT.MetricTonnePerKWH]: KPI_UNIT.USTonPerKWH,
  },
};
