<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator';
import { formatTimer, calculateTimeDifference } from '@/utils/misc';
import { Moment } from 'moment';
import { AssetDeploymentLog } from '@/api/deploymentDetails';
import {
  assetLifecyclesWhenAssetDeploymentCanBeCancelled,
  assetsControlStatusesWhenAssetDeploymentCanBeCancelled,
  statesWhenAssetDeploymentCanBeHold,
  assetsControlStatusesWhenAssetDeploymentCanBeHold,
  statesWhenAssetDeploymentCanBeResume,
  assetsControlStatusesWhenAssetDeploymentCanBeResume,
  calculateAssetDeploymentStatus,
} from './assetDeployment';
import { UserModule } from '@/store/modules/user';
import { ALL_CLAIMS_CODES } from '@/utils/workData/lookuptable';

@Component({
  name: 'Details',
})
export default class extends Vue {
  @Prop({ default: '' }) deployLogId!: string;
  @Prop({}) data!: AssetDeploymentLog;
  @Prop({ default: false }) buttons!: boolean;

  /** Local variables */
  unwatch: any = null;
  details: any = {};
  deployLog: string = '';

  mounted() {
    this.unwatch = this.$watch('data', (newValue, oldValue) => {
      if (!newValue) return;
      this.details = newValue;
    });

    this.unwatch = this.$watch('deployLogId', (newValue, oldValue) => {
      if (!newValue) return;
      this.deployLog = newValue;
    });
  }

  /**
   * Calculate difference between deployment start and end time
   */
  timeDifference(
    startDateAndTime: string | number,
    endDateAndTime: string | number
  ): string | undefined {
    return calculateTimeDifference(startDateAndTime, endDateAndTime);
  }

  /**
   * Cancel asset deployment
   */
  cancelAassetDeployment(): void {
    this.$emit('cancelAassetDeployment');
  }

  /**
   * Hold asset deployment
   */
  holdAssetDeployment(): void {
    this.$emit('holdAssetDeployment');
  }

  /**
   * Resume asset deployment
   */
  resumeAssetDeployment(): void {
    this.$emit('resumeAssetDeployment');
  }

  /**
   * Format date and time to logged in user time zone
   * @param manifestCreationTime
   */
  formatDateAndTime(manifestCreationTime: string): string | Moment {
    return formatTimer(manifestCreationTime, 'datetime');
  }

  /**
   * Handle display of asset deployment cancel button
   */
  get assetDeploymentCanBeCancelled(): boolean {
    return (
      assetLifecyclesWhenAssetDeploymentCanBeCancelled.includes(
        this.data.assetDeployStatus
      ) &&
      assetsControlStatusesWhenAssetDeploymentCanBeCancelled.includes(
        this.data.deploymentControl
      ) &&
      UserModule.claims.hasClaim(
        ALL_CLAIMS_CODES.AUTHRSC_ACTION_DEPLOYMENT_CANCEL
      )
    );
  }

  /**
   * Handle display of asset deployment hold button
   */
  get assetDeploymentCanBeHold(): boolean {
    return (
      statesWhenAssetDeploymentCanBeHold.includes(
        this.data.assetDeployStatus
      ) &&
      assetsControlStatusesWhenAssetDeploymentCanBeHold.includes(
        this.data.deploymentControl
      ) &&
      UserModule.claims.hasClaim(
        ALL_CLAIMS_CODES.AUTHRSC_ACTION_DEPLOYMENT_HOLD
      )
    );
  }

  /**
   * Handle display of asset deployment resume button
   */
  get assetDeploymentCanBeResume(): boolean {
    return (
      statesWhenAssetDeploymentCanBeResume.includes(
        this.data.assetDeployStatus
      ) &&
      assetsControlStatusesWhenAssetDeploymentCanBeResume.includes(
        this.data.deploymentControl
      ) &&
      UserModule.claims.hasClaim(
        ALL_CLAIMS_CODES.AUTHRSC_ACTION_DEPLOYMENT_RESUME
      )
    );
  }

  /**
   * Get final asset deployment status by certain conditions
   */
  get finalAssetDeploymentStatus() {
    return calculateAssetDeploymentStatus(this.data);
  }
}
</script>

<template>
  <div class="content">
    <div class="header">
      <h3 class="title">
        {{ $t('deployManagementModule.history.deployLog') }}:
        {{ details.companyAssetId }}
      </h3>
      <div class="button-container" v-if="buttons">
        <el-button
          id="cancel_deployment_button"
          v-if="assetDeploymentCanBeCancelled"
          v-permission="['AUTHRSC_ACTION_DEPLOYMENT_CANCEL']"
          @click="cancelAassetDeployment"
        >
          {{ $t('deployManagementModule.deployments.cancel') }}
        </el-button>
        <el-button
          id="hold_deployment_button"
          v-if="assetDeploymentCanBeHold"
          v-permission="['AUTHRSC_ACTION_DEPLOYMENT_HOLD']"
          @click="holdAssetDeployment"
        >
          {{ $t('deployManagementModule.deployments.hold') }}
        </el-button>
        <el-button
          id="resume_deployment_button"
          v-if="assetDeploymentCanBeResume"
          v-permission="['AUTHRSC_ACTION_DEPLOYMENT_RESUME']"
          @click="resumeAssetDeployment"
        >
          {{ $t('deployManagementModule.deployments.resume') }}
        </el-button>
      </div>
    </div>
    <div class="part-update-progress-item-history">
      <p>
        <span>{{ $t('deployManagementModule.history.assetId') }}:</span>
        <span>{{ details.companyAssetId }}</span>
      </p>
      <p>
        <span>{{ $t('deployManagementModule.history.assetType') }}:</span>
        <span v-if="details.assetType">{{ $t(`${details.assetType}`) }}</span>
      </p>
      <p>
        <span>{{ $t('deployManagementModule.history.productModel') }}:</span>
        <span>{{ details.productModelCode }}</span>
      </p>
      <p>
        <span
          >{{ $t('deployManagementModule.history.organizationName') }}:</span
        >
        <span>{{ details.organizationName }}</span>
      </p>
      <p>
        <span
          >{{ $t('deployManagementModule.history.deploymentStartTime') }}:</span
        >
        <span>{{ formatDateAndTime(details.deploymentStartTime) }}</span>
      </p>
      <p>
        <span
          >{{ $t('deployManagementModule.history.deploymentEndTime') }}:</span
        >
        <span>{{ formatDateAndTime(details.deploymentEndTime) }}</span>
      </p>
      <p>
        <span>{{ $t('deployManagementModule.history.totalTimeSpent') }}:</span>
        <span>{{
          timeDifference(
            details.deploymentStartTime,
            details.deploymentEndTime
          ) ?? '-'
        }}</span>
      </p>
      <p>
        <span>{{ $t('deployManagementModule.history.deployStatus') }}:</span>
        <span>
          <span
            v-if="details.assetDeployStatus"
            :class="['tag', details.assetDeployStatus]"
          >
            {{ $t(`${finalAssetDeploymentStatus}`) }}
          </span>
        </span>
      </p>
    </div>
    <div class="part-update-progress-item">
      <div class="line"></div>
      <div class="part-title">
        <h3 class="title">
          {{
            $t('deployManagementModule.history.productSystemReleaseUpdating')
          }}
        </h3>
        <div class="part-title-status-info">
          <div class="time-difference">
            {{
              timeDifference(
                details.deploymentStartTime,
                details.deploymentEndTime
              ) ?? ''
            }}
          </div>
          <span
            v-if="details.assetDeployStatus"
            :class="['tag', details.assetDeployStatus]"
          >
            {{ $t(`${finalAssetDeploymentStatus}`) }}
          </span>
        </div>
      </div>
      <p>
        <span>{{ $t('deployManagementModule.history.from') }}:</span>
        <span>{{ details.systemReleaseIdBeforeDeployment ?? '-' }}</span>
      </p>
      <p>
        <span>{{ $t('deployManagementModule.history.to') }}:</span>
        <span>{{ details.systemReleaseIdAfterDeployment ?? '-' }}</span>
      </p>
    </div>
    <div
      class="part-update-progress-item"
      v-for="(item, index) in details.partUpdateProgress"
      :key="index"
    >
      <div class="line" />

      <div class="part-title">
        <h3 class="title">{{ $t(`PART_UPDATING_${item.obuInstance}`) }}:</h3>
        <div class="part-title-status-info">
          <div class="time-difference">
            {{ timeDifference(item.deployStartTime, item.deployEndTime) ?? '' }}
          </div>
          <span
            v-if="item.partReleaseDeploymentStatus"
            :class="['tag', item.partReleaseDeploymentStatus]"
          >
            {{ $t(`${item.partReleaseDeploymentStatus}`) }}
          </span>
        </div>
      </div>

      <p>
        <span>{{ $t('deployManagementModule.history.from') }}:</span>
        <span>{{ item.releaseBeforeDeployId ?? '-' }}</span>
      </p>
      <p>
        <span>{{ $t('deployManagementModule.history.to') }}:</span>
        <span>{{ item.releaseAfterDeployId ?? '-' }}</span>
      </p>
    </div>
  </div>
</template>

<style scoped>
.part-title {
  display: flex;
  justify-content: space-between;
}

.part-title-status-info {
  display: flex;
  margin-right: 2vh;
}
</style>

<style lang="scss" scoped>
.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.content {
  padding: 16px;
  width: 50%;
}

.line {
  width: 100%;
  border-bottom: 2px solid rgba(55, 62, 65, 0.2);
}

p {
  width: 110%;
  color: #868a8c;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  display: flex;
  justify-content: space-between;

  span:nth-child(even) {
    text-align: right;
    margin: auto auto auto 0px;
    color: #373e41;
    opacity: 1;
    font-style: normal;
    font-weight: 500;
  }
}

.part-update-progress-item-history {
  p > span:nth-child(1) {
    width: 200px;
  }
}

.part-update-progress-item {
  span:nth-child(1) {
    width: 100px;
  }
  span:nth-child(2) {
    margin: auto auto auto 0px;
  }
}

.time-difference {
  margin-top: auto;
  margin-bottom: auto;
  margin-right: 10px;
}

.title {
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
}

.tag {
  font-family: Roboto;
  font-weight: 500;
  font-size: 12px;
  line-height: 14.06px;
  padding: 5px;
  border-radius: 5px;
  color: #373e41;
  display: inline-block;
  width: 80px;
  text-align: center;
}

.ASDS_POSTPONED {
  background: #ffbb54;
}

.ASDS_DEPLOYING {
  background: #7be3d1;
}

.ASDS_ROLLING_BACK {
  background: #56ccf2;
}

.ASDS_ON_HOLD {
  background: #a5a6f6;
}

.ASDS_SUCCESS {
  background: #6fcf97;
}

.ASDS_REVERTED {
  background: #88e2ff;
}

.ASDS_CANCELED {
  background: #e0e0e0;
}

.ASDS_CANCELLED {
  background: #e0e0e0;
}

.ASDS_FAILED {
  background: #f2994a;
}

.ASDS_WAITING {
  background: #f5cb22;
}

.ASDS_INSTALLING {
  background: #22cbf5;
}

.PRDS_WAITING {
  background: #f5cb22;
}

.PRDS_INSTALLING {
  background: #22cbf5;
}

.PRDS_SUCCESS {
  background: #6fcf97;
}
.PRDS_REVERTING {
  background: #88e2ff;
}

.PRDS_REVERTED {
  background: #88e2ff;
}

.PRDS_FAILED {
  background: #f2994a;
}

.PRDS_SKIPPED {
  background: #f2994a;
}

.ASDDS_POSTPONED {
  background: #ffbb54;
}

.ASDDS_DEPLOYING {
  background: #7be3d1;
}

.ASDDS_ROLLING_BACK {
  background: #56ccf2;
}

.ASDDS_ON_HOLD {
  background: #a5a6f6;
}

.ASDDS_SUCCESS {
  background: #6fcf97;
}

.ASDDS_REVERTED {
  background: #88e2ff;
}

.ASDDS_CANCELED {
  background: #e0e0e0;
}

.ASDDS_CANCELLED {
  background: #e0e0e0;
}

.ASDDS_FAILED {
  background: #f2994a;
}

.ASDDS_WAITING {
  background: #f5cb22;
}

.ASDDS_INSTALLING {
  background: #22cbf5;
}
</style>
