<script lang="ts">
import { getOrgById } from '@/api/organizations';
import {
  deleteUser,
  getModules,
  getUserById,
  updateActivationStatus,
} from '@/api/users';
import NewCard from '@/components/cusCard/NewCard.vue';
import DeactivateDialog from '@/components/dialog/DeactivateDialog.vue';
import ModuleAccess from '@/components/form/ModuleAccess.vue';
import { PageModule } from '@/store/modules/page';
import { UserModule } from '@/store/modules/user';
import {
  customFailedMessage,
  promptFailedBox,
  promptSuccessBox,
} from '@/utils/prompt';
import {
  ACTIVATION_STATUS,
  ALL_CLAIMS_CODES,
} from '@/utils/workData/lookuptable';
import { Component, Prop, Vue } from 'vue-property-decorator';
import UserInfo from './components/UserInfo.vue';

@Component({
  name: 'viewCustInfo',
  components: {
    'new-card': NewCard,
    'user-info': UserInfo,
    'module-access': ModuleAccess,
    'deactivate-dialog': DeactivateDialog,
  },
})
export default class extends Vue {
  @Prop({ required: true }) userId!: string;

  /** Local variables */
  editBtnAuth: string[] = [ALL_CLAIMS_CODES.AUTHRSC_ACTION_USER_UPDATE];
  deacBtnAuth: string[] = [ALL_CLAIMS_CODES.AUTHRSC_ACTION_USER_DEACTIVATE];
  removeBtnAuth: string[] = [ALL_CLAIMS_CODES.AUTHRSC_ACTION_USER_REMOVE];

  userInfo: any = {};
  activateOrDeactivate: string = '';
  modulesInfo: any = [];
  defaultChecked: string[] = [];
  title: string = '';
  activeName: string = 'first';
  visiable: boolean = false;
  dialogContent: any = '';
  orgNum: number = 0;
  userNum: number = 0;
  userTotal: number = 15;
  isUserInfoLoading: boolean = false;
  loadingText: string = this.$t('userModule.loadingUserData') as string;
  deleteDialogVisible: boolean = false;
  notAllowedActionsByHimSelf: string[] = ['REMOVE', 'DEACTIVATE'];
  activateOrDeactivateBtnIsVisible: boolean = false;

  created() {
    this.fetchUserModules();
    this.userId && this.fetchUserData();
  }

  async fetchUserModules() {
    await getModules().then((res) => {
      if (!res) {
        customFailedMessage(this.$t('common.errorWithFetchingData') as string);
        return;
      }

      if (res.code) {
        this.modulesInfo = res.data;
        return;
      }

      customFailedMessage(this.$t('common.errorWithFetchingData') as string);
    });
  }

  /**
   * Fetch user data remotly from API
   */
  async fetchUserData(): Promise<void> {
    try {
      this.isUserInfoLoading = true;
      const res = await getUserById(this.userId);

      if (res.code === 200) {
        this.userInfo = res.data;
        //this.userInfo = Object.assign({}, this.userInfo, res.data);
        this.title = this.userInfo.username;
        PageModule.setTitle(this.title);
        if (this.userInfo.activationStatus === ACTIVATION_STATUS.Deactivated) {
          this.activateOrDeactivate = 'activate';
        } else {
          this.activateOrDeactivate = 'deactivate';
        }

        let arr: string[] = [];
        this.userInfo.claims.forEach((item: any) => {
          arr.push(item.resource);
        });

        this.defaultChecked = arr;
        this.getOrganizationById(res.data.organizationId);
      }
    } catch (error) {
      console.log(error);
      customFailedMessage(this.$t('common.errorWithFetchingData') as string);
    } finally {
    }
  }

  /**
   * Handle cancel modal event
   */
  handleCancel(): void {
    this.visiable = false;
  }

  /**
   * Show deactivate modal
   */
  showDeactive(): void {
    if (this.selfCheckNotAllowedAction('DEACTIVATE')) {
      return;
    }
    this.visiable = true;
    if (this.userInfo.activationStatus === ACTIVATION_STATUS.Deactivated) {
      this.dialogContent = this.$t('userModule.activateInfo', {
        name: this.title,
      });
    } else {
      this.dialogContent = this.$t('userModule.deactivateInfo', {
        name: this.title,
      });
    }
  }

  /**
   * Handle user deactivation
   */
  async handleDeactive(): Promise<void> {
    let infoMsg = (
      this.activateOrDeactivate === 'deactivate'
        ? this.$t('common.deactivate')
        : this.$t('common.activate')
    ) as string;

    await updateActivationStatus(this.userId, {
      activationStatus:
        this.activateOrDeactivate === 'deactivate'
          ? ACTIVATION_STATUS.Deactivated
          : ACTIVATION_STATUS.Activated,
    }).then((res) => {
      if (res.code === 200) {
        promptSuccessBox(infoMsg);

        this.userInfo.activationStatus = ACTIVATION_STATUS.Deactivated;
        this.$router.push('/administration/index');
      } else if (res.code === 400) {
        promptFailedBox(infoMsg);
      }
    });

    this.visiable = false;
  }

  /**
   * Handle delete user
   */
  async handleUserDelete() {
    var response = await deleteUser(this.userId);
    this.deleteDialogVisible = false;

    if (response.code !== 200) {
      promptFailedBox(this.$t('common.remove') as string);
      return;
    }

    promptSuccessBox(this.$t('common.remove') as string);
    this.$router.push('/administration/index');
  }

  /**
   * Close modal event
   */
  closeDeleteDialog(): void {
    this.deleteDialogVisible = false;
  }

  /**
   * Show delete dialog
   */
  showDeleteDialog(): void {
    if (this.selfCheckNotAllowedAction('REMOVE')) {
      return;
    }
    this.deleteDialogVisible = true;
  }

  /**
   * Handle click
   * @param tab
   * @param event
   */
  handleClick(tab: any, event: any): void {}

  /**
   * Handle edit event
   */
  handleEdit() {
    this.$router.push(`/administration/edit-user/${this.userId}`);
  }

  /**
   * Self check not allowed actions like removed or deactivated/active logged in user himself
   */
  selfCheckNotAllowedAction(action: string): boolean {
    if (
      this.userInfo.email === UserModule.email &&
      this.notAllowedActionsByHimSelf.includes(action)
    ) {
      const errorMessage: string =
        action === 'REMOVE'
          ? (this.$t(
              'administrationModule.notAllowedToRemoveYourOwnAcoount'
            ) as string)
          : action === 'DEACTIVATE' &&
            UserModule.activationStatus === ACTIVATION_STATUS.Activated
          ? (this.$t(
              'administrationModule.notAllowedToDeactivateYourOwnAcoount'
            ) as string)
          : action === 'DEACTIVATE' &&
            UserModule.activationStatus === ACTIVATION_STATUS.Deactivated
          ? (this.$t(
              'administrationModule.notAllowedToActivateYourOwnAcoount'
            ) as string)
          : '';
      customFailedMessage(errorMessage);
      return true;
    }
    return false;
  }

  /**
   * Do not allow self action on own entity like user to disable or remove
   * @return boolean
   */
  get checkIfAllowedSelfAction(): boolean {
    return this.userInfo.email != UserModule.email;
  }

  /**
   * Fetch selected user's organization to check the activation status
   * logic is used for activate/deactivate btn on the selected user to be shown only when user's org is activated
   * the loading event should be ended by this thread when all infos/constrains are available to display
   * @param organizationId
   */
  async getOrganizationById(organizationId: string): Promise<void> {
    try {
      if (!organizationId) return;
      this.activateOrDeactivateBtnIsVisible =
        (await getOrgById(organizationId)).data.activationStatus ===
        ACTIVATION_STATUS.Activated
          ? true
          : false;
    } catch (error) {
      console.log(error);
    } finally {
      this.isUserInfoLoading = false;
    }
  }
}
</script>

<template>
  <new-card
    :createdOrEdit="userId"
    :visible="true"
    :editBtnAuth="editBtnAuth"
    :deacBtnAuth="deacBtnAuth"
    :removeBtnAuth="removeBtnAuth"
    :activateOrDeactivate="activateOrDeactivate"
    :backPath="'/administration/index'"
    @edit-infos="handleEdit"
    @handle-deactive="showDeactive"
    @handle-remove="showDeleteDialog"
    v-loading="isUserInfoLoading"
    :element-loading-text="loadingText"
    :visibleDeactivate="
      checkIfAllowedSelfAction && activateOrDeactivateBtnIsVisible
    "
    :visibleRemove="checkIfAllowedSelfAction"
  >
    <el-tabs
      v-model="activeName"
      @tab-click="handleClick"
      style="margin: 0px 20px"
      class="detail-tabs"
    >
      <el-tab-pane
        :label="$t('userModule.generalInfo')"
        name="first"
        style="overflow: auto; height: 730px"
      >
        <user-info :userInfo="userInfo" />
      </el-tab-pane>
      <el-tab-pane :label="$t('userModule.moduleAccess')" name="second">
        <div style="margin-left: 60px">
          <module-access
            :authorizableResources="modulesInfo"
            :defaultChecked="defaultChecked"
            :isEdit="false"
          ></module-access>
        </div>
      </el-tab-pane>
    </el-tabs>

    <el-dialog
      :visible="deleteDialogVisible"
      class="new-cust-dialog"
      :width="'600px'"
      :top="'25vh'"
      :title="`${$t('userModule.removeUser')} ${$t(title)}`"
      @close="closeDeleteDialog"
    >
      <div class="d-flex ai-center" style="flex-direction: column">
        <div style="margin-top: 20px">
          <img src="@/assets/imgs/deactivate.svg" style="width: 48px" />
        </div>
        <div style="margin: 10px 30px 30px; font: 400 20px/36px Roboto">
          <span style="white-space: nowrap">{{
            $t('userModule.removeInfo', { name: title })
          }}</span>
        </div>
        <div style="margin: 10px 0px">
          <el-button
            class="deactivate-btn"
            style="margin-right: 30px"
            type="plain"
            @click="closeDeleteDialog"
            >{{ $t('customerModule.cancel') }}</el-button
          >
          <el-button
            class="deactivate-btn"
            type="info"
            @click="handleUserDelete"
            >{{ $t('userModule.removeUser') }}</el-button
          >
        </div>
      </div>
    </el-dialog>

    <deactivate-dialog
      :visiable.sync="visiable"
      :activateOrDeactivate="activateOrDeactivate"
      :title="'userModule.user'"
      :content="dialogContent"
      @handle-cancel="handleCancel"
      @handle-deactivate="handleDeactive"
    />
  </new-card>
</template>

<style lang="scss" scoped>
.new-cust-dialog :deep(.el-dialog__title) {
  font-size: 20px;
  font-family: var(--fontRobotoMedium);
  line-height: 19px;
  color: #373e41;
}

.new-cust-dialog :deep(.el-dialog__headerbtn) {
  top: 10px;
  font-size: 30px;
  color: #373d41;
  position: static;
}

.new-cust-dialog :deep(.el-dialog__header) {
  padding: 0px;
  padding-bottom: 0px;
  background-color: var(--Main) !important;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 20px;
  padding-right: 16px;
  height: 44px;
}

.el-button--plain:hover {
  color: #ffffff;
  border-color: #5f6567;
  background-color: #5f6567;
}

.new-cust-dialog :deep(.el-dialog__headerbtn:hover .el-dialog__close) {
  color: #5f6567;
}

.deactivate-btn {
  width: 160px;
  margin-right: 30px;
  font-family: $font-Roboto-Medium;
  font-size: 16px !important;
  border: 1px solid #373e41;
}
</style>
