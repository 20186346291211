<script setup lang="ts" setup>
import { PerformedMaintenanceAppointments } from '@/api/maintenance';
import i18n from '@/lang';
import { computed } from 'vue';
import Pagination from '@/components/pagination/Pagination.vue';
import { SorterOrder } from '@/model/queryParameters/QueryParameter';
import { Col } from '@/utils/workData/maintenanceConf';

const props = defineProps<{
  tableData: PerformedMaintenanceAppointments[];
  cols: Col[];
  pageSize?: number;
  total?: number;
}>();

const emit = defineEmits<{
  (e: 'handlePage', page: number, pageSize: number): void;
  (e: 'showDetailsModal', row: PerformedMaintenanceAppointments): void;
  (e: 'handleSortChange', field: string, order: SorterOrder): void;
}>();

const listQuery = {
  page: 1,
  limit: props.pageSize,
};

const tableDataWithAssetTypeTranslated = computed(() => {
  return props.tableData.map((td) => ({
    ...td,
    assetTypeTranslated: i18n.tc(td.assetType),
  }));
});

function getList(val: any) {
  if (props.pageSize) {
    emit('handlePage', val.page, props.pageSize);
  }
}

function showDetailsModal(row: PerformedMaintenanceAppointments) {
  emit('showDetailsModal', row);
}

function handleSortChange(val: { order: string; prop: string }) {
  if (val.prop === 'assetTypeTranslated') {
    val.prop = 'assetType';
  }
  if (val.order === 'ascending') {
    emit('handleSortChange', val.prop, SorterOrder.ASC);
  } else if (val.order === 'descending') {
    emit('handleSortChange', val.prop, SorterOrder.DESC);
  }
}
</script>

<template>
  <div class="table-container">
    <el-table
      :data="tableDataWithAssetTypeTranslated"
      style="width: 100%"
      @row-click="showDetailsModal"
      @sort-change="handleSortChange"
    >
      <el-table-column
        v-for="(col, index) in props.cols"
        :key="`col_${index}`"
        :prop="col.prop"
        :sortable="col.sortable"
        :label="$tc(col.label)"
        show-overflow-tooltip
      />
    </el-table>
    <Pagination
      v-show="props.total && props.pageSize && props.total > props.pageSize"
      :total="props.total"
      :page.sync="listQuery.page"
      :limit.sync="listQuery.limit"
      @pagination="getList"
    />
  </div>
</template>

<style lang="scss" scoped>
.table-container {
  overflow: auto;
}

.el-table tbody tr:hover > td {
  /* background-color: rgb(var(--Main), 0.6) !important; */
  background-color: var(--Main) !important;
  /* opacity: 0.5; */
  /* cursor: pointer; */
}

:deep(.el-table tr) {
  cursor: pointer;
}

.el-table th {
  border-top: 1px solid #dddddd;
  border-bottom: 1px solid #dddddd !important;
}

.el-table td {
  border-bottom: 1px solid #dddddd !important;
}

.el-table {
  font-size: 14px !important;
  font-family: var(--fontRobotoRegular);
  line-height: 16px;
  color: #373e41 !important;
  opacity: 1;
}

.el-table thead {
  font-size: 14px !important;
  font-family: var(--fontRobotoMedium);
  line-height: 16px;
  color: #373e41 !important;
  opacity: 1;
}

:deep(.el-table .sort-caret.ascending) {
  border-bottom-color: #000000;
}

:deep(.el-table .sort-caret.descending) {
  border-top-color: #000000;
}

:deep(.el-table .ascending .sort-caret.ascending) {
  border-bottom-color: var(--Main);
}

:deep(.el-table .descending .sort-caret.descending) {
  border-top-color: var(--Main);
}

:deep(.el-table .cell) {
  word-break: break-word;
  white-space: nowrap;
  width: auto !important;
}
</style>
