<script lang="ts" setup>
import { UUID } from '@/api/common';
import { useSelectedCustomerInfo } from '@/auth/selectedCustomer';
import { useLoggedInUser } from '@/auth/user';
import OrganizationTreeSelect from '@/components/select/OrganizationTreeSelect.vue';
import { useAsync } from '@/composables/async';
import { showNotificationOnError } from '@/composables/error';
import { mapDeep } from '@/utils/collections';
import { mapOrganizationSummaryToDropdownItem } from '@/utils/org';
import { computed, ref } from 'vue';
import { OrganizationDropdownItem } from './models';

const { selectedCustomer, updateSelectedCustomer, companies } =
  useSelectedCustomerInfo();
const loggedInUser = useLoggedInUser();
const updating = ref<Promise<void> | undefined>();
const updatingState = useAsync(updating);

interface IdMap {
  orgId: UUID;
  customerId: UUID;
}

const orgToCustomerMap = computed((): Map<UUID, UUID> => {
  const customerSummaries = companies.value.data ?? [];

  const idMaps = customerSummaries.flatMap((customer): IdMap[] => {
    if (!customer.primaryOrganization) {
      // This shouldn't happen, because our component should only be called when the
      // full organization structure is determined.
      throw new Error('Primary organization must be defined');
    }
    // Return pairs of [organizationId, customerId] for all orgs of this customer
    return mapDeep(
      customer.primaryOrganization,
      'organizations',
      (org): IdMap => ({
        orgId: org.organizationId,
        customerId: customer.customerId,
      })
    );
  });
  return new Map(idMaps.map((idMap) => [idMap.orgId, idMap.customerId]));
});

const customers = computed((): OrganizationDropdownItem[] | undefined => {
  return companies.value.data?.map((company) =>
    mapOrganizationSummaryToDropdownItem(company.primaryOrganization!)
  );
});

function handleSelectOrganizationId(selectedOrgId: UUID | undefined) {
  if (selectedOrgId === undefined) {
    updating.value = updateSelectedCustomer(undefined);
    return;
  }

  const selectedCustomerId = orgToCustomerMap.value.get(selectedOrgId);
  if (!selectedCustomerId) {
    throw new Error('unknown organization ID');
  }

  updating.value = updateSelectedCustomer(selectedCustomerId, selectedOrgId);
}

showNotificationOnError(updatingState, 'navbar.selectCustomerFailed');
</script>

<template>
  <OrganizationTreeSelect
    v-loading="customers === undefined || updatingState.loading"
    :selected="selectedCustomer?.organization?.id"
    :dropdown-items="customers ?? []"
    :filterable="true"
    :show-all="!loggedInUser?.mustImpersonate"
    @change="handleSelectOrganizationId"
  />
</template>

<style lang="scss" scoped></style>
