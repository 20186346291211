import { api } from '@/utils/api';
import { getRequest } from '@/utils/request';
import { USER_DATE_TIME_FORMAT } from '@/utils/time';
import { UnitSystem } from '@/utils/units/systemConversionDefinitions';
import {
  ACTIVATION_STATUS,
  ALL_CLAIMS_CODES,
  COMPANY_TYPE,
  LANGUAGE,
  THEME,
  UserRole,
} from '@/utils/workData/lookuptable';
import moment from 'moment';
import { UTCTimestamp, UUID } from './common';
import { CommonResultPromise } from './commonResult';
import { PolicyType } from './policies';
import { IActivationStatus } from './types';

export enum UserConsentStatus {
  CONSENTED = 'PLCS_CONSENTED',
  NOT_CONSENTED = 'PLCS_NOT_CONSENTED',
  CONSENT_REFUSED = 'PLCS_CONSENT_REFUSED',
}

export interface UserConsent {
  policyType: PolicyType;
  consentStatus?: UserConsentStatus;
  consentTime?: string;
  policyVersionId: string;
  policyVersion: string;
}

/**
 * Collection of user's current response (agree/disagree) to each required consent policy type.
 */
export type UserConsentStatuses = Partial<
  Record<PolicyType, UserConsentStatus>
>;

/**
 * Collection of required policies that user will need to consent to,
 * and the user's current consent status of them, if any.
 */
export type UserConsents = Partial<Record<PolicyType, UserConsent>>;

export function getUsers(
  filter: string
): CommonResultPromise<FetchUsersResponse> {
  const request = getRequest();
  const response = request.request({
    url: `/users${filter}`,
    method: 'get',
  });
  return response;
}

export function getUserById(id: string): CommonResultPromise<User> {
  const request = getRequest();
  const response = request.request({
    url: `/users/${id}`,
    method: 'get',
  });
  return response;
}

export async function fetchUserById(id: UUID): Promise<User> {
  return api().get(`/users/${id}`);
}

export function createUser(data: any): CommonResultPromise {
  const request = getRequest();
  const response = request.request({
    url: `/users`,
    method: 'post',
    data,
  });
  return response;
}

export function deleteUser(id: string): CommonResultPromise {
  const request = getRequest();
  const response = request.request({
    url: `/users/${id}`,
    method: 'delete',
  });
  return response;
}

export function updateUser(id: string, data: any): CommonResultPromise {
  const request = getRequest();
  const response = request.request({
    url: `/users/${id}`,
    method: 'put',
    data,
  });
  return response as any as CommonResultPromise;
}

export async function getUserSettingById(id: string): Promise<UserSettings> {
  return api().get<UserSettings>(`/users/${id}/setting`);
}

export async function getUserConsents(id: string): Promise<UserConsents> {
  const request = getRequest();
  const response = await request.get<UserConsent[]>(`/user-consent/${id}`);
  return Object.fromEntries(
    response.data.map((userConsent) => [userConsent.policyType, userConsent])
  );
}

export async function updateUserConsent(
  userId: string,
  userConsentStatuses: UserConsentStatuses
): Promise<UserConsents> {
  const request = getRequest();
  const response = await request.post<UserConsent[]>(
    `user-consent/${userId}`,
    userConsentStatuses
  );
  return Object.fromEntries(
    response.data.map((userConsent) => [userConsent.policyType, userConsent])
  );
}

export function updateUserSetting(
  userUUID: string,
  requestBody: UserSettings
): Promise<UserSettings> {
  return api().put<UserSettings, UserSettings>(
    `/users/${userUUID}/setting`,
    requestBody
  );
}

export function resetPassword(id: string, data: any): CommonResultPromise {
  const request = getRequest();
  const response = request.request({
    url: `/users/${id}/password:reset`,
    method: 'put',
    data,
  });
  return response;
}

export function getPermissions(): CommonResultPromise {
  const request = getRequest();
  const response = request.request({
    url: `users/permissions`,
    method: 'get',
  });
  return response;
}

export function updateLastLoginTime(id: string): CommonResultPromise {
  const request = getRequest();
  const response = request.request({
    url: `/users/${id}/lastlogintime`,
    method: 'put',
  });
  return response;
}

export function updateActivationStatus(
  id: string,
  data: IActivationStatus
): CommonResultPromise {
  const request = getRequest();
  const response = request.request({
    url: `/users/${id}/activation`,
    method: 'put',
    data,
  });
  return response;
}

export function validateToken(params: any): CommonResultPromise {
  const request = getRequest();
  const response = request.request({
    url: '/user/password:validatetoken',
    method: 'post',
    params,
  });
  return response;
}

export function getModules(): CommonResultPromise {
  const request = getRequest();
  const response = request.request({
    url: '/authorizableResources',
    method: 'get',
  });
  return response;
}

export function copyUserTemplateFromCompany(
  userId: string
): CommonResultPromise {
  const request = getRequest();
  const response = request.request({
    url: `users/${userId}/template/copy`,
    method: 'post',
  });
  return response;
}

export type SearchHistoryEntry = {
  order: number;
  name: string;
};

export function getUserSearchHistory(): CommonResultPromise<
  SearchHistoryEntry[]
> {
  const request = getRequest();
  const response = request.get<SearchHistoryEntry[]>(
    '/user-settings/searched-asset-names'
  );
  return response;
}

export function saveUserSearchHistory(
  history: SearchHistoryEntry[]
): CommonResultPromise {
  const request = getRequest();
  const response = request.post('/user-settings/searched-asset-names', history);
  return response;
}

export interface FetchUsersResponse {
  count: number;
  total: number;
  links: LinkModel[];
  users: UserListInformation[];
}

export interface LinkModel {
  href: string;
}

export interface UserListInformation {
  id: string;
  firstName: string;
  lastName: string;
  username: string;
  email: string;
  mobilePhone: string;
  companyId: string;
  tenants: string;
  organizationId: string;
  note: string;
  createdOn: string;
  i18nCode: string;
  emailVerified: boolean;
  activationStatus: string;
  organizationName: string;
  role: string;
  roleName: string;
  companyType: string;
  claims: {
    resource: string;
    actions: string[];
  }[];
  policyRegion: string;
}

export interface User {
  id: UUID;
  firstName?: string;
  lastName?: string;
  username: string;
  email: string;
  mobilePhone?: string;
  companyId: UUID;
  tenants: UUID; // TODO Legacy?
  organizationId: string;
  note?: string;
  createdOn: UTCTimestamp;
  i18nCode: LANGUAGE;
  emailVerified: boolean;
  activationStatus: ACTIVATION_STATUS;
  organizationName: string;
  /**
   * Role code (e.g. 'ROLES_CUST_ADMIN').
   */
  role: UserRole;
  /**
   * Human-readable name of role.
   */
  roleName: string;
  companyType: COMPANY_TYPE;
  claims: Claim[];
  theme: THEME;
  setting: UserSettingsResponse;
  modules: Module[];
  pages: Page[];
  /**
   * Policy region code, e.g. 'POLRGN_EU'
   */
  policyRegion: string;
  phone?: string; // TODO Doesn't seem to exist
}

export type AuthorizableResourceCode = ALL_CLAIMS_CODES;
export type AuthorizableActionCode = string;

export interface Claim {
  resource: AuthorizableResourceCode;
  actions: AuthorizableActionCode[];
}

export interface AuthorizableResource<
  C = ALL_CLAIMS_CODES,
  P = ALL_CLAIMS_CODES | ''
> {
  /**
   * UUID of Authorizable resource.
   */
  id: string;

  /**
   * Authorizable resource code, i.e. AUTHRSC_*.
   */
  code: C;

  /**
   * Parent authorizable resource code, i.e. AUTHRSC_*, or empty string
   * if this is a top-level resource.
   */
  parentCode: P;
}

// TODO Perhaps split this one to allow only AUTHRSC_MOD_* codes.
export type ALL_MODULE_CODES = ALL_CLAIMS_CODES;

// TODO Perhaps split this one to allow only AUTHRSC_PAGE_* codes.
export type ALL_PAGE_CODES = ALL_CLAIMS_CODES;

export type Module = AuthorizableResource<
  ALL_MODULE_CODES,
  ALL_MODULE_CODES | ''
>;

export type Page = AuthorizableResource<ALL_PAGE_CODES, ALL_MODULE_CODES>;

/**
 * Interface used when the current user's information is fetched at login.
 */
export interface UserSettings {
  gridPageSize: number;
  datetimeFormat: string;
  timeZone: string;
  unitSystem: UnitSystem;
  i18nCode: LANGUAGE;
}

export function makeDefaultUserSettings(): UserSettings {
  return {
    datetimeFormat: USER_DATE_TIME_FORMAT,
    gridPageSize: 20,
    timeZone: moment.tz.guess(),
    unitSystem: UnitSystem.Metric,
    i18nCode: LANGUAGE.EN_US,
  };
}

export interface UserSettingsResponse extends Omit<UserSettings, 'i18nCode'> {
  userId: UUID;
  companyId: UUID;
}
