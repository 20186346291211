import AssetStatus from '@/widgets/home/AssetStatus.vue';
import Events from '@/widgets/home/Events.vue';
import DashboardMap from '@/widgets/home/Map.vue';
import UecEvents from '@/widgets/home/UecEvents.vue';

import NewAssetSafety from '@/widgets/asset/kpis/safety/NewAssetSafety.vue';
import FleetEfficiency from '@/widgets/fleet/FleetEfficiency.vue';
import FleetPayload from '@/widgets/fleet/FleetPayload.vue';
import FleetPerformance from '@/widgets/fleet/FleetPerformance.vue';
import FleetPower from '@/widgets/fleet/FleetPower.vue';
import FleetProductivity from '@/widgets/fleet/FleetProductivity.vue';
import FleetSafety from '@/widgets/fleet/FleetSafety.vue';
import FleetStatus from '@/widgets/fleet/FleetStatus.vue';
import FleetWorkingHours from '@/widgets/fleet/FleetWorkingHours.vue';
import PayloadMonitoring from '@/widgets/fleet/payloadMonitoring/PayloadMonitoring.vue';
import NewFleetSafety from '@/widgets/fleet/safety/NewFleetSafety.vue';

import AssetEfficiency from '@/widgets/asset/kpis/AssetEfficiency.vue';
import AssetOverview from '@/widgets/asset/kpis/AssetOverview.vue';
import AssetPayload from '@/widgets/asset/kpis/AssetPayload.vue';
import AssetPower from '@/widgets/asset/kpis/AssetPower.vue';
import AssetProductivity from '@/widgets/asset/kpis/AssetProductivity.vue';
import AssetRealTime from '@/widgets/asset/kpis/AssetRealTime.vue';
import AssetTripLog from '@/widgets/asset/kpis/AssetTripLog.vue';
import AssetWorkingHours from '@/widgets/asset/kpis/AssetWorkingHours.vue';

import AssetBasicInfo from '@/widgets/asset/info/AssetBasicInfo.vue';
import OperationalDashboardAssetBasicInfo from '@/widgets/asset/info/OperationalDashboardAssetBasicInfo.vue';

import AssetMaintenanceHistory from '@/widgets/asset/maintenance/AssetMaintenanceHistory.vue';
import AssetMaintenanceStatusList from '@/widgets/asset/maintenance/AssetMaintenanceStatusList.vue';
import MaintenancePerformed from '@/widgets/maintenance/MaintenancePerformed.vue';
import MaintenancePlanList from '@/widgets/maintenance/MaintenancePlanList.vue';
import MaintenanceStatus from '@/widgets/maintenance/MaintenanceStatus.vue';

import ParameterConfiguration from '@/widgets/asset/parameters/ParameterConfiguration.vue';
import AssetModelSpec from '@/widgets/asset/specifications/AssetModelSpec.vue';
import AssetPartsInfo from '@/widgets/asset/specifications/AssetPartsInfo.vue';

export enum WidgetKey {
  AssetsStatus = 'WIDGET_ASSETSSTATUS',
  Map = 'WIDGET_MAP',
  Events = 'WIDGET_EVENTS',
  MCompactorParamConfig = 'WIDGET_MCOMPACTOR_PARAM_CONFIG',
  SCompactorParamConfig = 'WIDGET_SCOMPACTOR_PARAM_CONFIG',
  TippingVehiclesParamConfig = 'WIDGET_TIPPING_VEHICLES_PARAM_CONFIG',
  RCVParamConfig = 'WIDGET_RCV_PARAM_CONFIG',
  AlbaSCompactorParamConfig = 'WIDGET_ALBA_SCOMPACTOR_PARAM_CONFIG',
  GenericAssetParamConfig = 'WIDGET_GENERIC_ASSET_PARAM_CONFIG',
  OperTippingVehiclesParamConfig = 'WIDGET_OPER_TIPPING_VEHICLES_PARAM_CONFIG',
  ActStatus = 'WIDGET_ACTSTATUS',
  FleetWorkingHours = 'WIDGET_FLEET_WORKING_HOURS',
  FleetPayload = 'WIDGET_FLEET_PAYLOAD',
  FleetPowerConsumption = 'WIDGET_FLEET_POWER_CONSUMPTION',
  FleetProductivity = 'WIDGET_FLEET_PRODUCTIVITY',
  FleetEfficiency = 'WIDGET_FLEET_EFFICIENCY',
  TippingFleetSafety = 'WIDGET_TIPPING_FLEET_SAFETY',
  TippingNewFleetSafety = 'WIDGET_TIPPING_NEW_FLEET_SAFETY',
  TippingPerformance = 'WIDGET_TIPPING_PERFORMANCE',
  TippingFleetTarget = 'WIDGET_TIPPING_FLEET_TARGET',
  Realtime = 'WIDGET_REALTIME',
  WorkingHours = 'WIDGET_WORKING_HOURS',
  Payload = 'WIDGET_PAYLOAD',
  PowerConsumption = 'WIDGET_POWER_CONSUMPTION',
  Productivity = 'WIDGET_PRODUCTIVITY',
  Efficiency = 'WIDGET_EFFICIENCY',
  TripLog = 'WIDGET_TRIP_LOG',
  TippingAssetOverview = 'WIDGET_TIPPING_ASSET_OVERVIEW',
  TippingSafety = 'WIDGET_TIPPING_SAFETY',
  TippingNewSafety = 'WIDGET_TIPPING_NEW_SAFETY',
  TippingTarget = 'WIDGET_TIPPING_TARGET',
  Basicinfo = 'WIDGET_BASICINFO',
  OperationLog = 'WIDGET_OPERATION_LOG',
  MaintenanceStatus = 'WIDGET_MAINTEN_STATUS',
  MaintenancePerformed = 'WIDGET_MAINTEN_PERFORMED',
  MaintenanceHistory = 'WIDGET_MAINTEN_HISTORY',
  MaintenancePlanList = 'WIDGET_MAINTEN_PLAN_LIST',
  ModelSpec = 'WIDGET_MODEL_SPEC',
  Partsinfo = 'WIDGET_PARTSINFO',
  UecEvents = 'WIDGET_UEC_EVENTS',
  OperBasicInfo = 'WIDGET_OPER_BASICINFO',
}

// TODO: merge fleet & kpi widgets (e.g. fleet work hours & asset work hours, code is like 90% the same)
// TODO: merge small & expanded widgets into same component (if it makes sense & is doable)
/* @ts-expect-error Need proper type for SFC components */
export const WIDGET_CODE_MAP = new Map<WidgetKey, CodeItem | typeof Vue>([
  // home
  [WidgetKey.AssetsStatus, AssetStatus],
  [WidgetKey.Map, DashboardMap],
  [WidgetKey.Events, Events],

  [WidgetKey.MCompactorParamConfig, ParameterConfiguration],
  [WidgetKey.SCompactorParamConfig, ParameterConfiguration],
  [WidgetKey.TippingVehiclesParamConfig, ParameterConfiguration],
  [WidgetKey.RCVParamConfig, ParameterConfiguration],
  [WidgetKey.AlbaSCompactorParamConfig, ParameterConfiguration],
  [WidgetKey.GenericAssetParamConfig, ParameterConfiguration],
  [WidgetKey.OperTippingVehiclesParamConfig, ParameterConfiguration],

  // fleet
  [WidgetKey.ActStatus, FleetStatus],
  [WidgetKey.FleetWorkingHours, FleetWorkingHours],
  [WidgetKey.FleetPayload, FleetPayload],
  [WidgetKey.FleetPowerConsumption, FleetPower],
  [WidgetKey.FleetProductivity, FleetProductivity],
  [WidgetKey.FleetEfficiency, FleetEfficiency],
  [WidgetKey.TippingFleetSafety, FleetSafety],
  [WidgetKey.TippingNewFleetSafety, NewFleetSafety],
  [WidgetKey.TippingPerformance, FleetPerformance],
  [WidgetKey.TippingFleetTarget, PayloadMonitoring],

  // kpis
  [WidgetKey.Realtime, AssetRealTime],
  [WidgetKey.WorkingHours, AssetWorkingHours],
  [WidgetKey.Payload, AssetPayload],
  [WidgetKey.PowerConsumption, AssetPower],
  [WidgetKey.Productivity, AssetProductivity],
  [WidgetKey.Efficiency, AssetEfficiency],
  [WidgetKey.TripLog, AssetTripLog],
  [WidgetKey.TippingAssetOverview, AssetOverview],
  [WidgetKey.TippingSafety, FleetSafety],
  [WidgetKey.TippingNewSafety, NewAssetSafety],
  [WidgetKey.TippingTarget, PayloadMonitoring],

  // asset info
  [WidgetKey.Basicinfo, AssetBasicInfo],
  [WidgetKey.OperationLog, Events],

  // maintenance
  [WidgetKey.MaintenanceStatus, MaintenanceStatus],
  [WidgetKey.MaintenancePerformed, MaintenancePerformed],
  [WidgetKey.MaintenanceHistory, AssetMaintenanceHistory],
  [
    WidgetKey.MaintenancePlanList,
    {
      '*': AssetMaintenanceStatusList,
      AUTHRSC_PAGE_MAINT_OVERVIEW: MaintenancePlanList,
    },
  ],

  // specifications
  [WidgetKey.ModelSpec, AssetModelSpec],
  [WidgetKey.Partsinfo, AssetPartsInfo],

  [WidgetKey.UecEvents, UecEvents],
  [WidgetKey.OperBasicInfo, OperationalDashboardAssetBasicInfo],
]);
