import { QueryParameter } from '@/model/queryParameters/QueryParameter';
import { api } from '@/utils/api';
import { AssetType } from '@/utils/assetTypes';
import { KPI_UNIT } from '@/utils/units/unitDefinitions';
import { LANGUAGE } from '@/utils/workData/lookuptable';
import { UUID } from './common';

export type PropertyCode = string;

export interface ConfigurationPresetValue {
  propertyCode: PropertyCode;
  value: number | string;
}

export interface DetailedConfigurationPresetValue
  extends ConfigurationPresetValue {
  propertyName: string;
  unit: KPI_UNIT;
}

export interface ConfigurationPreset {
  id: UUID;
  name: string;
  assetType: AssetType;
  configurationParameters: ConfigurationPresetValue[];
}

export interface SimplePreset {
  id: UUID;
  name: string;
  assetType: AssetType;
  companyId: UUID;
  companyName: string;
}

export interface DetailedPreset extends SimplePreset {
  configurationParameters: DetailedConfigurationPresetValue[];
}

export type CreateConfigurationPreset = Omit<ConfigurationPreset, 'id'>;

export function createPreset(
  body: CreateConfigurationPreset
): Promise<ConfigurationPreset> {
  return api().post<ConfigurationPreset, CreateConfigurationPreset>(
    '/configuration-parameters/preset/create',
    body
  );
}

export async function getPresets(
  queryParams: QueryParameter
): Promise<SimplePreset[]> {
  return api().post<SimplePreset[], QueryParameter>(
    '/configuration-parameters/preset',
    queryParams
  );
}

export async function getPreset(
  presetId: UUID,
  i18nCode: LANGUAGE
): Promise<DetailedPreset> {
  return api().get<DetailedPreset>(
    `/configuration-parameters/preset/${presetId}`,
    { params: { i18nCode } }
  );
}
