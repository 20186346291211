<script lang="ts">
import { getPolicies, Policy } from '@/api/policies';
import {
  UserConsents,
  UserConsentStatus,
  UserConsentStatuses,
} from '@/api/users';
import { AsyncValue, useAsync } from '@/composables/async';
import i18n from '@/lang';
import { FilterOperator } from '@/model/queryParameters/QueryParameter';
import { UserModule } from '@/store/modules/user';
import { computed, Ref, unref } from 'vue';
import { Component, Prop, Vue } from 'vue-property-decorator';

interface UserConsentCheckbox {
  policy: Policy;
  isConsented: boolean;
}

async function getPoliciesAndBuildCheckboxes(
  policyRegion: string,
  locale: string,
  userConsents: UserConsents
): Promise<UserConsentCheckbox[]> {
  const policies = await getPolicies({
    filters: [
      {
        name: 'policyRegionCode',
        operator: FilterOperator.EQUAL,
        value: [policyRegion],
      },
      {
        name: 'companyType',
        operator: FilterOperator.EQUAL,
        value: [UserModule.companyType],
      },
      {
        name: 'i18nCode',
        operator: FilterOperator.EQUAL,
        value: [locale],
      },
    ],
  });
  return Object.values(policies).map(
    (policy): UserConsentCheckbox => ({
      policy,
      isConsented:
        userConsents[policy.code]?.consentStatus ===
        UserConsentStatus.CONSENTED,
    })
  );
}

@Component({
  name: 'TermsAndConditionsDialog',
})
export default class extends Vue {
  @Prop({ default: 'min-content' }) width!: string;
  @Prop() userConsents!: UserConsents;

  checkboxes!: Ref<AsyncValue<UserConsentCheckbox[]>>;

  created() {
    this.checkboxes = useAsync(
      computed(() =>
        getPoliciesAndBuildCheckboxes(
          UserModule.policyRegion,
          i18n.locale,
          this.userConsents
        )
      )
    );
  }

  handleCancelDialog() {
    this.$emit('cancel');
  }

  handleAgree() {
    const checkboxes = unref(this.checkboxes).data;
    if (!checkboxes) {
      return;
    }
    const newUserConsents: UserConsentStatuses = Object.fromEntries(
      checkboxes.map((checkbox) => [
        checkbox.policy.code,
        checkbox.isConsented
          ? UserConsentStatus.CONSENTED
          : UserConsentStatus.NOT_CONSENTED,
      ])
    );
    this.$emit('agree', newUserConsents);
  }
}
</script>

<template>
  <el-dialog
    class="terms-and-conditions-dialog"
    :title="$t('consent.termsAndConditions')"
    :width="width"
    center
    :show-close="false"
    :visible="!!checkboxes.value.data"
  >
    <div>
      <el-checkbox
        v-for="policyCheckbox in checkboxes.value.data"
        :key="policyCheckbox.policy.code"
        :label="policyCheckbox.policy.code"
        v-model="policyCheckbox.isConsented"
        :data-test-id="policyCheckbox.policy.code"
        style="margin: 10px 10px"
      >
        <p
          v-html="
            $t(`consent.policies.${policyCheckbox.policy.code}`, {
              url: policyCheckbox.policy.url,
            })
          "
        />
      </el-checkbox>
    </div>

    <span slot="footer" class="dialog-footer">
      <el-button
        class="terms-dialog-btn"
        type="plain"
        @click="handleCancelDialog"
        >{{ $t('consent.cancelBtn') }}
      </el-button>
      <el-button
        :disabled="
          checkboxes.value.data?.some((checkbox) => !checkbox.isConsented)
        "
        class="terms-dialog-btn"
        type="info"
        @click="handleAgree"
        >{{ $t('consent.agreeBtn') }}</el-button
      >
    </span>
  </el-dialog>
</template>

<style lang="scss" scoped>
:deep(.el-dialog) {
  border-radius: 4px;
}

:deep(.el-dialog__header) {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  padding-bottom: 20px;
  background-color: var(--Main) !important;
}

:deep(.el-dialog__title) {
  font-size: 20px;
  font-family: $font-Roboto-Medium;
  line-height: 19px;
  color: #373e41;
}

:deep(.el-dialog__headerbtn) {
  top: 10px;
  font-size: 30px;
  color: #373d41;
}

:deep(.el-dialog__headerbtn:hover .el-dialog__close) {
  color: #5f6567;
}

:deep(.el-dialog__body) {
  padding: 10px 25px 10px;
}

.terms-dialog-btn {
  width: 160px;
  margin-right: 30px;
  font-family: $font-Roboto-Medium;
  font-size: 16px !important;
  border: 1px solid #373e41;
}
</style>
