<script lang="ts">
import {
  CreateUpdateModalSpecsProperty,
  getProductModelById,
  getProperties,
  ModelSpecsNewProperty,
  Property,
  updateProductModel,
} from '@/api/products';
import { ErrorType } from '@/api/types';
import { customFailedMessage, promptSuccessBox } from '@/utils/prompt';
import {
  PROD_ERROR_CODE_LIST,
  SPEC_UNIT_LIST,
} from '@/utils/workData/lookuptable';
import { Component, Emit, Prop, Vue, Watch } from 'vue-property-decorator';
import { checkNewPropertiesValues } from '../helpers/checkNewPropertiesValues';

@Component({
  name: 'modelSpec',
})
export default class extends Vue {
  @Prop({ required: false }) propertiesPrev!: any;
  @Prop({ required: false }) assetType!: string;
  @Prop({ required: false }) state!: any;

  staticSpecs: Property[] = [];

  newSpecs: Property[] = [];
  unitList = SPEC_UNIT_LIST;

  errorCode = PROD_ERROR_CODE_LIST;
  errorInfos: ErrorType[] = [
    {
      code: '',
      field: '',
      message: '',
    },
  ];

  entityTypeProductModel = 'ENTT_PRODUCT_MODEL';

  mounted() {
    if (this.state === 'create' && this.$route.params.action != 'copy') {
      this.getAllProperties(this.entityTypeProductModel, this.assetType);
    }
  }

  get errNameInfo() {
    let errInfo: string = '';
    if (
      this.errorInfos.find((item) => item.field === this.errorCode.property)
        ?.code === 'ApiErrorFieldNotFound'
    ) {
      errInfo = `${this.$t('prodCata.propertyNotFound')}`;
    }
    if (
      this.errorInfos.find((item) => item.field === this.errorCode.units)
        ?.code === 'ApiErrorFieldNotFound'
    ) {
      errInfo = `${this.$t('prodCata.unitsNotFound')}`;
    }

    if (
      this.errorInfos.find((item) => item.field === this.errorCode.assetType)
        ?.code === 'ApiErrorFieldDuplicate'
    ) {
      errInfo = `${this.$t('prodCata.propertyDuplicate')}`;
    }
    return errInfo === '' ? this.errorInfos[0].message : errInfo;
  }

  async getAllProperties(entityType: string, assetType: string) {
    const res = await getProperties(entityType, assetType);
    if (res.code === 200) {
      this.staticSpecs = res.data;
      this.newSpecs = [];
    }
  }

  @Watch('assetType')
  private watchAssetTypeChange(value: string, oldVal: any) {
    if (this.state === 'create' && this.$route.params.action != 'copy') {
      this.getAllProperties(this.entityTypeProductModel, value);
    }
  }

  @Watch('propertiesPrev', { immediate: true, deep: true })
  watchSpecsInfo(value: any, oldVal: any) {
    if (this.$route.params.action || this.$route.params.action === 'copy') {
      //the specification form is in the copy state
      this.filterProperties(this.propertiesPrev.properties);
    }
  }

  //send specData to parent component
  @Emit('send-modal-spec')
  sendSpecData() {
    return this.filterMatchAllProperties('create');
  }

  async updateProperties(id: any) {
    const res = await getProductModelById(id);
    if (!res.data.properties || res.data.properties?.length === 0) {
      //the user has edited the assetType
      this.getAllProperties(
        this.entityTypeProductModel,
        res.data.assetTypeCode
      );
    } else {
      this.filterProperties(res.data.properties);
    }
  }

  filterProperties(oldProperties: Property[]) {
    if (oldProperties?.length > 0) {
      let oldStaticProperties = oldProperties.filter((item) => {
        return item.isPreDefined === true;
      });

      this.staticSpecs =
        oldStaticProperties.length > 0 ? oldStaticProperties : [];
      let oldButNotStaticProperties = oldProperties.filter((item) => {
        return !item.isPreDefined;
      });
      this.newSpecs =
        oldButNotStaticProperties.length > 0 ? oldButNotStaticProperties : [];
    } else {
      this.staticSpecs = [];
      this.newSpecs = [];
    }
  }

  filterMatchAllProperties(type: string): {
    properties: CreateUpdateModalSpecsProperty[];
    newProperties: ModelSpecsNewProperty[];
  } {
    const allProperties: Property[] = [...this.staticSpecs, ...this.newSpecs];
    const oldProperties: CreateUpdateModalSpecsProperty[] =
      allProperties.reduce(
        (accumulator: CreateUpdateModalSpecsProperty[], item: Property) => {
          if (item.keyId) {
            if (type === 'create') {
              accumulator.push({
                propertyKeyId: item.keyId,
                value: item.value,
              });
            }
            if (type === 'save') {
              if (!!item.isPreDefined) {
                accumulator.push({
                  id: item.keyId,
                  value: item.value,
                });
              } else {
                accumulator.push({
                  id: item.keyId,
                  value: item.value,
                  unit: item.unit,
                  name: item.name,
                });
              }
            }
          }

          return accumulator;
        },
        []
      );

    const newProperties: ModelSpecsNewProperty[] = this.newSpecs.filter(
      (item: any) => {
        return !item.keyId;
      }
    );

    let sendSpecs = {
      properties: oldProperties,
      newProperties,
    };

    return sendSpecs;
  }

  async updateModelSpec(productModelId: string) {
    try {
      const requestPayload = new FormData();
      requestPayload.append('productModelPicture', '');
      const specs = this.filterMatchAllProperties('save');
      if (specs.newProperties.length) {
        const errors = checkNewPropertiesValues(specs.newProperties);
        if (errors.length) {
          customFailedMessage(errors.join(', '));
          return;
        }
      }

      requestPayload.append(
        'productModelRequestBody',
        new Blob([JSON.stringify(specs)], {
          type: 'application/json',
        })
      );

      const res = await updateProductModel(productModelId, requestPayload);
      if (res.code === 200) {
        promptSuccessBox(this.$t('common.updated') as string);
        this.errorInfos = [];
        this.$router.push('/prod-cata/index?activeName=productModels');
      } else if (res.code === 400) {
        // customFailedMessage(this.$t('common.save') as string);
        // this.errorInfos = res.data.errors[0].message;
        /* @ts-expect-error TODO Wrong type */
        this.errorInfos = [...res.data.errors[0]];
        customFailedMessage(this.errNameInfo as any);
      }
    } catch (error) {
      console.log(error);
    } finally {
    }
  }

  addNewEmptySpec() {
    this.newSpecs.push({} as Property);
  }
}
</script>

<template>
  <div class="model_spec">
    <!-- static area -->
    <div class="unit_line" v-for="item in staticSpecs" :key="item.keyId">
      <div class="label_title">
        <span v-show="item.isPreDefined">{{ $t(item.code) }}</span>
        <span v-show="!item.isPreDefined">{{ item.name }}</span>
      </div>
      <div class="label_unit">
        <span>{{ item.unit && $te(item.unit) ? $t(item.unit) : '' }}</span>
      </div>
      <div class="label_value">
        <el-input
          :id="item.keyId + '_prod_model_create'"
          v-model="item.value"
          size="medium"
          :placeholder="$t('prodCata.inputSpecValue')"
        ></el-input>
      </div>
    </div>

    <!-- add spec area -->
    <div class="unit_line" v-for="(item, index) in newSpecs" :key="index">
      <div class="new_label_title">
        <el-input
          id="prod_model_create_spec_name"
          v-model="item.name"
          size="medium"
          :placeholder="$t('prodCata.inputSpecName')"
        ></el-input>
      </div>
      <div class="new_label_unit">
        <el-select
          id="prod_model_create_spec_unit"
          v-model="item.unit"
          v-bind:placeholder="$t('prodCata.selectUnit')"
        >
          <el-option
            v-for="(item, index) in unitList"
            :key="index"
            :label="$t(item.value)"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </div>
      <div class="label_value">
        <el-input
          id="prod_model_create_spec_value"
          v-model="item.value"
          size="medium"
          :placeholder="$t('prodCata.inputSpecValue')"
        ></el-input>
      </div>
      <div
        id="model_spec_delete"
        class="model_spec_icon"
        @click="newSpecs.splice(index, 1)"
      >
        <i class="el-icon-remove rules-delete" />
      </div>
    </div>

    <div
      id="model_spec_rule_add"
      style="width: 240px; margin-top: 20px; cursor: pointer"
      @click="addNewEmptySpec"
    >
      <i class="el-icon-circle-plus add-rules-plus" />
      <span class="add-rules">{{ $t('prodCata.addSpecs') }}</span>
    </div>
  </div>
</template>

<style lang="scss" scoped>
$green: #349d99;

.model_spec {
  margin: 40px 0 0 12px;
}
.label_title {
  width: 280px;
  border: 1px solid #373e41;
  padding: 11px 20px;
}
.new_label_title {
  width: 280px;
  margin-bottom: 20px;
}

.label_unit {
  width: 180px;
  border: 1px solid #afb1b3;
  padding: 11px 20px;
  margin: 0 40px 0 20px;
}

.new_label_unit {
  width: 180px;
  margin: 0 40px 0 20px;
}

.label_title,
.label_unit {
  border-radius: 4px;
  height: 40px;
  margin-bottom: 20px;
  font-size: 16px;
  line-height: 16px;
}

.unit_line {
  display: flex;
}

.label_value {
  width: 280px;
}

.add-rules-plus {
  vertical-align: bottom;
  color: $green;
  padding: 1px;
  font-size: 22px;
}

.add-rules {
  cursor: pointer;
  vertical-align: middle;
  margin-left: 10px;
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
  color: $green;
  opacity: 1;
}

.model_spec_icon {
  margin-left: 20px;
  font-size: 24px;
  line-height: 40px;
}

:deep(.el-input--medium .el-input__inner) {
  width: 100%;
  font-size: 16px;
}

:deep(.el-select, .el-input__inner) {
  width: 100%;
  font-size: 16px;
}
</style>
