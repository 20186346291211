import { GeofenceAsset } from '@/api/geofenceTypes';
import { LatLngBounds } from 'leaflet';

/**
 * Calculate the bounds of all provided assets, including padding around it (default 10%).
 * @param assets List of assets
 * @param paddingRatio A ratio for padding the result. Defaults to 10%.
 * @returns A valid LatLngBounds if the list contains any assets with valid lat and lan, or an invalid LatLngBounds otherwise
 */
export function calculateAssetBoundsWithPadding(
  assets: Array<GeofenceAsset>,
  paddingRatio?: number
) {
  paddingRatio ??= 0.1; // Default to 10% padding
  let result = assets.reduce(
    (accumulator, asset) =>
      accumulator.extend([asset.geodeticLatitude, asset.geodeticLongitude]),
    new LatLngBounds([])
  );
  if (result.isValid()) {
    result = result.pad(paddingRatio);
  }
  return result;
}
