<script lang="ts">
import { UTCTimestamp } from '@/api/common';
import i18n from '@/lang';
import { KPI_UNIT } from '@/utils/units/unitDefinitions';

export interface TableColumn {
  /**
   * Label need to be already translated
   */
  prop: string;
  label: string;
  isSortable: boolean;
  isInputProp: boolean;
  dateFormat?: string;
}

export interface ParametersTableRow<T = any> {
  propertyName: string;
  metricUnit: KPI_UNIT;
  unitCode: KPI_UNIT;
  inUseValue?: T;
  configuredValue?: T;
  lastChangedTime?: UTCTimestamp;
  lastConfiguredTime: UTCTimestamp;
  propertyCode: string;
  newValue?: T;
  hasValidationError: boolean;
  isWritable: boolean;
}

export const mainPageColumns: TableColumn[] = [
  {
    label: i18n.tc('paramConfig.columns.propertyName'),
    prop: 'propertyName',
    isSortable: true,
    isInputProp: false,
  },
  {
    label: i18n.tc('paramConfig.columns.inUseValue'),
    prop: 'inUseValue',
    isSortable: false,
    isInputProp: false,
  },
  {
    label: i18n.tc('paramConfig.columns.configuredValue'),
    prop: 'configuredValue',
    isSortable: false,
    isInputProp: false,
  },
  {
    label: i18n.tc('paramConfig.columns.unitCode'),
    prop: 'unitCode',
    isSortable: true,
    isInputProp: false,
  },
  {
    label: i18n.tc('paramConfig.columns.lastChangedTime'),
    prop: 'lastChangedTime',
    isSortable: true,
    isInputProp: false,
    dateFormat: 'datetime',
  },
];

export const configureColumns: TableColumn[] = [
  {
    label: i18n.tc('paramConfig.columns.propertyName'),
    prop: 'propertyName',
    isSortable: true,
    isInputProp: false,
  },
  {
    label: i18n.tc('paramConfig.columns.inUseValue'),
    prop: 'inUseValue',
    isSortable: false,
    isInputProp: false,
  },
  {
    label: i18n.tc('paramConfig.columns.configuredValue'),
    prop: 'configuredValue',
    isSortable: false,
    isInputProp: false,
  },
  {
    label: i18n.tc('paramConfig.columns.newValue'),
    prop: 'newValue',
    isSortable: false,
    isInputProp: true,
  },
  {
    label: i18n.tc('paramConfig.columns.unitCode'),
    prop: 'unitCode',
    isSortable: true,
    isInputProp: false,
  },
  {
    label: i18n.tc('paramConfig.columns.lastConfiguredTime'),
    prop: 'lastConfiguredTime',
    isSortable: true,
    isInputProp: false,
    dateFormat: 'datetime',
  },
];

function hasValidationError(param: ParametersTableRow): boolean {
  let isValid = false;
  try {
    const value = parseFloat(param.newValue ?? '');
    if (isFinite(value)) {
      isValid = true;
    }
  } catch {
    /* no-op */
  }
  return !isValid;
}
</script>

<script lang="ts" setup>
export interface TableProps {
  tableData: ParametersTableRow<any>[];
  columns: TableColumn[];
  paginated: boolean;
  pageSize?: number;
  total?: number;
}

const props = defineProps<TableProps>();
</script>

<template>
  <div class="table-containter">
    <el-table :data="tableData">
      <el-table-column
        v-for="(column, index) in columns"
        :key="index"
        :prop="column.prop"
        :label="column.label"
        :sortable="column.isSortable"
      >
        <template v-slot="slotProps" v-if="column.isInputProp">
          <input
            v-if="slotProps.row.isWritable"
            v-model="slotProps.row.newValue"
            @input="
              slotProps.row.hasValidationError = hasValidationError(
                slotProps.row
              )
            "
            :class="
              slotProps.row.hasValidationError
                ? 'input-element-validation'
                : 'input-element'
            "
          />
        </template>

        <template
          v-slot="slotProps"
          v-else-if="
            column.dateFormat &&
            (column.dateFormat === 'date' || column.dateFormat === 'datetime')
          "
        >
          <span>
            {{ slotProps.row[column.prop] | formatTimer(column.dateFormat) }}
          </span>
        </template>

        <template v-slot="slotProps" v-else-if="column.prop === 'unitCode'">
          <span>
            {{ i18n.t(slotProps.row[column.prop]) }}
          </span>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<style lang="scss" scoped>
.table-containter {
  overflow: auto;
  height: 51.5vh;
}
.el-table tbody tr:hover > td {
  background-color: var(--Main) !important;
}

.el-table th {
  border-top: 1px solid #dddddd;
  border-bottom: 1px solid #dddddd !important;
}

.el-table td {
  border-bottom: 1px solid #dddddd !important;
}

.el-table,
.el-table thead {
  font-size: 14px !important;
  font-family: var(--fontRobotoRegular);
  line-height: 16px;
  color: #373e41 !important;
  opacity: 1;
}

.el-table .current-row > td {
  background-color: var(--DropdownHover) !important;
}

.el-table th.el-table__cell > .cell {
  display: flex;
  align-items: center;
}
</style>

<style lang="scss" scoped>
.table-header {
  display: inline-block;
  margin-right: 2px;
}

.table-header-flex {
  display: flex;
  flex-direction: row;
  margin-right: 2px;
}

.module-access {
  margin-left: 4px;
  margin-right: 4px;
}

.module-access-item {
  width: 17px;
  height: 17px;
}

:deep(.el-table .sort-caret.ascending) {
  border-bottom-color: #000000;
}
:deep(.el-table .sort-caret.descending) {
  border-top-color: #000000;
}
:deep(.el-table .ascending .sort-caret.ascending) {
  border-bottom-color: var(--Main);
}
:deep(.el-table .descending .sort-caret.descending) {
  border-top-color: var(--Main);
}

.input-element {
  border: 0.5px solid #707070;
  border-radius: 3px;
  width: 80px;
  height: 25px;
  text-align: center;
}

.input-element-validation {
  border: 3px solid rgb(255, 142, 39);
  border-radius: 3px;
  width: 80px;
  height: 25px;
  text-align: center;
  color: rgb(255, 142, 39);
}
</style>
