<script setup lang="ts">
import { UUID } from '@/api/common';
import FleetStatus from '@/components/operationalStatus/FleetStatus.vue';
import { useRoute, useRouter } from '@/composables/router';
import {
  isOperationalFleetViewRoute,
  operationalDashboardSingleAssetOverviewLocation,
  singleAssetOverviewLocation,
} from '@/router/links/assetOverview';
import { AssetType } from '@/utils/assetTypes';

const router = useRouter();
const route = useRoute();

function handleSelectAsset(assetId: UUID, assetType: AssetType): void {
  if (isOperationalFleetViewRoute(route.value)) {
    router.push(
      operationalDashboardSingleAssetOverviewLocation(assetType, assetId)
    );
  } else {
    router.push(singleAssetOverviewLocation(assetType, assetId));
  }
}
</script>

<template>
  <FleetStatus @select-asset="handleSelectAsset" />
</template>
