export interface DateRange {
  /**
   * Start date of range.
   */
  start: string; // '2022-07-07'

  /**
   * Inclusive end date of range.
   * I.e. matches any date up to and INCLUDING `end`.
   * `end` is thus always exactly one day before `endExclusive`.
   */
  end: string; // '2022-07-13'

  /**
   * Exclusive end date of range.
   * I.e. matches any date up to but EXCLUDING `endExclusive`.
   * `endExclusive` is thus always exactly one day after `end`.
   */
  endExclusive: string; // '2022-07-14'
}

/**
 * Indication of which week format to user
 */
export const enum WeekFormat {
  WKFMT_ISO = 'WKFMT_ISO',
}

/**
 * The selected period in the date selector:
 * 14 days, 13 weeks or 12 months
 */
export const enum DatePeriod {
  DATP_DAY = 'DATP_DAY',
  DATP_WEEK = 'DATP_WEEK',
  DATP_MONTH = 'DATP_MONTH',
}

export interface PeriodRange extends DateRange {
  datePeriod: DatePeriod;
}
