/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon';
icon.register({
  export: {
    width: 16,
    height: 16,
    viewBox: '0 0 16 16',
    data: '<defs/><g id="导出" transform="translate(.22 .546)" _fill="#373e41"><path pid="0" id="路径_1026" data-name="路径 1026" d="M112.577 135.4a.58.58 0 00-.58.58v4.593a.775.775 0 01-.774.774H99.934a.775.775 0 01-.774-.774v-10.639a.775.775 0 01.774-.774h4.954a.58.58 0 000-1.16h-4.954A1.936 1.936 0 0098 129.934v10.638a1.936 1.936 0 001.934 1.934h11.289a1.936 1.936 0 001.934-1.934v-4.593a.58.58 0 00-.58-.579z" transform="translate(-98 -128)"/><path pid="1" id="路径_1027" data-name="路径 1027" d="M253.038 143.877c1.8-1.706 3.621-4.342 6.839-4.172a.42.42 0 01.4.419v1.116a.417.417 0 00.679.326l4.17-3.373a.416.416 0 000-.65l-4.15-3.733a.432.432 0 00-.7.324v1.131a.421.421 0 01-.353.414c-5.533 1.263-6.933 7.752-6.885 8.198z" transform="translate(-250.199 -133.613)"/></g>',
  },
});
