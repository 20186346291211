<script lang="ts">
import { Component, Emit, Prop, Vue, Watch } from 'vue-property-decorator';
import GeneralInfo from '@/components/form/GeneralInfo.vue';

@Component({
  name: 'AssignFormPrev',
})
export default class extends Vue {
  @Prop({ required: false }) hasAssignedForm!: any;

  mounted() {}
}
</script>

<template>
  <div style="margin-left: 30px; margin-top: 50px">
    <el-row>
      <el-col :span="8">
        <GeneralInfo
          id="assign_form_prev_orderId"
          :name="'assetMgmt.orderId'"
          :value="hasAssignedForm.orderId"
        />
        <GeneralInfo
          id="assign_form_prev_cadId"
          :name="'assetMgmt.cadId'"
          :value="hasAssignedForm.cadId"
        />
        <GeneralInfo
          id="assign_form_prev_customer"
          :name="'assetMgmt.customer'"
          :value="hasAssignedForm.customerName"
        />
        <GeneralInfo
          id="assign_form_prev_organization"
          :name="'assetMgmt.organization'"
          :value="hasAssignedForm.organizationName"
        />
      </el-col>

      <el-col :span="10" :offset="4">
        <GeneralInfo
          id="assign_form_prev_customerSubscriptionPackage"
          :name="'assetMgmt.customerSubscriptionPackage'"
          :value="hasAssignedForm.subscriptionPackage"
        />
        <GeneralInfo
          id="assign_form_prev_subscriptionStartDate"
          :name="'assetMgmt.subscriptionStartDate'"
          :value="hasAssignedForm.subscriptionStartDate?.replace('T', ' ')"
        />
        <GeneralInfo
          id="assign_form_prev_subscriptionEndDate"
          :name="'assetMgmt.subscriptionEndDate'"
          :value="hasAssignedForm.subscriptionEndDate?.replace('T', ' ')"
        />
        <GeneralInfo
          id="assign_form_prev_assetSubscriptionPackage"
          :name="'assetMgmt.assetSubscriptionPackage'"
          :value="hasAssignedForm.assetType"
        />
      </el-col>
    </el-row>
  </div>
</template>

<style lang="scss" scoped>
$FormWidth: 340px;
:deep(.el-input--medium .el-input__inner) {
  width: $FormWidth;
}

:deep(.form-item-container .form-title) {
  width: 210px;
  height: 40px;
  line-height: 40px;
}
</style>
