<script lang="ts">
import {
  getMaintenanceItemKPIs,
  getMaintenanceItemsList,
  getProductModels,
  MaintenanceItemKPI,
  MaintenanceListItem,
} from '@/api/maintenance';
import { ProductModel } from '@/api/products';
import PureTable from '@/components/table/PureTable.vue';
import SelectTableHeader from '@/components/table/SelectTableHeader.vue';
import {
  Filter,
  FilterOperator,
  Pagination,
  QueryParameter,
  Sorter,
  SorterOrder,
} from '@/model/queryParameters/QueryParameter';
import { UserModule } from '@/store/modules/user';
import { customFailedMessage } from '@/utils/prompt';
import {
  MAINT_CONF_LIST,
  MAINT_CONF_SEARCH_FIELDS,
} from '@/utils/workData/maintenanceConf';
import { Component, Vue } from 'vue-property-decorator';

interface AssetTypeList {
  id: string;
  label: string;
}

interface TableRowData extends MaintenanceListItem {
  translatedAssetType: string;
}

@Component({
  name: 'maintConf',
  components: {
    'select-table-header': SelectTableHeader,
    'pure-table': PureTable,
  },
})
export default class extends Vue {
  /** Local variables */
  pageSize = UserModule.gridPageSize;
  page: number = 1;
  cols = MAINT_CONF_LIST.map((item) => ({
    ...item,
    label: this.$t(item.label),
  }));
  assetTypeList: AssetTypeList[] = [];
  total: number = 0;
  maintenanceConfigIsLoading: boolean = false;
  productModelDisabled: boolean = true;
  tableList: TableRowData[] = [];
  productModels: ProductModel[] = [];
  assetType: string = '';
  productModel: string = '';
  maintFieldList = MAINT_CONF_SEARCH_FIELDS;
  searchObj: any = {
    reference: null,
    value: null,
  };
  sort: { field: string; order: SorterOrder } | null = null;
  sortableOrder: SorterOrder = SorterOrder.DESC;
  productModelDropwdownIsLoading: boolean = false;
  assetTypeListIsLoading: boolean = false;
  productModelsPage: number = 1;
  productModelsListSize: number = 10000;

  async created() {
    await this.getAvailableAssetTypeByKpis();
    this.fetchMaintenanceListItems();
  }

  /**
   * Fetch available asset types by maintenance KPIs
   * Used in the asset type dropdown options for only those asset types that have KPIs, will be used for requesting rules
   */
  async getAvailableAssetTypeByKpis(): Promise<void> {
    try {
      this.assetTypeListIsLoading = true;
      this.assetTypeList = [];
      const response = await getMaintenanceItemKPIs({});
      const assetTypeCodes: string[] = response.data?.map(
        (item: MaintenanceItemKPI) => item.assetTypeCode
      );
      assetTypeCodes.forEach((assetTypeCode: string) => {
        if (
          !this.assetTypeList.some(
            (item: AssetTypeList) => item.id === assetTypeCode
          )
        ) {
          this.assetTypeList.push({ id: assetTypeCode, label: assetTypeCode });
        }
      });
    } catch (error) {
      console.log(error);
    } finally {
      this.assetTypeListIsLoading = false;
    }
  }

  /**
   * Prepare query parameters for API requests
   * Take values from search obj, assetType, productModel, sort and pagination
   * If assetTypeCode is selected, value assetType will be send to API
   * When searchObj has reference status (maintenance status) filter operator will have the value: EQUAL
   */
  get queryParameters(): QueryParameter {
    const filters: Filter[] = [];
    if (this.searchObj.reference && this.searchObj.value) {
      filters.push({
        name: this.searchObj.reference,
        operator:
          this.searchObj.reference === 'status'
            ? FilterOperator.EQUAL
            : FilterOperator.LIKE,
        value: [this.searchObj.value],
      });
    }

    if (this.assetType) {
      filters.push({
        name: 'assetType',
        operator: FilterOperator.EQUAL,
        value: [this.assetType],
      });
    }

    if (this.productModel) {
      filters.push({
        name: 'productModel',
        operator: FilterOperator.EQUAL,
        value: [this.productModel],
      });
    }
    const sorters: Sorter[] = [];
    if (this.sort && this.sort.field && this.sort.order) {
      sorters.push({
        field: this.sort.field,
        order: this.sort.order,
      });
    }
    const pagination: Pagination = {
      page: this.page,
      size: this.pageSize,
    };
    const queryParameters: QueryParameter = {
      filters: filters,
      sorters: sorters,
      pagination: pagination,
    };

    return queryParameters;
  }

  /**
   * Fetch maintenance configuration items list
   * @param filter
   */
  async fetchMaintenanceListItems(): Promise<void> {
    try {
      this.maintenanceConfigIsLoading = true;
      const res = await getMaintenanceItemsList(this.queryParameters);
      if (res.code === 200) {
        this.total = res.data.total;
        this.tableList = res.data.maintenanceItems.map((item) => ({
          ...item,
          translatedAssetType: this.$t(item.assetType),
          status: this.$t(item.status),
        }));
        return;
      }

      customFailedMessage(res.data.errors![0].message);
    } catch (error) {
      console.log(error);
      customFailedMessage(
        this.$t('maintenance.errorWithFetchingData').toString()
      );
    } finally {
      this.maintenanceConfigIsLoading = false;
    }
  }

  /**
   * Handle pagination event
   * @param page
   * @param pageSize
   */
  handlePagination(page: number, pageSize: number): void {
    this.page = page;
    this.fetchMaintenanceListItems();
  }

  /**
   * Handle filter event
   */
  handleFilter(receivedField: string, order: SorterOrder): void {
    const field: string =
      receivedField === 'assetTypeCode' ? 'assetType' : receivedField;
    this.sort = order && field ? { field, order } : null;
    this.fetchMaintenanceListItems();
  }

  /**
   * Fetch asset type change event
   */
  async handleAssetTypeChanged(assetType: string): Promise<void> {
    try {
      if (assetType === '') {
        this.productModel = '';
        this.productModelDisabled = true;

        this.fetchMaintenanceListItems();
      } else {
        this.productModel = '';

        await this.fetchMaintenanceListItems();
        this.productModelDropwdownIsLoading = true;
        const res = await getProductModels(
          assetType,
          'LCL_APPROVED',
          this.productModelsPage,
          this.productModelsListSize
        );
        if (res.code === 200 && res.data.length > 0) {
          this.productModelDisabled = false;
          const resData = res.data.filter((productModel: ProductModel) =>
            this.tableList.some(
              (maintItem: MaintenanceListItem) =>
                maintItem.productModelId === productModel.id
            )
          );

          resData.forEach((v: any) => {
            v.code = v.code + ' - ' + v.modelNumber;
          });

          this.productModels = resData;
          return;
        }
      }
    } catch (error) {
      console.log(error);
    } finally {
      this.productModelDropwdownIsLoading = false;
    }
  }
}
</script>

<template>
  <div
    v-loading="maintenanceConfigIsLoading"
    class="app-container"
    style="overflow: auto; overflow-x: hidden"
  >
    <div id="maint_conf_home_title">
      <span class="header-title">{{ $t('maintConf.maintConfiguration') }}</span>
    </div>

    <div
      style="
        border-bottom: 1px solid #dddddd;
        padding-top: 10px;
        margin: 0 -20px;
      "
    />

    <div
      id="maint_conf_home_table"
      class="maint-conf-container"
      style="margin-top: 20px; padding: 0 10px"
    >
      <select-table-header
        class="select-header-items"
        style="margin-bottom: 20px"
        :searchFieldOptions="maintFieldList"
        :cols="cols"
        :searchParams="searchObj"
        @search-event="handleFilter"
      >
        <template #leftHeader>
          <el-select
            v-loading="assetTypeListIsLoading"
            id="maint_conf_asset_type_select"
            class="util-table-select"
            v-model="assetType"
            :placeholder="$t('maintConf.assetType')"
            clearable
            @change="handleAssetTypeChanged"
            filterable
          >
            <el-option
              v-for="(item, index) in assetTypeList"
              :key="index"
              :label="$t(item.id)"
              :value="item.id"
            />
          </el-select>

          <el-select
            id="maint_conf_product_model_select"
            v-loading="productModelDropwdownIsLoading"
            class="util-table-select"
            v-model="productModel"
            :placeholder="$t('maintConf.productModel')"
            :disabled="productModelDisabled"
            clearable
            @change="handleFilter('', sortableOrder)"
            filterable
          >
            <el-option
              v-for="(item, index) in productModels"
              :key="index"
              :label="item.code"
              :value="item.code"
            />
          </el-select>
        </template>

        <template #rightHeader>
          <el-button
            id="maint_conf_add_button"
            v-permission="['AUTHRSC_ACTION_MAINT_ITEM_CREATE']"
            type="plain"
            @click="$router.push('create-new-maint-item')"
            style="margin-right: 20px"
          >
            <i class="el-icon-plus common-icon" />{{
              $t('maintConf.newMaintItem')
            }}
          </el-button>
        </template>
      </select-table-header>

      <pure-table
        id="maint_conf_util_table"
        :tableList="tableList"
        :cols="cols"
        :showPagination="true"
        :total="total"
        :maxHeight="'690'"
        :viewPagePath="'view-maintenance-conf'"
        @handle-page="handlePagination"
        @handle-sort-change="handleFilter"
      />

      <div class="d-flex ai-center">
        <span class="total-statistics"
          >{{ $t('maintConf.totalMaintItemInfo') }}:</span
        >
        <span class="total-statistics-value">{{ total }}</span>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.el-select {
  margin-right: 40px;
}

.util-table-select :deep(.el-input__inner) {
  height: $inputHeight !important;
  width: 180px;
}

.select-header-items :deep(#common_table_search_reference) {
  width: 150px;
}

.select-header-items :deep(#common_table_search_input_value) {
  width: 220px;
}

.select-header-items :deep(#common_table_search_custom_input_value) {
  width: 220px;
}
</style>
