<script lang="ts" setup>
import WidgetLayout from '@/components/layout/widget/WidgetLayout.vue';
import { useRoute } from '@/composables/router';
import { computed } from 'vue';

const route = useRoute();

const code = computed(() => {
  const metaCode = route.value.meta?.code;
  if (typeof metaCode !== 'string') {
    throw new Error('invalid or missing page code in route meta');
  }

  return metaCode;
});
</script>

<template>
  <WidgetLayout
    style="height: 100%"
    :page-code="code"
    :hide-configurable="true"
  />
</template>
