<script lang="ts" setup>
import { useLogout } from '@/auth/token';
import ErrorPage from '@/components/layout/MessagePage.vue';

const logout = useLogout();
</script>

<template>
  <ErrorPage>
    <h2>{{ $t('pageNoPermissions.title') }}</h2>
    <p>{{ $t('pageNoPermissions.body') }}</p>
    <el-button @click="logout()" type="info" class="button">{{
      $t('common.logout')
    }}</el-button>
  </ErrorPage>
</template>

<style scoped>
.button {
  margin-top: 1em;
}
</style>
