<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
  name: 'CreateSubscriptionCard',
})
export default class extends Vue {
  @Prop({ required: true }) title!: string;
  @Prop({ required: true }) source!: string;
  @Prop({ required: true }) isEditable!: boolean;
  @Prop({ required: false }) createdOrEdit!: boolean;
  @Prop({ default: true }) deacDeprecate!: boolean;
  @Prop({ default: true }) visible!: boolean;

  handleCreate() {
    this.$emit('create-new-package');
  }

  handleDeactive() {
    this.$emit('handle-deactive');
  }

  handleEdit() {
    this.$emit('is-editable-event');
  }

  handleUpdate() {
    this.$emit('handle-update-event');
  }

  handleDeletePackage() {
    this.$emit('handle-delete-package');
  }

  goBack() {
    this.$router.push('/subscription-packages/index');
  }
}
</script>

<template>
  <div class="cus-add-container" style="overflow: auto">
    <div class="header d-flex ai-center jc-between">
      <div id="new_card_header_title" class="d-flex ai-center">
        <i
          v-if="visible"
          class="el-icon-arrow-left"
          style="font-size: 25px; cursor: Pointer"
          @click="goBack"
        />
        <span class="header-title">{{ $t(title) }}</span>
      </div>
      <div class="viewPageBtn" style="float: right">
        <el-button
          v-if="source === 'new'"
          id="create_subscription_package_button"
          v-permission="['AUTHRSC_ACTION_SUBS_PKG_CREATE']"
          style="margin-right: 10px; width: 80px"
          type="plain"
          @click="handleCreate"
        >
          <div class="d-flex ai-center jc-center">
            <div>
              <i class="el-icon-plus common-icon" />
            </div>
            <div>
              {{ $t('subscriptionPackages.create') }}
            </div>
          </div>
        </el-button>
        <el-button
          v-if="source === 'edit' && !isEditable"
          id="delete_subscription_package_button"
          v-permission="['AUTHRSC_ACTION_SUBS_PKG_DELETE']"
          style="margin-right: 10px; width: 80px"
          type="plain"
          @click="handleDeletePackage"
        >
          <div class="d-flex ai-center jc-center">
            <div>
              <i class="el-icon-minus common-icon" />
            </div>
            <div>
              {{ $t('subscriptionPackages.delete') }}
            </div>
          </div>
        </el-button>
        <el-button
          v-if="source === 'edit' && !isEditable"
          id="edit_subscription_package_button"
          v-permission="['AUTHRSC_ACTION_SUBS_PKG_UPDATE']"
          style="margin-right: 10px; width: 80px"
          type="plain"
          @click="handleEdit"
        >
          <div class="d-flex ai-center jc-center">
            <div>
              <img style="margin-right: 5px" src="@/assets/imgs/edit.svg" />
            </div>
            <div>
              {{ $t('subscriptionPackages.edit') }}
            </div>
          </div>
        </el-button>
        <el-button
          v-if="source === 'edit' && isEditable"
          id="new_card_header_btn_edit"
          style="margin-right: 10px; width: 80px"
          type="plain"
          @click="handleUpdate"
        >
          <div class="d-flex ai-center jc-center">
            <div>
              {{ $t('subscriptionPackages.save') }}
            </div>
          </div>
        </el-button>
      </div>
    </div>

    <div class="body">
      <slot></slot>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.cus-add-container {
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0px 3px 6px #c1c1c1;
  height: 100%;
  padding-bottom: 12px;

  .header {
    font-weight: 500;
    font-family: $font-Roboto-Regular;
    line-height: 21px;
    border-bottom: 1px solid #dddddd;
    padding: 20px 20px;
    height: 60px;
  }

  .body {
    display: flex;
    // min-height: 100vh;
    flex-direction: column;
    // background-color:blanchedalmond;
    min-height: calc(100% - 140px);
  }

  .footer {
    margin-top: auto;
    height: 90px;
    line-height: 100px;
    // margin: 20px 0;
    padding-right: 20px;
    .cancel-button {
      width: 170px;
      height: 40px;
    }
    .add-button {
      width: 170px;
      height: 40px;
    }
  }
}

.el-dropdown-link {
  cursor: pointer;
  color: #409eff;
}

.el-icon-arrow-down {
  font-size: 12px;
}

.mobileViewPageBtn {
  display: none;
}

@media (max-width: 768px) {
  .viewPageBtn {
    display: none;
  }

  .mobileViewPageBtn {
    display: block;
  }
}
</style>
