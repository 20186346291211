<script lang="ts">
import { Trip } from '@/utils/types/trip';
import { setupSelectedTrip } from '@/views/trip/trip';
import { TranslateResult } from 'vue-i18n';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

interface property {
  label: TranslateResult;
  value: string | Date | null | undefined;
}

interface TripDetails {
  id: property;
  startTime: property;
  totalTime: property;
  payload: property;
}

@Component({
  name: 'TripDetails',
})
export default class extends Vue {
  @Prop() trip: Trip | null = null;
  @Prop({ default: null }) expandedRoute!: string | null;

  selectedTrip: TripDetails = {
    id: {
      label: this.$t('trip.companyAssetId'),
      value: undefined,
    },
    startTime: {
      label: this.$t('trip.startTime'),
      value: undefined,
    },
    totalTime: {
      label: this.$t('trip.duration'),
      value: undefined,
    },
    payload: {
      label: this.$t('trip.payload'),
      value: undefined,
    },
  };

  @Watch('trip')
  handleData(newData: any) {
    if (newData) {
      this.populateSelectedTrips(newData);
      return;
    }

    this.selectedTrip = {
      id: {
        label: this.$t('trip.companyAssetId'),
        value: undefined,
      },
      startTime: {
        label: this.$t('trip.startTime'),
        value: undefined,
      },
      totalTime: {
        label: this.$t('trip.duration'),
        value: undefined,
      },
      payload: {
        label: this.$t('trip.payload'),
        value: undefined,
      },
    };
  }

  populateSelectedTrips(trip: Trip): void {
    this.selectedTrip = setupSelectedTrip(trip);
  }
}
</script>

<template>
  <div class="header-container">
    <div class="tripDetails">
      <p
        class="tripPropertyLabel"
        v-for="(tripProp, index) in selectedTrip"
        :key="index"
      >
        {{ tripProp.label }}:
        <span class="tripPropertyValue">{{ tripProp.value }}</span>
      </p>
    </div>
    <div v-if="expandedRoute" class="redirect-to-expanded-mode">
      <router-link :to="expandedRoute">
        <img src="@/assets/imgs/home/fullscreen.svg" />
      </router-link>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.header-container {
  height: 50px;
  display: flex;
  justify-content: space-between;
  border-top: 4px #dddddd solid;

  .tripDetails {
    margin: 0;
    padding: 0 0 0 16px;
    display: flex;
    flex-direction: row;
    column-gap: 44px;

    .tripPropertyLabel {
      font-family: Roboto;
      font-weight: 400;
      font-size: 16px;
      color: #373e41;

      .tripPropertyValue {
        font-weight: 700;
        margin: auto auto auto 9px;
      }
    }
  }

  .redirect-to-expanded-mode {
    display: flex;
    align-items: center;
    padding: 20px;
    margin: 0px 0 0 0;
  }
}
</style>
