<script lang="ts">
import { Event } from '@/api/geofenceTypes';
import bucklingIcon from '@/icons/svg/buckling.svg';
import overloadIcon from '@/icons/svg/overload.svg';
import safetyIcon from '@/icons/svg/safestate.svg';
import sideloadDangerIcon from '@/icons/svg/sideloaddanger.svg';
import sideloadWarningIcon from '@/icons/svg/sideloadwarning.svg';
import { EVENT_CODES } from '@/utils/workData/lookuptable';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import {
  LIcon,
  LMap,
  LMarker,
  LPopup,
  LTileLayer,
  LTooltip,
} from 'vue2-leaflet';
import Vue2LeafletMarkerCluster from 'vue2-leaflet-markercluster';

// TODO: Figure out how this circular dependency was supposed to work and fix it
// import EventDetailsModal from '@/views/dashboard/components/EventDetailsModal.vue';

@Component({
  name: 'LeafletMapEvents',
  components: {
    LMap,
    LTileLayer,
    LMarker,
    LPopup,
    LTooltip,
    LIcon,
    Vue2LeafletMarkerCluster,
    // EventDetailsModal,
  },
})
export default class extends Vue {
  @Prop() events!: Array<Event>;
  @Prop() selectedAsset!: string;
  safetyEvents: Array<Event> = [];
  eventCodes = EVENT_CODES;
  isEventDetailsModalVisible: boolean = false;
  selectedEventId: string = '';
  eventTypes: string[] = [
    'safety',
    'overload',
    'sideloadWarning',
    'sideloadDanger',
    'buckling',
  ];

  @Watch('events')
  handleData(newValue: any) {
    if (newValue) {
      this.getSafeyEvents(newValue);
    }
  }

  @Watch('selectedAsset')
  handleSelectedAsset(newData: any) {
    this.selectedAsset = newData;
  }

  created() {
    this.getSafeyEvents(this.events);
  }

  getSafeyEvents(events: Event[] = []) {
    this.safetyEvents = [];
    this.eventTypes.forEach((event: string) => {
      this.safetyEvents = [
        ...this.safetyEvents,
        ...events.filter(
          (o: any) =>
            o.eventTypeCode ===
            this.eventCodes[event as keyof typeof EVENT_CODES]
        ),
      ];
    });
  }

  getIcon(eventType: string) {
    const icons = {
      safety: safetyIcon,
      overload: overloadIcon,
      sideloadWarning: sideloadWarningIcon,
      sideloadDanger: sideloadDangerIcon,
      buckling: bucklingIcon,
    };
    const iconKey = Object.keys(this.eventCodes).find(
      (key) => this.eventCodes[key as keyof typeof EVENT_CODES] === eventType
    );
    return icons[iconKey as keyof typeof icons];
  }

  openEventInfo(id: string) {
    this.selectedEventId = id;
    this.isEventDetailsModalVisible = true;
  }

  handleQuitEventDetailsDialog() {
    this.isEventDetailsModalVisible = false;
  }
}
</script>

<template>
  <div>
    <Vue2LeafletMarkerCluster>
      <l-marker
        @click="openEventInfo(event.id)"
        v-for="event in safetyEvents"
        :lat-lng="[event.location.lat, event.location.lgt]"
        :key="/* @ts-expect-error TODO Wrong type */
        `${event.id}_${event.selected}`"
        :ref="`marker-${event.id}`"
      >
        <l-icon
          :className="`icon ${
            event.assetId === selectedAsset ? 'selected-l-icon' : ''
          }`"
          :iconUrl="getIcon(event.eventTypeCode)"
          :iconSize="[34, 34]"
        />
      </l-marker>
    </Vue2LeafletMarkerCluster>
    <!-- <EventDetailsModal
      v-if="isEventDetailsModalVisible"
      :visible.sync="isEventDetailsModalVisible"
      :eventId.sync="selectedEventId"
      @quit-event-details-dialog="handleQuitEventDetailsDialog"
    /> -->
  </div>
</template>

<style lang="scss">
.icon {
  border-radius: 50%;
}

.selected-l-icon {
  outline: 14px solid rgba(0, 0, 0, 0.2);
  background: rgba(0, 0, 0, 0.2);
}
</style>
