<script setup lang="ts">
import {
  DetailedNotification,
  getNotificationById,
  Notification,
} from '@/api/notifications';
import { useLoggedInUser } from '@/auth/user';
import { useAsync } from '@/composables/async';
import NotificationBell from '@/icons/templates/NotificationBell.vue';
import { computed } from 'vue';

const props = defineProps<{
  notification: Notification | undefined;
}>();
const emit = defineEmits<{ (e: 'close'): void }>();

const loggedInUser = useLoggedInUser();

const notification = useAsync(
  computed(async (): Promise<DetailedNotification | undefined> => {
    if (props.notification === undefined) return;

    return getNotificationById(
      props.notification.userNotificationId,
      loggedInUser.value?.settings.i18nCode
    );
  })
);
</script>

<template>
  <el-dialog
    :visible="props.notification !== undefined"
    @close="emit('close')"
    append-to-body
  >
    <template #title>
      <div class="title-container">
        <NotificationBell color="#373E41" />
        <div class="title">{{ props.notification?.description }}</div>
      </div>
    </template>

    <div v-loading="notification.loading" class="details">
      {{ notification.data?.details }}
    </div>
  </el-dialog>
</template>

<style lang="scss" scoped>
.title-container {
  display: flex;
  align-items: center;
  gap: 15px;
}

.title {
  font-size: 18px;
  font-weight: 500;
  color: #373e41;
}

.details {
  font-size: 16px;
  color: #373e41;
  word-break: keep-all;
}

:deep(.el-dialog__header) {
  border-bottom: 1px solid #dddddd;
  padding: 13px 19px 13px 19px;
  display: flex;
  justify-content: space-between;
}

:deep(.el-dialog__headerbtn) {
  position: initial;
  top: initial;
  right: initial;
}
</style>
