<script lang="ts">
import { UTCTimestamp, UUID } from '@/api/common';
import { Value } from '@/api/value';
import { SorterOrder } from '@/model/queryParameters/QueryParameter';
import { AssetType } from '@/utils/assetTypes';
import { filterTree, flatMapDeep } from '@/utils/collections';
import { elapsedLastSyncTime } from '@/utils/time';
import { InitializeReactive } from '@/utils/vueClassComponentHelpers';
import { KPI_FIELDS } from '@/utils/workData/lookuptable';
import {
  getOperationalStatusIcon,
  OperationalStatus,
  operationalStatusSorter,
} from '@/utils/workData/operationalStatus';
import { ElTree, TreeNode } from 'element-ui/types/tree';
import moment from 'moment';
import { Component, Prop, Vue } from 'vue-property-decorator';

// This is the type of the slot-scope in the el-tree below,
// but the Vue compiler doesn't understand it if we just add
// the type to the slot-scope attribute. Volar does understand it,
// so let's keep it for now, even if 'unused'.
interface AssetsTreeSlotScope {
  node: TreeNode<UUID, AssetsTableTreeData>;
  data: AssetsTableTreeData;
}

interface TableColumn {
  id: string;
  labelCode: string;
}

export interface FleetStatusKpis {
  [KPI_FIELDS.OperationalStatus]: Value<OperationalStatus>;
  [KPI_FIELDS.LastCommunicationTime]: Value<UTCTimestamp>;
}

export interface FleetStatusAsset {
  id: UUID;
  companyAssetId: string;
  assetType: AssetType;
  organization: FleetStatusOrganization;

  operationalStatus?: OperationalStatus;
  lastCommunicationTime?: UTCTimestamp;
}

export interface FleetStatusOrganization {
  id: UUID;
  name: string;
  assets: FleetStatusAsset[];
  suborganizations: FleetStatusOrganization[];
}

interface AssetTreeData {
  asset: FleetStatusAsset;

  // Asset UUID
  id: UUID;
  label: string;
}

interface OrganizationTreeData {
  organization: FleetStatusOrganization;

  // Organization UUID
  id: UUID;
  label: string;
  children: AssetsTableTreeData[];
}

type AssetsTableTreeData = AssetTreeData | OrganizationTreeData;

function isAssetTreeData(
  treeData: AssetsTableTreeData
): treeData is AssetTreeData {
  return 'asset' in treeData;
}

function fleetStatusAssetToTreeData(asset: FleetStatusAsset): AssetTreeData {
  return {
    id: asset.id,
    label: asset.companyAssetId,
    asset: asset,
  };
}

function fleetOrgToTableTreeDataWithoutChildren(
  org: FleetStatusOrganization
): OrganizationTreeData {
  return {
    id: org.id,
    label: org.name,
    organization: org,
    children: [],
  };
}

function fleetOrgToTableTreeData(
  org: FleetStatusOrganization
): OrganizationTreeData {
  return {
    ...fleetOrgToTableTreeDataWithoutChildren(org),
    children: [
      ...org.assets.map(fleetStatusAssetToTreeData),
      ...org.suborganizations.map(fleetOrgToTableTreeData),
    ],
  };
}

const tableColumns = [
  {
    id: 'status',
    labelCode: 'operationalStatusWidget.table.status',
  },
  {
    id: 'label',
    labelCode: 'operationalStatusWidget.table.assetId',
  },
  {
    id: 'lastSync',
    labelCode: 'operationalStatusWidget.table.lastSyncTime',
  },
] as const;

type TableColumnId = (typeof tableColumns)[number]['id'];

@Component({
  name: 'AssetsWidgetTable',
})
export default class AssetsWidgetTable extends Vue {
  SorterOrder = SorterOrder;
  elapsedLastSyncTime = elapsedLastSyncTime;
  getOperationalStatusIcon = getOperationalStatusIcon;
  isAssetTreeData = isAssetTreeData;
  tableColumns = tableColumns;

  $props!: {
    /**
     * Hierarchy of organizations with their assets.
     */
    hierarchies: AssetsWidgetTable['hierarchies'];

    /**
     * Show search box (default true).
     */
    allowFiltering?: AssetsWidgetTable['allowFiltering'];

    /**
     * Always show as a list of assets without their organization (default false).
     */
    listMode?: AssetsWidgetTable['listMode'];

    /**
     * Explicit order list of Operational Statuses to use for
     * sorting order.
     * Uses the overall ordering of all possible statuses if unspecified.
     */
    orderedOperationalStatuses?: OperationalStatus[];
  };

  @Prop({
    required: true,
  })
  hierarchies: FleetStatusOrganization[] | undefined;

  @Prop({
    default: true,
  })
  allowFiltering!: boolean;

  @Prop({
    default: false,
  })
  listMode!: boolean;

  @Prop({
    required: false,
  })
  orderedOperationalStatuses?: OperationalStatus[] | undefined;

  @InitializeReactive
  sortColumnId: TableColumnId | undefined = this.listMode ? 'label' : undefined;

  @InitializeReactive
  sortOrder: SorterOrder | undefined = this.listMode
    ? SorterOrder.ASC
    : undefined;

  filterText: string = '';

  get ordered(): boolean {
    return this.sortOrder !== undefined;
  }

  get tree(): AssetsTableTreeData[] | undefined {
    if (!this.hierarchies) {
      return undefined;
    }

    if (
      !this.listMode &&
      (this.sortOrder === undefined || this.sortColumnId === undefined)
    ) {
      // Unordered, i.e. return the tree structure
      return this.hierarchies.map(fleetOrgToTableTreeData);
    }

    // Ordered or list mode, build a flat list first
    const assets = this.hierarchies.flatMap((org) =>
      flatMapDeep(org, 'suborganizations', (suborg) => suborg.assets)
    );

    // Optionally sort the list
    if (this.sortOrder !== undefined && this.sortColumnId !== undefined) {
      let sorter: (a: FleetStatusAsset, b: FleetStatusAsset) => number;
      switch (this.sortColumnId) {
        case 'status':
          sorter = (a, b) =>
            operationalStatusSorter(
              a.operationalStatus,
              b.operationalStatus,
              this.orderedOperationalStatuses
            );
          break;

        case 'label':
          sorter = (a, b) => a.companyAssetId.localeCompare(b.companyAssetId);
          break;
        case 'lastSync':
          sorter = (a, b) =>
            moment(b.lastCommunicationTime).unix() -
            moment(a.lastCommunicationTime).unix();
          break;
      }

      assets.sort(sorter);
      if (this.sortOrder === SorterOrder.DESC) {
        assets.reverse();
      }
    }

    // In non-list-mode, insert organization name when it changed between two consecutive assets.
    // In list-mode, just show all assets.
    const assetsAndOrgs = this.listMode
      ? assets.map(fleetStatusAssetToTreeData)
      : assets.reduce((result: AssetsTableTreeData[], asset) => {
          const lastElement: AssetsTableTreeData | undefined =
            result[result.length - 1];
          const needsOrgName =
            !lastElement ||
            (isAssetTreeData(lastElement) &&
              lastElement.asset.organization.id !== asset.organization.id);
          if (needsOrgName) {
            return [
              ...result,
              fleetOrgToTableTreeDataWithoutChildren(asset.organization),
              fleetStatusAssetToTreeData(asset),
            ];
          }
          return [...result, fleetStatusAssetToTreeData(asset)];
        }, []);

    return assetsAndOrgs;
  }

  get filteredTree(): AssetsTableTreeData[] | undefined {
    // Note: Element UI has a filter-node-method property, but filtering only
    // works imperatively (i.e. calling filter() on the tree), and there's
    // no event for when its data was updated. So let's do the filtering
    // ourselves.

    if (!this.tree) {
      return undefined;
    }
    if (this.filterText === '') {
      return this.tree;
    }

    const upperFilterText = this.filterText.toUpperCase();
    // @ts-expect-error AssetsTableTreeData is a union type, but the children aren't
    // correctly determined by the filterTree's type constraints.
    return filterTree(this.tree, 'children', (data) =>
      isAssetTreeData(data)
        ? data.label.toUpperCase().includes(upperFilterText)
        : data.children.length > 0
    );
  }

  handleColumnClick(selectedColumnId: TableColumnId) {
    if (this.sortOrder && selectedColumnId === this.sortColumnId) {
      if (this.sortOrder === SorterOrder.ASC) {
        this.sortOrder = SorterOrder.DESC;
      } else {
        this.sortOrder = undefined;
      }
    } else {
      this.sortColumnId = selectedColumnId;
      this.sortOrder = SorterOrder.ASC;
    }
  }

  handleAssetClick(data: AssetTreeData): void {
    this.$emit('select-asset', data.asset);
  }
}
</script>

<template>
  <div class="container">
    <el-input
      v-if="allowFiltering"
      :placeholder="$t('operationalStatusWidget.filterKeyword')"
      suffix-icon="el-icon-search"
      class="input-search"
      clearable
      v-model="filterText"
    />
    <div class="tree-header">
      <button
        v-for="col in tableColumns"
        :key="col.id"
        @click="handleColumnClick(col.id)"
      >
        {{ $t(col.labelCode) }}
        <div class="ascending-decending-buttons">
          <span
            :class="
              sortOrder === SorterOrder.ASC && sortColumnId === col.id
                ? 'button-ascending-active'
                : 'button-ascending'
            "
          ></span>
          <span
            :class="
              sortOrder === SorterOrder.DESC && sortColumnId === col.id
                ? 'button-descending-active'
                : 'button-descending'
            "
          ></span>
        </div>
      </button>
    </div>

    <el-tree
      :class="ordered || listMode ? 'filtered-tree' : 'unfiltered-tree'"
      :data="filteredTree"
      default-expand-all
      ref="tree"
    >
      <!-- TODO slot scope is of type `AssetsTreeSlotScope`, but Vue 2.7 doesn't understand TS in template -->
      <template v-slot="{ node, data }">
        <!-- Asset tree node -->
        <div
          v-if="isAssetTreeData(data)"
          class="custom-tree-node"
          @click="handleAssetClick(data)"
        >
          <img
            v-if="data.asset.operationalStatus"
            :src="getOperationalStatusIcon(data.asset.operationalStatus)"
            class="status-icon"
            :title="$t(data.asset.operationalStatus).toString()"
            :alt="$t(data.asset.operationalStatus).toString()"
          />
          <span v-else>
            {{ $t('ASSSTAT_INACTIVE_NULL') }}
          </span>
          <span class="node-asset">
            {{ node.label }}
          </span>
          <span class="last-sync-value-field">{{
            data.asset.lastCommunicationTime
              ? elapsedLastSyncTime(data.asset.lastCommunicationTime)
              : ''
          }}</span>
        </div>

        <!-- Organization tree node -->
        <div v-else class="custom-tree-node">
          <span class="node-company">
            {{ node.label }}
          </span>
        </div>
      </template>
    </el-tree>
  </div>
</template>

<style lang="scss" scoped>
.container {
  display: flex;
  flex-direction: column;
}

.input-search {
  display: flex;
  margin: 0;
  padding: 6px 32px 16px 32px;
}

:deep(.el-input__inner) {
  width: 400px;
  margin: auto;
}

:deep(.el-input__suffix) {
  right: 80px;
}

:deep(.el-tree-node__content) {
  border-bottom: solid 1px rgba(44, 44, 44, 0.2);
  padding: 26px 32px 26px 0px;
}

.tree-header {
  font-family: var(--fontRobotoMedium);
  font-size: 14px;
  color: #373e41;
  padding: 16px 2px;
  display: grid;
  grid-gap: 10px;
  grid-template-columns: 0.4fr 1fr 0.96fr;
  border-bottom: solid 1px rgba(44, 44, 44, 0.2);

  button:nth-child(1) {
    margin: 0;
    width: 40px;
  }

  button:nth-child(2) {
    margin: 0 46px 0 0;
  }

  button:nth-child(3) {
    margin: 0 0 0 10px;
  }

  button {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 8px;
    background: none;
    border: none;

    &:hover {
      cursor: pointer;
    }

    .ascending-decending-buttons {
      display: flex;
      flex-direction: column;
      gap: 2px;

      .button-ascending-active {
        width: 0;
        height: 0;
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        border-bottom: 5px solid #ffcd00;
      }

      .button-descending-active {
        width: 0;
        height: 0;
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        border-top: 5px solid #ffcd00;
      }

      .button-ascending {
        width: 0;
        height: 0;
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        border-bottom: 5px solid black;
      }

      .button-descending {
        width: 0;
        height: 0;
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        border-top: 5px solid black;
      }
    }
  }
}

.custom-tree-node {
  padding: 16px 32px 16px 0px;
  width: 100%;
  display: grid;
  grid-template-columns: 0.2fr 1fr 0.5fr;
  justify-content: space-between;
  text-align: left;
}

.node-company {
  font-weight: bold;
}

.node-asset {
  font-weight: bold;
}

.filtered-tree {
  .custom-tree-node {
    padding-left: 16px;
  }

  :deep(.el-tree-node__expand-icon) {
    display: none;
  }
}

.last-sync-value-field {
  margin: auto;
}
</style>
