import { LOCALDATE_FORMAT } from '@/utils/time';
import moment from 'moment';

export function formatServerDate(date?: string) {
  return !!date ? moment(date).format('YYYY/MM/DD') : '';
}

export function formatDateForServer(date?: string | null) {
  return !!date ? moment(date).format(LOCALDATE_FORMAT) : null;
}
