export enum AggregationType {
  AggregateByTime = 'AGGREGATE_BY_TIME',
  AggregateByAsset = 'AGGREGATE_BY_ASSET',
}

export enum KpiDataBucketDimension {
  Time = 'DBDIM_TIME',
  Asset = 'DBDIM_ASSET',
}

export const aggregationTypeToBucket: Record<
  AggregationType,
  KpiDataBucketDimension
> = {
  [AggregationType.AggregateByTime]: KpiDataBucketDimension.Time,
  [AggregationType.AggregateByAsset]: KpiDataBucketDimension.Asset,
};
