import {
  getSubscriptionAssets,
  RenewAssetModel,
  renewAssetSubscription,
  SubscriptionAssetResponse,
  TerminateAssetModel,
  terminateAssetSubscription,
} from '@/api/subscriptionManagement';
import { useLoggedInUser } from '@/auth/user';
import {
  Pagination,
  SearchParam,
  Sorter,
} from '@/model/queryParameters/QueryParameter';
import { MaybeRef } from '@/utils/ref';
import {
  keepPreviousData,
  QueryFilters,
  useMutation,
  UseMutationReturnType,
  useQuery,
  useQueryClient,
  UseQueryReturnType,
} from '@tanstack/vue-query';
import { computed, unref } from 'vue';
import { NO_BACKGROUND_FETCH_OPTIONS, Selector } from './common';

const queryKeys = {
  assetSubscriptions: (
    pagination: Pagination,
    sortBy?: Sorter,
    filter?: SearchParam
  ) =>
    ({
      query: 'asset_subscriptions',
      pagination,
      filter,
      sortBy,
    } as const),
};

export function useAssetSubscriptionsQuery(
  pagination: MaybeRef<Pagination>,
  sortBy: MaybeRef<Sorter | undefined>,
  filter: MaybeRef<SearchParam | undefined>
): UseQueryReturnType<SubscriptionAssetResponse, Error>;
export function useAssetSubscriptionsQuery<R>(
  pagination: MaybeRef<Pagination>,
  sortBy: MaybeRef<Sorter | undefined>,
  filter: MaybeRef<SearchParam | undefined>,
  selector: Selector<SubscriptionAssetResponse, R>
): UseQueryReturnType<R, Error>;
export function useAssetSubscriptionsQuery<R>(
  pagination: MaybeRef<Pagination>,
  sortBy: MaybeRef<Sorter | undefined>,
  filter: MaybeRef<SearchParam | undefined>,
  selector?: Selector<SubscriptionAssetResponse, R>
): UseQueryReturnType<R, Error> {
  const loggedInUser = useLoggedInUser();

  return useQuery({
    ...NO_BACKGROUND_FETCH_OPTIONS,
    enabled: computed(() => loggedInUser.value !== undefined),
    placeholderData: keepPreviousData,
    queryKey: [
      computed(() =>
        queryKeys.assetSubscriptions(
          unref(pagination),
          unref(sortBy),
          unref(filter)
        )
      ),
    ],
    queryFn: ({ queryKey: [{ pagination, filter, sortBy }] }) =>
      getSubscriptionAssets(pagination, sortBy, filter),
    select: selector,
  });
}

const queryFilters = {
  assetSubscriptions: (): QueryFilters => ({
    queryKey: [{ query: 'asset_subscriptions' }],
  }),
};

export function useTerminateAssetSubscriptionMutation(): UseMutationReturnType<
  TerminateAssetModel,
  Error,
  TerminateAssetModel,
  void
> {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (asset) => terminateAssetSubscription(asset),
    onSuccess: () => {
      queryClient.invalidateQueries(queryFilters.assetSubscriptions());
    },
  });
}

export function useRenewAssetSubscriptionMutation(): UseMutationReturnType<
  RenewAssetModel,
  Error,
  RenewAssetModel,
  void
> {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (asset) => renewAssetSubscription(asset),
    onSuccess: () => {
      queryClient.invalidateQueries(queryFilters.assetSubscriptions());
    },
  });
}
