import {
  Filter,
  FilterOperator,
  Pagination,
  QueryParameter,
  SearchParam,
  Sorter,
} from '@/model/queryParameters/QueryParameter';
import { api } from '@/utils/api';
import { AssetType } from '@/utils/assetTypes';
import { CustomSelectionOptionsForSearching } from '@/utils/workData/lookuptable';
import { UUID } from './common';
import { HyvaRegionCode } from './hyvaRegion';
import { ServiceRegionCode } from './serviceRegions';

export enum SubscriptionStatus {
  Active = 'SUBSTAT_ACTIVE',
  Inactive = 'SUBSTAT_INACTIVE',
  Due = 'SUBSTAT_DUE',
  Pending = 'SUBSTAT_PENDING_RENEW',
  Terminated = 'SUBSTAT_TERMINATED',
}

export interface SubscriptionAsset {
  assetId: UUID;
  assetType: AssetType;
  companyAssetId: string;
  customerSubscriptionPackage: string;
  organizationName: string;
  companyName: string;
  companyId: UUID;
  daysToRenew: number;
  renewedTimes: number;
  assignmentId: UUID;
  subscriptionStatus: SubscriptionStatus;
  subscriptionStartDate: string;
  subscriptionEndDate: string;
  cadId: string;
  orderId: string;
  productModelCode: string;
  productModelNumber: string;
  lastCommunicationTime: string;
  installTime: string;
  controllerId: string;
  assetDataCollectionId: string;
  serialNumber: string;
  serviceRegion: ServiceRegionCode;
  hyvaRegion: HyvaRegionCode;
  manufactureDate: string;
  gatewayId: string;
  autoRenewalOn: boolean;

  /** Subscription Duration sent in the format of: P{number}{Y/M/D}, where Y stands for year, M for month, and D for day. P is for period.
   * READ MORE: https://en.wikipedia.org/wiki/ISO_8601#Durations
   */
  renewPeriod: string;
}

export interface SubscriptionAssetResponse {
  assets: SubscriptionAsset[];
  total: number;
  size: number;
}

export async function getSubscriptionAssets(
  pagination: Pagination,
  sortBy: Sorter | undefined,
  searchParam: SearchParam | undefined
): Promise<SubscriptionAssetResponse> {
  return await api().post<SubscriptionAssetResponse, QueryParameter>(
    `/subscription-management/assets`,
    {
      pagination,
      sorters: sortBy ? [sortBy] : [],
      filters: createFilterForSubscriptionAssetFetching(searchParam),
    }
  );
}

function createFilterForSubscriptionAssetFetching(
  searchParam: SearchParam | undefined
): Filter[] {
  if (!searchParam || !searchParam.reference) {
    return [];
  }

  const filters: Filter[] = [];

  // By default hide terminated asset subscriptions - only show them if that filter selected
  if (
    !searchParam.value ||
    searchParam.reference !==
      CustomSelectionOptionsForSearching.SearchBySubscriptionStatus
  ) {
    filters.push({
      name: CustomSelectionOptionsForSearching.SearchBySubscriptionStatus,
      operator: FilterOperator.NOT_EQUAL,
      value: [SubscriptionStatus.Terminated],
    });
  }

  if (searchParam.value) {
    filters.push({
      name: searchParam.reference,
      operator: searchParam.operator ?? FilterOperator.EQUAL,
      value: [searchParam.value],
    });
  }

  return filters;
}

export interface TerminateAssetModel {
  assetId: UUID;
  assignmentId: UUID;
}

export async function terminateAssetSubscription(
  body: TerminateAssetModel
): Promise<TerminateAssetModel> {
  return await api().post<TerminateAssetModel, undefined>(
    `/subscription-management/${body.assignmentId}/terminate/${body.assetId}`
  );
}

export interface RenewAssetModel {
  assetId: UUID;
  assignmentId: UUID;
}

export async function renewAssetSubscription(
  body: RenewAssetModel
): Promise<RenewAssetModel> {
  return await api().post<RenewAssetModel, undefined>(
    `/subscription-management/${body.assignmentId}/renew/${body.assetId}`
  );
}
