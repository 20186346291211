var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"page-container"},[_c('div',{staticClass:"page-title-container"},[_c('div',{staticClass:"back-button-container"},[_c('el-button',{staticClass:"back",on:{"click":_vm.handleBack}},[_c('img',{staticClass:"back-icon",attrs:{"src":require("@/icons/svg/back.svg")}})]),_c('span',{staticClass:"page-content-title"},[_vm._v(_vm._s(_vm.$t('newRelease.title')))])],1),_c('el-button',{staticClass:"new-release-button",attrs:{"id":"newRelease","type":"plain"},on:{"click":_vm.createRelease}},[_vm._v(" "+_vm._s(_vm.$t('releaseDetails.createNew'))+" ")])],1),_c('div',{staticClass:"container"},[_c('div',{staticClass:"left-content"},[_c('h2',{staticClass:"title"},[_vm._v(" "+_vm._s(_vm.$t('releaseDetails.productSystemReleaseInfo'))+" ")]),_c('div',{staticClass:"product-system-releaseID"},[_c('p',{staticClass:"lable"},[_vm._v(" "+_vm._s(_vm.$t(`releaseDetails.productSystemRelease.systemReleaseId`))),_c('span',{staticClass:"mandatory"},[_vm._v("*")]),_vm._v(": ")]),_c('el-select',{staticClass:"search-select-field",attrs:{"id":"releaseFilter","placeholder":_vm.$t('releaseManagementModule.productSystemReleaseId'),"filterable":""},on:{"change":_vm.handleProductSystemReleaseSelected},model:{value:(_vm.productSystemReleaseID),callback:function ($$v) {_vm.productSystemReleaseID=$$v},expression:"productSystemReleaseID"}},_vm._l((_vm.productSystemReleasesIDs),function(item){return _c('el-option',{key:/* @ts-expect-error TODO Wrong type */
              item.systemReleaseId,attrs:{"label":/* @ts-expect-error TODO Wrong type */
              item.systemReleaseId,"value":/* @ts-expect-error TODO Wrong type */
              item.id}})}),1)],1),_c('content-list',{staticClass:"product-release-info",attrs:{"content":_vm.productSystemReleaaseInformation.content,"showReleaseNotes":!!_vm.productSystemReleaseID,"productSystemReleaseID":_vm.productSystemReleaseID}}),_c('el-checkbox',{staticClass:"set-as-default",attrs:{"disabled":!_vm.id},on:{"change":_vm.handleSetAsDefault},model:{value:(_vm.checked),callback:function ($$v) {_vm.checked=$$v},expression:"checked"}},[_vm._v(" "+_vm._s(_vm.$t('releaseDetails.setAsDefault'))+" ")]),_c('div',{staticClass:"lined-customer-header"},[_c('h2',{staticClass:"title"},[_vm._v(_vm._s(_vm.$t('releaseDetails.linkedCustomerTitle')))]),(_vm.productSystemReleaseID)?_c('el-button',{directives:[{name:"permission",rawName:"v-permission",value:(['AUTHRSC_ACTION_RELEASE_LINK']),expression:"['AUTHRSC_ACTION_RELEASE_LINK']"}],staticClass:"new-release-button",attrs:{"id":"edit","disabled":_vm.customerListLoading || _vm.checked,"type":"plain"},on:{"click":_vm.editLinkedCustomer}},[_c('img',{staticClass:"edit-icon",attrs:{"src":require("@/icons/svg/editRelease.svg")}}),_vm._v(" "+_vm._s(_vm.$t('assetMgmt.edit'))+" ")]):_vm._e()],1),_c('content-table',{attrs:{"titleName":_vm.$t('subsMgmt.customer'),"titleValue":_vm.$t('subsMgmt.numberOfAssets'),"content":_vm.treeContentData.tree,"filterIds":_vm.treeContentData.selectedData}})],1),_c('div',{staticClass:"right-content"},[_c('h2',{staticClass:"title"},[_vm._v(_vm._s(_vm.$t('newRelease.partFirmwareInfo')))]),_vm._l((_vm.partFirmwareInformation),function(info){return _c('content-list',{key:/* @ts-expect-error TODO Wrong type */
          info.containedPartName,attrs:{"showReleaseNotes":false,"productSystemReleaseID":_vm.productSystemReleaseID,"content":info,"title":(
            info.find(
              /* @ts-expect-error TODO Wrong type */
              (i) => i.id === 'productSystemRelease.containedPartName'
            ) || {}
          ) /* @ts-expect-error TODO Wrong type */.value}})})],2)]),_c('tree-transfer',{attrs:{"title":_vm.$t('releaseDetails.linkedCustomerTitle'),"visible":_vm.treeTransferVisible,"confirmBtnName":_vm.$t('common.apply'),"listTitles":[
      _vm.$t('linkedCustomers.customersAvailable'),
      _vm.$t('linkedCustomers.customersLinked'),
    ],"data":_vm.treeContentData},on:{"handle-confirm":_vm.handleConfirm,"handle-cancel":_vm.handleCancel}}),_c('confirm-dialog',{attrs:{"visible":_vm.dialogVisible,"title":_vm.$t('newRelease.dialogTitle'),"confirmBtnName":_vm.$t('newRelease.dialogQuit'),"width":'600px'},on:{"handle-cancel":_vm.handleCancel,"handle-confirm":_vm.handleDialogConfirm}},[_c('a',{staticClass:"dialog-content"},[_vm._v(_vm._s(_vm.$t('newRelease.dialogContent')))])]),_c('set-as-default',{attrs:{"visible":_vm.setAsDefaultDialogVisible,"title":_vm.$t('newRelease.dialogSetAsDefault'),"confirmBtnName":_vm.$t('userSetting.confirm'),"width":'600px'},on:{"handle-cancel":_vm.handleCancel,"handle-confirm":_vm.handleDialogConfirmSetDefault}},[_c('a',{staticClass:"dialog-content"},[_vm._v(" "+_vm._s(!_vm.defaultRelease || _vm.defaultRelease.systemReleaseId === _vm.productSystemReleaseID ? _vm.$t('newRelease.dialogContentSetAsDefaultNoDefaultRelease') : _vm.$t('newRelease.dialogContentSetAsDefaultExistingDefaultRelease', { defaultReleaseName: _vm.defaultRelease.systemReleaseId, }))+" ")])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }