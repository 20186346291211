<script lang="ts">
import PureTable from '@/components/table/PureTable.vue';
import { PROD_CATA_MODEL_PARTS_LIST } from '@/utils/workData/prodCata';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import ModelSelect from '../components/ModelSelect.vue';

@Component({
  name: 'partsInfoPrev',
  components: {
    'pure-table': PureTable,
    'model-select': ModelSelect,
  },
})
export default class extends Vue {
  @Prop({ required: true }) partsInfoPrev!: any;

  cols = PROD_CATA_MODEL_PARTS_LIST;
  tableList: any = [];

  mounted() {
    this.$nextTick(() => {
      if (!this.partsInfoPrev?.containedParts) return;
      this.tableList = JSON.parse(
        JSON.stringify(this.partsInfoPrev.containedParts)
      );
      this.tableList.forEach((item: any) => {
        item.isMaintainable = item.isMaintainable
          ? 'MAINTAINABLE_TRUE'
          : 'MAINTAINABLE_FALSE';
      });
    });
  }

  @Watch('partsInfoPrev', { deep: true })
  private watchPartsInfo(value: any, oldVal: any) {
    this.tableList = JSON.parse(JSON.stringify(value.containedParts));
    this.tableList.forEach((item: any) => {
      item.isMaintainable = item.isMaintainable
        ? 'MAINTAINABLE_TRUE'
        : 'MAINTAINABLE_FALSE';
    });
  }
}
</script>

<template>
  <div class="app-container">
    <pure-table
      id="product_model_parts_info_prev"
      :tableList="tableList"
      :cols="cols"
      :showPagination="false"
    ></pure-table>
  </div>
</template>

<style lang="scss" scoped>
.total_items {
  font-size: 16px;
  font-family: $font-Roboto-Bold;
  color: #373e41;
  line-height: 28px;
  opacity: 0.6;
  span {
    font-size: 24px;
    font-weight: bold;
    line-height: 28px;
    opacity: 0.8;
    vertical-align: bottom;
  }
}
</style>
