import { stripPrefix } from '@/utils/string';
import {
  ReportFormat,
  REPORT_FORMAT_PREFIX,
  REPORT_FORMAT_TO_MIME_TYPE,
} from '@/utils/workData/lookuptable';

export function downloadFile(
  data: any,
  fileName: string,
  extension: ReportFormat
) {
  const blob = new Blob([data], {
    type: REPORT_FORMAT_TO_MIME_TYPE[extension],
  });
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.href = url;
  a.download = `${fileName}.${stripPrefix(
    extension,
    REPORT_FORMAT_PREFIX
  ).toLowerCase()}`;
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
}
