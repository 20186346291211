<script lang="ts" setup>
import { computed, unref, watchEffect } from 'vue';

import { Organization } from '@/api/organizations';
import {
  useContextTypeFromRoute,
  useSelectedOrganization,
} from '@/auth/context';
import { ContextType } from '@/auth/contextType';
import { useLoggedInUser } from '@/auth/user';
import OrganizationTreeSelect from '@/components/select/OrganizationTreeSelect.vue';
import { useRoute } from '@/composables/router';
import router from '@/router';
import {
  filterActivatedChildren,
  isOrganizationPartOfTree,
  mapOrgToDropdownItem,
} from '@/utils/org';
import { OrganizationDropdownItem } from './models';

const route = useRoute();

const loggedInUserContext = useLoggedInUser();
const { update: updateSelectedOrgId, ref: selectedOrganizationId } =
  useSelectedOrganization();

const contextType = useContextTypeFromRoute();
const isSelectableContext = computed(
  () => unref(contextType) !== ContextType.LoggedInUser
);

const organizationStructure = computed((): Organization | undefined => {
  const rootOrg = loggedInUserContext?.value?.organization;

  if (!rootOrg) {
    return undefined;
  }

  return filterActivatedChildren(rootOrg);
});

const dropdownOptions = computed((): OrganizationDropdownItem[] | undefined => {
  if (organizationStructure.value === undefined) return undefined;

  return [mapOrgToDropdownItem(organizationStructure.value)];
});

// TODO Move the business logic out, as the component is either rendered never, or twice (top bar and menu)
watchEffect(() => {
  const rootOrg = organizationStructure.value;
  if (!rootOrg) {
    // Still loading
    return;
  }

  // If we don't have an organization selected at all, or if the currently selected
  // organization UUID is no longer present in the user's organization list, switch
  // back to the user's main organization instead.
  const selectedOrgId = selectedOrganizationId.value;

  if (
    (selectedOrgId && !isOrganizationPartOfTree(selectedOrgId, rootOrg)) ||
    !selectedOrgId
  ) {
    updateSelectedOrgId(rootOrg.id);
  }
});

/**
 * When organization is changed and the route is on /asset/<asset-type>/:id detail page:
 * - redirect to fleet details route by that specific <asset-type>
 * - extract dynamically the route path for each asset type "/<asset-type>" from "/<asset-type>/:id" details page
 */
// TODO Change this to a more generic access control mechanism on the route(s) itself
function redirectToDefaultRoute(): void {
  if (route.value?.params?.id && route.value.path?.includes('assets')) {
    const currentRoute = router?.currentRoute;
    const segments = currentRoute?.path
      ?.split('/')
      ?.filter((segment) => segment !== '');
    segments?.pop();
    const parentRoutePath = `${'/' + segments.join('/')}`;
    router.push({ path: parentRoutePath });
  }
}

function onOrgChange(id: string | undefined): void {
  updateSelectedOrgId(id);
  redirectToDefaultRoute();
}
</script>

<template>
  <OrganizationTreeSelect
    v-loading="organizationStructure === undefined"
    v-show="isSelectableContext"
    :selected="selectedOrganizationId"
    :dropdownItems="dropdownOptions ?? []"
    :filterable="true"
    @change="onOrgChange"
  />
</template>
