<script lang="ts">
import {
  createReportTemplate,
  getReportTemplate,
  updateReportTemplate,
} from '@/api/report';
import { getRoles } from '@/api/roles';
import { ErrorType } from '@/api/types';
import CommonBtn from '@/components/button/CommonBtn.vue';
import BaseCard from '@/components/cusCard/BaseCard.vue';
import CusFormItem from '@/components/form/CusFormItem.vue';
import { PageModule } from '@/store/modules/page';
import { UserModule } from '@/store/modules/user';
import {
  getAssetTypesList,
  ReportAssetType,
  ReportAssetTypeKpi,
} from '@/utils/assetTypes';
import {
  customFailedMessage,
  promptFailedBox,
  promptSuccessBox,
} from '@/utils/prompt';
import { CHART_TYPE_LIST, THEME_LIST } from '@/utils/workData/lookuptable';
import { Component, Prop, Vue } from 'vue-property-decorator';
import QuitEditingTemplateModal from '../components/QuitEditingTemplateModal.vue';
import SelectCheckbox from '../components/SelectCheckbox.vue';
import { getAssetTypesFromCompanySubscription } from '../report';

interface ReportKPI {
  id: string;
  code: string;
  unit?: string;
  method: string;
}

const allKpi = {
  id: 'KPI.All_All',
  code: 'KPI.All',
  method: 'All',
};

@Component({
  name: 'createReportTemplate',
  components: {
    'base-card': BaseCard,
    'common-btn': CommonBtn,
    'cus-form-item': CusFormItem,
    'select-checkbox': SelectCheckbox,
    'quit-editing-template-modal': QuitEditingTemplateModal,
  },
})
export default class extends Vue {
  @Prop() reportTemplateId!: string;

  /** Local variables */
  loadingCreatePage: boolean = false;
  assetTypeList: any = [];
  kpiListMap: Map<string, Array<ReportKPI>> = new Map<
    string,
    Array<ReportKPI>
  >();
  showDialogBeforeBack: boolean = false;
  isQuitEditingTemplateModalVisible: boolean = false;
  isformValuesChanged: boolean = false;

  originKpis: any = [];
  kpiList: any = [];

  originRoles: any = [];
  subscriberRoleList: any = [];

  chartTypes = CHART_TYPE_LIST;
  chartKpiList: any = [];
  defaultSubscriberRoles: string[] = ['All'];

  activeColor: string = '';
  templateName: string = '';
  selectedAssetType: string = '';
  selectedAssetTypeTotalKpis: number = 0;
  allKpiAreUsed: boolean = false;

  reportTemplateForm: any = {
    name: '',
    assetType: '',
    tempKpis: [],

    kpis: [],
    subscriberRoles: [],

    allowedSubscriberRoles: [],
    charts: [],
    displayedInTable: true,
    companyId: UserModule.companyId,
    organizationId: UserModule.organizationId,
    creatorUserName: UserModule.username,
    note: '',
  };

  errorInfos: ErrorType[] = [
    {
      code: '',
      field: '',
      message: '',
    },
  ];
  subscriberRolesListIsLoading: boolean = false;

  async created() {
    this.showDialogBeforeBack = !!this.reportTemplateId;
    this.getThemeColor();

    this.loadingCreatePage = true;
    await Promise.all([this.fetchAssetTypes(), this.fetchRoles()]).then(() => {
      if (this.reportTemplateId) {
        this.getReportTemplate();
      }
    });

    this.checkAllKpiAreUsed();
  }

  /**
   * Get report template by id
   */
  async getReportTemplate(): Promise<void> {
    try {
      const response = await getReportTemplate(this.reportTemplateId);
      const template = response.data;
      PageModule.setTitle(template.name);
      this.showValuesInForm(template);
    } catch (error) {
      console.log(error);
    } finally {
    }
  }

  /**
   * Display form values
   * @param template
   */
  showValuesInForm(template: any): void {
    this.handleSelectAssetType(template.assetType);

    this.reportTemplateForm.name = template.name;
    this.reportTemplateForm.assetType = template.assetType;
    this.reportTemplateForm.displayedInTable = template.displayedInTable;
    this.reportTemplateForm.note = template.note;

    let tempKpis: any = [];
    if (template.kpis.length == this.selectedAssetTypeTotalKpis) {
      tempKpis = [allKpi];
    } else {
      template.kpis.forEach((kpi: any) => {
        return kpi.statisticsMethods.forEach((method: string) => {
          tempKpis.push(this.mapKpi(kpi, method));
        });
      });
    }
    this.reportTemplateForm.tempKpis = tempKpis;

    if (this.originRoles.length == template.allowedSubscriberRoles.length) {
      (this.$refs.reportTemplateRolesRef as SelectCheckbox).setCheckedItems([
        'All',
      ]);
    } else {
      (this.$refs.reportTemplateRolesRef as SelectCheckbox).setCheckedItems(
        template.allowedSubscriberRoles
      );
    }

    this.reportTemplateForm.charts = template.charts;
    const kpiCodes = template.kpis.map((kpi: any) => kpi.code);
    this.chartKpiList = this.originKpis
      .find((originKpi: any) => originKpi.assetType == template.assetType)
      .kpis.filter((originKpi: any) => kpiCodes.indexOf(originKpi.code) > -1)
      .map((originKpi: any) => this.mapChartKpiListItem(originKpi));
  }

  /**
   * Check if asset type is not selected in imput form
   */
  get assetTypeIsNotSelected(): boolean {
    return (
      this.reportTemplateForm?.assetType === '' ||
      !this.reportTemplateForm?.assetType
    );
  }

  /**
   * Get roles
   */
  get rules() {
    const tmpRules = {
      name: [
        {
          required: true,
          message: this.$t('report.tipInputName'),
          pattern: /^\S+/,
          trigger: 'change',
        },
        { validator: this.validateName, trigger: 'change' },
      ],
      assetType: [
        {
          required: true,
          message: this.$t('report.tipSelectAssetType'),
          trigger: 'change',
        },
      ],
      tempKpis: [
        {
          required: true,
          message: this.$t('report.tipSelectKpis'),
          trigger: 'change',
        },
      ],
      kpiCode: [{ validator: this.validateKpis, trigger: 'change' }],
      chartType: [{ validator: this.validateChartType, trigger: 'change' }],
    };
    return tmpRules;
  }

  /**
   * Get error name message
   */
  get errNameInfo() {
    let errInfo: string = '';

    if (
      this.errorInfos.find(
        (item) => item.field === 'ApiFieldReportTemplateName'
      )?.code === 'ApiErrorFieldDuplicate'
    ) {
      errInfo = `${this.$t('report.errInfoName')}`;
    }
    return errInfo;
  }

  /**
   * Validate KPIs
   * @param _rule
   * @param value
   * @param callback
   */
  validateKpis = (_rule: any, value: any, callback: any) => {
    if (value) {
      callback();
    } else {
      callback(new Error(`${this.$t('report.tipSelectKpi')}`));
    }
  };

  /**
   * Validate chart types
   * @param _rule
   * @param value
   * @param callback
   */
  validateChartType = (_rule: any, value: any, callback: any) => {
    if (value) {
      callback();
    } else {
      callback(new Error(`${this.$t('report.tipSelectChartType')}`));
    }
  };

  /**
   * Validate name
   * @param _rule
   * @param value
   * @param callback
   */
  validateName = (_rule: any, value: any, callback: any) => {
    if (value.length < 1 || value.length > 50) {
      callback(
        new Error(`${this.$t('report.nameShouldBeBetweenOneAndFifteen')}`)
      );
    } else {
      callback();
    }
  };

  /**
   * Get asset types to be used for new report template creationg
   * Must be filtered by company available subscription asset types subscriptions
   */
  async fetchAssetTypes(): Promise<void> {
    try {
      this.loadingCreatePage = true;
      const companyAssetTypes: string[] | undefined =
        await getAssetTypesFromCompanySubscription();
      const res = await getAssetTypesList();
      this.originKpis = res;
      res.forEach((item: ReportAssetType) => {
        /** Set available asset types by report template default asset type and available company subscriptions */
        this.handleAvailableAssetTypes(companyAssetTypes, item);
        let tempKpiArray: ReportKPI[] = [allKpi];
        item.kpis.forEach((kpi: ReportAssetTypeKpi) => {
          kpi.statisticsMethods.forEach((method: string) => {
            tempKpiArray.push(this.mapKpi(kpi, method));
          });
        });

        this.kpiListMap.set(item.assetType, tempKpiArray);
      });

      if (this.reportTemplateForm.assetType) {
        this.handleSelectAssetType(this.reportTemplateForm.assetType);
      }
    } catch (error) {
      console.log(error);
      customFailedMessage(
        this.$t('maintenance.errorWithFetchingData').toString()
      );
    } finally {
      this.loadingCreatePage = false;
    }
  }

  /**
   * Handle available asset type for new report template creation by:
   * Retrieving report template default asset types and filter those by company subs available asset types subscriptions
   * !Do not add asset types that have kpis empty list because on API we are not allowed to create with empty KPIs!
   */
  handleAvailableAssetTypes(
    companyAssetTypes: string[] | undefined,
    item: any
  ) {
    if (!companyAssetTypes || companyAssetTypes?.length == 0) {
      this.assetTypeList = [];
    } else if (
      companyAssetTypes.some(
        (assetType: string) => assetType == item.assetType
      ) &&
      !this.assetTypeList.some(
        (assetType: string) => assetType === item.assetType
      ) &&
      item.kpis.length > 0
    ) {
      this.assetTypeList.push(item.assetType);
    }
  }

  /**
   * Map KPI
   * @param kpi
   * @param method
   */
  mapKpi(kpi: any, method: string): ReportKPI {
    return {
      id: kpi.code + '_' + method,
      code: kpi.code,
      unit: kpi.unit,
      method: method,
    };
  }

  /**
   * Fetch roles
   */
  async fetchRoles(): Promise<void> {
    try {
      this.subscriberRolesListIsLoading = true;
      let finalUrl = `?page=1&size=1000&searchFieldName=companyType&searchFieldValues=${UserModule.companyType}`;
      const res = await getRoles(finalUrl);
      if (res.code === 200 && res.data.roles.length > 0) {
        this.originRoles = res.data.roles;
        res.data.roles.forEach((role: any) => {
          this.subscriberRoleList.push({
            id: role.id,
            code: role.code,
            label: role.name,
          });
        });
      }
    } catch (error) {
      console.log(error);
      customFailedMessage(this.$t('common.errorWithFetchingData') as string);
    } finally {
      this.subscriberRolesListIsLoading = false;
    }
  }

  /**
   * Handle selected asset type
   * @param assetType
   */
  handleSelectAssetType(assetType: string): void {
    this.reportTemplateForm.tempKpis = [];
    this.reportTemplateForm.charts = [];
    this.kpiList = this.kpiListMap.get(assetType);

    this.chartKpiList = [];
    this.selectedAssetType = assetType;
    this.selectedAssetTypeTotalKpis = this.originKpis.find(
      (item: any) => item.assetType === this.selectedAssetType
    ).kpis.length;
  }

  /**
   * Select all kpis by asset type
   */
  selectAllKpisByAssetType(): void {
    this.chartKpiList = [];
    const allKpis = this.originKpis.find(
      (item: any) => item.assetType === this.selectedAssetType
    ).kpis;

    allKpis.forEach((kpi: any) => {
      this.chartKpiList.push({
        kpiCode: kpi.code,
        unit: kpi.unit,
        disabled: false,
      });
    });
  }

  /**
   * Check all kpis that are used
   */
  checkAllKpiAreUsed(): void {
    this.allKpiAreUsed =
      !!this.chartKpiList.length &&
      (this.chartKpiList.length === this.reportTemplateForm.charts.length ||
        !!this.chartKpiList.every((el: { kpiCode: string }) =>
          this.handleChartKpiStatus(el.kpiCode)
        ));
  }

  /**
   * Remove KPI tag
   * @param tagValue
   */
  removeKpiTag(tagValue: any): void {
    this.manageChartKpiList(tagValue);
  }

  /**
   * Handle selected item
   * @param kpi
   */
  handleSelectItem(kpi: any): void {
    if (kpi.id === allKpi.id && this.reportTemplateForm.tempKpis.length > 0) {
      this.reportTemplateForm.tempKpis = [allKpi];
    } else {
      let index = this.reportTemplateForm.tempKpis.findIndex(
        (item: any) => item.id === allKpi.id
      );

      if (index > -1) {
        this.reportTemplateForm.tempKpis.splice(index, 1);
      }
    }

    this.manageChartKpiList(kpi);
    this.formValuesChanged();
  }

  /**
   * Manage chart kpis list
   * @param kpi
   */
  manageChartKpiList(kpi: any): void {
    if (kpi.id === allKpi.id) {
      this.selectAllKpisByAssetType();
      return;
    } else if (this.chartKpiList.length == this.selectedAssetTypeTotalKpis) {
      this.chartKpiList = [];
      this.reportTemplateForm.charts.forEach((listItem: any) => {
        const isKPI = this.reportTemplateForm.tempKpis.find(
          (item: any) => item.code == listItem.kpiCode
        );

        if (!isKPI) {
          listItem.kpiCode = '';
        }
      });
    }

    const chartKpiListItem = this.chartKpiList.find(
      (item: any) => item.kpiCode == kpi.code
    );

    if (
      chartKpiListItem &&
      !this.reportTemplateForm.tempKpis.some(
        (item: any) => item.code == kpi.code
      )
    ) {
      const index = this.chartKpiList.findIndex(
        (item: any) => item.kpiCode == kpi.code
      );
      this.chartKpiList.splice(index, 1);

      this.removeKpiFromChart(kpi);
    } else if (!chartKpiListItem) {
      const originKpi: any = this.originKpis
        .find((item: any) => item.assetType === this.selectedAssetType)
        .kpis.find((item: any) => item.code == kpi.code);
      this.chartKpiList.push(this.mapChartKpiListItem(originKpi));
    }
  }

  /**
   * Map chart kpi list item
   * @param originKpi
   */
  mapChartKpiListItem(originKpi: any): {
    kpiCode: string;
    unit: string;
    disabled: boolean;
  } {
    return {
      kpiCode: originKpi.code,
      unit: originKpi.unit,
      disabled: false,
    };
  }

  /**
   * Remove kpi from chart
   * @param kpi
   */
  removeKpiFromChart(kpi: any): void {
    const chart = this.reportTemplateForm.charts.find(
      (item: any) => item.kpiCode == kpi.code
    );

    if (chart) {
      chart.kpiCode = '';
    }
  }

  /**
   * Handle push chart
   */
  handlePushChart(): void {
    this.reportTemplateForm.charts.push({
      kpiCode: '',
      chartType: '',
    });

    this.formValuesChanged();
  }

  /**
   * Handle chart kpi status
   * @param kpiCode
   */
  handleChartKpiStatus(kpiCode: string): boolean {
    let charts = this.reportTemplateForm.charts;
    for (let index = 0; index < charts.length; ++index) {
      if (charts[index].kpiCode === kpiCode) {
        return true;
      }
    }
    return false;
  }

  /**
   * Handle deletion of a chart
   * @param index
   * @param kpiCode
   */
  handleDeleteChart(index: number, kpiCode: string): void {
    this.reportTemplateForm.charts.splice(index, 1);

    this.chartKpiList.map((item: any) => {
      if (item.kpiCode === kpiCode) {
        item.disabled = false;
      }
    });

    this.formValuesChanged();
  }

  /**
   * Get theme color
   */
  getThemeColor(): void {
    const themes = THEME_LIST;
    themes.forEach((item: any) => {
      if (item.id === UserModule.theme) {
        this.activeColor = item.colorVlue;
      }
    });
  }

  /**
   * Handle value changes in the input form
   */
  formValuesChanged(): void {
    this.isformValuesChanged = true;
    this.checkAllKpiAreUsed();
  }

  /**
   * Show dialog to return back
   */
  showBackDialog(): void {
    if (this.isformValuesChanged) {
      this.isQuitEditingTemplateModalVisible = true;
    } else {
      (this.$refs.baseCardRef as BaseCard).goBack();
    }
  }

  /**
   * Cancel event from dialog to quit editing template
   */
  cancelQuitEditingTemplateModal(): void {
    this.isQuitEditingTemplateModalVisible = false;
  }

  /**
   * Cancel editing template
   */
  handleQuitEditingTemplate(): void {
    this.isQuitEditingTemplateModalVisible = false;
    (this.$refs.baseCardRef as BaseCard).goBack();
  }

  /**
   * Handle create or save
   */
  handleCreateAndSave(): void {
    (this.$refs.reportTemplateRef as any).validate(async (valid: any) => {
      if (valid) {
        this.reportTemplateForm.allowedSubscriberRoles = [];

        this.reportTemplateForm.subscriberRoles = (
          this.$refs.reportTemplateRolesRef as any
        ).getCheckedItems();
        if (this.reportTemplateForm.subscriberRoles[0] === 'All') {
          this.originRoles.forEach((role: any) => {
            this.reportTemplateForm.allowedSubscriberRoles.push(role.code);
          });
        } else {
          this.reportTemplateForm.allowedSubscriberRoles =
            this.reportTemplateForm.subscriberRoles;
        }

        this.reportTemplateForm.name = this.reportTemplateForm.name.trim();
        this.reportTemplateForm.charts.forEach((chart: any, index: number) => {
          chart.sequence = index;
        });

        // KPIs
        let tempReportKpis: any = [];
        if (this.reportTemplateForm.tempKpis[0].code === allKpi.code) {
          // Kpi selects 'all'
          this.originKpis.forEach((item: any) => {
            if (item.assetType === this.reportTemplateForm.assetType) {
              tempReportKpis = item.kpis;
              return;
            }
          });
        } else {
          // kpi selects others
          let tempKpisMap: Map<string, any> = new Map<string, any>();

          this.reportTemplateForm.tempKpis.forEach((kpi: any) => {
            if (tempKpisMap.has(kpi.code)) {
              tempKpisMap.get(kpi.code).method.push(kpi.method);
            } else {
              let unitAndMethod = {
                unit: kpi.unit,
                method: [kpi.method],
              };
              tempKpisMap.set(kpi.code, unitAndMethod);
            }
          });

          for (let [key, value] of tempKpisMap) {
            tempReportKpis.push({
              code: key,
              unit: value.unit,
              statisticsMethods: value.method,
            });
          }
        }

        this.reportTemplateForm.kpis = tempReportKpis;

        if (this.reportTemplateId) {
          // Update report template

          await updateReportTemplate(
            this.reportTemplateId,
            this.reportTemplateForm
          ).then((res) => {
            this.errorInfos = [];
            if (res.code === 200) {
              promptSuccessBox(this.$t('common.saved') as string);
              this.$router.push('/report/index?activeName=reportTemplate');
            } else if (res.code === 400) {
              if (
                res.data.errors.some(
                  (error: any) => error.field === 'allowedSubscriberRoles'
                )
              ) {
                customFailedMessage(
                  (this.$t('report.subscriberRolesCannotBeEmpty') as string) +
                    '!'
                );
                return;
              }
              promptFailedBox(this.$tc(`common.update`));
            }
          });
        } else {
          // Create new report template

          await createReportTemplate(this.reportTemplateForm).then((res) => {
            if (res.code === 200) {
              this.errorInfos = [];
              this.$router.push('index?activeName=reportTemplate');
              promptSuccessBox(this.$t('common.created') as string);
            } else if (res.code === 400) {
              if (
                res.data.errors.some(
                  (error: any) =>
                    error.field === 'ApiFieldReportTemplateName' &&
                    error.code === 'ApiErrorFieldDuplicate'
                )
              ) {
                customFailedMessage(
                  this.$t('report.duplicatedReportTemplateName') as string
                );
                this.errorInfos = [...res.data.errors[0]];
                return;
              }

              if (
                res.data.errors.some(
                  (error: any) => error.field === 'allowedSubscriberRoles'
                )
              ) {
                customFailedMessage(
                  (this.$t('report.subscriberRolesCannotBeEmpty') as string) +
                    '!'
                );
                return;
              }

              promptFailedBox(this.$t('common.creation') as string);
              this.errorInfos = [...res.data.errors[0]];
            }
          });
        }
      }
    });
  }
}
</script>

<template>
  <base-card
    ref="baseCardRef"
    :title="
      reportTemplateId
        ? $t('report.editReportTemplate')
        : $t('report.createNewReportTemplate')
    "
    :backIconVisible="true"
    :showDialogBeforeBack="showDialogBeforeBack"
    @handle-back="showBackDialog"
    :buttons="[
      {
        title: reportTemplateId
          ? $t('report.saveTemplate')
          : $t('report.createTemplate'),
        onClick: () => handleCreateAndSave(),
        type: 'Info',
        disabled: false,
      },
    ]"
  >
    <div style="margin-top: 46px" v-loading="loadingCreatePage">
      <el-form
        ref="reportTemplateRef"
        :model="reportTemplateForm"
        :rules="rules"
        :inline-message="false"
        class="report-form"
      >
        <el-row class="columns-container">
          <el-col :span="10" class="left-column">
            <cus-form-item
              :title="$t('report.reportTemplateName')"
              :errContent="errNameInfo"
              style="margin-top: 0px"
            >
              <el-form-item prop="name">
                <el-input
                  v-if="!reportTemplateId"
                  v-model="reportTemplateForm.name"
                  v-bind:placeholder="$t('report.inputReportName')"
                ></el-input>

                <span v-if="reportTemplateId" class="field-value">{{
                  reportTemplateForm.name
                }}</span>
              </el-form-item>
            </cus-form-item>

            <cus-form-item :title="$t('report.assetType')">
              <el-form-item prop="assetType">
                <el-select
                  v-if="!reportTemplateId"
                  v-model="reportTemplateForm.assetType"
                  :placeholder="$t('report.inputAssetType')"
                  @change="handleSelectAssetType"
                >
                  <el-option
                    v-for="(item, index) in assetTypeList"
                    :key="index"
                    :label="$t(item)"
                    :value="item"
                  />
                </el-select>

                <span v-if="reportTemplateId" class="field-value">{{
                  $t(reportTemplateForm.assetType)
                }}</span>
              </el-form-item>
            </cus-form-item>

            <cus-form-item :title="$t('report.kpis')">
              <el-form-item prop="tempKpis">
                <el-select
                  class="report-multiple-select"
                  v-model="reportTemplateForm.tempKpis"
                  :popper-append-to-body="false"
                  :placeholder="$t('report.inputKpis')"
                  :disabled="assetTypeIsNotSelected"
                  multiple
                  value-key="id"
                  @change="checkAllKpiAreUsed"
                  @remove-tag="removeKpiTag($event)"
                >
                  <el-option
                    v-for="item in kpiList"
                    :key="item.id"
                    :label="$t(item.id)"
                    :value="item"
                    @click.native="handleSelectItem(item)"
                  >
                    <div class="report-multiple-checkbox">
                      <div>{{ $t(item.id) }}</div>
                      <div class="report-multiple-checkbox-inner">
                        <div class="report-multiple-checkbox-checked"></div>
                      </div>
                    </div>
                  </el-option>
                </el-select>
              </el-form-item>
            </cus-form-item>

            <cus-form-item :title="'report.table'" :required="false">
              <el-form-item>
                <el-switch
                  v-model="reportTemplateForm.displayedInTable"
                  :active-color="activeColor"
                  @change="formValuesChanged"
                />
              </el-form-item>
            </cus-form-item>

            <cus-form-item
              :title="$t('report.subscriberRoles')"
              v-loading="subscriberRolesListIsLoading"
            >
              <el-form-item>
                <el-scrollbar style="height: 200px">
                  <select-checkbox
                    v-if="
                      subscriberRoleList.length > 0 &&
                      defaultSubscriberRoles.length > 0
                    "
                    ref="reportTemplateRolesRef"
                    :itemList="subscriberRoleList"
                    :defaultCheckedItems="defaultSubscriberRoles"
                    @value-changed="formValuesChanged"
                  />
                </el-scrollbar>
              </el-form-item>
            </cus-form-item>

            <cus-form-item
              :title="'report.note'"
              :required="false"
              class="note-input-element"
            >
              <el-form-item>
                <el-input
                  v-model="reportTemplateForm.note"
                  v-bind:placeholder="$t('report.note')"
                  @change="formValuesChanged"
                ></el-input>
              </el-form-item>
            </cus-form-item>
          </el-col>

          <el-col :span="1" class="columns-separator">
            <div
              style="
                height: 100%;
                position: absolute;
                border-left: 1px solid #e5e5e5;
              "
            />
          </el-col>

          <el-col :span="13" :offset="1" class="right-column">
            <div
              class="add-new-chart-btn"
              :class="{
                'add-new-chart-btn-disabled':
                  assetTypeIsNotSelected ||
                  reportTemplateForm.charts.length === chartKpiList.length ||
                  reportTemplateForm.tempKpis.length === 0,
              }"
            >
              <div
                class="d-flex ai-center jc-between"
                :class="{
                  'add-new-chart-btn-click-disabled':
                    assetTypeIsNotSelected ||
                    reportTemplateForm.charts.length === chartKpiList.length ||
                    reportTemplateForm.tempKpis.length === 0,
                }"
                @click="handlePushChart"
              >
                <i class="el-icon-circle-plus" style="font-size: 28px" />
                <div>{{ $t('report.addNewChart') }}</div>
              </div>
            </div>

            <el-scrollbar style="height: 70vh">
              <div class="d-flex jc-between chart-tiles-container">
                <div
                  class="report-chart"
                  v-for="(chart, index) in reportTemplateForm.charts"
                  :key="index"
                >
                  <div class="d-flex jc-between chart-tile-header">
                    <span class="report-chart-title">
                      {{ $t('report.chart') + ' ' + (index + 1) }}</span
                    >
                    <div
                      @click="
                        handleDeleteChart(
                          index,
                          reportTemplateForm.charts[index].kpiCode
                        )
                      "
                    >
                      <i
                        class="el-icon-delete"
                        style="font-size: 24px; cursor: pointer"
                      />
                    </div>
                  </div>

                  <el-form-item
                    :prop="'charts.' + index + '.kpiCode'"
                    :rules="rules.kpiCode"
                  >
                    <el-select
                      class="report-select"
                      v-model="chart.kpiCode"
                      :placeholder="$t('report.selectKpi') + ' *'"
                      @change="formValuesChanged"
                    >
                      <el-option
                        v-for="(item, index) in chartKpiList"
                        :key="index"
                        :label="$t(item.kpiCode)"
                        :value="item.kpiCode"
                        :disabled="handleChartKpiStatus(item.kpiCode)"
                      />
                    </el-select>
                  </el-form-item>

                  <el-form-item
                    style="margin-top: 10px"
                    :prop="'charts.' + index + '.chartType'"
                    :rules="rules.chartType"
                  >
                    <el-select
                      class="report-select"
                      v-model="chart.chartType"
                      :placeholder="$t('report.selectChartType') + ' *'"
                      @change="formValuesChanged"
                    >
                      <el-option
                        v-for="(item, index) in chartTypes"
                        :key="index"
                        :label="$t(item.id)"
                        :value="item.id"
                      />
                    </el-select>
                  </el-form-item>
                </div>
              </div>
            </el-scrollbar>
          </el-col>
        </el-row>
      </el-form>
    </div>

    <quit-editing-template-modal
      v-if="isQuitEditingTemplateModalVisible"
      :visible.sync="isQuitEditingTemplateModalVisible"
      :title="'report.quitEditing'"
      @handle-cancel="cancelQuitEditingTemplateModal"
      @quit-edit-template-dialog="handleQuitEditingTemplate"
    />
  </base-card>
</template>

<style scoped>
.report-form {
  display: flex;
}

.columns-container {
  display: flex;
  gap: 20px;
  width: 100%;
}

:deep(.left-column .item .item-title) {
  text-align: left;
}

:deep(.item-content) {
  flex: 1 1 27.5rem;
  overflow: 'auto';
}

.left-column {
  min-width: 48%;
  max-width: 50%;
}

.columns-separator {
  width: 0px;
}

.right-column {
  min-width: 48%;
  max-width: 50%;
  margin-left: 0px;
  flex: 1 1 auto;
}

.note-input-element {
  margin-bottom: 20px;
}

.chart-tiles-container {
  display: block;
}

.left-column :deep(.checkbox-items) {
  margin-right: 10px;
}
</style>

<style lang="scss" scoped>
/* Screen larger than 1024px? 2 column */
@media (min-width: 1900px) {
  .chart-tiles-container {
    display: flex;
    flex-wrap: wrap;
  }
}

:deep(.el-scrollbar__wrap) {
  overflow-x: hidden !important;
}

:deep(.el-select-dropdown__item) {
  border-bottom: 1px solid #e5e5e5;
}

:deep(.el-tag--small) {
  height: 25px;
}

:deep(
    .el-select-dropdown.is-multiple .el-select-dropdown__item.selected::after
  ) {
  content: none;
}

:deep(
    .el-select-dropdown.is-multiple
      .el-select-dropdown__item.selected
      .report-multiple-checkbox-checked
  ) {
  background-color: var(--Main);
  width: 12px;
  height: 12px;
}

.report-multiple-select :deep(.el-scrollbar) {
  padding-bottom: 10px;
}

.add-new-chart-btn {
  cursor: pointer;
  color: #349d99;
  font-size: 20px;
  font-family: $font-Roboto-Medium;
  line-height: 24px;
  margin-bottom: 10px;
  width: 170px;
}

.add-new-chart-btn-disabled {
  color: #c0c4cc;
  cursor: not-allowed;
}

.add-new-chart-btn-click-disabled {
  pointer-events: none;
}

.report-chart {
  width: 406px;
  height: 184px;
  border: 1px solid #707070;
  border-radius: 8px;
  padding: 20px;
  // margin-top: 20px;
  margin: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.report-select {
  width: 365px;

  :deep(.el-input__inner) {
    height: $inputHeight;
    width: 365px;
    // width: 31vw;
    border: 1px solid #707070 !important;
    font-size: 1.285714rem;
    font-family: $font-Roboto-Regular;
    line-height: 1.357143rem;
    color: #373e41;
    opacity: 1;
  }
}

.el-select-dropdown__item.is-disabled {
  color: #c0c4cc !important;
}

.report-chart-title {
  font-size: 20px;
  font-family: $font-Roboto-Regular;
  line-height: 24px;
  color: #373e41;
}

.report-multiple-checkbox {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .report-multiple-checkbox-inner {
    border-radius: 2px;
    background-color: #ffffff;
    border: 2px solid #848484;
    width: 18px;
    height: 18px;

    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.field-value {
  color: #373e41;
  font-size: 16px;
  line-height: 18.75px;
}

.chart-tile-header {
  margin-bottom: 5px;
}
</style>
