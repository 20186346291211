<script lang="ts">
import { getKpisForMultipleAssets } from '@/api/kpis';
import {
  getCustomers,
  getFilteredReleases,
  getSystemReleaseAssets,
  productSystemReleasesByID,
  startNewRelease,
  SystemRelease,
} from '@/api/systemReleaseManagement';
import NavPage from '@/components/nav/NavPage.vue';
import PureTable from '@/components/table/PureTable.vue';
import { UserModule } from '@/store/modules/user';
import Debouncer from '@/utils/debounce';
import { customFailedMessage } from '@/utils/prompt';
import {
  getPartFirmwareInformation,
  getProductSystemInformation,
  mapFirmwareReleaseData,
} from '@/utils/releaseInformationUtil';
import { NEW_DEPLOY_COLS } from '@/utils/workData/deploymentManagementCols';
import {
  GENERAL_QUERY_OPERATORS,
  KPI_FIELDS,
} from '@/utils/workData/lookuptable';
import { OperationalStatus } from '@/utils/workData/operationalStatus';
import ContentContainer from '@/views/releasesManagement/components/ListContent.vue';
import { ElTable } from 'element-ui/types/table';
import { Component, Vue } from 'vue-property-decorator';

@Component({
  name: 'StartDeployment',
  components: {
    NavPage,
    'pure-table': PureTable,
    'content-list': ContentContainer,
  },
})
export default class extends Vue {
  title: string = `${this.$t('deployManagementModule.start.title')}`;

  systemRelease: string | null = null;
  systemReleases: SystemRelease[] = [];

  orgList: any[] = [];
  selectedOrganization: string | null = null;

  placeholder: string = 'Select';

  selectedAssetCount: number = 0;

  confirmStep: boolean = false; // true if we're at the confirming stage

  // table
  selectedPage: number = 1;
  pageSize: number = UserModule.gridPageSize;
  selectedAssets: string[] = []; // all selected assets
  selectedPageAssets: [] = []; // assets that are selected on this very page of the table

  assetList: [] = []; // raw asset list, but filtered
  rawAssetList: [] = []; // data from api, as it comes
  assetCount: number = 0;
  assetCols = NEW_DEPLOY_COLS.map((item) => ({
    ...item,
    label: this.$t(item.label),
  }));
  tableLoading: boolean = false;

  // table filter list
  filterList: { field: string; label?: string; dropdownEnum?: any }[] = [
    {
      field: 'companyAssetId',
      label: 'deployManagementModule.start.columns.customerAssetId',
    },
    {
      field: KPI_FIELDS.LastCommunicationTime.replace('.', '_'),
      label: 'deployManagementModule.start.columns.lastCommunicationTime',
    },
    {
      field: 'organizationName',
      label: 'deployManagementModule.start.columns.organization',
    },
    {
      field: KPI_FIELDS.OperationalStatus.replace('.', '_'),
      dropdownEnum: OperationalStatus,
      label: 'deployManagementModule.start.columns.operationalStatus',
    },
    { field: 'currentSystemReleaseId' },
  ];

  assetTableFilter: {
    searchFieldName: string;
    searchFieldValue: string | null;
  } = {
    searchFieldName: this.filterList[0].field,
    searchFieldValue: null,
  };

  filterEnum: any = null;

  // review table
  reviewAssetList: any[] = []; // current page
  fullReviewAssetList: any[] = []; // all pages
  reviewPage: number = 1;
  reviewAssetCount: number = 0;

  // system release info
  systemReleaseName: string = '';
  productSystemReleaseID: string = '';
  productSystemReleasesIDs = [];
  partFirmwareInformation: any[] = [[]];
  productSystemReleaaseInformation: { title: string; content: any[] } = {
    title: 'releaseDetails.productSystemReleaseInfo',
    content: [],
  };

  newReleaseData: any = {
    productSystemReleaseId: '',
    productSystemReleaseName: '',
    productSystemReleaseVersion: '',
    assetType: '',
    productModel: '',
    modelNumber: '',
    deploymentDuration: 0,
    releaseStatus: '',
    defaultRelease: true,
    noOfCustomers: 0,
    systemReleaseNote: '',
    linkedCustomers: [
      {
        customerId: '',
        customerName: '',
        organizations: [],
        numberOfAssets: 0,
      },
    ],
    partFirmwareInformation: [
      {
        partFirmwareName: '',
        containedPartName: '',
        partFirmwareVersion: '',
        partFirmwareReleaseNote: '',
      },
    ],
    createdTime: '',
  };

  // new deployment
  deploymentId: string | null = null;

  handleSearchInput() {
    Debouncer.debounce(this.filterRawAssetList);
  }

  async handleSearchClear() {
    this.assetTableFilter.searchFieldValue = '';
    this.filterEnum = this.filterList.find(
      (e) => e.field === this.assetTableFilter.searchFieldName
    )?.dropdownEnum;
    this.handleSearchInput();
  }

  async created() {
    // retrieve list of active releases
    const releasesResponse = await getFilteredReleases({
      filters: [
        {
          name: 'releaseStatus',
          operator: GENERAL_QUERY_OPERATORS.Equal,
          value: ['RELSTAT_ACTIVE'],
        },
      ],
      pagination: {
        page: 1,
        size: 10000, //TODO: either load with pagionation or find a way to load all of em
      },
    });

    if (!releasesResponse || releasesResponse.code !== 200) {
      customFailedMessage(
        this.$t('deployManagementModule.start.releasesFetchError') as string
      );
      return;
    }
    this.systemReleases = releasesResponse.data.systemReleases;
  }

  // when organization changes
  async organizationChange() {
    await this.refreshTableData();
  }

  async refreshTableData() {
    let selectedCompany: any = this.orgList.filter(
      (o: any) => o.name === this.selectedOrganization
    )[0];
    let id = selectedCompany.id;
    let filters: any[] = [
      {
        name: 'systemReleaseId',
        operator: GENERAL_QUERY_OPERATORS.NoEqual,
        value: [this.systemRelease],
      },
      {
        name: 'companyId',
        operator: GENERAL_QUERY_OPERATORS.Equal,
        value: [id],
      },
    ];

    // Some of the required filters cannot be applied on backend (mostly kpi data...)
    // so instead, we shall filter on frontend for now..
    //
    // if(this.assetTableFilter.searchFieldValue) {
    //   filters.push({
    //     name: this.assetTableFilter.searchFieldName,
    //     operator: GENERAL_QUERY_OPERATORS.Like,
    //     value: [this.assetTableFilter.searchFieldValue]
    //   });
    // }

    this.tableLoading = true;
    const assetsResponse = await getSystemReleaseAssets({
      pagination: {
        page: this.selectedPage,
        size: this.pageSize,
      },
      filters: filters,
    });

    if (!assetsResponse || assetsResponse.code !== 200) {
      customFailedMessage(
        this.$t('deployManagementModule.start.assetsFetchError') as string
      );
      this.tableLoading = false;
      this.assetList = [];
      return;
    }

    // fetch kpi data
    const assetIds = assetsResponse.data.assets.map((a: any) => a.assetUUID);
    if (assetIds.length != 0) {
      const kpiRequest = {
        metadata: {
          filter: {
            assetIds: assetIds,
          },
        },
        details: [
          {
            fields: [
              { code: KPI_FIELDS.LastCommunicationTime },
              { code: KPI_FIELDS.OperationalStatus },
            ],
          },
        ],
      };

      const kpiData: any = await getKpisForMultipleAssets(kpiRequest);

      if (!kpiData) {
        customFailedMessage(
          this.$t('deployManagementModule.start.kpiFetchError') as string
        );
        this.tableLoading = false;
        return;
      }

      kpiData.data.forEach((kpi: any) => {
        assetsResponse.data.assets.forEach((asset: any) => {
          // assing asset id, needed for the table to work properly.
          asset.id = asset.assetUUID;

          // assign kpi data
          if (asset.assetUUID === kpi.assetId) {
            kpi.values.forEach((kpiValue: any) => {
              // note: workaround for translation key issues

              asset[kpiValue.code.replace('.', '_')] = kpiValue.v;
            });
          }
        });
      });
    }

    this.rawAssetList = assetsResponse.data.assets;
    this.filterRawAssetList();
    this.assetCount = assetsResponse.data.total;
    this.tableLoading = false;

    if (!this.confirmStep) {
      this.assetList.forEach((asset: any) => {
        if (
          this.fullReviewAssetList.filter(
            (reviewAsset: any) => asset.id !== reviewAsset.id
          )
        ) {
          this.fullReviewAssetList.push(asset);
        }
      });
    }

    // refesh current page selected assets
    this.$nextTick(() => {
      let selectedAssets = this.assetList.filter((asset: any) =>
        this.selectedAssets.includes(asset.assetUUID)
      );

      let table = this.$refs.assetTable as ElTable;

      selectedAssets.forEach((asset: any) => {
        (table.$refs.tableRef as ElTable).toggleRowSelection(asset, true);
      });
    });
  }

  filterRawAssetList() {
    if (!this.assetTableFilter.searchFieldValue) {
      this.assetList = this.rawAssetList;
      return;
    }

    this.assetList = this.rawAssetList.filter((asset) => {
      let fieldValue = (
        asset[this.assetTableFilter.searchFieldName] as string
      ).toLowerCase();
      return fieldValue.includes(
        (this.assetTableFilter.searchFieldValue as string).toLowerCase()
      );
    }) as [];
  }

  async deployButtonClick() {
    if (!this.confirmStep) {
      // fetch data
      this.productSystemReleaaseInformation.content = [];
      this.partFirmwareInformation = [[]];
      try {
        const response = await productSystemReleasesByID(
          this.systemRelease as string
        );
        this.systemReleaseName = response.data.systemReleaseId;
        this.productSystemReleaseID = response.data.id;
        mapFirmwareReleaseData(response.data);
        this.productSystemReleaaseInformation.content =
          getProductSystemInformation(response.data);
        this.partFirmwareInformation = getPartFirmwareInformation(
          response.data.partFirmwareRelease
        );
      } catch (err) {
        console.log(err);
        customFailedMessage(
          this.$t('deployManagementModule.start.releasesFetchError') as string
        );
        return;
      }

      // to ensure assets were transfered to the proper list
      this.pageChange(this.selectedPage, this.pageSize);
      if (this.selectedAssets.length == 0) {
        return;
      }

      this.fullReviewAssetList = this.fullReviewAssetList.filter((asset: any) =>
        this.selectedAssets.includes(asset.id)
      );
      this.reviewAssetCount = this.fullReviewAssetList.length;
      this.reviewPageChange(this.reviewPage, this.pageSize);
      this.confirmStep = true;

      return;
    }

    const deploymentAnswer = await startNewRelease({
      deployments: [
        {
          systemReleaseUUID: this.systemRelease,
          companyId: this.orgList.filter(
            (o: any) => o.name === this.selectedOrganization
          )[0].id,
          assets: this.selectedAssets,
        },
      ],
    });

    if (!deploymentAnswer || deploymentAnswer.code !== 200) {
      customFailedMessage(
        this.$t('deployManagementModule.start.deploymentError') as string
      );
      return;
    }

    this.deploymentId = deploymentAnswer.data.deployments[0].deploymentId;
  }

  handleUpdateSelectedAssets(selectedAssets: []) {
    this.selectedPageAssets = selectedAssets;
    this.selectedAssetCount =
      this.selectedAssets.length + selectedAssets.length;
  }

  async pageChange(page: number, pageSize: number) {
    // update selected assets list
    let selectedAssets = [...this.selectedAssets];
    this.assetList.forEach((assetOnPage: any) => {
      const index = this.selectedAssets.indexOf(assetOnPage.assetUUID);
      if (index >= 0) {
        // remove items from this page that already exist in the selected assets list
        selectedAssets.splice(index, 1);
      }
    });

    // add back all the selected assets
    this.selectedAssets = [...selectedAssets, ...this.selectedPageAssets];
    this.selectedPageAssets = [];

    this.selectedPage = page;

    await this.refreshTableData();
    this.selectedAssetCount = this.selectedAssets.length;
  }

  fetchAssignedAssetsDataBySortEvent(a: any, b: any, c: any) {}

  // review asset table changed pages
  reviewPageChange(page: number, pageSize: number) {
    const index = (page - 1) * pageSize;
    this.reviewPage = page;
    this.reviewAssetList = [
      ...this.fullReviewAssetList.slice(index, index + pageSize),
    ];
  }

  // when selected system release changes
  async systemReleasesSelected() {
    const oldOrg = this.selectedOrganization;
    this.selectedOrganization = null;
    this.orgList = [];

    const customersResponse = await getCustomers({
      filters: [
        {
          name: 'systemReleaseUuid',
          operator: GENERAL_QUERY_OPERATORS.Equal,
          value: [this.systemRelease],
        },
        {
          name: 'linkedCustomers',
          operator: GENERAL_QUERY_OPERATORS.Equal,
          value: [true],
        },
      ],
      pagination: {
        page: '1',
        size: '100',
      },
    });
    if (!customersResponse || customersResponse.code !== 200) {
      customFailedMessage(
        this.$t('deployManagementModule.start.customersFetchError') as string
      );
      return;
    }

    this.orgList = customersResponse.data.customers.map((c: any) => {
      return { id: c.customerId, name: c.customerName };
    });

    if (this.orgList.find((o: any) => o.name == oldOrg)) {
      this.selectedOrganization = oldOrg;
      await this.refreshTableData();
    }
  }

  // see progress button clicked
  seeProgress() {
    this.$router.push(`/deploy/details/${this.deploymentId}`);
  }
}
</script>

<template>
  <div>
    <NavPage :visible="true" :class="!confirmStep ? '' : 'not-displayed'">
      <div class="header">
        <div>
          <span>{{ $t('deployManagementModule.start.deploy') }}</span>

          <el-select
            v-model="systemRelease"
            :placeholder="placeholder"
            @change="systemReleasesSelected"
            filterable
          >
            <el-option
              v-for="item in systemReleases"
              :key="item.id"
              :label="item.systemReleaseId"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </div>

        <div :class="systemRelease ? '' : 'invisible'">
          <span>{{ $t('deployManagementModule.start.to') }}</span>
          <el-select
            filterable
            v-model="selectedOrganization"
            :placeholder="placeholder"
            @change="organizationChange"
          >
            <el-option
              v-for="item in orgList"
              :key="item.id"
              :label="item.name"
              :value="item.name"
            >
            </el-option>
          </el-select>
        </div>
        <div>
          <span :class="selectedOrganization ? '' : 'invisible'">{{
            `${selectedAssetCount} ${$t(
              'deployManagementModule.start.assetsSelected'
            )}`
          }}</span>
          <el-button
            :class="selectedOrganization ? '' : 'invisible'"
            v-on:click="deployButtonClick"
            >{{ $t('deployManagementModule.start.deployButton') }}</el-button
          >
        </div>
      </div>
      <div :class="selectedOrganization ? 'filter-container' : 'invisible'">
        <div class="d-flex ai-center search-select-container">
          <el-select
            class="search-select-field"
            id="releaseFilter"
            v-model="assetTableFilter.searchFieldName"
            @change="handleSearchClear"
            filterable
          >
            <el-option
              v-for="item in filterList"
              :key="item.label"
              :label="
                $t(
                  item.label ||
                    `deployManagementModule.start.columns.${item.field}`
                )
              "
              :value="item.field"
            />
          </el-select>
          <el-select
            v-if="filterEnum"
            @change="handleSearchInput"
            v-model="assetTableFilter.searchFieldValue"
            class="search-input-content"
          >
            <el-option
              class="filter-select-option"
              v-for="item of filterEnum"
              :key="item"
              :label="$t(item)"
              :value="item"
            />
          </el-select>
          <el-input
            v-else
            id="input-search-value"
            class="search-input-content"
            :placeholder="$t('common.inputKeywordToSearch')"
            v-model="assetTableFilter.searchFieldValue"
            @clear="handleSearchClear"
            @keyup.native="handleSearchInput"
            clearable
          >
            <i
              slot="suffix"
              class="el-icon-search"
              @click="handleSearchInput"
            />
          </el-input>
        </div>
      </div>
      <pure-table
        :class="selectedOrganization ? '' : 'invisible'"
        :tableList="assetList"
        :total="assetCount"
        :cols="assetCols"
        :selectable="true"
        v-loading="tableLoading"
        ref="assetTable"
        @updateSelectedAssets="handleUpdateSelectedAssets"
        @handle-page="pageChange"
        @handle-sort-change="fetchAssignedAssetsDataBySortEvent"
      />
    </NavPage>

    <NavPage :visible="true" :class="confirmStep ? '' : 'not-displayed'">
      <div
        class="header header-success"
        :class="deploymentId ? '' : 'not-displayed'"
      >
        <span>{{ $t('deployManagementModule.start.deploymentStarted') }}</span>
        <el-button v-on:click="seeProgress">{{
          $t('deployManagementModule.start.seeProgress')
        }}</el-button>
      </div>
      <div class="header" :class="deploymentId ? 'not-displayed' : ''">
        <div>
          <span>{{ $t('deployManagementModule.start.deploy') }}</span>

          <span>{{ systemReleaseName }}</span>
        </div>

        <div :class="systemRelease ? '' : 'invisible'">
          <span>{{ $t('deployManagementModule.start.to') }}</span>
          <span>{{ selectedOrganization }}</span>
        </div>
        <div>
          <span :class="selectedOrganization ? '' : 'invisible'">{{
            `${selectedAssetCount} ${$t(
              'deployManagementModule.start.assetsSelected'
            )}`
          }}</span>
          <el-button
            :class="selectedOrganization ? '' : 'invisible'"
            v-on:click="deployButtonClick"
            >{{
              $t('deployManagementModule.start.confirmDeployButton')
            }}</el-button
          >
        </div>
      </div>
      <div class="container-outer">
        <div class="left-content">
          <h2 class="title">
            {{ $t('releaseDetails.productSystemReleaseInfo') }}
          </h2>
          <content-list
            :content="productSystemReleaaseInformation.content"
            :showReleaseNotes="true"
            :productSystemReleaseID="productSystemReleaseID"
          />
        </div>
        <div class="right-content">
          <h2 class="title">
            {{ $t('releaseDetails.partFirmwareInfo.title') }}
          </h2>
          <content-list
            v-for="info in partFirmwareInformation"
            :key="info.containedPartName"
            :showReleaseNotes="false"
            :productSystemReleaseID="productSystemReleaseID"
            :content="info"
            :title="
              (
                info.find(
                  /* @ts-expect-error TODO Unknown type */
                  (i) => i.id === 'productSystemRelease.containedPartName'
                ) || {}
              ).value
            "
          />
        </div>
      </div>
      <span class="header-title" style="margin: 1rem">{{
        `${$t('deployManagementModule.start.assetsToBeDeployed')} (${
          selectedAssets.length
        })`
      }}</span>
      <pure-table
        style="margin: 1rem"
        :tableList="reviewAssetList"
        :total="reviewAssetCount"
        :cols="assetCols"
        :selectable="false"
        @handle-page="reviewPageChange"
      />
    </NavPage>
  </div>
</template>

<style lang="scss" scoped>
.page-content {
  display: flex;
  flex-direction: row;
  width: 100%;
  padding: 16px;

  .table {
    width: 50%;
    height: 710px;
    padding: 16px;
    border-right: 1px solid rgba(55, 62, 65, 0.2);
  }

  .details {
    width: 50%;
  }
}

.header {
  background: #edeff0;
  font-size: 20px;
  margin: 1rem;

  display: flex;
  align-items: center;
  justify-content: space-between;
}

.header > div {
  display: flex;
  align-items: baseline;
}

.header > div span {
  margin-right: 0.25rem;
}

.invisible {
  opacity: 0;
}

.not-displayed {
  display: none;
}

.container-outer {
  text-align: left;
  display: flex;
  flex-direction: row;
  margin: 0px;
  padding: 18px;
  gap: 0px !important;
}

.lable {
  width: 264px;
  color: #373e41;
  text-transform: capitalize;
  font-weight: 400;
  font-family: Roboto;
  opacity: 0.6;
}

.product-system-releaseID {
  display: flex;
  flex-direction: row;
  gap: 30px;
  border: 1px solid #707070;
  border-bottom: none;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  padding: 20px 18px 12px 18px;
}

.product-release-info {
  border-top: none;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.left-content {
  width: 50%;
  flex-direction: column;
  margin: 0.5rem;
}

.right-content {
  width: 50%;
  display: flex;
  flex-direction: column;
  margin: 0.5rem;
}

.right-content > .container {
  margin-bottom: 0.5rem;
}

.header-success > span {
  color: #139d4c;
  width: 100%;
  font-size: xx-large;
  text-align: center;
}

.search-input-content {
  width: 400px;
}

.filter-container {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  margin: 1rem;
}

.search-select-container {
  :deep(.el-input__inner) {
    height: 40px;
    border: 1px solid #818181;
  }

  .search-select-field :deep(.el-input__inner) {
    width: 252px;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    border-right: transparent !important;
  }

  .search-select-field {
    margin: 0;
  }

  .search-input-content :deep(.el-input__inner) {
    width: 270px;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
  }

  .search-input-content :deep(.el-input__suffix) {
    line-height: 40px;
    color: rgba(0, 0, 0, 0.6);
    font-size: 18px;
    cursor: pointer;
    width: 282px;
  }
}
</style>
