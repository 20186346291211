export const HEALTH_MANAGEMENT_COLS = [
  {
    label: 'healthManagementModule.healthFactor',
    prop: 'healthFactor',
    required: true,
    visible: true,
    sortable: true,
  },
  {
    label: 'healthManagementModule.factorType',
    prop: 'factorType',
    required: true,
    visible: true,
    sortable: true,
  },
  {
    label: 'healthManagementModule.assetType',
    prop: 'assetType',
    required: true,
    multiLang: true,
    visible: true,
    sortable: true,
  },
  {
    label: 'healthManagementModule.ifEnable',
    prop: 'ifEnable',
    required: true,
    visible: true,
    sortable: true,
    toggleable: true,
  },
  {
    prop: 'editable',
    required: true,
    visible: false,
  },
  {
    prop: 'index',
    required: true,
    visible: false,
  },
  {
    label: 'healthManagementModule.normalCondition',
    prop: 'normalCondition',
    required: true,
    visible: true,
    sortable: true,
  },
  {
    label: 'healthManagementModule.warningCondition',
    prop: 'warningCondition',
    required: true,
    visible: true,
    sortable: true,
  },
  {
    label: 'healthManagementModule.alarmCondition',
    prop: 'alarmCondition',
    required: true,
    visible: true,
    sortable: true,
  },
];
