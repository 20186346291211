<script lang="ts">
import {
  createTemplate,
  DashboardTemplate,
  getModules,
  getTemplatesById,
  updateTemplate,
} from '@/api/dashboardConf';
import BaseCard from '@/components/cusCard/BaseCard.vue';
import EditDashTemplate from '@/components/template/EditDashTemplate.vue';
import { CustMgmtModule } from '@/store/modules/custMgmt';
import { promptSuccessBox } from '@/utils/prompt';
import { ALL_CLAIMS_CODES } from '@/utils/workData/lookuptable';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { handleUpdateAPIErrors } from './dashboardTemplate';

interface WIDGET_TYPE {
  i: string;
  x: number;
  y: number;
  w: number;
  h: number;
  minW: number;
  minH: number;
  maxW: number;
  maxH: number;
  isResizable: boolean;
  code: string;
  pageCode: string;
  name: string;
}

@Component({
  name: 'DashboardTemplateEdit',
  components: {
    BaseCard,
    EditDashTemplate,
  },
})
export default class extends Vue {
  @Prop() templateId!: string;

  title: string = '';

  editDashTemplateChild: any = {};
  templateForm: DashboardTemplate = {
    id: '',
    companyId: '',
    name: '',
    parentTemplateId: '',
    customerType: '' as any,
    industry: '' as any,
    widgets: [],
    unSelectedModules: [],
    isDefault: false,
  };

  handleRecover() {
    this.editDashTemplateChild.handleResetToDefault('', '', this.templateId);
  }

  async handleSave() {
    this.editDashTemplateChild.updateWidget();
    this.templateForm.unSelectedModules =
      this.editDashTemplateChild.filterModules();

    if (
      !this.editDashTemplateChild.isCheckedNodeListNull() &&
      !this.editDashTemplateChild.isPageNull()
    ) {
      this.templateForm.widgets = this.editDashTemplateChild.getAllWidgets();

      if (CustMgmtModule.customerId) {
        await updateTemplate(this.templateId, this.templateForm).then((res) => {
          if (res.code === 200) {
            promptSuccessBox(this.$t('common.save') as string);
            this.$router.push(
              `/customer-management/edit/${CustMgmtModule.customerId}?activeName=dashboardTemplateTab`
            );
            CustMgmtModule.setTemplateId('');
            CustMgmtModule.setCustomerId('');
          } else if (res.code === 400) {
            handleUpdateAPIErrors(res);
          }
        });
      } else {
        this.templateForm.companyId = '00000000-0000-0000-0000-000000000000';
        this.templateForm.parentTemplateId = CustMgmtModule.templateId;
        await createTemplate(this.templateForm).then((res) => {
          if (res.code === 200 && res.data) {
            CustMgmtModule.setIsSave(true);
            CustMgmtModule.setCustTemplateId(res.data.id);
            promptSuccessBox(this.$t('common.save') as string);
            this.$router.push(
              '/customer-management/create?activeName=dashboardTemplateTab'
            );
          } else if (res.code === 400) {
            handleUpdateAPIErrors(res);
          }
        });
      }
    }
  }

  async fetchTemplateById() {
    const res = await getTemplatesById(this.templateId);

    this.title = res.data.name;
    this.templateForm = res.data;

    this.editDashTemplateChild.updateWidgetsBuffer(this.templateForm.widgets);

    this.editDashTemplateChild.handleNodeClick({
      name: ALL_CLAIMS_CODES.AUTHRSC_MOD_HOME,
      children: [],
    });

    this.templateForm.widgets = [];
  }

  async fetchCheckedModules() {
    const resCheckedModules = await getModules(this.templateId);
    this.editDashTemplateChild.setCheckedModules(resCheckedModules);
  }

  mounted() {
    this.editDashTemplateChild = this.$refs.custEditDashTemplate as any;
    this.templateId && this.fetchCheckedModules();
    this.templateId && this.fetchTemplateById();
  }
}
</script>

<template>
  <BaseCard :title="title" :backIconVisible="true">
    <template v-slot:rightHeader>
      <div>
        <el-button
          id="new_card_header_btn_edit"
          style="margin-right: 10px; width: 120px"
          type="plain"
          @click="handleRecover"
        >
          <div class="d-flex ai-center jc-center">
            <div>
              <img
                style="width: 13px; height: 13px; margin-right: 5px"
                src="@/assets/imgs/customer-management/recover.svg"
              />
            </div>
            <div>
              {{ $t('common.recover') }}
            </div>
          </div>
        </el-button>
        <el-button
          id="new_card_header_btn_deactivate"
          type="plain"
          style="width: 120px"
          @click="handleSave"
        >
          <div class="d-flex ai-center jc-center">
            <div>
              <i class="el-icon-plus common-icon" />
            </div>
            <div>
              {{ $t('common.save') }}
            </div>
          </div>
        </el-button>
      </div>
    </template>

    <EditDashTemplate
      ref="custEditDashTemplate"
      :id="templateId"
      :customerType="templateForm.customerType"
    />
  </BaseCard>
</template>
