var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(_setup.BaseCard,{staticClass:"parameter-configuration-main-container",attrs:{"title":_setup.pageTitle,"backIconVisible":_setup.mode === _setup.Mode.Edit,"buttons":_setup.buttons,"showDialogBeforeBack":true},on:{"handle-back":_setup.handleCancel}},[_c('div',{staticClass:"table-container"},[(_setup.lastCommunicationTimeCanBeDisplayed)?_c('div',{directives:[{name:"loading",rawName:"v-loading",value:(
        (_setup.userRefresh && _setup.lastCommunicationTimeQuery.isFetching.value) ||
        _setup.lastCommunicationTimeQuery.isLoading.value
      ),expression:"\n        (userRefresh && lastCommunicationTimeQuery.isFetching.value) ||\n        lastCommunicationTimeQuery.isLoading.value\n      "}],staticClass:"communication-time-label"},[_vm._v(" "+_vm._s(_setup.i18n.tc('assets.lastCommunicationTime') + ':')+" "+_vm._s(_setup.lastCommunicationTimeQuery.data.value)+" ")]):_vm._e(),_c(_setup.ParameterConfigurationTable,{directives:[{name:"loading",rawName:"v-loading",value:(
        (_setup.userRefresh && _setup.currentAssetConfigParams.isFetching.value) ||
        _setup.currentAssetConfigParams.isLoading.value
      ),expression:"\n        (userRefresh && currentAssetConfigParams.isFetching.value) ||\n        currentAssetConfigParams.isLoading.value\n      "}],staticClass:"parameters-table",attrs:{"tableData":_setup.mode === _setup.Mode.Edit
          ? _setup.editModeRows
          : _setup.currentAssetConfigParams.data.value ?? [],"columns":_setup.columns,"paginated":false,"page-size":1,"total":1}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }