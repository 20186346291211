/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon';
icon.register({
  deactive: {
    width: 14,
    height: 14,
    viewBox: '0 0 14 14',
    data: '<g data-name="椭圆 111" _stroke="#373e41" _fill="none"><circle pid="0" cx="7" cy="7" r="7" _stroke="none"/><circle pid="1" cx="7" cy="7" r="6.5"/></g><path pid="2" data-name="直线 329" _stroke="#373e41" _fill="none" d="M2.5 11.5l9.45-9"/>',
  },
});
