<script lang="ts">
import {
  getFilterPartMaterials,
  getPartMaterials,
  PartMaterial,
  updateProductModel,
} from '@/api/products';
import { ErrorType } from '@/api/types';
import { UserModule } from '@/store/modules/user';
import { customFailedMessage, promptSuccessBox } from '@/utils/prompt';
import {
  LIFETIME_UNIT_LIST,
  MAINTAINABLE_LIST,
  MODEL_PART_TYPE_ENUM,
  PRODUCT_MODEL_LIFECYCLE_ENUM,
  PROD_CATA_OBU_INSTANCE_LIST,
  PROD_ERROR_CODE_LIST,
} from '@/utils/workData/lookuptable';
import { PROD_CATALOGUE_MODEL_PARTS_INFORMATIONS } from '@/utils/workData/prodCata';
import { cloneDeep } from 'lodash';
import { Component, Emit, Prop, Vue, Watch } from 'vue-property-decorator';

interface Column {
  label: string;
  prop: string;
  required: boolean;
  visible: boolean;
  sortable: string;
}

interface SortAndOrder {
  sortBy: string | null;
  order: string | null;
}

interface Part {
  name: string;
  partMaterialId: string;
  partMaterialName: string;
  partType: string;
  isMaintainable: boolean;
  lifeTime: string;
  lifeTimeUnit: string;
  disabled?: boolean;
  obuInstance?: string;
}

interface PartForm {
  parts: Part[];
}

@Component({
  name: 'partsForm',
  components: {},
})
export default class extends Vue {
  @Prop({ required: false }) partsInfoPrev!: any;
  @Prop({ required: false }) lifeCycle!: string;
  @Prop({ required: false }) state!: any;

  /** Local variables */
  maintainableList = MAINTAINABLE_LIST;
  obuInstances = PROD_CATA_OBU_INSTANCE_LIST;
  partsTableIsLoading: boolean = false;
  lifeTimeUnitList = LIFETIME_UNIT_LIST;
  materialNumberList: PartMaterial[] = [];
  columns: Column[] = PROD_CATALOGUE_MODEL_PARTS_INFORMATIONS;
  partForm: PartForm = {
    parts: [],
  };
  errorCode = PROD_ERROR_CODE_LIST;
  errorInfos: ErrorType[] = [
    {
      code: '',
      field: '',
      message: '',
    },
  ];
  pageSize: string = UserModule.gridPageSize.toString();
  page: string = '1';
  sortAndOrderData: SortAndOrder = {
    sortBy: null,
    order: null,
  };
  selectForm = {
    partNameorId: '',
    partType: '',
    lifeCycle: 'LCL_APPROVED',
  };

  created() {}

  /**
   * Error name info
   */
  get errNameInfo(): string {
    let errInfo: string = '';
    if (
      this.errorInfos.find((item) => item.field === this.errorCode.partType)
        ?.code === 'ApiErrorFieldInvalid'
    ) {
      errInfo = `${this.$t('prodCata.lifeCycleError')}`;
    }
    if (
      this.errorInfos.find(
        (item) => item.field === this.errorCode.partMaterialNumber
      )?.code === 'ApiErrorFieldDuplicate'
    ) {
      errInfo = `${this.$t('prodCata.partMaterialNumberDuplicated')}`;
    }
    return errInfo === '' ? this.errorInfos[0].message : errInfo;
  }

  /**
   * Watch over page in preview mode to preset conditions before changing to edit mode
   * @param value
   * @param oldVal
   */
  @Watch('partsInfoPrev', { immediate: true, deep: true })
  private watchPartsInfo(value: any, oldVal: any): void {
    if (
      this.$route.params.action ||
      this.$route.params.action === 'copy' ||
      this.state === 'edit'
    ) {
      this.partForm.parts = this.partsInfoPrev.containedParts
        ? cloneDeep(this.partsInfoPrev.containedParts)
        : [];
    }
    if (this.state === 'edit' && this.lifeCycle != 'LCL_DRAFT') {
      this.partForm.parts.map((item: any) => (item.disabled = true));
    } else {
      this.partForm.parts.map((item: any) => (item.disabled = false));
    }
  }

  //send specData to parent component
  @Emit('send-parts-info')
  sendPartsData() {
    let allowedKeys = [
      'name',
      'partMaterialId',
      'partMaterialNumber',
      'partMaterialName',
      'isMaintainable',
      'partType',
      'lifeTime',
      'lifeTimeUnit',
      'obuInstance',
    ];
    this.partForm.parts.forEach((rawObject: Part) => {
      Object.keys(rawObject)
        .filter((key) => !allowedKeys.includes(key))
        .forEach((item) => delete rawObject[item as keyof typeof rawObject]);
    });
    return [...this.partForm.parts];
  }

  /** Get all material ids */
  async fetchAllMaterialNumbers(): Promise<void> {
    try {
      if (this.materialNumberList.length === 0) {
        this.partsTableIsLoading = true;
        const requestUrl = this.generateRequestUrlWithParams(
          this.page,
          this.pageSize,
          this.selectForm,
          this.sortAndOrderData
        );
        const res = await getFilterPartMaterials(requestUrl);
        this.materialNumberList = res.data;
      }
    } catch (error) {
      console.log(error);
    } finally {
      this.partsTableIsLoading = false;
    }
  }

  /**
   * Generate request URL by multiple factors
   * Mandatory to filter by lifeCycle of type LCL_APPROVED to retrive items that are already approved
   * @param pageNumber
   * @param pageSize
   * @param selectForm
   * @param sortAndOrderData
   */
  generateRequestUrlWithParams(
    pageNumber: string,
    pageSize: string,
    selectForm: any,
    sortAndOrderData: any
  ): string {
    let finalUrl = '';
    selectForm.lifeCycle
      ? (finalUrl += `&lifeCycle=${selectForm.lifeCycle}`)
      : (finalUrl += '&lifeCycle=LCL_APPROVED');
    if (selectForm.partType) finalUrl += `&partType=${selectForm.partType}`;
    if (selectForm.partNameorId)
      finalUrl += `&nameOrNumber=${selectForm.partNameorId}`;
    pageNumber
      ? (finalUrl += `&page=${pageNumber}`)
      : (finalUrl += `?page=${1}`);
    pageNumber
      ? (finalUrl += `&size=${pageSize}`)
      : (finalUrl += `&size=${this.pageSize}`);
    if (sortAndOrderData.sortBy && sortAndOrderData.order)
      finalUrl += `&sortBy=${sortAndOrderData.sortBy}&order=${sortAndOrderData.order}`;
    if (finalUrl) finalUrl = '?' + finalUrl.slice(1);
    return finalUrl;
  }

  /**
   * Add new part for current product model >  parts informations
   */
  addNewPart(): void {
    this.partForm.parts.push({
      name: '',
      partMaterialId: '',
      partMaterialName: '',
      isMaintainable: false,
      partType: '',
      lifeTime: '',
      lifeTimeUnit: '',
      disabled: false,
      obuInstance: undefined,
    });
  }

  /**
   * Fetch it after the user has chosen the material number
   * @param e
   * @param index
   */
  matchNameAndType(e: any, index: any): void {
    if (!e) {
      getPartMaterials(this.partForm.parts[index].partMaterialId).then(
        (res: any) => {
          if (res.code === 200) {
            this.partForm.parts[index].partType = res.data?.partType;
            this.partForm.parts[index].partMaterialName = res.data?.name;
          }
        }
      );
    }
  }

  /**
   * Update product model parts data
   * @param productModelId
   */
  async updatePartsData(productModelId: string): Promise<void> {
    try {
      this.partsTableIsLoading = true;
      const requestPayload = new FormData();
      requestPayload.append('productModelPicture', '');
      requestPayload.append(
        'productModelRequestBody',
        new Blob([JSON.stringify({ containedParts: this.partForm.parts })], {
          type: 'application/json',
        })
      );
      const res = await updateProductModel(productModelId, requestPayload);
      if (res.code === 200) {
        promptSuccessBox(this.$t('common.updated') as string);
        this.errorInfos = [];
        this.$router.push('/prod-cata/index?activeName=productModels');
      }
      if (res.code === 400) {
        customFailedMessage(this.$t('common.edit') as string);
        /* @ts-expect-error TODO Wrong type */
        this.errorInfos = res.data.errors;
      }
    } catch (error) {
      console.log(error);
      customFailedMessage(this.errNameInfo as any);
    } finally {
      this.partsTableIsLoading = false;
    }
  }

  /**
   * Handle delete part row
   * - if is approved do not allow deletion
   * @param index
   */
  handleDeletePartItem(index: number, isDisabled: boolean): void {
    if (
      this.lifeCycle != PRODUCT_MODEL_LIFECYCLE_ENUM.Approved ||
      !isDisabled
    ) {
      this.partForm.parts.splice(index, 1);
      return;
    }
    customFailedMessage(this.$t('prodCata.approvedNoDeletable').toString());
  }

  /**
   * Get state of life cycle
   */
  get partIsApproved(): boolean {
    return this.lifeCycle != PRODUCT_MODEL_LIFECYCLE_ENUM.Draft;
  }

  /**
   * Get delete row button style
   */
  deleteRowBtnStyle(isDisabled: boolean): string {
    return this.lifeCycle != PRODUCT_MODEL_LIFECYCLE_ENUM.Draft && isDisabled
      ? 'color: gray;'
      : '';
  }

  obuInstanceIsApplicable(rowPartType: string): boolean {
    return rowPartType === MODEL_PART_TYPE_ENUM.PartTypeDigital;
  }
}
</script>

<template>
  <div v-loading="partsTableIsLoading">
    <el-table ref="table" :data="partForm.parts" stripe style="width: 100%">
      <el-table-column
        v-for="column in columns"
        :key="column.prop"
        :prop="column.prop"
        row-key="id"
        :label="column.label"
        :row-style="{ height: '20px' }"
        :cell-style="{ padding: '0px' }"
        :min-width="100"
        :style="'width: 100%'"
      >
        <template v-slot:header>
          <div class="table-header">
            <span style="white-space: nowrap">{{ $t(column.label) }}</span>
          </div>
        </template>
        <template v-slot="slotProps">
          <div v-if="column.prop === 'name'">
            <el-form>
              <el-form-item :prop="'parts.' + slotProps.$index + '.name'">
                <el-input
                  :id="slotProps.$index + 'prod_cata_spec_create_name'"
                  v-model="slotProps.row[column.prop]"
                  :placeholder="$t('prodCata.inputContainedName')"
                  :disabled="slotProps.row.disabled"
                  :title="
                    slotProps.row.disabled ? $t('common.fieldNotEditable') : ''
                  "
                />
              </el-form-item>
            </el-form>
          </div>
          <div v-if="column.prop === 'partMaterialId'">
            <el-form>
              <el-form-item
                :prop="'parts.' + slotProps.$index + '.partMaterialId'"
              >
                <el-select
                  :id="slotProps.$index + 'prod_cata_spec_create_material_id'"
                  v-model="slotProps.row[column.prop]"
                  filterable
                  @visible-change="matchNameAndType($event, slotProps.$index)"
                  :placeholder="$t('prodCata.selectMaterialId')"
                  :disabled="slotProps.row.disabled"
                  :title="
                    slotProps.row.disabled ? $t('common.fieldNotEditable') : ''
                  "
                >
                  <el-option
                    v-for="item in materialNumberList"
                    :key="item.id"
                    :label="item.partMaterialNumber"
                    :value="item.id"
                  />
                </el-select>
              </el-form-item>
            </el-form>
          </div>
          <div v-if="column.prop === 'partMaterialName'">
            <el-form class="cell-content">
              <el-form-item
                :prop="'parts.' + slotProps.$index + '.partMaterialName'"
              >
                <span
                  :id="slotProps.$index + 'prod_cata_spec_create_material_name'"
                  class="readonly_input"
                >
                  {{ $t(slotProps.row[column.prop]) }}
                </span>
              </el-form-item>
            </el-form>
          </div>
          <div v-if="column.prop === 'partType'">
            <el-form class="cell-content">
              <el-form-item :prop="'parts.' + slotProps.$index + '.partType'">
                <span
                  :id="slotProps.$index + 'prod_cata_spec_create_type'"
                  class="readonly_input"
                >
                  {{ $t(slotProps.row[column.prop]) }}
                </span>
              </el-form-item>
            </el-form>
          </div>

          <div v-if="column.prop === 'obuInstance'">
            <el-form>
              <el-form-item
                :prop="'parts.' + slotProps.$index + '.obuInstance'"
              >
                <el-select
                  class="obu-instance-dropdown"
                  v-if="obuInstanceIsApplicable(slotProps.row.partType)"
                  :id="slotProps.$index + 'prod_cata_spec_create_maint'"
                  v-model="slotProps.row[column.prop]"
                  v-bind:placeholder="$t('prodCata.selectObuInstance')"
                  :disabled="partIsApproved && slotProps.row.disabled"
                  :title="
                    slotProps.row.disabled ? $t('common.fieldNotEditable') : ''
                  "
                >
                  <el-option
                    v-for="(item, index) in obuInstances"
                    :key="index"
                    :label="$t(item.label)"
                    :value="item.id"
                  >
                  </el-option>
                </el-select>
                <div v-else>{{ $tc('common.notApplicable') }}</div>
              </el-form-item>
            </el-form>
          </div>

          <div v-if="column.prop === 'isMaintainable'">
            <el-form>
              <el-form-item
                :prop="'parts.' + slotProps.$index + '.isMaintainable'"
              >
                <el-select
                  :id="slotProps.$index + 'prod_cata_spec_create_maint'"
                  v-model="slotProps.row[column.prop]"
                  v-bind:placeholder="$t('prodCata.selectMaintainability')"
                  :disabled="slotProps.row.disabled"
                  :title="
                    slotProps.row.disabled ? $t('common.fieldNotEditable') : ''
                  "
                >
                  <el-option
                    v-for="(item, index) in maintainableList"
                    :key="index"
                    :label="$t(item.label)"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-form>
          </div>
          <div v-if="column.prop === 'lifeTime'">
            <el-form>
              <el-form-item :prop="'parts.' + slotProps.$index + '.lifeTime'">
                <el-input
                  :id="slotProps.$index + 'prod_cata_spec_create_lifetime'"
                  type="number"
                  min="0"
                  onkeypress="return event.keyCode === 8 || event.charCode >= 48 && event.charCode <= 57"
                  v-model="slotProps.row[column.prop]"
                  :placeholder="$t('prodCata.inputLifetime')"
                  :disabled="slotProps.row.disabled"
                  :title="
                    slotProps.row.disabled ? $t('common.fieldNotEditable') : ''
                  "
                />
              </el-form-item>
            </el-form>
          </div>
          <div v-if="column.prop === 'lifeTimeUnit'">
            <el-form>
              <el-form-item
                :prop="'parts.' + slotProps.$index + '.lifeTimeUnit'"
              >
                <el-select
                  :id="slotProps.$index + 'prod_cata_spec_create_unit'"
                  v-model="slotProps.row[column.prop]"
                  v-bind:placeholder="$t('prodCata.selectLifeTimeUnit')"
                  :disabled="slotProps.row.disabled"
                  :title="
                    slotProps.row.disabled ? $t('common.fieldNotEditable') : ''
                  "
                >
                  <el-option
                    v-for="(item, index) in lifeTimeUnitList"
                    :key="index"
                    :label="$t(item.label)"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-form>
          </div>
        </template>
      </el-table-column>
      <el-table-column fixed="right" :width="80">
        <template v-slot="slotProps">
          <div
            :title="
              partIsApproved
                ? $t('prodCata.approvedNoDeletable').toString()
                : ''
            "
            id="model_spec_delete"
            class="model_parts_icon"
            @click="
              handleDeletePartItem(slotProps.$index, slotProps.row.disabled)
            "
          >
            <i
              class="el-icon-remove rules-delete"
              :style="deleteRowBtnStyle(slotProps.row.disabled)"
            />
          </div>
        </template>
      </el-table-column>
    </el-table>

    <div
      id="model_parts_info_add"
      style="width: 170px; margin-top: 20px; cursor: pointer"
      @click="addNewPart"
    >
      <i class="el-icon-circle-plus add-rules-plus" />
      <span class="add-rules">{{ $t('prodCata.addPartsInfo') }}</span>
    </div>
  </div>
</template>

<style scoped>
.product-model-parts-table {
  margin-top: 20px;
}
</style>

<style lang="scss" scoped>
$green: #349d99;
$borderColor: #dddddd;

.border_row {
  height: 40px;
  border-bottom: 1px solid $borderColor;
  z-index: 10;
  .table_head {
    line-height: 40px;
    text-align: center;
    font-size: 18px;
    font-family: $font-Roboto-Regular;
    display: block;
  }
}

.readonly_input {
  width: 200px;
  height: 40px;
  font-family: $font-Roboto-Regular;
  font-size: 16px;
  text-align: center;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.add-rules-plus {
  vertical-align: bottom;
  color: $green;
  padding: 1px;
  font-size: 22px;
}

.add-rules {
  cursor: pointer;
  vertical-align: middle;
  margin-left: 10px;
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
  color: $green;
  opacity: 1;
}

.model_parts_icon {
  margin-left: 20px;
  font-size: 24px;
  line-height: 40px;
}

:deep(.el-input--medium .el-input__inner) {
  width: 100%;
  height: 38px;
  border: none !important;
  font-size: 16px;
  text-align: center;
}

:deep(.el-select, .el-input__inner) {
  width: 100%;
  margin-right: 0;
}

:deep(.el-input-group__append) {
  background-color: #ffffff;
  color: #040404;
  vertical-align: left;
  display: table-cell;
  position: relative;
  border: none;
}

:deep(.readonly_input) {
  text-align: left;
}

.obu-instance-dropdown :deep(.el-input) {
  width: 120px;
}

.obu-instance-dropdown :deep(.el-input__inner) {
  width: 120px;
}
</style>
