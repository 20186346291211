<script lang="ts">
import { Component, Vue, Watch } from 'vue-property-decorator';
import NavPage from '@/components/nav/NavPage.vue';
import NewPartForm from '../components/NewPartForm.vue';
import { getProductModelById } from '@/api/products';

@Component({
  name: 'ManageParts',
  components: {
    NavPage,
    NewPartForm,
  },
})
export default class extends Vue {
  activeName: string = 'general';
  id = this.$route.params.id ? this.$route.params.id : -1; //has id if admin visited from the index table
  productModelInfo = {};

  created() {}

  mounted() {
    if (this.id && this.id != -1) {
      //has id so have to get all the detail info
      (this.$refs.newPartForm as any).getPartDetails(this.id);
    }
  }

  // get Model Detail Info
  async getProductModelInfo() {
    const res = await getProductModelById(this.$route.params.id);
    this.productModelInfo = res.data;
  }

  createOnePart() {
    (this.$refs.newPartForm as any).createOnePart();
  }

  editOnePart() {
    (this.$refs.newPartForm as any).editOnePart();
  }
}
</script>

<template>
  <NavPage
    :arrowBack="false"
    :visible="true"
    @arrow-click="$router.push('/prod-cata/index?activeName=partsWarehouse')"
  >
    <template #button v-if="id === -1">
      <el-button
        id="prod_cate_ware_create_button"
        type="plain"
        @click="createOnePart"
      >
        <i class="el-icon-plus common-icon" />{{
          $t('prodCata.createProductModel')
        }}
      </el-button>
    </template>

    <template #button v-else>
      <el-button
        id="prod_cate_ware_save_button"
        type="plain"
        @click="editOnePart"
      >
        <svg-icon class="save-icon" name="save" />
        {{ $t('prodCata.saveProductModel') }}
      </el-button>
    </template>

    <div style="padding: 10px 40px">
      <NewPartForm ref="newPartForm" />
    </div>
  </NavPage>
</template>

<style lang="scss" scoped>
.user-setting-form {
  margin: 0 20px;
}
.save-icon {
  margin-right: 10px;
}
</style>
