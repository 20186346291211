import {
  HierarchyResponse,
  OrganizationAssetsHierarchyAsset,
} from '@/api/assets';

export function flattenSubOrganizationAssets(
  organization: HierarchyResponse
): OrganizationAssetsHierarchyAsset[] {
  return organization.organization.suborganizations.flatMap((org) => [
    ...org.organization.assets,
    ...flattenSubOrganizationAssets(org),
  ]);
}
