<script setup lang="ts">
import { ref, unref } from 'vue';

interface KpiSafetyTableEntity {
  id: string;
  assetId: string;
  buckling: string;
  overload: string;
  safeState: string;
  sideloadDanger: string;
  sideloadWarning: string;
}

const tableRef = ref();

withDefaults(
  defineProps<{
    tableData: KpiSafetyTableEntity[];
    fleet?: boolean;
  }>(),
  {
    fleet: false,
  }
);

const emit = defineEmits<{
  (e: 'select', id: string): void;
}>();

function handleRowClick(row: any) {
  emit('select', row.id);
}

const handleCurrentChange = (val: KpiSafetyTableEntity | undefined) => {
  tableRef.value = val;
};
</script>

<template>
  <div class="table-container">
    <el-table
      max-height="1100"
      :data="tableData"
      style="width: 100%; margin-right: 10px"
      @row-click="handleRowClick"
      highlight-current-row
      @current-change="handleCurrentChange"
    >
      <el-table-column
        prop="assetId"
        :label="$t('fleetSafety.assetId')"
        width="160"
      >
      </el-table-column>
      <el-table-column
        prop="safeState"
        :label="$t('fleetSafety.safeState')"
        width="60"
      >
        <template slot="header">
          <el-tooltip
            class="item"
            effect="dark"
            :content="$t('tippingVehicles.safety.KPI.SafeState').toString()"
            placement="top"
          >
            <img src="@/icons/svg/safestate.svg" />
          </el-tooltip>
        </template>
      </el-table-column>
      <el-table-column
        prop="sideloadDanger"
        :label="$t('fleetSafety.sideLoadDanger')"
        width="60"
      >
        <template slot="header">
          <el-tooltip
            class="item"
            effect="dark"
            :content="
              $t('tippingVehicles.safety.KPI.SideloadDanger').toString()
            "
            placement="top"
          >
            <img src="@/icons/svg/sideloaddanger.svg" />
          </el-tooltip>
        </template>
      </el-table-column>
      <el-table-column
        prop="sideloadWarning"
        :label="$t('fleetSafety.sideloadWarning')"
        width="60"
      >
        <template slot="header">
          <el-tooltip
            class="item"
            effect="dark"
            :content="
              $t('tippingVehicles.safety.KPI.SideloadWarning').toString()
            "
            placement="top"
          >
            <img src="@/icons/svg/sideloadwarning.svg" />
          </el-tooltip>
        </template>
      </el-table-column>
      <el-table-column
        prop="overload"
        :label="$t('fleetSafety.overload')"
        width="80"
      >
        <template slot="header">
          <el-tooltip
            class="item"
            effect="dark"
            :content="$t('tippingVehicles.safety.KPI.Overload').toString()"
            placement="top"
          >
            <img src="@/icons/svg/overload.svg" />
          </el-tooltip>
        </template>
      </el-table-column>
      <el-table-column
        prop="buckling"
        :label="$t('fleetSafety.buckling')"
        width="60"
      >
        <template slot="header">
          <el-tooltip
            class="item"
            effect="dark"
            :content="$t('tippingVehicles.safety.KPI.Buckling').toString()"
            placement="top"
          >
            <img src="@/icons/svg/buckling.svg" />
          </el-tooltip>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<style lang="scss" scoped>
.box {
  width: 400px;

  .top {
    text-align: center;
  }

  .left {
    float: left;
    width: 110px;
  }

  .right {
    float: right;
    width: 110px;
  }

  .bottom {
    clear: both;
    text-align: center;
  }

  .item {
    margin: 4px;
  }

  .left .el-tooltip__popper,
  .right .el-tooltip__popper {
    padding: 8px 10px;
  }

  .el-button {
    width: 110px;
  }
}

@media screen and (max-height: 767px) {
  .el-table {
    padding: 0 0 80px 0;
  }
}

@media screen and (min-height: 768px) and (max-height: 1023px) {
  .el-table {
    padding: 0 0 5px 0;
  }
}
</style>
