<script setup lang="ts">
import { UUID } from '@/api/common';
import { getAllGeofencesByOrganisationId, LifeCycle } from '@/api/geofence';
import { Geofence } from '@/api/geofenceTypes';
import {
  AssetTripEventsSummary,
  AvailableSafetyEventType,
  getAvailableSafetyEvents,
  getSingleAssetTripEvents,
  getTripByUUID,
  getTripEventsSummaryByTime,
  SafetyEventType,
  TripByUUIDResponse,
  TripsWithEventsSummaryByTime,
} from '@/api/trip';
import { useActiveContext } from '@/auth/context';
import PeriodSelector from '@/components/form/PeriodSelector.vue';
import HorizontalStackedBarChart from '@/components/kpiCharts/HorizontalStackedBarChart.vue';
import TimeOverloadStackedBarChart from '@/components/kpiCharts/TimeOverloadStackedBarChart.vue';
import WidgetCard from '@/components/layout/widget/WidgetCard.vue';
import { useAsync } from '@/composables/async';
import { useUnitConversion } from '@/composables/conversion';
import { useRoute } from '@/composables/router';
import i18n from '@/lang';
import router from '@/router';
import {
  ASSET_SAFETY_CODE,
  END_DATE_ROUTE_QUERY_PARAM,
  NEW_FLEET_SAFETY_CODE,
  SAFETY_EVENT_ROUTE_QUERY_PARAM,
  START_DATE_ROUTE_QUERY_PARAM,
} from '@/router/links/safetyWidget';
import { UserModule } from '@/store/modules/user';
import { max } from '@/utils/math';
import { never } from '@/utils/promise';
import {
  capitalizeFirstLetter,
  capitalizeFirstLetterOfTheWorldsInString,
} from '@/utils/string';
import {
  DEFAULT_PERIOD_RANGE,
  getLastInnerPeriodFromEndDate,
  LOCALDATE_FORMAT,
} from '@/utils/time';
import { kpiUnitValueToKpiData } from '@/utils/types/columnCustomizationTypes';
import { DatePeriod, PeriodRange } from '@/utils/types/date';
import { KPI_UNIT } from '@/utils/units/unitDefinitions';
import { TripStatus } from '@/utils/workData/lookuptable';
import {
  correctKpiUnit,
  getKpiProp,
  getTripKpis,
  TripPropertyLabelEnums,
} from '@/views/trip/trip';
import TripLiveDetailsSingleAsset from '@/widgets/asset/kpis/safety/expanded/components/TripLiveDetailsSingleAsset.vue';
import TripLivePlayerSingleAsset from '@/widgets/asset/kpis/safety/expanded/components/TripLivePlayerSingleAsset.vue';
import { AggregationType } from '@/widgets/utils/Constants';
import {
  computeTripSafetyStatistics,
  defaultSafetyWidgetTitle,
  formatSeverityStatistic,
  getLabelWithEvent,
  getLabelWithoutEvent,
  getStatisticLabelTranslated,
  TripSafetyStatistics,
} from '@/widgets/utils/tripSafety';
import moment from 'moment';
import { computed, ref, unref, watch, watchEffect } from 'vue';

const route = useRoute();

const activeContext = useActiveContext();

/** Period selection */
const selectedPeriodRange = computed((): PeriodRange => {
  const query = unref(route).query;
  const startDate = query[START_DATE_ROUTE_QUERY_PARAM];
  const endDate = query[END_DATE_ROUTE_QUERY_PARAM];

  if (typeof startDate !== 'string' || typeof endDate !== 'string') {
    return DEFAULT_PERIOD_RANGE;
  }

  return {
    ...getLastInnerPeriodFromEndDate(endDate),
    datePeriod: DatePeriod.DATP_DAY,
  };
});

function updateSelectedPeriodRange(newPeriodRange: PeriodRange): void {
  router.replace({
    query: {
      ...route.value.query,
      [START_DATE_ROUTE_QUERY_PARAM]: newPeriodRange.start,
      [END_DATE_ROUTE_QUERY_PARAM]: newPeriodRange.end,
    },
  });
}

/* --------------- Available safety events by company type --------------- */

const availableSafetyEventTypes = useAsync(
  computed(
    async (): Promise<AvailableSafetyEventType[]> =>
      getAvailableSafetyEvents(UserModule.companyType, unref(activeContext))
  )
);

// Select default event type after loading available event types for this company type.
const selectedEventType = computed((): SafetyEventType | undefined => {
  const availableSafetyEvents = unref(availableSafetyEventTypes).data;

  const query = unref(route).query;
  const safetyEvent = query[SAFETY_EVENT_ROUTE_QUERY_PARAM];
  if (
    safetyEvent &&
    availableSafetyEvents?.some((event) => event.eventTypeCode === safetyEvent)
  ) {
    return safetyEvent as SafetyEventType;
  }

  if (!availableSafetyEvents) return;

  return availableSafetyEvents.find(
    (availableEventType) => availableEventType.isDefault
  )?.eventTypeCode;
});

const selectedEvent = computed(() => {
  const availableEvents = unref(availableSafetyEventTypes).data;

  if (availableEvents && unref(selectedEventType)) {
    return availableEvents.find(
      (item) => item.eventTypeCode === unref(selectedEventType)
    );
  }
});

function handleSelectedEventUpdate(newSafetyEvent: SafetyEventType): void {
  router.replace({
    query: {
      ...route.value.query,
      [SAFETY_EVENT_ROUTE_QUERY_PARAM]: newSafetyEvent,
    },
  });
}

/* --------------- Handle Events summary by time (left side chart) --------------- */

const tripEventSummariesByTime = useAsync(
  computed(async (): Promise<TripsWithEventsSummaryByTime[]> => {
    const periodRange = unref(selectedPeriodRange);
    const eventType = unref(selectedEventType);

    if (!periodRange?.datePeriod || !eventType || !unref(route)?.params.id) {
      return never();
    }
    if (periodRange.datePeriod !== DatePeriod.DATP_DAY) {
      throw new Error(`Unsupported DatePeriod ${periodRange.datePeriod}`);
    }

    return getTripEventsSummaryByTime(
      {
        selectedDatePeriod: periodRange.datePeriod,
        eventTypeCode: eventType,
        datePeriodStartDate: periodRange.start,
        datePeriodEndDate: periodRange.endExclusive,
        assetUUIDs: route.value?.params?.id!,
      },
      unref(activeContext)
    );
  }),
  { clearOnRefresh: true }
);

/** Current horizontal chart data selection in LOCALDATE_FORMAT (i.e. yyyy-mm-dd) */
const dateSelectionFromChart = ref<string | undefined>(undefined);

function updateDateSelectionFromChart(newDate: string): void {
  dateSelectionFromChart.value = newDate;
}

// Select first available date from tripSummariesByTime response (in left chart), and unselect it if new data is loaded which no longer contains the currently selected date.
watchEffect(() => {
  const summaries = unref(tripEventSummariesByTime).data;
  if (!summaries) {
    return;
  }

  // Unset the selection if it no longer points to something available in the chart
  if (unref(dateSelectionFromChart)) {
    const selection = unref(dateSelectionFromChart);
    if (!summaries.some((summary) => summary.bucketStartDate === selection)) {
      dateSelectionFromChart.value = undefined;
    }
  }

  // Find the most recent date and select that by default
  if (!unref(dateSelectionFromChart)) {
    const summariesInDescendingOrder = [...summaries].sort((a, b) =>
      a.bucketStartDate < b.bucketStartDate ? 1 : -1
    );
    const mostRecentSummary: TripsWithEventsSummaryByTime | undefined =
      summariesInDescendingOrder[0];
    if (mostRecentSummary) {
      dateSelectionFromChart.value = mostRecentSummary.bucketStartDate;
    }
  }
});

/* --------------- Trip: events summary for single asset (right side chart) --------------- */

const singleAssetTripEvents = useAsync(
  computed(async (): Promise<AssetTripEventsSummary[]> => {
    const periodRange = unref(selectedPeriodRange);
    const eventType = unref(selectedEventType);

    if (!periodRange || !eventType) {
      return never();
    }
    if (periodRange.datePeriod !== DatePeriod.DATP_DAY) {
      throw new Error(`unsupported DatePeriod ${periodRange.datePeriod}`);
    }

    const assetUUID = route.value?.params?.id;
    const eventTypeCode = unref(selectedEventType)!;
    const datePeriodStartDate = periodRange.start;
    const datePeriodEndDate = periodRange.endExclusive;

    return getSingleAssetTripEvents(
      assetUUID,
      eventTypeCode,
      datePeriodStartDate,
      datePeriodEndDate,
      unref(activeContext)
    );
  }),
  { clearOnRefresh: true }
);

const selectedSingleAssetTripEvents = computed(
  (): AssetTripEventsSummary[] | undefined => {
    const allTripEvents = unref(singleAssetTripEvents).data;
    const selectedDay = unref(dateSelectionFromChart);
    if (!allTripEvents || !selectedDay) {
      return undefined;
    }
    const selectedDayMoment = moment(selectedDay);
    return allTripEvents.filter((tripSummary) =>
      moment(tripSummary.tripEndTime, LOCALDATE_FORMAT)
        .startOf('day')
        .isSame(selectedDayMoment, 'day')
    );
  }
);

const maximumSeverityLevel = computed((): number | undefined => {
  const allTripEvents = unref(singleAssetTripEvents).data;
  if (!allTripEvents) {
    return undefined;
  }
  return allTripEvents
    .map((summary) => summary.maximumSeverityLevel)
    .reduce(max, -Infinity);
});

/* --------------- ^ END of: Trip: events summary for single asset (right side chart) ^ --------------- */

/* --------------- Trip events statistics (bottom side of the charts) --------------- */

const tripEventStatistics = computed((): TripSafetyStatistics | undefined => {
  const selectedTrips = unref(selectedSingleAssetTripEvents);

  if (!selectedTrips || !selectedEvent.value) {
    return undefined;
  }

  const rawStatistics = computeTripSafetyStatistics(selectedTrips);

  return currentUserConvertNumberMany(
    rawStatistics,
    ['minSeverity', 'avgSeverity', 'maxSeverity'],
    selectedEvent.value.unitCode
  );
});

function handleHorizontalStackedBarsClick(dateIndex: number): void {
  const tripSummaries = unref(tripEventSummariesByTime).data;
  const selectedTripSummaryDate = tripSummaries?.[dateIndex]?.bucketStartDate;

  if (selectedTripSummaryDate) {
    updateDateSelectionFromChart(selectedTripSummaryDate);
  }
}

/* --------------- ^ END of: Trip events statistics (bottom side of the charts) ^ --------------- */

/* --------------- Trip LIVE details section ---------------  */

const selectedTripUUID = ref<UUID>();

const tripLiveMapIsVisible = computed(
  () => unref(selectedTripUUID) !== undefined
);

function handleTripUUIDSelection(tripUUID: UUID): void {
  selectedTripUUID.value = tripUUID;
}

function closeTripDetails(): void {
  selectedTripUUID.value = undefined;
}

watch([selectedEventType, selectedPeriodRange, dateSelectionFromChart], () => {
  closeTripDetails();
});

const {
  currentUserConvertUnitValue,
  currentUserPreferredUnit,
  currentUserConvertNumberMany,
} = useUnitConversion();

const tripByUUID = useAsync(
  computed(async (): Promise<TripByUUIDResponse> => {
    const tripUUID = unref(selectedTripUUID);
    if (tripUUID) {
      const trip: TripByUUIDResponse = await getTripByUUID(
        tripUUID,
        UserModule.i18nCode,
        unref(activeContext)
      );

      if (trip) {
        const kpis = getTripKpis(trip);
        const metricPayloadKpi = correctKpiUnit(
          getKpiProp(kpis, TripPropertyLabelEnums.TippedPayload)
        );
        const userPayloadKpi = currentUserConvertUnitValue(metricPayloadKpi);

        const endTime =
          trip.tripStatus === TripStatus.Underway
            ? undefined
            : trip.endPoint?.timestamp;
        const endPosition =
          trip.tripStatus === TripStatus.Underway ? undefined : trip.endPoint;

        return {
          id: trip.id,
          companyAssetId: trip.companyAssetId,
          assetType: trip.assetType,
          organizationId: trip.organizationId,
          organizationName: trip.organizationName,
          payload: userPayloadKpi,
          tripId: trip.tripId,
          tripStatus: trip.tripStatus,
          startTime: new Date(trip.startPoint?.timestamp!),
          startPosition: trip.startPoint,
          endTime: endTime ? new Date(endTime) : undefined,
          endPosition: endPosition,
          tripTime: trip.tripTime,
          summary: trip.tripSubActivities,
          events: trip.events,
          kpiData: kpis.map(kpiUnitValueToKpiData),
          timezone: trip.timezone,
        };
      }
    }

    return never();
  })
);

const geofencesList = useAsync(
  computed(async (): Promise<Geofence[]> => {
    const trip = unref(tripByUUID).data;
    if (trip) {
      const geofences = await getAllGeofencesByOrganisationId(
        trip.organizationId,
        unref(activeContext)
      );

      return geofences.data.geofences.filter(
        (g) =>
          g.lifeCycle === LifeCycle.Active &&
          g.associatedAssetTypes.includes(trip.assetType)
      );
    }

    return never();
  })
);

/* --------------- ^ END of: Trip LIVE details section ^ ---------------  */

/* --------------- Default widget title ---------------  */

/**
 * Compute if current grouping scope (fleet/asset) has:
 * - one safety event then: Fleet/Asset <event> e.g: Fleet Overload for TruckOM customer type
 * - has more safety events then initial translation title: "Fleet/Asset Safety" e.g: ("Fleet Safety" or "Asset Safety")
 */
const defaultWidgetTitle = computed((): string => {
  const availableEvents = unref(availableSafetyEventTypes).data;
  const isAssetScope = unref(route)?.params?.id ? true : false;
  if (
    availableEvents &&
    availableEvents?.length === 1 &&
    availableEvents?.[0]?.eventTypeCode
  ) {
    const finalResult = defaultSafetyWidgetTitle(
      availableEvents?.[0]?.eventTypeCode,
      isAssetScope
    );

    return capitalizeFirstLetterOfTheWorldsInString(finalResult);
  }

  return isAssetScope
    ? i18n.tc(ASSET_SAFETY_CODE)
    : i18n.tc(NEW_FLEET_SAFETY_CODE);
});

/* --------------- ^ END of: Default widget title ^ ---------------  */
</script>

<template>
  <WidgetCard
    :default-title="defaultWidgetTitle"
    :title-is-loading="availableSafetyEventTypes.loading"
    class="expanded-asset-safety-widget-body"
    :expandable="false"
  >
    <template #actions>
      <el-select
        v-model="selectedEventType"
        class="select-safety-event-types"
        v-loading="availableSafetyEventTypes.loading"
        @change="handleSelectedEventUpdate"
      >
        <el-option
          v-for="item in availableSafetyEventTypes.data ?? []"
          :key="item.eventTypeCode"
          :label="capitalizeFirstLetter($tc(item.eventTypeCode))"
          :value="item.eventTypeCode"
        />
      </el-select>
      <PeriodSelector
        class="period-selector"
        :expanded="true"
        @select="updateSelectedPeriodRange"
        :customizable="true"
        :dropDownIsDisabled="true"
        :customDate="selectedPeriodRange"
      />
    </template>
    <div class="expanded-asset-fleet-safety-widget">
      <div class="safety-widget-chart-container">
        <div class="main-content-charts-container">
          <div
            class="horizontal-stacked-bar-chart"
            v-loading="tripEventSummariesByTime.loading"
          >
            <HorizontalStackedBarChart
              :data="tripEventSummariesByTime.data ?? []"
              :safetyEvent="selectedEventType"
              :aggregationType="AggregationType.AggregateByTime"
              @selection="handleHorizontalStackedBarsClick"
            />
          </div>
          <div
            class="vertical-stacked-bar-chart"
            v-loading="singleAssetTripEvents.loading"
            :class="{ 'vertical-chart-padding': !tripLiveMapIsVisible }"
          >
            <TimeOverloadStackedBarChart
              v-if="!tripLiveMapIsVisible"
              :summaryEvents="selectedSingleAssetTripEvents ?? []"
              :safetyEvent="selectedEventType"
              :selectedDate="dateSelectionFromChart"
              :maximumSeverityLevel="maximumSeverityLevel"
              :safetyUnitCode="selectedEvent?.unitCode"
              @tripSelectionUUID="handleTripUUIDSelection"
            />

            <div v-if="tripLiveMapIsVisible" class="trip-live-main-container">
              <div class="trip-live-items">
                <!-- @vue-expect-error -->
                <TripLiveDetailsSingleAsset
                  v-loading="tripByUUID.loading"
                  :trip="tripByUUID.data"
                  @cancel="closeTripDetails"
                />
                <TripLivePlayerSingleAsset
                  v-loading="geofencesList.loading"
                  claass="trip-player-item"
                  :trip="tripByUUID.data"
                  :geofences="geofencesList.data"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="bottom-general-statistics" v-loading="!tripEventStatistics">
          <table
            v-if="selectedEventType && selectedEvent"
            class="statistics-table"
          >
            <thead>
              <tr>
                <th class="header-item">
                  {{ i18n.tc('newFleetSafety.totalTrips') }}
                </th>
                <th class="header-item">
                  {{ getLabelWithEvent(selectedEventType) }}
                </th>
                <th class="header-item">
                  {{ getLabelWithoutEvent(selectedEventType) }}
                </th>
                <th class="header-item">
                  {{ i18n.tc('newFleetSafety.simpleRatio') }}
                </th>
                <th class="header-item">
                  {{
                    getStatisticLabelTranslated(
                      'min',
                      selectedEventType,
                      currentUserPreferredUnit(selectedEvent?.unitCode)
                    )
                  }}
                </th>
                <th class="header-item">
                  {{
                    getStatisticLabelTranslated(
                      'avg',
                      selectedEventType,
                      currentUserPreferredUnit(selectedEvent?.unitCode)
                    )
                  }}
                </th>
                <th class="header-item">
                  {{
                    getStatisticLabelTranslated(
                      'max',
                      selectedEventType,
                      currentUserPreferredUnit(selectedEvent?.unitCode)
                    )
                  }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  {{ tripEventStatistics?.totalTrips }}
                </td>
                <td>
                  {{ tripEventStatistics?.tripsWithEvents }}
                </td>
                <td>
                  {{ tripEventStatistics?.tripsWithoutEvents }}
                </td>
                <td>
                  {{
                    formatSeverityStatistic(
                      tripEventStatistics?.ratio,
                      KPI_UNIT.Percentage
                    )
                  }}
                </td>
                <td>
                  {{
                    formatSeverityStatistic(
                      tripEventStatistics?.minSeverity,
                      currentUserPreferredUnit(selectedEvent?.unitCode)
                    )
                  }}
                </td>
                <td>
                  {{
                    formatSeverityStatistic(
                      tripEventStatistics?.avgSeverity,
                      currentUserPreferredUnit(selectedEvent?.unitCode)
                    )
                  }}
                </td>
                <td>
                  {{
                    formatSeverityStatistic(
                      tripEventStatistics?.maxSeverity,
                      currentUserPreferredUnit(selectedEvent?.unitCode)
                    )
                  }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </WidgetCard>
</template>

<style lang="css" scoped>
.trip-player-item {
  margin-top: 0 !important;
}
</style>

<style lang="scss">
.expanded-asset-fleet-safety-widget {
  overflow: hidden;
  height: 100%;
}

.safety-widget-chart-container {
  padding: 5px 16px 10px 16px;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.safety-widget-header-container {
  .select-safety-event-types {
    width: 170px;
    margin-right: 60px;
  }

  .period-selector {
    align-items: center;
  }
}

.main-content-charts-container {
  display: flex;
  margin-top: 5px;
  flex-grow: 1;
}

@media screen and (max-width: 1756px) {
  .header-item {
    margin: 5px 0 5px 0;
  }

  .bottom-general-statistics {
    padding: 0 20px;
  }
}

@media screen and (min-width: 1756px) {
  .bottom-general-statistics {
    padding: 0 100px;
  }
}

.horizontal-stacked-bar-chart {
  width: 25%;
  border-top: 1px solid lightgray;
  border-bottom: 1px solid lightgray;
}

.vertical-stacked-bar-chart {
  flex: 1;
  border: 1px solid lightgray;
  border-right: none;
}

.vertical-chart-padding {
  padding: 10px;
}

.bottom-general-statistics {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.statistics-table {
  width: 100%;
  text-align: center;
  font-size: 14px;
  font-family: Roboto;
  padding-top: 10px;
}

.trip-live-main-container {
  height: 100%;
  background-color: #b3b3b3;
  padding: 10px;
}

.trip-live-items {
  background-color: #ffffff;
  height: 100%;
}
</style>
