import { CommonResult } from '@/api/commonResult';
import { customFailedMessage, promptFailedBox } from '@/utils/prompt';
import i18n from '@/lang';
import { getRegExp } from '@/utils/misc';

/**
 * Cove scenarios for all possible API errors with code 400 on the create/update API request for dashboar templates
 * - when there is an issue with widgets that requires max width, will be displayed a popup error message
 * @param res
 * @returns
 */
export function handleUpdateAPIErrors(res: CommonResult<any>) {
  if (
    res.data.hasOwnProperty('errors') &&
    res.data.errors.some(
      (item: any) =>
        item.field == 'ApiFieldWidgets' && item.code == 'ApiErrorFieldInvalid'
    )
  ) {
    const codes: string[] = res.data.errors
      .find((item: any) => item.field == 'ApiFieldWidgets')
      ?.message?.match(/\bAUTHRSC_\w+\b/g);
    const finalCodesTranslated: string[] = codes.map((item: any) =>
      i18n.tc(item)
    );
    customFailedMessage(
      i18n
        .t('dashboardTemplate.maxWidthRequiredErrorMessage', {
          widget: finalCodesTranslated[0],
          page: finalCodesTranslated[1],
        })
        .toString()
    );
    return;
  }
  promptFailedBox(i18n.tc('common.save'));
}
