import { ModelSpecsNewProperty } from '@/api/products';
import i18n from '@/lang';

export const checkNewPropertiesValues = (properties: ModelSpecsNewProperty[]) =>
  properties.reduce(
    (accumulator: string[], currentValue: ModelSpecsNewProperty) => {
      const nameMandatory = i18n
        .t('prodCata.modelSpecsNameMandatory')
        .toString();
      const unitMandatory = i18n
        .t('prodCata.modelSpecsUnitMandatory')
        .toString();
      const valueMandatory = i18n
        .t('prodCata.modelSpecsValueMandatory')
        .toString();
      if (
        !currentValue.name &&
        !accumulator.find((s: string) => s === nameMandatory)
      ) {
        accumulator.push(nameMandatory);
      }
      if (
        !currentValue.unit &&
        !accumulator.find((s: string) => s === unitMandatory)
      ) {
        accumulator.push(unitMandatory);
      }
      if (
        !currentValue.value &&
        !accumulator.find((s: string) => s === valueMandatory)
      ) {
        accumulator.push(valueMandatory);
      }

      return accumulator;
    },
    []
  );
