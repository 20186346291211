<script setup lang="ts">
import { useContextTypeFromRoute } from '@/auth/context';
import { ContextType } from '@/auth/contextType';
import { useLoggedInUser } from '@/auth/user';
import CustomerOrganizationSelect from '@/components/layout/select/CustomerOrganizationSelect.vue';
import CustomerSelect from '@/components/layout/select/CustomerSelect.vue';
import SuborganizationSelect from '@/components/layout/select/SuborganizationSelect.vue';
import { COMPANY_TYPE } from '@/utils/workData/lookuptable';
import { computed, unref } from 'vue';

const loggedInUser = useLoggedInUser();
const isHyvaAdmin = computed(
  () => loggedInUser.value?.companyType === COMPANY_TYPE.Hyva
);
const contextType = useContextTypeFromRoute();
const isSelectableContext = computed(
  () => unref(contextType) !== ContextType.LoggedInUser
);
</script>

<template>
  <KeepAlive>
    <!-- CustomerOrganizationSelect is the latest and greatest version of the
    selector to be used for designated users (and possibly 'normal' customers too,
    in the future). However, because we don't want to change the behaviour for
    'normal' designated users (Helpdesk, BB, etc), but only show customers with
    their orgs for Operational Dashboard, this component is currently only shown
    for Hyva Admin company users
    -->
    <CustomerOrganizationSelect
      v-if="isSelectableContext && loggedInUser?.canImpersonate && isHyvaAdmin"
    />
    <CustomerSelect
      v-else-if="isSelectableContext && loggedInUser?.canImpersonate"
    />
    <SuborganizationSelect v-else-if="isSelectableContext" />
  </KeepAlive>
</template>
