<script setup lang="ts">
import { EventSummaryResponse, getEventSummary } from '@/api/tippingAssetKpi';
import noChange from '@/assets/imgs/fleet-overview/no_change.svg';
import { useActiveContext, useLoggedInUser } from '@/auth/context';
import TimeSelect from '@/components/form/TimeSelect.vue';
import WidgetCard from '@/components/layout/widget/WidgetCard.vue';
import { useRoute } from '@/composables/router';
import decrease from '@/icons/svg/arrowDownGreen.svg';
import increase from '@/icons/svg/arrowUpRed.svg';
import buckling from '@/icons/svg/buckling.svg';
import overload from '@/icons/svg/overload.svg';
import safestate from '@/icons/svg/safestate.svg';
import sideloadDanger from '@/icons/svg/sideloaddanger.svg';
import sideloadWarning from '@/icons/svg/sideloadwarning.svg';
import i18n from '@/lang';
import {
  Filter,
  FilterOperator,
  QueryParameter,
} from '@/model/queryParameters/QueryParameter';
import { customFailedMessage } from '@/utils/prompt';
import { DateRange } from '@/utils/types/date';
import {
  COMPANY_TYPE,
  EVENT_CODES,
  OccurrenceTrend,
} from '@/utils/workData/lookuptable';
import FleetSafetyExpanded from '@/widgets/fleet/expanded/FleetSafetyExpanded.vue';
import { inject, ref, unref } from 'vue';
import { StyleValue } from 'vue/types/jsx';

const expanded = inject<boolean>('expanded');
const summary = ref<EventSummaryResponse | null>(null);
const context = useActiveContext();
const loggedInUser = useLoggedInUser();
const route = useRoute();
const config = {
  event: [
    {
      type: EVENT_CODES.safety,
      image: safestate,
      kpi: 'KPI.SafeState',
    },
    {
      type: EVENT_CODES.sideloadWarning,
      image: sideloadWarning,
      kpi: 'KPI.SideloadWarning',
    },
    {
      type: EVENT_CODES.sideloadDanger,
      image: sideloadDanger,
      kpi: 'KPI.SideloadDanger',
    },
    {
      type: EVENT_CODES.overload,
      image: overload,
      kpi: 'KPI.Overload',
    },
    {
      type: EVENT_CODES.buckling,
      image: buckling,
      kpi: 'KPI.Buckling',
    },
  ],
};

async function loadEvents(start: string, end: string) {
  summary.value = null;
  const events = await fetchEvents(start, end);
  if (typeof events === 'string') {
    customFailedMessage(i18n.tc('common.errorWithFetchingData'));
    return;
  }
  summary.value = events;
}

async function fetchEvents(start: string, end: string) {
  const orgIds = unref(context).organizationIds;
  let queryParameters: QueryParameter = {};
  const assetsFilter: Filter[] = unref(route).params.id
    ? [
        {
          name: 'assetId',
          operator: FilterOperator.EQUAL,
          value: [unref(route).params.id],
        },
      ]
    : orgIds
    ? [
        {
          name: 'organizationId',
          operator: FilterOperator.IN,
          value: orgIds,
        },
      ]
    : [];
  const filter = [
    ...assetsFilter,
    {
      name: 'timestamp',
      operator: FilterOperator.BETWEEN,
      value: [start, end],
    },
    {
      name: 'eventTypeCode',
      operator: FilterOperator.IN,
      value: Object.values(EVENT_CODES),
    },
  ];
  queryParameters.filters = filter;
  queryParameters.timezone = unref(context)?.primaryOrgTimeZone;
  const user = unref(loggedInUser);
  const { data } =
    user && user.companyType === COMPANY_TYPE.Helpdesk
      ? await getEventSummary(queryParameters, unref(context))
      : await getEventSummary(queryParameters);
  return data;
}

function handleTimeFilter(dateRange: DateRange) {
  loadEvents(dateRange.start, dateRange.endExclusive);
}

function getEventDetails(type: string) {
  return (
    summary.value?.details.find((d) => d.eventTypeCode === type) ?? {
      eventTypeCode: type,
      numberOfEvents: 0,
      numberOfTrips: 0,
      numberOfEventsToday: 0,
      occurrenceTrend: OccurrenceTrend.NoChange,
    }
  );
}

function getEventPercentage(type: string) {
  const details = getEventDetails(type);
  const total = summary.value?.numberOfTotalTrips ?? 100;
  return total > 0
    ? Math.round((100 / total) * details.numberOfTrips)
    : undefined;
}

function getBarStyle(type: string) {
  return {
    width: `${getEventPercentage(type) ?? 0}%`,
  };
}

function getTrendImage(type: string) {
  const details = getEventDetails(type);

  switch (details.occurrenceTrend) {
    case OccurrenceTrend.Increasing:
      return increase;
    case OccurrenceTrend.Decreasing:
      return decrease;
    default:
      return noChange;
  }
}

function getPercentageStyle(type: string): StyleValue {
  const details = getEventDetails(type);

  return {
    color: {
      [OccurrenceTrend.Increasing]: '#ED6964',
      [OccurrenceTrend.Decreasing]: '#66BC93',
      [OccurrenceTrend.NoChange]: 'black',
    }[details.occurrenceTrend],
  };
}
</script>

<template>
  <WidgetCard v-if="!expanded" :loading="!summary" :expandable="true">
    <TimeSelect
      :expanded="true"
      @select="handleTimeFilter"
      :customizable="true"
    />
    <div class="events">
      <div class="today">
        {{ $t('tippingVehicles.safety.today') }}
      </div>
      <template v-for="event of config.event">
        <div :key="`${event.kpi}-name`" class="event-name">
          <el-tooltip
            class="item"
            effect="dark"
            :content="$t(`tippingVehicles.safety.${event.kpi}`).toString()"
            placement="top"
          >
            <img :src="event.image" class="event-image" />
          </el-tooltip>
          {{ $t(`tippingVehicles.safety.${event.kpi}`) }}
        </div>
        <div :key="`${event.kpi}-bar`" class="event-bar">
          <div class="event-bar-inner" :style="getBarStyle(event.type)" />
          <span
            class="event-bar-percentage"
            :style="getPercentageStyle(event.type)"
          >
            <img :src="getTrendImage(event.type)" class="event-bar-trend" />
            {{ getEventPercentage(event.type) ?? '-' }}% ({{
              getEventDetails(event.type).numberOfEvents
            }})
          </span>
        </div>
        <div :key="`${event.kpi}-today`" class="event-today">
          {{ getEventDetails(event.type).numberOfEventsToday }}
        </div>
      </template>
    </div>
  </WidgetCard>
  <FleetSafetyExpanded v-else />
</template>

<style lang="scss" scoped>
.events {
  display: grid;
  grid-template: auto / min-content auto 5rem;
  padding: 20px 20px 0 20px;
}

.today {
  grid-column: 3;
  margin-bottom: 0.5rem;
  text-align: center;
}

.event {
  &-name,
  &-bar,
  &-bar-percentage,
  &-today {
    display: flex;
    align-items: center;
    white-space: nowrap;
  }

  &-name {
    gap: 1rem;
    margin: 1rem 1rem 1rem 0;
    font-weight: 500;
  }

  &-image {
    width: 2rem;
    height: 2rem;
    object-fit: contain;
  }

  &-bar {
    justify-content: space-between;
    border-right: 1px solid #dddddd;
    border-bottom: 1px solid #dddddd;

    &-inner {
      width: 50%;
      height: 2rem;
      background-color: #ccc37a;
    }

    &-percentage {
      margin: 0.5rem;
      font-weight: 600;
    }

    &-trend {
      height: 1rem;
      margin-right: 0.5rem;
    }
  }

  &-today {
    justify-content: center;
    font-weight: 500;
    border-bottom: 1px solid #dddddd;
  }
}

.normal .event-bar {
  justify-content: flex-start;
}
</style>
