<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
  name: 'IconCell',
})
export default class extends Vue {
  @Prop() icon!: { name: string; label: string };

  iconName: string = '';
  iconLabel: string = '';

  created() {
    this.iconName = this.icon?.name;
    this.iconLabel = this.icon?.label;
  }
}
</script>

<template>
  <div>
    <svg-icon :name="iconName" />
    <span class="iconLabel">{{ iconLabel }}</span>
  </div>
</template>

<style scoped>
.timeline-wrapper:deep(.timeline) {
  margin-top: -1.5rem;
}
.iconLabel {
  margin-left: 0.5rem;
}
</style>
