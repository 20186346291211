<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
  name: 'DeactivateDialog',
})
export default class extends Vue {
  @Prop({ default: false }) visiable!: boolean;
  @Prop({ required: true }) title!: string;
  @Prop() activateOrDeactivate!: string;
  @Prop() content!: string;

  closeDialog() {
    this.$emit('handle-cancel');
  }

  handleDialogCancel() {
    this.$emit('handle-cancel');
  }

  dealDeactive() {
    this.$emit('handle-deactivate');
  }
}
</script>

<template>
  <el-dialog
    :visible="visiable"
    class="new-cust-dialog"
    :width="'600px'"
    :top="'25vh'"
    :title="
      (activateOrDeactivate === 'deactivate'
        ? $t('common.deactivate')
        : $t('common.activate')) +
      ' ' +
      $t(title)
    "
    @close="closeDialog"
  >
    <div class="d-flex ai-center" style="flex-direction: column">
      <div style="margin-top: 20px">
        <img src="@/icons/svg/deactive.svg" style="width: 48px" />
      </div>
      <div style="margin: 10px 30px 30px; font: 400 20px/36px Roboto">
        <span style="white-space: nowrap">{{ content }}</span>
      </div>
      <div style="margin: 10px 0px">
        <el-button
          class="deactivate-btn"
          style="margin-right: 30px"
          type="plain"
          @click="handleDialogCancel"
          >{{ $t('customerModule.cancel') }}</el-button
        >
        <el-button class="deactivate-btn" type="info" @click="dealDeactive">{{
          activateOrDeactivate === 'deactivate'
            ? $t('common.deactivate')
            : $t('common.activate')
        }}</el-button>
      </div>
    </div>
  </el-dialog>
</template>

<style scoped>
.new-cust-dialog :deep(.el-dialog__title) {
  font-size: 20px;
  font-family: var(--fontRobotoMedium);
  line-height: 19px;
  color: #373e41;
}

.new-cust-dialog :deep(.el-dialog__headerbtn) {
  top: 10px;
  font-size: 30px;
  color: #373d41;
  position: static;
}
</style>

<style lang="scss" scoped>
.new-cust-dialog :deep(.el-dialog__header) {
  padding: 0px;
  padding-bottom: 0px;
  background-color: var(--Main) !important;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 20px;
  padding-right: 16px;
  height: 44px;
}

.el-button--plain:hover {
  color: #ffffff;
  border-color: #5f6567;
  background-color: #5f6567;
}

.new-cust-dialog :deep(.el-dialog__headerbtn:hover .el-dialog__close) {
  color: #5f6567;
}

.deactivate-btn {
  width: 160px;
  margin-right: 30px;
  font-family: $font-Roboto-Medium;
  font-size: 16px !important;
  border: 1px solid #373e41;
}
</style>
