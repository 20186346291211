/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon';
icon.register({
  subsMgmt: {
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
    data: '<g data-name="Component 349 – 3"><g data-name="Component 348 – 34"><path pid="0" data-name="Subtraction 18" d="M14 20H6a6.007 6.007 0 01-6-6V6a6.007 6.007 0 016-6h8a6.007 6.007 0 016 6v8a6.006 6.006 0 01-6 6zM6 1a5.006 5.006 0 00-5 5v8a5.006 5.006 0 005 5h8a5.005 5.005 0 005-5V6a5.006 5.006 0 00-5-5z" _fill="#373e41"/></g></g><g data-name="Component 335 – 1"><g data-name="Path 1353" stroke-linecap="round" stroke-linejoin="round" _fill="#373e41"><path pid="1" d="M13.164 14.603l-2.866-1.451-.226-.115-.226.115-2.866 1.451.433-3.352.03-.229-.156-.171-2.244-2.467 3.173-.605.24-.046.111-.218 1.505-2.941 1.505 2.941.111.218.24.046 3.173.605-2.244 2.467-.155.171.03.23.432 3.351z"/><path pid="2" d="M10.072 5.672l-1.06 2.07a1 1 0 01-.703.528l-2.296.437 1.644 1.808a1 1 0 01.252.8l-.312 2.415 2.023-1.024a1 1 0 01.904 0l2.023 1.024-.312-2.415a1 1 0 01.252-.8l1.644-1.808-2.296-.437a1 1 0 01-.703-.527l-1.06-2.071m0-2.196l1.95 3.811 4.05.773-2.845 3.127.553 4.289-3.708-1.878-3.708 1.878.553-4.289L4.072 8.06l4.05-.773 1.95-3.811z"/></g></g>',
  },
});
