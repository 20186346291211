/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon';
icon.register({
  theme: {
    width: 128,
    height: 128,
    viewBox: '0 0 128 128',
    data: '<path pid="0" d="M125.5 37L95.3 2.8a8 8 0 00-6-2.8 8 8 0 00-6 2.8l-3.8 4.3a8 8 0 01-6 2.8h-19a8 8 0 01-6-2.8l-3.8-4.3a8 8 0 00-6-2.8 8 8 0 00-6 2.8L2.5 37A10.3 10.3 0 000 43.8c0 2.6.9 5 2.5 6.8l12 13.7a7.8 7.8 0 008.4 2.5c1.3-.5 2.7.5 2.7 2.1v49.4c0 5.4 3.8 9.7 8.5 9.7H94c4.7 0 8.5-4.3 8.5-9.7V69c0-1.6 1.4-2.6 2.7-2.1 3 1 6.2 0 8.3-2.5l12.1-13.7c1.6-1.8 2.5-4.2 2.5-6.8 0-2.5-.9-5-2.5-6.8z"/>',
  },
});
