<script lang="ts" setup>
import { UUID } from '@/api/common';
import { useContextTypeFromRoute } from '@/auth/context';
import { ContextType } from '@/auth/contextType';
import { useSelectedCustomerInfo } from '@/auth/selectedCustomer';
import { useAsync } from '@/composables/async';
import { showNotificationOnError } from '@/composables/error';
import { ref } from 'vue';

const { companies, selectedCustomer, updateSelectedCustomer } =
  useSelectedCustomerInfo();

const updating = ref<Promise<void> | undefined>();
const updatingState = useAsync(updating);

showNotificationOnError(updatingState, 'navbar.selectCustomerFailed');

function handleCustomerSelect(customerId: UUID): void {
  updating.value = updateSelectedCustomer(customerId);
}

const contextType = useContextTypeFromRoute();
</script>

<template>
  <el-select
    v-loading="companies.loading || updatingState.loading"
    v-show="contextType === ContextType.SelectedCustomer"
    :value="selectedCustomer?.customerId"
    @input="handleCustomerSelect"
    filterable
    :placeholder="
      companies.data?.length === 0
        ? $t('navbar.noAvailableCustomers')
        : $t('navbar.selectCustomer')
    "
  >
    <el-option
      v-for="company in companies.data"
      :key="company.customerId"
      :value="company.customerId"
      :label="company.customerName"
    />
  </el-select>
</template>

<style lang="scss" scoped>
.el-select {
  margin: 0;

  & :deep(.el-input__inner) {
    color: #ffffff;
    background-color: #373e41;
    font-weight: 500;
    border: none;

    @media (min-width: 769px) {
      border: 1px solid #ffffff !important;
    }
  }

  & :deep(.el-input__icon) {
    color: white !important;
  }
}
</style>
