import { render, staticRenderFns } from "./ExpandedPayloadMonitoring.vue?vue&type=template&id=247b7deb&scoped=true"
import script from "./ExpandedPayloadMonitoring.vue?vue&type=script&setup=true&lang=ts"
export * from "./ExpandedPayloadMonitoring.vue?vue&type=script&setup=true&lang=ts"
import style0 from "./ExpandedPayloadMonitoring.vue?vue&type=style&index=0&id=247b7deb&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "247b7deb",
  null
  
)

export default component.exports