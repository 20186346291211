<script lang="ts">
import {
  getAssetOnBoardingById,
  getUnassignedGateways,
} from '@/api/assetsMgmt';
import CusFormItem from '@/components/form/CusFormItem.vue';
import GeneralInfo from '@/components/form/GeneralInfo.vue';
import NavPage from '@/components/nav/NavPage.vue';
import { PageModule } from '@/store/modules/page';
import {
  AssetField,
  AssetFieldStateType,
  AssetFieldToState,
  AssetStage,
  AssetType,
  getAssetFieldStates,
} from '@/utils/assetTypes';
import { customFailedMessage } from '@/utils/prompt';
import { Component, Prop, Vue } from 'vue-property-decorator';
import ConnectionProgress from '../components/ConnectionProgress.vue';
import VerticalDivide from '../components/VerticalDivide.vue';
import { formatServerDate } from '../helper';

@Component({
  name: 'EditConnectAsset',
  components: {
    NavPage,
    'general-info': GeneralInfo,
    VerticalDivide,
    'cus-form-item': CusFormItem,
    'connection-progress': ConnectionProgress,
  },
})
export default class extends Vue {
  @Prop({ required: true }) assetOnBoardingId!: string;

  /** Local variables */
  assetFieldStateType = AssetFieldStateType;
  connecAssetInfo: any = {};
  title = '';
  isConnectAssetLoading: boolean = false;
  unassignedGatewaysList: any = [];
  selfCheckingIsLoading: boolean = false;
  isSelfChecked: boolean = false;
  selfCheckActions: any = [];
  isConnectionProgressLoading: boolean = false;
  preliminaryAssetId: string = '';
  VIN: string = '';

  created() {
    this.fetchAssetOnBoardingById();
    this.fetchUnassignedGateways();
  }

  /**
   * Retrieve from API the assetOnBoarding by Id data
   */
  async fetchAssetOnBoardingById() {
    this.isConnectionProgressLoading = true;
    this.isConnectAssetLoading = true;
    await getAssetOnBoardingById(this.assetOnBoardingId).then((res) => {
      if (!res) {
        customFailedMessage(this.$t('common.errorWithFetchingData') as string);
        return;
      }

      if (res.code === 200) {
        let response = res.data;
        this.connecAssetInfo = response;
        this.connecAssetInfo.manufactureDate = formatServerDate(
          res.data.manufactureDate
        );
        this.preliminaryAssetId = response.preliminaryAssetId;
        this.VIN = response.vinNumber;
        PageModule.setTitle(
          this.formFieldState[AssetField.SerialNumber].type ===
            AssetFieldStateType.NotApplicable
            ? this.$t('assetMgmt.connectAssets')
            : this.$t('assetMgmt.connectAssets') + `: ${res.data.serialNumber}`
        );
        this.isConnectAssetLoading = false;
      }

      if (res.code === 400) {
        customFailedMessage(this.$t('common.errorWithFetchingData') as string);
        this.isConnectAssetLoading = false;
      }

      this.isConnectionProgressLoading = false;
    });
  }

  /**
   * Retrieve from API unassigned gateways
   */
  async fetchUnassignedGateways() {
    await getUnassignedGateways().then((res) => {
      if (!res) {
        customFailedMessage(this.$t('common.errorWithFetchingData') as string);
        return;
      }

      if (res.code === 200) {
        this.unassignedGatewaysList = res.data;
        return;
      }

      customFailedMessage(this.$t('common.errorWithFetchingData') as string);
    });
  }

  get rules() {
    const rules = {
      gatewayId: [
        {
          required: true,
          message: this.$t('assetMgmt.gatewayIdRequired'),
          trigger: 'change',
        },
      ],
    };

    return rules;
  }

  get formFieldState(): AssetFieldToState {
    const assetType = this.connecAssetInfo.assetType as AssetType;

    return getAssetFieldStates(AssetStage.Connect, assetType);
  }

  handleCompanyAssetIdChange() {
    this.connecAssetInfo.preliminaryAssetId = this.preliminaryAssetId;
  }

  // handleVINChange() {
  //   this.connecAssetInfo.vinNumber = this.VIN;
  // }
}
</script>

<template>
  <NavPage
    v-loading="isConnectAssetLoading"
    :visible="true"
    :arrowBack="false"
    @arrow-click="$router.push('/asset-mgmt/index?activeName=registerAssets')"
  >
    <div style="margin-left: 40px; margin-top: 44px">
      <el-row>
        <el-col :span="9">
          <general-info
            v-if="
              formFieldState.SerialNumber.type !==
              assetFieldStateType.NotApplicable
            "
            id="model_view_general_info_name"
            :name="'assetMgmt.assetSerialNumber'"
            :value="connecAssetInfo.serialNumber"
          />
          <general-info
            v-if="formFieldState.VIN.type !== assetFieldStateType.NotApplicable"
            id="model_view_general_info_name"
            :name="'assetMgmt.VIN'"
            :value="connecAssetInfo.vinNumber"
          />
          <general-info
            id="model_view_general_info_name"
            :name="'assetMgmt.AssetHIN'"
            :value="connecAssetInfo.assetDataCollectionId"
          />
          <general-info
            id="model_view_general_info_asset_type"
            :name="'assetMgmt.assetType'"
            :value="$t(connecAssetInfo.assetType)"
          />
          <general-info
            id="model_view_general_info_model_number"
            :name="'assetMgmt.prodModel'"
            :value="connecAssetInfo.productModelCode"
          />
          <general-info
            id="model_view_general_info_model_number"
            :name="'assetMgmt.modelNumber'"
            :value="connecAssetInfo.productModelNumber"
          />
          <general-info
            id="model_view_general_info_service_region"
            :name="'common.serviceRegion'"
            :value="connecAssetInfo.serviceRegion"
          />
          <general-info
            id="model_view_general_info_service_region"
            :name="'common.hyvaRegion'"
            :value="connecAssetInfo.hyvaRegion"
          />
          <general-info
            id="model_view_general_info_manufacture_date"
            :name="'assetMgmt.manufactureDate'"
            :value="connecAssetInfo.manufactureDate"
          />
          <general-info
            id="model_view_general_info_controller_id"
            :name="'assetMgmt.controllerId'"
            :value="connecAssetInfo.controllerId"
          />
        </el-col>
        <el-col :span="1" :offset="1">
          <VerticalDivide />
        </el-col>
        <el-col :span="10" :offset="2">
          <cus-form-item :title="$t('assetMgmt.gatewayId')" :required="true">
            <el-select
              class="gateway-id-select"
              id="gatewayiddropdowninput"
              v-model="connecAssetInfo.gatewayId"
              :placeholder="$t('assetMgmt.gatewayId')"
              clearable
            >
              <el-option
                v-for="(item, index) in unassignedGatewaysList"
                :key="index"
                :label="item.gatewayId"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </cus-form-item>
          <!-- <cus-form-item
            v-if="formFieldState.VIN.type != assetFieldStateType.NotApplicable"
            :title="$t('assetMgmt.VIN')"
            :required="formFieldState.VIN.type == assetFieldStateType.Required"
          >
            <el-input
              @input="handleVINChange"
              class="company-asset-id-input"
              v-model="VIN"
              :maxlength="formFieldState.VIN.maxLength"
            />
          </cus-form-item> -->
          <div
            v-if="
              formFieldState.AssetId.type !== assetFieldStateType.NotApplicable
            "
          >
            <cus-form-item
              v-if="$route.name === 'assetMgmtConnectAssetEdit'"
              :title="$t('assetMgmt.preliminaryAssetId')"
              :required="
                formFieldState.AssetId.type === assetFieldStateType.Required
              "
            >
              <el-input
                @input="handleCompanyAssetIdChange"
                id="model_edit_general_info_company_asset_id"
                class="company-asset-id-input"
                v-model="preliminaryAssetId"
                :maxlength="formFieldState.AssetId.maxLength"
              />
            </cus-form-item>
            <general-info
              v-else
              id="model_view_general_info_company_asset_id"
              :name="'assetMgmt.preliminaryAssetId'"
              :value="connecAssetInfo.preliminaryAssetId"
            />
          </div>

          <connection-progress
            v-loading="isConnectionProgressLoading"
            :assetOnBoardingId="assetOnBoardingId"
            :assetOnBoardingObj="connecAssetInfo"
            :assetFieldStates="formFieldState"
          />
        </el-col>
      </el-row>
    </div>
  </NavPage>
</template>

<style scoped>
.gateway-id-select :deep(.el-input) {
  width: 300px;
}

.company-asset-id-input :deep(.el-input__inner) {
  width: 300px;
}

.self-check-btn {
  background-color: #349d99;
}

.self-check-btn:hover {
  background-color: #247c79 !important;
}

.self-check-btn:focus {
  background-color: #247c79 !important;
}

.self-check-btn:focus {
  background-color: #247c79 !important;
}

.self-check-container {
  display: flex;
}

.self-check-content {
  display: flex;
}

.self-check-btn-info {
  margin-left: 10px;
  color: #c1c1c1;
}

.self-check-done-container {
  border: 0.1px solid #c1c1c1;
  border-radius: 4px;
  width: 590px;
  height: 340px;
  margin-bottom: 30px;
  overflow: auto;
}

.self-check-done-btn {
  margin-left: 390px;
  height: 40px;
  width: 200px;
}

.self-check-items {
  padding: 10px;
  font-size: 18px;
}

.self-check-item-no-progress {
  padding: 10px;
  font-size: 18px;
  color: red;
}

.el-button {
  height: 43px;
}

.el-select :deep(.el-input__inner) {
  width: 300px;
}

.form-item-container {
  width: 600px;
}

/* .form-item-container :deep(.el-input__inner) {
    margin-left: -90px;
}

.form-item-container :deep(.show-value) {
    margin-left: -90px;
} */
</style>

<style lang="scss" scoped>
:deep(.form-item-container) {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 40px;
  .form-title {
    width: 270px;
    margin-right: 22px;
    text-align: left;
    font-family: $font-Roboto-Regular;
    color: #373e41;
  }
  .show-title {
    font-size: 18px;
  }
}

.common-button {
  width: $formButtonWidth;
  height: 40px;
  font-size: 1.285714rem;
}
</style>
