<script lang="ts">
import {
  GenerateGlobalReportRequestBody,
  generateReport,
  getReportTemplates,
  GlobalReportFilter,
  ReportTemplate,
} from '@/api/report';
import { ActiveContext, useActiveContext } from '@/auth/context';
import WidgetDialog from '@/components/dialog/WidgetDialog.vue';
import CusFormItem from '@/components/form/CusFormItem.vue';
import { Filter, SorterOrder } from '@/model/queryParameters/QueryParameter';
import { UserModule } from '@/store/modules/user';
import { customFailedMessage } from '@/utils/prompt';
import { REPORTDATE_FORMAT } from '@/utils/time';
import { DateRange } from '@/utils/types/date';
import { InitializeReactive } from '@/utils/vueClassComponentHelpers';
import {
  ReportFormat,
  SUBSCRIBE_REPORT_ASSET_SCOPES,
} from '@/utils/workData/lookuptable';
import { substractDaysFromData } from '@/views/report/helpers/substractDaysFromData';
import moment from 'moment';
import { Ref, unref } from 'vue';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { downloadFile } from '../report/components/components/helpers/downloadReport';

interface GenerateGlobalReportForm {
  reportName: string;
  reportFormat: ReportFormat;
}

@Component({
  name: 'ExportDailyTripLog',
  components: {
    CusFormItem,
    WidgetDialog,
  },
})
export default class extends Vue {
  @Prop({ default: false }) visible!: boolean;
  @Prop({ required: true }) reportTemplateData!: ReportTemplate;
  @Prop({ required: true }) dateRange!: DateRange;
  @Prop({ required: false }) sortField!: string;
  @Prop({ required: false }) sortOrder!: SorterOrder;
  @Prop({ required: true }) filters!: Filter[];

  context!: Ref<ActiveContext>;

  reportFormatList = [] as string[];
  @InitializeReactive
  reportFormValues!: GenerateGlobalReportForm;
  reportFormat: string = '';
  reportTemplateFormatIsLoading: boolean = false;
  contentIsLoading: boolean = false;

  created(): void {
    this.initializeDefaultSettings();
    this.context = useActiveContext();
  }

  async initializeDefaultSettings(): Promise<void> {
    this.reportFormValues = {
      reportFormat: ReportFormat.Excel,
      reportName: this.getReportName(),
    };
    this.handleReportFormats();
  }

  async handleReportFormats(): Promise<void> {
    try {
      this.reportTemplateFormatIsLoading = true;
      const res = await getReportTemplates();
      if (res.code === 200) {
        this.reportFormatList = res.data;
      }
      this.reportTemplateFormatIsLoading = false;
    } catch (error) {
      console.log(error);
    }
  }

  close(): void {
    this.$emit('update:visible', false);
  }

  get rules() {
    const tmpRules = {
      reportName: [
        {
          required: true,
          message: this.$t('report.tipInputReportName'),
          pattern: /^\S+/,
          trigger: 'change',
        },
        { validator: this.validateReportName, trigger: 'change' },
      ],
      reportFormat: [
        {
          required: true,
          message: this.$t('report.pleaseSelectAReportFormat'),
          pattern: /^\S+/,
          trigger: 'change',
        },
        { validator: this.validateReportName, trigger: 'change' },
      ],
    };

    return tmpRules;
  }

  validateReportName = (_rule: any, value: any, callback: any) => {
    if (value.length < 1 || value.length > 100) {
      return callback(
        new Error(`${this.$t('common.inputValidationOneToOneHundred')}`)
      );
    }
    return callback();
  };

  formatDateForReportTimestamp(date: string): string {
    return moment(date).format(REPORTDATE_FORMAT);
  }

  subtractData(date: string): string {
    return substractDaysFromData({
      dateToBeSubstracted: date,
      dateFormat: REPORTDATE_FORMAT,
    });
  }

  getReportName(): string {
    if (!this.dateRange?.start || !this.dateRange?.end) {
      return this.reportTemplateData.name;
    }

    return `${this.reportTemplateData
      ?.name!}_${this.formatDateForReportTimestamp(
      this.dateRange.start
    )}-${this.subtractData(this.dateRange.endExclusive)}`;
  }

  async exportReport(): Promise<void> {
    (this.$refs.refExportGlobalReportForm as any).validate(
      async (valid: boolean) => {
        if (!valid) {
          return;
        }

        if (this.reportFormValues.reportFormat === undefined) {
          throw new Error('Report Format has to be defined');
        }

        this.contentIsLoading = true;

        const finalSortField = [
          'tripStatus',
          'endPoint.timestamp',
          'startPoint.timestamp',
        ].includes(this.sortField)
          ? this.sortField
          : ['organizationName'].includes(this.sortField)
          ? 'organizationId'
          : ['companyAssetId'].includes(this.sortField)
          ? 'assetId'
          : 'startPoint.timestamp';

        const requestBody: GenerateGlobalReportRequestBody = {
          reportName: this.reportFormValues.reportName,
          reportTemplateId: this.reportTemplateData.id,
          reportSelectionStartDate: this.dateRange.start,
          reportSelectionEndDate: this.dateRange.end,
          assetScope: SUBSCRIBE_REPORT_ASSET_SCOPES.assetRange,
          reportFormat: this.reportFormValues.reportFormat,
          timezone: UserModule.timeZone,
          filters: this.filters as GlobalReportFilter[],
          sorters: [
            {
              field: finalSortField,
              order: this.sortOrder ?? SorterOrder.DESC,
            },
          ],
        };

        try {
          this.$emit('export-started');

          const response = await generateReport(
            requestBody,
            unref(this.context)?.impersonatedCompanyId
          );

          downloadFile(
            response,
            this.reportFormValues.reportName,
            this.reportFormValues.reportFormat
          );
        } catch {
          this.contentIsLoading = false;
          customFailedMessage(this.$t('common.errorWithFetchingData'));
          return;
        } finally {
          this.contentIsLoading = false;
          this.$emit('export-finished');
          this.close();
        }
      }
    );
  }
}
</script>

<template>
  <WidgetDialog
    class="export-global-report-modal"
    :visible.sync="visible"
    :confirmBtnName="$t('common.export')"
    :title="$t('report.exportReport')"
    :width="'800px'"
    @handle-cancel="close"
    @handle-confirm="exportReport"
    :contentIsLoading="contentIsLoading"
    :append-to-body="true"
  >
    <el-form
      class="input-form-element"
      style="margin: 32px 0px 40px 70px"
      ref="refExportGlobalReportForm"
      :model="reportFormValues"
      :rules="rules"
      :inline-message="false"
    >
      <CusFormItem :title="'report.reportAs'">
        <el-form-item prop="reportName">
          <el-input
            v-model="reportFormValues.reportName"
            :placeholder="$t('report.inputReportName')"
          />
        </el-form-item>
      </CusFormItem>

      <CusFormItem :title="'report.subscribeReportFormat'">
        <el-form-item
          prop="reportFormat"
          :disabled="reportTemplateFormatIsLoading"
        >
          <el-select
            v-model="reportFormValues.reportFormat"
            :placeholder="$t('report.subscribeReportFormat')"
            v-loading="reportTemplateFormatIsLoading"
          >
            <el-option
              v-for="(item, index) in reportFormatList"
              :key="index"
              :label="$t(item)"
              :value="item"
            />
          </el-select>
        </el-form-item>
      </CusFormItem>
    </el-form>
  </WidgetDialog>
</template>

<style scoped lang="scss">
.report-template-name {
  font-weight: bold;
  padding: 0 10px;
  font-family: var(--fontRobotoMedium, Roboto-Medium);
}

.date-range :deep(.select) {
  width: 150px;
}

.export-global-report-modal :deep(.el-form) {
  .item {
    margin-top: 0;
  }

  .item-title {
    text-align: left;
    margin-left: 40px;
  }

  .item-content {
    margin-left: -40px;
  }
}

.export-global-report-modal :deep(.el-dialog__body) {
  padding: 0 25px 0;
}

.export-global-report-modal :deep(.el-dialog__footer) {
  padding: 0 0 20px;
}

.item :deep(.el-input) {
  width: 406px;
}
</style>
