/**
 * Quality attribute of a {@link Value} object.
 */
export const enum ValueQuality {
  OK = 'OK',
  OUT_OF_RANGE = 'OUT_OF_RANGE',
  FAULT = 'FAULT',
  NO_VALUE = 'NO_VALUE',
}

/**
 * Limit check attribute of a {@link Value} object.
 */
export const enum ValueLimitCheck {
  OK = 'OK',
  WARN_LOW = 'WARN_LOW',
  WARN_HIGH = 'WARN_HIGH',
  ALARM_LOW = 'ALARM_LOW',
  ALARM_HIGH = 'ALARM_HIGH',
}

/**
 * Value object as posted by assets.
 */
export interface GwValue<T> {
  /**
   * Actual value of the property.
   */
  v: T;

  /**
   * Quality of value.
   * If absent, the value is considered to be {@link ValueQuality.OK}.
   */
  q?: ValueQuality;

  /**
   * Raw data.
   */
  //rd?: any; // Not currently used
}

/**
 * Value object as posted by assets and further processed in rulechains.
 */
export interface Value<T> extends GwValue<T> {
  /**
   * Limit check status of value.
   * If absent, the value is considered to be {@link ValueLimitCheck.OK}.
   */
  lc?: ValueLimitCheck;
}
