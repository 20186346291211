<script lang="ts">
import {
  useUnitConversion,
  UseUnitConversionReturnType,
} from '@/composables/conversion';
import { findKpiValue } from '@/utils/assets';
import { formatUnitValue, UndefinedRendering } from '@/utils/format';
import { parseNumberOrUndefined } from '@/utils/number';
import { convertUnitValue } from '@/utils/units/conversion';
import { KPI_UNIT } from '@/utils/units/unitDefinitions';
import { toUnitValue } from '@/utils/units/unitValue';
import {
  gpsStatusColorMapping,
  KPI_FIELDS,
} from '@/utils/workData/lookuptable';
import {
  OperationalStatus,
  operationalStatusColorMapping,
} from '@/utils/workData/operationalStatus';
import moment from 'moment';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

@Component({
  name: 'LeafletMapAssetContent',
})
export default class extends Vue {
  @Prop() assetId!: string;
  @Prop() kpis!: any[];
  @Prop() showGpsFix!: boolean;

  gpsStatus: string | undefined = '';
  operationalStatus: string | undefined = '';
  lastCommunicationTime: string | undefined = '';
  payload: string | undefined = '';
  unitConversion!: UseUnitConversionReturnType;

  @Watch('assetId')
  onAssetIdChanged() {
    this.clearData();
    this.populateData();
  }

  created() {
    this.unitConversion = useUnitConversion();
  }

  mounted() {
    this.populateData();
  }

  async clearData() {
    this.gpsStatus = undefined;
    this.operationalStatus = undefined;
    this.lastCommunicationTime = undefined;
    this.payload = undefined;
  }

  async populateData() {
    const unknownValue: string = this.$t('unknownValue') as string;

    const gpsStatus = this.getKpi(KPI_FIELDS.GpsStatus) as string | undefined; // TODO-TYPING: Remove cast when getKpi gains typing
    const operationalStatus = this.getKpi(KPI_FIELDS.OperationalStatus) as
      | string
      | undefined; // TODO-TYPING: Remove cast when getKpi gains typing
    const lastCommunicationTime = this.getKpi(
      KPI_FIELDS.LastCommunicationTime
    ) as Date | undefined; // TODO-TYPING: Remove cast when getKpi gains typing
    const payloadInKgString = this.getKpi('KPI.CurrentPayload') as
      | string
      | undefined;
    const payloadInKg = parseNumberOrUndefined(payloadInKgString);
    const payloadInMT =
      payloadInKg !== undefined
        ? convertUnitValue(
            toUnitValue(payloadInKg, KPI_UNIT.Kilogram),
            this.unitConversion.currentUserPreferredUnit(KPI_UNIT.MetricTonne)
          )
        : undefined;

    this.operationalStatus =
      operationalStatus !== undefined
        ? (this.$t(operationalStatus) as string)
        : unknownValue;
    this.gpsStatus =
      gpsStatus !== undefined
        ? (this.$t(`gpsConnectivity.${gpsStatus}`) as string)
        : unknownValue;
    this.lastCommunicationTime =
      lastCommunicationTime !== undefined
        ? this.formatTime(lastCommunicationTime)
        : unknownValue;
    this.payload =
      payloadInMT !== undefined
        ? formatUnitValue(payloadInMT, {
            numberOfDecimals: 2,
            undefinedAs: UndefinedRendering.Empty,
          })
        : unknownValue;
  }

  getKpi(code: string) {
    return findKpiValue(this.kpis, this.assetId, code);
  }

  formatTime(provided: Date) {
    return moment(provided).fromNow();
  }

  get operationalStatusStyle() {
    const color =
      operationalStatusColorMapping[
        this.getKpi(KPI_FIELDS.OperationalStatus) as OperationalStatus
      ];
    if (!color) return undefined;
    return { backgroundColor: color };
  }

  get GPSStatusStyle() {
    let color = gpsStatusColorMapping.get(
      this.getKpi(KPI_FIELDS.GpsStatus) as string
    );
    if (!color) return undefined;
    return { backgroundColor: color };
  }
}
</script>

<template>
  <div class="widget-content">
    <div class="gps" v-if="showGpsFix">
      <div class="key">{{ $t('assets.gpsConectivity') }}:</div>
      <div class="value" :style="GPSStatusStyle" :key="gpsStatus">
        {{ gpsStatus }}
      </div>
    </div>
    <div class="status">
      <div class="key">{{ $t('mapAssetContent.operationalStatus') }}:</div>
      <div
        class="value"
        :style="operationalStatusStyle"
        :key="operationalStatus"
      >
        {{ operationalStatus }}
      </div>
    </div>
    <div class="sync">
      <div class="key">{{ $t('mapAssetContent.lastSyncTime') }}:</div>
      <div class="value">{{ lastCommunicationTime }}</div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.widget-content {
  padding: 0.5rem;
  flex-direction: column;

  div {
    width: 100%;
    display: flex;
    align-items: center;
    margin-bottom: 0.3rem;

    .key {
      width: 60%;
    }

    .value {
      width: 40%;
      text-transform: capitalize;
      font-weight: bold;
    }
  }

  .gps {
    .value {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      background-color: #66cdaa;
      border-radius: 10rem;
      color: white;
      padding: 0.2rem 0.8rem;
    }
  }

  .status {
    .value {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      background-color: #83a1d8;
      border-radius: 10rem;
      color: white;
      padding: 0.2rem 0.8rem;
    }
  }
}
</style>
