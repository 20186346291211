import { ActiveContext } from '@/auth/context';
import { getRequest } from '@/utils/request';
import { KPI_UNIT } from '@/utils/units/unitDefinitions';
import { UnitValue } from '@/utils/units/unitValue';
import { UUID } from './common';
import { CommonResultPromise } from './commonResult';
import { Value, ValueLimitCheck, ValueQuality } from './value';

export interface KpiUnitValue<T> extends UnitValue<T> {
  code: string;
}

export interface KpiDataRequest {
  metadata: {
    filter: {
      assetId?: string;
      assetTypeCode?: string;
      organizationId?: string;
      organizationIds?: string[];
      assetIds?: string[];
      groupBy?: string[];
      companyId?: string;
    };
    selection?: {
      startDate?: string;
      endDate?: string;
      dataBucketSize?: string;
      dataBucketDimension?: string;
    };
    timezone?: string;
  };
  details: Array<{
    entity?: string;
    fields?: Array<{
      code?: string;
      unit?: string;
      needGrowthPercentage?: boolean;
    }>;
  }>;
}

export interface KpiDataField {
  code: string;
  unit: KPI_UNIT;
  growthPercentage?: number | null;
  fleetAverage?: number | null;
  sumPerAsset?: number | null;
  warningLimit?: {
    low: number | null;
    high: number | null;
  };
  alarmLimit?: {
    low: number | null;
    high: number | null;
  };
  values: KpiDataValue[];
}

export interface KpiDataValue<T = any> {
  id?: string | null;
  k?: string | null;
  lc?: ValueLimitCheck | null;
  q?: ValueQuality | null;
  ts?: string | null;
  v?: T | null;

  // KpiDataValue is also a HashMap<string,string> in the Java backend
  [key: string]: any;
}

export interface KpiDataResponse {
  details: {
    entity: string;
    fields: KpiDataField[];
  }[];
}

/**
 * @deprecated Use `Value<T>`instead
 */
// TODO Get rid of the generic `any` (at least make it `unknown`)
export interface MultiAssetKpiValueLegacy<T = any> {
  code: string;
  v: T;
}

/**
 * @deprecated Use `AssetsWithKpis<T>`instead
 */
// TODO Make this interface internal (only inside fetchKpisForMultipleAssets)
export interface MultiAssetKpiAssetLegacy {
  assetId: UUID;
  values: MultiAssetKpiValueLegacy[];
}

/**
 * @deprecated Use `useMultiAssetKpisQuery()` instead.
 */
export function getKpisForMultipleAssets(
  requestData: KpiDataRequest,
  context?: ActiveContext
): CommonResultPromise<MultiAssetKpiAssetLegacy[]> {
  const request = getRequest();
  const response = request.post<MultiAssetKpiAssetLegacy[]>(
    `/kpis/assets`,
    requestData,
    {
      params: {
        selectedCustomer: context?.impersonatedCompanyId,
      },
    }
  );
  return response;
}

export interface AssetsWithKpis<
  T extends object = Record<string, Value<unknown>>
> {
  [assetUUID: UUID]: T;
}

/**
 * Fetch KPIs for multiple assets.
 *
 * Returns them as a dictionary like:
 * ```json
 * {
 *     "<some_uuid>": {
 *         "KPI.Foo": { "v": "12" },
 *         "KPI.Bar": { "v": "34" }
 *     },
 *     "<another_uuid>": {
 *         "KPI.Foo": { "v": "56" },
 *         "KPI.Bar": { "v": "78" }
 *     },
 * }
 * ```
 *
 * Optionally use generic parameter `T` to specify the shape of the
 * telemetry.
 */
export async function fetchKpisForMultipleAssets<
  T extends object = Record<string, Value<unknown>>
>(
  assetIds: UUID[],
  propertyCodes: string[],
  context?: ActiveContext
): Promise<AssetsWithKpis<T>> {
  const request = getRequest();
  const response = await request.post<MultiAssetKpiAssetLegacy[]>(
    `/kpis/assets`,
    {
      metadata: {
        filter: { assetIds },
      },
      details: [{ fields: propertyCodes.map((code) => ({ code })) }],
    },
    {
      params: {
        selectedCustomer: context?.impersonatedCompanyId,
      },
    }
  );
  if (response.code !== 200 || !response.data) {
    throw new Error(
      'invalid response, expected code 200 and data to be defined'
    );
  }

  return Object.fromEntries(
    response.data.map((asset) => [
      asset.assetId,
      Object.fromEntries(
        asset.values.map(({ code, ...rest }) => [code, rest])
      ) as T,
    ])
  );
}
