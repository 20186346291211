<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import {
  subActivityColors,
  TripSubActivity,
} from '@/utils/workData/lookuptable';
import { AssetType } from '@/utils/assetTypes';

@Component({
  name: 'TripLegend',
})
export default class extends Vue {
  @Prop({ required: true }) type!: AssetType;

  items = Object.entries(subActivityColors);

  get filtered() {
    let list = [] as TripSubActivity[];

    switch (this.type) {
      case AssetType.MobileCompactor:
        list = [TripSubActivity.Moving, TripSubActivity.StandingStill];
        break;
      case AssetType.TippingVehicle:
        list = [
          TripSubActivity.Driving,
          TripSubActivity.Tipping,
          TripSubActivity.StandingStill,
        ];
        break;
    }

    return this.items.filter((i) => list.includes(i[0] as TripSubActivity));
  }
}
</script>

<template>
  <div class="legend">
    <div
      v-for="[activity, color] of filtered"
      :key="activity"
      class="legend-item"
    >
      <div class="legend-color" :style="`background-color: ${color}`" />
      <div>
        {{ $t(activity) }}
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.legend {
  display: flex;
  gap: 1rem;
  font-weight: 500;

  &-item {
    background-color: $trip-live-player-item-background-color;
    border-radius: 4px;
    padding: 0 4px 0 4px;
    display: flex;
    align-items: center;
  }

  &-color {
    width: 2rem;
    height: 0.75rem;
    margin-right: 0.5rem;
  }
}
</style>
