<script lang="ts">
import {
  createProductModel,
  CreateUpdateModalSpecsProperty,
  getProductModelById,
  ModelSpecsNewProperty,
} from '@/api/products';
import { ErrorType } from '@/api/types';
import NavPage from '@/components/nav/NavPage.vue';
import { customFailedMessage, promptSuccessBox } from '@/utils/prompt';
import { PROD_ERROR_CODE_LIST } from '@/utils/workData/lookuptable';
import { Component, Vue, Watch } from 'vue-property-decorator';
import GeneralForm from '../components/GeneralForm.vue';
import ModelSpecForm from '../components/ModelSpecForm.vue';
import PartsForm from '../components/PartsForm.vue';
import { checkNewPropertiesValues } from '../helpers/checkNewPropertiesValues';

@Component({
  name: 'createModel',
  components: {
    NavPage,
    'general-form': GeneralForm,
    'model-spec': ModelSpecForm,
    'parts-form': PartsForm,
  },
})
export default class extends Vue {
  /** Local variables */
  activeName: string = 'general';
  id = this.$route.params.id ? this.$route.params.id : -1; //has id if admin visited create by 'copy' , or has no id
  freezingBtn = true;
  productModelInfo = {};
  assetType = 'ASSTYP_STATIC_COMPACTOR';
  productModelPicture: any;
  createModelAllData: {
    properties: CreateUpdateModalSpecsProperty[];
    newProperties: ModelSpecsNewProperty[];
    containedParts: any[];
  } = {
    properties: [],
    newProperties: [],
    containedParts: [],
  };
  errorCode = PROD_ERROR_CODE_LIST;
  errorInfos: ErrorType[] = [
    {
      code: '',
      field: '',
      message: '',
    },
  ];
  productModelIsLoading: boolean = false;

  created() {
    if (this.id && this.id != -1) {
      //has id so have to get all the detail info
      this.getProductModelInfo();
    }
  }

  mounted() {
    this.showTabs();
  }

  /** Get error name info */
  get errNameInfo(): string {
    let errInfo: string = '';
    if (
      this.errorInfos.find((item) => item.field === this.errorCode.partMaterial)
        ?.code === 'ApiErrorFieldNotFound'
    ) {
      errInfo = `${this.$t('prodCata.partMaterialNotFound')}`;
    }
    if (
      this.errorInfos.find((item) => item.field === this.errorCode.property)
        ?.code === 'ApiErrorFieldNotFound'
    ) {
      errInfo = `${this.$t('prodCata.propertyNotFound')}`;
    }
    if (
      this.errorInfos.find((item) => item.field === this.errorCode.unknown)
        ?.code === 'ApiErrorFieldDuplicate'
    ) {
      errInfo = `${this.$t('prodCata.notMatchError')}`;
    }
    if (
      this.errorInfos.find((item) => item.field === this.errorCode.lifeCycle)
        ?.code === 'ApiErrorFieldInvalid'
    ) {
      errInfo = `${this.$t('prodCata.lifeCycleError')}`;
    }
    if (
      this.errorInfos.find((item) => item.field === this.errorCode.assetType)
        ?.code === 'ApiErrorFieldInvalid'
    ) {
      errInfo = `${this.$t('prodCata.assetTypeNone')}`;
    }
    if (
      this.errorInfos.find((item) => item.field === this.errorCode.assetType)
        ?.code === 'ApiErrorFieldDuplicate'
    ) {
      errInfo = `${this.$t('prodCata.propertyDuplicate')}`;
    }
    if (
      this.errorInfos.find(
        (item) => item.field === this.errorCode.containedParts
      )?.code === 'ApiErrorFieldInvalid'
    ) {
      errInfo = `${this.$t('prodCata.partMaterialNameInvalid')}`;
    }
    if (
      this.errorInfos.find(
        (item) => item.field === this.errorCode.containedParts
      )?.code === 'ApiErrorFieldDuplicate'
    ) {
      errInfo = `${this.$t('prodCata.containedPartsDuplicate')}`;
    }

    return errInfo === '' ? this.errorInfos[0].message : errInfo;
  }

  /**
   * When parts info tab is choosed, fetch part number info
   * @param val
   * @param oldVal
   */
  @Watch('activeName')
  onActiveChange(val: string, oldVal: string) {
    switch (val) {
      case 'general':
        this.$router.push('/prod-cata/create-new-model?activeName=general');
        break;
      case 'modelSpec':
        //get the assetType
        // (this.$refs.general as any).sendChosenAssetType();
        this.$router.push('/prod-cata/create-new-model?activeName=modelSpec');
        break;
      case 'partsInfo':
        this.$router.push('/prod-cata/create-new-model?activeName=partsInfo');
        (this.$refs.partsInfo as any).fetchAllMaterialNumbers();
        break;
    }
  }

  showTabs() {
    if (this.$route.query.activeName) {
      this.activeName = this.$route.query.activeName as string;
    } else {
      this.activeName = 'general';
    }
  }

  /** Switch button */
  switchCreateBtn(value: boolean) {
    this.freezingBtn = value;
  }

  /**
   * Get product model info
   */
  async getProductModelInfo() {
    try {
      this.productModelIsLoading = true;
      const res = await getProductModelById(this.$route.params.id);
      this.productModelInfo = res.data;
    } catch (error) {
      console.log(error);
    } finally {
      this.productModelIsLoading = false;
    }
  }

  async handleCreateProductModel(): Promise<void> {
    try {
      this.productModelIsLoading = true;
      (this.$refs.general as any).sendFormData();
      (this.$refs.general as any).sendPicture();
      (this.$refs.modelSpec as any).sendSpecData();
      (this.$refs.partsInfo as any).sendPartsData();

      const requestPayload = new FormData();
      requestPayload.append('productModelPicture', this.productModelPicture);

      if (this.createModelAllData.newProperties.length) {
        const errors = checkNewPropertiesValues(
          this.createModelAllData.newProperties
        );
        if (errors.length) {
          customFailedMessage(errors.join(', '));
          return;
        }
      }

      requestPayload.append(
        'productModelRequestBody',
        new Blob([JSON.stringify(this.createModelAllData)], {
          type: 'application/json',
        })
      );

      const res = await createProductModel(requestPayload);
      this.errorInfos = [];
      if (res.code === 200) {
        promptSuccessBox(this.$t('common.created') as string);
        this.$router.push('/prod-cata/index?activeName=productModels');
        return;
      } else if (res.code === 400) {
        /* @ts-expect-error TODO Wrong type */
        this.errorInfos = [...res.data.errors[0]];
      }
    } catch (error) {
      console.log(error);
      throw new Error(this.errNameInfo);
    } finally {
      this.productModelIsLoading = false;
    }
  }

  /** Get product model picture from child component */
  updateProductModelPicture(picture: any): void {
    this.productModelPicture = picture;
  }

  /** Update input form data from child component */
  updateInputFormData(data: any): void {
    this.createModelAllData = Object.assign({}, data);
  }

  /** Get model specification data */
  getModelSpecificationData({
    properties,
    newProperties,
  }: {
    properties: CreateUpdateModalSpecsProperty[];
    newProperties: ModelSpecsNewProperty[];
  }): void {
    this.createModelAllData.properties = properties;
    this.createModelAllData.newProperties = newProperties;
  }

  /** Get parts info from child component */
  getPartsInfo(data: any): void {
    this.createModelAllData.containedParts = data;
  }

  /** Update asset type from child component */
  updateAssetType(data: any): void {
    this.assetType = data;
  }
}
</script>

<template>
  <NavPage
    v-loading="productModelIsLoading"
    :arrowBack="false"
    :visible="true"
    @arrow-click="$router.push('/prod-cata/index')"
  >
    <template #button>
      <el-button
        id="prod_model_create_button"
        v-permission="['AUTHRSC_ACTION_PROD_MDL_CREATE']"
        type="plain"
        :disabled="freezingBtn"
        @click="handleCreateProductModel"
      >
        <i class="el-icon-plus common-icon" />{{
          $t('prodCata.createProductModel')
        }}
      </el-button>
    </template>
    <el-tabs id="prod_cata_tabs" v-model="activeName" style="padding: 0 40px">
      <el-tab-pane
        id="create_new_model_general"
        name="general"
        :label="$t('prodCata.general')"
      >
        <general-form
          ref="general"
          @disabledCreate="switchCreateBtn"
          :productModelInfo="productModelInfo"
          state="create"
          @product-model-input-form-event="updateInputFormData"
          @product-model-picture-evenet="updateProductModelPicture"
          @product-model-asset-type-event="updateAssetType"
        />
      </el-tab-pane>
      <el-tab-pane
        id="create_new_model_spec"
        name="modelSpec"
        :label="$t('prodCata.modelSpec')"
      >
        <model-spec
          :assetType="assetType"
          :propertiesPrev="productModelInfo"
          ref="modelSpec"
          @send-modal-spec="getModelSpecificationData"
          state="create"
        />
      </el-tab-pane>
      <el-tab-pane
        id="create_new_model_parts_info"
        name="partsInfo"
        :label="$t('prodCata.partsInfo')"
      >
        <parts-form
          ref="partsInfo"
          :partsInfoPrev="productModelInfo"
          state="create"
          @send-parts-info="getPartsInfo"
        />
      </el-tab-pane>
    </el-tabs>
  </NavPage>
</template>

<style lang="scss" scoped>
.user-setting-form {
  margin: 0 20px;
}
</style>
