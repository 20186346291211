import { StatisticalData } from '@/api/assets';
import { KpiDataValue } from '@/api/kpis';
import i18n from '@/lang';
import { KpisDataPayload } from '@/utils/kpidata';
import { LANGUAGE } from '@/utils/workData/lookuptable';

const addSimpleRow = (list: string[], text: string) => {
  list.push(text);
  list.push('\n');
};

const getEnTranslation = (text: string) => {
  return i18n.t(text, LANGUAGE.EN_US);
};

export const getStatisticalDataAfterDays = (
  statisticalData: StatisticalData
) => {
  const noOfFields = statisticalData.fields.length;
  const noOfDays = !!noOfFields ? statisticalData.fields[0].values.length : 0;
  const staticalDataRows = [] as { ts: string; v: string[] }[];

  for (let dayIndex = 0; dayIndex < noOfDays; dayIndex++) {
    const dayValues = [] as KpiDataValue[];
    for (let fieldIndex = 0; fieldIndex < noOfFields; fieldIndex++) {
      dayValues.push(statisticalData.fields[fieldIndex].values[dayIndex]);
    }
    const colValues = [] as string[];
    dayValues.forEach((dayValue) => {
      colValues.push(dayValue.v || '');
    });
    if (!!dayValues.length) {
      staticalDataRows.push({
        ts: dayValues[0].ts || '',
        v: dayValues.map((dv) => (dv.v ? (+dv.v).toFixed(2) : '0.00')),
      });
    }
  }

  staticalDataRows.sort((row1, row2) => {
    if (row1.ts < row2.ts) return -1;
    if (row1.ts === row2.ts) return 0;
    return 1;
  });

  return staticalDataRows;
};

const addKpiDataRows = (csv: string[], data: KpisDataPayload) => {
  data.kpis.kpiData.forEach((statisticalData) => {
    const companyAssetId = statisticalData.companyAssetId;
    const staticalDataRows = getStatisticalDataAfterDays(statisticalData);

    staticalDataRows.forEach((row) =>
      addSimpleRow(csv, `${companyAssetId},${row.ts},${row.v.join(',')}`)
    );
  });
};

export const composeCsvReport = ({
  startDate,
  endDate,
  data,
}: {
  startDate: string;
  endDate: string;
  data: KpisDataPayload;
}): string[] => {
  const csv = [] as string[];

  addSimpleRow(
    csv,
    `${getEnTranslation('report.dataTimeRange')},${getEnTranslation(
      'report.date'
    )}`
  );
  addSimpleRow(csv, `${getEnTranslation('report.startDate')},${startDate}`);
  addSimpleRow(csv, `${getEnTranslation('report.endDate')},${endDate}`);
  csv.push('\n');

  addSimpleRow(
    csv,
    `${getEnTranslation('report.kpisSummary')},${getEnTranslation(
      'report.value'
    )},${getEnTranslation('report.unit')}`
  );
  const cols = [] as string[];
  data.kpis.summary.forEach((summary) => {
    const translatedCode = getEnTranslation(summary.code).toString();
    const translatedUnit = getEnTranslation(summary.unit);
    cols.push(translatedCode);
    addSimpleRow(
      csv,
      `${getEnTranslation(
        'report.total'
      )} ${translatedCode},${summary.sum.toFixed(2)},${translatedUnit}`
    );
    addSimpleRow(
      csv,
      `${getEnTranslation(
        'report.average'
      )} ${translatedCode},${summary.avg.toFixed(2)},${translatedUnit}`
    );
  });
  csv.push('\n');

  addSimpleRow(
    csv,
    `${getEnTranslation('report.assetId')},${getEnTranslation(
      'report.date'
    )},${cols.join(',')}`
  );
  addKpiDataRows(csv, data);

  return csv;
};
