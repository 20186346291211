<script lang="ts" setup>
import {
  SafetyEventType,
  TripsWithEventsSummary,
  TripsWithEventsSummaryByTime,
} from '@/api/trip';
import { useRoute } from '@/composables/router';
import { Breakpoint, useMaxWindowSize } from '@/composables/window';
import i18n from '@/lang';
import router from '@/router';
import { NEW_FLEET_SAFETY_CODE } from '@/router/links/safetyWidget';
import { AggregationType } from '@/widgets/utils/Constants';
import {
  getExpandedFleetSafetyTooltipAggregatedByAsset,
  getExpandedFleetSafetyTooltipAggregatedByTime,
  getLabelWithEvent,
  getLabelWithoutEvent,
  isAssetSelection,
  TripEventSummaries,
  TripsSummariesChartSelection,
} from '@/widgets/utils/tripSafety';
import echarts, { EChartOption, ECharts } from 'echarts';
import { computed, nextTick, onUnmounted, ref, unref, watch } from 'vue';

const route = useRoute();

const isContainerWidthUnder1920px = ref<boolean>(window.innerWidth < 1920);

const props = withDefaults(
  defineProps<{
    isInExpandedMode?: boolean;
    data: TripEventSummaries | undefined;
    safetyEvent?: SafetyEventType;
    aggregationType?: AggregationType;
    selectedDate?: string | undefined;
    getSingleAssetLocation?: Function;
  }>(),
  {
    isInExpandedMode: false,
  }
);

const emit = defineEmits<{
  (e: 'selection', selection: TripsSummariesChartSelection | undefined): void;
}>();

const chartRef = ref<HTMLDivElement | HTMLCanvasElement>();
let chartInstance = ref<ECharts | null>();
let chartSlotIndexOnHover = ref<number>();
let chartSlotIndexOnClick = ref<number>();

const chartRatioTooltip = i18n.tc('newFleetSafety.ratio');

onUnmounted(() => {
  disposeChart();
});

watch(
  () => props.data,
  () => {
    disposeChart();
    const chartDOMElement = unref(chartRef);
    if (!chartDOMElement) {
      return;
    }

    const style = {
      fontSize: '',
      fontWeight: '',
      marginTop: '',
      marginLeft: '',
    };

    if (
      props.data?.summaries === undefined ||
      props.data?.summaries?.length === 0
    ) {
      const style = {
        fontSize: '20px',
        fontWeight: 'bold',
        marginTop: '20%',
        marginLeft: '40%',
      };

      chartDOMElement.innerHTML = i18n.tc('common.noData');
      Object.assign(chartDOMElement.style, style);
      return;
    }

    chartDOMElement.innerHTML = '';
    Object.assign(chartDOMElement.style, style);

    nextTick(() => {
      initChart(chartDOMElement);
    });
  }
);

/**
 * Initiate chart
 * - handle hover slot index for tooltip
 * - handle click slot index for date/asset selection
 */
function initChart(chartDOMElement: HTMLDivElement | HTMLCanvasElement): void {
  chartInstance.value = echarts.init(chartDOMElement);
  const instanceChart = unref(chartInstance);
  if (instanceChart) {
    instanceChart.setOption(getOption());
    /* @ts-expect-error TODO Wrong type */
    chartInstance.value!.getZr().on('mousemove', chartOnMouseMoveHandler);
    /* @ts-expect-error TODO Wrong type */
    chartInstance.value!.getZr().on('click', chartOnClickHandler);
    window.addEventListener('resize', resizeChart);
    resizeChart();
  }
}

function disposeChart() {
  if (chartInstance.value) {
    chartInstance.value.dispose();
    chartInstance.value = null;
    window.removeEventListener('resize', resizeChart);
    updateSelection(undefined);
  }
}

const resizeChart = () => {
  isContainerWidthUnder1920px.value = window.innerWidth < 1920;
  if (unref(chartInstance)) {
    unref(chartInstance)!.resize();
  }
};

/**
 * Abstract away the fact that we may either be plotting buckets
 * by date or by asset, just convert that into concept of key and label,
 * and (only) the extracted data we need from the original TripsWithEventsSummary's.
 */
interface DataPoint {
  key: string; // either ISO8601 date or asset uuid
  label: string; // formated local date or company asset id
  tripsWithEvents: number;
  tripsWithoutEvents: number;
  ratio: number;
}

const dataPoints = computed(() => {
  // Internal temporary type just to please typescript, such that we can
  // reliably get either the date or asset ID as keys/labels.
  interface GenericEventSummaryBucket {
    key: string;
    label: string;
    summary: TripsWithEventsSummary;
  }

  const data = props.data;
  if (!data) {
    return undefined;
  }

  const genericBuckets: GenericEventSummaryBucket[] =
    data.aggregationType === AggregationType.AggregateByTime
      ? data.summaries.map(
          (item): GenericEventSummaryBucket => ({
            key: item.bucketStartDate,
            label: item.bucketLabel,
            summary: item.tripsWithEventsSummary,
          })
        )
      : data.summaries.map(
          (item): GenericEventSummaryBucket => ({
            key: item.assetUUID,
            label: item.companyAssetId,
            summary: item.tripsWithEventsSummary,
          })
        );

  return genericBuckets.map(
    (bucket): DataPoint => ({
      key: bucket.key,
      label: bucket.label,
      tripsWithEvents: bucket.summary.numberOfTripsWithEvents,
      tripsWithoutEvents:
        bucket.summary.numberOfTotalTrips -
        bucket.summary.numberOfTripsWithEvents,
      ratio: bucket.summary.ratioPercentage,
    })
  );
});

/**
 * Populate chartSlotIndexOnHover ref with index of item being hovered over.
 */
function chartOnMouseMoveHandler(params: any): void {
  const pointInPixel = [params.event.offsetX, params.event.offsetY];
  if (
    !chartInstance.value ||
    !chartInstance.value.containPixel({ gridIndex: 0 }, pointInPixel)
  ) {
    return;
  }

  /** Get click category index */
  const pointInGrid = chartInstance.value!.convertFromPixel(
    { seriesIndex: 0 },
    pointInPixel
  );

  chartSlotIndexOnHover.value = Math.abs(pointInGrid[0]);
}

const chartSlotSelectionValue = ref<string | undefined>();

function updateSelection(
  newSelection: TripsSummariesChartSelection | undefined
): void {
  if (!unref(chartInstance)) {
    chartSlotSelectionValue.value = undefined;
    emit('selection', newSelection);
    return;
  }

  chartSlotSelectionValue.value = isAssetSelection(newSelection)
    ? newSelection.selectedAsset
    : newSelection?.selectedDate;
  emit('selection', newSelection);
  unref(chartInstance)!.setOption(getOption());
}

// When aggregation type changes, clear previous selection
watch(
  () => props.aggregationType,
  (newValue, oldValue) => {
    if (newValue !== oldValue) {
      updateSelection(undefined);
    }
  }
);

function chartOnClickHandler(params: any): void {
  const xAxisOffset = params.offsetX;
  const yAxisOffset = params.offsetY;

  const pointInPixel = [params.offsetX, params.offsetY];
  if (
    !chartInstance.value ||
    !chartInstance.value.containPixel({ gridIndex: 0 }, pointInPixel)
  ) {
    return;
  }

  /** Get click category index */
  const pointInGrid = chartInstance.value!.convertFromPixel(
    { seriesIndex: 0 },
    pointInPixel
  );

  /** Move axis pointer on a specific slot by click event */
  chartInstance.value!.dispatchAction({
    type: 'updateAxisPointer',
    x: xAxisOffset,
    y: yAxisOffset,
  });

  chartSlotIndexOnClick.value = Math.abs(pointInGrid[0]);

  const clickedSlotKey = unref(dataPoints)?.[chartSlotIndexOnClick.value].key;

  // Unselect if clicked again
  if (
    !clickedSlotKey ||
    props.selectedDate === clickedSlotKey ||
    unref(chartSlotSelectionValue) === clickedSlotKey
  ) {
    chartInstance.value!.dispatchAction({
      type: 'updateAxisPointer',
      x: undefined,
      y: undefined,
    });

    updateSelection(undefined);
    return;
  }

  const newSelection: TripsSummariesChartSelection =
    props.data?.aggregationType === AggregationType.AggregateByTime
      ? { selectedDate: clickedSlotKey }
      : { selectedAsset: clickedSlotKey };
  updateSelection(newSelection);

  // Force refresh of the tooltip (to show link)
  // TODO Move to updateSelection somehow (need pixel offsets)
  chartInstance.value!.dispatchAction({
    type: 'updateAxisPointer',
    x: xAxisOffset,
    y: yAxisOffset,
  });
}

// An axis has labels (for each tick) and a name (e.g. "Trips").
// We want the name to be shown below / beside the labels, so we offset
// the name by the space taken up to render each label.
const NAME_HEIGHT: number = 14;
const X_AXIS_LABELS_HEIGHT: number = 72;
const Y_LEFT_AXIS_LABELS_WIDTH: number = 40;
const Y_RIGHT_AXIS_LABELS_WIDTH: number = 40;
const X_AXIS_LABEL_ROTATION_DEGREE: number = 45;
const SLOT_HIGHLIGHT_BACKGROUND_COLOR: string = '#b3d6fe';

const getOption = () => {
  const isScreenSizeSmall = useMaxWindowSize(Breakpoint.Small);
  const legendHeight: number = isScreenSizeSmall.value ? 80 : 40;
  const legendItemGap: number = isScreenSizeSmall.value ? 10 : 40;

  const grid = {
    left: Y_LEFT_AXIS_LABELS_WIDTH + NAME_HEIGHT,
    right: Y_RIGHT_AXIS_LABELS_WIDTH + NAME_HEIGHT,
    top: legendHeight,
    bottom: X_AXIS_LABELS_HEIGHT + NAME_HEIGHT,
  };

  const tooltipTrigger: 'mousemove' | 'click' | 'none' = unref(
    chartSlotSelectionValue
  )
    ? 'none'
    : isScreenSizeSmall.value
    ? 'click'
    : 'mousemove';

  return {
    color: ['#be442d', '#d9d9d9', '#0138f7'],
    axisPointer: {
      triggerOn: tooltipTrigger,
    },
    tooltip: {
      trigger: 'axis',
      triggerOn: tooltipTrigger,
      axisPointer: {
        type: 'shadow',
        label: {
          show: false,
        },
      },
      enterable: true,
      formatter: function (params: any) {
        /* Handle mouse hover tooltip content when aggregation by time */
        const interactedBarIndex = isScreenSizeSmall.value
          ? unref(chartSlotIndexOnClick)
          : unref(chartSlotIndexOnHover);

        if (interactedBarIndex === undefined || !props.safetyEvent) return '';

        if (props.data?.aggregationType === AggregationType.AggregateByTime) {
          const selectedTripSummary: TripsWithEventsSummaryByTime =
            props.data?.summaries[interactedBarIndex];

          if (selectedTripSummary === undefined) return '';

          return getExpandedFleetSafetyTooltipAggregatedByTime(
            selectedTripSummary,
            props.safetyEvent
          );
        }

        /* Handle mouse click tooltip content when aggregation by asset */
        if (interactedBarIndex === undefined) return '';

        const selectedTripSummaryByAsset =
          props.data?.summaries[interactedBarIndex];
        if (selectedTripSummaryByAsset === undefined) return '';

        const selectedAssedUUID = unref(chartSlotSelectionValue);

        const redirectToSingleAssetIsVisible =
          route?.value?.query?.widget === NEW_FLEET_SAFETY_CODE;

        const location =
          props.getSingleAssetLocation && selectedAssedUUID
            ? props.getSingleAssetLocation(selectedAssedUUID)
            : undefined;

        const singleAssetHref = location
          ? router.resolve(location)?.href
          : undefined;

        /* Custom tooltip for asset aggregation needs to be shown for hover & when clicked */
        return getExpandedFleetSafetyTooltipAggregatedByAsset(
          selectedTripSummaryByAsset,
          props.safetyEvent,
          redirectToSingleAssetIsVisible,
          singleAssetHref
        );
      },
      /* Adjust tooltip position accordingly: default as top right from pointer and check for width and height of the chart to change at another pointer quadrants */
      position: function (pos, params, el, elRect, size) {
        const TOOLTIP_DISTANCE_FROM_POINTER: number = 10;

        const chartViewWidth = (size as any).viewSize[0];
        const tooltipWidth = el.offsetWidth;
        const tooltipHeight = el.offsetHeight;

        /* By default, put the tooltip at the top-right, relative to pointer */
        let newX = (pos[0] as number) + TOOLTIP_DISTANCE_FROM_POINTER;
        let newY =
          (pos[1] as number) - tooltipHeight - TOOLTIP_DISTANCE_FROM_POINTER;

        const tooltipOnLeftSideOfSelectedBarX =
          (pos[0] as number) - tooltipWidth - TOOLTIP_DISTANCE_FROM_POINTER;

        // Whether the tooltip exceeds the top boundary (including legends)
        const tooltipExceedsTopBoundary = newY < grid.top;

        // Whether the tooltip overflows if it's on the right side of the selected bar
        const positionExceedsRightBoundary =
          newX + tooltipWidth > chartViewWidth - grid.right;

        // Whether the tooltip overflows if it's on the left side of the selected bar
        const positionExceedsLeftBoundary = tooltipOnLeftSideOfSelectedBarX < 0;

        /* If the tooltip exceeds the top boundary (including legends), flip it to show below the pointer */
        if (tooltipExceedsTopBoundary) {
          newY = (pos[1] as number) + TOOLTIP_DISTANCE_FROM_POINTER;
        }

        // This happens on mobile devices where having the tooltip on the left or right side of the selected bar is not possible,
        // because the tooltip would flow out of the screen.
        if (positionExceedsLeftBoundary && positionExceedsRightBoundary) {
          const rightOverflow = Math.abs(
            newX + tooltipWidth - (chartViewWidth - grid.right)
          );

          return [newX - rightOverflow, newY];
        }

        /* If the tooltip exceeds the right boundary, move it to the left of the pointer */
        if (positionExceedsRightBoundary) {
          newX = tooltipOnLeftSideOfSelectedBarX;
        }

        return [newX, newY];
      },
    },
    legend: {
      data: [
        {
          name: props.safetyEvent ? getLabelWithEvent(props.safetyEvent) : '',
          icon: 'rect',
        },
        {
          name: props.safetyEvent
            ? getLabelWithoutEvent(props.safetyEvent)
            : '',
          icon: 'rect',
        },
        {
          name: chartRatioTooltip,
          icon: 'line',
        },
      ],
      orient: 'horizontal',
      left: 'center',
      top: 0,
      itemGap: legendItemGap,
    },
    grid,
    xAxis: [
      {
        name:
          props.aggregationType === AggregationType.AggregateByTime
            ? i18n.t('newFleetSafety.days')
            : i18n.t('newFleetSafety.assets'),
        nameLocation: 'middle',
        nameTextStyle: {
          fontWeight: 'bold',
        },
        nameGap: X_AXIS_LABELS_HEIGHT,
        type: 'category',
        data: dataPoints.value?.map((point) => point.key),
        axisLabel: {
          rotate: X_AXIS_LABEL_ROTATION_DEGREE,
          formatter: (value: any, index: number) => {
            value = dataPoints.value?.[index].label;
            const maxLength = 10;
            if (value.length > maxLength) {
              return value.substring(0, maxLength) + '...';
            }
            return value;
          },
          tooltip: {
            show: true,
          },
        },
        axisLine: {
          show: false,
        },
        axisTick: {
          show: false,
        },
      },
    ],
    yAxis: [
      {
        name: i18n.t('newFleetSafety.trips'),
        nameLocation: 'middle',
        nameTextStyle: {
          fontWeight: 'bold',
        },
        nameGap: Y_LEFT_AXIS_LABELS_WIDTH,
        type: 'value',
        axisLine: {
          show: false,
        },
        axisTick: {
          show: false,
        },
      },
      {
        name: '%',
        nameLocation: 'middle',
        nameTextStyle: {
          fontWeight: 'bold',
        },
        nameGap: Y_RIGHT_AXIS_LABELS_WIDTH,
        position: 'right',
        type: 'value',
        axisLine: {
          show: false,
        },
        axisTick: {
          show: false,
        },
        splitLine: {
          show: false,
        },
        min: 0,
        max: 100,
        splitNumber: 10,
      },
    ],
    series: [
      {
        name: props.safetyEvent ? getLabelWithEvent(props.safetyEvent) : '',
        type: 'bar',
        stack: 'total',
        yAxisIndex: 0,
        data: dataPoints.value?.map((point) => [
          point.key,
          point.tripsWithEvents,
        ]),
        barMaxWidth: 25,
        itemStyle: {
          borderColor: 'black',
          borderWidth: 0.5,
        },
      },
      {
        name: props.safetyEvent ? getLabelWithoutEvent(props.safetyEvent) : '',
        type: 'bar',
        stack: 'total',
        yAxisIndex: 0,
        data: dataPoints.value?.map((point) => [
          point.key,
          point.tripsWithoutEvents,
        ]),
        barMaxWidth: 25,
        itemStyle: {
          borderColor: 'black',
          borderWidth: 0.5,
        },
      },
      {
        name: chartRatioTooltip,
        type: 'line',
        yAxisIndex: 1,
        data: dataPoints.value?.map((point) => [point.key, point.ratio]),
      },
      {
        type: 'custom',
        renderItem: function (params, api) {
          const xValue = api.value!(0);
          const xCoord = api.coord!([xValue, 0])[0];
          const xSize = api.size!([1, 0])[0];
          const resultRect: EChartOption.SeriesCustom.RenderItemReturnRect = {
            type: 'rect',
            shape: {
              x: xCoord - xSize / 2,
              y: params.coordSys!.y,
              width: xSize,
              height: params.coordSys!.height,
            },
            style: api.style!({
              fill: SLOT_HIGHLIGHT_BACKGROUND_COLOR,
            }),
            z2: -Infinity,
          };
          return resultRect;
        },
        data: unref(chartSlotSelectionValue)
          ? [[unref(chartSlotSelectionValue)]]
          : [],
      },
    ],
  } as EChartOption<EChartOption.SeriesBar | EChartOption.SeriesCustom>;
};
</script>

<template>
  <div ref="chartRef" style="width: 100%; height: 100%" />
</template>
