<script lang="ts" setup>
import { MaintStatus } from '@/api/maintenance';
import { IncludedWidget } from '@/api/widgets';
import Card from '@/components/layout/widget/Card.vue';
import { useRoute, useRouter } from '@/composables/router';
import i18n from '@/lang';
import Vue, {
  computed,
  inject,
  onMounted,
  onUnmounted,
  Ref,
  ref,
  watch,
} from 'vue';
import { Location } from 'vue-router';

const props = withDefaults(
  defineProps<{
    defaultTitle?: string | undefined;
    titleIsLoading?: boolean;
    loading?: boolean;
    expandable?: boolean;
    actionTitle?: string;
    expandedLink?: Location;
    headerAdditionalInfo?: string[] | undefined;
    showBackButton?: boolean;
    floatActionsRight?: boolean;
  }>(),
  {
    titleIsLoading: false,
    showBackButton: false,
    floatActionsRight: false,
  }
);

const emit = defineEmits<{
  (e: 'click'): void;
  (e: 'resize-event'): void;
}>();

const widget = inject<Ref<IncludedWidget>>('widget');
const expanded = inject<Ref<boolean>>('expanded');

const router = useRouter();
const route = useRoute();

const container = ref<Vue>();
const width = ref(0);
const height = ref(0);
const observer = ref<ResizeObserver>();
const selected = inject<Ref<string> | undefined>('selected', undefined);

const expandedRoute = computed(() => {
  if (props.expandedLink) {
    return props.expandedLink;
  }
  return {
    query: {
      ...route.value.query,
      widget: widget?.value.code,
    },
  };
});

const containerClasses = computed(() => [
  ...(selected?.value === widget?.value.code ? ['selected'] : []),
  ...config.size.filter((s) => width.value >= s.width).map((s) => s.size),
]);
const style = computed(() =>
  !widget
    ? {}
    : {
        gridArea: `${widget.value.y + 1} / ${widget.value.x + 1} / span ${
          widget.value.h
        } / span ${widget.value.w}`,
        height: '100%',
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'column',
      }
);

onMounted(() => {
  if (!container.value?.$el) {
    return;
  }

  observer.value = new ResizeObserver((entries) => {
    const {
      contentRect: { width: w, height: h },
    } = entries[0];

    width.value = w;
    height.value = h;
  });

  observer.value.observe(container.value.$el);
});

watch(height, (next) => {
  if (!next) return;
  submitResizeEvent();
});

function submitResizeEvent() {
  emit('resize-event');
}

onUnmounted(() => observer.value?.disconnect());

const config = {
  size: [
    {
      width: 1000,
      size: 'big',
    },
    {
      width: 750,
      size: 'normal',
    },
  ],
};

function returnToPreviousPage() {
  router.back();
}
</script>

<template>
  <Card ref="container" :style="style" :class="containerClasses">
    <template slot="header">
      <slot name="header">
        <div v-loading="titleIsLoading" class="widget-card-header-inner">
          <i
            v-if="props.showBackButton === true"
            class="el-icon-arrow-left"
            style="font-size: 25px; cursor: pointer"
            @click="returnToPreviousPage"
          />
          <div v-if="defaultTitle" class="widget-header-title">
            {{ defaultTitle }}
          </div>
          <div v-else-if="widget" class="widget-header-title">
            {{ $te(widget.code) ? $t(widget.code) : widget.name }}

            <span v-if="headerAdditionalInfo" class="widget-header-info">
              {{ '(' + i18n.t('maintenance.filteredBy') }}
              <span
                v-for="(item, index) in headerAdditionalInfo"
                :key="index"
                :class="[
                  item === MaintStatus.OK
                    ? 'status-ok'
                    : item === MaintStatus.CLOSE
                    ? 'status-close'
                    : 'status-due',
                ]"
              >
                {{
                  i18n.t(item) +
                  (index + 1 < headerAdditionalInfo.length ? ', ' : '')
                }}
              </span>
              {{ ')' }}
            </span>
          </div>

          <div
            v-if="$slots.actions"
            class="actions-container"
            :style="{
              marginLeft: props.floatActionsRight === true ? 'auto' : 0,
            }"
          >
            <slot name="actions" />
          </div>

          <div
            v-if="props.expandable && !expanded && expandedRoute"
            style="display: flex; margin-left: auto"
          >
            <router-link
              :to="expandedRoute"
              :style="headerAdditionalInfo?.length ? 'padding: 7px' : ''"
            >
              <img src="@/assets/imgs/home/fullscreen.svg" />
            </router-link>
          </div>

          <el-button
            v-if="props.actionTitle"
            type="info"
            @click="emit('click')"
            style="margin-left: auto"
          >
            {{ props.actionTitle }}
          </el-button>
        </div>
      </slot>
    </template>
    <div
      v-on:resize="submitResizeEvent"
      class="content"
      v-loading="props.loading"
    >
      <slot />
    </div>
  </Card>
</template>

<style lang="scss" scoped>
.actions-container {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 8px;
}

.widget-card {
  &-container {
    display: none;

    &.selected {
      display: block;
    }

    @media (min-width: 769px) {
      display: block;
    }
  }

  &-header-inner {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 8px;

    img {
      height: 1em;
      vertical-align: middle;
    }
  }
}

.content {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.status-icon {
  margin: 10px;
}

.status-ok {
  color: $status-ok-color;
}

.status-close {
  color: $status-close-color;
}

.status-due {
  color: $status-due-color;
}
</style>
