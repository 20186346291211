<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
  name: 'WidgetDialog',
})
export default class extends Vue {
  @Prop({ default: false }) visible!: boolean;
  @Prop() title!: string;
  @Prop({ default: '60%' }) width!: string;
  @Prop() confirmBtnName!: string;
  @Prop() cancelBtnName?: string;
  @Prop({ default: true }) showCancelButton!: boolean;
  @Prop({ default: false }) contentIsLoading?: boolean;

  handleCloseDialog() {
    this.$emit('handle-cancel');
  }

  handleDialogCancel() {
    this.$emit('handle-cancel');
  }

  handleConfirm() {
    this.$emit('handle-confirm');
  }
}
</script>

<template>
  <el-dialog
    class="widget-dialog"
    :title="title"
    :visible="visible"
    :width="width"
    :showCancelButton="showCancelButton"
    center
    @close="handleCloseDialog"
  >
    <div class="widget-container" v-loading="contentIsLoading">
      <div>
        <slot></slot>
      </div>

      <div slot="footer" class="dialog-footer">
        <el-button
          class="widget-dialog-btn"
          type="plain"
          @click="handleDialogCancel"
          v-if="showCancelButton"
          >{{ cancelBtnName ?? $t('common.cancelBtn') }}</el-button
        >
        <el-button
          class="widget-dialog-btn"
          type="info"
          @click="handleConfirm"
          >{{ confirmBtnName }}</el-button
        >
      </div>
    </div>
  </el-dialog>
</template>

<style lang="scss" scoped>
:deep(.el-dialog) {
  border-radius: 4px;
}

:deep(.el-dialog__header) {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  padding-bottom: 20px;
  background-color: var(--Main) !important;
}

:deep(.el-dialog__title) {
  font-size: 20px;
  font-family: $font-Roboto-Medium;
  line-height: 19px;
  color: #373e41;
}

:deep(.el-dialog__headerbtn) {
  top: 10px;
  font-size: 30px;
  color: #373d41;
}

:deep(.el-dialog__headerbtn:hover .el-dialog__close) {
  color: #5f6567;
}

:deep(.el-dialog__body) {
  padding: 10px 25px 10px;
}

.widget-dialog-btn {
  width: 160px;
  margin-right: 30px;
  font-family: $font-Roboto-Medium;
  font-size: 16px !important;
  border: 1px solid #373e41;
}

.dialog-footer {
  text-align: center;
  margin: 0 0 20px 0;
}
</style>
