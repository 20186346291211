import { DatePeriod, DateRange, PeriodRange } from '@/utils/types/date';
import moment, { Moment } from 'moment';
import { DayRangeBoundaries } from './misc';

export function formatTime(seconds: number) {
  return format(moment.duration(seconds * 1000));
}

export function formatDuration(start: Date | string, end: Date | string) {
  return format(moment.duration(moment(start).diff(end)));
}

function format(duration: moment.Duration) {
  const h = duration.hours();
  const m = duration.minutes();
  const s = duration.seconds();

  if (h < 1 && m < 1) {
    return `${s}s`;
  }

  return `${duration.hours() ? `${h}h` : ''} ${
    duration.minutes() ? `${m}m` : ''
  } ${duration.seconds() ? `${s}s` : ''}`.trim();
}

/**
 * Local datetime representing start of today.
 *
 * Convenient starting point for other calculations.
 */
export function getStartOfDay(): Moment {
  return moment().startOf('day');
}

/**
 * Return the period (i.e. duration in days) of the given dateRange.
 */
export function getRangePeriod(dateRange: DateRange) {
  return moment(dateRange.endExclusive).diff(dateRange.start, 'days');
}

/**
 * Helper to convert a given daterange in the range before that.
 * For example, given a period of 7 days, returns the range of 7 days before that.
 */
export function getPreviousRange(dateRange: DateRange): DateRange {
  const periodInDays = getRangePeriod(dateRange);

  return {
    start: decreaseDays(dateRange.start, periodInDays),
    end: decreaseDays(dateRange.end, periodInDays),
    endExclusive: decreaseDays(dateRange.endExclusive, periodInDays),
  };
}

/**
 * Helper to convert a given daterange in the range after that.
 * For example, given a period of 7 days, returns the range of 7 days after that.
 */
export function getNextRange(dateRange: DateRange): DateRange {
  const periodInDays = getRangePeriod(dateRange);

  return {
    start: increaseDays(dateRange.start, periodInDays),
    end: increaseDays(dateRange.end, periodInDays),
    endExclusive: increaseDays(dateRange.endExclusive, periodInDays),
  };
}

export const DEFAULT_NUMBER_OF_LAST_DAYS = 7;
export const NUMBER_OF_LAST_DAYS_15 = 15;

export const DEFAULT_LAST_DAYS_PERIOD = 14;

/**
 * `moment` format used to get a format accepted by backend.
 */
export const LOCALDATE_FORMAT = 'YYYY-MM-DD';

/**
 * ISO 8601 time pattern
 */
export const ISO8601_LOCAL_DATETIME_PATTERN = 'YYYY-MM-DDTHH:mm:ss';

/**
 * Custom ISO 8601 time pattern
 */
export const ISO8601_CUSTOM_LOCAL_DATETIME_PATTERN = 'YYYY/MM/DD HH:mm:ss';

/**
 * Hours and minutes time pattern
 */
export const TIME_HOUR_MINUTES_PATTERN = 'HH:mm';

/**
 * `moment` format used in export reports.
 */
export const REPORTDATE_FORMAT = 'YYYYMMDD';

/**
 * `moment` timestamp format to use in exported filenames. NOTE: Windows doesn't allow `:` in filenames.
 *
 *  Read about timestamps in filenames from these links:
 *  - https://softwareengineering.stackexchange.com/questions/61683/standard-format-for-using-a-timestamp-as-part-of-a-filename
 *  - https://stackoverflow.com/questions/1248747/what-is-your-favorite-date-and-time-format-in-a-file-name
 */
export const FILENAME_TIMESTAMP_FORMAT = 'YYYY-MM-DD_HH-mm-ss';

/**
 * `momentz` format used by maintenance scheduler
 */
export const MAINTENANCE_SCHEDULER_DATE_TIME_PATTERN = 'YYYY/MM/DD HH:mm';

/**
 * Used for maintenance scheduler header
 */
export const MAINTENANCE_SCHEDULER_FORMAT = 'ddd MM/DD';

export const MONTH_DAY_FORMAT = 'MM/DD';

/**
 * Used for date picker component
 */
export const DATE_PICKER_DEFAULT_FORMAT = 'yyyy-MM-dd';

/**
 * Default datetime format for the user
 */
export const USER_DATE_TIME_FORMAT = 'YYYY-MM-DD hh:mm:ss';

/**
 * DateRange representing the default period (e.g. last 7 days).
 */
export const DEFAULT_DATE_RANGE: DateRange = {
  start: decreaseDays(getStartOfDay(), DEFAULT_NUMBER_OF_LAST_DAYS),
  end: decreaseDays(getStartOfDay(), 1),
  endExclusive: getStartOfDay().format(LOCALDATE_FORMAT),
};

export const DEFAULT_DATE_RANGE_15_DAYS: DateRange = {
  start: decreaseDays(getStartOfDay(), NUMBER_OF_LAST_DAYS_15),
  end: decreaseDays(getStartOfDay(), 1),
  endExclusive: getStartOfDay().format(LOCALDATE_FORMAT),
};

export function getDateRangeFromStartAndEnd(
  start: string,
  end: string
): DateRange {
  return {
    start,
    end: end,
    endExclusive: increaseDays(end, 1),
  };
}

/**
 * Retrieve DateRange for a week of the received day
 * i.e: received day '2023-10-06' => { start: '2023-10-06', end: '2023-10-06', endExlusive: '2023-10-13' }
 */
export function getWeekDateRange(dayOfTheWeek: string): DateRange {
  const weekStartDate = moment(dayOfTheWeek, LOCALDATE_FORMAT)
    .startOf('isoWeek')
    .format(LOCALDATE_FORMAT);
  const weekEndDate = moment(dayOfTheWeek, LOCALDATE_FORMAT)
    .endOf('isoWeek')
    .format(LOCALDATE_FORMAT);

  return {
    start: weekStartDate,
    end: weekEndDate,
    endExclusive: increaseDays(weekEndDate, 1),
  };
}

/**
 * Retrieve DateRange for a month of the received day
 * i.e: received day '2023-10-01' => { start: '2023-10-01', end: '2023-10-31', endExlusive: '2023-11-01' }
 */
export function getMonthDateRange(dayOfTheMonth: string): DateRange {
  const monthStartDate = moment(dayOfTheMonth, LOCALDATE_FORMAT)
    .startOf('month')
    .format(LOCALDATE_FORMAT);
  const monthEndDate = moment(dayOfTheMonth, LOCALDATE_FORMAT)
    .endOf('month')
    .format(LOCALDATE_FORMAT);

  return {
    start: monthStartDate,
    end: monthEndDate,
    endExclusive: increaseDays(monthEndDate, 1),
  };
}

export function getDateRangeForADatePeriod(
  periodRange: PeriodRange,
  selectedDate: string | undefined
): DateRange {
  if (!selectedDate) return periodRange;
  let finalResult = undefined;

  switch (periodRange.datePeriod) {
    case DatePeriod.DATP_DAY:
      finalResult = getDateRangeFromStartAndEnd(selectedDate, selectedDate);
      break;
    case DatePeriod.DATP_WEEK:
      finalResult = getWeekDateRange(selectedDate);
      break;
    case DatePeriod.DATP_MONTH:
      finalResult = getMonthDateRange(selectedDate);
      break;
  }

  return finalResult;
}

export function getDateRangeFromStartAndEndExclusive(
  start: string,
  endExclusive: string
): DateRange {
  return {
    start,
    end: decreaseDays(endExclusive, 1),
    endExclusive: endExclusive,
  };
}

/**
 * Compare two DateRange objects.
 * If they represent the same date range, return true, otherwise false.
 */
export function isSameDateRange(range1: DateRange, range2: DateRange): boolean {
  // endExclusive must always be one day after end, so no need to check, but let's stay safe...
  return (
    range1.start === range2.start &&
    range1.end === range2.end &&
    range1.endExclusive === range2.endExclusive
  );
}

/** ------------ New safety widget scope: custom period selector component ------------ */

/**
 * PeriodRange representing the default period (e.g. last 14 days).
 */
export const DEFAULT_PERIOD_RANGE: PeriodRange = {
  start: decreaseDays(getStartOfDay(), DEFAULT_LAST_DAYS_PERIOD),
  end: decreaseDays(getStartOfDay(), 1),
  endExclusive: getStartOfDay().format(LOCALDATE_FORMAT),
  datePeriod: DatePeriod.DATP_DAY,
};

/**
 * Get last end date of previous complete week, end date inclusive
 * E.g: First day of the week: Monday, Today date: 03.08.2023
 *  - will return 2023-07-31 (end date Sunday 2023-07-30 inclusive)
 * @param startDayOfWeek
 * @returns moment obj
 */
export function getLastDateInclusiveOfPreviousCompleteWeek(
  startDayOfWeek: number
): Moment {
  const currentDate = getStartOfDay();
  currentDate.startOf('week').day(startDayOfWeek).subtract(1, 'day');

  return currentDate;
}

/**
 * Get last date of previous complete month, end date inclusive
 * E.g: First day of the week: Monday, Today date: 03.08.2023
 *  - will return 2023-08-01 (end date 2023-07-31 inclusive)
 * @returns moment obj
 */
export function getLastDateInclusiveOfPreviousCompleteMonth(): Moment {
  const currentDate = moment(getStartOfDay().format(LOCALDATE_FORMAT));
  const startOfMonth = currentDate.clone().startOf('month');
  const lastDayOfPreviousMonth = startOfMonth.subtract(1, 'day');
  lastDayOfPreviousMonth.add(1, 'day');

  return lastDayOfPreviousMonth;
}

/**
 * Get first date of first complete week from the range
 * @param startDayOfWeek
 * @returns moment obj
 */
export function getFirstDateOfFirstCompleteWeek(
  date: Moment,
  startDayOfWeek: number
): Moment {
  const currentDate = moment(date.format(LOCALDATE_FORMAT));
  currentDate.startOf('week').day(startDayOfWeek);

  return currentDate;
}

/**
 * Get day range boundaries as start of and end of a specific date/day
 */
export const dayRangeBoundaries = (
  selectedDate: string | undefined
): DayRangeBoundaries => {
  const dateForConversion = selectedDate ?? moment();
  const start = moment(dateForConversion)
    .startOf('day')
    .format(ISO8601_LOCAL_DATETIME_PATTERN);
  const end = moment(dateForConversion)
    .startOf('day')
    .add(1, 'day')
    .format(ISO8601_LOCAL_DATETIME_PATTERN);

  return { startOfDay: start, endOfDay: end };
};

/**
 * Receive a local date and time like YYYY-MM-DD and HH:mm
 * And return it combined with time like ISO8601 pattern  YYYY-MM-DDTHH:mm
 * If there is no received date, provide a today date
 * i.e.: Received: 2023-09-01 and 10:00 Returned: 2023-09-01T10:00
 * @param receivedDate
 */
export function getISO8601DateAndTimeCombined(
  receivedDate: string | undefined,
  receivedTime: string
): string {
  const time = addHoursForTimePattern(receivedTime);
  return receivedDate != undefined
    ? `${receivedDate}T${time}`
    : `${getCurrentDay()}T${time}`;
}

/**
 * Add hours for time patterns HH:mm
 * - Receiving 12:00+ hours e.g.: +1 => result: 13:00
 * @param receivedTime
 */
export function addHoursForTimePattern(
  receivedTime: string,
  hours?: number | undefined
): string {
  const numberOfHours = hours ?? 1;
  return moment(receivedTime, TIME_HOUR_MINUTES_PATTERN)
    .add(numberOfHours, 'hours')
    .format(TIME_HOUR_MINUTES_PATTERN);
}

export function decreaseHoursForTimePattern(
  receivedTime: string,
  hours: number = 1
): string {
  return moment(receivedTime, TIME_HOUR_MINUTES_PATTERN)
    .subtract(hours, 'hours')
    .format(TIME_HOUR_MINUTES_PATTERN);
}

/**
 * Get current day formatted to YYYY-MM-DD pattern
 */
export function getCurrentDay(): string {
  return moment().startOf('day').format(LOCALDATE_FORMAT);
}

export function decreaseDays(
  receivedDate: Moment | string,
  numberToDecrease: number,
  format: string = LOCALDATE_FORMAT
): string {
  return moment(receivedDate).subtract(numberToDecrease, 'days').format(format);
}

export function increaseDays(
  receivedDate: Moment | string,
  numberToIncrease: number,
  format: string = LOCALDATE_FORMAT
): string {
  return moment(receivedDate).add(numberToIncrease, 'days').format(format);
}

/**
 * Calculate elapsed time from last sync date (received as 'YYYY-MM-DDTHH:mm:ss')
 * - The time fields will be progressively added as count increase for e.g.: '10s' => '10m 10s' => '10h 10m 10s'
 * - When count is bigger than 23h 59m 59s, the date and time pattern will be retrieved as 'YYYY/MM/DD HH:mm:ss'
 * Pattern returned for two scenarios: 1) Incremental units until 24h: '23h 59m 59s' or 2) '2023/10/10 10:00:00'
 */
export function elapsedLastSyncTime(lastSync: string): string {
  const lastSyncMoment = moment(lastSync);
  const currentTimeMoment = moment(new Date());
  const diff = moment.duration(currentTimeMoment.diff(lastSyncMoment));

  if (diff.asDays() >= 1) {
    return lastSyncMoment.format(ISO8601_CUSTOM_LOCAL_DATETIME_PATTERN);
  }

  const days = diff.days();
  const hours = diff.hours();
  const minutes = diff.minutes();
  const seconds = diff.seconds();

  const totalHours = days * 24 + hours;
  const finalResult = [];

  if (totalHours > 0) {
    finalResult.push(`${totalHours}h`);
  }

  if (minutes > 0) {
    finalResult.push(`${minutes}m`);
  }

  if (seconds > 0) {
    finalResult.push(`${seconds}s`);
  }

  return finalResult.join(' ');
}

export function getLastInnerPeriodFromEndDate(
  endDate: string,
  periodInDays = DEFAULT_LAST_DAYS_PERIOD
): DateRange {
  const endExlusive = increaseDays(endDate, 1);

  return {
    start: decreaseDays(endExlusive, periodInDays),
    end: endDate,
    endExclusive: endExlusive,
  };
}

/**
 * Calculate elapsed time by adding incrementaly the units bigger than 0
 * i.e.: '10s' => '10m 10s' => '10h 10m 10s'
 * Received time should be in seconds as timestamp
 */
export function calculateElapsedTime(time: string | number): string {
  const duration = moment.duration(time, 'seconds');
  const hours = duration.hours();
  const minutes = duration.minutes();
  const seconds = duration.seconds();

  const formattedTime = [];

  if (hours > 0) {
    formattedTime.push(`${hours}h`);
  }

  if (minutes > 0) {
    formattedTime.push(`${minutes}m`);
  }

  if (seconds > 0) {
    formattedTime.push(`${seconds}s`);
  }

  return formattedTime.join(' ');
}
