import { ContextType } from '@/auth/contextType';
import { AssetType } from '@/utils/assetTypes';
import { ALL_CLAIMS_CODES } from '@/utils/workData/lookuptable';
import { RouteConfig } from 'vue-router';

export enum RouteNames {
  MobileCompactors = 'MobileCompactors',
  MobileCompactorById = 'MobileCompactorInfo',
  MobileCompactorCreatePreset = 'MobileCompactorCreatePreset',
  MobileCompactorApplyPreset = 'MobileCompactorApplyPreset',
  StaticCompactors = 'StaticCompactors',
  StaticCompactorById = 'StaticCompactorInfo',
  StaticCompactorCreatePreset = 'StaticCompactorCreatePreset',
  StaticCompactorApplyPreset = 'StaticCompactorApplyPreset',
  TippingVehicles = 'TippingVehicles',
  TippingVehicleById = 'TippingVehiclesInfo',
  TippingVehicleCreatePreset = 'TippingVehicleCreatePreset',
  TippingVehicleApplyPreset = 'TippingVehicleApplyPreset',
  TippingVehicleFleetOperationalDashboard = 'OperationalDashboardTippingVehicles',
  TippingVehicleSingleOperationalDashboard = 'OperationalDashboardTippingVehicleInfo',
  AlbaStaticCompactors = 'AlbaStaticCompactors',
  AlbaStaticCompactorById = 'AlbaStaticCompactorInfo',
  AlbaStaticCompactorCreatePreset = 'AlbaStaticCompactorCreatePreset',
  AlbaStaticCompactorApplyPreset = 'AlbaStaticCompactorApplyPreset',
  GenericAssets = 'GenericAssets',
  GenericAssetById = 'GenericAssetInfo',
  GenericAssetCreatePreset = 'GenericAssetCreatePreset',
  GenericAssetApplyPreset = 'GenericAssetApplyPreset',
  RefuseCollectionVehicles = 'RefuseCollectionVehicles',
  RefuseCollectionVehicleById = 'RefuseCollectionVehicleInfo',
  RefuseCollectionVehicleCreatePreset = 'RefuseCollectionVehicleCreatePreset',
  RefuseCollectionVehicleApplyPreset = 'RefuseCollectionVehicleApplyPreset',
}

export interface OverviewRouteNames {
  single: RouteNames;
  fleet: RouteNames;
  fleetOperationalDashboard?: RouteNames;
  singleOperationalDashboard?: RouteNames;
  createPreset: RouteNames;
  applyPreset: RouteNames;
}

// @ts-expect-error TODO Add missing asset types
export const overviewRouteNames: Record<AssetType, OverviewRouteNames> = {
  [AssetType.MobileCompactor]: {
    fleet: RouteNames.MobileCompactors,
    single: RouteNames.MobileCompactorById,
    createPreset: RouteNames.MobileCompactorCreatePreset,
    applyPreset: RouteNames.MobileCompactorApplyPreset,
  },
  [AssetType.StaticCompactor]: {
    fleet: RouteNames.StaticCompactors,
    single: RouteNames.StaticCompactorById,
    createPreset: RouteNames.StaticCompactorCreatePreset,
    applyPreset: RouteNames.StaticCompactorApplyPreset,
  },
  [AssetType.TippingVehicle]: {
    fleet: RouteNames.TippingVehicles,
    single: RouteNames.TippingVehicleById,
    fleetOperationalDashboard:
      RouteNames.TippingVehicleFleetOperationalDashboard,
    singleOperationalDashboard:
      RouteNames.TippingVehicleSingleOperationalDashboard,
    createPreset: RouteNames.TippingVehicleCreatePreset,
    applyPreset: RouteNames.TippingVehicleApplyPreset,
  },
  [AssetType.AlbaStaticCompactor]: {
    fleet: RouteNames.AlbaStaticCompactors,
    single: RouteNames.AlbaStaticCompactorById,
    createPreset: RouteNames.AlbaStaticCompactorCreatePreset,
    applyPreset: RouteNames.AlbaStaticCompactorApplyPreset,
  },
  [AssetType.GenericAsset]: {
    fleet: RouteNames.GenericAssets,
    single: RouteNames.GenericAssetById,
    createPreset: RouteNames.GenericAssetCreatePreset,
    applyPreset: RouteNames.GenericAssetApplyPreset,
  },
  [AssetType.RefuseCollectionVehicle]: {
    fleet: RouteNames.RefuseCollectionVehicles,
    single: RouteNames.RefuseCollectionVehicleById,
    createPreset: RouteNames.RefuseCollectionVehicleCreatePreset,
    applyPreset: RouteNames.RefuseCollectionVehicleApplyPreset,
  },
};

export const routeNameToAssetTypeMappping: Partial<
  Record<RouteNames, AssetType>
> = Object.fromEntries(
  Object.entries(overviewRouteNames).flatMap(([assetType, routeNames]) => [
    [routeNames.fleet, assetType],
    [routeNames.single, assetType],
    [routeNames.fleetOperationalDashboard, assetType],
    [routeNames.singleOperationalDashboard, assetType],
  ])
);

const assetsRoutes: RouteConfig = {
  path: '/assets',
  component: () =>
    import(/* webpackChunkName: "layout" */ '@/components/layout/Layout.vue'),
  redirect: '/assets/mobile-compactors',
  name: 'Assets',
  meta: {
    title: 'route.assets',
    icon: 'assets',
    alwaysShow: true,
    code: ALL_CLAIMS_CODES.AUTHRSC_MOD_ASSETS,
    context: ContextType.SelectedCustomer,
  },
  children: [
    {
      path: 'mobile-compactors',
      component: () =>
        import(/* webpackChunkName: "assets" */ '@/views/assets/Assets.vue'),
      name: RouteNames.MobileCompactors,
      meta: {
        title: 'route.mobileCompactors',
        code: ALL_CLAIMS_CODES.AUTHRSC_MOD_MCOMPACTOR,
        assetType: AssetType.MobileCompactor,
        systemFeature: [null, AssetType.MobileCompactor],
      },
    },
    {
      path: 'mobile-compactors/:id',
      component: () =>
        import(/* webpackChunkName: "assets" */ '@/views/assets/Asset.vue'),
      name: RouteNames.MobileCompactorById,
      props: true,
      meta: {
        title: 'route.mobileCompactors',
        thirdLevelMenu: true,
        code: ALL_CLAIMS_CODES.AUTHRSC_MOD_MCOMPACTOR,
        assetType: AssetType.MobileCompactor,
        hidden: true,
        systemFeature: [null, AssetType.MobileCompactor],
      },
    },
    {
      path: 'mobile-compactors/:id/preset/create',
      component: () =>
        import(
          /* webpackChunkName: "presetCreate" */ '@/views/preset/CreatePreset.vue'
        ),
      name: RouteNames.MobileCompactorCreatePreset,
      meta: {
        title: 'preset.create',
        code: ALL_CLAIMS_CODES.AUTHRSC_MOD_MCOMPACTOR,
        assetType: AssetType.MobileCompactor,
        hidden: true,
      },
    },
    {
      path: 'mobile-compactors/:id/preset/apply',
      component: () =>
        import(
          /* webpackChunkName: "presetCreate" */ '@/views/preset/ApplyPreset.vue'
        ),
      name: RouteNames.MobileCompactorApplyPreset,
      meta: {
        title: 'preset.apply',
        code: ALL_CLAIMS_CODES.AUTHRSC_MOD_MCOMPACTOR,
        assetType: AssetType.MobileCompactor,
        hidden: true,
      },
    },
    {
      path: 'static-compactors',
      component: () =>
        import(/* webpackChunkName: "assets" */ '@/views/assets/Assets.vue'),
      name: RouteNames.StaticCompactors,
      meta: {
        title: 'route.staticCompactors',
        code: ALL_CLAIMS_CODES.AUTHRSC_MOD_SCOMPACTOR,
        systemFeature: [null, AssetType.StaticCompactor],
        assetType: AssetType.StaticCompactor,
      },
    },
    {
      path: 'static-compactors/:id',
      component: () =>
        import(/* webpackChunkName: "assets" */ '@/views/assets/Asset.vue'),
      name: RouteNames.StaticCompactorById,
      props: true,
      meta: {
        title: 'route.staticCompactors',
        thirdLevelMenu: true,
        code: ALL_CLAIMS_CODES.AUTHRSC_MOD_SCOMPACTOR,
        assetType: AssetType.StaticCompactor,
        hidden: true,
        systemFeature: [null, AssetType.StaticCompactor],
      },
    },
    {
      path: 'static-compactors/:id/preset/create',
      component: () =>
        import(
          /* webpackChunkName: "presetCreate" */ '@/views/preset/CreatePreset.vue'
        ),
      name: RouteNames.StaticCompactorCreatePreset,
      meta: {
        title: 'preset.create',
        code: ALL_CLAIMS_CODES.AUTHRSC_MOD_SCOMPACTOR,
        assetType: AssetType.StaticCompactor,
        hidden: true,
      },
    },
    {
      path: 'static-compactors/:id/preset/apply',
      component: () =>
        import(
          /* webpackChunkName: "presetCreate" */ '@/views/preset/ApplyPreset.vue'
        ),
      name: RouteNames.StaticCompactorApplyPreset,
      meta: {
        title: 'preset.apply',
        code: ALL_CLAIMS_CODES.AUTHRSC_MOD_SCOMPACTOR,
        assetType: AssetType.StaticCompactor,
        hidden: true,
      },
    },
    {
      path: 'alba-static-compactors',
      component: () =>
        import(/* webpackChunkName: "assets" */ '@/views/assets/Assets.vue'),
      name: RouteNames.AlbaStaticCompactors,
      meta: {
        title: 'route.albaStaticCompactors',
        code: ALL_CLAIMS_CODES.AUTHRSC_MOD_ALBA_SCOMPACTOR,
        systemFeature: [null, AssetType.AlbaStaticCompactor],
        assetType: AssetType.AlbaStaticCompactor,
      },
    },
    {
      path: 'alba-static-compactors/:id',
      component: () =>
        import(/* webpackChunkName: "assets" */ '@/views/assets/Asset.vue'),
      name: RouteNames.AlbaStaticCompactorById,
      props: true,
      meta: {
        title: 'route.albaStaticCompactors',
        thirdLevelMenu: true,
        code: ALL_CLAIMS_CODES.AUTHRSC_MOD_ALBA_SCOMPACTOR,
        hidden: true,
        systemFeature: [null, AssetType.AlbaStaticCompactor],
        assetType: AssetType.AlbaStaticCompactor,
      },
    },
    {
      path: 'alba-static-compactors/:id/preset/create',
      component: () =>
        import(
          /* webpackChunkName: "presetCreate" */ '@/views/preset/CreatePreset.vue'
        ),
      name: RouteNames.AlbaStaticCompactorCreatePreset,
      meta: {
        title: 'preset.create',
        code: ALL_CLAIMS_CODES.AUTHRSC_MOD_ALBA_SCOMPACTOR,
        assetType: AssetType.AlbaStaticCompactor,
        hidden: true,
      },
    },

    {
      path: 'alba-static-compactors/:id/preset/apply',
      component: () =>
        import(
          /* webpackChunkName: "presetCreate" */ '@/views/preset/ApplyPreset.vue'
        ),
      name: RouteNames.AlbaStaticCompactorApplyPreset,
      meta: {
        title: 'preset.apply',
        code: ALL_CLAIMS_CODES.AUTHRSC_MOD_ALBA_SCOMPACTOR,
        assetType: AssetType.AlbaStaticCompactor,
        hidden: true,
      },
    },
    {
      path: 'tipping-vehicles',
      component: () =>
        import(/* webpackChunkName: "assets" */ '@/views/assets/Assets.vue'),
      name: RouteNames.TippingVehicles,
      meta: {
        title: 'route.tipping',
        code: ALL_CLAIMS_CODES.AUTHRSC_MOD_TIPPING_VEHICLES,
        systemFeature: [null, AssetType.TippingVehicle],
        assetType: AssetType.TippingVehicle,
      },
    },
    {
      path: 'tipping-vehicles/:id',
      component: () =>
        import(/* webpackChunkName: "assets" */ '@/views/assets/Asset.vue'),
      name: RouteNames.TippingVehicleById,
      props: true,
      meta: {
        title: 'route.tipping',
        thirdLevelMenu: true,
        code: ALL_CLAIMS_CODES.AUTHRSC_MOD_TIPPING_VEHICLES,
        hidden: true,
        systemFeature: [null, AssetType.TippingVehicle],
        assetType: AssetType.TippingVehicle,
      },
    },
    {
      path: 'tipping-vehicles/:id/preset/create',
      component: () =>
        import(
          /* webpackChunkName: "presetCreate" */ '@/views/preset/CreatePreset.vue'
        ),
      name: RouteNames.TippingVehicleCreatePreset,
      meta: {
        title: 'preset.create',
        code: ALL_CLAIMS_CODES.AUTHRSC_MOD_TIPPING_VEHICLES,
        assetType: AssetType.TippingVehicle,
        hidden: true,
      },
    },

    {
      path: 'tipping-vehicles/:id/preset/apply',
      component: () =>
        import(
          /* webpackChunkName: "presetCreate" */ '@/views/preset/ApplyPreset.vue'
        ),
      name: RouteNames.TippingVehicleApplyPreset,
      meta: {
        title: 'preset.apply',
        code: ALL_CLAIMS_CODES.AUTHRSC_MOD_TIPPING_VEHICLES,
        assetType: AssetType.TippingVehicle,
        hidden: true,
      },
    },
    {
      path: 'refuse-collection-vehicles',
      component: () =>
        import(/* webpackChunkName: "assets" */ '@/views/assets/Assets.vue'),
      name: RouteNames.RefuseCollectionVehicles,
      meta: {
        title: 'route.refuseCollectionVehicles',
        code: ALL_CLAIMS_CODES.AUTHRSC_MOD_RCV,
        systemFeature: [null, AssetType.RefuseCollectionVehicle],
        assetType: AssetType.RefuseCollectionVehicle,
      },
    },
    {
      path: 'refuse-collection-vehicles/:id',
      component: () =>
        import(/* webpackChunkName: "assets" */ '@/views/assets/Asset.vue'),
      name: RouteNames.RefuseCollectionVehicleById,
      props: true,
      meta: {
        title: 'route.refuseCollectionVehicles',
        thirdLevelMenu: true,
        code: ALL_CLAIMS_CODES.AUTHRSC_MOD_RCV,
        hidden: true,
        assetType: AssetType.RefuseCollectionVehicle,
        systemFeature: [null, AssetType.RefuseCollectionVehicle],
      },
    },
    {
      path: 'refuse-collection-vehicles/:id/preset/create',
      component: () =>
        import(
          /* webpackChunkName: "presetCreate" */ '@/views/preset/CreatePreset.vue'
        ),
      name: RouteNames.RefuseCollectionVehicleCreatePreset,
      meta: {
        title: 'preset.create',
        code: ALL_CLAIMS_CODES.AUTHRSC_MOD_RCV,
        assetType: AssetType.RefuseCollectionVehicle,
        hidden: true,
      },
    },

    {
      path: 'refuse-collection-vehicles/:id/preset/apply',
      component: () =>
        import(
          /* webpackChunkName: "presetCreate" */ '@/views/preset/ApplyPreset.vue'
        ),
      name: RouteNames.RefuseCollectionVehicleApplyPreset,
      meta: {
        title: 'preset.apply',
        code: ALL_CLAIMS_CODES.AUTHRSC_MOD_RCV,
        assetType: AssetType.RefuseCollectionVehicle,
        hidden: true,
      },
    },
    {
      path: 'generic-assets',
      component: () =>
        import(/* webpackChunkName: "assets" */ '@/views/assets/Assets.vue'),
      name: RouteNames.GenericAssets,
      meta: {
        title: 'route.genericAssets',
        roles: ['admin'],
        assetType: AssetType.GenericAsset,
        code: ALL_CLAIMS_CODES.AUTHRSC_MOD_GENERIC_ASSET,
        systemFeature: [null, AssetType.GenericAsset],
      },
    },
    {
      path: 'generic-assets/:id',
      component: () =>
        import(/* webpackChunkName: "assets" */ '@/views/assets/Asset.vue'),
      name: RouteNames.GenericAssetById,
      props: true,
      meta: {
        title: 'route.genericAssets',
        thirdLevelMenu: true,
        roles: ['admin'],
        code: ALL_CLAIMS_CODES.AUTHRSC_MOD_GENERIC_ASSET,
        hidden: true,
        assetType: AssetType.GenericAsset,
        systemFeature: [null, AssetType.GenericAsset],
      },
    },
    {
      path: 'generic-assets/:id/preset/create',
      component: () =>
        import(
          /* webpackChunkName: "presetCreate" */ '@/views/preset/CreatePreset.vue'
        ),
      name: RouteNames.GenericAssetCreatePreset,
      meta: {
        title: 'preset.create',
        code: ALL_CLAIMS_CODES.AUTHRSC_MOD_GENERIC_ASSET,
        assetType: AssetType.GenericAsset,
        hidden: true,
      },
    },

    {
      path: 'generic-assets/:id/preset/apply',
      component: () =>
        import(
          /* webpackChunkName: "presetCreate" */ '@/views/preset/ApplyPreset.vue'
        ),
      name: RouteNames.GenericAssetApplyPreset,
      meta: {
        title: 'preset.apply',
        code: ALL_CLAIMS_CODES.AUTHRSC_MOD_GENERIC_ASSET,
        assetType: AssetType.GenericAsset,
        hidden: true,
      },
    },
  ],
};

export default assetsRoutes;
