<script lang="ts" setup>
import { CommonResult } from '@/api/commonResult';
import {
  getPerformedAppointments,
  PerformedAppointmentsResponse,
  PerformedMaintenanceAppointments,
} from '@/api/maintenance';
import { useActiveContext } from '@/auth/context';
import WidgetCard from '@/components/layout/widget/WidgetCard.vue';
import HistoryTable from '@/components/table/HistoryTable.vue';
import { useAsync } from '@/composables/async';
import { useRoute, useRouter } from '@/composables/router';
import i18n from '@/lang';
import {
  FilterOperator,
  QueryParameter,
} from '@/model/queryParameters/QueryParameter';
import { LOCALDATE_FORMAT } from '@/utils/time';
import { SINGLE_ASSET_MAINTENANCE_HISTORY_COLS } from '@/utils/workData/maintenanceConf';
import ViewMaintenanceHistoryModal from '@/views/maintenance/components/ViewMaintenanceHistoryModal.vue';
import moment from 'moment';
import { computed, ref, unref } from 'vue';

const route = useRoute();
const router = useRouter();
const context = useActiveContext();
const modalValues = ref<PerformedMaintenanceAppointments | undefined>();

/**
 * Get performed appointments query parameters
 * @param id
 */
function getPerformedAppointmentsQueryParameters(id: string): QueryParameter {
  const selectedOrganization = unref(context).organization;

  const queryParameters: QueryParameter = {
    filters: [
      {
        name: 'assetId',
        operator: FilterOperator.IN,
        value: [id],
      },
    ],
    pagination: {
      page: 1,
      size: 20,
    },
    // First timezone is used for 'normal' users, second one is for designated users
    timezone:
      selectedOrganization?.timezone ?? unref(context).primaryOrgTimeZone,
  };

  return queryParameters;
}

/**
 * Prepare performed appointments
 */
const performedAppointmentsListAPIResponse = useAsync(
  computed(async (): Promise<CommonResult<PerformedAppointmentsResponse>> => {
    const id = unref(route).params.id;
    if (!!id) {
      const response = await getPerformedAppointments(
        getPerformedAppointmentsQueryParameters(id)
      );

      return response;
    }

    return {
      code: 404,
      message: '',
      data: { performedMaintenanceAppointments: [], total: 0, size: 0 },
    };
  })
);

/**
 * Filter maintenance list
 */
const filteredMaintenanceList = computed(
  (): PerformedMaintenanceAppointments[] | undefined => {
    const performedAppointmentsListAPIResponseValue = unref(
      performedAppointmentsListAPIResponse
    );
    const performedMaintenanceAppointmentsData =
      performedAppointmentsListAPIResponseValue.data?.data;
    const performedMaintenanceAppointments =
      performedMaintenanceAppointmentsData?.performedMaintenanceAppointments;
    if (
      performedMaintenanceAppointments &&
      performedMaintenanceAppointments.length === 0
    ) {
      return [];
    }

    return performedMaintenanceAppointments?.map((app) => ({
      ...app,
      appointmentTime: `${moment(app.appointmentTime).format(
        LOCALDATE_FORMAT
      )}${
        app.isAdjusted
          ? ` - ${i18n.tc('maintenance.maintenanceHistory.adjusted')}`
          : ''
      }`,
    }));
  }
);

/**
 * Handle show modal with row details
 * @param row
 */
function showDetailsModal(row: PerformedMaintenanceAppointments) {
  modalValues.value = row;
}

/**
 * Handle cancel modal
 */
function handleModalCancel(): void {
  modalValues.value = undefined;
}

/**
 * Handle redirect to route
 */
function redirectToRoute(): void {
  const currentAssetUUID = unref(route).params.id;
  let path = '/maintenance/history';
  if (currentAssetUUID) {
    path += `?assetId=${currentAssetUUID}`;
  }
  router.push(path);
}
</script>

<template>
  <WidgetCard
    :loading="performedAppointmentsListAPIResponse.loading"
    :action-title="$tc('assets.allHistory')"
    @click="redirectToRoute()"
  >
    <HistoryTable
      :tableData="filteredMaintenanceList || []"
      @showDetailsModal="showDetailsModal"
      :cols="SINGLE_ASSET_MAINTENANCE_HISTORY_COLS"
      class="single-asset-history-table"
    />
    <ViewMaintenanceHistoryModal
      v-if="!!modalValues"
      :isModalVisible="!!modalValues"
      :title="`${modalValues?.companyAssetId} - ${modalValues?.maintenanceItemName}`"
      :appointment="modalValues"
      @close="handleModalCancel"
    />
  </WidgetCard>
</template>

<style lang="scss" scoped>
.single-asset-history-table {
  margin-left: 20px;
  margin-right: 20px;

  :deep(colgroup col) {
    width: 180px !important;
  }

  :deep(.el-table__body colgroup col:last-child) {
    width: auto !important;
    min-width: 180px;
  }

  :deep(.el-table__header colgroup col:nth-last-child(2)) {
    width: auto !important;
    min-width: 180px;
  }

  :deep(.el-table__header colgroup col:last-child) {
    width: 0 !important;
  }
}
</style>
