<script lang="ts">
import Pagination from '@/components/pagination/Pagination.vue';
import { UserModule } from '@/store/modules/user';
import Sortable from 'sortablejs';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

@Component({
  name: 'SubscriptionPackagesTable',
  components: {
    Pagination,
  },
})
export default class extends Vue {
  @Prop() tableList!: any[];
  @Prop() treeProp!: any;
  @Prop() cols!: [];
  @Prop() total!: number;
  @Prop() packageTypes!: any;
  @Prop() addBtnName!: string;
  @Prop() addPagePath!: string;
  @Prop() viewPagePath!: string;
  @Prop() searchContent!: string;
  @Prop() commonSelectA!: string;
  @Prop() commonSelectB!: string;
  @Prop() commonSelectC!: string;
  @Prop({ default: false }) selectDateFlag!: boolean;
  @Prop({ default: true }) addBtn!: boolean;

  options = [
    {
      value: 'Option1',
      label: 'Option1',
    },
    {
      value: 'Option2',
      label: 'Option2',
    },
    {
      value: 'Option3',
      label: 'Option3',
    },
    {
      value: 'Option4',
      label: 'Option4',
    },
    {
      value: 'Option5',
      label: 'Option5',
    },
  ];

  value = '';
  vehicleQuery = '';

  list: any = [...this.tableList];
  dragCols: any = [...this.cols];
  colChange: any = [];

  pageSize = UserModule.gridPageSize;
  listQuery = {
    page: 1,
    limit: UserModule.gridPageSize,
  };

  /** Local variables */
  selectedPackage = '';
  searchInputValue: string = '';

  @Watch('tableList')
  onListChange(value: any) {
    this.list = [...this.tableList];
  }

  get activeCols() {
    this.colChange = this.cols.filter((item: any) => {
      return item.visible;
    });

    this.dragCols = [...this.colChange];
    return [...this.colChange];
  }

  handleRowClick(row: any, column: any, event: any) {
    if (this.viewPagePath) {
      this.$router.push(this.viewPagePath + row.id);
    }
  }

  /**
   * Verify if row has the roleCode then show icon
   * @param index
   * @param roleCode
   */
  handleModuleAccessIconRendering(index: number, roleCode: string) {
    return this.list[index].claims
      ? this.list[index].claims.some((e: any) => e.resource == roleCode)
      : false;
  }

  columnDrop() {
    const theader: any = document.querySelector('.el-table__header-wrapper tr');

    Sortable.create(theader, {
      animation: 180,
      delay: 0,
      onEnd: (evt: any) => {
        const oldItem = this.dragCols[evt.oldIndex];
        this.dragCols.splice(evt.oldIndex, 1);
        this.dragCols.splice(evt.newIndex, 0, oldItem);
      },
    });
  }

  mounted() {
    this.columnDrop();
  }

  addNewPackages() {
    this.$router.push('/subscription-packages/create-package');
  }

  @Watch('searchInputValue')
  handleSearchField(value: any) {
    if (!this.searchInputValue && !this.selectedPackage) {
      this.selectedPackage = '';
      this.$emit('search-filter-pagination', 1, this.pageSize, null, null);
    }
  }

  handleSearchFilterAndPagination(val: any) {
    let page = 1;
    if (val.page) page = val.page;
    let searchValue: any = this.searchInputValue;

    if (
      ['^', '\\'].includes(searchValue) ||
      searchValue.indexOf('^') > -1 ||
      searchValue.indexOf('\\') > -1
    ) {
      this.searchInputValue = '';
      return;
    }

    if (['&', '#'].includes(searchValue))
      searchValue = encodeURIComponent(searchValue);
    if (!searchValue && !this.selectedPackage)
      this.$emit('search-filter-pagination', page, this.pageSize, null, null);
    if (searchValue && !this.selectedPackage)
      this.$emit(
        'search-filter-pagination',
        page,
        this.pageSize,
        searchValue,
        null
      );
    if (!searchValue && this.selectedPackage)
      this.$emit(
        'search-filter-pagination',
        page,
        this.pageSize,
        null,
        this.selectedPackage
      );
    if (searchValue && this.selectedPackage)
      this.$emit(
        'search-filter-pagination',
        page,
        this.pageSize,
        searchValue,
        this.selectedPackage
      );
  }
}
</script>

<template>
  <div>
    <div class="header-container">
      <div class="header-section-title">
        <span class="header-section-content">{{
          $t('subscriptionPackages.packageList')
        }}</span>
      </div>

      <div class="right-header-section">
        <el-input
          class="search-input"
          id="searchinput"
          @input="handleSearchFilterAndPagination"
          :placeholder="$t('subscriptionPackages.searchPackages')"
          suffix-icon="el-icon-search"
          v-model="searchInputValue"
        />
        <div class="subscription-packages-dropdown">
          <el-select
            id="subscriptionpackagedropdowninput"
            v-model="selectedPackage"
            @change="handleSearchFilterAndPagination"
            v-bind:placeholder="$t('subscriptionPackages.packageType')"
            filterable
            clearable
          >
            <el-option
              v-for="item in packageTypes"
              :key="item.id"
              :label="$t(`subscriptionPackages.` + item.type)"
              :value="item.type"
            />
          </el-select>
        </div>
        <el-button
          class="add-new-maintenance-btn"
          id="addnewpackagesbtn"
          v-permission="['AUTHRSC_ACTION_SUBS_PKG_CREATE']"
          type="plain"
          @click="addNewPackages"
        >
          <i class="el-icon-plus common-icon" />{{
            $t('subscriptionPackages.addNewPackages')
          }}
        </el-button>
      </div>
    </div>

    <el-table
      :data="list"
      :tree-props="treeProp"
      row-key="id"
      :row-style="{ height: '40px' }"
      :cell-style="{ padding: '7px 0px' }"
      @row-click="handleRowClick"
    >
      <el-table-column
        v-for="(col, index) in activeCols"
        :key="`col_${index}`"
        :prop="dragCols[index].prop"
        min-width="180"
        show-overflow-tooltip
      >
        <template v-slot:header>
          <div class="d-flex jc-between">
            <span style="white-space: nowrap">{{ $t(col.label) }}</span>
          </div>
        </template>

        <template v-slot="slotProps" v-if="col.prop === 'type'">
          {{ $t(`subscriptionPackages.${slotProps.row.type}`) }}
        </template>
      </el-table-column>
    </el-table>

    <div class="d-flex ai-center" style="margin-top: 20px">
      <span class="total-statistics"
        >{{ $t('subscriptionPackages.totalNrOfPackages') }}:</span
      >
      <span class="total-statistics-value">{{ total }}</span>
    </div>

    <!-- v-show="total > 0" -->
    <Pagination
      v-show="
        /* @ts-expect-error TODO Wrong type */
        parseInt(total) > parseInt(pageSize)
      "
      :total="total"
      :page.sync="listQuery.page"
      :limit.sync="listQuery.limit"
      @pagination="handleSearchFilterAndPagination"
    />

    <el-popover ref="popover" placement="bottom" trigger="click">
      <el-checkbox
        v-for="(item, index) in cols"
        :key="index"
        :label="
          /* @ts-expect-error TODO Wrong type */
          $t(item.label)
        "
        v-model="
          /* @ts-expect-error TODO Wrong type */
          item.visible
        "
        :disabled="
          /* @ts-expect-error TODO Wrong type */
          item.required
        "
        style="display: block; color: #373e41 !important"
      ></el-checkbox>
    </el-popover>
  </div>
</template>

<style scoped>
.el-table tbody tr:hover > td {
  /* background-color: rgb(var(--Main), 0.6) !important; */
  background-color: var(--Main) !important;
  /* opacity: 0.5; */
  /* cursor: pointer; */
}

.el-table th {
  border-top: 1px solid #dddddd;
  border-bottom: 1px solid #dddddd !important;
}

.el-table td {
  border-bottom: 1px solid #dddddd !important;
}

.el-table {
  font-size: 14px !important;
  font-family: var(--fontRobotoRegular);
  line-height: 16px;
  color: #373e41 !important;
  opacity: 1;
}

.el-table thead {
  font-size: 14px !important;
  font-family: var(--fontRobotoMedium);
  line-height: 16px;
  color: #373e41 !important;
  opacity: 1;
}

.header-container {
  display: flex;
  justify-content: space-between;
  margin: 10px 20px 10px 20px;
}

.header-section-title {
  font-size: 1.428571rem;
  font-family: var(--fontRobotoMedium);
  display: flex;
  align-items: center;
}

.header-section-content {
  margin-left: 0;
}

.right-header-section {
  display: flex;
  justify-content: space-between;
}

.search-input :deep(.el-input__inner) {
  width: 250px;
  height: 35px;
  margin-right: 20px;
}

.subscription-packages-dropdown {
  width: 220px;
  margin-right: 20px;
}

.subscription-packages-dropdown :deep(.el-input__inner) {
  width: 200px;
  height: 35px;
}

.search-input :deep(.el-icon-search) {
  margin-right: 50px;
}
</style>

<style lang="scss" scoped>
.module-access {
  margin-left: 4px;
  margin-right: 4px;
}

.module-access-item {
  width: 17px;
  height: 17px;
}

.dropDownMenu-item {
  line-height: 25px;
  text-indent: 10px;
  cursor: pointer;
  padding-left: 10px;
  padding-top: 5px;
}

.dropDownMenu-item:hover {
  background-color: var(--Main);
}

.dm-font {
  font-size: 14px;
  font-family: $font-Roboto-Regular;
  line-height: 16px;
  color: #363636;
}

.el-input {
  width: 300px;
}

.el-select {
  margin-right: 40px;
}

.util-table-input :deep(.el-input__inner) {
  height: $inputHeight;
  // width: 300px;
  // width: 31vw;
  border: 1px solid #707070 !important;
  font-size: 1.285714rem;
  font-family: $font-Roboto-Regular;
  line-height: 1.357143rem;
  color: #373e41;
  opacity: 1;
}

.util-table-select :deep(.el-input__inner) {
  height: $inputHeight;
  width: 200px;
  // width: 31vw;
  border: 1px solid #707070 !important;
  font-size: 1.285714rem;
  font-family: $font-Roboto-Regular;
  line-height: 1.357143rem;
  color: #373e41;
  opacity: 1;
}
</style>
