<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import { Role, getRoles } from '@/api/roles';
import {
  USER_ROLE_MANAGEMENT,
  USER_ROLE_MANAGEMENT_SEARCH_OPTIONS,
} from '@/utils/workData/userMgmt';
import { UserModule } from '@/store/modules/user';
import SelectTableHeader from '@/components/table/SelectTableHeader.vue';
import PureTable from '@/components/table/PureTable.vue';
import { customFailedMessage } from '@/utils/prompt';

@Component({
  name: 'UserRoleMgmt',
  components: {
    'select-table-header': SelectTableHeader,
    'pure-table': PureTable,
  },
})
export default class extends Vue {
  /** Local variables */
  currentCol: string = '';
  list: Role[] = [];
  pageSize = UserModule.gridPageSize;
  userRoleManagementColumns = USER_ROLE_MANAGEMENT.map((item) => ({
    ...item,
    label: this.$t(item.label),
  }));
  totalUserRoles: number = 0;
  imgSrc: string = '';
  isUserRoleLoading: boolean = false;
  loadingText: string = this.$t(
    'userRoleModule.loadingUserRolesData'
  ) as string;
  userRoleManagementSearchOptions: any = USER_ROLE_MANAGEMENT_SEARCH_OPTIONS;
  currentPage: number = 1;
  sortAndOrderData: any = {
    sortBy: null,
    order: null,
  };
  searchParams: any = {
    reference: null,
    value: null,
  };

  created() {
    this.prepareDefaultInitialization();
  }

  prepareDefaultInitialization() {
    let finalUrlParamsForSearch: string = this.generateRequestUrlWithParams(
      this.currentPage,
      this.pageSize,
      null,
      null,
      null
    );
    this.fetchUserRolesData(finalUrlParamsForSearch);
  }

  /** Retrieve remotly user role by parameters */
  fetchUserRolesByParameters() {
    let finalUrlParamsForSearch: string = this.generateRequestUrlWithParams(
      this.currentPage,
      this.pageSize,
      this.searchParams,
      this.sortAndOrderData.sortBy,
      this.sortAndOrderData.order
    );
    this.fetchUserRolesData(finalUrlParamsForSearch);
  }

  /** Filter by sort event */
  fetchRolesBySortEvent(sortBy: any, order: any) {
    if (sortBy === 'claims') return; // need IoT implementation !
    order != ''
      ? (this.sortAndOrderData.sortBy = sortBy)
      : (this.sortAndOrderData.sortBy = null);
    order != ''
      ? (this.sortAndOrderData.order = order)
      : (this.sortAndOrderData.order = null);
    let finalUrlParamsForSearch: string = this.generateRequestUrlWithParams(
      this.currentPage,
      this.pageSize,
      this.searchParams,
      this.sortAndOrderData.sortBy,
      this.sortAndOrderData.order
    );
    this.fetchUserRolesData(finalUrlParamsForSearch);
  }

  /** Handle pagination */
  fetchRolesByPageSelection(page: number, pageSize: number) {
    this.currentPage = page;
    let finalUrlParamsForSearch: string = this.generateRequestUrlWithParams(
      page,
      pageSize,
      this.searchParams,
      this.sortAndOrderData.sortBy,
      this.sortAndOrderData.order
    );
    this.fetchUserRolesData(finalUrlParamsForSearch);
  }

  /** Generate request URL by multiple factors */
  generateRequestUrlWithParams(
    pageNumber: any,
    pageSize: number,
    searchParams: any,
    sortBy: any,
    order: any
  ) {
    let searchFieldName = searchParams ? searchParams.reference : null;
    let searchFieldValue = searchParams ? searchParams.value : null;
    let finalUrl = '';

    pageNumber ? (finalUrl += `?page=${pageNumber}`) : 1;
    pageNumber ? (finalUrl += `&size=${pageSize}`) : this.pageSize;

    if (searchFieldName && searchFieldValue) {
      finalUrl += `&searchFieldName=${searchFieldName}&searchFieldValues=${encodeURIComponent(
        searchFieldValue
      )}`;
    }

    if (sortBy && order) finalUrl += `&sortBy=${sortBy}&order=${order}`;

    return finalUrl;
  }

  async fetchUserRolesData(customUrl: any) {
    this.isUserRoleLoading = true;
    await getRoles(customUrl).then((res) => {
      if (!res) return;

      if (res.code === 200) {
        this.list = res.data.roles.map((item) => ({
          ...item,
          companyTypeTranslated: this.$t(item.companyType),
        }));
        this.totalUserRoles = res.data.total;
      }

      if (res.code === 500)
        customFailedMessage(this.$t('common.serverError').toString());

      this.isUserRoleLoading = false;
    });
  }
}
</script>

<template>
  <div class="app-container">
    <div
      class="d-flex jc-between ai-center flex-wrap"
      style="margin-bottom: 10px"
    >
      <div class="d-flex ai-center">
        <span id="user_role_home_title" class="total-statistics">{{
          $t('userRoleModule.userRoleManagement')
        }}</span>
        <div class="d-flex ai-center role-total-statistics">
          <span id="user_role_home_title" style="margin-left: 16px"
            >{{ $t('userRoleModule.totalRoleInfo') }}:</span
          >
          <span
            v-loading="isUserRoleLoading"
            id="user_role_home_value"
            style="margin-left: 5px"
          >
            {{ totalUserRoles }}
          </span>
        </div>
      </div>
      <el-button
        id="user_role_home_btn_create"
        v-permission="['AUTHRSC_ACTION_ROLE_CREATE']"
        type="plain"
        icon="el-icon-plus"
        @click="$router.push(`/user-role-mgmt/create-new-role`)"
        >{{ $t('userRoleModule.createNewUserRole') }}</el-button
      >
    </div>

    <div class="border-line"></div>

    <select-table-header
      style="margin-bottom: 20px"
      :searchFieldOptions="userRoleManagementSearchOptions"
      :cols="userRoleManagementColumns"
      :searchParams="searchParams"
      @search-event="fetchUserRolesByParameters"
    ></select-table-header>
    <el-row style="margin-top: 10px; width: 100%">
      <pure-table
        id="user_role_home_table"
        v-loading="isUserRoleLoading"
        :element-loading-text="loadingText"
        :tableList="list"
        :cols="userRoleManagementColumns"
        :total="totalUserRoles"
        :viewPagePath="'view-role'"
        @handle-page="fetchRolesByPageSelection"
        @handle-sort-change="fetchRolesBySortEvent"
      />
    </el-row>
  </div>
</template>

<style lang="scss" scoped>
.role-total-statistics {
  font-size: 16px;
  font-family: $font-Roboto-Regular;
  line-height: 19px;
  color: rgba(55, 62, 65, 0.6);
}
.border-line {
  margin: 0.857143rem -1.428571rem 20px;
  border-bottom: 1px solid #dddddd;
}

.total-statistics {
  font-size: 1.428571rem; //20px
  font-family: $font-Roboto-Medium;
  line-height: 2rem; //28px
  color: #373e41;
  opacity: 1;
}

.total-statistics-value {
  padding-left: 0.714286rem; //10px
  font-size: 1.714286rem; //24px
  font-family: $font-Roboto-Medium;
  line-height: 2rem; //28px
  color: #373e41;
  opacity: 1;
}
</style>
