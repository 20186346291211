<script lang="ts">
import {
  createCustomer,
  getCustomerById,
  updateCustomer,
} from '@/api/customer';
import {
  createTemplate,
  getTemplatesById,
  updateTemplate,
} from '@/api/dashboardConf';
import {
  createCompanySubscriptions,
  DEFAULT_SUBSCRIPTION_PACKAGES_REQUEST_SIZE,
  getCompanySubscriptions,
  getSubscriptionPackages,
  updateCompanySubscriptions,
} from '@/api/subscriptionPackages';
import { getTimezones, TimezoneResponse } from '@/api/timezone';
import { copyUserTemplateFromCompany } from '@/api/users';
import NewCard from '@/components/cusCard/NewCard.vue';
import CusFormItem from '@/components/form/CusFormItem.vue';
import GeneralInfo from '@/components/form/GeneralInfo.vue';
import { useHyvaRegionsQuery } from '@/query/hyvaRegion';
import { useServiceRegionsQuery } from '@/query/serviceRegion';
import { CustMgmtModule } from '@/store/modules/custMgmt';
import { PageModule } from '@/store/modules/page';
import { disabledPastDate } from '@/utils/date';
import {
  customFailedMessage,
  promptFailedBox,
  promptSuccessBox,
} from '@/utils/prompt';
import {
  ACTIVATION_STATUS,
  COMPANY_TYPE,
  COMPANY_TYPE_LIST,
  ERROR_CODE_LIST,
  FEATURE_VALUE_TYPE,
  INDUSTRY_LIST,
  PACKAGE_TYPE,
  THEME_LIST,
} from '@/utils/workData/lookuptable';
import { Component, Prop, Vue } from 'vue-property-decorator';
import CustomerSubscriptionPackages from './components/CustomerSubscriptionPackages.vue';
import DashboardTemplate from './components/DashboardTemplate.vue';

interface ERROR_TYPE {
  field: string;
  code: string;
  message: string;
}

interface SubscriptionValidation {
  result: boolean;
  errorMessage: string;
}

@Component({
  name: 'CustomerManagementEdit',
  components: {
    NewCard,
    CusFormItem,
    CustomerSubscriptionPackages,
    DashboardTemplate,
    GeneralInfo,
  },
})
export default class extends Vue {
  @Prop() customerId!: string;

  /** Local variables */
  activeName: string = 'generalinformationtab';
  fieldName: string = '';
  errMessage: string = '';
  companySubscriptions: any = [];
  subscriptionPackages: any = {
    companyType: [],
    assetType: [],
  };
  source: string = '';
  title: string = '';
  isActive: boolean = false;
  packageType: any = PACKAGE_TYPE;
  inputStyle: string = '';
  subscriptionTabIsLoading: boolean = false;
  editModeIsLoading: boolean = false;
  companyTypes = COMPANY_TYPE_LIST.filter(
    (ctl) => ctl.id !== COMPANY_TYPE.Hyva
  );
  industryTypes = INDUSTRY_LIST;
  timeZones: TimezoneResponse[] = [];
  errorCode = ERROR_CODE_LIST;
  loadingText: string = this.$t(
    'subscriptionPackages.creatingCustomer'
  ).toString();
  callTimesStartDate: number = 0;
  callTimesEndDate: number = 0;
  packageHasError = {
    status: false,
    message: '',
  };
  custForm = {
    name: '',
    companyType: COMPANY_TYPE.Customer,
    industry: 'INDTYP_CONSTRUCTION',
    contractNumber: '',
    contactPersonName: '',
    contactPersonEmail: '',
    contactPersonPhone: '',
    serviceRegion: '',
    hyvaRegion: '',
    primaryUserEmail: '',
    zipCode: '',
    street: '',
    district: '',
    province: '',
    city: '',
    setting: {
      theme: 0,
    },
    imageUrl: '',
    activationStatus: ACTIVATION_STATUS.Activated,
    note: '',
    timeZone: '',
  };
  primaryUserId: string = '';
  logoFile: any;
  errorInfos: ERROR_TYPE[] = [
    {
      code: '',
      field: '',
      message: '',
    },
  ];
  themes = THEME_LIST;
  companyTypesForInstalledCodeDisplay = [
    COMPANY_TYPE.Hyva,
    COMPANY_TYPE.BodyBuilder,
  ];
  showInfoLabel: boolean = true;
  featureValueType = FEATURE_VALUE_TYPE;

  serviceRegionsQuery!: ReturnType<typeof useServiceRegionsQuery>;
  hyvaRegionsQuery!: ReturnType<typeof useHyvaRegionsQuery>;

  /**
   * Check when component is created if is new customer of edit an existing customer
   */
  created(): void {
    this.serviceRegionsQuery = useServiceRegionsQuery();
    this.hyvaRegionsQuery = useHyvaRegionsQuery();

    getTimezones().then(({ data }) => (this.timeZones = data));
    this.retrieveSubscriptionPackages(
      1,
      DEFAULT_SUBSCRIPTION_PACKAGES_REQUEST_SIZE
    );
    this.showTabs();

    if (this.$route.name === 'CustomerManagementEdit' && this.customerId) {
      this.retrieveCompanySubscriptionsByCompanyId(this.customerId);
      this.source = 'Edit';
      this.loadCustomer();
      return;
    }

    if (CustMgmtModule.reStore) {
      CustMgmtModule.setReStore(false);
      Object.assign(this.custForm, CustMgmtModule.custForm);
      this.logoFile = CustMgmtModule.logoFile;
      if (this.logoFile) {
        this.fileSize = this.logoFile.size;
        this.fileName = this.logoFile.name;
        this.fileType = this.logoFile.type;
      }
      this.companySubscriptions = CustMgmtModule.companySubscriptions;

      CustMgmtModule.clearData();
    }
  }

  /** Trigger change for company subscription dropdown options by selected custoemr type */
  handleSubscriptionPackages(): void {
    this.retrieveSubscriptionPackages(
      1,
      DEFAULT_SUBSCRIPTION_PACKAGES_REQUEST_SIZE
    );
  }

  handleCompanyIndustryChange(): void {
    (this.$refs.dashboardTemplateRef as any).fetchAllTemplates(
      this.custForm.companyType,
      this.custForm.industry
    );
  }

  /**
   * Set current dashboard tamplate
   * @param val
   */
  setCurrentTemplateChanges(val: boolean): void {
    CustMgmtModule.setReStore(val);
    CustMgmtModule.setCustMgmt(this.custForm);
    CustMgmtModule.setComSubsp(this.companySubscriptions);
    CustMgmtModule.setLogoFile(this.logoFile);
  }

  /**
   * Retrieve from BE company subscriptions by company id
   * @param company id
   */
  async retrieveCompanySubscriptionsByCompanyId(
    companyId: string
  ): Promise<void> {
    try {
      this.subscriptionTabIsLoading = true;
      let res: any = await getCompanySubscriptions(companyId);
      if (res && res.code === 200 && res.data.length > 0) {
        /** If package of company type is not the first in array, move it to 0 position */
        if (
          res.data[0].subscriptionPackageType != this.packageType.CompanyType
        ) {
          let allCompanySubscriptions: any = [];
          let packageOfCompanyType = res.data.find((item: any) => {
            return (
              item.subscriptionPackageType === this.packageType.CompanyType
            );
          });

          var index = res.data.indexOf(packageOfCompanyType);
          allCompanySubscriptions = res.data.filter((item: any) => {
            return item.subscriptionPackageType != this.packageType.CompanyType;
          });
          res.data.splice(index, 1);
          allCompanySubscriptions.unshift(packageOfCompanyType);
          this.companySubscriptions = allCompanySubscriptions;
        } else {
          this.companySubscriptions = res.data;
        }
      }
    } catch (error) {
      console.log(error);
      customFailedMessage(
        this.$t('subscriptionPackages.errorWithFetchingData').toString()
      );
    } finally {
      this.subscriptionTabIsLoading = false;
    }
  }

  /** Clean up subs packages */
  cleanUpSubscriptionPackagesData(): void {
    this.companySubscriptions = [];
    this.subscriptionPackages = {
      companyType: [],
      assetType: [],
    };
  }

  /**
   * Retrieve from BE subscription packages
   * @param page
   * @param size
   */
  async retrieveSubscriptionPackages(
    page: number,
    size: number
  ): Promise<void> {
    try {
      this.subscriptionTabIsLoading = true;
      this.cleanUpSubscriptionPackagesData();
      var res = await getSubscriptionPackages(page, size);
      res.subscriptionPackages.forEach((item: any) => {
        if (
          item.type === this.packageType.CompanyType &&
          item.companyType === this.custForm.companyType
        ) {
          this.subscriptionPackages.companyType.push(item);
        }

        if (item.type === this.packageType.AssetType)
          this.subscriptionPackages.assetType.push(item);
      });
      this.subscriptionTabIsLoading = false;
      //   this.isSubscriptionPackagesTabActive = true
    } catch (error) {
      console.log(error);
    } finally {
      this.subscriptionTabIsLoading = false;
    }
  }

  /**
   * Handle Tab click
   */
  handleTabClick(): void {
    let activeTab = this.$route.query.activeName;
    let partPath = this.customerId ? `edit/${this.customerId}` : 'create';
    switch (this.activeName) {
      case 'generalinformationtab':
        if (activeTab != 'generalinformationtab')
          this.$router.push(
            `/customer-management/${partPath}?activeName=generalinformationtab`
          );
        break;
      case 'subscriptionpackagestab':
        if (activeTab != 'subscriptionpackagestab')
          this.$router.push(
            `/customer-management/${partPath}?activeName=subscriptionpackagestab`
          );
        break;
      case 'dashboardTemplateTab':
        if (activeTab != 'dashboardTemplateTab')
          this.$router.push(
            `/customer-management/${partPath}?activeName=dashboardTemplateTab`
          );
        break;
    }
  }

  /**
   * Show a specific tab
   */
  showTabs(): void {
    if (this.$route.query.activeName) {
      this.activeName = this.$route.query.activeName as string;
    } else {
      this.activeName = 'generalinformationtab';
    }
  }

  /**
   * Picker option limit for contract start date input
   */
  pickerOptions: any = {
    disabledDate: disabledPastDate,
  };

  /**
   * Validate uploaded logo
   * @param rule
   * @param value
   */
  validateLogo = (rule: any, value: any, callback: any) => {
    if (value === '') {
      callback(new Error(`${this.$t('customerModule.tipUploadLogo')}`));
    } else {
      callback();
    }
  };

  /**
   * Validate uploaded logo for format and size
   */
  validateLogoFormatAndSize() {
    const imageFormats = ['image/jpeg', 'image/jpg', 'image/png'];
    const isImgFormatValid = imageFormats.includes(this.fileType);
    const isImgSizeValid = this.fileSize / 1024 / 1024 < 1;
    if (!isImgFormatValid) {
      this.errLogoInfo = this.$t(
        'customerModule.LogoFormatUnsupport'
      ) as string;
      return false;
    } else {
      this.errLogoInfo = '';
    }
    if (!isImgSizeValid) {
      this.errLogoInfo = this.$t('customerModule.LogoSizeExceed') as string;
      return false;
    } else {
      this.errLogoInfo = '';
    }
    return true;
  }

  /**
   * Get inline message
   */
  get inlineMsg(): boolean {
    return false;
  }

  /**
   * Rules defined for customer forms input
   */
  get rules() {
    const tmpRules = {
      name: [
        {
          required: true,
          message: this.$t('customerModule.tipInputCompanyName'),
          trigger: 'change',
        },
      ],
      companyType: [
        {
          required: true,
          message: this.$t('customerModule.tipSelectCompanyType'),
          trigger: 'change',
        },
      ],
      industry: [
        {
          required: true,
          message: this.$t('customerModule.tipSelectIndustry'),
          trigger: 'change',
        },
      ],
      contractNumber: [
        {
          required: true,
          message: this.$t('customerModule.tipInputContractNumber'),
          trigger: 'change',
        },
      ],
      contactPersonEmail: [
        {
          type: 'email',
          message: this.$t('customerModule.tipInputCorrectEmail'),
          trigger: ['blur', 'change'],
        },
      ],
      contactPersonPhone: [
        {
          pattern: /^[, 0-9-#+]{1,20}$/,
          message: this.$t('customerModule.tipInputCorrectPhone'),
        },
      ],
      primaryUserEmail: [
        { required: true, message: this.$t('customerModule.tipPrimaryEmail') },
        {
          type: 'email',
          message: this.$t('customerModule.tipInputCorrectEmail'),
          trigger: ['blur', 'change'],
        },
      ],
      imageUrl: [{ validator: this.validateLogo, trigger: 'change' }],
      timeZone: [
        {
          required: true,
          message: this.$t('customerModule.tipSelectTimeZone'),
          trigger: 'change',
        },
      ],
      serviceRegion: [
        {
          required: true,
          message: this.$t('customerModule.inputServiceRegion'),
          trigger: 'blur',
        },
      ],
    };
    return tmpRules;
  }

  fileSize: number = 0;
  fileName: string = '';
  fileType: string = '';
  errLogoInfo: string = '';

  get themeChanged() {
    this.themes.forEach((item: any) => {
      if (item.id === this.custForm.setting.theme) {
        item.name = 'btn-selected';
      } else {
        item.name = 'btn-default';
      }
    });

    return this.themes;
  }

  /**
   * Get customer name error message
   */
  get errNameInfo() {
    let errInfo: string = '';

    if (!this.errorInfos) return;

    if (
      this.errorInfos.find((item) => item.field === this.errorCode.name)
        ?.code === 'ApiErrorFieldDuplicate'
    ) {
      errInfo = `${this.$t('customerModule.duplicateName')}`;
    }
    // return this.errorInfos.find(item => item.field === this.errorCode.name)?.message
    return errInfo;
  }

  /**
   * Get duplicate contract number error message
   */
  get errDuplicateCtrNr() {
    let errInfo: string = '';

    if (!this.errorInfos) return;

    if (
      this.errorInfos.find(
        (item) => item.field === this.errorCode.contractNumber
      )?.code === 'ApiErrorFieldDuplicate'
    ) {
      errInfo = `${this.$t('customerModule.duplicateContractNumber')}`;
    }
    return errInfo;
  }

  /**
   * Get primarry account error message
   */
  get errPrimaryAccountInfo() {
    let errInfo: string = '';

    if (!this.errorInfos) return;

    if (
      this.errorInfos.find((item) => item.field === this.errorCode.email)
        ?.code === 'ApiErrorFieldDuplicate'
    ) {
      errInfo = `${this.$t('customerModule.primaryAccountEmailIsAlreadyUsed')}`;
    }

    // return this.errorInfos.find(item => item.field === this.errorCode.email)?.message
    return errInfo;
  }

  /**
   * Show checked
   * @param key
   */
  showChecked(key: number): void {
    this.themes.forEach((item: any) => {
      if (item.id === key) {
        item.name = 'btn-selected';
      } else {
        item.name = 'btn-default';
      }
    });
    this.custForm.setting.theme = key;
  }

  onServiceRegionChange(selectedValue: string) {
    const selectedRegion = this.serviceRegionsQuery.data.value?.find(
      (servReg) => servReg.serviceRegionCode === selectedValue
    );

    if (selectedRegion === undefined) {
      this.custForm.hyvaRegion = '';

      return;
    }

    this.custForm.hyvaRegion = selectedRegion.hyvaRegionCode;
  }

  /**
   * Validate before avatar upload
   * @param file
   */
  beforeAvatarUpload(file: any): void {
    this.logoFile = file;
    this.custForm.imageUrl = URL.createObjectURL(file);
    this.fileSize = file.size;
    this.fileName = file.name;
    this.fileType = file.type;
    this.showInfoLabel = false;

    (this.$refs.custForm as any).validateField('imageUrl');

    this.validateLogoFormatAndSize();
  }

  /**
   * Load customer
   */
  private async loadCustomer(): Promise<void> {
    this.editModeIsLoading = true;
    const res = await getCustomerById(this.customerId);
    if (res.code && res.code === 200) {
      Object.assign(this.custForm, res.data);
      PageModule.setTitle(this.custForm.name);
      this.custForm.imageUrl = `${Vue.prototype.$envConfig.VUE_APP_BASE_API}/companies/${this.customerId}/logo`;
      // set default value when do editing because the file type and
      // file size has no value from backend API
      // as to let validation pass when edit, it's necessary to do initilization
      this.fileType = 'image/jpeg';
      this.fileSize = 0.5 * 1024 * 1024;
      this.logoFile = null;
    }
    this.editModeIsLoading = false;
  }

  /**
   * Main entry for handleling create or update customer (+subscription packages)
   */
  async handleCustomerOperation(): Promise<void> {
    try {
      let templateId = (
        this.$refs.dashboardTemplateRef as any
      ).getCurrentTemplateId() as string;

      if (!templateId || templateId.length == 0) {
        customFailedMessage(
          this.$t('customerModule.noTemplateSelected') as string
        );
        return;
      }

      let packageBackendPayoad = this.preparePayload(this.companySubscriptions);

      if (this.packageHasError.status) {
        customFailedMessage(this.packageHasError.message);
        return;
      }

      this.loadingText = this.$t(
        'subscriptionPackages.creatingCustomer'
      ).toString();
      let validation: SubscriptionValidation =
        this.areSubscriptionPackagesValid();

      if (validation.result) {
        customFailedMessage(validation.errorMessage);
        return;
      }

      var formData = new FormData();
      formData.append('logo', this.logoFile);
      formData.append(
        'company',
        new Blob([JSON.stringify(this.custForm)], {
          type: 'application/json',
        })
      );

      (this.$refs.custForm as any).validate(async (valid: any) => {
        valid = valid && this.validateLogoFormatAndSize();
        if (!valid) {
          customFailedMessage(
            this.$tc('subscriptionPackages.generalInfoErrors')
          );
          return;
        }

        this.$nextTick(() => {
          this.editModeIsLoading = true;
        });
        if (!this.customerId) {
          await createCustomer(formData).then((res) => {
            if (!res) {
              promptFailedBox(
                this.$t('customerModule.customerCreation') as string
              );
              return;
            }

            if (res.code === 200) {
              let companyId = res.data.id;
              this.primaryUserId = res.data.primaryUserId;
              this.createNewCompanySubscription(
                companyId,
                packageBackendPayoad
              );

              let templateId = (
                this.$refs.dashboardTemplateRef as any
              ).getCurrentTemplateId();
              let templateForm: any = {};

              getTemplatesById(templateId).then((resTemplate) => {
                if (resTemplate.code === 200 && resTemplate.data) {
                  templateForm = resTemplate.data;
                  templateForm.companyId = companyId;
                  // templateForm.id = null;

                  if (CustMgmtModule.isSave) {
                    //If it have been saved ( store in DB)
                    this.updateTemplate(templateId, templateForm);
                  } else {
                    templateForm.masterTemplateId = CustMgmtModule.templateId;
                    this.createTemplate(templateForm);
                  }
                }
                if (resTemplate.code === 400) {
                  promptFailedBox(
                    this.$t('dashboardConf.createTemplate') as string
                  );
                }
              });
              return;
            } else {
              /* @ts-expect-error TODO Wrong type */
              this.errorInfos = res.data.errors;
              customFailedMessage(
                this.$tc('subscriptionPackages.generalInfoErrors')
              );
              this.editModeIsLoading = false;
            }
          });
          return;
        }

        this.loadingText = this.$t(
          'subscriptionPackages.updatingCustomer'
        ).toString();
        await updateCustomer(this.customerId, formData).then((res) => {
          if (!res) {
            promptFailedBox(this.$t('customerModule.customerUpdate') as string);
            return;
          }

          if (res.code === 200) {
            let companyId = res.data.id;
            this.updateCompanySubscription(companyId, packageBackendPayoad);
            this.errorInfos = [];

            return;
          }

          if (res.code === 400) {
            this.errorInfos = res.data.errors;
            customFailedMessage(
              this.$tc('subscriptionPackages.generalInfoErrors')
            );
            this.editModeIsLoading = false;
          }
        });
      });
    } catch (error) {
      console.log(error);
    } finally {
    }
  }

  /**
   * Create company subscription triggered in resolved Promise of created customer
   * @param companyId
   * @param packageBackendPayoad
   */
  async createNewCompanySubscription(
    companyId: string,
    packageBackendPayoad: any
  ): Promise<void> {
    await createCompanySubscriptions(companyId, packageBackendPayoad).then(
      (res: any) => {
        if (!res) {
          promptFailedBox(this.$t('customerModule.customerCreation') as string);
          return;
        }

        if (res.code === 200) {
          this.$router.push({ name: 'Home' });
          promptSuccessBox(this.$t('customerModule.customerCreated') as string);
          this.editModeIsLoading = false;
          this.errorInfos = [];
          return;
        }

        promptFailedBox(this.$t('customerModule.customerCreation') as string);
        this.errorInfos = res.data.errors;
        this.editModeIsLoading = false;
      }
    );
  }

  /**
   * Copy user template from company
   * @param userId
   */
  async copyUserTemplateFromCompany(userId: string): Promise<void> {
    await copyUserTemplateFromCompany(userId).then((res) => {
      if (res.code === 400) {
        promptFailedBox(this.$t('dashboardConf.templateNotExist') as string);
      }
    });
  }

  /**
   * Create dashboard template
   * @param templateFrom
   */
  async createTemplate(templateFrom: any): Promise<void> {
    await createTemplate(templateFrom).then((res: any) => {
      if (res.code === 200) {
        CustMgmtModule.setIsSave(false);
        this.copyUserTemplateFromCompany(this.primaryUserId);
      } else if (res.code === 400) {
        promptFailedBox(this.$t('dashboardConf.createTemplate') as string);
      }
    });
  }

  /**
   * Update dashboard template
   * @param templateId
   * @param value
   */
  async updateTemplate(templateId: string, value: any): Promise<void> {
    await updateTemplate(templateId, value).then((res: any) => {
      if (res.code === 200) {
        CustMgmtModule.setIsSave(false);
        this.copyUserTemplateFromCompany(this.primaryUserId);
      } else if (res.code === 400) {
        promptFailedBox(this.$t('dashboardConf.createTemplate') as string);
      }
    });
  }

  /**
   * Update company subscription triggered in resolved Promise of updated customer
   * @param companyId
   * @param packageBackendPayoad
   */
  async updateCompanySubscription(
    companyId: string,
    packageBackendPayoad: any
  ): Promise<void> {
    await updateCompanySubscriptions(companyId, packageBackendPayoad).then(
      (res: any) => {
        if (!res) {
          customFailedMessage(
            this.$t(
              'subscriptionPackages.errorFetchingDataForSubscriptionPackages'
            ) as string
          );
          return;
        }

        if (res.code === 200) {
          this.$router.push({ name: 'Home' });
          promptSuccessBox(this.$t('customerModule.customerUpdated') as string);
          this.editModeIsLoading = false;
          this.errorInfos = [];
          return;
        }

        customFailedMessage(
          this.$t(
            'subscriptionPackages.subscriptionPackagesNotUpdated'
          ) as string
        );
        this.editModeIsLoading = false;
        this.errorInfos = res.data.errors;
      }
    );
  }

  /**
   * Prepare backend payload for subscription packages
   * @param data
   */
  preparePayload(data: any) {
    let payload: any = [];
    let finalFeatures: any = [];
    this.packageHasError = {
      status: false,
      message: '',
    };

    if (data.length === 0) {
      this.packageHasError = {
        status: true,
        message: this.$t(
          'subscriptionPackages.atLeastOnePackageShouldBeCreated'
        ).toString(),
      };
      return;
    }

    data.forEach((item: any) => {
      finalFeatures = [];
      item.systemFeatures.forEach((feature: any) => {
        if (
          feature.value &&
          this.featureValueType.FvtDuration !== feature.valueType
        ) {
          finalFeatures.push({
            id: feature.id,
            value: feature.value,
          });
        }

        if (
          this.featureValueType.FvtDuration === feature.valueType &&
          feature.value &&
          feature.value.length > 0 &&
          feature.unit &&
          feature.unit.length > 0
        ) {
          finalFeatures.push({
            id: feature.id,
            value: feature.value ? feature.value.trim() : '',
            unit: feature.unit ? feature.unit.trim() : '',
          });
        }
      });

      payload.push({
        subscriptionPackageId: item.subscriptionPackageId,
        systemFeatures: finalFeatures ? finalFeatures : [],
      });
    });

    payload.forEach((item: any) => {
      if (item.systemFeatures.length === 0) {
        this.packageHasError = {
          status: true,
          message: this.$t(
            'subscriptionPackages.oneFeatureShouldHaveValidValue'
          ).toString(),
        };
        return;
      }
    });

    return payload;
  }

  /**
   * Check if subscription are valid by a types of rules
   */
  areSubscriptionPackagesValid(): SubscriptionValidation {
    let featureValuesIncomplete: any = [];
    let errorMessage: string = '';

    if (this.companySubscriptions.length === 0) {
      errorMessage = this.$t(
        'subscriptionPackages.subscriptionPackagesErrors'
      ).toString();
      featureValuesIncomplete.push(true);
      return {
        result: featureValuesIncomplete.includes(true),
        errorMessage: errorMessage,
      };
    }

    if (this.companySubscriptions.length > 0) {
      this.companySubscriptions.forEach((item: any) => {
        if (item.subscriptionPackageType == this.packageType.AssetType) {
          item.systemFeatures.forEach((feature: any) => {
            if (
              feature.hasOwnProperty('unit') &&
              ((!feature.unit && feature.value) ||
                (feature.unit && !feature.value) ||
                (feature.unit.length === 1 && feature.unit && feature.value))
            ) {
              // customFailedMessage(this.$t('subscriptionPackages.timeDurationIncorrectFormat').toString())
              errorMessage = this.$t(
                'subscriptionPackages.timeDurationIncorrectFormat'
              ).toString();
              featureValuesIncomplete.push(true);
            }
          });
        }

        if (!item.subscriptionPackageId) {
          featureValuesIncomplete.push(true);
          errorMessage = this.$t(
            'subscriptionPackages.subscriptionPackagesErrors'
          ).toString();
          return {
            result: featureValuesIncomplete.includes(true),
            errorMessage: errorMessage,
          };
        }

        if (item.systemFeatures.length === 0) {
          featureValuesIncomplete.push(true);
          errorMessage = this.$t(
            'subscriptionPackages.subscriptionPackagesErrors'
          ).toString();
          return {
            result: featureValuesIncomplete.includes(true),
            errorMessage: errorMessage,
          };
        }
      });
    }

    return {
      result: featureValuesIncomplete.includes(true),
      errorMessage: errorMessage,
    };
  }
}
</script>

<template>
  <NewCard
    id="add_new_cust_new_card"
    v-loading="editModeIsLoading"
    :backPath="$router.resolve({ name: 'CustomerManagement' }).route.path"
    :element-loading-text="loadingText"
    :createdOrEdit="false"
    :isNewEntity="$route.name === 'CustomerManagementCreate'"
    :isUpdatable="$route.name === 'CustomerManagementEdit'"
    @update-event="handleCustomerOperation"
    @create-event="handleCustomerOperation"
  >
    <el-tabs
      id="customermanagementtabcontainer"
      class="tab-container"
      v-model="activeName"
      @tab-click="handleTabClick"
      style="padding: 0 20px"
    >
      <el-tab-pane
        id="generalinformationtab"
        name="generalinformationtab"
        :label="$t('userModule.generalInfo')"
      >
        <el-form
          id="customformgrid"
          :model="custForm"
          ref="custForm"
          :rules="rules"
          :inline-message="inlineMsg"
          style="margin-left: 80px"
        >
          <CusFormItem :title="'customerModule.name'" :errContent="errNameInfo">
            <el-form-item prop="name">
              <el-input
                id="customernameinput"
                v-model="custForm.name"
                v-bind:placeholder="$t('customerModule.inputCompanyName')"
              ></el-input>
            </el-form-item>
          </CusFormItem>
          <CusFormItem :title="'customerModule.customerType'">
            <el-form-item prop="companyType">
              <el-select
                id="customertypedropdown"
                v-model="custForm.companyType"
                v-bind:placeholder="$t('customerModule.pleaseSelect')"
                :disabled="source === 'Edit'"
                @change="
                  handleCompanyIndustryChange();
                  handleSubscriptionPackages();
                "
              >
                <el-option
                  v-for="item in companyTypes"
                  :key="item.id"
                  :label="$t(item.id)"
                  :value="item.id"
                />
              </el-select>
            </el-form-item>
          </CusFormItem>

          <CusFormItem :title="'customerModule.companyIndustry'">
            <el-form-item prop="industry">
              <el-select
                id="companyindustrydropdown"
                v-model="custForm.industry"
                v-bind:placeholder="$t('customerModule.pleaseSelect')"
                @change="handleCompanyIndustryChange"
              >
                <el-option
                  v-for="item in industryTypes"
                  :key="item.id"
                  :label="$t(item.id)"
                  :value="item.id"
                />
              </el-select>
            </el-form-item>
          </CusFormItem>

          <CusFormItem
            :title="'customerModule.contractNumber'"
            :errContent="errDuplicateCtrNr"
          >
            <el-form-item prop="contractNumber">
              <el-input
                id="contractnumberinput"
                v-model="custForm.contractNumber"
                v-bind:placeholder="$t('customerModule.inputContractNumber')"
              ></el-input>
            </el-form-item>
          </CusFormItem>
          <CusFormItem
            :title="'customerModule.contactPersonName'"
            :required="false"
          >
            <el-form-item>
              <el-input
                id="contactpersonnameinput"
                v-model="custForm.contactPersonName"
                v-bind:placeholder="$t('customerModule.inputContactPersonName')"
              ></el-input>
            </el-form-item>
          </CusFormItem>
          <CusFormItem
            :title="'customerModule.contactPersonEmail'"
            :required="false"
          >
            <el-form-item prop="contactPersonEmail">
              <el-input
                id="contactpersonemail"
                v-model="custForm.contactPersonEmail"
                v-bind:placeholder="
                  $t('customerModule.inputContactPersonEmail')
                "
              ></el-input>
            </el-form-item>
          </CusFormItem>
          <CusFormItem
            :title="'customerModule.contactPersonMobile'"
            :required="false"
          >
            <el-form-item prop="contactPersonPhone">
              <el-input
                id="contactpersonphone"
                v-model="custForm.contactPersonPhone"
                v-bind:placeholder="
                  $t('customerModule.inputContactPersonMobile')
                "
              ></el-input>
            </el-form-item>
          </CusFormItem>
          <CusFormItem
            :title="'customerModule.primaryAccount'"
            :errContent="errPrimaryAccountInfo"
          >
            <el-form-item prop="primaryUserEmail">
              <el-input
                id="primaryuseremailinput"
                v-model="custForm.primaryUserEmail"
                :disabled="customerId ? true : false"
                v-bind:placeholder="$t('customerModule.inputPrimaryAccount')"
              ></el-input>
            </el-form-item>
          </CusFormItem>
          <GeneralInfo
            v-if="
              customerId &&
              companyTypesForInstalledCodeDisplay.includes(custForm.companyType)
            "
            id="customerinstallercodefield"
            :name="$t('customerModule.installerCode')"
            :value="
              /* @ts-expect-error TODO Wrong type */
              custForm.installerCode
            "
          />
          <CusFormItem
            :title="$t('customerModule.logo')"
            :errContent="errLogoInfo"
          >
            <el-form-item prop="imageUrl">
              <div class="d-flex ai-center" style="margin-right: 115px">
                <el-upload
                  id="profilepictureinput"
                  class="avatar-uploader"
                  action=""
                  :show-file-list="false"
                  :before-upload="beforeAvatarUpload"
                >
                  <img
                    id="profilepicturelogo"
                    v-if="custForm.imageUrl"
                    :src="custForm.imageUrl"
                    class="avatar"
                  />
                  <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                </el-upload>
                <div style="margin-left: 20px; width: 250px">
                  <div id="profilepicturedescription" v-if="showInfoLabel">
                    <div>{{ $t('customerModule.logoDescription') }}</div>
                    <div style="margin-top: -10px">
                      {{ $t('customerModule.logoSize') }}
                    </div>
                  </div>
                  <div id="profilepicturesize" v-if="!showInfoLabel">
                    <div>{{ fileName }}</div>
                    <div style="margin-top: -10px">
                      {{ (fileSize / 1024).toFixed(2) }}KB
                    </div>
                  </div>
                </div>
              </div>
            </el-form-item>
          </CusFormItem>
          <CusFormItem :title="'customerModule.themeColours'">
            <el-form-item>
              <div id="themecolorgroup" class="d-flex ai-center">
                <div
                  class="d-flex ai-center"
                  style="width: 60px; height: 60px"
                  v-for="(item, index) in themeChanged"
                  :key="index"
                >
                  <el-button
                    :id="'customthemecolor' + index"
                    :class="item.name"
                    :style="item.value"
                    @click="showChecked(item.id)"
                  />
                </div>
              </div>
            </el-form-item>
          </CusFormItem>
          <CusFormItem :title="'common.serviceRegion'" :required="true">
            <el-form-item prop="serviceRegion">
              <el-select
                v-loading="serviceRegionsQuery.isLoading.value"
                v-model="custForm.serviceRegion"
                v-bind:placeholder="$t('customerModule.pleaseSelect')"
                filterable
                @change="onServiceRegionChange"
              >
                <el-option
                  v-for="item in serviceRegionsQuery.data.value"
                  :key="item.serviceRegionCode"
                  :label="$t(item.serviceRegionCode)"
                  :value="item.serviceRegionCode"
                />
              </el-select>
            </el-form-item>
          </CusFormItem>
          <CusFormItem
            v-loading="hyvaRegionsQuery.isLoading.value"
            :title="'common.hyvaRegion'"
            :required="false"
          >
            <el-form-item prop="hyvaRegion">
              <el-select
                v-model="custForm.hyvaRegion"
                v-bind:placeholder="''"
                filterable
                disabled
              >
                <el-option
                  v-for="item in hyvaRegionsQuery.data.value"
                  :key="item.code"
                  :label="$t(item.code)"
                  :value="item.code"
                />
              </el-select>
            </el-form-item>
          </CusFormItem>
          <CusFormItem :title="'customerModule.province'" :required="false">
            <el-form-item>
              <el-input
                id="provinceinput"
                v-model="custForm.province"
                v-bind:placeholder="$t('customerModule.inputProvince')"
              ></el-input>
            </el-form-item>
          </CusFormItem>
          <CusFormItem :title="'customerModule.city'" :required="false">
            <el-form-item>
              <el-input
                id="cityinput"
                v-model="custForm.city"
                v-bind:placeholder="$t('customerModule.inputCity')"
              ></el-input>
            </el-form-item>
          </CusFormItem>
          <CusFormItem :title="'customerModule.district'" :required="false">
            <el-form-item>
              <el-input
                id="districtinput"
                v-model="custForm.district"
                v-bind:placeholder="$t('customerModule.inputDistrict')"
              ></el-input>
            </el-form-item>
          </CusFormItem>
          <CusFormItem :title="'customerModule.street'" :required="false">
            <el-form-item>
              <el-input
                id="cust_create_input_street"
                v-model="custForm.street"
                v-bind:placeholder="$t('customerModule.inputStreet')"
              ></el-input>
            </el-form-item>
          </CusFormItem>
          <CusFormItem :title="'customerModule.postCode'" :required="false">
            <el-form-item>
              <el-input
                id="postcodeinput"
                v-model="custForm.zipCode"
                v-bind:placeholder="$t('customerModule.inputPostCode')"
              ></el-input>
            </el-form-item>
          </CusFormItem>
          <CusFormItem :title="'customerModule.note'" :required="false">
            <el-form-item>
              <el-input
                id="noteinput"
                v-model="custForm.note"
                v-bind:placeholder="$t('customerModule.inputNote')"
              ></el-input>
            </el-form-item>
          </CusFormItem>
          <CusFormItem :title="'customerModule.timeZone'">
            <el-form-item prop="timeZone">
              <el-select
                id="timezonedropdown"
                v-model="custForm.timeZone"
                v-bind:placeholder="$t('customerModule.pleaseSelect')"
                filterable
              >
                <el-option
                  v-for="timeZone in timeZones"
                  :key="timeZone.fullName"
                  :value="timeZone.fullName"
                />
              </el-select>
            </el-form-item>
          </CusFormItem>
        </el-form>
      </el-tab-pane>

      <el-tab-pane
        id="subscriptionpackagestab"
        name="subscriptionpackagestab"
        :label="$t('subscriptionPackages.title')"
      >
        <CustomerSubscriptionPackages
          :companySubscriptions="companySubscriptions"
          :subscriptionPackages="subscriptionPackages"
          :source="source"
          :subscriptionTabIsLoading="subscriptionTabIsLoading"
        />
      </el-tab-pane>

      <el-tab-pane
        id="dashboardTemplatetTab"
        name="dashboardTemplateTab"
        :label="$t('dashboardTemplate.title')"
      >
        <DashboardTemplate
          ref="dashboardTemplateRef"
          :customerId="customerId"
          :customerType="custForm.companyType"
          :industry="custForm.industry"
          :isCreate="customerId ? false : true"
          @temporary-save="setCurrentTemplateChanges"
        />
      </el-tab-pane>
    </el-tabs>
  </NewCard>
</template>

<style scoped>
.avatar-uploader .el-upload {
  border: 1px solid #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 96px;
  height: 96px;
  line-height: 96px !important;
  text-align: center;
}
.avatar {
  width: 96px;
  height: 96px;
  display: block;
}
</style>

<style lang="scss" scoped>
.btn-default {
  width: 36px;
  height: 36px;
}

.btn-selected {
  width: 52px;
  height: 52px;
}
</style>
