import Vue from 'vue';

/**
 * Root Vue component as created in main.ts.
 *
 * Used for providing legacy wrappers `vueClassComponentHelpers.ts`.
 */
let mainInstance: Vue | undefined;

export function haveMainInstance(): boolean {
  return mainInstance !== undefined;
}

export function registerMainInstance(instance: Vue): void {
  mainInstance = instance;
}

/**
 * Run a function in the context of the main app instance.
 *
 * This is only needed for legacy code to already look like Vue 3 code,
 * e.g. to make beforeEach hooks in Vue 2 router (3.x) be able to use composables
 * (which does work out of the box in Vue 3 router (4.x)).
 */
export function runInMainContextSync<T>(fn: () => T): T {
  console.log('runInMain');
  if (!mainInstance) {
    throw new Error(
      'Cannot execute in app context, main instance not initialized yet'
    );
  }

  // Construct a component to setup an 'injection context',
  // such that the function can access things like inject().
  let result: T;
  const dummy = new Vue({
    parent: mainInstance,
    created() {
      result = fn();
    },
    render() {
      return null;
    },
  });
  dummy.$destroy();
  return result!;
}

/**
 * Run a function in the context of the main app instance.
 *
 * This is only needed for legacy code to already look like Vue 3 code,
 * e.g. to make beforeEach hooks in Vue 2 router (3.x) be able to use composables
 * (which does work out of the box in Vue 3 router (4.x)).
 */
export async function runInMainContextAsync<T>(
  fn: () => Promise<T>
): Promise<T> {
  if (!mainInstance) {
    throw new Error(
      'Cannot execute in app context, main instance not initialized yet'
    );
  }

  // Construct a component to setup an 'injection context',
  // such that the function can access things like inject().
  let resultPromise: Promise<T>;
  const dummy = new Vue({
    parent: mainInstance,
    created() {
      resultPromise = fn();
    },
    render() {
      return null;
    },
  });
  const result = await resultPromise!;
  dummy.$destroy();
  return result;
}
