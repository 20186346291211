import {
  MaintenanceAppointment,
  PerformedMaintenanceAppointments,
} from '@/api/maintenance';
import { MaintenanceScheduler } from '@/components/scheduler/scheduler';
import moment, { Moment } from 'moment';

const unitHours: moment.unitOfTime.DurationConstructor = 'hours';

/**
 * Parse each received maintenance appoint to required Kendo UI Scheduler interface
 * @param receivedMaintenanceAppointments
 * @returns MaintenanceScheduler[]
 */
export function processMaintenanceAppointments(
  receivedMaintenanceAppointments: (
    | MaintenanceAppointment
    | PerformedMaintenanceAppointments
  )[]
): MaintenanceScheduler[] {
  const finalResponse: MaintenanceScheduler[] = [];
  receivedMaintenanceAppointments.forEach(
    (item: MaintenanceAppointment | PerformedMaintenanceAppointments) => {
      finalResponse.push({
        id: item.id,
        title: item.maintenanceItemName,
        start: getAppointmentStartTimeSlot(item.appointmentTime),
        startTimezone: item.timezone,
        end: getAppointmentEndTimeSlot(item.appointmentTime, item.duration!),
        endTimezone: item.timezone,
        isAllDay: false,
        description: item.companyAssetId,

        companyId: item.companyId,
        maintenancePlanId: item.maintenancePlanId!,
        organizationId: item.organizationId,
        organizationName: item.organizationName!,
        maintenanceItemId: item.maintenanceItemId,
        maintenanceItemName: item.maintenanceItemName,
        appointmentDateTimeUtc: item.appointmentDateTimeUtc!,
        appointmentDateUtc: item.appointmentDateUtc!,
        appointmentTime: item.appointmentTime,
        lastMaintenanceDate: item.lastMaintenanceDate!,
        timezone: item.timezone,
        duration: item.duration!,
        status: item.status!,
        isManuallyCreated: item.isManuallyCreated,
        isConductedManually: item.isConductedManually!,
        companyAssetId: item.companyAssetId,
        assetId: item.assetId,
        assetType: item.assetType,
        productModelId: item.productModelId!,
        maintainedParts: item.maintainedParts!,
        productModel: item.productModel,
        dominantValueRepresentedDays: item.dominantValueRepresentedDays!,
        rulesProgress: item.rulesProgress!,
      });
    }
  );
  return finalResponse;
}

/**
 * Handle maintenance appointment start date by parsing it to Date type required by Kendo UI Scheduler
 * @param receivedAppointmentTime
 * @returns Date
 */
export function getAppointmentStartTimeSlot(
  receivedAppointmentTime: string
): Date {
  return moment(receivedAppointmentTime).toDate();
}

/**
 * Handle maintenance appointment end date by parsing it to Date type required by Kendo UI Scheduler
 * Accordingly with the following logic:
 *  - End date is always bigger than start date therefore is offset forwards by duration field value received per item
 * @param receivedAppointmentTime
 * @param duration
 * @returns Date
 */
export function getAppointmentEndTimeSlot(
  receivedAppointmentTime: string,
  duration: string
): Date {
  return offsetAppointmentEndTime(receivedAppointmentTime, duration).toDate();
}

/**
 * Offset end date accordingly to duration value
 * - The offset must be only ahead of initial start date time of the appointment
 * @param receivedAppointmentTime
 * @param duration
 * @returns Moment
 */
export function offsetAppointmentEndTime(
  receivedAppointmentTime: string,
  duration: string
): Moment {
  const durationValue: DurationValue | undefined = getDurationValue(duration);
  return moment(receivedAppointmentTime).add(
    durationValue!.value,
    durationValue!.unit
  );
}

/**
 * Get duration value per duration code
 * @param duration
 * @returns DurationValue
 */
export function getDurationValue(duration: string): DurationValue | undefined {
  const durationValue = APPOINTMENT_DURATION.get(duration);
  if (!durationValue) {
    throw new Error(`Invalid Duration Value for: '${duration}'`);
  }
  return durationValue;
}

/**
 * Map used to retrieve duration value and unit by received duration code
 */
const APPOINTMENT_DURATION = new Map<string, DurationValue>([
  ['PT1H', { unit: unitHours, value: 1 }],
  ['PT2H', { unit: unitHours, value: 2 }],
  ['PT3H', { unit: unitHours, value: 3 }],
  ['PT4H', { unit: unitHours, value: 4 }],
  ['PT5H', { unit: unitHours, value: 5 }],
  ['PT6H', { unit: unitHours, value: 6 }],
  ['PT7H', { unit: unitHours, value: 7 }],
  ['PT8H', { unit: unitHours, value: 8 }],
  ['PT9H', { unit: unitHours, value: 9 }],
  ['PT10H', { unit: unitHours, value: 10 }],
  ['PT11H', { unit: unitHours, value: 11 }],
  ['PT12H', { unit: unitHours, value: 12 }],
]);

/**
 * Interface for Duration value
 */
interface DurationValue {
  unit: moment.unitOfTime.DurationConstructor;
  value: number;
}

export interface SchedulerData {
  id: string;
  day: string;
  isSelected: boolean;
  appointments: MaintenanceScheduler[];
}

/**
 * Get element hex color
 * @param element
 * @returns elementHexColor as string
 */
export function getElementColor(element: string): string | undefined {
  const elementHexColor = PlannerColorMap.get(element);
  if (!elementHexColor) {
    throw new Error(`Invalid element provided: '${element}'`);
  }
  return elementHexColor;
}

const PlannerColorMap = new Map<string, string>([
  ['weekHighlight', '#edf2f2'],
  ['dayHighlight', '#ff6358'],
]);

export const enum MaintenanceModalTriggerSource {
  New = 'NEW',
  Edit = 'EDIT',
  Show = 'show', // needs to be lowercased because of TransferList component which use the triggerSource as an action
}

export const PERFORMED_MAINTENANCE_STATUS = 'MAINS_PERFORMED';
export const DEFAULT_APPOINTMENT_DURATION = 'PT1H';
