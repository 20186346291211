<script lang="ts" setup>
import { UUID } from '@/api/common';
import { AssetTripEventsSummary, SafetyEventType } from '@/api/trip';
import { useLoggedInUser } from '@/auth/user';
import { useUnitConversion } from '@/composables/conversion';
import i18n from '@/lang';
import {
  dayRangeBoundaries,
  ISO8601_LOCAL_DATETIME_PATTERN,
} from '@/utils/time';
import { KPI_UNIT } from '@/utils/units/unitDefinitions';
import { toUnitValue } from '@/utils/units/unitValue';
import {
  getFormattedSafetyDate,
  getLabelWithEvent,
  getLabelWithoutEvent,
  getLargeEventSeverityTooltipContent,
  getSeverityEventLabelLabel,
  getSmallEventSeverityTooltipContent,
  isSameDateAndTimeIncluded,
} from '@/widgets/utils/tripSafety';
import echarts, { EChartOption, ECharts } from 'echarts';
import moment from 'moment';
import {
  computed,
  nextTick,
  onMounted,
  onUnmounted,
  ref,
  unref,
  watch,
} from 'vue';

const props = defineProps<{
  summaryEvents: AssetTripEventsSummary[];
  safetyEvent?: SafetyEventType;
  selectedDate?: string;
  maximumSeverityLevel?: number;
  safetyUnitCode: KPI_UNIT | undefined;
}>();

const emit = defineEmits<{
  (e: 'tripSelectionUUID', tripSelectionUUID: UUID): void;
}>();

const { currentUserConvertNumberMany, currentUserPreferredUnit } =
  useUnitConversion();

const convertedUnitCode = computed(() =>
  props.safetyUnitCode
    ? currentUserPreferredUnit(props.safetyUnitCode)
    : undefined
);

const convertedSummaryEvents = computed(
  (): AssetTripEventsSummary[] | undefined => {
    const safetyUnitCode = props.safetyUnitCode;
    if (safetyUnitCode == undefined) return;

    return props.summaryEvents.map((event) =>
      currentUserConvertNumberMany(
        event,
        [
          'minimumSeverityLevel',
          'averageSeverityLevel',
          'maximumSeverityLevel',
        ],
        safetyUnitCode
      )
    );
  }
);

/** Chart instance */
const chartRef = ref<HTMLDivElement | HTMLCanvasElement>();
let chartInstance = ref<ECharts | null>();

/** Check if display resolution width is below 1920px to adjust axis labels with different characteristics */
const isContainerMaxWidth1920px = ref<boolean>(window.innerWidth < 1920);

/**
 * On mounted, initiate chart instance
 */
onMounted(() => {
  initChart();
});

/**
 * Destroy chart instance and remove event listener for resizing
 */
onUnmounted(() => {
  window.removeEventListener('resize', resizeChart);
  disposeChart();
});

/**
 * Initiate stacked bars chart
 */
function initChart() {
  const style = {
    fontSize: '',
    fontWeight: '',
    marginTop: '',
    marginLeft: '',
  };

  if (convertedSummaryEvents.value === undefined) {
    return;
  }

  if (
    !convertedSummaryEvents.value ||
    convertedSummaryEvents.value.length === 0
  ) {
    const style = {
      fontSize: '20px',
      fontWeight: 'bold',
      marginTop: '15%',
      marginLeft: '32%',
    };

    chartRef.value!.innerHTML = i18n.tc(
      'newFleetSafety.noTripsCompletedForSelectedDay'
    );

    Object.assign(chartRef.value!.style, style);
    return;
  }

  Object.assign(chartRef.value!.style, style);

  chartInstance.value = echarts.init(chartRef.value!);

  chartInstance.value!.on('click', (params: any) =>
    chartOnClickHandler(params)
  );

  unref(chartInstance)!.setOption(getOption());
  window.addEventListener('resize', resizeChart);
}

function chartOnClickHandler(params: any): void {
  const sumaryEvent = convertedSummaryEvents.value?.find((item) =>
    moment(item.tripEndTime, ISO8601_LOCAL_DATETIME_PATTERN).isSame(
      moment(params.data[0]).format(ISO8601_LOCAL_DATETIME_PATTERN)
    )
  );

  if (sumaryEvent && sumaryEvent.tripUUID) {
    emit('tripSelectionUUID', sumaryEvent.tripUUID);
  }
}

/**
 * Watch over any props change then update/re instantiate the chart
 * - every change in the parent should re instantiate the chart
 */
watch(
  () => props,
  () => {
    disposeChart();
    nextTick(async () => {
      initChart();
    });
  },
  {
    immediate: true,
    deep: true,
  }
);

/**
 * Handle chart resizing relative to container size
 */
const resizeChart = () => {
  disposeChart();
  isContainerMaxWidth1920px.value = window.innerWidth < 1920;
  initChart();
};

/**
 * Dispose chart instance
 */
function disposeChart(): void {
  if (chartInstance.value) {
    chartInstance.value.dispose();
    chartInstance.value = null;
  }
}

/**
 * Get trips with event
 */
const tripsWithEvents = computed((): (string | number)[][] => {
  let result: (string | number)[][] = [[0, 0]];
  const filteredEvents = convertedSummaryEvents.value?.filter(
    (trip: AssetTripEventsSummary) => trip.numberOfEvents > 0
  );

  if (filteredEvents?.length) {
    result = filteredEvents.map((trip: AssetTripEventsSummary) => [
      getFormattedSafetyDate(trip.tripEndTime),
      1,
    ]);
  }

  return result;
});

/**
 * Get trips without event
 */
const tripsWithoutEvents = computed((): (string | number)[][] => {
  let result: (string | number)[][] = [[0, 0]];
  const filteredEvents = convertedSummaryEvents.value?.filter(
    (trip: AssetTripEventsSummary) => trip.numberOfEvents === 0
  );

  if (filteredEvents?.length) {
    result = filteredEvents.map((trip: AssetTripEventsSummary) => [
      getFormattedSafetyDate(trip.tripEndTime),
      1,
    ]);
  }

  return result;
});

const { currentUserConvertUnitValue } = useUnitConversion();

/**
 * Get event severity
 */
const eventSeverity = computed((): (string | number)[][] => {
  let result: (string | number)[][] = [[0, 0]];
  const filteredEvents = convertedSummaryEvents.value?.filter(
    (trip: AssetTripEventsSummary) => trip.numberOfEvents > 0
  );

  if (filteredEvents?.length) {
    result = filteredEvents.map((trip: AssetTripEventsSummary) => [
      getFormattedSafetyDate(trip.tripEndTime),
      props.safetyUnitCode
        ? currentUserConvertUnitValue(
            toUnitValue(trip.averageSeverityLevel, props.safetyUnitCode)
          ).v
        : trip.averageSeverityLevel,
    ]);
  }

  return result;
});

/**
 * Get severity candle stick data (min and max severity number)
 * Maximum severity should be bigger than minimum severity and there should be many number of events
 */
const severityCandleStickData = computed((): (string | number)[][] => {
  let result: (string | number)[][] = [];
  const filteredEvents = convertedSummaryEvents.value?.filter(
    (trip) => trip.numberOfEvents > 1
  );

  if (filteredEvents?.length) {
    result = filteredEvents.map((trip) => [
      getFormattedSafetyDate(trip.tripEndTime),
      trip.minimumSeverityLevel,
      trip.maximumSeverityLevel,
    ]);
  }

  return result;
});

const LEGEND_HEIGHT: number = 40;
const NAME_HEIGHT: number = 14;
const X_AXIS_LABELS_HEIGHT: number = 14;
const Y_AXIS_LABELS_WIDTH: number = 40;
const LEGEND_ITEM_GAP: number = 40;
const X_AXIS_NAME_OFFSET: number = 5;
const TRIPS_BAR_HEIGHT: number = 60;
const Y_AXIS_OFFSET: number = 5;

const grids = [
  {
    left: Y_AXIS_LABELS_WIDTH + NAME_HEIGHT + Y_AXIS_OFFSET,
    right: Y_AXIS_OFFSET,
    top: LEGEND_HEIGHT,
    bottom:
      TRIPS_BAR_HEIGHT +
      X_AXIS_LABELS_HEIGHT +
      NAME_HEIGHT +
      X_AXIS_NAME_OFFSET,
  },
  {
    left: Y_AXIS_LABELS_WIDTH + NAME_HEIGHT + Y_AXIS_OFFSET,
    right: Y_AXIS_OFFSET,
    height: TRIPS_BAR_HEIGHT + X_AXIS_LABELS_HEIGHT,
    bottom: NAME_HEIGHT + X_AXIS_NAME_OFFSET,
  },
];

const loggedInUser = useLoggedInUser();

/**
 * Generate echart stacked bar options
 */
const getOption = () =>
  ({
    color: ['#8bd0f5', '#be442d', '#d9d9d9'],
    tooltip: {
      show: true,
      trigger: 'item',
      confine: true,
      axisPointer: {
        type: 'shadow',
      },
      formatter: function (params: any) {
        /**
         * Allow tooltip to be rendered only for
         * - severity percentage bar (blue one),
         * - custom candle stick bar (black),
         * - trip with events bar (red one)
         * from grid 0 (top side chart
         */
        if (params.componentIndex === 2) return;

        const eventItem = convertedSummaryEvents.value?.find((item) =>
          moment(item.tripEndTime, ISO8601_LOCAL_DATETIME_PATTERN).isSame(
            moment(params.value[0]).format(ISO8601_LOCAL_DATETIME_PATTERN)
          )
        );

        if (eventItem === undefined || !convertedUnitCode.value) return '';

        if (
          params.componentSubType === 'bar' &&
          !isSameDateAndTimeIncluded(
            severityCandleStickData.value,
            params.value[0]
          )
        ) {
          /** Set tooltip content that will be rendered for severity percentage bar in the top chart grid (blue one)
           * that doesn't have any candle stick error bar stack over it
           */
          return getSmallEventSeverityTooltipContent(
            i18n.tc('newFleetSafety.tripStatistics'),
            eventItem,
            props.safetyEvent!,
            convertedUnitCode.value
          );
        }

        if (
          params.componentSubType === 'bar' &&
          isSameDateAndTimeIncluded(
            severityCandleStickData.value,
            params.value[0]
          )
        ) {
          /** Set tooltip content that will be rendered for severity percentage bar in the top chart grid (blue one)
           * that has a stacked candle stick error bar with min/max
           */
          return getLargeEventSeverityTooltipContent(
            i18n.tc('newFleetSafety.tripStatistics'),
            eventItem,
            props.safetyEvent!,
            convertedUnitCode.value
          );
        }

        return getLargeEventSeverityTooltipContent(
          i18n.tc('newFleetSafety.tripStatistics'),
          eventItem,
          props.safetyEvent!,
          convertedUnitCode.value
        );
      },
    },
    legend: {
      data: [
        {
          name: props.safetyEvent ? getLabelWithEvent(props.safetyEvent) : '',
          icon: 'rect',
        },
        {
          name: props.safetyEvent
            ? getLabelWithoutEvent(props.safetyEvent)
            : '',
          icon: 'rect',
        },
        {
          name:
            props.safetyEvent && convertedUnitCode.value
              ? getSeverityEventLabelLabel(
                  props.safetyEvent,
                  convertedUnitCode.value
                )
              : '',
          icon: 'rect',
        },
      ],
      orient: 'horizontal',
      left: 'center',
      top: 0,
      itemGap: LEGEND_ITEM_GAP,
      // Toggling the event severity makes the axis shift a bit,
      // so just disable that possibility
      selectedMode: false,
    },
    grid: grids,
    xAxis: [
      {
        gridIndex: 0,
        type: 'time',
        minInterval: 3600 * 1000,
        maxInterval: 3600 * 1000,
        min: dayRangeBoundaries(props.selectedDate)?.startOfDay,
        max: dayRangeBoundaries(props.selectedDate)?.endOfDay,
        axisLabel: {
          formatter: function (value: any) {
            const date = new Date(value);
            let hours = String(date.getHours()).padStart(2, '0');
            const minutes = String(date.getMinutes()).padStart(2, '0');
            return `${hours}:${minutes}`;
          },
          rotate: 90,
        },
        axisLine: {
          show: true,
        },
        axisTick: {
          show: true,
        },
        splitLine: {
          show: false,
        },
        z: 10,
      },
      {
        name: i18n.tc('newFleetSafety.time'),
        position: 'top',
        nameLocation: 'middle',
        minInterval: 3600 * 1000,
        maxInterval: 3600 * 1000,
        nameGap: -(TRIPS_BAR_HEIGHT + 2 * NAME_HEIGHT + X_AXIS_NAME_OFFSET),
        gridIndex: 1,
        type: 'time',
        min: dayRangeBoundaries(props.selectedDate)?.startOfDay,
        max: dayRangeBoundaries(props.selectedDate)?.endOfDay,
        nameTextStyle: {
          fontWeight: 'bold',
        },
        axisLabel: {
          show: false,
        },
        axisLine: {
          show: false,
        },
        axisTick: {
          show: false,
        },
        splitLine: {
          show: false,
        },
      },
    ],
    yAxis: [
      {
        name:
          props.safetyEvent && convertedUnitCode.value
            ? getSeverityEventLabelLabel(
                props.safetyEvent,
                convertedUnitCode.value
              )
            : '',
        gridIndex: 0,
        type: 'value',
        position: 'left',
        offset: Y_AXIS_OFFSET,
        // Disabled, see the 'fake' maxScaler series below.
        // max: props.maximumSeverityLevel,
        nameLocation: 'middle',
        nameGap: Y_AXIS_LABELS_WIDTH,
        nameTextStyle: {
          fontWeight: 'bold',
        },
        axisLine: {
          show: true,
        },
        axisTick: {
          show: false,
        },
      },
      {
        name: i18n.tc('newFleetSafety.trips'),
        nameLocation: 'middle',
        gridIndex: 1,
        offset: Y_AXIS_OFFSET,
        type: 'value',
        position: 'left',
        inverse: true,
        min: 0,
        max: 1,
        splitNumber: 1,
        nameGap: Y_AXIS_LABELS_WIDTH,
        nameTextStyle: {
          fontWeight: 'bold',
          align: 'right',
        },
        axisLine: {
          show: true,
        },
        axisTick: {
          show: false,
        },
      },
    ],
    series: [
      {
        name:
          props.safetyEvent && convertedUnitCode.value
            ? getSeverityEventLabelLabel(
                props.safetyEvent,
                convertedUnitCode.value
              )
            : '',
        type: 'bar',
        xAxisIndex: 0,
        yAxisIndex: 0,
        data: eventSeverity.value,
        barWidth: 5,
      },
      {
        name: props.safetyEvent ? getLabelWithEvent(props.safetyEvent) : '',
        type: 'bar',
        yAxisIndex: 1,
        xAxisIndex: 1,
        data: tripsWithEvents.value,
        barWidth: 5,
      },
      {
        name: props.safetyEvent ? getLabelWithoutEvent(props.safetyEvent) : '',
        type: 'bar',
        barGap: '-100%',
        yAxisIndex: 1,
        xAxisIndex: 1,
        data: tripsWithoutEvents.value,
        barWidth: 5,
      },
      {
        type: 'custom',
        name: 'error',
        renderItem: function (params: any, api: any) {
          var xValue = api.value(0);
          var highPoint = api.coord([xValue, api.value(1)]);
          var lowPoint = api.coord([xValue, api.value(2)]);
          var halfWidth = (api.size!([1, 0]) as number[])[0] * 200000;
          var style = api.style({
            stroke: 'black',
          });

          return {
            type: 'group',
            children: [
              {
                type: 'line',
                transition: ['shape'],
                shape: {
                  x1: highPoint[0] - halfWidth,
                  y1: highPoint[1],
                  x2: highPoint[0] + halfWidth,
                  y2: highPoint[1],
                },
                style: style,
              },
              {
                type: 'line',
                transition: ['shape'],
                shape: {
                  x1: highPoint[0],
                  y1: highPoint[1],
                  x2: lowPoint[0],
                  y2: lowPoint[1],
                },
                style: style,
              },
              {
                type: 'line',
                transition: ['shape'],
                shape: {
                  x1: lowPoint[0] - halfWidth,
                  y1: lowPoint[1],
                  x2: lowPoint[0] + halfWidth,
                  y2: lowPoint[1],
                },
                style: style,
              },
            ],
          };
        },
        encode: {
          x: 0,
          y: [1, 2],
        },
        data: severityCandleStickData.value,
        z: 100,
      },
      {
        // Fake series to put a max value in the chart.
        // The 'official' way is to put a max on the yscale,
        // but can create a strange additional tick at the top.
        // E.g. if the max is set to 19, it may show axis ticks
        // as: 3, 6, 9, 12, 15, 18, 19 (not the 1 vs 3 steps).
        name: 'maxScaler',
        type: 'bar',
        xAxisIndex: 0,
        yAxisIndex: 0,
        data: [0, props.maximumSeverityLevel],
        barWidth: 5,
        barGap: '-100%',
      },
    ],
  } as EChartOption<EChartOption.SeriesBar>);
</script>

<template>
  <div
    data-testid="vertical-time-overload-stacked-bar-chart"
    class="vertical-time-overload-stacked-bar-chart"
    ref="chartRef"
  ></div>
</template>

<style lang="scss" scoped>
.vertical-time-overload-stacked-bar-chart {
  width: 100%;
  height: 100%;
}
</style>
