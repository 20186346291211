/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon';
icon.register({
  custMgmt: {
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
    data: '<g data-name="组件 348 – 34"><path pid="0" data-name="减去 18" d="M14 20H6a6.007 6.007 0 01-6-6V6a6.007 6.007 0 016-6h8a6.007 6.007 0 016 6v8a6.006 6.006 0 01-6 6zM6 1a5.006 5.006 0 00-5 5v8a5.006 5.006 0 005 5h8a5.005 5.005 0 005-5V6a5.006 5.006 0 00-5-5z" _fill="#373e41"/></g><path pid="1" data-name="路径 1434" d="M4.269 14.801h-.12c-1.278 0-2.241-.843-2.044-1.77a3.353 3.353 0 012.752-2.874 2.588 2.588 0 011.04-4.959 2.653 2.653 0 01.383.038 4.133 4.133 0 00-.447 1.842 4.179 4.179 0 001.159 2.887 5.291 5.291 0 00-2.731 3.881 2.268 2.268 0 00.008.955zm13.627-1.769a3.355 3.355 0 00-2.752-2.874 2.588 2.588 0 00-1.039-4.959 2.52 2.52 0 00-.358.037 4.125 4.125 0 01.447 1.845A4.17 4.17 0 0113.005 10a5.5 5.5 0 012.723 3.8 2.245 2.245 0 01.008 1h.117c1.278.001 2.242-.842 2.043-1.768zm-5.6 3.034a2.76 2.76 0 002.148-.9 1.3 1.3 0 00.279-1.129 4.29 4.29 0 00-3.409-3.553l-.848-.177.795-.345a3.149 3.149 0 10-2.509-.008l.8.353-.861.169a4.136 4.136 0 00-3.419 3.583 1.32 1.32 0 00.3 1.122 2.733 2.733 0 002.133.881h4.591z" _fill="#373e41"/>',
  },
});
