import {
  OrganizationAssetsHierarchyAsset,
  OrganizationAssetsHierarchyOrganization,
  OrganizationAssetsHierarchyResponseLegacy,
} from '@/api/assets';
import { Asset } from '@/api/geofenceTypes';
import {
  KpiDataField,
  KpiDataResponse,
  MultiAssetKpiAssetLegacy,
} from '@/api/kpis';
import { flatMapDeep, isDefined, mapTree } from './collections';
import { parseNumberOrUndefined } from './number';
import { KPI_UNIT } from './units/unitDefinitions';
import { UnitValue } from './units/unitValue';
import { EntityType, KPI_FIELDS } from './workData/lookuptable';
import { OperationalStatus } from './workData/operationalStatus';

export interface KPIData<T = any> {
  value: T;
  unit: KPI_UNIT;
}

export function extractAssetsFromOrganizationHierarchies(
  hierarchy: OrganizationAssetsHierarchyOrganization[]
): OrganizationAssetsHierarchyAsset[] {
  return hierarchy.flatMap(extractAssetsFromOrganizationHierarchy);
}

/**
 * Extract all assets from the hierarchy as a flat list of assets.
 */
export function extractAssetsFromOrganizationHierarchy(
  hierarchy: OrganizationAssetsHierarchyOrganization
): OrganizationAssetsHierarchyAsset[] {
  return flatMapDeep(hierarchy, 'suborganizations', (org) => org.assets);
}

export function filterOrganizationHierarchiesAssets(
  hierarchies: OrganizationAssetsHierarchyOrganization[],
  assetFilterer: (asset: OrganizationAssetsHierarchyAsset) => boolean
): OrganizationAssetsHierarchyOrganization[] {
  return hierarchies
    .map((hierarchy) =>
      filterOrganizationHierarchyAssets(hierarchy, assetFilterer)
    )
    .filter(isDefined);
}

/**
 * Return filtered version of the tree, only keeping assets that match the filter.
 *
 * 'Empty' organizations (i.e. no longer having assets nor sub-organizations) are
 * pruned from the result.
 */
export function filterOrganizationHierarchyAssets(
  hierarchy: OrganizationAssetsHierarchyOrganization,
  assetFilterer: (asset: OrganizationAssetsHierarchyAsset) => boolean
): OrganizationAssetsHierarchyOrganization | undefined {
  return mapTree(hierarchy, 'suborganizations', (org) => {
    const assets = org.assets.filter(assetFilterer);
    if (assets.length === 0 && org.suborganizations.length === 0) {
      return undefined;
    }
    return {
      ...org,
      assets,
    };
  });
}

/**
 * @deprecated
 */
export function extractAssetsFromOrganizationHierarchyLegacy(
  hierarchy: OrganizationAssetsHierarchyResponseLegacy
): OrganizationAssetsHierarchyAsset[] {
  return [
    ...(hierarchy.organization.assets ?? []),
    ...hierarchy.organization.suborganizations.flatMap(
      extractAssetsFromOrganizationHierarchyLegacy
    ),
  ];
}

/**
 * @deprecated Use `extractAssetsFromOrganizationHierarchy` instead
 */
export function findAssets(organization: any): Asset[] {
  const result = [...organization.assets];

  for (const sub of organization.suborganizations) {
    result.push(...findAssets(sub.organization));
  }
  return result;
}

export function findKpiField(
  data: KpiDataResponse,
  code: (typeof KPI_FIELDS)[keyof typeof KPI_FIELDS]
): KpiDataField | undefined {
  const details = data?.details.find((x) => x.entity === EntityType.ASSET);
  const field = details?.fields?.find((x) => x.code === code);

  return field;
}

export function findAssetNumericUnitValue(
  data: KpiDataResponse,
  code: (typeof KPI_FIELDS)[keyof typeof KPI_FIELDS]
): UnitValue<number | undefined> | undefined {
  const field = findKpiField(data, code);

  if (!field || field.values.length == 0 || !field.values[0].v) {
    return undefined;
  }

  return {
    unit: field.unit,
    v: parseNumberOrUndefined(field.values[0].v),
  };
}

export function findAssetUnitValue<T>(
  data: KpiDataResponse,
  code: (typeof KPI_FIELDS)[keyof typeof KPI_FIELDS]
): UnitValue<T> | undefined {
  const field = findKpiField(data, code);

  if (!field || field.values.length == 0 || !field.values[0].v) {
    return undefined;
  }

  return {
    unit: field.unit,
    v: convertKpiValueToUnit(field.values[0].v, field?.unit) as unknown as T,
  };
}

function convertKpiValueToUnit(value: string, unit: KPI_UNIT) {
  switch (unit) {
    case KPI_UNIT.Boolean:
      return value === 'true';
    default:
      return value;
  }
}

export function findKpiValue(
  kpis: MultiAssetKpiAssetLegacy[],
  asset: string | { id: string },
  code: string
) {
  if (!kpis || !Array.isArray(kpis)) {
    return;
  }

  const assetId = typeof asset === 'string' ? asset : asset.id;
  const value = kpis.find((kpi) => kpi.assetId === assetId);

  if (!value) {
    return;
  }

  return value.values.find((v: any) => v.code === code)?.v;
}

// TODO-TYPING: Add type for the config parameter
export function getAssetStatusConfig(
  type: OperationalStatus,
  option: 'color' | 'icon',
  config: any
) {
  const configuration = config.status.find(
    (s: { type: OperationalStatus }) => s.type === type
  );

  return configuration?.[option];
}
