<script lang="ts">
import Pagination from '@/components/pagination/Pagination.vue';
import { UserModule } from '@/store/modules/user';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

@Component({
  name: 'UtilTable',
  components: {
    Pagination,
  },
})
export default class extends Vue {
  @Prop({ default: true }) showCheckedColumn!: boolean;

  @Prop() tableList!: any[];
  @Prop() cols!: [];
  @Prop() total!: number;
  @Prop() path!: string;
  @Prop() showPage!: boolean;

  value = '';
  list: any = [...this.tableList];
  dragCols: any = [...this.cols];
  colChange: any = [];

  pageSize = UserModule.gridPageSize;
  listQuery = {
    page: 1,
    limit: UserModule.gridPageSize,
  };

  @Watch('tableList')
  onListChange(value: any) {
    this.list = [...this.tableList];
  }
  get activeCols() {
    this.colChange = this.cols.filter((item: any) => {
      return item.visible;
    });

    this.dragCols = [...this.colChange];
    return [...this.colChange];
  }

  tableRowClassName(row: any, rowIndex: number) {
    if (row.row.chosen) {
      return 'choosing-row';
    }
    return '';
  }

  handleSelectionChange(val: any) {
    this.$emit('count-chosen', val);
    this.tableList.forEach((item: any) => {
      item.chosen = false;
    });
    val.forEach((item: any) => {
      item.chosen = true;
    });
  }

  getList(val: any) {
    this.$emit('handle-page', val.page, this.pageSize);
  }

  /**
   * Verify if row has the roleCode then show icon
   * @param index
   * @param roleCode
   */
  handleModuleAccessIconRendering(index: number, roleCode: string) {
    return this.list[index].claims
      ? this.list[index].claims.some((e: any) => e.resource == roleCode)
      : false;
  }

  mounted() {}

  handleSortChange(val: any) {
    let sortType = '';

    if (val.order === 'ascending') {
      sortType = 'ASC';
    } else if (val.order === 'descending') {
      sortType = 'DESC';
    }

    this.$emit('handle-sort-change', val.prop, sortType);
  }
}
</script>

<template>
  <div>
    <el-table
      :data="list"
      row-key="id"
      :row-style="{ height: '40px' }"
      :cell-style="{ padding: '7px 0px' }"
      @selection-change="handleSelectionChange"
      :row-class-name="tableRowClassName"
      @sort-change="handleSortChange"
    >
      <el-table-column
        type="selection"
        :reserve-selection="showCheckedColumn"
        width="55"
        v-if="showCheckedColumn"
      >
      </el-table-column>

      <el-table-column
        v-for="(col, index) in activeCols"
        :key="`col_${index}`"
        :prop="dragCols[index].prop"
        :min-width="col.width ? col.width : '180'"
        show-overflow-tooltip
        :sortable="dragCols[index].sortable"
      >
        <template v-slot:header>
          <div class="table-header">
            <span style="white-space: nowrap">{{ $t(col.label) }}</span>
          </div>
        </template>

        <!-- translate language -->
        <template v-slot="slotProps" v-if="col.multiLang">
          <span>{{ $t(slotProps.row[col.prop]) }}</span>
        </template>

        <template
          v-slot="slotProps"
          v-else-if="col.dateFormat === 'date' || col.dateFormat === 'datetime'"
        >
          <span>{{
            slotProps.row[col.prop] | formatTimer(col.dateFormat)
          }}</span>
        </template>
      </el-table-column>
    </el-table>

    <Pagination
      v-show="
        /* @ts-expect-error TODO Wrong type */
        showPage && parseInt(total) > parseInt(pageSize)
      "
      :total="total"
      :page.sync="listQuery.page"
      :limt.sync="listQuery.limit"
      @pagination="getList"
    />

    <el-popover ref="popover" placement="bottom" trigger="click">
      <el-checkbox
        v-for="(item, index) in cols"
        :key="index"
        :label="
          /* @ts-expect-error TODO Wrong type */
          $t(item.label)
        "
        v-model="
          /* @ts-expect-error TODO Wrong type */
          item.visible
        "
        :disabled="
          /* @ts-expect-error TODO Wrong type */
          item.required
        "
        style="display: block; color: #373e41 !important"
      ></el-checkbox>
    </el-popover>
  </div>
</template>

<style lang="scss" scoped>
.table-header {
  display: inline-block;
  margin-right: 2px;
  color: #373e41 !important;
}
:deep(.choosing-row) {
  background: #edeff0 !important;
}

:deep(.el-table th .el-checkbox__input) {
  display: none;
}

:deep(.el-checkbox__input.is-checked .el-checkbox__inner) {
  background-color: #ffcd00 !important;
  border-color: #848484 !important;
  &:after {
    border: none;
  }
}

:deep(.el-table .sort-caret.ascending) {
  border-bottom-color: #000000;
}
:deep(.el-table .sort-caret.descending) {
  border-top-color: #000000;
}
:deep(.el-table .ascending .sort-caret.ascending) {
  border-bottom-color: var(--Main);
}
:deep(.el-table .descending .sort-caret.descending) {
  border-top-color: var(--Main);
}
</style>
