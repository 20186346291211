/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon';
icon.register({
  home: {
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
    data: '<defs><clipPath id="svgicon_home_a"><path pid="0" data-name="路径 865" d="M86.523-153.621a3.189 3.189 0 003.189-3.189 3.189 3.189 0 00-3.189-3.19 3.189 3.189 0 00-3.189 3.189v2.427a.773.773 0 00.762.762zm.086-1.063H84.4v-2.133a2.174 2.174 0 012.213-2.119 2.165 2.165 0 012.04 2.126 2.166 2.166 0 01-2.044 2.126z" transform="translate(-83.333 160)" _fill="#373e41"/></clipPath></defs><g data-name="组件 338 – 2"><path pid="1" data-name="减去 15" d="M14 20H6a6.007 6.007 0 01-6-6V6a6.007 6.007 0 016-6h8a6.007 6.007 0 016 6v8a6.006 6.006 0 01-6 6zM6 1a5.006 5.006 0 00-5 5v8a5.006 5.006 0 005 5h8a5.005 5.005 0 005-5V6a5.006 5.006 0 00-5-5z" _fill="#373e41"/></g><g data-name="组 3002"><path pid="2" data-name="路径 863" d="M6.406 3.002a3.2 3.2 0 00-2.944 1.6 3.4 3.4 0 000 3.423 3.2 3.2 0 002.944 1.6h2.455a.792.792 0 00.77-.792V6.32a3.281 3.281 0 00-3.225-3.318zm-2.3 3.318c-.006-1.218.005 1.221-.006.004zm4.734 4.045H6.315A3.316 3.316 0 003 13.685a3.316 3.316 0 003.315 3.317 3.316 3.316 0 003.316-3.316v-2.524a.8.8 0 00-.792-.792zm4.939 6.637A3.361 3.361 0 0017 13.685a3.361 3.361 0 00-3.22-3.316h-2.6a.815.815 0 00-.815.794v2.517a3.378 3.378 0 003.414 3.322z" _fill="#373e41"/><g data-name="组 1193" transform="translate(10.621 3)" clip-path="url(#svgicon_home_a)"><path pid="3" data-name="路径 864" d="M-.426-.425h7.229v7.229H-.426z" _fill="#373e41"/></g></g>',
  },
});
