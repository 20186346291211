import { render, staticRenderFns } from "./ViewDashTemplate.vue?vue&type=template&id=5ccdf849&scoped=true"
import script from "./ViewDashTemplate.vue?vue&type=script&lang=ts"
export * from "./ViewDashTemplate.vue?vue&type=script&lang=ts"
import style0 from "./ViewDashTemplate.vue?vue&type=style&index=0&id=5ccdf849&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5ccdf849",
  null
  
)

export default component.exports