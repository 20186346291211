<script lang="ts">
import { Role } from '@/api/roles';
import { ErrorType } from '@/api/types';
import CusFormItem from '@/components/form/CusFormItem.vue';
import ModuleAccess from '@/components/form/ModuleAccess.vue';
import {
  COMPANY_TYPE_LIST,
  ERROR_CODE_LIST,
} from '@/utils/workData/lookuptable';
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
  name: 'ChangeRole',
  components: {
    'cus-form-item': CusFormItem,
    'module-access': ModuleAccess,
  },
})
export default class extends Vue {
  @Prop() roleForm!: Role;
  @Prop() authorizableResources: any = []; // tree structure model from where module access section is created
  @Prop() defaultChecked: string[] = [];

  companyTypes = COMPANY_TYPE_LIST;
  errorCode = ERROR_CODE_LIST;
  errorInfos: ErrorType[] = [
    {
      code: '',
      field: '',
      message: '',
    },
  ];

  validateClaimsFtd = (_rule: any, _value: any, callback: any) => {
    const checkedNode = (this.$refs.moduleAccess as any).getCheckedNodes();
    if (checkedNode.length < 1) {
      callback(new Error(`${this.$t('userRoleModule.tipSelectModuleAccess')}`));
    } else {
      callback();
    }
  };

  get errNameInfo() {
    let errInfo: string = '';
    if (
      this.errorInfos.find((item) => item.field === this.errorCode.name)
        ?.code === 'ApiErrorFieldDuplicate'
    ) {
      errInfo = `${this.$t('userRoleModule.duplicateName')}`;
    }
    // return this.errorInfos.find(item => item.field === this.errorCode.name)?.message
    return errInfo;
  }

  handleInput() {
    this.errorInfos = [];
  }
}
</script>

<template>
  <div>
    <cus-form-item
      :title="$t('userRoleModule.userRoleName')"
      :errContent="errNameInfo"
    >
      <el-form-item prop="name">
        <!-- On edit - if the name is changed, the code will be changed -> disabled -->
        <el-input
          id="add_new_role_input_name"
          v-model="roleForm.name"
          size="medium"
          v-bind:placeholder="$t('userRoleModule.inputNewRoleName')"
          @input="handleInput"
          :disabled="!!roleForm.id"
          class="role-input"
        ></el-input>
      </el-form-item>
    </cus-form-item>

    <cus-form-item :title="$t('userRoleModule.customerType')">
      <el-form-item prop="companyType">
        <!-- On edit - if the company type is changed, the code will be changed -> disabled -->
        <el-select
          id="add_new_role_select_companyType"
          v-model="roleForm.companyType"
          v-bind:placeholder="$t('userRoleModule.selectCustomerType')"
          filterable
          :disabled="!!roleForm.id"
        >
          <el-option
            v-for="item in companyTypes"
            :key="item.id"
            :label="$t(item.value)"
            :value="item.id"
          />
        </el-select>
      </el-form-item>
    </cus-form-item>

    <cus-form-item :title="$t('userRoleModule.note')" :required="false">
      <el-form-item>
        <el-input
          id="add_new_role_input_note"
          v-model="roleForm.note"
          size="medium"
          v-bind:placeholder="$t('userRoleModule.inputNote')"
          class="role-input"
        ></el-input>
      </el-form-item>
    </cus-form-item>

    <div class="role-module-access">
      <div class="role-module-access-header">
        <span class="show-title">{{ $t('userRoleModule.moduleAccess') }}:</span>
        <span v-if="true" class="required-field">*</span>
      </div>
      <div>
        <el-form-item prop="claimsFtd">
          <module-access
            id="add_new_role_module_access"
            ref="moduleAccess"
            :authorizableResources="authorizableResources"
            :defaultProps="{ children: 'children', label: 'label' }"
            :defaultChecked="defaultChecked"
            :defaultExpand="false"
            :isEdit="true"
          ></module-access>
        </el-form-item>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.role-module-access {
  display: flex;
  justify-content: flex-start;
  margin-top: 20px;

  .role-module-access-header {
    flex: 0 0 12.5rem;
    padding: 0 1rem 20px 0;
    color: #8e8e8e;
    text-align: right;
    font-weight: 500;
  }
}

:deep(.role-input input) {
  height: 32px;
  font-family: inherit;
  font-size: inherit;
  width: inherit;
}
</style>
