<script lang="ts">
import { getKpiData } from '@/api/assets';
import { UUID } from '@/api/common';
import { KpiDataField, KpiDataResponse, KpiDataValue } from '@/api/kpis';
import { ActiveContext, useActiveContext } from '@/auth/context';
import TimeSelect from '@/components/form/TimeSelect.vue';
import GrowthPercentage from '@/components/general/GrowthPercentage.vue';
import WidgetCard from '@/components/layout/widget/WidgetCard.vue';
import {
  useUnitConversion,
  UseUnitConversionReturnType,
} from '@/composables/conversion';
import redDownArrow from '@/icons/svg/arrowDown.svg';
import greenUpArrow from '@/icons/svg/arrowUp.svg';
import { useOrganizationAssetsHierarchyQuery } from '@/query/assets';
import { extractAssetsFromOrganizationHierarchy } from '@/utils/assets';
import { AssetType } from '@/utils/assetTypes';
import { mapDeep } from '@/utils/collections';
import { formatValue } from '@/utils/format';
import { sum } from '@/utils/math';
import { getPreviousRange } from '@/utils/time';
import { DateRange } from '@/utils/types/date';
import { KPI_UNIT } from '@/utils/units/unitDefinitions';
import { toUnitValue, UnitValue } from '@/utils/units/unitValue';
import {
  fakeUnref,
  InitializeReactive,
} from '@/utils/vueClassComponentHelpers';
import {
  EntityType,
  KpiDataBucketDimension,
  KPI_FIELDS,
} from '@/utils/workData/lookuptable';
import { unref, watchEffect } from 'vue';
import { Component, Vue } from 'vue-property-decorator';

@Component({
  name: 'AssetOverview',
  components: {
    WidgetCard,
    TimeSelect,
    GrowthPercentage,
  },
})
export default class extends Vue {
  formatValue = formatValue;
  greenUpArrow = greenUpArrow;
  redDownArrow = redDownArrow;

  kpiOrder: string[] = [
    KPI_FIELDS.TippingPayload,
    KPI_FIELDS.TripCount,
    KPI_FIELDS.TippingTime,
  ];
  singleAssetFilter = {
    assetIds: [this.$route.params.id],
  };
  details: any[] = [
    {
      entity: EntityType.ASSET,
      fields: [
        {
          code: KPI_FIELDS.TippingPayload,
          unit: KPI_UNIT.MetricTonne,
        },
        {
          code: KPI_FIELDS.TripCount,
          unit: KPI_UNIT.UnitCount,
        },
        {
          code: KPI_FIELDS.TippingTime,
          unit: KPI_UNIT.Second,
        },
      ],
    },
  ];

  @InitializeReactive
  assetKpis: KpiDataField[] | undefined;

  @InitializeReactive
  fleetKpis: KpiDataField[] | undefined;

  @InitializeReactive
  dateRange: DateRange | undefined;

  @InitializeReactive
  context!: ActiveContext;

  orgHierarchyQuery!: ReturnType<typeof useOrganizationAssetsHierarchyQuery>;

  unitConversion!: UseUnitConversionReturnType;

  created() {
    this.context = fakeUnref(useActiveContext());
    this.unitConversion = useUnitConversion();

    // Fetch assets (and organization IDs) for the currently selected organization
    this.orgHierarchyQuery = useOrganizationAssetsHierarchyQuery(
      AssetType.TippingVehicle
    );

    // Start fetching widget data as soon as the inputs for it become available
    // TODO Change this to reactive
    watchEffect(() => this.getData());
  }

  /**
   * Determine the list of organization IDs from the currently selected
   * customer/organization.
   *
   * Note: this ensures that whatever is fetched as fleetKpis matches the
   * number of assets of these organizations.
   */
  get organizationIds(): UUID[] | undefined {
    const hierarchy = unref(this.orgHierarchyQuery.data);
    if (!hierarchy) {
      return undefined;
    }
    return mapDeep(hierarchy, 'suborganizations', (org) => org.id);
  }

  get numberOfAssets(): number | undefined {
    const hierarchy = unref(this.orgHierarchyQuery.data);
    if (!hierarchy) {
      return undefined;
    }
    return extractAssetsFromOrganizationHierarchy(hierarchy).length;
  }

  handleTimeFilter(dateRange: DateRange) {
    this.dateRange = dateRange;
  }

  private async getData(): Promise<void> {
    if (!this.dateRange || this.numberOfAssets === undefined) {
      return undefined;
    }
    const previousDateRange = getPreviousRange(this.dateRange);
    const [fleetKpis, assetKpis, oldAssetKpis] = await Promise.all([
      this.fetchFleetKpis(this.dateRange),
      this.fetchAssetKpis(this.dateRange),
      this.fetchAssetKpis(previousDateRange),
    ]);
    this.fleetKpis = this.orderData(fleetKpis.details[0].fields);
    const assetData = this.orderData(assetKpis.details[0].fields);
    const oldAssetData = oldAssetKpis.details[0].fields;
    this.assetKpis = assetData.map((field) =>
      this.computeGrowthPercentage(field, oldAssetData)
    );
  }

  private async fetchAssetKpis(dateRange: DateRange): Promise<KpiDataResponse> {
    const result = await getKpiData(
      {
        metadata: {
          filter: this.singleAssetFilter,
          selection: {
            startDate: dateRange.start,
            endDate: dateRange.endExclusive,
            // Note: we could fetch by DBDIM_ASSET here, but because other widgets
            // on the page typically need the same data by time, it's more efficient
            // to also fetch by time here, to ensure we can get the data with a single
            // request (once we switch to Vue Query here).
            dataBucketDimension: KpiDataBucketDimension.TIME,
          },
        },
        details: this.details,
      },
      this.context
    );
    return result.data;
  }

  private async fetchFleetKpis(dateRange: DateRange): Promise<KpiDataResponse> {
    const result = await getKpiData(
      {
        metadata: {
          filter: {
            assetTypeCode: AssetType.TippingVehicle,
            organizationIds: this.organizationIds,
          },
          selection: {
            startDate: dateRange.start,
            endDate: dateRange.endExclusive,
            // Note: we're fetching by DBDIM_TIME, because fetching
            // by DBDIM_ASSET requires us to iterate over
            // each individual asset (which is very costly in case
            // of a large number of assets).
            // So instead of just averaging each DBDIM_ASSET asset, we
            // sum the whole fleet and then divide by the number of assets.
            // Note that this doesn't allow us to filter out assets that
            // e.g. had no tippings at all, but for now, this isn't needed anyway.
            // Using DBDIM_TIME instead of DBDIM_ASSET uses ~400ms instead of ~7s
            // at the time of writing, so it is/was worth the improvement.
            dataBucketDimension: KpiDataBucketDimension.TIME,
          },
        },
        details: this.details.map((detail) => ({
          ...detail,
          entity: EntityType.FLEET,
        })),
      },
      this.context
    );
    return result.data;
  }

  // TODO Get rid of this function, and just iterate over this.kpiOrder as needed
  private orderData(data: KpiDataField[]) {
    // Keep only the codes that we need: due to a bug in KPI microservice,
    // see comment above in `this.details`.
    const filtered = data.filter((a) => this.kpiOrder.includes(a.code));
    const sorted = filtered.sort((a, b) => {
      return this.kpiOrder.indexOf(a.code) - this.kpiOrder.indexOf(b.code);
    });
    return sorted;
  }

  private computeGrowthPercentage(
    currentAssetData: KpiDataField,
    lastAssetData: KpiDataField[]
  ): KpiDataField {
    const oldField = lastAssetData.find(
      (oldField) => oldField.code === currentAssetData.code
    );
    if (!oldField || !this.numberOfAssets) return currentAssetData;

    const currentValue = this.sumOfSelectedPeriod(currentAssetData.values);
    const lastValue = this.sumOfSelectedPeriod(oldField.values);
    const canCalculatePercentage = lastValue != 0;

    return {
      ...currentAssetData,
      growthPercentage: canCalculatePercentage
        ? (100 * (currentValue - lastValue)) / lastValue
        : undefined,
    };
  }

  sumOfSelectedPeriod(values: KpiDataValue[]): number {
    const total = values
      .map((value) => (value.v ? parseFloat(value.v) : 0))
      .reduce(sum, 0);
    return total;
  }

  convertUnit(field: KpiDataField, value: number): UnitValue<number> {
    return this.unitConversion.currentUserConvertUnitValue(
      toUnitValue(value, field.unit)
    );
  }
}
</script>

<template>
  <WidgetCard class="widget-main-container" :loading="!assetKpis">
    <TimeSelect
      class="time-selector-element"
      :expanded="true"
      @select="handleTimeFilter"
      :customizable="true"
    />
    <div class="status-container">
      <div
        class="status-card"
        v-for="(status, index) in assetKpis ?? []"
        :key="status.code"
      >
        <div class="status-card-title">
          {{ $t(`tippingVehicles.assetOverview.${status.code}`) }}
          {{
            status.unit !== 'UNIT_COUNT'
              ? `(${$t(`tippingVehicles.assetOverview.units.${status.unit}`)})`
              : ''
          }}
        </div>
        <div class="status-card-body">
          <div class="status-card-line">
            <span class="label">{{
              $t('tippingVehicles.assetOverview.actual')
            }}</span>
            <span class="value">{{
              formatValue(
                convertUnit(status, sumOfSelectedPeriod(status.values)),
                { maxDecimals: 1 }
              )
            }}</span>
          </div>
          <div class="status-card-line">
            <span class="label">{{
              $t('tippingVehicles.assetOverview.fleetAvg')
            }}</span>
            <span class="value">{{
              formatValue(
                convertUnit(
                  status,
                  sumOfSelectedPeriod(fleetKpis?.[index].values ?? []) /
                    (numberOfAssets ?? 0)
                ),
                { maxDecimals: 1 }
              )
            }}</span>
          </div>
          <div class="growth-container">
            <GrowthPercentage
              class="percentage"
              :growthPercentage="status.growthPercentage"
            />
            <el-tooltip
              v-if="
                $te(`tippingVehicles.assetOverview.description.${status.code}`)
              "
              placement="top"
              popper-class="info-tooltip custom-asset-overview-info-tooltip-width"
            >
              <i class="el-icon-info"></i>
              <div slot="content">
                <div class="info-text">
                  {{
                    $t(
                      `tippingVehicles.assetOverview.description.${status.code}`
                    )
                  }}
                </div>
              </div>
            </el-tooltip>
          </div>
        </div>
      </div>
    </div>
  </WidgetCard>
</template>

<style>
/*
  Scoped styles are designed to apply only to the elements that are present when the component is initially rendered.
  Because, the tooltip is not initially part of the DOM, only added when we hover it, we need to add this "global style"
  with a custom name, so there's a small chance that this style spills over to a different component.
*/
.custom-asset-overview-info-tooltip-width {
  width: 400px;
}
</style>

<style lang="css" scoped>
.widget-main-container :deep(.widget-card-body) {
  overflow: hidden;
}

.time-selector-element {
  margin: 10px 0px 0px 20px;
}

.growth-container {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 8px;
  padding: 0px 18px;
}

.info-text {
  font-size: 16px;
  color: #373e41;
}
</style>

<style lang="scss" scoped>
.percentage {
  display: flex;
  justify-content: flex-end;
}

.status-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 30px 20px 20px 20px;
}

.status-card {
  display: flex;
  flex-direction: column;
  min-width: 200px;
  height: 170px;
  border: 1px solid #e5e5e5;
  flex: 1;
}

.status-card-title {
  height: 48px;
  background-color: #f3f3f3;
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-items: center;
}

.status-card-body {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  flex-grow: 1;
}

.status-card-line {
  display: flex;
  justify-content: space-between;
  padding: 2px 19px 0px 16px;

  .label {
    font-family: Roboto;
  }

  .value {
    font-family: Roboto;
    font-weight: 700;
    font-size: 18px;
  }
}

@media screen and (max-width: 1756px) {
  .status-card {
    min-width: 160px;
  }
}

@media screen and (min-width: 1920px) {
  .status-card {
    min-width: 200px;
  }
}
</style>
