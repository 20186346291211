<script lang="ts">
import { getModules, getPagesByModuleCode } from '@/api/dashboardConf';
import {
  getWidgetsByPageCodeAndTemplateId,
  IncludedWidget,
} from '@/api/widgets';
import { NUMBER_OF_GRID_COLUMNS, scaleHorizontal } from '@/utils/widget';
import { PAGE_NO_WIDGET } from '@/utils/workData/dashboardConf';
import { COMPANY_TYPE } from '@/utils/workData/lookuptable';
import { PAGE_LAYOUT_MAP } from '@/utils/workData/utilMap';
import { Tree } from 'element-ui';
import { TreeData } from 'element-ui/types/tree';
import VueGridLayout from 'vue-grid-layout';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { filterModulesForCompanyType } from './moduleFiltering';
import { modulesToTreeData, ModuleTreeData } from './tree';

@Component({
  name: 'ViewDashTemplate',
  components: {
    'grid-layout': VueGridLayout.GridLayout,
    'grid-item': VueGridLayout.GridItem,
    'el-tree': Tree,
  },
})
export default class extends Vue {
  @Prop() templateId!: string;

  pageNoWidget: any = PAGE_NO_WIDGET;
  pageNoWidgetFlag: boolean = false;
  loadingPage: boolean = false;
  loadingLayout: boolean = false;
  loadingModules: boolean = false;
  colNum: number = 12;

  treeData: TreeData[] = [];
  highlightCode: string = '';
  pages: any = [];
  layout: IncludedWidget[] = [];
  currentModule: string = '';

  @Watch('pages')
  listenLoadPages() {
    this.$nextTick(() => {
      this.renderPageBg(0);
    });
  }

  // handleEdit(){
  //     this.$router.push(`/dashboard-conf/edit/${this.id}`)
  // }

  async handleNodeClick(node: any) {
    if (node.children.length === 0) {
      this.loadingPage = true;
      const res = await getPagesByModuleCode(this.templateId, node.name);
      let resTmp = res.data;
      this.pages = resTmp;

      this.loadingPage = false;

      this.handlePageClick(0, resTmp[0]?.code);
    }
  }

  cancelModulePageCheckboxBgColor() {
    var modulePageCheckbox =
      document.getElementsByClassName('module-page-title');
    Array(...modulePageCheckbox).forEach((item: any) => {
      item.style.backgroundColor = 'transparent';
    });
  }

  renderPageBg(index: number) {
    this.cancelModulePageCheckboxBgColor();
    var modulePageCheckbox: any =
      document.getElementsByClassName('module-page-title');
    if (modulePageCheckbox[index]) {
      modulePageCheckbox[index].style.backgroundColor = 'var(--Main)';
    }
  }

  handlePageClick(index: number, pageCode: string) {
    /** background */
    this.renderPageBg(index);

    this.fetchWidgets(pageCode);
  }

  setCurrentModule(moduleObj: ModuleTreeData) {
    if (moduleObj.children.length > 0) {
      this.setCurrentModule(moduleObj.children[0]);
    } else {
      this.currentModule = moduleObj.name;
    }

    this.$nextTick(() => {
      (this.$refs.testTree as any).setCurrentKey(this.currentModule);
    });

    this.loadingModules = false;

    this.handleNodeClick({ name: this.currentModule, children: [] });
  }

  async fetchModules(id: string, companyType: COMPANY_TYPE) {
    this.loadingModules = true;

    const res = await getModules(id);
    if (res.data.length > 0) {
      const filteredModules = filterModulesForCompanyType(
        res.data,
        companyType
      );
      const treeData = modulesToTreeData(filteredModules);
      this.treeData = treeData;
      this.setCurrentModule(treeData[0]);
    }
  }

  async fetchWidgets(pageCode: string) {
    if (this.pageNoWidget.includes(pageCode)) {
      this.pageNoWidgetFlag = true;
      this.layout = [];
    } else {
      this.pageNoWidgetFlag = false;
      this.loadingLayout = true;
      this.colNum = PAGE_LAYOUT_MAP.get(pageCode)?.column ?? 12;

      const res = await getWidgetsByPageCodeAndTemplateId(
        pageCode,
        this.templateId
      );
      this.layout = scaleHorizontal(
        res.data,
        NUMBER_OF_GRID_COLUMNS,
        this.colNum
      );

      this.loadingLayout = false;
    }
  }
}
</script>

<template>
  <el-row class="new-template-content flex-wrap" type="flex">
    <el-col :span="4">
      <div>
        <div class="module-page-widget-title">
          {{ $t('dashboardConf.modules') }}
        </div>
        <div class="dashboard-view-tree-container" v-loading="loadingModules">
          <el-tree
            id="view_template_tree"
            class="viewTmp-tree"
            ref="testTree"
            node-key="name"
            :data="treeData"
            :iconClass="'#'"
            :default-expand-all="true"
            :current-node-key="highlightCode"
            @node-click="handleNodeClick"
          />
        </div>
      </div>
    </el-col>

    <el-col
      :span="20"
      style="border-left: 1px solid #dddddd; padding-right: 20px"
    >
      <div class="module-page-widget-title">
        {{ $t('dashboardConf.pages') }}
      </div>
      <div
        class="d-flex jc-start"
        style="border-bottom: 1px solid #dddddd; height: 50px"
        v-loading="loadingPage"
      >
        <div
          class="module-page-title"
          v-for="(item, index) in pages"
          :key="index"
          :id="item.code"
          @click="handlePageClick(index, item.code)"
        >
          {{ $t('pageTitle.' + item.code) }}
        </div>
      </div>
      <div class="mainGridLayoutContent" v-loading="loadingLayout">
        <div
          v-show="pageNoWidgetFlag"
          class="d-flex d-flex-col ai-center"
          style="padding-top: 18%"
        >
          <img
            src="@/assets/imgs/noWidget.svg"
            style="width: 61px; height: 61px"
          />
          <div class="layout-no-widget">
            No widget is available for this page!
          </div>
        </div>
        <grid-layout
          v-show="!pageNoWidgetFlag"
          ref="gridlayout"
          :layout.sync="layout"
          :col-num="colNum"
          :row-height="130"
          :is-draggable="false"
          :is-resizable="false"
          :vertical-compact="true"
          :use-css-transforms="true"
        >
          <grid-item
            v-for="(item, index) in layout"
            :key="index"
            :x="item.x"
            :y="item.y"
            :w="item.w"
            :h="item.h"
            :i="item.i"
            drag-allow-from=".vue-draggable-handle"
          >
            <div
              class="grid-card"
              :id="'LAYOUT_' + item.pageCode + '_' + item.code"
            >
              <div style="margin-top: 50px">{{ $t(item.code) }}</div>
            </div>
          </grid-item>
        </grid-layout>
      </div>
    </el-col>
  </el-row>
</template>

<style lang="scss" scoped>
/* Scrollbar slider */
::-webkit-scrollbar-thumb {
  background-color: #a1a3a9;
  border-radius: 5px;
}

::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

.vue-grid-layout {
  // background: #eee;
}

.vue-grid-item:not(.vue-grid-placeholder) {
  border: 1px solid #373e41;
}

.vue-grid-item .resizing {
  opacity: 0.9;
}

.vue-grid-item .static {
  background: #cce;
}

.vue-grid-item .grid-card {
  font-size: 24px;
  text-align: center;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  height: 100%;
  width: 100%;
}

.vue-grid-item .no-drag {
  height: 100%;
  width: 100%;
}

.vue-grid-item .minMax {
  font-size: 12px;
}

.vue-grid-item .add {
  cursor: pointer;
}

.vue-draggable-handle {
  position: absolute;
  // width: 20px;
  // height: 20px;
  top: 0;
  left: 0;
  padding: 0 8px 8px 0;
  // background-origin: content-box;
  // background-color: black;
  // box-sizing: border-box;
  // border-radius: 10px;
  cursor: pointer;
}

.new-template-content {
  min-height: 83vh;
  // margin-top:50px;
  // border-top: 1px solid pink;
}

.module-page-widget-title {
  padding: 10px 0 10px 10px;
  font-size: 18px;
  font-family: $font-Roboto-Medium;
  line-height: 21px;
  color: #373e41;
  border-bottom: 1px solid #dddddd;
}

.module-page-title {
  height: 50px;
  border-right: 1px solid #dddddd;
  font-size: 16px;
  font-family: $font-Roboto-Regular;
  line-height: 50px;
  color: #373e41;
  padding: 0 16px;
  cursor: pointer;
}

.mainGridLayoutContent {
  width: 100%;
  min-height: 73vh;
  background: rgba(228, 228, 228, 0.6);
}

.dashboard-view-tree-container {
  height: calc(100% - 40px);
  overflow-y: auto;
  // margin-right: 20px;
}

.viewTmp-tree :deep(.el-tree-node) {
  margin-bottom: 0px !important;
}

.viewTmp-tree :deep(.el-tree-node__content) {
  // margin: 10px 0px;
  height: 40px;
  border-bottom: 1px solid #dddddd;
}

.viewTmp-tree :deep(.el-tree-node.is-current > .el-tree-node__content) {
  background-color: var(--Main);
}

.layout-no-widget {
  margin-top: 34px;
  font-size: 34px;
  font-family: $font-Roboto-Regular;
  line-height: 45px;
  color: #373e41;
  opacity: 0.5;
}
</style>
