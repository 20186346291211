<script lang="ts">
import FeaturesTable from '@/components/table/FeaturesTable.vue';
import { FEATURES_TABLE_COLS } from '@/utils/workData/subscriptionPackages';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { SystemFeatureValue } from '../CreatePackage.vue';

@Component({
  name: 'Features',
  components: {
    'features-table': FeaturesTable,
  },
})
export default class extends Vue {
  @Prop({ required: true }) featuresData?: SystemFeatureValue[];
  @Prop({ required: true }) isEditable!: boolean;

  /** Local variables */
  cols = FEATURES_TABLE_COLS;
}
</script>

<template>
  <div class="features-body">
    <div v-if="featuresData === undefined" class="no-features-container">
      {{ $t('subscriptionPackages.selectPackageType') }}.
    </div>
    <div v-else-if="featuresData.length === 0" class="no-features-container">
      {{ $t('subscriptionPackages.noFeaturesForThisPackageType') }}.
    </div>
    <div v-else="featuresData.length > 0">
      <features-table
        :tableList="featuresData"
        :cols="cols"
        :isEditable="isEditable"
      />
    </div>
  </div>
</template>

<style scoped>
.features-body {
  width: 50rem;
}

.no-features-container {
  color: red;
  font-family: var(--fontRobotoRegular);
  font-size: 16px;
  font-weight: bold;
  margin: 20px 0 20px 20px;
}
</style>
