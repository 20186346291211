<script lang="ts">
import { getAssetLocation, getAssetsWithLocation } from '@/api/assetLocation';
import { getAssetById } from '@/api/assets';
import { UUID } from '@/api/common';
import { Geofence, GeofenceAsset } from '@/api/geofenceTypes';
import { ActiveContext, useActiveContext } from '@/auth/context';
import LeafletMap from '@/components/leafletMap/LeafletMap.vue';
import FleetStatus from '@/components/operationalStatus/FleetStatus.vue';
import { AssetType } from '@/utils/assetTypes';
import { calculateAssetBoundsWithPadding } from '@/utils/bounds';
import { InitializeReactive } from '@/utils/vueClassComponentHelpers';
import { OperationalStatus } from '@/utils/workData/operationalStatus';
import { Ref, unref } from 'vue';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

const components = {
  LeafletMap,
  FleetStatus,
} as const;

@Component({
  name: 'MapExpanded',
  components,
})
export default class extends Vue {
  static components: typeof components;

  @Prop() geofences?: Geofence[];

  searchque: string = '';
  assetList: GeofenceAsset[] = [];
  center: number[] = [0, 0];
  zoom: number = 10;
  show: boolean = false;
  selectedAssetId: UUID = '';

  @InitializeReactive
  activeOperationalStatuses: Set<OperationalStatus> | undefined;

  loading: boolean = true;

  $refs!: {
    map: LeafletMap;
  };

  computed: any = {
    columns: function columns() {
      if (this.rows.length == 0) return [];
      return Object.keys(this.rows[0]);
    },
  };

  context!: Ref<ActiveContext>;

  @Watch('geofences')
  onGeofenceInit() {
    if (this.geofences) {
      this.$refs.map?.initializeGeofence(this.geofences);
    }
  }

  created() {
    this.context = useActiveContext();
  }

  mounted() {
    this.updateAssets();
  }

  private async updateAssets() {
    let assetType: AssetType = AssetType.All;

    switch (this.$route.name) {
      case 'StaticCompactors':
        assetType = AssetType.StaticCompactor;
        break;
      case 'AlbaStaticCompactors':
        assetType = AssetType.AlbaStaticCompactor;
        break;
      case 'MobileCompactors':
        assetType = AssetType.MobileCompactor;
        break;
      case 'TippingVehicles':
        assetType = AssetType.TippingVehicle;
        break;
      case 'RefuseCollectionVehicles':
        assetType = AssetType.RefuseCollectionVehicle;
        break;
      case 'GenericAssets':
        assetType = AssetType.GenericAsset;
        break;
      default:
        assetType = AssetType.All;
        break;
    }

    if (this.$route.params.id) {
      const assetId = this.$route.params.id;
      const { data } = await getAssetById(assetId, unref(this.context));
      const { data: assets } = await getAssetLocation(
        data,
        unref(this.context)
      );

      // AHMAPP-5116 discovery:
      // With the following line enabled the map is zoomed out to the the specified zoom level at the first assets coordinates.
      // With the following line disabled the map is zoomed out to the the specified zoom level at specified geo coordinates [0,0].
      // This is different from Map.vue where geofences are rendered as well, causing different behavior.

      this.center = [assets[0].geodeticLatitude, assets[0].geodeticLongitude];

      this.assetList = assets;
      this.loading = false;
      return;
    }

    const { data: assets } = await getAssetsWithLocation(
      assetType,
      unref(this.context)
    );

    const bounds = calculateAssetBoundsWithPadding(assets);
    if (bounds.isValid()) {
      this.$nextTick(() => {
        this.$refs.map?.setBounds(bounds);
      });
    }

    this.assetList = assets;
    this.loading = false;
  }

  get assetIdFilter(): UUID[] | undefined {
    const id = this.$route.params.id;
    return id ? [id] : undefined;
  }

  handleSelectAsset(assetId: UUID) {
    this.selectedAssetId = assetId;
  }

  onOperationalStatusChange(activeStatuses: Set<OperationalStatus>) {
    this.activeOperationalStatuses = activeStatuses;
  }

  get assetListFiltered(): GeofenceAsset[] {
    const activeStatuses = this.activeOperationalStatuses;

    return activeStatuses
      ? this.assetList.filter(
          (asset) =>
            asset.operationalStatus !== undefined &&
            activeStatuses.has(asset.operationalStatus)
        )
      : this.assetList;
  }
}
</script>

<template>
  <div class="container">
    <FleetStatus
      class="leftwidget"
      :allow-filtering="!assetIdFilter"
      :asset-id-filter="assetIdFilter"
      :list-mode="!!assetIdFilter"
      @select-asset="handleSelectAsset"
      @operational-status-change="onOperationalStatusChange"
    />
    <div class="rightwidget">
      <LeafletMap
        ref="map"
        :center="center"
        :zoom="zoom"
        :assets="assetListFiltered"
        :selectedAssetId="selectedAssetId"
        :geofenceConfig="{ enableCreate: false, enableEdit: false }"
      />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  max-width: 100%;
  max-height: 100%;
  margin: 0;
  height: 1100px;
}

.leftwidget {
  width: 620px;
}

.rightwidget {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.search-box {
  width: 100%;
  background: white;
  border: 2px solid white;
  border-top-left-radius: 10px;
  position: center;
  padding: 2% 4% 2% 4%;
  display: auto;
}

.srcbox {
  border: 1px solid rgb(166, 166, 166);
  padding: 1.5% 0% 1.5% 1.5%;
  border-radius: 5px;
}

.assetsTable {
  overflow: auto;
}

.tableInner {
  position: fixed;
}

.mod-input {
  width: 90%;
  border: 0.5px solid white;
}

.mod-input:focus {
  outline: none;
}

.svg-icon {
  width: 16px;
  height: 16px;
  color: inherit;
  stroke: currentColor;
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
}

.svg-fill {
  fill: currentColor;
  stroke: none;
}

.svg-up {
  transform: rotate(0deg);
}

.svg-right {
  transform: rotate(90deg);
}

.svg-down {
  transform: rotate(180deg);
}

.svg-left {
  transform: rotate(-90deg);
}

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-thumb {
  background: rgb(199, 195, 195);
}

::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0.2);
}

.widget-card-container {
  border-radius: 0%;
}
</style>
