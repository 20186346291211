import { render, staticRenderFns } from "./ModelSpecForm.vue?vue&type=template&id=3cc29470&scoped=true"
import script from "./ModelSpecForm.vue?vue&type=script&lang=ts"
export * from "./ModelSpecForm.vue?vue&type=script&lang=ts"
import style0 from "./ModelSpecForm.vue?vue&type=style&index=0&id=3cc29470&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3cc29470",
  null
  
)

export default component.exports