var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{ref:"tableRef",staticStyle:{"flex-grow":"1"}},[_c('el-table',{directives:[{name:"show",rawName:"v-show",value:(_vm.tableHeight !== 0),expression:"tableHeight !== 0"}],attrs:{"data":_vm.list,"tree-props":_vm.treeProp,"row-key":"id","height":_vm.tableHeight,"row-style":{ height: '40px' },"cell-style":{ padding: '7px 0px' }},on:{"row-click":_vm.handleRowClick,"sort-change":_vm.handleSortChange}},_vm._l((_vm.activeCols),function(col,index){return _c('el-table-column',{key:index + '_' + _vm.tableComponentKey,attrs:{"prop":col.prop,"min-width":"180","sortable":col.sortable,"width":col.width ? col.width : 'auto'},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('div',{staticClass:"table-header"},[_c('span',{staticStyle:{"white-space":"nowrap"}},[_vm._v(_vm._s(_vm.$t(_vm.activeCols[index]?.label)))])])]},proxy:true},(col.multiLang)?{key:"default",fn:function(slotProps){return [_c('span',[_vm._v(_vm._s(_vm.$t(slotProps.row[col.prop])))])]}}:(col.dateFormat === 'date' || col.dateFormat === 'datetime')?{key:"default",fn:function(slotProps){return [_c('span',[_vm._v(_vm._s(_vm._f("formatTimer")(slotProps.row[col.prop],col.dateFormat)))])]}}:(col.prop === 'action')?{key:"default",fn:function(slotProps){return [_c('div',{staticClass:"report-action-column"},_vm._l((slotProps.row[col.prop]),function(item,index){return _c('el-tooltip',{key:`event-${index}`,staticClass:"item",attrs:{"disabled":!(
                item === _vm.getAction() &&
                (slotProps.row['isDisabled'] === true ||
                  slotProps.row['numberOfSubscriptions'] > 0)
              ),"content":item === _vm.getAction() &&
              (slotProps.row['isDisabled'] === true ||
                slotProps.row['numberOfSubscriptions'] > 0)
                ? _vm.$t('report.disableForActiveSubscriptions', {
                    activeSubscriptions:
                      slotProps.row['numberOfSubscriptions'],
                  }).toString()
                : ''}},[_c('span',[_c('el-button',{key:index,staticClass:"report-action-btn2",attrs:{"disabled":item === _vm.getAction() &&
                  (item.disabled === true ||
                    slotProps.row['isDisabled'] === true ||
                    slotProps.row['numberOfSubscriptions'] > 0),"size":"small"},on:{"click":function($event){return _vm.handleActionBtnClick(item, slotProps.row)}}},[_vm._v(" "+_vm._s(_vm.$t(item.name))+" ")])],1)])}),1)]}}:null],null,true)})}),1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }