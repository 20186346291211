<script lang="ts" setup>
import { getAssetById } from '@/api/assets';
import { useActiveContext } from '@/auth/context';
import WidgetLayout from '@/components/layout/widget/WidgetLayout.vue';
import { useAsync } from '@/composables/async';
import { useRoute } from '@/composables/router';
import { useBreadcrumbs } from '@/composables/title';
import { computed, unref } from 'vue';

const route = useRoute();

const activeContext = useActiveContext();
const assetRef = useAsync(
  computed(() =>
    route.value.params.id
      ? getAssetById(route.value.params.id, unref(activeContext))
      : undefined
  )
);
const crumbs = computed(() => assetRef.value?.data?.data.companyAssetId);

useBreadcrumbs(crumbs);

const code = computed(() => {
  const metaCode = route.value.meta?.code;
  if (typeof metaCode !== 'string') {
    throw new Error('invalid or missing page code in route meta');
  }

  return metaCode;
});

const blacklist = computed(() => [`${code.value.replace('MOD', 'PAGE')}_HOME`]);
</script>

<template>
  <WidgetLayout
    :page-code="code"
    :tabbed="true"
    :tab-blacklist="blacklist"
    :hideConfigurable="true"
    style="height: 100%"
  />
</template>
