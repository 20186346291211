export interface Col {
  label: string;
  prop: string;
  visible: boolean;
  required?: boolean;
  sortable?: string;
}

export const MAINT_CONF_LIST = [
  {
    label: 'maintConf.itemName',
    prop: 'name',
    required: true,
    visible: true,
    sortable: 'custom',
  },
  {
    label: 'maintConf.assetType',
    prop: 'translatedAssetType',
    alias: 'assetType',
    required: true,
    visible: true,
    multiLang: true,
    sortable: 'custom',
  },
  {
    label: 'maintConf.productModel',
    prop: 'productModel',
    required: true,
    visible: true,
    sortable: 'custom',
  },
  {
    label: 'maintConf.replaceCount',
    prop: 'numberOfPartsToReplace',
    required: false,
    visible: true,
    sortable: 'custom',
  },
  {
    label: 'maintConf.checkCount',
    prop: 'numberOfPartsToCheck',
    required: false,
    visible: true,
    sortable: 'custom',
  },
  {
    label: 'maintConf.applyRulesCount',
    prop: 'numberOfRules',
    required: false,
    visible: true,
    sortable: 'custom',
  },
  {
    label: 'maintConf.itemStatus',
    prop: 'status',
    required: true,
    visible: true,
    multiLang: true,
    sortable: 'custom',
  },
];

export const MAINT_CONF_SEARCH_FIELDS = [
  {
    label: 'maintConf.searchItemName',
    prop: 'name',
  },
  {
    label: 'maintConf.itemStatus',
    prop: 'status',
  },
  // {
  //     label: 'maintConf.assetType',
  //     prop: 'assetType',
  // },
  // {
  //     label: 'maintConf.productModel',
  //     prop: 'productModelName',
  // },
  // {
  //     label: 'maintConf.replaceCount',
  //     prop: 'replacePartCount',
  // },
  // {
  //     label: 'maintConf.checkCount',
  //     prop: 'checkPartCount',
  // },
  // {
  //     label: 'maintConf.applyRulesCount',
  //     prop: 'ruleCount',
  // },
];

export const ASSET_MAINTENANCE_ITEM_COLS = [
  {
    label: 'maintenance.assetId',
    prop: 'companyAssetId',
    required: true,
    visible: true,
  },
  {
    label: 'maintenance.assetType',
    prop: 'assetType',
    required: true,
    visible: true,
  },
  {
    label: 'maintenance.organization',
    prop: 'organizationName',
    required: true,
    visible: true,
  },
  {
    label: 'maintenance.maintenanceItem',
    prop: 'maintenanceItemName',
    required: true,
    visible: true,
  },
  {
    label: 'maintenance.lastMaintenanceDate',
    prop: 'lastMaintenanceDate',
    required: true,
    visible: true,
    dateFormat: 'date',
  },
  {
    label: 'maintenance.nextMaintenanceDate',
    prop: 'nextMaintenanceDate',
    required: true,
    visible: true,
    dateFormat: 'date',
  },
  {
    label: 'maintenance.status',
    prop: 'status',
    required: true,
    visible: true,
  },
  {
    label: 'maintenance.maintenanceMonitor',
    prop: 'maintenanceMonitor',
    required: true,
    visible: true,
  },
];

export const SINGLE_ASSET_MAINTENANCE_ITEM_COLS = [
  {
    label: 'maintenance.maintenanceItem',
    prop: 'maintenanceItemName',
    required: true,
    visible: true,
  },
  {
    label: 'maintenance.lastMaintenanceDate',
    prop: 'lastMaintenanceDate',
    required: true,
    visible: true,
    dateFormat: 'date',
  },
  {
    label: 'maintenance.nextMaintenanceDate',
    prop: 'nextMaintenanceDate',
    required: true,
    visible: true,
    dateFormat: 'date',
  },
  {
    label: 'maintenance.status',
    prop: 'status',
    required: true,
    visible: true,
  },
  {
    label: 'maintenance.maintenanceMonitor',
    prop: 'maintenanceMonitor',
    required: true,
    visible: true,
  },
];

export const ASSET_MAINTENANCE_HISTORY_COLS = [
  {
    label: 'maintenance.maintenanceHistory.assetId',
    prop: 'companyAssetId',
    visible: true,
    sortable: 'custom',
  },
  {
    label: 'maintenance.maintenanceHistory.assetType',
    prop: 'assetTypeTranslated',
    visible: true,
    sortable: 'custom',
  },
  {
    label: 'maintenance.maintenanceHistory.productModel',
    prop: 'productModel',
    visible: true,
    sortable: 'custom',
  },
  {
    label: 'maintenance.maintenanceHistory.date',
    prop: 'appointmentTime',
    visible: true,
    sortable: 'custom',
  },
  {
    label: 'maintenance.maintenanceHistory.maintenanceItem',
    prop: 'maintenanceItemName',
    visible: true,
    sortable: 'custom',
  },
  {
    label: 'maintenance.maintenanceHistory.note',
    prop: 'note',
    visible: true,
  },
];

export const SINGLE_ASSET_MAINTENANCE_HISTORY_COLS = [
  {
    label: 'maintenance.maintenanceHistory.maintenanceItem',
    prop: 'maintenanceItemName',
    visible: true,
  },
  {
    label: 'maintenance.maintenanceHistory.date',
    prop: 'appointmentTime',
    visible: true,
  },
  {
    label: 'maintenance.maintenanceHistory.note',
    prop: 'note',
    visible: true,
  },
];

export const MAINT_HISTORY_COLS = [
  {
    label: 'maintenance.assetId',
    prop: 'assetDisplayID',
    required: true,
    visible: true,
    sortable: true,
  },
  {
    label: 'maintenance.assetType',
    prop: 'assetType',
    required: true,
    visible: true,
    sortable: true,
  },
  {
    label: 'maintConf.productModel',
    prop: 'productModelName',
    required: true,
    visible: true,
    sortable: true,
  },
  {
    label: 'maintenance.date',
    prop: 'date',
    required: true,
    visible: true,
    sortable: true,
  },
  {
    label: 'maintenance.maintenanceItem',
    prop: 'name',
    required: true,
    visible: true,
    sortable: true,
  },
  {
    label: 'maintenance.note',
    prop: 'note',
    required: false,
    visible: true,
    sortable: true,
  },
];
