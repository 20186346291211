import { TrackingDays } from '@/api/geofenceTypes';
import { Organization, OrganizationChild } from '@/api/organizations';
import { ACTIVATION_STATUS } from './workData/lookuptable';

export const generateUrl = (filterParams: any) => {
  let result = '';
  for (let item in filterParams) {
    if (filterParams[item] && String(filterParams[item])) {
      result = result + `&${item}=` + encodeURIComponent(filterParams[item]);
    }
  }

  if (result) {
    result = '?' + result.slice(1);
  }

  return result;
};

// Switch theme
export const switchTheme = (
  className: string,
  color: string,
  hoverColor: string
) => {
  const body: any = document.querySelector('body');
  body.classList.add(className);
  document.documentElement.style.setProperty('--Main', color);
  document.documentElement.style.setProperty('--DropdownHover', hoverColor);
};

/**
 * Filter deactivate organization
 */
export const filterDeactivatedOrg = (orgs: Organization[]): Organization[] => {
  // Organization and ChildOrganization have a different structure (e.g. Organization
  // has less fields than OrganizationChild), so need a workaround to filter them
  // consistently.
  type BaseOrg = {
    activationStatus: ACTIVATION_STATUS;
    children: OrganizationChild[];
  };

  function filterActivatable<T extends BaseOrg>(orgs: T[]): T[] {
    return orgs
      .filter((org) => org.activationStatus === ACTIVATION_STATUS.Activated)
      .map((org: T) => ({
        ...org,
        children: org.children ? filterActivatable(org.children) : [],
      }));
  }

  return filterActivatable(orgs);
};

export const sortWeekDays = (weekDays: TrackingDays[]): TrackingDays[] => {
  return Object.values(TrackingDays).reduce(
    (accum: TrackingDays[], trackingDay: TrackingDays) => {
      if (weekDays.includes(trackingDay)) {
        accum.push(trackingDay);
      }
      return accum;
    },
    []
  );
};

// export const toggleClass = (ele: HTMLElement, className: string) => {
//   if (!ele || !className) {
//     return
//   }
//   let classString = ele.className
//   const nameIndex = classString.indexOf(className)
//   if (nameIndex === -1) {
//     classString += '' + className
//   } else {
//     classString =
//       classString.substr(0, nameIndex) +
//       classString.substr(nameIndex + className.length)
//   }
//   ele.className = classString
// }
