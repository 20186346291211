<script lang="ts">
import {
  getHealthFactorDefinitions,
  putHealthFactorDefinitions,
} from '@/api/healthStatus';
import BaseCard from '@/components/cusCard/BaseCard.vue';
import PureTable from '@/components/table/PureTable.vue';
import { SorterOrder } from '@/model/queryParameters/QueryParameter';
import { UserModule } from '@/store/modules/user';
import Debouncer from '@/utils/debounce';
import { customFailedMessage, customSuccessMessage } from '@/utils/prompt';
import {
  AssetTypes,
  EnabledStatus,
  HealthSearchType,
} from '@/utils/types/health';
import { HEALTH_MANAGEMENT_COLS } from '@/utils/workData/healthMgmt';
import { GENERAL_QUERY_OPERATORS } from '@/utils/workData/lookuptable';
import { Component, Vue } from 'vue-property-decorator';

@Component({
  name: 'healthMgmt',
  components: {
    'base-card': BaseCard,
    PureTable,
  },
})
export default class extends Vue {
  /** Local variables */
  cols = HEALTH_MANAGEMENT_COLS.map((item) => ({
    ...item,
    label: item.label ? this.$t(item.label) : '',
  }));
  pageTotal = UserModule.gridPageSize;
  data: any[] = [];

  firstLoad = true; // true until created => getData() is finished
  editing = false;

  searchFieldTypes: HealthSearchType[] = Object.values(HealthSearchType);
  searchField: { name: HealthSearchType; value: string } = {
    name: this.searchFieldTypes[0],
    value: '',
  };

  validSearchValues: string[] | null = null;
  queryOperator: GENERAL_QUERY_OPERATORS = GENERAL_QUERY_OPERATORS.Like;
  isHealthFactorTableLoading: boolean = false;

  created() {
    this.getData();

    // only set firstLoad to false after all data has been collected
    // otherwise the form will create a rerender and extra API calls
    this.firstLoad = false;
  }

  /**
   * Parse filter
   */
  parseFilter() {
    let selectedFilters: any = {
      filters: [],
      sorters: [
        {
          field: 'assetType',
          order: SorterOrder.ASC,
        },
      ],
    };

    // search field
    if (this.searchField.value !== '') {
      selectedFilters.filters.push({
        name: this.searchField.name,
        operator: this.queryOperator,
        value: [`${this.searchField.value}`],
      });
    }

    return selectedFilters;
  }

  /**
   * Handle get data remotly from the API
   */
  async getData(): Promise<void> {
    try {
      this.isHealthFactorTableLoading = true;
      const res = await getHealthFactorDefinitions(this.parseFilter());
      this.data = res.data.map((entry: any) => {
        return {
          id: entry.id,
          healthFactor: entry.name,
          factorType: this.$t(
            `healthManagementModule.${
              entry.errorFlag ? 'error' : 'alarmSetting'
            }`
          ),
          assetType: this.$t(entry.assetType),
          ifEnable: entry.enabled,
          normalCondition: entry?.limitNormal || entry?.flagNormal || '',
          warningCondition:
            [entry?.limitWarningHigh, entry?.limitWarningLow]
              .filter((val) => val)
              .join(' / ') || entry?.flagWarning,
          alarmCondition:
            [entry?.limitAlarmHigh, entry?.limitAlarmLow]
              .filter((val) => val)
              .join(' / ') || entry?.flagAlarm,
          editable: false,
        };
      });
      this.data.forEach((entry: any) => (entry.editable = this.editing));
    } catch (error) {
      console.log(error);
    } finally {
      this.isHealthFactorTableLoading = false;
    }
  }

  /**
   * Handle save event
   */
  async save(): Promise<void> {
    let send = this.data.map((entry: any) => {
      return {
        id: entry.id,
        isEnabled: entry.ifEnable,
      };
    });
    const updateResult = await putHealthFactorDefinitions(send);
    if (updateResult.code == 200) {
      customSuccessMessage(
        this.$t('healthManagementModule.saveSucessful').toString()
      );
    } else {
      customFailedMessage(
        this.$t('healthManagementModule.errorOccured').toString()
      );
    }
  }

  /**
   * Switch from read only to edit mode
   */
  toggleEditFactor(): void {
    this.editing && this.save();
    this.editing = !this.editing;
    this.data.forEach((entry: any) => (entry.editable = !entry.editable));
  }

  // search field name changed (e.g. Organisation)
  handleSearchFieldFilter(): void {
    let oldValidSearchValues = this.validSearchValues;
    switch (this.searchField.name) {
      case HealthSearchType.ifEnable:
        this.queryOperator = GENERAL_QUERY_OPERATORS.Equal;
        this.validSearchValues = Object.values(EnabledStatus);
        break;

      case HealthSearchType.assetType:
        this.queryOperator = GENERAL_QUERY_OPERATORS.Equal;
        this.validSearchValues = Object.values(AssetTypes);
        break;

      default:
        this.queryOperator = GENERAL_QUERY_OPERATORS.Like;
        this.validSearchValues = null;
    }

    if (
      this.validSearchValues == null ||
      oldValidSearchValues == null ||
      this.validSearchValues.join() != oldValidSearchValues.join()
    ) {
      this.searchField.value = '';
    }

    this.handleSearchFieldForm();
  }

  /**
   * Handle search field event
   */
  handleSearchFieldForm(): void {
    if (this.searchField.value.length === 0 && !this.firstLoad) {
      this.getData();
    }
    if (this.searchField.value.length > 2) {
      Debouncer.debounce(this.getData);
    }
  }
}
</script>

<template>
  <div>
    <base-card
      class="table-container"
      ref="baseCardRef"
      :title="$t('AUTHRSC_PAGE_HEALTH_FACTOR')"
      :backIconVisible="false"
      :showDialogBeforeBack="false"
      @handle-back="false"
    >
      <div class="table-filter-container">
        <!-- Search field -->
        <div class="search-container">
          <el-select
            class="filter-select"
            id="fields"
            v-model="searchField.name"
            @change="handleSearchFieldFilter"
            filterable
            default-first-option
          >
            <el-option
              class="filter-select-option"
              v-for="item in searchFieldTypes"
              :key="item"
              :label="$t('healthManagementModule.' + item)"
              :value="item"
            />
          </el-select>

          <el-input
            class="search-select-input"
            :placeholder="$t('common.inputKeywordToSearch')"
            v-if="!validSearchValues"
            v-model="searchField.value"
            @clear="handleSearchFieldForm"
            @keyup.native="handleSearchFieldForm"
            clearable
          >
            <i
              slot="suffix"
              class="el-icon-search"
              @click="handleSearchFieldForm"
            />
          </el-input>
          <el-select
            v-if="validSearchValues"
            class="search-select-input"
            v-model="searchField.value"
            @change="handleSearchFieldFilter"
            filterable
            default-first-option
          >
            <el-option
              class="filter-select-option"
              v-for="item in validSearchValues"
              :key="item"
              :label="$t(item)"
              :value="item"
            />
          </el-select>
        </div>

        <!-- Toggle Edit Factors button -->
        <el-button
          id="edit_health_factors_button-"
          v-permission="['AUTHRSC_ACTION_HEALTH_FACTOR_ENABLE']"
          v-if="!editing"
          class="toggle-edit-button"
          type="plain"
          @click="toggleEditFactor"
        >
          {{ $t('healthManagementModule.enableFactor') }}
        </el-button>
        <el-button
          v-permission="['AUTHRSC_ACTION_HEALTH_FACTOR_ENABLE']"
          v-if="editing"
          class="toggle-edit-button"
          type="plain"
          @click="toggleEditFactor"
        >
          <img src="@/assets/imgs/save.png" style="margin-right: 20px" />{{
            $t('healthManagementModule.save')
          }}
        </el-button>
      </div>
      <PureTable
        v-loading="isHealthFactorTableLoading"
        class="health-table"
        :tableList="data"
        :cols="cols"
        :showTableHeaderOptions="false"
        :editing="editing"
      />
    </base-card>
  </div>
</template>

<style lang="scss" scoped>
.table-container {
  width: 100%;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 3px 6px #c1c1c1;
  display: flex;
  flex-direction: column;

  .table-filter-container {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin: 20px;
    gap: 20px;
  }
}

.search-container {
  display: flex;
  align-items: center;

  .filter-select :deep(.el-input__inner) {
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    border-right: transparent !important;
  }

  // This is copied from the timeselector?
  .search-select-input :deep(.el-input__inner) {
    width: 300px;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
  }
}

.filter-select-assets-search-input {
  width: 180px;
  margin: 4px 20px;

  :deep(.el-input__inner) {
    width: 180px;
  }
}

.filter-select {
  margin: 0 !important;

  :deep(.el-input__inner) {
    width: 200px;
  }
}

.el-icon-search {
  line-height: 40px;
  color: rgba(0, 0, 0, 0.6);
  font-size: 18px;
  cursor: pointer;
  margin-right: 8px;
}

.toggle-edit-button {
  margin: auto 0 auto auto;
}

.toggle-edit-button :deep(> span) {
  display: flex;
  align-items: center;
}

.health-table {
  padding: 0 16px 16px 16px;
}
</style>
