import { getRequest } from '@/utils/request';
import { CommonResultPromise } from './commonResult';

export type TimezoneResponse = {
  id: string;
  fullName: string;
  abbreviation: string;
  offset: string;
  index: number;
  deprecated: boolean;
};

export function getTimezones(): CommonResultPromise<TimezoneResponse[]> {
  const request = getRequest();
  const response = request.get<TimezoneResponse[]>('/timezones');
  return response;
}
