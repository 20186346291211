<script lang="ts">
import {
  useUnitConversion,
  UseUnitConversionReturnType,
} from '@/composables/conversion';
import { formatUnitValue, UndefinedRendering } from '@/utils/format';
import { formatTimer } from '@/utils/misc';
import {
  ColumnRendering,
  ColumnRenderType,
  KpiData,
  TableColumn,
} from '@/utils/types/columnCustomizationTypes';
import { KPI_UNIT } from '@/utils/units/unitDefinitions';
import { toUnitValue } from '@/utils/units/unitValue';
import { Component, Prop, Vue } from 'vue-property-decorator';

interface Row {
  kpiData: KpiData[] | undefined;
  [extra: string]: unknown;
}

@Component({
  name: 'CustomTableCell',
})
export default class extends Vue {
  @Prop({ required: true }) column!: TableColumn;
  @Prop({ required: true }) row!: Row;
  @Prop({
    required: false,
  })
  renderingOptions?: ColumnRendering;
  @Prop({ required: false }) scope: any;

  renderComponent: boolean = false;
  computedValue: string = '';
  componentProperties: any;
  unitConversion!: UseUnitConversionReturnType;

  async created() {
    this.unitConversion = useUnitConversion();
    this.renderComponent =
      this.renderingOptions?.type === ColumnRenderType.Component;

    if (this.renderComponent) {
      if (this.renderingOptions?.getComponentProperties) {
        this.componentProperties = this.renderingOptions.getComponentProperties(
          this.row
        );
      } else {
        this.componentProperties = { row: this.row };
      }
      return;
    }

    const prop = this.column.prop;
    if (this.column.isKpiData) {
      const kpiData = this.row.kpiData?.find(
        (data) => data?.code.toLowerCase() === prop.toLowerCase()
      );

      if (kpiData === undefined) {
        this.computedValue = '';

        return;
      }

      if (kpiData.unit === KPI_UNIT.Boolean) {
        this.computedValue = kpiData.value;

        return;
      }

      this.computedValue = formatUnitValue(
        toUnitValue(parseFloat(kpiData.value), kpiData.unit),
        { undefinedAs: UndefinedRendering.Empty }
      );
    } else {
      this.computedValue = this.formatValue(
        this.row[prop],
        this.column.kpiUnitCode,
        this.scope.row.timeZone
      );
    }
  }

  private formatValue(value: any, unit: string, timeZone: string | undefined) {
    if (this.renderingOptions?.getValue) {
      value = this.renderingOptions.getValue(value);
    }

    if (value === undefined || value === null || value === '') {
      return '';
    }

    switch (this.renderingOptions?.type) {
      case ColumnRenderType.Date:
        return formatTimer(value, 'date', timeZone);

      case ColumnRenderType.DateTime:
        return formatTimer(value, 'datetime', timeZone);

      case ColumnRenderType.DateTimeUtc:
        return formatTimer(value, 'datetime', 'UTC');

      case ColumnRenderType.Translatable:
        return this.$t(
          `${this.renderingOptions.translatePrefix || ''}${value}`
        ) as string;
    }

    if (
      !this.renderingOptions?.hideKpiUnit &&
      unit &&
      unit != KPI_UNIT.Boolean
    ) {
      value = `${value} ${this.$t(unit)}`;
    }
    return value;
  }
}
</script>

<template>
  <div>
    <component
      :is="renderingOptions.getComponent"
      v-if="renderComponent && renderingOptions?.getComponent"
      v-bind="componentProperties"
    />
    <div v-else>{{ computedValue }}</div>
  </div>
</template>
