<script setup lang="ts">
import { Trip } from '@/utils/types/trip';
import { setupSelectedTrip, TripDetails } from '@/views/trip/trip';
import { onMounted, ref, watch } from 'vue';

const props = defineProps<{
  trip: Trip | undefined;
}>();

const emit = defineEmits<{
  (e: 'cancel', tripLiveIsVisible: boolean): void;
}>();

const selectedTrip = ref<TripDetails>();

watch(
  () => props.trip,
  () => {
    selectedTripSetup(props.trip);
  },
  {
    immediate: true,
    deep: true,
  }
);

onMounted(() => {
  selectedTripSetup(props.trip);
});

function selectedTripSetup(trip: Trip | undefined): void {
  if (!trip) return;
  selectedTrip.value = setupSelectedTrip(trip);
}

function handleCancel(): void {
  emit('cancel', false);
}
</script>

<template>
  <div class="trip-details-container">
    <div class="trip-details-items">
      <div
        class="trip-property-label"
        v-for="(tripProp, index) in selectedTrip"
        :key="index"
      >
        {{ tripProp.label }}:
        <div class="trip-property-value">{{ tripProp.value }}</div>
      </div>
    </div>
    <div class="close-button" @click="handleCancel">&#10005;</div>
  </div>
</template>

<style lang="scss" scoped>
.trip-details-container {
  height: 50px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  .trip-details-items {
    display: flex;
    justify-content: space-between;
    align-items: center;
    column-gap: 25px;
    margin-left: 20px;
  }

  .close-button {
    font-size: 25px;
    font-weight: bold;
    align-self: center;
    margin-right: 10px;
    margin-top: -5px;
    cursor: pointer;
  }

  .trip-property-label {
    display: flex;
    font-family: Roboto;
    font-weight: 400;
    font-size: 16px;
    color: #373e41;
    line-height: 18.75px;

    .trip-property-value {
      font-weight: 700;
      margin: auto auto auto 9px;
      font-size: 16px;
    }
  }
}
</style>
