var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.isOrganizationManagementLoading),expression:"isOrganizationManagementLoading"}],attrs:{"element-loading-text":_vm.loadingText}},[(
      !_vm.organizationInputFormIsVisible && !_vm.organizationDetailViewFormIsVisible
    )?_c('div',[_c('div',{staticClass:"d-flex jc-start ai-center",staticStyle:{"margin":"20px 0"}},[_c('div',{staticClass:"d-flex ai-center flex-g1"}),_c('div',{staticClass:"d-flex ai-center"},[_c('el-button',{staticStyle:{"margin-right":"5px"},attrs:{"type":"plain"},on:{"click":_vm.showOrganizationInputForm}},[_c('i',{staticClass:"el-icon-plus common-icon"}),_vm._v(_vm._s(_vm.$t('organizationModule.addNewOrganization'))+" ")])],1)]),_c('el-row',[_c('pure-table',{staticClass:"orgTable",attrs:{"tableList":_vm.tableData,"total":0,"cols":_vm.cols,"treeProp":_vm.treeProp,"expandAll":true},on:{"handle-page":_vm.fetchOrganizationsByPageSelection,"handle-sort-change":_vm.fetchOrganizationsBySortEvent,"row-click":_vm.viewOrganizationRowItem}})],1)],1):_vm._e(),(_vm.organizationDetailViewFormIsVisible)?_c('div',[_c('div',{staticClass:"d-flex jc-start ai-center",staticStyle:{"margin":"20px 0"}},[_c('div',{staticClass:"d-flex ai-center flex-g1"}),_c('div',{staticClass:"d-flex ai-center"},[_c('el-button',{staticStyle:{"margin-right":"5px"},attrs:{"type":"plain"},on:{"click":_vm.editSelectedOrganization}},[_c('i',{staticClass:"el-icon-plus common-icon"}),_vm._v(_vm._s(_vm.$t('common.edit'))+" ")])],1),_c('el-button',{attrs:{"type":"plain"},on:{"click":_vm.showModalWindow}},[_c('div',{staticClass:"d-flex ai-center jc-center"},[_c('div',[_c('img',{staticStyle:{"margin-right":"5px"},attrs:{"src":require("@/assets/imgs/deactivate.svg")}})]),_c('div',[_vm._v(" "+_vm._s(_vm.activateOrDeactivate === 'deactivate' ? _vm.$t('common.deactivate') : _vm.$t('common.activate'))+" ")])])]),_c('el-button',{staticStyle:{"margin-right":"5px"},attrs:{"type":"plain"},on:{"click":_vm.cancelOrganizationManagementForm}},[_c('i',{staticClass:"el-icon-close common-icon"}),_vm._v(_vm._s(_vm.$t('common.cancelBtn'))+" ")])],1),_c('org-detail-page',{attrs:{"orgInfo":_vm.orgForm}})],1):_vm._e(),(
      _vm.organizationInputFormIsVisible && !_vm.organizationDetailViewFormIsVisible
    )?_c('div',[_c('div',{staticClass:"d-flex jc-start ai-center",staticStyle:{"margin":"20px 0"}},[_c('div',{staticClass:"d-flex ai-center flex-g1"}),_c('div',{staticClass:"d-flex ai-center"},[_c('el-button',{staticStyle:{"margin-right":"5px"},attrs:{"type":"plain"},on:{"click":_vm.validateClickAction}},[_c('i',{staticClass:"el-icon-plus common-icon"}),_vm._v(_vm._s(_vm.$t('common.save'))+" ")]),(_vm.treeSelectIsDisabled)?_c('el-button',{attrs:{"type":"plain"},on:{"click":_vm.showModalWindow}},[_c('div',{staticClass:"d-flex ai-center jc-center"},[_c('div',[_c('img',{staticStyle:{"margin-right":"5px"},attrs:{"src":require("@/assets/imgs/deactivate.svg")}})]),_c('div',[_vm._v(" "+_vm._s(_vm.activateOrDeactivate === 'deactivate' ? _vm.$t('common.deactivate') : _vm.$t('common.activate'))+" ")])])]):_vm._e(),_c('el-button',{staticStyle:{"margin-right":"5px"},attrs:{"type":"plain"},on:{"click":_vm.cancelOrganizationManagementForm}},[_c('i',{staticClass:"el-icon-close common-icon"}),_vm._v(_vm._s(_vm.$t('common.cancelBtn'))+" ")])],1)]),_c('organization-management-form',{ref:"organizationManagementForm",attrs:{"orgForm":_vm.orgForm,"treeSelectIsDisabled":_vm.treeSelectIsDisabled,"errorInfos":_vm.errorInfos,"selectedCustomerOrganization":_vm.loggedInUser?.companyType === _vm.COMPANY_TYPE.HyvaRegionalSupport
          ? _vm.orgList
          : [_vm.selectedCustomerOrganization]},on:{"save-org":_vm.handleOrganizationSaveRemotly}})],1):_vm._e(),_c('deactivate-dialog',{attrs:{"visiable":_vm.modalWindowIsVisible,"activateOrDeactivate":_vm.activateOrDeactivate,"title":_vm.$t('userModule.user'),"content":_vm.dialogContent},on:{"update:visiable":function($event){_vm.modalWindowIsVisible=$event},"handle-cancel":_vm.handleCancelDeactivateModal,"handle-deactivate":_vm.handleDeactivateOrganization}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }