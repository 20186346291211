<script setup lang="ts">
import { getAssetById } from '@/api/assets';
import { getProductModelById, Property } from '@/api/products';
import { useActiveContext } from '@/auth/context';
import WidgetCard from '@/components/layout/widget/WidgetCard.vue';
import { useRoute } from '@/composables/router';
import { onMounted, ref, unref } from 'vue';

const isProductModelSpecLoading = ref(false);
const productModelProperties = ref<Property[]>([]);
const context = useActiveContext();
const route = useRoute();

onMounted(async () => {
  await updateProductId();
});

async function updateProductId() {
  try {
    isProductModelSpecLoading.value = true;
    const response = await getAssetById(unref(route).params.id, unref(context));
    if (response.code === 200 && response.data) {
      updateProductModelInfo(response.data.productModelId);
    }
  } catch (error) {
    console.log(error);
    isProductModelSpecLoading.value = false;
  }
}

async function updateProductModelInfo(productModelId: string) {
  try {
    const response = await getProductModelById(productModelId);
    if (response.code === 200 && response.data.properties) {
      productModelProperties.value = response.data.properties;
    }
  } catch (error) {
    console.log(error);
  } finally {
    isProductModelSpecLoading.value = false;
  }
}
</script>

<style scoped>
.no-product-specifications {
  font-size: 18px;
  font-family: var(--fontRobotoRegular, Roboto-Regular);
  color: #373e41;
  margin: 20px;
}
</style>

<template>
  <WidgetCard :loading="isProductModelSpecLoading">
    <div class="model_spec" v-if="productModelProperties">
      <div
        class="unit_line"
        v-for="item in productModelProperties"
        :key="item.keyId"
      >
        <div class="right_title">
          <span>
            {{ $t(item.code.toString()) }}
            {{ item.unit && $te(item.unit) ? '(' + $t(item.unit) + ')' : '' }}
          </span>
        </div>
        <div class="label_title">
          <span>{{ item.value }}</span>
        </div>
      </div>
    </div>
    <div
      class="no-product-specifications"
      v-if="!isProductModelSpecLoading && !productModelProperties"
    >
      {{ $t('prodCata.thereAreNoModelSpecifications') }}
    </div>
  </WidgetCard>
</template>

<style lang="scss" scoped>
$green: #349d99;

.model_spec {
  margin-top: 20px;
  padding: 0 30px;
}
.right_title {
  width: 240px;
  text-align: right;
  margin-right: 34px;
  font-size: 16px;
  line-height: 38px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.label_title {
  width: 183px;
  background: #f3f3f3;
  border: 1px solid #f3f3f3;
  padding: 0 17px;
  font-size: 16px;
  font-weight: bold;
  line-height: 38px;
  border-radius: 4px;
}

.unit_line {
  display: flex;
  margin-bottom: 20px;
}

:deep(.el-input--medium .el-input__inner) {
  width: 100%;
}

:deep(.el-select, .el-input__inner) {
  width: 100%;
}
</style>
