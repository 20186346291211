import { formatTime } from '@/utils/time';
import {
  ColumnRendering,
  ColumnRenderType,
} from '@/utils/types/columnCustomizationTypes';
import { Position } from '@/utils/types/geoposition';
import { isProperNumber, parseNumberOrUndefined } from '../number';
import { UI_COLUMN_CODES } from './lookuptable';

// Common column customizations. Defined your own specific customizations if you're using them more than a few times
const CommonCustomizations = {
  GenericTranslatable: { type: ColumnRenderType.Translatable },
  NumberWithTwoDigits: {
    type: ColumnRenderType.Generic,
    getValue: (value: any) => parseNumberOrUndefined(value)?.toFixed(2),
  },
  FormattedDuration: {
    type: ColumnRenderType.Generic,
    getValue: (seconds: number | undefined) => {
      if (!isProperNumber(seconds)) return '';

      return formatTime(seconds);
    },
  },
  DoubleExtraLargerGeneric: { type: ColumnRenderType.Generic, width: 450 },
  XLargerGeneric: { type: ColumnRenderType.Generic, width: 350 },
  LargerGeneric: { type: ColumnRenderType.Generic, width: 260 },
  MediumGeneric: { type: ColumnRenderType.Generic, width: 170 },
  SmallerGeneric: { type: ColumnRenderType.Generic, width: 120 },
  NoSort: { type: ColumnRenderType.Generic, sortable: false },
};

export const TRIPS_TABLE_CUSTOMIZATION: Partial<
  Record<UI_COLUMN_CODES, ColumnRendering>
> = {
  UICOL_TRIP_STATUS: CommonCustomizations.GenericTranslatable,
  UICOL_TRIP_END_DATETIME: {
    type: ColumnRenderType.DateTime,
    sortField: 'endPoint.timestamp',
  },
  UICOL_TRIP_START_DATETIME: {
    type: ColumnRenderType.DateTime,
    sortField: 'startPoint.timestamp',
  },
  UICOL_TRIP_START_POSITION: {
    type: ColumnRenderType.Generic,
    getValue: (a: Position) => {
      return a.addressLabel || [a.street, a.city, a.country].join(' ');
    },
    sortField: 'startPoint.addressLabel',
  },
  UICOL_TRIP_END_POSITION: {
    type: ColumnRenderType.Generic,
    getValue: (a: Position) => {
      return a.addressLabel || [a.street, a.city, a.country].join(' ');
    },
    sortField: 'endPoint.addressLabel',
  },
  UICOL_TRIP_SUMMARY: {
    type: ColumnRenderType.Component,
    getComponent: () => import('@/components/table/cells/TimelineCell.vue'),
    width: 525,
    sortable: false,
    overflow: false,
  },
  UICOL_TRIP_TIME: CommonCustomizations.FormattedDuration,
  UICOL_TRIP_TIPPING_TIME: {
    ...CommonCustomizations.FormattedDuration,
    hideKpiUnit: true,
  },
  UICOL_TRIP_MILEAGE: CommonCustomizations.NumberWithTwoDigits,
  UICOL_TRIP_MAX_TIPPING_ANGLE: CommonCustomizations.LargerGeneric,
  UICOL_TRIP_MAX_SIDELOAD_ANGLE: CommonCustomizations.LargerGeneric,
  UICOL_TRIP_HAS_SIDELOAD_DANGER_WARNING: CommonCustomizations.LargerGeneric,
  UICOL_TRIP_MAX_PRESSURE_OIL_CYLINDER: CommonCustomizations.LargerGeneric,
  UICOL_TRIP_MAX_PRESSURE_RETURN_FILTER: CommonCustomizations.LargerGeneric,
  UICOL_TRIP_PAYLOAD: CommonCustomizations.NumberWithTwoDigits,
  UICOL_TRIP_AVERAGE_SPEED: CommonCustomizations.NumberWithTwoDigits,
  UICOL_TRIP_BEFORE_TIPPING_PAYLOAD: CommonCustomizations.NumberWithTwoDigits,
  UICOL_TRIP_LEFTOVER_PAYLOAD: CommonCustomizations.NumberWithTwoDigits,
  UICOL_TRIP_TIPPED_PAYLOAD: CommonCustomizations.NumberWithTwoDigits,
  UICOL_TRIP_ID: CommonCustomizations.LargerGeneric,
  UICOL_TRIP_MASS_THRESHOLD: CommonCustomizations.NumberWithTwoDigits,
  UICOL_TRIP_MAX_SPEED: CommonCustomizations.NumberWithTwoDigits,
};

export const OPERATION_LOG_TABLE_CUSTOMIZATION: Partial<
  Record<UI_COLUMN_CODES, ColumnRendering>
> = {
  UICOL_OPERATION_LOG_ICON: {
    ...CommonCustomizations.SmallerGeneric,
    type: ColumnRenderType.Component,
    getComponent: () => import('@/components/table/cells/IconCell.vue'),
    getComponentProperties: (row) => ({
      icon: { name: row.severityCode, label: row.severityName },
    }),
  },
  UICOL_OPERATION_LOG_ORGANIZATION: {
    ...CommonCustomizations.LargerGeneric,
    sortable: true,
  },
  UICOL_OPERATION_LOG_TIMESTAMP: {
    ...CommonCustomizations.MediumGeneric,
    type: ColumnRenderType.DateTime,
  },
  UICOL_OPERATION_LOG_TIMESTAMP_UTC: {
    ...CommonCustomizations.MediumGeneric,
    type: ColumnRenderType.DateTimeUtc,
  },
  UICOL_OPERATION_LOG_EVENT_TYPE: {
    ...CommonCustomizations.MediumGeneric,
    sortable: true,
  },
  UICOL_OPERATION_LOG_EVENT_NAME: {
    ...CommonCustomizations.XLargerGeneric,
    sortable: true,
  },
  UICOL_OPERATION_LOG_EVENT_DESCRIPTION: {
    ...CommonCustomizations.DoubleExtraLargerGeneric,
    sortable: true,
  },
  [UI_COLUMN_CODES.UICOL_OPERATION_LOG_EVENT_TYPE_CODE]: {
    ...CommonCustomizations.XLargerGeneric,
    getValue: (eventTypeCode: string) => eventTypeCode.replace(/^EVNT_/, ''),
  },
  [UI_COLUMN_CODES.UICOL_OPERATION_LOG_EVENT_TYPE_CODE_UEC]: {
    ...CommonCustomizations.XLargerGeneric,
    getValue: (eventTypeCode: string) => eventTypeCode.replace(/^EVNT_/, ''),
  },
};
