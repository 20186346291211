import {
  VuexModule,
  Module,
  Mutation,
  Action,
  getModule,
} from 'vuex-module-decorators';
import store from '@/store';
import { getUITable, updateUITable } from '@/api/columnCustomization';
import { UI_TABLE } from '@/utils/workData/lookuptable';

import {
  TableColumn,
  UITable,
  UIColumn,
} from '@/utils/types/columnCustomizationTypes';
import { AssetType } from '@/utils/assetTypes';

interface TableOptions {
  code: UI_TABLE;
  assetType: AssetType;
  columns?: TableColumn[];
}

@Module({ dynamic: true, store, name: 'tableColumnCustomization' })
class TableColumnCustomization extends VuexModule {
  private tableColumns: Record<string, TableColumn[]> = {};

  // Returns table customization for a given table/assetType combination
  @Action
  public async getTableColumns(options: TableOptions): Promise<TableColumn[]> {
    const code = options.code;
    const assetType = options.assetType;

    // retrieve cached version if it exists
    const tableId = `${code}_${assetType}`;
    if (this.tableColumns[tableId]) {
      return this.tableColumns[tableId];
    }

    try {
      // call api and cache response if ok
      let { data } = await getUITable(code, assetType);
      let tableColumns: TableColumn[] = await this.uiTableToColumns(data); // await is required because of the @Action annotation
      tableColumns.sort((a: TableColumn, b: TableColumn) => a.order - b.order);
      this.tableColumns[tableId] = tableColumns;
      return tableColumns;
    } catch (e) {
      console.error(e);
      throw e;
    }
  }

  @Action
  public async saveTable(options: TableOptions) {
    if (!options.columns) {
      return;
    }

    try {
      const tableId = `${options.code}_${options.assetType}`;
      this.tableColumns[tableId] = options.columns;
      const uiColumns = await this.tableColumnsToUIColumns(options.columns); // await is required because of the @Action annotation
      await updateUITable(options.code, options.assetType, uiColumns);
    } catch (e) {
      console.error(e);
      throw e;
    }
  }

  @Action
  private uiTableToColumns(table: UITable): TableColumn[] {
    return table.columns.map((col: UIColumn) => {
      return {
        label: col.name,
        prop: col.dataFieldName,
        required: col.isMandatory,
        visible: col.isVisible,
        order: col.order,
        code: col.code,
        kpiUnitCode: col.kpiUnitCode,
        isKpiData: col.isKpiData,
      };
    });
  }

  @Action
  private tableColumnsToUIColumns(tableColumns: TableColumn[]): UIColumn[] {
    return tableColumns.map((col: TableColumn) => {
      return {
        code: col.code,
        isVisible: col.visible,
        order: col.order,
        name: col.label,
        isKpiData: false,
        isMandatory: col.required,
        dataFieldName: col.prop,
        kpiUnitCode: col.kpiUnitCode,
      };
    });
  }
}

export const ColumnCustomizationModule = getModule(TableColumnCustomization);
