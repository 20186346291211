import { ALL_CLAIMS_CODES } from '@/utils/workData/lookuptable';
import { RouteConfig } from 'vue-router';

const maintenanceConfig: RouteConfig = {
  path: '/maintenance-conf',
  component: () =>
    import(/* webpackChunkName: "layout" */ '@/components/layout/Layout.vue'),
  redirect: '/maintenance-conf/index',
  meta: {
    code: ALL_CLAIMS_CODES.AUTHRSC_MOD_MAINT_CONFIG,
  },
  children: [
    {
      path: 'index',
      component: () =>
        import(
          /* webpackChunkName: "maintenanceConf" */ '@/views/maintenanceConf/MaintenanceConf.vue'
        ),
      name: 'maintConf',
      meta: {
        title: 'route.maintConf',
        icon: 'maintConf',
        claim: ALL_CLAIMS_CODES.AUTHRSC_PAGE_MAINT_CONFIG,
        code: ALL_CLAIMS_CODES.AUTHRSC_MOD_MAINT_CONFIG,
      },
    },
    {
      path: 'create-new-maint-item',
      component: () =>
        import(
          /* webpackChunkName: "maintenanceConf" */ '@/views/maintenanceConf/AddNewMaintConf.vue'
        ),
      name: 'createNewMaintItem',
      meta: {
        title: 'route.maintConf',
        claim: ALL_CLAIMS_CODES.AUTHRSC_ACTION_MAINT_ITEM_CREATE,
        code: ALL_CLAIMS_CODES.AUTHRSC_PAGE_MAINT_CONFIG,
        hidden: true,
      },
    },
    {
      path: 'create-new-maint-item/edit/:id',
      component: () =>
        import(
          /* webpackChunkName: "maintenanceConf" */ '@/views/maintenanceConf/AddNewMaintConf.vue'
        ),
      name: 'editNewMaintItem',
      props: true,
      meta: {
        title: 'route.maintConf',
        claim: ALL_CLAIMS_CODES.AUTHRSC_ACTION_MAINT_ITEM_UPDATE,
        code: ALL_CLAIMS_CODES.AUTHRSC_PAGE_MAINT_CONFIG,
        hidden: true,
      },
    },
    {
      path: 'view-maintenance-conf/:id',
      component: () =>
        import(
          /* webpackChunkName: "maintenanceConf" */ '@/views/maintenanceConf/ViewMaintConf.vue'
        ),
      name: 'viewMaintItem',
      props: true,
      meta: {
        title: 'route.maintConf',
        code: ALL_CLAIMS_CODES.AUTHRSC_PAGE_MAINT_CONFIG,
        hidden: true,
      },
    },
  ],
};

export default maintenanceConfig;
