<script lang="ts">
import { getAssetInfoById } from '@/api/assetsMgmt';
import { LoggedInUser, useLoggedInUser } from '@/auth/user';
import GeneralInfo from '@/components/form/GeneralInfo.vue';
import NavPage from '@/components/nav/NavPage.vue';
import { useAccessControlSystemFeature } from '@/composables/systemFeature';
import { AssetManagementRouteNames } from '@/router/modules/assetMgmt';
import { PageModule } from '@/store/modules/page';
import {
  AssetFieldStateType,
  AssetFieldToState,
  AssetStage,
  AssetType,
  getAssetFieldStates,
} from '@/utils/assetTypes';
import {
  fakeUnref,
  InitializeReactive,
} from '@/utils/vueClassComponentHelpers';
import {
  ALL_CLAIMS_CODES,
  AssetLifecycle,
  FvtAccessControlOption,
  SYSTEM_FEATURES,
} from '@/utils/workData/lookuptable';
import { computed, Ref } from 'vue';
import { Component, Prop, Vue } from 'vue-property-decorator';
import ConnectionProgress from '../components/ConnectionProgress.vue';
import VerticalDivide from '../components/VerticalDivide.vue';
import { formatServerDate } from '../helper';

@Component({
  name: 'ConnectAssetView',
  components: {
    NavPage,
    'general-info': GeneralInfo,
    VerticalDivide,
    'connection-progress': ConnectionProgress,
  },
})
export default class extends Vue {
  AssetLifeCycle = AssetLifecycle;

  @Prop({ required: true }) assetId!: string;

  /** Local variables */
  assetOnBoardingObj: any = {};
  AssetFieldStateType = AssetFieldStateType;
  title = '';
  connectAssetViewIsLoading: boolean = false;
  isConnectionProgressLoading: boolean = false;
  loggedInUser!: Ref<LoggedInUser | undefined>;

  @InitializeReactive
  paramConfigEntitlement!: FvtAccessControlOption;

  created() {
    this.paramConfigEntitlement = fakeUnref(
      useAccessControlSystemFeature(
        SYSTEM_FEATURES.ParameterConfig,
        computed(() => this.assetOnBoardingObj.assetType)
      )
    );
    this.loggedInUser = useLoggedInUser();
  }

  mounted() {
    this.fetchAssetDetail();
  }

  async fetchAssetDetail() {
    this.connectAssetViewIsLoading = true;
    this.isConnectionProgressLoading = true;
    await getAssetInfoById(this.assetId).then((res) => {
      this.assetOnBoardingObj = res;
      this.assetOnBoardingObj.manufactureDate = formatServerDate(
        this.assetOnBoardingObj.manufactureDate
      );
      PageModule.setTitle(res.companyAssetId);

      this.isConnectionProgressLoading = false;
      this.connectAssetViewIsLoading = false;
    });
  }

  routeMap = new Map<string, string>([
    [AssetType.MobileCompactor, '/assets/mobile-compactors'],
    [AssetType.StaticCompactor, '/assets/static-compactors'],
    [AssetType.TippingVehicle, '/assets/tipping-vehicles'],
    [AssetType.GenericAsset, '/assets/generic-assets'],
  ]);

  authResourceByAssetType = new Map<AssetType, ALL_CLAIMS_CODES>([
    [
      AssetType.TippingVehicle,
      ALL_CLAIMS_CODES.AUTHRSC_PAGE_TIPPING_VEHICLES_PARAMS_CONFIG,
    ],
    [
      AssetType.StaticCompactor,
      ALL_CLAIMS_CODES.AUTHRSC_PAGE_SCOMPACTOR_PARAMS_CONFIG,
    ],
    [
      AssetType.MobileCompactor,
      ALL_CLAIMS_CODES.AUTHRSC_PAGE_MCOMPACTOR_PARAMS_CONFIG,
    ],
    [
      AssetType.GenericAsset,
      ALL_CLAIMS_CODES.AUTHRSC_PAGE_GENERIC_ASSET_PARAMS_CONFIG,
    ],
  ]);

  /**
   * Open single asset parameters config route
   */
  openParametersConfig(): void {
    this.$router.push(
      `${this.routeMap.get(this.assetOnBoardingObj.assetType)}/${
        this.assetOnBoardingObj.id
      }?tab=${this.authResourceByAssetType.get(
        this.assetOnBoardingObj.assetType
      )}`
    );
  }

  /**
   * Check if the logged in user has auth claim to view the tab in single asset page per asset type
   * If don't, do not display the button
   */
  get parametersButtonIsVisible(): boolean {
    if (this.loggedInUser.value === undefined) return false;

    return (
      this.loggedInUser.value.claims.hasClaim(
        this.authResourceByAssetType.get(this.assetOnBoardingObj.assetType)!
      ) && this.paramConfigEntitlement !== FvtAccessControlOption.NoAccess
    );
  }

  get formFieldState(): AssetFieldToState {
    const assetType = this.assetOnBoardingObj.assetType as AssetType;

    return getAssetFieldStates(AssetStage.Connect, assetType);
  }

  get hasEditAccess(): boolean {
    return (
      this.loggedInUser.value !== undefined &&
      this.loggedInUser.value.claims.hasClaim(
        ALL_CLAIMS_CODES.AUTHRSC_ACTION_CONNECTED_ASSET_UPDATE
      )
    );
  }

  redirectToEditPage() {
    this.$router.push({
      name: AssetManagementRouteNames.EditConnectedAsset,
      params: { assetId: this.assetId },
    });
  }
}
</script>

<template>
  <NavPage
    v-loading="connectAssetViewIsLoading"
    :visible="true"
    :arrowBack="false"
    @arrow-click="$router.push('/asset-mgmt/index?activeName=connectedAssets')"
  >
    <template #button>
      <el-button
        v-if="
          hasEditAccess &&
          assetOnBoardingObj?.status !== AssetLifeCycle.Assigned
        "
        type="plain"
        @click="redirectToEditPage"
      >
        <i class="el-icon-edit common-icon" />{{ $t('common.edit') }}
      </el-button>
    </template>
    <div style="margin-left: 40px; margin-top: 44px">
      <el-row :gutter="20">
        <el-col :span="10">
          <general-info
            v-if="
              formFieldState.SerialNumber.type !==
              AssetFieldStateType.NotApplicable
            "
            id="model_view_general_info_name"
            :name="'assetMgmt.assetSerialNumber'"
            :value="assetOnBoardingObj.serialNumber"
          />
          <general-info
            id="model_view_general_info_asset_data_collection"
            :name="'assetMgmt.AssetHIN'"
            :value="assetOnBoardingObj.assetDataCollectionId"
          />
          <general-info
            id="model_view_general_info_asset_type"
            :name="'assetMgmt.assetType'"
            :value="$t(assetOnBoardingObj.assetType)"
          />
          <general-info
            id="model_view_general_info_model_code"
            :name="'assetMgmt.prodModel'"
            :value="assetOnBoardingObj.productModelCode"
          />
          <general-info
            id="model_view_general_info_model"
            :name="'assetMgmt.modelNumber'"
            :value="assetOnBoardingObj.productModelNumber"
          />
          <general-info
            id="model_view_general_info_service_region"
            :name="'common.serviceRegion'"
            :value="assetOnBoardingObj.serviceRegion"
          />
          <general-info
            id="model_view_general_info_service_region"
            :name="'common.hyvaRegion'"
            :value="assetOnBoardingObj.hyvaRegion"
          />
          <general-info
            id="model_view_general_info_manufacture_date"
            :name="'assetMgmt.manufactureDate'"
            :value="assetOnBoardingObj.manufactureDate"
          />
          <general-info
            id="model_view_general_info_controller_id"
            :name="'assetMgmt.controllerId'"
            :value="assetOnBoardingObj.controllerId"
          />

          <el-button
            v-if="parametersButtonIsVisible"
            id="params-config-btn"
            style="margin-right: 30px"
            type="plain"
            @click="openParametersConfig"
          >
            {{ $t('paramConfig.parameters') }}
          </el-button>
        </el-col>

        <el-col :span="1" :offset="1">
          <VerticalDivide />
        </el-col>
        <el-col :span="10" :offset="3">
          <general-info
            id="model_view_general_info_gateway_id"
            :name="'assetMgmt.gatewayId'"
            :value="assetOnBoardingObj.gatewayId"
          />
          <general-info
            v-if="formFieldState.VIN.type !== AssetFieldStateType.NotApplicable"
            id="model_view_general_info_company_asset_id"
            :name="'assetMgmt.VIN'"
            :value="assetOnBoardingObj.vinNumber"
          />
          <general-info
            v-if="
              formFieldState.AssetId.type !== AssetFieldStateType.NotApplicable
            "
            id="model_view_general_info_company_asset_id"
            :name="'assetMgmt.preliminaryAssetId'"
            :value="assetOnBoardingObj.companyAssetId"
          />
          <general-info
            id="model_view_general_info_firmware_version"
            :name="'assetMgmt.assetVersion'"
            :value="assetOnBoardingObj.firmwareVersion"
          />
          <connection-progress
            v-if="!isConnectionProgressLoading"
            :assetOnBoardingId="assetId"
            :assetOnBoardingObj="assetOnBoardingObj"
          />
        </el-col>
      </el-row>
    </div>
  </NavPage>
</template>

<style lang="scss" scoped>
:deep(.form-item-container) {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 40px;
  .form-title {
    width: 270px;
    margin-right: 22px;
    text-align: left;
    font-family: $font-Roboto-Regular;
    color: #373e41;
  }
  .show-title {
    font-size: 18px;
  }
}
</style>
