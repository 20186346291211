var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('el-dialog',{staticClass:"widget-dialog tree-transfer-dialog",attrs:{"title":_vm.title,"visible":_vm.visible,"center":"","width":"90%"},on:{"close":_vm.closeDialog}},[_c('div',{staticClass:"tree-transfer"},[_c('div',{staticClass:"tree-container"},[_c('span',{staticClass:"tree-title"},[_vm._v(_vm._s(_vm.listTitles[0])+" ("+_vm._s(_vm.tree1Count)+") ")]),_c('el-tree',{ref:"tree1",staticClass:"tree",attrs:{"data":_vm.data.tree,"show-checkbox":"","default-expand-all":"","node-key":"id","check-on-click-node":"","accordion":"","filter-node-method":_vm.filterTreeNode},on:{"check-change":() => {
            /* @ts-expect-error TODO Unknown type */
            this.selectAllCheckbox1 = false;
          }},scopedSlots:_vm._u([{key:"default",fn:function({ data }){return _c('div',{staticClass:"el-tree-node__label custom-tree-element"},[_vm._v(" "+_vm._s(/* @ts-expect-error TODO Wrong type */ data.label)+" "),(
              /* @ts-expect-error TODO Wrong type */
              data.value
            )?_c('span',{staticClass:"asset-count"},[_vm._v(" "+_vm._s(/* @ts-expect-error TODO Wrong type */ data.value)+" "+_vm._s(_vm.numberOfAssetsLabel))]):_vm._e()])}}])})],1),_c('div',{staticClass:"buttons"},[_c('el-button',{on:{"click":_vm.moveRight}},[_c('i',{staticClass:"el-icon-right thick-arrow"})]),_c('el-button',{on:{"click":_vm.moveLeft}},[_c('i',{staticClass:"el-icon-back thick-arrow"})])],1),_c('div',{staticClass:"tree-container"},[_c('span',{staticClass:"tree-title"},[_vm._v(_vm._s(_vm.listTitles[1])+" ("+_vm._s(_vm.tree2Count)+")")]),_c('el-tree',{ref:"tree2",staticClass:"tree",attrs:{"data":_vm.data.tree,"show-checkbox":"","default-expand-all":"","node-key":"id","check-on-click-node":"","filter-node-method":_vm.filterTreeNode},on:{"check-change":function($event){/* @ts-expect-error TODO Wrong type */
          _vm.selectAllCheckbox2 = false}},scopedSlots:_vm._u([{key:"default",fn:function({ data }){return _c('div',{staticClass:"el-tree-node__label custom-tree-element",class:/* @ts-expect-error TODO Wrong type */
            _vm.shouldHighlight(data.id) ? 'highlight' : ''},[_vm._v(" "+_vm._s(/* @ts-expect-error TODO Wrong type */ data.label)+" "),(
              /* @ts-expect-error TODO Wrong type */
              data.value != null && data.value != undefined
            )?_c('span',{staticClass:"asset-count"},[_vm._v(" "+_vm._s(/* @ts-expect-error TODO Wrong type */ data.value)+" "+_vm._s(_vm.numberOfAssetsLabel))]):_vm._e()])}}])})],1)]),_c('span',{staticClass:"dialog-footer",attrs:{"slot":"footer"},slot:"footer"},[_c('el-button',{staticClass:"widget-dialog-btn",attrs:{"type":"plain"},on:{"click":_vm.handleDialogCancel}},[_vm._v(_vm._s(_vm.$t('common.cancelBtn')))]),_c('el-button',{staticClass:"widget-dialog-btn",attrs:{"type":"info"},on:{"click":_vm.handleConfirm}},[_vm._v(_vm._s(_vm.confirmBtnName))])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }