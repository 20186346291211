<script lang="ts" setup>
import { AvailableSafetyEventType, getAvailableSafetyEvents } from '@/api/trip';
import { IncludedWidget } from '@/api/widgets';
import widgetButton from '@/assets/imgs/widgetButton.svg';
import { useActiveContext } from '@/auth/context';
import Title from '@/components/layout/Title.vue';
import { TitleButtonProps } from '@/components/layout/TitleButton.vue';
import WidgetGrid from '@/components/layout/widget/WidgetGrid.vue';
import WidgetTabs from '@/components/layout/widget/WidgetTabs.vue';
import WidgetWrapper from '@/components/layout/widget/WidgetWrapper.vue';
import { useAsync } from '@/composables/async';
import { useRoute, useRouter } from '@/composables/router';
import { Breakpoint, useMaxWindowSize } from '@/composables/window';
import i18n from '@/lang';
import {
  ASSET_SAFETY_CODE,
  NEW_FLEET_SAFETY_CODE,
} from '@/router/links/safetyWidget';
import { PageModule } from '@/store/modules/page';
import { UserModule } from '@/store/modules/user';
import { capitalizeFirstLetterOfTheWorldsInString } from '@/utils/string';
import { FULLPAGE_EXPANDED_WIDGETS } from '@/utils/workData/utilMap';
import {
  defaultSafetyWidgetTitle,
  routeIncludesExpandedSafetyWidget,
} from '@/widgets/utils/tripSafety';
import { computed, Ref, ref, unref, watch } from 'vue';
import { Location } from 'vue-router';

const props = defineProps<{
  pageCode: string;
  tabbed?: boolean;
  tabBlacklist?: string[];
  columns?: number;
  rowHeight?: string;
  hideConfigurable?: boolean;
}>();

const router = useRouter();
const selected = ref<string>();
const hideButtons = ref<boolean>(false);
const expandedWidget = ref<IncludedWidget>();

const editGridDialogButton: TitleButtonProps = {
  text: i18n.tc('dashboardConf.widgets'),
  icon: widgetButton,
  click: showEditGridDialog,
};
const buttons = props.hideConfigurable
  ? ref<TitleButtonProps[]>([])
  : ref<TitleButtonProps[]>([editGridDialogButton]);

const activeContext = useActiveContext();

const availableSafetyEventTypes = useAsync(
  computed(
    async (): Promise<AvailableSafetyEventType[]> =>
      getAvailableSafetyEvents(UserModule.companyType, unref(activeContext))
  )
);

watch(expandedWidget, (next) => {
  if (next) {
    const name = setupBreadCrumbName(next.name, next.code);

    if (!PageModule.title.includes(name) && name != undefined) {
      PageModule.setTitle(
        route.value.query.tab
          ? [...PageModule.title, setupTabCrumbName(), name]
          : [...PageModule.title, name]
      );
    }
  }

  buttons.value =
    expandedWidget.value || props.hideConfigurable === true
      ? []
      : [editGridDialogButton];
});

function setupTabCrumbName(): string {
  const tabCrumb = i18n.te(('pageTitle.' + route.value.query.tab) as string)
    ? i18n.t(('pageTitle.' + route.value.query.tab) as string)
    : i18n.t(route.value.query.tab as string);

  return tabCrumb;
}

function setupBreadCrumbName(breadCrumbName: string, code: string): string {
  if (i18n.te(code) && !routeIncludesExpandedSafetyWidget(code)) {
    breadCrumbName = i18n.t(code) as string;
  } else if (routeIncludesExpandedSafetyWidget(code)) {
    /*  */
    const availableEvents = unref(availableSafetyEventTypes).data;
    const isAssetScope = unref(route)?.params?.id ? true : false;
    breadCrumbName = isAssetScope
      ? i18n.tc(ASSET_SAFETY_CODE)
      : i18n.tc(NEW_FLEET_SAFETY_CODE);

    if (
      availableEvents &&
      availableEvents?.length === 1 &&
      availableEvents?.[0]?.eventTypeCode
    ) {
      const finalResult = defaultSafetyWidgetTitle(
        availableEvents?.[0]?.eventTypeCode,
        isAssetScope
      );

      breadCrumbName = capitalizeFirstLetterOfTheWorldsInString(finalResult);
    }
  }

  return breadCrumbName;
}

const route = useRoute();

watch(route, (next, previous) => {
  const { widget: nextWidget } = next.query;
  const { widget: previousWidget } = previous.query;

  if (nextWidget !== previousWidget) {
    expandedWidget.value = undefined;
  }
});

const editGridDialogVisible = ref(false);
function showEditGridDialog() {
  editGridDialogVisible.value = true;
}
function hideEditGridDialog() {
  editGridDialogVisible.value = false;
}

const expandedFullPage: Ref<boolean> = computed(() => {
  const widget = expandedWidget.value;
  return widget != undefined && FULLPAGE_EXPANDED_WIDGETS.includes(widget.code);
});

function widgetCodeToPageTitle(widgetCode: string): string {
  return i18n.t(widgetCode);
}

const fromLocation = computed((): Location | undefined => {
  const from = route.value.query.from;

  if (typeof from === 'string') {
    return router.resolve(from).location;
  }
});

const fromPageTitle = computed((): string | undefined => {
  const from = fromLocation.value;
  if (!from) {
    return undefined;
  }
  const fromWidgetCode = from.query?.widget;
  if (typeof fromWidgetCode === 'string') {
    return widgetCodeToPageTitle(fromWidgetCode);
  }
});

const backButtonText = computed((): string => {
  if (fromPageTitle.value) {
    return i18n.t('returnToPage', {
      pageName: fromPageTitle.value,
    });
  }

  return i18n.t('returnToDashboard');
});

const backButtonLocation = computed((): Location => {
  if (fromLocation.value) {
    return fromLocation.value;
  }

  const { widget, ...query } = route.value.query;
  const resolved = router.resolve({
    query,
  });

  return resolved.location;
});

const isScreenSizeSmall = useMaxWindowSize(Breakpoint.Small);

function hideWidgetButton(value: boolean) {
  hideButtons.value = value;
}
</script>

<template>
  <div
    class="widget-layout"
    :class="{ 'full-page': expandedFullPage || isScreenSizeSmall }"
  >
    <Title class="title" :buttons="hideButtons ? [] : buttons" />
    <transition name="fade-transform" mode="out-in">
      <div v-if="expandedWidget" class="single-widget-container" key="expanded">
        <div>
          <el-button
            type="info"
            class="return-to-dashboard-button"
            @click="router.push(backButtonLocation)"
          >
            <div class="button-content">
              <img class="button-icon" src="@/assets/imgs/arrowBack.svg" />
              {{ backButtonText }}
            </div>
          </el-button>
        </div>
        <WidgetWrapper
          :widget="expandedWidget"
          :page-code="selected ?? props.pageCode"
          :expanded="true"
        />
      </div>
      <div
        v-else-if="props.tabbed"
        class="tabbed-widget-container"
        key="tabbed"
      >
        <WidgetTabs
          :page-code="props.pageCode"
          :tab-blacklist="props.tabBlacklist ?? []"
          @hideButtonEvent="hideWidgetButton"
          @select="selected = $event"
        />
        <transition name="fade-transform" mode="out-in">
          <WidgetGrid
            v-if="selected"
            :key="selected"
            :page-code="selected"
            :columns="props.columns ?? 3"
            :row-height="props.rowHeight ?? '15rem'"
            @expand-widget="expandedWidget = $event"
            :edit-grid-dialog-visible="editGridDialogVisible"
            @close-edit-grid-dialog="hideEditGridDialog()"
          />
        </transition>
      </div>
      <WidgetGrid
        v-else
        :page-code="props.pageCode"
        :columns="props.columns ?? 3"
        :row-height="props.rowHeight ?? '15rem'"
        @expand-widget="expandedWidget = $event"
        :edit-grid-dialog-visible="editGridDialogVisible"
        @close-edit-grid-dialog="hideEditGridDialog()"
      />
    </transition>
  </div>
</template>

<style lang="scss" scoped>
.widget-layout {
  display: flex;
  flex-direction: column;

  &.full-page {
    height: 100%;
  }
}

.single-widget-container {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.el-button {
  margin-bottom: 1rem;
}

.button {
  &-content {
    display: flex;
    align-items: center;
    gap: 1rem;
  }

  &-icon {
    height: 1.25rem;
  }
}

.title {
  margin-bottom: 0.5rem;
}

.return-to-dashboard-button {
  height: 40px;
  margin-bottom: 20px;
}

.tabbed-widget-container {
  display: flex;
  flex-direction: column;
  height: 100%;
}
</style>
