<script lang="ts" setup>
import { getAssetById } from '@/api/assets';
import { PropertyCode } from '@/api/preset';
import { useActiveContext } from '@/auth/context';
import Breadcrumbs from '@/components/layout/Breadcrumbs.vue';
import WidgetCard from '@/components/layout/widget/WidgetCard.vue';
import PureTable from '@/components/table/PureTable.vue';
import { useAsync } from '@/composables/async';
import { useRoute } from '@/composables/router';
import i18n from '@/lang';
import { never } from '@/utils/promise';
import { Option } from '@/utils/types/option';
import { PRESET_PARAMETER_COLS } from '@/utils/workData/preset';
import { computed, ref, unref } from 'vue';

export interface ParameterData {
  name: string;
  value: string;
  rawMetricValue: number;
  translatedUnit: string;
  propertyCode: PropertyCode;
}

defineProps<{
  data: ParameterData[] | undefined;
  dropdownLabel: string;
  dropdownOptions: Option[];
  selectedOption: string | undefined;
  title: string;
}>();

const emit = defineEmits<{
  (e: 'dropdown-change', id: string): void;
}>();

const tableCols = ref(
  PRESET_PARAMETER_COLS.map((item) => ({
    ...item,
    label: i18n.t(item.label),
  }))
);

const route = useRoute();
const activeContext = useActiveContext();
const assetRef = useAsync(
  computed(() =>
    route.value.params.id
      ? getAssetById(route.value.params.id, unref(activeContext))
      : undefined
  )
);

const breadcrumb = useAsync(
  computed(async (): Promise<string[] | undefined> => {
    if (!route.value.meta?.assetType) {
      return undefined;
    }

    if (assetRef.value.loading) {
      return never();
    }

    if (assetRef.value.error) {
      throw new Error(assetRef.value.error.message);
    }

    return [
      i18n.t(route.value.meta.assetType),
      assetRef.value.data!.data.companyAssetId,
    ];
  })
);

function handleSelectedOptionChange(optionKey: string) {
  emit('dropdown-change', optionKey);
}
</script>

<template>
  <div class="wrapper">
    <Breadcrumbs
      style="margin: 6px 0px 12px 0px"
      v-loading="breadcrumb.loading"
      :crumbs="breadcrumb.data ?? []"
    />
    <WidgetCard
      style="display: flex; flex-direction: column; flex-grow: 1"
      :default-title="title"
      :show-back-button="true"
    >
      <div class="header-container">
        <div class="dropdown-label">{{ dropdownLabel }}:</div>
        <el-select
          size="medium"
          :value="selectedOption"
          @change="handleSelectedOptionChange"
        >
          <el-option
            v-for="option in dropdownOptions"
            :label="option.label"
            :value="option.key"
          />
        </el-select>
        <div v-if="$slots.actionButton" style="margin-left: auto">
          <slot name="actionButton" />
        </div>
      </div>

      <div style="flex-grow: 1">
        <PureTable
          v-if="selectedOption !== undefined"
          v-loading="data === undefined"
          class="table"
          :cols="tableCols"
          :tableList="data ?? []"
          :noDataText="$t('preset.noParameters')"
          :fitToParent="true"
        />
      </div>
    </WidgetCard>
  </div>
</template>

<style scoped lang="scss">
.header-container {
  margin: 20px 24px;
  padding: 15px;
  background-color: #edeff0;
  display: flex;
  align-items: center;
}

.wrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.dropdown-label {
  font-size: 20px;
  font-weight: 500;
  margin-right: 20px;
}

.table {
  padding: 0px 16px;
}

:deep(.el-input__inner) {
  text-overflow: ellipsis;
}
</style>
