import {
  DefaultError,
  QueryKey,
  QueryObserverOptions,
} from '@tanstack/vue-query';

/**
 * Options that users may typically want to override when
 * fetching a certain query.
 */
export type GenericQueryOptions<
  TQueryFnData = unknown,
  TQueryKey extends QueryKey = QueryKey,
  TError = DefaultError,
  TData = TQueryFnData,
  TQueryData = TQueryFnData,
  TPageParam = never
> = Pick<
  QueryObserverOptions<
    TQueryFnData,
    TError,
    TData,
    TQueryData,
    TQueryKey,
    TPageParam
  >,
  | 'staleTime'
  | 'refetchInterval'
  | 'refetchIntervalInBackground'
  | 'refetchOnWindowFocus'
  | 'refetchOnReconnect'
  | 'refetchOnMount'
  | 'retryOnMount'
  | 'select'
>;

/**
 * Helper to provide (somewhat) correct typing to structuralSharing method for vue-query.
 *
 * A structuralSharing method is called after data is fetched, but ALSO after
 * an (optional) `select()` method is called, and/or `combine()` (on `useQueries()`)
 *
 * @usage
 * ```ts
 * const myQueryWithoutSelect = useQuery({
 *    ...
 *    structuralSharing: makeStructuralSharer<Foo>((oldData, newData) => { ... }),
 * });
 *
 * const myQueryWithSelect = useQuery({
 *    ...
 *    select: (data: Foo) => data.bar > 42,
 *                                              //  vvvvvvv -> NOTE: return type of select added!
 *    structuralSharing: makeStructuralSharer<Foo | boolean>((oldData, newData) => { ... }),
 * });
 * ```
 *
 * @see https://github.com/TanStack/query/issues/6525
 */
export function makeStructuralSharer<TDataOrTSelect>(
  sharer: (
    oldData: TDataOrTSelect | undefined,
    newData: TDataOrTSelect
  ) => TDataOrTSelect
): (oldData: unknown | undefined, newData: unknown) => unknown {
  return sharer as (oldData: unknown | undefined, newData: unknown) => unknown;
}

export function getNoBackgroundFetchOptions<T>(): GenericQueryOptions<T, any> {
  return NO_BACKGROUND_FETCH_OPTIONS;
}

export const NO_BACKGROUND_FETCH_OPTIONS = {
  refetchOnWindowFocus: false,
} as const;

export type Selector<T, R> = (value: T) => R;
