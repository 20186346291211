<script lang="ts" setup>
import { CommonResult } from '@/api/commonResult';
import {
  getMaintenanceAppointmentsList,
  getMaintenanceFiltersList,
  getPerformedAppointments,
  MaintenanceAppointment,
  MaintenanceFilterItem,
  PerformedMaintenanceAppointments,
} from '@/api/maintenance';
import { useActiveContext } from '@/auth/context';
import BaseCard from '@/components/cusCard/BaseCard.vue';
import MultiAssetTypeSelect, {
  AssetTypeSelectItem,
} from '@/components/form/MultiAssetTypeSelect.vue';
import MultiProductModelSelect, {
  ProductModelSelectItem,
} from '@/components/form/MultiProductModelSelect.vue';
import MaintenanceModal from '@/components/modal/MaintenanceModal.vue';
import Cell from '@/components/scheduler/Cell.vue';
import CustomMaintenanceScheduler from '@/components/scheduler/CustomMaintenanceScheduler.vue';
import { MaintenanceScheduler } from '@/components/scheduler/scheduler';
import i18n from '@/lang';
import {
  Filter,
  FilterOperator,
  Pagination,
  QueryParameter,
} from '@/model/queryParameters/QueryParameter';
import { getDaysOfTheWeekAsDates } from '@/utils/misc';
import { LOCALDATE_FORMAT } from '@/utils/time';
import { Calendar } from '@progress/kendo-vue-dateinputs';
import moment from 'moment';
import { computed, nextTick, onMounted, ref, unref, watchEffect } from 'vue';
import {
  DEFAULT_APPOINTMENT_DURATION,
  getElementColor,
  MaintenanceModalTriggerSource,
  PERFORMED_MAINTENANCE_STATUS,
  processMaintenanceAppointments,
  SchedulerData,
} from './planner';

import { AsyncValue, useAsync } from '@/composables/async';
import { showNotificationOnError } from '@/composables/error';
import { customFailedMessage } from '@/utils/prompt';

interface MaintenanceFilterItemSelected extends MaintenanceFilterItem {
  selected: boolean;
}

/**
 * Allow explicit refresh of planner page from server,
 */
const forceUpdatePlannerPage = ref(0);

/**
 * Maintenance item name selection filter used as a backup for reloading process to keep up the old setup of filters
 */
const backUpMaintenanceItemList = ref<MaintenanceFilterItemSelected[]>([]);

async function getMaintenanceFilterListItems({
  organizationId,
  selectedAssetTypeCodes,
  selectedProductModelIds,
}: {
  organizationId: string;
  selectedAssetTypeCodes?: string[];
  selectedProductModelIds?: string[];
  isFirstCall?: boolean;
}): Promise<MaintenanceFilterItem[]> {
  if (
    selectedAssetTypeCodes?.length === 0 &&
    selectedProductModelIds?.length === 0
  ) {
    return [];
  }

  const filters: Filter[] = [
    ...(selectedAssetTypeCodes?.length
      ? [
          {
            name: 'assetType',
            operator: FilterOperator.IN,
            value: selectedAssetTypeCodes,
          },
        ]
      : []),
    ...(selectedProductModelIds?.length
      ? [
          {
            name: 'productModelId',
            operator: FilterOperator.IN,
            value: selectedProductModelIds,
          },
        ]
      : []),
  ];
  const response = await getMaintenanceFiltersList(organizationId, { filters });
  if (response.code !== 200) {
    throw new Error('Invalid api response,');
  }

  return response.data ?? [];
}

const miniCalendarSelectedDate = ref<Date>(
  new Date()
); /** Need a current date & specific format for the scheduler */
const daysOfTheCurrentWeekDate = ref<string[]>([]);
const customCell = 'customMinicalendarTemplate'; /** Temporary field */
const maintenanceModalTitle = ref<string>(
  i18n.tc('maintenance.maintenancePlanner.addNewMaintenanceAppointment')
); /** Default modal title */

const modalTriggerSource = ref<MaintenanceModalTriggerSource>(
  MaintenanceModalTriggerSource.New
); /** Expose to modal from which event has been triggered */
const isModalVisible = ref<boolean>(false); /** Flag for modal  */
const minicalendarMaintenanceDays: any =
  []; /** Mini calendar -> days on which are planned maintenance */
const context = useActiveContext();
const selectedAppointment = ref<MaintenanceScheduler>();

const maintenanceFilterItems = ref<MaintenanceFilterItem[]>([]);
const selectedAssetTypeCodes = ref<string[] | undefined>();
const selectedProductModelIds = ref<string[] | undefined>();

const assetTypeList = useAsync(
  computed(() => {
    const maintenanceFilterItemsValue = unref(maintenanceFilterItems);
    if (maintenanceFilterItemsValue.length === 0) return undefined;
    return maintenanceFilterItemsValue?.reduce((accumulator, current) => {
      if (!accumulator.find((item) => item.assetType === current.assetType)) {
        accumulator.push({
          assetType: current.assetType,
          selected: true,
        });
      }
      return accumulator;
    }, [] as AssetTypeSelectItem[]);
  })
);

const productModelList = useAsync(
  computed(() => {
    const assetTypeListValue = unref(assetTypeList);
    const maintenanceFilterItemsValue = unref(maintenanceFilterItems);
    if (maintenanceFilterItemsValue.length === 0 || !assetTypeListValue)
      return undefined;

    return maintenanceFilterItemsValue?.reduce((accumulator, current) => {
      if (
        !accumulator.find(
          (item) => item.productModelId === current.productModelId
        ) &&
        assetTypeListValue.data?.some(
          (at) => current.assetType === at.assetType && at.selected
        )
      ) {
        accumulator.push({
          productModel: current.productModel,
          productModelId: current.productModelId,
          selected: true,
        });
      }
      return accumulator;
    }, [] as ProductModelSelectItem[]);
  })
);

watchEffect(() => {
  const assetTypeListValue = unref(assetTypeList).data;
  const selectedAssetTypeCodesValue = unref(selectedAssetTypeCodes);
  if (
    !selectedAssetTypeCodesValue &&
    assetTypeListValue &&
    assetTypeListValue.length > 0
  ) {
    selectedAssetTypeCodes.value = assetTypeListValue
      .filter((el) => el.selected)
      .map((el) => el.assetType);
  }
});

watchEffect(() => {
  unref(assetTypeList);
  const productModelListValue = unref(productModelList).data;
  const selectedProductModelIdsValue = unref(selectedProductModelIds);
  if (
    !selectedProductModelIdsValue &&
    productModelListValue &&
    productModelListValue.length > 0
  ) {
    selectedProductModelIds.value = productModelListValue
      .filter((el) => el.selected)
      .map((el) => el.productModelId);
  }
});

const maintenanceItemInnitialList = useAsync(
  computed(async (): Promise<MaintenanceFilterItem[] | undefined> => {
    const selectedOrganization = unref(context).organization;
    const organizationId = selectedOrganization?.id;
    if (!organizationId) {
      return undefined;
    }
    const response = await getMaintenanceFilterListItems({
      organizationId,
    });

    return response ?? undefined;
  })
);

const maintenanceItemsInnitialInfo = computed(
  (): AsyncValue<MaintenanceFilterItem[]> => {
    const maintenanceFilterListItems = unref(maintenanceItemInnitialList);
    if (
      maintenanceFilterListItems.loading ||
      maintenanceFilterListItems.error
    ) {
      return {
        loading: maintenanceFilterListItems.loading,
        error: maintenanceFilterListItems.error,
        data: undefined,
      };
    }
    if (maintenanceFilterListItems.data === undefined) {
      return {
        loading: false,
        error: new Error('Invalid api response, data must be present.'),
        data: undefined,
      };
    }

    return {
      loading: false,
      error: undefined,
      data: maintenanceFilterListItems.data,
    };
  }
);
showNotificationOnError(
  maintenanceItemsInnitialInfo,
  'common.errorWithFetchingData'
);

watchEffect(() => {
  if (unref(maintenanceFilterItems).length === 0) {
    if (
      unref(maintenanceItemsInnitialInfo).loading ||
      !!unref(maintenanceItemsInnitialInfo).error
    ) {
      maintenanceFilterItems.value = [];
      return;
    }
    const maintenanceItemsInfoData = unref(maintenanceItemsInnitialInfo).data;
    if (!maintenanceItemsInfoData) {
      maintenanceFilterItems.value = [];
      return;
    }
    maintenanceFilterItems.value = maintenanceItemsInfoData;
  }
});

watchEffect(() => {
  const selectedProductModelIdsValue = unref(selectedProductModelIds);
  if (selectedProductModelIdsValue?.length === 0) {
    customFailedMessage(
      i18n.tc('maintenance.maintenancePlanner.selectOneProductModel')
    );
  }
});

watchEffect(() => {
  const selectedAssetTypeCodesValue = unref(selectedAssetTypeCodes);

  if (selectedAssetTypeCodesValue?.length === 0) {
    customFailedMessage(
      i18n.tc('maintenance.maintenancePlanner.selectOneAssetType')
    );
  }
});

/**
 * Fetch maintenance items list
 * Keep Previous Filter selection for items checkbox statuses
 */
const maintenanceItemList = useAsync(
  computed(async (): Promise<MaintenanceFilterItemSelected[] | undefined> => {
    unref(forceUpdatePlannerPage); /** Force update planner page */
    const selectedOrganization = unref(context).organization;
    const productModelListValue = unref(productModelList);
    const assetTypeListValue = unref(assetTypeList);
    const selectedAssetTypeCodesValue = assetTypeListValue.data
      ?.filter((el) => el.selected)
      .map((el) => el.assetType);
    const selectedProductModelIdsValue = productModelListValue.data
      ?.filter((el) => el.selected)
      .map((el) => el.productModelId);
    const organizationId = selectedOrganization?.id;
    if (!organizationId) {
      return undefined;
    }

    const response = await getMaintenanceFilterListItems({
      organizationId,
      selectedAssetTypeCodes: selectedAssetTypeCodesValue,
      selectedProductModelIds: selectedProductModelIdsValue,
    });

    /**
     * Process default maintenance items filter: selected all
     * Keep previous filters state after the reload operation
     */
    const finalResponse = response.reduce<MaintenanceFilterItemSelected[]>(
      (
        accumulator: MaintenanceFilterItemSelected[],
        current: MaintenanceFilterItem
      ) => {
        let currentItemIsSelected = true;

        if (unref(forceUpdatePlannerPage) && unref(backUpMaintenanceItemList)) {
          const backupItems = unref(backUpMaintenanceItemList);

          const selectedStatus = backupItems?.find(
            (item: MaintenanceFilterItemSelected) => {
              return item.id === current.id;
            }
          )?.selected;

          currentItemIsSelected = selectedStatus ?? true;
        }

        accumulator.push({
          ...current,
          selected: currentItemIsSelected,
        });

        return accumulator;
      },
      [] as MaintenanceFilterItemSelected[]
    );

    backUpMaintenanceItemList.value = finalResponse;
    return finalResponse;
  })
);

showNotificationOnError(maintenanceItemList, 'common.errorWithFetchingData');

const maintenanceItemListSelectedCheckboxes = computed(
  (): MaintenanceFilterItemSelected[] | undefined =>
    unref(maintenanceItemList).data?.filter(
      (maintenanceItem) => maintenanceItem.selected
    )
);

watchEffect(() => {
  const maintenanceItemListValue = unref(maintenanceItemList);
  const maintenanceItemListSelectedCheckboxesValue = unref(
    maintenanceItemListSelectedCheckboxes
  );

  if (
    maintenanceItemListSelectedCheckboxesValue?.length === 0 &&
    maintenanceItemListValue.data?.length !== 0
  ) {
    customFailedMessage(
      i18n.tc('maintenance.maintenancePlanner.selectOneMaintenanceItem')
    );
    return;
  }

  backUpMaintenanceItemList.value = unref(maintenanceItemList).data!;
});

/**
 * Filter table and chart data
 */
function filterDataProductModel(
  selectedProductModelList: ProductModelSelectItem[]
): void {
  backupMaintenanceItemsRestoreToDefault();
  selectedProductModelIds.value = selectedProductModelList.map(
    (el) => el.productModelId
  );
}

/**
 * Filter table and chart data
 */
function filterDataAssetType(
  selectedAssetTypeList: AssetTypeSelectItem[]
): void {
  backupMaintenanceItemsRestoreToDefault();
  selectedAssetTypeCodes.value = selectedAssetTypeList.map(
    (el) => el.assetType
  );
}

onMounted(() => {
  getCurrentWeekDaysAsDates();
  hihlightMiniCalendarWeek();
});

/**
 * Default day selection today for mini calendar
 */
function miniCalendarDefaultDaySelection() {
  (document.querySelector('.k-calendar-nav-today') as HTMLElement)?.click();
}

/**
 * Extract week's days of the current day selection from mini calendar as an array
 */
function getCurrentWeekDaysAsDates(): void {
  daysOfTheCurrentWeekDate.value = getDaysOfTheWeekAsDates(
    unref(miniCalendarSelectedDate)
  );
}

/**
 * Highlight mini calendar week by day selection
 */
function hihlightMiniCalendarWeek(): void {
  nextTick(() => {
    /** Get the minicalendar all week days of the current selected day as Node List */
    const existingHighlightedWeek = document.querySelectorAll(
      '.k-calendar-tr'
    ) as NodeListOf<Element>;

    /** Remove any style applied previously */
    existingHighlightedWeek.forEach((day: Element) => {
      day.removeAttribute('style');
    });

    /** Get the selected day HTMLElement from mini calendar component */
    const selectedDay = document.querySelector('.k-selected') as HTMLElement;
    if (selectedDay) {
      /** Apply background color style to highlight the entire week */
      (selectedDay.parentNode! as HTMLElement).style.backgroundColor =
        getElementColor('weekHighlight')!;
    }
  });
}

/**
 * Reseting values when detaching from operations
 */
function cleanInitialization() {
  isModalVisible.value = false;
  modalTriggerSource.value = MaintenanceModalTriggerSource.New;
  minicalendarMaintenanceDays.value = [];
  maintenanceModalTitle.value = i18n.tc(
    'maintenance.maintenancePlanner.addNewMaintenanceAppointment'
  );
}

/**
 * Prepare query parameters to fetch maintenance appointments
 */
function maintenanceAppointmentsQueryParameters(
  maintenanceItemListIdsValue: string[]
): QueryParameter {
  const pagination: Pagination = { page: 1, size: 100000 };
  const selectedOrganization = unref(context.value).organization;
  const orgId = selectedOrganization?.id;
  const maintenanceItemListFilter = [
    {
      name: 'itemId',
      operator: FilterOperator.IN,
      value: maintenanceItemListIdsValue,
    },
  ];
  const queryParameters: QueryParameter = {
    filters: !orgId
      ? [...maintenanceItemListFilter]
      : [
          {
            name: 'organizationId',
            operator: FilterOperator.IN,
            value: [orgId],
          },
          ...maintenanceItemListFilter,
        ],
    sorters: [],
    pagination: pagination,
    timezone: selectedOrganization?.timezone,
  };
  return queryParameters;
}

/**
 * Retrieve non conducted maintenance with performed appointments from API, filtered by query parameters
 */
const maintenanceAppointments = computed(
  async (): Promise<
    CommonResult<(MaintenanceAppointment | PerformedMaintenanceAppointments)[]>
  > => {
    const maintenanceItemListSelectedCheckboxesValue = unref(
      maintenanceItemListSelectedCheckboxes
    );
    if (
      !maintenanceItemListSelectedCheckboxesValue ||
      maintenanceItemListSelectedCheckboxesValue.length === 0
    ) {
      return {
        code: 404,
        message: '',
        data: [],
      };
    }
    const maintenanceItemListIdsValue =
      maintenanceItemListSelectedCheckboxesValue?.map((mi) => mi.id) ?? [];
    /** Fetch maintenance appointments list */
    const maintenanceAppointmentsResponse: CommonResult<
      (MaintenanceAppointment | PerformedMaintenanceAppointments)[]
    > = await getMaintenanceAppointmentsList(
      maintenanceAppointmentsQueryParameters(maintenanceItemListIdsValue)
    );

    return prepareAllAppointmentsResponse(maintenanceAppointmentsResponse);
  }
);

/**
 * Prepare all maintenance appointments that includes performed ones
 */
async function prepareAllAppointmentsResponse(
  maintenanceAppointmentsResponse: CommonResult<
    (MaintenanceAppointment | PerformedMaintenanceAppointments)[]
  >
): Promise<
  CommonResult<(MaintenanceAppointment | PerformedMaintenanceAppointments)[]>
> {
  const response = await getPerformedAppointments(
    getPerformedAppointmentsQueryParameters()
  );

  const performedAppointments = response.data.performedMaintenanceAppointments;

  /**
   * For further distinctions (mini calendar & big calendar behaviour) between normal appointments and performed ones,
   * Adding specific status for filtering and missing duration field
   */
  performedAppointments?.map(
    (appointment: PerformedMaintenanceAppointments) => {
      appointment.status = PERFORMED_MAINTENANCE_STATUS;
      appointment.duration = DEFAULT_APPOINTMENT_DURATION;

      return appointment;
    }
  );
  maintenanceAppointmentsResponse.data = [
    ...maintenanceAppointmentsResponse.data,
    ...performedAppointments!,
  ];

  return maintenanceAppointmentsResponse;
}

/**
 * Fetch appointment dates
 */
const appointmentDates = useAsync(
  computed(async (): Promise<string[]> => {
    const maintenanceAppointmentsValue = (await unref(maintenanceAppointments))
      .data;

    /**
     * Dates for mini calendar marking should be visible only those that are not performed
     */
    const nonPerformedAppointments = maintenanceAppointmentsValue.filter(
      (
        appointment: MaintenanceAppointment | PerformedMaintenanceAppointments
      ) => appointment.status != PERFORMED_MAINTENANCE_STATUS
    );

    return (
      nonPerformedAppointments?.map(
        (
          appointment: MaintenanceAppointment | PerformedMaintenanceAppointments
        ) => moment(appointment.appointmentTime).format(LOCALDATE_FORMAT)
      ) ?? []
    );
  })
);

/**
 * Fetch scheduler appointments
 */
const schedulerAppointments = useAsync(
  computed(async (): Promise<MaintenanceScheduler[]> => {
    const maintenanceAppointmentsValue = await unref(maintenanceAppointments);
    if (
      maintenanceAppointmentsValue.code == 200 &&
      maintenanceAppointmentsValue.data.length > 0
    ) {
      return processMaintenanceAppointments(maintenanceAppointmentsValue.data);
    }
    return [];
  })
);

/**
 * Prepare formatted data for right side big calendar: Appointments scheduler view
 */
const schedulerData = computed((): SchedulerData[] => {
  const schedulerAppointmentsValue = unref(schedulerAppointments).data;
  const data: SchedulerData[] = [];
  daysOfTheCurrentWeekDate.value.forEach((day: string) => {
    const appointmentsByDate =
      schedulerAppointmentsValue?.filter(
        (appointment: MaintenanceScheduler) => {
          return (
            moment(day).format(LOCALDATE_FORMAT) ===
            moment(appointment.start).format(LOCALDATE_FORMAT)
          );
        }
      ) ?? [];
    data.push({
      id: new Date().getTime().toString(),
      day: day,
      isSelected: moment(miniCalendarSelectedDate.value).isSame(moment(day)),
      appointments: appointmentsByDate,
    });
  });

  return data;
});

/**
 * Handle mini calendar date selection
 * @param event
 */
function miniCalendarDaySelection(event: Object): void {
  hihlightMiniCalendarWeek();
  /* @ts-expect-error TODO Wrong type */
  miniCalendarSelectedDate.value = event.value;
  getCurrentWeekDaysAsDates();
}

/**
 * Display modal
 */
function showMaintenanceDialog(source: MaintenanceModalTriggerSource): void {
  modalTriggerSource.value = source;
  isModalVisible.value = true;
}

/**
 * Cancel modal
 */
function handleModalCancel(): void {
  cleanInitialization();
}

/**
 * Reload DOM when a child event calls it
 */
function handleReloadPlanner(): void {
  cleanInitialization();
  forceUpdatePlannerPage.value = forceUpdatePlannerPage.value + 1;
}

/**
 * Event triggered when clicking on scheduler maintenance plan
 * Compose modal title by adding space between company asset id and maintenance item name
 */
function editSchedulerEvent(appointment: MaintenanceScheduler) {
  maintenanceModalTitle.value =
    appointment.companyAssetId +
    '\u00A0 - \u00A0' +
    appointment.maintenanceItemName;
  selectedAppointment.value = appointment;
  showMaintenanceDialog(MaintenanceModalTriggerSource.Edit);
}

/** -------------------------- Performed appointments -------------------------- */

/**
 * Prepare query parameters to request performed appointments
 */
function getPerformedAppointmentsQueryParameters(): QueryParameter {
  const pagination: Pagination = { page: 1, size: 100000 };
  const selectedOrganization = unref(context).organization;
  const organizationId = selectedOrganization?.id;

  const productModelListValue = unref(productModelList);
  const assetTypeListValue = unref(assetTypeList);
  const selectedAssetTypeCodesValue = assetTypeListValue.data
    ?.filter((el) => el.selected)
    .map((el) => el.assetType);
  const selectedProductModels = productModelListValue.data
    ?.filter((el) => el.selected)
    .map((el) => el.productModel);

  const maintenanceItemListSelectedCheckboxesValue = unref(
    maintenanceItemListSelectedCheckboxes
  );
  const maintenanceItemListIdsValue =
    maintenanceItemListSelectedCheckboxesValue?.map((mi) => mi.id) ?? [];

  const queryParameters: QueryParameter = {
    filters: [
      ...(organizationId?.length
        ? [
            {
              name: 'organizationId',
              operator: FilterOperator.EQUAL,
              value: [organizationId],
            },
          ]
        : []),
      ...(selectedAssetTypeCodesValue?.length
        ? [
            {
              name: 'assetType',
              operator: FilterOperator.IN,
              value: selectedAssetTypeCodesValue,
            },
          ]
        : []),
      ...(selectedProductModels?.length
        ? [
            {
              name: 'productModel',
              operator: FilterOperator.IN,
              value: selectedProductModels,
            },
          ]
        : []),
      ...(maintenanceItemListIdsValue?.length
        ? [
            {
              name: 'maintenanceItemId',
              operator: FilterOperator.IN,
              value: maintenanceItemListIdsValue,
            },
          ]
        : []),
    ],
    sorters: [],
    pagination: pagination,
    // First timezone is used for 'normal' users, second one is for designated users
    timezone:
      selectedOrganization?.timezone ?? unref(context).primaryOrgTimeZone,
  };
  return queryParameters;
}

/**
 * Used to restore to default state (all maintenance item selected)
 * When switching between filters type (asset type, product model)
 */
function backupMaintenanceItemsRestoreToDefault(): void {
  unref(backUpMaintenanceItemList).map(
    (item: MaintenanceFilterItemSelected) => (item.selected = true)
  );
}
</script>

<template>
  <BaseCard
    class="maintenance-planner-main-container"
    ref="baseCardRef"
    :title="$t('AUTHRSC_PAGE_MAINT_PLANNER')"
    :backIconVisible="false"
    :showDialogBeforeBack="false"
    @handle-back="false"
  >
    <div class="no-assets" v-if="maintenanceFilterItems.length === 0">
      <span>{{ $tc('assetMgmt.noAssetsAvailableInThisOrg') }}</span>
    </div>
    <div class="maintenance-planner-container" v-else>
      <!-- This div is currently hidden due to task https://sioux-global.atlassian.net/browse/AHMAPP-6064-->
      <div class="add-new-maintenance-btn-container" style="display: none">
        <el-button
          class="add-new-maintenance-btn"
          id="addnewmplanbtn"
          type="plain"
          @click="showMaintenanceDialog(MaintenanceModalTriggerSource.New)"
        >
          <i class="el-icon-plus common-icon" />{{
            $t('maintenance.maintenancePlanner.addNewMaintenanceAppointment')
          }}
        </el-button>
      </div>
      <div class="maintenance-calendar-body">
        <div class="maintenance-calendar">
          <div class="maintenance-calendar-left">
            <div class="maintenance-items-label">
              {{ $t('maintenance.items') }}:
            </div>
            <div
              class="maintenance-items"
              v-loading="maintenanceFilterItems.length === 0"
            >
              <MultiAssetTypeSelect
                :assetTypeList="assetTypeList"
                @filterData="filterDataAssetType"
                :error="
                  selectedAssetTypeCodes?.length === 0
                    ? 'ApiFieldAssetTypeCode'
                    : undefined
                "
              />
              <MultiProductModelSelect
                v-show="
                  assetTypeList.data &&
                  assetTypeList.data.every((at) => !at.selected)
                "
                :productModelList="{
                  data: undefined,
                  loading: false,
                  error: undefined,
                }"
                @filterData="filterDataProductModel"
                :disabled="
                  assetTypeList.data === undefined ||
                  selectedAssetTypeCodes?.length === 0
                "
              />
              <MultiProductModelSelect
                v-show="
                  assetTypeList.data &&
                  assetTypeList.data.some((at) => !!at.selected)
                "
                :productModelList="productModelList"
                @filterData="filterDataProductModel"
                :disabled="
                  assetTypeList.data === undefined ||
                  selectedAssetTypeCodes?.length === 0
                "
              />
              <div class="maintenance-item-check-boxes">
                <div
                  class="maintenance-item-check-boxes-container"
                  v-if="
                    selectedProductModelIds &&
                    selectedProductModelIds.length > 0 &&
                    selectedAssetTypeCodes &&
                    selectedAssetTypeCodes.length > 0 &&
                    !maintenanceItemList.loading &&
                    !maintenanceItemList.error &&
                    maintenanceItemList.data &&
                    maintenanceItemList.data.length > 0
                  "
                >
                  <el-checkbox
                    v-for="maintenanceItem in maintenanceItemList.data"
                    :key="maintenanceItem.id"
                    :label="maintenanceItem.name"
                    v-model="maintenanceItem.selected"
                  >
                    {{ maintenanceItem.name }}
                  </el-checkbox>
                </div>
                <div v-else>
                  {{ $tc('common.noData') }}
                </div>
                <div v-if="maintenanceItemList.error">
                  {{ $tc('common.errorWithFetchingData') }}
                </div>
              </div>
            </div>
            <div
              class="mini-calendar"
              v-loading="
                schedulerAppointments?.loading ||
                maintenanceItemListSelectedCheckboxes?.length === 0 ||
                selectedAssetTypeCodes?.length === 0 ||
                selectedProductModelIds?.length === 0
              "
            >
              <Calendar
                :cell="customCell"
                @change="miniCalendarDaySelection"
                @hook:mounted="miniCalendarDefaultDaySelection"
              >
                <template v-slot:customMinicalendarTemplate="{ props }">
                  <Cell
                    :data-item="props.dataItem"
                    :is-focused="props.isFocused"
                    :is-selected="props.isSelected"
                    :is-today="props.isToday"
                    :value="props.value"
                    :formatted-value="props.formattedValue"
                    :appointments-dates="appointmentDates.data"
                    @click="(e) => props.onClick(e)"
                  />
                </template>
              </Calendar>
            </div>
          </div>
          <div
            class="maintenance-calendar-right"
            v-loading="
              schedulerAppointments?.loading ||
              maintenanceItemListSelectedCheckboxes?.length === 0 ||
              selectedAssetTypeCodes?.length === 0 ||
              selectedProductModelIds?.length === 0
            "
          >
            <CustomMaintenanceScheduler
              v-if="!schedulerAppointments.loading && schedulerData.length > 0"
              @edit="editSchedulerEvent"
              :currentDate="miniCalendarSelectedDate"
              :scheduledAppointments="schedulerData"
            />
          </div>
        </div>
      </div>
      <MaintenanceModal
        v-if="isModalVisible"
        class="appointment-modal"
        :isModalVisible="isModalVisible"
        :title="maintenanceModalTitle"
        :appointment="selectedAppointment"
        :modalTriggerSource="modalTriggerSource"
        @close="handleModalCancel"
        @reload="handleReloadPlanner"
      />
    </div>
  </BaseCard>
</template>

<style lang="scss" scoped>
.maintenance-items {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}

.maintenance-items :deep(.el-input__inner) {
  border-radius: 0px;
}

.maintenance-items-label {
  margin-bottom: 5px;
  font-weight: 600;
}

.maintenance-item-check-boxes {
  border: 0.5px solid;
  border-color: rgba(0, 0, 0, 0.08);
  padding: 5px 0px 5px 5px;
}

.no-assets {
  width: 100%;
  height: 100%;
  text-align: center;
  padding-top: 30px;
  font-size: 20px;
}

.maintenance-item-check-boxes-container {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  max-height: 225px;
}

.maintenance-planner-main-container {
  overflow: hidden;
}

.page-title-container {
  display: flex;
  padding: 10px;
  border-bottom: 0.5px solid #dddddd;
  align-items: center;
}

.page-title-image {
  width: 24px;
  height: 24px;
  margin-left: 20px;
}

.page-content-title {
  margin-left: 10px;
  font-size: 20px;
  font-weight: medium;
  font-family: $font-Roboto-Medium;
}

.maintenance-planner-container {
  background-color: #fff;
  border-radius: 4px;
  height: 100%;
  width: 100%;
}

.add-new-maintenance-btn-container {
  border-bottom: 0.5px solid #dddddd;
}

.add-new-maintenance-btn {
  margin: 16px 0 16px 30px;
}

.maintenance-calendar-body {
  min-height: 71vh;
  overflow: hidden;
  padding: 10px 20px 20px 20px;
}

.maintenance-calendar {
  display: flex;
  flex-direction: row;
  height: auto;
}

.mini-calendar {
  margin-bottom: 10px;
}

.maintenance-calendar-left {
  flex-basis: 16%;
  width: 100%;
  box-sizing: border-box;
  padding: 10px;
}

.maintenance-calendar-right {
  flex-basis: 84%;
  width: 100%;
  box-sizing: border-box;
  padding: 10px;
  min-width: 70%;
  overflow: auto;
}

.appointment-modal :deep(.el-dialog) {
  width: 650px !important;
}
</style>
