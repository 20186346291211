import { ActiveContext } from '@/auth/context';
import { api } from '@/utils/api';
import { getRequest } from '@/utils/request';
import { UUID } from './common';
import { CommonResultPromise } from './commonResult';
import { Geofence } from './geofenceTypes';

export async function getGeofenceById(id: UUID): Promise<Geofence> {
  return await api().get<Geofence>(`/geofences/${id}`);
}

export enum LifeCycle {
  Draft = 'GFNLCL_DRAFT',
  Active = 'GFNLCL_ACTIVE',
  Deprecated = 'GFNLCL_DEPRECATED',
}

export type GeofenceResponse = {
  geofences: Geofence[];
};

export function getAllGeofencesByOrganisationId(
  id: string,
  context?: ActiveContext
): CommonResultPromise<GeofenceResponse> {
  const request = getRequest();
  const response = request.get<GeofenceResponse>(
    `/geofence_by_organizations_by_organization/${id}`,
    {
      params: {
        selectedCustomer: context?.impersonatedCompanyId,
      },
    }
  );
  return response;
}

export function updateGeofence(id: string, data: any): CommonResultPromise {
  const request = getRequest();
  const response = request.request({
    url: `/geofences/${id}`,
    method: 'put',
    data: data,
  });
  return response;
}

export function createGeofence(data: any): CommonResultPromise {
  const request = getRequest();
  const response = request.request({
    url: '/geofences/',
    method: 'post',
    data: data,
  });
  return response;
}

export function activateGeofence(id: string): CommonResultPromise {
  const request = getRequest();
  const response = request.request({
    url: `/geofences/${id}`,
    method: 'put',
    data: { lifeCycle: 'GFNLCL_ACTIVE' },
  });
  return response;
}

export function deleteGeofence(id: string): CommonResultPromise {
  const request = getRequest();
  const response = request.request({
    url: `/geofences/${id}`,
    method: 'delete',
  });
  return response;
}

export function deprecateGeofence(id: string): CommonResultPromise {
  const request = getRequest();
  const response = request.request({
    url: `/geofences/${id}`,
    method: 'put',
    data: { lifeCycle: 'GFNLCL_DEPRECATED' },
  });
  return response;
}

export function searchGeofence(
  searchque: string,
  filter: string
): CommonResultPromise {
  const request = getRequest();
  const response = request.request({
    url: `/geofence_by_organizations_by_organization/${searchque}?name${filter}`,
    method: 'get',
  });
  return response;
}
