var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"app",attrs:{"id":"app"}},[(
      _setup.loginStatus === _setup.LoginStatus.Error ||
      _setup.consentState.userConsents.value.error
    )?_c(_setup.ServiceUnavailable):(
      _setup.loginStatus === _setup.LoginStatus.Pending ||
      _setup.consentState.userConsents.value.loading
    )?_c(_setup.Loading):(_setup.loginStatus === _setup.LoginStatus.NotFound)?_c(_setup.UserNotFound):(_setup.noPermissions)?_c(_setup.NoPermissions):(_setup.consentState.needsConsent.value)?_c(_setup.TermsAndConditionsDialog,{attrs:{"userConsents":_setup.consentState.userConsents.value.data},on:{"cancel":function($event){return _setup.logout()},"agree":_setup.consentState.agree}}):(_setup.consentState.hasConsented.value)?_c('router-view'):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }