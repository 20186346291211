<script lang="ts">
import {
  FinishDeployment,
  getFinishedDeployments,
} from '@/api/systemReleaseManagement';
import { flattenOrganizations } from '@/auth/context';
import { LoggedInUserRef, useLoggedInUser } from '@/auth/user';
import PureTable from '@/components/table/PureTable.vue';
import {
  Filter,
  FilterOperator,
  QueryParameter,
  Sorter,
  SorterOrder,
} from '@/model/queryParameters/QueryParameter';
import { UserModule } from '@/store/modules/user';
import { calculateTimeDifference } from '@/utils/misc';
import { DEPLOYMENT_HISTORY_MANAGEMENT_COLS } from '@/utils/workData/deploymentHistoryManagementCols';
import { COMPANY_TYPE, PROPER_ASSET_TYPE } from '@/utils/workData/lookuptable';
import { Component, Vue } from 'vue-property-decorator';

@Component({
  name: 'History',
  components: {
    PureTable,
  },
})
export default class extends Vue {
  /** Local variables */
  cols = DEPLOYMENT_HISTORY_MANAGEMENT_COLS.map((item) => ({
    ...item,
    label: this.$t(item.label),
  }));
  pageTotal = UserModule.gridPageSize;
  data: FinishDeployment[] = [];
  numberOfReleases = this.data.length;
  searchInput = '';
  historyTableIsLoading: boolean = false;
  filterList: { field: string; label?: string; dropdownEnum?: any }[] = [
    { field: 'systemReleaseId' },
    { field: 'companyName' },
    { field: 'deploymentBy' },
    { field: 'assetType', dropdownEnum: PROPER_ASSET_TYPE },
    { field: 'productModelCode' },
    { field: 'productModelNumber' },
  ];
  filterEnum: any = null;
  tableFilter: { searchFieldName: string; searchFieldValue: string | null } = {
    searchFieldName: this.filterList[0].field,
    searchFieldValue: null,
  };
  pagination = {
    page: 1,
    size: UserModule.gridPageSize,
  };
  appliedFilters: QueryParameter = {
    filters: [],
    sorters: [],
    pagination: this.pagination,
  };
  loggedInUser!: LoggedInUserRef;
  isDifferentUserThanHyvaadmin: boolean = false;

  created(): void {
    this.loggedInUser = useLoggedInUser();
    this.isDifferentUserThanHyvaadmin =
      UserModule.companyType != COMPANY_TYPE.Hyva;

    this.prepareDefaultQueryParameters();
    this.getData();
  }

  /**
   * Prepare default query parameters
   */
  prepareDefaultQueryParameters(): void {
    if (this.isDifferentUserThanHyvaadmin && this.loggedInUser.value) {
      const filterByOrganizations: Filter = {
        name: 'organizationId',
        operator: FilterOperator.IN,
        value: flattenOrganizations(this.loggedInUser.value.organization).map(
          (org) => org.id
        ),
      };
      this.appliedFilters.filters = [filterByOrganizations];
    }
    this.applyDefaultSorterByDeploymentEndTime();
  }

  /**
   * Handle get history deployments
   */
  async getData(): Promise<void> {
    try {
      this.data = [];
      this.historyTableIsLoading = true;
      const response = await getFinishedDeployments(this.appliedFilters);
      this.data = response.data?.deployments.map((item) => ({
        ...item,
        assetType: this.$t(item.assetType),
      }));
      this.pageTotal = response.data.total;
      this.data.map((systemDeployment: FinishDeployment) => {
        const diff = calculateTimeDifference(
          systemDeployment.deploymentStartTime,
          systemDeployment.deploymentEndTime
        );
        systemDeployment.totalTimeSpent = diff;
      });
    } catch (err) {
      console.log(err);
    } finally {
      this.historyTableIsLoading = false;
    }
  }

  /**
   * Handle search input event
   */
  handleSearchInput(): void {
    this.appliedFilters.filters = [];
    if (this.isDifferentUserThanHyvaadmin) this.applyDefaultFilter();
    if (this.tableFilter.searchFieldValue) {
      const searchFilter: Filter = {
        name: this.tableFilter.searchFieldName,
        operator: FilterOperator.LIKE,
        value: [encodeURIComponent(this.tableFilter.searchFieldValue)],
      };
      this.appliedFilters.filters.push(searchFilter);
    }
    this.getData();
  }

  /**
   * Handle search clear event
   */
  async handleSearchClear(): Promise<void> {
    this.tableFilter.searchFieldValue = '';
    this.filterEnum = this.filterList.find(
      (e) => e.field === this.tableFilter.searchFieldName
    )?.dropdownEnum;
    this.handleSearchInput();
  }

  /**
   * Handle page selection event
   * @param page
   */
  handlePage(page: number): void {
    this.pagination.page = page;
    this.getData();
  }

  /**
   * Handle sort change event
   * @param sortField
   * @param sortOrder
   */
  async handleSortChange(sortField: string, sortOrder: string): Promise<void> {
    if (sortField === 'totalTimeSpent') return;
    this.appliedFilters.sorters = [];
    if (sortOrder) {
      const currentSorter: Sorter = {
        field: sortField,
        order:
          sortOrder === SorterOrder.ASC ? SorterOrder.ASC : SorterOrder.DESC,
      };
      this.appliedFilters.sorters = [currentSorter];
    } else {
      this.applyDefaultSorterByDeploymentEndTime();
    }
    this.getData();
  }

  /**
   * Apply default sorter by created on
   * - deploymentFinishTime will be retrieved DESC by the API
   */
  applyDefaultSorterByDeploymentEndTime(): void {
    const defaultSorter: Sorter = {
      field: 'deploymentEndTime',
      order: SorterOrder.DESC,
    };
    this.appliedFilters.sorters = [defaultSorter];
  }

  /**
   * Apply default filter from current logged in user:
   * - current org and sub org levels
   */
  applyDefaultFilter(): void {
    if (!this.loggedInUser.value) return;
    const filterByOrganizations: Filter = {
      name: 'organizationId',
      operator: FilterOperator.IN,
      value: flattenOrganizations(this.loggedInUser.value.organization).map(
        (org) => org.id
      ),
    };
    this.appliedFilters.filters = [filterByOrganizations];
  }
}
</script>

<template>
  <div class="table-container">
    <div class="filter-container">
      <div class="d-flex ai-center search-select-container">
        <el-select
          class="search-select-field"
          id="releaseFilter"
          v-model="tableFilter.searchFieldName"
          @change="handleSearchClear"
          filterable
        >
          <el-option
            v-for="item in filterList"
            :key="item.label"
            :label="
              $t(item.label || `deployManagementModule.history.${item.field}`)
            "
            :value="item.field"
          />
        </el-select>
        <el-select
          v-if="filterEnum"
          @change="handleSearchInput"
          v-model="tableFilter.searchFieldValue"
          class="search-input-content"
        >
          <el-option
            class="filter-select-option"
            v-for="item of filterEnum"
            :key="item"
            :label="$t(item)"
            :value="item"
          />
        </el-select>
        <el-input
          v-else
          id="input-search-value"
          class="search-input-content"
          :placeholder="$t('common.inputKeywordToSearch')"
          v-model="tableFilter.searchFieldValue"
          @clear="handleSearchClear"
          @keyup.native="handleSearchInput"
          clearable
        >
          <i slot="suffix" class="el-icon-search" @click="handleSearchInput" />
        </el-input>
      </div>
      <PureTable
        v-loading="historyTableIsLoading"
        class="release-table"
        :tableList="data"
        :total="pageTotal"
        :cols="cols"
        :viewPagePath="'history/details'"
        @handle-page="handlePage"
        @handle-sort-change="handleSortChange"
      />
    </div>
  </div>
</template>

<style lang="scss" scoped>
:deep(.release-table) {
  width: 100%;
  :deep(.el-table__body colgroup col) {
    width: 300px;
  }
}

.new-deploy-button {
  margin: auto 0 auto auto;
}

.add-icon {
  margin: auto 15px auto auto;
}

.filter-container {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
}

.filter-relese {
  margin: 0;
}

.search-input-content {
  width: 400px;
}

.table-container {
  width: 100%;
  padding: 16px;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 3px 6px #c1c1c1;
  display: flex;
  flex-direction: column;
}

.search-select-container {
  :deep(.el-input__inner) {
    height: 40px;
    border: 1px solid #818181;
  }

  .search-select-field :deep(.el-input__inner) {
    width: 252px;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    border-right: transparent !important;
  }

  .search-select-field {
    margin: 0;
  }

  .search-input-content :deep(.el-input__inner) {
    width: 270px;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
  }

  .search-input-content :deep(.el-input__suffix) {
    line-height: 40px;
    color: rgba(0, 0, 0, 0.6);
    font-size: 18px;
    cursor: pointer;
    width: 282px;
  }
}
</style>
