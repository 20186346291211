<script lang="ts">
import { AssetDistribution, getAssetDistribution } from '@/api/assets';
import {
  applyMaintenanceItemById,
  deprecateMaintenanceItemById,
  getMaintItemById,
  MainteananceItemById,
  MaintenanceItemPart,
  MaintenanceItemRule,
} from '@/api/maintenance';
import {
  ContainedPart,
  getProductModelById,
  ProductModel,
} from '@/api/products';
import NewCard from '@/components/cusCard/NewCard.vue';
import GeneralInfo from '@/components/form/GeneralInfo.vue';
import {
  FilterOperator,
  QueryParameter,
} from '@/model/queryParameters/QueryParameter';
import { customFailedMessage, promptSuccessBox } from '@/utils/prompt';
import {
  AssetLifecycle,
  MAINTENANCE_ITEM_OPERATIONS_KIND_ENUM,
  MAINTENANCE_ITEM_STATUS,
} from '@/utils/workData/lookuptable';
import { Component, Prop, Vue } from 'vue-property-decorator';
import BaseHeader from './components/BaseHeader.vue';
import Modal from './components/Modal.vue';

@Component({
  name: 'viewMaintConf',
  components: {
    'general-info': GeneralInfo,
    'new-card': NewCard,
    'base-header': BaseHeader,
    modal: Modal,
  },
})
export default class extends Vue {
  @Prop() id!: string;

  /** Local variables */
  title: string = '';
  visible: boolean = false;
  dialogContent: any = '';
  partToReplacedList: string[] = [];
  partToCheckedList: string[] = [];
  maintenanceItemStatus: string = MAINTENANCE_ITEM_STATUS.MainiNew;
  isMaintenanceViewConfigLoading: boolean = false;
  isApplyBtnLoading: boolean = false;
  maintConfigInfo: MainteananceItemById = {
    id: '',
    name: '',
    assetType: '',
    productModel: '',
    maintenanceParts: [],
    maintenanceRules: [],
    productModelId: '',
    status: '',
    duration: '',
    isConductedManually: false,
  };
  maintenancePartsListIsLoading: boolean = false;
  modalDialogIsLoading: boolean = false;

  created() {
    this.fetchMaintConfInfoById();
  }

  /**
   * Handle edit maintenance item
   */
  handleEdit(): void {
    this.$router.push(
      `/maintenance-conf/create-new-maint-item/edit/${this.id}`
    );
  }

  /**
   * Prepare custom query parameters for requesting asset distribution
   */
  get queryParametersForAssetDistribution() {
    const finalQueryParameters: QueryParameter = {};
    finalQueryParameters.filters = [
      {
        name: 'productModelId',
        operator: FilterOperator.EQUAL,
        value: [this.maintConfigInfo.productModelId],
      },
      {
        name: 'status',
        operator: FilterOperator.EQUAL,
        value: [AssetLifecycle.Assigned],
      },
    ];

    return finalQueryParameters;
  }

  /**
   * Handle display modal for appling maintenance item
   * Show to user the number of affected assets and customers
   */
  async handleDisplayModal(): Promise<void> {
    try {
      this.isApplyBtnLoading = true;
      const res = await getAssetDistribution(
        this.queryParametersForAssetDistribution
      );
      if (res.code === 200) {
        let totalAssets: number = res.data.reduce<number>(
          (
            previousValue: number,
            currentValue: AssetDistribution,
            _currentIndex: number,
            _array: AssetDistribution[]
          ) => {
            const result =
              Number(previousValue) + Number(currentValue.noOfAssets);
            return result;
          },
          0
        );
        let uniqueCustomers: string[] = [];
        let methodAction: string =
          this.maintenanceItemStatus === MAINTENANCE_ITEM_STATUS.MainiNew
            ? this.$t('common.apply').toString().toLowerCase()
            : this.maintenanceItemStatus ===
              MAINTENANCE_ITEM_STATUS.MainiApplied
            ? this.$t('common.deprecate').toString().toLowerCase()
            : '';

        res.data.forEach((item: AssetDistribution) => {
          if (
            !uniqueCustomers.some(
              (uniqueCustomer: string) => uniqueCustomer === item.companyId
            )
          ) {
            uniqueCustomers.push(item.companyId);
          }
        });

        this.dialogContent = this.$t('maintConf.modalContent', {
          method: methodAction,
          itemName: `<span class="highlight-modal-info">${this.title}</span>`,
          totalAssets: `<span class="highlight-modal-info">${totalAssets}</span>`,
          totalCustomers: `<span class="highlight-modal-info">${uniqueCustomers.length}</span>`,
        }).toString();
      }
    } catch (error) {
      console.log(error);
    } finally {
      this.visible = true;
      this.isApplyBtnLoading = false;
    }
  }

  /**
   * Handle cancel
   */
  handleCancel(): void {
    this.visible = false;
  }

  /**
   * Handle Apply mainteance item
   */
  async handleApply(): Promise<void> {
    try {
      this.modalDialogIsLoading = true;
      const res = await applyMaintenanceItemById(this.maintConfigInfo.id);
      if (res.code === 200) {
        promptSuccessBox(this.$t('MAINI_APPLIED').toString());
        this.maintenanceItemStatus = MAINTENANCE_ITEM_STATUS.MainiApplied;
      }

      if (res.code === 400 || res.code === 500) {
        customFailedMessage(this.$tc('maintConf.couldNotApply'));
      }
    } catch (error) {
      console.log(error);
    } finally {
      this.modalDialogIsLoading = false;
      this.visible = false;
    }
  }

  /**
   * Handle deprecate maintenance item
   */
  async handleDeprecate(): Promise<void> {
    try {
      this.modalDialogIsLoading = true;
      const res = await deprecateMaintenanceItemById(this.maintConfigInfo.id);
      if (res.code === 200) {
        promptSuccessBox(this.$t('MAINI_DEPRECATED').toString());
        this.maintenanceItemStatus = MAINTENANCE_ITEM_STATUS.MainiDeprecated;
      }

      if (res.code === 400 || res.code === 500) {
        customFailedMessage(this.$tc('maintConf.couldNotDeprecate'));
      }
    } catch (error) {
      console.log(error);
    } finally {
      this.modalDialogIsLoading = false;
      this.visible = false;
    }
  }

  /**
   * Fetch maintenance configuration item by id
   */
  async fetchMaintConfInfoById(): Promise<void> {
    try {
      this.isMaintenanceViewConfigLoading = true;
      const res = await getMaintItemById(this.id);
      if (res.code === 200) {
        this.fetchProductModelById(res.data.productModelId, res.data!);
        this.title = res.data.name;
        this.maintConfigInfo = res.data;
        this.maintenanceItemStatus = res.data.status;
        this.moveDefaultRuleToFirstPosition();
        return;
      }

      if (
        res.code === 400 &&
        res.data.errors![0].code === 'ApiErrorFieldNotFound'
      ) {
        customFailedMessage(
          this.$t('maintConf.maintenanceItemDoesNotExit').toString()
        );
      }
    } catch (error) {
      console.log(error);
    } finally {
      this.isMaintenanceViewConfigLoading = false;
    }
  }

  moveDefaultRuleToFirstPosition(): void {
    const indexOfNaturalKPICode =
      this.maintConfigInfo?.maintenanceRules?.findIndex(
        (item: MaintenanceItemRule) =>
          item.maintenanceKpiCode === 'MKPI.NaturalDays'
      );
    if (indexOfNaturalKPICode && indexOfNaturalKPICode > 0) {
      const element = this.maintConfigInfo?.maintenanceRules?.splice(
        indexOfNaturalKPICode,
        1
      )[0];
      this.maintConfigInfo?.maintenanceRules?.splice(0, 0, element!);
    }
  }

  /**
   * Fetch product model by id then from contained parts
   * Extract contained part name that will be corelated with mainteannce item part status to be checked/ to be replaced
   */
  async fetchProductModelById(
    productModelId: string,
    maintenanceItem: MainteananceItemById
  ): Promise<void> {
    try {
      this.maintenancePartsListIsLoading = true;
      const res = await getProductModelById(productModelId);
      const productModel: ProductModel = res.data;

      this.maintConfigInfo?.maintenanceParts?.forEach(
        (v: MaintenanceItemPart) => {
          const productModelName = productModel?.containedParts?.find(
            (part: ContainedPart) => part.id === v.containedPartId
          )?.name;
          if (
            v.maintenanceOperationKind ===
              MAINTENANCE_ITEM_OPERATIONS_KIND_ENUM.MAINOK_REPLACE &&
            productModelName
          ) {
            this.partToReplacedList.push(productModelName);
          } else if (
            v.maintenanceOperationKind ===
              MAINTENANCE_ITEM_OPERATIONS_KIND_ENUM.MAINOK_CHECK &&
            productModelName
          ) {
            this.partToCheckedList.push(productModelName);
          }
        }
      );
    } catch (error) {
      console.log(error);
    } finally {
      this.maintenancePartsListIsLoading = false;
    }
  }
}
</script>

<template>
  <div v-loading="isMaintenanceViewConfigLoading" class="app-container">
    <div id="maint_conf_home_title" style="margin-left: 20px">
      <span class="header-title">{{ $t('maintConf.maintConfiguration') }}</span>
    </div>

    <div
      style="
        border-bottom: 1px solid #dddddd;
        padding-top: 10px;
        margin: 0 -20px;
      "
    />

    <base-header
      :title="id ? title : $t('Maintenance Item Name')"
      :backIconVisible="true"
    >
      <el-button
        v-if="maintenanceItemStatus != 'MAINI_DEPRECATED'"
        id="new_card_header_btn_edit"
        v-permission="['AUTHRSC_ACTION_MAINT_ITEM_UPDATE']"
        style="margin-right: 10px; width: 80px"
        type="plain"
        @click="handleEdit"
      >
        <div class="d-flex ai-center jc-center">
          <div>
            <img style="margin-right: 5px" src="@/assets/imgs/edit.svg" />
          </div>
          <div>
            {{ $t('common.edit') }}
          </div>
        </div>
      </el-button>
      <el-button
        v-loading="isApplyBtnLoading"
        id="new_card_header_btn_deactivate"
        v-permission="['AUTHRSC_ACTION_MAINT_ITEM_APPLY']"
        v-if="maintenanceItemStatus === 'MAINI_NEW'"
        type="plain"
        style="width: 120px"
        @click="handleDisplayModal"
      >
        <div class="d-flex ai-center jc-center">
          <div>
            <img
              class="icon-content"
              src="@/assets/imgs/maintenance/config/calendar_checkbox.svg"
            />
          </div>
          <div><i class="el-icon-calendar" />{{ $t('common.apply') }}</div>
        </div>
      </el-button>
      <el-button
        id="new_card_header_btn_deactivate"
        v-if="maintenanceItemStatus === 'MAINI_APPLIED'"
        v-permission="['AUTHRSC_ACTION_MAINT_ITEM_DEPRECATE']"
        type="plain"
        style="width: 120px"
        @click="handleDisplayModal"
      >
        <div class="d-flex ai-center jc-center">
          <div>
            <img style="margin-right: 5px" src="@/assets/imgs/deactivate.svg" />
          </div>
          <div>
            {{ $t('common.deprecate') }}
          </div>
        </div>
      </el-button>
    </base-header>

    <div style="border-bottom: 1px solid #dddddd; margin: 0 -20px" />

    <div style="margin-left: 20px">
      <general-info
        id="view_maint_conf_itemName"
        :name="$t('maintConf.itemName')"
        :value="maintConfigInfo.name"
      ></general-info>
      <general-info
        id="view_maint_conf_assetType"
        :name="$t('maintConf.assetType')"
        :value="$t(maintConfigInfo.assetType)"
      ></general-info>
      <general-info
        id="view_maint_conf_productModel"
        :name="$t('maintConf.productModel')"
        :value="maintConfigInfo.productModel"
      ></general-info>

      <div
        v-loading="maintenancePartsListIsLoading"
        id="view_maint_conf_parts"
        class="cus-form-module"
        style="margin-top: 30px"
      >
        <div id="view_maint_conf_parts_header" class="cus-form-module-header">
          <span class="show-title">{{ $t('maintConf.partList') }}:</span>
        </div>
        <div id="view_maint_conf_parts_content">
          <div class="part-list-header">
            <span>{{ $t('maintConf.partsReplace') }}</span>
          </div>
          <div
            id="view_maint_conf_parts_replace"
            class="part-list-box"
            style="overflow-y: auto"
          >
            <div
              v-for="(item, index) in partToReplacedList"
              :id="'view_maint_conf_parts_replace' + index"
              :key="index"
              style="margin-top: 16px; margin-left: 20px"
            >
              {{ item }}
            </div>
          </div>
          <div class="part-list-header" style="margin-top: 20px">
            <span>{{ $t('maintConf.partsCheck') }}</span>
          </div>
          <div
            id="view_maint_conf_parts_check"
            class="part-list-box"
            style="overflow-y: auto"
          >
            <div
              v-for="(item, index) in partToCheckedList"
              :id="'view_maint_conf_parts_check' + index"
              :key="index"
              style="margin-top: 16px; margin-left: 20px"
            >
              {{ item }}
            </div>
          </div>
        </div>
      </div>

      <div
        id="view_conf_maint_rules"
        class="cus-form-module"
        style="margin-top: 30px"
      >
        <div id="view_conf_maint_rules_header" class="cus-form-module-header">
          <span class="show-title">{{ $t('maintConf.maintRules') }}:</span>
        </div>
        <div id="view_conf_maint_rules_body">
          <div
            v-for="(item, index) in maintConfigInfo.maintenanceRules"
            :id="'view_conf_maint_rules_body' + index"
            :key="index"
          >
            <div v-if="index != 0" class="rules-relation">
              <span>{{ $t('maintConf.or') }}</span>
            </div>
            <div class="d-flex ai-center" style="margin-bottom: 10px">
              <div style="width: 100px; background-color: red"></div>
              <div class="rules-kpi" style="width: 300px">
                {{ $t('maintConf.kpi') }}
              </div>
              <div class="rules-kpi" style="width: 300px">
                {{ $t('maintConf.value') }}
              </div>
            </div>
            <div class="d-flex ai-center">
              <div class="maint-rules" style="width: 100px">
                {{ $t('maintConf.rule') }} {{ index + 1 }}
              </div>
              <div class="maint-value" style="width: 300px">
                {{ $t(item.maintenanceKpiCode) }}
              </div>
              <div class="maint-value" style="width: 300px">
                {{ item.thresholdValue }}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        id="view_conf_maint_rules"
        class="cus-form-module maintenance-conduct-method"
        style="margin-top: 30px"
      >
        <div
          id="view_conf_maint_rules_header"
          class="cus-form-module-header method-conduct-header"
        >
          <span class="show-title">{{ $t('maintConf.method') }}:</span>
        </div>
        <div class="radio-item">
          <input
            v-model="maintConfigInfo.isConductedManually"
            :disabled="true"
            type="radio"
            id="ritemb"
            name="ritem"
            :value="Boolean(false)"
          />
          <label for="ritemb">{{ $t('maintConf.automaticallyConduct') }}</label>
        </div>

        <div class="radio-item">
          <input
            v-model="maintConfigInfo.isConductedManually"
            :disabled="true"
            type="radio"
            id="ritema"
            name="ritem"
            :value="Boolean(true)"
          />
          <label for="ritema">{{ $t('maintConf.manuallyConduct') }}</label>
        </div>
      </div>
    </div>
    <modal
      id="view_maint_common_dialog"
      :visible="visible"
      :title="title"
      :isApplyStatus="maintenanceItemStatus === 'MAINI_NEW' ? true : false"
      :content="dialogContent"
      :dialogIsLoading="modalDialogIsLoading"
      @handle-cancel="handleCancel"
      @handle-apply="handleApply"
      @handle-deprecate="handleDeprecate"
    >
      <div class="modal-content" v-html="dialogContent" />
    </modal>
  </div>
</template>

<style scoped>
.modal-content :deep(.highlight-modal-info) {
  color: var(--Main) !important;
}

.modal-content {
  padding: 30px 80px;
  text-align: center;
  font-size: 20px;
  line-height: 1.6;
  font-family: var(--fontRobotoMedium);
  word-break: keep-all;
}

.icon-content {
  height: 15px;
  width: 20px;
  margin-right: 4px;
}
</style>

<style lang="scss" scoped>
.scrollbar-wrapper {
  overflow-x: hidden !important;
}

.rules-kpi {
  font-size: 20px;
  font-family: $font-Roboto-Medium;
  line-height: 24px;
  color: #373e41;
  opacity: 0.6;
}

.maint-value {
  font-size: 20px;
  font-family: $font-Roboto-Regular;
  line-height: 24px;
  color: #373e41;
  opacity: 1;
}

.part-list-header {
  margin-bottom: 8px;
  font-size: 20px;
  font-family: $font-Roboto-Medium;
  line-height: 24px;
  color: #373e41;
}

.part-list-box {
  height: 140px;
  width: 320px;
  border: 1px solid #707070;
  border-radius: 4px;

  font-size: 18px;
  font-family: $font-Roboto-Regular;
  line-height: 21px;
  color: #373e41;
}

.maintenance-conduct-method {
  margin-top: 40px;
}

.maintenance-conduct-method :deep(.item-title) {
  padding-right: 22px;
}

.maintenance-conduct-method :deep(.item-content) {
  margin-top: -12px;
  margin-left: -10px;
}

.radio-item {
  display: inline-block;
  position: relative;
  padding: 0 6px;
  font-size: 20px;
  font-family: $font-Roboto-Medium;
}

.radio-item input[type='radio'] {
  display: none;
}

.radio-item label {
  color: #666;
  font-weight: normal;
}

.radio-item label:before {
  content: ' ';
  display: inline-block;
  position: relative;
  top: 5px;
  margin: 0 5px 0 0;
  width: 20px;
  height: 20px;
  border-radius: 11px;
  border: 2px solid var(--Main);
  background-color: transparent;
}

.radio-item input[type='radio']:checked + label:after {
  border-radius: 11px;
  width: 12px;
  height: 12px;
  position: absolute;
  top: 9px;
  left: 10px;
  content: ' ';
  display: block;
  background: var(--Main);
}

.method-conduct-header {
  margin-top: 4px;
  margin-right: 12px;
}
</style>
