<script lang="ts">
import { AsyncValue } from '@/composables/async';
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';

export interface ProductModelSelectItem {
  productModel: string;
  productModelId: string;
  selected: boolean;
}

@Component({
  name: 'MultiProductModelSelect',
})
export default class extends Vue {
  @Prop() productModelList!: AsyncValue<ProductModelSelectItem[] | undefined>;
  @Prop() disabled!: boolean;

  // el-select props
  selectedProductModelOptions: string[] = [];

  //el-switch props
  selectedProductModelsSwitch: ProductModelSelectItem[] = [];

  allDropdownProductModelsSelected: boolean = true;

  /**
   * Check the received list changes to reflect in the dropdown
   * @param newData
   */
  @Watch('productModelList', { immediate: true })
  handleData(newData: AsyncValue<ProductModelSelectItem[] | undefined>): void {
    this.determineAllSelectionState();
    if (newData.data?.length) {
      this.selectedProductModelOptions = this.getSelectedItemsName(
        newData.data
      );
      this.selectedProductModelsSwitch = newData.data;
    }
  }

  /**
   * Determine 'All' btn state by comparing the total number of items list against which one are selected
   */
  determineAllSelectionState(): void {
    this.allDropdownProductModelsSelected =
      this.productModelList.data?.length ===
      this.productModelList.data?.filter((item) => item.selected)?.length;
  }

  /**
   * Filter selected items then return the name of those objects into the main input element
   * @param itemsList
   */
  getSelectedItemsName(itemsList: ProductModelSelectItem[]): string[] {
    const selectedItems: ProductModelSelectItem[] = itemsList?.filter(
      (item: ProductModelSelectItem) => item.selected
    );
    return selectedItems?.map(
      (obj: ProductModelSelectItem) => obj.productModel
    );
  }

  /**
   * Handle item dropdown changes
   */
  async itemDropdownChanged(): Promise<void> {
    this.selectedProductModelsSwitch.forEach(
      (item: ProductModelSelectItem) =>
        (item.selected = this.selectedProductModelOptions.includes(
          item.productModel
        ))
    );
    this.determineAllSelectionState();
    this.$emit(
      'filterData',
      this.selectedProductModelsSwitch.filter(
        (item: ProductModelSelectItem) => item.selected
      )
    );
  }

  /**
   * Toggle all dropdown items selection
   */
  async toggleAllDropdownItemsSelected(): Promise<void> {
    this.allDropdownProductModelsSelected =
      !this.allDropdownProductModelsSelected;
    this.selectedProductModelsSwitch.forEach(
      (item: ProductModelSelectItem) =>
        (item.selected = this.allDropdownProductModelsSelected)
    );
    this.selectedProductModelOptions = this.selectedProductModelsSwitch
      .filter((item: ProductModelSelectItem) => item.selected)
      .map((item: ProductModelSelectItem) => item.productModel);
    this.$emit(
      'filterData',
      this.selectedProductModelsSwitch.filter(
        (item: ProductModelSelectItem) => item.selected
      )
    );
  }
}
</script>

<template>
  <el-select
    :class="
      selectedProductModelOptions.length > 1
        ? 'filter-select bigger-list'
        : 'filter-select'
    "
    v-model="selectedProductModelOptions"
    ref="itemFilterRef"
    multiple
    collapse-tags
    default-first-option
    :placeholder="$t('maintenance.maintenancePlanner.selectProductModel')"
    @change="itemDropdownChanged"
    :disabled="disabled"
  >
    <div
      class="filter-select-items-select-all"
      @click="toggleAllDropdownItemsSelected"
    >
      <span :style="true ? 'opacity: 1' : 'opacity: 0.7'">{{
        $t('common.all')
      }}</span>
      <el-switch
        :value="allDropdownProductModelsSelected"
        class="el-switch-component"
        active-color="var(--Main)"
        inactive-color="#D5D5D5"
      >
      </el-switch>
    </div>
    <el-divider class="filter-select-item-divider" />
    <el-option
      class="filter-select-option filter-select-items-option"
      v-for="item in selectedProductModelsSwitch"
      :key="item.productModelId"
      :value="item.productModel"
    >
      <span :style="item.selected ? 'opacity: 1' : 'opacity: 0.7'">{{
        item.productModel
      }}</span>
      <el-switch
        :value="item.selected"
        class="el-switch-component"
        active-color="var(--Main)"
        inactive-color="#D5D5D5"
      >
      </el-switch>
    </el-option>
  </el-select>
</template>

<style lang="scss" scoped>
.filter-select {
  width: 100%;
}

.filter-select-option {
  &.hover {
    background-color: unset !important;
  }

  &:hover {
    background-color: var(--DropdownHover) !important;
  }
}

.search-container {
  display: flex;
  align-items: center;

  .filter-select :deep(.el-input__inner) {
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    border-right: transparent !important;
  }

  // This is copied from the timeselector?
  .search-select-input :deep(.el-input__inner) {
    width: 300px;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
  }

  .search-select-dropdown :deep(.el-input__inner) {
    border-right: inherit;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
  }

  .search-select-dropdown {
    margin-right: 0;
  }
}

.filter-select-items-search-input {
  width: 210px;
  margin: 4px 20px;

  :deep(.el-input__inner) {
    width: 100%;
  }
}

.filter-select-items-select-all,
.filter-select-items-option {
  padding: 0 1rem !important; // has to be important to override some other css
  height: 34px; // The dropdown widget sets the height fixed instead using the padding
  display: flex;
  align-items: center;
  color: #606266;
  font-size: 16px;

  &:hover {
    cursor: pointer;
    background-color: var(--DropdownHover) !important;
  }

  span {
    color: #373e41;
    font-weight: 400;
  }
}

.filter-select-item-divider {
  margin: 4px 0;
}

.el-icon-search {
  line-height: 40px;
  color: rgba(0, 0, 0, 0.6);
  font-size: 18px;
  cursor: pointer;
  margin-right: 8px;
  margin-top: -4px;
}

.el-switch-component {
  margin: auto 0 auto auto;
}

.column-button {
  margin-left: auto;
  cursor: pointer;
  font-size: 16px;
  font-family: $font-Roboto-Medium;
  line-height: 19px;
  color: #373e41;

  :hover {
    color: var(--Main);
  }

  > img {
    width: 14px;
    height: 14px;
    margin-right: 8px;
  }
}

.column-checkbox {
  display: block;
  color: #373e41 !important;
}

.el-select-dropdown__item.selected::after {
  display: none;
}

.bigger-list :deep(.el-tag.el-tag--info) {
  max-width: 120px;
}
</style>
