/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon';
icon.register({
  dashboardConf: {
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
    data: '<g data-name="组件 353 – 9"><path pid="0" data-name="减去 15" d="M14 20H6a6.007 6.007 0 01-6-6V6a6.007 6.007 0 016-6h8a6.007 6.007 0 016 6v8a6.006 6.006 0 01-6 6zM6 1a5.006 5.006 0 00-5 5v8a5.006 5.006 0 005 5h8a5.005 5.005 0 005-5V6a5.006 5.006 0 00-5-5z" _fill="#373e41"/></g><g data-name="组 3021" transform="translate(21256 5182.25)" _fill="#373e41"><path pid="1" data-name="减去 21" d="M-21241.097-5168.511h-9.787a2.052 2.052 0 01-2.1-2v-5.989a2.052 2.052 0 012.1-2h9.789a2.052 2.052 0 012.1 2v5.991a2.052 2.052 0 01-2.102 1.998zm-5.606-2.989a.333.333 0 000 .667h6.332a.333.333 0 000-.667zm-4.232-4.667a1 1 0 00-1 1v3.332a1 1 0 001 1h2.2a1 1 0 001-1v-3.332a1 1 0 00-1-1zm4.232 3.332a.333.333 0 000 .667h6.332a.333.333 0 000-.667zm4.865-3.332a1 1 0 00-1 1v.667a1 1 0 001 1h.8a1 1 0 001-1v-.667a1 1 0 00-1-1zm-4.2 0a1 1 0 00-1 1v.667a1 1 0 001 1h1.5a1 1 0 001-1v-.667a1 1 0 00-1-1z" _stroke="#373e41" stroke-width=".2"/><rect pid="2" data-name="矩形 986" width="12" height="1" rx=".5" transform="translate(-21252 -5167)"/></g>',
  },
});
