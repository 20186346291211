/**
 * UUID.
 *
 * @example "e349e4ea-9ca6-457a-973c-57f8dcdaca8c"
 */
export type UUID = string;

/**
 * UTC timestamp formatted as string in ISO8601 format.
 * Example: 2021-11-19T08:50:41Z or 2021-11-19T08:50:41.123Z (including milliseconds).
 */
export type UTCTimestamp = string;

/**
 * Convert a single entity or list of entities into a comma-separated list to pass as
 * a query parameter in a URL. Returns undefined if the input is undefined or an empty list.
 * @param entities
 */
export function listToQueryParam(
  entities: string[] | string | undefined
): string | undefined {
  if (!entities) {
    return undefined;
  }
  if (Array.isArray(entities)) {
    if (entities.length === 0) {
      return undefined;
    }
    return entities.join(',');
  }
  return entities;
}
