<script lang="ts">
import { getKpiData } from '@/api/assets';
import { ActiveContext, useActiveContext } from '@/auth/context';
import KpiBarChart from '@/components/widget/KpiBarChart.vue';
import { KpiGrowthUnitValue } from '@/components/widget/kpiNumber/KpiNumber.vue';
import {
  useUnitConversion,
  UseUnitConversionReturnType,
} from '@/composables/conversion';
import { determineEntityType } from '@/utils/assetRouteChecker';
import { collateExpandTableData } from '@/utils/kpidata';
import { customFailedMessage } from '@/utils/prompt';
import { DEFAULT_DATE_RANGE } from '@/utils/time';
import { DateRange } from '@/utils/types/date';
import { KPI_UNIT } from '@/utils/units/unitDefinitions';
import { EntityType } from '@/utils/workData/lookuptable';
import { ROUTE_ASSET_MAP } from '@/utils/workData/utilMap';
import { Ref, unref } from 'vue';
import {
  Component,
  Inject,
  ProvideReactive,
  Vue,
  Watch,
} from 'vue-property-decorator';

@Component({
  name: 'FleetPower',
  components: {
    KpiBarChart,
  },
})
export default class extends Vue {
  dateRange = DEFAULT_DATE_RANGE;
  kpiNumberList: KpiGrowthUnitValue<number | undefined>[] = [
    {
      name: this.$t('KPI.totalPowerConsumption'),
      code: undefined,
      v: 0,
      unit: KPI_UNIT.KilowattHour,
      growth: undefined,
    },
    {
      name: this.$t('KPI.avePowerConsumption'),
      code: undefined,
      v: 0,
      unit: KPI_UNIT.KilowattHour,
      growth: undefined,
    },
  ];
  totalNumber: number | undefined = 0;
  averageNumber: number | undefined = 0;
  growth: number | undefined;
  KpiChartIsLoading: boolean = false;
  chartData: any = [];
  unitCode = 'UNIT_KWH';
  fleetType: string = 'date'; //initial "date", can be changed to "asset"
  routeAssetMap = ROUTE_ASSET_MAP;
  kpiTypeList: any = [
    {
      code: 'KPI.PowerConsumption',
      tableHeadLabel: 'KPI.powerConsumption',
      tableProp: 'powerConsumption',
      unit: 'UNIT_KWH',
      needGrowthPercentage: 'true',
    },
  ];
  entityType: EntityType = determineEntityType(this.$route);

  @ProvideReactive('tableList') tableList: any = [];
  @ProvideReactive('cols') cols: any = [];

  context!: Ref<ActiveContext>;
  unitConversion!: UseUnitConversionReturnType;

  @Inject() expanded!: boolean;

  created() {
    this.unitConversion = useUnitConversion();
    this.context = useActiveContext();
  }

  dateRangeChange(dateRange: DateRange) {
    this.dateRange = dateRange;
    this.fecthKpi();
  }

  collateAndCalculate(responseData: any) {
    let totalValue: number = 0;
    let availableLength: number = 0;
    responseData.forEach((item: any) => {
      if (item.v != null) {
        availableLength++;
      }
      totalValue = totalValue + Number(item.v ? item.v : 0);
      this.chartData.push([
        this.fleetType === 'date' ? item.ts : item.id,
        item.v != null ? Number(item.v).toFixed(2) : '#N/A',
      ]);
    });
    this.totalNumber = availableLength > 0 ? totalValue : undefined;
    this.averageNumber =
      availableLength > 0 && this.totalNumber !== undefined
        ? this.totalNumber / availableLength
        : undefined;
    this.$set(this.kpiNumberList[0], `v`, this.totalNumber);
    this.$set(this.kpiNumberList[1], `v`, this.averageNumber);
  }

  //the Fleet Data type changes--->date or asset
  changeFleetType(type: string) {
    this.fleetType = type;
  }

  @Watch('fleetType')
  fleetTypeRefreshData(newData: string) {
    this.fecthKpi();
  }

  async fecthKpi() {
    this.KpiChartIsLoading = true;
    let submitFields: any = [];
    this.kpiTypeList.forEach((item: any) => {
      submitFields.push({
        code: item.code,
        unit: item.unitCode,
        needGrowthPercentage: item.needGrowthPercentage,
      });
    });
    let submitSelection: any =
      this.fleetType === 'date'
        ? {
            startDate: this.dateRange.start,
            endDate: this.dateRange.endExclusive,
            dataBucketDimension: 'DBDIM_TIME',
            dataBucketSize: 'DBSIZE_DAY',
          }
        : {
            startDate: this.dateRange.start,
            endDate: this.dateRange.endExclusive,
            dataBucketDimension: 'DBDIM_ASSET',
          };
    let kpiPostData = {
      metadata: {
        filter: {
          assetTypeCode: this.$route.name
            ? (this.routeAssetMap.get(this.$route.name) as any).assetTypeCode
            : '',
          organizationIds: unref(this.context).organizationIds,
        },
        selection: submitSelection,
        timezone: unref(this.context)?.primaryOrgTimeZone,
      },
      details: [
        {
          entity: this.entityType,
          fields: submitFields,
        },
      ],
    };
    await getKpiData(kpiPostData, unref(this.context)).then((res) => {
      this.KpiChartIsLoading = false;
      if (!res) return;
      if (res.code === 200) {
        this.chartData = [];
        this.collateAndCalculate(
          res.data.details[0].fields[0] && res.data.details[0].fields[0].values
            ? res.data.details[0].fields[0].values
            : []
        );
        this.growth =
          res.data.details[0].fields[0] &&
          res.data.details[0].fields[0].growthPercentage != null
            ? res.data.details[0].fields[0].growthPercentage
            : undefined;
        this.$set(this.kpiNumberList[0], `growth`, this.growth);

        if (this.expanded) {
          //it's in the expanded page, FE should caculate the table data
          let { cols, tableList } = collateExpandTableData(
            res.data.details[0].fields,
            this.kpiTypeList,
            this.fleetType,
            this.unitConversion.currentUserPreferredUnit,
            this.unitConversion.currentUserConvertUnitValue
          );
          this.cols = cols;
          this.tableList = tableList;
        }
        return;
      } else {
        this.chartData = [];
      }
      customFailedMessage(this.$t('KPI.errorFetchingKpisData').toString());
    });
  }
}
</script>

<template>
  <KpiBarChart
    id="asset-home-power-consumption"
    :loading="KpiChartIsLoading"
    itemColor="#34CAD6"
    :kpiUnit="unitCode"
    :kpiNumberList="kpiNumberList"
    :chartData="chartData"
    @send-date="dateRangeChange"
    @send-fleet-type="changeFleetType"
  />
</template>

<style lang="scss" scoped></style>
