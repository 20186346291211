var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"self-check-container"},[(!_vm.isConnected && !_vm.isConnecting && !_vm.isConnectionFail)?_c('div',{staticClass:"self-check-content"},[_c('el-button',{staticClass:"self-check-btn",attrs:{"id":"selfcheckbtn","type":"primary"},on:{"click":_vm.handleConnect}},[_vm._v(" "+_vm._s(_vm.$t('assetMgmt.connectBtn'))+" ")]),_c('div',{staticClass:"self-check-btn-info",attrs:{"id":"selfcheckinfo"}},[_vm._v(" "+_vm._s(_vm.$t('assetMgmt.selfCheckBtnInfo'))+" ")])],1):_c('div',[_c('div',{staticClass:"connecting-container"},[(!_vm.isConnected && !_vm.isConnectionFail && !_vm.noProgressData)?_c('div',{staticClass:"connecting-message"},[_vm._v(" "+_vm._s(_vm.connectionMessage)+" ")]):_vm._e(),(!_vm.isConnected && !_vm.isConnectionFail && !_vm.noProgressData)?_c('div',{staticClass:"dot-collision-animation"}):_vm._e()]),(!_vm.noProgressData && _vm.progressStatuses.length != 0)?_c('div',{staticClass:"self-check-done-container"},_vm._l((_vm.progressStatuses),function(item,index){return _c('div',{key:index,staticClass:"self-check-items"},[(
            item.assetConnectingSubStatus ===
            _vm.assetOnBoardingProgress.AssConOnBoardingFailed
          )?_c('div',{staticClass:"connection-status-message-fail"},[_vm._v(" - "+_vm._s(_vm.$t(item.assetConnectingSubStatus))+" ")]):_vm._e(),(
            item.assetConnectingSubStatus ===
            _vm.assetOnBoardingProgress.AssConOnboardingSuccessful
          )?_c('div',{staticClass:"connection-status-message-success"},[_vm._v(" - "+_vm._s(_vm.$t(item.assetConnectingSubStatus))+" ")]):_vm._e(),(
            item.assetConnectingSubStatus !=
              _vm.assetOnBoardingProgress.AssConOnboardingSuccessful &&
            item.assetConnectingSubStatus !=
              _vm.assetOnBoardingProgress.AssConOnBoardingFailed
          )?_c('div',{staticClass:"connection-status-message"},[_vm._v(" - "+_vm._s(_vm.$t(item.assetConnectingSubStatus))+" ")]):_vm._e()])}),0):_vm._e(),(_vm.progressStatuses.length === 0)?_c('div',{staticClass:"self-check-done-container"},[_c('div',{staticClass:"self-check-item-no-progress"},[_c('div',{staticClass:"self-check-iteme"},[_vm._v(" - "+_vm._s(_vm.$t('assetMgmt.noProgressFoundContent'))+" ")])])]):_vm._e(),(_vm.noProgressData)?_c('div',{staticClass:"self-check-done-container"},[_c('div',{staticClass:"self-check-item-no-progress"},[_c('div',{staticClass:"self-check-item"},[_vm._v(" - "+_vm._s(_vm.$t('assetMgmt.noProgressFoundContent'))+" ")])])]):_vm._e(),(_vm.isConnected)?_c('el-button',{staticClass:"self-check-done-btn",attrs:{"id":"selfcehckdonebtn","type":"info"},on:{"click":_vm.handleDoneSelfCheck}},[_vm._v(" "+_vm._s(_vm.$t('common.doneBtn'))+" ")]):_vm._e()],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }