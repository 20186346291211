<script lang="ts">
import { getAccessibleAssets } from '@/api/accessibleAssets';
import { getAssignedAssets } from '@/api/assetsMgmt';
import { UUID } from '@/api/common';
import { ActiveContext, useActiveContext } from '@/auth/context';
import {
  SelectedCustomerInfo,
  useSelectedCustomerInfo,
} from '@/auth/selectedCustomer';
import { LoggedInUser, useLoggedInUser } from '@/auth/user';
import PureTable from '@/components/table/PureTable.vue';
import SelectTableHeader from '@/components/table/SelectTableHeader.vue';
import {
  addFilter,
  Filter,
  FilterOperator,
  Pagination,
  QueryParameter,
  Sorter,
  SorterOrder,
} from '@/model/queryParameters/QueryParameter';
import { UserModule } from '@/store/modules/user';
import { AssetType } from '@/utils/assetTypes';
import { isDesignatedCompany } from '@/utils/companyService';
import { ASSET_STATUS_FIELD, ASSIGN_DATE, CREATED_ON } from '@/utils/constants';
import { fetchLastCommunicationTimes } from '@/utils/lastAssetCommunicationTime';
import { customFailedMessage } from '@/utils/prompt';
import {
  fakeUnref,
  InitializeReactive,
} from '@/utils/vueClassComponentHelpers';
import {
  ASSIGNED_ASSETS_COLS,
  ASSIGNED_ASSETS_SEARCH_OPTION_COLS,
} from '@/utils/workData/assetsMgmt';
import {
  AssetLifecycle,
  ASSET_CONNECT_STATUS,
  COMPANY_TYPE,
} from '@/utils/workData/lookuptable';
import moment from 'moment';
import { Ref, unref } from 'vue';
import { Component, Vue } from 'vue-property-decorator';

interface TableRowData {
  id: string;
  assetDataCollectionId: string;
  assetType: AssetType;
  translatedAssetType: string;
  companyAssetId: string;
  companyName?: string;
  companyId: UUID;
  connectionStatus: string;
  controllerId?: string;
  gatewayId: string;
  installTime: string;
  lastCommunicationTime?: string;
  manufactureDate?: string;
  organizationName: string;
  productModelCode: string;
  productModelNumber: string;
  serialNumber?: string;
  preliminaryAssetId: string;
  vinNumber: string;
  translatedServiceRegion: string;
  translatedHyvaRegion: string;
}

@Component({
  name: 'AssignedAssets',
  components: {
    'select-table-header': SelectTableHeader,
    'pure-table': PureTable,
  },
})
export default class extends Vue {
  /** Local variables */
  assetSerial: string = '';
  assignedAssetsCols = ASSIGNED_ASSETS_COLS.map((item) => ({
    ...item,
    label: this.$t(item.label),
  }));
  tableList: TableRowData[] = [];
  total: number = 0;
  isAssignedAssetsLoading: boolean = false;
  assetConnectStatus: any = ASSET_CONNECT_STATUS;
  assignedAssetsSearchFields: any = ASSIGNED_ASSETS_SEARCH_OPTION_COLS;
  currentPage: number = 1;
  pageSize: number = UserModule.gridPageSize;
  tableElementPath: string = 'connect/view';
  allProductModels: any = [];
  useAccessibleAssets: boolean = false;
  sortAndOrderData: any = {
    sortBy: null,
    order: null,
  };
  searchParams: any = {
    reference: null,
    value: null,
  };

  context!: Ref<ActiveContext>;
  selectedCustomer!: SelectedCustomerInfo;

  @InitializeReactive
  loggedInUser!: LoggedInUser | undefined;

  created() {
    this.loggedInUser = fakeUnref(useLoggedInUser());
    this.selectedCustomer = useSelectedCustomerInfo();
    this.context = useActiveContext();
  }

  mounted() {
    this.useAccessibleAssets = [
      COMPANY_TYPE.BodyBuilder.toString(),
      COMPANY_TYPE.Dealer.toString(),
    ].includes(UserModule.companyType);
    this.$nextTick(() => {
      let finalUrlParamsForSearch: string = this.generateRequestUrlWithParams(
        1,
        this.pageSize,
        this.searchParams,
        this.sortAndOrderData.sortBy,
        this.sortAndOrderData.order
      );
      this.fetchAssignedAssetsData(finalUrlParamsForSearch);
    });
  }

  handleSearching(col: string, order: string) {
    let finalUrlParamsForSearch: string = this.generateRequestUrlWithParams(
      this.currentPage,
      this.pageSize,
      this.searchParams,
      this.sortAndOrderData.sortBy,
      this.sortAndOrderData.order
    );
    this.fetchAssignedAssetsData(finalUrlParamsForSearch);
  }

  fetchAssignedAssetsDataByPageSelection(page: number, pageSize: number) {
    this.currentPage = page;
    let finalUrlParamsForSearch: string = this.generateRequestUrlWithParams(
      page,
      pageSize,
      this.searchParams,
      this.sortAndOrderData.sortBy,
      this.sortAndOrderData.order
    );
    this.fetchAssignedAssetsData(finalUrlParamsForSearch);
  }

  /** Filter by sort event */
  fetchAssignedAssetsDataBySortEvent(sortBy: any, order: any) {
    if (sortBy === 'serialNumber') sortBy = 'assetSerialNumber';
    order != '' && sortBy === 'organization'
      ? (this.sortAndOrderData.sortBy = 'organizationName')
      : order != '' && sortBy != 'organization'
      ? (this.sortAndOrderData.sortBy = sortBy)
      : (this.sortAndOrderData.sortBy = null);
    order != ''
      ? (this.sortAndOrderData.order = order)
      : (this.sortAndOrderData.order = null);
    let finalUrlParamsForSearch: string = this.generateRequestUrlWithParams(
      this.currentPage,
      this.pageSize,
      this.searchParams,
      this.sortAndOrderData.sortBy,
      this.sortAndOrderData.order
    );
    this.fetchAssignedAssetsData(finalUrlParamsForSearch);
  }

  /** Generate request URL by multiple factors */
  generateRequestUrlWithParams(
    pageNumber: any,
    pageSize: number,
    searchParams: any,
    sortBy: any,
    order: any
  ) {
    let finalUrl = '/assets';
    let searchFieldName = searchParams ? searchParams.reference : null;
    let searchFieldValue =
      searchParams.value && searchParams.reference != 'installTime'
        ? encodeURIComponent(searchParams.value)
        : searchParams.value && searchParams.reference === 'installTime'
        ? moment(searchParams.value).format('YYYY-MM-DD')
        : null;

    pageNumber
      ? (finalUrl += `?page=${pageNumber}`)
      : (finalUrl += `?page=${1}`);
    pageNumber
      ? (finalUrl += `&size=${pageSize}`)
      : (finalUrl += `&size=${this.pageSize}`);

    finalUrl += `&status=LCYASS_ASSIGNED`;

    if (searchFieldName && searchFieldValue) {
      finalUrl += `&searchFieldName=${searchFieldName}&searchFieldValues=${searchFieldValue}`;
    }

    if (sortBy == null) {
      sortBy = ASSIGN_DATE;
      order = SorterOrder.DESC;
    }

    if (sortBy && order) {
      finalUrl += `&sortBy=${sortBy}&order=${order}_NULLS_LAST`;
    }

    return finalUrl;
  }

  /**
   * Fetch assigned assets accordingly with company type:
   * - for hyvaadmin: from asset on boarding
   * - for body builder: from accessible assets by org id
   * @param customUrl
   */
  async fetchAssignedAssetsData(customUrl: string) {
    try {
      this.isAssignedAssetsLoading = true;
      const res = this.useAccessibleAssets
        ? await getAccessibleAssets(
            this.prepareQueryParameterForAccessibleAssets(),
            UserModule.organizationId
          )
        : await getAssignedAssets(customUrl);
      if (!res || res.code !== 200) {
        customFailedMessage(this.$t('common.errorWithFetchingData') as string);
        return;
      }
      this.total = res.data.total;
      const lastCommunicationTimes = await fetchLastCommunicationTimes(
        res.data.assets.map((x) => x.id),
        unref(this.context)
      );
      this.tableList = res.data.assets.map(
        (x) =>
          ({
            id: x.id,
            assetDataCollectionId: x.assetDataCollectionId,
            assetType: x.assetType,
            translatedAssetType: this.$t(x.assetType),
            companyAssetId: x.companyAssetId,
            companyName: 'companyName' in x ? x.companyName : undefined,
            companyId: x.companyId,
            connectionStatus:
              'connectStatus' in x ? x.connectStatus : undefined,
            controllerId: x.controllerId,
            gatewayId: x.gatewayId,
            installTime: 'installTime' in x ? x.installTime : undefined,
            lastCommunicationTime: lastCommunicationTimes.get(x.id),
            manufactureDate: x.manufactureDate,
            organizationName:
              'organizationName' in x ? x.organizationName : x.organization,
            productModelCode: x.productModelCode,
            productModelNumber: x.productModelNumber,
            serialNumber: x.serialNumber,
            timezone: x.timezone,
            preliminaryAssetId: x.preliminaryAssetId,
            vinNumber: x.vinNumber,
            translatedServiceRegion: this.$t(x.serviceRegion),
            translatedHyvaRegion: this.$t(x.hyvaRegion),
          } as TableRowData)
      );
    } catch (error) {
      console.log(error);
    } finally {
      this.isAssignedAssetsLoading = false;
    }
  }

  /** Prepare query parameters for accessible assets */
  prepareQueryParameterForAccessibleAssets(): QueryParameter {
    const defaultFilterForRegisteredAsset: Filter = {
      name: ASSET_STATUS_FIELD,
      operator: FilterOperator.EQUAL,
      value: [AssetLifecycle.Assigned],
    };
    const sorterField: string =
      this.sortAndOrderData.sortBy === 'assetSerialNumber'
        ? 'serialNumber'
        : this.sortAndOrderData.sortBy;
    const sorter: Sorter =
      this.sortAndOrderData.sortBy != null
        ? { field: sorterField, order: this.sortAndOrderData.order }
        : { field: CREATED_ON, order: SorterOrder.DESC };
    const pagination: Pagination = {
      page: this.currentPage,
      size: this.pageSize,
    };
    let queryParameter: QueryParameter = {
      filters: [defaultFilterForRegisteredAsset],
      sorters: [sorter],
      pagination: pagination,
    };

    if (this.searchParams.value != null) {
      // filter option assetSerialNumber as we receive from response payload in request filter is mapped as serialNumber
      const filterName: string =
        this.searchParams.reference === 'assetSerialNumber'
          ? 'serialNumber'
          : this.searchParams.reference;
      const operator: FilterOperator = this.searchParams.operator
        ? this.searchParams.operator
        : FilterOperator.LIKE;
      const filter: Filter = {
        name: filterName,
        operator: operator,
        value: [this.searchParams.value],
      };
      queryParameter = addFilter(queryParameter, filter);
    }
    return queryParameter;
  }

  changeSelectedCustomer(row: TableRowData) {
    if (
      this.loggedInUser &&
      !isDesignatedCompany(this.loggedInUser.companyType)
    ) {
      return;
    }

    // BB users can see all the assets of the customers that they can impersonate on the Assets Management page.
    // If they click on an asset of a customer that they are not impersonating currently, and from there click
    // on the "Parameters" button, they will get an error, if we don't change the selected customer "under the hood".
    //
    // READ MORE: https://hyva-solutions.atlassian.net/browse/DP-2429?focusedCommentId=34080
    this.selectedCustomer.updateSelectedCustomer(row.companyId);
  }
}
</script>

<template>
  <div class="app-container">
    <select-table-header
      style="margin-bottom: 20px"
      :searchFieldOptions="assignedAssetsSearchFields"
      :cols="assignedAssetsCols"
      :searchParams="searchParams"
      @search-event="handleSearching"
    />
    <pure-table
      v-loading="isAssignedAssetsLoading"
      :element-loading-text="$t('assetsModule.assetsAreLoading')"
      :tableList="tableList"
      :total="total"
      :cols="assignedAssetsCols"
      :viewPagePath="tableElementPath"
      @handle-page="fetchAssignedAssetsDataByPageSelection"
      @handle-sort-change="fetchAssignedAssetsDataBySortEvent"
      @row-click="changeSelectedCustomer"
    />
  </div>
</template>

<style lang="scss" scoped>
.total_items {
  font-size: 16px;
  font-family: $font-Roboto-Bold;
  color: #373e41;
  line-height: 28px;
  opacity: 0.6;
  span {
    font-size: 24px;
    line-height: 28px;
    opacity: 0.8;
    vertical-align: bottom;
  }
}
</style>
