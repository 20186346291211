<script lang="ts" setup>
import { PropertyCode } from '@/api/preset';
import WidgetDialog from '@/components/dialog/WidgetDialog.vue';
import { useUnitConversion } from '@/composables/conversion';
import { useMergedRouteMeta, useRoute, useRouter } from '@/composables/router';
import i18n from '@/lang';
import { useAssetInfoQuery } from '@/query/assets';
import {
  useConfigurationParameterQuery,
  useCreatePresetMutation,
} from '@/query/preset';
import { ApiResponseError, ErrorCode } from '@/utils/api';
import { AssetType } from '@/utils/assetTypes';
import { formatValue } from '@/utils/format';
import { parseNumberOrUndefined } from '@/utils/number';
import { customFailedMessage, customSuccessMessage } from '@/utils/prompt';
import { Option } from '@/utils/types/option';
import { convertRawNumber } from '@/utils/units/conversion';
import { KPI_UNIT } from '@/utils/units/unitDefinitions';
import { AssetLifecycle } from '@/utils/workData/lookuptable';
import { computed, ref } from 'vue';
import PresetCard, { ParameterData } from './PresetCard.vue';

interface IntermediateParamConfig {
  name: string;
  value?: number;
  unit: KPI_UNIT;
  propertyCode: PropertyCode;
}

enum ValueType {
  InUseValue = 'paramConfig.columns.inUseValue',
}

const valueTypeOptions: Option[] = [
  { key: ValueType.InUseValue, label: i18n.t(ValueType.InUseValue) },
];
const selectedOption = ref<string>(valueTypeOptions[0].key);

const { currentUserPreferredUnit } = useUnitConversion();

const route = useRoute();

const { data: asset } = useAssetInfoQuery(route.value.params.id);

const { data, isFetchedAfterMount } = useConfigurationParameterQuery(
  route.value.params.id,
  {
    staleTime: Infinity,
    refetchOnMount: 'always',
    select: (value) => {
      const paramConfigWithRelevantValues = value.configurationParameters
        .map(
          (param): IntermediateParamConfig => ({
            name: param.propertyName,
            propertyCode: param.propertyCode,
            unit: param.unitCode,
            value: parseNumberOrUndefined(
              selectedOption.value === ValueType.InUseValue
                ? param.inUseValue?.v
                : param.configuredValue?.v
            ),
          })
        )
        .filter((item) => item.value !== undefined && item.value !== null);

      return paramConfigWithRelevantValues.map(
        (param): ParameterData => ({
          ...param,
          rawMetricValue: param.value!,
          translatedUnit: i18n.t(currentUserPreferredUnit(param.unit)),
          value: formatValue(
            {
              v: convertRawNumber(
                param.value!,
                param.unit,
                currentUserPreferredUnit(param.unit)
              ),
            },
            { maxDecimals: 4 }
          ),
        })
      );
    },
  },
  computed(() => asset.value !== undefined),
  computed(() => asset.value?.status === AssetLifecycle.Assigned)
);

function handleOptionChange(option: string) {
  selectedOption.value = option;
}

const router = useRouter();

const presetName = ref<string>('');

const isCreateDialogOpen = ref(false);
function handlePresetCreateClick() {
  isCreateDialogOpen.value = true;
}

const createMutation = useCreatePresetMutation(
  () => {
    customSuccessMessage(i18n.t('preset.createSuccess'));
    isCreateDialogOpen.value = false;
    router.back();
  },
  (err) => {
    if (
      err instanceof ApiResponseError &&
      err.errors?.[0].code === ErrorCode.Duplicate
    ) {
      customFailedMessage(i18n.t('preset.createErrorUnique'));

      return;
    }

    customFailedMessage(i18n.t('preset.createError'));
  }
);

const mergedRouteMeta = useMergedRouteMeta();

const assetType = computed((): AssetType => {
  const assetType = mergedRouteMeta?.value.assetType;
  if (!assetType) {
    throw new Error('Cannot determine asset type from route');
  }
  return assetType;
});

async function createParameterPreset() {
  if (presetName.value.trim() === '') {
    customFailedMessage(i18n.t('preset.nameRequired'));

    return;
  }

  if (presetName.value.trim().length > 100) {
    customFailedMessage(i18n.t('common.errorLimit', { limit: '100' }));

    return;
  }

  createMutation.mutate({
    name: presetName.value,
    assetType: assetType.value,
    configurationParameters: data.value!.map((item) => ({
      propertyCode: item.propertyCode,
      value: item.rawMetricValue,
    })),
  });
}
</script>

<template>
  <div style="height: 100%">
    <PresetCard
      :data="isFetchedAfterMount ? data : undefined"
      :dropdown-label="$t('preset.parameterValues')"
      :dropdown-options="valueTypeOptions"
      :selected-option="selectedOption"
      :title="$t('preset.create')"
      @dropdown-change="handleOptionChange"
    >
      <template #actionButton>
        <span class="header-create-button">
          <el-button
            @click="handlePresetCreateClick"
            :disabled="data?.length === 0"
          >
            <i class="el-icon-plus common-icon" />
            {{ $t('common.create') }}
          </el-button>
        </span>
      </template>
    </PresetCard>
    <WidgetDialog
      :visible="isCreateDialogOpen"
      :title="$t('preset.create')"
      :confirmBtnName="$t('common.create')"
      width="40%"
      @handle-cancel="isCreateDialogOpen = false"
      @handle-confirm="createParameterPreset"
    >
      <div class="dialog-container">
        <div class="label">
          {{ $t('preset.name') }}:{{ ' ' }} <span style="color: red">*</span>
        </div>
        <div style="width: 50%">
          <el-input v-model="presetName" />
        </div>
      </div>
    </WidgetDialog>
  </div>
</template>

<style lang="scss">
.header-create-button {
  .el-button {
    color: white;
    font-size: 16px;
    font-weight: 500;
    background-color: #373e41;
    border-radius: 4px;
    padding: 10px 20px;
    border: 0px;
    cursor: pointer;
  }

  .el-button:hover {
    background-color: #5f6567;
    color: white;
  }

  .el-button:active {
    background-color: #5f6567;
    color: white;
  }
}

.dialog-container {
  padding: 57px 48px;
  display: flex;
  align-items: center;
  gap: 37px;
}

.label {
  color: #373e41;
  font-weight: 500;
  font-size: 18px;
}
</style>
