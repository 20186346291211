<script lang="ts">
import { getUsers, UserListInformation } from '@/api/users';
import { flattenOrganizations } from '@/auth/context';
import { LoggedInUserRef, useLoggedInUser } from '@/auth/user';
import PureTable from '@/components/table/PureTable.vue';
import SelectTableHeader from '@/components/table/SelectTableHeader.vue';
import { UserModule } from '@/store/modules/user';
import { customFailedMessage } from '@/utils/prompt';
import {
  ACTIVATION_STATUS_LIST,
  SYSTEM_FEATURES,
} from '@/utils/workData/lookuptable';
import {
  USER_COLS,
  USER_MGMT_TYPES,
  USER_SEARCH_FIELDS,
} from '@/utils/workData/userMgmt';
import moment from 'moment';
import { unref } from 'vue';
import { Component, Vue } from 'vue-property-decorator';

interface User extends UserListInformation {
  translatedEmailVerified: string;
}

@Component({
  name: 'UserManagement',
  components: {
    'select-table-header': SelectTableHeader,
    'pure-table': PureTable,
  },
})
export default class extends Vue {
  /** Local variables */
  path: string = 'view-user-info';
  userTypes = USER_MGMT_TYPES;
  activationStatus = ACTIVATION_STATUS_LIST;
  total: number = 0;
  totalUsers: number = 0;
  listLoading: boolean = false;
  list: User[] = [];
  cols = USER_COLS.map((item) => ({ ...item, label: this.$t(item.label) }));
  pageSize = UserModule.gridPageSize;
  currentPage = 1;
  isUserManagementLoading: boolean = false;
  userSearchFields = USER_SEARCH_FIELDS;
  testValue: string = '';
  loadingText: string = this.$t('userModule.loadingUserData') as string;
  sortAndOrderData: any = {
    sortBy: null,
    order: null,
  };
  searchParams: any = {
    reference: null,
    value: null,
  };
  hasCreateNewUserFeature: boolean = false;

  loggedInUser!: LoggedInUserRef;

  async created() {
    this.loggedInUser = useLoggedInUser();
    this.hasCreateNewUserFeature = await UserModule.hasSystemFeature(
      SYSTEM_FEATURES.CreateUser
    );
    let finalUrlParamsForSearch: string = this.generateRequestUrlWithParams(
      1,
      this.pageSize,
      this.searchParams,
      this.sortAndOrderData.sortBy,
      this.sortAndOrderData.order
    );
    this.fetchUserManagementData(finalUrlParamsForSearch);
  }

  /** Generate request URL by multiple factors */
  generateRequestUrlWithParams(
    pageNumber: any,
    pageSize: number,
    searchParams: any,
    sortBy: any,
    order: any
  ) {
    let searchFieldName = searchParams ? searchParams.reference : null;
    let searchFieldValue =
      searchParams.value && searchParams.reference != 'createdDate'
        ? encodeURIComponent(searchParams.value)
        : searchParams.value && searchParams.reference === 'createdDate'
        ? moment(searchParams.value).format('YYYY-MM-DD')
        : null;
    let finalUrl = '';

    pageNumber
      ? (finalUrl += `?page=${pageNumber}`)
      : (finalUrl += `?page=${1}`);
    pageNumber
      ? (finalUrl += `&size=${pageSize}`)
      : (finalUrl += `&size=${this.pageSize}`);

    const user = unref(this.loggedInUser);
    finalUrl += `&companyId=${user?.companyId}`;

    if (searchFieldName && searchFieldValue) {
      finalUrl += `&searchFieldName=${searchFieldName}&searchFieldValues=${encodeURIComponent(
        searchFieldValue
      )}`;
    }

    if (sortBy && order) finalUrl += `&sortBy=${sortBy}&order=${order}`;

    return finalUrl;
  }

  fetchUsersDataByPageSelection(page: number, pageSize: number) {
    this.currentPage = page;
    let finalUrlParamsForSearch: string = this.generateRequestUrlWithParams(
      page,
      pageSize,
      this.searchParams,
      this.sortAndOrderData.sortBy,
      this.sortAndOrderData.order
    );
    this.fetchUserManagementData(finalUrlParamsForSearch);
  }

  fetchUserManagementBySearchParameters(col: string, order: string) {
    let finalUrlParamsForSearch: string = this.generateRequestUrlWithParams(
      this.currentPage,
      this.pageSize,
      this.searchParams,
      this.sortAndOrderData.sortBy,
      this.sortAndOrderData.order
    );
    this.fetchUserManagementData(finalUrlParamsForSearch);
  }

  /** Filter by sort event */
  fetchUsersDataBySortEvent(sortBy: any, order: any) {
    if (sortBy === 'claims') return; // need IoT implementation !
    order != ''
      ? (this.sortAndOrderData.sortBy = sortBy)
      : (this.sortAndOrderData.sortBy = null);
    order != ''
      ? (this.sortAndOrderData.order = order)
      : (this.sortAndOrderData.order = null);
    let finalUrlParamsForSearch: string = this.generateRequestUrlWithParams(
      this.currentPage,
      this.pageSize,
      this.searchParams,
      this.sortAndOrderData.sortBy,
      this.sortAndOrderData.order
    );
    this.fetchUserManagementData(finalUrlParamsForSearch);
  }

  async fetchUserManagementData(filter: string) {
    this.isUserManagementLoading = true;
    await getUsers(filter).then((res) => {
      if (!res) {
        customFailedMessage(this.$t('common.errorWithFetchingData') as string);
        return;
      }

      if (res.code === 200) {
        this.total = res.data.total;
        this.list = res.data.users
          .filter((user) =>
            flattenOrganizations(
              unref(this.loggedInUser)?.organization ?? []
            ).some((org) => org.id === user.organizationId)
          )
          .map(
            (item): User => ({
              ...item,
              activationStatus: this.$t(item.activationStatus),
              translatedEmailVerified: item.emailVerified
                ? this.$t('userModule.yes')
                : this.$t('userModule.no'),
            })
          );

        if (
          typeof this.list != 'undefined' &&
          this.list != null &&
          this.list.length != null
        ) {
          this.totalUsers = this.list.length;
        }
        this.isUserManagementLoading = false;
      }

      if (res.code === 500) {
        customFailedMessage(this.$t('common.errorWithFetchingData') as string);
      }

      this.isUserManagementLoading = false;
    });
  }
}
</script>

<template>
  <div class="wrapper">
    <div>
      <select-table-header
        class="filters"
        :searchFieldOptions="userSearchFields"
        :cols="cols"
        :searchParams="searchParams"
        @search-event="fetchUserManagementBySearchParameters"
      >
        <template #rightHeader>
          <el-button
            id="create_user_button"
            v-if="hasCreateNewUserFeature"
            v-permission="['AUTHRSC_ACTION_USER_CREATE']"
            type="plain"
            @click="$router.push(`add-new-user`)"
            style="margin-right: 20px"
          >
            <i class="el-icon-plus common-icon" />{{
              $t('userModule.addNewUser')
            }}
          </el-button>
        </template>
      </select-table-header>
    </div>

    <div style="flex-grow: 1">
      <pure-table
        v-loading="isUserManagementLoading"
        :element-loading-text="loadingText"
        :tableList="list"
        :total="total"
        :cols="cols"
        :viewPagePath="path"
        :fitToParent="true"
        @handle-page="fetchUsersDataByPageSelection"
        @handle-sort-change="fetchUsersDataBySortEvent"
      />
    </div>

    <div class="d-flex ai-center total-count">
      <div class="d-flex ai-center">
        <span class="total-statistics"
          >{{ $t('userModule.userTotalInfo') }}:</span
        >
        <span class="total-statistics-value">{{ totalUsers }}</span>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.el-input {
  width: 300px;
}

.filters {
  margin: 20px 0;
  flex-wrap: wrap;
  gap: 10px 0px;
}

.wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.total-count {
  margin-top: 5px;
}

.user-input :deep(.el-input__inner) {
  height: $inputHeight;
  width: 300px;
  // width: 31vw;
  border: 1px solid #707070 !important;
  font-size: 1.285714rem;
  font-family: $font-Roboto-Regular;
  line-height: 1.357143rem;
  color: #373e41;
  opacity: 1;
}

.el-select {
  margin-right: 40px;
}

.user-select :deep(.el-input__inner) {
  height: $inputHeight;
  width: 200px;
  // width: 31vw;
  border: 1px solid #707070 !important;
  font-size: 1.285714rem;
  font-family: $font-Roboto-Regular;
  line-height: 1.357143rem;
  color: #373e41;
  opacity: 1;
}
</style>
