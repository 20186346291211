import moment from 'moment';

export function mapFirmwareReleaseData(data: any) {
  for (let mapping of data.containedPartReleaseMapping) {
    let firmwareRelease = data.partFirmwareRelease.find(
      (firmwareRelease: any) =>
        firmwareRelease.id === mapping.partFirmwareReleaseUUID
    );
    Object.assign(firmwareRelease, mapping);
  }
}

export function getPartFirmwareInformation(partFirmwareArray: any) {
  // These fields will be omitted(not mapped at all)
  const ignoreFields = [
    'id',
    'partFirmwareName',
    'containedPartCode',
    'obuInstance',
  ];

  return partFirmwareArray.map((currentCard: any) => {
    // let's not delete anything from the original object
    let shallowCopy = { ...currentCard };

    // remove ommited fields
    for (let field of ignoreFields) delete shallowCopy[field];

    let partFirmware = Object.entries(shallowCopy).map((e: [string, any]) => {
      return {
        id: `productSystemRelease.${e[0]}`,
        value: e[1],
      };
    });

    return partFirmware;
  });
}

export function getProductSystemInformation(data: any) {
  // let's not delete anything from the original object
  let shallowCopy = { ...data };
  delete shallowCopy['id'];

  return Object.entries(shallowCopy)
    .filter((entry: [string, any]) => typeof entry[1] !== 'object')
    .map((entry: [string, any]) => {
      const key = entry[0];
      let value = null;

      // some keys get special treatment
      switch (key) {
        case 'deploymentDurationMinutes':
          value = moment.utc(entry[1] * 1000 * 60).format('HH:mm:ss');
          break;

        case 'createdTime':
          value = moment(entry[1]).format('YYYY/MM/DD HH:mm:ss');
          break;

        default:
          value = entry[1];
      }

      return {
        id: `productSystemRelease.${key}`,
        value: value,
      };
    });
}

export function getTreeData(data: any): {}[] {
  if (!data) return [];

  let treeData = [];

  for (let i = 0; i < data.length; i++) {
    let node = null;

    if (data[i].customerId) {
      node = {
        id: data[i].customerId,
        label: data[i].customerName,
        value: data[i].numberOfAssets,
        children: getTreeData(data[i].organizations),
      };
    } else {
      node = {
        id: data[i].id,
        label: data[i].name,
        value: data[i].numberOfAssets,
        children: getTreeData(data[i].children),
      };
    }

    treeData.push(node);
  }
  return treeData;
}
