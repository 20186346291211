<script lang="ts">
import { HyvaRegionCode } from '@/api/hyvaRegion';
import { Organization } from '@/api/organizations';
import { ServiceRegionCode } from '@/api/serviceRegions';
import { getTimezones, TimezoneResponse } from '@/api/timezone';
import { ErrorType } from '@/api/types';
import CommonBtn from '@/components/button/CommonBtn.vue';
import CusFormItem from '@/components/form/CusFormItem.vue';
import { useHyvaRegionsQuery } from '@/query/hyvaRegion';
import { useServiceRegionsQuery } from '@/query/serviceRegion';
import {
  ACTIVATION_STATUS,
  COUNTRY_TYPE_LIST,
  ERROR_CODE_LIST,
} from '@/utils/workData/lookuptable';
import Treeselect from '@riophae/vue-treeselect';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

export interface OrgForm {
  name: string;
  parentId: string | undefined;
  parentName: string;
  street: string;
  district: string;
  province: string;
  city: string;
  serviceRegion: ServiceRegionCode;
  hyvaRegion?: HyvaRegionCode;
  zipCode: string;
  timezone: string;
  companyId: string;
  activationStatus: ACTIVATION_STATUS;
  note: string;
}

@Component({
  name: 'OrganizationManagementForm',
  components: {
    'tree-select': Treeselect,
    'cus-form-item': CusFormItem,
    'common-btn': CommonBtn,
  },
})
export default class extends Vue {
  @Prop({ required: true }) orgForm!: OrgForm;
  @Prop({ required: true }) treeSelectIsDisabled!: boolean;
  @Prop() errorInfos!: ErrorType[];
  @Prop() selectedCustomerOrganization!: Organization[];

  countries = COUNTRY_TYPE_LIST;
  errorCode = ERROR_CODE_LIST;
  timeZoneOptions: TimezoneResponse[] = [];
  placeHolderOrgForm: OrgForm = {
    name: '',
    parentId:
      this.orgForm.parentId && this.orgForm.parentId.length > 0
        ? this.orgForm.parentId
        : undefined,
    parentName: this.orgForm.parentName,
    street: '',
    district: '',
    province: '',
    city: '',
    serviceRegion: '',
    hyvaRegion: '',
    zipCode: '',
    timezone: '',
    companyId: '',
    activationStatus: ACTIVATION_STATUS.Activated,
    note: '',
  };

  serviceRegionsQuery!: ReturnType<typeof useServiceRegionsQuery>;
  hyvaRegionsQuery!: ReturnType<typeof useHyvaRegionsQuery>;

  created() {
    this.hyvaRegionsQuery = useHyvaRegionsQuery();
    this.serviceRegionsQuery = useServiceRegionsQuery();
    getTimezones().then(({ data }) => (this.timeZoneOptions = data));
    this.handleData(this.orgForm);
  }

  /** Update local form data when property orgForm is updated */
  @Watch('orgForm')
  handleData(newOrgData: OrgForm) {
    this.placeHolderOrgForm = newOrgData;
    this.placeHolderOrgForm.parentId =
      this.orgForm.parentName !== '—' && this.orgForm.parentId
        ? this.orgForm.parentId
        : undefined;
    return newOrgData;
  }

  /**
   * Validate parent id
   */
  validateParentId = (rule: any, value: any, callback: any) => {
    if (!this.treeSelectIsDisabled && !this.orgForm.parentId) {
      callback(
        new Error(
          `${this.$t('organizationModule.selectOrganizationSubdivision')}`
        )
      );
    }
    callback();
  };

  get inlineMsg() {
    return false;
  }

  get rules() {
    const rules = {
      name: [
        {
          required: true,
          message: this.$t('organizationModule.tipInputOrgName'),
          trigger: 'blur',
        },
      ],
      country: [
        {
          required: true,
          message: this.$t('organizationModule.tipInputCountry'),
          trigger: 'blur',
        },
      ],
      timezone: [
        {
          required: true,
          message: this.$t('organizationModule.tipInputTimeZone'),
          trigger: 'blur',
        },
      ],
      serviceRegion: [
        {
          required: true,
          message: this.$t('customerModule.inputServiceRegion'),
          trigger: 'blur',
        },
      ],
      parentId: [
        { required: true, validator: this.validateParentId, trigger: 'input' },
      ], // parent Org should not be required if it's self
    };
    return rules;
  }

  onServiceRegionChange(selectedValue: string) {
    const selectedRegion = this.serviceRegionsQuery.data.value?.find(
      (servReg) => servReg.serviceRegionCode === selectedValue
    );

    if (selectedRegion === undefined) {
      this.orgForm.hyvaRegion = undefined;

      return;
    }

    this.orgForm.hyvaRegion = selectedRegion.hyvaRegionCode;
  }

  /**
   * Handle select subdivision of option
   */
  handleSelect() {
    (this.$refs.placeHolderOrgForm as any)?.validateField('parentId');
  }

  handleTreeselectOpen() {
    let treeselectDom: any = document.getElementsByClassName(
      'vue-treeselect__control-arrow-container'
    );
    treeselectDom[0].setAttribute('style', 'transform: rotateZ(-90deg)');
  }

  handleTreeselectClose() {
    let treeselectDom: any = document.getElementsByClassName(
      'vue-treeselect__control-arrow-container'
    );
    treeselectDom[0].setAttribute('style', 'transform: rotateZ(90deg)');
  }

  get handleOrgNameValidation() {
    let errInfo: string = '';
    if (
      this.errorInfos.find((item) => item.field === this.errorCode.name)
        ?.code === 'ApiErrorFieldDuplicate'
    ) {
      errInfo = `${this.$t('organizationModule.duplicateName')}`;
    }
    return errInfo;
  }

  handleInput(input: string) {
    this.$emit('handle-input');
  }

  /** Handle final btn click action */
  sendOrganizationForm() {
    this.placeHolderOrgForm.companyId = this.orgForm.companyId;
    (this.$refs.orgFormRef as any).validate((valid: any) => {
      if (valid) {
        this.$emit('save-org', this.placeHolderOrgForm);
      }
    });
  }

  /**
   * Tree select custom key for children
   * @param node
   */
  customKey(node: any) {
    return {
      id: node.id,
      label: node.name,
      children:
        node.children && node.children.length > 0 ? node.children : undefined,
    };
  }
}
</script>

<template>
  <div>
    <el-form
      class="new-account"
      ref="orgFormRef"
      :model="placeHolderOrgForm"
      :rules="rules"
      :inline-message="inlineMsg"
    >
      <cus-form-item
        :title="'organizationModule.organizationName'"
        :errContent="handleOrgNameValidation"
      >
        <el-form-item prop="name">
          <el-input
            id="new_org_input_name"
            v-model="placeHolderOrgForm.name"
            type="text"
            autocomplete="off"
          ></el-input>
        </el-form-item>
      </cus-form-item>

      <cus-form-item
        :title="'organizationModule.subdivisionOf'"
        :required="true"
      >
        <el-form-item prop="parentId">
          <tree-select
            id="new_org_tree_select"
            :options="selectedCustomerOrganization"
            v-model="placeHolderOrgForm.parentId"
            :normalizer="customKey"
            :disabled="treeSelectIsDisabled"
            :clearable="false"
            :backspaceRemoves="false"
            v-bind:placeholder="
              placeHolderOrgForm.parentId
                ? $t('organizationModule.selectSubdivisionOf')
                : ''
            "
            @input="handleSelect"
            @open="handleTreeselectOpen"
            @close="handleTreeselectClose"
          />
        </el-form-item>
      </cus-form-item>
      <cus-form-item :title="'common.serviceRegion'" :required="true">
        <el-form-item prop="serviceRegion">
          <el-select
            v-loading="serviceRegionsQuery.isLoading.value"
            v-model="orgForm.serviceRegion"
            v-bind:placeholder="$t('customerModule.pleaseSelect')"
            filterable
            @change="onServiceRegionChange"
          >
            <el-option
              v-for="item in serviceRegionsQuery.data.value"
              :key="item.serviceRegionCode"
              :label="$t(item.serviceRegionCode)"
              :value="item.serviceRegionCode"
            />
          </el-select>
        </el-form-item>
      </cus-form-item>
      <cus-form-item
        v-loading="hyvaRegionsQuery.isLoading.value"
        :title="'common.hyvaRegion'"
        :required="false"
      >
        <el-form-item prop="hyvaRegion">
          <el-select
            v-model="orgForm.hyvaRegion"
            v-bind:placeholder="''"
            filterable
            disabled
          >
            <el-option
              v-for="item in hyvaRegionsQuery.data.value"
              :key="item.code"
              :label="$t(item.code)"
              :value="item.code"
            />
          </el-select>
        </el-form-item>
      </cus-form-item>
      <cus-form-item :title="'customerModule.province'" :required="false">
        <el-form-item>
          <el-input
            id="new_org_input_province"
            v-model="placeHolderOrgForm.province"
            v-bind:placeholder="$t('customerModule.inputProvince')"
          ></el-input>
        </el-form-item>
      </cus-form-item>
      <cus-form-item :title="'customerModule.city'" :required="false">
        <el-form-item>
          <el-input
            id="new_org_input_city"
            v-model="placeHolderOrgForm.city"
            v-bind:placeholder="$t('customerModule.inputCity')"
          ></el-input>
        </el-form-item>
      </cus-form-item>
      <cus-form-item :title="'customerModule.street'" :required="false">
        <el-form-item>
          <el-input
            id="new_org_input_street"
            v-model="placeHolderOrgForm.street"
            v-bind:placeholder="$t('customerModule.inputStreet')"
          ></el-input>
        </el-form-item>
      </cus-form-item>
      <cus-form-item :title="'customerModule.district'" :required="false">
        <el-form-item>
          <el-input
            id="new_org_input_district"
            v-model="placeHolderOrgForm.district"
            v-bind:placeholder="$t('customerModule.inputDistrict')"
          ></el-input>
        </el-form-item>
      </cus-form-item>
      <cus-form-item :title="'customerModule.postCode'" :required="false">
        <el-form-item>
          <el-input
            id="new_org_input_zipCode"
            v-model="placeHolderOrgForm.zipCode"
            v-bind:placeholder="$t('customerModule.inputPostCode')"
          ></el-input>
        </el-form-item>
      </cus-form-item>
      <cus-form-item :title="'customerModule.note'" :required="false">
        <el-form-item>
          <el-input
            id="new_org_input_note"
            v-model="placeHolderOrgForm.note"
            v-bind:placeholder="$t('customerModule.inputNote')"
          ></el-input>
        </el-form-item>
      </cus-form-item>
      <cus-form-item :title="'organizationModule.timezone'">
        <el-form-item prop="timezone">
          <el-select
            id="new_org_select_timeZone"
            v-model="placeHolderOrgForm.timezone"
            v-bind:placeholder="$t('customerModule.pleaseSelect')"
          >
            <el-option
              v-for="item in timeZoneOptions"
              :key="item.id"
              :label="item.fullName"
              :value="item.fullName"
            />
          </el-select>
        </el-form-item>
      </cus-form-item>
      <cus-form-item
        :title="'organizationModule.activationStatus'"
        :required="false"
      >
        <el-form-item>
          <span>{{ $t(placeHolderOrgForm.activationStatus) }}</span>
        </el-form-item>
      </cus-form-item>
    </el-form>
  </div>
</template>

<style lang="scss" scoped></style>
