import { GEOFENCE_TYPE_CONFIG } from '@/utils/workData/lookuptable';

export const GeofenceStatusType = {
  Active: 'GFNLCL_ACTIVE',
  Draft: 'GFNLCL_DRAFT',
  Deprecated: 'GFNLCL_DEPRECATED',
};

export const geofenceTypeColorMapping: Map<string, string> = new Map([
  [GEOFENCE_TYPE_CONFIG.GeofenceTypeWorkshop, '#8494ED'],
  [GEOFENCE_TYPE_CONFIG.GeofenceTypeSite, '#71B786'],
  [GEOFENCE_TYPE_CONFIG.GeofenceTypeLogingPoint, '#d749bb'],
  [GEOFENCE_TYPE_CONFIG.GeofenceTypeUnloadingPoint, '#ffcd00'],
  [GEOFENCE_TYPE_CONFIG.GeofenceTypeCollectionPoint, '#d59d3b'],
  [GEOFENCE_TYPE_CONFIG.GeofenceTypeParking, '#67C2DE'],
  [GEOFENCE_TYPE_CONFIG.GeofenceTypeTransferStation, '#f5898b'],
  [GEOFENCE_TYPE_CONFIG.GeofenceTypeWastePlant, '#5a56bd'],
]);
