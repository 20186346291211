<script lang="ts">
import GrowthPercentage from '@/components/general/GrowthPercentage.vue';
import { formatUnitValue, UndefinedRendering } from '@/utils/format';
import { UnitValue } from '@/utils/units/unitValue';
import { Component, Prop, Vue } from 'vue-property-decorator';

export interface KpiGrowthUnitValue<T> extends UnitValue<T> {
  code: string | undefined; // TODO Should become a mandatory field?
  name: string;
  growth: number | undefined;
}

@Component({
  name: 'KpiNumber',
  components: {
    GrowthPercentage,
  },
})
export default class extends Vue {
  @Prop({ required: true }) kpi!: KpiGrowthUnitValue<number | undefined>;

  get formattedUnitValue(): string {
    return formatUnitValue(this.kpi, {
      numberOfDecimals: 2,
      undefinedAs: UndefinedRendering.Empty,
    });
  }
}
</script>

<template>
  <div class="form-item-container">
    <div class="form-title">
      <span class="show-title">{{ kpi.name }}:</span>
      <span class="show-value">{{ formattedUnitValue }}</span>
    </div>
    <div class="growth-number" v-show="kpi.growth != null">
      <GrowthPercentage :growthPercentage="kpi.growth" />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.form-item-container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 6px;
  .form-title {
    flex-shrink: 0;
    margin-right: 60px;
    min-width: 315px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .show-title {
      font-size: 16px;
      color: #373e41;
    }
    .show-value {
      font-weight: bold;
      line-height: 24px;
      color: #373e41;
    }
  }
  .growth-number {
    .show-value {
      display: flex;
    }
  }
  .decrease_red {
    color: #ed6964;
  }
  .increase_green {
    color: #66bc93;
  }
}
</style>
