<script lang="ts">
import ResizeMixin from '@/components/charts/mixins/resize';
import { sum } from '@/utils/math';
import echarts, { EChartOption } from 'echarts';
import { watchEffect } from 'vue';
import { mixins } from 'vue-class-component';
import { Component, Prop } from 'vue-property-decorator';

export type PieChartSelection = Record<string, boolean>;

interface EChartsLegendSelectChangedEvent {
  /**
   * Event type.
   */
  type: 'legendselectchanged';
  /**
   * Name of legend.
   */
  name: string;
  /**
   * Dictionary of which legend elements are currently enabled.
   */
  selected: PieChartSelection;
}

export interface PieChartElement {
  name: string;
  value: number;
}

@Component({
  name: 'PieChart',
})
export default class PieChart extends mixins(ResizeMixin) {
  $props!: {
    categories: PieChart['categories'];
    data: PieChart['data'];
    colors: PieChart['colors'];
    selectedCategories?: PieChart['selectedCategories'];
    allowFiltering?: PieChart['allowFiltering'];
    events?: PieChart['events'];
    id?: PieChart['id'];
    className?: PieChart['className'];
    width?: PieChart['width'];
    height?: PieChart['height'];
  };
  @Prop({ required: true }) categories!: EChartOption.Legend.LegendDataObject[];
  @Prop({ required: true }) data!: PieChartElement[];
  @Prop({ required: true }) colors!: string[];
  @Prop({ required: false }) selectedCategories?: PieChartSelection;
  @Prop({ default: true }) allowFiltering!: boolean;
  @Prop({ required: false }) events?: {
    name: string;
    callback: (...args: any) => void;
  }[];
  @Prop({ default: 'id' }) id!: string;
  @Prop({ default: 'chart' }) className!: string;
  @Prop({ default: '100%' }) width!: string;
  @Prop({ default: '200px' }) height!: string;

  created() {
    // Automatically refresh the chart whenever its mounted/unmounted,
    // and when anything it references changes
    watchEffect(() => this.updateOption());
  }

  mounted() {
    this.$nextTick(() => {
      this.initChart();
    });
  }

  beforeDestroy() {
    if (!this.chart) {
      return;
    }
    this.chart.dispose();
    this.chart = null;
  }

  private initChart() {
    this.chart = echarts.init(this.$el as HTMLDivElement, 'light');
    if (this.events) {
      this.events.forEach((e) => {
        if (!this.chart) return;
        this.chart.on(e.name, e.callback);
      });
    }
    this.chart.on('legendselectchanged', this.handleLegendClick);
    this.updateOption();
  }

  private updateOption(): void {
    // Always compute/cache the option, even if we don't have a chart,
    // to ensure the watchEffect is properly tracking dependencies.
    // This cludge won't be necessary once we move to vue-echarts.
    const option = this.eChartOption;
    this.chart?.setOption(option);
  }

  private get eChartOption(): EChartOption<EChartOption.SeriesPie> {
    const everyCategoryUnselectedColor: string = '#EEEEEE';
    const isEmpty = this.data.map((cat) => cat.value).reduce(sum, 0) === 0;

    const isEveryCategoryUnselected =
      !this.selectedCategories ||
      Object.values(this.selectedCategories).every(
        (catSelected) => !catSelected
      );

    return {
      color: isEveryCategoryUnselected
        ? new Array(this.categories.length).fill(everyCategoryUnselectedColor)
        : this.colors,
      tooltip: {
        show: !isEveryCategoryUnselected,
        trigger: 'item',
        formatter: '{b} : {c} / {d}%',
      },
      legend: {
        orient: 'vertical',
        top: '2%',
        right: '2%',
        itemHeight: 24,
        textStyle: {
          fontSize: 14,
          fontWeight: 400,
          lineHeight: 16,
          fontFamily: 'Roboto',
        },
        data: this.categories,
        selected: isEveryCategoryUnselected
          ? {}
          : this.selectedCategories ?? {},
      },
      series: [
        {
          name: '',
          type: 'pie',
          radius: [30, 70],
          center: ['23%', '50%'],
          emphasis: {
            itemStyle: {
              color: isEveryCategoryUnselected
                ? everyCategoryUnselectedColor
                : undefined,
            },
          },
          cursor: isEveryCategoryUnselected ? 'default' : undefined,
          label: {
            fontSize: 14,
            fontWeight: 'bold',
            lineHeight: 16,
            fontFamily: 'Roboto',
            show: true,
            position: 'inside',
            formatter: function (param: any) {
              return param.value === 0 ? '' : `${param.value}`;
            },
            rich: {
              c: {
                color: '#000',
                fontSize: 14,
                fontWeight: 'bold',
                lineHeight: 16,
                fontFamily: 'Roboto',
              },
              per: {
                color: '#000',
                fontSize: 12,
                fontWeight: 400,
                lineHeight: 14,
                fontFamily: 'Roboto',
              },
            },
          },
          data: this.data,
          animationEasing: 'cubicInOut',
          animationDuration: 1000,
          hoverAnimation: !isEmpty,
        },
      ],
    };
  }

  handleLegendClick(params: EChartsLegendSelectChangedEvent) {
    this.$emit('legend-click', params.name);
  }
}
</script>

<template>
  <div :id="id" :class="className" :style="{ height: height, width: width }" />
</template>
