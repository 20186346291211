/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon';
icon.register({
  healthFactor: {
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
    data: '<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M14 20H6C4.40927 19.9981 2.88422 19.3654 1.75941 18.2406C0.634589 17.1158 0.00185261 15.5907 0 14L0 6C0.00185261 4.40927 0.634589 2.88422 1.75941 1.75941C2.88422 0.634589 4.40927 0.00185261 6 0L14 0C15.5907 0.00185261 17.1158 0.634589 18.2406 1.75941C19.3654 2.88422 19.9981 4.40927 20 6V14C19.9984 15.5908 19.3658 17.116 18.2409 18.2409C17.116 19.3658 15.5908 19.9984 14 20ZM6 1C4.67441 1.00159 3.40356 1.52888 2.46622 2.46622C1.52888 3.40356 1.00159 4.67441 1 6V14C1.00159 15.3256 1.52888 16.5964 2.46622 17.5338C3.40356 18.4711 4.67441 18.9984 6 19H14C15.3257 18.9987 16.5967 18.4715 17.5341 17.5341C18.4715 16.5967 18.9987 15.3257 19 14V6C18.9984 4.67441 18.4711 3.40356 17.5338 2.46622C16.5964 1.52888 15.3256 1.00159 14 1H6Z" fill="#373E41"/><path d="M4.12238 8.184C4.12238 6.013 5.56738 4 7.54638 4C8.2075 4.03222 8.84504 4.25577 9.38152 4.64349C9.91799 5.0312 10.3303 5.56638 10.5684 6.184C10.806 5.56728 11.2174 5.03271 11.7527 4.64507C12.288 4.25743 12.9243 4.03336 13.5844 4C15.5634 4 17.0084 6.013 17.0084 8.184C17.0084 12.83 10.5624 16.133 10.5624 16.133C10.5624 16.133 6.22038 14.043 4.66237 10.614H8.51437L9.12537 9.527L9.83937 12.027L11.5114 9.877H13.6014V9.119H11.1414L10.1594 10.382L9.35938 7.573L8.07438 9.857H4.38438C4.46472 10.1155 4.56124 10.3686 4.67338 10.615H2.98438V9.857H4.38438C4.21316 9.31574 4.12483 8.75169 4.12238 8.184Z" fill="#373E41"/></svg>',
  },
});
