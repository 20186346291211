<script lang="ts">
import {
  LoggedInUser,
  UpdateUserSettingsFunction,
  useLoggedInUser,
  useUpdateUserSettings,
} from '@/auth/user';
import { UserModule } from '@/store/modules/user';
import {
  fakeUnref,
  InitializeReactive,
} from '@/utils/vueClassComponentHelpers';
import { LANGUAGE } from '@/utils/workData/lookuptable';
import { Component, Vue } from 'vue-property-decorator';

@Component({
  name: 'LangSelect',
})
export default class extends Vue {
  /** Local variables */
  companyType = UserModule.companyType;
  languageSelectionIsLoading: boolean = false;
  updateUserSettings!: UpdateUserSettingsFunction;

  @InitializeReactive
  loggedInUser: LoggedInUser | undefined;

  created() {
    this.loggedInUser = fakeUnref(useLoggedInUser());
    this.updateUserSettings = useUpdateUserSettings();
  }

  get language() {
    return this.loggedInUser?.settings.i18nCode;
  }

  /**
   * Update user language settings by remote API
   * @param i18nCode
   */
  async updateUserLanguageRemote(i18nCode: LANGUAGE) {
    try {
      this.languageSelectionIsLoading = true;
      await this.updateUserSettings({ i18nCode });
      this.$message({
        message: this.$t('components.changeLanguageTips').toString(),
        type: 'success',
      });
    } catch (error) {
      console.log(error);
    } finally {
      this.languageSelectionIsLoading = false;
    }
  }
}
</script>

<template>
  <div v-loading="languageSelectionIsLoading">
    <el-dropdown
      v-if="companyType === 'CMPTYP_HYVA'"
      trigger="click"
      class="international"
      @command="updateUserLanguageRemote"
    >
      <div class="international-icon">
        <img src="@/assets/imgs/translation.png" />
        <!-- <svg-icon
          name="language"
          class="international-icon"
        /> -->
      </div>
      <el-dropdown-menu slot="dropdown">
        <el-dropdown-item :disabled="language === 'zh-CN'" command="zh-CN">
          中文
        </el-dropdown-item>
        <el-dropdown-item :disabled="language === 'en-US'" command="en-US">
          English
        </el-dropdown-item>
        <el-dropdown-item :disabled="language === 'nl-NL'" command="nl-NL">
          Nederlands
        </el-dropdown-item>
        <!-- <el-dropdown-item
          :disabled="language==='es'"
          command="es"
        >
          Español
        </el-dropdown-item>
        <el-dropdown-item
          :disabled="language==='ja'"
          command="ja"
        >
          日本語
        </el-dropdown-item>
        <el-dropdown-item
          :disabled="language==='ko'"
          command="ko"
        >
          한국어
        </el-dropdown-item>
        <el-dropdown-item
          :disabled="language==='it'"
          command="it"
        >
          Italiano
        </el-dropdown-item> -->
      </el-dropdown-menu>
    </el-dropdown>
  </div>
</template>

<style scoped>
.el-dropdown-menu__item:hover {
  background-color: var(--Main);
  color: #373e41;
}
.el-dropdown-menu__item:focus {
  background-color: var(--Main);
  color: #373e41;
}
</style>

<style lang="scss" scoped>
.international-icon {
  height: 100%;
  display: flex;
  align-items: center;
}
</style>
