export const REGEX_WHITESPACE_PATTERN = /\s+/;
export const REGEX_NUMBER_PATTERN = /\s+/;

/**
 * Capitalize first letter for translation string
 */
export const capitalizeFirstLetter = (translationString: string): string => {
  return translationString.charAt(0).toUpperCase() + translationString.slice(1);
};

/**
 * Accepting string with dynamic words count:
 * - make all first word's letter upper case
 * - if the grouping is a number, keep it as it is
 */
export function capitalizeFirstLetterOfTheWorldsInString(
  inputString: string
): string {
  const words = inputString.split(REGEX_WHITESPACE_PATTERN);

  const nonEmptyWords = words.filter((word) => word.trim() !== '');

  const capitalizedWords = nonEmptyWords.map((word) => {
    if (REGEX_NUMBER_PATTERN.test(word)) {
      return word;
    }
    const firstLetter = word[0]?.toUpperCase();
    const restOfWord = word.slice(1)?.toLowerCase();

    return firstLetter + restOfWord;
  });

  const result = capitalizedWords.join(' ');

  return result;
}

export function stripPrefix(value: string, prefix: string): string {
  return value.startsWith(prefix) ? value.slice(prefix.length) : value;
}
