<script lang="ts">
import { MaintStatus } from '@/api/maintenance';
import Close from '@/assets/imgs/maintenance/overview/close.svg';
import Due from '@/assets/imgs/maintenance/overview/due.svg';
import Ok from '@/assets/imgs/maintenance/overview/ok.svg';
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
  name: 'MaintStatusTile',
})
export default class extends Vue {
  @Prop({ required: true }) maintStatus!: MaintStatus;
  @Prop({ required: true }) maintStatusCount!: number | undefined;
  @Prop({ required: true }) isSelected!: boolean;
  @Prop({ required: true }) isClickable!: boolean;
  emits!: ['click-status'];

  toolTipMessage: any = '';

  get status() {
    let status = this.$t('maintenance.ok');
    this.toolTipMessage = this.$t('maintenance.maintenanceOverviewOk');

    if (this.maintStatus === MaintStatus.CLOSE) {
      status = this.$t('maintenance.close');
      this.toolTipMessage = this.$t('maintenance.maintenanceOverviewClose');
    } else if (this.maintStatus === MaintStatus.DUE) {
      status = this.$t('maintenance.due');
      this.toolTipMessage = this.$t('maintenance.maintenanceOverviewDue');
    }

    return status;
  }

  get imgSrc() {
    let imgSrc = Ok;

    if (this.maintStatus === MaintStatus.CLOSE) {
      imgSrc = Close;
    } else if (this.maintStatus === MaintStatus.DUE) {
      imgSrc = Due;
    }

    return imgSrc;
  }

  handleClick() {
    if (this.isClickable) {
      this.$emit('click-status');
    }
  }
}
</script>

<template>
  <el-tooltip
    placement="top"
    :class="[isSelected ? 'active-tile' : 'tooltip']"
    effect="dark"
    popper-class="tooltip-body"
  >
    <div slot="content">{{ toolTipMessage }}</div>
    <div
      :class="
        isClickable
          ? 'maint-status-card-container cursor'
          : 'maint-status-card-container'
      "
      @click="handleClick"
    >
      <div class="card-header">
        <div class="image-container">
          <img class="status-image" :src="imgSrc" />
        </div>
        <!-- <img viewBox="0 0 18.3 14.3" src="@/assets/imgs/exclamation-circle.svg"
                    class="exclamation-icon"/> -->
      </div>
      <div class="maint-status-card-label">
        <span>{{ maintStatusCount ?? '-' }}</span>
      </div>
      <div class="maint-status-card-status">
        <span>{{ status }}</span>
      </div>
    </div>
  </el-tooltip>
</template>

<style lang="scss" scoped>
.maint-status-card-container {
  border: 1px solid #e9e8e8;
  border-radius: 7px;
  width: 120px;
  height: 174px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;

  .maint-status-card-label {
    font-size: 31px;
    font-family: $font-Roboto-Bold;
    line-height: 52px;
    color: #373e41;
    opacity: 1;

    margin-top: 1px;
    margin-bottom: 10px;
  }

  .maint-status-card-status {
    font-size: 19px;
    font-family: $font-Roboto-Medium;
    line-height: 8px;
    color: #373e41;
    opacity: 0.6;
    margin-bottom: 20px;
  }
}

.cursor {
  cursor: pointer;
}

.maint-status-card-container:hover {
  border: 2px solid #373e41;
}

.content {
  background-color: orange !important;
}

.card-header {
  //margin-left: 30px;
  display: flex;
}

.exclamation-icon {
  margin-bottom: 50px;
  margin-left: 10px;
}

.tooltip {
}

.tooltip-body {
  background: #ffcd00 !important;
}

.tooltip:hover {
  box-shadow: 5px 5px 5px #a3a2a2;
}

.active-tile {
  background: white;
  border: 1px solid #373e41;
}

.active-tile:hover {
  box-shadow: 5px 5px 5px #a3a2a2;
}

.status-image {
  max-width: 60px;
}
</style>
