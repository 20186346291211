<script lang="ts" setup>
import {
  AssetObjectModel,
  AssetsResponse,
  fetchAssetsByFlexibleFiltering,
} from '@/api/assets';
import { CommonResult } from '@/api/commonResult';
import {
  AppointmentBody,
  conductMaintenanceAppointment,
  createNewMaintenanceAppointment,
  getMaintenanceFiltersList,
  getMaintItemById,
  MainteananceItemById,
  MaintenanceAppointment,
  MaintenanceFilterItem,
  MaintenanceItemPart,
  updateMaintenanceAppointment,
} from '@/api/maintenance';
import {
  ContainedPart,
  getProductModelById,
  ProductModel,
} from '@/api/products';
import { useActiveContext } from '@/auth/context';
import CusFormItem from '@/components/form/CusFormItem.vue';
import TransferList from '@/components/form/TransferList.vue';
import { AsyncValue, useAsync } from '@/composables/async';
import i18n from '@/lang';
import {
  FilterOperator,
  Pagination,
  QueryParameter,
} from '@/model/queryParameters/QueryParameter';
import { disabledPastDate } from '@/utils/date';
import { customFailedMessage, promptSuccessBox } from '@/utils/prompt';
import { DATE_PICKER_DEFAULT_FORMAT, LOCALDATE_FORMAT } from '@/utils/time';
import { MAINTENANCE_ITEM_OPERATION_KIND } from '@/utils/workData/lookuptable';
import {
  DEFAULT_APPOINTMENT_DURATION,
  MaintenanceModalTriggerSource,
} from '@/views/maintenance/planner';
import moment from 'moment';
import { computed, ref, unref, watch, watchEffect } from 'vue';
import { MaintenanceScheduler } from '../scheduler/scheduler';

interface PartsList {
  replace: ItemPart[];
  check: ItemPart[];
}

interface ItemPart {
  id: string;
  partName: string;
  checked: boolean;
}

const context = useActiveContext();
const appointmentModalIsLoading = ref<boolean>(false);
const defaultAppointmentDuration = DEFAULT_APPOINTMENT_DURATION;
const datePickerDefaultFormat = ref<string>(DATE_PICKER_DEFAULT_FORMAT);

const assetsList = ref<AssetObjectModel[]>([]);
const selectedAssetId = ref<string>();

const maintenanceItemsList = ref<MaintenanceFilterItem[] | undefined>([]);
const selectedMaintenanceItem = ref<string>();

const currentSelectedDate = ref<string>();

const inputFormIsInEditMode = ref<boolean>(false);
const inputFormIsInConductMode = ref<boolean>(false);

const noteInputFieldContent = ref<string>();

/**
 * Used to retrieve product model by id > contained part > part name for the parts retrieved from applied-item endpoint
 */
const maintenanceItemProductModelId = ref<string>();

const partsList = ref<PartsList | undefined>({
  replace: [],
  check: [],
});

const assetInputForm = {
  selectedAssetId: selectedAssetId.value,
  selectedMaintenanceItem: selectedMaintenanceItem.value,
  currentSelectedDate: currentSelectedDate.value,
  duration: DEFAULT_APPOINTMENT_DURATION,
};

const props = defineProps<{
  isModalVisible: boolean;
  title: string;
  modalTriggerSource: MaintenanceModalTriggerSource;
  appointment?: MaintenanceScheduler | MaintenanceAppointment | undefined;
}>();

const emit = defineEmits<{
  (e: 'close'): void;
  (e: 'reload'): void;
}>();

/** Button close event */
function closeDialog() {
  if (
    unref(inputFormIsInEditMode.value) ||
    unref(inputFormIsInConductMode.value)
  ) {
    inputFormIsInEditMode.value = false;
    inputFormIsInConductMode.value = false;
    return;
  }
  emit('close');
}

function closeModalFromX(): void {
  emit('close');
}

/** Change to edit mode button event */
function changeToEditMode() {
  inputFormIsInEditMode.value = true;
}

/** ------------------- Assets data ------------------- */

/**
 * Prepare query parameters to fetch available maintenance assets for current selected org
 */
function getMaintenanceAssetsQueryParameters(): QueryParameter {
  const pagination: Pagination = { page: 1, size: 100000 };
  const selectedOrganization = unref(context.value).organization;
  const orgId = selectedOrganization?.id;
  const queryParameters: QueryParameter = {
    filters: !orgId
      ? []
      : [
          {
            name: 'organizationId',
            operator: FilterOperator.IN,
            value: [orgId],
          },
        ],
    sorters: [],
    pagination: pagination,
    timezone: selectedOrganization?.timezone,
  };
  return queryParameters;
}

/**
 * Fetch from API available assets list
 */
const assetsListAPIResponse = useAsync(
  computed(async (): Promise<CommonResult<AssetsResponse>> => {
    const response = await fetchAssetsByFlexibleFiltering(
      getMaintenanceAssetsQueryParameters()
    );
    return response;
  })
);

/**
 * Prepare final assets list info
 */
const assetsListInfo = computed(
  (): AsyncValue<CommonResult<AssetsResponse>> => {
    const assetsListItems = unref(assetsListAPIResponse);
    if (assetsListItems.loading || assetsListItems.error) {
      return {
        loading: assetsListItems.loading,
        error: assetsListItems.error,
        data: undefined,
      };
    }
    if (assetsListItems.data === undefined) {
      return {
        loading: false,
        error: new Error('Invalid api response, data must be present.'),
        data: undefined,
      };
    }

    return {
      loading: false,
      error: undefined,
      data: assetsListItems.data,
    };
  }
);

/**
 * Look over current assets list
 * if not populated insert new data from api response
 */
watchEffect(() => {
  const assetsResponse = unref(assetsListAPIResponse);
  if (unref(assetsList).length === 0) {
    if (assetsResponse.loading || !!assetsResponse.error) {
      assetsList.value = [];
      return;
    }
    const assetsInfoData = unref(assetsListInfo).data;
    if (!assetsInfoData) {
      assetsList.value = [];
      return;
    }
    assetsList.value = assetsInfoData.data.assets;
  }

  handleEditMode();
});

/** ------------------- Maintenance item data ------------------- */

/**
 * Prepare query parameters to fetch available maintenance assets for current selected org
 */
function getMaintenanceItemsQueryParameters(): QueryParameter {
  const pagination: Pagination = { page: 1, size: 100000 };
  const assetId = unref(selectedAssetId);
  const selectedOrganization = unref(context.value).organization;
  const queryParameters: QueryParameter = {
    filters: !assetId
      ? []
      : [
          {
            name: 'assetId',
            operator: FilterOperator.EQUAL,
            value: [assetId],
          },
        ],
    sorters: [],
    pagination: pagination,
    timezone: selectedOrganization?.timezone,
  };
  return queryParameters;
}

/**
 * Fetch from API available maintenance items list
 */
const maintenanceItemsListAPIResponse = useAsync(
  computed(async (): Promise<CommonResult<MaintenanceFilterItem[]>> => {
    const selectedOrganization = unref(context).organization;
    const organizationId = selectedOrganization?.id;
    const response = await getMaintenanceFiltersList(
      organizationId!,
      getMaintenanceItemsQueryParameters()
    );

    return response;
  })
);

/**
 * Prepare final assets list info
 */
const maintenanceItemsListInfo = computed(
  (): AsyncValue<CommonResult<MaintenanceFilterItem[]>> => {
    const maintenanceItems = unref(maintenanceItemsListAPIResponse);
    if (maintenanceItems.loading || maintenanceItems.error) {
      return {
        loading: maintenanceItems.loading,
        error: maintenanceItems.error,
        data: undefined,
      };
    }
    if (maintenanceItems.data === undefined) {
      return {
        loading: false,
        error: new Error('Invalid api response, data must be present.'),
        data: undefined,
      };
    }

    return {
      loading: false,
      error: undefined,
      data: maintenanceItems.data,
    };
  }
);

/**
 * Look over maintenance items list
 * if not populated insert new data from api response
 */
watchEffect(() => {
  /** Clean previous input value or dropdown list */
  selectedMaintenanceItem.value = undefined;
  maintenanceItemsList.value = [];
  partsList.value = { replace: [], check: [] };

  const assetsResponse = unref(assetsListAPIResponse);
  if (
    maintenanceItemsList &&
    unref(maintenanceItemsList!)?.length === 0 &&
    unref(selectedAssetId)
  ) {
    if (assetsResponse.loading || !!assetsResponse.error) {
      maintenanceItemsList.value = [];
      return;
    }
    const maintenanceItemsInfoData = unref(maintenanceItemsListInfo).data;
    if (!maintenanceItemsInfoData) {
      maintenanceItemsList.value = [];
      return;
    }
    maintenanceItemsList.value = maintenanceItemsInfoData.data;
  }

  handleEditMode();
});

/**
 * Create new maintenance appointment item
 */
async function addNewMaintenanceAppointment(): Promise<void> {
  try {
    if (inputFormIsValid()) {
      customFailedMessage(i18n.tc('common.completeRequiredFieldsFirst'));
      // throw new Error('Input form has invalid fields!');
      return;
    }
    appointmentModalIsLoading.value = true;
    const response = await createNewMaintenanceAppointment(
      getAppointmentRequestBody()
    );
    if (response.code === 200) {
      emit('reload');
    }
    if (response.code === 400) {
      customFailedMessage(
        i18n.tc('maintenance.maintenancePlanner.existingAppointmentOnSameDate')
      );
    }
  } catch (error) {
    console.log(error);
  } finally {
    appointmentModalIsLoading.value = false;
  }
}

/**
 * Check if at least one of the fields is invalid
 */
function inputFormIsValid() {
  return (
    !unref(selectedAssetId) ||
    !unref(selectedMaintenanceItem) ||
    !unref(currentSelectedDate)
  );
}

/**
 * Prepare appointment request body
 */
function getAppointmentRequestBody(): AppointmentBody {
  return {
    assetId: unref(selectedAssetId)!,
    maintenanceItemId: unref(selectedMaintenanceItem)!,
    appointmentTime: getCurrentInputDateSelection(),
    duration: unref(defaultAppointmentDuration),
  };
}

/**
 * API field 'appointmentTime' require date pattern: 'YYYY-MM-DDTHH:mm'
 * Take user date input selection then concatenate with action current time HH:mm
 */
function getCurrentInputDateSelection(): string {
  return (
    moment(unref(currentSelectedDate)!).format('YYYY-MM-DDT') +
    moment().format('HH:mm')
  );
}

/** ------------------- Maintenance item by id data ------------------- */

const getMaintenanceItemByIdFromApi = useAsync(
  computed(
    async (): Promise<CommonResult<MainteananceItemById> | undefined> => {
      if (unref(selectedMaintenanceItem)) {
        const response = await getMaintItemById(
          unref(selectedMaintenanceItem)!
        );
        return response;
      }
    }
  )
);

/**
 * Process maintenance item by id data
 */
const maintenanceItemByIdData = computed(
  (): AsyncValue<CommonResult<MainteananceItemById>> => {
    const maintenanceItemById = unref(getMaintenanceItemByIdFromApi);
    if (maintenanceItemById.loading || maintenanceItemById.error) {
      return {
        loading: maintenanceItemById.loading,
        error: maintenanceItemById.error,
        data: undefined,
      };
    }
    if (maintenanceItemById.data === undefined) {
      return {
        loading: false,
        error: new Error('Invalid api response, data must be present.'),
        data: undefined,
      };
    }

    return {
      loading: false,
      error: undefined,
      data: maintenanceItemById.data,
    };
  }
);

/**
 * Look over selectedMaintenanceItem
 * If there is a value, fetch maintenance item by id then populate transferDataList for displaying the parts as replace/check
 */
watchEffect(() => {
  const assetsResponse = unref(assetsListAPIResponse);
  if (unref(selectedMaintenanceItem)) {
    if (assetsResponse.loading || !!assetsResponse.error) {
      partsList.value = undefined;
      return;
    }
    const itemData = unref(maintenanceItemByIdData).data;
    if (!itemData) {
      partsList.value = undefined;
      return;
    }

    /** Provide general product model id */
    maintenanceItemProductModelId.value = itemData.data.productModelId!;
    /** Get maintenance item product model by id where part name can be extracted */
    const productModelById = unref(productModelByIdData)?.data;

    /** Populate transfer list (replace/check) with part id and part name */
    partsList.value = itemData.data.maintenanceParts?.reduce(
      (accumulator: PartsList, current: MaintenanceItemPart) => {
        const finalData: PartsList = {
          replace: accumulator.replace ? [...accumulator.replace] : [],
          check: accumulator.check ? [...accumulator.check] : [],
        };

        /** Get part name from product model by id > contained parts field */
        const maintenancePartName =
          productModelById?.data?.containedParts?.find(
            (item: ContainedPart) => item.id === current.containedPartId
          )?.name;

        if (
          current.maintenanceOperationKind ===
          MAINTENANCE_ITEM_OPERATION_KIND.Replace
        ) {
          finalData?.replace?.push({
            id: current.containedPartId,
            partName: maintenancePartName!,
            checked: false,
          });
        } else {
          finalData?.check?.push({
            id: current.containedPartId,
            partName: maintenancePartName!,
            checked: false,
          });
        }
        return finalData;
      },
      { replace: [], check: [] } as PartsList
    );
  }
});

/**
 * Get product model by id
 */
const getProductModelByIdFromApi = useAsync(
  computed(async (): Promise<CommonResult<ProductModel> | undefined> => {
    if (unref(maintenanceItemProductModelId)) {
      const response = await getProductModelById(
        unref(maintenanceItemProductModelId)!
      );
      return response;
    }
  })
);

/**
 * Process product model by id data
 * From the response will be retrieved parts name
 */
const productModelByIdData = computed(
  (): AsyncValue<CommonResult<ProductModel>> => {
    const productModelById = unref(getProductModelByIdFromApi);
    if (productModelById.loading || productModelById.error) {
      return {
        loading: productModelById.loading,
        error: productModelById.error,
        data: undefined,
      };
    }
    if (productModelById.data === undefined) {
      return {
        loading: false,
        error: new Error('Invalid api response, data must be present.'),
        data: undefined,
      };
    }

    return {
      loading: false,
      error: undefined,
      data: productModelById.data,
    };
  }
);

/**
 * Give to the date picker date options for disabling the dates
 */
const datePickerOptions: { disabledDate: (date: Date) => boolean } = {
  disabledDate: disabledPastDate,
};

/**
 * Populate fields in edit mode
 */
function handleEditMode(): void {
  if (props.modalTriggerSource === MaintenanceModalTriggerSource.Edit) {
    selectedAssetId.value = props.appointment?.assetId ?? undefined;
    selectedMaintenanceItem.value =
      props.appointment?.maintenanceItemId ?? undefined;
    currentSelectedDate.value = moment(
      props.appointment?.appointmentTime
    ).format(LOCALDATE_FORMAT);
  }
}

/**
 * Update maintenance appointment by id
 */
async function updateAppointment(): Promise<void> {
  try {
    appointmentModalIsLoading.value = true;
    const response = await updateMaintenanceAppointment(
      props.appointment?.id!,
      {
        appointmentTime: getCurrentInputDateSelection(),
      }
    );
    if (response.code === 200) {
      emit('reload');
      promptSuccessBox(i18n.tc('maintenance.adjusted'));
    }
  } catch (error) {
    console.log(error);
  } finally {
    appointmentModalIsLoading.value = false;
  }
}

/**
 * Switch to conduct mode
 */
function changeToConductAppointmentMode(): void {
  inputFormIsInConductMode.value = true;
}

/**
 * Conduct maintenance appointment
 */
async function conductAppointment(): Promise<void> {
  try {
    appointmentModalIsLoading.value = true;
    const requestBody = {
      appointmentTime: getCurrentInputDateSelection(),
      note: unref(noteInputFieldContent),
      maintainedParts: prepareMaintenancePartRequestBody(),
    };

    const response = await conductMaintenanceAppointment(
      props.appointment?.id!,
      requestBody
    );
    if (response.code === 200) {
      emit('reload');
      promptSuccessBox(i18n.tc('maintenance.conducted'));
    }
  } catch (error) {
    console.log(error);
  } finally {
    appointmentModalIsLoading.value = false;
  }
}

/**
 * Prepare maintenance parts for conduct request body
 */
function prepareMaintenancePartRequestBody(): MaintenanceItemPart[] {
  const parts = unref(partsList);
  let finalList: MaintenanceItemPart[] = [];

  parts?.check.forEach((item: ItemPart) => {
    if (item.checked) {
      finalList.push({
        containedPartId: item.id,
        maintenanceOperationKind: MAINTENANCE_ITEM_OPERATION_KIND.Check,
      });
    }
  });

  parts?.replace.forEach((item: ItemPart) => {
    if (item.checked) {
      finalList.push({
        containedPartId: item.id,
        maintenanceOperationKind: MAINTENANCE_ITEM_OPERATION_KIND.Replace,
      });
    }
  });
  return finalList;
}

/**
 * Watch when user change to adjust mode
 * Change to today date if the selected appointment has a date in the past
 */
watch([inputFormIsInEditMode], () => {
  const currentDate = unref(currentSelectedDate);
  const todayDate = moment().format(LOCALDATE_FORMAT);
  if (moment().isAfter(moment(currentDate))) {
    currentSelectedDate.value = todayDate;
  }
});

/**
 * Scenario: for conduct mode when active, check if appointment date (past/future) is different from today
 * If it is, inject default value: today
 */
watch([inputFormIsInConductMode], () => {
  const currentDate = unref(currentSelectedDate);
  const todayDate = moment().format(LOCALDATE_FORMAT);
  if (!moment().isSame(moment(currentDate))) {
    currentSelectedDate.value = todayDate;
  }
});

/**
 * Check note input to not have 256 or bigger chars as length
 * Throw a pop up error message
 */
watch(noteInputFieldContent, () => {
  if (unref(noteInputFieldContent)?.length! >= 256) {
    customFailedMessage(i18n.tc('common.max256CharsAllowed'));
  }
});

/**
 * Get current date
 */
function formatDateToPattern(date: string | undefined): string {
  return (
    moment(date).format(LOCALDATE_FORMAT) ?? moment().format(LOCALDATE_FORMAT)
  );
}

const inlineMsg: boolean = false;

const rules = {};
</script>

<template>
  <el-dialog
    :visible="isModalVisible"
    class="maintenance-appointment-dialog"
    :width="'700px'"
    :top="'5vh'"
    :title="title"
    @close="closeModalFromX"
  >
    <el-form
      class="content-container"
      id="maintenance-input-form"
      :model="assetInputForm"
      ref="appointmentForm"
      :rules="rules"
      :inline-message="inlineMsg"
      style="margin-left: 10px"
      v-loading="
        appointmentModalIsLoading ||
        assetsListAPIResponse.loading ||
        maintenanceItemsListAPIResponse.loading
      "
    >
      <CusFormItem
        :title="'maintenance.assetId'"
        :required="modalTriggerSource === MaintenanceModalTriggerSource.New"
      >
        <el-form-item
          prop="selectedAssetId"
          v-loading="assetsListAPIResponse.loading"
        >
          <el-select
            v-if="modalTriggerSource === MaintenanceModalTriggerSource.New"
            id="mainteance-asset"
            v-model="selectedAssetId"
            v-bind:placeholder="$t('common.pleaseSelect')"
            filterable
          >
            <el-option
              v-for="asset in assetsList"
              :key="asset.id"
              :label="asset?.companyAssetId"
              :value="asset?.id"
            />
          </el-select>
          <span v-else class="field-value">{{
            props?.appointment?.companyAssetId
          }}</span>
        </el-form-item>
      </CusFormItem>

      <CusFormItem
        :title="'maintenance.item'"
        :required="modalTriggerSource === MaintenanceModalTriggerSource.New"
      >
        <el-form-item
          prop="selectedMaintenanceItem"
          v-loading="maintenanceItemsListAPIResponse.loading"
        >
          <el-select
            v-if="modalTriggerSource === MaintenanceModalTriggerSource.New"
            id="mainteance-item"
            v-model="selectedMaintenanceItem"
            v-bind:placeholder="$t('common.pleaseSelect')"
            filterable
          >
            <el-option
              v-for="item in maintenanceItemsList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            />
          </el-select>
          <span v-else class="field-value">{{
            props?.appointment?.maintenanceItemName
          }}</span>
        </el-form-item>
      </CusFormItem>
      <div class="transfer-list-container">
        <TransferList
          v-loading="
            maintenanceItemByIdData.loading || productModelByIdData.loading
          "
          :triggerSource="modalTriggerSource"
          :isConductMaintenanceActiv="inputFormIsInConductMode"
          :transferListData="partsList"
        />
      </div>
      <CusFormItem
        :title="
          !inputFormIsInConductMode
            ? 'maintenance.appointmentDate'
            : 'maintenance.conductanceDate'
        "
        :required="inputFormIsInEditMode || inputFormIsInConductMode"
      >
        <el-form-item prop="currentSelectedDate">
          <el-date-picker
            v-if="
              modalTriggerSource === MaintenanceModalTriggerSource.New ||
              inputFormIsInConductMode ||
              inputFormIsInEditMode
            "
            class="appointment-date-picker"
            popper-class="date-picker-popper"
            v-model="currentSelectedDate"
            type="date"
            :placeholder="$t('common.tipSelectDate')"
            :value-format="unref(datePickerDefaultFormat)"
            :picker-options="datePickerOptions"
            :clearable="false"
            :disabled="inputFormIsInConductMode"
          />
          <span v-else class="field-value">
            {{ formatDateToPattern(props?.appointment?.appointmentTime) }}</span
          >
        </el-form-item>
      </CusFormItem>

      <CusFormItem
        :title="'maintConf.maintRules'"
        :required="false"
        class="maintenance-rules-container"
      >
        <el-form-item prop="rulesProgress">
          <div
            v-for="(rule, index) in props.appointment?.rulesProgress"
            :key="index"
            class="rule-progress-item field-value"
            :id="'rule-progress-item-' + index"
          >
            <div
              class="rule-progress-item-kpi-code"
              :id="'rule-progress-item-kpi-code-' + index"
            >
              {{ i18n.t(rule.kpiCode) + ':' }}
            </div>
            <div
              class="rule-progress-item-threshold"
              :id="'rule-progress-item-threshold-' + index"
            >
              {{
                appointment?.dominantValueRepresentedDays +
                '/' +
                rule.thresholdValue
              }}
            </div>
          </div>
        </el-form-item>
      </CusFormItem>

      <CusFormItem
        v-if="inputFormIsInConductMode"
        :title="'maintenance.note'"
        :required="false"
      >
        <el-form-item prop="currentSelectedDate">
          <el-input
            v-model="noteInputFieldContent"
            type="textarea"
            maxlength="256"
          />
        </el-form-item>
      </CusFormItem>
    </el-form>

    <div class="action-buttons-container" style="flex-direction: column">
      <div class="action-buttons" style="margin: 10px 0px">
        <el-button
          class="action-button"
          v-if="
            modalTriggerSource === MaintenanceModalTriggerSource.New ||
            inputFormIsInEditMode ||
            inputFormIsInConductMode
          "
          id="cancel-btn"
          type="plain"
          @click="closeDialog"
        >
          {{ $t('maintenance.cancel') }}
        </el-button>

        <el-button
          class="action-button"
          v-if="
            appointment?.isConductedManually &&
            modalTriggerSource === MaintenanceModalTriggerSource.Edit &&
            !inputFormIsInEditMode &&
            !inputFormIsInConductMode
          "
          v-permission="['AUTHRSC_ACTION_MAINT_APPT_CONDUCT']"
          id="conduct-btn"
          type="plain"
          @click="changeToConductAppointmentMode"
        >
          {{ $t('maintenance.conduct') }}
        </el-button>

        <el-button
          class="action-button"
          v-if="modalTriggerSource === MaintenanceModalTriggerSource.New"
          v-permission="['AUTHRSC_ACTION_MAINT_APPT_CREATE']"
          id="cancelmodalbtn"
          type="plain"
          @click="addNewMaintenanceAppointment"
        >
          {{ $t('maintenance.add') }}
        </el-button>

        <el-button
          class="action-button"
          v-if="
            !inputFormIsInEditMode &&
            modalTriggerSource === MaintenanceModalTriggerSource.Edit &&
            !inputFormIsInConductMode
          "
          id="edit-btn"
          v-permission="['AUTHRSC_ACTION_MAINT_APPT_ADJUST']"
          type="plain"
          @click="changeToEditMode"
        >
          {{ $t('maintenance.adjustAppointment') }}
        </el-button>

        <el-button
          class="action-button"
          v-if="inputFormIsInEditMode && !inputFormIsInConductMode"
          v-permission="['AUTHRSC_ACTION_MAINT_APPT_ADJUST']"
          id="edit-btn"
          type="plain"
          @click="updateAppointment"
        >
          {{ $t('maintenance.adjustmentDone') }}
        </el-button>

        <el-button
          class="action-button"
          v-if="inputFormIsInConductMode"
          v-permission="['AUTHRSC_ACTION_MAINT_APPT_CONDUCT']"
          id="edit-btn"
          type="plain"
          @click="conductAppointment"
        >
          {{ $t('maintenance.done') }}
        </el-button>
      </div>
    </div>
  </el-dialog>
</template>

<style scoped>
.maintenance-appointment-dialog {
  border-radius: 15px;
}

.maintenance-appointment-dialog :deep(.el-dialog__title) {
  font-size: 20px;
  font-family: var(--fontRobotoMedium);
  font-weight: 500;
  line-height: 19px;
  color: #373e41;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}

.maintenance-appointment-dialog :deep(.el-dialog__header) {
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
}

.maintenance-appointment-dialog :deep(.el-dialog) {
  border-radius: 6px;
}

.maintenance-appointment-dialog :deep(.el-dialog__headerbtn) {
  top: 10px;
  font-size: 30px;
  color: #373d41;
  position: static;
  margin-top: -5px;
}
</style>

<style lang="scss" scoped>
.maintenance-appointment-dialog :deep(.el-dialog__header) {
  padding: 6px;
  padding-bottom: 6px;
  background-color: var(--Main) !important;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 20px;
  padding-right: 16px;
  min-height: 44px;
  height: auto;
}

.el-button--plain:hover {
  color: #ffffff;
  border-color: #5f6567;
  background-color: #5f6567;
}

.maintenance-appointment-dialog
  :deep(.el-dialog__headerbtn:hover .el-dialog__close) {
  color: #5f6567;
}

.action-buttons {
  padding: 0 50px;
  display: flex;
  justify-content: end;
  width: 600px;
}

.note-container {
  display: flex;
  margin-top: 10px;
  margin-left: 5px;

  .note-title {
    flex-shrink: 0;
    width: 150px;
    margin-top: 20px;
    margin-right: 82px;
    text-align: right;
  }

  .note-input {
    width: 600px;
    font-size: 1.285714rem;
    font-family: $font-Roboto-Regular;
    line-height: 1.357143rem;
    color: #373e41;
    opacity: 1;
  }

  :deep(.el-textarea__inner) {
    width: $inputWidth;
    font-size: 1.285714rem;
    font-family: $font-Roboto-Regular;
    line-height: 1.357143rem;
    color: #373e41;
    opacity: 1;
    border: 1px solid #707070 !important;
  }
}

.transfer-list-container {
  width: 32.5rem;
  min-height: 80px;
  margin: 10px auto 0px auto;
}

.content-container {
  min-height: 300px;
  margin-bottom: 20px;
  margin-left: auto;
  margin-right: auto;
}

.content-container :deep(.item-title) {
  padding-bottom: 0px;
  text-align: left;
  margin-bottom: auto;
  margin-top: 2px;
}

.content-container :deep(.el-form-item) {
  width: 300px;
  margin-bottom: 0px;
}

.appointment-date-picker :deep(.el-input__prefix) {
  left: 360px;
  right: 0px;
}

.appointment-date-picker :deep(.el-input__inner) {
  padding-left: 15px;
}

.content-container :deep(.el-input__inner) {
  width: 300px;
}

.content-container :deep(.el-date-editor) {
  width: 300px;
}

.el-date-editor :deep(.el-input__icon) {
  margin-left: -155px;
}

.maintenance-rules-container :deep(.el-form-item__content) {
  align-items: flex-start;
}

.rule-progress-item {
  display: flex;
  font-family: $font-Roboto-Medium;
  font-size: 16px;
}

.rule-progress-item-kpi-code {
  width: 150px;
}

.rule-progress-item-threshold {
  margin-left: 20px;
}

.content-container :deep(.item) {
  margin-left: auto;
  margin-right: auto;
  margin-top: 0;
  word-break: break-word;
}

.item {
  width: 32.5rem;
  padding: 0px 10px 0px 10px;
}

.el-dialog {
  width: 200px;
}

.action-buttons-container {
  width: 500px;
}

.maintenance-appointment-dialog :deep(.el-dialog__header) {
  justify-content: center;
}

.field-value {
  font-family: $font-Roboto-Medium;
  font-size: 16px;
  line-height: 20px;
}

.action-button {
  margin-left: 15px;
}
</style>
