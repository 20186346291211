<script lang="ts" setup>
import { getMaintenanceStatusOverview, MaintStatus } from '@/api/maintenance';
import { useActiveContext } from '@/auth/context';
import WidgetCard from '@/components/layout/widget/WidgetCard.vue';
import { useAsync } from '@/composables/async';
import { showNotificationOnError } from '@/composables/error';
import i18n from '@/lang';
import {
  Filter,
  FilterOperator,
  QueryParameter,
} from '@/model/queryParameters/QueryParameter';
import { customFailedMessage } from '@/utils/prompt';
import MaintStatusTile from '@/views/maintenance/components/MaintStatusTile.vue';
import { computed, unref } from 'vue';
import { useRoute } from '../../composables/router';
import { AssetType } from '../../utils/workData/lookuptable';
import { ActiveContext } from '../@/auth/context';
import { useSelectedMaintenanceStatuses } from './status';

type MaintenanceStatusCounts = Record<MaintStatus, number>;
const maintenanceStatusOrder = [
  MaintStatus.OK,
  MaintStatus.CLOSE,
  MaintStatus.DUE,
];

const routeMap = new Map<string, string>([
  ['/assets/mobile-compactors', AssetType.MobileCompactor],
  ['/assets/static-compactors', AssetType.StaticCompactor],
  ['/assets/tipping-vehicles', AssetType.TippingVehicle],
]);

const route = useRoute();

const {
  ref: selectedMaintenanceStatuses,
  update: updateMaintenanceStatuses,
  isClickable: isClickable,
} = useSelectedMaintenanceStatuses();

/** Handle click event on maintenance status tiles */
async function handleTileClick(clickedTile: MaintStatus) {
  const previousSelection = unref(selectedMaintenanceStatuses);
  const newSelection = new Set(previousSelection);
  if (newSelection.has(clickedTile) && Array.from(newSelection).length === 1) {
    customFailedMessage(
      i18n.tc('maintenance.atLeastOneMaintenanceStatusShouldBeSelected')
    );
    return;
  }
  if (newSelection.has(clickedTile) && Array.from(newSelection).length > 1) {
    newSelection.delete(clickedTile);
  } else {
    newSelection.add(clickedTile);
  }
  updateMaintenanceStatuses(newSelection);
}

async function getMaintenanceStatusCounts(
  orgIds: string[],
  assetType?: string
): Promise<MaintenanceStatusCounts> {
  const assetTypeFilter: Filter[] = assetType
    ? [
        {
          name: 'assetType',
          operator: FilterOperator.IN,
          value: [assetType],
        },
      ]
    : [];
  const queryParameters: QueryParameter = {
    filters: [
      ...assetTypeFilter,
      {
        name: 'organizationId',
        operator: FilterOperator.IN,
        value: orgIds,
      },
    ],
  };
  const maintenanceStatusOverviewResult = await getMaintenanceStatusOverview(
    queryParameters
  );
  if (
    maintenanceStatusOverviewResult.code !== 200 ||
    !maintenanceStatusOverviewResult.data
  ) {
    throw new Error('Error fetching maintenance status overview');
  }
  const maintenanceStatusOverview = maintenanceStatusOverviewResult.data;

  const statusCounts = Object.fromEntries(
    maintenanceStatusOrder.map((status) => [
      status,
      maintenanceStatusOverview.find(
        (entry) => entry.maintenanceStatus === status
      )?.numberOfMaintenancePlans ?? 0,
    ])
  ) as MaintenanceStatusCounts;
  return statusCounts;
}
const activeContext = useActiveContext();
function getOrganizationIdsList(
  externalRoute: boolean,
  context: ActiveContext
): string[] | undefined {
  if (externalRoute) {
    return context.organizationIds;
  }
  return context.organization?.id ? [context.organization?.id] : [];
}
const maintenanceStatusCounts = useAsync(
  computed((): Promise<Record<MaintStatus, number>> | undefined => {
    const context = unref(activeContext);
    const externalRoute = routeMap.has(route.value.path);
    const selectedOrganizations = getOrganizationIdsList(
      externalRoute,
      context
    );
    if (selectedOrganizations && selectedOrganizations.length > 0) {
      return getMaintenanceStatusCounts(
        selectedOrganizations,
        externalRoute ? routeMap.get(route.value.path) : undefined
      );
    }
  })
);

showNotificationOnError(maintenanceStatusCounts, 'errorFetchingData');
</script>

<template>
  <WidgetCard
    :loading="maintenanceStatusCounts.loading"
    :expandable="!isClickable"
    :expandedLink="{ path: '/maintenance/overview' }"
    class="maintenance-status-widget"
  >
    <div class="maintenance-status-teils-container">
      <MaintStatusTile
        v-for="(status, index) in maintenanceStatusOrder"
        :key="index"
        :maintStatus="status"
        :isClickable="isClickable"
        :maintStatusCount="maintenanceStatusCounts.data?.[status]"
        :isSelected="selectedMaintenanceStatuses.has(status)"
        @click-status="handleTileClick(status)"
      />
    </div>
  </WidgetCard>
</template>

<style lang="scss" scoped>
.maintenance-status-widget :deep(.content) {
  display: flex;
  justify-content: center;
}

.maintenance-status-teils-container {
  width: 100vh;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}
</style>
