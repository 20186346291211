<script lang="ts" setup>
import { SafetyEventType, TripsWithEventsSummaryByTime } from '@/api/trip';
import i18n from '@/lang';
import { AggregationType } from '@/widgets/utils/Constants';
import echarts, { EChartOption } from 'echarts';
import {
  computed,
  nextTick,
  onMounted,
  onUnmounted,
  ref,
  unref,
  watch,
  watchEffect,
} from 'vue';

const props = defineProps<{
  data: TripsWithEventsSummaryByTime[];
  safetyEvent?: SafetyEventType;
  aggregationType?: AggregationType;
}>();

const emit = defineEmits<{
  (e: 'selection', dateIndex: number): void;
}>();

const stackedBarChartData = ref<TripsWithEventsSummaryByTime[]>([]);
const chartRef = ref<HTMLDivElement | HTMLCanvasElement>();
let chartInstance = ref<echarts.ECharts | null>();

const isContainerMaxWidthUnder1920px = ref<boolean>(window.innerWidth < 1920);

const chartWithEvent = computed((): string => {
  if (!props.safetyEvent) return '';
  return i18n
    .tc('newFleetSafety.tripsWith')
    .concat(' ')
    .concat(i18n.tc(props.safetyEvent))
    .concat(' ')
    .concat(i18n.tc('newFleetSafety.event'));
});
const chartWithoutEvent = computed((): string => {
  if (!props.safetyEvent) return '';
  return i18n
    .tc('newFleetSafety.tripsWithout')
    .concat(' ')
    .concat(i18n.tc(props.safetyEvent))
    .concat(' ')
    .concat(i18n.tc('newFleetSafety.event'));
});

const chartRatioTooltip = ref<string>(i18n.tc('newFleetSafety.ratio'));

onMounted(() => {
  initChart();
});

/**
 * Check if component is mounted and if there is a props > data, assigned it locally
 */
watchEffect(() => {
  if (
    props.data != undefined &&
    stackedBarChartData.value.length === 0 &&
    chartRef
  ) {
    stackedBarChartData.value = props.data;
    nextTick(async () => {
      initChart();
    });
  }
});

/**
 * Watch over props > data changed of new request made from API
 */
watch(
  () => props.data,
  () => {
    if (chartRef) {
      if (chartInstance.value) chartInstance.value?.dispose();
      stackedBarChartData.value = props.data;
      nextTick(() => {
        initChart();
      });
    }
  },
  { immediate: true }
);

/**
 * Initiate chart
 */
function initChart() {
  disposeChart();
  chartInstance.value = echarts.init(chartRef.value!);
  unref(chartInstance)!.setOption(getOption());

  // Disable axis pointer interaction for mouse hover
  chartInstance.value!.setOption({
    axisPointer: {
      triggerOn: 'none',
    },
  });

  // Default slot selection when landing on the page accordingly to the selected date
  const mostRecentPointPosition = chartInstance.value.convertToPixel(
    { seriesIndex: 0 },
    [0, props.data.length - 1]
  );
  chartInstance.value!.dispatchAction({
    type: 'updateAxisPointer',
    x: mostRecentPointPosition[0],
    y: mostRecentPointPosition[1],
  });

  // Handle click event on chart slot element
  /* @ts-expect-error TODO Wrong type */
  chartInstance.value!.getZr().on('click', (params: any) => {
    const xAxisOffset = params.event.offsetX;
    const yAxisOffset = params.event.offsetY;

    var pointInPixel = [xAxisOffset, yAxisOffset];
    if (
      !chartInstance.value ||
      !chartInstance.value.containPixel({ gridIndex: 0 }, pointInPixel)
    ) {
      return;
    }

    // Move ssaxis pointer on a specific slot by click event
    chartInstance.value!.dispatchAction({
      type: 'updateAxisPointer',
      x: xAxisOffset,
      y: yAxisOffset,
    });

    // Get click category index
    var pointInGrid = chartInstance.value!.convertFromPixel(
      { seriesIndex: 0 },
      pointInPixel
    );

    // Emit clicked slot index to the parent component for further processing
    emit('selection', Math.abs(pointInGrid[1]));
  });

  window.addEventListener('resize', resizeChart);
}

/**
 * Distroy chart instance and remove event listener for resizing
 */
onUnmounted(() => {
  disposeChart();
});

function disposeChart() {
  if (chartInstance.value) {
    chartInstance.value.dispose();
    chartInstance.value = null;
    window.removeEventListener('resize', resizeChart);
  }
}

/**
 * Handle resize chart
 */
const resizeChart = () => {
  isContainerMaxWidthUnder1920px.value = window.innerWidth < 1920;
  if (unref(chartInstance)) {
    unref(chartInstance)!.resize();
  }
};

const Y_AXIS_LABELS_WIDTH: number = 80;
const MARGIN = 10;

const grid = {
  left: Y_AXIS_LABELS_WIDTH,
  right: MARGIN,
  top: MARGIN,
  bottom: MARGIN,
};

/**
 * Provide echart stacked vertical bars options
 */
const getOption = () =>
  ({
    color: ['#be442d', '#d9d9d9', '#0138f7'],
    tooltip: {
      show: true,
      trigger: 'axis',
      formatter: () => '',
      axisPointer: {
        type: 'shadow',
        label: {
          show: false,
        },
        shadowStyle: {
          color: 'rgba(179, 214, 254, 0.7)',
        },
      },
      position: 'inside',
    },
    grid,
    xAxis: [
      {
        nameLocation: 'middle',
        nameTextStyle: {
          fontWeight: 'bold',
        },
        type: 'value',
        axisLine: {
          show: false,
        },
        axisTick: {
          show: false,
        },
        show: false,
      },
      {
        nameLocation: 'middle',
        nameTextStyle: {
          fontWeight: 'bold',
        },
        position: 'right',
        type: 'value',
        axisLine: {
          show: false,
        },
        axisTick: {
          show: false,
        },
        splitLine: {
          show: false,
        },
        min: 0,
        max: 100,
        splitNumber: 10,
        show: false,
      },
    ],
    yAxis: [
      {
        nameLocation: 'middle',
        nameTextStyle: {
          fontWeight: 'bold',
        },
        nameGap: 80,
        type: 'category',
        data: stackedBarChartData?.value
          ? stackedBarChartData?.value?.map((item) => item?.bucketLabel)
          : [],
        axisLabel: {
          formatter: (value: any) => {
            const maxLength = 10;
            if (value.length > maxLength) {
              return value.substring(0, maxLength) + '...';
            }
            return value;
          },
          tooltip: {
            show: true,
          },
        },
        axisLine: {
          show: false,
        },
        axisTick: {
          show: false,
        },
      },
    ],
    series: [
      {
        name: chartWithEvent.value,
        type: 'bar',
        stack: 'total',
        xAxisIndex: 0,
        data: stackedBarChartData?.value
          ? stackedBarChartData?.value?.map(
              (item) => item?.tripsWithEventsSummary?.numberOfTripsWithEvents!
            )
          : [],
        barMaxWidth: 10,
        itemStyle: {
          borderColor: 'black',
          borderWidth: 0.5,
        },
      },
      {
        name: chartWithoutEvent.value,
        type: 'bar',
        stack: 'total',
        xAxisIndex: 0,
        data: stackedBarChartData?.value
          ? stackedBarChartData?.value?.map(
              (item) =>
                item?.tripsWithEventsSummary?.numberOfTotalTrips! -
                item?.tripsWithEventsSummary?.numberOfTripsWithEvents!
            )
          : [],
        barMaxWidth: 10,
        itemStyle: {
          borderColor: 'black',
          borderWidth: 0.5,
        },
      },
      {
        name: chartRatioTooltip.value,
        type: 'line',
        xAxisIndex: 1,
        data: stackedBarChartData?.value
          ? stackedBarChartData?.value?.map(
              (item) => item?.tripsWithEventsSummary?.ratioPercentage!
            )
          : [],
      },
    ],
  } as EChartOption<EChartOption.SeriesBar>);
</script>

<template>
  <div id="stacked-bar-chart" class="stacked-bar-chart" ref="chartRef"></div>
</template>

<style lang="scss" scoped>
.stacked-bar-chart {
  width: 100%;
  height: 100%;
}
</style>
