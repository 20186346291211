/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon';
icon.register({
  setting: {
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
    data: '<path pid="0" data-name="减去 18" d="M14 20H6a6.007 6.007 0 01-6-6V6a6.007 6.007 0 016-6h8a6.007 6.007 0 016 6v8a6.006 6.006 0 01-6 6zM6 1a5.006 5.006 0 00-5 5v8a5.006 5.006 0 005 5h8a5.005 5.005 0 005-5V6a5.006 5.006 0 00-5-5z" _fill="#373e41"/><path pid="1" data-name="路径 1442" d="M12 10a2 2 0 10-.586 1.414A1.926 1.926 0 0012 10zm4 .883a.293.293 0 01-.063.18.237.237 0 01-.156.1l-1.445.218a5.008 5.008 0 01-.305.711q.273.39.836 1.078a.3.3 0 01.079.2.261.261 0 01-.07.18 8.1 8.1 0 01-.774.844c-.376.37-.62.555-.735.555a.381.381 0 01-.2-.07l-1.078-.845a4.356 4.356 0 01-.711.3 12.517 12.517 0 01-.227 1.453.262.262 0 01-.281.219H9.136a.3.3 0 01-.192-.066.23.23 0 01-.09-.168l-.218-1.438a4.829 4.829 0 01-.7-.29l-1.1.837a.281.281 0 01-.2.07.268.268 0 01-.2-.086 10.19 10.19 0 01-1.288-1.312.306.306 0 01.007-.36c.079-.109.211-.282.4-.52s.328-.42.422-.55a3.871 3.871 0 01-.32-.774l-1.43-.21a.247.247 0 01-.164-.1.3.3 0 01-.063-.184V9.121a.3.3 0 01.063-.18.234.234 0 01.148-.1l1.453-.219a3.881 3.881 0 01.3-.719q-.4-.555-.836-1.078a.293.293 0 01-.078-.187.329.329 0 01.07-.18 7.7 7.7 0 01.77-.84q.567-.558.739-.559a.33.33 0 01.2.079l1.078.836a4.351 4.351 0 01.71-.3 13 13 0 01.227-1.453.263.263 0 01.282-.219h1.734a.3.3 0 01.192.066.23.23 0 01.09.168l.218 1.438a4.707 4.707 0 01.7.29l1.11-.837a.254.254 0 01.187-.07.3.3 0 01.2.078 10.671 10.671 0 011.288 1.328.253.253 0 01.055.172.3.3 0 01-.062.18c-.079.11-.211.283-.4.52s-.328.42-.422.55a4.619 4.619 0 01.32.766l1.43.219a.244.244 0 01.164.1.3.3 0 01.063.185v1.734z" _fill="#373e41"/>',
  },
});
