<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
  name: 'TransferList',
})
export default class extends Vue {
  @Prop({ required: true }) transferListData!: any;
  @Prop({ required: true }) triggerSource!: any;
  @Prop({ required: true }) isConductMaintenanceActiv!: boolean;
  @Prop() isViewMode!: boolean;
  @Prop() isHistory!: boolean;

  handleMoveToReplaceList(index: number, itemId: any) {
    if (!this.isConductMaintenanceActiv) {
      return;
    }

    let itemToMove: any = this.transferListData?.check.find(
      (item: any) => item.id === itemId
    );
    this.transferListData?.replace.unshift(itemToMove);
    this.transferListData?.check.splice(index, 1);
  }

  handleMoveToCheckList(index: number, itemId: any) {
    if (!this.isConductMaintenanceActiv) {
      return;
    }

    let itemToMove: any = this.transferListData?.replace.find(
      (item: any) => item.id === itemId
    );
    this.transferListData?.check.unshift(itemToMove);
    this.transferListData?.replace.splice(index, 1);
  }

  get isInReadOnly() {
    return this.triggerSource === 'new' || !this.isConductMaintenanceActiv;
  }
}
</script>

<template>
  <div>
    <div class="list-container">
      <div class="list-header__replaced">
        <div class="list-header__title">
          {{
            isHistory ? $t('maintenance.replaced') : $t('maintenance.replace')
          }}:
        </div>
      </div>
      <div class="list-replaced-items-body">
        <div class="list-replace-content">
          <div
            v-if="
              transferListData?.replace && transferListData?.replace.length > 0
            "
          >
            <div
              class="list-item-replaced"
              v-for="(item, index) in transferListData?.replace"
              :key="item.id"
            >
              <div class="transfer-item">
                <span v-if="isViewMode" class="item-checkbox">{{
                  item.partName
                }}</span>
                <el-checkbox
                  v-else
                  v-model="item.checked"
                  :disabled="isInReadOnly"
                  class="item-checkbox"
                  :label="item.partName"
                  name="type"
                >
                </el-checkbox>
                <div
                  v-if="triggerSource != 'show'"
                  class="item-actions"
                  :style="isInReadOnly ? 'opacity: 0.3' : ''"
                >
                  <span
                    class="item-actions__up"
                    @click="handleMoveToCheckList(index, item.id)"
                    :title="isInReadOnly ? '' : $t('maintenance.moveDown')"
                    :class="triggerSource === 'new' ? 'disable-click' : ''"
                    :style="isInReadOnly ? 'cursor: not-allowed' : ''"
                  >
                    <img
                      class="action-icon"
                      src="@/assets/imgs/maintenance/arrow_down.svg"
                      style="width: 18px"
                    />
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div v-else class="no-list-items">
            {{ $t('maintenance.noParts') }}
          </div>
        </div>
      </div>
      <div class="list-header__checked">
        <div class="list-header__title">
          {{ isHistory ? $t('maintenance.checked') : $t('maintenance.check') }}:
        </div>
      </div>
      <div class="list-checked-items-body">
        <div class="list-checked-container">
          <div
            v-if="transferListData?.check && transferListData?.check.length > 0"
          >
            <div
              class="list-item-checked"
              v-for="(item, index) in transferListData?.check"
              :key="item.id"
            >
              <div class="transfer-item">
                <span v-if="isViewMode" class="item-checkbox">{{
                  item.partName
                }}</span>
                <el-checkbox
                  v-else
                  v-model="item.checked"
                  :disabled="isInReadOnly"
                  class="item-checkbox"
                  :label="item.partName"
                  name="type"
                >
                </el-checkbox>
                <div
                  v-if="triggerSource != 'show'"
                  class="item-actions"
                  :style="isInReadOnly ? 'opacity: 0.3' : ''"
                >
                  <span
                    class="item-actions__up"
                    @click="handleMoveToReplaceList(index, item.id)"
                    :title="isInReadOnly ? '' : $t('maintenance.moveUp')"
                    :class="triggerSource === 'new' ? 'disable-click' : ''"
                    :style="isInReadOnly ? 'cursor: not-allowed' : ''"
                  >
                    <img
                      class="action-icon"
                      src="@/assets/imgs/maintenance/arrow_up.svg"
                      style="width: 18px"
                    />
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div v-else class="no-list-items">
            {{ $t('maintenance.noParts') }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.list-container {
  background-color: white;
  margin: 0px 10px 15px 10px;
  border: 12px solid var(--Main);
  border-radius: 5px;
}

.list-header__replaced {
  background-color: var(--Main);
  padding: 5px 0 7px 2px;
  /* border-bottom: 0.4px solid #FFCD00; */
}

.list-header__checked {
  background-color: var(--Main);
  padding: 7px 0 7px 2px;
}

.list-replaced-items-body {
  background-color: var(--Main);
}

.list-replace-content {
  background-color: white;
  overflow-y: scroll;
  height: 7rem;
  border-radius: 7px;
}

.list-checked-items-body {
  background-color: var(--Main);
}

.list-checked-container {
  background-color: white;
  overflow-y: scroll;
  height: 7rem;
  border-radius: 7px;
}

.list-header__title {
  font-size: 1rem;
  font-family: var(--fontRobotoMedium);
}

.transfer-item {
  display: flex;
  justify-content: space-between;
  padding: 6px 0 6px 10px;
}

.item-actions {
  margin-right: 0.7rem;
}

.item-actions__up {
  font-size: 1rem;
  cursor: pointer;
  margin: 0 1px;
}

.item-actions__down {
  font-size: 1rem;
  cursor: pointer;
  margin: 0 1px;
}

.list-items-body {
  overflow: auto;
  height: 7rem;
}

.replace-item-list {
  min-height: 20px;
}

.replace-container {
  /* margin: 10px 0; */
  width: 530px;
  margin-top: 1rem;
}

.replace-part-container-header {
  background-color: gray;
  font-size: 12px;
}

.disable-click {
  pointer-events: none;
}

.is-checked :deep(.el-checkbox__inner) {
  background-color: #219c4d !important;
}
</style>

<style lang="scss">
.list-replace-content::-webkit-scrollbar {
  width: 1em;
  border-radius: 10px;
}

.list-replace-content::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
}

.list-replace-content::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  border-radius: 10px;
}

.list-checked-container::-webkit-scrollbar {
  width: 1em;
  border-radius: 10px;
}

.list-checked-container::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
}

.list-checked-container::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  border-radius: 10px;
}

.action-icon:hover {
  background-color: var(--Main);
  border-radius: 20px;
}
</style>

<style lang="scss" scoped>
.no-list-items {
  justify-content: center;
  display: flex;
  margin-top: 10%;
  font-size: 1rem;
  font-family: $font-Roboto-Regular;
  font-weight: bold;
}
</style>
