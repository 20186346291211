import { toError } from '@/utils/error';
import { computed, getCurrentInstance, ref, Ref, unref, watch } from 'vue';

/**
 * Asynchronous value including meta information on loading
 * and failure.
 */
export type AsyncValue<T> = {
  /**
   * When true, the value is being loaded with a new
   * value.
   *
   * The loading indicator is initially set to `true` and
   */
  loading: boolean;

  /**
   * When not undefined, the last data retrieval resulted
   * in an error.
   *
   * The error is unset whenever new data is being retrieved.
   */
  error: Error | undefined;

  /**
   * Result of most recent data retrieval, if any.
   *
   * Note that this value starts out as `undefined` and
   * will stay undefined when initial retrieval fails,
   * but the data will retain its value while retrieving
   * new data, and/or when that subsequent retrieval fails.
   */
  data: T | undefined;
};

function isPromise<T>(value: PromiseLike<T> | T): value is PromiseLike<T> {
  return !!value && typeof value === 'object' && 'then' in value;
}

export interface UseAsyncOptions {
  /**
   * Whether to clear any existing data (i.e. set `data` to `undefined`)
   * when a new Promise is processed.
   */
  clearOnRefresh?: boolean;
}

/**
 * Wrap a Ref ('stream') of Promises into an explicit Ref of async values,
 * i.e. data with associated meta information describing loading and error
 * states.
 *
 * If the Promise changes (e.g. new data is fetched), any previous pending
 * promises will be discarded. I.e. only the latest fetch is 'published' to
 * `.data`, and the state will keep being 'Loading' during this time.
 * This simplifies handling e.g. changing user input while still fetching
 * the previous data.
 *
 * @example
 * ```vue
 * <script lang="ts" setup>
 * async function fetchStuff(): Promise<Stuff> {
 *    // ... fetch stuff from API or something ...
 * }
 *
 * // Note that fetchStuff gets called any time any of its dependencies
 * // change (e.g. Refs or other reactive values it uses), because it's
 * // wrapped in `computed()`.
 * const stuff = useAsync(computed(fetchStuff));
 *
 * // If you explicitly wanted a one-time load, do e.g.:
 * // const stuff = useAsync(ref(fetchStuff()))
 *
 * // Example of using in a computed expression:
 * const justTheData = computed(() => unref(stuff).data);
 *
 * // Alternatively, when destructuring, use e.g.
 * // const { data, loading, error } = toRefs(useAsync(computed(fetchStuff)));
 * </script>
 *
 * <template>
 *   <MyWidget :data="stuff.data" :loading="stuff.loading" :error="stuff.error" />
 * </template>
 * ```
 */
export function useAsync<T>(
  promiseRef: Ref<PromiseLike<T> | T>,
  options?: UseAsyncOptions
): Ref<AsyncValue<T>> {
  if (!getCurrentInstance()) {
    // We want the API of useAsync to be trivial to use, i.e. not having to worry
    // about cleaning up when component gets unmounted etc. However, that means we'll
    // have to be called in the context of a component instance being setup, otherwise
    // the ref watcher will never be stopped, which causes a memory leak.
    throw new Error('`useAsync` cannot be used outside of component instance');
  }

  const state: Ref<AsyncValue<T>> = ref({
    loading: true,
    error: undefined,
    data: undefined,
  });
  let trackingPromise: PromiseLike<T> | undefined;

  // TODO consider changing this to somehow use `computed`, to make evaluation lazy.
  watch(
    promiseRef,
    (value, _oldValue, onCleanup) => {
      onCleanup(() => {
        // Even though we're already comparing trackingPromise against the
        // actual promise, we also want it to no longer trigger on the
        // very last call.
        trackingPromise = undefined;
      });

      if (!isPromise(value)) {
        // Immediate (non-async) value, just resolve.
        state.value = {
          loading: false,
          error: undefined,
          data: value,
        };
      } else {
        trackingPromise = value;
        state.value = {
          loading: true,
          error: undefined,
          data: options?.clearOnRefresh ? undefined : unref(state).data,
        };

        value.then(
          (v) => {
            if (trackingPromise !== value) {
              // Got a newer promise (or stopped watching) while this one was still resolving,
              // so just discard this one's result.
              return;
            }
            state.value = {
              loading: false,
              error: undefined,
              data: v,
            };
          },
          (err: unknown) => {
            if (trackingPromise !== value) {
              // Got a newer promise (or stopped watching) while this one was still resolving,
              // so just discard this one's result.
              return;
            }
            state.value = {
              loading: false,
              error: toError(err),
              data: unref(state).data,
            };
          }
        );
      }
    },
    {
      immediate: true,
      deep: false,
    }
  );

  return state;
}

/**
 * Catch AsyncValue errors, similar to regular try/catch.
 * Non-error values are passed through as-is, error values are passed to the error mapper.
 * If the error mapper throws an error, it is converted into an AsyncValue having that error.
 *
 * This overload allows the errorMapper to return undefined, in which case the resulting AsyncValue
 * no longer have the error field set, but its data will also be undefined.
 */
export function catchAsync<T, R>(
  source: Ref<AsyncValue<T>>,
  errorMapper: (error: Error, original: AsyncValue<T>) => void
): Ref<AsyncValue<T | undefined>>;
/**
 * Catch AsyncValue errors, similar to regular try/catch.
 * Non-error values are passed through as-is, error values are passed to the error mapper.
 * If the error mapper throws an error, it is converted into an AsyncValue having that error.
 *
 * This overload allows the errorMapper to return a new AsyncValue to be used instead of
 * the original.
 */
export function catchAsync<T, R>(
  source: Ref<AsyncValue<T>>,
  errorMapper: (error: Error, original: AsyncValue<T>) => AsyncValue<R>
): Ref<AsyncValue<T | R>>;
export function catchAsync<T, R>(
  source: Ref<AsyncValue<T>>,
  errorMapper: (error: Error, original: AsyncValue<T>) => void | AsyncValue<R>
): Ref<AsyncValue<T | R>> {
  return computed((): AsyncValue<T | R> => {
    const value = unref(source);
    if (!value.error) {
      return value;
    }
    try {
      const result = errorMapper(value.error, value);
      if (result === undefined) {
        return {
          error: undefined,
          data: undefined,
          loading: false,
        };
      }
      return result;
    } catch (err) {
      return {
        error: toError(err),
        data: value.data,
        loading: value.loading,
      };
    }
  });
}
