export const SUBSCRIPTION_PACKAGES_COLS = [
  {
    label: 'subscriptionPackages.name',
    prop: 'name',
    required: true,
    visible: true,
  },
  {
    label: 'subscriptionPackages.type',
    prop: 'type',
    required: true,
    visible: true,
  },
  {
    label: 'subscriptionPackages.note',
    prop: 'note',
    required: false,
    visible: true,
  },
  {
    label: 'subscriptionPackages.featuresEnabled',
    prop: 'featuresCount',
    required: false,
    visible: true,
  },
];

export const SUBSCRIPTION_FEATURES_COLS = [
  {
    label: 'subscriptionPackages.feature',
    prop: 'code',
    required: true,
    visible: true,
  },
  {
    label: 'subscriptionPackages.value',
    prop: 'value',
    required: true,
    visible: true,
  },
  {
    label: 'subscriptionPackages.operations',
    prop: 'operations',
    required: true,
    visible: true,
  },
];

export const FEATURES_TABLE_COLS = [
  {
    label: 'subscriptionPackages.feature',
    prop: 'code',
    required: true,
    visible: true,
  },
  {
    label: 'subscriptionPackages.value',
    prop: 'value',
    required: true,
    visible: true,
  },
];
