<script lang="ts">
import { Deployment, getDeployments } from '@/api/systemReleaseManagement';
import PureTable from '@/components/table/PureTable.vue';
import { useBooleanSystemFeature } from '@/composables/systemFeature';
import { Sorter, SorterOrder } from '@/model/queryParameters/QueryParameter';
import { UserModule } from '@/store/modules/user';
import Debouncer from '@/utils/debounce';
import { DEPLOYMENT_MANAGEMENT_COLS } from '@/utils/workData/deploymentManagementCols';
import {
  GENERAL_QUERY_OPERATORS,
  PROPER_ASSET_TYPE,
  SYSTEM_FEATURES,
} from '@/utils/workData/lookuptable';
import { ComputedRef } from 'vue';
import { Component, Vue } from 'vue-property-decorator';

@Component({
  name: 'releasesManagement',
  components: {
    PureTable,
  },
})
export default class extends Vue {
  /** Local variables */
  cols = DEPLOYMENT_MANAGEMENT_COLS.map((item) => ({
    ...item,
    label: this.$t(item.label),
  }));
  pageTotal = UserModule.gridPageSize;
  data: Deployment[] = [];
  numberOfReleases = this.data.length;
  searchInput = '';
  deploymentsTableIsLoading: boolean = false;
  pagination = {
    page: 1,
    size: this.pageTotal,
  };

  // table filter list
  filterList: { field: string; label?: string; dropdownEnum?: any }[] = [
    { field: 'systemReleaseId' },
    { field: 'companyName' },
    { field: 'deploymentBy' },
    { field: 'assetType', dropdownEnum: PROPER_ASSET_TYPE },
    { field: 'productModelCode' },
    { field: 'productModelNumber' },
  ];
  filterEnum: any = null;
  tableFilter: { searchFieldName: string; searchFieldValue: string | null } = {
    searchFieldName: this.filterList[0].field,
    searchFieldValue: null,
  };
  appliedFilters: {
    filters: any[];
    pagination: { page: number; size: number };
    sorters: { field: string; order: string }[];
  } = {
    filters: [],
    pagination: this.pagination,
    sorters: [],
  };
  remoteSoftwareEntitlement!: ComputedRef<boolean>;

  created() {
    this.remoteSoftwareEntitlement = useBooleanSystemFeature(
      SYSTEM_FEATURES.RemoteSoftwareUpdate
    );
    this.prepareDefaultQueryParameters();
    this.getData();
  }

  /**
   * Prepare default query parameters
   */
  prepareDefaultQueryParameters(): void {
    this.applyDefaultSorterByCreatedOn();
  }

  /**
   * Get deployments data from API
   */
  async getData(): Promise<void> {
    try {
      this.deploymentsTableIsLoading = true;
      const res = await getDeployments(this.appliedFilters);
      const response = res.data;
      for (let i in response.deployments) {
        response.deployments[
          i
        ].progressPercentage = `${response.deployments[i].progressPercentage}%`;
        response.deployments[i].defaultRelease = response.deployments[i]
          .defaultRelease
          ? this.$t('userModule.yes').toString()
          : this.$t('userModule.no').toString();
      }
      this.data = response.deployments.map((item) => ({
        ...item,
        assetType: this.$t(item.assetType),
      }));
      this.pageTotal = response.total;
    } catch (err) {
      console.log(err);
    } finally {
      this.deploymentsTableIsLoading = false;
    }
  }

  /**
   * Handle sort change event
   * @param sortField
   * @param sortOrder
   */
  async handleSortChange(sortField: string, sortOrder: string): Promise<void> {
    this.appliedFilters.sorters = [];
    if (sortOrder) {
      this.appliedFilters.sorters.push({
        field: sortField,
        order: sortOrder,
      });
    } else {
      this.applyDefaultSorterByCreatedOn();
    }
    await this.getData();
  }

  /**
   * Handle search input event
   */
  handleSearchInput(): void {
    this.appliedFilters.filters = [];
    if (this.tableFilter.searchFieldValue) {
      this.appliedFilters.filters = [
        {
          name: this.tableFilter.searchFieldName,
          operator: GENERAL_QUERY_OPERATORS.Like,
          value: [encodeURIComponent(this.tableFilter.searchFieldValue)],
        },
      ];
    }
    Debouncer.debounce(this.getData);
  }

  async handleSearchClear(): Promise<void> {
    this.tableFilter.searchFieldValue = '';
    this.filterEnum = this.filterList.find(
      (e) => e.field === this.tableFilter.searchFieldName
    )?.dropdownEnum;
    this.handleSearchInput();
  }

  /**
   * Handle page change event
   * @param page
   */
  handlePage(page: number): void {
    this.pagination.page = page;
    Debouncer.debounce(this.getData);
  }

  /**
   * Add new deployment
   */
  addnewDeploy(): void {
    this.$router.push('new/');
  }

  /**
   * Apply default sorter by created on
   * - deploymentFinishTime will be retrieved DESC by the API
   */
  applyDefaultSorterByCreatedOn(): void {
    const defaultSorter: Sorter = {
      field: 'deploymentStartTime',
      order: SorterOrder.DESC,
    };
    this.appliedFilters.sorters = [defaultSorter];
  }
}
</script>

<template>
  <div v-loading="deploymentsTableIsLoading">
    <div class="table-container">
      <div class="filter-container">
        <div class="d-flex ai-center search-select-container">
          <el-select
            class="search-select-field"
            id="releaseFilter"
            v-model="tableFilter.searchFieldName"
            @change="handleSearchClear"
            filterable
          >
            <el-option
              v-for="item in filterList"
              :key="item.label"
              :label="
                $t(item.label || `deployManagementModule.history.${item.field}`)
              "
              :value="item.field"
            />
          </el-select>
          <el-select
            v-if="filterEnum"
            @change="handleSearchInput"
            v-model="tableFilter.searchFieldValue"
            class="search-input-content"
          >
            <el-option
              class="filter-select-option"
              v-for="item of filterEnum"
              :key="item"
              :label="$t(item)"
              :value="item"
            />
          </el-select>
          <el-input
            v-else
            id="input-search-value"
            class="search-input-content"
            :placeholder="$t('common.inputKeywordToSearch')"
            v-model="tableFilter.searchFieldValue"
            @clear="handleSearchClear"
            @keyup.native="handleSearchInput"
            clearable
          >
            <i
              slot="suffix"
              class="el-icon-search"
              @click="handleSearchInput"
            />
          </el-input>
        </div>
        <el-button
          v-if="remoteSoftwareEntitlement.value"
          v-permission="['AUTHRSC_ACTION_DEPLOYMENT_CREATE']"
          class="new-deploy-button"
          id="newDeploy"
          type="plain"
          @click="addnewDeploy"
        >
          <img class="add-icon" src="@/icons/svg/addRelease.svg" />
          {{ $t('deployManagementModule.deployments.newDeployment') }}
        </el-button>
      </div>

      <pure-table
        :tableList="data"
        :total="pageTotal"
        :cols="cols"
        :viewPagePath="'details'"
        @handle-page="handlePage"
        @handle-sort-change="handleSortChange"
        :showTableHeaderOptions="false"
      />
    </div>
  </div>
</template>

<style lang="scss" scoped>
:deep(.release-table) {
  :deep(.el-table__body colgroup col) {
    width: 300px;
  }
}

.new-deploy-button {
  margin: auto 0 auto auto;
}

.add-icon {
  margin: auto 15px auto auto;
}

.filter-container {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
}

.filter-relese {
  margin: 0;
}

.search-input-content {
  width: 400px;
}

.table-container {
  width: 100%;
  padding: 16px;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 3px 6px #c1c1c1;
  display: flex;
  flex-direction: column;
}

.search-select-container {
  :deep(.el-input__inner) {
    height: 40px;
    border: 1px solid #818181;
  }

  .search-select-field :deep(.el-input__inner) {
    width: 252px;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    border-right: transparent !important;
  }

  .search-select-field {
    margin: 0;
  }

  .search-input-content :deep(.el-input__inner) {
    width: 270px;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
  }

  .search-input-content :deep(.el-input__suffix) {
    line-height: 40px;
    color: rgba(0, 0, 0, 0.6);
    font-size: 18px;
    cursor: pointer;
    width: 282px;
  }
}
</style>
