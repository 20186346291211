<script lang="ts">
import {
  createSubscriptionPackage,
  deleteSubscriptionPackageById,
  FetchSystemFeatureResponse,
  getSubscriptionPackageById,
  getSubscriptionPackages,
  getSystemFeatures,
  SubscriptionPackage,
  updateSubscriptionPackageById,
} from '@/api/subscriptionPackages';
import { PageModule } from '@/store/modules/page';
import {
  customFailedMessage,
  promptFailedBox,
  promptSuccessBox,
} from '@/utils/prompt';
import {
  APPLICABILITY_TYPE,
  FEATURE_VALUE_TYPE,
  PACKAGE_TYPE,
  valueTypesToDefaults,
} from '@/utils/workData/lookuptable';
import Features from '@/views/subscriptionPackages/components/Features.vue';
import General from '@/views/subscriptionPackages/components/General.vue';
import { Component, Vue } from 'vue-property-decorator';
import CreateSubscriptionCard from './components/CreateSubscriptionCard.vue';

export interface SystemFeatureValue extends FetchSystemFeatureResponse {
  value?: string;
  unit?: string;
  isRequired?: boolean;
}

@Component({
  name: 'CreatePackage',
  components: {
    'new-card': CreateSubscriptionCard,
    general: General,
    features: Features,
  },
})
export default class extends Vue {
  /** Local variables */
  activeName: string = '';
  allSubscriptions: SubscriptionPackage[] = [];
  generalTabIsLoading: boolean = false;
  featuresData: FetchSystemFeatureResponse[] = [];
  processedFeaturesData?: SystemFeatureValue[];
  generalEditData: any = {};
  source: string = 'new';
  isCardVisible: boolean = false;
  isEditable: boolean = true;
  formErrors: boolean = false;
  moduleTitle: string = '';
  generalData: any = {
    packageName: '',
    packageType: '',
    companyType: '',
    assetType: '',
    note: '',
  };
  fieldErrors: any = {
    packageName: '',
    packageType: '',
    companyType: '',
    assetType: '',
    note: '',
  };
  featureValueType = FEATURE_VALUE_TYPE;
  timeDurationFieldHasErrors: any = [];

  created() {
    if (this.$route.params.packageId) {
      // check if loaded route has packageId set => edit mode and display current package data
      this.source = 'edit';
      this.isEditable = false;
      this.getPackageById(this.$route.params.packageId);
      return;
    }

    this.getAllSubscriptionPackages();
  }

  /**
   * Get a specific subscription package by route packageId
   * @param packageId
   */
  async getPackageById(packageId: string) {
    this.generalTabIsLoading = true;
    await getSubscriptionPackageById(packageId).then((res: any) => {
      if (res.code && res.code === 200) {
        PageModule.setTitle(res.data.name);
        this.generalEditData = {
          id: res.data.id,
          packageName: res.data.name,
          packageType: res.data.type,
          assetType: res.data.assetType,
          companyType: res.data.companyType,
          note: res.data.note,
        };

        this.generalData = this.generalEditData;
        this.processedFeaturesData = res.data.systemFeatures;

        this.source = 'edit';
        this.activeName = 'general';
        this.isCardVisible = true;
        this.generalTabIsLoading = false;
        return;
      }

      this.generalTabIsLoading = false;
      customFailedMessage(
        this.$t('subscriptionPackages.errorWithFetchingData').toString()
      );
    });
  }

  /**
   * Get all subscription packages for further processing of unique name on the new creation
   */
  async getAllSubscriptionPackages() {
    this.generalTabIsLoading = true;
    try {
      const subscriptionPackageResponse = await getSubscriptionPackages();
      this.allSubscriptions = subscriptionPackageResponse.subscriptionPackages;

      this.source = 'new';
      this.activeName = 'general';
      this.isCardVisible = true;
      this.generalTabIsLoading = false;
    } catch {
      customFailedMessage(
        this.$t('subscriptionPackages.errorWithFetchingData').toString()
      );
    }
  }

  /**
   * Get features specific to a package type
   * @param packageType
   */
  async getFeatures(packageType: string) {
    try {
      const systemFeatures = await getSystemFeatures();

      this.featuresData = systemFeatures;
      this.processedFeaturesData = systemFeatures
        .map(
          (item): SystemFeatureValue => ({
            ...item,
            value: valueTypesToDefaults[item.valueType] ?? '',
            // Basically all the fields are required, but for those that we have default value set
            // will always have a value. We have some fields where there are no defaults, but validation is done on the backend
            // for them, so we have to showcase on the frontend that they are indeed required.
            isRequired: valueTypesToDefaults[item.valueType] === undefined,
          })
        )
        .filter((item) => {
          if (packageType === PACKAGE_TYPE.AssetType)
            return item.applicabilityType === APPLICABILITY_TYPE.FatAssetType;
          if (packageType === PACKAGE_TYPE.CompanyType)
            return item.applicabilityType === APPLICABILITY_TYPE.FatCompanyType;
        });
    } catch {
      customFailedMessage(
        this.$t('subscriptionPackages.errorWithFetchingData').toString()
      );
    }
  }

  /**
   * Create new package remotely
   */
  async handleCreateNewPackage() {
    this.validateFields();

    if (
      !this.generalData.packageType &&
      this.processedFeaturesData?.length === 0
    ) {
      customFailedMessage(
        this.$t('subscriptionPackages.atLeastOneFeatureRequired').toString()
      );
      return;
    }

    if (this.formErrors) {
      customFailedMessage(
        this.$t('subscriptionPackages.completeRequiredFields').toString()
      );
      return;
    }

    let payload = this.prepareBackendPayload('post');

    if (payload.systemFeatures.length === 0) {
      customFailedMessage(
        this.$t(
          'subscriptionPackages.oneFeatureShouldHaveValidValue'
        ).toString()
      );
      return;
    }

    if (this.timeDurationFieldHasErrors.some((item: any) => item)) {
      customFailedMessage(
        this.$t('subscriptionPackages.timeDurationIncorrectFormat').toString()
      );
      this.timeDurationFieldHasErrors = [];
      return;
    }

    await createSubscriptionPackage(payload).then((res: any) => {
      if (res.code && (res.code === 201 || res.code === 200)) {
        this.$router.push('/subscription-packages/index');
        promptSuccessBox(
          this.$t('subscriptionPackages.packageCreated').toString()
        );
        return;
      }

      if (res.data.errors[0].code === 'ApiErrorFieldDuplicate') {
        this.fieldErrors.packageName = this.$t(
          'subscriptionPackages.nameMustBeUnique'
        );
        customFailedMessage(
          this.$t('subscriptionPackages.packageNameDuplicate').toString()
        );
        return;
      }

      if (
        res.data.errors[0].code === 'ApiErrorFieldMaxLength' &&
        res.data.errors[0].field === 'ApiFieldName'
      ) {
        this.fieldErrors.packageName = this.$t(
          'subscriptionPackages.packageNameTooLong'
        );
        customFailedMessage(
          this.$t('subscriptionPackages.packageNameTooLong').toString()
        );
        return;
      }

      if (
        res.data.errors[0].code === 'ApiErrorFieldMaxLength' &&
        res.data.errors[0].field === 'ApiFieldNote'
      ) {
        this.fieldErrors.note = this.$t(
          'subscriptionPackages.packageNoteTooLong'
        );
        customFailedMessage(
          this.$t('subscriptionPackages.packageNoteTooLong').toString()
        );
        return;
      }

      promptFailedBox(
        this.$t('subscriptionPackages.packageCreation').toString()
      );
    });
  }

  validateFields() {
    let hasErrors = [];
    let excludedKeys: any = ['note'];

    if (
      !this.generalData.packageType &&
      this.processedFeaturesData?.length === 0
    ) {
      this.formErrors = true;
    }

    if (this.generalData.packageType === PACKAGE_TYPE.CompanyType) {
      excludedKeys.push('assetType');
    } else {
      excludedKeys.push('companyType');
    }

    for (const [key, value] of Object.entries(this.generalData)) {
      if (value === '' && !excludedKeys.includes(key.toString())) {
        hasErrors.push(true);
        this.fieldErrors[key] =
          this.$t('subscriptionPackages.fieldIsRequired') + '.';
      } else {
        this.fieldErrors[key] = '';
      }
    }

    if (hasErrors.includes(true)) {
      this.formErrors = true;
      return;
    }

    this.formErrors = false;
  }

  /**
   * Update an existing package remotely
   */
  async handleUpdate() {
    this.validateFields();
    if (this.formErrors) {
      customFailedMessage(
        this.$t('subscriptionPackages.completeRequiredFields').toString()
      );
      return;
    }

    let payload = this.prepareBackendPayload('put');

    if (payload.systemFeatures.length === 0) {
      customFailedMessage(
        this.$t(
          'subscriptionPackages.oneFeatureShouldHaveValidValue'
        ).toString()
      );
      return;
    }

    if (this.timeDurationFieldHasErrors.some((item: any) => item)) {
      customFailedMessage(
        this.$t('subscriptionPackages.timeDurationIncorrectFormat').toString()
      );
      this.timeDurationFieldHasErrors = [];
      return;
    }

    await updateSubscriptionPackageById(
      this.$route.params.packageId,
      payload
    ).then((res: any) => {
      if (res.code && (res.code === 201 || res.code === 200)) {
        this.$router.push('/subscription-packages/index');
        promptSuccessBox(
          this.$t('subscriptionPackages.packageUpdated').toString()
        );
        return;
      }

      if (res.data.errors[0].code === 'ApiErrorFieldDuplicate') {
        this.fieldErrors.packageName = this.$t(
          'subscriptionPackages.nameMustBeUnique'
        );
        customFailedMessage(
          this.$t('subscriptionPackages.packageNameDuplicate').toString()
        );
        return;
      }

      if (
        res.data.errors[0].code === 'ApiErrorFieldMaxLength' &&
        res.data.errors[0].field === 'ApiFieldName'
      ) {
        this.fieldErrors.packageName = this.$t(
          'subscriptionPackages.packageNameTooLong'
        );
        customFailedMessage(
          this.$t('subscriptionPackages.packageNameTooLong').toString()
        );
        return;
      }

      if (
        res.data.errors[0].code === 'ApiErrorFieldMaxLength' &&
        res.data.errors[0].field === 'ApiFieldNote'
      ) {
        this.fieldErrors.note = this.$t(
          'subscriptionPackages.packageNoteTooLong'
        );
        customFailedMessage(
          this.$t('subscriptionPackages.packageNoteTooLong').toString()
        );
        return;
      }

      promptFailedBox(
        this.$t('subscriptionPackages.packageUpdateFail').toString()
      );
    });
  }

  /**
   * Prepare payload structure for BE requests POST & PUT
   */
  prepareBackendPayload(type: string) {
    let finalFeatures: any = [];
    this.timeDurationFieldHasErrors = [];
    this.processedFeaturesData?.forEach((item) => {
      finalFeatures.push({
        id: item.id,
        value: item.value ? item.value.trim() : '',
        unit:
          item.valueType === this.featureValueType.FvtDuration
            ? item.unit ?? null
            : undefined,
      });

      if (
        this.featureValueType.FvtDuration === item.valueType &&
        ((!item.value && item.unit) || (item.value && !item.unit))
      ) {
        this.timeDurationFieldHasErrors.push(true);
      } else {
        this.timeDurationFieldHasErrors.push(false);
      }
    });

    let payload: any = {
      name: this.generalData.packageName,
      systemFeatures: finalFeatures,
      note: this.generalData.note,
    };

    if (type === 'post') {
      payload.type = this.generalData.packageType;
    }

    if (this.generalData.companyType) {
      payload.companyType = this.generalData.companyType;
    } else {
      payload.assetType = this.generalData.assetType;
    }

    return payload;
  }

  /**
   * Event triggered when package type is changed -> bring the coresponding features by package type
   */
  handlePackageTypeFeatures(packageType: string) {
    this.getFeatures(packageType);
  }

  /**
   * Triggered from child when updating
   */
  handleGeneralDataUpdate(data: any) {
    if (this.isEditable) this.validateFields();
    this.generalData = data;
  }

  /**
   * Delete subscription package when edit mode
   */
  async handleDeletePackage() {
    const result = await deleteSubscriptionPackageById(
      this.$route.params.packageId
    );
    if (result.code === 400) {
      customFailedMessage(`${result.data.errors[0].message}.`);

      return;
    }

    this.$router.push('/subscription-packages/index');
    promptSuccessBox(this.$t('subscriptionPackages.packageDeleted').toString());
  }
}
</script>

<template>
  <new-card
    id="createnewsubscriptionpackagebody"
    :title="
      source === 'edit'
        ? generalEditData.packageName
        : $t('subscriptionPackages.createNewPackageTitle')
    "
    v-if="isCardVisible"
    :createdOrEdit="false"
    :source="source"
    :isEditable="isEditable"
    @create-new-package="handleCreateNewPackage"
    @is-editable-event="isEditable = !isEditable"
    @handle-update-event="handleUpdate"
    @handle-delete-package="handleDeletePackage"
  >
    <el-tabs
      id="subscribtionpackagestabs"
      class="tab-container"
      v-model="activeName"
      style="padding: 0 20px"
    >
      <el-tab-pane
        id="generaltab"
        name="general"
        :label="$t('subscriptionPackages.general')"
      >
        <general
          v-loading="generalTabIsLoading"
          :editData="generalEditData"
          :source="source"
          :isEditable="isEditable"
          :generalData="generalData"
          :fieldErrors="fieldErrors"
          @general-data-update="handleGeneralDataUpdate"
          @handle-package-type-features="handlePackageTypeFeatures"
        />
      </el-tab-pane>
      <el-tab-pane
        id="featurestab"
        name="features"
        :label="$t('subscriptionPackages.features')"
      >
        <features
          :featuresData="processedFeaturesData"
          :isEditable="isEditable"
        />
      </el-tab-pane>
    </el-tabs>
  </new-card>
</template>

<style scoped>
.tab-container :deep(.el-tabs__header) {
  margin: 0 0 8px !important;
}
</style>
