<script lang="ts">
import { Role } from '@/api/roles';
import { AuthorizableResource } from '@/api/users';
import NewCard from '@/components/cusCard/NewCard.vue';
import GeneralInfo from '@/components/form/GeneralInfo.vue';
import ModuleAccess from '@/components/form/ModuleAccess.vue';
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
  name: 'ViewRoleInfo',
  components: {
    'new-card': NewCard,
    'module-access': ModuleAccess,
    'general-info': GeneralInfo,
  },
})
export default class extends Vue {
  @Prop({ required: true }) roleForm!: Role;
  @Prop() authorizableResources: AuthorizableResource[] = [];
  @Prop() defaultChecked: string[] = [];
}
</script>

<template>
  <div class="content">
    <general-info
      :name="'userRoleModule.userRoleName'"
      :value="roleForm?.name"
    />
    <general-info
      :name="'userRoleModule.customerType'"
      :value="roleForm?.companyType"
    />
    <general-info :name="'userRoleModule.note'" :value="roleForm?.note" />
    <general-info :name="'userRoleModule.moduleAccess'" class="module-access">
      <module-access
        id="add_new_role_module_access"
        ref="moduleAccess"
        :authorizableResources="authorizableResources"
        :defaultProps="{ children: 'children', label: 'label' }"
        :defaultChecked="defaultChecked"
        :defaultExpand="false"
        :isEdit="false"
      >
      </module-access>
    </general-info>
  </div>
</template>

<style lang="scss" scoped>
:deep(.module-access .form-title) {
  align-self: start;
}
.content {
  padding-bottom: 20px;
}
</style>
