import { LoginStatus, useLoginStatus } from '@/auth/user';
import { haveMainInstance, runInMainContextAsync } from '@/mainInstance';
import { until } from '@vueuse/core';
import NProgress from 'nprogress';
import 'nprogress/nprogress.css';
import { nextTick } from 'vue';
import { Route } from 'vue-router';
import router from '.';

NProgress.configure({ showSpinner: false });

router.beforeEach(async (to: Route, _from: Route, next: any) => {
  NProgress.start();

  if (!haveMainInstance()) {
    // Vue Router 3.x starts navigating even before the app is mounted,
    // so we don't have main instance available yet.
    // In that case, we need to wait until the app is mounted, which is
    // done after one tick.
    await nextTick();
  }

  // Obtain current login status. If log in is in-progress, delay
  // navigation until it is completed.
  await runInMainContextAsync(async () => {
    const loginStatusRef = useLoginStatus();
    if (loginStatusRef.value !== LoginStatus.Pending) {
      return loginStatusRef.value;
    }
    return until(loginStatusRef).changed();
  });

  // Note: regardless of login status afterwards (e.g. Error),
  // let the navigation status complete to the original path.
  // This ensures the URL stays at what the user originally requested
  // to see, such that e.g. in case of service unavailability,
  // they can just refresh the page to get back to where they
  // wanted to be.

  next();
});

router.afterEach((to: Route) => {
  NProgress.done();
});
