<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
  name: 'Modal',
})
export default class extends Vue {
  @Prop({ required: false }) visible!: boolean;
  @Prop({ required: true }) title!: string;
  @Prop({ default: false }) showLoginBtn!: boolean;
  @Prop({ default: true }) showDeactivateBtn!: boolean;
  @Prop({ default: true }) isApplyStatus!: boolean;
  @Prop({ default: false }) dialogIsLoading?: boolean;

  closeDialog() {
    this.$emit('handle-cancel');
  }

  handleDialogCancel() {
    this.$emit('handle-cancel');
  }

  handleApply() {
    this.$emit('handle-apply');
  }

  handleDeprecate() {
    this.$emit('handle-deprecate');
  }
}
</script>

<template>
  <el-dialog
    :visible="visible"
    class="new-cust-dialog"
    :width="'650px'"
    :top="'25vh'"
    :title="$t(title)"
    @close="closeDialog"
  >
    <div
      class="d-flex ai-center"
      style="flex-direction: column"
      v-loading="dialogIsLoading"
    >
      <slot></slot>

      <div style="margin: 10px 0px">
        <el-button
          class="deactivate-btn"
          style="margin-right: 30px"
          type="plain"
          @click="handleDialogCancel"
          >{{ $t('customerModule.cancel') }}</el-button
        >
        <el-button
          v-if="isApplyStatus"
          class="deactivate-btn"
          type="info"
          @click="handleApply"
          >{{ $t('common.apply') }}</el-button
        >
        <el-button
          v-if="!isApplyStatus"
          class="deactivate-btn"
          type="info"
          @click="handleDeprecate"
          >{{ $t('common.deprecate') }}</el-button
        >
      </div>
    </div>
  </el-dialog>
</template>

<style scoped>
.el-dialog {
  border-radius: 10px;
}

.new-cust-dialog :deep(.el-dialog__title) {
  font-size: 20px;
  font-family: var(--fontRobotoMedium);
  line-height: 19px;
  color: #373e41;
}

.new-cust-dialog :deep(.el-dialog__headerbtn) {
  top: 10px;
  font-size: 30px;
  color: #373d41;
  position: static;
}
</style>

<style lang="scss" scoped>
.new-cust-dialog :deep(.el-dialog__header) {
  padding: 0px;
  padding-bottom: 0px;
  background-color: var(--Main) !important;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 20px;
  padding-right: 16px;
  height: 44px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.el-button--plain:hover {
  color: #ffffff;
  border-color: #5f6567;
  background-color: #5f6567;
}

.new-cust-dialog :deep(.el-dialog__headerbtn:hover .el-dialog__close) {
  color: #5f6567;
}

.deactivate-btn {
  width: 160px;
  margin-right: 30px;
  font-family: var(--fontRobotoMedium);
  font-size: 16px !important;
  border: 1px solid #373e41;
}

.new-cust-dialog :deep(.el-dialog) {
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
}

.new-cust-dialog :deep(.el-dialog__body) {
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
}
</style>
