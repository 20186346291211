<script lang="ts">
import KpiNumberExpanded from '@/components/widget/kpiNumber/KpiNumberExpanded.vue';
import KpiNumberMultiExpanded, {
  MultiKpiGrowthUnitValue,
} from '@/components/widget/kpiNumber/KpiNumberMultiExpanded.vue';
import { Component, InjectReactive, Prop, Vue } from 'vue-property-decorator';
import KpiTable from './KpiTable.vue';

@Component({
  name: 'KpiInfoExpanded',
  components: {
    KpiNumberExpanded,
    KpiNumberMultiExpanded,
    KpiTable,
  },
})
export default class extends Vue {
  @Prop({ required: true }) kpiNumberList!: MultiKpiGrowthUnitValue<
    number | undefined
  >[];
  @Prop({ default: false }) multiKpi!: boolean;

  @InjectReactive('tableList') tableList!: any;
  @InjectReactive('cols') cols!: any;

  created() {}
}
</script>

<template>
  <div>
    <div v-if="!multiKpi">
      <KpiNumberExpanded v-for="item in kpiNumberList" :kpi="item" />
    </div>
    <div v-if="multiKpi">
      <KpiNumberMultiExpanded v-for="item in kpiNumberList" :kpi="item" />
    </div>

    <KpiTable
      v-on="$listeners"
      :cols="cols"
      v-show="tableList.length > 0"
      :tableList="tableList"
    ></KpiTable>
  </div>
</template>

<style lang="scss" scoped></style>
