import { ActiveContext } from '@/auth/context';
import { QueryParameter } from '@/model/queryParameters/QueryParameter';
import { getRequest } from '@/utils/request';
import {
  GENERAL_QUERY_OPERATORS,
  OccurrenceTrend,
} from '@/utils/workData/lookuptable';
import { CommonResultPromise } from './commonResult';

export interface Filter {
  name: string;
  operator: GENERAL_QUERY_OPERATORS;
  value: string | string[];
}

export type EventSummaryResponse = {
  numberOfTotalTrips: number;
  details: {
    eventTypeCode: string;
    numberOfEvents: number;
    numberOfTrips: number;
    numberOfEventsToday: number;
    occurrenceTrend: OccurrenceTrend;
  }[];
};

export function getEventSummary(
  queryParameter: QueryParameter,
  context?: ActiveContext
): CommonResultPromise<EventSummaryResponse> {
  const request = getRequest();
  const config = context
    ? {
        params: {
          selectedCustomer: context?.impersonatedCompanyId,
        },
      }
    : {};
  const response = request.post<EventSummaryResponse>(
    '/safety-events/summary',
    queryParameter,
    config
  );
  return response;
}
