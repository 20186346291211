<script lang="ts" setup>
import { UserModule } from '@/store/modules/user';
import { computed, unref, watch, watchEffect } from 'vue';
import { updateUser } from './api/users';
import { ContextType } from './auth/contextType';
import { useSelectedCustomerInfo } from './auth/selectedCustomer';
import { useUserConsentState } from './auth/termsAndConditions';
import { TokenInfo, useLogout, useTokenInfo } from './auth/token';
import {
  LoggedInUser,
  LoginStatus,
  useLoggedInUser,
  useLoginStatus,
} from './auth/user';
import { useMergedRouteMeta, useRoute, useRouter } from './composables/router';
import i18n from './lang';
import { resetRouter } from './router';
import settings from './settings';
import { PageModule } from './store/modules/page';
import { generateRoutes, PermissionModule } from './store/modules/permission';
import { switchTheme } from './utils';
import { setApiToken } from './utils/api';
import { setRequestToken } from './utils/request';
import {
  ALL_CLAIMS_CODES,
  COMPANY_TYPE,
  LANGUAGE,
  THEME,
  THEME_LIST,
} from './utils/workData/lookuptable';
import ServiceUnavailable from './views/errorPage/ServiceUnavailable.vue';
import Loading from './views/login/Loading.vue';
import NoPermissions from './views/login/NoPermissions.vue';
import TermsAndConditionsDialog from './views/login/TermsAndConditionsDialog.vue';
import UserNotFound from './views/login/UserNotFound.vue';

const tokenInfo = useTokenInfo();
const loggedInUser = useLoggedInUser();
const router = useRouter();
const logout = useLogout();

// Install API token in Axios
watchEffect(
  () => {
    const token = unref(tokenInfo)?.bearerToken;
    setRequestToken(token);
    setApiToken(token);
  },
  {
    // Update the token in request as soon as it becomes available, as
    // other watchers will start making calls to requests, needing this
    // token.
    flush: 'sync',
  }
);

// Keep legacy UserModule in sync with new loggedInUser concept
watch(loggedInUser, (user) => UserModule.setUserInfo(user), {
  immediate: true,
});

// Handle automatic syncing of emailVerified flag from KeyCloak to BE.
// TODO: This should probably better be handled by a direct call from KeyCloak to VAS.
const stopEmailVerifier = watch(
  (): [TokenInfo | undefined, LoggedInUser | undefined] => [
    unref(tokenInfo),
    unref(loggedInUser),
  ],
  ([token, user]) => {
    if (
      token?.emailVerified === undefined ||
      user?.emailVerified === undefined
    ) {
      return;
    }
    // Keycloak has better insight into the user's email status, so
    // when it indicates the mail has been verified, update BE accordingly.
    if (token.emailVerified && !user.emailVerified) {
      stopEmailVerifier();
      console.log("Updating user's emailVerified status to true");
      updateUser(user.id, {
        email: user.email,
        emailVerified: true,
      });
    }
  },
  { immediate: true }
);

// Change interface language based on user's preference
watch(
  () => loggedInUser.value?.settings.i18nCode ?? LANGUAGE.EN_US,
  (localeCode, prevLocaleCode) => {
    if (localeCode === prevLocaleCode) {
      return;
    }
    i18n.locale = localeCode;
    document.documentElement.lang = localeCode;
  },
  { immediate: true }
);

// Change application theme based on user's setting
watch(
  () => loggedInUser.value?.theme ?? THEME.theme1,
  (themeId, prevThemeId) => {
    if (themeId === prevThemeId) {
      return;
    }
    const theme = THEME_LIST.find((theme) => theme.id === themeId);
    if (theme) {
      switchTheme('body-theme-' + theme.key, theme.colorVlue, theme.hoverColor);
    }
  },
  { immediate: true }
);

// Update routes and redirect if needed
watch(
  loggedInUser,
  (user, prevUser) => {
    // User info is explicitly unset -> apparently a logout
    if (!user && prevUser) {
      console.log('LoggedInUser unset, forcing reload...');

      // Unregister any existing routes, to make sure they're no longer available.
      resetRouter(); // Ugly workaround for Vue Router v3. In v4, we should record the callbacks from addRoute() and call these.

      // Forget all stored state about the user, such as selected customer/organization
      // TODO: this seems a bit blunt, might be better to have individual keys register themselves
      // to be cleared on logout.
      sessionStorage.clear();

      // Just in case, we navigate to loggedOut, although in practice we shouldn't
      // ever get there, as the reload should redirect to keycloak immediately.
      router.push({ name: 'loggedOut' });
      location.reload();
      return;
    }

    if (!user) {
      // Nothing to be done, no user info yet.
      return;
    }

    // User info is available, compute routes

    // Compute accessible routes based on modules and pages enabled for this user
    const routeConfigs = generateRoutes(user);
    resetRouter(); // Ugly workaround for Vue Router v3. In v4, we should record the callbacks from addRoute() and call these.
    routeConfigs.forEach((routeConfig) => router.addRoute(routeConfig));
    PermissionModule.SET_ROUTES(routeConfigs);

    // Routes have been changed, force navigation again to reconsider these.
    // Note: (probably) due to the workaround of `resetRouter()`, router.currentRoute
    // is still '/' at this point, even though new routes have been installed and
    // `location.href` does still point to the correct original URL.
    const fullPath = location.hash.slice(1); // strip '#' from hash
    router.replace(fullPath);

    performance.mark('routesUpdated');
    performance.measure(
      'determineRoutes',
      'keycloakInitialized',
      'routesUpdated'
    );
    console.debug('Routes updated');
  },
  { immediate: true }
);

// Switch back to home when selecting a different company,
// as the user may not have access there
// TODO Move this to a router navigation guard on the component?
const { selectedCustomer } = useSelectedCustomerInfo();
const mergedMeta = useMergedRouteMeta();
watch(
  selectedCustomer,
  (newSelection, oldSelection) => {
    if (
      newSelection &&
      newSelection !== oldSelection &&
      // Without this condition, after a new customer is selected the app would reroute
      // an Operational Support user back to the homepage (in this case to Customer Management)
      loggedInUser.value?.companyType !== COMPANY_TYPE.Hyva
    ) {
      // When the selected customer is changed ONLY redirect the user to the Homepage
      // when they are on a page on which they can change the selected customer.
      //
      // We can change the customer "under the hood" and in that case redirection to the Homepage is not desirable.
      if (
        mergedMeta.value.context === ContextType.SelectedCustomer &&
        router.currentRoute.name != 'Home'
      ) {
        router.push({ name: 'Home' });
      }
    }
  },
  { immediate: true }
);

function getPageTitle(key: string) {
  const hasKey = i18n.te(key);
  if (hasKey) {
    const pageName = i18n.t(key);
    return `${pageName} - ${settings.title}`;
  }
  return settings.title;
}

const route = useRoute();

watchEffect(() => {
  // Making sure that the title changes when the user changes its preferred language
  const _unreffedi18nCode = unref(loggedInUser)?.settings.i18nCode;
  const titleKey = route.value.meta?.title;

  if (titleKey) {
    document.title = getPageTitle(titleKey);
    PageModule.setTitle(i18n.te(titleKey) ? i18n.t(titleKey) : []);
  }
});

const loginStatus = useLoginStatus();

/**
 * True when user can be found, but is not properly configured yet,
 * e.g. doesn't have a role assigned, and nothing to initially redirect to.
 */
const noPermissions = computed(
  () =>
    loginStatus.value == LoginStatus.LoggedIn &&
    !loggedInUser.value?.claims.hasClaim(ALL_CLAIMS_CODES.AUTHRSC_MOD_HOME) &&
    !loggedInUser.value?.claims.hasClaim(ALL_CLAIMS_CODES.AUTHRSC_PAGE_HOME)
);

const consentState = useUserConsentState();
</script>

<template>
  <div class="app" id="app">
    <ServiceUnavailable
      v-if="
        loginStatus === LoginStatus.Error ||
        consentState.userConsents.value.error
      "
    />
    <Loading
      v-else-if="
        loginStatus === LoginStatus.Pending ||
        consentState.userConsents.value.loading
      "
    />
    <UserNotFound v-else-if="loginStatus === LoginStatus.NotFound" />
    <NoPermissions v-else-if="noPermissions" />
    <TermsAndConditionsDialog
      v-else-if="consentState.needsConsent.value"
      @cancel="logout()"
      @agree="consentState.agree"
      :userConsents="consentState.userConsents.value.data"
    />
    <router-view v-else-if="consentState.hasConsented.value" />
  </div>
</template>

<style lang="scss" scoped>
.app {
  display: flex;
  flex-direction: column;
}
</style>
