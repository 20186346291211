<script lang="ts">
import {
  activateSystemRelease,
  getCustomers,
  productSystemRelease,
  productSystemReleasesByID,
  SystemRelease,
} from '@/api/systemReleaseManagement';
import TreeTransfer from '@/components/dialog/TreeTransfer.vue';
import WidgetDialog from '@/components/dialog/WidgetDialog.vue';
import { customFailedMessage } from '@/utils/prompt';
import {
  getPartFirmwareInformation,
  getProductSystemInformation,
  getTreeData,
  mapFirmwareReleaseData,
} from '@/utils/releaseInformationUtil';
import { GENERAL_QUERY_OPERATORS } from '@/utils/workData/lookuptable';
import { Component, Vue, Watch } from 'vue-property-decorator';
import ContentContainer from './components/ListContent.vue';
import ContentTable from './components/TableContent.vue';

@Component({
  name: 'NewRelease',
  components: {
    'content-list': ContentContainer,
    'content-table': ContentTable,
    'tree-transfer': TreeTransfer,
    'confirm-dialog': WidgetDialog,
    'set-as-default': WidgetDialog,
  },
})
export default class extends Vue {
  treeContentData: { selectedData: Array<string>; tree: any } = {
    tree: {},
    selectedData: [],
  };
  setAsDefaultDialogVisible: boolean = false;
  customers: any = null;
  treeTransferVisible: boolean = false;
  productSystemReleaseID: string = '';
  productSystemReleasesIDs = [];
  partFirmwareInformation = [[]];
  productSystemReleaaseInformation: {
    title: string;
    content: any[];
    rawContent: any;
  } = {
    title: 'releaseDetails.productSystemReleaseInfo',
    content: [],
    rawContent: {},
  };
  checked: boolean = false;
  dialogVisible: boolean = false;
  id: string = '';
  defaultRelease: SystemRelease = {
    id: '',
    assetType: '',
    defaultRelease: true,
    deploymentDurationMinutes: 0,
    manifestCreationTime: '',
    maturityLevel: '',
    numberOfLinkedCustomers: 0,
    numberOfLinkedOrganizations: 0,
    productModelCode: '',
    productModelNumber: '',
    region: '',
    releaseStatus: '',
    systemReleaseId: '',
    systemReleaseName: '',
    systemReleaseVersion: '',
  };
  productID: string = '';
  createNewReleaseData: any = {
    productSystemReleaseId: '',
    productSystemReleaseName: '',
    productSystemReleaseVersion: '',
    assetType: '',
    productModel: '',
    modelNumber: '',
    deploymentDuration: 0,
    releaseStatus: '',
    defaultRelease: true,
    noOfCustomers: 0,
    systemReleaseNote: '',
    linkedOrganizations: [
      {
        customerId: '',
        customerName: '',
        organizations: [],
        numberOfAssets: 0,
      },
    ],
    partFirmwareInformation: [
      {
        partFirmwareName: '',
        containedPartName: '',
        partFirmwareVersion: '',
        partFirmwareReleaseNote: '',
      },
    ],
    createdTime: '',
  };

  customerListLoading: boolean = false;

  async created() {
    await this.getData();
  }

  async getData() {
    try {
      const response = await productSystemRelease({
        filters: [
          {
            name: 'releaseStatus',
            operator: GENERAL_QUERY_OPERATORS.Equal,
            value: ['RELSTAT_NEW'], // only new releases can be activated
          },
        ],
        //TODO remove pagination when backe-end accepts request body with no pagination
        pagination: {
          page: '1',
          size: '1000',
        },
      });
      this.productSystemReleasesIDs = response.data.systemReleases;
    } catch (err) {
      console.log(err);
    }
  }

  @Watch('productSystemReleaseID')
  async handleIdChange() {
    if (!this.productSystemReleaseID) {
      return;
    }
    this.customerListLoading = true;
    try {
      this.customers = (
        await getCustomers({
          filters: [
            {
              name: 'systemReleaseUuid',
              operator: GENERAL_QUERY_OPERATORS.Equal,
              value: [this.productSystemReleaseID],
            },
            {
              name: 'linkedCustomers',
              operator: GENERAL_QUERY_OPERATORS.Equal,
              value: [false],
            },
          ],
          pagination: {
            page: '1',
            size: '100',
          },
        })
      ).data.customers;
      this.customerListLoading = false;
    } catch (e) {
      console.error(e);
      customFailedMessage(this.$t('common.errorWithFetchingData') as string);
    }
    let treeData = getTreeData(this.customers);
    this.treeContentData = {
      tree: treeData,
      selectedData: [],
    };
  }

  handleSetAsDefault(isSetAsDefault: boolean) {
    this.productID =
      this.productSystemReleaaseInformation.rawContent['systemReleaseId'];
    this.setAsDefaultDialogVisible = isSetAsDefault;
  }

  editLinkedCustomer() {
    this.treeTransferVisible = true;
  }

  async handleProductSystemReleaseSelected(id: string) {
    this.productSystemReleaaseInformation.content = [];
    this.productSystemReleaaseInformation.rawContent = {};
    this.partFirmwareInformation = [[]];
    this.id = id;
    try {
      const response = await productSystemReleasesByID(id);
      this.checked = response.data.defaultRelease == true;
      if (!this.checked) {
        const defaultReleaseResponse = await productSystemRelease({
          filters: [
            {
              name: 'productModelCode',
              operator: GENERAL_QUERY_OPERATORS.Equal,
              value: [response.data.productModelCode],
            },
            {
              name: 'productModelNumber',
              operator: GENERAL_QUERY_OPERATORS.Equal,
              value: [response.data.productModelNumber],
            },
            {
              name: 'defaultRelease',
              operator: GENERAL_QUERY_OPERATORS.Equal,
              value: [true],
            },
          ],
          //TODO remove pagination when backe-end accepts request body with no pagination
          pagination: {
            page: '1',
            size: '1000',
          },
        });
        this.defaultRelease = !!defaultReleaseResponse.data.systemReleases
          .length
          ? defaultReleaseResponse.data.systemReleases[0]
          : null;
      } else {
        this.defaultRelease = response.data;
      }

      mapFirmwareReleaseData(response.data);
      this.productSystemReleaaseInformation.content =
        getProductSystemInformation(response.data);
      this.productSystemReleaaseInformation.rawContent = response.data;
      this.partFirmwareInformation = getPartFirmwareInformation(
        response.data.partFirmwareRelease
      );
    } catch (err) {
      console.log(err);
    }
  }

  async createRelease() {
    if (!this.productSystemReleaseID) return;
    const { data } = await activateSystemRelease(this.productSystemReleaseID, {
      defaultRelease: this.checked,
      ...(!this.checked && {
        linkedOrganizations: this.constructLinkedCustomers(),
      }),
    });
    if (data && !data.errors) {
      this.$router.go(-1);
      return;
    }
    console.error(data?.errors);
    customFailedMessage(this.$t('common.serverError') as string);
  }

  handleDialogConfirmSetDefault() {
    this.handleCancel(false);
  }

  constructLinkedCustomers() {
    let linekdCustomers = new Array<any>();
    for (let i = 0; i < this.customers.length; i++) {
      let customer = this.customers[i];

      let organizations = this.getLinkedOrganizations(customer);
      if (
        !this.treeContentData.selectedData.includes(customer.customerId) &&
        organizations.length == 0
      )
        continue;

      linekdCustomers.push(...organizations);
    }
    return linekdCustomers;
  }

  getLinkedOrganizations(parentOrg: any): any[] {
    let linkedOrgs = [];
    if (this.treeContentData.selectedData.includes(parentOrg.id))
      linkedOrgs.push(parentOrg.id);

    let children = parentOrg.children || parentOrg.organizations;

    if (children)
      for (let i = 0; i < children.length; i++) {
        linkedOrgs.push(...this.getLinkedOrganizations(children[i]));
      }
    return linkedOrgs;
  }

  handleDialogConfirm() {
    this.$router.push(`/release/index`);
  }

  handleBack() {
    this.dialogVisible = true;
  }

  handleConfirm() {
    this.treeTransferVisible = false;
  }

  handleCancel(setAsDefault: boolean = true) {
    if (this.treeTransferVisible) this.treeTransferVisible = false;
    if (this.dialogVisible) this.dialogVisible = false;
    if (this.setAsDefaultDialogVisible) {
      setAsDefault && (this.checked = false);
      this.setAsDefaultDialogVisible = false;
    }
  }
}
</script>

<template>
  <div class="page-container">
    <div class="page-title-container">
      <div class="back-button-container">
        <el-button class="back" @click="handleBack">
          <img class="back-icon" src="@/icons/svg/back.svg" />
        </el-button>
        <span class="page-content-title">{{ $t('newRelease.title') }}</span>
      </div>
      <el-button
        class="new-release-button"
        id="newRelease"
        type="plain"
        @click="createRelease"
      >
        {{ $t('releaseDetails.createNew') }}
      </el-button>
    </div>
    <div class="container">
      <div class="left-content">
        <h2 class="title">
          {{ $t('releaseDetails.productSystemReleaseInfo') }}
        </h2>
        <div class="product-system-releaseID">
          <p class="lable">
            {{ $t(`releaseDetails.productSystemRelease.systemReleaseId`)
            }}<span class="mandatory">*</span>:
          </p>
          <el-select
            class="search-select-field"
            id="releaseFilter"
            v-model="productSystemReleaseID"
            v-bind:placeholder="
              $t('releaseManagementModule.productSystemReleaseId')
            "
            @change="handleProductSystemReleaseSelected"
            filterable
          >
            <el-option
              v-for="item in productSystemReleasesIDs"
              :key="
                /* @ts-expect-error TODO Wrong type */
                item.systemReleaseId
              "
              :label="
                /* @ts-expect-error TODO Wrong type */
                item.systemReleaseId
              "
              :value="
                /* @ts-expect-error TODO Wrong type */
                item.id
              "
            />
          </el-select>
        </div>
        <content-list
          class="product-release-info"
          :content="productSystemReleaaseInformation.content"
          :showReleaseNotes="!!productSystemReleaseID"
          :productSystemReleaseID="productSystemReleaseID"
        />
        <el-checkbox
          class="set-as-default"
          v-model="checked"
          @change="handleSetAsDefault"
          :disabled="!id"
        >
          {{ $t('releaseDetails.setAsDefault') }}
        </el-checkbox>
        <div class="lined-customer-header">
          <h2 class="title">{{ $t('releaseDetails.linkedCustomerTitle') }}</h2>
          <el-button
            class="new-release-button"
            id="edit"
            v-permission="['AUTHRSC_ACTION_RELEASE_LINK']"
            :disabled="customerListLoading || checked"
            type="plain"
            @click="editLinkedCustomer"
            v-if="productSystemReleaseID"
          >
            <img class="edit-icon" src="@/icons/svg/editRelease.svg" />
            {{ $t('assetMgmt.edit') }}
          </el-button>
        </div>
        <content-table
          :titleName="$t('subsMgmt.customer')"
          :titleValue="$t('subsMgmt.numberOfAssets')"
          :content="treeContentData.tree"
          :filterIds="treeContentData.selectedData"
        />
      </div>
      <div class="right-content">
        <h2 class="title">{{ $t('newRelease.partFirmwareInfo') }}</h2>
        <content-list
          v-for="info in partFirmwareInformation"
          :key="
            /* @ts-expect-error TODO Wrong type */
            info.containedPartName
          "
          :showReleaseNotes="false"
          :productSystemReleaseID="productSystemReleaseID"
          :content="info"
          :title="
            (
              info.find(
                /* @ts-expect-error TODO Wrong type */
                (i) => i.id === 'productSystemRelease.containedPartName'
              ) || {}
            ) /* @ts-expect-error TODO Wrong type */.value
          "
        />
      </div>
    </div>
    <tree-transfer
      :title="$t('releaseDetails.linkedCustomerTitle')"
      :visible="treeTransferVisible"
      :confirmBtnName="$t('common.apply')"
      :listTitles="[
        $t('linkedCustomers.customersAvailable'),
        $t('linkedCustomers.customersLinked'),
      ]"
      :data="treeContentData"
      v-on:handle-confirm="handleConfirm"
      v-on:handle-cancel="handleCancel"
    >
    </tree-transfer>
    <confirm-dialog
      :visible="dialogVisible"
      :title="$t('newRelease.dialogTitle')"
      :confirmBtnName="$t('newRelease.dialogQuit')"
      :width="'600px'"
      @handle-cancel="handleCancel"
      @handle-confirm="handleDialogConfirm"
    >
      <a class="dialog-content">{{ $t('newRelease.dialogContent') }}</a>
    </confirm-dialog>
    <set-as-default
      :visible="setAsDefaultDialogVisible"
      :title="$t('newRelease.dialogSetAsDefault')"
      :confirmBtnName="$t('userSetting.confirm')"
      :width="'600px'"
      @handle-cancel="handleCancel"
      @handle-confirm="handleDialogConfirmSetDefault"
    >
      <a class="dialog-content">
        {{
          !defaultRelease ||
          defaultRelease.systemReleaseId === productSystemReleaseID
            ? $t('newRelease.dialogContentSetAsDefaultNoDefaultRelease')
            : $t('newRelease.dialogContentSetAsDefaultExistingDefaultRelease', {
                defaultReleaseName: defaultRelease.systemReleaseId,
              })
        }}
      </a>
    </set-as-default>
  </div>
</template>

<style lang="scss" scoped>
:deep(.el-dialog__body) {
  padding: 20px 25px;
}

:deep(.el-dialog__footer) {
  padding: 0 64px 48px 64px;

  .dialog-footer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .widget-dialog-btn {
      width: 206px;
    }
  }
}

:deep(.widget-dialog-btn) {
  margin: 0;
}

.dialog-content {
  height: 700px;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 23px;
  text-align: center;
  word-break: break-word;
}

.back-icon {
  height: 16px;
}

.back {
  padding: 0;
  margin: 0;
  border: none;
}

.mandatory {
  color: #ff0000;
}

.lable {
  width: 264px;
  color: #373e41;
  text-transform: capitalize;
  font-weight: 400;
  font-family: Roboto;
  opacity: 0.6;
}

.product-system-releaseID {
  display: flex;
  flex-direction: row;
  gap: 30px;
  border: 1px solid #707070;
  border-bottom: none;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  padding: 20px 18px 12px 18px;
}

.product-release-info :deep(.container) {
  border-top: none;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.new-release-button {
  padding: 11px 16px;
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  text-align: right;
}

.edit-icon {
  height: 18px;
  width: 16px;
  margin-right: 12px;
}

.lined-customer-header {
  display: flex;
  justify-content: space-between;
  margin: 0 0 10px 0;
  height: 42px;
}

.page-container {
  background: #ffffff;
  padding: 16px;
  border-radius: 8px;
  box-shadow: 0 1px 4px rgb(0 21 41 / 8%);
}

.page-title-container {
  display: flex;
  flex-direction: row;
  border-bottom: 2px solid #dddddd;
  margin: 0 0 20px 0;
  padding: 16px 0;
  justify-content: space-between;

  .back-button-container {
    margin: auto auto auto 0;
  }
}

.container {
  display: flex;
  gap: 58px;
}

.left-content {
  width: 50%;
}

.right-content {
  width: 50%;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.title {
  margin: 20px 0;
  color: #373e41;
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 21px;
}

.set-as-default {
  margin: 24px 0;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 21px;
}
</style>
