<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';

import { ChangedReport } from '@/api/reportSubscription';
import WidgetDialog from '@/components/dialog/WidgetDialog.vue';
import CusFormItem from '@/components/form/CusFormItem.vue';
import { KpisDataPayload } from '@/utils/kpidata';
import { LANGUAGE, ReportFormat } from '@/utils/workData/lookuptable';

import { substractDaysFromData } from '../../helpers/substractDaysFromData';
import { composeCsvReport } from './helpers/composeCsvReport';
import { downloadFile } from './helpers/downloadReport';
@Component({
  name: 'ExportReportModal',
  components: {
    CusFormItem,
    WidgetDialog,
  },
})
export default class extends Vue {
  @Prop({ default: false }) visible!: boolean;
  @Prop({ required: true }) title!: string;
  @Prop({ required: true }) fileName!: string;
  @Prop({ required: true }) data!: KpisDataPayload;
  @Prop({ required: true }) startDate!: string;
  @Prop({ required: true }) endDate!: string;

  reportFormatList: ReportFormat[] = [];
  reportFormValues: ChangedReport = { name: this.fileName } as ChangedReport;

  created() {
    this.initializeDefaultSettings();
  }

  async initializeDefaultSettings() {
    // TODO: Add back this after Excel report generation was added to the backend for this report

    // const res = await getReportTemplates();
    // if (res.code === 200) {
    //   this.reportFormatList = res.data;
    // }

    this.reportFormatList = [ReportFormat.CSV];
  }

  addSimpleRow(list: string[], text: string) {
    list.push(text);
    list.push('\n');
  }

  getEnTranslation(text: string) {
    return this.$t(text, LANGUAGE.EN_US);
  }

  async exportReport() {
    (this.$refs.refSubcribeReportForm as any).validate((valid: any) => {
      if (!valid) {
        return;
      }

      const csv = composeCsvReport({
        startDate: this.startDate,
        endDate: substractDaysFromData({ dateToBeSubstracted: this.endDate }),
        data: this.data,
      });

      downloadFile(csv.join(''), this.reportFormValues.name, ReportFormat.CSV);
      this.close();
    });
  }

  /** In case of close modal, the visible attribute from parent is set to false */
  close(): void {
    this.$emit('update:visible', false);
  }

  get rules() {
    const tmpRules = {
      name: [
        {
          required: true,
          message: this.$t('report.tipInputReportName'),
          pattern: /^\S+/,
          trigger: 'change',
        },
        { validator: this.validateName, trigger: 'change' },
      ],
      reportFormat: [
        {
          required: true,
          message: this.$t('report.pleaseSelectAReportFormat'),
          pattern: /^\S+/,
          trigger: 'change',
        },
        { validator: this.validateName, trigger: 'change' },
      ],
    };

    return tmpRules;
  }

  validateName = (_rule: any, value: any, callback: any) => {
    if (value.length < 1 || value.length > 100) {
      callback(
        new Error(`${this.$t('common.inputValidationOneToOneHundred')}`)
      );
    } else {
      callback();
    }
  };
}
</script>

<template>
  <div>
    <WidgetDialog
      :visible.sync="visible"
      :confirmBtnName="$t('common.export')"
      :title="$t('report.exportReport')"
      :width="'800px'"
      @handle-cancel="close"
      @handle-confirm="exportReport"
    >
      <el-form
        style="margin: 32px 0px 40px 104px"
        ref="refSubcribeReportForm"
        :model="reportFormValues"
        :rules="rules"
        :inline-message="false"
      >
        <div>
          <CusFormItem :title="'report.reportAs'">
            <el-form-item prop="name">
              <el-input
                v-model="reportFormValues.name"
                :placeholder="$t('report.inputReportName')"
              />
            </el-form-item>
          </CusFormItem>

          <CusFormItem :title="'report.subscribeReportFormat'">
            <el-form-item prop="reportFormat">
              <el-select
                v-model="reportFormValues.reportFormat"
                :placeholder="$t('report.subscribeReportFormat')"
              >
                <el-option
                  v-for="(item, index) in reportFormatList"
                  :key="index"
                  :label="$t(item)"
                  :value="item"
                />
              </el-select>
            </el-form-item>
          </CusFormItem>
        </div>
      </el-form>
    </WidgetDialog>
  </div>
</template>
