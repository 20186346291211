<script lang="ts">
import { Component, Watch, Prop, Vue } from 'vue-property-decorator';
import { MAINTENANCE_STATUS } from '@/utils/workData/lookuptable';
import {
  MaintenanceAppointment,
  MaintenanceRuleProgress,
} from '@/api/maintenance';
import { formatTimer } from '@/utils/misc';
import { UserModule } from '@/store/modules/user';
import i18n from '@/lang';

@Component({
  name: 'MaintenancePlanListTable',
})
export default class extends Vue {
  @Prop() tableList!: MaintenanceAppointment[];
  @Prop() total!: number;
  @Prop() cols!: [];
  @Prop() treeProp!: any;
  @Prop() path!: string;

  list: MaintenanceAppointment[] = [...this.tableList];
  dragCols: any = [...this.cols];
  colChange: any = [];
  maintenanceStatuses: any = MAINTENANCE_STATUS;
  progressRuleNaturalDay: string = 'MKPI.NaturalDays';
  unitDay: string =
    UserModule.i18nCode === 'zh-CN' ? i18n.tc('common.day') : 'd';

  @Watch('tableList')
  onListChange(value: any) {
    this.list = [...this.tableList];
  }

  /**
   * Set index as parameter to row HTMLelement for table
   * @param row
   * @param index
   */
  tableRowClassName(row: any, index: any) {
    row.index = index;
  }

  get activeCols() {
    this.colChange = this.cols.filter((item: any) => {
      return item.visible;
    });

    this.dragCols = [...this.colChange];
    return [...this.colChange];
  }

  handleRowClick(row: MaintenanceAppointment) {
    this.$emit('appointment-clicked', row);
  }

  /**
   * Get rules progress as concatenated string by row obj
   * e.g: 10d/20d
   * @param appointment
   */
  getRulesProgressThresholdValues(appointment: MaintenanceAppointment): string {
    return (
      Number(
        appointment.rulesProgress.find(
          (rule: MaintenanceRuleProgress) =>
            rule.kpiCode === this.progressRuleNaturalDay
        )?.thresholdValue
      ) - Number(appointment.dominantValueRepresentedDays)
    )
      .toString()
      .concat(this.unitDay);
  }

  /**
   * Make sure that natural days is always on the first place in the rules progress array
   * @param appointment
   */
  handleNaturalDaysToBeFirstInRulesProgress(
    appointment: MaintenanceAppointment
  ): MaintenanceAppointment {
    if (
      appointment.rulesProgress.length > 0 &&
      appointment.rulesProgress[0]?.kpiCode === this.progressRuleNaturalDay
    ) {
      return appointment;
    }

    const naturalDaysIndex = appointment.rulesProgress.findIndex(
      (app: MaintenanceRuleProgress) =>
        app.kpiCode === this.progressRuleNaturalDay
    );

    if (!naturalDaysIndex) return appointment;

    appointment.rulesProgress.splice(
      0,
      0,
      appointment.rulesProgress[naturalDaysIndex]
    );
    appointment.rulesProgress.splice(naturalDaysIndex, 1);

    return appointment;
  }

  /**
   * Get progress bar text value by row obj
   * e.g.: 10d / 20d
   * When dominantValueRepresentedDays > natural days threshold, the value should be returned negative with "-"
   *  e.g.: -40d / 20d
   * @param appointment
   */
  getProgressBarTextValue(appointment: MaintenanceAppointment): string {
    const naturalDayThresholdValue =
      this.getNaturalDaysThresholdValue(appointment)?.toString();

    let representedDayValue: string = Number(
      appointment.dominantValueRepresentedDays
    ).toString();

    return representedDayValue
      .concat(this.unitDay)
      .concat(' / ')
      .concat(naturalDayThresholdValue.toString())
      .concat(this.unitDay);
  }

  /**
   * Return natural day threshold value for each row as received appointment obj
   * @param appointment
   */
  getNaturalDaysThresholdValue(appointment: MaintenanceAppointment): number {
    return Number(
      appointment.rulesProgress.find(
        (rule: MaintenanceRuleProgress) =>
          rule.kpiCode === this.progressRuleNaturalDay
      )?.thresholdValue
    );
  }

  /**
   * Get progress bar value
   * Range between 0 - 100
   * @param appointment
   */
  getProgressBarValue(appointment: MaintenanceAppointment): number {
    const dominantDayValue = Number(appointment.dominantValueRepresentedDays);
    const naturalDayThresholdValue =
      this.getNaturalDaysThresholdValue(appointment);

    if (dominantDayValue > naturalDayThresholdValue) return 100;

    return (
      (Number(appointment.dominantValueRepresentedDays) /
        naturalDayThresholdValue) *
      100
    );
  }

  formatColumnDateValue(date: string, pattern: string) {
    return formatTimer(date, pattern);
  }
}
</script>

<template>
  <div>
    <el-table
      class="maintenace-plan-list-table"
      :data="list"
      row-key="id"
      :row-style="{ height: '25px' }"
      :cell-style="{ padding: '7px 0px' }"
      @row-click="handleRowClick"
      ref="sortTable"
      :tree-props="treeProp"
      :row-class-name="tableRowClassName"
    >
      <el-table-column
        v-for="(col, index) in activeCols"
        :key="`col_${index}`"
        :prop="dragCols[index].prop"
        min-width="160px"
        show-overflow-tooltip
        :width="dragCols[index].prop === 'maintenanceMonitor' ? 350 : 180"
      >
        <template v-slot:header>
          <div class="d-flex jc-between">
            <span style="white-space: nowrap">
              {{ $t(activeCols[index]?.label) }}
            </span>
          </div>
        </template>

        <template v-slot="slotProps" v-if="col.prop === 'assetType'">
          {{ $t(list[slotProps.$index].assetType) }}
        </template>

        <!-- format date info -->
        <template
          v-slot="slotProps"
          v-else-if="col.dateFormat === 'date' || col.dateFormat === 'datetime'"
        >
          {{
            slotProps.row[col.prop]
              ? formatColumnDateValue(slotProps.row[col.prop], col.dateFormat)
              : '-'
          }}
        </template>

        <template
          v-slot="slotProps"
          v-else-if="
            col.prop === 'status' ||
            col.prop === 'maintenanceMonitor' ||
            col.prop === 'name'
          "
        >
          <div v-if="col.prop === 'status'">
            <div
              v-if="
                list[slotProps.$index].status === maintenanceStatuses.MainSOk
              "
              class="status-ok"
            >
              {{ $t(list[slotProps.$index].status) }}
            </div>
            <div
              v-if="
                list[slotProps.$index].status === maintenanceStatuses.MainSDue
              "
              class="status-due"
            >
              {{ $t(list[slotProps.$index].status) }}
            </div>
            <div
              v-if="
                list[slotProps.$index].status === maintenanceStatuses.MainSClose
              "
              class="status-close"
            >
              {{ $t(list[slotProps.$index].status) }}
            </div>
          </div>

          <div
            class="maintenance-monitor-progress-bar-container"
            v-if="col.prop === 'maintenanceMonitor'"
          >
            <div class="progress-bar-wrapper">
              <div
                :class="[
                  list[slotProps.$index].status === maintenanceStatuses.MainSOk
                    ? 'progress-bar-ok-value'
                    : list[slotProps.$index].status ===
                      maintenanceStatuses.MainSDue
                    ? 'progress-bar-due-value'
                    : 'progress-bar-close-value',
                ]"
                :style="{
                  width: getProgressBarValue(list[slotProps.$index]) + '%',
                }"
              >
                <div class="progress-text-value">
                  {{ getProgressBarTextValue(list[slotProps.$index]) }}
                </div>
              </div>
            </div>
            <div class="maintenance-monitor-progress-rules-value">
              {{ getRulesProgressThresholdValues(list[slotProps.$index]) }}
            </div>
          </div>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<style scoped>
.el-table tbody tr:hover > td {
  background-color: var(--Main) !important;
}

.el-table td {
  border-bottom: 1px solid #dddddd !important;
}

.el-table {
  font-size: 14px !important;
  font-family: var(--fontRobotoRegular);
  line-height: 16px;
  color: #373e41 !important;
  opacity: 1;
}

.el-table thead {
  font-size: 14px !important;
  font-family: var(--fontRobotoMedium);
  line-height: 16px;
  color: #373e41 !important;
  opacity: 1;
}
</style>

<style lang="scss" scoped>
.dm-font {
  font-size: 14px;
  font-family: $font-Roboto-Regular;
  line-height: 16px;
  color: #363636;
}

.status-ok {
  text-transform: uppercase;
  font: $font-Roboto-Medium;
  text-align: center;
  background-color: #0e918c;
  border-radius: 20px;
  font-size: 12px;
  width: 83px;
  color: white;
}

.status-due {
  text-transform: uppercase;
  text-align: center;
  font: $font-Roboto-Medium;
  background-color: #be442d;
  border-radius: 20px;
  font-size: 12px;
  width: 83px;
  color: white;
}

.status-close {
  text-transform: uppercase;
  text-align: center;
  font: $font-Roboto-Medium;
  background-color: #f79b3f;
  border-radius: 20px;
  font-size: 12px;
  width: 83px;
  color: white;
}

/** Maintenance monitor column characteristics */

.progress-bar-wrapper {
  background-color: #c2c2c2;
  width: 230px;
  border-radius: 5px;
}

.maintenance-monitor-progress-bar-container {
  display: flex;
}

.progress-text-value {
  color: white;
}

.progress-bar-ok-value {
  border-radius: 5px;
  background-color: $status-ok-color;
  padding-left: 10px;
}

.progress-bar-close-value {
  border-radius: 5px;
  background-color: $status-close-color;
  padding-left: 10px;
}

.progress-bar-due-value {
  border-radius: 5px;
  background-color: $status-due-color;
  padding-left: 10px;
}

.maintenance-monitor-progress-rules-value {
  margin-left: 4px;
}
</style>
