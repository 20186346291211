<script setup lang="ts">
import { inject } from 'vue';
import SmallNewFleetSafety from './SmallNewFleetSafety.vue';
import ExpandedNewFleetSafety from './ExpandedNewFleetSafety.vue';

const isInExpandedMode = inject('expanded') ?? false;
</script>

<template>
  <ExpandedNewFleetSafety v-if="isInExpandedMode" />
  <SmallNewFleetSafety v-else />
</template>
