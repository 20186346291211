/**
 * Convenience method that returns true if the value is not undefined
 * (i.e. defined). Useful to pass to `Array.filter()`.
 */
export function isDefined<T>(value: T | undefined | null): value is T {
  return value !== undefined && value !== null;
}

/**
 * Union of all key names that are candidates for recursing into
 * the same structure.
 *
 * For example, `interface Foo { a: number, children: Foo[]; }` would
 * yield `ChildKeys<Foo> -> 'children'`
 */
export type ChildKeys<T> = {
  [K in keyof T]: T[K] extends Array<T> | undefined ? K : never;
}[keyof T];

/**
 * Recursively iterate over all objects and return a single array with
 * the results of the mapper.
 *
 * Note: if the mapper returns e.g. `Foo[]`, the result will likewise be
 * `Foo[][]`. Use `flatMapDeep()` if you expected a `Foo[]`.
 */
export function mapDeep<T extends object, K extends ChildKeys<T>, R>(
  value: T,
  childrenKey: K,
  mapper: (element: T) => R
): R[] {
  const children = (value[childrenKey] ?? []) as unknown as T[];
  return [
    mapper(value),
    ...children.flatMap((child) => mapDeep(child, childrenKey, mapper)),
  ];
}

/**
 * Recursively iterate over all objects and return a single array with
 * the flattened results of the mapper.
 */
export function flatMapDeep<T extends object, K extends ChildKeys<T>, R>(
  value: T,
  childrenKey: K,
  mapper: (element: T) => R[]
): R[] {
  return mapDeep(value, childrenKey, mapper).flat();
}

/**
 * Filter recursive structure by calling a mapper function on each element,
 * keeping the same structure, but e.g. changing properties.
 *
 * Iteration is depth-first (bottom-up), so the mapper can check whether
 * any children have e.g. already been filtered out so far.
 */
export function mapTree<T extends object, K extends ChildKeys<T>>(
  value: T,
  childrenKey: K,
  mapper: (value: T) => T | undefined
): T | undefined {
  if (childrenKey in value && value[childrenKey]) {
    const newChildren = mapTrees(
      value[childrenKey] as unknown as T[],
      childrenKey,
      mapper
    );
    value = {
      ...value,
      [childrenKey]: newChildren,
    };
  }
  return mapper(value);
}

/**
 * Filter recursive structure by calling a mapper function on each element,
 * keeping the same structure, but e.g. changing properties.
 *
 * Iteration is depth-first (bottom-up), so the mapper can check whether
 * any children have e.g. already been filtered out so far.
 */
export function mapTrees<T extends object, K extends ChildKeys<T>>(
  tree: T[],
  childrenKey: K,
  mapper: (value: T) => T | undefined
): T[] {
  return tree
    .map((element) => mapTree(element, childrenKey, mapper))
    .filter(isDefined);
}

/**
 * Filter recursive structure by calling a filter function on each element,
 * filtering it out whenever it returns a falsy value.
 *
 * Iteration is depth-first (bottom-up), so the filterer can check whether
 * any children have already been filtered out so far.
 */
export function filterTree<T extends object, K extends ChildKeys<T>>(
  tree: T[],
  childrenKey: K,
  filterer: (value: T) => boolean
): T[] {
  return mapTrees(tree, childrenKey, (element) =>
    filterer(element) ? element : undefined
  );
}

/**
 * Key-value tuples of an object, as used by `objectEntries()` and `objectFromEntries()`.
 */
export type Entries<T> = {
  [K in keyof T]: [K, T[K]];
}[keyof T][];

/**
 * Typed version of Object.entries().
 * See https://github.com/microsoft/TypeScript/pull/12253#issuecomment-263132208 for why it's not the default.
 */
export function objectEntries<T extends object>(x: T): Entries<T> {
  return Object.entries(x) as Entries<T>;
}

/**
 * Typed version of Object.keys().
 * See https://github.com/microsoft/TypeScript/pull/12253#issuecomment-263132208 for why it's not the default.
 */
export function objectKeys<T extends object>(o: T): Array<keyof T> {
  return Object.keys(o) as Array<keyof T>;
}

/**
 * Typed version of Object.fromEntries().
 * See https://github.com/microsoft/TypeScript/pull/12253#issuecomment-263132208 for why it's not the default.
 */
export function objectFromEntries<
  T extends Entries<Record<PropertyKey, unknown>>
>(entries: T): { [K in T[number] as K[0]]: K[1] } {
  return Object.fromEntries(entries) as { [K in T[number] as K[0]]: K[1] };
}

/**
 * Key-value tuple of an object, as used by `objectEntries()` and `objectFromEntries()`.
 */
export type Entry<T> = {
  [K in keyof T]: [K, T[K]];
}[keyof T];

/**
 * Map one object (record) into another.
 *
 * Both keys and values of the resulting object can be changed.
 */
export function mapObjectEntries<T extends object, K extends PropertyKey, V>(
  object: T,
  mapper: (entry: Entry<T>) => [K, V]
): { [KK in K]: V } {
  return objectFromEntries(objectEntries(object).map(mapper) as any) as any;
}

/**
 * Map one object (record) into another.
 *
 * Only values in the resulting object can be changed.
 */
export function mapObjectValues<T extends object, V>(
  object: T,
  mapper: (value: Entry<T>[1], key: Entry<T>[0]) => V
): { [KK in keyof T]: V } {
  return mapObjectEntries(object, ([key, value]): [keyof T, V] => [
    key,
    mapper(value, key),
  ]);
}

/**
 * Object properties (and Record entries) do not have a guaranteed order.
 *
 * This function allows changing a key while keeping the order. `oldKey` will be swapped for `newKey`.
 */
export function changeKeyWhileKeepingOrder<T extends Record<string, any>>(
  record: T,
  oldKey: keyof T,
  newKey: keyof T
): T {
  if (!(oldKey in record)) {
    throw new Error(`Key ${String(oldKey)} does not exist in the record.`);
  }

  return mapObjectEntries(record, ([key, value]) =>
    key === oldKey ? [newKey, value] : [key, value]
  ) as T;
}
