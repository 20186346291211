import { FilterOperator } from '@/model/queryParameters/QueryParameter';

export const SUBSCRIPTION_MANAGEMENT_COLS = [
  {
    label: 'subsMgmt.daysToRenew',
    prop: 'daysToRenew',
    required: true,
    visible: true,
    multiLang: true,
    sortable: false,
  },
  {
    label: 'subsMgmt.customerAssetId',
    prop: 'companyAssetId',
    required: true,
    visible: true,
    sortable: true,
  },
  {
    label: 'subsMgmt.customer',
    prop: 'companyName',
    required: true,
    visible: true,
    multiLang: true,
    sortable: true,
  },
  {
    label: 'subsMgmt.organization',
    prop: 'organizationName',
    required: true,
    visible: true,
    multiLang: true,
    sortable: true,
  },
  {
    label: 'subsMgmt.subscriptionStatus',
    prop: 'translatedSubscriptionStatus',
    alias: 'subscriptionStatus',
    required: true,
    visible: true,
    multiLang: true,
    sortable: false,
  },
  {
    label: 'subsMgmt.startDate',
    prop: 'subscriptionStartDate',
    required: true,
    visible: true,
    multiLang: true,
    sortable: true,
    dateFormat: 'date',
  },
  {
    label: 'subsMgmt.endDate',
    prop: 'subscriptionEndDate',
    required: true,
    visible: true,
    multiLang: true,
    sortable: true,
    dateFormat: 'date',
  },
  {
    label: 'subsMgmt.customerSubscriptionPackage',
    prop: 'customerSubscriptionPackage',
    required: true,
    visible: true,
    multiLang: true,
    sortable: true,
    width: 300,
  },
  {
    label: 'subsMgmt.orderId',
    prop: 'orderId',
    required: false,
    visible: false,
    multiLang: true,
    sortable: true,
  },
  {
    label: 'subsMgmt.cadId',
    prop: 'cadId',
    required: false,
    visible: false,
    multiLang: true,
    sortable: true,
  },
  {
    label: 'subsMgmt.renewedTime',
    prop: 'renewedTimes',
    required: false,
    visible: false,
    multiLang: true,
    sortable: false,
  },
  {
    label: 'subsMgmt.assetSubscriptionPackage',
    prop: 'translatedAssetType',
    alias: 'assetType',
    required: false,
    visible: false,
    multiLang: true,
    sortable: false,
  },
  {
    label: 'subsMgmt.gatewayId',
    prop: 'gatewayId',
    required: false,
    visible: false,
    sortable: false,
  },
  {
    label: 'subsMgmt.productModel',
    prop: 'productModelCode',
    required: false,
    visible: false,
    sortable: false,
  },
  {
    label: 'subsMgmt.productModelNumber',
    prop: 'productModelNumber',
    required: false,
    visible: false,
    sortable: false,
  },
  {
    label: 'subsMgmt.lastCommunicationTime',
    prop: 'lastCommunicationTime',
    required: false,
    visible: false,
    dateFormat: 'datetime',
    sortable: false,
  },
  {
    label: 'subsMgmt.installTime',
    prop: 'installTime',
    required: false,
    visible: false,
    dateFormat: 'datetime',
    sortable: false,
  },
  {
    label: 'subsMgmt.controllerId',
    prop: 'controllerId',
    required: false,
    visible: false,
    sortable: false,
  },
  {
    label: 'subsMgmt.assetDataCollectionId',
    prop: 'assetDataCollectionId',
    required: false,
    visible: false,
    sortable: false,
  },
  {
    label: 'subsMgmt.assetSerialNumber',
    prop: 'serialNumber',
    required: false,
    visible: false,
    sortable: false,
  },
  {
    label: 'common.serviceRegion',
    prop: 'translatedServiceRegion',
    alias: 'serviceRegion',
    required: false,
    visible: false,
    sortable: false,
  },
  {
    label: 'common.hyvaRegion',
    prop: 'translatedHyvaRegion',
    alias: 'hyvaRegion',
    required: false,
    visible: false,
    sortable: false,
  },
  {
    label: 'subsMgmt.manufactureDate',
    prop: 'manufactureDate',
    required: false,
    visible: false,
    dateFormat: 'date',
    sortable: false,
  },
  {
    label: 'subsMgmt.actionColumn',
    prop: 'action',
    required: true,
    visible: true,
    width: 400,
  },
];

export const SUBSCRIPTION_FILTER_OPTIONS = [
  {
    label: 'subsMgmt.subscriptionStatus',
    prop: 'subscriptionStatus',
    operator: FilterOperator.EQUAL,
  },
  {
    label: 'subsMgmt.customer',
    prop: 'companyName',
    operator: FilterOperator.LIKE,
  },
  {
    label: 'subsMgmt.customerPackage',
    prop: 'customerSubscriptionPackage',
    operator: FilterOperator.LIKE,
  },
] as const;
