<script lang="ts" setup>
import WidgetLayout from '@/components/layout/widget/WidgetLayout.vue';
</script>

<template>
  <WidgetLayout
    pageCode="AUTHRSC_PAGE_HOME"
    :columns="4"
    row-height="12.5rem"
    :hide-configurable="true"
  />
</template>
