var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('div',{staticClass:"big-calendar-container"},_vm._l((_vm.scheduledAppointments),function(item,index){return _c('div',{key:index},[_c('div',{staticClass:"big-calendar-day",class:[item.isSelected ? 'selected-day' : '']},[_c('div',{staticClass:"day-title"},[_vm._v(_vm._s(_setup.formatDayHeader(item.day)))]),_vm._l((item.appointments),function(appointment,appointmentIndex){return _c('div',{key:appointmentIndex,staticClass:"items-container",class:[
            appointment.status === 'MAINS_OK'
              ? 'appointment-ok'
              : appointment.status === 'MAINS_CLOSE'
              ? 'appointment-close'
              : appointment.status === _setup.performedMaintenanceStatus
              ? 'appointment-performed'
              : 'appointment-due',
          ],attrs:{"title":appointment.status === _setup.performedMaintenanceStatus
              ? _vm.$tc('maintenance.maintenancePlanner.alreadyConducted')
              : ''},on:{"click":function($event){return _setup.handleAppointmentClickEvent(appointment)}}},[_c('div',{staticClass:"item-first-line"},[_vm._v(_vm._s(appointment.companyAssetId))]),_c('div',{staticClass:"item-second-line"},[_vm._v(" "+_vm._s(appointment.maintenanceItemName)+" ")])])})],2)])}),0)])
}
var staticRenderFns = []

export { render, staticRenderFns }