<script lang="ts">
// @ts-nocheck Too many errors in this file, especially when building using Vue CLI

import { AssetType } from '@/utils/assetTypes';
import { Input, Popover, Tree } from 'element-ui';

export default {
  name: 'SelectTree',
  props: {
    //  Receive binding parameters
    value: String,
    //  Input box width
    width: String,
    //  Option data
    options: {
      type: Array,
      required: true,
    },
    historyOptions: {
      type: Array,
      required: true,
    },
    //  Input box placeholder
    placeholder: {
      type: String,
      required: false,
      default: 'please choose',
    },
    //  Tree node configuration options
    props: {
      type: Object,
      required: false,
      default: () => ({
        parent: 'parentId',
        value: 'rowGuid',
        label: 'areaName',
        children: 'children',
      }),
    },
  },
  //  Set binding parameters
  model: {
    prop: 'value',
    event: 'selected',
  },
  computed: {
    //  Whether it is tree structure data
    dataType() {
      const jsonStr = JSON.stringify(this.options);
      return jsonStr.indexOf(this.props.children) !== -1;
    },
    //  If it is not a tree structure, it will be converted to tree structure data
    data() {
      return this.dataType
        ? this.labelModel.length >= 3
          ? this.options
          : this.historyOptions
        : this.switchTree();
    },
  },
  watch: {
    labelModel(val) {
      if (!this.autofill) {
        this.onShowPopover(); // show popover when user types
      } else {
        this.autofill = false;
      }
      if (!val) {
        this.valueModel = '';
      }
      this.$nextTick(() => {
        // need to wait for the :data v-bind to update since computed runs first
        (this.$refs.tree as Tree | null)?.filter(val);
      });
    },
    value(val) {
      this.labelModel = this.queryTree(this.data, val);
    },
  },
  data() {
    return {
      //  Tree menu display status
      showStatus: false,
      //  Menu width
      treeWidth: 'auto',
      //  Input box display value
      labelModel: '',
      //  Actual request value
      valueModel: '0',
      autofill: false,
    };
  },
  created() {
    //  Detect the original value of the input box and display the corresponding label
    if (this.value) {
      this.labelModel = this.queryTree(this.data, this.value);
    }
    //  Get the width of the input box and synchronize to the width of the tree menu
    this.$nextTick(() => {
      this.treeWidth = `${
        /* @ts-expect-error TODO Unknown type */
        (this.width || (this.$refs.input as Input | null)?.clientWidth) - 24
      }px`;
    });
  },
  methods: {
    //Navigate to
    /* @ts-expect-error TODO Unknown type */
    navigateToAsset(data) {
      if (data.id) {
        let assetType = null;
        if (data.assetType === AssetType.MobileCompactor)
          assetType = 'mobile-compactors';
        if (data.assetType === AssetType.StaticCompactor)
          assetType = 'static-compactors';
        if (data.assetType === AssetType.TippingVehicle)
          assetType = 'tipping-vehicles';
        if (assetType == null) return;

        this.$emit('addToHistorySearch', data.label);

        let assetPath = `/assets/${assetType}/${data.id}`;
        this.$router.push(assetPath);

        this.autofill = true;
        this.onHidePopover();
        /* @ts-expect-error TODO Wrong type */
        (this.$refs.popover as Popover | null)?.doClose();
      }
    },

    //  Click node
    /* @ts-expect-error TODO Wrong type */
    onClickNode(node) {
      this.labelModel = node[this.props.label];
      /* @ts-expect-error TODO Wrong type */
      this.filterNode(this.label, this.options);
    },
    //  The flat array is transformed into a tree-like hierarchy
    switchTree() {
      return this.cleanChildren(this.buildTree(this.options, '0'));
    },
    //  Hide tree menu
    onCloseTree() {
      /* @ts-expect-error TODO Wrong type */
      (this.$refs.popover as Popover | null)?.showPopper = false;
    },
    //  Trigger on display
    onShowPopover() {
      this.showStatus = true;
      (this.$refs.tree as Tree | null)?.filter(this.labelModel);
    },
    //  Trigger when hidden
    onHidePopover() {
      this.showStatus = false;
      /* @ts-expect-error TODO Wrong type */
      this.$emit('selected', this.label);
    },
    //  Tree node filtering method
    /* @ts-expect-error TODO Unknown type */
    filterNode(query, data) {
      if (!query) return true;
      return (
        data[this.props.label].toUpperCase().indexOf(query.toUpperCase()) !== -1
      );
    },
    //  Search the ID in the tree data
    /* @ts-expect-error TODO Unknown type */
    queryTree(tree, id) {
      /* @ts-expect-error TODO Unknown type */
      let stark = [];
      /* @ts-expect-error TODO Unknown type */
      stark = stark.concat(tree);
      while (stark.length) {
        /* @ts-expect-error TODO Unknown type */
        const temp = stark.shift();
        if (temp[this.props.children]) {
          stark = stark.concat(temp[this.props.children]);
        }
        if (temp[this.props.value] === id) {
          return temp[this.props.label];
        }
      }
      return '';
    },
    //  Convert a one-dimensional flat array into a multi-level object
    /* @ts-expect-error TODO Unknown type */
    buildTree(data, id = '0') {
      /* @ts-expect-error TODO Unknown type */
      const fa = (parentId) => {
        const temp = [];
        for (let i = 0; i < data.length; i++) {
          const n = data[i];
          if (n[this.props.parent] === parentId) {
            n.children = fa(n.rowGuid);
            temp.push(n);
          }
        }
        return temp;
      };
      return fa(id);
    },
    //  Clear empty children items
    /* @ts-expect-error TODO Unknown type */
    cleanChildren(data) {
      /* @ts-expect-error TODO Unknown type */
      const fa = (list) => {
        /* @ts-expect-error TODO Unknown type */
        list.map((e) => {
          if (e.children.length) {
            fa(e.children);
          } else {
            e = { ...e };
            delete e.children;
          }
          return e;
        });
        return list;
      };
      return fa(data);
    },
  },
};
</script>

<template>
  <el-popover
    ref="popover"
    placement="bottom-start"
    trigger="click"
    v-model="showStatus"
    @show="onShowPopover"
    @hide="onHidePopover"
  >
    <el-tree
      ref="tree"
      class="select-tree"
      highlight-current
      :default-expand-all="true"
      :style="`min-width: ${treeWidth}`"
      :data="data"
      :props="props"
      :expand-on-click-node="false"
      :filter-node-method="filterNode"
      @node-click="onClickNode"
    >
      <div
        style="width: 100%"
        slot-scope="{ node, data }"
        v-on:click="navigateToAsset(data)"
      >
        <div
          v-if="typeof data.order === 'number' || data.order"
          class="custom-tree-node-history"
        >
          <i class="el-icon-time" />
          <span>{{ data.label }}</span>
        </div>
        <div v-else-if="data.children" class="custom-tree-node">
          <div v-if="!data.parent">
            <img
              v-if="data.assetType === 'ASSTYP_MOBILE_COMPACTOR'"
              class="asset-type-image"
              src="@/icons/svg/mobileCompactor.svg"
            />
            <img
              v-if="data.assetType === 'ASSTYP_STATIC_COMPACTOR'"
              class="asset-type-image"
              src="@/icons/svg/staticCompactor.svg"
            />
            <img
              v-if="data.assetType === 'ASSTYP_TIPPING_VEHICLES'"
              class="asset-type-image"
              src="@/icons/svg/tippingVehicle.svg"
            />
          </div>
          <div class="node-label">
            {{
              /* @ts-expect-error TODO Unknown type */
              node.label
            }}
          </div>
          <div v-if="!data.parent" class="node-status">
            {{
              /* @ts-expect-error TODO Unknown type */
              $te(
                `${node.data.status}`
              ) /* @ts-expect-error TODO Unknown type */
                ? $t(`${node.data.status}`)
                : $t('unknownValue')
            }}
          </div>
        </div>
      </div>
    </el-tree>
    <el-input
      slot="reference"
      ref="input"
      v-model="labelModel"
      clearable
      class="main-input"
      :style="`width: ${width}px`"
      suffix-icon="el-icon-search"
      :placeholder="placeholder"
      debounce="600"
    >
    </el-input>
  </el-popover>
</template>

<style lang="scss">
.main-input {
  .el-input__inner {
    width: calc(100% - 8px);
    background-color: #373e41;
    color: #ffffff;
    transform: translate(4px, 0px);
    border-color: white !important;
  }
}

.custom-tree-node-history {
  .el-icon-time {
    opacity: 0.6;
    margin: auto 26px auto -26px;
  }
}

.custom-tree-node {
  display: flex;
  flex-direction: row;
}

.asset-type-image {
  height: 16px;
  margin: auto 26px auto 0;
}

.node-status {
  text-transform: capitalize;
  margin: auto 8px auto auto;
}

.el-input.el-input--suffix {
  cursor: pointer;
  overflow: hidden;
}

.select-tree {
  max-height: 350px;
  overflow-y: scroll;
}
/*  Menu scroll bar */
.select-tree::-webkit-scrollbar {
  z-index: 11;
  width: 6px;
}
.select-tree::-webkit-scrollbar-track,
.select-tree::-webkit-scrollbar-corner {
  background: #fff;
}
.select-tree::-webkit-scrollbar-thumb {
  border-radius: 5px;
  width: 6px;
  background: #b4bccc;
}
.select-tree::-webkit-scrollbar-track-piece {
  background: #fff;
  width: 6px;
}
</style>
