<script setup lang="ts">
import Title from '@/components/layout/Title.vue';
import { useRouter } from '@/composables/router';
import { UserModule } from '@/store/modules/user';
import { ALL_CLAIMS_CODES } from '@/utils/workData/lookuptable';

const props = withDefaults(
  defineProps<{
    createdOrEdit?: boolean | string;
    deacDeprecate?: boolean;
    activateOrDeactivate?: string;
    visible?: boolean;
    visibleEdit?: boolean;
    visibleDeactivate?: boolean;
    visibleRemove?: boolean;
    isNewEntity?: boolean;
    isUpdatable?: boolean;
    backPath?: string;
    editBtnAuth?: ALL_CLAIMS_CODES[];
    deacBtnAuth?: ALL_CLAIMS_CODES[];
    removeBtnAuth?: ALL_CLAIMS_CODES[];
  }>(),
  {
    deacDeprecate: true,
    activateOrDeactivate: 'activate',
    visible: true,
    visibleEdit: true,
    visibleDeactivate: true,
    visibleRemove: false,
    isNewEntity: false,
    isUpdatable: false,
    backPath: '-1',
    editBtnAuth: () => [ALL_CLAIMS_CODES.AUTHRSC_MOD_HOME],
    deacBtnAuth: () => [ALL_CLAIMS_CODES.AUTHRSC_MOD_HOME],
    removeBtnAuth: () => [ALL_CLAIMS_CODES.AUTHRSC_MOD_HOME],
  }
);

const emit = defineEmits([
  'create-event',
  'update-event',
  'edit-infos',
  'handle-remove',
  'handle-deactive',
]);

const router = useRouter();

function hasAuth(authCodes: ALL_CLAIMS_CODES[], authAction?: string) {
  return authCodes.some((value) =>
    UserModule.claims.hasClaim(value, authAction)
  );
}

function handleCreate() {
  emit('create-event');
}

function handleUpdate() {
  emit('update-event');
}

function handleEdit() {
  emit('edit-infos');
}

function handleRemove() {
  emit('handle-remove');
}

function handleDeactive() {
  emit('handle-deactive');
}

function goBack() {
  props.backPath === '-1' ? router.go(-1) : router.push(props.backPath);
}
</script>

<template>
  <div class="cus-add-container" style="overflow: auto">
    <div class="header d-flex ai-center jc-between">
      <div id="new_card_header_title" class="d-flex ai-center">
        <i
          v-if="visible"
          class="el-icon-arrow-left"
          style="font-size: 25px; cursor: Pointer"
          @click="goBack"
        />
        <Title />
        <slot name="headerInfoAfterTitle"></slot>
      </div>
      <div class="viewPageBtn" style="float: right" v-if="createdOrEdit">
        <el-button
          v-if="visibleEdit && hasAuth(editBtnAuth)"
          id="new_card_header_btn_edit"
          style="margin-right: 10px; width: 80px"
          type="plain"
          @click="handleEdit"
        >
          <div class="d-flex ai-center jc-center">
            <div>
              <img style="margin-right: 5px" src="@/assets/imgs/edit.svg" />
            </div>
            <div>
              {{ $t('common.edit') }}
            </div>
          </div>
        </el-button>
        <el-button
          v-if="visibleDeactivate && hasAuth(deacBtnAuth)"
          id="new_card_header_btn_deactivate"
          type="plain"
          style="width: 120px; margin-right: 10px"
          @click="handleDeactive"
        >
          <div class="d-flex ai-center jc-center">
            <div>
              <img
                style="margin-right: 5px"
                src="@/assets/imgs/deactivate.svg"
              />
            </div>
            <div>
              {{
                deacDeprecate
                  ? activateOrDeactivate === 'deactivate'
                    ? $t('common.deactivate')
                    : $t('common.activate')
                  : $t('common.deprecate')
              }}
            </div>
          </div>
        </el-button>
        <el-button
          v-if="visibleRemove && hasAuth(removeBtnAuth)"
          id="new_card_header_btn_remove"
          style="margin-right: 10px; width: 120px"
          type="plain"
          @click="handleRemove"
        >
          <div class="d-flex ai-center jc-center">
            <div>
              <img
                style="margin-right: 5px"
                src="@/assets/imgs/deactivate.svg"
              />
            </div>
            <div>
              {{ $t('common.remove') }}
            </div>
          </div>
        </el-button>
      </div>
      <div v-if="isUpdatable" class="viewPageBtn" style="float: right">
        <el-button
          id="new_card_header_btn_save"
          type="plain"
          style="width: 120px"
          @click="handleUpdate"
        >
          <div class="d-flex ai-center jc-center">
            <div>
              <img
                class="save-icon-btn"
                style="margin-right: 5px"
                src="@/assets/imgs/customer-management/diskette.svg"
              />
            </div>
            <div>
              {{ $t('common.save') }}
            </div>
          </div>
        </el-button>
      </div>
      <div v-if="isNewEntity" class="viewPageBtn" style="float: right">
        <el-button
          id="new_card_header_btn_save"
          type="plain"
          style="width: 120px"
          @click="handleCreate"
        >
          <div class="d-flex ai-center jc-center">
            <div>
              <i class="el-icon-plus common-icon" />
            </div>
            <div>
              {{ $t('common.create') }}
            </div>
          </div>
        </el-button>
      </div>

      <div class="mobileViewPageBtn" v-if="createdOrEdit">
        <el-dropdown>
          <div>
            <i class="el-icon-more" />
          </div>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item
              @click.native="handleEdit"
              v-if="visibleEdit && hasAuth(editBtnAuth)"
              >{{ $t('common.edit') }}</el-dropdown-item
            >
            <el-dropdown-item
              @click.native="handleRemove"
              v-if="visibleRemove && hasAuth(removeBtnAuth)"
              >{{ $t('common.remove') }}</el-dropdown-item
            >
            <el-dropdown-item
              @click.native="handleDeactive"
              v-if="visibleDeactivate && hasAuth(deacBtnAuth)"
              >{{ $t('common.deactivate') }}</el-dropdown-item
            >
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </div>

    <div class="body">
      <slot></slot>
    </div>
  </div>
</template>

<style scoped>
.save-icon-btn {
  width: 15px;
  height: 15px;
}
</style>

<style lang="scss" scoped>
.cus-add-container {
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0px 3px 6px #c1c1c1;
  height: 100%;
  padding-bottom: 12px;

  .header {
    font-family: $font-Roboto-Regular;
    line-height: 21px;
    border-bottom: 1px solid #dddddd;
    padding: 20px 20px;
    height: 60px;
  }

  .body {
    display: flex;
    // min-height: 100vh;
    flex-direction: column;
    // background-color:blanchedalmond;
    min-height: calc(100% - 60px);
  }
}

.el-dropdown-link {
  cursor: pointer;
  color: #409eff;
}

.el-icon-arrow-down {
  font-size: 12px;
}

.mobileViewPageBtn {
  display: none;
}

@media (max-width: 768px) {
  .viewPageBtn {
    display: none;
  }

  .mobileViewPageBtn {
    display: block;
  }
}
</style>
