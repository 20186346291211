var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('el-table',{attrs:{"data":_vm.list,"row-key":"id","row-style":{ height: '40px' },"cell-style":{ padding: '7px 0px' },"row-class-name":_vm.tableRowClassName},on:{"selection-change":_vm.handleSelectionChange}},[_c('el-table-column',{attrs:{"type":"selection","width":"55"}}),_vm._l((_vm.activeCols),function(col,index){return _c('el-table-column',{key:`col_${index}`,attrs:{"prop":_vm.dragCols[index].prop,"min-width":"180","show-overflow-tooltip":""},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('div',{staticClass:"d-flex jc-between"},[_c('span',{staticStyle:{"white-space":"nowrap"}},[_vm._v(_vm._s(_vm.$t(col.label)))])])]},proxy:true},(col.multiLang)?{key:"default",fn:function(slotProps){return [_c('span',[_vm._v(_vm._s(_vm.$t(slotProps.row[col.prop])))])]}}:null],null,true)})})],2),_c('el-popover',{ref:"popover",attrs:{"placement":"bottom","trigger":"click"}},_vm._l((_vm.cols),function(item,index){return _c('el-checkbox',{key:index,staticStyle:{"display":"block","color":"#373e41 !important"},attrs:{"label":/* @ts-expect-error TODO Wrong type */
        _vm.$t(item.label),"disabled":/* @ts-expect-error TODO Wrong type */
        item.required},model:{value:(
        /* @ts-expect-error TODO Wrong type */
        item.visible
      ),callback:function ($$v) {_vm.$set(/* @ts-expect-error TODO Wrong type */
        item, "visible", $$v)},expression:"\n        /* @ts-expect-error TODO Wrong type */\n        item.visible\n      "}})}),1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }