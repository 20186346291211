/**
 * Returns a Promise that never resolves.
 */
export function never(): Promise<never> {
  return new Promise(() => {
    /* no-op */
  });
}

/**
 * Sleep for the given number of milliseconds.
 */
export function sleep(milliseconds: number): Promise<void> {
  return new Promise((resolve) => setTimeout(resolve, milliseconds));
}

export interface Deferred<T> {
  promise: Promise<T>;
  resolve: (value: T | PromiseLike<T>) => void;
  reject: (error: Error) => void;
}

export function defer<T>(): Deferred<T> {
  let resolve: Deferred<T>['resolve'];
  let reject: Deferred<T>['reject'];
  return {
    promise: new Promise((res, rej) => {
      resolve = res;
      reject = rej;
    }),
    resolve: resolve!,
    reject: reject!,
  };
}
