import { UUID } from '@/api/common';
import {
  getCurrentAssetConfigurationParameters,
  GetCurrentAssetConfigurationParametersResponse,
  postAssetConfigurationChangeSet,
  PostAssetConfigurationChangeSetRequest,
  PostAssetConfigurationChangeSetResponse,
} from '@/api/configParams';
import {
  ConfigurationPreset,
  CreateConfigurationPreset,
  createPreset,
  DetailedPreset,
  getPreset,
  getPresets,
  SimplePreset,
} from '@/api/preset';
import { ActiveContext, useActiveContext } from '@/auth/context';
import { useLoggedInUser } from '@/auth/user';
import {
  FilterOperator,
  SorterOrder,
} from '@/model/queryParameters/QueryParameter';
import { AssetType } from '@/utils/assetTypes';
import { isDefined } from '@/utils/collections';
import { MaybeRef } from '@/utils/ref';
import { LANGUAGE } from '@/utils/workData/lookuptable';
import {
  QueryFilters,
  useMutation,
  UseMutationReturnType,
  useQuery,
  useQueryClient,
  UseQueryReturnType,
} from '@tanstack/vue-query';
import { computed, unref } from 'vue';
import { GenericQueryOptions, NO_BACKGROUND_FETCH_OPTIONS } from './common';

const queryKeys = {
  configurationParameter: (
    assetId: UUID,
    i18nCode: LANGUAGE,
    enabled?: boolean,
    sendSelectedCustomer?: boolean,
    context?: ActiveContext
  ) =>
    ({
      query: 'configuration-parameter',
      assetId,
      i18nCode,
      enabled,
      sendSelectedCustomer,
      context,
    } as const),
  preset: (id: UUID | undefined, i18nCode: LANGUAGE) =>
    ({
      query: 'preset',
      id,
      i18nCode,
    } as const),
  presets: (assetType: AssetType, name?: string) => ({
    query: 'presets',
    assetType,
    name,
  }),
};

const queryFilters = {
  presets: (): QueryFilters => ({
    queryKey: [{ query: 'presets' }],
  }),
  assetConfigurationParameters: (assetId: UUID): QueryFilters => ({
    queryKey: [{ query: 'configuration-parameter', assetId }],
  }),
};

export function useConfigurationParameterQuery<
  TData = GetCurrentAssetConfigurationParametersResponse
>(
  assetId: MaybeRef<UUID>,
  options?: GenericQueryOptions<
    GetCurrentAssetConfigurationParametersResponse,
    ReturnType<(typeof queryKeys)['configurationParameter']>[],
    Error,
    TData
  >,
  enabled?: MaybeRef<boolean>,
  sendSelectedCustomer?: MaybeRef<boolean>
): UseQueryReturnType<TData, Error> {
  const context = useActiveContext();
  const loggedInUser = useLoggedInUser();

  return useQuery({
    ...NO_BACKGROUND_FETCH_OPTIONS,
    ...options,
    enabled: computed(() => unref(enabled) !== false),
    queryKey: [
      computed(() =>
        queryKeys.configurationParameter(
          unref(assetId),
          loggedInUser.value?.settings.i18nCode ?? LANGUAGE.EN_US,
          unref(enabled),
          unref(sendSelectedCustomer),
          context.value
        )
      ),
    ],
    queryFn: ({
      queryKey: [{ assetId, context, sendSelectedCustomer, i18nCode }],
    }) =>
      getCurrentAssetConfigurationParameters(
        assetId,
        {
          pagination: { page: 1, size: 100_000 },
          sorters: [
            {
              field: 'propertyName',
              order: SorterOrder.ASC,
            },
          ],
          filters: [
            {
              name: 'i18nCode',
              operator: FilterOperator.EQUAL,
              value: [i18nCode],
            },
          ],
          timezone: context?.organization?.timezone,
        },
        sendSelectedCustomer !== false ? (context as ActiveContext) : undefined
      ),
  });
}

export function usePresetQuery<TData = DetailedPreset>(
  id: MaybeRef<UUID | undefined>,
  options?: GenericQueryOptions<
    DetailedPreset,
    ReturnType<(typeof queryKeys)['preset']>[],
    Error,
    TData
  >
): UseQueryReturnType<TData, Error> {
  const loggedInUser = useLoggedInUser();

  return useQuery({
    ...NO_BACKGROUND_FETCH_OPTIONS,
    staleTime: 10 * 1000,
    ...options,
    enabled: computed(() => unref(id) !== undefined),
    queryKey: [
      computed(() =>
        queryKeys.preset(
          unref(id),
          loggedInUser.value?.settings.i18nCode ?? LANGUAGE.EN_US
        )
      ),
    ],
    queryFn: ({ queryKey: [{ id, i18nCode }] }) => getPreset(id!, i18nCode),
  });
}

export function usePresetsQuery<TData = SimplePreset[]>(
  assetType: MaybeRef<AssetType>,
  name?: MaybeRef<string | undefined>,
  options?: GenericQueryOptions<
    SimplePreset[],
    ReturnType<(typeof queryKeys)['presets']>[],
    Error,
    TData
  >
): UseQueryReturnType<TData, Error> {
  return useQuery({
    ...options,
    staleTime: 10 * 1000,
    ...NO_BACKGROUND_FETCH_OPTIONS,
    queryKey: [
      computed(() => queryKeys.presets(unref(assetType), unref(name))),
    ],
    queryFn: ({ queryKey: [{ assetType, name }] }) =>
      getPresets({
        filters: [
          {
            name: 'assetType',
            operator: FilterOperator.EQUAL,
            value: [assetType],
          },
          ...[
            name
              ? {
                  name: 'name',
                  operator: FilterOperator.LIKE,
                  value: [name],
                }
              : undefined,
          ].filter(isDefined),
        ],
      }),
  });
}

export function useCreatePresetMutation(
  onSuccess?: (
    data: ConfigurationPreset,
    variables: CreateConfigurationPreset,
    context: void
  ) => unknown,
  onError?: (
    error: Error,
    variables: CreateConfigurationPreset,
    context: void | undefined
  ) => unknown
): UseMutationReturnType<
  ConfigurationPreset,
  Error,
  CreateConfigurationPreset,
  void
> {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (preset) => createPreset(preset),
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries(queryFilters.presets());

      return onSuccess?.(data, variables, context);
    },
    onError,
  });
}

export interface ApplyPresetMutation {
  assetUUID: UUID;
  body: PostAssetConfigurationChangeSetRequest;
  sendSelectedCustomer: boolean;
}

export function useSetParameterConfigurationMutation(
  onSuccess?: (
    data: PostAssetConfigurationChangeSetResponse,
    variables: ApplyPresetMutation,
    context: void
  ) => unknown,
  onError?: (
    error: Error,
    variables: ApplyPresetMutation,
    context: void | undefined
  ) => unknown
): UseMutationReturnType<
  PostAssetConfigurationChangeSetResponse,
  Error,
  ApplyPresetMutation,
  void
> {
  const queryClient = useQueryClient();
  const context = useActiveContext();

  return useMutation({
    mutationFn: (model) =>
      postAssetConfigurationChangeSet(
        model.assetUUID,
        model.body,
        model.sendSelectedCustomer ? context.value : undefined
      ),
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries(
        queryFilters.assetConfigurationParameters(variables.assetUUID)
      );

      return onSuccess?.(data, variables, context);
    },
    onError,
  });
}
