<script lang="ts">
import {
  createTemplate,
  DashboardTemplate,
  getModules,
  getTemplatesById,
  updateTemplate,
} from '@/api/dashboardConf';
import BaseCard from '@/components/cusCard/BaseCard.vue';
import EditDashTemplate from '@/components/template/EditDashTemplate.vue';
import { PageModule } from '@/store/modules/page';
import { promptFailedBox, promptSuccessBox } from '@/utils/prompt';
import {
  ALL_CLAIMS_CODES,
  COMPANY_TYPE,
  COMPANY_TYPE_LIST,
  ERROR_CODE_LIST,
  INDUSTRY_LIST,
  INDUSTRY_TYPE,
} from '@/utils/workData/lookuptable';
import { Component, Prop, Vue } from 'vue-property-decorator';

interface WIDGET_TYPE {
  i: string;
  x: number;
  y: number;
  w: number;
  h: number;
  minW: number;
  minH: number;
  maxW: number;
  maxH: number;
  isResizable: boolean;
  code: string;
  pageCode: string;
  name: string;
}

@Component({
  name: 'AddNewTemplate',
  components: {
    BaseCard,
    EditDashTemplate,
  },
})
export default class extends Vue {
  @Prop() id!: string;

  errorCode = ERROR_CODE_LIST;
  custTypes = COMPANY_TYPE_LIST;
  industries = INDUSTRY_LIST;
  errorInfos = [
    {
      code: '',
      field: '',
      message: '',
    },
  ];

  action: any;
  editDashTemplateChild: any = {};
  title: string = '';
  templateForm: DashboardTemplate = {
    id: '',
    name: '',
    customerType: COMPANY_TYPE.Customer,
    industry: INDUSTRY_TYPE.Construction,
    widgets: [],
    unSelectedModules: [],
    isDefault: false,
  };
  templateNameRegex = /^\S+/;

  validateTemplateNameLength = (rule: any, value: any, callback: any) => {
    if (value.length < 1 || value.length > 100) {
      callback(new Error(''));
      this.$message({
        type: 'error',
        message: `${this.$t('dashboardConf.tipTemplateNameForm')}`,
        duration: 2000,
      });
    } else {
      callback();
    }
  };

  get rules() {
    const tmpRules = {
      name: [
        { required: true, message: '', pattern: /^\S+/, trigger: 'change' },
        { validator: this.validateTemplateNameLength, trigger: 'blur' },
      ],
    };

    return tmpRules;
  }

  handleResetToDefault() {
    this.editDashTemplateChild.handleResetToDefault(
      this.templateForm.customerType,
      this.templateForm.industry
    );
  }

  showErrorInfo(errorCode: any, info: any) {
    this.errorInfos = errorCode;

    if (
      this.errorInfos.find((item) => item.field === this.errorCode.name)
        ?.code === 'ApiErrorFieldDuplicate'
    ) {
      this.$message({
        type: 'error',
        message: `${this.$t(info.msg, { action: info.action })}`,
      });
    }
  }

  handleCreate() {
    this.editDashTemplateChild.updateWidget();
    this.templateForm.unSelectedModules =
      this.editDashTemplateChild.filterModules();

    if (
      !this.editDashTemplateChild.isCheckedNodeListNull() &&
      !this.editDashTemplateChild.isPageNull()
    ) {
      (this.$refs.templateForm as any).validate(async (valid: any) => {
        if (valid) {
          this.templateForm.widgets =
            this.editDashTemplateChild.getAllWidgets();

          if (this.id && !this.action) {
            await updateTemplate(this.id, this.templateForm).then((res) => {
              if (res.code === 200) {
                promptSuccessBox(this.$t('common.save') as string);
                this.$router.push('/dashboard-conf/index');
              } else if (res.code === 400) {
                promptFailedBox(this.$t('common.save') as string);
              }
            });
          } else {
            await createTemplate(this.templateForm).then((res) => {
              if (res.code === 200) {
                promptSuccessBox(this.$t('common.create') as string);
                this.$router.push('/dashboard-conf/index');
              } else if (res.code === 400) {
                this.showErrorInfo(res.data.errors, {
                  msg: 'dashboardConf.duplicateName',
                  action: this.$t('common.create'),
                });
              }
            });
          }
        } else {
          this.$message({
            type: 'error',
            message: `${this.$t('dashboardConf.tipInputTemplateName')}`,
            duration: 2000,
          });
        }
      });
    }
  }

  async fetchTempalteById() {
    const res = await getTemplatesById(this.id);

    PageModule.setTitle(res.data.name);
    this.templateForm = res.data;

    if (this.action) {
      this.templateForm.name += ' - ' + this.action;
      this.templateForm.id = '';
    }

    this.editDashTemplateChild.updateWidgetsBuffer(this.templateForm.widgets);

    this.editDashTemplateChild.handleNodeClick({
      name: ALL_CLAIMS_CODES.AUTHRSC_MOD_HOME,
      children: [],
    });

    this.templateForm.widgets = [];
  }

  async fetchCheckedModules() {
    const resCheckedModules = await getModules(this.id);
    this.editDashTemplateChild.setCheckedModules(resCheckedModules);
  }

  mounted() {
    this.editDashTemplateChild = this.$refs.editDashTemplate as any;
    this.id && this.fetchCheckedModules();
    this.id && this.fetchTempalteById();
  }

  created() {
    this.action = this.$route.query.action;
  }
}
</script>

<template>
  <BaseCard
    id="new_template_dashboard"
    ref="baseCardRef"
    :title="id ? templateForm.name : $t('dashboardConf.createNewTemplate')"
    :backIconVisible="true"
    :showDialogBeforeBack="false"
    @handle-back="false"
    style="overflow: auto; overflow-x: hidden"
  >
    <template v-slot:rightHeader>
      <div>
        <el-button
          id="new_template_reset_to_default_btn"
          style="margin-right: 10px; width: 150px"
          type="plain"
          @click="handleResetToDefault"
        >
          <div class="d-flex ai-center jc-center">
            <div>
              <img
                style="margin-right: 5px"
                src="@/assets/imgs/dashboard-config/resetToDefault.svg"
              />
            </div>
            <div>
              {{ $t('common.resetToDefault') }}
            </div>
          </div>
        </el-button>
        <el-button
          id="new_template_create_btn"
          type="plain"
          style="width: 120px"
          @click="handleCreate"
        >
          <div class="d-flex ai-center jc-center">
            <div>
              <img
                v-if="id && !action"
                style="margin-right: 5px"
                src="@/assets/imgs/dashboard-config/save.svg"
              />
              <i v-else class="el-icon-plus common-icon" />
            </div>
            <div>
              {{ id && !action ? $t('common.save') : $t('common.create') }}
            </div>
          </div>
        </el-button>
      </div>
    </template>

    <el-form
      ref="templateForm"
      :model="templateForm"
      :rules="rules"
      :show-message="false"
    >
      <div class="new-template-header">
        <div class="dash-header">
          <div class="dash-header-label">
            <span class="show-title"
              >{{ $t('dashboardConf.templateName') }}:</span
            >
            <span class="required-field">*</span>
          </div>
          <el-form-item prop="name">
            <el-input
              id="new_template_name_input"
              class="util-table-input"
              v-model="templateForm.name"
              :placeholder="$t('dashboardConf.inputTmpName')"
            />
          </el-form-item>
        </div>
        <div class="dash-header">
          <div class="dash-header-label">
            <span class="show-title">{{ $t('dashboardConf.custType') }}:</span>
            <span class="required-field">*</span>
          </div>
          <el-form-item>
            <el-select
              id="new_template_cust_type_select"
              class="util-table-select"
              v-model="templateForm.customerType"
              :placeholder="$t('dashboardConf.custType')"
            >
              <el-option
                v-for="(item, index) in custTypes"
                :key="index"
                :label="$t(item.id)"
                :value="item.id"
              />
            </el-select>
          </el-form-item>
        </div>
        <div class="dash-header">
          <div class="dash-header-label">
            <span class="show-title">{{ $t('dashboardConf.industry') }}:</span>
            <span class="required-field">*</span>
          </div>
          <el-form-item>
            <el-select
              id="new_template_industry_select"
              class="util-table-select"
              v-model="templateForm.industry"
              :placeholder="$t('dashboardConf.industry')"
            >
              <el-option
                v-for="(item, index) in industries"
                :key="index"
                :label="$t(item.id)"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </div>
      </div>

      <EditDashTemplate
        ref="editDashTemplate"
        :id="id"
        :customerType="templateForm.customerType"
      />
    </el-form>
  </BaseCard>
</template>

<style lang="scss" scoped>
.new-template-header {
  margin: 20px 0px;
  display: flex;
  align-items: center;
  justify-content: flex-start;

  .dash-header {
    display: flex;
    align-items: center;
    margin-left: 40px;

    .dash-header-label {
      margin-right: 20px;
      text-align: left;
    }
  }
}

.el-select {
  margin-right: 0px;
}

.util-table-input :deep(.el-input__inner) {
  height: $inputHeight;
  width: 260px;
  // width: 31vw;
  border: 1px solid #707070 !important;
  font-size: 1.285714rem;
  font-family: $font-Roboto-Regular;
  line-height: 1.357143rem;
  color: #373e41;
  opacity: 1;
}

.el-form-item.is-error {
  .util-table-input :deep(.el-input__inner) {
    border-color: #f56c6c !important;
  }
}

.util-table-select :deep(.el-input__inner) {
  height: $inputHeight;
  width: 200px;
  border: 1px solid #707070 !important;
  font-size: 1.285714rem;
  font-family: $font-Roboto-Regular;
  line-height: 1.357143rem;
  color: #373e41;
  opacity: 1;
}
</style>
