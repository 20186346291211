<script lang="ts">
import { getAssetById } from '@/api/assets';
import {
  CustomersResponse,
  deprecateSystemRelease,
  getAssetListForSpecifiedRelease,
  getCustomers,
  getSystemReleases,
  productSystemRelease,
  startNewRelease,
  SystemRelease,
  updateSystemRelease,
} from '@/api/systemReleaseManagement';
import { ActiveContext, useActiveContext } from '@/auth/context';
import TreeTransfer from '@/components/dialog/TreeTransfer.vue';
import {
  default as WidgetDialog,
  default as WidgetDialogVue,
} from '@/components/dialog/WidgetDialog.vue';
import Status from '@/components/status/Status.vue';
import { UserModule } from '@/store/modules/user';
import { customFailedMessage, customSuccessMessage } from '@/utils/prompt';
import {
  getPartFirmwareInformation,
  getProductSystemInformation,
  getTreeData,
  mapFirmwareReleaseData,
} from '@/utils/releaseInformationUtil';
import {
  ALL_CLAIMS_CODES,
  GENERAL_QUERY_OPERATORS,
} from '@/utils/workData/lookuptable';
import { Ref, unref } from 'vue';
import { Component, Vue } from 'vue-property-decorator';
import ContentContainer from './components/ListContent.vue';
import ContentTable from './components/TableContent.vue';

@Component({
  name: 'Release',
  components: {
    'content-list': ContentContainer,
    'content-table': ContentTable,
    'tree-transfer': TreeTransfer,
    Status,
    'widget-dialog': WidgetDialogVue,
    'set-as-default': WidgetDialog,
  },
})
export default class extends Vue {
  /** Local variables */
  checked: boolean = false;
  treeTransferVisible: boolean = false;
  currentId: string = '';
  showDeprecateDefaultDialog: boolean = false;
  isReleaseManagement: boolean = false;
  isPendingUpdates: boolean = false;
  releaseAssetIds: string[] = [];
  status = {};
  pendingUpdateDetailPageIsLoading: boolean = false;

  defaultRelease: SystemRelease = {
    id: '',
    assetType: '',
    defaultRelease: true,
    deploymentDurationMinutes: 0,
    manifestCreationTime: '',
    maturityLevel: '',
    numberOfLinkedCustomers: 0,
    numberOfLinkedOrganizations: 0,
    productModelCode: '',
    productModelNumber: '',
    region: '',
    releaseStatus: '',
    systemReleaseId: '',
    systemReleaseName: '',
    systemReleaseVersion: '',
  };
  releaseInfo = {
    title: '',
    content: Array.of<any>([]),
    rawContent: {} as any,
  };

  treeContentData: { selectedData: Array<string>; tree: any } = {
    tree: {},
    selectedData: [],
  };
  setAsDefaultDialogVisible: boolean = false;
  hasSetAsDefaultPermission = UserModule.claims.hasClaim(
    ALL_CLAIMS_CODES.AUTHRSC_ACTION_RELEASE_DEFAULT
  );
  customers: any = null;
  partFirmwareInformation: any[] = [];
  productID: string = '';
  productSystemReleaseID: string = '';
  deprecated: boolean = true;
  releaseAssetListFilter = {
    filters: [
      {
        name: 'systemReleaseId',
        operator: GENERAL_QUERY_OPERATORS.Equal,
        value: [''],
      },
      {
        name: 'companyId',
        operator: GENERAL_QUERY_OPERATORS.Equal,
        value: [UserModule.companyId],
      },
    ],
    sorters: [],
    pagination: {
      page: '1',
      size: '10',
    },
  };
  customerListLoading: boolean = true;

  context!: Ref<ActiveContext>;

  async created() {
    this.context = useActiveContext();
    this.currentId = this.$route.params.id;
    this.isReleaseManagement = this.$route.name === 'releaseView';
    this.isPendingUpdates = this.$route.name === 'pendingUpdates';
    await this.initReleaseProperties(this.currentId);
    await this.initReleaseAssetIds();
  }

  async initReleaseProperties(id: string): Promise<void> {
    try {
      this.pendingUpdateDetailPageIsLoading = true;
      const response = await getSystemReleases(id);
      this.deprecated = !response.data.releaseStatus.includes('ACTIVE');
      this.checked = response.data.defaultRelease == true;
      if (!this.checked) {
        const defaultReleaseResponse = await productSystemRelease({
          filters: [
            {
              name: 'productModelCode',
              operator: GENERAL_QUERY_OPERATORS.Equal,
              value: [response.data.productModelCode],
            },
            {
              name: 'productModelNumber',
              operator: GENERAL_QUERY_OPERATORS.Equal,
              value: [response.data.productModelNumber],
            },
            {
              name: 'defaultRelease',
              operator: GENERAL_QUERY_OPERATORS.Equal,
              value: [true],
            },
          ],
          //TODO remove pagination when backe-end accepts request body with no pagination
          pagination: {
            page: '1',
            size: '1000',
          },
        });
        this.defaultRelease = !!defaultReleaseResponse.data.systemReleases
          .length
          ? defaultReleaseResponse.data.systemReleases[0]
          : null;
      } else {
        this.defaultRelease = response.data;
      }
      this.status = { value: response.data.releaseStatus };
      mapFirmwareReleaseData(response.data);
      const assetName = await this.getAssetNameFromRoute();
      this.releaseInfo.title = assetName
        ? this.$t('route.deployManagement.pendingUpdates', {
            releaseName: response.data.systemReleaseId,
            assetName: assetName,
          })
        : response.data.systemReleaseId;
      this.releaseInfo.rawContent = response.data;
      this.releaseInfo.content = getProductSystemInformation(response.data);
      this.partFirmwareInformation = getPartFirmwareInformation(
        response.data.partFirmwareRelease
      );

      let selectedData: string[] = [];
      try {
        const linked = await this.getCustomerData(id, true);

        /** Add number of customers for current release item */
        const indexOfDefaultRelease: number =
          this.releaseInfo.content
            ?.map((item) => item.id)
            .indexOf('productSystemRelease.defaultRelease') + 1 ?? 9; // show after default release field or at same position
        const numberOfLinkedCustomersValue = {
          id: 'productSystemRelease.numberOfLinkedCustomers',
          value: linked.customers?.length ?? 0,
        };
        this.releaseInfo.content.splice(
          indexOfDefaultRelease,
          0,
          numberOfLinkedCustomersValue
        );

        const unlinked = await this.getCustomerData(id, false);

        [this.customers, selectedData] = this.mergeCustomers(linked, unlinked);

        this.customerListLoading = false;
      } catch (e) {
        console.error(e);
        customFailedMessage(this.$t('common.errorWithFetchingData') as string);
      }

      const treeData = getTreeData(this.customers);

      this.treeContentData = {
        tree: treeData,
        selectedData: selectedData,
      };
    } catch (err) {
      console.log(err);
      customFailedMessage(this.$t('common.errorWithFetchingData') as string);
    } finally {
      this.pendingUpdateDetailPageIsLoading = false;
    }
  }

  private async getCustomerData(id: string, linked: boolean) {
    const response = await getCustomers({
      filters: [
        {
          name: 'systemReleaseUuid',
          operator: GENERAL_QUERY_OPERATORS.Equal,
          value: [id],
        },
        {
          name: 'linkedCustomers',
          operator: GENERAL_QUERY_OPERATORS.Equal,
          value: [linked],
        },
      ],
      pagination: {
        page: '1',
        size: '100',
      },
    });

    return response.data;
  }

  private mergeCustomers(
    linked: CustomersResponse,
    unlinked: CustomersResponse
  ) {
    const ids: string[] = [];
    const merged = [
      ...linked.customers.map((c) => {
        ids.push(...c.organizations.map((o) => o.id));

        const other = unlinked.customers.find(
          (u) => u.customerId === c.customerId
        );

        if (!other) {
          ids.push(c.customerId);

          return c;
        }

        return {
          ...c,
          organizations: [...c.organizations, ...other.organizations],
        };
      }),
      ...unlinked.customers.filter(
        (c) => !linked.customers.some((l) => c.customerId === l.customerId)
      ),
    ];

    return [merged, ids] as const;
  }

  async deprecateRelease(): Promise<void> {
    if (this.checked) {
      this.showDeprecateDefaultDialog = true;

      return;
    }
    await deprecateSystemRelease(this.currentId);
    this.$router.go(0); // TODO: Check for user experience
  }

  async editLinkedCustomer(): Promise<void> {
    this.treeTransferVisible = true;
  }

  async handleConfirm(): Promise<void> {
    const linkedCustomers = this.constructLinkedCustomers();
    await updateSystemRelease(this.currentId, {
      linkedOrganizations: linkedCustomers,
    });

    this.treeTransferVisible = false;
  }

  private constructLinkedCustomers() {
    const linekdCustomers = new Array<any>();

    this.customers.forEach((customer: any) => {
      const organizations = this.getLinkedOrganizations(customer);
      if (
        !this.treeContentData.selectedData.includes(customer.customerId) &&
        organizations.length == 0
      ) {
        return;
      }

      linekdCustomers.push(...organizations);
    });

    return linekdCustomers;
  }

  private constructSelectedData(): string[] {
    const selectedData = [] as string[];

    this.customers.forEach((customer: any) => {
      const organizations = this.getAllLinkedOrganizations(customer);
      if (!organizations.length) {
        return;
      }

      selectedData.push(...organizations);
      selectedData.push(customer.customerId);
    });

    return selectedData;
  }

  private getLinkedOrganizations(parentOrg: any): any[] {
    const linkedOrgs = [];
    if (this.treeContentData.selectedData.includes(parentOrg.id)) {
      linkedOrgs.push(parentOrg.id);
    }

    const children = parentOrg.suborganizations || parentOrg.organizations;

    if (children) {
      children.forEach((child: any) => {
        linkedOrgs.push(...this.getLinkedOrganizations(child));
      });
    }

    return linkedOrgs;
  }

  private getAllLinkedOrganizations(parentOrg: any): any[] {
    const linkedOrgs = [];
    if (
      this.treeContentData.tree.some((el: any) =>
        el.children.some((c: any) => c.id === parentOrg.id)
      )
    ) {
      linkedOrgs.push(parentOrg.id);
    }

    const children = parentOrg.suborganizations || parentOrg.organizations;

    if (children) {
      children.forEach((child: any) => {
        linkedOrgs.push(...this.getAllLinkedOrganizations(child));
      });
    }

    return linkedOrgs;
  }

  handleCancel(): void {
    if (this.treeTransferVisible) {
      this.treeTransferVisible = false;
    }
    if (this.setAsDefaultDialogVisible) {
      this.checked = false;
      this.setAsDefaultDialogVisible = false;
    }
  }

  navigateBack(): void {
    if (this.isReleaseManagement) {
      this.$router.push(`/release/index`);
      return;
    }
    if (this.isPendingUpdates) {
      this.$router.go(-1);
      return;
    }
    this.$router.push(`/deploy/index`);
  }

  handleSetAsDefault(): void {
    this.productID = this.releaseInfo.rawContent['productSystemReleaseId'];
    this.productSystemReleaseID = this.releaseInfo.rawContent['id'];
    this.setAsDefaultDialogVisible = true;
  }

  async handleDialogConfirmSetDefault(): Promise<void> {
    try {
      const response = await updateSystemRelease(this.productSystemReleaseID, {
        defaultRelease: true,
      });
      if (response.code === 200) {
        this.handleCancel();
        this.checked = true;
        this.treeContentData.selectedData = this.constructSelectedData();
        customSuccessMessage(
          this.$t('releaseManagementModule.setAsDefaultSuccess').toString()
        );
      }
      if (response.code === 500) {
        this.handleCancel();
        customFailedMessage(
          `${this.$t(
            'releaseManagementModule.setAsDefaultFailure'
          ).toString()} ${response.message}`
        );
      }
    } catch (err) {
      console.log(err);
      customFailedMessage(
        this.$t('releaseManagementModule.setAsDefaultFailure2').toString()
      );
    }
  }

  private async initReleaseAssetIds(): Promise<void> {
    if (this.$route.query.assetUUID) {
      this.releaseAssetIds.push(this.$route.query.assetUUID as string);
      return;
    }

    try {
      this.releaseAssetListFilter.filters[0].value = [
        this.releaseInfo.rawContent.id,
      ];
      const response = await getAssetListForSpecifiedRelease(
        this.releaseAssetListFilter
      );
      this.releaseAssetIds = response.data.assets.map(
        (asset: any) => asset.assetUUID
      );
    } catch (err) {
      console.log(err);
    }
  }

  async deploy(): Promise<void> {
    try {
      this.pendingUpdateDetailPageIsLoading = true;
      const response = await startNewRelease({
        deployments: [
          {
            systemReleaseUUID: this.releaseInfo.rawContent['id'], // UUID for system release
            companyId: UserModule.companyId, // company ID is not returned on the API
            assets: this.releaseAssetIds, // list of assetUUID
          },
        ],
      });
      if (response.code === 200) {
        this.$router.push(`/deploy/index`);
      }
      if (response.code === 400) {
        const errorMessage: string =
          (this.$t(
            'releaseManagementModule.deploymentEncounteredAnError'
          ) as string) +
          ' ' +
          response.data.errors[0]?.message;
        customFailedMessage(errorMessage);
      }
    } catch (error) {
      console.log(error);
    } finally {
      this.pendingUpdateDetailPageIsLoading = false;
    }
  }

  private async getAssetNameFromRoute(): Promise<string | undefined> {
    const assetId = this.$route.query.assetUUID;
    if (!assetId || typeof assetId !== 'string') {
      return;
    }
    const assetResponse = await getAssetById(assetId, unref(this.context));
    if (assetResponse.code !== 200) {
      return;
    }
    return assetResponse.data.assetId;
  }
}
</script>

<template>
  <div
    class="page-container"
    v-loading="pendingUpdateDetailPageIsLoading"
    style="min-height: 100%"
  >
    <div class="page-title-container">
      <el-button class="back" @click="navigateBack">
        <img class="back-icon" src="@/icons/svg/back.svg" />
      </el-button>
      <span class="page-content-title">{{ releaseInfo.title }}</span>
      <Status :status="status" />
      <el-button
        style="float: right"
        v-if="!isReleaseManagement"
        v-on:click="deploy"
      >
        {{ $t('releaseManagementModule.deployButton') }}
      </el-button>
      <el-button
        style="float: right"
        id="deprecate_release_button"
        v-permission="['AUTHRSC_ACTION_RELEASE_DEPRECATE']"
        v-else-if="!deprecated"
        v-on:click="deprecateRelease"
      >
        {{ $t('releaseManagementModule.deprecateButton') }}
      </el-button>
    </div>
    <div class="container">
      <div class="left-content">
        <h2 class="title">
          {{ $t('releaseDetails.productSystemReleaseInfo') }}
        </h2>
        <content-list
          :showReleaseNotes="true"
          :productSystemReleaseID="$route.params.id"
          :content="releaseInfo.content"
          class="content-component"
        />
        <div v-if="isReleaseManagement">
          <el-checkbox
            class="set-as-default"
            v-model="checked"
            :disabled="checked || !hasSetAsDefaultPermission"
            @change="handleSetAsDefault"
            v-if="!deprecated"
          >
            {{ $t('releaseDetails.setAsDefault') }}
          </el-checkbox>
          <div class="linked-customer-header" v-if="!deprecated">
            <h2 class="title">
              {{ $t('releaseDetails.linkedCustomerTitle') }}
            </h2>
            <el-button
              class="new-release-button"
              id="edit_linked_customers_button"
              v-permission="['AUTHRSC_ACTION_RELEASE_LINK']"
              type="plain"
              :disabled="customerListLoading || checked"
              @click="editLinkedCustomer"
              v-loading="treeTransferVisible"
            >
              <img class="edit-icon" src="@/icons/svg/editRelease.svg" />
              {{ $t('assetMgmt.edit') }}
            </el-button>
          </div>
          <content-table
            :titleName="$t('subsMgmt.customer')"
            :titleValue="$t('subsMgmt.numberOfAssets')"
            :content="treeContentData.tree"
            :filterIds="treeContentData.selectedData"
            v-if="!deprecated"
          />
        </div>
      </div>
      <div class="right-content">
        <h2 class="title">{{ $t('releaseDetails.partFirmwareInfo.title') }}</h2>
        <content-list
          v-for="info in partFirmwareInformation"
          :showReleaseNotes="false"
          :productSystemReleaseID="$route.params.id"
          :key="info.containedPartName"
          :content="info"
          :title="
            (
              info.find(
                /* @ts-expect-error TODO Wrong type */
                (i) => i.id === 'productSystemRelease.containedPartName'
              ) || {}
            ).value
          "
        />
      </div>
    </div>
    <tree-transfer
      :title="$t('releaseDetails.linkedCustomerTitle')"
      :visible="treeTransferVisible"
      :confirmBtnName="$t('common.apply')"
      :listTitles="[
        $t('linkedCustomers.customersAvailable'),
        $t('linkedCustomers.customersLinked'),
      ]"
      :data="treeContentData"
      v-on:handle-confirm="handleConfirm"
      v-on:handle-cancel="handleCancel"
    >
    </tree-transfer>
    <set-as-default
      :visible="setAsDefaultDialogVisible"
      :title="$t('newRelease.dialogSetAsDefault')"
      :confirmBtnName="$t('userSetting.confirm')"
      :width="'600px'"
      @handle-cancel="handleCancel"
      @handle-confirm="handleDialogConfirmSetDefault"
    >
      <a class="dialog-content">
        {{
          !defaultRelease ||
          defaultRelease.systemReleaseId === productSystemReleaseID
            ? $t('newRelease.dialogContentSetAsDefaultNoDefaultRelease')
            : $t('newRelease.dialogContentSetAsDefaultExistingDefaultRelease', {
                defaultReleaseName: defaultRelease.systemReleaseId,
              })
        }}
      </a>
    </set-as-default>

    <widget-dialog
      :title="$t('releaseManagementModule.deprecateDefaultTitle')"
      :visible="showDeprecateDefaultDialog"
      :showCancelButton="false"
      :confirmBtnName="$t('maintenance.ok')"
      v-on:handle-confirm="showDeprecateDefaultDialog = false"
    >
      {{ $t('releaseManagementModule.deprecateDefaultMessage') }}
    </widget-dialog>
  </div>
</template>

<style lang="scss" scoped>
:deep(.el-dialog__body) {
  padding: 20px 25px;
}

:deep(.el-dialog__footer) {
  padding: 0 64px 48px 64px;

  .dialog-footer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .widget-dialog-btn {
      width: 206px;
    }
  }
}

:deep(.widget-dialog-btn) {
  margin: 0;
}

.dialog-content {
  height: 700px;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 23px;
  text-align: center;
  word-break: break-word;
}

.linked-customer-header {
  display: flex;
  justify-content: space-between;
  margin: 0 0 10px 0;
  height: 42px;
}

.back-icon {
  height: 16px;
}

.back {
  padding: 0;
  margin: 0;
  border: none;
}

.page-container {
  background: #ffffff;
  padding: 16px;
  border-radius: 8px;
  box-shadow: 0 1px 4px rgb(0 21 41 / 8%);
}

.page-title-container {
  border-bottom: 2px solid #dddddd;
  margin: 0 0 20px 0;
  padding: 16px 0;
}

.container {
  display: flex;
  gap: 58px;
}

.left-content {
  width: 50%;
}

.right-content {
  width: 50%;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.title {
  margin: 20px 0;
  color: #373e41;
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 21px;
}

.set-as-default {
  margin: 24px 0;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 21px;
}

.content-component :deep(.container) {
  min-height: 30rem;
}
</style>
