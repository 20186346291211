import { Message } from 'element-ui';
import i18n from '@/lang';

const calculateDuration = (msg: string) => {
  if (msg.length < 100) {
    return 3 * 1000;
  } else if (msg.length < 150) {
    return 6 * 1000;
  }

  return 10 * 1000;
};

export const promptSuccessBox = (msg: string) => {
  Message({
    message: `${msg} ${i18n.t('common.success')}`,
    type: 'success',
    duration: calculateDuration(msg),
  });
};

export const promptFailedBox = (msg: string) => {
  Message({
    message: `${msg} ${i18n.t('common.fail')}`,
    type: 'error',
    duration: calculateDuration(msg),
  });
};

export const customSuccessMessage = (msg: string) => {
  Message({
    message: msg,
    type: 'success',
    duration: calculateDuration(msg),
  });
};

export const customFailedMessage = (msg: string) => {
  Message({
    message: msg,
    type: 'error',
    duration: calculateDuration(msg),
  });
};

export const customWarningMessage = (msg: string) => {
  Message({
    message: msg,
    type: 'warning',
    duration: calculateDuration(msg),
  });
};
