<script lang="ts">
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

export interface MaintenanceSelectItem {
  maintenanceItemId: string;
  maintenanceItemName: string;
  selected: boolean;
}

@Component({
  name: 'MultiMaintenanceItemSelect',
})
export default class extends Vue {
  @Prop() maintenanceList: MaintenanceSelectItem[] | undefined;
  @Prop() disabled!: boolean;

  // el-select props
  selectedItemOptions: string[] = [];

  //el-switch props
  selectedItemsSwitch: MaintenanceSelectItem[] = [];

  allDropdownItemsSelected: boolean = true;

  /**
   * Check the received list changes to reflect in the dropdown
   * @param newData
   */
  @Watch('maintenanceList', { immediate: true })
  handleData(newData: MaintenanceSelectItem[] | undefined): void {
    this.determineAllSelectionState();
    if (newData?.length) {
      this.selectedItemOptions = this.getSelectedMaintenanceName(newData);
      this.selectedItemsSwitch = newData;
    }
  }

  /**
   * Determine 'All' btn state by comparing the total number of items list against which one are selected
   */
  determineAllSelectionState(): void {
    if (!!this.maintenanceList) {
      this.allDropdownItemsSelected =
        this.maintenanceList.length ===
        this.maintenanceList.filter((item) => item.selected)?.length;
    }
  }

  /**
   * Filter selected maintenances then return the name of those objects into the main input element
   * @param list
   */
  getSelectedMaintenanceName(list: MaintenanceSelectItem[]): string[] {
    const selectedItems: MaintenanceSelectItem[] = list?.filter(
      (obj: MaintenanceSelectItem) => obj.selected
    );
    return selectedItems?.map(
      (obj: MaintenanceSelectItem) => obj.maintenanceItemName
    );
  }

  /**
   * Handle item dropdown changes
   */
  async itemDropdownChanged(): Promise<void> {
    this.selectedItemsSwitch.forEach(
      (item: MaintenanceSelectItem) =>
        (item.selected = this.selectedItemOptions.includes(
          item.maintenanceItemName
        ))
    );
    this.determineAllSelectionState();
    this.$emit(
      'filterData',
      this.selectedItemsSwitch.filter(
        (item: MaintenanceSelectItem) => item.selected
      )
    );
  }

  /**
   * Toggle all dropdown items selection
   */
  async toggleAllDropdownItemsSelected(): Promise<void> {
    this.allDropdownItemsSelected = !this.allDropdownItemsSelected;
    this.selectedItemsSwitch.forEach(
      (item: MaintenanceSelectItem) =>
        (item.selected = this.allDropdownItemsSelected)
    );
    this.selectedItemOptions = this.selectedItemsSwitch
      .filter((item: MaintenanceSelectItem) => item.selected)
      .map((item: MaintenanceSelectItem) => item.maintenanceItemName);
    this.$emit(
      'filterData',
      this.selectedItemsSwitch.filter(
        (item: MaintenanceSelectItem) => item.selected
      )
    );
  }
}
</script>

<template>
  <el-select
    :class="
      selectedItemOptions.length > 1
        ? 'filter-select bigger-list'
        : 'filter-select'
    "
    v-model="selectedItemOptions"
    ref="itemFilterRef"
    multiple
    collapse-tags
    default-first-option
    :placeholder="$t('maintenance.maintenanceHistory.selectMaintenanceItem')"
    @change="itemDropdownChanged"
    :disabled="disabled"
  >
    <div
      class="filter-select-items-select-all"
      @click="toggleAllDropdownItemsSelected"
    >
      <span :style="true ? 'opacity: 1' : 'opacity: 0.7'">{{
        $t('common.all')
      }}</span>
      <el-switch
        :value="allDropdownItemsSelected"
        class="el-switch-component"
        active-color="var(--Main)"
        inactive-color="#D5D5D5"
      >
      </el-switch>
    </div>
    <el-divider class="filter-select-item-divider" />
    <el-option
      class="filter-select-option filter-select-items-option"
      v-for="item in selectedItemsSwitch"
      :key="item.maintenanceItemId"
      :value="item.maintenanceItemName"
    >
      <span :style="item.selected ? 'opacity: 1' : 'opacity: 0.7'">{{
        item.maintenanceItemName
      }}</span>
      <el-switch
        :value="item.selected"
        class="el-switch-component"
        active-color="var(--Main)"
        inactive-color="#D5D5D5"
      >
      </el-switch>
    </el-option>
  </el-select>
</template>

<style lang="scss" scoped>
.filter-select {
  width: 100%;
}

.filter-select-option {
  &.hover {
    background-color: unset !important;
  }

  &:hover {
    background-color: var(--DropdownHover) !important;
  }
}

.search-container {
  display: flex;
  align-items: center;

  .filter-select :deep(.el-input__inner) {
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    border-right: transparent !important;
  }

  // This is copied from the timeselector?
  .search-select-input :deep(.el-input__inner) {
    width: 300px;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
  }

  .search-select-dropdown :deep(.el-input__inner) {
    border-right: inherit;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
  }

  .search-select-dropdown {
    margin-right: 0;
  }
}

.filter-select-items-search-input {
  width: 210px;
  margin: 4px 20px;

  :deep(.el-input__inner) {
    width: 100%;
  }
}

.filter-select-items-select-all,
.filter-select-items-option {
  padding: 0 1rem !important; // has to be important to override some other css
  height: 34px; // The dropdown widget sets the height fixed instead using the padding
  display: flex;
  align-items: center;
  color: #606266;
  font-size: 16px;

  &:hover {
    cursor: pointer;
    background-color: var(--DropdownHover) !important;
  }

  span {
    color: #373e41;
    font-weight: 400;
  }
}

.filter-select-item-divider {
  margin: 4px 0;
}

.el-icon-search {
  line-height: 40px;
  color: rgba(0, 0, 0, 0.6);
  font-size: 18px;
  cursor: pointer;
  margin-right: 8px;
  margin-top: -4px;
}

.el-switch-component {
  margin: auto 0 auto auto;
}

.column-button {
  margin-left: auto;
  cursor: pointer;
  font-size: 16px;
  font-family: $font-Roboto-Medium;
  line-height: 19px;
  color: #373e41;

  :hover {
    color: var(--Main);
  }

  > img {
    width: 14px;
    height: 14px;
    margin-right: 8px;
  }
}

.column-checkbox {
  display: block;
  color: #373e41 !important;
}

.el-select-dropdown__item.selected::after {
  display: none;
}

.bigger-list :deep(.el-tag.el-tag--info) {
  max-width: 120px;
}
</style>
