import { getAssetTypes } from '@/api/report';
import { objectEntries, objectFromEntries } from './collections';

const assetTypesStorageName: string = 'assetTypesList';

export enum AssetType {
  All = 'ASSTYP_ALL',
  TippingVehicle = 'ASSTYP_TIPPING_VEHICLES',
  MobileCompactor = 'ASSTYP_MOBILE_COMPACTOR',
  StaticCompactor = 'ASSTYP_STATIC_COMPACTOR',
  Crane = 'ASSTYP_CRANES',
  SkipLoader = 'ASSTYP_SKIP_LOADERS',
  WasteCollTruck = 'ASSTYP_WASTE_COLL_TRUCKS',
  WasteBin = 'ASSTYP_WASTE_BINS',
  AlbaStaticCompactor = 'ASSTYP_ALBA_STATIC_COMPACTOR',
  RefuseCollectionVehicle = 'ASSTYP_REFUSE_COLLECTION_VEHICLE',
  GenericAsset = 'ASSTYP_GENERIC',
}

export enum AssetField {
  AssetId = 'AssetId',
  VIN = 'VIN',
  SerialNumber = 'SerialNumber',
}

export enum AssetStage {
  Register = 'Register',
  Connect = 'Connect',
  Assign = 'Assign',
}

export enum AssetFieldStateType {
  NotApplicable = 'NotApplicable',
  ReadOnly = 'ReadOnly',
  Optional = 'Optional',
  Required = 'Required',
}

export interface AssetFieldState {
  type: AssetFieldStateType;
  maxLength?: number;
}

// Currently on production only TV and MC asset types are actively used, meanwhile
// on dev we have a bunch of others that have the same field mapping as MCs.
//
// To not change dev's behaviour (and copy paste the same structure a bunch of times)
// this mapping will be used for every other Asset Type until a change is not required there.
//
// In that case create a new mapping in `assetTypeToFields`.
const mobileCompactorToFields = {
  [AssetField.AssetId]: {
    [AssetStage.Register]: {
      type: AssetFieldStateType.Optional,
      maxLength: 12,
    },
    [AssetStage.Connect]: {
      type: AssetFieldStateType.Required,
      maxLength: 12,
    },
    [AssetStage.Assign]: {
      type: AssetFieldStateType.NotApplicable,
    },
  },
  [AssetField.VIN]: {
    [AssetStage.Register]: {
      type: AssetFieldStateType.NotApplicable,
    },
    [AssetStage.Connect]: {
      type: AssetFieldStateType.NotApplicable,
    },
    [AssetStage.Assign]: {
      type: AssetFieldStateType.NotApplicable,
    },
  },
  [AssetField.SerialNumber]: {
    [AssetStage.Register]: {
      type: AssetFieldStateType.Required,
    },
    [AssetStage.Connect]: {
      type: AssetFieldStateType.Required,
    },
    [AssetStage.Assign]: {
      type: AssetFieldStateType.Required,
    },
  },
};

const assetTypeToFields: Record<
  AssetType,
  Record<AssetField, Record<AssetStage, AssetFieldState>>
> = {
  [AssetType.TippingVehicle]: {
    [AssetField.AssetId]: {
      [AssetStage.Register]: {
        type: AssetFieldStateType.Optional,
        maxLength: 12,
      },
      [AssetStage.Connect]: {
        type: AssetFieldStateType.Required,
        maxLength: 12,
      },
      [AssetStage.Assign]: {
        type: AssetFieldStateType.NotApplicable,
      },
    },
    [AssetField.VIN]: {
      [AssetStage.Register]: {
        type: AssetFieldStateType.Optional,
        maxLength: 17,
      },
      [AssetStage.Connect]: {
        type: AssetFieldStateType.Optional,
        maxLength: 17,
      },
      [AssetStage.Assign]: {
        type: AssetFieldStateType.Optional,
        maxLength: 17,
      },
    },
    [AssetField.SerialNumber]: {
      [AssetStage.Register]: {
        type: AssetFieldStateType.NotApplicable,
      },
      [AssetStage.Connect]: {
        type: AssetFieldStateType.NotApplicable,
      },
      [AssetStage.Assign]: {
        type: AssetFieldStateType.NotApplicable,
      },
    },
  },
  [AssetType.MobileCompactor]: mobileCompactorToFields,
  [AssetType.AlbaStaticCompactor]: mobileCompactorToFields,
  [AssetType.All]: mobileCompactorToFields,
  [AssetType.Crane]: mobileCompactorToFields,
  [AssetType.GenericAsset]: mobileCompactorToFields,
  [AssetType.RefuseCollectionVehicle]: mobileCompactorToFields,
  [AssetType.SkipLoader]: mobileCompactorToFields,
  [AssetType.StaticCompactor]: mobileCompactorToFields,
  [AssetType.WasteBin]: mobileCompactorToFields,
  [AssetType.WasteCollTruck]: mobileCompactorToFields,
};

export function getAssetFieldStateType(
  type: AssetType,
  field: AssetField,
  stage: AssetStage
): AssetFieldState {
  return (
    assetTypeToFields[type]?.[field]?.[stage] ?? {
      type: AssetFieldStateType.NotApplicable,
    }
  );
}

export type AssetFieldToState = Record<AssetField, AssetFieldState>;

const defaultAssetFieldState: AssetFieldToState = {
  AssetId: { type: AssetFieldStateType.NotApplicable },
  SerialNumber: { type: AssetFieldStateType.NotApplicable },
  VIN: { type: AssetFieldStateType.NotApplicable },
};

export function getAssetFieldStates(
  stage: AssetStage,
  assetType?: AssetType
): AssetFieldToState {
  if (!assetType) return defaultAssetFieldState;

  return objectFromEntries(
    objectEntries(assetTypeToFields[assetType]).map(
      ([field, stages]): [AssetField, AssetFieldState] => [field, stages[stage]]
    )
  );
}

export async function getAssetTypesList(): Promise<any> {
  let assetTypes: any = sessionStorage.getItem(assetTypesStorageName);
  if (assetTypes) {
    return JSON.parse(assetTypes);
  } else {
    return await getAssetTypes().then((response) => {
      assetTypes = response.data;
      sessionStorage.setItem(assetTypesStorageName, JSON.stringify(assetTypes));

      return assetTypes;
    });
  }
}

export interface ReportAssetType {
  assetType: string;
  kpis: ReportAssetTypeKpi[];
}

export interface ReportAssetTypeKpi {
  code: string;
  statisticsMethods: string[];
  unit: string;
}
