<script setup lang="ts">
import { AssetObjectModel, fetchAssetsByFlexibleFiltering } from '@/api/assets';
import { UUID } from '@/api/common';
import { EventName, getEventNames } from '@/api/event';
import { useActiveContext } from '@/auth/context';
import { useSelectedCustomerInfo } from '@/auth/selectedCustomer';
import { useLoggedInUser } from '@/auth/user';
import MultiSelectDropDown from '@/components/form/MultiSelectDropDown.vue';
import TimeSelect from '@/components/form/TimeSelect.vue';
import WidgetCard from '@/components/layout/widget/WidgetCard.vue';
import UtilTable from '@/components/table/UtilTable.vue';
import { useAsync } from '@/composables/async';
import { useRoute } from '@/composables/router';
import i18n from '@/lang';
import {
  Filter,
  FilterOperator,
  Pagination,
  QueryParameter,
  Sorter,
  SorterOrder,
} from '@/model/queryParameters/QueryParameter';
import { useOrganizationAssetsHierarchyQuery } from '@/query/assets';
import { getNoBackgroundFetchOptions } from '@/query/common';
import { useEventsQuery } from '@/query/events';
import {
  isNormalSingleAssetViewRoute,
  isOperationalSingleAssetViewRoute,
} from '@/router/links/assetOverview';
import {
  RouteNames,
  routeNameToAssetTypeMappping,
} from '@/router/modules/assets';
import { AssetType } from '@/utils/assetTypes';
import { isDefined, mapDeep } from '@/utils/collections';
import { isDesignatedCompany } from '@/utils/companyService';
import { stripPrefix } from '@/utils/string';
import { DateRange } from '@/utils/types/date';
import { Option } from '@/utils/types/option';
import {
  companyTypeToEventCategories,
  EventCategoryType,
  EventTypeCode,
  EVENT_CODE_PREFIX,
} from '@/utils/workData/lookuptable';
import { OPERATION_LOG_TABLE_CUSTOMIZATION } from '@/utils/workData/tableCustomization';
import EventDetailsModal from '@/views/dashboard/components/EventDetailsModal.vue';
import {
  getOperationalDashboardColumns,
  getTableColumns,
  mapEventCategoriesToOptions,
} from '@/widgets/utils/events';
import { computed, Ref, ref, watch } from 'vue';

interface Organization {
  orgId: UUID;
  orgName: string;
}

const props = defineProps<{ operationalSupportMode: boolean }>();

interface TableFilterConfiguration<K extends string> {
  name: string;
  availableOptions: Ref<Option<K>[] | undefined>;
  userSelection: Ref<K[] | undefined>;
  updateSelection: (newSelection: K[]) => void;
  getQueryParamsFilter: (selection: K[]) => Filter | undefined;
  renderFilter: boolean;
}

interface TableFilter<K extends string> extends TableFilterConfiguration<K> {
  inUseSelection: Ref<K[]>;
}

const loggedInUser = useLoggedInUser();
const route = useRoute();

const assetType = computed((): AssetType | undefined => {
  const routeName = route.value.name as RouteNames;
  return routeNameToAssetTypeMappping[routeName];
});

const context = useActiveContext();
const isSingleAssetView = computed(
  (): boolean =>
    isNormalSingleAssetViewRoute(route.value) ||
    isOperationalSingleAssetViewRoute(route.value)
);

const getOrganizationAssetsQuery = useOrganizationAssetsHierarchyQuery(
  assetType,
  !props.operationalSupportMode ||
    (props.operationalSupportMode &&
      context.value?.selectedCompanyId !== undefined)
);

const { companies } = useSelectedCustomerInfo();

const allAssets = useAsync(
  computed(async (): Promise<AssetObjectModel[]> => {
    const filters: QueryParameter = {
      filters: [
        {
          name: 'assetType',
          operator: FilterOperator.EQUAL,
          value: [assetType.value ?? ''],
        },
      ],
    };

    const response = await fetchAssetsByFlexibleFiltering(filters);

    return response.data.assets;
  })
);

const organizations = useAsync(
  computed(async (): Promise<Organization[]> => {
    if (!loggedInUser.value) {
      throw new Error('LoggedInUser is not defined.');
    }
    if (!context.value) {
      throw new Error('ActiveContext is not defined.');
    }

    if (context.value.organization === undefined) {
      throw new Error('Organization not set');
    }

    return mapDeep(
      context.value.organization,
      'children',
      (org): Organization => ({
        orgId: org.id,
        orgName: org.name,
      })
    );
  })
);

const eventNames = useAsync(
  computed(async (): Promise<EventName[]> => {
    if (!loggedInUser.value || props.operationalSupportMode) {
      return [];
    }

    return getEventNames(
      assetType.value ? [assetType.value] : undefined,
      companyTypeToEventCategories[loggedInUser.value.companyType],
      loggedInUser.value.settings.i18nCode
    );
  })
);

const uecEventTypes = useAsync(
  computed(async (): Promise<EventName[]> => {
    if (!props.operationalSupportMode) return [];

    return getEventNames(
      assetType.value ? [assetType.value] : undefined,
      [EventCategoryType.uec],
      loggedInUser.value?.settings.i18nCode
    );
  })
);

function buildCategoriesFilter(): TableFilterConfiguration<EventCategoryType> {
  const selectedCategories = ref<EventCategoryType[]>();
  return {
    name: i18n.t('assets.eventTypeCategory'),
    renderFilter: !props.operationalSupportMode,
    availableOptions: ref(
      mapEventCategoriesToOptions(
        companyTypeToEventCategories[loggedInUser.value!.companyType]
      )
    ),
    userSelection: selectedCategories,
    updateSelection(newSelection) {
      selectedCategories.value = newSelection;
    },
    getQueryParamsFilter(selection) {
      if (selection.length === 0) {
        return undefined;
      }

      return {
        name: 'eventTypeCategoryCode',
        operator: FilterOperator.IN,
        value: selection,
      };
    },
  };
}

function buildEventNamesFilter(): TableFilterConfiguration<EventTypeCode> {
  const selectedEventNames = ref<EventTypeCode[]>();

  return {
    name: i18n.t('eventsWidget.eventName'),
    renderFilter: !props.operationalSupportMode,
    availableOptions: computed(() => {
      return eventNames.value.data?.map(
        (item): Option<EventTypeCode> => ({
          key: item.eventTypeCode,
          label: item.eventTypeName,
        })
      );
    }),
    userSelection: selectedEventNames,
    updateSelection(newSelection) {
      selectedEventNames.value = newSelection;
    },
    getQueryParamsFilter(selection) {
      if (selection.length === 0) {
        return undefined;
      }

      return {
        name: 'eventTypeCode',
        operator: FilterOperator.IN,
        value: selection,
      };
    },
  };
}

function buildAssetsFilter(): TableFilterConfiguration<UUID> {
  const selectedAssets = ref<UUID[]>();

  return {
    name: i18n.t('operationLog.UICOL_OPERATION_LOG_ASSET'),
    renderFilter: !isSingleAssetView.value,
    availableOptions: computed(() => {
      if (
        props.operationalSupportMode &&
        context.value?.selectedCompanyId === undefined
      ) {
        return allAssets.value.data?.map(
          (item): Option<UUID> => ({
            key: item.id,
            label: item.companyAssetId,
          })
        );
      }

      if (!getOrganizationAssetsQuery.data.value) return [];

      return mapDeep(
        getOrganizationAssetsQuery.data.value,
        'suborganizations',
        (org) =>
          org.assets.map(
            (asset): Option<UUID> => ({
              key: asset.id,
              label: asset.companyAssetId,
            })
          )
      ).flat();
    }),
    userSelection: selectedAssets,
    updateSelection(newSelection) {
      selectedAssets.value = newSelection;
    },
    getQueryParamsFilter(selection) {
      if (selection.length === 0) {
        return undefined;
      }

      return {
        name: 'assetId',
        operator: FilterOperator.IN,
        value: selection,
      };
    },
  };
}

function buildCustomersFilter(): TableFilterConfiguration<UUID> {
  const selectedCustomers = ref<UUID[]>();

  return {
    name: i18n.t('customerModule.customer'),
    renderFilter:
      props.operationalSupportMode &&
      context.value?.selectedCompanyId === undefined,
    availableOptions: computed(() => {
      return companies.value.data?.map(
        (company): Option<UUID> => ({
          key: company.customerId,
          label: company.customerName,
        })
      );
    }),
    userSelection: selectedCustomers,
    updateSelection(newSelection) {
      selectedCustomers.value = newSelection;
    },
    getQueryParamsFilter(selection) {
      if (selection.length === 0) {
        return undefined;
      }

      return {
        name: 'companyId',
        operator: FilterOperator.IN,
        value: selection,
      };
    },
  };
}

function buildOrganizationsFilter(): TableFilterConfiguration<UUID> {
  const selectedOrganizations = ref<UUID[]>();

  return {
    name: i18n.t('assets.organization'),
    renderFilter:
      (!isSingleAssetView.value && !props.operationalSupportMode) ||
      (props.operationalSupportMode &&
        context.value?.selectedCompanyId !== undefined),
    availableOptions: computed(() => {
      return (
        organizations.value.data?.map(
          (item): Option<UUID> => ({ key: item.orgId, label: item.orgName })
        ) ?? []
      );
    }),
    userSelection: selectedOrganizations,
    updateSelection(newSelection) {
      selectedOrganizations.value = newSelection;
    },
    getQueryParamsFilter(selection) {
      if (selection.length === 0) {
        return undefined;
      }

      return {
        name: 'organizationId',
        operator: FilterOperator.IN,
        value: selection,
      };
    },
  };
}

function buildUECEventCodes(): TableFilterConfiguration<string> {
  const selectedUECEventCodes = ref<string[]>();

  return {
    name: i18n.t('operationLog.UICOL_OPERATION_LOG_EVENT_TYPE_CODE_UEC'),
    renderFilter: props.operationalSupportMode,
    availableOptions: computed(() => {
      return uecEventTypes.value.data?.map(
        (item): Option => ({
          key: item.eventTypeCode,
          label: stripPrefix(item.eventTypeCode, EVENT_CODE_PREFIX),
        })
      );
    }),
    userSelection: selectedUECEventCodes,
    updateSelection(newSelection) {
      selectedUECEventCodes.value = newSelection;
    },
    getQueryParamsFilter(selection) {
      if (selection.length == 0) {
        return undefined;
      }

      return {
        name: 'eventTypeCode',
        operator: FilterOperator.IN,
        value: selection,
      };
    },
  };
}

const allFilters: Record<string, TableFilterConfiguration<any>> = {
  uecCodes: buildUECEventCodes(),
  categories: buildCategoriesFilter(),
  names: buildEventNamesFilter(),
  assets: buildAssetsFilter(),
  customers: buildCustomersFilter(),
  organizations: buildOrganizationsFilter(),
} as const;

function tableFilterConfigurationToTableFilter<K extends string>(
  config: TableFilterConfiguration<K>
): TableFilter<K> {
  return {
    ...config,
    inUseSelection: computed(
      () =>
        config.userSelection.value ??
        config.availableOptions.value?.map((option) => option.key) ??
        []
    ),
  };
}

const tableFilters = computed((): TableFilter<any>[] => {
  const filters: TableFilterConfiguration<any>[] = Object.entries(allFilters)
    .filter(([_, filterConfig]) => filterConfig.renderFilter === true)
    .map(([_, filterConfig]) => filterConfig);

  return filters.map(tableFilterConfigurationToTableFilter);
});

const selectedTimespan = ref<DateRange>();

function updateSelectedDateRange(newRange: DateRange) {
  selectedTimespan.value = newRange;
}

const queryFilters = computed((): Filter[] | undefined => {
  const userFilters = tableFilters.value
    .map((filter) => filter.getQueryParamsFilter(filter.inUseSelection.value))
    .filter(isDefined);
  const hasEmptyFilters = userFilters.length !== tableFilters.value.length;

  if (hasEmptyFilters) {
    return undefined;
  }

  const queryParamsFilters: Filter[] = [
    assetType.value
      ? {
          name: 'assetTypeCode',
          operator: FilterOperator.EQUAL,
          value: [assetType.value],
        }
      : undefined,
    ...userFilters,
    isSingleAssetView.value
      ? {
          name: 'assetId',
          operator: FilterOperator.IN,
          value: [route.value.params['id']],
        }
      : undefined,
    selectedTimespan.value
      ? {
          name: 'timestamp',
          operator: FilterOperator.BETWEEN_EXCLUSIVE,
          value: [
            selectedTimespan.value.start,
            selectedTimespan.value.endExclusive,
          ],
        }
      : undefined,
    loggedInUser && loggedInUser.value?.settings.i18nCode
      ? {
          name: 'i18nCode',
          operator: FilterOperator.EQUAL,
          value: [loggedInUser.value?.settings.i18nCode],
        }
      : undefined,
  ].filter(isDefined);

  return queryParamsFilters;
});

const DEFAULT_SORTER: Sorter = {
  field: 'timestamp',
  order: SorterOrder.DESC,
};
const sorter = ref<Sorter>(DEFAULT_SORTER);
const pagination = ref<Pagination>({
  page: 1,
  size: loggedInUser?.value?.settings.gridPageSize ?? 15,
});
const timezone = computed((): string | undefined =>
  loggedInUser.value && isDesignatedCompany(loggedInUser.value.companyType)
    ? context.value.primaryOrgTimeZone
    : loggedInUser.value?.settings.timeZone
);
const queryParameters = computed((): QueryParameter | undefined => {
  if (queryFilters.value === undefined) {
    return undefined;
  }

  return {
    filters: queryFilters.value,
    sorters: [sorter.value],
    pagination: pagination.value,
    timezone: timezone.value,
  };
});

watch(
  [queryFilters, sorter],
  () => {
    setPagination(1);
  },
  { deep: true }
);

const hasInvalidFilters = computed(
  (): boolean => queryParameters.value === undefined
);

const eventsQuery = useEventsQuery(queryParameters, {
  ...getNoBackgroundFetchOptions(),
  staleTime: 10 * 1000,
});
const selectedEventId = ref<UUID | undefined>();

function onSort(field: string, order: SorterOrder | '') {
  sorter.value = order
    ? {
        field,
        order,
      }
    : DEFAULT_SORTER;
}

function setPagination(page: number) {
  pagination.value = {
    ...pagination.value,
    page: page,
  };
}
</script>

<template>
  <WidgetCard style="display: flex">
    <div class="header-actions">
      <MultiSelectDropDown
        :style="{ maxWidth: isSingleAssetView ? '35%' : '100%' }"
        v-for="filter in tableFilters"
        v-loading="filter.availableOptions.value === undefined"
        :filter-label="filter.name"
        :options="filter.availableOptions.value ?? []"
        :selected-options="filter.inUseSelection.value"
        @change="filter.updateSelection"
      />

      <TimeSelect :customizable="true" @select="updateSelectedDateRange" />
    </div>

    <UtilTable
      v-loading="eventsQuery.isFetching.value === true"
      class="event-table"
      :element-loading-text="$t('events.loadingText')"
      :cols="
        !operationalSupportMode
          ? getTableColumns()
          : getOperationalDashboardColumns()
      "
      :table-list="
        hasInvalidFilters ? [] : eventsQuery.data.value?.events ?? []
      "
      :custom-rendering="OPERATION_LOG_TABLE_CUSTOMIZATION"
      :show-table-header-options="false"
      :page-total="eventsQuery.data.value?.total"
      :fitToParent="true"
      :page="pagination.page"
      @row-click="selectedEventId = $event.id"
      @handle-sort-change="onSort"
      @handle-pagination="setPagination"
    />

    <EventDetailsModal
      v-if="selectedEventId"
      :visible="true"
      :event-id="selectedEventId"
      :operationalSupportMode="props.operationalSupportMode"
      @quit-event-details-dialog="selectedEventId = undefined"
    />
  </WidgetCard>
</template>

<style scoped lang="scss">
.header-actions {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  padding: 16px;
  justify-content: flex-start;
}

.event-table {
  width: 100%;
  flex-direction: column;
}
</style>
