<script setup lang="ts">
import { UUID } from '@/api/common';
import {
  SubscriptionAsset,
  SubscriptionStatus,
} from '@/api/subscriptionManagement';
import { useLoggedInUser } from '@/auth/user';
import WidgetCard from '@/components/layout/widget/WidgetCard.vue';
import PureTable from '@/components/table/PureTable.vue';
import SelectTableHeader from '@/components/table/SelectTableHeader.vue';
import i18n from '@/lang';
import {
  FilterOperator,
  Pagination,
  SearchParam,
  Sorter,
  SorterOrder,
} from '@/model/queryParameters/QueryParameter';
import {
  useAssetSubscriptionsQuery,
  useRenewAssetSubscriptionMutation,
  useTerminateAssetSubscriptionMutation,
} from '@/query/subscription';
import { customFailedMessage, customSuccessMessage } from '@/utils/prompt';
import { TableAction } from '@/utils/types/columnCustomizationTypes';
import { CustomSelectionOptionsForSearching } from '@/utils/workData/lookuptable';
import {
  SUBSCRIPTION_FILTER_OPTIONS,
  SUBSCRIPTION_MANAGEMENT_COLS,
} from '@/utils/workData/subscriptionManagement';
import moment from 'moment';
import { ref, watch, watchEffect } from 'vue';
import RenewAssetSubscriptionDialog from './components/RenewAssetSubscriptionDialog.vue';
import TerminateAssetSubscriptionDialog from './components/TerminateAssetSubscriptionDialog.vue';

interface AssetRenew {
  assetId: UUID;
  companyAssetId: string;
  newSubscriptionEndDate: moment.Moment;
  subscriptionId: UUID;
}

interface TableData extends SubscriptionAsset {
  translatedSubscriptionStatus: string;
  translatedAssetType: string;
  translatedServiceRegion: string;
  translatedHyvaRegion: string;
  action: TableAction[];
}

const loggedInUser = useLoggedInUser();

const pagination = ref<Pagination>({
  page: 1,
  size: loggedInUser.value?.settings.gridPageSize ?? 25,
});
const sortBy = ref<Sorter | undefined>(undefined);

// NOTE: This is updated inside SelectTableHeader.
// Can't update SelectTableHeader to emit an event without major change, it's used in 20+ other components.
const searchParams = ref<SearchParam>({
  value: null,
  reference: CustomSelectionOptionsForSearching.SearchBySubscriptionStatus,
  operator: FilterOperator.EQUAL,
});
const tableFilters = ref(SUBSCRIPTION_FILTER_OPTIONS);

watch(
  searchParams,
  () => {
    if (!searchParams.value.value) return;

    pagination.value = {
      ...pagination.value,
      page: 1,
    };
  },
  { deep: true }
);

const {
  data,
  isLoading,
  isFetching,
  error: dataError,
} = useAssetSubscriptionsQuery(pagination, sortBy, searchParams, (value) => {
  return {
    ...value,
    assets: value.assets.map(
      (asset): TableData => ({
        ...asset,
        translatedAssetType: i18n.t(asset.assetType),
        translatedServiceRegion: asset.serviceRegion
          ? i18n.t(asset.serviceRegion)
          : '',
        translatedHyvaRegion: asset.hyvaRegion ? i18n.t(asset.hyvaRegion) : '',
        translatedSubscriptionStatus: i18n.t(asset.subscriptionStatus),
        action: getAssetActions(asset),
      })
    ),
  };
});

watchEffect(() => {
  if (dataError.value) {
    console.error(dataError.value);
    customFailedMessage(i18n.t('common.errorWithFetchingData'));
  }
});

function getAssetActions(asset: SubscriptionAsset): TableAction[] {
  if (asset.subscriptionStatus === SubscriptionStatus.Terminated) {
    return [];
  }

  return [
    asset.autoRenewalOn
      ? {
          name: i18n.t('subsMgmt.autoRenewalIsOn'),
          disabled: true,
        }
      : {
          name: i18n.t('subsMgmt.renewBtn'),
          disabled: false,
          onClickEmitterName: 'renew-click',
        },
    {
      name: i18n.t('subsMgmt.terminateBtn'),
      disabled: false,
      onClickEmitterName: 'terminate-click',
    },
  ];
}

function handleSortChange(field: string, order: SorterOrder | '') {
  sortBy.value =
    order === ''
      ? undefined
      : {
          field,
          order,
        };
}

function setPagination(page: number) {
  pagination.value = {
    ...pagination.value,
    page: page,
  };
}

const tableCols = ref(
  SUBSCRIPTION_MANAGEMENT_COLS.map((item) => ({
    ...item,
    label: i18n.t(item.label),
  }))
);

const selectedAssetForRenewal = ref<AssetRenew>();

function setSelectedAssetForRenewal(asset: AssetRenew | undefined) {
  selectedAssetForRenewal.value = asset;
}

function onRenewClick(asset: SubscriptionAsset) {
  const subscriptionDurationInfo = moment.duration(asset.renewPeriod);
  const subscriptionEndDate = moment(asset.subscriptionEndDate);
  const now = moment();
  const renewalStartDate = moment.max(now, subscriptionEndDate);

  setSelectedAssetForRenewal({
    companyAssetId: asset.companyAssetId,
    newSubscriptionEndDate: renewalStartDate.add(subscriptionDurationInfo),
    assetId: asset.assetId,
    subscriptionId: asset.assignmentId,
  });
}

const renewMutation = useRenewAssetSubscriptionMutation();
async function finalizeAssetSubscriptionRenew() {
  if (selectedAssetForRenewal.value === undefined) {
    return;
  }

  renewMutation.mutate({
    assetId: selectedAssetForRenewal.value.assetId,
    assignmentId: selectedAssetForRenewal.value.subscriptionId,
  });

  setSelectedAssetForRenewal(undefined);
  customSuccessMessage(i18n.t('subsMgmt.subsRenewedSuccessfully'));
}

const selectedAssetForTermination = ref<SubscriptionAsset>();
function setSelectedAssetForTermination(asset: SubscriptionAsset | undefined) {
  selectedAssetForTermination.value = asset;
}

const terminateMutation = useTerminateAssetSubscriptionMutation();
async function finalizeAssetSubscriptionTermination() {
  if (selectedAssetForTermination.value === undefined) {
    return;
  }

  terminateMutation.mutate({
    assetId: selectedAssetForTermination.value.assetId,
    assignmentId: selectedAssetForTermination.value.assignmentId,
  });

  setSelectedAssetForTermination(undefined);
  customSuccessMessage(i18n.t('subsMgmt.subsTerminatedSuccessfully'));
}
</script>

<template>
  <WidgetCard
    class="card"
    :expandable="false"
    :default-title="$t('subsMgmt.title')"
    :loading="isLoading"
  >
    <div style="padding: 20px 20px 0px 20px">
      <SelectTableHeader
        :searchParams="searchParams"
        :cols="tableCols"
        :searchFieldOptions="tableFilters"
      />
    </div>

    <PureTable
      v-loading="isFetching"
      style="padding: 20px"
      :cols="tableCols"
      :tableList="data?.assets ?? []"
      :total="data?.total"
      @handle-sort-change="handleSortChange"
      @handle-page="setPagination"
      @renew-click="onRenewClick"
      @terminate-click="setSelectedAssetForTermination"
    />

    <TerminateAssetSubscriptionDialog
      v-if="selectedAssetForTermination"
      :company-asset-id="selectedAssetForTermination.companyAssetId"
      @cancel="setSelectedAssetForTermination(undefined)"
      @confirm="finalizeAssetSubscriptionTermination"
    />
    <RenewAssetSubscriptionDialog
      v-if="selectedAssetForRenewal"
      :company-asset-id="selectedAssetForRenewal.companyAssetId"
      :new-subscription-end-date="
        selectedAssetForRenewal.newSubscriptionEndDate
      "
      @cancel="setSelectedAssetForRenewal(undefined)"
      @confirm="finalizeAssetSubscriptionRenew"
    />
  </WidgetCard>
</template>

<style scoped lang="scss">
.card {
  display: flex;
  flex-direction: column;
  height: 100%;
}
</style>
