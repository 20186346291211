/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon';
icon.register({
  maintenance: {
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
    data: '<path pid="0" data-name="减去 15" d="M14 20H6a6.007 6.007 0 01-6-6V6a6.007 6.007 0 016-6h8a6.007 6.007 0 016 6v8a6.006 6.006 0 01-6 6zM6 1a5.006 5.006 0 00-5 5v8a5.006 5.006 0 005 5h8a5.005 5.005 0 005-5V6a5.006 5.006 0 00-5-5z" _fill="#373e41"/><g data-name="组 3006" _fill="#373e41"><path pid="1" data-name="减去 14" d="M12.617 16.203a2.315 2.315 0 01-.547-2.385L6.516 8.264a2.3 2.3 0 01-2.93-2.93l1.253 1.253a1 1 0 001.414-1.414L5 3.92a2.3 2.3 0 012.93 2.93l5.555 5.555a2.3 2.3 0 012.93 2.93l-.97-.97a1 1 0 00-1.414 1.414l.97.97a2.314 2.314 0 01-2.384-.546z"/><g data-name="组 3005"><path pid="2" data-name="矩形 975" d="M7.908 11.777l1.414 1.414-3.535 3.536a1 1 0 01-1.415 0 1 1 0 010-1.414l3.536-3.536z"/><path pid="3" data-name="联合 54" d="M11.012 9.225l2.121-2.121.844.843-2.122 2.122zm2.127-2.115l1.132-1.697 1.697-1.131.848.848-1.131 1.697-1.697 1.132z"/></g></g>',
  },
});
