export function disabledFutureDate(date: Date): boolean {
  return date.getTime() > Date.now();
}

export function disabledPastDate(date: Date): boolean {
  return date.getTime() < Date.now() - 1000 * 3600 * 24;
}

/**
 * Number of days for a date period: DATP_DAY
 */
export const NUMBER_OF_DAYS_FOR_DATE_PERIOD_DAYS = 14;

/**
 * Number of days for a date period: DATP_WEEKS
 */
export const NUMBER_OF_DAYS_FOR_DATE_PERIOD_WEEKS = 13 * 7;

/**
 * Number of days for a date period: DATP_MONTHS
 */
export const NUMBER_OF_DAYS_FOR_DATE_PERIOD_MONTHS = 365;

export const NUMBER_OF_HOURS_IN_A_DAY = 24;
