<script lang="ts">
import { ALL_PAGE_CODES } from '@/api/users';
import TableContainer from '@/components/table/TableContainer.vue';
import { UserModule } from '@/store/modules/user';
import { ALL_CLAIMS_CODES, COMPANY_TYPE } from '@/utils/workData/lookuptable';
import { Component, Vue } from 'vue-property-decorator';
import AlarmConfigurationForCustomer from '../alarm/settingsForCustomer/AlarmConfigurationForCustomer.vue';
import Geofence from './geofence/Geofence.vue';
import OrgMgmt from './orgMgmt/OrgMgmt.vue';
import UserMgmt from './userMgmt/UserMgmt.vue';
@Component({
  name: 'Administration',
  components: {
    AlarmConfigurationForCustomer,
    Geofence,
    OrgMgmt,
    TableContainer,
    UserMgmt,
  },
})
export default class extends Vue {
  /** Local variables */
  ALL_CLAIMS_CODES = ALL_CLAIMS_CODES;
  activeName: string = 'userTab';
  menuAccess: Partial<Record<ALL_PAGE_CODES, boolean>> = {
    AUTHRSC_PAGE_USERMNG: false,
    AUTHRSC_PAGE_ORGMNG: false,
    AUTHRSC_PAGE_CUST_ALARM_CONFIGURATION: false,
    AUTHRSC_PAGE_GEOFENCES: false,
  };

  async mounted() {
    const keys = Object.keys(this.menuAccess) as ALL_PAGE_CODES[];
    for (let i = 0; i < keys.length; i++) {
      this.menuAccess[keys[i]] = await UserModule.hasMenu(keys[i]);
    }
    this.showTabs();
  }

  /**
   * Handle tab click
   */
  handleTabClick(): void {
    if (
      this.activeName === 'orgTab' &&
      this.$route.query.activeName !== 'orgTab'
    ) {
      this.$router.push('/administration/index?activeName=orgTab');
    } else if (
      this.activeName === 'userTab' &&
      this.$route.query.activeName !== 'userTab'
    ) {
      this.$router.push('/administration/index?activeName=userTab');
    } else if (
      this.activeName === 'geofences' &&
      this.$route.query.activeName !== 'geofences'
    ) {
      this.$router.push('/administration/index?activeName=geofences');
    } else if (
      this.activeName === 'alarmTab' &&
      this.$route.query.activeName !== 'alarmTab'
    ) {
      this.$router.push('/administration/index?activeName=alarmTab');
    }
  }

  /**
   * Show default tab view by route query or by page authorization code
   */
  async showTabs() {
    if (this.$route.query.activeName) {
      this.activeName = this.$route.query.activeName as string;
    } else {
      this.activeName = this.hasMenu(ALL_CLAIMS_CODES.AUTHRSC_PAGE_USERMNG)
        ? 'userTab'
        : this.hasMenu(ALL_CLAIMS_CODES.AUTHRSC_PAGE_ORGMNG)
        ? 'orgTab'
        : this.hasMenu(ALL_CLAIMS_CODES.AUTHRSC_PAGE_CUST_ALARM_CONFIGURATION)
        ? 'alarmTab'
        : this.hasMenu(ALL_CLAIMS_CODES.AUTHRSC_PAGE_GEOFENCES)
        ? 'geofences'
        : '';
    }
  }

  /**
   * Check if has menu access for showing tab
   * @param menu
   */
  hasMenu(menu: ALL_PAGE_CODES) {
    return this.menuAccess[menu];
  }

  /** Should not allow tab GEOFENCE and ALARM SETTINGS to be visible for company type of body builder or dealer even if there are claims */
  get tabNotVisibleForBodyBuilderOrDealerOrTruckOEM() {
    return ![
      COMPANY_TYPE.BodyBuilder,
      COMPANY_TYPE.TruckOEM,
      COMPANY_TYPE.Dealer,
    ].includes(UserModule.companyType);
  }
}
</script>

<template>
  <TableContainer style="display: flex; flex-direction: column">
    <el-tabs
      class="admin-tab"
      style="display: flex; flex-direction: column; height: 100%"
      v-model="activeName"
      @tab-click="handleTabClick"
    >
      <el-tab-pane
        v-if="hasMenu(ALL_CLAIMS_CODES.AUTHRSC_PAGE_USERMNG)"
        v-bind:label="$t('administrationModule.userManagement')"
        name="userTab"
      >
        <UserMgmt />
      </el-tab-pane>
      <el-tab-pane
        v-if="hasMenu(ALL_CLAIMS_CODES.AUTHRSC_PAGE_ORGMNG)"
        v-bind:label="$t('administrationModule.organizationManagement')"
        name="orgTab"
      >
        <OrgMgmt ref="orgMgmt" />
      </el-tab-pane>
      <el-tab-pane
        v-if="
          hasMenu(ALL_CLAIMS_CODES.AUTHRSC_PAGE_CUST_ALARM_CONFIGURATION) &&
          tabNotVisibleForBodyBuilderOrDealerOrTruckOEM
        "
        v-bind:label="$t('administrationModule.alarmSettings')"
        name="alarmTab"
      >
        <AlarmConfigurationForCustomer ref="alarmConfig" />
      </el-tab-pane>
      <el-tab-pane
        v-if="
          hasMenu(ALL_CLAIMS_CODES.AUTHRSC_PAGE_GEOFENCES) &&
          tabNotVisibleForBodyBuilderOrDealerOrTruckOEM
        "
        v-bind:label="$t('administrationModule.geofences')"
        name="geofences"
      >
        <geofence v-if="activeName === 'geofences'" ref="geofences" />
      </el-tab-pane>
    </el-tabs>
  </TableContainer>
</template>

<style lang="scss" scoped>
.admin-tab :deep(.el-tabs__item) {
  font-size: 1.428571rem !important; //20px
}
:deep(.el-tab-pane) {
  height: 100%;
}
</style>
