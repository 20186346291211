<script lang="ts">
import {
  AccessibleAssetsObjectModel,
  getAccessibleAssetsByFlexibleFiltering,
} from '@/api/accessibleAssets';
import { AssetObjectModel, fetchAssetsByFlexibleFiltering } from '@/api/assets';
import { getCustomerById, updateActivationStatus } from '@/api/customer';
import { AssetsWithKpis } from '@/api/kpis';
import {
  getOrganizations,
  getOrgById,
  Organization,
} from '@/api/organizations';
import {
  DEFAULT_SUBSCRIPTION_PACKAGES_REQUEST_SIZE,
  getCompanySubscriptions,
  getSubscriptionPackages,
} from '@/api/subscriptionPackages';
import { LoggedInUserRef, useLoggedInUser } from '@/auth/user';
import NewCard from '@/components/cusCard/NewCard.vue';
import DeactivateDialog from '@/components/dialog/DeactivateDialog.vue';
import PureTable from '@/components/table/PureTable.vue';
import SelectTableHeader from '@/components/table/SelectTableHeader.vue';
import {
  addFilter,
  Filter,
  FilterOperator,
  Pagination,
  QueryParameter,
  Sorter,
  SorterOrder,
} from '@/model/queryParameters/QueryParameter';
import { useMultiAssetKpisQuery } from '@/query/kpis';
import { PageModule } from '@/store/modules/page';
import { UserModule } from '@/store/modules/user';
import { isHyvaCompany } from '@/utils/companyService';
import { ACCESS_TIME, CREATED_ON, ORGANIZATION_ID } from '@/utils/constants';
import {
  customFailedMessage,
  promptFailedBox,
  promptSuccessBox,
} from '@/utils/prompt';
import {
  ASSETS_SEARCH_OPTION_COLS,
  ASSIGNED_ASSETS_COLS,
  ASSIGNED_ASSETS_SEARCH_OPTION_COLS,
  CUSTOMER_MANAGEMENT_DESIGNATED_USER_ASSETS_COLS,
} from '@/utils/workData/assetsMgmt';
import {
  ACTIVATION_STATUS,
  ALL_CLAIMS_CODES,
  COMPANY_TYPE,
  COMPANY_TYPES_THAT_REQUIRE_ACCESSIBLE_ASSETS,
  DESIGNATED_COMPANY_TYPES,
  KPI_FIELDS,
  PACKAGE_TYPE,
} from '@/utils/workData/lookuptable';
import { ORG_COLS } from '@/utils/workData/orgMgmt';
import { USER_COLS, USER_SEARCH_FIELDS } from '@/utils/workData/userMgmt';
import { UseQueryReturnType } from '@tanstack/vue-query';
import { computed } from 'vue';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { FleetStatusKpis } from '../assets/components/AssetsWidgetTable.vue';
import CustomerSubscriptionPackages from './components/CustomerSubscriptionPackages.vue';
import DashboardTemplate from './components/DashboardTemplate.vue';
import GeneralInfomation from './components/GeneralInfomation.vue';
import OrganizationManagement from './components/organizationManagement/OrganizationManagement.vue';
import UserManagement from './components/userManagement/UserManagement.vue';

interface TranslateColumns {
  translatedAssetType: string;
  translatedStatus: string;
  translatedServiceRegion: string;
  translatedHyvaRegion: string;
}

interface AssetObjectModelKpi extends AssetObjectModel {
  lastCommunicationTime?: string;
}

interface AssetObjectModelRowData
  extends AssetObjectModelKpi,
    TranslateColumns {}

interface AccessibleAssetsRowData
  extends AccessibleAssetsObjectModel,
    TranslateColumns {}

@Component({
  name: 'CustomerInfo',
  components: {
    'new-card': NewCard,
    'pure-table': PureTable,
    'general-information': GeneralInfomation,
    'deactivate-dialog': DeactivateDialog,
    'subscription-packages': CustomerSubscriptionPackages,
    DashboardTemplate,
    'select-table-header': SelectTableHeader,
    'user-management': UserManagement,
    'organization-management': OrganizationManagement,
  },
})
export default class extends Vue {
  @Prop({ required: true }) customerId!: string;

  customerInfo: any = {};
  activateOrDeactivate: string = '';
  logo: any = '';
  title: string = '';
  selectedAssetes: string[] = [];
  customerType: string = '';
  totalUsers: number = 0;
  activeName: string = 'generalInfoTab';
  visiable: boolean = false;
  dialogContent: any = '';
  currentPage = 1;
  orgNum: number = 0;
  userCols = USER_COLS.map((item) => ({
    ...item,
    label: this.$t(item.label),
  }));
  orgCols = ORG_COLS.map((item) => ({
    ...item,
    label: this.$t(item.label),
  }));
  assetCols = ASSIGNED_ASSETS_COLS.map((item) => ({
    ...item,
    label: this.$t(item.label),
  }));
  accessibleAssetCols = CUSTOMER_MANAGEMENT_DESIGNATED_USER_ASSETS_COLS.map(
    (item) => ({
      ...item,
      label: this.$t(item.label),
    })
  );
  assetList: AssetObjectModel[] = [];
  accessibleAssetList: AccessibleAssetsObjectModel[] = [];
  userNum: number = 0;
  totalOrganizations: number = 0;
  assetTotal: number = 0;
  companySubscriptions: any | null = null;
  subscriptionPackages: any = {
    companyType: [],
    assetType: [],
  };
  packageType: any = PACKAGE_TYPE;
  source: string = 'view';
  subscriptionTabIsLoading: boolean = false;
  isViewModeLoading: boolean = false;
  userSearchFields: any = USER_SEARCH_FIELDS;
  templateId: string = '';
  pageSize: number = UserModule.gridPageSize;
  isAssetManagementLoading: boolean = false;
  userSortAndOrderData: any = {
    sortBy: null,
    order: null,
  };
  assetSortAndOrderData: any = {
    sortBy: null,
    order: null,
  };
  assetSearchParams: any = {
    reference: null,
    value: null,
  };
  messageTotalStatistics: string = '';
  totalStatistics: number = 0;
  isTotalStatisticsInfoVisible: boolean = false;
  assignedAssetsSearchFields: any = ASSETS_SEARCH_OPTION_COLS;
  queryParameter: QueryParameter = {};
  companyTypesThatRequiresAssetsByServiceRegion: string[] = [
    COMPANY_TYPE.Helpdesk,
  ];
  useAccessibleAssets!: boolean;
  requestAssetsForHelpdesk: boolean = false;
  helpdeskServiceRegions: string[] = [];
  selectedUserCanImpersonate: boolean = false;
  loggedInUser!: LoggedInUserRef;
  lastCommunicationKpiQuery?: UseQueryReturnType<
    AssetsWithKpis<FleetStatusKpis> | undefined,
    Error
  >;

  created() {
    if (this.$route.query.tab === 'assets') {
      this.activeName = 'assetsTab';
      this.$router.replace({ query: undefined });
    }
    this.loggedInUser = useLoggedInUser();
    this.prepareDefaultInitialization();

    if (!this.useAccessibleAssets) {
      this.lastCommunicationKpiQuery = useMultiAssetKpisQuery(
        computed(() => this.assetList.map((asset) => asset.id)),
        [KPI_FIELDS.LastCommunicationTime]
      );
    }
  }

  get assetsWithKpis(): AssetObjectModelKpi[] {
    if (
      this.lastCommunicationKpiQuery === undefined ||
      !this.lastCommunicationKpiQuery.data.value
    ) {
      return this.assetList;
    }

    return this.assetList.map(
      (asset): AssetObjectModelKpi => ({
        ...asset,
        lastCommunicationTime:
          this.lastCommunicationKpiQuery!.data.value![asset.id]?.[
            KPI_FIELDS.LastCommunicationTime
          ].v,
      })
    );
  }

  get translatedAssetsWithKpis(): AssetObjectModelRowData[] {
    return this.assetsWithKpis.map((item) => ({
      ...item,
      translatedAssetType: this.$t(item.assetType),
      translatedStatus: this.$t(item.assetStatus),
      translatedServiceRegion: this.$t(item.serviceRegion),
      translatedHyvaRegion: this.$t(item.hyvaRegion),
      companyAssetId: item.companyAssetId || item.preliminaryAssetId,
    }));
  }

  get translatedAccessibleAssets(): AccessibleAssetsRowData[] {
    return this.accessibleAssetList.map((item) => ({
      ...item,
      translatedAssetType: this.$t(item.assetType),
      translatedStatus: this.$t(item.assetStatus),
      companyAssetId: item.companyAssetId || item.preliminaryAssetId,
      translatedServiceRegion: this.$t(item.serviceRegion),
      translatedHyvaRegion: this.$t(item.hyvaRegion),
    }));
  }

  async prepareDefaultInitialization() {
    this.customerId && (await this.fetchCustomerInfo());
    await this.retrieveSubscriptionPackages(
      1,
      DEFAULT_SUBSCRIPTION_PACKAGES_REQUEST_SIZE
    );
    await this.retrieveCompanySubscriptionsByCompanyId(this.customerId);

    this.useAccessibleAssets =
      COMPANY_TYPES_THAT_REQUIRE_ACCESSIBLE_ASSETS.includes(
        this.customerInfo.companyType
      );
    this.requestAssetsForHelpdesk =
      this.companyTypesThatRequiresAssetsByServiceRegion.includes(
        this.customerInfo.companyType
      );
    this.selectedUserCanImpersonate = DESIGNATED_COMPANY_TYPES.includes(
      this.customerInfo.companyType
    );

    if (this.selectedUserCanImpersonate) {
      this.assignedAssetsSearchFields = ASSIGNED_ASSETS_SEARCH_OPTION_COLS;
    }

    await this.handleFetchAssets();
  }

  async fetchCustomerInfo() {
    this.isViewModeLoading = true;
    await getCustomerById(this.customerId).then((res) => {
      if (!res) {
        customFailedMessage(
          this.$t('subscriptionPackages.errorWithFetchingData').toString()
        );
        return;
      }
      if (res.code === 200 && Object.keys(res.data).length > 0) {
        this.customerInfo = res.data;
        PageModule.setTitle(this.customerInfo.name);
        this.logo = `${Vue.prototype.$envConfig.VUE_APP_BASE_API}/companies/${this.customerId}/logo`;

        if (
          this.customerInfo.activationStatus === ACTIVATION_STATUS.Deactivated
        ) {
          this.activateOrDeactivate = 'activate';
        } else {
          this.activateOrDeactivate = 'deactivate';
        }
        this.customerType = res.data.companyType;
        this.isViewModeLoading = false;
        return;
      }

      this.isViewModeLoading = false;
      customFailedMessage(
        this.$t('subscriptionPackages.errorWithFetchingData').toString()
      );
    });
  }

  /**
   * Retrieve from BE company subscriptions by company id
   * @param company id
   */
  async retrieveCompanySubscriptionsByCompanyId(
    companyId: string
  ): Promise<void> {
    try {
      await getCompanySubscriptions(companyId).then((res: any) => {
        let allCompanySubscriptions: any = [];
        if (res.code === 200 && res.data.length > 0) {
          /** If package of company type is not the first in array, move it to 0 position */
          if (
            res.data[0].subscriptionPackageType != this.packageType.CompanyType
          ) {
            let packageOfCompanyType = res.data.find((item: any) => {
              return (
                item.subscriptionPackageType === this.packageType.CompanyType
              );
            });

            var index = res.data.indexOf(packageOfCompanyType);
            allCompanySubscriptions = res.data.filter((item: any) => {
              return (
                item.subscriptionPackageType != this.packageType.CompanyType
              );
            });
            res.data.splice(index, 1);
            allCompanySubscriptions.unshift(packageOfCompanyType);
          }
        }
        this.companySubscriptions =
          allCompanySubscriptions.length > 0
            ? allCompanySubscriptions
            : res.data;
      });
    } catch (error) {
      console.log(error);
      customFailedMessage(
        this.$t('subscriptionPackages.errorWithFetchingData').toString()
      );
    } finally {
    }
  }

  /**
   * Retrieve from BE subscription packages
   * @param page
   * @param size
   */
  async retrieveSubscriptionPackages(page: number, size: number) {
    this.subscriptionTabIsLoading = true;
    await getSubscriptionPackages(page, size).then((res) => {
      res.subscriptionPackages.forEach((item: any) => {
        if (item.type === this.packageType.CompanyType)
          this.subscriptionPackages.companyType.push(item);
        if (item.type === this.packageType.AssetType)
          this.subscriptionPackages.assetType.push(item);
      });

      this.subscriptionTabIsLoading = false;
    });
  }

  showDeactive() {
    if (
      UserModule.claims.hasClaim(
        ALL_CLAIMS_CODES.AUTHRSC_ACTION_COMPANY_DEACTIVATE
      )
    ) {
      this.visiable = true;
      if (
        this.customerInfo.activationStatus === ACTIVATION_STATUS.Deactivated
      ) {
        this.dialogContent = this.$t('userModule.activateInfo', {
          name: this.title,
        });
      } else {
        this.dialogContent = this.$t('userModule.deactivateInfo', {
          name: this.title,
        });
      }
    }
  }

  handleCancel() {
    this.visiable = false;
  }

  async handleDeactive() {
    await updateActivationStatus(this.customerId, {
      activationStatus:
        this.activateOrDeactivate === 'deactivate'
          ? ACTIVATION_STATUS.Deactivated
          : ACTIVATION_STATUS.Activated,
    }).then((res) => {
      let infoMsg = (
        this.activateOrDeactivate === 'deactivate'
          ? this.$t('common.deactivate')
          : this.$t('common.activate')
      ) as string;
      if (!res) {
        customFailedMessage(
          this.$t('subscriptionPackages.errorWithFetchingData').toString()
        );
        return;
      }

      if (res.code === 200) {
        this.$router.push({ name: 'Home' });
        promptSuccessBox(infoMsg);
      } else if (res.code === 400) {
        promptFailedBox(infoMsg);
      }
    });

    this.visiable = false;
  }

  handleTabClick(tab: any, event: any): void {
    if (this.activeName === 'assetsTab') {
      this.messageTotalStatistics = this.$t(
        'assetsModule.assetsTotalInfo'
      ) as string;
      this.totalStatistics = this.assetTotal;
      this.isTotalStatisticsInfoVisible = true;
      return;
    }

    if (this.activeName === 'usersTab') {
      this.messageTotalStatistics = this.$t(
        'userModule.userTotalInfo'
      ) as string;
      this.$nextTick(() => {
        (this.$refs.userManagement as any).sendTotalUsers();
      });
      return;
    }

    if (this.activeName === 'organizationsTab') {
      this.messageTotalStatistics = this.$t(
        'organizationModule.organizationTotalInfo'
      ) as string;
      this.$nextTick(() => {
        (this.$refs.organizationManagement as any).sendTotalOrganizations();
      });
      return;
    }

    this.isTotalStatisticsInfoVisible = false;
    this.totalStatistics = 0;
    this.messageTotalStatistics = '';
  }

  handleEdit() {
    this.$router.push(`/customer-management/edit/${this.customerId}`);
  }

  /** Filter by sort event */
  async fetchAssignedAssetsDataBySortEvent(sortBy: any, order: any) {
    if (sortBy === 'organization') sortBy = 'organizationName';
    order != ''
      ? (this.assetSortAndOrderData.sortBy = sortBy)
      : (this.assetSortAndOrderData.sortBy = null);
    order != ''
      ? (this.assetSortAndOrderData.order = order)
      : (this.assetSortAndOrderData.order = null);
    await this.handleFetchAssets();
  }

  /**
   * Fetch new assets by pagination
   */
  fetchAssignedAssetsDataByPageSelection(page: number, pageSize: number) {
    this.currentPage = page;
    this.pageSize = pageSize;
    this.handleFetchAssets();
  }

  /**
   * Handle fetch assets data for selected customer, coveres the following company types:
   * - when normal customer: trigger flow for POST /assets with companyId
   * - when helpdesk: trigger flow for POST /assets with service regions
   * - when body builder / dealer: trigger flow for POST /accessible-assets by primaryOrgId of selected custoemr
   */
  async handleFetchAssets() {
    if (this.requestAssetsForHelpdesk) {
      this.fetchAssetsForHelpDeskCustomer();
      return;
    }

    if (this.useAccessibleAssets) {
      this.fetchAccessibleAssets();
      return;
    }

    await this.fetchAssetsForNormalCustomers();
  }

  /**
   * Fetch for body builder and dealer customer types accessible assets by adding in filter all children and current org ids
   */
  async fetchAccessibleAssets() {
    try {
      this.isAssetManagementLoading = true;
      let flattenOrgIds: string[] = [];
      if (
        this.loggedInUser.value?.companyType ===
        COMPANY_TYPE.HyvaRegionalSupport
      ) {
        const response = await getOrganizations(this.customerId);
        flattenOrgIds = this.flattenOrganizationsIds(response.data);
      } else {
        const orgByIdResponse = await getOrgById(
          this.customerInfo.primaryOrganizationId
        );
        flattenOrgIds = this.flattenOrganizationsIds([orgByIdResponse.data]);
      }
      const filter: Filter = {
        name: ORGANIZATION_ID,
        operator: FilterOperator.IN,
        value: flattenOrgIds,
      };
      const queryParameter: QueryParameter = this.prepareQueryParameter(
        filter,
        true
      );
      const response = await getAccessibleAssetsByFlexibleFiltering(
        queryParameter
      );
      this.assetTotal = response.data.total;
      this.totalStatistics = this.assetTotal;
      this.accessibleAssetList = response.data.assets;
    } catch (error) {
      console.log(error);
      customFailedMessage(
        this.$t('subscriptionPackages.errorWithFetchingData').toString()
      );
    } finally {
      this.isAssetManagementLoading = false;
    }
  }

  /** Flat current org level id and children */
  flattenOrganizationsIds(
    organizations: Organization | Organization[]
  ): string[] {
    if (!Array.isArray(organizations)) {
      organizations = [organizations];
    }
    return organizations.flatMap((org) => [
      org.id,
      ...this.flattenOrganizationsIds(org.children),
    ]);
  }

  /**
   * Fetch POST /assets by service regions for helpdesk customer
   */
  async fetchAssetsForHelpDeskCustomer() {
    try {
      this.isAssetManagementLoading = true;
      const queryParameter: QueryParameter = this.prepareQueryParameter();
      const response = await fetchAssetsByFlexibleFiltering(
        queryParameter,
        this.customerInfo.id
      );
      this.assetTotal = response.data.total;
      this.totalStatistics = this.assetTotal;
      this.assetList = response.data.assets;
    } catch (error) {
      console.log(error);
      customFailedMessage(
        this.$t('subscriptionPackages.errorWithFetchingData').toString()
      );
    } finally {
      this.isAssetManagementLoading = false;
    }
  }

  /**
   * Fetch assets for normal customer by add in query params to POST /assets: current selected customer UUID
   */
  async fetchAssetsForNormalCustomers() {
    try {
      this.isAssetManagementLoading = true;
      const queryParameter: QueryParameter = this.prepareQueryParameter(
        this.loggedInUser?.value &&
          isHyvaCompany(this.loggedInUser.value.companyType)
          ? {
              name: 'companyId',
              operator: FilterOperator.EQUAL,
              value: [this.customerId],
            }
          : undefined
      );
      const response = await fetchAssetsByFlexibleFiltering(
        queryParameter,
        this.loggedInUser?.value &&
          !isHyvaCompany(this.loggedInUser.value.companyType)
          ? this.customerId
          : undefined
      );
      this.assetTotal = response.data.total;
      this.totalStatistics = this.assetTotal;
      this.assetList = response.data.assets;
    } catch (error) {
      console.log(error);
      customFailedMessage(
        this.$t('subscriptionPackages.errorWithFetchingData').toString()
      );
    } finally {
      this.isAssetManagementLoading = false;
    }
  }

  /**
   * Handle update selected asset
   * @param assets
   */
  handleUpdateSelectedAssets(assets: string[]) {
    this.selectedAssetes = assets;
  }

  /**
   * Prepare query parameter
   * @param filter
   * @return QueryParameter
   */
  prepareQueryParameter(
    filter?: Filter,
    isAccessTime?: boolean
  ): QueryParameter {
    const sorter: Sorter =
      this.assetSortAndOrderData.sortBy != null
        ? {
            field: this.assetSortAndOrderData.sortBy,
            order: this.assetSortAndOrderData.order,
          }
        : {
            field: isAccessTime ? ACCESS_TIME : CREATED_ON,
            order: SorterOrder.DESC,
          };
    const pagination: Pagination = {
      page: this.currentPage,
      size: this.pageSize,
    };
    let queryParameter: QueryParameter = {
      filters: [],
      sorters: [sorter],
      pagination: pagination,
    };
    if (
      this.assetSearchParams.value != null &&
      this.assetSearchParams.value != ''
    ) {
      const operator: FilterOperator = this.assetSearchParams.operator
        ? this.assetSearchParams.operator
        : FilterOperator.LIKE;
      const filter: Filter = {
        name: this.assetSearchParams.reference,
        operator: operator,
        value: [this.assetSearchParams.value],
      };
      queryParameter = addFilter(queryParameter, filter);
    }
    if (filter) {
      queryParameter = addFilter(queryParameter, filter);
    }
    return queryParameter;
  }

  /** Update user count from child event */
  updateTotalUsers(usersCount: number) {
    this.isTotalStatisticsInfoVisible = false;
    this.totalUsers = usersCount;
    this.totalStatistics = usersCount;
    this.isTotalStatisticsInfoVisible = true;
  }

  /** Update orgs count from child event */
  updateTotalOrganizations(orgsCount: number) {
    this.isTotalStatisticsInfoVisible = false;
    this.totalOrganizations = orgsCount;
    this.totalStatistics = orgsCount;
    this.isTotalStatisticsInfoVisible = true;
  }
}
</script>

<template>
  <new-card
    v-loading="isViewModeLoading"
    id="view_cust_info_new_card"
    :createdOrEdit="customerId"
    :visible="true"
    :activateOrDeactivate="activateOrDeactivate"
    :editBtnAuth="['AUTHRSC_ACTION_COMPANY_UPDATE']"
    @edit-infos="handleEdit"
    @handle-deactive="showDeactive"
  >
    <template #headerInfoAfterTitle>
      <div
        v-if="isTotalStatisticsInfoVisible"
        class="d-flex ai-center cust-total-statistics"
        style="margin-left: 16px"
      >
        <span id="cust_view_pane_user_totalInfo" style="margin-right: 5px">{{
          messageTotalStatistics + ':'
        }}</span>
        <span id="cust_view_pane_user_totalValue">{{ totalStatistics }}</span>
      </div>
    </template>
    <el-tabs
      id="cust_view_tabs"
      v-model="activeName"
      @tab-click="handleTabClick"
      style="margin: 0px 20px"
    >
      <el-tab-pane
        id="cust_view_pane_generalInfo"
        :label="$t('customerModule.generalInfo')"
        name="generalInfoTab"
        style="margin-left: 60px"
      >
        <general-information :customerInfo="customerInfo" :logo="logo" />
      </el-tab-pane>

      <el-tab-pane
        id="cust_view_pane_assets"
        :label="$t('assetsModule.assets')"
        name="assetsTab"
      >
        <select-table-header
          v-if="activeName === 'assetsTab'"
          style="margin: 20px 0"
          :searchFieldOptions="assignedAssetsSearchFields"
          :cols="useAccessibleAssets ? accessibleAssetCols : assetCols"
          :searchParams="assetSearchParams"
          :selectedAssetes="selectedAssetes"
          :customerType="customerType"
          @search-event="handleFetchAssets"
        />
        <el-row>
          <pure-table
            v-loading="isAssetManagementLoading"
            :element-loading-text="
              $t('assetsModule.assetsAreLoading').toString()
            "
            v-if="activeName === 'assetsTab'"
            :cols="useAccessibleAssets ? accessibleAssetCols : assetCols"
            :tableList="
              useAccessibleAssets
                ? translatedAccessibleAssets
                : translatedAssetsWithKpis
            "
            :total="assetTotal"
            @updateSelectedAssets="handleUpdateSelectedAssets"
            @handle-page="fetchAssignedAssetsDataByPageSelection"
            @handle-sort-change="fetchAssignedAssetsDataBySortEvent"
          />
        </el-row>
      </el-tab-pane>

      <el-tab-pane
        id="cust_view_pane_user"
        :label="$t('userModule.users')"
        name="usersTab"
      >
        <user-management
          ref="userManagement"
          v-if="activeName === 'usersTab'"
          :customerId="customerId"
          :selectedCompanyType="customerInfo.companyType"
          :activeName="activeName"
          :searchFieldOptions="userSearchFields"
          :cols="userCols"
          :organizationId="customerInfo.primaryOrganizationId"
          @send-total-users="updateTotalUsers"
        />
      </el-tab-pane>

      <el-tab-pane
        id="cust_view_pane_organization"
        :label="$t('organizationModule.organization')"
        name="organizationsTab"
      >
        <organization-management
          ref="organizationManagement"
          v-if="activeName === 'organizationsTab'"
          :customerId="customerId"
          :organizationId="customerInfo.primaryOrganizationId"
          :cols="orgCols"
          @send-total-organizations="updateTotalOrganizations"
        />
      </el-tab-pane>

      <el-tab-pane
        id="cust_view_pane_subscription"
        :label="$t('subscriptionPackages.title')"
        name="dashboardConfigurationTab"
      >
        <div
          v-loading="companySubscriptions == null"
          class="company-subscription-container"
        >
          <div
            v-if="companySubscriptions && companySubscriptions.length == 0"
            class="no-subscription-packages"
          >
            {{ $t('subscriptionPackages.noSubsPackagesForThisCustomer') }}.
          </div>
          <subscription-packages
            :companySubscriptions="companySubscriptions"
            :subscriptionPackages="subscriptionPackages"
            :subscriptionTabIsLoading="subscriptionTabIsLoading"
            :source="source"
          />
        </div>
      </el-tab-pane>

      <el-tab-pane
        id="cust_view_pane_dashTemplate"
        :label="$t('dashboardConf.dashboardConf')"
        name="dashTempalte"
      >
        <DashboardTemplate
          v-if="customerId && customerType"
          :customerId="customerId"
          :customerType="customerType"
          :isCreate="false"
          :isView="true"
        />
      </el-tab-pane>
    </el-tabs>

    <deactivate-dialog
      :visiable.sync="visiable"
      :activateOrDeactivate="activateOrDeactivate"
      :title="'customerModule.customer'"
      :content="dialogContent"
      @handle-cancel="handleCancel"
      @handle-deactivate="handleDeactive"
    />
  </new-card>
</template>

<style scoped>
.no-subscription-packages {
  margin: 20px 0 20px 10px;
  font-weight: bold;
  font-size: 16px;
  color: red;
}
</style>

<style lang="scss" scoped>
.cust-total-statistics {
  font-size: 16px;
  font-family: $font-Roboto-Regular;
  line-height: 19px;
  color: rgba(55, 62, 65, 0.6);
}

.company-subscription-container {
  width: 100%;
  min-height: 5rem;
}
</style>
