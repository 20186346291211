var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('el-table',{ref:"sortTable",staticClass:"maintenace-plan-list-table",attrs:{"data":_vm.list,"row-key":"id","row-style":{ height: '25px' },"cell-style":{ padding: '7px 0px' },"tree-props":_vm.treeProp,"row-class-name":_vm.tableRowClassName},on:{"row-click":_vm.handleRowClick}},_vm._l((_vm.activeCols),function(col,index){return _c('el-table-column',{key:`col_${index}`,attrs:{"prop":_vm.dragCols[index].prop,"min-width":"160px","show-overflow-tooltip":"","width":_vm.dragCols[index].prop === 'maintenanceMonitor' ? 350 : 180},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('div',{staticClass:"d-flex jc-between"},[_c('span',{staticStyle:{"white-space":"nowrap"}},[_vm._v(" "+_vm._s(_vm.$t(_vm.activeCols[index]?.label))+" ")])])]},proxy:true},(col.prop === 'assetType')?{key:"default",fn:function(slotProps){return [_vm._v(" "+_vm._s(_vm.$t(_vm.list[slotProps.$index].assetType))+" ")]}}:(col.dateFormat === 'date' || col.dateFormat === 'datetime')?{key:"default",fn:function(slotProps){return [_vm._v(" "+_vm._s(slotProps.row[col.prop] ? _vm.formatColumnDateValue(slotProps.row[col.prop], col.dateFormat) : '-')+" ")]}}:(
          col.prop === 'status' ||
          col.prop === 'maintenanceMonitor' ||
          col.prop === 'name'
        )?{key:"default",fn:function(slotProps){return [(col.prop === 'status')?_c('div',[(
              _vm.list[slotProps.$index].status === _vm.maintenanceStatuses.MainSOk
            )?_c('div',{staticClass:"status-ok"},[_vm._v(" "+_vm._s(_vm.$t(_vm.list[slotProps.$index].status))+" ")]):_vm._e(),(
              _vm.list[slotProps.$index].status === _vm.maintenanceStatuses.MainSDue
            )?_c('div',{staticClass:"status-due"},[_vm._v(" "+_vm._s(_vm.$t(_vm.list[slotProps.$index].status))+" ")]):_vm._e(),(
              _vm.list[slotProps.$index].status === _vm.maintenanceStatuses.MainSClose
            )?_c('div',{staticClass:"status-close"},[_vm._v(" "+_vm._s(_vm.$t(_vm.list[slotProps.$index].status))+" ")]):_vm._e()]):_vm._e(),(col.prop === 'maintenanceMonitor')?_c('div',{staticClass:"maintenance-monitor-progress-bar-container"},[_c('div',{staticClass:"progress-bar-wrapper"},[_c('div',{class:[
                _vm.list[slotProps.$index].status === _vm.maintenanceStatuses.MainSOk
                  ? 'progress-bar-ok-value'
                  : _vm.list[slotProps.$index].status ===
                    _vm.maintenanceStatuses.MainSDue
                  ? 'progress-bar-due-value'
                  : 'progress-bar-close-value',
              ],style:({
                width: _vm.getProgressBarValue(_vm.list[slotProps.$index]) + '%',
              })},[_c('div',{staticClass:"progress-text-value"},[_vm._v(" "+_vm._s(_vm.getProgressBarTextValue(_vm.list[slotProps.$index]))+" ")])])]),_c('div',{staticClass:"maintenance-monitor-progress-rules-value"},[_vm._v(" "+_vm._s(_vm.getRulesProgressThresholdValues(_vm.list[slotProps.$index]))+" ")])]):_vm._e()]}}:null],null,true)})}),1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }