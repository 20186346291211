import { Quantity } from './unitDefinitions';

export interface UnitConversion {
  quantity: Quantity;
  factor: number;
  offset?: number;
}

export function convertRaw<T extends number | undefined>(
  value: T,
  from: UnitConversion,
  to: UnitConversion
): T {
  if (from === to || value === undefined) {
    return value;
  }
  let v: number = value; // TS doesn't understand that we just dropped `undefined`

  if (from.quantity !== to.quantity) {
    throw new Error(`cannot convert from ${from.quantity} to ${to.quantity}`);
  }

  // Convert to base value within quantity
  if (from.offset) {
    v += from.offset;
  }
  v *= from.factor;

  // Convert to target value within quantity
  v /= to.factor;
  if (to.offset) {
    v -= to.offset;
  }

  return v as T;
}
