<script lang="ts">
import {
  createRole,
  getRoleById,
  RoleForm,
  RoleWithClaimsFtd,
  updateRole,
} from '@/api/roles';
import { ErrorType } from '@/api/types';
import { AuthorizableResource, getModules } from '@/api/users';
import CommonBtn from '@/components/button/CommonBtn.vue';
import NewCardVue from '@/components/cusCard/NewCard.vue';
import CusFormItem from '@/components/form/CusFormItem.vue';
import GeneralInfo from '@/components/form/GeneralInfo.vue';
import ModuleAccess from '@/components/form/ModuleAccess.vue';
import {
  customFailedMessage,
  promptFailedBox,
  promptSuccessBox,
} from '@/utils/prompt';
import {
  COMPANY_TYPE,
  COMPANY_TYPE_LIST,
  ERROR_CODE_LIST,
  UserRole,
} from '@/utils/workData/lookuptable';
import { Tree } from 'element-ui';
import { omit } from 'lodash';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import ChangeRoleVue from './components/ChangeRole.vue';
import ViewRoleInfoVue from './components/ViewRoleInfo.vue';

@Component({
  name: 'CreateNewRole',
  components: {
    'new-card': NewCardVue,
    'cus-form-item': CusFormItem,
    'module-access': ModuleAccess,
    'common-btn': CommonBtn,
    'el-tree': Tree,
    'general-info': GeneralInfo,
    'view-role-info': ViewRoleInfoVue,
    'change-role': ChangeRoleVue,
  },
})
export default class extends Vue {
  @Prop() roleId?: string;
  // TODO This should be 'just' Role, so let's see what else what need to change
  role?: RoleWithClaimsFtd;
  authorizableResources: AuthorizableResource[] = [];
  companyTypes = COMPANY_TYPE_LIST;
  inputFormIsInEditMode: boolean = false;

  defaultChecked: string[] = [];
  isRoleLoading: boolean = false;
  /**
   * Backend payload structure used in v-model bindings
   */
  roleForm: RoleForm = {
    name: '',
    companyType: this.companyTypes[0].id,
    note: '',
    claims: [],
    claimsFtd: [''],
    code: UserRole.CustomerAdmin,
    id: '',
  };

  errorCode = ERROR_CODE_LIST;
  errorInfos: ErrorType[] = [
    {
      code: '',
      field: '',
      message: '',
    },
  ];

  @Watch('role')
  onRoleChange() {
    if (this.role) {
      this.roleForm = {
        ...this.role,
        companyType: this.role.companyType,
      };
    }
  }

  validateClaimsFtd = (rule: any, value: any, callback: any) => {
    const checkedNode = (
      (this.$refs.child as ChangeRoleVue).$refs.moduleAccess as any
    ).getCheckedNodes();
    if (checkedNode.length < 1) {
      callback(new Error(`${this.$t('userRoleModule.tipSelectModuleAccess')}`));
    } else {
      callback();
    }
  };

  get inlineMsg() {
    return false;
  }

  get rules() {
    const tmpRules = {
      name: [
        {
          required: true,
          message: this.$t('userRoleModule.tipInputUserRoleName'),
          trigger: 'change',
        },
      ],
      companyType: [
        {
          required: true,
          message: this.$t('userRoleModule.tipSelectCompanyType'),
          trigger: 'change',
        },
      ],
      claimsFtd: [{ validator: this.validateClaimsFtd, trigger: 'change' }],
    };
    return tmpRules;
  }

  /**
   * Trigger backend API for creating new role
   */
  async createNewRoleRemote() {
    (this.$refs.roleForm as any).validate(async (valid: any) => {
      if (valid) {
        this.prepareBackendClaimsPayload(
          (
            (this.$refs.child as ChangeRoleVue).$refs.moduleAccess as any
          ).getCheckedNodes()
        );
        await createRole(this.roleForm).then((res) => {
          if (res.code === 200) {
            promptSuccessBox(this.$t('common.create') as string);
            this.errorInfos = [];
            this.$router.push('/user-role-mgmt/index');
          } else if (res.code === 400) {
            promptFailedBox(this.$t('common.create') as string);

            /* @ts-expect-error TODO Wrong type */
            this.errorInfos = res.data.errors;
          }
        });
      } else {
      }
    });
  }
  async updateRoleRemote() {
    (this.$refs.roleForm as any).validate(async (valid: any) => {
      if (valid && !!this.roleId) {
        this.prepareBackendClaimsPayload(
          (
            (this.$refs.child as ChangeRoleVue).$refs.moduleAccess as any
          ).getCheckedNodes()
        );

        const formValues = omit(this.roleForm, 'name');
        await updateRole(this.roleId, formValues).then((res) => {
          if (res.code === 200) {
            promptSuccessBox(this.$t('common.edit') as string);
            this.errorInfos = [];
            this.$router.push('/user-role-mgmt/index');
          } else if (res.code === 400) {
            promptFailedBox(this.$t('common.edit') as string);

            /* @ts-expect-error TODO Wrong type */
            this.errorInfos = res.data.errors;
          }
        });
      } else {
      }
    });
  }

  /**
   * Prepare structure for claims, attached to roleForm obj
   * @param data
   */
  prepareBackendClaimsPayload(data: any) {
    let temporaryClaims: any = [];
    data.map((item: any) => temporaryClaims.push({ resource: item.id }));
    this.roleForm.claims = temporaryClaims;
  }

  async fetchModules() {
    const res = await getModules();
    this.authorizableResources = res.data;
  }

  created() {
    this.fetchModules();
    if (this.roleId) {
      this.fetchUserRolesData(this.roleId);
    }
  }

  async fetchUserRolesData(roleId: string) {
    this.isRoleLoading = true;
    await getRoleById(roleId).then((res) => {
      if (!res) return;
      if (res.code === 200) {
        this.role = res.data;
        if (this.role) {
          this.roleForm = {
            ...this.role,
            companyType: this.role.companyType,
          };
        }
        this.defaultChecked = this.role.claims.map((claim) => claim.resource);
      }
      if (res.code === 500) {
        customFailedMessage(this.$t('common.serverError').toString());
      }

      this.isRoleLoading = false;
    });
  }

  handleEdit() {
    this.$router.push(`/user-role-mgmt/edit-role/${this.roleId}`);
  }

  isViewPage() {
    return this.$route.name === 'ViewRole';
  }

  isCreatePage() {
    return this.$route.name === 'CreateNewRole';
  }

  isEditPage() {
    return this.$route.name === 'EditRole';
  }
}
</script>

<template>
  <new-card
    id="add_new_role_new_card"
    @edit-infos="handleEdit"
    :createdOrEdit="roleId"
    :visible="true"
    :visibleDeactivate="false"
    :visibleEdit="isViewPage()"
    :editBtnAuth="['AUTHRSC_ACTION_ROLE_EDIT']"
    :isUpdatable="isEditPage()"
    @create-event="createNewRoleRemote"
    @update-event="updateRoleRemote"
    :isNewEntity="isCreatePage()"
  >
    <el-form
      ref="roleForm"
      :model="roleForm"
      :rules="rules"
      :inline-message="inlineMsg"
      style="margin-left: 50px; height: 730px"
      v-loading="isRoleLoading"
    >
      <div v-if="isViewPage()">
        <view-role-info
          :roleForm="roleForm"
          :defaultChecked="defaultChecked"
          :authorizableResources="authorizableResources"
        />
      </div>
      <div v-else>
        <change-role
          ref="child"
          :roleForm="roleForm"
          :defaultChecked="defaultChecked"
          :authorizableResources="authorizableResources"
        />
      </div>
    </el-form>
  </new-card>
</template>

<style lang="scss" scoped>
.role-module-access {
  display: flex;
  justify-content: flex-start;
  margin-top: 20px;

  .role-module-access-header {
    margin-right: 20px;
    flex-shrink: 0;
    width: 180px;
    text-align: left;
  }
}

@media (max-width: 768px) {
  .role-module-access {
    display: block;

    .role-module-access-header {
      margin-right: 20px;
      flex-shrink: 0;
      width: 150px;
      text-align: left;
    }
  }
}
</style>
