<script setup lang="ts">
import { UUID } from '@/api/common';
import { Option } from '@/utils/types/option';
import { computed, defineEmits, defineProps, ref } from 'vue';

const props = defineProps<{
  filterLabel?: string;
  options: Option[];
  selectedOptions: string[];
  error?: string;
  disabled?: boolean;
}>();

const emit = defineEmits<{
  (e: 'change', value: string[]): void;
}>();

const searchField = ref('');

const searchedOptionIds = computed((): Set<UUID> => {
  return new Set(filteredOptions.value.map((option) => option.key));
});

const selectedOptionIds = computed((): Set<UUID> => {
  return new Set(props.selectedOptions.map((option) => option));
});

const allOptsSelected = computed(() => {
  if (searchField.value !== '') {
    return filteredOptions.value.every((option) =>
      selectedOptionIds.value.has(option.key)
    );
  }

  return props.options.length === props.selectedOptions.length;
});

const filteredOptions = computed(() => {
  return props.options.filter((o) => {
    return (
      searchField.value == '' ||
      o.label.toLowerCase().includes(searchField.value.toLowerCase())
    );
  });
});

function toggleAllOpt() {
  const isSearchAssetFieldEmpty = searchField.value === '';

  // When 'All' goes from switched OFF to ON
  if (!allOptsSelected.value) {
    if (isSearchAssetFieldEmpty) {
      updateSelectedOptions(props.options.map((o) => o.key));
    } else {
      // Keep previous selection and add those that are currently shown AND unselected in the dropdown
      updateSelectedOptions([
        ...props.selectedOptions,
        ...filteredOptions.value
          .map((o) => o.key)
          .filter((option) => !selectedOptionIds.value.has(option)),
      ]);
    }

    return;
  }

  // When 'All' goes from switched ON to OFF
  if (isSearchAssetFieldEmpty) {
    updateSelectedOptions([]);
  } else {
    // Remove options that are currently selected AND shown in the dropdown
    updateSelectedOptions(
      props.selectedOptions
        .filter((option) => !searchedOptionIds.value.has(option))
        .map((option) => option)
    );
  }
}

function updateSelectedOptions(newSelection: string[]) {
  emit('change', newSelection);
}

function selectedOptionsChanged(event: string[]) {
  updateSelectedOptions(event);
}

function optionSelected(option: { key: string; label: string }) {
  return props.selectedOptions.find((o) => o == option.key) !== undefined;
}
</script>

<template>
  <el-select
    class="filter-select bigger-list"
    multiple
    :value="props.selectedOptions"
    collapse-tags
    :placeholder="props.filterLabel ?? $t('common.pleaseSelect')"
    :error="error"
    :disabled="disabled"
    @change="selectedOptionsChanged($event)"
  >
    <el-input
      class="search-select-input"
      :placeholder="$t('common.inputKeywordToSearch')"
      v-model="searchField"
      clearable
      ><i slot="suffix" class="el-input__icon el-icon-search" />
    </el-input>

    <el-divider class="filter-select-asset-divider" />
    <div @click="toggleAllOpt()" class="filter-select-assets-select-all">
      <span
        :class="allOptsSelected ? 'option-selected' : 'option-not-selected'"
        >{{ $t('common.all') }}</span
      >
      <el-switch
        :value="allOptsSelected"
        class="el-switch-component"
        active-color="var(--Main)"
        inactive-color="#D5D5D5"
      ></el-switch>
    </div>
    <el-divider class="filter-select-asset-divider" />
    <el-option value="novalue" v-if="filteredOptions.length < 1" disabled>{{
      $t('noResults')
    }}</el-option>
    <el-option
      class="filter-select-option filter-select-assets-option"
      v-for="option in filteredOptions"
      :key="option.key"
      :label="option.label"
      :value="option.key"
    >
      <span
        :class="
          optionSelected(option) ? 'option-selected' : 'option-not-selected'
        "
        >{{ option.label }}</span
      >
      <el-switch
        class="el-switch-component"
        active-color="var(--Main)"
        inactive-color="#D5D5D5"
        :value="optionSelected(option)"
      ></el-switch>
    </el-option>
  </el-select>
</template>

<style lang="scss" scoped>
.option-selected {
  opacity: 1;
}
.option-not-selected {
  opacity: 0.7;
}
.filter-select {
  width: 100%;
  min-width: 16rem;
  margin-right: 0;
  flex: 1;
}
.filter-select-option {
  &.hover {
    background-color: unset !important;
  }
  &:hover {
    background-color: var(--DropdownHover) !important;
  }
}
.search-select-input {
  padding: 0 5px;
}
.filter-select-assets-select-all,
.filter-select-assets-option {
  padding: 0 1rem !important; // has to be important to override some other css
  height: 34px; // The dropdown widget sets the height fixed instead using the padding
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #606266;
  font-size: 16px;
  &:hover {
    cursor: pointer;
    background-color: var(--DropdownHover) !important;
  }
  span {
    color: #373e41;
    font-weight: 400;
  }
}
.filter-select-asset-divider {
  margin: 4px 0;
}
.el-icon-search {
  line-height: 40px;
  color: rgba(0, 0, 0, 0.6);
  font-size: 18px;
  cursor: pointer;
  margin-right: 8px;
  margin-top: -4px;
}
.el-switch-component {
  margin: auto 0 auto 5px;
}
.el-select-dropdown__item.selected::after {
  display: none;
}
</style>
