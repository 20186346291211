<script lang="ts" setup>
import { CommonResult } from '@/api/commonResult';
import {
  getMaintenanceAppointmentsList,
  MaintenanceAppointment,
} from '@/api/maintenance';
import { useActiveContext } from '@/auth/context';
import WidgetCard from '@/components/layout/widget/WidgetCard.vue';
import MaintenanceModal from '@/components/modal/MaintenanceModal.vue';
import MaintenancePlanListTable from '@/components/table/MaintenancePlanListTable.vue';
import { AsyncValue, useAsync } from '@/composables/async';
import { useRoute } from '@/composables/router';
import {
  FilterOperator,
  Pagination,
  QueryParameter,
} from '@/model/queryParameters/QueryParameter';
import { SINGLE_ASSET_MAINTENANCE_ITEM_COLS } from '@/utils/workData/maintenanceConf';
import { MaintenanceModalTriggerSource } from '@/views/maintenance/planner';
import { computed, ref, unref, watchEffect } from 'vue';

const route = useRoute();
const context = useActiveContext();

const cols = SINGLE_ASSET_MAINTENANCE_ITEM_COLS;
const scheduledAppointmentsTableData = ref<MaintenanceAppointment[]>([]);
const forceUpdateMaintenanceAppointments = ref(0);
const modalTriggerSource = ref<any>(MaintenanceModalTriggerSource.Edit);
const isModalVisible = ref<boolean>(false);
const selectedAppointment = ref<MaintenanceAppointment>();
const maintenanceModalTitle = ref<string>('');

const scheduledAppointmentsAPIResponse = useAsync(
  computed(async (): Promise<CommonResult<MaintenanceAppointment[]>> => {
    unref(
      forceUpdateMaintenanceAppointments
    ); /** Force update appointments table */
    const id = unref(route).params.id;
    if (!!id) {
      const response: CommonResult<MaintenanceAppointment[]> =
        await getMaintenanceAppointmentsList(
          getScheduledAppointmentsQueryParameters(id)
        );
      return response;
    }

    return {
      code: 404,
      message: '',
      data: [],
    };
  })
);

function getScheduledAppointmentsQueryParameters(id: string): QueryParameter {
  const pagination: Pagination = { page: 1, size: 100000 };
  const selectedOrganization = unref(context.value).organization;

  const queryParameters: QueryParameter = {
    filters: [
      {
        name: 'assetId',
        operator: FilterOperator.IN,
        value: [id],
      },
    ],
    pagination,
    // First timezone is used for 'normal' users, second one is for designated users
    timezone:
      selectedOrganization?.timezone ?? unref(context).primaryOrgTimeZone,
  };
  return queryParameters;
}

const scheduledAppointmentsListInfo = computed(
  (): AsyncValue<CommonResult<MaintenanceAppointment[]>> => {
    const appointmentsList = unref(scheduledAppointmentsAPIResponse);
    if (appointmentsList.loading || appointmentsList.error) {
      return {
        loading: appointmentsList.loading,
        error: appointmentsList.error,
        data: undefined,
      };
    }
    if (appointmentsList.data === undefined) {
      return {
        loading: false,
        error: new Error('Invalid api response, data must be present.'),
        data: undefined,
      };
    }

    return {
      loading: false,
      error: undefined,
      data: appointmentsList.data,
    };
  }
);

/**
 * Populate table data if has no data
 */
watchEffect(() => {
  scheduledAppointmentsTableData.value = [];
  const scheduledAppointments = unref(scheduledAppointmentsListInfo);
  if (unref(scheduledAppointmentsTableData).length === 0) {
    if (scheduledAppointments.loading || !!scheduledAppointments.error) {
      scheduledAppointmentsTableData.value = [];
      return;
    }
    const appointmentsResponse = unref(scheduledAppointmentsListInfo).data
      ?.data;

    if (!appointmentsResponse) {
      scheduledAppointmentsTableData.value = [];
      return;
    }
    scheduledAppointmentsTableData.value = appointmentsResponse;
  }
});

function handleAppointmentClicked(appointment: MaintenanceAppointment) {
  maintenanceModalTitle.value =
    appointment.companyAssetId +
    '\u00A0 - \u00A0' +
    appointment.maintenanceItemName;
  selectedAppointment.value = appointment;
  isModalVisible.value = true;
}

function handleModalCancel(): void {
  isModalVisible.value = false;
}

function handleReloadAppointmentsTable(): void {
  forceUpdateMaintenanceAppointments.value =
    forceUpdateMaintenanceAppointments.value + 1;
  handleModalCancel();
}
</script>

<template>
  <WidgetCard
    :loading="scheduledAppointmentsListInfo.loading"
    :action-title="$tc('assets.planMaintenance')"
    @click="$router.push('/maintenance/planner')"
  >
    <MaintenancePlanListTable
      ref="maintenanceOverviewPlanListTable"
      class="single-asset-maintenance-plan-list"
      :cols="cols"
      :tableList="scheduledAppointmentsTableData"
      :pageTotal="0"
      max-height="340"
      :shouldStretchLastColumn="true"
      @appointment-clicked="handleAppointmentClicked"
    />
    <MaintenanceModal
      v-if="isModalVisible"
      class="appointment-modal"
      :isModalVisible="isModalVisible"
      :title="maintenanceModalTitle"
      :appointment="selectedAppointment"
      :modalTriggerSource="modalTriggerSource"
      @close="handleModalCancel"
      @reload="handleReloadAppointmentsTable"
    />
  </WidgetCard>
</template>

<style lang="scss" scoped>
.appointment-modal :deep(.el-dialog) {
  width: 650px !important;
}

.single-asset-maintenance-plan-list {
  margin-left: 20px;
  margin-right: 20px;

  :deep(.el-table__body) {
    width: inherit !important;

    td:last-child .cell {
      width: auto !important;
    }

    colgroup col:last-child {
      width: 100%;
    }
  }

  :deep(.el-table__header) {
    width: inherit !important;

    .cell:nth-last-child(1) {
      width: auto !important;
      min-width: 180px;
    }

    colgroup col:last-child {
      width: 100%;
    }
  }
}

:deep(.progress-bar-wrapper) {
  width: 100%;
}

:deep(.maintenance-monitor-progress-rules-value) {
  width: 30px;
}
</style>
