import { getKpisForMultipleAssets } from '@/api/kpis';
import { ActiveContext } from '@/auth/context';
import { KPI_FIELDS } from './workData/lookuptable';

export async function fetchLastCommunicationTimes(
  assetIds: string[],
  context: ActiveContext
): Promise<Map<string, any>> {
  const requestData = {
    metadata: {
      filter: {
        assetIds,
      },
    },
    details: [
      {
        fields: [{ code: KPI_FIELDS.LastCommunicationTime }],
      },
    ],
  };
  try {
    const response = await getKpisForMultipleAssets(requestData, context);
    return new Map(
      response.data.map((obj) => [obj.assetId, obj.values.at(0)?.v])
    );
  } catch (err) {
    console.log(err);
    return new Map<string, any>();
  }
}
