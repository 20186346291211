<script lang="ts">
import { ErrorType } from '@/api/types';
import { getModules, getUserById, updateUser } from '@/api/users';
import NewCard from '@/components/cusCard/NewCard.vue';
import i18n from '@/lang';
import { PageModule } from '@/store/modules/page';
import {
  customFailedMessage,
  promptFailedBox,
  promptSuccessBox,
} from '@/utils/prompt';
import { ALL_CLAIMS_CODES } from '@/utils/workData/lookuptable';
import { omit } from 'lodash';
import { Component, Prop, Vue } from 'vue-property-decorator';
import EditUserInfo from './components/EditUserInfo.vue';

@Component({
  name: 'EditUser',
  components: {
    EditUserInfo,
    NewCard,
  },
})
export default class extends Vue {
  @Prop({ required: true }) userId!: string;

  modulesInfo: any = [];
  defaultChecked: string[] = [];
  title: string = '';
  editUserFormIsLoading: boolean = false;
  userForm = {
    username: '',
    firstName: '',
    lastName: '',
    email: '',
    roleName: '',
    organizationId: null,
    mobilePhone: '',
    note: '',
    claimsFtd: [''],
    claims: [],
  };

  errorInfos: ErrorType[] = [
    {
      code: '',
      field: '',
      message: '',
    },
  ];

  activeName: string = 'first';
  visiable: boolean = false;
  dialogContent: any = '';

  created() {
    this.fetchModules();
    this.fetch();
  }

  handleCancel() {
    this.visiable = false;
  }

  handleClick(tab: any, event: any): void {}

  async saveUserInfo() {
    const newValues = omit(this.userForm, 'claims');

    await updateUser(this.userId, newValues).then((res) => {
      if (res.code === 200) {
        this.errorInfos = [];
        promptSuccessBox(this.$t('common.save') as string);
        this.$router.push('/administration/index');
      } else if (res.code === 400) {
        promptFailedBox(this.$t('common.save') as string);
        this.errorInfos = res.data.errors;
      } else if (res.code === 500) {
        this.$message({
          message: this.$t('userModule.saveFailedrights').toString(),
          type: 'error',
        });
        return;
      }
    });
  }

  async fetchModules() {
    await getModules().then((res) => {
      if (!res) {
        customFailedMessage(this.$t('common.errorWithFetchingData') as string);
        return;
      }

      if (res.code === 200) {
        this.modulesInfo = res.data;
        return;
      }

      customFailedMessage(this.$t('common.errorWithFetchingData') as string);
    });
  }

  async fetch() {
    try {
      this.editUserFormIsLoading = true;
      await getUserById(this.userId).then((res) => {
        if (!res) {
          customFailedMessage(
            this.$t('common.errorWithFetchingData') as string
          );
          return;
        }

        if (res.code === 200) {
          // this.userForm = res.data;
          Object.assign(this.userForm, res.data);
          const title = i18n.t('common.editInterpolated', {
            name: this.userForm.username,
          });
          PageModule.setTitle(title);

          const rootMenus = [ALL_CLAIMS_CODES.AUTHRSC_MOD_ADMINISTRATION];
          let arr: string[] = [];

          this.userForm.claims.forEach((item: any) => {
            if (!rootMenus.includes(item.resource)) arr.push(item.resource);
          });

          this.userForm.claimsFtd = arr;
          return;
        }

        customFailedMessage(this.$t('common.errorWithFetchingData') as string);
      });
    } catch (error) {
      console.log(error);
    } finally {
      this.editUserFormIsLoading = false;
    }
  }
}
</script>

<template>
  <NewCard v-loading="editUserFormIsLoading">
    <el-tabs
      v-model="activeName"
      @tab-click="handleClick"
      style="margin: 0px 20px"
      class="detail-tabs"
    >
      <el-tab-pane
        :label="$t('userModule.generalInfo')"
        name="first"
        style="overflow: auto"
      >
        <EditUserInfo
          :id="userId"
          :userForm="userForm"
          :errorInfo="errorInfos"
          @handle-saveInfos="saveUserInfo"
        />
      </el-tab-pane>
    </el-tabs>
  </NewCard>
</template>

<style lang="scss" scoped></style>
