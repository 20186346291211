<script lang="ts">
import { ALL_PAGE_CODES } from '@/api/users';
import NavPage from '@/components/nav/NavPage.vue';
import { UserModule } from '@/store/modules/user';
import { ALL_CLAIMS_CODES } from '@/utils/workData/lookuptable';
import { Component, Vue } from 'vue-property-decorator';
import Deployments from './Deployments.vue';
import History from './History.vue';
import PendingUpdates from './PendingUpdates.vue';

@Component({
  name: 'DeployManagement',
  components: {
    NavPage,
    deployments: Deployments,
    history: History,
    'pending-updates': PendingUpdates,
  },
})
export default class extends Vue {
  ALL_CLAIMS_CODES = ALL_CLAIMS_CODES;

  /** Local variables */
  activeName: string = 'deployments';
  previousTabIndex: number = 0;

  menuAccess: Partial<Record<ALL_PAGE_CODES, boolean>> = {
    AUTHRSC_PAGE_PENDING_UPDATES: false,
    AUTHRSC_PAGE_DEPLOYMENTS: false,
    AUTHRSC_PAGE_DEPLOYMENT_HISTORY: false,
  };

  /**
   * Handle tab click
   */
  handleTabClick(): void {
    if (
      this.$route.query.activeName != this.activeName &&
      this.activeName === 'deployments'
    )
      this.$router.push('/deploy/index?activeName=deployments');
    if (
      this.$route.query.activeName != this.activeName &&
      this.activeName === 'history'
    )
      this.$router.push('/deploy/index?activeName=history');
    if (
      this.$route.query.activeName != this.activeName &&
      this.activeName === 'pending-updates'
    )
      this.$router.push('/deploy/index?activeName=pending-updates');
  }

  /**
   * Show default tab
   */
  showDefaultTab(): void {
    if (this.$route.query.activeName) {
      this.activeName = this.$route.query.activeName as string;
      return;
    }

    this.activeName = this.menuAccess.AUTHRSC_PAGE_DEPLOYMENTS
      ? 'deployments'
      : this.menuAccess.AUTHRSC_PAGE_PENDING_UPDATES
      ? 'pending-updates'
      : 'history';
  }

  async mounted(): Promise<void> {
    var keys = Object.keys(this.menuAccess) as ALL_PAGE_CODES[];
    for (let i = 0; i < keys.length; i++) {
      this.menuAccess[keys[i]] = await UserModule.hasMenu(keys[i]);
    }
    this.showDefaultTab();
  }

  /**
   * Check if the logged user has auth resource code for tab view
   * @param menu
   */
  hasMenu(menu: ALL_PAGE_CODES): boolean {
    return this.menuAccess[menu] ?? false;
  }
}
</script>

<template>
  <NavPage :visible="false">
    <el-tabs
      id="prod_cata_tabs"
      v-model="activeName"
      @tab-click="handleTabClick"
      style="padding: 0 20px"
    >
      <el-tab-pane
        v-if="hasMenu(ALL_CLAIMS_CODES.AUTHRSC_PAGE_PENDING_UPDATES)"
        id="pending-updates"
        name="pending-updates"
        :label="$t('deployManagementModule.navCard.pendingUpdates')"
      >
        <pending-updates v-if="activeName === 'pending-updates'" />
      </el-tab-pane>
      <el-tab-pane
        v-if="hasMenu(ALL_CLAIMS_CODES.AUTHRSC_PAGE_DEPLOYMENTS)"
        id="deployments"
        name="deployments"
        :label="$t('deployManagementModule.navCard.deployments')"
      >
        <deployments v-if="activeName === 'deployments'" />
      </el-tab-pane>
      <el-tab-pane
        v-if="hasMenu(ALL_CLAIMS_CODES.AUTHRSC_PAGE_DEPLOYMENT_HISTORY)"
        id="history"
        name="history"
        :label="$t('deployManagementModule.navCard.history')"
      >
        <history v-if="activeName === 'history'" />
      </el-tab-pane>
    </el-tabs>
  </NavPage>
</template>

<style lang="scss" scoped>
.user-setting-form {
  margin: 0 20px;
}
</style>
