<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import AssetStatusTable from './AssetStatusTable.vue';
import Details from './Details.vue';
import NavPage from '@/components/nav/NavPage.vue';
import {
  getSystemDeployment,
  getDeploymentForSpecifiedAsset,
  SystemDeployment,
  SystemDeploymentAsset,
  AssetDeploymentLog,
} from '@/api/deploymentDetails';
import { HISTORY_DETAILS_COLS } from '@/utils/workData/historyDetails';
import { PageModule } from '@/store/modules/page';
import { calculateTimeDifference, formatTimer } from '@/utils/misc';
import { Moment } from 'moment';

interface tableCols {
  label: string;
  prop: string;
}

@Component({
  name: 'HistoryView',
  components: {
    NavPage,
    'asset-status-table': AssetStatusTable,
    'asset-details': Details,
  },
})
export default class extends Vue {
  /** Local variables */
  historyDetailPageIsLoading: boolean = false;
  deploymentDetailsSectionIsLoading: boolean = false;
  selectedAssetDeployId: string = '';
  title: string = '';
  currentSystemDeployment: SystemDeployment = {
    noOfCancelledDeploys: 0,
    noOfFailedDeploys: 0,
    noOfInvolvedAssets: 0,
    noOfRevertedDeploys: 0,
    noOfSuccessDeploys: 0,
    systemReleaseDeploymentEndTime: '',
    systemReleaseDeploymentStartTime: '',
    systemReleaseDeploymentStatus: '',
    systemDeploymentId: '',
    systemReleaseId: '',
    companyId: '',
    companyName: '',
    assets: [],
  };
  cols: tableCols[] = HISTORY_DETAILS_COLS;
  detailsData: any = {};
  pageTitle: string = '';

  created() {
    this.getDeploymentsData();
  }

  /**
   * Generate title
   */
  generateTitle(systemReleaseId: string, companyName: string): void {
    PageModule.setTitle(`${systemReleaseId ?? ''} | ${companyName ?? ''}`);
  }

  /**
   * Get deployments data from API
   */
  async getDeploymentsData(): Promise<void> {
    try {
      this.historyDetailPageIsLoading = true;
      const response = await getSystemDeployment(
        this.$route.params.deploymentId
      );
      this.currentSystemDeployment = response.data;
      /** Used to have same field for status processing as retrieved from GET /asset-logs endpoint response */
      this.currentSystemDeployment.assets?.map(
        (item: SystemDeploymentAsset) => {
          item.assetDeployStatus = item.deployStatus;
        }
      );
      this.handleDeploymentDuration();
      this.selectedAssetDeployId =
        this.currentSystemDeployment!.assets[0].assetDeploymentId;
      if (response.data.assets.length > 0)
        this.getSpecifiedDeployDetails(this.selectedAssetDeployId);
      this.generateTitle(
        response.data.systemReleaseId,
        response.data.companyName
      );
    } catch (error) {
      console.log(error);
    } finally {
      this.historyDetailPageIsLoading = false;
    }
  }

  /**
   * Handle from UI the difference between end and start time
   * returned format: 0h 0m 0s
   */
  handleDeploymentDuration(): void {
    this.currentSystemDeployment.assets?.forEach(
      (asset: SystemDeploymentAsset) => {
        asset.deploymentDuration = calculateTimeDifference(
          asset.deploymentStartTime,
          asset.deploymentEndTime
        );
      }
    );
  }

  /**
   * Get spcecific deployment details by id
   * @param id
   */
  async getSpecifiedDeployDetails(id: string): Promise<void> {
    try {
      this.deploymentDetailsSectionIsLoading = true;
      this.selectedAssetDeployId = id;
      const res = await getDeploymentForSpecifiedAsset(id);
      this.detailsData = res.data;
    } catch (error) {
      console.log(error);
    } finally {
      this.deploymentDetailsSectionIsLoading = false;
    }
  }

  /**
   * Format date and time to logged in user time zone
   * @param timestamp
   */
  formatDateAndTime(timestamp: string): string | Moment {
    return formatTimer(timestamp, 'datetime');
  }
}
</script>

<template>
  <NavPage
    v-loading="historyDetailPageIsLoading"
    :arrowBackIsVisible="true"
    :headerTitle="pageTitle"
  >
    <template #header-content>
      <div class="status-container">
        <span class="status-element">
          {{ $t('historyView.success') }}:
          {{ currentSystemDeployment.noOfSuccessDeploys }}
        </span>
        <span class="status-element">
          {{ $t('historyView.reverted') }}:
          {{ currentSystemDeployment.noOfRevertedDeploys }}
        </span>
        <span class="status-element">
          {{ $t('historyView.cancelled') }}:
          {{ currentSystemDeployment.noOfCancelledDeploys }}
        </span>
        <span class="status-element">
          {{ $t('historyView.failed') }}:
          {{ currentSystemDeployment.noOfFailedDeploys }}
        </span>
        <span class="status-date-element">
          {{
            `${formatDateAndTime(
              currentSystemDeployment.systemReleaseDeploymentStartTime
            )} -
             ${formatDateAndTime(
               currentSystemDeployment.systemReleaseDeploymentEndTime
             )}`
          }}
        </span>
      </div>
    </template>
    <h3 class="header" style="margin-left: 20px">
      {{ $t('deployManagementModule.history.assetsInvolved') }} ({{
        currentSystemDeployment.noOfInvolvedAssets
      }})
    </h3>
    <div class="page-content">
      <asset-status-table
        :tableData="currentSystemDeployment.assets"
        :cols="cols"
        class="table"
        @selectAsset="getSpecifiedDeployDetails"
      />
      <asset-details
        v-loading="deploymentDetailsSectionIsLoading"
        :data="detailsData"
      />
    </div>
  </NavPage>
</template>

<style scoped>
.status-container {
  margin-right: 0%;
}

.status-element {
  padding: 0 20px 0 20px;
}

.status-date-element {
  color: gray;
}
</style>

<style lang="scss" scoped>
.page-content {
  display: flex;
  flex-direction: row;
  width: 100%;
  padding: 16px;

  .table {
    width: 50%;
    height: 710px;
    padding: 16px;
    border-right: 1px solid rgba(55, 62, 65, 0.2);
  }

  .details {
    width: 50%;
  }
}

.header {
  border-bottom: 1px solid;
  margin: 0;
}
</style>
