export const HISTORY_DETAILS_COLS = [
  {
    label: 'deployManagementModule.history.companyAssetId',
    prop: 'companyAssetId',
  },
  {
    label: 'deployManagementModule.history.organizationName',
    prop: 'organizationName',
  },
  {
    label: 'deployManagementModule.history.timeSpent',
    prop: 'deploymentDuration',
  },
  {
    label: 'deployManagementModule.history.deployStatus',
    prop: 'deployStatus',
  },
];
