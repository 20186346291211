/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon';
icon.register({
  kpiZoom: {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<g data-name="Group 3602" _fill="#373e41"><path pid="0" data-name="Path 1589" d="M6 1.5V0H.75A.749.749 0 000 .75V6h1.5V2.556l6.962 6.963 1.057-1.057L2.556 1.5z"/><path pid="1" data-name="Path 1590" d="M23.25 0h-5.247v1.5h3.44l-6.962 6.962 1.057 1.057 6.963-6.963V6h1.5V.75A.75.75 0 0023.25 0z"/><path pid="2" data-name="Path 1591" d="M22.501 21.444l-6.963-6.963-1.057 1.057 6.963 6.963h-3.44v1.5h5.247a.75.75 0 00.75-.75v-5.247h-1.5z"/><path pid="3" data-name="Path 1592" d="M8.462 14.474L1.5 21.444v-3.44H0v5.247a.75.75 0 00.75.75H6v-1.5H2.556l6.963-6.967z"/></g>',
  },
});
