<script lang="ts">
import {
  CustomizedKpiLimit,
  getAlarmsDataByCustomUrl,
  getCustomizedAlarmsDataByCustomUrl,
  updateAlarmConfigByLimitId,
  updateAlarmConfigByOrganizationId,
} from '@/api/alarmConfig';
import { LoggedInUserRef, useLoggedInUser } from '@/auth/user';
import SelectTableHeader from '@/components/table/SelectTableHeader.vue';
import { CustMgmtModule } from '@/store/modules/custMgmt';
import { UserModule } from '@/store/modules/user';
import { isCompanyTypeOf } from '@/utils/companyService';
import {
  customFailedMessage,
  customSuccessMessage,
  customWarningMessage,
} from '@/utils/prompt';
import {
  ALARM_CONFIG_SELECTION_OPTIONS,
  ALARM_CONFIG_TABLE_COLUMNS,
  ALARM_SETTINGS_TABLE_COLUMNS_FOR_CUSTOMER,
} from '@/utils/workData/alarmConfig';
import { ASSET_TYPE_LIST, COMPANY_TYPE } from '@/utils/workData/lookuptable';
import { unref } from 'vue';
import { Component, Vue } from 'vue-property-decorator';
import { Kpi } from '../models/kpi.model';
import AlarmConfigTable from './AlarmConfigTable.vue';
import { InvalidAlarm } from '../models/invalidAlarm';
import { AlarmTableData } from '../models/alarmTableData';

@Component({
  name: 'AlarmConfigurationEditPage',
  components: {
    'select-table-header': SelectTableHeader,
    AlarmConfigTable,
  },
})
export default class extends Vue {
  /** Local variables */
  alarmTableColumns = ALARM_CONFIG_TABLE_COLUMNS;
  assetTypeList = ASSET_TYPE_LIST;
  searchFieldOptions = ALARM_CONFIG_SELECTION_OPTIONS;
  loadingText: string = this.$t('alarmConfig.loadingAlarmData') as string;
  updatingText: string = this.$t('customerModule.updatingText') as string;
  isAlarmConfigInEditMode: boolean = false;
  totalAlarms: number = 0;
  warningMessageListAndLastCalled: { time: number; list: string[] }[] = [];
  currentPage = 1;
  pageSize = UserModule.gridPageSize;
  alarmTableIsLoading: boolean = false;
  tableDataList: AlarmTableData[] = [];
  isColumnSelectionVisible: boolean = false;
  sortAndOrderData: any = {
    sortBy: null,
    order: null,
  };
  searchParams: any = {
    reference: null,
    value: null,
  };
  defaultSort: string = 'createdOn';
  defaultOrder: string = 'DESC';
  isAlarmConfigurationUpdating: boolean = false;
  loggedInUser!: LoggedInUserRef;
  editAlarmIsLoading: boolean = false;
  changedAlarms: Kpi[] = [];
  invalidAlarms: InvalidAlarm[] = [];

  created() {
    this.loggedInUser = useLoggedInUser();
    this.prepareDefaultInitialization();

    if (!isCompanyTypeOf([COMPANY_TYPE.Hyva])) {
      this.alarmTableColumns = ALARM_SETTINGS_TABLE_COLUMNS_FOR_CUSTOMER;
    }
  }

  /** Prepare default initialization */
  prepareDefaultInitialization() {
    let finalUrlParamsForSearch: string = this.generateRequestUrlWithParams(
      this.currentPage,
      this.pageSize,
      null,
      this.defaultSort,
      this.defaultOrder
    );
    this.fetchRemoteAlarms(finalUrlParamsForSearch);

    CustMgmtModule.setReStore(false);
    CustMgmtModule.clearData();
  }

  /** Fetch when search is triggered */
  fetchAlarmDataBySearchParams() {
    let finalUrlParamsForSearch: string = this.generateRequestUrlWithParams(
      this.currentPage,
      this.pageSize,
      this.searchParams,
      this.sortAndOrderData.sortBy,
      this.sortAndOrderData.order
    );
    this.fetchRemoteAlarms(finalUrlParamsForSearch);
  }

  /** Filter by sort event */
  fetchAlarmsDataBySortEvent(sortBy: any, order: any) {
    order != ''
      ? (this.sortAndOrderData.sortBy = sortBy)
      : (this.sortAndOrderData.sortBy = null);
    order != ''
      ? (this.sortAndOrderData.order = order)
      : (this.sortAndOrderData.order = null);
    let finalUrlParamsForSearch: string = this.generateRequestUrlWithParams(
      this.currentPage,
      this.pageSize,
      this.searchParams,
      this.sortAndOrderData.sortBy,
      this.sortAndOrderData.order
    );
    this.fetchRemoteAlarms(finalUrlParamsForSearch);
  }

  /** Handle pagination */
  fetchAlarmsDataByPageSelection(page: number, pageSize: number) {
    this.currentPage = page;
    let finalUrlParamsForSearch: string = this.generateRequestUrlWithParams(
      page,
      pageSize,
      this.searchParams,
      this.sortAndOrderData.sortBy,
      this.sortAndOrderData.order
    );
    this.fetchRemoteAlarms(finalUrlParamsForSearch);
  }

  /** Generate request URL by multiple factors */
  generateRequestUrlWithParams(
    pageNumber: any,
    pageSize: number,
    searchParams: any,
    sortBy: any,
    order: any
  ) {
    let searchFieldName = searchParams ? searchParams.reference : null;
    let searchFieldValue = searchParams ? searchParams.value : null;
    let finalUrl = '';

    pageNumber ? (finalUrl += `?page=${pageNumber}`) : 1;
    pageNumber ? (finalUrl += `&size=${pageSize}`) : this.pageSize;

    if (searchFieldName && searchFieldValue) {
      finalUrl += `&searchFieldName=${searchFieldName}&searchFieldValues=${encodeURIComponent(
        searchFieldValue
      )}`;
    }

    if (sortBy && order) {
      finalUrl += `&sortBy=${sortBy}&order=${order}`;
    } else {
      finalUrl += `&sortBy=${this.defaultSort}&order=${this.defaultOrder}`; // default must be sorted by createdOn DESC !
    }

    return finalUrl;
  }

  removeInvalidAlarm(id: string) {
    const findRowIndex = this.invalidAlarms.findIndex((i) => i.id == id);
    if (findRowIndex >= 0) {
      this.invalidAlarms = this.invalidAlarms.splice(findRowIndex, 1);
    }
  }

  addInvalidAlarm(invalidAlarm: InvalidAlarm) {
    const findRow = this.invalidAlarms.find((i) => i.id == invalidAlarm.id);
    if (!findRow) {
      this.invalidAlarms.push(invalidAlarm);
    } else {
      findRow.missingWarningInterval = invalidAlarm.missingWarningInterval;
      findRow.missingAlarmInterval = invalidAlarm.missingAlarmInterval;
      findRow.isInvalidWarningThreshold =
        invalidAlarm.isInvalidWarningThreshold;
      findRow.isInvalidAlarmThreshold = invalidAlarm.isInvalidAlarmThreshold;
      findRow.isInvalidAlarmAndWarning = invalidAlarm.isInvalidAlarmAndWarning;
    }
  }

  addChangedAlarm(changedAlarm: Kpi) {
    let existingAlarm = this.changedAlarms.find(
      (row) => row.id == changedAlarm.id
    );

    if (!existingAlarm) {
      this.changedAlarms.push(changedAlarm);
    } else {
      existingAlarm.alarmLow = changedAlarm.alarmLow;
      existingAlarm.alarmHigh = changedAlarm.alarmHigh;
      existingAlarm.alarmIntervalUnit = changedAlarm.alarmIntervalUnit;
      existingAlarm.alarmIntervalValue = changedAlarm.alarmIntervalValue;
      existingAlarm.warningLow = changedAlarm.warningLow;
      existingAlarm.warningHigh = changedAlarm.warningHigh;
      existingAlarm.warningIntervalUnit = changedAlarm.warningIntervalUnit;
      existingAlarm.warningIntervalValue = changedAlarm.warningIntervalValue;
    }
  }

  /** Save remotly the alarm configuration */
  async handleSaveAlarmLimits(): Promise<void> {
    const { time: lastMessageTime, list }: { time?: number; list?: string[] } =
      this.warningMessageListAndLastCalled.pop() || {
        time: undefined,
        list: undefined,
      };
    const currentMessageTime = Date.now();

    if (this.invalidAlarms.length > 0) {
      const warningValidateMessage: string[] = [];
      if (this.invalidAlarms.some((i) => i.missingWarningInterval)) {
        warningValidateMessage.push(
          this.$t('alarmConfig.warningIntervalEmptyErrorMessage').toString()
        );
      }

      if (this.invalidAlarms.some((i) => i.missingAlarmInterval)) {
        warningValidateMessage.push(
          this.$t('alarmConfig.alarmIntervalEmptyErrorMessage').toString()
        );
      }

      if (
        this.invalidAlarms.some((i) => i.warningIntervalWithoutWarningTreshold)
      ) {
        warningValidateMessage.push(
          this.$t(
            'alarmConfig.warningIntervalWithoutWarningTresholdErrorMessage'
          ).toString()
        );
      }

      if (this.invalidAlarms.some((i) => i.alarmIntervalWithoutAlarmTreshold)) {
        warningValidateMessage.push(
          this.$t(
            'alarmConfig.alarmIntervalWithoutAlarmTresholdErrorMessage'
          ).toString()
        );
      }

      if (
        this.invalidAlarms.some(
          (i) => i.isInvalidWarningThreshold || i.isInvalidAlarmThreshold
        )
      ) {
        warningValidateMessage.push(
          this.$t('alarmConfig.thresholdErrorMessage').toString()
        );
      }

      if (this.invalidAlarms.some((i) => i.isInvalidAlarmAndWarning)) {
        warningValidateMessage.push(
          this.$t('alarmConfig.thresholdsRangeErrorMessage').toString()
        );
      }

      if (
        list?.length &&
        lastMessageTime &&
        currentMessageTime - lastMessageTime < 200
      ) {
        list.forEach((e) => {
          const index = warningValidateMessage.indexOf(e);
          if (index !== -1) {
            warningValidateMessage.splice(index, 1);
          }
        });
      }
      if (warningValidateMessage.length) {
        customWarningMessage(warningValidateMessage.join(' '));
      }
      return;
    }

    if (this.changedAlarms.length > 0) {
      this.editAlarmIsLoading = true;
      for (let i = 0; i < this.changedAlarms.length; i++) {
        await this.saveAlarmConfigurationLimit(this.changedAlarms[i]);
      }
      customSuccessMessage(
        this.$t('alarmConfig.configSuccessfullySaved') as string
      );
    }
    this.editAlarmIsLoading = false;
    this.$router.go(-1);
  }

  async saveAlarmConfigurationLimit(payloadData: Kpi): Promise<void> {
    if (isCompanyTypeOf([COMPANY_TYPE.Hyva])) {
      await updateAlarmConfigByLimitId(payloadData.id, {
        ...payloadData,
        warningIntervalUnit: payloadData.warningIntervalUnit || null,
        warningIntervalValue: payloadData.warningIntervalValue || null,
        alarmIntervalUnit: payloadData.alarmIntervalUnit || null,
        alarmIntervalValue: payloadData.alarmIntervalValue || null,
        warningHigh: payloadData.warningHigh || null,
        warningLow: payloadData.warningLow || null,
        alarmHigh: payloadData.alarmHigh || null,
        alarmLow: payloadData.alarmLow || null,
      });
    } else {
      const user = unref(this.loggedInUser);
      if (!user) {
        // Should never happen
        throw new Error('No user logged in');
      }
      await updateAlarmConfigByOrganizationId(user.organization.id, {
        ...payloadData,
        warningIntervalUnit: payloadData.warningIntervalUnit || null,
        warningIntervalValue: payloadData.warningIntervalValue || null,
        alarmIntervalUnit: payloadData.alarmIntervalUnit || null,
        alarmIntervalValue: payloadData.alarmIntervalValue || null,
        warningHigh: payloadData.warningHigh || null,
        warningLow: payloadData.warningLow || null,
        alarmHigh: payloadData.alarmHigh || null,
        alarmLow: payloadData.alarmLow || null,
      });
    }
  }

  fetchRemoteAlarms(finalUrlParamsForSearch: string) {
    if (isCompanyTypeOf([COMPANY_TYPE.Hyva])) {
      this.fetchRemoteAlarmsDataByCustomUrl(finalUrlParamsForSearch);
    } else {
      this.fetchRemoteCustomizedAlarmsDataByCustomUrl(finalUrlParamsForSearch);
    }
  }

  mapThresholdsAndIntervalsToKPI(item: CustomizedKpiLimit): AlarmTableData {
    const changedAlarm = this.changedAlarms.find((p) => p.id == item.id);

    if (!changedAlarm) {
      return {
        ...item,
        isEditable: true,
      };
    }

    return {
      ...item,
      isEditable: true,
      warningLow: this.toNumberOfNull(changedAlarm.warningLow),
      warningHigh: this.toNumberOfNull(changedAlarm.warningHigh),
      warningIntervalUnit: changedAlarm.warningIntervalUnit,
      warningIntervalValue: this.toNumberOfNull(
        changedAlarm.warningIntervalValue
      ),
      alarmLow: this.toNumberOfNull(changedAlarm.alarmLow),
      alarmHigh: this.toNumberOfNull(changedAlarm.alarmHigh),
      alarmIntervalUnit: changedAlarm.alarmIntervalUnit,
      alarmIntervalValue: this.toNumberOfNull(changedAlarm.alarmIntervalValue),
    };
  }

  toNumberOfNull(value?: string): number | null {
    return value ? Number(value) : null;
  }

  /** Remote fetch alarms bata by custom URL */
  async fetchRemoteAlarmsDataByCustomUrl(customUrl: any) {
    this.alarmTableIsLoading = true;
    await getAlarmsDataByCustomUrl(customUrl).then((res) => {
      if (!res) return;

      if (res.code && res.code === 200) {
        this.totalAlarms = res.data.total;

        this.tableDataList = res.data.limits.map(
          (item: CustomizedKpiLimit): AlarmTableData =>
            this.mapThresholdsAndIntervalsToKPI(item)
        );
      }

      if (res.code === 500)
        customFailedMessage(this.$t('common.serverError').toString());

      this.alarmTableIsLoading = false;
    });
  }

  async fetchRemoteCustomizedAlarmsDataByCustomUrl(customUrl: any) {
    this.alarmTableIsLoading = true;
    const user = unref(this.loggedInUser);
    if (!user) {
      // Should never happen
      throw new Error('No user logged in');
    }
    await getCustomizedAlarmsDataByCustomUrl(
      user.organization.id,
      customUrl
    ).then((res) => {
      if (!res) return;

      if (res.code && res.code === 200) {
        this.totalAlarms = res.data.total;
        this.tableDataList = res.data.limits.map(
          (item: CustomizedKpiLimit): AlarmTableData =>
            this.mapThresholdsAndIntervalsToKPI(item)
        );
      }

      if (res.code === 500)
        customFailedMessage(this.$t('common.serverError').toString());

      this.alarmTableIsLoading = false;
    });
  }
}
</script>

<template>
  <div
    class="app-container"
    id="alarm_settings_main_component"
    style="overflow: hidden"
    v-loading="editAlarmIsLoading"
    :element-loading-text="$t('alarmConfig.updatingAlarmData').toString()"
  >
    <div class="d-flex jc-start ai-center" style="margin-top: -8px">
      <i
        class="el-icon-arrow-left"
        style="font-size: 25px; cursor: Pointer"
        @click="$router.go(-1)"
      />
      <div class="alarm-config-title">
        {{ $t('alarmConfig.title') }}
      </div>
      <div
        id="alarm_config_total_info"
        class="d-flex ai-center flex-g1 alarm-total-statistics"
      />
      <div>
        <el-button
          id="alarm_settings_configure_save_btn"
          type="plain"
          @click="handleSaveAlarmLimits"
        >
          <img class="configure-icon" src="@/icons/svg/save-disk-icon.svg" />
          {{ $t('common.save') }}
        </el-button>
      </div>
    </div>

    <div class="border-line"></div>

    <el-row>
      <select-table-header
        id="alarm_settings_selection_component"
        style="margin-bottom: 20px"
        :searchFieldOptions="searchFieldOptions"
        :cols="alarmTableColumns"
        :searchParams="searchParams"
        :isColumnSelectionVisible="isColumnSelectionVisible"
        @search-event="fetchAlarmDataBySearchParams"
      />
      <AlarmConfigTable
        id="alarm_settings_index_table"
        v-loading="alarmTableIsLoading || isAlarmConfigurationUpdating"
        :element-loading-text="loadingText"
        :tableList="tableDataList"
        :total="totalAlarms"
        :cols="alarmTableColumns"
        :viewPagePath="null"
        :warningMessageListAndLastCalled="warningMessageListAndLastCalled"
        @handle-page="fetchAlarmsDataByPageSelection"
        @handle-sort-change="fetchAlarmsDataBySortEvent"
        @remove-invalid-alarm="removeInvalidAlarm"
        @add-invalid-alarm="addInvalidAlarm"
        @add-changed-alarm="addChangedAlarm"
      />
    </el-row>
  </div>
</template>

<style scoped>
.configure-icon {
  height: 15px;
  margin: 0 5px -3px 0;
}
</style>

<style lang="scss" scoped>
.alarm-config-title {
  font-size: 20px;
  font-family: $font-Roboto-Medium;
  font-weight: 500;
  line-height: 24px;
  color: #373e41;
  margin-right: 16px;
}

.alarm-total-statistics {
  font-size: 16px;
  font-family: $font-Roboto-Medium;
  font-weight: 400;
  line-height: 19px;
  color: rgba(55, 62, 65, 0.6);
}

.border-line {
  margin: 0.857143rem -1.428571rem 20px;
  border-bottom: 1px solid #dddddd;
}

.el-select {
  margin-right: 40px;
}

.cust-select :deep(.el-input__inner) {
  height: $inputHeight;
  width: 200px;
  border: 1px solid #707070 !important;
  font-size: 1.285714rem;
  font-family: $font-Roboto-Medium;
  font-weight: 400;
  line-height: 1.357143rem;
  color: #373e41;
  opacity: 1;
}
</style>
