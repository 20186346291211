<script lang="ts">
import {
  getAssetOnBoardingById,
  getProductModelsByCustomParams,
} from '@/api/assetsMgmt';
import { getTimezones, TimezoneResponse } from '@/api/timezone';
import { ErrorType } from '@/api/types';
import CusFormItem from '@/components/form/CusFormItem.vue';
import i18n from '@/lang';
import { useHyvaRegionsQuery } from '@/query/hyvaRegion';
import { useServiceRegionsQuery } from '@/query/serviceRegion';
import {
  AssetField,
  AssetFieldStateType,
  AssetFieldToState,
  AssetStage,
  AssetType,
  getAssetFieldStates,
} from '@/utils/assetTypes';
import { disabledFutureDate } from '@/utils/date';
import { customFailedMessage } from '@/utils/prompt';
import {
  ASSET_TYPE_LIST,
  ERROR_CODE_LIST,
  PRODUCT_MODEL_STATUS_ENUM,
} from '@/utils/workData/lookuptable';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { formatServerDate } from '../helper';
import { Asset, ProductModelNumber, SimpleProductModel } from './models/asset';

@Component({
  name: 'registerForm',
  components: { CusFormItem },
})
export default class extends Vue {
  @Prop({ required: true }) registerAssetForm!: any;
  @Prop({ required: false, default: false }) validateFieldInputs!: boolean;
  @Prop({ required: false, default: false }) isRegisterFormValid!: boolean;
  @Prop({ required: false, default: false }) editAssetId!: string;

  /** Local variables */
  assetList = ASSET_TYPE_LIST;
  assetTypeList = AssetType;
  assetFieldStateType = AssetFieldStateType;
  prodModelList: ProductModelNumber[] | null = [];
  modelNumbers: SimpleProductModel[] | null = [];
  productModelStatusEnum: typeof PRODUCT_MODEL_STATUS_ENUM =
    PRODUCT_MODEL_STATUS_ENUM;
  timezones: TimezoneResponse[] = [];
  page: string = '1';
  isEditAssetLoading: boolean = false;
  pageSize: string = '10000';
  defaultSortByReference: string = 'createdOn';
  defaultSortOrder: string = 'DESC';
  defaultPageSize: string = '1000';
  defaultLifeCycle: string = 'LCL_APPROVED';
  isProductModelChanged: boolean = false;
  editAssetModelNumber: string = '';
  initialProductModel: ProductModelNumber | undefined =
    {} as ProductModelNumber;

  pickerOptions: any = {
    disabledDate: disabledFutureDate,
  };

  serviceRegionsQuery!: ReturnType<typeof useServiceRegionsQuery>;
  hyvaRegionsQuery!: ReturnType<typeof useHyvaRegionsQuery>;

  created() {
    this.hyvaRegionsQuery = useHyvaRegionsQuery();
    this.serviceRegionsQuery = useServiceRegionsQuery();
    getTimezones().then(({ data }) => (this.timezones = data));
    if (!!this.editAssetId) {
      this.fetchAssetOnBoardingById(true);
    }
  }

  errorCode = ERROR_CODE_LIST;
  errorInfos: ErrorType[] = [
    {
      code: '',
      field: '',
      message: '',
    },
  ];
  /**
   * Retrieve from API the assetOnBoarding by Id data
   */
  async fetchAssetOnBoardingById(
    shouldGetModelsAndSetInitialFormValues?: boolean
  ) {
    try {
      this.isEditAssetLoading = true;
      await getAssetOnBoardingById(this.editAssetId).then((res) => {
        if (!res) {
          customFailedMessage(
            this.$t('common.errorWithFetchingData') as string
          );
          return;
        }

        if (res.code === 200) {
          this.registerAssetForm.assetDataCollectionId =
            res.data.assetDataCollectionId;
          this.registerAssetForm.assetType = res.data.assetType;
          this.registerAssetForm.productModelCode = res.data.productModelCode;

          this.registerAssetForm.productModelId = '';

          this.registerAssetForm.modelNumber = res.data.productModelNumber;
          this.editAssetModelNumber = res.data.productModelNumber;
          this.registerAssetForm.serviceRegion = res.data.serviceRegion;
          this.registerAssetForm.hyvaRegion = res.data.hyvaRegion;
          this.registerAssetForm.serialNumber = res.data.serialNumber;
          this.registerAssetForm.manufactureDate = formatServerDate(
            res.data.manufactureDate
          );
          this.registerAssetForm.timeZone = res.data.timezone;
          this.registerAssetForm.controllerId = res.data.controllerId;
          this.registerAssetForm.preliminaryAssetId =
            res.data.preliminaryAssetId;
          this.registerAssetForm.vinNumber = res.data.vinNumber;
          if (shouldGetModelsAndSetInitialFormValues) {
            this.getProductModels(true);
          }

          //send Up the title
          const title: string = i18n
            .t('common.editInterpolated', {
              name: res.data.serialNumber
                ? `${res.data.assetDataCollectionId}/${res.data.serialNumber}`
                : res.data.assetDataCollectionId,
            })
            .toString();
          this.$emit('send-title', title);
        }

        if (res.code === 400) {
          customFailedMessage(
            this.$t('common.errorWithFetchingData') as string
          );
        }
      });
    } catch (error) {
      console.log(error);
    } finally {
    }
  }

  handleAssetTypeChange() {
    this.prodModelList = null;
    this.registerAssetForm.productModelId = '';
    this.registerAssetForm.modelNumber = '';

    if (this.registerAssetForm.assetType === '') {
      this.prodModelList = [];
      return;
    }

    this.getProductModels();
  }

  get formFieldState(): AssetFieldToState {
    const assetType = this.registerAssetForm.assetType as AssetType;

    return getAssetFieldStates(AssetStage.Register, assetType);
  }

  @Watch('validateFieldInputs')
  private handleFieldValidation() {
    (this.$refs.registerAssetForm as any).validate((isValid: any) => {
      if (
        !!this.editAssetId &&
        this.registerAssetForm.productModelId === this.initialProductModel?.code
      ) {
        this.$emit('is-valid-event', isValid, this.initialProductModel?.id);
      } else {
        this.$emit('is-valid-event', isValid);
      }
    });
  }

  generateRequestUrlWithParams({
    pageNumber,
    pageSize,
    assetType,
    sortBy,
    order,
    lifeCycle,
  }: {
    pageNumber: string;
    pageSize: string;
    assetType: string;
    sortBy: string;
    order: string;
    lifeCycle: string;
  }) {
    let finalUrl = '';
    pageNumber
      ? (finalUrl += `?page=${pageNumber}`)
      : (finalUrl += `?page=${1}`);
    pageNumber
      ? (finalUrl += `&size=${pageSize}`)
      : (finalUrl += `&size=${this.defaultPageSize}`);
    finalUrl += `&lifeCycle=${lifeCycle}`;
    if (assetType) {
      finalUrl += `&assetType=${assetType}`;
    }
    sortBy && order
      ? (finalUrl += `&sortBy=${sortBy}&order=${order}`)
      : (finalUrl += `&sortBy=${this.defaultSortByReference}&order=${this.defaultSortOrder}`);
    return finalUrl;
  }

  /** Retrieve product models only with lifecycle = LCL_APPROVED */
  async getProductModels(shouldSetInitialsFormValues: boolean = false) {
    try {
      const customParams = !!this.editAssetId
        ? this.generateRequestUrlWithParams({
            pageNumber: '1',
            pageSize: this.defaultPageSize,
            assetType: this.registerAssetForm.assetType,
            sortBy: this.defaultSortByReference,
            order: this.defaultSortOrder,
            lifeCycle: this.defaultLifeCycle,
          })
        : `?assetType=${this.registerAssetForm.assetType}&lifeCycle=${this.productModelStatusEnum.LclApproved}&sortBy=createdOn&order=DESC&page=${this.page}&size=${this.pageSize}`;
      await getProductModelsByCustomParams(customParams).then((res: any) => {
        if (!res) {
          customFailedMessage(
            this.$t('common.errorWithFetchingData') as string
          );
          return;
        }

        if (res.code === 200) {
          const response = res.data;
          const uniqueModel = response
            .map((asset: Asset) => asset.code)
            .filter(
              (code: string, index: number, self: string) =>
                self.indexOf(code) === index
            );
          const uniqueModelList: ProductModelNumber[] = [];
          for (let model of uniqueModel) {
            const newUniqueModel = {
              id: response.filter((asset: Asset) => asset.code === model)[0].id,
              code: model,
              modelNumbers: response
                .filter((asset: Asset) => asset.code === model)
                .map((filteredAsset: Asset) => {
                  return {
                    code: filteredAsset.modelNumber,
                    id: filteredAsset.id,
                  };
                }),
            };
            uniqueModelList.push(newUniqueModel);
          }
          this.prodModelList = uniqueModelList;
          if (shouldSetInitialsFormValues) {
            this.initialProductModel = uniqueModelList?.find(
              (el) => el.code === this.registerAssetForm.productModelCode
            );
            this.registerAssetForm.productModelId =
              this.initialProductModel?.code || '';
            this.getModelNumbers();
            const productModelNumbers = uniqueModelList?.find((el) =>
              el.modelNumbers.some(
                (mn: SimpleProductModel) =>
                  mn.code === this.editAssetModelNumber
              )
            );
            this.registerAssetForm.modelNumber =
              productModelNumbers?.modelNumbers.find(
                (mn: SimpleProductModel) =>
                  mn.code === this.editAssetModelNumber
              )?.code;
          }

          return;
        }

        if (res.code === 400) {
          customFailedMessage(
            this.$t('common.errorWithFetchingData') as string
          );
        }
      });
    } catch (error) {
      console.log(error);
    } finally {
      this.isEditAssetLoading = false;
    }
  }

  getModelNumbers() {
    if (
      !this.isProductModelChanged &&
      this.registerAssetForm.productModelId !== this.initialProductModel?.code
    ) {
      this.isProductModelChanged = true;
    }
    const filteredProdModelList: ProductModelNumber[] | undefined =
      this.prodModelList?.filter(
        (pm: ProductModelNumber) =>
          pm.code === this.registerAssetForm.productModelId
      );

    this.modelNumbers =
      filteredProdModelList !== undefined && filteredProdModelList.length !== 0
        ? filteredProdModelList[0].modelNumbers
        : [];

    if (
      !!this.registerAssetForm.modelNumber &&
      !this.modelNumbers?.some(
        (el: SimpleProductModel) =>
          el.code === this.registerAssetForm.modelNumber
      )
    ) {
      this.registerAssetForm.modelNumber = '';
    }
  }

  validateMaxLength(
    field: AssetField,
    value: string | undefined,
    callback: Function
  ) {
    if (
      this.formFieldState[field] &&
      this.formFieldState[field].maxLength !== undefined &&
      value &&
      value.length > this.formFieldState[field]!.maxLength!
    ) {
      callback(
        new Error(
          this.$t('assetMgmt.maxLengthValidation', {
            characterCount: this.formFieldState[field]!.maxLength!,
          })
        )
      );
    }

    callback();
  }

  onServiceRegionChange(selectedValue: string) {
    const selectedRegion = this.serviceRegionsQuery.data.value?.find(
      (servReg) => servReg.serviceRegionCode === selectedValue
    );

    if (selectedRegion === undefined) {
      this.registerAssetForm.hyvaRegion = undefined;

      return;
    }

    this.registerAssetForm.hyvaRegion = selectedRegion.hyvaRegionCode;
  }

  //form validate rules
  get rules() {
    const tmpRules = {
      assetType: [
        {
          required: true,
          message: this.$t('common.mandatoryField'),
          trigger: 'change',
        },
      ],
      productModelId: [
        {
          required: true,
          message: this.$t('common.mandatoryField'),
          trigger: 'change',
        },
      ],
      modelNumber: [
        {
          required: true,
          message: this.$t('common.mandatoryField'),
          trigger: 'change',
        },
      ],
      serviceRegion: [
        {
          required: true,
          message: this.$t('common.mandatoryField'),
          trigger: 'change',
        },
      ],
      serialNumber: [
        {
          required:
            this.formFieldState[AssetField.SerialNumber].type ===
            AssetFieldStateType.Required,
          message: this.$t('common.mandatoryField'),
          trigger: 'change',
        },
        {
          validator: (
            rule: any,
            value: string | undefined,
            callback: Function
          ) => this.validateMaxLength(AssetField.SerialNumber, value, callback),
          trigger: 'change',
        },
      ],
      timeZone: [
        {
          required: true,
          message: this.$t('common.mandatoryField'),
          trigger: 'change',
        },
      ],
      vinNumber: [
        {
          required:
            this.formFieldState[AssetField.VIN].type ===
            AssetFieldStateType.Required,
          message: this.$t('common.mandatoryField'),
          trigger: 'change',
        },
        {
          validator: (
            rule: any,
            value: string | undefined,
            callback: Function
          ) => this.validateMaxLength(AssetField.VIN, value, callback),
          trigger: 'change',
        },
      ],
      preliminaryAssetId: [
        {
          required:
            this.formFieldState[AssetField.AssetId].type ===
            AssetFieldStateType.Required,
          message: this.$t('common.mandatoryField'),
          trigger: 'change',
        },
        {
          validator: (rule: any, value: string, callback: Function) =>
            this.validateMaxLength(AssetField.AssetId, value, callback),
          trigger: 'change',
        },
      ],
    };

    return tmpRules;
  }
}
</script>

<template>
  <el-form
    ref="registerAssetForm"
    :model="registerAssetForm"
    :inline-message="true"
    :rules="rules"
    style="margin: 20px 20px 60px 20px"
    v-loading="!!editAssetId && isEditAssetLoading"
  >
    <CusFormItem
      v-if="editAssetId"
      :title="$t('assetMgmt.AssetHIN')"
      :required="false"
    >
      <el-form-item prop="assetDataCollectionId">
        <span>{{ registerAssetForm.assetDataCollectionId }}</span>
      </el-form-item>
    </CusFormItem>

    <CusFormItem
      v-if="editAssetId"
      :title="$t('assetMgmt.assetType')"
      :required="false"
    >
      <el-form-item prop="assetType">
        <span>{{ $t(registerAssetForm.assetType) }}</span>
      </el-form-item>
    </CusFormItem>

    <CusFormItem v-else :title="$t('assetMgmt.assetType')">
      <el-form-item prop="assetType">
        <el-select
          v-model="registerAssetForm.assetType"
          v-bind:placeholder="$t('assetMgmt.selectAssetType')"
          @change="handleAssetTypeChange"
          clearable
          filterable
        >
          <el-option
            v-for="(item, index) in assetList"
            :key="index"
            :label="$t(item.value)"
            :value="item.id"
          />
        </el-select>
      </el-form-item>
    </CusFormItem>

    <CusFormItem :title="$t('assetMgmt.prodModel')">
      <el-form-item prop="productModelId" v-loading="prodModelList === null">
        <el-select
          v-model="registerAssetForm.productModelId"
          value-key="code"
          v-bind:placeholder="$t('assetMgmt.selectProdModel')"
          @change="getModelNumbers"
          clearable
          filterable
        >
          <el-option
            v-for="item in prodModelList"
            :key="item.code"
            :label="item.code"
            :value="item.code"
          />
        </el-select>
      </el-form-item>
    </CusFormItem>

    <CusFormItem :title="$t('assetMgmt.modelNumber')">
      <el-form-item prop="modelNumber">
        <el-select
          v-model="registerAssetForm.modelNumber"
          v-bind:placeholder="$t('assetMgmt.modelNumberInput')"
          clearable
          filterable
          :disabled="!!editAssetId && !isProductModelChanged"
        >
          <el-option
            v-for="item in modelNumbers"
            :key="item.id"
            :label="item.code"
            :value="item.id"
          />
        </el-select>
      </el-form-item>
    </CusFormItem>

    <CusFormItem
      v-show="formFieldState.VIN.type !== assetFieldStateType.NotApplicable"
      :title="$t('assetMgmt.VIN')"
      :required="formFieldState.VIN.type === assetFieldStateType.Required"
    >
      <el-form-item prop="vinNumber">
        <el-input
          v-model="registerAssetForm.vinNumber"
          size="medium"
          v-bind:placeholder="$t('assetMgmt.inputVIN')"
          clearable
        />
      </el-form-item>
    </CusFormItem>

    <CusFormItem
      v-show="formFieldState.AssetId.type !== assetFieldStateType.NotApplicable"
      :title="$t('assetMgmt.preliminaryAssetId')"
      :required="formFieldState.AssetId.type === assetFieldStateType.Required"
    >
      <el-form-item prop="preliminaryAssetId">
        <el-input
          v-model="registerAssetForm.preliminaryAssetId"
          size="medium"
          v-bind:placeholder="$t('assetMgmt.inputPreliminaryAssetId')"
          clearable
        />
      </el-form-item>
    </CusFormItem>

    <CusFormItem :title="'common.serviceRegion'" :required="true">
      <el-form-item prop="serviceRegion">
        <el-select
          v-loading="serviceRegionsQuery.isLoading.value"
          v-model="registerAssetForm.serviceRegion"
          v-bind:placeholder="$t('customerModule.pleaseSelect')"
          filterable
          @change="onServiceRegionChange"
        >
          <el-option
            v-for="item in serviceRegionsQuery.data.value"
            :key="item.serviceRegionCode"
            :label="$t(item.serviceRegionCode)"
            :value="item.serviceRegionCode"
          />
        </el-select>
      </el-form-item>
    </CusFormItem>
    <CusFormItem
      v-loading="hyvaRegionsQuery.isLoading.value"
      :title="'common.hyvaRegion'"
      :required="false"
    >
      <el-form-item prop="hyvaRegion">
        <el-select
          v-model="registerAssetForm.hyvaRegion"
          v-bind:placeholder="''"
          filterable
          disabled
        >
          <el-option
            v-for="item in hyvaRegionsQuery.data.value"
            :key="item.code"
            :label="$t(item.code)"
            :value="item.code"
          />
        </el-select>
      </el-form-item>
    </CusFormItem>

    <CusFormItem
      v-show="
        formFieldState.SerialNumber.type !== assetFieldStateType.NotApplicable
      "
      :title="$t('assetMgmt.assetSerialNumber')"
      :required="
        formFieldState.SerialNumber.type === assetFieldStateType.Required
      "
    >
      <el-form-item v-if="editAssetId" prop="serialNumber">
        <span>{{ registerAssetForm.serialNumber }}</span>
      </el-form-item>
      <el-form-item v-else prop="serialNumber">
        <el-input
          v-model="registerAssetForm.serialNumber"
          size="medium"
          v-bind:placeholder="$t('assetMgmt.inputAssetSerialNumber')"
          clearable
        />
      </el-form-item>
    </CusFormItem>

    <CusFormItem :title="$t('assetMgmt.manufacterDate')" :required="false">
      <el-form-item prop="manufactureDate" class="date-picker">
        <el-date-picker
          v-model="registerAssetForm.manufactureDate"
          type="date"
          v-bind:placeholder="$t('assetMgmt.selectManufactureDate')"
          clearable
          :picker-options="pickerOptions"
        />
      </el-form-item>
    </CusFormItem>

    <CusFormItem :title="$t('assetMgmt.timeZone')">
      <el-form-item prop="timeZone">
        <el-select
          v-model="registerAssetForm.timeZone"
          v-bind:placeholder="$t('assetMgmt.selectTimeZone')"
          clearable
          filterable
        >
          <el-option
            v-for="timezone of timezones"
            :key="timezone.fullName"
            :value="timezone.fullName"
          />
        </el-select>
      </el-form-item>
    </CusFormItem>

    <CusFormItem :title="$t('assetMgmt.controllerId')" :required="false">
      <el-form-item prop="controllerId">
        <el-input
          v-model="registerAssetForm.controllerId"
          size="medium"
          v-bind:placeholder="$t('assetMgmt.inputControllerId')"
          clearable
        />
      </el-form-item>
    </CusFormItem>
  </el-form>
</template>

<style scoped>
.date-picker {
  width: 100%;
}

:deep(.el-input__inner) {
  margin-right: 4px;
  border-color: #dcdfe6 !important;
}
</style>

<style lang="scss" scoped>
$FormWidth: 320px;
$FontSize: 1.04em;

.el-date-editor.el-input__inner {
  width: 500px;
}

.el-date-editor {
  .el-form-item__content {
    width: $FormWidth !important;
    margin-right: 0;

    .el-date-editor {
      width: 320px;
    }
  }
}

:deep(.el-input--medium .el-input__inner) {
  width: $FormWidth !important;
  font-size: $FontSize;
}
.long_item :deep(.form-title) {
  width: 260px;
  height: 40px;
  line-height: 40px;
}
.text_display {
  font-size: $FontSize;
  font-family: $font-Roboto-Regular;
}

.el-date-editor.el-input,
.el-date-editor.el-input__inner {
  width: $FormWidth;
}

:deep(.el-select) {
  width: $FormWidth !important;
}

.el-input--suffix {
  width: $FormWidth !important;
}
</style>
