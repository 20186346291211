import { render, staticRenderFns } from "./ViewRoleInfo.vue?vue&type=template&id=1453c12f&scoped=true"
import script from "./ViewRoleInfo.vue?vue&type=script&lang=ts"
export * from "./ViewRoleInfo.vue?vue&type=script&lang=ts"
import style0 from "./ViewRoleInfo.vue?vue&type=style&index=0&id=1453c12f&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1453c12f",
  null
  
)

export default component.exports