<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
  name: 'DefaultDialog',
})
export default class extends Vue {
  @Prop() title!: string;
  @Prop({ default: false }) visible!: boolean;
  @Prop({ default: '60%' }) width!: string;

  handleDialogClose() {
    this.$emit('handle-cancel');
  }
}
</script>

<template>
  <el-dialog
    :title="title"
    :visible="visible"
    :width="width"
    center
    class="widget-dialog"
    @close="handleDialogClose"
  >
    <slot />
  </el-dialog>
</template>

<style lang="scss" scoped>
:deep(.el-dialog) {
  border-radius: 4px;
}

:deep(.el-dialog__header) {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  padding-bottom: 20px;
  background-color: var(--Main) !important;
}

:deep(.el-dialog__title) {
  font-size: 20px;
  font-family: $font-Roboto-Medium;
  line-height: 19px;
  color: #373e41;
}

// truncate title text to fit
:deep(.el-dialog__title) {
  width: calc(100% - 90px);
  margin: 0 auto;
  display: block;
  white-space: nowrap; /* forces text to single line */
  overflow: hidden;
  text-overflow: ellipsis;
}

:deep(.el-dialog__headerbtn) {
  top: 10px;
  font-size: 30px;
  color: #373d41;
}

:deep(.el-dialog__headerbtn:hover .el-dialog__close) {
  color: #5f6567;
}

:deep(.el-dialog__body) {
  padding: 0;
}
</style>
