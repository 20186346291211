import { UUID } from '@/api/common';
import { AssetsWithKpis, fetchKpisForMultipleAssets } from '@/api/kpis';
import { Value } from '@/api/value';
import { ActiveContext, useActiveContext } from '@/auth/context';
import { MaybeRef } from '@/utils/ref';
import { useQuery, UseQueryReturnType } from '@tanstack/vue-query';
import { computed, unref } from 'vue';
import { GenericQueryOptions } from './common';

const queryKeys = {
  multiAssetKpis: (
    assetIds: UUID[] | undefined,
    propertyCodes: string[],
    context?: ActiveContext,
    sendSelectedCustomer?: boolean
  ) =>
    ({
      query: 'multi-asset-kpis',
      assetIds: assetIds,
      propertyCodes: propertyCodes,
      context,
      sendSelectedCustomer,
    } as const),
};

/**
 * Fetch multiple properties for multiple assets.
 *
 * If assetIDs is undefined, the query will be disabled.
 */
export function useMultiAssetKpisQuery<
  T extends object = Record<string, Value<unknown>>,
  TData = AssetsWithKpis<T>
>(
  assetIds: MaybeRef<UUID[] | undefined>,
  propertyCodes: MaybeRef<string[]>,
  options?: GenericQueryOptions<
    AssetsWithKpis<T>,
    ReturnType<(typeof queryKeys)['multiAssetKpis']>[],
    Error,
    TData
  >,
  sendSelectedCustomer?: MaybeRef<boolean>
): UseQueryReturnType<TData, Error> {
  const context = useActiveContext();
  return useQuery({
    staleTime: 10 * 1000,
    ...options,
    enabled: computed(() => unref(assetIds) !== undefined),
    queryKey: [
      computed(() =>
        queryKeys.multiAssetKpis(
          unref(assetIds),
          unref(propertyCodes),
          unref(context),
          unref(sendSelectedCustomer)
        )
      ),
    ],
    queryFn: ({
      queryKey: [{ assetIds, propertyCodes, sendSelectedCustomer, context }],
    }) =>
      assetIds?.length === 0
        ? {}
        : fetchKpisForMultipleAssets(
            assetIds!,
            propertyCodes,
            sendSelectedCustomer !== false
              ? (context as ActiveContext)
              : undefined
          ),
  });
}
