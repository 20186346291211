import { DirectiveOptions } from 'vue';
import { UserModule } from '@/store/modules/user';

export const permission: DirectiveOptions = {
  inserted(el, binding) {
    const { value: requestedResources } = binding;

    if (
      requestedResources &&
      Array.isArray(requestedResources) &&
      requestedResources.length > 0
    ) {
      const hasPermission = requestedResources.some((rsc) =>
        UserModule.claims.hasClaim(rsc)
      );
      if (!hasPermission) {
        el.style.display = 'none';
      }
    } else {
      throw new Error(
        'need resources! Like v-permission="[\'AUTHRSC_SOMETHING\']"'
      );
    }
  },
};
