<script lang="ts" setup>
import Breadcrumbs from '@/components/layout/Breadcrumbs.vue';
import { PageModule } from '@/store/modules/page';
import { computed } from 'vue';
import TitleButton, { TitleButtonProps } from './TitleButton.vue';

defineProps<{
  buttons?: TitleButtonProps[];
}>();

const title = computed(() => PageModule.title);
</script>

<template>
  <div class="page-header">
    <Breadcrumbs :crumbs="title" />
    <div class="spacer" />
    <TitleButton
      v-for="(button, index) of buttons"
      :key="index"
      :text="button.text"
      :icon="button.icon"
      :click="button.click"
    />
  </div>
</template>

<style lang="scss" scoped>
.page-header {
  line-height: 32px;
  display: none;
  gap: 1rem;
  align-items: center;

  @media (min-width: 769px) {
    display: flex;
  }
}

.spacer {
  flex: 1;
}

.button-icon {
  height: 0.85rem;
  margin: 0 0.5rem 2px 0;
  vertical-align: middle;
}
</style>
