<script lang="ts" setup>
import {
  getPagesByModuleCodeByCompanyId,
  ModulePageResponse,
} from '@/api/dashboardConf';
import { useFetch } from '@/composables/fetch';
import { useRoute, useRouter } from '@/composables/router';
import { useAccessControlSystemFeature } from '@/composables/systemFeature';
import { RouteNames } from '@/router/modules/assets';
import { UserModule } from '@/store/modules/user';
import { AssetType } from '@/utils/assetTypes';
import {
  ALL_CLAIMS_CODES,
  FvtAccessControlOption,
  SYSTEM_FEATURES,
} from '@/utils/workData/lookuptable';
import { ref, watch } from 'vue';

const props = defineProps<{
  pageCode: string;
  tabBlacklist: string[];
}>();
const emit = defineEmits<{
  (e: 'select', tab: string): void;
  (e: 'hideButtonEvent', value: boolean): void;
}>();

const route = useRoute();
const router = useRouter();

const noButtonPages = [
  ALL_CLAIMS_CODES.AUTHRSC_PAGE_TIPPING_VEHICLES_PARAMS_CONFIG,
  ALL_CLAIMS_CODES.AUTHRSC_PAGE_SCOMPACTOR_PARAMS_CONFIG,
  ALL_CLAIMS_CODES.AUTHRSC_PAGE_MCOMPACTOR_PARAMS_CONFIG,
  ALL_CLAIMS_CODES.AUTHRSC_PAGE_ALBA_SCOMPACTOR_PARAMS_CONFIG,
  ALL_CLAIMS_CODES.AUTHRSC_PAGE_RCV_PARAMS_CONFIG,
];

// TODO fetch available pages from user module
const { data, loading, error } = useFetch(
  getPagesByModuleCodeByCompanyId(UserModule.companyId, props.pageCode)
);
const tabs = ref<ModulePageResponse[]>();
const selected = ref<string>();

watch(data, (newPageModules) => {
  if (!newPageModules) {
    return;
  }

  const unsortedTabs = newPageModules.filter(
    (t) =>
      !props.tabBlacklist.includes(t.code) &&
      UserModule.claims.hasClaim(t.code as ALL_CLAIMS_CODES)
  );

  // Sort tabs accordingly for each asset type
  tabs.value = sortTabsOrder(unsortedTabs, route.value.name as RouteNames);

  let { tab } = route.value.query;
  let selectedTab: string | undefined;

  if (!tab || Array.isArray(tab) || !tabs.value?.some((t) => t.code === tab)) {
    if (tabs.value.length === 0) {
      selectedTab = undefined;
    } else {
      selectedTab = tabs.value[0].code;
    }

    if (tab !== selectedTab) {
      router.replace({
        query: {
          ...route.value.query,
          tab: selectedTab,
        },
      });
    }
  } else {
    selectedTab = tab;
  }

  selected.value = selectedTab;
});

watch(selected, (next, previous) => {
  if (!next) {
    return;
  }

  if (previous) {
    router.push({
      query: {
        ...route.value.query,
        tab: next,
      },
    });
  }

  if (
    selected.value &&
    noButtonPages.includes(selected.value as ALL_CLAIMS_CODES)
  ) {
    emit('hideButtonEvent', true);
  } else {
    emit('hideButtonEvent', false);
  }
  emit('select', next);
});

const mobileCompactorParamConfigAccessControl = useAccessControlSystemFeature(
  SYSTEM_FEATURES.ParameterConfig,
  AssetType.MobileCompactor
);
const staticCompactorParamConfigAccessControl = useAccessControlSystemFeature(
  SYSTEM_FEATURES.ParameterConfig,
  AssetType.StaticCompactor
);
const tippingVehicleParamConfigAccessControl = useAccessControlSystemFeature(
  SYSTEM_FEATURES.ParameterConfig,
  AssetType.TippingVehicle
);

/**
 * Tabs claims code order by asset type:
 * - Mobile Compactors: KPIs, Asset Information, Maintenance, Specifications, ParameterConfiguration
 * - Static Compactors: KPIs, Asset Information, Maintenance, Specifications, ParameterConfiguration
 * - Tipping Vehicles: Asset Information, KPIs, Maintenance, Specifications, ParameterConfiguration
 */
const TabsOrderByAssetType: Map<RouteNames, ALL_CLAIMS_CODES[]> = new Map([
  [
    RouteNames.MobileCompactorById,
    [
      ALL_CLAIMS_CODES.AUTHRSC_PAGE_MCOMPACTOR_KPIS,
      ALL_CLAIMS_CODES.AUTHRSC_PAGE_MCOMPACTOR_ASSETINFO,
      ALL_CLAIMS_CODES.AUTHRSC_PAGE_MCOMPACTOR_MAINT,
      ALL_CLAIMS_CODES.AUTHRSC_PAGE_MCOMPACTOR_SPEC,
      // TODO: Convert this into a computed - unreffing should not happen in main context -> loses reactivity
      ...(mobileCompactorParamConfigAccessControl.value !==
      FvtAccessControlOption.NoAccess
        ? [ALL_CLAIMS_CODES.AUTHRSC_PAGE_MCOMPACTOR_PARAMS_CONFIG]
        : []),
    ],
  ],
  [
    RouteNames.StaticCompactorById,
    [
      ALL_CLAIMS_CODES.AUTHRSC_PAGE_SCOMPACTOR_KPIS,
      ALL_CLAIMS_CODES.AUTHRSC_PAGE_SCOMPACTOR_ASSETINFO,
      ALL_CLAIMS_CODES.AUTHRSC_PAGE_SCOMPACTOR_MAINT,
      ALL_CLAIMS_CODES.AUTHRSC_PAGE_SCOMPACTOR_SPEC,
      ...(staticCompactorParamConfigAccessControl.value !==
      FvtAccessControlOption.NoAccess
        ? [ALL_CLAIMS_CODES.AUTHRSC_PAGE_SCOMPACTOR_PARAMS_CONFIG]
        : []),
    ],
  ],
  [
    RouteNames.TippingVehicleById,
    [
      ALL_CLAIMS_CODES.AUTHRSC_PAGE_TIPPING_VEHICLES_ASSETINFO,
      ALL_CLAIMS_CODES.AUTHRSC_PAGE_TIPPING_VEHICLES_KPIS,
      ALL_CLAIMS_CODES.AUTHRSC_PAGE_TIPPING_VEHICLES_MAINT,
      ALL_CLAIMS_CODES.AUTHRSC_PAGE_TIPPING_VEHICLES_SPEC,
      ...(tippingVehicleParamConfigAccessControl.value !==
      FvtAccessControlOption.NoAccess
        ? [ALL_CLAIMS_CODES.AUTHRSC_PAGE_TIPPING_VEHICLES_PARAMS_CONFIG]
        : []),
    ],
  ],
]);

/**
 * Sort Tabs order accordingly with the order for each asset type
 */
function sortTabsOrder(
  unsortedTabs: ModulePageResponse[],
  routeName: RouteNames
): ModulePageResponse[] {
  const tabsOrderByClaimCode = TabsOrderByAssetType.get(routeName);
  if (tabsOrderByClaimCode) {
    const sortedTabs = tabsOrderByClaimCode?.map((code) => {
      const originalTab = unsortedTabs?.find((item) => item.code === code);
      if (originalTab) {
        return originalTab;
      }
      return {} as ModulePageResponse;
    });
    if (sortedTabs) {
      return sortedTabs.filter((item) => item.code != undefined);
    }
  }

  return unsortedTabs;
}
</script>

<template>
  <el-tabs v-if="!loading" v-model="selected">
    <el-tab-pane
      v-for="tab of tabs"
      :key="tab.id"
      :name="tab.code"
      :label="$t('pageTitle.' + tab.code)"
    />
  </el-tabs>
</template>

<style lang="scss" scoped>
.el-tabs {
  margin: 0 -1rem 0 -1rem;
  background-color: #e4e4e4;
  overflow: hidden;

  @media (min-width: 769px) {
    margin: 0 0 1rem 0;
    padding: 0 1.5rem;
    background-color: #ffffff;
    box-shadow: 0 3px 6px #c1c1c1;
    border-radius: 8px;
  }

  & :deep(.el-tabs__header) {
    margin-bottom: 10px;
  }

  & :deep(.el-tabs__nav-wrap) {
    &::after {
      content: none;
    }

    @media (min-width: 769px) {
      padding: 0;
    }
  }
}
</style>
