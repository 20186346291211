<script lang="ts">
import { getAccessibleAssets } from '@/api/accessibleAssets';
import { getAssets } from '@/api/assets';
import { UUID } from '@/api/common';
import { getOrgById, updateActivationStatus } from '@/api/organizations';
import {
  AccessibleServiceRegionResponse,
  AccessibleServiceRegionsField,
  getAccessibleServiceRegions,
} from '@/api/serviceRegions';
import { getUsers, UserListInformation } from '@/api/users';
import { LoggedInUserRef, useLoggedInUser } from '@/auth/user';
import NewCard from '@/components/cusCard/NewCard.vue';
import DeactivateDialog from '@/components/dialog/DeactivateDialog.vue';
import PureTable from '@/components/table/PureTable.vue';
import {
  Filter,
  FilterOperator,
  Pagination,
  QueryParameter,
} from '@/model/queryParameters/QueryParameter';
import { useMultiAssetKpisQuery } from '@/query/kpis';
import { PageModule } from '@/store/modules/page';
import { UserModule } from '@/store/modules/user';
import { isCompanyTypeOf, isDesignatedCompany } from '@/utils/companyService';
import { ORGANIZATION_ID } from '@/utils/constants';
import { generateUrl } from '@/utils/index';
import {
  customFailedMessage,
  promptFailedBox,
  promptSuccessBox,
} from '@/utils/prompt';
import { ASSETS_COLS } from '@/utils/workData/assetsMgmt';
import {
  ACTIVATION_STATUS,
  ALL_CLAIMS_CODES,
  KPI_FIELDS,
} from '@/utils/workData/lookuptable';
import { USER_COLS } from '@/utils/workData/userMgmt';
import { FleetStatusKpis } from '@/views/assets/components/AssetsWidgetTable.vue';
import { computed } from 'vue';
import { Component, Prop, Vue } from 'vue-property-decorator';
import OrgInfo from './components/OrgInfo.vue';
import { HELPDESK_COMPANY_TYPES } from './organizationManagementConstants';

interface AssetInformation {
  id: UUID;
  companyAssetId: string;
  vinNumber: string;
  controllerId: string;
  lastCommunicationTime: string;
  status: string;
  organization: string;
}

@Component({
  name: 'ViewOrganizationInfo',
  components: {
    NewCard,
    OrgInfo,
    PureTable,
    DeactivateDialog,
  },
})
export default class extends Vue {
  @Prop() orgId!: string;

  /** Local variables */
  editBtnAuth: string[] = [ALL_CLAIMS_CODES.AUTHRSC_ACTION_ORG_UDPATE];
  deacBtnAuth: string[] = [ALL_CLAIMS_CODES.AUTHRSC_ACTION_ORG_DEACTIVATE];
  title: string = '';
  currentOrgInfo: any = {};
  activateOrDeactivate: string = '';
  activeName: string = 'first';
  userTotal: number = 0;
  userList: UserListInformation[] = [];
  userCols = USER_COLS.map((item) => ({
    ...item,
    label: this.$t(item.label),
  }));
  assetTotal: number = 0;
  assets: AssetInformation[] = [];
  assetCols = ASSETS_COLS.map((item) => ({
    ...item,
    label: this.$t(item.label),
  }));
  visiable: boolean = false;
  dialogContent: any = '';
  isOrganizationViewPageLoading: boolean = false;
  loadingText: string = this.$t('userModule.loadingOrganizationData') as string;
  notAllowedActionsByHimSelf: string[] = ['DEACTIVATE'];
  helpdeskCompanyTypes: string[] = HELPDESK_COMPANY_TYPES;
  isHyvaHelpdeskAdmin: boolean = false;
  serviceRegionsTextValue: string = '';
  loggedInUser!: LoggedInUserRef;
  lastCommunicationKpiQuery!: ReturnType<
    typeof useMultiAssetKpisQuery<FleetStatusKpis>
  >;

  created() {
    this.loggedInUser = useLoggedInUser();
    this.isHyvaHelpdeskAdmin = isCompanyTypeOf(this.helpdeskCompanyTypes);
    if (this.orgId) {
      if (this.isHyvaHelpdeskAdmin) {
        this.getAccessibleServiceRegions();
      } else {
        this.fetchOrganizationById();
      }
    }

    this.lastCommunicationKpiQuery = useMultiAssetKpisQuery(
      computed(() => this.assets.map((asset) => asset.id)),
      [KPI_FIELDS.LastCommunicationTime]
    );
  }

  get assetsWithKpis(): AssetInformation[] {
    const lastCommunicationTimes = this.lastCommunicationKpiQuery.data.value;

    if (!lastCommunicationTimes) {
      return this.assets;
    }

    return this.assets.map((asset) => ({
      ...asset,
      lastCommunicationTime:
        lastCommunicationTimes[asset.id]?.[KPI_FIELDS.LastCommunicationTime].v,
    }));
  }

  get translatedAssetsWithKpis(): AssetInformation[] {
    return this.translateAssetsTableData(
      this.assetsWithKpis.map(
        (asset): AssetInformation => ({
          id: asset.id,
          companyAssetId: asset.companyAssetId,
          vinNumber: asset.vinNumber,
          controllerId: asset.controllerId,
          lastCommunicationTime: asset.lastCommunicationTime || '',
          status: asset.status,
          organization: asset.organization,
        })
      )
    );
  }

  handleEdit() {
    if (this.isHyvaHelpdeskAdmin) {
      this.$router.push(`/administration/edit-helpdesk-org/${this.orgId}`);
    } else {
      this.$router.push(`/administration/edit-org/${this.orgId}`);
    }
  }

  handleCancel() {
    this.visiable = false;
  }

  getActiveName() {
    return this.activeName;
  }

  /**
   * Show deactivate organization
   */
  showDeactive(): void {
    if (this.selfCheckNotAllowedAction('DEACTIVATE')) {
      return;
    }
    this.visiable = true;
    if (
      this.currentOrgInfo.activationStatus === ACTIVATION_STATUS.Deactivated
    ) {
      this.dialogContent = this.$t('userModule.activateInfo', {
        name: this.title,
      });
    } else {
      this.dialogContent = this.$t('userModule.deactivateInfo', {
        name: this.title,
      });
    }
    // this.dialogContent = this.$t('organizationModule.deactivateInfo', {'name': this.title});
  }

  async handleDeactive() {
    let infoMsg = (
      this.activateOrDeactivate === 'deactivate'
        ? this.$t('common.deactivate')
        : this.$t('common.activate')
    ) as string;

    await updateActivationStatus(this.orgId, {
      activationStatus:
        this.activateOrDeactivate === 'deactivate'
          ? ACTIVATION_STATUS.Deactivated
          : ACTIVATION_STATUS.Activated,
    }).then((res) => {
      if (res.code === 200) {
        promptSuccessBox(infoMsg);

        // this.currentOrgInfo.activationStatus = ACTIVATION_STATUS.Deactivated;
        this.$router.push('/administration/index?activeName=orgTab');
      } else if (res.code === 400) {
        promptFailedBox(infoMsg);
      }
    });
    this.visiable = false;
  }

  async handleUserPage(page: number, pageSize: number) {
    let filterParams = {
      page: page,
      size: pageSize,
      searchFieldName: ORGANIZATION_ID,
      searchFieldValues: this.orgId,
    };

    await getUsers(generateUrl(filterParams)).then((res) => {
      if (!res) {
        customFailedMessage(this.$t('common.errorWithFetchingData') as string);
        return;
      }

      if (res.code === 200 && res.data) {
        let resData = res.data;
        resData.users.forEach((item: any) => {
          item.userVerified = item.emailVerified
            ? 'userModule.yes'
            : 'userModule.no';
        });

        this.userTotal = resData.total;
        this.userList = this.translateUserTableData(resData.users);
      }
    });
  }

  async fetchOrganizationById() {
    this.isOrganizationViewPageLoading = true;
    await getOrgById(this.orgId).then((res) => {
      if (!res) {
        customFailedMessage(this.$t('common.errorWithFetchingData') as string);
        return;
      }

      if (res.code === 200) {
        this.currentOrgInfo = res.data;
        PageModule.setTitle(this.currentOrgInfo.name);
        if (
          this.currentOrgInfo.activationStatus === ACTIVATION_STATUS.Deactivated
        ) {
          this.activateOrDeactivate = 'activate';
        } else {
          this.activateOrDeactivate = 'deactivate';
        }

        this.isOrganizationViewPageLoading = false;
        return;
      }

      this.isOrganizationViewPageLoading = false;
      customFailedMessage(this.$t('common.errorWithFetchingData') as string);
    });

    let userFilterParams = {
      page: 1,
      size: UserModule.gridPageSize,
      searchFieldName: 'organizationId',
      searchFieldValues: this.orgId,
    };
    await getUsers(generateUrl(userFilterParams)).then((res) => {
      if (!res) {
        customFailedMessage(this.$t('common.errorWithFetchingData') as string);
        return;
      }

      if (res.code === 200) {
        let resData = res.data;
        resData.users.forEach((item: any) => {
          item.userVerified = item.emailVerified
            ? 'userModule.yes'
            : 'userModule.no';
        });

        this.userTotal = resData.total;
        this.userList = this.translateUserTableData(resData.users);
        return;
      }

      customFailedMessage(this.$t('common.errorWithFetchingData') as string);
    });

    if (!isDesignatedCompany(this.loggedInUser.value!.companyType)) {
      await this.fetchAssetsForCustomer();
    } else {
      await this.fetchAssetsForDesignatedUser();
    }
  }

  async fetchAssetsForDesignatedUser() {
    if (!this.loggedInUser.value) {
      throw new Error('LoggedInUser is undefined.');
    }
    const accessibleAssets = await getAccessibleAssets({}, this.orgId);

    this.assetTotal = accessibleAssets.data.total;
    this.assets = accessibleAssets.data.assets.map(
      (asset): AssetInformation => ({
        id: asset.id,
        companyAssetId: asset.companyAssetId || asset.preliminaryAssetId,
        vinNumber: asset.vinNumber,
        controllerId: asset.controllerId,
        lastCommunicationTime: asset.lastCommunicationTime || '',
        status: asset.assetStatus,
        organization: asset.organizationName,
      })
    );
  }

  async fetchAssetsForCustomer() {
    let assetFilterParams = {
      searchFieldName: 'organizationId',
      searchFieldValues: [this.orgId],
      companyId: UserModule.companyId,
      page: 1,
      size: UserModule.gridPageSize,
    };
    await getAssets(generateUrl(assetFilterParams)).then((res) => {
      if (!res) {
        customFailedMessage(this.$t('common.errorWithFetchingData') as string);
        return;
      }

      if (res.code === 200) {
        this.assetTotal = res.data.total;
        this.assets = res.data.assets.map(
          (asset): AssetInformation => ({
            id: asset.id,
            companyAssetId: asset.companyAssetId || asset.preliminaryAssetId,
            controllerId: asset.controllerId,
            status: asset.status,
            lastCommunicationTime: asset.lastCommunicationTime || '',
            vinNumber: asset.vinNumber,
            organization: asset.organizationName,
          })
        );

        return;
      }

      customFailedMessage(this.$t('common.errorWithFetchingData') as string);
    });
  }

  /** Get accessible service regions for organization id, use case: logged in as helpdesk */
  async getAccessibleServiceRegions() {
    try {
      this.isOrganizationViewPageLoading = true;
      const filter: Filter = {
        name: ORGANIZATION_ID,
        operator: FilterOperator.EQUAL,
        value: [this.orgId],
      };
      const pagination: Pagination = { page: 1, size: 1000 };
      const queryParameter: QueryParameter = {
        filters: [filter],
        sorters: [],
        pagination: pagination,
      };
      const response = await getAccessibleServiceRegions(queryParameter);
      const organizationAccessibleserviceRegions: AccessibleServiceRegionsField[] =
        response.data.find(
          (item: AccessibleServiceRegionResponse) =>
            item.organizationId == this.orgId
        )?.accessibleServiceRegions!;
      if (organizationAccessibleserviceRegions) {
        this.serviceRegionsTextValue =
          organizationAccessibleserviceRegions[0].name;
        for (let i = 1; i < organizationAccessibleserviceRegions.length; i++) {
          this.serviceRegionsTextValue +=
            ', ' + organizationAccessibleserviceRegions[i].name;
        }
      }

      this.fetchOrganizationById();
    } catch (error) {
      console.log(error);
    } finally {
    }
  }

  translateAssetsTableData(
    preTranslatedTableData: AssetInformation[]
  ): AssetInformation[] {
    return preTranslatedTableData.map((item) => ({
      ...item,
      status: this.$t(item.status),
    }));
  }

  translateUserTableData(
    preTranslatedTableData: UserListInformation[]
  ): UserListInformation[] {
    return preTranslatedTableData.map((item) => ({
      ...item,
      activationStatus: this.$t(item.activationStatus),
    }));
  }

  /**
   * Self check not allowed actions like removed or deactivated organization associated to logged in user himself
   */
  selfCheckNotAllowedAction(action: string): boolean {
    if (
      this.currentOrgInfo.id === UserModule.organizationId &&
      this.notAllowedActionsByHimSelf.includes(action)
    ) {
      const errorMessage: string =
        action === 'DEACTIVATE' &&
        this.currentOrgInfo.activationStatus === ACTIVATION_STATUS.Activated
          ? (this.$t(
              'administrationModule.notAllowedToDeactivateYourOwnOrganization'
            ) as string)
          : action === 'DEACTIVATE' &&
            this.currentOrgInfo.activationStatus ===
              ACTIVATION_STATUS.Deactivated
          ? (this.$t(
              'administrationModule.notAllowedToActivateYourOwnOrganization'
            ) as string)
          : '';
      customFailedMessage(errorMessage);
      return true;
    }
    return false;
  }

  /*
   * Do not allow self action on own entity like organization to disable it
   * @return boolean
   */
  get checkIfAllowedSelfAction(): boolean {
    return this.currentOrgInfo.id != UserModule.organizationId;
  }
}
</script>

<template>
  <NewCard
    :createdOrEdit="orgId"
    :visible="true"
    :editBtnAuth="editBtnAuth"
    :deacBtnAuth="deacBtnAuth"
    :activateOrDeactivate="activateOrDeactivate"
    :backPath="'/administration/index?activeName=orgTab'"
    @edit-infos="handleEdit"
    @handle-deactive="showDeactive"
    v-loading="isOrganizationViewPageLoading"
    :element-loading-text="loadingText"
    :visibleDeactivate="checkIfAllowedSelfAction"
  >
    <el-tabs v-model="activeName" style="margin: 0px 20px">
      <el-tab-pane :label="$t('customerModule.generalInfo')" name="first">
        <OrgInfo
          :orgInfo="currentOrgInfo"
          :isHyvaHelpdeskAdmin="isHyvaHelpdeskAdmin"
          :serviceRegions="serviceRegionsTextValue"
        />
      </el-tab-pane>

      <el-tab-pane :label="$t('assetsModule.assets')" name="second">
        <el-row style="margin: 10px 0px">
          <div class="d-flex ai-center">
            <span class="total-statistics"
              >{{ $t('assetsModule.assetsTotalInfo') }}:</span
            >
            <span class="total-statistics-value"> {{ assetTotal }}</span>
          </div>
        </el-row>
        <el-row>
          <PureTable
            :tableList="translatedAssetsWithKpis"
            :total="assetTotal"
            :cols="assetCols"
          />
        </el-row>
      </el-tab-pane>

      <el-tab-pane :label="$t('userModule.users')" name="third">
        <el-row style="margin: 10px 0px">
          <div class="d-flex ai-center">
            <span class="total-statistics"
              >{{ $t('userModule.userTotalInfo') }}:</span
            >
            <span class="total-statistics-value">{{ userTotal }}</span>
          </div>
        </el-row>
        <el-row>
          <PureTable
            :tableList="userList"
            :total="userTotal"
            :cols="userCols"
            @handle-page="handleUserPage"
          />
        </el-row>
      </el-tab-pane>
    </el-tabs>

    <DeactivateDialog
      :visiable.sync="visiable"
      :activateOrDeactivate="activateOrDeactivate"
      :title="'Organization'"
      :content="dialogContent"
      @handle-cancel="handleCancel"
      @handle-deactivate="handleDeactive"
    />
  </NewCard>
</template>
