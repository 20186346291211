import {
  OrganizationAssetsHierarchyAsset,
  OrganizationAssetsHierarchyOrganization,
} from '@/api/assets';
import { UUID } from '@/api/common';
import { Organization, OrganizationChild } from '@/api/organizations';
import { OrganizationDropdownItem } from '@/components/layout/select/models';
import { filterTree } from './collections';
import { ACTIVATION_STATUS } from './workData/lookuptable';

/**
 * Represents a Customer and its Primary Organization.
 * In case of designated users (bb, oem, helpdesk, dealer) only the primaryOrganizationId is known.
 */
export interface CustomerSummary {
  customerId: UUID;
  customerName: string;
  primaryOrganizationId: UUID;
  primaryOrganization?: OrganizationSummary;
}

export interface OrganizationSummary {
  organizationName: string;
  organizationId: UUID;
  organizations: OrganizationSummary[];
}

export function isOrganizationPartOfTree(
  orgId: string,
  org: Organization | OrganizationChild
): boolean {
  if (orgId == org.id) {
    return true;
  }

  for (const child of org.children) {
    if (isOrganizationPartOfTree(orgId, child)) {
      return true;
    }
  }

  return false;
}

export function filterActivatedChildren(org: Organization): Organization {
  const filteredOrg = filterTree(
    [org],
    'children',
    (child) => child.activationStatus === ACTIVATION_STATUS.Activated
  );

  return filteredOrg[0];
}

export function mapOrgsToTreeSelectDropdownItems(
  orgs: Organization[]
): OrganizationDropdownItem[] {
  return orgs.map(
    (root): OrganizationDropdownItem => mapOrgToDropdownItem(root)
  );
}

export function mapOrgToDropdownItem(
  org: Organization | OrganizationChild
): OrganizationDropdownItem {
  return {
    value: org.id,
    label: org.name,
    children: org.children.map(
      (child): OrganizationDropdownItem => mapOrgToDropdownItem(child)
    ),
  };
}

export function mapOrgsToCustomerSummary(
  orgs: Organization[]
): CustomerSummary[] {
  return orgs.map((org): CustomerSummary => {
    const organizationSummary = mapOrgChildToOrganizationSummary(org);

    return {
      customerId: org.companyId,
      customerName: org.name,
      primaryOrganization: organizationSummary,
      primaryOrganizationId: organizationSummary.organizationId,
    };
  });
}

export function mapOrgChildToOrganizationSummary(
  orgChild: Organization | OrganizationChild
): OrganizationSummary {
  return {
    organizationId: orgChild.id,
    organizationName: orgChild.name,
    organizations: orgChild.children.map(mapOrgChildToOrganizationSummary),
  };
}

export function mapOrganizationSummaryToDropdownItem(
  org: OrganizationSummary
): OrganizationDropdownItem {
  return {
    value: org.organizationId,
    label: org.organizationName,
    children: org.organizations.map(mapOrganizationSummaryToDropdownItem),
  };
}

export function mapOrgSummariesToOrganizationAssetsHierarchyOrganization(
  orgSummaries: OrganizationSummary[],
  orgIdToAssetIds: Map<UUID, OrganizationAssetsHierarchyAsset[]>
): OrganizationAssetsHierarchyOrganization[] {
  return orgSummaries.map((org) =>
    mapOrgSummaryToOrganizationAssetsHierarchyOrganization(org, orgIdToAssetIds)
  );
}

export function mapOrgSummaryToOrganizationAssetsHierarchyOrganization(
  orgSummary: OrganizationSummary,
  orgIdToAssetIds: Map<UUID, OrganizationAssetsHierarchyAsset[]>
): OrganizationAssetsHierarchyOrganization {
  return {
    id: orgSummary.organizationId,
    name: orgSummary.organizationName,
    activationStatus: ACTIVATION_STATUS.Activated,
    assets: orgIdToAssetIds.get(orgSummary.organizationId) ?? [],
    suborganizations: orgSummary.organizations.map((org) =>
      mapOrgSummaryToOrganizationAssetsHierarchyOrganization(
        org,
        orgIdToAssetIds
      )
    ),
  };
}

export function findOrganizationInTree(
  org: Organization | OrganizationChild,
  id: UUID
): Organization | OrganizationChild | undefined {
  if (org.id === id) {
    return org;
  }

  for (const childOrg of org.children) {
    const result = findOrganizationInTree(childOrg, id);
    if (result) {
      return result;
    }
  }

  return undefined;
}
