<script lang="ts">
import { getKpiData } from '@/api/assets';
import { ActiveContext, useActiveContext } from '@/auth/context';
import KpiBarChart from '@/components/widget/KpiBarChart.vue';
import { KpiGrowthUnitValue } from '@/components/widget/kpiNumber/KpiNumber.vue';
import {
  useUnitConversion,
  UseUnitConversionReturnType,
} from '@/composables/conversion';
import { determineEntityType } from '@/utils/assetRouteChecker';
import { collateExpandTableData } from '@/utils/kpidata';
import { customFailedMessage } from '@/utils/prompt';
import { DEFAULT_DATE_RANGE } from '@/utils/time';
import { DateRange } from '@/utils/types/date';
import { KPI_UNIT } from '@/utils/units/unitDefinitions';
import { EntityType } from '@/utils/workData/lookuptable';
import { ROUTE_ASSET_MAP } from '@/utils/workData/utilMap';
import { Ref, unref } from 'vue';
import {
  Component,
  Inject,
  ProvideReactive,
  Vue,
} from 'vue-property-decorator';

@Component({
  name: 'AssetEfficiency',
  components: {
    KpiBarChart,
  },
})
export default class extends Vue {
  dateRange = DEFAULT_DATE_RANGE;
  kpiNumberList: KpiGrowthUnitValue<number | undefined>[] = [
    {
      name: this.$t('KPI.overallPowerEfficiency'),
      code: undefined,
      v: 0,
      unit: KPI_UNIT.MetricTonnePerKWH,
      growth: undefined,
    },
    {
      name: this.$t('KPI.totalPowerConsumption'),
      code: undefined,
      v: 0,
      unit: KPI_UNIT.KilowattHour,
      growth: undefined,
    },
    {
      name: this.$t('KPI.totalLoad'),
      code: undefined,
      v: 0,
      unit: KPI_UNIT.MetricTonne,
      growth: undefined,
    },
  ];
  KpiChartIsLoading: boolean = false;
  chartData: any = [];
  unitCode = 'UNIT_METRIC_TONNE_PER_KWH';
  routeAssetMap = ROUTE_ASSET_MAP;
  kpiTypeList = [
    {
      code: 'KPI.Efficiency',
      tableHeadLabel: 'KPI.efficiency',
      tableProp: 'efficiency',
      unit: 'UNIT_METRIC_TONNE_PER_KWH',
      needGrowthPercentage: true,
    },
    {
      code: 'KPI.PowerConsumption',
      tableHeadLabel: 'KPI.powerConsumption',
      tableProp: 'powerConsumption',
      unit: 'UNIT_KWH',
      needGrowthPercentage: true,
    },
    {
      code: 'KPI.Payload',
      tableHeadLabel: 'KPI.payload',
      tableProp: 'payload',
      unit: 'UNIT_METRIC_TONNE',
      needGrowthPercentage: true,
    },
  ];
  entityType: EntityType = determineEntityType(this.$route);

  @ProvideReactive('tableList') tableList: any = [];
  @ProvideReactive('cols') cols: any = [];

  @Inject() expanded!: boolean;
  context!: Ref<ActiveContext>;
  unitConversion!: UseUnitConversionReturnType;

  created() {
    this.unitConversion = useUnitConversion();
    this.context = useActiveContext();
  }

  dateRangeChange(dateRange: DateRange) {
    this.dateRange = dateRange;

    this.$nextTick(() => {
      this.fecthKpi();
    });
  }

  collateAndCalculate(toHandleData: any) {
    toHandleData.values.forEach((item: any) => {
      this.chartData.push([
        item.ts,
        item.v != null ? Number(item.v).toFixed(2) : '#N/A',
      ]);
    });
  }

  calculateTotalKpi(detailData: any) {
    let totalValue: number = 0;
    let availableLength: number = 0;
    detailData.forEach((item: any) => {
      if (item.v != null) {
        availableLength++;
      }
      totalValue = totalValue + Number(item.v ? item.v : 0);
    });
    return availableLength > 0 ? Number(totalValue.toFixed(2)) : '#N/A';
  }

  async fecthKpi() {
    this.KpiChartIsLoading = true;
    let submitFields: any = [];
    this.kpiTypeList.forEach((item: any) => {
      submitFields.push({
        code: item.code,
        unit: item.unitCode,
        needGrowthPercentage: item.needGrowthPercentage,
      });
    });
    let kpiPostData = {
      metadata: {
        filter: {
          assetId: this.$route.params.id,
        },
        selection: {
          startDate: this.dateRange.start,
          endDate: this.dateRange.endExclusive,
          dataBucketSize: 'DBSIZE_DAY',
        },
      },
      details: [
        {
          entity: this.entityType,
          fields: submitFields,
        },
      ],
    };
    await getKpiData(kpiPostData, unref(this.context)).then((res) => {
      this.KpiChartIsLoading = false;
      if (!res) return;
      if (res.code === 200) {
        this.chartData = [];
        //this charts needs to show more kpi data. FE have to filter them
        let powerConsumptionDetailData = res.data.details[0].fields.filter(
          (item) => {
            return item.code === 'KPI.PowerConsumption';
          }
        );
        let payloadDetailData = res.data.details[0].fields.filter((item) => {
          return item.code === 'KPI.Payload';
        });
        let efficiencyData = res.data.details[0].fields.filter((item) => {
          return item.code === 'KPI.Efficiency';
        });

        this.collateAndCalculate(efficiencyData[0]);

        this.kpiNumberList.forEach((item: any) => {
          this.$set(
            this.kpiNumberList[0],
            `v`,
            this.calculateTotalKpi(efficiencyData[0].values)
          );
          this.$set(
            this.kpiNumberList[0],
            `growth`,
            efficiencyData[0].growthPercentage != null
              ? efficiencyData[0].growthPercentage
              : undefined
          );

          this.$set(
            this.kpiNumberList[1],
            `v`,
            this.calculateTotalKpi(powerConsumptionDetailData[0].values)
          );
          this.$set(
            this.kpiNumberList[1],
            `growth`,
            powerConsumptionDetailData[0].growthPercentage != null
              ? powerConsumptionDetailData[0].growthPercentage
              : undefined
          );

          this.$set(
            this.kpiNumberList[2],
            `v`,
            this.calculateTotalKpi(payloadDetailData[0].values)
          );
          this.$set(
            this.kpiNumberList[2],
            `growth`,
            payloadDetailData[0].growthPercentage != null
              ? payloadDetailData[0].growthPercentage
              : undefined
          );
        });

        if (this.expanded) {
          //it's in the expanded page, FE should caculate the table data
          let { cols, tableList } = collateExpandTableData(
            res.data.details[0].fields,
            this.kpiTypeList,
            'date',
            this.unitConversion.currentUserPreferredUnit,
            this.unitConversion.currentUserConvertUnitValue
          );
          this.cols = cols;
          this.tableList = tableList;
        }
        return;
      } else {
        this.chartData = [];
      }
      customFailedMessage(this.$t('KPI.errorFetchingKpisData').toString());
    });
  }
}
</script>

<template>
  <KpiBarChart
    id="kpi-efficiency"
    :loading="KpiChartIsLoading"
    itemColor="#4096C4"
    :kpiUnit="unitCode"
    :kpiNumberList="kpiNumberList"
    :chartData="chartData"
    @send-date="dateRangeChange"
  />
</template>

<style lang="scss" scoped></style>
