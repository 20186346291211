<script lang="ts" setup>
import {
  DEFAULT_MAINTENANCE_DAYS_RANGE,
  getMaintenanceHistoriesStatistics,
  Summary,
} from '@/api/maintenance';
import { useActiveContext } from '@/auth/context';
import TimeSelect from '@/components/form/TimeSelect.vue';
import WidgetCard from '@/components/layout/widget/WidgetCard.vue';
import i18n from '@/lang';
import { Filter, FilterOperator } from '@/model/queryParameters/QueryParameter';
import { UserModule } from '@/store/modules/user';
import { customFailedMessage } from '@/utils/prompt';
import { DEFAULT_DATE_RANGE_15_DAYS } from '@/utils/time';
import { DateRange } from '@/utils/types/date';
import MaintChart from '@/views/maintenance/components/MaintChart.vue';
import moment from 'moment';
import { ref, unref } from 'vue';

/** Local variables */
export interface ChartData {
  xAxies: string[];
  values: number[];
}

const activeContext = useActiveContext();
const isChartLoading = ref<boolean>(false);
let dateRange: DateRange = DEFAULT_DATE_RANGE_15_DAYS;
const maintenancePerformedChartData = ref<ChartData>();
const totalNrOfMaintenances = ref<number>(0);
const aveNrOfMaintenances = ref<number>(0);
const maintenanceChartIsVisible = ref<boolean>(true);

/**
 * Handle date interval picker inputs
 */
async function handleDateIntervalInput(
  selectedDateRange: DateRange
): Promise<void> {
  dateRange = selectedDateRange;
  await getMaintenancePerformedStatistics();
}

/**
 * Retrieve from BE the maintenance performance statistics for chart
 */
async function getMaintenancePerformedStatistics() {
  isChartLoading.value = true;
  maintenanceChartIsVisible.value = false;

  const chartData: ChartData = {
    xAxies: [],
    values: [],
  };

  const context = unref(activeContext);
  const orgFilter: Filter[] = context.organization
    ? [
        {
          name: 'organizationId',
          operator: FilterOperator.IN,
          value: [context.organization.id],
        },
      ]
    : [];
  const res = await getMaintenanceHistoriesStatistics({
    filters: [
      ...orgFilter,
      {
        name: 'maintenanceTime',
        operator: FilterOperator.BETWEEN,
        value: [dateRange.start, dateRange.endExclusive],
      },
    ],
    timezone: UserModule.timeZone,
  });

  if (!res || res.code != 200) {
    customFailedMessage(i18n.t('common.errorWithFetchingData') as string);
    return;
  }

  res.data.summary.sort((a: Summary, b: Summary) => {
    return a.maintenanceDate.localeCompare(b.maintenanceDate);
  });

  res.data.summary.forEach((item: Summary) => {
    chartData.xAxies.push(
      moment(new Date(item.maintenanceDate)).format('yyyy/MM/DD')
    );
    chartData.values.push(item.maintenancePlansConducted);
  });
  totalNrOfMaintenances.value = res.data.totalMaintenancePlansConducted;
  aveNrOfMaintenances.value = parseFloat(
    res.data.maintenancePlansConductedPerDay.toFixed(2)
  );
  maintenancePerformedChartData.value = chartData;
  isChartLoading.value = false;
  maintenanceChartIsVisible.value = true;
}
</script>

<template>
  <WidgetCard :loading="isChartLoading">
    <div
      class="select-time-date-container"
      id="performed-maintenance-date-selector"
    >
      <TimeSelect
        :expanded="true"
        @select="handleDateIntervalInput"
        :customizable="true"
        :defaultLastDays="DEFAULT_MAINTENANCE_DAYS_RANGE"
      />
    </div>

    <div v-if="maintenanceChartIsVisible" class="maintenance-status-overview">
      <div class="maintenance-status-overview-item">
        <div class="">
          <div class="status-total-number-label">
            {{ $t('maintenance.totalNrOfMaintenance') }}:
          </div>
          <div class="status-total-number-label">
            {{ $t('maintenance.aveNrOfMaintenance') }}:
          </div>
        </div>
        <div>
          <div class="status-total-number-value">
            {{ totalNrOfMaintenances }}
          </div>
          <div class="status-average-number-value">
            {{ aveNrOfMaintenances }}
          </div>
        </div>
      </div>
    </div>

    <MaintChart
      v-if="maintenanceChartIsVisible"
      :chartData="maintenancePerformedChartData"
      class="canvas-chart"
      height="17rem"
      style="margin-top: -1.78rem; margin-bottom: -2.85rem"
    />
  </WidgetCard>
</template>

<style scoped>
.chart-no-data-message {
  font-size: 18px;
  color: red;
  padding: 4px;
  margin: 100px 0 0 250px;
  font-family: var(--fontRobotoMedium);
}

.maintenance-status-overview {
  display: flex;
  flex-direction: column;
  font-style: normal;
  margin-bottom: 20px;
  width: auto;
}
</style>

<style lang="scss" scoped>
.select-time-date-container {
  display: flex;
  padding: 10px;
  margin: 0 0 5px 12px;

  @media only screen and (max-width: 1024px) {
    display: block;
  }
}

.maintenance-status-overview-item {
  width: 800px;
  display: flex;
  font-size: 16px;
  padding: 4px;
  margin: 0 6px 0 18px;
  font-family: var(--fontRobotoThin);
}

.status-total-number-value {
  font-weight: bold;
  width: 80px;
  display: flex;
  justify-content: right;
}

.status-average-number-value {
  font-weight: bold;
  width: 80px;
  display: flex;
  justify-content: right;
}
</style>
