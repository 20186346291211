import { hasHorizontalScrollbar, hasVerticalScrollbar } from './dom';

/**
 * Compares the scroll width with the client width to determine whether there's an active, visible horizontal scrollbar.
 * NOTE: Only call this function from a page on which you have a table present. For more general use, use `@utils/dom.ts`.
 * @param tableIndex - In case of multiple tables on the same page, set the index of the relevant table (counting from top to bottom)
 */
export function hasTableHorizontalScrollbar(tableIndex: number = 0): boolean {
  const tableDoms = document.getElementsByClassName('el-table__body-wrapper');

  if (tableIndex >= tableDoms.length) {
    throw new Error("Page doesn't  contain a table.");
  }

  return hasHorizontalScrollbar(tableDoms[tableIndex]);
}

/**
 * Compares the scroll heigth with the client heigth to determine whether there's an active, visible vertical scrollbar.
 * NOTE: Only call this function from a page on which you have a table present. For more general use, use `@utils/dom.ts`.
 * @param tableIndex - In case of multiple tables on the same page, set the index of the relevant table (counting from top to bottom)
 */
export function hasTableVerticalScrollbar(tableIndex: number = 0): boolean {
  const tableDoms = document.getElementsByClassName('el-table__body-wrapper');

  if (tableIndex >= tableDoms.length) {
    throw new Error("Page doesn't contain a table.");
  }

  return hasVerticalScrollbar(tableDoms[tableIndex]);
}
