export enum KPI_UNIT {
  Bar = 'UNIT_BAR',
  Psi = 'UNIT_PSI',
  Boolean = 'UNIT_BOOLEAN',
  Degrees = 'UNIT_DEGREES_360',
  DegreesCelsius = 'UNIT_DEGREES_CELSIUS',
  DegreesFahrenheit = 'UNIT_DEGREES_FAHRENHEIT',
  DegreesKelvin = 'UNIT_KELVIN',
  Meter = 'UNIT_METER',
  Millimeter = 'UNIT_MILLIMETER',
  Inch = 'UNIT_INCH',
  MetersPerSecond = 'UNIT_MTR_PER_SEC',
  Second = 'UNIT_SECOND',
  MetricTonne = 'UNIT_METRIC_TONNE',
  USTon = 'UNIT_US_TON',
  Kilogram = 'UNIT_KILOGRAM',
  Mile = 'UNIT_MILE',
  Pound = 'UNIT_POUND',
  UnitCount = 'UNIT_COUNT',
  Hour = 'UNIT_HOUR',
  Kilometer = 'UNIT_KILOMETER',
  KilometerPerHour = 'UNIT_KM_PER_HOUR',
  MilePerHour = 'UNIT_MILE_PER_HOUR',
  Text = 'UNIT_TEXT',
  Percentage = 'UNIT_PERCENTAGE',
  KilogramPerHour = 'UNIT_KILOGRAM_PER_HOUR',
  PoundPerHour = 'UNIT_POUND_PER_HOUR',
  MetricTonnePerHour = 'UNIT_METRIC_TONNE_P_HOUR',
  USTonPerHour = 'UNIT_US_TON_PER_HOUR',
  KilogramPerKWH = 'UNIT_KILOGRAM_PER_KWH',
  PoundPerKWH = 'UNIT_POUND_PER_KWH',
  MetricTonnePerKWH = 'UNIT_METRIC_TONNE_PER_KWH',
  USTonPerKWH = 'UNIT_US_TON_PER_KWH',
  KilowattHour = 'UNIT_KWH',
  PsiPerSecond = 'UNIT_PSI_PER_SECOND',
  BarPerSecond = 'UNIT_BAR_PER_SECOND',
  Year = 'UNIT_YEAR',
  Day = 'UNIT_DAY',
  Milliseconds = 'UNIT_TIMESTAMP_MILLIS',
  Month = 'UNIT_MONTH',
  Minute = 'UNIT_MINUTE',
}

/**
 * List of quantities used in our system.
 */
export enum Quantity {
  Length = 'QUANT_LENGTH',
  Mass = 'QUANT_MASS',
  Speed = 'QUANT_SPEED',
  Temperature = 'QUANT_TEMPERATURE',
  Pressure = 'QUANT_PRESSURE',
  PressurePerTime = 'QUANT_PRESSURE_PER_TIME',
  PayloadTimeEfficiency = 'QUANT_MASS_PER_TIME',
  PayloadEnergyEfficiency = 'QUANT_EFFICIENCY',
  Time = 'QUANT_TIME',
}
