<script lang="ts">
import { KpiDataRequest } from '@/api/kpis';
import {
  getPendingUpdates,
  PendingUpdate,
  startNewRelease,
} from '@/api/systemReleaseManagement';
import { flattenOrganizations } from '@/auth/context';
import { LoggedInUserRef, useLoggedInUser } from '@/auth/user';
import NewCard from '@/components/cusCard/NewCard.vue';
import PureTable from '@/components/table/PureTable.vue';
import { UserModule } from '@/store/modules/user';
import { customFailedMessage } from '@/utils/prompt';
import { DEPLOYMENT_PENDING_UPDATES_MANAGEMENT_COLS } from '@/utils/workData/deploymentPendingUpdatesManagementCols';
import {
  GENERAL_QUERY_OPERATORS,
  KPI_FIELDS,
} from '@/utils/workData/lookuptable';
import { unref } from 'vue';
import { Component, Vue } from 'vue-property-decorator';

interface SelectedRelease {
  asset: string | string[];
  releaseUUID: string;
}

interface NewRelease {
  systemReleaseUUID: string;
  companyId: string;
  assets: string[];
}

@Component({
  name: 'BatchDeploy',
  components: {
    'new-card': NewCard,
    'pure-table': PureTable,
  },
})
export default class extends Vue {
  /** Local variables */
  isViewModeLoading: boolean = false;
  isBatchDeploymentPageLoading: boolean = false;
  customerId: string = this.$route.params.customerId;
  activateOrDeactivate: string = '';
  data: PendingUpdate[] = [];
  selectedReleases: SelectedRelease[] = [];
  deploymentCols = DEPLOYMENT_PENDING_UPDATES_MANAGEMENT_COLS.map((item) => ({
    ...item,
    label: this.$t(item.label),
  }));
  deploymentsTotal: number = 0;
  assetList: string[] = [];
  kpiData: KpiDataRequest = {
    metadata: {
      filter: {
        assetIds: [],
      },
    },
    details: [
      {
        entity: '',
        fields: [
          {
            code: KPI_FIELDS.OperationalStatus,
          },
          {
            code: KPI_FIELDS.LastCommunicationTime,
          },
        ],
      },
    ],
  };
  releaseAssetListFilter = {
    filters: [
      {
        name: 'systemReleaseId',
        operator: GENERAL_QUERY_OPERATORS.Like,
        value: [''],
      },
    ],
    sorters: [
      {
        field: 'assetType',
        order: 'asc',
      },
    ],
    pagination: {
      page: '1',
      size: '10',
    },
  };
  pagination = {
    page: 1,
    size: UserModule.gridPageSize,
  };
  filter = {
    name: '',
    operator: GENERAL_QUERY_OPERATORS.Equal,
    values: [''],
  };
  filters = {
    filters: [
      {
        name: 'organizationId',
        operator: GENERAL_QUERY_OPERATORS.In,
        value: [] as string[], // Filled in in created()
      },
    ],
    sorters: [
      {
        field: 'assetType',
        order: 'asc',
      },
    ],
    pagination: this.pagination,
  };
  loggedInUser!: LoggedInUserRef;

  created() {
    this.loggedInUser = useLoggedInUser();
    const user = unref(this.loggedInUser);
    if (!user) {
      // Should never happen
      throw new Error('No user logged in');
    }
    this.filters.filters[0].value = flattenOrganizations(user.organization).map(
      (org) => org.id
    );
    this.getData();
  }

  /**
   * Get pending updates from API
   */
  async getData(): Promise<void> {
    try {
      this.isViewModeLoading = true;
      const response = await getPendingUpdates(this.filters);
      this.kpiData.metadata.filter.assetIds = response.data.pendingUpdates.map(
        (asset: PendingUpdate) => asset.id
      );
      this.data = response.data.pendingUpdates.map((item) => ({
        ...item,
        assetType: this.$t(item.assetType),
        opreationalStatus: item.opreationalStatus
          ? this.$t(item.opreationalStatus)
          : '',
      }));
    } catch (err) {
      console.error(err);
    } finally {
      this.isViewModeLoading = false;
    }
  }

  /**
   * Handle update selected release
   * @param selectedReleases
   */
  handleUpdateSelectedRelease(selectedReleases: SelectedRelease[]): void {
    this.selectedReleases = selectedReleases;
  }

  /**
   * Generate deployment body
   */
  async generateDeploymentsBody(): Promise<NewRelease[]> {
    const user = unref(this.loggedInUser);
    if (!user) {
      // Should never happen
      throw new Error('No user logged in');
    }
    let deployments: NewRelease[] = [];
    let releases = Object.keys(this.selectedReleases);
    for (let release of releases) {
      let newRelease = {
        systemReleaseUUID: release,
        companyId: user.companyId, // company ID is not returned on the API
        assets: this.getAssetIdsForSystemRelease(
          this.selectedReleases[release as any]
        ),
      };
      deployments.push(newRelease);
    }
    return deployments;
  }

  /**
   * Get asset ids for system release
   * @param releases
   */
  getAssetIdsForSystemRelease(releases: any): string[] {
    let ids: string[] = releases.map(
      (release: SelectedRelease) => release.asset
    );
    return ids;
  }

  /**
   * Handle deploy event
   */
  async handleDeploy(): Promise<void> {
    try {
      this.isBatchDeploymentPageLoading = true;
      const response = await startNewRelease({
        deployments: await this.generateDeploymentsBody(),
      });
      if (response.code === 200) this.$router.push(`/deploy/index`);
      if (response.code === 400) {
        customFailedMessage(response.data.errors[0].message as string);
      }
    } catch (err) {
      console.error(err);
    } finally {
      this.isBatchDeploymentPageLoading = false;
    }
  }
}
</script>

<template>
  <new-card
    v-loading="isBatchDeploymentPageLoading"
    id="view_cust_info_new_card"
    :visibleEdit="false"
    :visibleDeactivate="false"
    :visible="true"
    :activateOrDeactivate="activateOrDeactivate"
  >
    <div class="page-content">
      <div class="page-content-header">
        <h3>
          {{ selectedReleases.length }}
          {{ $t('deployManagementModule.batchDeployment.assetsSelected') }}
        </h3>
        <el-button
          class="new-deploy-button"
          id="newDeploy"
          type="plain"
          @click="handleDeploy"
        >
          {{ $t('deployManagementModule.batchDeployment.deployButton') }}
        </el-button>
      </div>
      <el-row>
        <pure-table
          v-loading="isViewModeLoading"
          :tableList="data"
          :total="deploymentsTotal"
          :cols="deploymentCols"
          :selectable="true"
          :grouped-selection="true"
          @updateSelectedAssets="handleUpdateSelectedRelease"
        />
      </el-row>
    </div>
  </new-card>
</template>

<style lang="scss">
.page-content {
  padding: 24px;

  h2 {
    color: #373e41;
  }

  .page-content-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background: #edeff0;
    padding: 12px;

    h3 {
      font-size: 22px;
    }

    .new-deploy-button {
      margin: auto 0 auto auto;
      height: 36px;
      width: 120px;
    }
  }
}

.row {
  display: flex;
  flex-direction: row;

  .el-select {
    margin: auto auto auto 12px;
  }

  .el-button {
    width: 220px;
    background: #373e41;
    color: white;
    margin: auto 0 auto auto;
  }
}
</style>
