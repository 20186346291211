import {
  OrganizationAssetsHierarchyAsset,
  OrganizationAssetsHierarchyResponseLegacy,
} from '@/api/assets';
import {
  AssetTripEventsSummary,
  GetTripsWithEventsSummary2HoursDetails,
  SafetyEventType,
  TripsWithEventsSummary2HoursDetails,
  TripsWithEventsSummaryByAsset,
  TripsWithEventsSummaryByTime,
} from '@/api/trip';
import i18n from '@/lang';
import {
  ASSET_SAFETY_CODE,
  NEW_FLEET_SAFETY_CODE,
} from '@/router/links/safetyWidget';
import { formatUnitValue } from '@/utils/format';
import { max, min, sum } from '@/utils/math';
import { capitalizeFirstLetter } from '@/utils/string';
import {
  addHoursForTimePattern,
  ISO8601_LOCAL_DATETIME_PATTERN,
  TIME_HOUR_MINUTES_PATTERN,
} from '@/utils/time';
import { KPI_UNIT } from '@/utils/units/unitDefinitions';
import { toUnitValue } from '@/utils/units/unitValue';
import moment from 'moment';
import { AggregationType } from './Constants';

export interface StackedChartSize {
  width: string;
  height: string;
}

export type TripEventSummaries =
  | {
      aggregationType: AggregationType.AggregateByTime;
      summaries: TripsWithEventsSummaryByTime[];
    }
  | {
      aggregationType: AggregationType.AggregateByAsset;
      summaries: TripsWithEventsSummaryByAsset[];
    };

/* ---------- Trip Summary selection ---------- */

export type DateSelection = {
  selectedDate: string;
};

export type AssetSelection = {
  selectedAsset: string;
};

export type TripsSummariesChartSelection = DateSelection | AssetSelection;

export function isDateSelection(
  item: TripsSummariesChartSelection | undefined
): item is DateSelection {
  return item ? 'selectedDate' in item : false;
}

export function isAssetSelection(
  item: TripsSummariesChartSelection | undefined
): item is AssetSelection {
  return item ? 'selectedAsset' in item : false;
}

/* ---------- End of Trip Summary selection ---------- */

/**
 * Get label translated 'Trips with <event>'
 * @param event
 */
export function getLabelWithEvent(event: SafetyEventType): string {
  return `${i18n.t('newFleetSafety.tripsWithEvent', { event: i18n.t(event) })}`;
}

/**
 * Get label translated '<event> percentage (%)'
 * @param event
 */
export function getEventPercentageLabel(
  event: SafetyEventType,
  unitCode: string
): string {
  return `${i18n.t('newFleetSafety.eventPercentage', {
    event: capitalizeFirstLetter(i18n.tc(event)),
    unit: i18n.t(unitCode),
  })}`;
}

/**
 * Get label translated '<event> (<unitCode>)'
 * @param event
 */
export function getSeverityEventLabelLabel(
  event: SafetyEventType | string,
  unitCode: KPI_UNIT
): string {
  return `${i18n.t('newFleetSafety.eventWithUnitCode', {
    event: capitalizeFirstLetter(i18n.tc(event)),
    unit: i18n.t(unitCode),
  })}`;
}

/**
 * Get label translated '<event> (%)'
 * @param event
 */
export function getPercentageLabel(event: SafetyEventType): string {
  return `${capitalizeFirstLetter(i18n.tc(event))} (%)`;
}

/**
 * Get label translated 'Trips without <event>'
 * @param event
 */
export function getLabelWithoutEvent(eventType: SafetyEventType): string {
  return `${i18n.t('newFleetSafety.tripsWithoutEvent', {
    event: i18n.t(eventType),
  })}`;
}

/**
 * Get label translated '<statisticRef> <event> %'
 * i.e: 'Max. overload %'
 * @param event
 */
export function getStatisticLabelTranslated(
  statisticType: 'min' | 'max' | 'avg',
  event: SafetyEventType,
  unitCode: string
): string {
  return `${i18n.t('newFleetSafety.statisticRefPercentage', {
    statisticRef: i18n.tc(`common.${statisticType}`) + '.',
    event: capitalizeFirstLetter(i18n.tc(event)),
    unit: `(${i18n.tc(unitCode)})`,
  })}`;
}

/**
 * Get same received UTC offset then format it to a pattern
 * @param dateToFormat
 * @param formatPattern if undefined then default ISO8601_LOCAL_DATETIME_PATTERN
 */
export function getFormattedSafetyDate(
  dateToFormat: string,
  formatPattern: string = ISO8601_LOCAL_DATETIME_PATTERN
): string {
  return moment(dateToFormat).utc().format(formatPattern);
}

/* --------------------- CUSTOM TOOLTIP --------------------- */

export const CUSTOM_TOOLTIP_FONT_SIZE: number = 14;
export const CUSTOM_TOOLTIP_FONT_FAMILY: string = 'Roboto';
export const CUSTOM_TOOLTIP_PADDING: number = 4;
export const CUSTOM_TOOLTIP_FONT_WEIGHT: number = 700;
export const CUSTOM_TOOLTIP_MIN_PADDING: number = 20;
export const CUSTOM_TOOLTIP_HEADER_TEXT_COLOR: string = '#cbcccd';

/**
 * Retrieve longest string by length
 */
export function getLongestLabelByLength(arrayElements: string[]): string {
  return arrayElements.reduce((longest, current) => {
    return current.length > longest.length ? current : longest;
  }, '');
}

/**
 * Calculate min row width in pixels of an content text determined by:
 * - font size
 * - font family
 */
export function getMinRowWidthInPixels(
  textContent: string,
  fontSize: number,
  fontFamily: string,
  minPadding: number = 10
): number {
  const canvas = document.createElement('canvas');
  const context = canvas.getContext('2d');
  context!.font = `${fontSize}px ${fontFamily}`;
  const textWidth = context!.measureText(textContent).width;

  return textWidth + minPadding;
}

/**
 * Get small event severity tooltip html content that will be used by echart options to render the component
 */
export function getSmallEventSeverityTooltipContent(
  tooltipTitle: string,
  eventItem: AssetTripEventsSummary,
  safetyEvent: SafetyEventType,
  safetyUnitCode: KPI_UNIT
): string {
  const tripStartTimeLabel: string = i18n.tc('newFleetSafety.tripStartTime');
  const tripEndTimeLabel: string = i18n.tc('newFleetSafety.tripEndTime');
  const safetyEventLabel: string = capitalizeFirstLetter(i18n.tc(safetyEvent));

  const longestLabel: string = getLongestLabelByLength([
    tripStartTimeLabel,
    tripEndTimeLabel,
    safetyEventLabel,
  ]);

  const customRowMinWidth: string = `${getMinRowWidthInPixels(
    longestLabel,
    CUSTOM_TOOLTIP_FONT_SIZE,
    CUSTOM_TOOLTIP_FONT_FAMILY,
    CUSTOM_TOOLTIP_MIN_PADDING
  )}`;

  let content = `
    <div class="tooltip-element" style="font-size: ${CUSTOM_TOOLTIP_FONT_SIZE}px; font-family: ${CUSTOM_TOOLTIP_FONT_FAMILY}">
      <div style="border-bottom: solid 1px white; color: ${CUSTOM_TOOLTIP_HEADER_TEXT_COLOR}; padding: ${CUSTOM_TOOLTIP_PADDING}px; font-weight: ${CUSTOM_TOOLTIP_FONT_WEIGHT};">
        ${tooltipTitle}
      </div>
    `;

  content += `
      <div style="display: flex; padding: ${CUSTOM_TOOLTIP_PADDING}px; font-weight: ${CUSTOM_TOOLTIP_FONT_WEIGHT};">
        <div style="text-align: left; width: ${customRowMinWidth}px;">
          ${tripStartTimeLabel}:
        </div>
        <div style="text-align: left;">
          ${getFormattedSafetyDate(
            eventItem.tripStartTime!,
            TIME_HOUR_MINUTES_PATTERN
          )}
        </div>
      </div>
      <div style="display: flex; padding: ${CUSTOM_TOOLTIP_PADDING}px; font-weight: ${CUSTOM_TOOLTIP_FONT_WEIGHT};">
        <div style="text-align: left; width: ${customRowMinWidth}px;">
          ${tripEndTimeLabel}:
        </div>
        <div style="text-align: left;">
          ${getFormattedSafetyDate(
            eventItem.tripEndTime!,
            TIME_HOUR_MINUTES_PATTERN
          )}
        </div>
      </div>
      <div style="display: flex; padding: ${CUSTOM_TOOLTIP_PADDING}px; font-weight: ${CUSTOM_TOOLTIP_FONT_WEIGHT};">
        <div style="text-align: left; width: ${customRowMinWidth}px;">
          ${safetyEventLabel}:
        </div>
        <div style="text-align: left;">
          ${formatSeverityStatistic(
            eventItem.averageSeverityLevel,
            safetyUnitCode
          )}
        </div>
      </div>
    </div>
  `;

  return content;
}

/**
 * Get large event severity tooltip html content that will be used by echart options to render the component
 */
export function getLargeEventSeverityTooltipContent(
  tooltipTitle: string,
  eventItem: AssetTripEventsSummary,
  safetyEvent: SafetyEventType,
  safetyUnitCode: KPI_UNIT
): string {
  const tripStartTimeLabel: string = i18n.tc('newFleetSafety.tripStartTime');
  const tripEndTimeLabel: string = i18n.tc('newFleetSafety.tripEndTime');
  const numberOfTypeEventsLabel: string = i18n
    .t('newFleetSafety.noOfTypeEvents', {
      event: i18n.tc(safetyEvent),
    })
    .toString();
  const statisticAvgLabel: string = getStatisticLabelTranslated(
    'avg',
    safetyEvent,
    safetyUnitCode
  );
  const statisticMinLabel: string = getStatisticLabelTranslated(
    'min',
    safetyEvent,
    safetyUnitCode
  );
  const statisticMaxLabel: string = getStatisticLabelTranslated(
    'max',
    safetyEvent,
    safetyUnitCode
  );

  const longestLabel: string = getLongestLabelByLength([
    tripStartTimeLabel,
    tripEndTimeLabel,
    numberOfTypeEventsLabel,
    statisticAvgLabel,
    statisticMinLabel,
    statisticMaxLabel,
  ]);

  const customRowMinWidth: string = `${getMinRowWidthInPixels(
    longestLabel,
    CUSTOM_TOOLTIP_FONT_SIZE,
    CUSTOM_TOOLTIP_FONT_FAMILY,
    CUSTOM_TOOLTIP_MIN_PADDING
  )}px`;

  let content = `
    <div class="tooltip-element" style="font-size: ${CUSTOM_TOOLTIP_FONT_SIZE}px; font-family: ${CUSTOM_TOOLTIP_FONT_FAMILY}">
      <div style="border-bottom: solid 1px white; color: ${CUSTOM_TOOLTIP_HEADER_TEXT_COLOR}; padding: ${CUSTOM_TOOLTIP_PADDING}px; font-weight: ${CUSTOM_TOOLTIP_FONT_WEIGHT};">
        ${tooltipTitle}
      </div>`;

  content += `
      <div style="display: flex; padding: ${CUSTOM_TOOLTIP_PADDING}px; font-weight: ${CUSTOM_TOOLTIP_FONT_WEIGHT};">
        <div style="text-align: left; width: ${customRowMinWidth};">
          ${tripStartTimeLabel}:
        </div>
        <div style="text-align: left;">
          ${getFormattedSafetyDate(
            eventItem?.tripStartTime!,
            TIME_HOUR_MINUTES_PATTERN
          )}
        </div>
      </div>
      <div style="display: flex; padding: ${CUSTOM_TOOLTIP_PADDING}px; font-weight: ${CUSTOM_TOOLTIP_FONT_WEIGHT};">
        <div style="text-align: left; width: ${customRowMinWidth};">
          ${tripEndTimeLabel}:
        </div>
        <div style="text-align: left;">
          ${getFormattedSafetyDate(
            eventItem?.tripEndTime!,
            TIME_HOUR_MINUTES_PATTERN
          )}
        </div>
      </div>
      <div style="display: flex; padding: ${CUSTOM_TOOLTIP_PADDING}px; font-weight: ${CUSTOM_TOOLTIP_FONT_WEIGHT};">
        <div style="text-align: left; width: ${customRowMinWidth};">
          ${numberOfTypeEventsLabel}:
        </div>
        <div style="text-align: left;">
          ${eventItem.numberOfEvents}
        </div>
      </div>
      <div style="display: flex; padding: ${CUSTOM_TOOLTIP_PADDING}px; font-weight: ${CUSTOM_TOOLTIP_FONT_WEIGHT};">
        <div style="text-align: left; width: ${customRowMinWidth};">
          ${statisticAvgLabel}:
        </div>
        <div style="text-align: left;">
          ${formatSeverityStatistic(
            eventItem.averageSeverityLevel,
            safetyUnitCode
          )}
        </div>
      </div>
      <div style="display: flex; padding: ${CUSTOM_TOOLTIP_PADDING}px; font-weight: ${CUSTOM_TOOLTIP_FONT_WEIGHT};">
        <div style="text-align: left; width: ${customRowMinWidth};">
        ${statisticMinLabel}:
        </div>
        <div style="text-align: left;">
          ${formatSeverityStatistic(
            eventItem.minimumSeverityLevel,
            safetyUnitCode
          )}
        </div>
      </div>
      <div style="display: flex; padding: ${CUSTOM_TOOLTIP_PADDING}px; font-weight: ${CUSTOM_TOOLTIP_FONT_WEIGHT};">
        <div style="text-align: left; width: ${customRowMinWidth};">
        ${statisticMaxLabel}:
        </div>
        <div style="text-align: left;">
          ${formatSeverityStatistic(
            eventItem.maximumSeverityLevel,
            safetyUnitCode
          )}
        </div>
      <div/>
    <div/>
  `;

  return content;
}

/**
 * Get expanded fleet tooltip html content that will be used by echart options to render the component aggregated by time
 */
export function getExpandedFleetSafetyTooltipAggregatedByTime(
  eventItem: TripsWithEventsSummaryByTime,
  safetyEvent: SafetyEventType
): string {
  const labelWithEvent: string = getLabelWithEvent(safetyEvent);
  const labelWithoutEvent: string = getLabelWithoutEvent(safetyEvent);
  const ratio: string = i18n.tc('newFleetSafety.ratio');

  const longestLabel: string = getLongestLabelByLength([
    labelWithEvent,
    labelWithoutEvent,
    ratio,
  ]);

  const customRowMinWidth: string = `${getMinRowWidthInPixels(
    longestLabel,
    CUSTOM_TOOLTIP_FONT_SIZE,
    CUSTOM_TOOLTIP_FONT_FAMILY,
    CUSTOM_TOOLTIP_MIN_PADDING
  )}px`;

  let content = `
    <div class="tooltip-element" style="font-size: ${CUSTOM_TOOLTIP_FONT_SIZE}px; font-family: ${CUSTOM_TOOLTIP_FONT_FAMILY}">
      <div style="border-bottom: solid 1px white; color: ${CUSTOM_TOOLTIP_HEADER_TEXT_COLOR}; padding: ${CUSTOM_TOOLTIP_PADDING}px; font-weight: ${CUSTOM_TOOLTIP_FONT_WEIGHT};">
        ${eventItem.bucketStartDate}
      </div>
    `;

  content += `
      <div style="display: flex; padding: ${CUSTOM_TOOLTIP_PADDING}px;  font-weight: ${CUSTOM_TOOLTIP_FONT_WEIGHT};">
        <div style="text-align: left; min-width: ${customRowMinWidth};">
          ${labelWithEvent}:
        </div>
        <div style="text-align: left;">
          ${eventItem.tripsWithEventsSummary.numberOfTripsWithEvents}
        </div>
      </div>
      <div style="display: flex; padding: ${CUSTOM_TOOLTIP_PADDING}px;  font-weight: ${CUSTOM_TOOLTIP_FONT_WEIGHT};">
        <div style="text-align: left; min-width: ${customRowMinWidth};">
          ${labelWithoutEvent}:
        </div>
        <div style="text-align: left;">
          ${
            eventItem.tripsWithEventsSummary.numberOfTotalTrips -
            eventItem.tripsWithEventsSummary.numberOfTripsWithEvents
          }
        </div>
      </div>
      <div style="display: flex; padding: ${CUSTOM_TOOLTIP_PADDING}px;  font-weight: ${CUSTOM_TOOLTIP_FONT_WEIGHT};">
        <div style="text-align: left; min-width: ${customRowMinWidth};">
          ${ratio}:
        </div>
        <div style="text-align: left;">
          ${formatSeverityStatistic(
            eventItem.tripsWithEventsSummary.ratioPercentage,
            KPI_UNIT.Percentage
          )}
        </div>
      </div>
    </div>
  `;

  return content;
}

/**
 * Get expanded fleet tooltip html content that will be used by echart options to render the tooltip component when aggregated by asset
 */
export function getExpandedFleetSafetyTooltipAggregatedByAsset(
  eventItem: TripsWithEventsSummaryByAsset,
  safetyEvent: SafetyEventType,
  redirectToSingleAssetIsVisible: boolean,
  singleAssetHref: string | undefined
): string {
  const labelWithEvent: string = getLabelWithEvent(safetyEvent);
  const labelWithoutEvent: string = getLabelWithoutEvent(safetyEvent);
  const ratio: string = i18n.tc('newFleetSafety.ratio');

  const longestLabel: string = getLongestLabelByLength([
    labelWithEvent,
    labelWithoutEvent,
    ratio,
  ]);

  const customRowMinWidth: string = `${getMinRowWidthInPixels(
    longestLabel,
    CUSTOM_TOOLTIP_FONT_SIZE,
    CUSTOM_TOOLTIP_FONT_FAMILY,
    CUSTOM_TOOLTIP_MIN_PADDING
  )}px`;
  const customArrowTopMargin: number = -4;

  let content = `<div class="tooltip-element" style="font-size: ${CUSTOM_TOOLTIP_FONT_SIZE}px; font-family: ${CUSTOM_TOOLTIP_FONT_FAMILY}">
    <div style="display: flex; justify-content: space-between; border-bottom: solid 1px white; padding: ${CUSTOM_TOOLTIP_PADDING}px; font-weight: ${CUSTOM_TOOLTIP_FONT_WEIGHT};">
      <div style="color: white; color: ${CUSTOM_TOOLTIP_HEADER_TEXT_COLOR};">${
    eventItem.companyAssetId
  }</div>
      ${
        redirectToSingleAssetIsVisible && singleAssetHref != undefined
          ? `
      <a style="text-decoration: none;" href="${singleAssetHref}">
        <div
          class="circle"
          style="
            width: 20px;
            height: 20px;
            background-color: white;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            position: relative;
            z-index: 1000"
        >
          <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M11.9902 24.6895C18.6177 24.6895 23.9902 19.3169 23.9902 12.6895C23.9902 6.06204 18.6177 0.689453 11.9902 0.689453C5.36282 0.689453 -0.00976562 6.06204 -0.00976562 12.6895C-0.00976562 19.3169 5.36282 24.6895 11.9902 24.6895Z" fill="white"/>
            <path d="M13.6651 7.28924C13.6178 7.3088 13.5773 7.34195 13.5489 7.38449C13.5204 7.42702 13.5052 7.47705 13.5051 7.52824V10.3582H7.04709C6.97936 10.3582 6.91433 10.3849 6.86606 10.4324C6.81779 10.4799 6.79014 10.5445 6.78909 10.6122L6.78909 14.7502C6.78856 14.7845 6.79484 14.8184 6.80757 14.8502C6.8203 14.882 6.83922 14.9109 6.86323 14.9353C6.88724 14.9596 6.91586 14.979 6.94743 14.9922C6.97899 15.0054 7.01287 15.0122 7.04709 15.0122H13.5051V17.8502C13.5049 17.9014 13.5199 17.9515 13.5482 17.9942C13.5765 18.0368 13.6169 18.0701 13.6642 18.0897C13.7115 18.1093 13.7635 18.1144 13.8137 18.1044C13.8639 18.0943 13.91 18.0695 13.9461 18.0332L19.1161 12.8802C19.1646 12.8317 19.1918 12.7659 19.1918 12.6972C19.1918 12.6286 19.1646 12.5628 19.1161 12.5142L13.9461 7.34624C13.9101 7.31001 13.8643 7.28525 13.8142 7.27511C13.7642 7.26496 13.7123 7.26988 13.6651 7.28924Z" fill="#373E41"/>
          </svg>
        </div>
      </a>`
          : ``
      }
    </div>
  `;

  content += `
      <div style="display: flex; padding: ${CUSTOM_TOOLTIP_PADDING}px; font-weight: ${CUSTOM_TOOLTIP_FONT_WEIGHT};">
        <div style="text-align: left; min-width: ${customRowMinWidth};">
          ${labelWithEvent}:
        </div>
        <div style="text-align: left;">
          ${eventItem.tripsWithEventsSummary.numberOfTripsWithEvents}
        </div>
      </div>
      <div style="display: flex; padding: ${CUSTOM_TOOLTIP_PADDING}px;  font-weight: ${CUSTOM_TOOLTIP_FONT_WEIGHT};">
        <div style="text-align: left; min-width: ${customRowMinWidth};">
          ${labelWithoutEvent}:
        </div>
        <div style="text-align: left;">
          ${
            eventItem.tripsWithEventsSummary.numberOfTotalTrips -
            eventItem.tripsWithEventsSummary.numberOfTripsWithEvents
          }
        </div>
      </div>
      <div style="display: flex; padding: ${CUSTOM_TOOLTIP_PADDING}px;  font-weight: ${CUSTOM_TOOLTIP_FONT_WEIGHT};">
        <div style="text-align: left; min-width: ${customRowMinWidth};">
          ${ratio}:
        </div>
        <div style="text-align: left;">
          ${formatSeverityStatistic(
            eventItem.tripsWithEventsSummary.ratioPercentage,
            KPI_UNIT.Percentage
          )}
        </div>
      </div>
    </div>
  `;

  return content;
}

/**
 * Get expanded fleet tooltip html content that will be used by echart options to render the right side chart tooltip
 */
export function getExpandedFleetSafetyHorizontalChartTooltip(
  bucketObj: TripsWithEventsSummary2HoursDetails,
  safetyEvent: SafetyEventType,
  safetyUnitCode: KPI_UNIT
): string {
  const statisticAvgLabel: string = getStatisticLabelTranslated(
    'avg',
    safetyEvent,
    safetyUnitCode
  );
  const statisticMinLabel: string = getStatisticLabelTranslated(
    'min',
    safetyEvent,
    safetyUnitCode
  );
  const statisticMaxLabel: string = getStatisticLabelTranslated(
    'max',
    safetyEvent,
    safetyUnitCode
  );
  const ratioLabel: string = i18n.tc('newFleetSafety.ratio');

  const longestLabel: string = getLongestLabelByLength([
    statisticAvgLabel,
    statisticMinLabel,
    statisticMaxLabel,
    ratioLabel,
  ]);

  const customRowMinWidth: string = `${getMinRowWidthInPixels(
    longestLabel,
    CUSTOM_TOOLTIP_FONT_SIZE,
    CUSTOM_TOOLTIP_FONT_FAMILY,
    CUSTOM_TOOLTIP_MIN_PADDING
  )}px`;

  let content = `
  <div class="tooltip-element" style="font-size: 14px; font-family: Roboto">
    <div style="border-bottom: solid 1px white; color: ${CUSTOM_TOOLTIP_HEADER_TEXT_COLOR}; padding: ${CUSTOM_TOOLTIP_PADDING}px; font-weight: ${CUSTOM_TOOLTIP_FONT_WEIGHT};">
      ${bucketObj.bucketLabel} - ${addHoursForTimePattern(
    bucketObj.bucketLabel,
    2
  )}
    </div>
  `;

  content += `
        <div style="display: flex; padding: ${CUSTOM_TOOLTIP_PADDING}px; font-weight: ${CUSTOM_TOOLTIP_FONT_WEIGHT};">
          <div style="text-align: left; width: ${customRowMinWidth};">
            ${statisticAvgLabel}:
          </div>
          <div style="text-align: left;">
            ${formatSeverityStatistic(
              bucketObj.eventsDetailedSummary.averageSeverityLevel,
              safetyUnitCode
            )}
          </div>
        </div>
        <div style="display: flex; padding: ${CUSTOM_TOOLTIP_PADDING}px; font-weight: ${CUSTOM_TOOLTIP_FONT_WEIGHT};">
          <div style="text-align: left; width: ${customRowMinWidth};">
            ${statisticMinLabel}:
          </div>
          <div style="text-align: left;">
            ${formatSeverityStatistic(
              bucketObj.eventsDetailedSummary.minimumSeverityLevel,
              safetyUnitCode
            )}
          </div>
        </div>
        <div style="display: flex; padding: ${CUSTOM_TOOLTIP_PADDING}px; font-weight: ${CUSTOM_TOOLTIP_FONT_WEIGHT};">
          <div style="text-align: left; width: ${customRowMinWidth};">
            ${statisticMaxLabel}:
          </div>
          <div style="text-align: left;">
            ${formatSeverityStatistic(
              bucketObj.eventsDetailedSummary.maximumSeverityLevel,
              safetyUnitCode
            )}
          </div>
        </div>
        <div style="display: flex; padding: ${CUSTOM_TOOLTIP_PADDING}px; font-weight: ${CUSTOM_TOOLTIP_FONT_WEIGHT};">
        <div style="text-align: left; width: ${customRowMinWidth};">
          ${ratioLabel}:
        </div>
        <div style="text-align: left;">
          ${formatSeverityStatistic(
            bucketObj.tripsWithEventsSummary.ratioPercentage,
            KPI_UNIT.Percentage
          )}
        </div>
      </div>
    </div>
  `;

  return content;
}

/**
 * Check if date and time is included into a multi level array of type (string | number)[][]
 * @param arrayData
 * @param searchedDateTime
 * @return boolean
 */
export function isSameDateAndTimeIncluded(
  arrayData: (string | number)[][],
  searchedDateTime: string
) {
  return arrayData.some((outerLevelItem) => {
    const dateTimeItem = outerLevelItem[0];

    return moment(dateTimeItem).isSame(moment(searchedDateTime));
  });
}

export function formatSeverityStatistic(
  value: number | undefined,
  unitCode: KPI_UNIT
): string {
  const unit = i18n.tc(unitCode);
  if (value === undefined || !isFinite(value)) {
    return `-- ${unit}`;
  }

  return formatUnitValue(toUnitValue(value, unitCode));
}

export interface TripSafetyStatistics {
  totalTrips: number;
  tripsWithEvents: number;
  tripsWithoutEvents: number;
  ratio: number;
  minSeverity: number;
  avgSeverity: number;
  maxSeverity: number;
}

export interface TripSafetyStatisticsForTwoHoursBuckets
  extends TripSafetyStatistics {
  totalAssets: number;
}

export function computeTripSafetyStatistics(
  selectedTrips: AssetTripEventsSummary[]
): TripSafetyStatistics {
  const totalTrips = selectedTrips.length;
  const selectedTripsWithEvents = selectedTrips.filter(
    (tripSummary) => tripSummary.numberOfEvents > 0
  );
  const tripsWithEvents = selectedTripsWithEvents.length;
  const tripsWithoutEvents = totalTrips - tripsWithEvents;

  const minSeverity = selectedTripsWithEvents
    .map((tripSummary) => tripSummary.minimumSeverityLevel)
    .reduce(min, Infinity);
  const maxSeverity = selectedTripsWithEvents
    .map((tripSummary) => tripSummary.maximumSeverityLevel)
    .reduce(max, -Infinity);
  const numberOfEvents = selectedTripsWithEvents
    .map((tripSummary) => tripSummary.numberOfEvents)
    .reduce(sum, 0);
  const sumOfLevels = selectedTripsWithEvents
    .map((tripSummary) => tripSummary.sumOfSeverityLevel)
    .reduce(sum, 0);
  const avgSeverity = sumOfLevels / numberOfEvents;

  return {
    totalTrips,
    tripsWithEvents,
    tripsWithoutEvents,
    ratio: (100 * tripsWithEvents) / totalTrips,
    minSeverity,
    avgSeverity,
    maxSeverity,
  };
}

export function computeTripSafetyStatisticsForTwoHoursBuckets(
  tripsSummaries: GetTripsWithEventsSummary2HoursDetails
): TripSafetyStatisticsForTwoHoursBuckets {
  const totalAssets = tripsSummaries.numberOfAssetsWithTrips;
  const totalTrips = tripsSummaries.tripsWithEventsDetailedSummary
    .map((summary) => summary.tripsWithEventsSummary.numberOfTotalTrips)
    .reduce(sum, 0);
  const tripsWithEvents = tripsSummaries.tripsWithEventsDetailedSummary
    .map((summary) => summary.tripsWithEventsSummary.numberOfTripsWithEvents)
    .reduce(sum, 0);
  const tripsWithoutEvents = totalTrips - tripsWithEvents;

  const eventBucketsWithEvents =
    tripsSummaries.tripsWithEventsDetailedSummary.filter(
      (tripSummary) => tripSummary.eventsDetailedSummary.numberOfEvents > 0
    );
  const minSeverity = eventBucketsWithEvents
    .map(
      (tripSummary) => tripSummary.eventsDetailedSummary.minimumSeverityLevel
    )
    .reduce(min, Infinity);
  const maxSeverity = eventBucketsWithEvents
    .map(
      (tripSummary) => tripSummary.eventsDetailedSummary.maximumSeverityLevel
    )
    .reduce(max, -Infinity);
  const numberOfEvents = eventBucketsWithEvents
    .map((tripSummary) => tripSummary.eventsDetailedSummary.numberOfEvents)
    .reduce(sum, 0);
  const sumOfLevels = eventBucketsWithEvents
    .map((tripSummary) => tripSummary.eventsDetailedSummary.sumOfSeverityLevel)
    .reduce(sum, 0);
  const avgSeverity = sumOfLevels / numberOfEvents;

  return {
    totalAssets,
    totalTrips,
    tripsWithEvents,
    tripsWithoutEvents,
    ratio: (100 * tripsWithEvents) / totalTrips,
    minSeverity,
    avgSeverity,
    maxSeverity,
  };
}

export function flattenOrganizationHierarchyAssets(
  orgHierarchy: OrganizationAssetsHierarchyResponseLegacy
): OrganizationAssetsHierarchyAsset[] {
  let flattenedAssets: OrganizationAssetsHierarchyAsset[] =
    orgHierarchy.organization.assets ?? [];

  if (orgHierarchy.organization.suborganizations === undefined) {
    return flattenedAssets;
  }

  for (const suborg of orgHierarchy.organization.suborganizations) {
    flattenedAssets = flattenedAssets.concat(
      flattenOrganizationHierarchyAssets(suborg)
    );
  }

  return flattenedAssets;
}

/**
 * New safety widgets: generate translated title for grouping scope (fleet/asset) that has:
 * - one safety event then: Fleet/Asset <event> e.g: ("Fleet Overload" or "Asset Overload") for TruckOM customer type
 */
export function defaultSafetyWidgetTitle(
  eventTypeCode: SafetyEventType,
  isAssetScope: boolean
): string {
  const groupingScopeLabel: string = isAssetScope
    ? i18n.tc('newFleetSafety.asset')
    : i18n.tc('newFleetSafety.fleet');

  return `${groupingScopeLabel} ${i18n.tc(eventTypeCode)}`;
}

/**
 * Check if the route includes the code for expanded single asset/fleet safety widget
 */
export function routeIncludesExpandedSafetyWidget(code: string): boolean {
  return [ASSET_SAFETY_CODE, NEW_FLEET_SAFETY_CODE].includes(code);
}
