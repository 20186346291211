import { CommonResult } from '@/api/commonResult';
import { reactive, toRefs } from 'vue';

/**
 * @deprecated Use useAsync instead
 */
export function useFetch<T>(p: Promise<CommonResult<T>>) {
  const state = reactive({
    data: undefined as T | undefined,
    loading: true,
    error: undefined as string | undefined,
  });

  const fetch = (promise: Promise<CommonResult<T>>) => {
    state.loading = true;

    promise
      .then(({ data }) => {
        state.error = undefined;
        state.data = data as any;
      })
      .catch((e: unknown) => {
        state.error = e instanceof Error ? e.message : 'Unknown error';
        state.data = undefined;
      })
      .finally(() => (state.loading = false));
  };

  fetch(p);

  return {
    ...toRefs(state),
    fetch,
  };
}
