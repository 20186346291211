<script lang="ts" setup>
import hyvaLogo from '@/assets/imgs/hyva.svg';
import menuIn from '@/assets/imgs/menu_in.svg';
import menuOut from '@/assets/imgs/menu_out.svg';
import { useLoggedInUser } from '@/auth/user';
import LangSelect from '@/components/layout/select/LangSelect.vue';
import NavBarSearch from '@/components/navBarSearch/NavBarSearch.vue';
import HelpdeskIcon from '@/icons/svg/helpdesk.svg';
import { PageModule } from '@/store/modules/page';
import { UserModule } from '@/store/modules/user';
import { ALL_CLAIMS_CODES, COMPANY_TYPE } from '@/utils/workData/lookuptable';
import Vue, { computed } from 'vue';
import Notifications from './notifications/Notifications.vue';
import ContextSelector from './select/ContextSelector.vue';

const props = defineProps<{
  collapsed: boolean;
}>();

const emit = defineEmits<{
  (e: 'toggle-collapsed'): void;
  (e: 'logout'): void;
}>();

const loggedInUser = useLoggedInUser();
const isHyvaAdmin = computed(
  () => loggedInUser.value?.companyType === COMPANY_TYPE.Hyva
);
const url = computed(() => {
  if (isHyvaAdmin.value) {
    return hyvaLogo;
  }

  return `${Vue.prototype.$envConfig.VUE_APP_BASE_API}/companies/${UserModule.companyId}/logo`;
});

const title = computed(() => PageModule.title[PageModule.title.length - 1]);
</script>

<template>
  <div class="topbar">
    <img :src="url" class="logo lg" />
    <span class="lg"> OneEMS </span>
    <button class="button" @click="emit('toggle-collapsed')">
      <img :src="props.collapsed ? menuOut : menuIn" class="hamburger" />
    </button>
    <div class="lg">
      <ContextSelector />
    </div>
    <span class="sm">
      {{ title }}
    </span>
    <div class="spacer" />
    <NavBarSearch
      v-if="!isHyvaAdmin"
      class="search lg"
      :key="UserModule.companyId"
    />
    <div>
      <LangSelect v-if="isHyvaAdmin" />
    </div>
    <router-link
      v-if="
        loggedInUser?.claims.hasClaim(ALL_CLAIMS_CODES.AUTHRSC_PAGE_HELPCENTER)
        // Based on this claim the Help Center tab is rendered --> no point to show it if the user won't have access to the tab
      "
      to="/user-setting/index?activeName=helpCenter"
      style="display: flex"
    >
      <img :src="HelpdeskIcon" />
    </router-link>
    <div>
      <Notifications />
    </div>
    <el-dropdown class="lg" trigger="click">
      <div class="dropdown">
        <div class="avatar">
          <svg-icon name="user" />
        </div>
        {{ UserModule.username }}
      </div>
      <el-dropdown-menu slot="dropdown">
        <router-link to="/user-setting">
          <el-dropdown-item v-permission="['AUTHRSC_MOD_USER_SETTINGS']">
            {{ $t('navbar.userSetting') }}
          </el-dropdown-item>
        </router-link>
        <el-dropdown-item @click.native="emit('logout')">
          {{ $t('navbar.logOut') }}
        </el-dropdown-item>
      </el-dropdown-menu>
    </el-dropdown>
  </div>
</template>

<style lang="scss" scoped>
.topbar {
  display: flex;
  align-items: center;
  gap: 1rem;
  padding: 0 1rem;

  @media (min-width: 769px) {
    padding-left: 0;
  }
}

.sm {
  display: flex;
  align-items: center;

  @media (min-width: 769px) {
    display: none;
  }
}

.lg {
  display: none;
  align-items: center;

  @media (min-width: 769px) {
    display: flex;
  }
}

.logo {
  height: 100%;
}

.button {
  color: white;
  background: none;
  border: none;
  cursor: pointer;
}

.hamburger {
  height: 0.75rem;
}

.spacer {
  flex: 1;
}

.dropdown {
  display: flex;
  align-items: center;
  color: #ffffff;
  font-size: 1rem;
  cursor: pointer;
}

.avatar {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 1.75rem;
  height: 1.75rem;
  margin-right: 0.5rem;
  padding: 0.25rem;
  background-color: #586365;
  border-radius: 50%;
}

.search {
  margin-bottom: -3px;

  & :deep(.el-input) {
    width: 15rem !important;
  }
}
</style>
