import { render, staticRenderFns } from "./Dashboard.vue?vue&type=template&id=a5476774"
import script from "./Dashboard.vue?vue&type=script&lang=ts&setup=true"
export * from "./Dashboard.vue?vue&type=script&lang=ts&setup=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports