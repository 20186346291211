import moment from 'moment';

import { LOCALDATE_FORMAT } from '@/utils/time';

/**
 * Substracts noOfDays from dateToBeSubstracted
 * By default, it substracts 1 day from given date and formats it to LOCALDATE_FORMAT
 */
export const substractDaysFromData = ({
  dateToBeSubstracted,
  noOfDays = 1,
  dateFormat = LOCALDATE_FORMAT,
}: {
  dateToBeSubstracted: string;
  noOfDays?: number;
  dateFormat?: string;
}): string => {
  const newDate = new Date(dateToBeSubstracted);
  newDate.setDate(newDate.getDate() - noOfDays);

  return moment(newDate).format(dateFormat);
};
