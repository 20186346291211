import { UUID } from '@/api/common';
import { AssetType } from '@/utils/assetTypes';
import { Location } from 'vue-router';
import { overviewRouteNames } from '../modules/assets';
import { overviewOperationalDashboardRouteNames } from '../modules/operationalDashboard';

export function createPresetLocation(
  assetType: AssetType,
  assetId: UUID,
  isOperationalSupportUser: boolean = false
): Location {
  return {
    name: isOperationalSupportUser
      ? overviewOperationalDashboardRouteNames[assetType]?.createPreset
      : overviewRouteNames[assetType].createPreset,
    params: { id: assetId },
  };
}

export function applyPresetLocation(
  assetType: AssetType,
  assetId: UUID,
  isOperationalSupportUser: boolean = false
): Location {
  return {
    name: isOperationalSupportUser
      ? overviewOperationalDashboardRouteNames[assetType]?.applyPreset
      : overviewRouteNames[assetType].applyPreset,
    params: { id: assetId },
  };
}
