<script lang="ts">
import { UserModule } from '@/store/modules/user';
import Sortable from 'sortablejs';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

@Component({
  name: 'UtilTable',
})
export default class extends Vue {
  @Prop() tableList!: any[];
  @Prop() cols!: [];
  @Prop() total!: number;
  @Prop() path!: string;

  value = '';
  list: any = [...this.tableList];
  dragCols: any = [...this.cols];
  colChange: any = [];

  pageSize = UserModule.gridPageSize;
  listQuery = {
    page: 1,
    limit: UserModule.gridPageSize,
  };

  @Watch('tableList')
  onListChange(value: any) {
    this.list = [...this.tableList];
  }
  get activeCols() {
    this.colChange = this.cols.filter((item: any) => {
      return item.visible;
    });

    this.dragCols = [...this.colChange];
    return [...this.colChange];
  }

  tableRowClassName(row: any, rowIndex: number) {
    if (row.row.chosen) {
      return 'choosing-row';
    }
    return '';
  }

  handleSelectionChange(val: any) {
    this.$emit('count-chosen', val.length);
    this.tableList.forEach((item: any) => {
      item.chosen = false;
    });
    val.forEach((item: any) => {
      item.chosen = true;
    });
  }

  getList(val: any) {
    this.$emit('handle-page', val.page, this.pageSize);
  }

  /**
   * Verify if row has the roleCode then show icon
   * @param index
   * @param roleCode
   */
  handleModuleAccessIconRendering(index: number, roleCode: string) {
    return this.list[index].claims
      ? this.list[index].claims.some((e: any) => e.resource == roleCode)
      : false;
  }

  columnDrop() {
    const theader: any = document.querySelector('.el-table__header-wrapper tr');

    Sortable.create(theader, {
      animation: 180,
      delay: 0,
      onEnd: (evt: any) => {
        const oldItem = this.dragCols[evt.oldIndex];
        this.dragCols.splice(evt.oldIndex, 1);
        this.dragCols.splice(evt.newIndex, 0, oldItem);
      },
    });
  }

  mounted() {
    this.columnDrop();
  }
}
</script>

<template>
  <div>
    <el-table
      :data="list"
      row-key="id"
      :row-style="{ height: '40px' }"
      :cell-style="{ padding: '7px 0px' }"
      @selection-change="handleSelectionChange"
      :row-class-name="tableRowClassName"
    >
      <el-table-column type="selection" width="55"> </el-table-column>

      <el-table-column
        v-for="(col, index) in activeCols"
        :key="`col_${index}`"
        :prop="dragCols[index].prop"
        min-width="180"
        show-overflow-tooltip
      >
        <template v-slot:header>
          <div class="d-flex jc-between">
            <span style="white-space: nowrap">{{ $t(col.label) }}</span>
          </div>
        </template>

        <!-- translate language -->
        <template v-slot="slotProps" v-if="col.multiLang">
          <span>{{ $t(slotProps.row[col.prop]) }}</span>
        </template>
      </el-table-column>
    </el-table>

    <el-popover ref="popover" placement="bottom" trigger="click">
      <el-checkbox
        v-for="(item, index) in cols"
        :key="index"
        :label="
          /* @ts-expect-error TODO Wrong type */
          $t(item.label)
        "
        v-model="
          /* @ts-expect-error TODO Wrong type */
          item.visible
        "
        :disabled="
          /* @ts-expect-error TODO Wrong type */
          item.required
        "
        style="display: block; color: #373e41 !important"
      ></el-checkbox>
    </el-popover>
  </div>
</template>

<style lang="scss" scoped>
:deep(.choosing-row) {
  background: #ffeb99 !important;
}

:deep(.el-table th .el-checkbox__input) {
  display: none;
}

:deep(.el-checkbox__input.is-checked .el-checkbox__inner) {
  background-color: #ffcd00 !important;
  border-color: #848484 !important;
  &:after {
    border: none;
  }
}
</style>
