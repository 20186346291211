<script lang="ts">
import { ElTree } from 'element-ui/types/tree';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

@Component({
  name: 'TreeTransfer',
})
export default class extends Vue {
  @Prop() title!: string;
  @Prop() confirmBtnName!: string;
  @Prop({ default: false }) visible!: boolean;
  @Prop() listTitles!: Array<any>;
  @Prop() data!: { selectedData: Array<string>; tree: any };
  @Prop({ default: ' assets' }) numberOfAssetsLabel!: string;

  unwatch: any = null;

  tree1Count = 0;
  tree2Count = 0;

  @Watch('visible')
  visibleChanged(newValue: any, oldValue: any) {
    this.$nextTick(() => {
      const tree1 = this.$refs.tree1 as ElTree<HTMLElement, any>;
      const tree2 = this.$refs.tree2 as ElTree<HTMLElement, any>;

      tree1.filter(false);
      tree2.filter(true);
      [this.tree1Count, this.tree2Count] = this.countCustomers();
    });
  }

  countCustomers(): any {
    let selectedData = this.data.selectedData;
    let selectedCount = this.data.tree.reduce(
      (count: number, node: any) =>
        count + (this.anySelected(node, selectedData) ? 1 : 0),
      0
    );
    return [this.data.tree.length - selectedCount, selectedCount];
  }

  anySelected(node: any, selectedList: any[]) {
    if (selectedList.includes(node.id)) {
      return true;
    }
    for (let child of node.children) {
      if (this.anySelected(child, selectedList)) {
        return true;
      }
    }
    return false;
  }

  shouldHighlight(id: any) {
    return this.data.selectedData.includes(id);
  }

  beforeUnmount() {
    if (this.unwatch) {
      this.unwatch();
    }
  }

  closeDialog() {
    this.$emit('handle-cancel');
  }

  handleDialogCancel() {
    this.$emit('handle-cancel');
  }

  handleConfirm() {
    this.$emit('handle-confirm');
  }

  moveRight() {
    const tree1 = this.$refs.tree1 as ElTree<HTMLElement, any>;
    const tree2 = this.$refs.tree2 as ElTree<HTMLElement, any>;

    let nodes: Array<any> = tree1.getCheckedNodes();
    this.data.selectedData.push(...nodes.map((node) => node.id));

    tree1.filter(false);
    tree2.filter(true);
    tree1.setCheckedKeys([], true);
    [this.tree1Count, this.tree2Count] = this.countCustomers();
  }

  moveLeft() {
    const tree1 = this.$refs.tree1 as ElTree<HTMLElement, any>;
    const tree2 = this.$refs.tree2 as ElTree<HTMLElement, any>;
    let nodes: Array<string> = tree2
      .getCheckedNodes()
      .map((node: { id: any }) => node.id);
    this.data.selectedData = this.data.selectedData.filter(
      (id: string) => !nodes.includes(id)
    );

    tree1.filter(false);
    tree2.filter(true);
    tree2.setCheckedKeys([], true);
    [this.tree1Count, this.tree2Count] = this.countCustomers();
  }

  filterTreeNode(value: any, node: any) {
    return this.data.selectedData.includes(node.id) == value;
  }
}
</script>

<template>
  <el-dialog
    class="widget-dialog tree-transfer-dialog"
    :title="title"
    :visible="visible"
    center
    width="90%"
    @close="closeDialog"
  >
    <div class="tree-transfer">
      <div class="tree-container">
        <span class="tree-title">{{ listTitles[0] }} ({{ tree1Count }}) </span>
        <el-tree
          class="tree"
          :data="data.tree"
          show-checkbox
          v-on:check-change="
            () => {
              /* @ts-expect-error TODO Unknown type */
              this.selectAllCheckbox1 = false;
            }
          "
          default-expand-all
          node-key="id"
          ref="tree1"
          check-on-click-node
          accordion
          :filter-node-method="filterTreeNode"
        >
          <div
            class="el-tree-node__label custom-tree-element"
            slot-scope="{ data }"
          >
            {{
              /* @ts-expect-error TODO Wrong type */
              data.label
            }}
            <span
              class="asset-count"
              v-if="
                /* @ts-expect-error TODO Wrong type */
                data.value
              "
            >
              {{
                /* @ts-expect-error TODO Wrong type */
                data.value
              }}
              {{ numberOfAssetsLabel }}</span
            >
          </div>
        </el-tree>
      </div>
      <div class="buttons">
        <el-button v-on:click="moveRight"
          ><i class="el-icon-right thick-arrow"></i
        ></el-button>
        <el-button v-on:click="moveLeft"
          ><i class="el-icon-back thick-arrow"></i
        ></el-button>
      </div>
      <div class="tree-container">
        <span class="tree-title">{{ listTitles[1] }} ({{ tree2Count }})</span>
        <el-tree
          class="tree"
          v-on:check-change="
            /* @ts-expect-error TODO Wrong type */
            selectAllCheckbox2 = false
          "
          :data="data.tree"
          show-checkbox
          default-expand-all
          node-key="id"
          ref="tree2"
          check-on-click-node
          :filter-node-method="filterTreeNode"
        >
          <div
            class="el-tree-node__label custom-tree-element"
            slot-scope="{ data }"
            :class="
              /* @ts-expect-error TODO Wrong type */
              shouldHighlight(data.id) ? 'highlight' : ''
            "
          >
            {{
              /* @ts-expect-error TODO Wrong type */
              data.label
            }}
            <span
              class="asset-count"
              v-if="
                /* @ts-expect-error TODO Wrong type */
                data.value != null && data.value != undefined
              "
            >
              {{
                /* @ts-expect-error TODO Wrong type */
                data.value
              }}
              {{ numberOfAssetsLabel }}</span
            >
          </div>
        </el-tree>
      </div>
    </div>

    <span slot="footer" class="dialog-footer">
      <el-button
        class="widget-dialog-btn"
        type="plain"
        @click="handleDialogCancel"
        >{{ $t('common.cancelBtn') }}</el-button
      >
      <el-button class="widget-dialog-btn" type="info" @click="handleConfirm">{{
        confirmBtnName
      }}</el-button>
    </span>
  </el-dialog>
</template>

<style>
.tree-transfer-dialog .tree-transfer {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}
.tree-transfer-dialog .tree-transfer button {
  margin: 1rem;
  width: fit-content;
  border-radius: 100%;
  padding: 0.5rem;
}
.tree-transfer-dialog .tree-transfer .buttons {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.tree-transfer-dialog .tree-transfer .tree-container {
  max-height: 35vh;
  width: 100%;
  height: 100%;
}
.tree-transfer-dialog .tree {
  margin-top: 1rem;
  border: solid 1px rgba(55, 62, 65, 0.4);
  border-radius: 10px;
  font-size: normal;
  overflow-y: auto;
  max-height: 35vh;
}

.tree-transfer-dialog .highlight {
  background-color: rgba(145, 200, 255, 0.2);
  padding-left: 1rem;
  padding-right: 1rem;
  border-radius: 0.125rem;
}

.tree-transfer-dialog .custom-tree-element {
  width: 100%;
  padding-left: 1rem;
  padding-right: 1rem;
}

.tree-transfer-dialog .asset-count {
  float: right;
}

.tree-transfer-dialog .tree-title {
  font-size: larger;
  font-weight: bolder;
}

.tree-transfer-dialog {
  border-radius: 8px !important;
}

.tree-transfer-dialog .el-dialog__header {
  background: rgb(255, 205, 0);
  border-radius: 8px;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
  padding: 20px;
}
.tree-transfer-dialog .el-dialog__title {
  font-size: 24px;
  font-weight: 700;
}
.tree-transfer-dialog .el-dialog__headerbtn {
  font-size: 2rem;
  line-height: 2rem;
}
.tree-transfer-dialog .widget-dialog-btn {
  padding-left: 3rem;
  padding-right: 3rem;
  margin-left: 1rem;
  margin-right: 1rem;
}

.tree-transfer-dialog .el-tree-node__content {
  padding: 16px 30px 16px 80px;
}

.tree-transfer-dialog .thick-arrow {
  transform: scale(1.5);
  color: white;
}

.tree-transfer-dialog .buttons > button {
  background: #373e41;
}

.tree-transfer-dialog .buttons > button:focus,
.tree-transfer-dialog .buttons > button:hover {
  background: #373e41;
  opacity: 40%;
  border-color: #373e41;
}
.tree-transfer-dialog .el-dialog {
  border-radius: 8px;
}

.tree-container,
.tree {
  height: 30vh;
}
</style>
