<script lang="ts" setup>
import { unref } from 'vue';
import Dialog from '@/components/layout/widget/Dialog.vue';
import { GridLayout, GridItem } from 'vue-grid-layout';
import { computed, Ref, ref, watch } from 'vue';
import { useFetch } from '@/composables/fetch';
import { GenericWidget, getWidgetsByPageCode } from '@/api/widgets';
import { updateUserWidgets } from '@/api/widgets';
import { IncludedWidget } from '@/api/widgets';
import Card from '@/components/layout/widget/Card.vue';
import {
  calculateWidgetGridLayout,
  filterWidgetsByPermissions,
  scaleHorizontal,
  NUMBER_OF_GRID_COLUMNS,
} from '@/utils/widget';
import { UserModule } from '@/store/modules/user';
import { useLoggedInUser } from '@/auth/user';

const props = defineProps<{
  visible: boolean;
  pageCode: string;
  columns: number;
  widgets: IncludedWidget[];
}>();
const emit = defineEmits<{
  (e: 'update', widgets: IncludedWidget[]): void;
  (e: 'close'): void;
}>();

const propWidgetsScaled = computed(() =>
  scaleHorizontal(props.widgets, NUMBER_OF_GRID_COLUMNS, props.columns)
);

const loggedInUser = useLoggedInUser();
const { data, loading, error } = useFetch(
  getWidgetsByPageCode(props.pageCode, unref(loggedInUser))
);
const allWidgetsScaled: Ref<GenericWidget[]> = computed(() =>
  scaleHorizontal(
    filterWidgetsByPermissions(data.value ?? []),
    NUMBER_OF_GRID_COLUMNS,
    props.columns
  )
);

/**
 * layoutScaled is in scaled down units, e.g. if props.columns is 4, layoutScaled.x is in the range [0,2]
 */
const layoutScaled: Ref<IncludedWidget[]> = ref<IncludedWidget[]>([]);

const selectedWidgets = computed({
  get: () => layoutScaled.value.map((w) => w.code),
  set: (value) => {
    layoutScaled.value = calculateWidgetGridLayout(
      allWidgetsScaled.value,
      layoutScaled.value,
      value,
      {
        columns: props.columns,
        columnWidth: 1,
      }
    );
  },
});

watch(
  () => props.visible,
  (next) => {
    if (next) {
      layoutScaled.value = propWidgetsScaled.value.map((w) => ({ ...w }));
    }
  },
  { immediate: true }
);

function updateLayout() {
  loading.value = true;

  const layout = scaleHorizontal(
    layoutScaled.value,
    props.columns,
    NUMBER_OF_GRID_COLUMNS
  );
  updateUserWidgets(UserModule.id, layout)
    .then(() => {
      emit('update', layout);
      emit('close');
    })
    .catch((e) => (error.value = e.message))
    .finally(() => (loading.value = false));
}
</script>

<template>
  <Dialog
    :visible="props.visible"
    :title="$tc('route.dashboardConf')"
    @cancel="emit('close')"
    @confirm="updateLayout"
  >
    <template v-if="error">
      {{ error }}
    </template>
    <template v-else>
      <div class="columns" v-loading="loading">
        <div class="widgets">
          <span class="widgets-title">
            {{ $t('dashboardConf.widgets') }}
          </span>
          <el-checkbox
            v-for="widget of allWidgetsScaled"
            v-model="selectedWidgets"
            :key="widget.code"
            :label="widget.code"
          >
            {{ $te(widget.code) ? $t(widget.code) : widget.name }}
          </el-checkbox>
        </div>
        <GridLayout
          :layout.sync="layoutScaled"
          :col-num="props.columns"
          :row-height="64"
        >
          <GridItem
            v-for="item of layoutScaled"
            :key="item.code"
            :i="item.i"
            :x="item.x"
            :y="item.y"
            :w="item.w"
            :h="item.h"
            :minW="item.minW"
            :minH="item.minH"
            :maxW="item.maxW"
            :maxH="item.maxH"
            :isResizable="item.isResizable"
          >
            <Card>
              <template slot="header">
                <div class="card-header">
                  {{ $te(item.code) ? $t(item.code) : item.name }}
                </div>
              </template>
            </Card>
          </GridItem>
        </GridLayout>
      </div>
    </template>
  </Dialog>
</template>

<style lang="scss" scoped>
.columns {
  display: flex;
}

.widgets {
  display: flex;
  gap: 0.5rem;
  flex-direction: column;

  &-title {
    margin-bottom: 0.5rem;
    font-size: 1.125rem;
  }
}

.vue-grid-layout {
  flex: 1;
  min-height: 84px;
  background-color: #e4e4e4;
  border-radius: 16px;
}

:deep(.vue-grid-placeholder) {
  display: none;
}

:deep(.vue-resizable-handle) {
  width: 0.75rem;
  height: 0.75rem;
  padding: 0;
  margin: 0.25rem;
  background: none;
  border: 2px solid #9f9f9f;
  border-top: none;
  border-left: none;
  border-bottom-right-radius: 6px;
}

.card-header {
  margin: 0.5rem 0;
  font-weight: 500;
}
</style>
