<script lang="ts">
import {
  FvtAccessControlOption,
  SUBSCRIPTION_PACKAGE_TIME_UNITS,
  VALUE_TYPE,
} from '@/utils/workData/lookuptable';
import { SystemFeatureValue } from '@/views/subscriptionPackages/CreatePackage.vue';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

@Component({
  name: 'FeaturesTable',
})
export default class extends Vue {
  @Prop() tableList!: SystemFeatureValue[];
  @Prop() cols!: [];
  @Prop() treeProp!: any;
  @Prop() isEditable!: boolean;

  list: SystemFeatureValue[] = [...this.tableList];
  colChange: any = [];
  timeUnitList: any = SUBSCRIPTION_PACKAGE_TIME_UNITS;
  VALUE_TYPE = VALUE_TYPE;

  handleInputFieldEvent() {
    this.list = [...this.tableList];
  }

  accessControlOptions = Object.values(FvtAccessControlOption).map(
    (value, idx) => ({
      id: idx.toString(),
      label: this.$t(value),
      value: value,
    })
  );

  booleanValues = [
    {
      id: '33e73e52-1aed-433c-8e82-64b7142ef230',
      label: this.$t('subscriptionPackages.enabled'),
      value: 'true',
    },
    {
      id: '45db2638-2d03-43ed-9ac4-7ac2f5b1a531',
      label: this.$t('subscriptionPackages.disabled'),
      value: 'false',
    },
  ];

  @Watch('tableList')
  onListChange(value: any) {
    this.list = [...this.tableList];
  }

  /**
   * Set index as parameter to row HTMLelement for table
   * @param row
   * @param index
   */
  tableRowClassName(row: any, index: any) {
    row.index = index;
  }

  get activeCols() {
    this.colChange = this.cols.filter((item: any) => {
      return item.visible;
    });

    return [...this.colChange];
  }
}
</script>

<template>
  <div>
    <div v-if="list.length > 0">
      <el-table
        :data="list"
        row-key="id"
        :row-style="{ height: '25px' }"
        :cell-style="{ padding: '7px 0px' }"
        ref="sortTable"
        :tree-props="treeProp"
        :row-class-name="tableRowClassName"
        style="min-height: 180px"
      >
        <el-table-column
          v-for="(col, index) in activeCols"
          :key="`col_${index}`"
          min-width="180"
          :width="col.prop === 'code' ? 320 : 240"
        >
          <template v-slot:header>
            <div class="d-flex jc-between">
              <span style="white-space: nowrap">{{ $t(col.label) }}</span>
            </div>
          </template>

          <template v-slot="slotProps" v-if="col.prop === 'code'">
            {{ $t(`subscriptionPackages.${slotProps.row.code}`) }}
            <span
              style="color: red; margin-left: 5px"
              v-if="slotProps.row.isRequired"
              >*</span
            >
          </template>

          <template v-slot="slotProps" v-else-if="col.prop === 'value'">
            <div
              v-if="slotProps.row.valueType === VALUE_TYPE.FvtDuration"
              id="timerangeinputs"
              class="time-range-inputs"
            >
              <el-input
                :id="'featuredatevalue_' + ++slotProps.$index"
                class="range-input"
                :disabled="!isEditable"
                v-model="slotProps.row.value"
                :placeholder="$t('subscriptionPackages.valuePlaceholder')"
                maxlength="2"
                onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                clearable
              />
              <el-select
                :id="'featurebooleanvalue_' + ++slotProps.$index"
                :disabled="!isEditable"
                class="range-units-dropdown"
                v-model="slotProps.row.unit"
                :placeholder="$t('subscriptionPackages.unit')"
                clearable
              >
                <el-option
                  v-for="item in timeUnitList"
                  :key="item.id"
                  :label="
                    slotProps.row.value > 1
                      ? `${$t(item.value)}s`
                      : $t(item.value)
                  "
                  :value="item.value"
                />
              </el-select>
            </div>
            <el-input
              v-else-if="
                slotProps.row.valueType === VALUE_TYPE.FvtNumber ||
                slotProps.row.valueType === VALUE_TYPE.FvtString
              "
              :id="'featureinputvalue_' + ++slotProps.$index"
              :disabled="!isEditable"
              class="input-container"
              v-model="slotProps.row.value"
              @change="handleInputFieldEvent"
              v-bind:placeholder="
                $t('subscriptionPackages.enterValuePlaceholder')
              "
            ></el-input>
            <el-select
              v-else-if="slotProps.row.valueType === VALUE_TYPE.FvtBoolean"
              :id="'featurebooleanvalue_' + ++slotProps.$index"
              :disabled="!isEditable"
              class="boolean-input-container"
              v-model="slotProps.row.value"
              @change="handleInputFieldEvent"
              v-bind:placeholder="
                $t('subscriptionPackages.enterValuePlaceholder')
              "
            >
              <el-option
                v-for="item in booleanValues"
                :key="item.id"
                :label="item.label"
                :value="item.value"
              />
            </el-select>
            <el-select
              v-else-if="
                slotProps.row.valueType === VALUE_TYPE.FvtAccessControl
              "
              :disabled="!isEditable"
              class="boolean-input-container"
              v-model="slotProps.row.value"
              @change="handleInputFieldEvent"
              v-bind:placeholder="
                $t('subscriptionPackages.enterValuePlaceholder')
              "
            >
              <el-option
                v-for="item in accessControlOptions"
                :key="item.id"
                :label="item.label"
                :value="item.value"
              />
            </el-select>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div v-else class="no-features-found">
      {{ $t('subscriptionPackages.noFeatures') + '.' }}
    </div>
  </div>
</template>

<style scoped>
.el-table tbody tr:hover > td {
  /* background-color: rgb(var(--Main), 0.6) !important; */
  background-color: var(--Main) !important;
  /* opacity: 0.5; */
  /* cursor: pointer; */
}

.el-table__body-wrapper {
  overflow: hidden !important;
}

.el-table th {
  border-top: 1px solid #dddddd;
  border-bottom: 1px solid #dddddd !important;
}

.el-table td {
  border-bottom: 1px solid #dddddd !important;
}

.el-table {
  font-size: 14px !important;
  font-family: var(--fontRobotoRegular);
  line-height: 16px;
  color: #373e41 !important;
  opacity: 1;
}

:deep(.cell) {
  display: flex;
}

.el-table thead {
  font-size: 14px !important;
  font-family: var(--fontRobotoMedium);
  line-height: 16px;
  color: #373e41 !important;
  opacity: 1;
}

.el-table {
  background: white !important;
}

.time-range-inputs {
  display: flex;
}

.range-input :deep(.el-input__inner) {
  width: 100px !important;
  height: 40px;
}

.range-input {
  width: 105px !important;
}

.range-units-dropdown :deep(.el-input__inner) {
  width: 120px !important;
  margin-left: 5px;
  height: 40px !important;
}

.input-container :deep(.el-input__inner) {
  width: 225px !important;
}

.boolean-input-container :deep(.el-input__inner) {
  width: 225px !important;
}
</style>

<style lang="scss" scoped>
.el-icon-arrow-down {
  font-size: 14px;
  border: none;
  background-color: transparent;
  outline: none;
  cursor: pointer;
}

.el-checkbox.is-disabled.is-checked {
  background-color: #e4e6e7;
}

.el-checkbox {
  margin-right: 0px;
}

.no-features-found {
  text-align: center;
  margin-top: 25%;
  font-weight: bold;
  font-size: 16px;
  color: red;
}
</style>
