import { QueryParameter } from '@/model/queryParameters/QueryParameter';
import { api } from '@/utils/api';
import { LANGUAGE } from '@/utils/workData/lookuptable';
import { UUID } from './common';

export interface UnreadNotificationApiResponse {
  unreadNotifications: number;
}

export enum NotificationCode {
  OrderRenewal = 'NOTDEF_ORDER_RENEWAL',
  CustomerAdminOnboarding = 'NOTDEF_CUSTOMER_ADMIN_ONBOARDING',
  NewUserByCustomerAdmin = 'NOTDEF_NEW_USER_BY_CUST_ADMIN',
  DeactivationByCustomerAdmin = 'NOTDEF_DEACTIVATION_BY_CUST_ADMIN',
  NewAssetOnboarded = 'NOTDEF_NEW_ASSET_ONBOARDED',
  NewAssetConnected = 'NOTDEF_NEW_ASSET_CONNECTED',
  NewAssetRegistered = 'NOTDEF_NEW_ASSET_REGISTERED',
  NewSystemRelease = 'NOTDEF_NEW_SYSTEM_RELEASE_AVAILABLE',
  NewMaintenancePlan = 'NOTDEF_NEW_MAINTENANCE_PLAN',
  ConductMaintenancePlan = 'NOTDEF_CONDUCT_A_MAINTENANCE_PLAN',
  RescheduleMaintenancePlan = 'NOTDEF_RESCHEDULE_A_MAINTENANCE_PLAN',
  AlarmEvent = 'NOTDEF_ALARM_EVENT',
  WarningEvent = 'NOTDEF_WARNING_EVENT',
  SafetyEvent = 'NOTDEF_SAFETY_EVENT',
  ErrorCodeEvent = 'NOTDEF_ERROR_CODE_EVENT',
  NewUserByHyva = 'NOTDEF_NEW_USER_BY_HYVA',
  DeactivationByHyva = 'NOTDEF_DEACTIVATION_BY_HYVA',
  UserActivation = 'NOTDEF_USER_ACTIVATION',
  NewOneEMSVersionDeployed = 'NOTDEF_NEW_ONEEMS_VERSION_DEPLOYED',
  MaintenanceDowntimeUpdate = 'NOTDEF_MAINTENANCE_DOWNTIME_UPGRADE',
}

export interface Notification {
  userNotificationId: UUID;
  notificationCode: NotificationCode;
  description: string;
  notificationTime: string;
  markedAsRead: boolean;
}

export interface DetailedNotification extends Notification {
  details: string;
}

export interface NotificationResponse {
  notifications: Notification[];
  totalUnread: number;
  size: number;
  total: number;
}

export interface NotificationStatusUpdate {
  notificationIds: UUID[];
  markedAsRead?: boolean;
}

export interface NotificationStatusUpdateResponse {
  notificationIds: UUID[];
}

export type Details = Partial<Record<LANGUAGE, string>>;

export interface ParameterValue {
  v: string;
}

export type Parameters = { [key: string]: ParameterValue };

export interface NewNotificationEmission {
  notificationCode: NotificationCode;
  parameters?: Parameters;
  details?: Details;
}

export async function getNumberOfUnreadNotifications(
  userId: UUID
): Promise<UnreadNotificationApiResponse> {
  return await api().get<UnreadNotificationApiResponse>(
    `/user-notifications/dashboard/${userId}/count-unread`
  );
}

export async function getNotificationsOfUser(
  userId: UUID,
  queryParams: QueryParameter
): Promise<NotificationResponse> {
  return await api().post<NotificationResponse, QueryParameter>(
    `/user-notifications/dashboard/${userId}`,
    queryParams
  );
}

export async function updateNotificationStatuses(
  userId: UUID,
  notificationsToUpdate: NotificationStatusUpdate
): Promise<NotificationStatusUpdateResponse> {
  return await api().put<
    NotificationStatusUpdateResponse,
    NotificationStatusUpdate
  >(
    `/user-notifications/dashboard/${userId}/read-status`,
    notificationsToUpdate
  );
}

export async function emitNewNotification(
  newNotification: NewNotificationEmission
): Promise<NewNotificationEmission> {
  return await api().post<NewNotificationEmission, NewNotificationEmission>(
    '/user-notifications/emit',
    newNotification
  );
}

export async function getNotificationById(
  id: UUID,
  i18nCode?: string
): Promise<DetailedNotification> {
  return await api().get<DetailedNotification>(`/user-notifications/${id}`, {
    params: { i18nCode },
  });
}
