import { useLoggedInUser } from '@/auth/user';
import { FilterKeys } from '@/utils/types';
import {
  convertRawNumber,
  convertRawNumberMany,
  convertUnitObjectMany,
  convertUnitValue,
  convertUnitValueMany,
  userPreferredUnit,
} from '@/utils/units/conversion';
import { KPI_UNIT } from '@/utils/units/unitDefinitions';
import { UnitValue } from '@/utils/units/unitValue';

export type UserPreferredUnitGetter = (fromMetricUnit: KPI_UNIT) => KPI_UNIT;

export type UserUnitValueConverter = <T extends UnitValue<number | undefined>>(
  unitValue: T
) => T;

export type ManyPropertyConverter = <T extends object>(
  object: T,
  keys: FilterKeys<T, number | undefined>[],
  fromMetricUnit: KPI_UNIT
) => T;

export type NumberConverter = (value: number, metricUnit: KPI_UNIT) => number;

export interface UseUnitConversionReturnType {
  currentUserPreferredUnit: UserPreferredUnitGetter;
  currentUserConvertUnitValue: UserUnitValueConverter;
  currentUserConvertNumber: NumberConverter;
  currentUserConvertNumberMany: ManyPropertyConverter;
  currentUserConvertUnitObjectMany<T extends { unit: KPI_UNIT }>(
    object: T,
    keys: FilterKeys<T, number | undefined>[]
  ): T;
  currentUserConvertUnitValueMany<T extends UnitValue<number | undefined>>(
    object: T,
    additionalKeys: FilterKeys<T, number | undefined>[]
  ): T;
  hasCustomPreferredUnit: (metricUnit: KPI_UNIT) => boolean;
}

export function useUnitConversion(): UseUnitConversionReturnType {
  const loggedInUser = useLoggedInUser();

  function currentUserPreferredUnit(fromMetricUnit: KPI_UNIT): KPI_UNIT {
    if (!loggedInUser.value?.unitMapping) {
      return fromMetricUnit;
    }

    return userPreferredUnit(fromMetricUnit, loggedInUser.value.unitMapping);
  }

  function hasCustomPreferredUnit(unit: KPI_UNIT): boolean {
    if (!loggedInUser.value?.unitMapping) {
      return false;
    }

    return loggedInUser.value.unitMapping[unit] !== undefined;
  }

  return {
    currentUserPreferredUnit,
    currentUserConvertUnitValue: (metricUnitValue) =>
      convertUnitValue(
        metricUnitValue,
        currentUserPreferredUnit(metricUnitValue.unit)
      ),
    currentUserConvertNumber: (value, metricUnit) =>
      convertRawNumber(value, metricUnit, currentUserPreferredUnit(metricUnit)),
    currentUserConvertNumberMany: (object, keys, fromMetricUnit) =>
      convertRawNumberMany(
        object,
        keys,
        fromMetricUnit,
        currentUserPreferredUnit(fromMetricUnit)
      ),
    currentUserConvertUnitObjectMany: (object, keys) =>
      convertUnitObjectMany(
        object,
        keys,
        currentUserPreferredUnit(object.unit)
      ),
    currentUserConvertUnitValueMany: (object, additionalKeys) =>
      convertUnitValueMany(
        object,
        additionalKeys,
        currentUserPreferredUnit(object.unit)
      ),
    hasCustomPreferredUnit,
  };
}
