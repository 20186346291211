import { api } from '@/utils/api';

/**
 * Represent a Hyva Region code that has prefix `HYVARGN_` (e.g. `HYVARGN_ROA`, `HYVARGN_EMEA`)
 */
export type HyvaRegionCode = string;

export interface HyvaRegion {
  code: HyvaRegionCode;
  name: string;
}

export async function getHyvaRegions(): Promise<HyvaRegion[]> {
  return await api().get<HyvaRegion[]>('/hyva-regions');
}
