import { UserModule } from '@/store/modules/user';
import { getRequest } from '@/utils/request';
import { CommonResultPromise } from './commonResult';

export function getSystemReleases(id: string): CommonResultPromise {
  const request = getRequest();
  const response = request.request({
    url: `/system-releases/${id}`,
    method: 'get',
  });
  return response;
}

export function getSystemReleaseNotes(id: string): CommonResultPromise<string> {
  const request = getRequest();
  const response = request.request({
    url: `/system-releases/${id}/release-notes/${UserModule.i18nCode}`,
    method: 'get',
  });

  return response;
}

export function getFirmwareReleaseNotes(
  systemId: string,
  firmwareId: string
): CommonResultPromise<string> {
  const request = getRequest();
  const response = request.request({
    url: `/system-releases/${systemId}/firmware-releases/${firmwareId}/release-notes/${UserModule.i18nCode}`,
    method: 'get',
  });

  return response;
}

export function getFilteredReleases(data: {
  filters: any[];
  pagination: { page: number; size: number };
}): CommonResultPromise<SystemReleaseResponse> {
  const request = getRequest();
  const response = request.post<SystemReleaseResponse>('system-releases', data);
  return response;
}

export function getSystemReleaseAssets(query: {
  filters: any[];
  pagination: { page: number; size: number };
}): CommonResultPromise {
  const request = getRequest();
  const response = request.request({
    url: '/system-releases/assets',
    method: 'post',
    data: query,
  });
  return response;
}

export function getReleaseById(id: string): CommonResultPromise {
  const request = getRequest();
  const response = request.request({
    url: `/system-releases/${id}`,
    method: 'post',
  });
  return response;
}

export function productSystemRelease(data: object): CommonResultPromise {
  const request = getRequest();
  const response = request.request({
    url: '/system-releases',
    method: 'post',
    data: data,
  });
  return response;
}

export function productSystemReleasesByID(id: string): CommonResultPromise {
  const request = getRequest();
  const response = request.request({
    url: `/system-releases/${id}`,
    method: 'get',
  });
  return response;
}

export function getCustomers(
  data: any
): CommonResultPromise<CustomersResponse> {
  const request = getRequest();
  const response = request.post<CustomersResponse>(
    '/system-releases/customers',
    data
  );
  return response;
}

export function updateSystemRelease(
  id: string,
  payload: any
): CommonResultPromise {
  const request = getRequest();
  const response = request.request({
    url: `/system-releases/${id}`,
    method: 'put',
    data: payload,
  });
  return response;
}

export function deprecateSystemRelease(id: string): CommonResultPromise {
  const request = getRequest();
  const response = request.request({
    url: `/system-releases/${id}/deprecate`,
    method: 'put',
    data: null,
  });
  return response;
}

export function activateSystemRelease(
  id: string,
  data: { defaultRelease: boolean; linkedOrganizations?: string[] }
): CommonResultPromise {
  const request = getRequest();
  const response = request.request({
    url: `/system-releases/${id}/activate`,
    method: 'put',
    data: data,
  });
  return response;
}

export function getDeployments(
  requestBody: any,
  request = getRequest()
): CommonResultPromise<DeploymentsResponse> {
  const response = request.post<DeploymentsResponse>(
    'system-deployments',
    requestBody
  );
  return response;
}

export function getFinishedDeployments(
  requestBody: any
): CommonResultPromise<FinishedDeploymentResponse> {
  const request = getRequest();
  const response = request.post<FinishedDeploymentResponse>(
    '/system-deployments/history',
    requestBody
  );
  return response;
}

export function getPendingUpdates(
  data: any
): CommonResultPromise<PendingUpdatesResponse> {
  const request = getRequest();
  const response = request.post<PendingUpdatesResponse>(
    '/system-deployments/pending-updates',
    data
  );
  return response;
}

export function startNewRelease(data: any): CommonResultPromise {
  const request = getRequest();
  const response = request.request({
    url: '/system-deployments:start',
    method: 'put',
    data: data,
  });
  return response;
}

export function getAssetListForSpecifiedRelease(
  data: any
): CommonResultPromise {
  const request = getRequest();
  const response = request.request({
    url: '/system-releases/assets',
    method: 'post',
    data: data,
  });
  return response;
}

export interface PendingUpdatesResponse {
  pendingUpdates: PendingUpdate[];
  size: number;
  total: number;
}

export interface PendingUpdate {
  id: string;
  assetType: string;
  assetUUID: string;
  companyAssetId: string;
  currentSystemReleaseId: string;
  currentSystemReleaseUUID: string;
  newSystemReleaseId: string;
  newSystemReleaseUUID: string;
  newSystemReleaseVersion: string;
  organizationId: string;
  organizationName: string;
  productModelCode: string;
  productModelNumber: string;
  lastCommunicationTime?: string;
  opreationalStatus?: string;
}

export interface SystemReleaseResponse {
  systemReleases: SystemRelease[];
  size: number;
  total: number;
}
export interface SystemRelease {
  id: string;
  assetType: string;
  defaultRelease: boolean | string;
  deploymentDurationMinutes: number;
  manifestCreationTime: string;
  maturityLevel: string;
  numberOfLinkedCustomers: number;
  numberOfLinkedOrganizations: number;
  productModelCode: string;
  productModelNumber: string;
  region: string;
  releaseStatus: string;
  systemReleaseId: string;
  systemReleaseName: string;
  systemReleaseVersion: string;
  deploymentDuration?: string;
}

export interface FinishedDeploymentResponse {
  deployments: FinishDeployment[];
  size: number;
  total: number;
}

export interface FinishDeployment {
  id: string;
  assetType: string;
  companyId: string;
  companyName: string;
  deploymentBy: string;
  deploymentDurationInMinutes: number;
  deploymentEndTime: string;
  deploymentStartTime: string;
  deploymentStatus: string;
  noOfCancelledDeploys: number;
  noOfFailedDeploys: number;
  noOfInvolvedAssets: number;
  noOfRevertedDeploys: number;
  noOfSuccessDeploys: number;
  productModelCode: string;
  productModelNumber: string;
  successRate: number;
  systemReleaseId: string;
  totalTimeSpent?: string; // used for column that has data calculated in the UI
}

export interface DeploymentsResponse {
  deployments: Deployment[];
  size: number;
  total: number;
}

export interface Deployment {
  id: string;
  assetType: string;
  companyId: string;
  companyName: string;
  deploymentBy: string;
  deploymentStartTime: string;
  deploymentStatus: string;
  noOfInvolvedAssets: number;
  productModelCode: string;
  productModelNumber: string;
  systemReleaseId: string;
  progressPercentage?: number | string;
  defaultRelease?: boolean | string;
}

export interface CustomersResponse {
  customers: {
    customerId: string;
    customerName: string;
    numberOfAssets: number;
    organizations: {
      id: string;
      name: string;
      parentId?: string;
      numberOfAssets: number;
    }[];
  }[];
}
