<script lang="ts">
import {
  createPartMaterial,
  getPartMaterials,
  updatePartMaterial,
} from '@/api/products';
import { ErrorType } from '@/api/types';
import NewCard from '@/components/cusCard/NewCard.vue';
import CusFormItem from '@/components/form/CusFormItem.vue';
import { customFailedMessage, promptSuccessBox } from '@/utils/prompt';
import {
  MODEL_PART_TYPE_ENUM,
  MODEL_PART_TYPE_LIST,
  PRODUCT_MODEL_LIFECYCLE_ENUM,
  PROD_ERROR_CODE_LIST,
} from '@/utils/workData/lookuptable';
import { Component, Emit, Prop, Vue } from 'vue-property-decorator';

@Component({
  name: 'general',
  components: {
    'new-card': NewCard,
    'cus-form-item': CusFormItem,
  },
})
export default class extends Vue {
  @Prop({ required: false }) productModelInfo!: any;
  @Prop({ required: false }) state!: any;

  partTypeList = MODEL_PART_TYPE_LIST;
  errPartNameInfo = '';
  errPartIdInfo = '';

  id = '';

  /**
   * Backend payload structure used in v-model bindings
   */
  materialPartForm = {
    partMaterialNumber: '',
    partType: MODEL_PART_TYPE_ENUM.PartTypeHydraulic,
    name: '',
    lifeCycle: PRODUCT_MODEL_LIFECYCLE_ENUM.Draft,
  };

  errorCode = PROD_ERROR_CODE_LIST;
  errorInfos: ErrorType[] = [
    {
      code: '',
      field: '',
      message: '',
    },
  ];

  mounted() {}

  get errNameInfo() {
    let errInfo: string = '';
    if (
      this.errorInfos.find((item) => item.field === this.errorCode.partType)
        ?.code === 'ApiErrorFieldNotFound'
    ) {
      errInfo = `${this.$t('prodCata.partTypeNotFound')}`;
    }
    if (
      this.errorInfos.find((item) => item.field === this.errorCode.lifeCycle)
        ?.code === 'ApiErrorFieldNotFound'
    ) {
      errInfo = `${this.$t('prodCata.lifeCycleNone')}`;
    }
    if (
      this.errorInfos.find((item) => item.field === this.errorCode.lifeCycle)
        ?.code === 'ApiErrorCannotBeUpdated'
    ) {
      errInfo = `${this.$t('prodCata.lifeCycleUpdateError')}`;
    }
    if (
      this.errorInfos.find(
        (item) => item.field === this.errorCode.partMaterialNumber
      )?.code === 'ApiErrorFieldDuplicate'
    ) {
      errInfo = `${this.$t('prodCata.partMaterialNumberDuplicated')}`;
    }

    return errInfo === '' ? this.errorInfos[0].message : errInfo;
  }

  validatePartMaterialNumber = (rule: any, value: any, callback: any) => {
    let newReplaceValue = value.replace(/^\s\s*/, '').replace(/\s\s*$/, '');
    if (newReplaceValue.length < 1 || newReplaceValue.length > 25) {
      callback(new Error(`${this.$t('prodCata.tipInputPartNumberCorrect')}`));
    } else {
      callback();
    }
  };

  validatePartMaterialName = (rule: any, value: any, callback: any) => {
    let newReplaceValue = value.replace(/^\s\s*/, '').replace(/\s\s*$/, '');
    if (newReplaceValue.length < 1 || newReplaceValue.length > 50) {
      callback(new Error(`${this.$t('prodCata.tipInputPartNameCorrect')}`));
    } else {
      callback();
    }
  };

  //form validate rules
  get rules() {
    const tmpRules = {
      name: [
        {
          required: true,
          message: this.$t('prodCata.tipInputPartName'),
          trigger: 'change',
        },
        { validator: this.validatePartMaterialName, trigger: 'change' },
      ],
      partMaterialNumber: [
        {
          required: true,
          message: this.$t('prodCata.tipInputPartNumber'),
          trigger: 'change',
        },
        { validator: this.validatePartMaterialNumber, trigger: 'change' },
      ],
    };

    return tmpRules;
  }

  //send formdata to parent component
  @Emit('send-general-form')
  sendFormData() {
    return this.materialPartForm;
  }

  async getPartDetails(id: any) {
    this.id = id;
    const res = await getPartMaterials(id);
    if (res.code === 200) {
      this.materialPartForm.partMaterialNumber = res.data.partMaterialNumber;
      this.materialPartForm.partType = res.data.partType;
      this.materialPartForm.lifeCycle = res.data.lifeCycle;
      this.materialPartForm.name = res.data.name;
    }
  }

  async createOnePart() {
    (this.$refs.materialPartForm as any).validate(async (valid: any) => {
      if (valid) {
        const res = await createPartMaterial(this.materialPartForm);
        if (res.code === 200) {
          promptSuccessBox(this.$t('common.created') as string);
          this.errorInfos = [];
          this.$router.push('/prod-cata/index?activeName=partsWarehouse');
        } else if (res.code === 400) {
          // customFailedMessage(this.$t('common.created') as string);
          // this.errorInfos = res.data.errors[0].message;
          this.errorInfos = [...res.data.errors[0]];
          customFailedMessage(this.errNameInfo as any);
        }
      }
    });
  }

  async editOnePart() {
    (this.$refs.materialPartForm as any).validate(async (valid: any) => {
      if (valid) {
        const res = await updatePartMaterial(this.id, this.materialPartForm);
        if (res.code === 200) {
          promptSuccessBox(this.$t('common.save') as string);
          this.errorInfos = [];
          this.$router.push('/prod-cata/index?activeName=partsWarehouse');
        } else if (res.code === 400) {
          // customFailedMessage(this.$t('common.save') as string);
          // this.errorInfos = res.data.errors[0].message;
          this.errorInfos = [...res.data.errors[0]];
          customFailedMessage(this.errNameInfo as any);
        }
      }
    });
  }
}
</script>

<template>
  <el-form
    ref="materialPartForm"
    :model="materialPartForm"
    :inline-message="true"
    :rules="rules"
    style="margin-left: 13px; height: 730px"
  >
    <!-- Marerial ID -->
    <cus-form-item
      :title="$t('prodCata.partMaterialId')"
      :errContent="errPartIdInfo"
    >
      <el-form-item prop="partMaterialNumber">
        <el-input
          id="prod_cate_ware_create_id"
          v-model="materialPartForm.partMaterialNumber"
          size="medium"
          v-bind:placeholder="$t('prodCata.inputMaterialId')"
        ></el-input>
      </el-form-item>
    </cus-form-item>

    <!-- Marerial name -->
    <cus-form-item
      :title="$t('prodCata.partMaterialName')"
      :errContent="errPartNameInfo"
    >
      <el-form-item prop="name">
        <el-input
          id="prod_cate_ware_create_name"
          v-model="materialPartForm.name"
          size="medium"
          v-bind:placeholder="$t('prodCata.inputMaterialName')"
        ></el-input>
      </el-form-item>
    </cus-form-item>

    <cus-form-item :title="$t('prodCata.partType')">
      <el-form-item prop="partType">
        <el-select
          id="prod_cate_ware_create_type"
          v-model="materialPartForm.partType"
          v-bind:placeholder="$t('prodCata.selectAssetType')"
        >
          <el-option
            v-for="(item, index) in partTypeList"
            :key="index"
            :label="$t(item.label)"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </el-form-item>
    </cus-form-item>
  </el-form>
</template>

<style lang="scss" scoped>
$FormWidth: 340px;

:deep(.el-input--medium .el-input__inner) {
  width: $FormWidth;
}

:deep(.el-button) {
  border: none !important;
}

:deep(.el-button--success) {
  background-color: #47a96a;
}

.product-uploader {
  width: $FormWidth;
  height: 210px;
  background: #ffffff;
  border: 1px solid #707070;
  border-radius: 8px;
  position: relative;
}
.prod_img {
  width: $FormWidth;
  height: 210px;
  margin: -1px;
}
.el-icon-upload {
  font-size: 60px;
  position: absolute;
  width: $FormWidth;
  height: 210px;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding-top: 66px;
  margin: auto;
}
#model_create_description {
  margin: 20px 0 0 200px;
}
</style>
