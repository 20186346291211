var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(_setup.BaseCard,{ref:"baseCardRef",staticClass:"history-body",attrs:{"title":_vm.$t('AUTHRSC_PAGE_MAINT_HISTORY'),"backIconVisible":false,"showDialogBeforeBack":false,"buttons":[]},on:{"handle-back":false}},[_c('div',{staticClass:"history-header-operations"},[_c('el-input',{staticClass:"search-input",attrs:{"id":"searchinput","placeholder":_vm.$t('maintenance.searchByAssetId'),"suffix-icon":"el-icon-search","clearable":"","disabled":!_setup.performedAppointmentsListAPIResponse.data ||
        _setup.performedAppointmentsListAPIResponse.data.data
          .performedMaintenanceAppointments.length === 0},model:{value:(_setup.assetIdSearchInput),callback:function ($$v) {_setup.assetIdSearchInput=$$v},expression:"assetIdSearchInput"}}),_c(_setup.MultiAssetTypeSelectVue,{attrs:{"assetTypeList":_setup.assetTypeList,"error":_setup.assetTypeList.error ? 'ApiFieldAssetTypeCode' : undefined,"disabled":!_setup.performedAppointmentsListAPIResponse.data ||
        _setup.performedAppointmentsListAPIResponse.data.data
          .performedMaintenanceAppointments.length === 0}}),_c(_setup.MultiProductModelSelect,{directives:[{name:"show",rawName:"v-show",value:(
        !_setup.assetTypeList.data ||
        (_setup.assetTypeList.data && _setup.assetTypeList.data.every((at) => !at.selected))
      ),expression:"\n        !assetTypeList.data ||\n        (assetTypeList.data && assetTypeList.data.every((at) => !at.selected))\n      "}],attrs:{"productModelList":{
        data: undefined,
        loading: false,
        error: undefined,
      },"disabled":_setup.assetTypeList.data === undefined ||
        (_setup.assetTypeList.data && _setup.assetTypeList.data.every((at) => !at.selected))}}),_c(_setup.MultiProductModelSelect,{directives:[{name:"show",rawName:"v-show",value:(
        _setup.assetTypeList.data && _setup.assetTypeList.data.some((at) => !!at.selected)
      ),expression:"\n        assetTypeList.data && assetTypeList.data.some((at) => !!at.selected)\n      "}],attrs:{"productModelList":_setup.productModelList,"disabled":_setup.assetTypeList.data === undefined ||
        (_setup.assetTypeList.data && _setup.assetTypeList.data.every((at) => !at.selected))}}),_c(_setup.MultiMaintenanceItemSelect,{directives:[{name:"show",rawName:"v-show",value:(
        !_setup.productModelList.data ||
        (_setup.productModelList.data &&
          _setup.productModelList.data.every((at) => !at.selected))
      ),expression:"\n        !productModelList.data ||\n        (productModelList.data &&\n          productModelList.data.every((at) => !at.selected))\n      "}],attrs:{"maintenanceList":undefined,"disabled":_setup.assetTypeList.data === undefined ||
        _setup.assetTypeList.data.every((atl) => !atl.selected) ||
        _setup.productModelList.data === undefined ||
        _setup.productModelList.data.every((pml) => !pml.selected)}}),_c(_setup.MultiMaintenanceItemSelect,{directives:[{name:"show",rawName:"v-show",value:(
        _setup.productModelList.data &&
        _setup.productModelList.data.some((at) => !!at.selected)
      ),expression:"\n        productModelList.data &&\n        productModelList.data.some((at) => !!at.selected)\n      "}],attrs:{"maintenanceList":_setup.maintenanceList.data,"disabled":_setup.assetTypeList.data === undefined ||
        _setup.assetTypeList.data.every((atl) => !atl.selected) ||
        _setup.productModelList.data === undefined ||
        _setup.productModelList.data.every((pml) => !pml.selected)}}),_c(_setup.TimeSelect,{attrs:{"expanded":true,"customizable":true,"defaultLastDays":_setup.DEFAULT_MAINTENANCE_DAYS_RANGE},on:{"select":_setup.handleTimeFilter}})],1),_c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_setup.performedAppointmentsListAPIResponsePaginated.loading),expression:"performedAppointmentsListAPIResponsePaginated.loading"}],staticClass:"chart-container",attrs:{"element-loading-text":_vm.$t('common.loading')}},[_c(_setup.HistoryTable,{attrs:{"tableData":_setup.filteredMaintenanceList || [],"pageSize":_setup.pageSize,"total":_setup.total,"cols":_setup.ASSET_MAINTENANCE_HISTORY_COLS},on:{"handlePage":_setup.fetchMaintenanceListByPageSelection,"showDetailsModal":_setup.showDetailsModal,"handleSortChange":_setup.fetchMaintenanceListBySortEvent}})],1),(!!_setup.modalValues)?_c(_setup.ViewMaintenanceHistoryModal,{attrs:{"isModalVisible":!!_setup.modalValues,"title":`${_setup.modalValues?.companyAssetId} - ${_setup.modalValues?.maintenanceItemName}`,"appointment":_setup.modalValues},on:{"close":_setup.handleModalCancel}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }