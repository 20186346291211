<script lang="ts">
import {
  getAssetsOrganizationsHierarchy,
  OrganizationAssetHierarchy,
} from '@/api/assets';
import { UUID } from '@/api/common';
import { Organization } from '@/api/organizations';
import {
  GenerateGlobalReportRequestBody,
  generateReport,
  getReportTemplates,
  ReportTemplate,
} from '@/api/report';
import {
  ActiveContext,
  flattenOrganizations,
  useActiveContext,
} from '@/auth/context';
import WidgetDialog from '@/components/dialog/WidgetDialog.vue';
import CusFormItem from '@/components/form/CusFormItem.vue';
import TimeSelect from '@/components/form/TimeSelect.vue';
import { FilterOperator } from '@/model/queryParameters/QueryParameter';
import { UserModule } from '@/store/modules/user';
import { AssetType } from '@/utils/assetTypes';
import { customFailedMessage } from '@/utils/prompt';
import { REPORTDATE_FORMAT } from '@/utils/time';
import { DateRange } from '@/utils/types/date';
import { InitializeReactive } from '@/utils/vueClassComponentHelpers';
import {
  GLOBAL_REPORT_ASSET_SCOPES,
  ReportFormat,
  SUBSCRIBE_REPORT_ASSET_SCOPES,
} from '@/utils/workData/lookuptable';
import { downloadFile } from '@/views/report/components/components/helpers/downloadReport';
import { flattenSubOrganizationAssets } from '@/views/report/helpers/report';
import moment from 'moment';
import { Ref, unref } from 'vue';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { substractDaysFromData } from '../helpers/substractDaysFromData';

interface GenerateGlobalReportForm {
  reportName: string;
  reportFormat: ReportFormat;
  selectedOrganizationId: UUID | null;
  selectedAssetId: UUID | null;
}

@Component({
  name: 'ExportGlobalReportModal',
  components: {
    CusFormItem,
    WidgetDialog,
    TimeSelect,
  },
})
export default class extends Vue {
  @Prop({ default: false }) visible!: boolean;
  @Prop({ required: true }) reportTemplateData!: ReportTemplate;

  reportFormatList = [] as string[];

  @InitializeReactive
  reportFormValues!: GenerateGlobalReportForm;
  globalReportAssetScope = GLOBAL_REPORT_ASSET_SCOPES;
  assetRangeScope: string = GLOBAL_REPORT_ASSET_SCOPES.organizationRange;
  reportTemplateFormatIsLoading: boolean = false;
  selectedPeriodDateRange: DateRange = {} as DateRange;
  context: Ref<Readonly<ActiveContext>> | null = null;
  organizationTree: Organization | undefined = undefined;
  availableOrganizations: Organization[] = [];
  availableAssets: OrganizationAssetHierarchy[] | null = null;
  contentIsLoading: boolean = false;

  created(): void {
    this.initializeDefaultSettings();
    this.context = useActiveContext();
    this.organizationTree = unref(this.context).organization;
    this.availableOrganizations = flattenOrganizations(this.organizationTree!);
  }

  initializeDefaultSettings() {
    this.reportFormValues = {
      reportFormat: ReportFormat.Excel,
      reportName: this.reportTemplateData.name,
      selectedAssetId: null, // WORKAROUND for class component refs not adding reactive properties when undefined
      selectedOrganizationId: null, // WORKAROUND for class component refs not adding reactive properties when undefined
    };

    this.handleAvailableAssets();
    this.handleReportFormats();
  }

  /* Get current org assets and from sub orgs all assets of type Tipping Vehicle */
  async handleAvailableAssets(): Promise<void> {
    try {
      const organizationHierarchyAssetsResponse =
        await getAssetsOrganizationsHierarchy(
          undefined,
          unref(this.context)?.organization?.id
        );

      const ownAssets =
        organizationHierarchyAssetsResponse.data?.organization.assets.filter(
          (asset) => asset.assetType === AssetType.TippingVehicle
        );

      const subOrganizationsAssets = flattenSubOrganizationAssets(
        organizationHierarchyAssetsResponse.data
      )?.filter((asset) => asset.assetType === AssetType.TippingVehicle);

      this.availableAssets = [...ownAssets, ...subOrganizationsAssets];
    } catch (error) {
      console.log(error);
    }
  }

  async handleReportFormats(): Promise<void> {
    try {
      this.reportTemplateFormatIsLoading = true;
      const res = await getReportTemplates();
      if (res.code === 200) {
        this.reportFormatList = res.data;
      }
      this.reportTemplateFormatIsLoading = false;
    } catch (error) {
      console.log(error);
    }
  }

  /** In case of close modal, the visible attribute from parent is set to false */
  close(): void {
    this.$emit('update:visible', false);
  }

  get rules() {
    const tmpRules = {
      reportName: [
        {
          required: true,
          message: this.$t('report.tipInputReportName'),
          pattern: /^\S+/,
          trigger: 'change',
        },
        { validator: this.validateReportName, trigger: 'change' },
      ],
      reportFormat: [
        {
          required: true,
          message: this.$t('report.pleaseSelectAReportFormat'),
          pattern: /^\S+/,
          trigger: 'change',
        },
        { validator: this.validateReportName, trigger: 'change' },
      ],

      selectedAssetId: [
        {
          required:
            this.assetRangeScope === this.globalReportAssetScope.assetRange
              ? true
              : false,
          message: this.$t('report.pleaseSelectAnAsset'),
          pattern: /^\S+/,
          trigger: ['blur', 'change'],
        },
        { validator: this.validateAssetIdInput, trigger: ['blur', 'change'] },
      ],

      selectedOrganizationId: [
        {
          required:
            this.assetRangeScope ===
            this.globalReportAssetScope.organizationRange
              ? true
              : false,
          message: this.$t('report.pleaseSelectAnOrganization'),
          pattern: /^\S+/,
          trigger: ['blur', 'change'],
        },
        {
          validator: this.validateOrganizationIdInput,
          trigger: ['blur', 'change'],
        },
      ],
    };

    return tmpRules;
  }

  validateReportName = (_rule: any, value: any, callback: any) => {
    if (value.length < 1 || value.length > 100) {
      return callback(
        new Error(`${this.$t('common.inputValidationOneToOneHundred')}`)
      );
    }
    return callback();
  };

  /**
   * Validate asset id input
   * @param _rule
   * @param value
   * @param callback
   */
  validateAssetIdInput = (_rule: any, value: any, callback: any) => {
    if (
      (!value || value === undefined || value === null) &&
      this.assetRangeScope === this.globalReportAssetScope.assetRange
    ) {
      return callback(new Error(`${this.$t('report.pleaseSelectAnAsset')}`));
    }
    return callback();
  };

  /**
   * Validate organization id input
   * @param _rule
   * @param value
   * @param callback
   */
  validateOrganizationIdInput = (_rule: any, value: any, callback: any) => {
    if (
      (!value || value === undefined || value === null) &&
      this.assetRangeScope === this.globalReportAssetScope.organizationRange
    ) {
      return callback(new Error(`${this.$t('report.selectOrganization')}`));
    }
    return callback();
  };

  handleTimeFilter(periodDateRange: DateRange): void {
    this.selectedPeriodDateRange = periodDateRange;
    this.updateReportName();
  }

  formatDateForReportTimestamp(date: string): string {
    return moment(date).format(REPORTDATE_FORMAT);
  }

  subtractData(date: string): string {
    return substractDaysFromData({
      dateToBeSubstracted: date,
      dateFormat: REPORTDATE_FORMAT,
    });
  }

  updateReportName(): void {
    if (
      !this.selectedPeriodDateRange?.start ||
      !this.selectedPeriodDateRange?.end
    ) {
      this.reportFormValues.reportName = this.reportTemplateData.name;
      return;
    }

    const finalReportName = `${this.reportTemplateData
      ?.name!}_${this.formatDateForReportTimestamp(
      this.selectedPeriodDateRange.start
    )}-${this.subtractData(this.selectedPeriodDateRange.endExclusive)}`;

    this.reportFormValues.reportName = finalReportName;
  }

  async exportReport(): Promise<void> {
    (this.$refs.refExportGlobalReportForm as any).validate(
      async (valid: boolean) => {
        if (!valid) return;

        if (this.reportFormValues.reportFormat === undefined) {
          throw new Error('Report Format has to be defined');
        }

        this.contentIsLoading = true;

        const requestBody: GenerateGlobalReportRequestBody = {
          reportName: this.reportFormValues.reportName,
          reportTemplateId: this.reportTemplateData.id,
          reportSelectionStartDate: this.selectedPeriodDateRange.start,
          reportSelectionEndDate: this.selectedPeriodDateRange.end,
          assetScope:
            this.assetRangeScope ===
            this.globalReportAssetScope.organizationRange
              ? SUBSCRIBE_REPORT_ASSET_SCOPES.organizationRange
              : SUBSCRIBE_REPORT_ASSET_SCOPES.assetRange,
          reportFormat: this.reportFormValues.reportFormat,
          timezone: UserModule.timeZone,
          filters: [
            {
              name:
                this.assetRangeScope ===
                this.globalReportAssetScope.organizationRange
                  ? 'organizationId'
                  : 'assetId',
              operator: FilterOperator.IN,
              value: [
                this.assetRangeScope ===
                this.globalReportAssetScope.organizationRange
                  ? this.reportFormValues.selectedOrganizationId!
                  : this.reportFormValues.selectedAssetId!,
              ],
            },
          ],
        };

        try {
          const response = await generateReport(requestBody);

          downloadFile(
            response,
            this.reportFormValues.reportName,
            this.reportFormValues.reportFormat
          );
          this.contentIsLoading = false;
        } catch {
          this.contentIsLoading = false;
          customFailedMessage(this.$t('common.errorWithFetchingData'));
        }

        return;
      }
    );
  }

  handleAssetRangeChange(): void {
    this.reportFormValues.selectedAssetId = null;
    this.reportFormValues.selectedOrganizationId = null;

    this.$nextTick(() => {
      this.validateInputFormValues();
    });
  }

  async validateInputFormValues(): Promise<void> {
    (this.$refs.refExportGlobalReportForm as any).validate(
      (valid: boolean) => {}
    );
  }
}
</script>

<template>
  <WidgetDialog
    class="export-global-report-modal"
    :visible.sync="visible"
    :confirmBtnName="$t('common.export')"
    :title="$t('report.exportReport')"
    :width="'800px'"
    @handle-cancel="close"
    @handle-confirm="exportReport"
    :contentIsLoading="contentIsLoading"
  >
    <el-form
      class="input-form-element"
      style="margin: 32px 0px 40px 70px"
      ref="refExportGlobalReportForm"
      :model="reportFormValues"
      :rules="rules"
      :inline-message="false"
    >
      <CusFormItem :title="'report.reportName'">
        <el-form-item prop="reportName">
          <el-input
            v-model="reportFormValues.reportName"
            :placeholder="$t('report.inputReportName')"
          />
        </el-form-item>
      </CusFormItem>

      <CusFormItem :title="'report.reportTemplate'">
        <el-form-item prop="name">
          <div class="report-template-name">
            {{ reportTemplateData.name }}
          </div>
        </el-form-item>
      </CusFormItem>

      <CusFormItem
        :title="'report.dateRange'"
        v-show="reportTemplateData.isDateSelectionRequired"
      >
        <el-form-item prop="dateRange">
          <TimeSelect
            class="date-range"
            :expanded="true"
            @select="handleTimeFilter"
            :customizable="true"
          />
        </el-form-item>
      </CusFormItem>

      <CusFormItem :title="'report.assetRange'">
        <el-form-item prop="assetRange">
          <el-select
            v-model="assetRangeScope"
            :placeholder="$t('report.inputAssetRange')"
            @change="handleAssetRangeChange"
          >
            <el-option
              v-for="(item, index) in globalReportAssetScope"
              :key="index"
              :label="$t(item)"
              :value="item"
            />
          </el-select>
        </el-form-item>
      </CusFormItem>

      <CusFormItem
        :title="'report.selectOrganization'"
        v-if="assetRangeScope === globalReportAssetScope.organizationRange"
      >
        <el-form-item prop="selectedOrganizationId">
          <el-select
            v-model="reportFormValues.selectedOrganizationId"
            :placeholder="$t('report.selectOrganization')"
          >
            <el-option
              v-for="item in availableOrganizations"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            />
          </el-select>
        </el-form-item>
      </CusFormItem>

      <CusFormItem
        :title="'report.selectAsset'"
        v-if="assetRangeScope === globalReportAssetScope.assetRange"
      >
        <el-form-item prop="selectedAssetId">
          <el-select
            v-model="reportFormValues.selectedAssetId"
            :placeholder="$t('report.selectAsset')"
          >
            <el-option
              v-for="item in availableAssets"
              :key="item.id"
              :label="item.companyAssetId"
              :value="item.id"
            />
          </el-select>
        </el-form-item>
      </CusFormItem>

      <CusFormItem :title="'report.subscribeReportFormat'">
        <el-form-item
          prop="reportFormat"
          :disabled="reportTemplateFormatIsLoading"
        >
          <el-select
            v-model="reportFormValues.reportFormat"
            :placeholder="$t('report.subscribeReportFormat')"
            v-loading="reportTemplateFormatIsLoading"
          >
            <el-option
              v-for="(item, index) in reportFormatList"
              :key="index"
              :label="$t(item)"
              :value="item"
            />
          </el-select>
        </el-form-item>
      </CusFormItem>
    </el-form>
  </WidgetDialog>
</template>

<style scoped lang="scss">
.report-template-name {
  font-weight: bold;
  padding: 0 10px;
  font-family: var(--fontRobotoMedium, Roboto-Medium);
}

.date-range :deep(.select) {
  width: 150px;
}

::v-deep {
  .date-range {
    .select {
      .el-input__suffix {
        right: 260px;
      }
    }
  }
}

.export-global-report-modal :deep(.el-form) {
  .item {
    margin-top: 0;
  }

  .item-title {
    text-align: left;
    margin-left: 40px;
  }

  .item-content {
    margin-left: -40px;
  }
}

.export-global-report-modal :deep(.el-dialog__body) {
  padding: 0 25px 0;
}

.export-global-report-modal :deep(.el-dialog__footer) {
  padding: 0 0 20px;
}

.item :deep(.el-input) {
  width: 406px;
}
</style>
