<script lang="ts" setup>
import { isStringNumberZero } from '@/utils/number';
import { computed } from 'vue';

const props = withDefaults(
  defineProps<{
    growthPercentage: number | undefined;
    /**
     * `isPositiveGrowthBad`: there are some cases when growth is actually not a good thing (e.g. yesteday's trip took 2 hours, today's 3), and in this case
     * we need to invert the colors by using red for growth and green decrease
     */
    isPositiveGrowthBad?: boolean;
    numberOfDecimals?: number;
  }>(),
  {
    numberOfDecimals: 1,
    isPositiveGrowthBad: false,
  }
);

const formattedPercentageNumber = computed((): string | undefined =>
  props.growthPercentage?.toFixed(props.numberOfDecimals)
);
</script>

<template>
  <div class="percentage">
    <span
      v-if="
        formattedPercentageNumber !== undefined &&
        isStringNumberZero(formattedPercentageNumber)
      "
      class="neutral"
    >
      <span class="value">
        {{ formattedPercentageNumber.replace('-', '') }}%
      </span>
    </span>
    <span
      v-else-if="props.growthPercentage && props.growthPercentage > 0"
      :class="{
        negative: props.isPositiveGrowthBad,
        positive: !props.isPositiveGrowthBad,
      }"
    >
      <img v-if="props.isPositiveGrowthBad" src="@/icons/svg/arrowUpRed.svg" />
      <img v-else src="@/icons/svg/arrowUp.svg" />
      <span class="value"> {{ formattedPercentageNumber }}% </span>
    </span>
    <span
      v-else-if="props.growthPercentage && props.growthPercentage < 0"
      :class="{
        negative: !props.isPositiveGrowthBad,
        positive: props.isPositiveGrowthBad,
      }"
    >
      <img
        v-if="props.isPositiveGrowthBad"
        src="@/icons/svg/arrowDownGreen.svg"
      />
      <img v-else src="@/icons/svg/arrowDown.svg" />
      <span class="value"> {{ formattedPercentageNumber }}% </span>
    </span>
  </div>
</template>

<style lang="scss" scoped>
.percentage {
  font-size: 18px;
  font-weight: bold;
}

.positive {
  color: #66bc93;
}

.negative {
  color: #ed6964;
}

.neutral {
  color: #969696;
}
</style>
