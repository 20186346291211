<script lang="ts">
import {
  getOrganizations,
  getOrgById,
  Organization,
  OrganizationChild,
  OrganizationResponse,
} from '@/api/organizations';
import { ServiceRegion } from '@/api/serviceRegions';
import { LoggedInUserRef, useLoggedInUser } from '@/auth/user';
import PureTable from '@/components/table/PureTable.vue';
import SelectTableHeader from '@/components/table/SelectTableHeader.vue';
import { useServiceRegionsQuery } from '@/query/serviceRegion';
import { UserModule } from '@/store/modules/user';
import { isCompanyTypeOf } from '@/utils/companyService';
import { customFailedMessage } from '@/utils/prompt';
import { COMPANY_TYPE } from '@/utils/workData/lookuptable';
import {
  ACCESSIBLE_SERVICE_REGION_PROPERTY_NAME,
  ORG_MANAGEMENT_COLS,
  ORG_MANAGEMENT_SEARCH_FIELDS,
} from '@/utils/workData/orgMgmt';
import Treeselect from '@riophae/vue-treeselect';
import { unref } from 'vue';
import { Component, Vue } from 'vue-property-decorator';
import { HELPDESK_COMPANY_TYPES } from './organizationManagementConstants';

interface TableRowData extends Organization {
  translatedActivationStatus: string;
  translatedAccessibleServiceRegions: string;
}

@Component({
  name: 'OrgMgmt',
  components: {
    Treeselect,
    'select-table-header': SelectTableHeader,
    'pure-table': PureTable,
  },
})
export default class extends Vue {
  /** Local variables */
  tableData: TableRowData[] = [];
  totalOrg: number = 0;
  treeProp = { children: 'children', hasChildren: 'hasChildren' };
  isOrganizationManagementLoading: boolean = false;
  cols = ORG_MANAGEMENT_COLS.map((item) => ({
    ...item,
    label: this.$t(item.label),
  }));
  orgSearchFieldList = ORG_MANAGEMENT_SEARCH_FIELDS;
  isHelpdeskUser: boolean = false;
  helpdeskPropertyNames: string[] = [ACCESSIBLE_SERVICE_REGION_PROPERTY_NAME];

  options = [{ id: 1 }, { id: 2 }, { id: 3 }];

  searchObj: any = {
    reference: null,
    value: null,
  };

  helpdeskCompanyTypes: string[] = HELPDESK_COMPANY_TYPES;
  loggedInUser!: LoggedInUserRef;
  serviceRegionsQuery!: ReturnType<typeof useServiceRegionsQuery>;

  getTotalOrg(root: TableRowData[] | OrganizationChild[]) {
    let count = root.length;

    for (const { children } of root) {
      count += this.getTotalOrg(children);
    }

    return count;
  }

  generateUrl = (filterParams: any) => {
    let result = '';
    for (let item in filterParams) {
      if (filterParams[item] && String(filterParams[item])) {
        result = result + `&${item}=` + encodeURIComponent(filterParams[item]);
      }
    }

    return result;
  };

  getFilterUrl(col: string, order: string) {
    let filterParams: any = {
      orderBy: col,
      order: order,
    };

    let key: string = this.searchObj.reference;
    let value: string = this.searchObj.value;

    if (key && value) {
      filterParams[key] = value;
    }

    return this.generateUrl(filterParams);
  }

  async fetchOrgMgmtInfos() {
    this.isOrganizationManagementLoading = true;
    // This can probably just be taken directly from loggedInUser.organization, but
    // esp when orgs change, that info must then be reloaded from the server. So for now,
    // let's just always reload.
    const user = unref(this.loggedInUser);
    if (!user) {
      // Should never happen
      throw new Error('No user logged in');
    }
    await getOrgById(user.organization.id).then((res) => {
      if (!res) {
        customFailedMessage(this.$t('common.errorWithFetchingData') as string);
        return;
      }

      if (res.code === 200) {
        this.tableData = this.translateTableData([res.data]);
        this.totalOrg = this.getTotalOrg(this.tableData);

        this.handleParentName(this.tableData);
        this.isOrganizationManagementLoading = false;
        return;
      }

      this.isOrganizationManagementLoading = false;
      customFailedMessage(this.$t('common.errorWithFetchingData') as string);
    });
  }

  getOrganizationRegionNames(serviceRegionCodes: string[]) {
    if (!serviceRegionCodes) return;

    let regions = '';
    serviceRegionCodes.forEach((regionCode: string) => {
      const regionName = this.getRegionNameByCode(regionCode);
      if (regionName) {
        if (!!regions == false) {
          regions = regionName;
        } else {
          regions += ', ' + regionName;
        }
      }
    });

    return regions;
  }

  getRegionNameByCode(regionCode: string) {
    if (this.serviceRegionsQuery.isError) return;

    return this.serviceRegionsQuery.data.value?.find(
      (region: ServiceRegion) => region.serviceRegionCode == regionCode
    )?.serviceRegionName;
  }

  async handleFilter(col: string, order: string) {
    let paramsUrl: string = this.getFilterUrl(col, order);
    const companyId = unref(this.loggedInUser)?.companyId;
    if (!companyId) {
      // Shouldn't ever happen
      throw new Error('No user logged in');
    }
    this.getOrgs(companyId, paramsUrl);
  }

  handleParentName(orgs: OrganizationResponse) {
    if (orgs.length === 0) return;
    orgs.forEach((item: Organization) => {
      item.parentName = item.parentName ? item.parentName : '—';
      if (item.timezone) {
        item.timezone = item.timezone.replace('/', '_');
      }
      return this.handleParentName(item.children);
    });
  }

  customKey(node: any) {
    return {
      id: node.id,
      label: node.name,
      children: node.children.length > 0 ? node.children : undefined,
    };
  }

  goToCreateOrganizationFormPage() {
    if (isCompanyTypeOf(this.helpdeskCompanyTypes)) {
      this.$router.push('add-new-helpdesk-org');
    } else {
      this.$router.push('add-new-org');
    }
  }

  async getOrgs(companyId: string, filter?: string) {
    try {
      this.isOrganizationManagementLoading = true;
      const response = await getOrganizations(companyId, filter);
      this.tableData = this.translateTableData(response.data);
      this.totalOrg = this.getTotalOrg(this.tableData);

      this.handleParentName(this.tableData);
    } catch (error) {
      console.log(error);
      customFailedMessage(this.$t('common.errorWithFetchingData') as string);
    } finally {
      this.isOrganizationManagementLoading = false;
    }
  }

  translateTableData(
    preTranslatedTableData: OrganizationResponse
  ): TableRowData[] {
    return preTranslatedTableData.map(
      (item): TableRowData => ({
        ...item,
        translatedActivationStatus: this.$t(item.activationStatus),
        translatedAccessibleServiceRegions:
          item.accessibleServiceRegions
            ?.map((region) => this.$t(region))
            .join(', ') ?? '',
        children: this.translateTableData(item.children).map(
          (item): OrganizationChild => ({
            ...item,
            parentId: item.parentId ?? '',
            parentName: item.parentName ?? '',
          })
        ),
      })
    );
  }

  created() {
    this.loggedInUser = useLoggedInUser();
    this.serviceRegionsQuery = useServiceRegionsQuery();

    if (UserModule.companyType === COMPANY_TYPE.Helpdesk) {
      this.isHelpdeskUser = true;
      this.helpdeskPropertyNames.forEach((property: string) => {
        const column = this.cols.find((col) => col.prop == property);

        if (column) {
          column.visible = true;
        }
      });

      this.fetchOrgMgmtInfos();
    } else {
      this.helpdeskPropertyNames.forEach((property: string) => {
        const columnIndex = this.cols.findIndex((col) => col.prop == property);

        if (columnIndex > -1) {
          this.cols.splice(columnIndex, 1);
        }
      });

      this.fetchOrgMgmtInfos();
    }
  }
}
</script>

<template>
  <div style="padding: 0px 20px">
    <select-table-header
      class="filters"
      :searchFieldOptions="orgSearchFieldList"
      :cols="cols"
      :searchParams="searchObj"
      @search-event="handleFilter"
    >
      <template #rightHeader>
        <el-button
          id="create_organization_button"
          v-permission="['AUTHRSC_ACTION_ORG_CREATE']"
          type="plain"
          @click="goToCreateOrganizationFormPage"
          style="margin-right: 20px"
        >
          <i class="el-icon-plus common-icon" />{{
            $t('organizationModule.addNewOrganization')
          }}
        </el-button>
      </template>
    </select-table-header>

    <pure-table
      v-loading="isOrganizationManagementLoading"
      :element-loading-text="$t('userModule.loadingOrganizationData')"
      class="orgTable"
      :tableList="tableData"
      :showPagination="false"
      :cols="cols"
      :viewPagePath="'view-org-info'"
      :treeProp="treeProp"
      :expandAll="true"
    />

    <div class="d-flex ai-center">
      <span class="total-statistics"
        >{{ $t('organizationModule.organizationTotalInfo') }}:</span
      >
      <span class="total-statistics-value">{{ totalOrg }}</span>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.filters {
  margin: 20px 0;
  flex-wrap: wrap;
  gap: 10px 0px;
}

.el-dropdown-link {
  cursor: pointer;
  color: #409eff;
}

.el-icon-arrow-down {
  font-size: 14px;
}

.cancel-button {
  width: 150px;
  height: 50px;
}

.remove-button {
  width: 150px;
  height: 50px;
  // background-color: #000;
  // border: #000;
}

.orgTable :deep(.el-table__row--level-0) {
  background-color: rgba(55, 62, 65, 0.13) !important;
}

.orgTable :deep(.el-table__row > td) {
  border-bottom: none;
}

.el-input {
  width: 300px;
}

.org-input :deep(.el-input__inner) {
  height: $inputHeight;
  width: 300px;
  // width: 31vw;
  border: 1px solid #707070 !important;
  font-size: 1.285714rem;
  font-family: $font-Roboto-Regular;
  line-height: 1.357143rem;
  color: #373e41;
  opacity: 1;
}

.el-select {
  margin-right: 40px;
}

.org-select :deep(.el-input__inner) {
  height: $inputHeight;
  width: 200px;
  // width: 31vw;
  border: 1px solid #707070 !important;
  font-size: 1.285714rem;
  font-family: $font-Roboto-Regular;
  line-height: 1.357143rem;
  color: #373e41;
  opacity: 1;
}
</style>
