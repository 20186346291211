<script lang="ts" setup>
import { IncludedWidget } from '@/api/widgets';
import { isWidgetConfig } from '@/utils/widget';
import { WIDGET_CODE_MAP } from '@/utils/workData/widgetCodeMap';
import { computed, provide, toRef } from 'vue';

const props = defineProps<{
  widget: IncludedWidget;
  pageCode: string;
  expanded?: boolean;
}>();

const component = computed(() => {
  const component = WIDGET_CODE_MAP.get(props.widget.code);

  if (!component || !isWidgetConfig(component)) {
    return component;
  }

  const { '*': fallback, [props.pageCode as any]: widget } = component;

  return widget ?? fallback;
});

provide('widget', toRef(props, 'widget'));
provide('expanded', toRef(props, 'expanded'));
</script>

<template>
  <component :is="component" />
</template>
