<script setup lang="ts">
import { UUID } from '@/api/common';
import { LifeCycle } from '@/api/geofence';
import { GeofenceTrackingStrategy, TrackingDays } from '@/api/geofenceTypes';
import { Organization } from '@/api/organizations';
import { useLoggedInUser } from '@/auth/user';
import i18n from '@/lang';
import { useOrganizationAssetsHierarchyQuery } from '@/query/assets';
import { extractAssetsFromOrganizationHierarchy } from '@/utils/assets';
import { AssetType } from '@/utils/assetTypes';
import { findOrganizationInTree } from '@/utils/org';
import {
  GEOFENCE_TYPE_CONFIG_LIST,
  HOURS_OF_THE_DAY,
} from '@/utils/workData/lookuptable';
import Treeselect from '@riophae/vue-treeselect';
import { computed, watch } from 'vue';

export interface GeofenceFormModel {
  name: string;
  organizationId?: UUID;
  lifeCycle: LifeCycle;
  associatedAssetTypes: AssetType[];
  geofenceType: string;
  tracking: GeofenceTrackingStrategy | undefined;
  trackingTime: GeofenceTrackingStrategy | undefined;
  trackingStartTime: string | undefined;
  trackingEndTime: string | undefined;
  trackingDays: TrackingDays[];
}

interface Normalizer {
  id: string;
  label: string;
  children: Organization[] | undefined;
}

const props = defineProps<{
  formModel: GeofenceFormModel;
  allowTracking: boolean;
  formRules: any;
}>();

const emit = defineEmits<{
  (e: 'toggleAllowTracking'): void;
}>();

const loggedInUser = useLoggedInUser();
const { data: assetsHierarchyData } = useOrganizationAssetsHierarchyQuery();
watch(
  loggedInUser,
  () => {
    console.log(loggedInUser.value);
  },
  { immediate: true, deep: true }
);

const availableAssetTypes = computed((): AssetType[] | undefined => {
  if (assetsHierarchyData.value === undefined) {
    return undefined;
  }

  const extractedAssets = extractAssetsFromOrganizationHierarchy(
    assetsHierarchyData.value
  );

  return [...new Set(extractedAssets.map((asset) => asset.assetType))];
});

const geofenceOptions = GEOFENCE_TYPE_CONFIG_LIST;
const trackingModes: { id: GeofenceTrackingStrategy; label: string }[] = [
  {
    id: GeofenceTrackingStrategy.Always,
    label: i18n.t('geofence.trackAlways'),
  },
  {
    id: GeofenceTrackingStrategy.TimePeriod,
    label: i18n.t('geofence.trackTimePeriod'),
  },
];
const trackingOptions: { id: GeofenceTrackingStrategy; label: string }[] = [
  {
    id: GeofenceTrackingStrategy.InsideTimeInterval,
    label: i18n.t('geofence.inTimeInterval'),
  },
  {
    id: GeofenceTrackingStrategy.OutSideTimeInterval,
    label: i18n.t('geofence.outTimeInterval'),
  },
  {
    id: GeofenceTrackingStrategy.AllDays,
    label: i18n.t('geofence.allDay'),
  },
];
const timeOptions = HOURS_OF_THE_DAY;
const dayOptions = Object.keys(TrackingDays);

const organizationName = computed((): string | undefined => {
  if (
    loggedInUser.value === undefined ||
    props.formModel.organizationId === undefined
  ) {
    return undefined;
  }

  const org = findOrganizationInTree(
    loggedInUser.value.organization,
    props.formModel.organizationId
  );
  return org?.name;
});

function customKeyNormalizer(node: Organization): Normalizer {
  return {
    id: node.id,
    label: node.name,
    children: node.children.length > 0 ? node.children : undefined,
  };
}

const statusButtonType = computed((): 'success' | 'warning' | 'info' => {
  switch (props.formModel.lifeCycle) {
    case LifeCycle.Active:
      return 'success';
    case LifeCycle.Deprecated:
      return 'info';
    case LifeCycle.Draft:
      return 'warning';
    default:
      return 'warning';
  }
});
</script>

<template>
  <el-form :model="formModel" label-position="left" :rules="formRules">
    <el-form-item prop="name">
      <div class="tag">
        {{ $t('geofence.geofenceName') }}: <span class="aster">*</span>
      </div>
      <el-input
        :placeholder="$t('geofence.enterName')"
        v-model="formModel.name"
        :disabled="formModel.lifeCycle === LifeCycle.Deprecated"
      />
    </el-form-item>

    <el-form-item
      v-if="formModel.lifeCycle === LifeCycle.Draft"
      prop="organizationId"
      required
    >
      <div class="tag">
        {{ $t('ORGANIZATION') }}: <span class="aster">*</span>
      </div>

      <Treeselect
        :options="loggedInUser ? [loggedInUser.organization] : []"
        v-model="formModel.organizationId"
        :normalizer="customKeyNormalizer"
        :placeholder="$t('geofence.selectOrg')"
      />
    </el-form-item>
    <el-form-item v-else>
      <span class="tag">{{ $t('ORGANIZATION') }}: </span>
      <span>{{
        organizationName ||
        $t('geofence.ApiErrorFieldNotFound.ApiFieldOrganizationId')
      }}</span>
    </el-form-item>

    <el-form-item
      v-if="formModel.lifeCycle !== LifeCycle.Active"
      :model="formModel.geofenceType"
    >
      <span class="tag"> {{ $t('geofence.geofenceType') }}: </span>
      <el-select
        v-model="formModel.geofenceType"
        default-first-option
        :placeholder="$t('geofence.geofenceType')"
        :disabled="formModel.lifeCycle === LifeCycle.Deprecated"
      >
        <el-option
          v-for="option in geofenceOptions"
          :key="option.id"
          :label="$t(option.value)"
          :value="option.value"
        >
        </el-option>
      </el-select>
    </el-form-item>
    <el-form-item v-else :model="formModel.geofenceType">
      <span class="tag">{{ $t('geofence.geofenceType') }}</span>
      <span>{{ $t(formModel.geofenceType) }}</span>
    </el-form-item>

    <el-form-item :model="formModel.associatedAssetTypes">
      <div class="tag">{{ $t('geofence.assetType') }}</div>
      <el-select
        v-loading="availableAssetTypes === undefined"
        v-model="formModel.associatedAssetTypes"
        multiple
        default-first-option
        :placeholder="$t('geofence.assetPlaceholder')"
        :disabled="formModel.lifeCycle === LifeCycle.Deprecated"
      >
        <el-option
          v-for="asset in availableAssetTypes"
          :label="$t(asset)"
          :key="asset"
          :value="asset"
        >
        </el-option>
      </el-select>
    </el-form-item>

    <el-form-item :model="formModel.lifeCycle">
      <div class="tag">{{ $t('geofence.geofenceStatus') }}:</div>
      <div>
        <el-button :type="statusButtonType" plain disabled>
          {{ $t(formModel.lifeCycle) }}
        </el-button>
      </div>
    </el-form-item>

    <el-form-item class="tracking">
      <div class="tag">{{ $t('geofence.allowTracking') }}:</div>
      <el-switch
        :value="props.allowTracking"
        active-color="#e4c65c"
        @change="emit('toggleAllowTracking')"
        :disabled="formModel.lifeCycle === LifeCycle.Deprecated"
      />

      <el-radio-group
        v-if="props.allowTracking"
        fill="gray"
        v-model="formModel.tracking"
        size="medium"
        :disabled="formModel.lifeCycle === LifeCycle.Deprecated"
      >
        <el-radio-button
          v-for="option in trackingModes"
          :label="option.id"
          :key="option.id"
          >{{ option.label }}
        </el-radio-button>
      </el-radio-group>
    </el-form-item>

    <div
      v-if="
        props.allowTracking &&
        formModel.tracking === GeofenceTrackingStrategy.TimePeriod
      "
    >
      <el-form-item v-if="trackingOptions" :model="formModel.trackingTime">
        <span class="tag"> {{ $t('geofence.trackingTime') }}: </span>
        <el-select
          v-model="formModel.trackingTime"
          :disabled="formModel.lifeCycle === LifeCycle.Deprecated"
        >
          <el-option
            v-for="option in trackingOptions"
            :value="option.id"
            :key="option.id"
            :label="option.label"
          >
          </el-option>
        </el-select>
      </el-form-item>

      <el-form-item
        v-if="formModel.trackingTime !== GeofenceTrackingStrategy.AllDays"
        prop="trackingStartTime"
      >
        <div class="tag">{{ $t('geofence.start') }}:</div>
        <el-select
          v-model="formModel.trackingStartTime"
          :disabled="formModel.lifeCycle === LifeCycle.Deprecated"
          :placeholder="$t('geofence.placeholders.select')"
        >
          <el-option
            v-for="(option, index) in timeOptions"
            :value="option"
            :key="index"
            :label="option"
          />
        </el-select>
      </el-form-item>

      <el-form-item
        v-if="formModel.trackingTime !== GeofenceTrackingStrategy.AllDays"
        prop="trackingEndTime"
      >
        <div class="tag">{{ $t('geofence.end') }}:</div>
        <el-select
          v-model="formModel.trackingEndTime"
          :disabled="formModel.lifeCycle === LifeCycle.Deprecated"
        >
          <el-option
            v-for="(option, index) in timeOptions"
            :value="option"
            :key="index"
            :label="option"
          />
        </el-select>
      </el-form-item>

      <el-form-item prop="trackingDays">
        <div class="tag">{{ $t('geofence.daysAvailable') }}:</div>
        <el-checkbox-group
          fill="grey"
          v-model="formModel.trackingDays"
          :disabled="formModel.lifeCycle === LifeCycle.Deprecated"
        >
          <el-checkbox-button
            type="info"
            v-for="value in dayOptions"
            :key="value"
            :label="value"
          >
            {{ $t(`weekDaysShort.${value}`) }}
          </el-checkbox-button>
        </el-checkbox-group>
      </el-form-item>
    </div>
  </el-form>
</template>

<style lang="scss" scoped>
.tag {
  color: #8e8e8e;
  font-weight: 500;
  font-size: 14px;
  grid-column: 1;
}

.tag input:after {
  content: '*';
}

.oneliner {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: space-around;
}

.oneliner > div {
  width: 200px;
  height: 100px;
}

.el-select {
  margin-right: 0px !important;
  width: 100%;
}

.aster {
  color: red;
  background-position: right top;
}

.el-form-item {
  margin-bottom: 28px;
}

.fields {
  margin-top: 32px;
  margin-right: 24px;
  flex-basis: 40%;
}

.tracking {
  :deep(.el-form-item__content) {
    grid-template-columns: 0.33fr 0.15fr 0.5fr !important;
  }
}

:deep(.el-form-item__content) {
  display: inline-grid;
  grid-template-columns: 0.5fr 1fr;
  width: 100%;

  @media (max-width: 1200px) {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
}
</style>
