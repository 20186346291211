var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('ul',{staticClass:"container"},[(_vm.title)?_c('h3',{staticClass:"title"},[_vm._v(" "+_vm._s(_vm.$t(_vm.title))+" ")]):_vm._e(),_vm._l((!!_vm.productSystemReleaseID
        ? [
            ...(!!_vm.showReleaseNotes
              ? _vm.content
              : _vm.content.filter(
                  (e) =>
                    e.id !== 'productSystemRelease.partFirmwareReleaseUUID'
                )),
            {
              id: !!_vm.showReleaseNotes ? _vm.systemNoteId : _vm.firmwareNoteId,
              value: '',
            },
          ]
        : _vm.content),function(row,index){return _c('li',{key:index,staticClass:"row"},[_c('p',{staticClass:"lable"},[_vm._v(_vm._s(_vm.$t(`releaseDetails.${row.id}`))+":")]),(row.id === _vm.systemNoteId || row.id === _vm.firmwareNoteId)?_c('p',[_c('span',{staticClass:"vlaue read-more",on:{"click":function($event){_vm.showNotes(_vm.$tc(`releaseDetails.${row.id}`))}}},[_vm._v(_vm._s(_vm.$t('releaseDetails.clickToRead')))])]):(_vm.isDateTimeField(row.id))?_c('p',{staticClass:"value"},[_vm._v(" "+_vm._s(_vm.formatDateAndTime(row.value))+" ")]):(_vm.isSystemReleaseRegion(row.id))?_c('p',{staticClass:"value"},[_vm._v(" "+_vm._s(_vm.$t(row.value))+" ")]):_c('p',{staticClass:"value"},[_vm._v(_vm._s(_vm.$t(row.value?.toString())))])])})],2),_c('DefaultDialog',{attrs:{"visible":_vm.dialogVisible,"title":_vm.dialogTitle},on:{"update:visible":function($event){_vm.dialogVisible=$event},"handle-cancel":_vm.handleDialogClose}},[_c('div',{staticClass:"dialog-content"},[_vm._v(" "+_vm._s(_vm.dialogContent)+" ")])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }