<script setup lang="ts">
import { inject } from 'vue';
import SmallAssetSafetyWidget from './SmallAssetSafetyWidget.vue';
import ExpandedAssetSafetyWidget from './expanded/ExpandedAssetSafetyWidget.vue';

const isInExpandedMode = inject('expanded') ?? false;
</script>

<template>
  <ExpandedAssetSafetyWidget v-if="isInExpandedMode" />
  <SmallAssetSafetyWidget v-else />
</template>
