<script lang="ts">
import { getAssets } from '@/api/assets';
import { getOrganizations } from '@/api/organizations';
import {
  fetchReportTemplatesByCustomParams,
  getReportTemplates,
} from '@/api/report';
import { flattenOrganizations } from '@/auth/context';
import WidgetDialog from '@/components/dialog/WidgetDialog.vue';
import CusFormItem from '@/components/form/CusFormItem.vue';
import { UserModule } from '@/store/modules/user';
import { disabledPastDate } from '@/utils/date';
import { generateRequestPayload } from '@/utils/misc';
import {
  SUBSCRIBE_REPORT_ASSET_SCOPES,
  SUBSCRIBE_REPORT_DATE_RANGE_TYPE_LIST,
  SUBSCRIBE_REPORT_TEMPLATE_FREQUENCY_LIST,
} from '@/utils/workData/lookuptable';
import moment from 'moment';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { dateFormat, reportTemplateName } from '../report';

@Component({
  name: 'ModifySubscriptionModal',
  components: {
    CusFormItem,
    WidgetDialog,
  },
})
export default class extends Vue {
  @Prop({ default: false }) visible!: boolean;
  @Prop({ required: true }) title!: string;
  @Prop() activateOrDeactivate!: string;
  @Prop({ required: true }) subscriptionToModify!: any;

  /** Local variables */
  reportTemplateFrequencyList: any = SUBSCRIBE_REPORT_TEMPLATE_FREQUENCY_LIST;
  reportFormatList = [] as { id: string; label: string }[];
  reportFormatIsLoading: boolean = false;
  subscribeReportAssetRangeType: any = SUBSCRIBE_REPORT_ASSET_SCOPES;
  subscribeReportDateRangeList: any = SUBSCRIBE_REPORT_DATE_RANGE_TYPE_LIST;
  assetsForCurrentUser: any = [];
  orgsForCurrentUser: any = [];
  reportTemplateList: any = [];
  generalQueryData: any = {
    pageNumber: 1,
    pageSize: 10_000,
    searchParams: [
      {
        reference: null,
        operator: null,
        value: null,
      },
    ],
    sortByAndOrder: [
      {
        sortBy: null,
        order: null,
      },
    ],
  };

  canChangeSubscriptionStartDate: boolean = false;

  created() {
    this.initializeDefaultSettings();
    this.checkIfCanChangeSubscriptionStartDate();
  }

  checkIfCanChangeSubscriptionStartDate() {
    const currentDate = new Date();
    currentDate.setHours(12, 0, 0, 0);
    const subscriptionStartDate = new Date(this.subscriptionToModify.startDate);

    this.canChangeSubscriptionStartDate = moment(subscriptionStartDate).isAfter(
      currentDate
    );
  }

  async initializeDefaultSettings() {
    try {
      this.reportFormatIsLoading = true;

      let finalUrlParamsForSearch: string = generateRequestPayload(
        this.generalQueryData
      );
      await this.fetchReportTemplates(finalUrlParamsForSearch);
      await this.fetchAssetsForCurrentUser();
      await this.fetchOrgsForCurrentUser();

      const res = await getReportTemplates();
      if (res.code === 200) {
        this.reportFormatList = res.data.map((el) => ({ id: el, label: el }));
      }
    } catch (error) {
      console.log(error);
    } finally {
      this.reportFormatIsLoading = false;
    }
  }

  /** Retrieve assets that current user can have access */
  async fetchAssetsForCurrentUser() {
    let requestParams: string = `?page=1&size=100000&companyId=${UserModule.companyId}`;
    await getAssets(requestParams).then((res) => {
      const response = res.data.assets;
      if (!response) return;

      if (res.code === 200 && response) {
        this.assetsForCurrentUser = response;
      }
    });
  }

  /** Retrieve organizations that current user can have access */
  async fetchOrgsForCurrentUser() {
    await getOrganizations(UserModule.companyId).then((res) => {
      const response = res.data;
      if (!res) return;

      if (res.code === 200 && response) {
        this.orgsForCurrentUser = flattenOrganizations(response);
      }
    });
  }

  /** Fetch remotly form API report template data */
  async fetchReportTemplates(requestPayload: any) {
    await fetchReportTemplatesByCustomParams(requestPayload).then((res) => {
      if (!res) return;

      if (res.code === 200 && res.data.reportTemplates.length > 0) {
        this.reportTemplateList = res.data.reportTemplates;
      }
    });
  }

  closeDialog() {
    this.$emit('handle-cancel');
  }

  cancelModal() {
    (this.$refs.refSubcribeReportForm as any).resetFields();
    this.$emit('handle-cancel');
  }

  handleSubscribedReportModification() {
    (this.$refs.refSubcribeReportForm as any).validate((valid: any) => {
      if (valid) {
        this.subscriptionToModify[reportTemplateName] =
          this.reportTemplateList.find(
            (r: any) => r.id == this.subscriptionToModify.reportTemplateId
          ).name;
        this.$emit(
          'handle-modify-subscribed-report',
          this.subscriptionToModify
        );
      }
    });
  }

  pickerOptions: any = {
    disabledDate: disabledPastDate,
  };

  /** Rules definition for the input form */
  get rules() {
    const tmpRules = {
      reportName: [
        {
          required: true,
          message: this.$t('report.tipInputReportName'),
          pattern: /^\S+/,
          trigger: 'change',
        },
        { validator: this.validateName, trigger: 'change' },
      ],
      reportTemplateName: [
        {
          required: true,
          message: this.$t('report.tipInputReportTemplateName'),
          pattern: /^\S+/,
          trigger: 'change',
        },
        { validator: this.validateName, trigger: 'change' },
      ],
      subscriptionFrequency: [
        { required: true, message: this.$t('report.pleaseSelectFrequency') },
        {
          message: this.$t('report.pleaseSelectFrequency'),
          trigger: ['blur', 'change'],
        },
      ],
      subscribedEmail: [
        { required: true, message: this.$t('common.tipInputEmail') },
        {
          type: 'email',
          message: this.$t('common.tipInputCorrectEmail'),
          trigger: ['blur', 'change'],
        },
      ],
      startDate: [
        { required: true, message: this.$t('common.tipSelectDate') },
        { validator: this.validateStartDate, trigger: 'blur' },
      ],
      endDate: [
        { required: true, message: this.$t('common.tipSelectDate') },
        { validator: this.validateEndDate, trigger: 'blur' },
      ],
      reportFormat: [
        {
          required: true,
          message: this.$t('report.pleaseSelectAReportFormat'),
          pattern: /^\S+/,
          trigger: 'change',
        },
        { validator: this.validateName, trigger: 'change' },
      ],
    };

    return tmpRules;
  }

  validateStartDate = (rule: any, value: any, callback: any) => {
    let end = moment(this.subscriptionToModify.startDate).format(dateFormat);
    if (end) (this.$refs.refSubcribeReportForm as any).validateField('endDate');
    callback();
  };

  validateEndDate = (rule: any, value: any, callback: any) => {
    let end = new Date(moment(value).format(dateFormat));
    let start = new Date(
      moment(this.subscriptionToModify.startDate).format(dateFormat)
    );

    if (moment(start).isAfter(end)) {
      callback(new Error(`${this.$t('report.tipSelectCorrectDate')}`));
    } else {
      callback();
    }
  };

  validateName = (rule: any, value: any, callback: any) => {
    if (value.length < 1 || value.length > 100) {
      callback(
        new Error(`${this.$t('common.inputValidationOneToOneHundred')}`)
      );
    } else {
      callback();
    }
  };

  /** Format date */
  formatDateTime(date: any) {
    return moment(date).format(dateFormat);
  }
}
</script>

<template>
  <div>
    <WidgetDialog
      :visible.sync="visible"
      :confirmBtnName="$t('report.modifySubscribedReport')"
      :title="$t(title)"
      :width="'800px'"
      @handle-cancel="cancelModal"
      @handle-confirm="handleSubscribedReportModification"
    >
      <el-form
        style="margin: 32px 0px 40px 40px"
        ref="refSubcribeReportForm"
        :model="subscriptionToModify"
        :rules="rules"
        :inline-message="false"
      >
        <div>
          <CusFormItem :title="'report.reportName'" :required="false">
            <el-form-item prop="name">
              <div class="form-report-template">
                <span> {{ subscriptionToModify.name }}</span>
              </div>
            </el-form-item>
          </CusFormItem>

          <CusFormItem
            :title="'report.reportTemplate'"
            :required="false"
            class="report-template-id"
          >
            <el-form-item prop="reportTemplateId">
              <div
                class="form-report-template"
                v-for="(item, index) in reportTemplateList"
                :key="index"
              >
                <span v-if="item.id === subscriptionToModify.reportTemplateId">
                  {{ item.name }}
                </span>
              </div>
            </el-form-item>
          </CusFormItem>

          <CusFormItem
            :title="'report.assetRange'"
            class="data-range-element"
            :required="false"
          >
            <el-form-item prop="reportAssetScope">
              <div
                class="form-report-template"
                v-for="(item, index) in subscribeReportAssetRangeType"
                :key="index"
              >
                <span v-if="item === subscriptionToModify.reportAssetScope">
                  {{ $t(item) }}
                </span>
              </div>
            </el-form-item>
          </CusFormItem>

          <CusFormItem
            v-if="
              subscriptionToModify.reportAssetScope ===
              subscribeReportAssetRangeType.assetRange
            "
            :title="'report.assetPlaceholder'"
            class="data-range-element"
            :required="false"
          >
            <el-form-item>
              <div
                class="form-report-template"
                v-for="(item, index) in assetsForCurrentUser"
                :key="index"
              >
                <span v-if="item.id === subscriptionToModify.assetId">
                  {{ item.companyAssetId }}
                </span>
              </div>
            </el-form-item>
          </CusFormItem>

          <CusFormItem
            v-if="
              subscriptionToModify.reportAssetScope ===
              subscribeReportAssetRangeType.organizationRange
            "
            :title="'report.organizationPlaceholder'"
            class="data-range-element"
            :required="false"
          >
            <el-form-item>
              <div
                class="form-report-template"
                v-for="(item, index) in orgsForCurrentUser"
                :key="index"
              >
                <span v-if="item.id === subscriptionToModify.organizationId">
                  {{ item.name }}
                </span>
              </div>
            </el-form-item>
          </CusFormItem>

          <CusFormItem
            v-if="subscriptionToModify.reportAssetScope"
            :title="'report.dateRange'"
            class="data-range-element"
            :required="false"
          >
            <el-form-item prop="reportAssetScope">
              <div
                class="form-report-template"
                v-for="(item, index) in subscribeReportDateRangeList"
                :key="index"
              >
                <span
                  v-if="
                    item.value == subscriptionToModify.numberOfReportingDays
                  "
                >
                  {{ $t(item.id) }}
                </span>
              </div>
            </el-form-item>
          </CusFormItem>

          <CusFormItem :title="'report.periodicalFrequency'" :required="true">
            <el-form-item prop="subscriptionFrequency">
              <el-select
                v-model="subscriptionToModify.subscriptionFrequency"
                :placeholder="$t('report.selectPeriodicalFrequency')"
              >
                <el-option
                  v-for="(item, index) in reportTemplateFrequencyList"
                  :key="index"
                  :label="$t(item.id)"
                  :value="item.id"
                />
              </el-select>
            </el-form-item>
          </CusFormItem>

          <CusFormItem :title="'report.subscribedEmail'" :required="true">
            <el-form-item prop="subscribedEmail">
              <el-input
                v-model="subscriptionToModify.subscribedEmail"
                :placeholder="$t('report.inputSubscribeEmail')"
              />
            </el-form-item>
          </CusFormItem>

          <CusFormItem
            v-if="!canChangeSubscriptionStartDate"
            :title="'report.subscribeStartDate'"
            :required="false"
          >
            <el-form-item prop="startDate">
              <div class="form-report-template">
                {{ formatDateTime(subscriptionToModify.startDate) }}
              </div>
            </el-form-item>
          </CusFormItem>

          <CusFormItem
            v-if="canChangeSubscriptionStartDate"
            :title="'report.subscribeStartDate'"
            :required="true"
          >
            <el-form-item prop="startDate">
              <el-date-picker
                class="view-report-date-picker"
                popper-class="date-picker-popper"
                v-model="subscriptionToModify.startDate"
                type="date"
                :placeholder="$t('report.selectDate')"
                :picker-options="pickerOptions"
                :clearable="false"
                value-format="yyyy-MM-dd"
              />
            </el-form-item>
          </CusFormItem>

          <CusFormItem :title="'report.subscribeEndDate'" :required="true">
            <el-form-item prop="endDate">
              <el-date-picker
                class="view-report-date-picker"
                popper-class="date-picker-popper"
                v-model="subscriptionToModify.endDate"
                type="date"
                :placeholder="$t('report.selectDate')"
                :picker-options="pickerOptions"
                :clearable="false"
                value-format="yyyy-MM-dd"
              />
            </el-form-item>
          </CusFormItem>
        </div>

        <CusFormItem :title="'report.subscribeReportFormat'" :required="true">
          <el-form-item prop="reportFormat" v-loading="reportFormatIsLoading">
            <el-select
              v-model="subscriptionToModify.reportFormat"
              :placeholder="$t('report.subscribeReportFormat')"
            >
              <el-option
                v-for="(item, index) in reportFormatList"
                :key="index"
                :label="$t(item.label)"
                :value="item.id"
              />
            </el-select>
          </el-form-item>
        </CusFormItem>
      </el-form>
    </WidgetDialog>
  </div>
</template>

<style scoped>
.report-template-id :deep(.el-form-item__content) {
  display: block !important;
}

.data-range-element :deep(.form-title) {
  padding-left: 22px;
}

.stop-subscription-modal-container :deep(.stop-modal-content) {
  text-align: center;
  word-break: break-word;
}

.stop-subscription-modal-container :deep(.highlight-modal-info) {
  color: var(--Main) !important;
}

.new-cust-dialog :deep(.el-dialog__title) {
  font-size: 20px;
  font-family: var(--fontRobotoMedium);
  line-height: 19px;
  color: #373e41;
  margin-left: 35%;
}

.new-cust-dialog :deep(.el-dialog__headerbtn) {
  top: 10px;
  font-size: 30px;
  color: #373d41;
  position: static;
}
</style>

<style lang="scss" scoped>
.form-report-template {
  font-size: 18px;
  font-family: $font-Roboto-Medium;
  line-height: 24px;
  color: #373e41;

  span {
    margin-left: 5px;
  }
}

.new-cust-dialog :deep(.el-dialog__header) {
  padding: 0px;
  padding-bottom: 0px;
  background-color: var(--Main) !important;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 20px;
  padding-right: 16px;
  height: 44px;
}

.el-button--plain:hover {
  color: #ffffff;
  border-color: #5f6567;
  background-color: #5f6567;
}

.new-cust-dialog :deep(.el-dialog__headerbtn:hover .el-dialog__close) {
  color: #5f6567;
}

.deactivate-btn {
  width: 160px;
  margin-right: 30px;
  font-family: $font-Roboto-Medium;
  font-size: 16px !important;
  border: 1px solid #373e41;
}

.view-report-date-picker:deep(.el-input__prefix) {
  left: 360px;
}

.view-report-date-picker:deep(.el-input__inner) {
  padding-left: 15px;
}
</style>
