<script lang="ts">
import {
  getHealthStatusFactorDefinitions,
  HealthFactorDefinitionResponse,
} from '@/api/healthStatus';
import { ActiveContext, useActiveContext } from '@/auth/context';
import { UserModule } from '@/store/modules/user';
import { HealthStatus } from '@/utils/types/health';
import {
  HEALTH_STATUS_COLOR,
  WarningType,
  WARNING_TO_HEALTH_STATUS_COLOR,
} from '@/utils/workData/lookuptable';
import moment from 'moment';
import { Ref, unref } from 'vue';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

type StatusWithEnable = { status: HealthStatus; isEnabled: boolean };

const warningOrder = [
  WarningType.Alarm,
  WarningType.Warning,
  WarningType.Normal,
];

@Component({
  name: 'AssetHealthFactorList',
})
export default class extends Vue {
  @Prop() currentAsset!: string;

  /** Local variables */
  data: HealthFactorDefinitionResponse[] = [];
  listData: HealthFactorDefinitionResponse[] = [];
  context!: Ref<ActiveContext>;
  isFactorDefinitionLoading: boolean = false;

  //TODO: translate
  statusWithEnableList: StatusWithEnable[] = Object.entries(
    HEALTH_STATUS_COLOR
  ).map(([key, value]) => ({
    status: { name: key, color: value },
    isEnabled: true,
  }));

  async created() {
    this.context = useActiveContext();
    await this.getData();
  }

  async getData() {
    this.isFactorDefinitionLoading = true;
    const activeContext: ActiveContext = unref(this.context);
    const healtsStatusFactors = await getHealthStatusFactorDefinitions(
      this.currentAsset,
      activeContext,
      UserModule.i18nCode
    );
    this.data = healtsStatusFactors.data;
    this.data.forEach(
      (entry) =>
        (entry.lastChangeTimestamp = moment
          .utc(entry.lastChangeTimestamp)
          .format('YYYY/MM/DD HH:mm:ss'))
    );
    this.updateData();
  }

  @Watch('currentAsset')
  async currentAssetChanged(newValue: string, oldValue: string) {
    this.currentAsset = newValue;

    await this.getData();
  }

  /** Update requested data */
  updateData() {
    this.listData = this.data
      .filter(({ healthCondition }) => {
        const index = Object.values(WarningType).indexOf(healthCondition!);
        return this.statusWithEnableList[index].isEnabled;
      })
      .sort((a, b) => {
        let order =
          warningOrder.indexOf(a.healthCondition!) -
          warningOrder.indexOf(b.healthCondition!);

        if (order != 0) return order;

        // some order index, should be sorted by dates instead
        return (
          new Date(b.lastChangeTimestamp!).getTime() -
          new Date(a.lastChangeTimestamp!).getTime()
        );
      });
    this.isFactorDefinitionLoading = false;
  }

  async toggleStatus(index: number) {
    this.statusWithEnableList[index].isEnabled =
      !this.statusWithEnableList[index].isEnabled;
    this.updateData();
  }

  statusColorFromCondition(warning: WarningType): string {
    return WARNING_TO_HEALTH_STATUS_COLOR[warning];
  }
}
</script>

<template>
  <div
    class="container"
    v-loading.lock="isFactorDefinitionLoading"
    :element-loading-text="$t('healthManagementModule.healthFactorsAreLoading')"
  >
    <div class="status-list">
      <div
        v-for="(statusWithEnable, index) in statusWithEnableList"
        v-bind:key="index"
        @click="toggleStatus(index)"
        class="status-item"
        :style="{ opacity: statusWithEnable.isEnabled ? 1 : 0.5 }"
      >
        <span
          class="status-item-icon"
          :style="{ backgroundColor: statusWithEnable.status.color }"
        />
        {{ $t('assets.' + statusWithEnable.status.name.toLowerCase()) }}
      </div>
    </div>
    <!--    TODO: implement infinite scroll-->
    <ul class="asset-status-list">
      <li
        v-for="(status, index) in listData"
        :key="status.code + '_' + index"
        class="asset-status-item"
      >
        <div class="asset-status-text-container">
          <span
            class="asset-status-icon"
            :style="{
              /* @ts-expect-error TODO Wrong type */
              backgroundColor: statusColorFromCondition(status.healthCondition),
            }"
          />
          <div class="asset-status-text-container-sub">
            <span class="asset-status-subject">{{ status.name }}</span>
            <span class="asset-status-description">{{
              status.healthConditionDescription
            }}</span>
          </div>
        </div>
        <span class="asset-status-timestamp">{{
          status.lastChangeTimestamp
        }}</span>
      </li>
    </ul>
  </div>
</template>

<style scoped>
.container {
  height: 300px;
  padding: 10px 25px;
  overflow: auto;
}

.status-list {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.status-item {
  width: 28%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 8px 10px;
  border: 1px solid gray;
  border-radius: 5px;
  font-weight: 600;
  background-color: white;
  cursor: pointer;
  transition: background-color 0.1s ease-in-out;
}
.status-item:hover {
  background-color: #ececec;
}

.status-item-icon,
.asset-status-icon {
  height: 16px;
  width: 16px;
  border-radius: 50%;
}

.asset-status-list {
  height: 300px;
  padding: 0;
  margin: 15px 0 0 0;
  list-style: none;
}
.asset-status-list .asset-status-item {
  display: flex;
  justify-content: space-between;
  padding: 15px 20px;
  border-bottom: 1px solid #797979;
}
.asset-status-list .asset-status-item:last-child {
  border-bottom: none;
}
.asset-status-list .asset-status-item + .list-item {
  margin-top: 10px;
}
.asset-status-text-container {
  display: flex;
  gap: 20px;
}
.asset-status-text-container-sub {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.asset-status-subject {
  font-size: 14px;
  font-weight: 600;
}
.asset-status-description {
  font-size: 12px;
}
.asset-status-timestamp {
  font-size: 11px;
  color: #797979;
}
</style>
