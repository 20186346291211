<script lang="ts" setup>
import logout from '@/assets/imgs/logout.svg';
import ICPNumber from '@/components/layout/ICPNumber.vue';
import SidebarItem from '@/components/layout/SidebarItem.vue';
import { useRoute } from '@/composables/router';
import { PermissionModule } from '@/store/modules/permission';
import { UserModule } from '@/store/modules/user';
import { computed } from 'vue';
import ContextSelector from './select/ContextSelector.vue';

const props = defineProps<{
  collapsed: boolean;
  desktop: boolean;
  canImpersonate: boolean;
}>();
const emit = defineEmits<{
  (e: 'collapse'): void;
  (e: 'logout'): void;
}>();

const routes = computed(() => PermissionModule.routes);
const name = computed(() => UserModule.username);

const route = useRoute();

const activeMenu = computed(() => {
  const { meta, path } = route.value;

  if (meta?.activeMenu) {
    return meta.activeMenu;
  }

  /**
   * TODO
   * This is a template method to solve the menu highlighting problem
   * Defect:
   *  1. Only applicable to two-level menu. If it exceeds three levels, it may be invalid
   *  2. This method is not concise
   *
   * Suggestion:
   *  I think the better way is to add property to judge whether highlight menu in 'meta'
   *
   */
  let activePath = '';
  let pathNameArr = path.split('/').slice(1); // strip the first empty string

  // TODO: remove all of this and fix it properly
  if (pathNameArr.length > 1) {
    if (meta?.hidden) {
      if (meta.thirdLevelMenu) {
        activePath = '/' + pathNameArr[0] + '/' + pathNameArr[1];
      } else {
        activePath = '/' + pathNameArr[0] + '/index';
      }
    } else {
      activePath = '/' + pathNameArr[0] + '/' + pathNameArr[1];
    }
  } else {
    activePath = '/' + pathNameArr[0];
  }

  return activePath;
});
</script>

<template>
  <div :class="['sidebar', props.collapsed ? 'collapsed' : 'expanded']">
    <div class="user">
      <span class="username">
        {{ name }}
      </span>
      <ContextSelector />
    </div>
    <el-menu
      :router="true"
      :default-active="activeMenu"
      :collapse="props.collapsed && props.desktop"
      :collapse-transition="false"
    >
      <SidebarItem
        v-for="(route, index) of routes"
        :key="index"
        :route="route"
        :base-path="route.path"
        :collapsed="props.collapsed"
        @collapse="emit('collapse')"
      />
      <el-menu-item class="logout" @click="emit('logout')">
        <img class="logout-icon" :src="logout" />
        Logout
      </el-menu-item>
    </el-menu>
    <el-footer>
      <ICPNumber class="bottom-bar" />
    </el-footer>
  </div>
</template>

<style lang="css">
/* Element-UI uses display: none for the menu items that are collapsed
 * which is not right, because it does not consider the width of those elements.
 * This means that if a collapsed menu item had a longer title, than any other items
 * when we expanded that menu item, and made it visible, the whole sidebar would expand with it, which would look clunky.
 * Because visibility: hidden takes note of the hidden element's width the Sidebar has the right width.
*/
ul[style='display: none;'][role='menu'] {
  display: block !important;
  visibility: hidden;
  height: 0;
}
</style>

<style lang="scss" scoped>
.el-footer {
  height: 60px;
  z-index: 3000;

  padding: 0 20px;
  box-sizing: border-box;
  flex-shrink: 0;
}

.el-menu {
  flex: 1;
  display: flex;
  flex-direction: column;
  border: none;
}

.sidebar {
  position: fixed;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  top: 0;
  left: 0;
  height: 100%;
  background-color: #ffffff;
  overflow: hidden;
  z-index: 2000;

  // READ MORE: https://css-tricks.com/using-css-transitions-auto-dimensions/#aa-technique-1-max-height
  max-width: 600px;
  min-width: 4rem;
  transition: max-width 0.25s ease-out, min-width 0.25s ease-out;

  &.collapsed {
    max-width: 4rem;

    @media (max-width: 768px) {
      transform: translateX(-100%);
    }
  }

  &.expanded {
    min-width: 200px;
  }

  @media (min-width: 769px) {
    position: static;
    height: auto;
    padding: 0.5rem 0;
    padding-bottom: 0;
    box-shadow: 0 3px 6px #c1c1c1;
  }
}

.user {
  display: flex;
  flex-direction: column;
  padding: 1.25rem;
  margin-bottom: 0.75rem;
  border-bottom: 1px solid #dddddd;

  @media (min-width: 769px) {
    display: none;
  }

  &name {
    margin-bottom: 1rem;
  }
}

.logout {
  margin-top: auto;
  &-icon {
    height: 1rem;
    margin-right: 1.5rem;
  }

  @media (min-width: 769px) {
    display: none;
  }
}
</style>
