<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
  name: 'BaseInfos',
})
export default class extends Vue {
  @Prop({ required: true }) name!: string;
  @Prop({ required: false }) value: string | number | undefined;
}
</script>

<template>
  <div class="base-infos-container">
    <div class="base-infos-title">
      <span>{{ $t(name) }}:</span>
      <slot name="tipInfo"></slot>
    </div>
    <div class="base-infos-value-container">
      <slot></slot>
      <span class="base-infos-value">{{
        /* @ts-expect-error TODO Wrong type */
        $t(value)
      }}</span>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.base-infos-container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 45px;

  .base-infos-title {
    flex-shrink: 0;
    width: $inputTitleWidth;
    margin-right: 20px;
    text-align: left;

    font-size: 16px;
    font-family: $font-Roboto-Regular;
    font-weight: 400;
    letter-spacing: 0em;
    text-align: left;
    color: #373e41;
  }

  .base-infos-value-container {
    display: flex;
    min-width: 0;
  }

  .base-infos-value {
    font-size: 16px;
    font-weight: bold;
    font-family: $font-Roboto-Regular;
    color: #373e41;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    display: inline-block;
  }
}
</style>
