<script lang="ts">
import {
  AssetObjectModel,
  fetchAssetsByFlexibleFiltering,
  OrganizationAssetsHierarchyResponseLegacy,
} from '@/api/assets';
import { getOrganizations, Organization } from '@/api/organizations';
import {
  fetchReportTemplatesByCustomParams,
  getReportTemplates,
  ReportTemplate,
} from '@/api/report';
import { ChangedReport, SelectOption } from '@/api/reportSubscription';
import {
  ActiveContext,
  flattenOrganizations,
  useActiveContext,
} from '@/auth/context';
import WidgetDialog from '@/components/dialog/WidgetDialog.vue';
import CusFormItem from '@/components/form/CusFormItem.vue';
import {
  Filter,
  FilterOperator,
  Pagination,
  QueryParameter,
  Sorter,
  SorterOrder,
} from '@/model/queryParameters/QueryParameter';
import { UserModule } from '@/store/modules/user';
import { disabledPastDate } from '@/utils/date';
import {
  ACTIVATION_STATUS,
  SUBSCRIBE_REPORT_ASSET_SCOPES,
  SUBSCRIBE_REPORT_DATE_RANGE_TYPE_LIST,
  SUBSCRIBE_REPORT_TEMPLATE_FREQUENCY_LIST,
} from '@/utils/workData/lookuptable';
import moment from 'moment';
import { Ref, unref } from 'vue';
import { Component, Prop, Vue } from 'vue-property-decorator';
import {
  dateFormat,
  getAssetTypesFromCompanySubscription,
  reportTemplateName,
} from '../report';

type SimpleAsset = {
  id: string;
  assetId: string;
};

@Component({
  name: 'CreateNewReportSubscribtionModal',
  components: {
    'cus-form-item': CusFormItem,
    'widget-dialog': WidgetDialog,
  },
})
export default class extends Vue {
  /** Local variables */
  @Prop({ default: false }) visible!: boolean;
  @Prop({ required: true }) title!: string;
  @Prop() organizations?: OrganizationAssetsHierarchyResponseLegacy[];
  @Prop() isReportTemplateSelected?: boolean;
  @Prop() templateId?: string;

  /** Local variables */
  reportTemplateFrequencyList = SUBSCRIBE_REPORT_TEMPLATE_FREQUENCY_LIST;
  reportFormatList = [] as string[];
  subscribeReportAssetRangeType: any = SUBSCRIBE_REPORT_ASSET_SCOPES;
  subscribeReportDateRangeList = SUBSCRIBE_REPORT_DATE_RANGE_TYPE_LIST;

  reportFormValues: ChangedReport = {} as ChangedReport;
  reportTemplateList: ReportTemplate[] = [];
  assetsForCurrentUser: AssetObjectModel[] | SimpleAsset[] = [];
  orgsForCurrentUser: SelectOption[] | [] = [];
  generalQueryData: any = {
    pageNumber: 1,
    pageSize: UserModule.gridPageSize,
    searchParams: [
      {
        reference: null,
        operator: null,
        value: null,
      },
    ],
    sortByAndOrder: [
      {
        sortBy: null,
        order: null,
      },
    ],
  };
  context!: Ref<ActiveContext>;
  availableAssetTypeFromSubscriptions: string[] | [] = [];
  page: number = 1;
  pageSize: number = 10000;
  defaultSortReference: string = 'CREATED_ON';
  defaultFilterReference: string = 'ASSET_TYPE';

  created() {
    this.context = useActiveContext();
    this.initializeDefaultSettings();
  }

  /**
   * Initialize default settings
   */
  async initializeDefaultSettings(): Promise<void> {
    await this.fetchAvailableSubscriptionsAssetTypes();
    await this.fetchReportTemplates();
    await this.fetchOrgsForCurrentUser();

    const res = await getReportTemplates();
    if (res.code === 200) {
      this.reportFormatList = res.data;
    }
  }

  /**
   * Prepare query parameters for API request
   */
  get reportTemplatesQueryParameters(): QueryParameter {
    const filters: Filter[] = [];
    filters.push({
      name: this.defaultFilterReference,
      operator: FilterOperator.IN,
      value: this.availableAssetTypeFromSubscriptions,
    });

    const sorters: Sorter[] = [];
    sorters.push({
      field: this.defaultSortReference,
      order: SorterOrder.DESC,
    });

    const pagination: Pagination = {
      page: this.page,
      size: this.pageSize,
    };
    const queryParameters: QueryParameter = {
      filters: filters,
      sorters: sorters,
      pagination: pagination,
    };

    return queryParameters;
  }

  /**
   * Fetch available asset types from logged in user company subscriptions
   * Used to show in the dialog modal on the report template dropdown
   * Only those report templates that have available asset type
   * ! When there are no asset types available, mandatory a random text must be provided !
   * ! For the query params to not retrieve any report templates with real asset type !
   */
  async fetchAvailableSubscriptionsAssetTypes(): Promise<void> {
    try {
      /** Random text to match non existing asset type */
      const randomQueryParametersValue: string =
        'SUBSCRIPTION_WITH_NO_AVAILABLE_ASSET_TYPES';

      const res: string[] | undefined =
        await getAssetTypesFromCompanySubscription();

      this.availableAssetTypeFromSubscriptions =
        res && res.length > 0 ? [...res] : [randomQueryParametersValue];
    } catch (error) {
      console.log(error);
    } finally {
    }
  }

  /**
   * Template changed event
   */
  async templateChanged(): Promise<void> {
    await this.fetchAssetsForCurrentUser();
    this.reportFormValues[reportTemplateName] = this.reportTemplateList.find(
      (r: ReportTemplate) => r.id == this.reportFormValues.reportTemplateId
    )?.name;
  }

  /** Fetch remotly form API report template data */
  async fetchReportTemplates(): Promise<void> {
    try {
      const res = await fetchReportTemplatesByCustomParams(
        this.reportTemplatesQueryParameters
      );
      if (res.code === 200 && res.data.reportTemplates.length > 0) {
        this.reportTemplateList = res.data.reportTemplates;
      }
    } catch (error) {
      console.log(error);
    } finally {
    }
  }

  /**
   * Retrieve assets that current user can have access and by asset type of the choosen report template
   */
  async fetchAssetsForCurrentUser(): Promise<void> {
    try {
      const APIResponse = await fetchAssetsByFlexibleFiltering(
        this.assetsQueryParameters
      );
      const response = APIResponse.data.assets;
      if (!response) return;

      if (APIResponse.code === 200 && response) {
        this.assetsForCurrentUser = response.map(
          (item): SimpleAsset => ({
            id: item.id,
            assetId: item.companyAssetId,
          })
        );
      }
    } catch (error) {
      console.log(error);
    } finally {
    }
  }

  /**
   * Generate assets request query parameters
   */
  get assetsQueryParameters(): QueryParameter {
    let finalQueryParameters: QueryParameter = {};
    const finalFilters: Filter[] = [];

    finalFilters.push({
      name: 'organizationId',
      operator: FilterOperator.IN,
      value: this.getActiveOrgsIds(),
    });

    const reportAssetType: string | undefined = this.reportTemplateList.find(
      (report: any) => report.id == this.reportFormValues.reportTemplateId
    )?.assetType;
    if (reportAssetType) {
      finalFilters.push({
        name: 'assetType',
        operator: FilterOperator.EQUAL,
        value: [reportAssetType],
      });
    }

    finalQueryParameters.filters = finalFilters;
    finalQueryParameters.pagination = { page: 1, size: 10000 };
    return finalQueryParameters;
  }

  /**
   * Fetch active organizations UUIDs from curent active context, from same level as org towards bellow as logged in user
   * @returns string[]
   */
  private getActiveOrgsIds(): string[] {
    return flattenOrganizations(unref(this.context).organization!).reduce(
      function (result: string[], currentOrg: Organization) {
        if (currentOrg.activationStatus != ACTIVATION_STATUS.Deactivated) {
          result.push(currentOrg.id);
        }

        return result;
      },
      []
    );
  }

  /** Retrieve organizations that current user can have access */
  async fetchOrgsForCurrentUser() {
    try {
      this.orgsForCurrentUser = [];
      const res = await getOrganizations(UserModule.companyId);
      const response = res.data;
      if (!res) return;

      if (res.code === 200 && response) {
        this.orgsForCurrentUser = flattenOrganizations(response)?.filter(
          (org: Organization) =>
            org.activationStatus == ACTIVATION_STATUS.Activated
        );
      }
    } catch (error) {
      console.log(error);
    } finally {
    }
  }

  /**
   * Close modal dialog
   */
  closeDialog(): void {
    this.$emit('handle-cancel');
  }

  cancelModal() {
    this.$emit('handle-cancel');
  }

  /**
   * Handle submit
   */
  async submit(): Promise<void> {
    const data = {
      name: this.reportFormValues.name,
      reportTemplateId: this.templateId,
      reportAssetScope: this.reportFormValues.reportAssetScope,
      subscriptionFrequency: this.reportFormValues.subscriptionFrequency,
      subscribedEmail: this.reportFormValues.subscribedEmail,
      startDate: this.reportFormValues.startDate,
      endDate: this.reportFormValues.endDate,
      reportFormat: this.reportFormValues.reportFormat,
      numberOfReportingDays: this.reportFormValues.numberOfReportingDays,
      ...(this.reportFormValues.reportAssetScope ===
      this.subscribeReportAssetRangeType.assetRange
        ? {
            assetId: this.reportFormValues.assetId,
          }
        : {
            organizationId: this.reportFormValues.organizationId,
          }),
    };
    (this.$refs.refSubcribeReportForm as any).validate((valid: any) => {
      if (valid) {
        this.$emit('handle-create-subscribed-report', data);
      }
    });
  }

  /**
   * Handle close modal dialog
   */
  close(): void {
    this.$emit('update:visible', false);
  }

  /** Handle event emit when confirm & valid */
  handleSubscribedReportModification() {
    (this.$refs.refSubcribeReportForm as any).validate((valid: any) => {
      if (valid) {
        this.$emit('handle-create-subscribed-report', this.reportFormValues);
      }
    });
  }

  pickerOptions: any = {
    disabledDate: disabledPastDate,
  };

  /** Rules definition for the input form */
  get rules() {
    const tmpRules = {
      name: [
        {
          required: true,
          message: this.$t('report.tipInputReportName'),
          pattern: /^\S+/,
          trigger: 'change',
        },
        { validator: this.validateName, trigger: 'change' },
      ],
      reportTemplateId: [
        {
          required: !this.isReportTemplateSelected,
          message: this.$t('report.tipInputReportTemplateName'),
          pattern: /^\S+/,
          trigger: 'change',
        },
        { validator: this.validateName, trigger: 'change' },
      ],
      reportAssetScope: [
        { required: true, message: this.$t('report.tipInputReportAssetRange') },
        { validator: this.validateName, trigger: ['blur', 'change'] },
      ],
      assetId: [
        {
          required:
            this.reportFormValues.reportAssetScope ===
            this.subscribeReportAssetRangeType.assetRange,
          message: this.$t('report.tipInputReportAsset'),
          trigger: 'change',
          pattern: /^\S+/,
        },
        { validator: this.validateName, trigger: 'change' },
      ],
      organizationId: [
        {
          required:
            !this.isReportTemplateSelected &&
            this.reportFormValues.reportAssetScope ===
              this.subscribeReportAssetRangeType.organizationRange,
          message: this.$t('report.tipInputReportOrganization'),
          trigger: 'change',
          pattern: /^\S+/,
        },
        { validator: this.validateName, trigger: 'change' },
      ],
      numberOfReportingDays: [
        { required: true, message: this.$t('report.tipInputReportDateRange') },
        { validator: this.validateName, trigger: ['blur', 'change'] },
      ],
      subscriptionFrequency: [
        { required: true, message: this.$t('report.pleaseSelectFrequency') },
        {
          message: this.$t('report.pleaseSelectFrequency'),
          trigger: ['blur', 'change'],
        },
      ],
      subscribedEmail: [
        { required: true, message: this.$t('common.tipInputEmail') },
        {
          type: 'email',
          message: this.$t('common.tipInputCorrectEmail'),
          trigger: ['blur', 'change'],
        },
      ],
      startDate: [
        { required: true, message: this.$t('common.tipSelectDate') },
        { validator: this.validateStartDate, trigger: 'blur' },
      ],
      endDate: [
        { required: true, message: this.$t('common.tipSelectDate') },
        { validator: this.validateEndDate, trigger: 'blur' },
      ],
      reportFormat: [
        {
          required: true,
          message: this.$t('report.pleaseSelectAReportFormat'),
          pattern: /^\S+/,
          trigger: 'change',
        },
        { validator: this.validateName, trigger: 'change' },
      ],
    };

    return tmpRules;
  }

  validateStartDate = (rule: any, value: any, callback: any) => {
    let end = moment(this.reportFormValues.startDate).format(dateFormat);
    if (end) (this.$refs.refSubcribeReportForm as any).validateField('endDate');
    callback();
  };

  validateEndDate = (rule: any, value: any, callback: any) => {
    let end = new Date(moment(value).format(dateFormat));
    let start = new Date(
      moment(this.reportFormValues.startDate).format(dateFormat)
    );

    if (moment(start).isAfter(end)) {
      callback(new Error(`${this.$t('report.tipSelectCorrectDate')}`));
    } else {
      callback();
    }
  };

  validateName = (rule: any, value: any, callback: any) => {
    if (!value || value === undefined) return callback();
    if (value.length < 1 || value.length > 100) {
      callback(
        new Error(`${this.$t('common.inputValidationOneToOneHundred')}`)
      );
    } else {
      callback();
    }
  };

  /**  Clean up the optional field that is not needed for request payload */
  handleAssetRangeChanges(type: string) {
    if (type === this.subscribeReportAssetRangeType.assetRange) {
      delete this.subscribeReportAssetRangeType.organizationId;
    } else {
      delete this.subscribeReportAssetRangeType.assetId;
    }
    if (this.isReportTemplateSelected && !!this.organizations?.length) {
      switch (this.reportFormValues.reportAssetScope) {
        case this.subscribeReportAssetRangeType.assetRange:
          this.assetsForCurrentUser = this.organizations
            .flatMap(({ organization }) => organization.assets)
            .map((asset) => ({
              // @ts-expect-error Refactor to new asset hierarchy
              id: asset.id,
              // @ts-expect-error Refactor to new asset hierarchy
              assetId: asset.companyAssetId,
            }));
        case this.subscribeReportAssetRangeType.organizationRange:
          this.orgsForCurrentUser = this.organizations.map(
            ({ organization }) =>
              ({
                id: organization.id,
                name: organization.name,
              } as SelectOption)
          );
      }
    }
  }
}
</script>

<template>
  <div>
    <widget-dialog
      :visible.sync="visible"
      :confirmBtnName="$t('common.subscribe')"
      :title="$t(title)"
      :width="'800px'"
      @handle-cancel="!!isReportTemplateSelected ? close() : cancelModal()"
      @handle-confirm="
        !!isReportTemplateSelected
          ? submit()
          : handleSubscribedReportModification()
      "
    >
      <el-form
        style="margin: 32px 0px 40px 40px"
        ref="refSubcribeReportForm"
        :model="reportFormValues"
        :rules="rules"
        :inline-message="false"
      >
        <div>
          <cus-form-item :title="'report.reportName'">
            <el-form-item prop="name">
              <el-input
                v-model="reportFormValues.name"
                :placeholder="$t('report.inputReportName')"
              />
            </el-form-item>
          </cus-form-item>

          <cus-form-item
            v-if="!isReportTemplateSelected"
            :title="'report.reportTemplate'"
          >
            <el-form-item prop="reportTemplateId">
              <el-select
                v-model="reportFormValues.reportTemplateId"
                :placeholder="$t('report.inputReportTemplateName')"
                @change="templateChanged"
              >
                <el-option
                  class="form-report-template"
                  v-for="(item, index) in reportTemplateList"
                  :key="index"
                  :label="item.name"
                  :value="item.id"
                />
              </el-select>
            </el-form-item>
          </cus-form-item>

          <cus-form-item
            :title="'report.assetRange'"
            class="data-range-element"
          >
            <el-form-item prop="reportAssetScope">
              <el-select
                v-model="reportFormValues.reportAssetScope"
                :placeholder="$t('report.inputAssetRange')"
                @change="handleAssetRangeChanges"
                :disabled="
                  !isReportTemplateSelected &&
                  (!reportFormValues.reportTemplateId ||
                    reportFormValues.reportTemplateId.length == 0)
                "
              >
                <el-option
                  class="form-report-template"
                  v-for="(item, index) in subscribeReportAssetRangeType"
                  :key="index"
                  :label="$t(item)"
                  :value="item"
                />
              </el-select>
            </el-form-item>
          </cus-form-item>

          <cus-form-item
            v-if="
              reportFormValues.reportAssetScope ===
              subscribeReportAssetRangeType.assetRange
            "
            :title="'report.selectAsset'"
            class="data-range-element"
          >
            <el-form-item prop="assetId">
              <el-select
                v-model="reportFormValues.assetId"
                :placeholder="$t('report.inputAsset')"
              >
                <el-option
                  class="form-report-template"
                  v-for="(item, index) in assetsForCurrentUser"
                  :key="index"
                  :label="item.assetId"
                  :value="item.id"
                />
              </el-select>
            </el-form-item>
          </cus-form-item>

          <cus-form-item
            v-if="
              reportFormValues.reportAssetScope ===
              subscribeReportAssetRangeType.organizationRange
            "
            :title="'report.selectOrganization'"
            class="data-range-element"
          >
            <el-form-item prop="organizationId">
              <el-select
                v-model="reportFormValues.organizationId"
                :placeholder="$t('report.inputOrganization')"
              >
                <el-option
                  class="form-report-template"
                  v-for="(item, index) in orgsForCurrentUser"
                  :key="index"
                  :label="item.name"
                  :value="item.id"
                />
              </el-select>
            </el-form-item>
          </cus-form-item>

          <cus-form-item
            v-if="
              !!isReportTemplateSelected || reportFormValues.reportAssetScope
            "
            :title="'report.dateRange'"
            class="data-range-element"
          >
            <el-form-item prop="numberOfReportingDays">
              <el-select
                v-model="reportFormValues.numberOfReportingDays"
                :placeholder="$t('report.inputDateRange')"
              >
                <el-option
                  class="form-report-template"
                  v-for="(item, index) in subscribeReportDateRangeList"
                  :key="index"
                  :label="$t(item.id)"
                  :value="item.value"
                />
              </el-select>
            </el-form-item>
          </cus-form-item>

          <cus-form-item :title="'report.periodicalFrequency'">
            <el-form-item prop="subscriptionFrequency">
              <el-select
                v-model="reportFormValues.subscriptionFrequency"
                :placeholder="$t('report.selectPeriodicalFrequency')"
              >
                <el-option
                  v-for="(item, index) in reportTemplateFrequencyList"
                  :key="index"
                  :label="$t(item.id)"
                  :value="item.id"
                />
              </el-select>
            </el-form-item>
          </cus-form-item>

          <cus-form-item :title="'report.subscribedEmail'">
            <el-form-item prop="subscribedEmail">
              <el-input
                v-model="reportFormValues.subscribedEmail"
                :placeholder="$t('report.inputSubscribeEmail')"
              />
            </el-form-item>
          </cus-form-item>

          <cus-form-item :title="'report.subscribeStartDate'">
            <el-form-item prop="startDate">
              <el-date-picker
                class="view-report-date-picker"
                popper-class="date-picker-popper"
                v-model="reportFormValues.startDate"
                type="date"
                :placeholder="$t('report.selectDate')"
                value-format="yyyy-MM-dd"
                :picker-options="pickerOptions"
                :clearable="false"
              />
            </el-form-item>
          </cus-form-item>

          <cus-form-item :title="'report.subscribeEndDate'">
            <el-form-item prop="endDate">
              <el-date-picker
                class="view-report-date-picker"
                popper-class="date-picker-popper"
                v-model="reportFormValues.endDate"
                type="date"
                :placeholder="$t('report.selectDate')"
                value-format="yyyy-MM-dd"
                :picker-options="pickerOptions"
                :clearable="false"
              />
            </el-form-item>
          </cus-form-item>
        </div>

        <cus-form-item :title="'report.subscribeReportFormat'">
          <el-form-item prop="reportFormat">
            <el-select
              v-model="reportFormValues.reportFormat"
              :placeholder="$t('report.subscribeReportFormat')"
            >
              <el-option
                v-for="(item, index) in reportFormatList"
                :key="index"
                :label="$t(item)"
                :value="item"
              />
            </el-select>
          </el-form-item>
        </cus-form-item>
      </el-form>
    </widget-dialog>
  </div>
</template>

<style scoped>
:deep(.el-date-editor.el-input) {
  width: 100%;
}

.data-range-element :deep(.form-title) {
  padding-left: 22px;
}

.stop-subscription-modal-container :deep(.stop-modal-content) {
  text-align: center;
  word-break: break-word;
}

.stop-subscription-modal-container :deep(.highlight-modal-info) {
  color: var(--Main) !important;
}

.new-cust-dialog :deep(.el-dialog__title) {
  font-size: 20px;
  font-family: var(--fontRobotoMedium);
  line-height: 19px;
  color: #373e41;
  margin-left: 35%;
}

.new-cust-dialog :deep(.el-dialog__headerbtn) {
  top: 10px;
  font-size: 30px;
  color: #373d41;
  position: static;
}

.date-picker-popper .el-time-panel__btn.confirm {
  font-weight: 500;
  color: var(--Main);
}

.date-picker-popper .el-input__inner:focus {
  border: 1px solid #dcdfe6;
}

.date-picker-popper .el-date-table td.today span {
  color: var(--Main);
}

.date-picker-popper .el-date-table td.available:hover {
  color: var(--Main);
}

.date-picker-popper .el-date-table td.current:not(.disabled) span {
  color: #ffffff;
  background-color: var(--Main);
}

.date-picker-popper .el-picker-panel__footer .el-button--mini {
  border: 1px solid #dcdfe6;
}

.date-picker-popper .el-picker-panel__footer .el-button--text {
  border: transparent !important;
  color: #3e3741;
}

.date-picker-popper .el-button.is-plain:hover,
.el-button.is-plain:focus {
  color: var(--Main);
}
</style>

<style lang="scss" scoped>
.new-cust-dialog :deep(.el-dialog__header) {
  padding: 0px;
  padding-bottom: 0px;
  background-color: var(--Main) !important;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 20px;
  padding-right: 16px;
  height: 44px;
}

.el-button--plain:hover {
  color: #ffffff;
  border-color: #5f6567;
  background-color: #5f6567;
}

.new-cust-dialog :deep(.el-dialog__headerbtn:hover .el-dialog__close) {
  color: #5f6567;
}

.deactivate-btn {
  width: 160px;
  margin-right: 30px;
  font-family: $font-Roboto-Medium;
  font-size: 16px !important;
  border: 1px solid #373e41;
}

.view-report-date-picker:deep(.el-input__prefix) {
  left: 360px;
  right: 0px;
}

.view-report-date-picker:deep(.el-input__inner) {
  padding-left: 15px;
}
</style>
