import { SorterOrder } from '@/model/queryParameters/QueryParameter';
import { api } from '@/utils/api';
import { getRequest } from '@/utils/request';
import { UUID } from './common';
import { CommonResultPromise } from './commonResult';
import { HyvaRegionCode } from './hyvaRegion';
import { ServiceRegionCode } from './serviceRegions';
import { IActivationStatus } from './types';

export function getCustomers(
  page: number,
  size: number,
  filter: string = ''
): CommonResultPromise {
  const request = getRequest();
  const response = request.request({
    url: `/companies?page=${page}&size=${size}${filter}`,
    method: 'get',
  });
  return response;
}

export function getCustomersByCustomUrl(
  customUrl: any
): CommonResultPromise<FetchCustomersResponse> {
  const request = getRequest();
  const response = request.request({
    url: `/companies${customUrl}`,
    method: 'get',
  });
  return response;
}

// TODO: Actually have an endpoint that retrieves all customers
//       This endpoint retrieves only the first 10 without any params.
export function getAllCustomers(
  params: FetchCustomerParams
): CommonResultPromise<FetchCustomersResponse> {
  const request = getRequest();
  const response = request.request({
    url: `/companies`,
    method: 'get',
    params,
  });
  return response;
}

export function getCustomerById(
  id: UUID
): CommonResultPromise<CompanyResponseModel> {
  const request = getRequest();
  const response = request.request<CompanyResponseModel>({
    url: `/companies/${id}`,
    method: 'get',
  });
  return response;
}

export async function fetchCustomerById(
  id: UUID
): Promise<CompanyResponseModel> {
  return api().get(`/companies/${id}`);
}

export function updateCustomer(id: string, data: any): CommonResultPromise {
  const request = getRequest();
  const response = request.request({
    url: `/companies/${id}`,
    method: 'put',
    data,
  });
  return response;
}

export function createCustomer(
  requestBody: FormData,
  request = getRequest()
): CommonResultPromise<CompanyResponseModel> {
  const response = request.post<CompanyResponseModel>(
    '/companies',
    requestBody
  );
  return response;
}

export function deleteCustomer(id: string): CommonResultPromise {
  const request = getRequest();
  const response = request.request({
    url: `/companies/${id}`,
    method: 'delete',
  });
  return response;
}

export function updateActivationStatus(
  id: string,
  data: IActivationStatus
): CommonResultPromise {
  const request = getRequest();
  const response = request.request({
    url: `/companies/${id}/activation`,
    method: 'put',
    data,
  });
  return response;
}

export interface FetchCustomerParams {
  page: number;
  size: number;
  sortBy: string;
  order: SorterOrder;
  searchFieldName?: string;
  searchFieldValues?: string[];
}

export interface FetchCustomersResponse {
  companies: CustomerModel[];
  links: CustomerLinkModel[];
  count: number;
  total: number;
}

export interface CustomerModel {
  activationStatus: string;
  assetCount: number;
  city: string;
  companyType: string;
  contactPersonEmail: string;
  contactPersonName: string;
  contactPersonPhone: string;
  contractNumber: string;
  serviceRegion: ServiceRegionCode;
  hyvaRegion: HyvaRegionCode;
  deleted: boolean;
  district: string;
  id: string;
  industry: string;
  name: string;
  note: string;
  organizationCount: number;
  primaryOrganizationId: string;
  primaryUserEmail: string;
  primaryUserId: string;
  province: string;
  refreshDataInterval: number;
  street: string;
  timeZone: string;
  userCount: number;
  zipCode: string;
}

export interface CustomerLinkModel {
  first: LinkModel;
  last: LinkModel;
  next: LinkModel;
  self: LinkModel;
}

export interface LinkModel {
  href: string;
}

export interface CompanyResponseModel {
  id: string;
  name: string;
  primaryUserId: string;
  primaryUserEmail: string;
  installerCode: string;
  contractNumber: string;
  companyType: string;
  note: string;
  contactPersonName: string;
  contactPersonEmail: string;
  contactPersonPhone: string;
  primaryOrganizationId: string;
  industry: string;
  street: string;
  district: string;
  province: string;
  city: string;
  serviceRegion: ServiceRegionCode;
  hyvaRegion: HyvaRegionCode;
  zipCode: string;
  organizationCount: number;
  userCount: number;
  assetCount: number;
  activationStatus: string;
  setting: string;
  refreshDataInterval: number;
  isDeleted: string;
  amountOfUsers?: string;
  contractStartDate?: string;
  contractEndDate?: string;
}

export interface CompanyRequestModel {
  id?: string;
  name: string;
  primaryUserId: string;
  primaryUserEmail: string;
  contractNumber: string;
  companyType: string;
  note: string;
  contactPersonName: string;
  contactPersonEmail: string;
  contactPersonPhone: string;
  primaryOrganizationId: string;
  industry: string;
  street: string;
  district: string;
  province: string;
  city: string;
  country: string;
  zipCode: string;
  activationStatus: string;
  setting: string;
  refreshDataInterval: number;
  isDeleted: string;
  contractStartDate?: string;
  contractEndDate?: string;
}

export interface ErrorResponseModel {
  errors: {
    code: string;
    field: string;
    message: string;
  }[];
}
