<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
  name: 'assign-dialog',
  components: {},
})
export default class extends Vue {
  @Prop({ required: false }) title!: string;
  @Prop({ default: '800px' }) popupWidth!: string;
  @Prop({ default: '25vh' }) popupTop!: string;
  @Prop({ required: true }) modalVisiable!: boolean;

  created() {}

  get visible() {
    return this.modalVisiable;
  }

  set visible(val) {
    this.$emit('updateVisible', val);
  }

  cancelModal() {
    this.visible = false;
  }

  continueModal() {
    this.$emit('submitPopupData');
  }
}
</script>

<template>
  <div>
    <el-dialog
      :visible.sync="visible"
      class="cust-dialog"
      :width="popupWidth"
      :top="popupTop"
      :title="title"
    >
      <div class="d-flex ai-center" style="flex-direction: column">
        <slot></slot>
        <span slot="footer" class="">
          <div class="btn_area footer_btn">
            <div class="back_btn" @click="cancelModal">
              <span>{{ $t('customerModule.cancel') }}</span>
            </div>
            <div class="clicking_btn" @click="continueModal">
              <span>{{ $t('common.deprecate') }}</span>
            </div>
          </div>
        </span>
      </div>
    </el-dialog>
  </div>
</template>

<style lang="scss" scoped>
.btn_area {
  display: flex;
  .back_btn,
  .clicking_btn {
    margin-right: 33px;
    height: 38px;
    border: 1px solid #373e41;
    border-radius: 4px;
    cursor: pointer;
  }
  .back_btn {
    width: 198px;
    text-align: center;
    line-height: 36px;
    font-size: 16px;
    font-weight: bold;
  }
  .clicking_btn {
    background: #373e41;
    color: #ffffff;
    width: 198px;
    text-align: center;
    line-height: 36px;
    font-size: 16px;
    font-weight: bold;
  }
}

.footer_btn {
  margin-bottom: 70px;
  .back_btn,
  .clicking_btn {
    margin: 0 18px;
  }
}

.dialog-header-title {
  font-family: $font-Roboto-Regular;
  color: #373e41;
  font-size: 24px;
  text-align: center;
  margin: 40px 0 110px;
  width: 430px;
  line-height: 40px;
}
.cust-dialog :deep(.el-dialog__header) {
  background-color: var(--Main) !important;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 60px;
}

.cust-dialog :deep(.el-dialog__title) {
  font-size: 20px;
  font-family: $font-Roboto-Medium;
  font-weight: 500;
  line-height: 19px;
  color: #373e41;
}

:deep(.el-dialog__headerbtn) {
  font-size: 26px;
  color: #373d41;
  position: absolute;
  top: 16px;
  right: 34px;
}
.new-cust-dialog :deep(.el-dialog__headerbtn:hover .el-dialog__close) {
  color: #5f6567;
}
</style>
