<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import { ButtonProps } from '@/components/button/CardButton.vue';
import CardButton from '@/components/button/CardButton.vue';

@Component({
  name: 'BaseCard',
  components: {
    CardButton,
  },
})
export default class extends Vue {
  @Prop() title!: string;
  @Prop({ default: false }) backIconVisible!: boolean;
  @Prop({ default: '-1' }) backPath!: string;
  @Prop({ default: false }) showDialogBeforeBack!: boolean;
  @Prop({ default: [] }) buttons?: ButtonProps[];

  handleGoBack() {
    if (!this.showDialogBeforeBack) {
      this.goBack();
    } else {
      this.$emit('handle-back');
    }
  }

  goBack() {
    this.backPath === '-1'
      ? this.$router.go(-1)
      : this.$router.push(this.backPath);
  }
}
</script>

<template>
  <div class="base-card">
    <div class="base-card-header">
      <div id="new_card_header_title" class="d-flex ai-center">
        <i
          v-if="backIconVisible"
          class="el-icon-arrow-left"
          style="font-size: 25px; cursor: Pointer"
          @click="handleGoBack"
        />
        <span class="header-title">{{ title }}</span>
        <slot name="titleInformation"></slot>
      </div>
      <div>
        <slot name="rightHeader"></slot>
      </div>
      <div>
        <CardButton
          class="right-side-btn"
          v-for="(button, index) in buttons"
          :key="index"
          v-bind="button"
          :id="'base-card-right-side-button-' + button.title"
        />
      </div>
    </div>

    <div style="border-bottom: 1px solid #dddddd; margin: 0 -20px" />

    <div class="base-card-body">
      <slot></slot>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.base-card {
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0px 3px 6px #c1c1c1;
  width: 100%;
  height: 100%;

  .base-card-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-family: $font-Roboto-Regular;
    line-height: 21px;
    height: 60px;
    margin-left: 20px;
    margin-right: 10px;
  }

  .base-card-body {
    min-height: calc(100% - 140px);
  }
  .right-side-btn {
    margin-right: 16px;
  }
}
</style>
