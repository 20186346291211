<script lang="ts">
import {
  AssetDeploymentLog,
  cancelAssetReleaseDeployment,
  cancelSystemReleaseDeployment,
  getDeploymentForSpecifiedAsset,
  getSystemDeployment,
  holdAssetReleaseDeployment,
  holdSystemReleaseDeployment,
  resumeAssetReleaseDeployment,
  resumeSystemReleaseDeployment,
  SystemDeployment,
  SystemDeploymentAsset,
} from '@/api/deploymentDetails';
import NavPage, { Button } from '@/components/nav/NavPage.vue';
import { PageModule } from '@/store/modules/page';
import { UserModule } from '@/store/modules/user';
import { calculateTimeDifference } from '@/utils/misc';
import { customSuccessMessage, customWarningMessage } from '@/utils/prompt';
import { HISTORY_DETAILS_COLS } from '@/utils/workData/historyDetails';
import { ALL_CLAIMS_CODES, SRDS_STATUS } from '@/utils/workData/lookuptable';
import { Component, Vue } from 'vue-property-decorator';
import AssetStatusTable from './AssetStatusTable.vue';
import Details from './Details.vue';

interface TableColumns {
  label: string;
  prop: string;
}

@Component({
  name: 'DeploymentsDetails',
  components: {
    NavPage,
    'asset-status-table': AssetStatusTable,
    'asset-details': Details,
  },
})
export default class extends Vue {
  /** Local variables */
  deploymentDetailsPageIsLoading: boolean = false;
  deploymentDetailsIsLoading: boolean = false;
  selectedAssetDeployId: string = '';
  title: string = '';
  data: SystemDeployment = {
    noOfCancelledDeploys: 0,
    noOfFailedDeploys: 0,
    noOfInvolvedAssets: 0,
    noOfRevertedDeploys: 0,
    noOfSuccessDeploys: 0,
    systemReleaseDeploymentEndTime: '',
    systemReleaseDeploymentStartTime: '',
    systemReleaseDeploymentStatus: '',
    systemDeploymentId: '',
    systemReleaseId: '',
    companyId: '',
    companyName: '',
    assets: [],
  };
  cols: TableColumns[] = HISTORY_DETAILS_COLS;
  detailsData: AssetDeploymentLog = {
    assetDeployStatus: '',
    assetType: '',
    deploymentControl: '',
    deploymentDuration: 0,
    deploymentStartTime: '',
    organizationId: '',
    organizationName: '',
    partUpdateProgress: [],
    productModelCode: '',
    productModelNumber: '',
    systemReleaseIdBeforeDeployment: '',
  };
  buttons: Button[] = [];
  statesWhenSystemDeploymentCanBeCancelled: string[] = [
    SRDS_STATUS.WAITING,
    SRDS_STATUS.IN_PROGRESS,
    SRDS_STATUS.ON_HOLD,
  ];
  statesWhenSystemDeploymentCanBeHold: string[] = [
    SRDS_STATUS.WAITING,
    SRDS_STATUS.IN_PROGRESS,
  ];
  statesWhenSystemDeploymentCanBeResume: string[] = [SRDS_STATUS.ON_HOLD];

  created() {
    this.getDeploymentsData();
  }

  /**
   * Get deployments data
   */
  async getDeploymentsData(): Promise<void> {
    try {
      this.deploymentDetailsPageIsLoading = true;
      const res = await getSystemDeployment(this.$route.params.deploymentId);
      this.data = res.data;
      this.data.assets.map((item: SystemDeploymentAsset) => {
        item.assetDeployStatus = item.deployStatus; // add same recerence field to process the final status through certain logic
      });
      this.handleDeploymentDuration();
      this.selectedAssetDeployId = this.data!.assets[0].assetDeploymentId;
      if (res.data.assets.length > 0)
        this.getSpecifiedDeployDetails(this.selectedAssetDeployId);
      this.generateTitle(res.data.systemReleaseId, res.data.companyName);
      this.handleDefaultSystemReleaseActionButtons();
    } catch (error) {
      console.log(error);
    } finally {
      this.deploymentDetailsPageIsLoading = false;
    }
  }

  /**
   * Generate title
   */
  generateTitle(systemReleaseId: string, companyName: string): void {
    PageModule.setTitle(`${systemReleaseId ?? ''} | ${companyName ?? ''}`);
  }

  /**
   * Handle default system release action button accordingly with following logic:
   * - cancel should not be posibile if is already in the final state
   * - display on hold all assets btn if system deployment release is not in final state and exist at least one asset that could be put on hold
   * - display resume all btn for current system release if the retrieved system release deployment activation status is ON HOLD
   */
  handleDefaultSystemReleaseActionButtons(): void {
    this.buttons = [];
    if (
      this.statesWhenSystemDeploymentCanBeCancelled.includes(
        this.data.systemReleaseDeploymentStatus
      ) &&
      UserModule.claims.hasClaim(
        ALL_CLAIMS_CODES.AUTHRSC_ACTION_DEPLOYMENT_CANCEL
      )
    ) {
      this.buttons.push({
        id: 'CANCEL',
        label: 'deployManagementModule.deployments.cancelAll',
        action: this.cancelSystemDeploymentRelease,
      });
    }

    if (
      this.statesWhenSystemDeploymentCanBeHold.includes(
        this.data.systemReleaseDeploymentStatus
      ) &&
      UserModule.claims.hasClaim(
        ALL_CLAIMS_CODES.AUTHRSC_ACTION_DEPLOYMENT_HOLD
      )
    ) {
      this.buttons.push({
        id: 'HOLD',
        label: 'deployManagementModule.deployments.holdAll',
        action: this.holdSystemDeploymentRelease,
      });
    }

    if (
      this.statesWhenSystemDeploymentCanBeResume.includes(
        this.data.systemReleaseDeploymentStatus
      ) &&
      UserModule.claims.hasClaim(
        ALL_CLAIMS_CODES.AUTHRSC_ACTION_DEPLOYMENT_RESUME
      )
    ) {
      this.buttons = this.buttons.filter((btn: Button) => btn.id != 'HOLD');
      this.buttons.push({
        id: 'RESUME',
        label: 'deployManagementModule.deployments.resumeAll',
        action: this.resumeSystemReleaseDeployment,
      });
    }
  }

  /**
   * Handle from UI the difference between end and start time
   * returned format: 0h 0m 0s
   */
  handleDeploymentDuration(): void {
    this.data.assets?.forEach((asset: SystemDeploymentAsset) => {
      asset.deploymentDuration = calculateTimeDifference(
        asset.deploymentStartTime,
        asset.deploymentEndTime
      );
    });
  }

  /**
   * Get deployments details
   * @param id
   */
  async getSpecifiedDeployDetails(id: string): Promise<void> {
    try {
      this.deploymentDetailsIsLoading = true;
      this.selectedAssetDeployId = id;
      const res = await getDeploymentForSpecifiedAsset(id);
      this.detailsData = res.data;
    } catch (error) {
      console.log(error);
    } finally {
      this.deploymentDetailsIsLoading = false;
    }
  }

  /**
   * Cancel system release deployment and all assets assigned to it that can be cancelled (not in finish state)
   */
  async cancelSystemDeploymentRelease(): Promise<void> {
    try {
      this.deploymentDetailsPageIsLoading = true;
      const response = await cancelSystemReleaseDeployment(
        this.$route.params.deploymentId
      );
      if (response.code === 200) {
        customSuccessMessage(
          this.$t(
            'deployManagementModule.deployments.pendingAssetDeploymentCancellingRequest'
          ).toString()
        );
        this.$router.push({
          path: '/deploy/index',
          query: { activeName: 'deployments' },
        });
      }
      if (response.code === 400)
        customWarningMessage(
          this.$t(
            'deployManagementModule.deployments.systemReleaseDeploymentCouldNotBeenCancelled'
          ).toString()
        );
    } catch (error) {
      console.log(error);
    } finally {
      this.deploymentDetailsPageIsLoading = false;
    }
  }

  /**
   * Hold all assets from current system deployment only which can be put on hold (not in finish state)
   */
  async holdSystemDeploymentRelease(): Promise<void> {
    try {
      this.deploymentDetailsPageIsLoading = true;
      const response = await holdSystemReleaseDeployment(
        this.$route.params.deploymentId
      );
      if (response.code === 200)
        customSuccessMessage(
          this.$t(
            'deployManagementModule.deployments.pendingAssetDeploymentHoldRequest'
          ).toString()
        );
      if (response.code === 400)
        customWarningMessage(
          this.$t(
            'deployManagementModule.deployments.systemReleaseDeploymentCouldNotBeenHold'
          ).toString()
        );
    } catch (error) {
      console.log(error);
    } finally {
      this.getDeploymentsData();
    }
  }

  /**
   * Resume system deployment release and all assets assigned to it that can be resumed
   */
  async resumeSystemReleaseDeployment(): Promise<void> {
    try {
      this.deploymentDetailsPageIsLoading = true;
      const response = await resumeSystemReleaseDeployment(
        this.$route.params.deploymentId
      );
      if (response.code === 200)
        customSuccessMessage(
          this.$t(
            'deployManagementModule.deployments.pendingAssetDeploymentResumeRequest'
          ).toString()
        );
      if (response.code === 400)
        customWarningMessage(
          this.$t(
            'deployManagementModule.deployments.systemReleaseDeploymentCouldNotBeenResume'
          ).toString()
        );
    } catch (error) {
      console.log(error);
    } finally {
      this.getDeploymentsData();
    }
  }

  /**
   * Cancel asset deployment
   */
  async cancelAssetDeployment(): Promise<void> {
    try {
      this.deploymentDetailsPageIsLoading = true;
      const response = await cancelAssetReleaseDeployment(
        this.selectedAssetDeployId
      );
      if (response.code === 200)
        customSuccessMessage(
          this.$t(
            'deployManagementModule.deployments.pendingAssetDeploymentCancellingRequest'
          ).toString()
        );
      if (response.code === 400)
        customWarningMessage(
          this.$t(
            'deployManagementModule.deployments.assetCouldNotBeenCancelled'
          ).toString()
        );
    } catch (error) {
      console.log(error);
    } finally {
      this.deploymentDetailsPageIsLoading = false;
      this.getDeploymentsData();
    }
  }

  /**
   * Hold asset deployment
   */
  async holdAssetDeployment(): Promise<void> {
    try {
      this.deploymentDetailsPageIsLoading = true;
      const response = await holdAssetReleaseDeployment(
        this.selectedAssetDeployId
      );
      if (response.code === 200)
        customSuccessMessage(
          this.$t(
            'deployManagementModule.deployments.pendingAssetDeploymentHoldRequest'
          ).toString()
        );
      if (response.code === 400)
        customWarningMessage(
          this.$t(
            'deployManagementModule.deployments.assetCouldNotBeenHold'
          ).toString()
        );
    } catch (error) {
      console.log(error);
    } finally {
      this.deploymentDetailsPageIsLoading = false;
      this.getDeploymentsData();
    }
  }

  /**
   * Resume asset deploy
   */
  async resumeAssetDeployment(): Promise<void> {
    try {
      this.deploymentDetailsPageIsLoading = true;
      const response = await resumeAssetReleaseDeployment(
        this.selectedAssetDeployId
      );
      if (response.code === 200)
        customSuccessMessage(
          this.$t(
            'deployManagementModule.deployments.pendingAssetDeploymentResumeRequest'
          ).toString()
        );
      if (response.code === 400)
        customWarningMessage(
          this.$t(
            'deployManagementModule.deployments.assetCouldNotBeenResume'
          ).toString()
        );
    } catch (error) {
      console.log(error);
    } finally {
      this.deploymentDetailsPageIsLoading = false;
      this.getDeploymentsData();
    }
  }
}
</script>

<template>
  <NavPage
    v-loading="deploymentDetailsPageIsLoading"
    :visible="true"
    :buttons="buttons"
  >
    <h3 class="header" style="margin-left: 20px">
      {{ $t('deployManagementModule.history.assetsInvolved') }} ({{
        data.noOfInvolvedAssets
      }})
    </h3>
    <div class="page-content">
      <asset-status-table
        :tableData="data.assets"
        :cols="cols"
        class="table"
        @selectAsset="getSpecifiedDeployDetails"
      />
      <asset-details
        v-loading="deploymentDetailsIsLoading"
        :data="detailsData"
        buttons="true"
        :deployLogId="selectedAssetDeployId"
        @holdAssetDeployment="holdAssetDeployment"
        @cancelAassetDeployment="cancelAssetDeployment"
        @resumeAssetDeployment="resumeAssetDeployment"
      />
    </div>
  </NavPage>
</template>

<style lang="scss" scoped>
.page-content {
  display: flex;
  flex-direction: row;
  width: 100%;
  padding: 16px;

  .table {
    width: 50%;
    height: 710px;
    padding: 16px;
    border-right: 1px solid rgba(55, 62, 65, 0.2);
  }

  .details {
    width: 50%;
  }
}

.header {
  border-bottom: 1px solid;
  margin: 0;
}
</style>
