import { render, staticRenderFns } from "./BatchApprove.vue?vue&type=template&id=4cbb7e81&scoped=true"
import script from "./BatchApprove.vue?vue&type=script&lang=ts"
export * from "./BatchApprove.vue?vue&type=script&lang=ts"
import style0 from "./BatchApprove.vue?vue&type=style&index=0&id=4cbb7e81&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4cbb7e81",
  null
  
)

export default component.exports