/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon';
icon.register({
  lang: {
    width: 32,
    height: 32,
    viewBox: '0 0 32 32',
    data: '<g data-name="椭圆 114" _stroke="#fff" _fill="none"><circle pid="0" cx="16" cy="16" r="16" _stroke="none"/><circle pid="1" cx="16" cy="16" r="15.5"/></g><text transform="translate(1 8)" _fill="#fff" font-size="14" font-family="Roboto-Regular,Roboto"><tspan x="-8.969" y="13">EN</tspan></text>',
  },
});
