export const ALARM_CONFIG_TABLE_COLUMNS = [
  {
    label: 'alarmConfig.kpiName',
    prop: 'propertyName',
    required: true,
    visible: true,
    sortable: 'custom',
  },
  {
    label: 'alarmConfig.unit',
    prop: 'unit',
    required: true,
    visible: true,
    width: '40',
  },
  {
    label: 'alarmConfig.assetType',
    prop: 'assetType',
    required: true,
    visible: true,
    multiLang: true,
    width: '140',
  },
  {
    label: 'alarmConfig.warningThreshold',
    prop: 'warningThreshold',
    required: true,
    visible: true,
  },
  {
    label: 'alarmConfig.warningInterval',
    prop: 'warningInterval',
    required: true,
    visible: true,
  },
  {
    label: 'alarmConfig.alarmThreshold',
    prop: 'alarmThreshold',
    required: true,
    visible: true,
  },
  {
    label: 'alarmConfig.alarmInterval',
    prop: 'alarmInterval',
    required: true,
    visible: true,
  },
  {
    label: 'alarmConfig.lock',
    prop: 'isLocked',
    required: true,
    visible: true,
    width: '80',
  },
];

export const ALARM_SETTINGS_TABLE_COLUMNS_FOR_CUSTOMER = [
  {
    label: 'alarmConfig.kpiName',
    prop: 'propertyName',
    required: true,
    visible: true,
    sortable: 'custom',
  },
  {
    label: 'alarmConfig.unit',
    prop: 'unit',
    required: true,
    visible: true,
    width: '60',
  },
  {
    label: 'alarmConfig.assetType',
    prop: 'assetType',
    required: true,
    visible: true,
    multiLang: true,
    width: '150',
  },
  {
    label: 'alarmConfig.warningThreshold',
    prop: 'warningThreshold',
    required: true,
    visible: true,
  },
  {
    label: 'alarmConfig.warningInterval',
    prop: 'warningInterval',
    required: true,
    visible: true,
  },
  {
    label: 'alarmConfig.alarmThreshold',
    prop: 'alarmThreshold',
    required: true,
    visible: true,
  },
  {
    label: 'alarmConfig.alarmInterval',
    prop: 'alarmInterval',
    required: true,
    visible: true,
  },
];

export const ALARM_CONFIG_SELECTION_OPTIONS = [
  {
    label: 'alarmConfig.kpiName',
    prop: 'propertyName',
  },
  {
    label: 'alarmConfig.assetType',
    prop: 'assetType',
  },
  {
    label: 'alarmConfig.metricUnit',
    prop: 'unit',
  },
  {
    label: 'alarmConfig.lock',
    prop: 'isLocked',
  },
];
