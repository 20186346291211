<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
  name: 'BaseHeader',
})
export default class extends Vue {
  @Prop() title!: string;
  @Prop({ default: false }) backIconVisible!: boolean;

  handleGoBack() {
    this.$router.go(-1);
  }
}
</script>

<template>
  <div class="base-header">
    <div id="new_card_header_title" class="d-flex ai-center">
      <i
        v-if="backIconVisible"
        class="el-icon-arrow-left"
        style="font-size: 25px; cursor: Pointer"
        @click="handleGoBack"
      />
      <span class="header-title">{{ title }}</span>
    </div>
    <div>
      <slot></slot>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.base-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: $font-Roboto-Regular;
  line-height: 21px;
  height: 60px;
  margin-left: 10px;
  margin-right: 10px;
}
</style>
