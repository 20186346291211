import Vue from 'vue';
import { getUnits } from './api/units';
import {
  getUnitConversions,
  KpiToUnitConversionMapping,
} from './utils/units/unitMapping';

interface GlobalState {
  units: KpiToUnitConversionMapping;
}

const state = Vue.observable<GlobalState>({
  units: {},
});

export default {
  state,
  async fetchUnits() {
    try {
      const units = await getUnits();
      const unitConversions = getUnitConversions(units);
      state.units = unitConversions;
    } catch (error) {
      console.error('Error fetching units: ', error);
    }
  },
};
