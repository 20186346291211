// Mark: Javascript code starts running
performance.mark('initStart');
// Measure: Time taken from loading scripts and other resources until JS starts executing
performance.measure('loadScripts', 'navigationStart', 'initStart');

import Keycloak from 'keycloak-js';
import { fetchConfig } from './config';

// This file checks whether a redirect to KeyCloak is needed to obtain
// a token. Only when the token is available, will this script start
// loading any other components.
//
// !!! WARNING: DO NOT just add imports to this file!
//
// Any imports added here may mean that a large (webpack) bundle gets
// additionally included, which is wasted time just to decide to redirect
// to KeyCloak.
//
// !!! WARNING: DO NOT just add imports to this file!

async function init() {
  // Fetch config
  const config = await fetchConfig();

  // Mark: Configuration known
  performance.mark('configured');

  // Obtain KeyCloak token
  const keycloak = new Keycloak({
    url: config.VUE_APP_IDENTITY_SERVICE_URL,
    realm: config.VUE_APP_IDENTITY_SERVICE_REALM,
    clientId: config.VUE_APP_IDENTITY_SERVICE_CLIENT_ID,
  });

  const urlParams = new URLSearchParams(window.location.search);
  const ssoIdpHint = urlParams.get('sso');

  if (ssoIdpHint) {
    console.log('sso=' + ssoIdpHint);
  }

  // TODO: See if we can catch an error here, and then do pass it
  // on to main() to render a proper 'service unavailable' or something.
  // (WITHOUT just importing something from main already here!)

  let authenticated = await keycloak.init(
    ssoIdpHint ? {} : { onLoad: 'login-required' }
  );

  if (!authenticated && ssoIdpHint) {
    await keycloak.login({ idpHint: ssoIdpHint });
    authenticated = true;
  }

  // Mark: Token obtained
  performance.mark('keycloakInitialized');
  performance.measure('obtainToken', 'configured', 'keycloakInitialized');

  console.debug('Authenticated', authenticated);

  // Dynamically import the rest of the app, to only do this
  // when we have a token.
  const { main } = await import('./main');
  await main(config, keycloak);
}

// Initialize app
init().catch((err: unknown) => {
  console.error('init() failed:', err);
  document.write(
    'Application failed to initialize. Please refresh the browser or contact your system administrator if the problem persists.'
  );
});
