import { UUID } from '@/api/common';
import {
  getNotificationsOfUser,
  NotificationResponse,
  NotificationStatusUpdate,
  NotificationStatusUpdateResponse,
  updateNotificationStatuses,
} from '@/api/notifications';
import { useLoggedInUser } from '@/auth/user';
import { FilterOperator } from '@/model/queryParameters/QueryParameter';
import {
  QueryFilters,
  useMutation,
  UseMutationReturnType,
  useQuery,
  useQueryClient,
  UseQueryReturnType,
} from '@tanstack/vue-query';
import { computed } from 'vue';

const queryKeys = {
  notifications: (loggedInUserId: UUID, loggedInUserLanguage: string) =>
    ({
      query: 'notifications',
      loggedInUserId: loggedInUserId,
      loggedInUserLanguage: loggedInUserLanguage,
    } as const),
};

const queryFilters = {
  notifications: (): QueryFilters => ({
    queryKey: [{ query: 'notifications' }],
  }),
};

export function useNotificationsQuery(): UseQueryReturnType<
  NotificationResponse,
  Error
> {
  const loggedInUser = useLoggedInUser();

  return useQuery({
    refetchInterval: 60 * 1000,
    enabled: computed(() => loggedInUser.value !== undefined),
    queryKey: [
      computed(() =>
        queryKeys.notifications(
          loggedInUser.value!.id,
          loggedInUser.value!.settings.i18nCode
        )
      ),
    ],
    queryFn: ({ queryKey: [{ loggedInUserId, loggedInUserLanguage }] }) =>
      getNotificationsOfUser(loggedInUserId, {
        filters: [
          {
            name: 'i18nCode',
            operator: FilterOperator.EQUAL,
            value: [loggedInUserLanguage],
          },
        ],
      }),
  });
}

/**
 * Mark a specific list of Notification UUIDs as read.
 */
export function useUpdateNotificationReadStatusMutation(): UseMutationReturnType<
  NotificationStatusUpdateResponse,
  Error,
  NotificationStatusUpdate,
  void
> {
  const queryClient = useQueryClient();
  const loggedInUser = useLoggedInUser();

  return useMutation({
    mutationFn: (notificationStatusUpdate) => {
      if (!loggedInUser.value) {
        throw new Error('loggedInUser is undefined');
      }

      return updateNotificationStatuses(
        loggedInUser.value.id,
        notificationStatusUpdate
      );
    },
    onSuccess: () => {
      queryClient.invalidateQueries(queryFilters.notifications());
    },
  });
}
