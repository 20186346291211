<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator';
import {
  MODEL_PART_TYPE_LIST,
  PRODUCT_MODEL_STATUS,
} from '@/utils/workData/lookuptable';

@Component({
  name: 'model-select',
  components: {},
})
export default class extends Vue {
  @Prop({ default: true }) showLifeCycleSelect!: boolean;

  partTypeList = MODEL_PART_TYPE_LIST;
  statusList = PRODUCT_MODEL_STATUS;
  partNameorId = '';
  lifeCycle: string = '';
  partType: string = '';

  mounted() {}

  sendFilterData() {
    let sendData = {
      partNameorId: this.partNameorId,
      partType: this.partType,
      lifeCycle: this.lifeCycle,
    };
    this.$emit('send-filter-data', sendData);
  }
}
</script>

<template>
  <div>
    <div class="d-flex ai-center" style="margin-bottom: 30px">
      <el-input
        id="part_select_input"
        class="select-input"
        v-model="partNameorId"
        :placeholder="$t('prodCata.searchPartNameorID')"
        style="margin-right: 40px"
        @keyup.enter.native="sendFilterData"
        @change="sendFilterData"
        clearable
      >
        <i slot="suffix" class="el-icon-search" @click="sendFilterData"></i>
      </el-input>

      <el-select
        id="part_select_part_type"
        class="table-select"
        v-model="partType"
        clearable
        :placeholder="$t('prodCata.selectPartType')"
        @change="sendFilterData"
      >
        <el-option
          v-for="(item, index) in partTypeList"
          :key="index"
          :label="$t(item.label)"
          :value="item.value"
        >
        </el-option>
      </el-select>

      <el-select
        id="part_select_status"
        class="table-select"
        v-model="lifeCycle"
        clearable
        v-if="showLifeCycleSelect"
        :placeholder="$t('prodCata.selectStatus')"
        @change="sendFilterData"
      >
        <el-option
          v-for="item in statusList"
          :key="item.value"
          :label="$t(item.label)"
          :value="item.value"
        >
        </el-option>
      </el-select>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.dropDownMenu-item {
  line-height: 25px;
  text-indent: 10px;
  cursor: pointer;
  padding-left: 10px;
  padding-top: 5px;
}

.dropDownMenu-item:hover {
  background-color: var(--Main);
}

.el-input {
  width: 300px;
}

.el-select {
  margin-right: 40px;
}

.select-input :deep(.el-input__inner) {
  height: $inputHeight;
  width: 300px;
  // width: 31vw;
  border: 1px solid #707070 !important;
  font-size: 16px;
  font-family: $font-Roboto-Regular;
  line-height: 1.357143rem;
  color: #373e41;
  opacity: 1;
}

.el-icon-search {
  margin-right: 6px;
  line-height: 40px;
}

.table-select :deep(.el-input__inner) {
  height: $inputHeight;
  width: 200px;
  // width: 31vw;
  border: 1px solid #707070 !important;
  font-size: 16px;
  font-family: $font-Roboto-Regular;
  line-height: 1.357143rem;
  color: #373e41;
  opacity: 1;
}
</style>
