/** TODO Important: After maintenance modules overhaul (FE + BE), fix Service
Status field on this component! Currently it's translated to Unknown since there
is no working flow */
<script lang="ts">
import { AssetObjectModel, getAssetById, getKpiData } from '@/api/assets';
import { CommonResult } from '@/api/commonResult';
import { postAssetConfigurationVpn } from '@/api/configParams';
import {
  getHealthStatusFactorDefinitions,
  HealthFactorDefinitionResponse,
} from '@/api/healthStatus';
import { getKpisForMultipleAssets, KpiDataRequest } from '@/api/kpis';
import { getProductModelById, ProductModel } from '@/api/products';
import { ActiveContext, useActiveContext } from '@/auth/context';
import { LoggedInUserRef, useLoggedInUser } from '@/auth/user';
import DefaultDialog from '@/components/dialog/DefaultDialog.vue';
import AssetHealthFactorList from '@/components/health/AssetHealthFactorList.vue';
import WidgetCard from '@/components/layout/widget/WidgetCard.vue';
import {
  useUnitConversion,
  UseUnitConversionReturnType,
} from '@/composables/conversion';
import { useBooleanSystemFeature } from '@/composables/systemFeature';
import MobileCompactor from '@/icons/svg/mobileCompactor.svg';
import StaticCompactor from '@/icons/svg/staticCompactor.svg';
import TippingVehicle from '@/icons/svg/tippingVehicle.svg';
import { FilterOperator } from '@/model/queryParameters/QueryParameter';
import { UserModule } from '@/store/modules/user';
import {
  findAssetNumericUnitValue,
  findAssetUnitValue,
  findKpiValue,
} from '@/utils/assets';
import { AssetType } from '@/utils/assetTypes';
import { formatUnitValue } from '@/utils/format';
import { formatTimer } from '@/utils/misc';
import { customFailedMessage, customSuccessMessage } from '@/utils/prompt';
import { UnitValue } from '@/utils/units/unitValue';
import {
  DESIGNATED_COMPANY_TYPES,
  EntityType,
  gpsStatusColorMapping,
  KPI_FIELDS,
  SYSTEM_FEATURES,
  WarningType,
  WARNING_TO_HEALTH_STATUS_COLOR,
} from '@/utils/workData/lookuptable';
import { operationalStatusColorMapping } from '@/utils/workData/operationalStatus';
import BaseInfos from '@/views/assets/components/BaseInfos.vue';
import moment from 'moment';
import { ComputedRef, Ref, unref } from 'vue';
import { Component, Prop, Vue } from 'vue-property-decorator';
import {
  getMaintenanceStatusOverview,
  MaintenanceOverview,
  MaintStatus,
} from '../../../api/maintenance';

interface kpiResponse {
  code: string | null;
  v: string | null;
}

const warningOrder = [
  WarningType.Alarm,
  WarningType.Warning,
  WarningType.Normal,
];

const emptyProductModel: ProductModel = {
  id: '',
  code: '',
  modelNumber: '',
  assetTypeId: '',
  assetTypeCode: '',
  lifeCycle: '',
  numberOfParts: '',
  picturePath: '',
  properties: [],
  containedParts: [],
};

@Component({
  name: 'AssetBasicInfo',
  components: {
    WidgetCard,
    BaseInfos,
    DefaultDialog,
    AssetHealthFactorList,
  },
})
export default class extends Vue {
  @Prop({ default: false })
  operationalSupportMode!: boolean;

  /** Local variables */
  assetInfos?: AssetObjectModel;
  AssetType = AssetType;
  operationalStatus: string = '';
  isHealthModalVisible: boolean = false;
  healthStatusData: HealthFactorDefinitionResponse[] = [];
  systemReleaseVersion?: UnitValue<string>;
  isNewSystemReleaseAvailable?: UnitValue<boolean>;
  isDeployingNewRelease?: UnitValue<boolean>;
  massThreshold?: UnitValue<number | undefined>;
  lastCommunicationTime: string | undefined = undefined;
  gpsAccuracy: string | undefined = undefined;
  unknownValue: string = this.$t('unknownValue') as string;
  isLoading: boolean = true;
  serviceStatusIsLoading: boolean = false;
  hasHealthFeature: boolean = false;
  context!: Ref<ActiveContext>;
  productModel: ProductModel = { ...emptyProductModel };
  loggedInUser!: LoggedInUserRef;
  customerCanImpersonate: boolean = false;
  serviceStatus: MaintStatus = MaintStatus.OK;
  simCardIMEI?: UnitValue<string>;
  simCardIMSI?: UnitValue<string>;
  lastBodyEmptyCalibration?: UnitValue<string>;
  lastPayloadCalibration?: UnitValue<string>;
  remoteSoftwareEntitlement!: ComputedRef<boolean>;
  unitConversion!: UseUnitConversionReturnType;

  formatUnitValue = formatUnitValue;

  async created() {
    this.loggedInUser = useLoggedInUser();
    this.remoteSoftwareEntitlement = useBooleanSystemFeature(
      SYSTEM_FEATURES.RemoteSoftwareUpdate
    );
    this.unitConversion = useUnitConversion();
    this.customerCanImpersonate = DESIGNATED_COMPANY_TYPES.includes(
      UserModule.companyType
    );

    this.isLoading = true;
    this.serviceStatusIsLoading = true;
    this.context = useActiveContext();
    await this.loadAssetData();
    await this.getServiceStatusData();
    await this.prepareDefaultBehavior();
  }

  async prepareDefaultBehavior(): Promise<void> {
    if (!this.assetInfos) return;

    this.hasHealthFeature = await UserModule.hasSystemFeature([
      SYSTEM_FEATURES.HealthScore,
      this.assetInfos.assetType,
    ]);
    if (this.hasHealthFeature) {
      await this.getHealthStatusData();
    }
  }

  formatTime(value: string | undefined, includeTime: boolean = false) {
    if (!value) {
      return undefined;
    }

    return formatTimer(value, includeTime ? 'datetime' : 'date');
  }

  formatTimeUtc(value: string | undefined) {
    if (!value) {
      return undefined;
    }

    return formatTimer(value, 'datetime', 'UTC');
  }

  /**
   * Show GPS fix field in the UI
   */
  get showGpsFix(): boolean | undefined {
    if (!this.assetInfos) return;

    return this.assetInfos.assetType != AssetType.StaticCompactor;
  }

  get serviceClass() {
    switch (this.serviceStatus) {
      case MaintStatus.DUE:
        return 'status status-red';
      case MaintStatus.OK:
        return 'status status-green';
      case MaintStatus.CLOSE:
        return 'status status-orange';
    }
  }

  get operationalStatusStyle() {
    // @ts-expect-error TODO Make this.operationalStatus the correct type
    const color = operationalStatusColorMapping[this.operationalStatus];
    if (!color) return undefined;
    return { backgroundColor: color };
  }

  get gpsConnectStyle() {
    let color = gpsStatusColorMapping.get(this.gpsAccuracy as string);
    if (!color) return undefined;
    return { backgroundColor: color };
  }

  get backgroundColorFromHealthStatus() {
    return this.healthStatusData[0]
      ? WARNING_TO_HEALTH_STATUS_COLOR[
          this.healthStatusData[0].healthCondition!
        ]
      : '#be442d';
  }

  get lastCommunicationTimeFormatted() {
    return (
      this.formatTime(this.lastCommunicationTime, true) || this.unknownValue
    );
  }

  async getHealthStatusData(): Promise<void> {
    try {
      const res = await getHealthStatusFactorDefinitions(
        this.$route.params.id,
        unref(this.context),
        UserModule.i18nCode
      );
      if (res && res.code === 200) {
        res.data.forEach(
          (entry: any) =>
            (entry.lastChangeTimestamp = moment
              .utc(entry.lastChangeTimestamp)
              .format('YYYY/MM/DD HH:mm:ss'))
        );
        res.data.sort(
          (a: any, b: any) =>
            warningOrder.indexOf(a.healthCondition) -
            warningOrder.indexOf(b.healthCondition)
        );
        this.healthStatusData = res.data;
      }
    } catch (err) {
      console.log(err);
    }
  }

  maintenanceStatusOrder = [MaintStatus.OK, MaintStatus.CLOSE, MaintStatus.DUE];
  async getServiceStatusData(): Promise<void> {
    try {
      const res = await getMaintenanceStatusOverview({
        filters: [
          {
            name: 'assetId',
            operator: FilterOperator.IN,
            value: this.assetInfos ? [this.assetInfos.id] : [],
          },
        ],
      });

      if (res.code === 200) {
        const maintenanceStatusOverview: MaintenanceOverview[] = res.data;

        this.serviceStatus = this.maintenanceStatusOrder.reduce(
          (returnStatus, status) => {
            if (
              maintenanceStatusOverview.some(
                (overview) =>
                  overview.maintenanceStatus === status &&
                  overview.numberOfMaintenancePlans > 0
              )
            ) {
              returnStatus = status;
            }
            return returnStatus;
          },
          MaintStatus.OK
        );
      }
    } catch (err) {
      console.log(err);
    } finally {
      this.serviceStatusIsLoading = false;
    }
  }

  openHealthModal(): void {
    this.isHealthModalVisible = true;
  }

  handleModalCancel(): void {
    this.isHealthModalVisible = false;
  }

  async enableVpn(): Promise<void> {
    try {
      await postAssetConfigurationVpn(this.$route.params.id);
      customSuccessMessage(this.$t('assets.enableVpnTrigger.success'));
    } catch (err) {
      customFailedMessage(this.$t('assets.enableVpnTrigger.failure'));
    }
  }

  async loadAssetData(): Promise<void> {
    try {
      const assetId: string = this.$route.params.id;
      const assetByIdResponse = await getAssetById(
        assetId,
        unref(this.context)
      );

      await Promise.all([
        this.loadProductModelById(assetByIdResponse.data?.productModelId),
        this.loadAssetKpiData(assetId, assetByIdResponse),
        this.loadKpiData(assetId),
      ]);
    } catch (err) {
      console.log(err);
    } finally {
      this.isLoading = false;
    }
  }

  private async loadProductModelById(productModelId: string): Promise<void> {
    try {
      const response = await getProductModelById(productModelId);
      this.productModel = response.data;
    } catch (error) {
      console.log(error);
      this.productModel = { ...emptyProductModel };
    }
  }

  private async loadAssetKpiData(
    assetId: string,
    assetByIdResponse: CommonResult<AssetObjectModel>
  ) {
    const requestPayload: KpiDataRequest = {
      metadata: { filter: { assetIds: [assetId] } },
      details: [
        {
          fields: [
            { code: KPI_FIELDS.OperationalStatus },
            { code: KPI_FIELDS.LastCommunicationTime },
          ],
        },
      ],
    };
    if (assetByIdResponse.data?.assetType != AssetType.StaticCompactor) {
      requestPayload.details[0].fields!.push({
        code: KPI_FIELDS.GpsStatus,
      });
    }

    const res = await getKpisForMultipleAssets(
      requestPayload,
      unref(this.context)
    );

    if (assetByIdResponse && assetByIdResponse.code === 200) {
      this.assetInfos = assetByIdResponse.data;
      this.operationalStatus = findKpiValue(
        res.data,
        assetId,
        KPI_FIELDS.OperationalStatus
      );
      this.lastCommunicationTime =
        findKpiValue(res.data, assetId, KPI_FIELDS.LastCommunicationTime) ??
        undefined;
      this.gpsAccuracy =
        findKpiValue(res.data, assetId, KPI_FIELDS.GpsStatus) ?? undefined;
    }
  }

  private async loadKpiData(assetId: string) {
    const res = await getKpiData(
      {
        metadata: { filter: { assetId: assetId } },
        details: [
          {
            entity: EntityType.ASSET,
            fields: [
              { code: KPI_FIELDS.SystemReleaseVersion },
              { code: KPI_FIELDS.NewSystemReleaseAvailable },
              { code: KPI_FIELDS.DeployingNewRelease },
              { code: KPI_FIELDS.MassThreshold },
              { code: KPI_FIELDS.IMSI },
              { code: KPI_FIELDS.IMEI },
              { code: KPI_FIELDS.LastBodyEmptyCalibration },
              { code: KPI_FIELDS.LastPayloadCalibration },
            ],
          },
        ],
      },
      unref(this.context)
    );

    this.systemReleaseVersion = findAssetUnitValue(
      res.data,
      KPI_FIELDS.SystemReleaseVersion
    );
    this.isNewSystemReleaseAvailable = findAssetUnitValue(
      res.data,
      KPI_FIELDS.NewSystemReleaseAvailable
    );
    this.isDeployingNewRelease = findAssetUnitValue(
      res.data,
      KPI_FIELDS.DeployingNewRelease
    );

    const massThresholdKpiData: UnitValue<number | undefined> | undefined =
      findAssetNumericUnitValue(res.data, KPI_FIELDS.MassThreshold);

    this.massThreshold =
      massThresholdKpiData === undefined
        ? undefined
        : this.unitConversion.currentUserConvertUnitValue(massThresholdKpiData);

    this.simCardIMSI = findAssetUnitValue(res.data, KPI_FIELDS.IMSI);
    this.simCardIMEI = findAssetUnitValue(res.data, KPI_FIELDS.IMEI);
    this.lastBodyEmptyCalibration = findAssetUnitValue(
      res.data,
      KPI_FIELDS.LastBodyEmptyCalibration
    );
    this.lastPayloadCalibration = findAssetUnitValue(
      res.data,
      KPI_FIELDS.LastPayloadCalibration
    );
  }

  /**
   * Slim down the larger asset ids
   * @param text
   * @param max
   */
  truncateTextToMax(text: string, max: number): string {
    return text.substr(0, max - 1) + (text.length > max ? '...' : '');
  }

  /**
   * Handle product model picture
   */
  get pictureUrlSource(): string {
    if (this.productModel && this.productModel?.picturePath) {
      return this.productModel?.picturePath;
    }
    let tempSvg;
    switch (this.assetInfos?.assetType) {
      case AssetType.MobileCompactor: {
        tempSvg = MobileCompactor;
        break;
      }
      case AssetType.StaticCompactor: {
        tempSvg = StaticCompactor;
        break;
      }
      case AssetType.AlbaStaticCompactor: {
        tempSvg = StaticCompactor;
        break;
      }
      case AssetType.TippingVehicle: {
        tempSvg = TippingVehicle;
        break;
      }
      case AssetType.RefuseCollectionVehicle: {
        tempSvg = StaticCompactor;
        break;
      }
      case AssetType.GenericAsset: {
        tempSvg = TippingVehicle;
        break;
      }
    }
    return tempSvg;
  }
}
</script>

<template>
  <WidgetCard :loading="isLoading">
    <el-row class="asset-info-row" v-if="!isLoading && assetInfos">
      <el-col :span="8" style="text-align: center" v-if="pictureUrlSource">
        <img class="asset-image" :src="pictureUrlSource" />
      </el-col>
      <el-col :span="!pictureUrlSource ? 12 : 8" class="basic-info-col">
        <BaseInfos
          :name="$t('assets.assetId')"
          :value="assetInfos.companyAssetId || unknownValue"
        />
        <BaseInfos
          :name="$t('assets.assignedData')"
          :value="formatTime(assetInfos.assignDate)"
        />
        <BaseInfos
          :name="$t('assets.timeZone')"
          :value="assetInfos.timezone || unknownValue"
        />
        <BaseInfos
          :name="$t('assets.productModel')"
          :value="assetInfos.productModelCode || unknownValue"
        />
        <BaseInfos
          :name="$t('assets.gatewayId')"
          :value="assetInfos.gatewayId || unknownValue"
        />
        <BaseInfos
          v-if="operationalSupportMode"
          :name="$t('assets.simCardIMSI')"
          :value="simCardIMSI ? simCardIMSI.v : ''"
        />
        <BaseInfos
          v-if="operationalSupportMode"
          :name="$t('assets.simCardIMEI')"
          :value="simCardIMEI ? simCardIMEI.v : ''"
        />
        <BaseInfos
          :name="$t('KPI.massThreshold')"
          :value="massThreshold ? formatUnitValue(massThreshold) : ''"
        />
        <BaseInfos v-if="operationalSupportMode" :name="$t('assets.enableVpn')">
          <template v-slot:tipInfo>
            <i class="el-icon-info" v-popover:enableVpnPopover></i>
            <el-popover
              ref="enableVpnPopover"
              placement="top-start"
              width="330"
              trigger="hover"
            >
              <div>
                {{ $t('assets.enableVpnInfo') }}
              </div>
            </el-popover>
          </template>
          <div>
            <el-button :disabled="false" @click="enableVpn">
              {{ $t('assets.vpnTrigger') }}
            </el-button>
          </div>
        </BaseInfos>
      </el-col>
      <el-col :span="!pictureUrlSource ? 12 : 8" class="basic-info-col">
        <BaseInfos
          :name="$t('assets.organization')"
          :value="assetInfos.organization || unknownValue"
        />
        <BaseInfos :name="$t('assets.gpsConectivity')" v-if="showGpsFix">
          <div class="status" :key="gpsAccuracy" :style="gpsConnectStyle">
            <span class="status-value">{{
              gpsAccuracy ? $t(`gpsConnectivity.${gpsAccuracy}`) : unknownValue
            }}</span>
          </div>
        </BaseInfos>
        <BaseInfos
          :name="$t('assets.lastCommunicationTime')"
          :value="lastCommunicationTimeFormatted"
        />
        <BaseInfos :name="$t('assets.operationStatus')">
          <div
            v-if="operationalStatus"
            class="status"
            :key="operationalStatus"
            :style="operationalStatusStyle"
          >
            <span class="status-value">{{
              operationalStatus ? $t(operationalStatus) : unknownValue
            }}</span>
          </div>
        </BaseInfos>
        <BaseInfos :name="$t('assets.systemReleaseVersion')">
          <span class="status-value-dark">{{
            systemReleaseVersion?.v || unknownValue
          }}</span>

          <span
            v-if="isDeployingNewRelease && isDeployingNewRelease.v === true"
          >
            <router-link
              v-if="remoteSoftwareEntitlement.value"
              to="/deploy/index?activeName=pending-updates"
            >
              <span class="status-link">
                {{ $tc('assets.deployingNewRelease') }}
              </span>
            </router-link>

            <el-tooltip
              v-else
              :content="$t('assets.noSoftwareUpdateEntitlement')"
            >
              <span class="disabled-route">
                {{ $tc('assets.deployingNewRelease') }}
              </span>
            </el-tooltip>
          </span>
          <span
            v-else-if="
              isNewSystemReleaseAvailable &&
              isNewSystemReleaseAvailable.v === true
            "
          >
            <router-link
              v-if="remoteSoftwareEntitlement.value"
              to="/deploy/index?activeName=pending-updates"
            >
              <span class="status-link">
                &nbsp;{{ $tc('assets.newSystemReleaseAvailable') }}
              </span>
            </router-link>

            <el-tooltip
              v-else
              :content="$t('assets.noSoftwareUpdateEntitlement')"
            >
              <span class="disabled-route">
                &nbsp;{{ $tc('assets.newSystemReleaseAvailable') }}
              </span>
            </el-tooltip>
          </span>
        </BaseInfos>

        <BaseInfos
          :name="$t('assets.healthScore')"
          v-if="hasHealthFeature || operationalSupportMode"
        >
          <template v-slot:tipInfo>
            <i class="el-icon-info" v-popover:popover></i>
            <el-popover
              ref="popover"
              placement="top-start"
              width="330"
              trigger="hover"
            >
              <div>
                <div class="health-score-container" style="margin-bottom: 10px">
                  <div class="health-score-tip" style="background: #4daf70" />
                  <span class="health-score-content">{{
                    $t('assets.tiphealthScoreGood')
                  }}</span>
                </div>
                <div class="health-score-container" style="margin-bottom: 10px">
                  <div class="health-score-tip" style="background: #ffcd00" />
                  <span class="health-score-content">{{
                    $t('assets.tiphealthScoreWarning')
                  }}</span>
                </div>
                <div class="health-score-container">
                  <div class="health-score-tip" style="background: #be442d" />
                  <span class="health-score-content">{{
                    $t('assets.tiphealthScoreNeedFix')
                  }}</span>
                </div>
              </div>
            </el-popover>
          </template>
          <span
            class="health-score-icon"
            :style="{ backgroundColor: backgroundColorFromHealthStatus }"
          />
          <a @click="openHealthModal" class="health-score-button">{{
            $t('assets.checkHealthFactorList')
          }}</a>
          <DefaultDialog
            :visible.sync="isHealthModalVisible"
            :title="
              truncateTextToMax(assetInfos.companyAssetId || unknownValue, 12) +
              ' - ' +
              $t('assets.healthFactorList')
            "
            :width="'450px'"
            @handle-cancel="handleModalCancel"
          >
            <div class="last-update-time">
              {{ lastCommunicationTimeFormatted }}
            </div>
            <AssetHealthFactorList :currentAsset="$route.params.id" />
          </DefaultDialog>
        </BaseInfos>
        <BaseInfos
          :name="$t('assets.lastBodyEmptyCalibration')"
          v-if="operationalSupportMode"
          :value="formatTimeUtc(lastBodyEmptyCalibration?.v)"
        />
        <BaseInfos
          :name="$t('assets.lastPayloadCalibration')"
          v-if="operationalSupportMode"
          :value="formatTimeUtc(lastPayloadCalibration?.v)"
        />
      </el-col>
    </el-row>
  </WidgetCard>
</template>

<style lang="scss" scoped>
.asset-info-main {
  background-color: #ffffff;
  box-shadow: 0px 3px 6px #c1c1c1;
  border-radius: 8px;
  margin-top: 20px;

  .asset-info-header {
    height: 60px;
    line-height: 60px;
    padding-left: 30px;
    border-bottom: 1px solid #dddddd;
    margin-bottom: 10px;

    .asset-info-title {
      font-size: 18px;
      font-family: $font-Roboto-Medium;
      line-height: 21px;
      color: #373e41;
    }
  }
}

.basic-info-col {
  margin-top: -10px;
}
.health-score-container {
  display: flex;
  align-items: center;
}

.health-score-content {
  width: 300px;
  word-break: keep-all;
}

.health-score-tip {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  margin-right: 15px;
}

.health-score {
  width: 24px;
  height: 24px;
  background: #4daf70;
  border-radius: 50%;
}

.health-score-icon {
  height: 16px;
  min-width: 16px;
  border-radius: 50%;
}

.health-score-button {
  font-family: Roboto;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: left;

  margin-left: 5px;
  font-weight: 600;
  text-decoration: underline;
  cursor: pointer;
}

.last-update-time {
  padding: 15px 25px 5px 25px;
  font-size: 14px;
  font-weight: 600;
}

.status-value {
  font-size: 16px;
  font-family: $font-Roboto-Regular;
  line-height: 32px;
  color: white;
}

.status {
  width: 130px;
  height: 32px;
  display: table-cell;
  text-align: center;
  vertical-align: middle;
  border-radius: 18px;
}

.disabled-route {
  opacity: 0.5;
}

.status-yellow {
  background-color: #ffcd00;
}

.status-gray {
  background-color: gray;
}

.status-red0 {
  background: #efb08c;
}

.status-red1 {
  background: #d35d6e;
}

.status-red {
  background: #be442d;
}

.status-green {
  background-color: #0e918c;
}

.status-orange {
  background-color: #f79b3f;
}

.asset-info-row {
  padding: 10px;
  padding-bottom: 0;
}

.asset-image {
  position: relative;
  max-height: 250px;
  max-width: 400px;
  width: 100%;
  padding-right: 10px;
  padding-bottom: 10px;
}

.loading-asset-image {
  position: relative;
  padding: 10px;
}

.truncate {
  text-overflow: ellipsis;
}

:deep(.base-infos-title) {
  width: 220px !important;
}

.status-value-dark {
  font-size: 16px;
  font-weight: bold;
  font-family: $font-Roboto-Regular;
  line-height: 19px;
  color: #373e41;
}

.status-link {
  font-size: 16px;
  font-family: $font-Roboto-Bold;
  line-height: 19px;
  color: #373e41;

  &:hover {
    color: var(--Main);
  }
}
</style>
