import { AuthorizableResource } from '@/api/users';
import i18n from '@/lang';
import { TreeData } from 'element-ui/types/tree';

export function authorizableResourcesToTreeData(
  resources: AuthorizableResource[],
  disabled: boolean = false
): TreeData[] {
  const recurse = (parentCode: string) => {
    return resources
      .filter((resource) => resource.parentCode === parentCode)
      .map((resource): TreeData => {
        return {
          id: resource.code,
          label: i18n.tc(resource.code),
          disabled,
          children: recurse(resource.code),
        };
      });
  };
  return recurse(''); // The father id of the first level node is an empty string
}
