/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon';
icon.register({
  subscriptionPackages: {
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
    data: `<svg id="Component_351_98" data-name="Component 351 – 98" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
  <defs>
    <style>
      .cls-1, .cls-2 {
        fill: #373e41;
      }

      .cls-2 {
        stroke-linecap: round;
        stroke-linejoin: round;
      }

      .cls-3, .cls-4 {
        stroke: none;
      }

      .cls-4 {
        fill: #373e41;
      }
    </style>
  </defs>
  <g id="Component_349_6" data-name="Component 349 – 6">
    <g id="Component_348_34" data-name="Component 348 – 34">
      <path id="Subtraction_18" data-name="Subtraction 18" class="cls-1" d="M14,20H6a6.007,6.007,0,0,1-6-6V6A6.007,6.007,0,0,1,6,0h8a6.007,6.007,0,0,1,6,6v8A6.006,6.006,0,0,1,14,20ZM6,1A5.006,5.006,0,0,0,1,6v8a5.006,5.006,0,0,0,5,5h8a5.005,5.005,0,0,0,5-5V6a5.006,5.006,0,0,0-5-5Z"/>
    </g>
  </g>
  <g id="Group_3018" data-name="Group 3018" transform="translate(-11 -299.869)">
    <g id="Component_335_8" data-name="Component 335 – 8" transform="translate(14.393 303.869)">
      <g id="Path_1353" data-name="Path 1353" class="cls-2">
        <path class="cls-3" d="M 3.602204322814941 4.341992378234863 L 2.833351850509644 3.95255184173584 L 2.607421875 3.838111877441406 L 2.381491899490356 3.95255184173584 L 1.61264443397522 4.341992378234863 L 1.732171893119812 3.415081977844238 L 1.761751890182495 3.185751914978027 L 1.606171846389771 3.014701843261719 L 0.9705368280410767 2.315846681594849 L 1.853661894798279 2.147461891174316 L 2.093791961669922 2.10168194770813 L 2.205131769180298 1.884051918983459 L 2.607421875 1.097753643989563 L 3.009711980819702 1.884051918983459 L 3.121051788330078 2.10168194770813 L 3.361181974411011 2.147461891174316 L 4.244307041168213 2.315846681594849 L 3.608671903610229 3.014701843261719 L 3.453091859817505 3.18576192855835 L 3.482671976089478 3.415081977844238 L 3.602204322814941 4.341992378234863 Z"/>
        <path class="cls-4" d="M 2.607421875 2.187258958816528 C 2.460460901260376 2.42186713218689 2.221784830093384 2.586277008056641 1.947311878204346 2.638611793518066 L 1.941064119338989 2.639803171157837 L 1.976061820983887 2.678281784057617 C 2.172090530395508 2.893815040588379 2.2643141746521 3.184048414230347 2.228827953338623 3.473022699356079 C 2.471397399902344 3.373768329620361 2.74344801902771 3.373769044876099 2.986016988754272 3.473023176193237 C 2.950530767440796 3.184048891067505 3.042753219604492 2.893805027008057 3.238781929016113 2.678281784057617 L 3.27377986907959 2.639803171157837 L 3.267531871795654 2.638611793518066 C 2.993058919906616 2.586277008056641 2.754382848739624 2.42186713218689 2.607421875 2.187258958816528 M 2.607421875 1.9073486328125e-06 L 3.454831838607788 1.656311988830566 L 5.214841842651367 1.991891860961914 L 3.978561878204346 3.351131916046143 L 4.218892097473145 5.214841842651367 L 2.607421875 4.398591995239258 L 0.9959416389465332 5.214841842651367 L 1.236281871795654 3.351131916046143 L 1.9073486328125e-06 1.991891860961914 L 1.760011911392212 1.656311988830566 L 2.607421875 1.9073486328125e-06 Z"/>
      </g>
      <g id="Path_1440" data-name="Path 1440" class="cls-2" transform="translate(8)">
        <path class="cls-3" d="M 3.602204322814941 4.341992378234863 L 2.833351850509644 3.95255184173584 L 2.607421875 3.838111877441406 L 2.381491899490356 3.95255184173584 L 1.61264443397522 4.341992378234863 L 1.732171893119812 3.415081977844238 L 1.761751890182495 3.185751914978027 L 1.606171846389771 3.014701843261719 L 0.9705368280410767 2.315846681594849 L 1.853661894798279 2.147461891174316 L 2.093791961669922 2.10168194770813 L 2.205131769180298 1.884051918983459 L 2.607421875 1.097753643989563 L 3.009711980819702 1.884051918983459 L 3.121051788330078 2.10168194770813 L 3.361181974411011 2.147461891174316 L 4.244307041168213 2.315846681594849 L 3.608671903610229 3.014701843261719 L 3.453091859817505 3.18576192855835 L 3.482671976089478 3.415081977844238 L 3.602204322814941 4.341992378234863 Z"/>
        <path class="cls-4" d="M 2.607421875 2.187258958816528 C 2.460460901260376 2.42186713218689 2.221784830093384 2.586277008056641 1.947311878204346 2.638611793518066 L 1.941064119338989 2.639803171157837 L 1.976061820983887 2.678281784057617 C 2.172090530395508 2.893815040588379 2.2643141746521 3.184048414230347 2.228827953338623 3.473022699356079 C 2.471397399902344 3.373768329620361 2.74344801902771 3.373769044876099 2.986016988754272 3.473023176193237 C 2.950530767440796 3.184048891067505 3.042753219604492 2.893805027008057 3.238781929016113 2.678281784057617 L 3.27377986907959 2.639803171157837 L 3.267531871795654 2.638611793518066 C 2.993058919906616 2.586277008056641 2.754382848739624 2.42186713218689 2.607421875 2.187258958816528 M 2.607421875 1.9073486328125e-06 L 3.454831838607788 1.656311988830566 L 5.214841842651367 1.991891860961914 L 3.978561878204346 3.351131916046143 L 4.218892097473145 5.214841842651367 L 2.607421875 4.398591995239258 L 0.9959416389465332 5.214841842651367 L 1.236281871795654 3.351131916046143 L 1.9073486328125e-06 1.991891860961914 L 1.760011911392212 1.656311988830566 L 2.607421875 1.9073486328125e-06 Z"/>
      </g>
    </g>
    <g id="Component_335_9" data-name="Component 335 – 9" transform="translate(14.393 310.869)">
      <g id="Path_1353-2" data-name="Path 1353" class="cls-2">
        <path class="cls-3" d="M 3.602204322814941 4.341992378234863 L 2.833351850509644 3.95255184173584 L 2.607421875 3.838111877441406 L 2.381491899490356 3.95255184173584 L 1.61264443397522 4.341992378234863 L 1.732171893119812 3.415081977844238 L 1.761751890182495 3.185751914978027 L 1.606171846389771 3.014701843261719 L 0.9705368280410767 2.315846681594849 L 1.853661894798279 2.147461891174316 L 2.093791961669922 2.10168194770813 L 2.205131769180298 1.884051918983459 L 2.607421875 1.097753643989563 L 3.009711980819702 1.884051918983459 L 3.121051788330078 2.10168194770813 L 3.361181974411011 2.147461891174316 L 4.244307041168213 2.315846681594849 L 3.608671903610229 3.014701843261719 L 3.453091859817505 3.18576192855835 L 3.482671976089478 3.415081977844238 L 3.602204322814941 4.341992378234863 Z"/>
        <path class="cls-4" d="M 2.607421875 2.187258958816528 C 2.460460901260376 2.42186713218689 2.221784830093384 2.586277008056641 1.947311878204346 2.638611793518066 L 1.941064119338989 2.639803171157837 L 1.976061820983887 2.678281784057617 C 2.172090530395508 2.893815040588379 2.2643141746521 3.184048414230347 2.228827953338623 3.473022699356079 C 2.471397399902344 3.373768329620361 2.74344801902771 3.373769044876099 2.986016988754272 3.473023176193237 C 2.950530767440796 3.184048891067505 3.042753219604492 2.893805027008057 3.238781929016113 2.678281784057617 L 3.27377986907959 2.639803171157837 L 3.267531871795654 2.638611793518066 C 2.993058919906616 2.586277008056641 2.754382848739624 2.42186713218689 2.607421875 2.187258958816528 M 2.607421875 1.9073486328125e-06 L 3.454831838607788 1.656311988830566 L 5.214841842651367 1.991891860961914 L 3.978561878204346 3.351131916046143 L 4.218892097473145 5.214841842651367 L 2.607421875 4.398591995239258 L 0.9959416389465332 5.214841842651367 L 1.236281871795654 3.351131916046143 L 1.9073486328125e-06 1.991891860961914 L 1.760011911392212 1.656311988830566 L 2.607421875 1.9073486328125e-06 Z"/>
      </g>
      <g id="Path_1440-2" data-name="Path 1440" class="cls-2" transform="translate(8)">
        <path class="cls-3" d="M 3.602204322814941 4.341992378234863 L 2.833351850509644 3.95255184173584 L 2.607421875 3.838111877441406 L 2.381491899490356 3.95255184173584 L 1.61264443397522 4.341992378234863 L 1.732171893119812 3.415081977844238 L 1.761751890182495 3.185751914978027 L 1.606171846389771 3.014701843261719 L 0.9705368280410767 2.315846681594849 L 1.853661894798279 2.147461891174316 L 2.093791961669922 2.10168194770813 L 2.205131769180298 1.884051918983459 L 2.607421875 1.097753643989563 L 3.009711980819702 1.884051918983459 L 3.121051788330078 2.10168194770813 L 3.361181974411011 2.147461891174316 L 4.244307041168213 2.315846681594849 L 3.608671903610229 3.014701843261719 L 3.453091859817505 3.18576192855835 L 3.482671976089478 3.415081977844238 L 3.602204322814941 4.341992378234863 Z"/>
        <path class="cls-4" d="M 2.607421875 2.187258958816528 C 2.460460901260376 2.42186713218689 2.221784830093384 2.586277008056641 1.947311878204346 2.638611793518066 L 1.941064119338989 2.639803171157837 L 1.976061820983887 2.678281784057617 C 2.172090530395508 2.893815040588379 2.2643141746521 3.184048414230347 2.228827953338623 3.473022699356079 C 2.471397399902344 3.373768329620361 2.74344801902771 3.373769044876099 2.986016988754272 3.473023176193237 C 2.950530767440796 3.184048891067505 3.042753219604492 2.893805027008057 3.238781929016113 2.678281784057617 L 3.27377986907959 2.639803171157837 L 3.267531871795654 2.638611793518066 C 2.993058919906616 2.586277008056641 2.754382848739624 2.42186713218689 2.607421875 2.187258958816528 M 2.607421875 1.9073486328125e-06 L 3.454831838607788 1.656311988830566 L 5.214841842651367 1.991891860961914 L 3.978561878204346 3.351131916046143 L 4.218892097473145 5.214841842651367 L 2.607421875 4.398591995239258 L 0.9959416389465332 5.214841842651367 L 1.236281871795654 3.351131916046143 L 1.9073486328125e-06 1.991891860961914 L 1.760011911392212 1.656311988830566 L 2.607421875 1.9073486328125e-06 Z"/>
      </g>
    </g>
  </g>
</svg>`,
  },
});
