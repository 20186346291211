import { QueryParameter } from '@/model/queryParameters/QueryParameter';
import { getRequest } from '@/utils/request';

export enum PolicyType {
  GENERAL_TERMS = 'PLKD_GENERAL_TERMS',
  PRIVACY_POLICY = 'PLKD_PRIVACY_POLICY',
}

export interface Policy {
  policyUUID: string;
  code: PolicyType;
  policyRegionCode: string;
  policyCurrentVersionUUID: string;
  policyCurrentVersion: string;
  url: string;
  i18nCode: string;
}

export type Policies = Partial<Record<PolicyType, Policy>>;

export async function getPolicies(
  queryParameter: QueryParameter
): Promise<Policies> {
  const request = getRequest();
  const response = await request.post<Policy[]>('/policies', queryParameter);
  const policies: Policies = Object.fromEntries(
    response.data.map((policy) => [policy.code, policy])
  );
  return policies;
}
