<script lang="ts" setup>
import {
  GetTripsWithEventsSummary2HoursDetails,
  SafetyEventType,
  TripsWithEventsSummary2HoursDetails,
} from '@/api/trip';
import { useUnitConversion } from '@/composables/conversion';
import i18n from '@/lang';
import { KPI_UNIT } from '@/utils/units/unitDefinitions';
import {
  getExpandedFleetSafetyHorizontalChartTooltip,
  getSeverityEventLabelLabel,
} from '@/widgets/utils/tripSafety';
import { useResizeObserver } from '@vueuse/core';
import echarts, { EChartOption } from 'echarts';
import { computed, onUnmounted, ref, watch } from 'vue';

const props = defineProps<{
  data: GetTripsWithEventsSummary2HoursDetails | undefined;
  safetyEvent: SafetyEventType | undefined;
  maximumSeverityLevel?: number;
  safetyUnitCode: KPI_UNIT | undefined;
}>();

const {
  currentUserPreferredUnit,
  currentUserConvertNumberMany,
  currentUserConvertNumber,
} = useUnitConversion();

const convertedUnitCode = computed((): KPI_UNIT | undefined =>
  props.safetyUnitCode
    ? currentUserPreferredUnit(props.safetyUnitCode)
    : undefined
);

const convertedMaximumSeverityLevel = computed((): number | undefined => {
  if (
    props.maximumSeverityLevel === undefined ||
    props.safetyUnitCode === undefined
  ) {
    return undefined;
  }

  return currentUserConvertNumber(
    props.maximumSeverityLevel,
    props.safetyUnitCode
  );
});

const convertedData = computed(
  (): GetTripsWithEventsSummary2HoursDetails | undefined => {
    const safetyUnitCode = props.safetyUnitCode;
    if (!props.data || !safetyUnitCode) {
      return undefined;
    }

    return {
      numberOfAssetsWithTrips: props.data.numberOfAssetsWithTrips,
      tripsWithEventsDetailedSummary:
        props.data.tripsWithEventsDetailedSummary.map(
          (summary): TripsWithEventsSummary2HoursDetails => ({
            bucketLabel: summary.bucketLabel,
            tripsWithEventsSummary: summary.tripsWithEventsSummary,
            eventsDetailedSummary: currentUserConvertNumberMany(
              summary.eventsDetailedSummary,
              [
                'averageSeverityLevel',
                'maximumSeverityLevel',
                'minimumSeverityLevel',
                'sumOfSeverityLevel',
              ],
              safetyUnitCode
            ),
          })
        ),
    };
  }
);

const chartRef = ref<HTMLDivElement | HTMLCanvasElement>();
const chartInstance = ref<echarts.ECharts>();

function initChart(chartDOMElement: HTMLDivElement | HTMLCanvasElement) {
  chartInstance.value = echarts.init(chartDOMElement);
  chartInstance.value.on(
    'legendselectchanged',
    (legend: { name: string; selected: Record<string, boolean> }) => {
      legendSelectState.value = legend.selected;
    }
  );
}

function disposeChart() {
  if (chartInstance.value) {
    chartInstance.value.dispose();
    chartInstance.value = undefined;
  }
}

onUnmounted(() => {
  disposeChart();
});

// Create chartInstance from chartRef.
// Conceptually, it's a computed, but we also need to properly dispose
// of the chart when the DOM element gets destroyed, so we're building
// it with a watch instead.
watch(chartRef, (chartDOMElement) => {
  if (chartInstance.value) {
    disposeChart();
  }

  if (!chartDOMElement) {
    return;
  }

  initChart(chartDOMElement);
});

useResizeObserver(chartRef, () => {
  if (chartInstance.value) {
    chartInstance.value.resize();
  }
});

const eventSeverityLabel = computed((): string | undefined => {
  if (!props.safetyEvent || !convertedUnitCode.value) {
    return undefined;
  }

  return getSeverityEventLabelLabel(props.safetyEvent, convertedUnitCode.value);
});

const legendSelectState = ref<Record<string, boolean>>();

const eventSeverity = computed((): (string | number)[][] => {
  const finalResult =
    convertedData.value?.tripsWithEventsDetailedSummary.map((summary) => [
      summary.bucketLabel,
      summary.eventsDetailedSummary.averageSeverityLevel,
    ]) ?? [];

  return finalResult;
});

const ratioChartDate = computed((): (string | number)[][] => {
  const finalResult =
    props.data?.tripsWithEventsDetailedSummary.map((summary) => [
      summary.bucketLabel,
      summary.tripsWithEventsSummary.ratioPercentage,
    ]) ?? [];

  return finalResult;
});

const severityCandleStickData = computed((): (string | number)[][] => {
  let result: (string | number)[][] = [];
  const filteredEvents =
    convertedData.value?.tripsWithEventsDetailedSummary.filter(
      (trip: TripsWithEventsSummary2HoursDetails) =>
        trip.eventsDetailedSummary.numberOfEvents > 1
    );

  if (filteredEvents != undefined && filteredEvents.length) {
    result = filteredEvents.map((trip: TripsWithEventsSummary2HoursDetails) => [
      trip.bucketLabel,
      trip.eventsDetailedSummary.minimumSeverityLevel,
      trip.eventsDetailedSummary.maximumSeverityLevel,
    ]);
  }

  return result;
});

const lineSeriesItem = computed(
  (): EChartOption.Series => ({
    name: i18n.t('newFleetSafety.ratio'),
    type: 'line',
    xAxisIndex: 1,
    data: ratioChartDate.value,
    barGap: '50%',
    encode: {
      x: 1,
      y: 0,
    },
  })
);

const barSeriesItem = computed(
  (): EChartOption.Series => ({
    name: eventSeverityLabel.value,
    type: 'bar',

    data: eventSeverity.value,
    barMaxWidth: window.innerWidth < 1920 ? 10 : 20,
    encode: {
      x: 1,
      y: 0,
    },
  })
);

const candleStickSeriesItem = computed((): EChartOption.Series => {
  const showCandlesticks =
    !legendSelectState.value ||
    (eventSeverityLabel.value &&
      legendSelectState.value[eventSeverityLabel.value]);

  if (!showCandlesticks) {
    // Basically an empty/hidden series item
    return { type: 'custom' };
  }

  return {
    type: 'custom',
    name: 'error',
    renderItem: function (params: any, api: any) {
      var yValue = api.value(0);
      var highPoint = api.coord([api.value(1), yValue]);
      var lowPoint = api.coord([api.value(2), yValue]);
      var halfTickHeight = api.size([0, 1])[1] * 0.2;
      var style = api.style({
        stroke: 'black',
      });

      return {
        type: 'group',
        children: [
          {
            type: 'line',
            transition: ['shape'],
            shape: {
              x1: highPoint[0],
              y1: highPoint[1] - halfTickHeight,
              x2: highPoint[0],
              y2: highPoint[1] + halfTickHeight,
            },
            style: style,
          },
          {
            type: 'line',
            transition: ['shape'],
            shape: {
              x1: highPoint[0],
              y1: highPoint[1],
              x2: lowPoint[0],
              y2: lowPoint[1],
            },
            style: style,
          },
          {
            type: 'line',
            transition: ['shape'],
            shape: {
              x1: lowPoint[0],
              y1: lowPoint[1] - halfTickHeight,
              x2: lowPoint[0],
              y2: lowPoint[1] + halfTickHeight,
            },
            style: style,
          },
        ],
      };
    },
    encode: {
      x: [1, 2],
      y: 0,
    },
    data: severityCandleStickData.value,
    z: 100,
  };
});

const LEGEND_HEIGHT: number = 30;
const NAME_HEIGHT: number = 14;
const X_AXIS_LABELS_HEIGHT: number = 28;
const Y_LEFT_AXIS_LABELS_WIDTH: number = 40;
const LEGEND_ITEM_GAP: number = 40;
const RIGHT_MARGIN: number = 14;

const grid = {
  left: Y_LEFT_AXIS_LABELS_WIDTH + NAME_HEIGHT,
  right: RIGHT_MARGIN,
  top: LEGEND_HEIGHT + X_AXIS_LABELS_HEIGHT + NAME_HEIGHT,
  bottom: X_AXIS_LABELS_HEIGHT + NAME_HEIGHT,
};

const options = computed((): EChartOption => {
  return {
    color: ['#8bd0f5', '#0138f7'],
    tooltip: {
      show: true,
      trigger: 'item',
      axisPointer: {
        type: 'shadow',
      },
      formatter: function (params: any) {
        if (!props.safetyEvent || !convertedUnitCode.value) return '';

        const bucketLabel = params.value?.[0];

        if (!bucketLabel) return '';

        const bucketObj =
          convertedData.value?.tripsWithEventsDetailedSummary.find(
            (item) => item.bucketLabel === bucketLabel
          );

        if (bucketObj)
          return getExpandedFleetSafetyHorizontalChartTooltip(
            bucketObj,
            props.safetyEvent,
            convertedUnitCode.value
          );

        return '';
      },
    },
    legend: {
      data: [
        {
          name: eventSeverityLabel.value,
          icon: 'rect',
        },
        {
          name: i18n.t('newFleetSafety.ratio'),
          icon: 'line',
        },
      ],
      selected: legendSelectState.value ?? {},
      orient: 'horizontal',
      left: 'center',
      top: 0,
      itemGap: LEGEND_ITEM_GAP,
    },
    grid,
    xAxis: [
      {
        name: eventSeverityLabel.value,
        type: 'value',
        position: 'bottom',
        max: convertedMaximumSeverityLevel.value,
        nameLocation: 'middle',
        nameGap: X_AXIS_LABELS_HEIGHT,
        nameTextStyle: {
          fontWeight: 'bold',
        },
        axisLine: {
          show: true,
          onZero: false,
        },
        axisTick: {
          show: true,
        },
        splitLine: {
          show: false,
        },
      },
      {
        name: i18n.t('newFleetSafety.ratio'),
        nameLocation: 'middle',
        nameTextStyle: {
          fontWeight: 'bold',
        },
        nameGap: X_AXIS_LABELS_HEIGHT,
        position: 'top',
        type: 'value',
        axisLine: {
          show: true,
        },
        axisTick: {
          show: true,
        },
        splitLine: {
          show: true,
        },
        min: 0,
        max: 100,
        splitNumber: 10,
        show: true,
      },
    ],
    yAxis: [
      {
        // This is the axis onto which the bars etc are plotted,
        // which is a category axis to make sure the buckets align
        // correctly.
        type: 'category',
        position: 'left',
        splitNumber: 12,
        inverse: true,
        axisLine: {
          show: false,
        },
        axisTick: {
          show: false,
        },
        splitLine: {
          show: false,
        },
        axisLabel: {
          show: false,
        },
        z: 10,
        data: [
          '00:00',
          '02:00',
          '04:00',
          '06:00',
          '08:00',
          '10:00',
          '12:00',
          '14:00',
          '16:00',
          '18:00',
          '20:00',
          '22:00',
        ],
      },
      {
        // This is a 'fake' axis to show the time ticks + labels
        // on the correct spots around the bars.
        name: i18n.t('newFleetSafety.time'),
        nameTextStyle: {
          fontWeight: 'bold',
        },
        type: 'value',
        position: 'left',
        nameLocation: 'middle',
        nameGap: Y_LEFT_AXIS_LABELS_WIDTH,
        min: 0,
        max: 12,
        splitNumber: 12,
        inverse: true,
        axisLabel: {
          formatter: function (value: any, index: number) {
            const hours = (index * 2).toString().padStart(2, '0');
            return `${hours}:00`;
          },
        },
        axisLine: {
          show: true,
        },
        axisTick: {
          show: true,
        },
        splitLine: {
          show: false,
        },
        z: 10,
      },
    ],
    series: [
      barSeriesItem.value,
      lineSeriesItem.value,
      candleStickSeriesItem.value,
    ],
  };
});

watch([chartInstance, options], () => {
  chartInstance.value?.setOption(options.value, { notMerge: true });
});
</script>

<template>
  <!--
    In case of props.data being undefined, DO render the chart, because
    the most likely case is that the data will come in very soon.
    Only when props.data is defined (but 'empty'), should we render "no data".
    We're using a <p> instead of a <div>, because if both branches of the v-if/else
    are the same type of component, the Vue virtual DOM doesn't properly clean up
    the div that ECharts instantiated itself in.
  -->
  <p
    v-if="convertedData?.tripsWithEventsDetailedSummary.length === 0"
    class="no-data"
  >
    {{ $t('common.noData') }}
  </p>
  <div
    v-else
    data-testid="horizontal-average-severity-stacked-bar-chart"
    class="horizontal-average-severity-stacked-bar-chart"
    ref="chartRef"
  ></div>
</template>

<style lang="scss" scoped>
.horizontal-average-severity-stacked-bar-chart {
  width: 100%;
  height: 100%;
}

.no-data {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  font-weight: bold;
  margin: auto;
  height: 100%;
}
</style>
