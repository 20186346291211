import {
  getModule,
  Module,
  Mutation,
  VuexModule,
} from 'vuex-module-decorators';
import store from '@/store';
import type { TranslateResult } from 'vue-i18n';

@Module({ dynamic: true, store, name: 'page' })
class Page extends VuexModule {
  title: TranslateResult[] = [];

  @Mutation
  public setTitle(title: TranslateResult | TranslateResult[]) {
    this.title = Array.isArray(title) ? title : [title];
  }
}

export const PageModule = getModule(Page);
