<script lang="ts">
import {
  getFirmwareReleaseNotes,
  getSystemReleaseNotes,
} from '@/api/systemReleaseManagement';
import DefaultDialog from '@/components/dialog/DefaultDialog.vue';
import { formatTimer } from '@/utils/misc';
import { Moment } from 'moment';
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
  name: 'ContentContainer',
  components: {
    DefaultDialog,
  },
})
export default class extends Vue {
  @Prop() content!: any[];
  @Prop() title!: string;
  @Prop() showReleaseNotes?: string;
  @Prop() productSystemReleaseID?: string;

  systemNoteId: string = 'productSystemRelease.systemReleaseNotes';
  firmwareNoteId: string =
    'productSystemRelease.partFirmwareServiceReleaseNotes';
  dialogTitle: string = '';
  dialogVisible: boolean = false;
  dialogContent: string = '';
  dateTimeFields: string[] = ['productSystemRelease.manifestCreationTime'];
  systemReleaseRegionsReference: string = 'productSystemRelease.region';

  checkForTitle() {
    return this.title !== undefined;
  }

  isDateTimeField(rowId: string): boolean {
    return this.dateTimeFields.includes(rowId);
  }

  isSystemReleaseRegion(rowId: string): boolean {
    return this.systemReleaseRegionsReference === rowId;
  }

  async showNotes(title: string) {
    this.dialogTitle = title;
    const firmwareElement = this.content.find(
      (el) => el.id === 'productSystemRelease.partFirmwareReleaseUUID'
    );
    const response =
      (this.productSystemReleaseID &&
        (this.showReleaseNotes
          ? await getSystemReleaseNotes(this.productSystemReleaseID)
          : (firmwareElement &&
              (await getFirmwareReleaseNotes(
                this.productSystemReleaseID,
                firmwareElement.value
              ))) ||
            null)) ||
      null;
    if (response?.code === 200) {
      this.dialogContent = response.data;
    } else {
      this.dialogContent = this.$t(
        'releaseDetails.productSystemRelease.releaseNotesCouldNotBeFound'
      ).toString();
    }
    this.dialogVisible = true;
  }

  handleDialogClose(): void {
    this.dialogVisible = false;
  }

  formatDateAndTime(manifestCreationTime: string): string | Moment {
    return formatTimer(manifestCreationTime, 'datetime');
  }
}
</script>

<template>
  <div>
    <ul class="container">
      <h3 class="title" v-if="title">
        {{ $t(title) }}
      </h3>
      <li
        v-for="(row, index) in !!productSystemReleaseID
          ? [
              ...(!!showReleaseNotes
                ? content
                : content.filter(
                    (e) =>
                      e.id !== 'productSystemRelease.partFirmwareReleaseUUID'
                  )),
              {
                id: !!showReleaseNotes ? systemNoteId : firmwareNoteId,
                value: '',
              },
            ]
          : content"
        v-bind:key="index"
        class="row"
      >
        <p class="lable">{{ $t(`releaseDetails.${row.id}`) }}:</p>
        <p v-if="row.id === systemNoteId || row.id === firmwareNoteId">
          <span
            class="vlaue read-more"
            @click="showNotes($tc(`releaseDetails.${row.id}`))"
            >{{ $t('releaseDetails.clickToRead') }}</span
          >
        </p>
        <p v-else-if="isDateTimeField(row.id)" class="value">
          {{ formatDateAndTime(row.value) }}
        </p>
        <p v-else-if="isSystemReleaseRegion(row.id)" class="value">
          {{ $t(row.value) }}
        </p>
        <p v-else class="value">{{ $t(row.value?.toString()) }}</p>
      </li>
    </ul>
    <DefaultDialog
      :visible.sync="dialogVisible"
      :title="dialogTitle"
      @handle-cancel="handleDialogClose"
    >
      <div class="dialog-content">
        {{ dialogContent }}
      </div>
    </DefaultDialog>
  </div>
</template>

<style lang="scss" scoped>
.read-more {
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  text-decoration-line: underline;

  &:hover {
    cursor: pointer;
  }
}

.container {
  border: 1px solid #707070;
  border-radius: 8px;
  text-align: left;
  display: flex;
  flex-direction: column;
  margin: 0px;
  padding: 18px;
  gap: 0px !important;
}

.row {
  display: flex;
  gap: 30px;
  font-size: 16px;
  line-height: 19px;
}

.lable {
  width: 264px;
  color: #373e41;
  text-transform: capitalize;
  font-weight: 400;
  font-family: Roboto;
  opacity: 0.6;
}

.value {
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
}

.title {
  margin: 0 0 24px 0;
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
}

.dialog-content {
  width: 100%;
  max-height: 60vh;
  overflow-y: auto;
  padding: 1rem;
  white-space: pre-line;
}
</style>
