<script lang="ts">
import { DashboardTemplate, getTemplates } from '@/api/dashboardConf';
import BaseCard from '@/components/cusCard/BaseCard.vue';
import PureTable from '@/components/table/PureTable.vue';
import SelectTableHeader from '@/components/table/SelectTableHeader.vue';
import { UserModule } from '@/store/modules/user';
import { generateUrl } from '@/utils/index';
import {
  DASHBOARDCONF_COLS,
  DASHBOARDCONF_SEARCH_FIELDS,
} from '@/utils/workData/dashboardConf';
import {
  COMPANY_TYPE_LIST,
  DashboardType,
  INDUSTRY_LIST,
} from '@/utils/workData/lookuptable';
import { Component, Vue } from 'vue-property-decorator';

interface TableRowData extends DashboardTemplate {
  translatedIndustry: string;
  translatedCustomerType: string;
  translatedType: string;
}

@Component({
  name: 'DashboardConf',
  components: {
    BaseCard,
    SelectTableHeader,
    PureTable,
  },
})
export default class extends Vue {
  pageSize = UserModule.gridPageSize;
  dashboardConfCols = DASHBOARDCONF_COLS.map((item) => ({
    ...item,
    label: this.$t(item.label),
  }));
  dashboardFieldList = DASHBOARDCONF_SEARCH_FIELDS;
  custTypes = COMPANY_TYPE_LIST;
  industries = INDUSTRY_LIST;
  isTemplateTableLoading: boolean = false;
  templateName: string = '';
  custType: string = '';
  industry: string = '';
  loadingText: string = this.$t('dashboardConf.loadingTemplates') as string;
  tableList: TableRowData[] = [];
  total: number = 0;

  searchObj: any = {
    reference: null,
    value: null,
  };

  async handlePagination(page: number, pageSize: number) {}

  getFilterUrl(col: string, order: string) {
    let filterParams: any = {
      customerType: this.custType,
      industry: this.industry,
      orderBy: col,
      order: order,
    };

    let key: string = this.searchObj.reference;
    let value: string = this.searchObj.value;

    if (key && value) {
      filterParams[key] = value;
    }

    return generateUrl(filterParams);
  }

  async handleFilter(col: string, order: string) {
    let paramsUrl: string = this.getFilterUrl(col, order);
    this.fetchTemplates(paramsUrl);
  }

  handleCopy(rowInfo: any) {
    this.$router.push({
      path: `/dashboard-conf/edit/${rowInfo.id}`,
      query: {
        action: 'copy',
      },
    });
  }

  async fetchTemplates(filter: string) {
    this.isTemplateTableLoading = true;
    await getTemplates(filter).then((res) => {
      if (!res) return;

      if (res.code === 200) {
        let resData = res.data;
        this.total = resData.length;

        resData.forEach((item) => {
          item.type = item.isDefault
            ? DashboardType.default
            : DashboardType.normal;
          item.action = [
            {
              name: this.$t('COPY'),
              disabled: false,
              onClickEmitterName: 'COPY',
            },
          ];
        });

        this.tableList = resData.map((item) => ({
          ...item,
          translatedCustomerType: this.$t(item.customerType),
          translatedIndustry: this.$t(item.industry),
          translatedType: item.type ? this.$t(item.type) : '',
        }));
      }

      this.isTemplateTableLoading = false;
    });
  }

  created() {
    this.fetchTemplates('');
  }
}
</script>

<template>
  <BaseCard
    ref="baseCardRef"
    :title="$t('AUTHRSC_MOD_DASHBOARD_CONFIG')"
    :backIconVisible="false"
    :showDialogBeforeBack="false"
    @handle-back="false"
    style="overflow: auto; overflow-x: hidden"
  >
    <div id="dashboard_conf_home_table" class="dashboard-conf-container">
      <SelectTableHeader
        style="margin-bottom: 20px"
        :searchFieldOptions="dashboardFieldList"
        :searchParams="searchObj"
        @search-event="handleFilter"
      >
        <template #leftHeader>
          <el-select
            id="dashboard_conf_cust_type_select"
            class="util-table-select"
            v-model="custType"
            :placeholder="$t('dashboardConf.custType')"
            clearable
            @change="handleFilter"
          >
            <el-option
              v-for="(item, index) in custTypes"
              :key="index"
              :label="$t(item.id)"
              :value="item.id"
            />
          </el-select>

          <el-select
            id="dashboard_conf_industry_select"
            class="util-table-select"
            v-model="industry"
            :placeholder="$t('dashboardConf.industry')"
            clearable
            @change="handleFilter"
          >
            <el-option
              v-for="(item, index) in industries"
              :key="index"
              :label="$t(item.id)"
              :value="item.id"
            />
          </el-select>
        </template>

        <template #rightHeader>
          <el-button
            id="dashboard_conf_add_button"
            v-permission="['AUTHRSC_ACTION_DASHBOARD_TMPL_CREATE']"
            type="plain"
            @click="$router.push('create')"
            style="margin-right: 20px"
          >
            <i class="el-icon-plus common-icon" />{{
              $t('dashboardConf.buildNewTmp')
            }}
          </el-button>
        </template>
      </SelectTableHeader>

      <PureTable
        id="dashboard_conf_util_table"
        v-loading="isTemplateTableLoading"
        :element-loading-text="loadingText"
        :tableList="tableList"
        :cols="dashboardConfCols"
        :showPagination="false"
        :total="total"
        :viewPagePath="'view'"
        :maxHeight="'680'"
        @handle-page="handlePagination"
        @COPY="handleCopy"
        @handle-sort-change="handleFilter"
      />

      <div class="d-flex ai-center" style="margin-top: 10px">
        <span class="total-statistics"
          >{{ $t('dashboardConf.templateTotalInfo') }}:</span
        >
        <span class="total-statistics-value">{{ total }}</span>
      </div>
    </div>
  </BaseCard>
</template>

<style lang="scss" scoped>
.total-statistics {
  font-size: 1.428571rem; //20px
  font-family: $font-Roboto-Medium;
  line-height: 2rem; //28px
  color: #373e41;
  opacity: 1;
}

.dashboard-conf-container {
  margin: 16px;
}

.el-input {
  width: 300px;
}

.el-select {
  margin-right: 40px;
}

.util-table-input :deep(.el-input__inner) {
  height: $inputHeight;
  width: 300px;
  // width: 31vw;
  border: 1px solid #707070 !important;
  font-size: 1.285714rem;
  font-family: $font-Roboto-Regular;
  line-height: 1.357143rem;
  color: #373e41;
  opacity: 1;
}

.util-table-select :deep(.el-input__inner) {
  height: $inputHeight;
  width: 200px;
  // width: 31vw;
  border: 1px solid #707070 !important;
  font-size: 1.285714rem;
  font-family: $font-Roboto-Regular;
  line-height: 1.357143rem;
  color: #373e41;
  opacity: 1;
}
</style>
