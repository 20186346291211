<script lang="ts">
import moment from 'moment';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

import { TemplateResponse } from '@/api/report';
import TimeSelect from '@/components/form/TimeSelect.vue';
import { KpisDataPayload } from '@/utils/kpidata';
import {
  DEFAULT_DATE_RANGE,
  getRangePeriod,
  REPORTDATE_FORMAT,
} from '@/utils/time';
import { DateRange } from '@/utils/types/date';
import {
  ASSET_SCOPE_LIST,
  ASSET_SCOPE_SINGLE_ASSET,
} from '@/utils/workData/reportMgmt';

import { StatisticalData, StatisticalKpiSummary } from '@/api/assets';
import { KpiDataField, KpiDataValue } from '@/api/kpis';
import {
  useUnitConversion,
  UseUnitConversionReturnType,
} from '@/composables/conversion';
import { KPI_UNIT } from '@/utils/units/unitDefinitions';
import { toUnitValue, UnitValue } from '@/utils/units/unitValue';
import { getKpisData } from '../helpers/getKpisData';
import { substractDaysFromData } from '../helpers/substractDaysFromData';
import ExportReportModalVue from './components/ExportReportModal.vue';

interface GENERATE_REPORT {
  reportScope: string;
  reportScopeValue: string;
  startDate?: string;
  endDate?: string;
}

interface ReportScoaValue {
  id: string;
  name: string;
}

@Component({
  name: 'ViewReportHeader',
  components: {
    TimeSelect,
    ExportReportModalVue,
  },
})
export default class extends Vue {
  @Prop({ required: true }) scope!: string;
  @Prop({ required: true }) scopeValue!: string;
  @Prop({ required: true }) startDate!: string;
  @Prop({ required: true }) endDate!: string;
  @Prop() reportScopedValueList!: ReportScoaValue[];
  @Prop({ required: true }) template!: TemplateResponse;
  @Prop({ default: false }) isGeneratedReportVisible!: boolean;

  assetScope = ASSET_SCOPE_LIST;
  dateRange = DEFAULT_DATE_RANGE;

  generateReportFilter: GENERATE_REPORT = {
    reportScope: '',
    reportScopeValue: '',
    startDate: '',
    endDate: '',
  };
  isExportReportModalVisible: boolean = false;
  kpisData: KpisDataPayload = {} as KpisDataPayload;
  unitConversion!: UseUnitConversionReturnType;

  mounted() {
    this.unitConversion = useUnitConversion();
    this.handleGenerateReport();
  }

  /**
   * Watch over report scope field and when is cleared triggered generate report event to parent
   */
  @Watch('generateReportFilter.reportScope')
  handleReportScopeInputChange() {
    if (!Boolean(this.generateReportFilter.reportScope)) {
      this.handleGenerateReport();
    }
  }

  /**
   * Watch over report scope value field and when is cleared triggered generate report event to parent
   */
  @Watch('generateReportFilter.reportScopeValue')
  handleReportScopeValueChange() {
    if (!Boolean(this.generateReportFilter.reportScopeValue)) {
      this.handleGenerateReport();
    }
  }

  /**
   * Watch over report time range field and when is changed triggered generate report event to parent
   * To be triggered only when generated report is visible
   */
  @Watch('dateRange', { deep: true })
  handleReportTimeRangeChange() {
    if (!this.isGeneratedReportVisible) {
      return;
    }
    this.isGeneratedReportVisible = false;
    this.handleGenerateReport();
  }

  /**
   * Handle report scope change
   */
  handleReportScopeChange(): void {
    this.$emit('asset-scope-changed', this.generateReportFilter.reportScope);
    this.generateReportFilter.reportScopeValue = '';
  }

  /**
   * Handle report scope value change
   */
  handleSelectedReportScopeValue(): void {
    this.$emit(
      'selected-asset-name',
      this.reportScopedValueList.find(
        (item: ReportScoaValue) =>
          item.id === this.generateReportFilter.reportScopeValue
      )?.name
    );
    this.$emit(
      'report-scope-value-changed',
      this.generateReportFilter.reportScopeValue
    );
  }

  /**
   * Handle date range change
   * To be triggered only when generated report is already visible
   * @param dateRange
   */
  dateRangeChange(dateRange: DateRange): void {
    this.dateRange = dateRange;
    this.selectedDays(getRangePeriod(dateRange));
    this.generateReportFilter.startDate = this.dateRange.start;
    this.generateReportFilter.endDate = this.dateRange.endExclusive;
    if (this.isGeneratedReportVisible) {
      this.handleGenerateReport();
    }
  }

  selectedDays(days: number | string) {
    const daysString = days.toString();

    if (isNaN(parseInt(daysString))) {
      this.$emit('selected-days');
      return;
    }

    this.$emit('selected-days', daysString);
  }

  /**
   * Handle generate report event
   */
  handleGenerateReport(): void {
    this.$emit('generate-report', this.generateReportFilter);
  }

  /**
   * Handle subscribe modal visible event
   */
  handleSubscribeModalVisible(): void {
    this.$emit('subscribe-modal-visible-event', true);
  }

  /**
   * Check if report scope field has data
   */
  get reportScopeHasData(): boolean {
    return !!this.generateReportFilter.reportScope;
  }

  /**
   * Check if report scope value field has data
   */
  get reportScopeVlueHasData(): boolean {
    return !!this.generateReportFilter.reportScopeValue;
  }

  /**
   * Check if there are all filters checked an can generate report
   */
  get canGenerateReport(): boolean {
    return (
      Boolean(this.generateReportFilter.reportScope) &&
      Boolean(this.generateReportFilter.reportScopeValue) &&
      Boolean(this.dateRange.start) &&
      Boolean(this.dateRange.endExclusive)
    );
  }

  /**
   * Get report scope value placeholder
   */
  get reportReportValuePlaceholder(): string {
    return this.generateReportFilter?.reportScope == ASSET_SCOPE_SINGLE_ASSET
      ? this.$tc('report.selectAsset')
      : this.$tc('report.selectOrganization');
  }

  exportReport(): void {
    this.isExportReportModalVisible = true;
    this.getReportData();
  }

  convertUnit(value: number, unit: KPI_UNIT): UnitValue<number> {
    return this.unitConversion.currentUserConvertUnitValue(
      toUnitValue(value, unit)
    );
  }

  convertSummaryItem(item: StatisticalKpiSummary): StatisticalKpiSummary {
    const convertedAvg = this.convertUnit(item.avg, item.unit);
    const convertedSum = this.convertUnit(item.sum, item.unit);

    return {
      ...item,
      avg: convertedAvg.v,
      sum: convertedSum.v,
      unit: convertedSum.unit,
    };
  }

  convertKpiDataField(kpiItem: KpiDataField): KpiDataField {
    const convertedField = this.convertUnit(kpiItem.sumPerAsset!, kpiItem.unit);

    const convertedValues = kpiItem.values.map((value): KpiDataValue => {
      return {
        ...value,
        ...this.convertUnit(parseFloat(value.v), kpiItem.unit),
      };
    });

    return {
      code: kpiItem.code,
      sumPerAsset: convertedField.v,
      unit: convertedField.unit,
      values: convertedValues,
    };
  }

  convertKpiDataItem(item: StatisticalData): StatisticalData {
    const convertedFields = item.fields.map((kpiItem) => {
      return this.convertKpiDataField(kpiItem);
    });

    return {
      ...item,
      fields: convertedFields,
    };
  }

  getReportData(): void {
    try {
      this.getKpisDataResponse().then((data) => {
        const convertedSummary = data.kpis.summary.map(
          (item: StatisticalKpiSummary) => {
            return this.convertSummaryItem(item);
          }
        );

        const convertedKpiData = data.kpis.kpiData.map(
          (item: StatisticalData) => {
            return this.convertKpiDataItem(item);
          }
        );

        this.kpisData = {
          ...data,
          kpis: {
            kpiData: convertedKpiData,
            summary: convertedSummary,
          },
        };
      });
    } catch (error) {
    } finally {
    }
  }

  /**
   * Fetch kpis data remotly from the API
   */
  async getKpisDataResponse(): Promise<KpisDataPayload> {
    return getKpisData({
      assetScope: this.scope,
      template: this.template,
      assetId: this.scopeValue,
      startDate: this.startDate,
      endDate: this.endDate,
    });
  }

  formatDateForReportTimestamp(date: string): string {
    return moment(date).format(REPORTDATE_FORMAT);
  }

  substractData(date: string): string {
    return substractDaysFromData({
      dateToBeSubstracted: date,
      dateFormat: REPORTDATE_FORMAT,
    });
  }
}
</script>

<template>
  <div class="container">
    <div class="d-flex ai-center flex-g1">
      <div class="header-report-scope">
        <div class="header-item report-scope">
          <el-select
            id="report-scope-element"
            v-model="generateReportFilter.reportScope"
            @change="handleReportScopeChange"
            :placeholder="$t('report.reportScope')"
            filterable
            clearable
          >
            <el-option
              v-for="item in assetScope"
              :key="item.id"
              :label="$t(item.id)"
              :value="item.id"
            />
          </el-select>
        </div>

        <div class="header-item report-scope-value">
          <el-select
            id="report-scope-value-element"
            v-model="generateReportFilter.reportScopeValue"
            @change="handleSelectedReportScopeValue"
            :placeholder="
              reportScopeHasData ? reportReportValuePlaceholder : ''
            "
            :disabled="!reportScopeHasData"
            :title="!reportScopeHasData ? $t('common.completeAllFilters') : ''"
            filterable
            clearable
          >
            <el-option
              v-for="item in reportScopedValueList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            />
          </el-select>
        </div>

        <TimeSelect
          class="time-picker"
          :expanded="true"
          @select="dateRangeChange"
        />
      </div>

      <el-button
        id="generate-report-btn-element"
        class="generate-report-btn"
        type="info"
        @click="handleGenerateReport"
        :disabled="!canGenerateReport"
        :title="!canGenerateReport ? $t('common.completeAllFilters') : ''"
      >
        {{ $t('report.generateReport') }}
      </el-button>
    </div>

    <div class="d-flex ai-center right-header-btn-group">
      <el-button
        class="right-side-btn subscribe-report-btn"
        type="plain"
        icon="el-icon-plus"
        @click="handleSubscribeModalVisible"
        :disabled="!isGeneratedReportVisible"
        :title="
          !isGeneratedReportVisible ? $t('common.completeAllFilters') : ''
        "
      >
        {{ $t('report.subscribeReport') }}
      </el-button>
      <el-button
        id="export_report_button"
        :disabled="!isGeneratedReportVisible"
        type="plain"
        icon="el-icon-download"
        @click="exportReport"
      >
        {{ $t('report.exportReport') }}
      </el-button>
    </div>
    <ExportReportModalVue
      v-if="isExportReportModalVisible"
      :visible.sync="isExportReportModalVisible"
      :title="$t('report.exportReport')"
      :fileName="`${kpisData.template.name}_${formatDateForReportTimestamp(
        startDate
      )}-${substractData(endDate)}`"
      :data="kpisData"
      :startDate="startDate"
      :endDate="endDate"
    />
  </div>
</template>

<style lang="scss" scoped>
.time-picker {
  margin-top: auto;
  margin-bottom: auto;
}

.generate-report-btn {
  height: 40px;
  border-radius: 6px;
  margin-left: 10px;
}

.container {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.header-report-scope {
  display: flex;
  flex-wrap: wrap;

  :deep(.el-input__inner) {
    height: 40px;
    border: 1px solid #818181;
  }

  :deep(.el-select) {
    margin-right: 3px !important;
  }
}

:deep(.el-icon-circle-close) {
  font-size: 18px;
}
</style>
