import {
  computed,
  getCurrentInstance,
  onUnmounted,
  Ref,
  ref,
  unref,
} from 'vue';
import VueRouter, { Route, RouteMeta } from 'vue-router';

export function useRouter(): VueRouter {
  const instance = getCurrentInstance();

  if (!instance) {
    throw Error(
      'cannot get router reference: cannot determine Vue instance context'
    );
  }

  return instance.proxy.$router;
}

export function useRoute(): Ref<Route> {
  const router = useRouter();
  const route = ref(router.currentRoute);
  const callback = ref(router.afterEach((to) => (route.value = to)));

  onUnmounted(() => callback.value());

  return route;
}

/**
 * Shallow merge all meta data of each matching route record of the route.
 *
 * I.e. all meta keys from parents down to children will be merged, where
 * keys from children override those from the parent.
 */
export function useMergedRouteMeta(
  route: Ref<Route> | Route = useRoute()
): Ref<RouteMeta> {
  return computed(() =>
    unref(route).matched.reduce(
      (merged, routeRecord) => ({ ...merged, ...routeRecord.meta }),
      {}
    )
  );
}
