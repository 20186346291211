/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon';
icon.register({
  prodCata: {
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
    data: '<g data-name="Component 353 – 9"><path pid="0" data-name="Subtraction 15" d="M14 20H6a6.007 6.007 0 01-6-6V6a6.007 6.007 0 016-6h8a6.007 6.007 0 016 6v8a6.006 6.006 0 01-6 6zM6 1a5.006 5.006 0 00-5 5v8a5.006 5.006 0 005 5h8a5.005 5.005 0 005-5V6a5.006 5.006 0 00-5-5z" _fill="#373e41"/></g><path pid="1" data-name="Path 1481" d="M14.258 15.051a.851.851 0 01-.851.851H6.596a.851.851 0 110-1.7h6.811a.851.851 0 01.851.849zm0-3.405a.851.851 0 01-.851.851H6.596a.851.851 0 110-1.7h6.811a.851.851 0 01.851.854zm0-3.405a.851.851 0 01-.851.851H6.596a.851.851 0 110-1.7h6.811a.851.851 0 01.851.849zm-3.405-3.405a.851.851 0 01-.851.851H6.597a.851.851 0 110-1.7h3.405a.851.851 0 01.851.849z" _fill="#373e41"/>',
  },
});
