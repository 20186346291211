/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon';
icon.register({
  alarmConfiguration: {
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
    data: `<svg id="Component_356_156" data-name="Component 356 – 156" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
      <g id="Component_353_9" data-name="Component 353 – 9">
        <path id="Subtraction_15" data-name="Subtraction 15" d="M14,20H6a6.007,6.007,0,0,1-6-6V6A6.007,6.007,0,0,1,6,0h8a6.007,6.007,0,0,1,6,6v8A6.006,6.006,0,0,1,14,20ZM6,1A5.006,5.006,0,0,0,1,6v8a5.006,5.006,0,0,0,5,5h8a5.005,5.005,0,0,0,5-5V6a5.006,5.006,0,0,0-5-5Z" transform="translate(0 0)" fill="#373e41"/>
      </g>
      <g id="alarm-clock" transform="translate(3.887 -18.813)">
        <g id="Group_3329" data-name="Group 3329" transform="translate(1.45 24.539)">
          <g id="Group_3328" data-name="Group 3328">
            <path id="Path_1536" data-name="Path 1536" d="M65.419,77.017a4.662,4.662,0,1,0,4.662,4.662A4.667,4.667,0,0,0,65.419,77.017Zm0,8.479a3.817,3.817,0,1,1,3.817-3.817A3.821,3.821,0,0,1,65.419,85.5Z" transform="translate(-60.757 -77.017)"/>
          </g>
        </g>
        <g id="Group_3331" data-name="Group 3331" transform="translate(5.436 27.004)">
          <g id="Group_3330" data-name="Group 3330" transform="translate(0 0)">
            <path id="Path_1537" data-name="Path 1537" d="M229.66,182.3h-1.127v-1.634a.423.423,0,0,0-.845,0v2.056a.422.422,0,0,0,.423.423h1.549a.423.423,0,1,0,0-.845Z" transform="translate(-227.688 -180.243)"/>
          </g>
        </g>
        <g id="Group_3333" data-name="Group 3333" transform="translate(1.746 32.117)">
          <g id="Group_3332" data-name="Group 3332" transform="translate(0 0)">
            <path id="Path_1538" data-name="Path 1538" d="M75.115,394.476a.423.423,0,0,0-.6.031l-1.268,1.408a.423.423,0,1,0,.628.565l1.268-1.408A.422.422,0,0,0,75.115,394.476Z" transform="translate(-73.143 -394.368)"/>
          </g>
        </g>
        <g id="Group_3335" data-name="Group 3335" transform="translate(8.366 32.117)">
          <g id="Group_3334" data-name="Group 3334" transform="translate(0 0)">
            <path id="Path_1539" data-name="Path 1539" d="M352.393,395.92l-1.268-1.408a.422.422,0,1,0-.628.565l1.268,1.408a.422.422,0,1,0,.628-.565Z" transform="translate(-350.389 -394.371)"/>
          </g>
        </g>
        <g id="Group_3337" data-name="Group 3337" transform="translate(0 23.256)">
          <g id="Group_3336" data-name="Group 3336" transform="translate(0 0)">
            <path id="Path_1540" data-name="Path 1540" d="M3.647,23.875a2.151,2.151,0,0,0-3.017,0A2.129,2.129,0,0,0,0,25.4a2.1,2.1,0,0,0,.634,1.5.423.423,0,0,0,.594,0L3.65,24.476a.423.423,0,0,0,0-.6ZM.976,25.956A1.259,1.259,0,0,1,.845,25.4a1.29,1.29,0,0,1,1.29-1.3,1.32,1.32,0,0,1,.565.127Z" transform="translate(0 -23.26)"/>
          </g>
        </g>
        <g id="Group_3339" data-name="Group 3339" transform="translate(8.45 23.256)">
          <g id="Group_3338" data-name="Group 3338">
            <path id="Path_1541" data-name="Path 1541" d="M357.061,23.875a2.152,2.152,0,0,0-3.017,0,.422.422,0,0,0,0,.6l2.422,2.422a.422.422,0,0,0,.594,0,2.1,2.1,0,0,0,.634-1.5A2.13,2.13,0,0,0,357.061,23.875Zm-.345,2.077-1.725-1.725a1.293,1.293,0,0,1,1.856,1.17A1.259,1.259,0,0,1,356.716,25.952Z" transform="translate(-353.918 -23.256)"/>
          </g>
        </g>
      </g>
    </svg>`,
  },
});
