<script lang="ts">
import {
  getDefaultTemplate,
  getModules,
  getPagesByModuleCode,
  getTemplatesById,
  Page,
} from '@/api/dashboardConf';
import { GenericWidget, getWidgetsByPageCode } from '@/api/widgets';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
// import VueGridLayout from 'vue-grid-layout'
import { ALL_PAGE_CODES, Module } from '@/api/users';
import {
  filterWidgetsByPermissions,
  NUMBER_OF_GRID_COLUMNS,
  scaleHorizontal,
} from '@/utils/widget';
import { PAGE_NO_WIDGET } from '@/utils/workData/dashboardConf';
import { ALL_CLAIMS_CODES, COMPANY_TYPE } from '@/utils/workData/lookuptable';
import { PAGE_LAYOUT_MAP } from '@/utils/workData/utilMap';
import { Tree } from 'element-ui';
import { TreeData } from 'element-ui/types/tree';
import { GridItem, GridLayout } from 'vue-grid-layout';
import { filterModulesForCompanyType } from './moduleFiltering';
import { modulesToTreeData } from './tree';

var mouseXY = {
  x: 0,
  y: 0,
};

var DragPos = {
  i: '',
  x: 0,
  y: 0,
};

interface WIDGET_TYPE {
  i?: string;
  x: number;
  y: number;
  w: number;
  h: number;
  minW: number;
  minH: number;
  maxW: number;
  maxH: number;
  isResizable: boolean;
  code: string;
  pageCode: ALL_PAGE_CODES;
  name: string;
}

@Component({
  name: 'EditDashTemplate',
  components: {
    'el-tree': Tree,
    'grid-layout': GridLayout,
    'grid-item': GridItem,
  },
})
export default class extends Vue {
  @Prop() id!: string;
  @Prop() customerType!: COMPANY_TYPE;
  @Prop({ default: true }) showModule!: boolean;
  @Prop({ default: false }) filterByPermission!: boolean;
  @Prop({
    default: () => {
      return {
        moduleSpan: 5,
        pageSpan: 15,
        widgetSpan: 4,
      };
    },
  })
  templateColSpan!: any;

  pageLayoutMap = PAGE_LAYOUT_MAP;
  pageNoWidget = PAGE_NO_WIDGET;
  pageNoWidgetFlag: boolean = false;
  currentNodeKey: string = ALL_CLAIMS_CODES.AUTHRSC_MOD_HOME;
  colNum: number = NUMBER_OF_GRID_COLUMNS;
  loadingPage: boolean = false;
  loadingWidget: boolean = false;
  loadingTree: boolean = false;

  modulesInfo: any = [];
  treeData: TreeData[] = [];
  defaultChecked: string[] = [ALL_CLAIMS_CODES.AUTHRSC_MOD_HOME];

  pages: Page[] = [];

  layout: WIDGET_TYPE[] = [];
  widgets: GenericWidget[] = [];
  widgetsBuffer: Map<string, WIDGET_TYPE[]> = new Map();

  pageCheckListBufer: Map<string, string[]> = new Map();
  currentPageCode: string = '';
  currentModuleNode: string = '';
  currentPages: string[] = [];
  checkPageList: ALL_PAGE_CODES[] = [ALL_CLAIMS_CODES.AUTHRSC_PAGE_HOME];
  checkedNodeList: string[] = [];
  modules: Module[] = [];

  @Watch('pages')
  listenLoadPages() {
    this.$nextTick(() => {
      this.renderPageBg(0);
    });
  }

  @Watch('customerType', {
    deep: true,
  })
  updateTreeData() {
    const newModules = filterModulesForCompanyType(
      this.modules,
      this.customerType
    );
    this.treeData = modulesToTreeData(newModules);
  }

  handlePageCheck() {
    let isExit: boolean = false;
    for (let item of this.pages) {
      if (this.checkPageList.includes(item.code)) {
        isExit = true;
        break;
      }
    }

    if (isExit) {
      if (!this.defaultChecked.includes(this.currentModuleNode)) {
        this.defaultChecked.push(this.currentModuleNode);
      }

      (this.$refs.createPageTree as any).setCheckedKeys(this.defaultChecked);
    } else {
      this.defaultChecked.splice(
        this.defaultChecked.findIndex(
          (item) => item === this.currentModuleNode
        ),
        1
      );
      (this.$refs.createPageTree as any).setCheckedKeys(this.defaultChecked);
    }
  }

  async handleModuleCheck(node: any, checkedNodes: any) {
    this.currentPages = [];
    const res = await getPagesByModuleCode('', node.name);
    res.data.forEach((item: any) => {
      this.currentPages.push(item.code);
    });

    if (!checkedNodes.checkedKeys.includes(node.name)) {
      this.checkPageList = this.checkPageList.filter((item: string) => {
        return !this.currentPages.includes(item);
      });
      this.defaultChecked.splice(
        this.defaultChecked.findIndex((item) => item === node.name),
        1
      );
    } else {
      if (node.children.length > 0) {
        node.children.forEach((item: any) => {
          this.defaultChecked.push(item.name);
        });
      } else {
        this.defaultChecked.push(node.name);
      }

      this.currentPages.forEach((item: any) => {
        if (!this.checkPageList.includes(item)) {
          this.checkPageList.push(item);
        }
      });
    }
  }

  updateWidgetStatus(widgetId: string, dragDisable: boolean) {
    if (!document.getElementById(widgetId)) return false;

    let widgetDom = document.getElementById(widgetId) as HTMLElement;
    widgetDom.draggable = dragDisable;
    widgetDom.style.cursor = dragDisable ? 'pointer' : 'not-allowed';
    widgetDom.style.background = dragDisable ? '' : 'rgb(175, 175, 175, 0.6)';
    widgetDom.style.color = dragDisable ? '' : 'rgb(55, 62, 65, 0.6)';
  }

  removeGridItem(pageCode: string, widgetCode: string) {
    const index = this.layout.map((item) => item.code).indexOf(widgetCode);
    this.layout.splice(index, 1);

    let widgetId = pageCode + '_' + widgetCode;
    this.updateWidgetStatus(widgetId, true);
  }

  drag(val: any) {
    let parentRect: any = (
      document.getElementById('content') as any
    ).getBoundingClientRect();

    let mouseInGrid = false;
    if (
      mouseXY.x > parentRect.left &&
      mouseXY.x < parentRect.right &&
      mouseXY.y > parentRect.top &&
      mouseXY.y < parentRect.bottom
    ) {
      mouseInGrid = true;
    }

    if (
      mouseInGrid === true &&
      this.layout.findIndex((item) => item.i === 'drop') === -1
    ) {
      this.layout.push({
        i: 'drop',
        x: (this.layout.length * 2) % (this.colNum || 12),
        y: this.layout.length + (this.colNum || 12),
        w: val.w,
        h: val.h,
        minW: 1,
        minH: 1,
        maxW: 4,
        maxH: 5,
        isResizable: false,
        code: 'code',
        /* @ts-expect-error TODO Wrong type */
        pageCode: 'pageCode',
        name: 'grid',
      });
    }

    let index = this.layout.findIndex((item) => item.i === 'drop');
    if (index !== -1) {
      try {
        (this.$refs.gridlayout as any).$children[
          this.layout.length
        ].$refs.item.style.display = 'none';
      } catch {}

      let childIndex = (this.$refs.gridlayout as any).$children.length - 1;

      let el = (this.$refs.gridlayout as any).$children[childIndex];
      el.dragging = {
        top: mouseXY.y - parentRect.top,
        left: mouseXY.x - parentRect.left,
      };

      let new_pos = el.calcXY(
        mouseXY.y - parentRect.top,
        mouseXY.x - parentRect.left
      );

      if (mouseInGrid === true) {
        (this.$refs.gridlayout as any).dragEvent(
          'dragstart',
          'drop',
          new_pos.x,
          new_pos.y,
          val.h,
          val.w
        );

        DragPos.i = val.i;
        DragPos.x = this.layout[index].x;
        DragPos.y = this.layout[index].y;
      }

      if (mouseInGrid === false) {
        (this.$refs.gridlayout as any).dragEvent(
          'dragend',
          'drop',
          new_pos.x,
          new_pos.y,
          1,
          1
        );
        this.layout = this.layout.filter((obj) => obj.i !== 'drop');
      }
    }
  }

  dragend(val: WIDGET_TYPE) {
    let parentRect: any = (
      document.getElementById('content') as any
    ).getBoundingClientRect();

    let mouseInGrid = false;
    if (
      mouseXY.x > parentRect.left &&
      mouseXY.x < parentRect.right &&
      mouseXY.y > parentRect.top &&
      mouseXY.y < parentRect.bottom
    ) {
      mouseInGrid = true;
    }

    if (mouseInGrid === true) {
      // alert(`Dropped element props:\n${JSON.stringify(this.DragPos, ['x', 'y', 'w', 'h'], 2)}`);

      (this.$refs.gridlayout as any).dragEvent(
        'dragend',
        'drop',
        DragPos.x,
        DragPos.y,
        1,
        1
      );

      this.layout = this.layout.filter((obj) => obj.i !== 'drop');

      this.layout.push({
        i: DragPos.i,
        x: DragPos.x,
        y: DragPos.y,
        w: val.w,
        h: val.h,
        minW: val.minW,
        minH: val.minH,
        maxW: val.maxW,
        maxH: val.maxH,
        isResizable: val.isResizable,
        code: val.code,
        pageCode: val.pageCode,
        name: val.name,
      });

      (this.$refs.gridlayout as any).dragEvent(
        'dragend',
        DragPos.i,
        DragPos.x,
        DragPos.y,
        1,
        1
      );

      try {
        (this.$refs.gridlayout as any).$children[
          this.layout.length
        ].$refs.item.style.display = 'block';
      } catch {}
    }

    for (let i = this.layout.length - 1; i >= 0; --i) {
      if (this.layout[i].code === val.code) {
        let widgetId = val.pageCode + '_' + val.code;
        this.updateWidgetStatus(widgetId, false);
        break;
      }
    }
  }

  async handleResetToDefault(
    customerType: string,
    industry: string,
    templateId?: string
  ) {
    const response = templateId
      ? await getTemplatesById(templateId)
      : await getDefaultTemplate(customerType, industry);
    if (response.data) {
      this.fetchCheckedModules(response.data.id);
      this.currentPageCode = '';

      this.updateWidgetsBuffer(
        response.data.widgets.map(
          (widget): GenericWidget => ({
            ...widget,
            templateId: '00000000-0000-0000-0000-000000000000',
            pageCode: widget.pageCode,
            x: 0,
            y: 0,
          })
        )
      );

      (this.$refs.createPageTree as any).setCurrentKey(
        ALL_CLAIMS_CODES.AUTHRSC_MOD_HOME
      );

      this.handleNodeClick({
        name: ALL_CLAIMS_CODES.AUTHRSC_MOD_HOME,
        children: [],
      });
    }
  }

  cancelModulePageCheckboxBgColor() {
    var modulePageCheckbox = document.getElementsByClassName(
      'module-page-checkbox'
    );
    Array(...modulePageCheckbox).forEach((item: any) => {
      item.style.backgroundColor = 'transparent';
    });
  }

  renderPageBg(index: number) {
    this.cancelModulePageCheckboxBgColor();
    var modulePageCheckbox: any = document.getElementsByClassName(
      'module-page-checkbox'
    );
    if (modulePageCheckbox[index]) {
      modulePageCheckbox[index].style.backgroundColor = 'var(--Main)';
    }
  }

  updateWidgetsBuffer(widgets: GenericWidget[]) {
    widgets = this.filterWidgets(widgets);

    const newWidgetsBuffer = widgets.reduce((cumulative, widget) => {
      const pageLayout = this.pageLayoutMap.get(widget.pageCode);
      const scaledWidget =
        pageLayout != undefined
          ? scaleHorizontal(
              [widget],
              NUMBER_OF_GRID_COLUMNS,
              pageLayout.column
            )[0]
          : widget;
      if (cumulative.has(widget.pageCode)) {
        cumulative.get(widget.pageCode)?.push(scaledWidget);
      } else {
        cumulative.set(widget.pageCode, [scaledWidget]);
      }
      return cumulative;
    }, new Map<ALL_PAGE_CODES, WIDGET_TYPE[]>());

    this.widgetsBuffer = newWidgetsBuffer;
    this.checkPageList = [...newWidgetsBuffer.keys()];
  }

  async handleNodeClick(node: any) {
    this.currentModuleNode = node.name;

    if (node.children.length === 0) {
      this.loadingPage = true;
      const res = await getPagesByModuleCode('', node.name);
      let pageData = res.data;
      this.pages = pageData;

      this.loadingPage = false;

      this.handlePageClick(0, pageData[0]?.code);
    }
  }

  updateWidget() {
    if (this.currentPageCode) {
      this.widgetsBuffer.set(this.currentPageCode, this.layout);
    }
  }

  handlePageClick(index: number, pageCode: ALL_PAGE_CODES) {
    /** background */
    this.renderPageBg(index);

    this.updateWidget();

    this.currentPageCode = pageCode;
    this.layout = [];

    if (this.pageNoWidget.includes(pageCode)) {
      this.pageNoWidgetFlag = true;
      this.layout = [
        {
          x: 0,
          y: 0,
          w: 0,
          h: 0,
          minW: 0,
          minH: 0,
          maxW: 0,
          maxH: 0,
          isResizable: false,
          code: 'WIDGET_NULL',
          pageCode: pageCode,
          name: 'null',
        },
      ];
      this.widgets = [];
    } else {
      this.colNum =
        this.pageLayoutMap.get(pageCode)?.column ?? NUMBER_OF_GRID_COLUMNS;

      this.pageNoWidgetFlag = false;
      const widgets = this.widgetsBuffer.get(this.currentPageCode);
      if (widgets != undefined) {
        this.layout = widgets.map((x) => ({ ...x }));
      }

      this.fetchWidgets(pageCode);
    }
  }

  filterWidgets(widgets: GenericWidget[]) {
    if (this.filterByPermission) {
      return filterWidgetsByPermissions(widgets);
    }
    return widgets;
  }

  async fetchWidgets(pageCode: string) {
    this.loadingWidget = true;

    const res = await getWidgetsByPageCode(pageCode);
    const pageLayout = this.pageLayoutMap.get(pageCode);
    this.widgets = pageLayout
      ? scaleHorizontal<GenericWidget>(
          this.filterWidgets(res.data),
          NUMBER_OF_GRID_COLUMNS,
          pageLayout.column
        )
      : this.filterWidgets(res.data);

    this.loadingWidget = false;

    let widgetCodes: string[] = this.layout.map((item: any) => item.code);

    this.$nextTick(() => {
      this.widgets.map((item: any) => {
        let widgetId = item.pageCode + '_' + item.code;
        if (widgetCodes.includes(item.code)) {
          this.updateWidgetStatus(widgetId, false);
        } else {
          this.updateWidgetStatus(widgetId, true);
        }
      });
    });
  }

  async fetchModules() {
    this.modules = (await getModules('')).data;
    this.updateTreeData();
  }

  setCheckedModules(resCheckedModules: any) {
    this.defaultChecked = [];

    let checkedModules = modulesToTreeData(resCheckedModules.data);

    let arr: string[] = [];
    checkedModules.forEach((item) => {
      if (item.children && item.children.length > 0) {
        item.children.forEach((item) => {
          arr.push(item.name);
        });
      } else {
        arr.push(item.name);
      }
    });

    this.defaultChecked = arr;

    (this.$refs.createPageTree as any).setCheckedKeys(this.defaultChecked);
    this.loadingTree = false;
  }

  async fetchCheckedModules(templateId: string) {
    this.loadingTree = true;
    const resCheckedModules = await getModules(templateId);
    this.setCheckedModules(resCheckedModules);
  }

  filterModules() {
    let unSelectedModules: string[] = [];

    (this.$refs.createPageTree as any)
      .getCheckedNodes(false, true)
      .forEach((item: any) => {
        this.checkedNodeList.push(item.name);
      });

    let unCheckedNodeList = this.modulesInfo.filter((item: any) => {
      return !this.checkedNodeList.includes(item.code);
    });

    unCheckedNodeList.forEach((item: any) => {
      unSelectedModules.push(item.code);
    });

    return unSelectedModules;
  }

  isPageNull() {
    for (let page of this.checkPageList) {
      if (!this.pageNoWidget.includes(page)) {
        if (
          !this.widgetsBuffer.has(page) ||
          this.widgetsBuffer.get(page)?.length === 0
        ) {
          this.$message({
            type: 'error',
            // message: `${this.$t(page)} Is Null`,
            // widget should be added.
            message: `${this.$t('dashboardConf.pageWidgetsNull')}`,
            duration: 3000,
          });

          return true;
        }
      }
    }

    return false;
  }

  isCheckedNodeListNull() {
    if (this.checkedNodeList.length === 0) {
      this.$message({
        type: 'error',
        message: `${this.$t('dashboardConf.tipSelectModules')}`,
        duration: 2000,
      });

      return true;
    }

    return false;
  }

  getAllWidgets() {
    let allWidgets: WIDGET_TYPE[] = [];

    this.checkPageList.map((pageCode: ALL_PAGE_CODES) => {
      let scaledPageWidgets = this.widgetsBuffer.get(pageCode);
      if (!scaledPageWidgets) {
        scaledPageWidgets = [
          {
            x: 0,
            y: 0,
            w: 0,
            h: 0,
            minW: 0,
            minH: 0,
            maxW: 0,
            maxH: 0,
            isResizable: false,
            code: 'WIDGET_NULL',
            pageCode,
            name: 'null',
          },
        ];
      }

      // Apply conversion to widget scale here (back to 12 columns)
      const pageLayout = this.pageLayoutMap.get(pageCode);
      const pageWidgets = pageLayout
        ? scaleHorizontal(
            scaledPageWidgets,
            pageLayout.column,
            NUMBER_OF_GRID_COLUMNS
          )
        : scaledPageWidgets;

      allWidgets.push.apply(allWidgets, pageWidgets);
    });

    return allWidgets;
  }

  mounted() {
    document.addEventListener(
      'dragover',
      function (e) {
        // prevent default to allow drop
        e.preventDefault();
        (e.dataTransfer as any).dropEffect = 'move';

        mouseXY.x = e.clientX;
        mouseXY.y = e.clientY;
      },
      false
    );
  }

  created() {
    this.fetchModules();
    if (!this.id) {
      this.handleNodeClick({
        name: ALL_CLAIMS_CODES.AUTHRSC_MOD_HOME,
        children: [],
      });
    }
  }

  isHomepage(code: string): boolean {
    return code == ALL_CLAIMS_CODES.AUTHRSC_PAGE_HOME;
  }
}
</script>

<template>
  <el-row class="new-template-content">
    <el-col :span="templateColSpan.moduleSpan" v-if="showModule">
      <div>
        <div class="module-page-widget-title">
          {{ $t('dashboardConf.modules') }}
        </div>
        <div v-loading="loadingTree">
          <el-tree
            id="edit_template_tree"
            ref="createPageTree"
            class="dashboard-conf-tree"
            node-key="name"
            :data="treeData"
            :current-node-key="currentNodeKey"
            :default-checked-keys="defaultChecked"
            show-checkbox
            @node-click="handleNodeClick"
            @check="handleModuleCheck"
          />
        </div>
      </div>
    </el-col>

    <el-col
      :span="templateColSpan.pageSpan"
      class="new-template-content-layout"
    >
      <div class="module-page-widget-title" v-if="showModule">
        {{ $t('dashboardConf.pages') }}
      </div>
      <div style="border-bottom: 1px solid #dddddd" v-if="showModule">
        <el-scrollbar>
          <el-checkbox-group v-model="checkPageList">
            <div class="d-flex ai-center" v-loading="loadingPage">
              <div
                v-for="(item, index) in pages"
                :key="index"
                :id="item.code"
                class="module-page-checkbox"
              >
                <el-checkbox
                  :label="item.code"
                  :disabled="isHomepage(item.code)"
                  style="margin: 10px 10px"
                  @change="handlePageCheck"
                >
                  <span @click.prevent="handlePageClick(index, item.code)">{{
                    $t('pageTitle.' + item.code)
                  }}</span>
                </el-checkbox>
              </div>
            </div>
          </el-checkbox-group>
        </el-scrollbar>
      </div>
      <div id="content">
        <div
          v-show="pageNoWidgetFlag"
          class="d-flex d-flex-col ai-center"
          style="padding-top: 18%"
        >
          <img
            src="@/assets/imgs/noWidget.svg"
            style="width: 61px; height: 61px"
          />
          <div class="layout-no-widget">
            No widget is available for this page!
          </div>
        </div>
        <grid-layout
          v-show="!pageNoWidgetFlag"
          ref="gridlayout"
          :layout.sync="layout"
          :col-num="colNum"
          :row-height="114"
          :responsive="false"
          :is-draggable="true"
          :vertical-compact="true"
          :use-css-transforms="true"
        >
          <grid-item
            v-for="item in layout"
            :key="item.i"
            :i="item.i"
            :x="item.x"
            :y="item.y"
            :w="item.w"
            :h="item.h"
            :minW="item.minW"
            :minH="item.minH"
            :maxW="item.maxW"
            :maxH="item.maxH"
            :isResizable="item.isResizable"
            drag-allow-from=".vue-draggable-handle"
          >
            <div
              :id="'LAYOUT_' + item.pageCode + '_' + item.code"
              class="grid-card"
            >
              <div class="vue-draggable-handle">
                <i class="el-icon-rank" style="color: #000; font-size: 24px" />
              </div>
              <div
                class="vue-grid-remove"
                @click="removeGridItem(item.pageCode, item.code)"
              >
                <i class="el-icon-delete" />
              </div>
              <div class="grid-card-content">{{ $t(item.code) }}</div>
            </div>
          </grid-item>
        </grid-layout>
      </div>
    </el-col>

    <el-col :span="templateColSpan.widgetSpan">
      <div>
        <div>
          <div class="module-page-widget-title">
            {{ $t('dashboardConf.widgets') }}
          </div>
          <div v-loading="loadingWidget">
            <div
              v-for="(item, index) in widgets"
              :key="index"
              :id="item.pageCode + '_' + item.code"
              draggable="true"
              @drag="drag({ i: item.i, w: item.w, h: item.h })"
              @dragend="dragend(item)"
              class="widget-title"
            >
              {{ $t(item.code) }}
            </div>
          </div>
        </div>
      </div>
    </el-col>
  </el-row>
</template>

<style>
/* .vue-grid-item.vue-grid-placeholder {
    background: var(--Main) ;
    opacity: .2;
} */
</style>

<style lang="scss" scoped>
:deep(.el-scrollbar__bar.is-vertical) {
  display: none !important;
}

:deep(.el-scrollbar__wrap) {
  overflow: scroll;
  height: 58px; //多出来的20px是横向滚动条默认的样式
}

:deep(.el-scrollbar .el-scrollbar__wrap .el-scrollbar__view) {
  white-space: nowrap;
  display: inline-block;
}

.new-template-content {
  border-top: 1px solid #dddddd;
  border-bottom: 1px solid #dddddd;
  border-right: 1px solid #dddddd;
  // min-height: 73vh;

  .new-template-content-layout {
    border-right: 1px solid #dddddd;
    border-left: 1px solid #dddddd;
    height: 100%;
  }

  .module-page-widget-title {
    padding: 10px 0 10px 10px;
    font-size: 18px;
    font-family: $font-Roboto-Medium;
    line-height: 21px;
    color: #373e41;
    border-bottom: 1px solid #dddddd;
  }

  .widget-title {
    font-size: 14px;
    font-family: $font-Roboto-Regular;
    line-height: 40px;
    color: #373e41;

    padding-left: 10px;
    border: 1px solid #dddddd;
    cursor: pointer;
    margin: 5px 5px 0;
    text-align: center;

    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  .widget-title:hover {
    background-color: var(--Main);
  }
}

#content {
  min-height: 65.5vh;
  // overflow-y: auto;
  background: rgba(228, 228, 228, 0.6);
}

.dashboard-conf-tree :deep(.el-tree-node) {
  margin-bottom: 0px !important;
}
.dashboard-conf-tree :deep(.el-tree-node__content) {
  // margin: 10px 0px;
  height: 40px;
  border-bottom: 1px solid #dddddd;
}

.dashboard-conf-tree :deep(.el-tree-node.is-current > .el-tree-node__content) {
  background-color: var(--Main);
}

// /* Scrollbar slider */
// ::-webkit-scrollbar-thumb {
//   background-color: #a1a3a9;
//   border-radius: 5px;
// }

// ::-webkit-scrollbar {
//   width: 10px;
//   height: 10px;
// }

.vue-grid-item {
  touch-action: none;
}

.vue-grid-item:not(.vue-grid-placeholder) {
  background: transparent;
  // border: 1px solid rgba(55, 62, 65, 0.6);
  // border-radius: 2px;
}

.vue-grid-item .resizing {
  opacity: 0.9;
}

.vue-grid-item .static {
  background: #cce;
}

.vue-grid-item .no-drag {
  height: 100%;
  width: 100%;
}

.vue-grid-item .minMax {
  font-size: 12px;
}

.vue-grid-item .add {
  cursor: pointer;
}

.droppable-element {
  width: 150px;
  text-align: center;
  background: #fdd;
  border: 1px solid black;
  margin: 10px 0;
  padding: 10px;
}

.grid-card:hover {
  border: none;
  background: #ffea98;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.4);

  .vue-draggable-handle {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    padding: 5px 8px 8px 0;
    cursor: pointer;
    font-size: 24px;
    color: #373e41;
  }

  .vue-grid-remove {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    padding: 5px 8px 8px 0;
    cursor: pointer;
    font-size: 24px;
    color: #373e41;
  }
}

.grid-card {
  border: 1px solid #373e41;
  border-radius: 2px;
}

.vue-grid-item .grid-card {
  height: 100%;
  font-size: 18px;
  font-family: $font-Roboto-Regular;
  line-height: 24px;
  color: #373e41;

  .grid-card-content {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.vue-draggable-handle {
  display: none;
  // position: absolute;
  // top: 0;
  // left: 0;
  // padding: 5px 8px 8px 0;
  // cursor: pointer;
  // font-size: 24px;
  // color: #373E41;
}

.vue-grid-remove {
  display: none;
  // position: absolute;
  // top: 0;
  // right: 0;
  // padding: 5px 8px 8px 0;
  // cursor: pointer;
  // font-size: 24px;
  // color: #373E41;
}

.layout-no-widget {
  margin-top: 34px;
  font-size: 34px;
  font-family: $font-Roboto-Regular;
  font-weight: 400;
  line-height: 45px;
  color: #373e41;
  opacity: 0.5;
}
</style>
