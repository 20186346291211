<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import NavPage from '@/components/nav/NavPage.vue';
import ProductModels from './productModels/ProductModels.vue';
import PartsWarehouse from './partsWarehouse/PartsWarehouse.vue';
import { UserModule } from '@/store/modules/user';
import { ALL_CLAIMS_CODES } from '@/utils/workData/lookuptable';

@Component({
  name: 'ProductCatalogue',
  components: {
    NavPage,
    'product-models': ProductModels,
    'parts-warehouse': PartsWarehouse,
  },
})
export default class extends Vue {
  activeName: string = 'productModels';

  mounted() {
    this.showTabs();
  }

  handleTabClick() {
    if (
      this.activeName === 'productModels' &&
      this.$route.query.activeName != 'productModels' &&
      UserModule.claims.hasClaim(ALL_CLAIMS_CODES.AUTHRSC_PAGE_PROD_MDLS)
    ) {
      this.$router.push('/prod-cata/index?activeName=productModels');
    } else if (
      this.activeName === 'partsWarehouse' &&
      this.$route.query.activeName != 'partsWarehouse' &&
      UserModule.claims.hasClaim(ALL_CLAIMS_CODES.AUTHRSC_PAGE_PARTS_WHSE)
    ) {
      this.$router.push('/prod-cata/index?activeName=partsWarehouse');
    }
  }

  showTabs() {
    if (this.$route.query.activeName) {
      this.activeName = this.$route.query.activeName as string;
    } else {
      this.activeName = 'productModels';
    }
  }
}
</script>

<template>
  <NavPage :visible="false">
    <template #button v-if="activeName === 'productModels'">
      <el-button
        id="prod_cata_prod_model_add_model_button"
        v-permission="['AUTHRSC_ACTION_PROD_MDL_CREATE']"
        type="plain"
        @click="$router.push('/prod-cata/create-new-model')"
      >
        <i class="el-icon-plus common-icon" />{{
          $t('prodCata.addProductModel')
        }}
      </el-button>
    </template>

    <template #button v-else>
      <div>
        <el-button
          id="prod_cata_prod_ware_approve_button"
          v-permission="['AUTHRSC_ACTION_PART_APPROVE']"
          type="plain"
          @click="$router.push('/prod-cata/batch-approve')"
        >
          <i class="el-icon-circle-check common-icon" />{{
            $t('prodCata.batchApprove')
          }}
        </el-button>

        <el-button
          id="prod_cata_prod_ware_add_part_button"
          v-permission="['AUTHRSC_ACTION_PART_CREATE']"
          type="plain"
          @click="$router.push('/prod-cata/create-new-part')"
        >
          <i class="el-icon-plus common-icon" />{{
            $t('prodCata.addProductPart')
          }}
        </el-button>
      </div>
    </template>
    <el-tabs
      id="prod_cata_tabs"
      v-model="activeName"
      @tab-click="handleTabClick"
      style="padding: 0 20px"
    >
      <el-tab-pane
        id="prod_cata_model"
        name="productModels"
        :label="$t('prodCata.productModels')"
      >
        <product-models v-if="activeName === 'productModels'" />
      </el-tab-pane>
      <el-tab-pane
        id="prod_cata_parts_warehouse"
        name="partsWarehouse"
        :label="$t('prodCata.partsWarehouse')"
      >
        <parts-warehouse v-if="activeName === 'partsWarehouse'" />
      </el-tab-pane>
    </el-tabs>
  </NavPage>
</template>

<style lang="scss" scoped>
.user-setting-form {
  margin: 0 20px;
}
</style>
