<script lang="ts">
import { AsyncValue } from '@/composables/async';
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';

export interface AssetTypeSelectItem {
  assetType: string;
  selected: boolean;
}

@Component({
  name: 'MultiAssetTypeSelect',
})
export default class extends Vue {
  @Prop() assetTypeList!: AsyncValue<AssetTypeSelectItem[] | undefined>;
  @Prop() error!: string | undefined;
  @Prop() disabled?: boolean;

  selectedAssetTypeOptions: string[] = [];

  // el-select props
  selectedAssetTypeOptionsTranslated: string[] = [];

  //el-switch props
  selectedAssetTypesSwitch: AssetTypeSelectItem[] = [];

  allDropdownAssetTypesSelected: boolean = true;

  /**
   * Check the received list changes to reflect in the dropdown
   * @param newData
   */
  @Watch('assetTypeList', { immediate: true })
  handleData(newData: AsyncValue<AssetTypeSelectItem[] | undefined>): void {
    this.determineAllSelectionState();
    if (newData.data?.length) {
      this.selectedAssetTypeOptions = this.getSelectedAssetsCode(newData.data);
      this.selectedAssetTypeOptionsTranslated =
        this.selectedAssetTypeOptions.map((el) => this.$tc(el));
      this.selectedAssetTypesSwitch = newData.data;
    }
  }

  /**
   * Determine 'All' btn state by comparing the total number of assets list against which one are selected
   */
  determineAllSelectionState(): void {
    this.allDropdownAssetTypesSelected =
      this.assetTypeList.data?.length ===
      this.assetTypeList.data?.filter((item) => item.selected)?.length;
  }

  /**
   * Filter selected assets then return the name of those objects into the main input element
   * @param assetsList
   */
  getSelectedAssetsCode(assetsList: AssetTypeSelectItem[]): string[] {
    const selectedAssets: AssetTypeSelectItem[] = assetsList?.filter(
      (asset: AssetTypeSelectItem) => asset.selected
    );
    return selectedAssets?.map((obj: AssetTypeSelectItem) => obj.assetType);
  }

  getValue(assetsCode: string): string {
    return this.$tc(assetsCode);
  }

  /**
   * Handle asset dropdown changes
   */
  async assetDropdownChanged(): Promise<void> {
    this.selectedAssetTypesSwitch.forEach(
      (item: AssetTypeSelectItem) =>
        (item.selected = this.selectedAssetTypeOptionsTranslated.includes(
          this.$tc(item.assetType)
        ))
    );
    this.determineAllSelectionState();
    this.$emit(
      'filterData',
      this.selectedAssetTypesSwitch.filter(
        (asset: AssetTypeSelectItem) => asset.selected
      )
    );
  }

  /**
   * Toggle all dropdown assets selection
   */
  async toggleAllDropdownAssetsSelected(): Promise<void> {
    this.allDropdownAssetTypesSelected = !this.allDropdownAssetTypesSelected;
    this.selectedAssetTypesSwitch.forEach(
      (item: AssetTypeSelectItem) =>
        (item.selected = this.allDropdownAssetTypesSelected)
    );
    this.selectedAssetTypeOptions = this.selectedAssetTypesSwitch
      .filter((item: AssetTypeSelectItem) => item.selected)
      .map((item: AssetTypeSelectItem) => item.assetType);
    this.selectedAssetTypeOptionsTranslated = this.selectedAssetTypeOptions.map(
      (el) => this.$tc(el)
    );
    this.$emit(
      'filterData',
      this.selectedAssetTypesSwitch.filter(
        (asset: AssetTypeSelectItem) => asset.selected
      )
    );
  }
}
</script>

<template>
  <el-select
    class="filter-select"
    :class="
      selectedAssetTypeOptionsTranslated.length > 1
        ? 'filter-select bigger-list'
        : 'filter-select'
    "
    v-model="selectedAssetTypeOptionsTranslated"
    ref="assetFilterRef"
    multiple
    collapse-tags
    default-first-option
    :placeholder="$t('maintenance.maintenancePlanner.selectAssetType')"
    @change="assetDropdownChanged"
    :error="error"
    :disabled="disabled"
  >
    <div
      class="filter-select-assets-select-all"
      @click="toggleAllDropdownAssetsSelected"
    >
      <span :style="true ? 'opacity: 1' : 'opacity: 0.7'">{{
        $t('common.all')
      }}</span>
      <el-switch
        :value="allDropdownAssetTypesSelected"
        class="el-switch-component"
        active-color="var(--Main)"
        inactive-color="#D5D5D5"
      >
      </el-switch>
    </div>
    <el-divider class="filter-select-asset-divider" />
    <el-option
      class="filter-select-option filter-select-assets-option"
      v-for="asset in selectedAssetTypesSwitch"
      :key="getValue(asset.assetType)"
      :value="getValue(asset.assetType)"
    >
      <span :style="asset.selected ? 'opacity: 1' : 'opacity: 0.7'">{{
        $t(asset.assetType)
      }}</span>
      <el-switch
        :value="asset.selected"
        class="el-switch-component"
        active-color="var(--Main)"
        inactive-color="#D5D5D5"
      >
      </el-switch>
    </el-option>
  </el-select>
</template>

<style lang="scss" scoped>
.filter-select {
  width: 100%;
}

.filter-select-option {
  &.hover {
    background-color: unset !important;
  }

  &:hover {
    background-color: var(--DropdownHover) !important;
  }
}

.search-container {
  display: flex;
  align-items: center;

  .filter-select :deep(.el-input__inner) {
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    border-right: transparent !important;
  }

  // This is copied from the timeselector?
  .search-select-input :deep(.el-input__inner) {
    width: 300px;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
  }

  .search-select-dropdown :deep(.el-input__inner) {
    border-right: inherit;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
  }

  .search-select-dropdown {
    margin-right: 0;
  }
}

.filter-select-assets-search-input {
  width: 210px;
  margin: 4px 20px;

  :deep(.el-input__inner) {
    width: 100%;
  }
}

.filter-select-assets-select-all,
.filter-select-assets-option {
  padding: 0 1rem !important; // has to be important to override some other css
  height: 34px; // The dropdown widget sets the height fixed instead using the padding
  display: flex;
  align-items: center;
  color: #606266;
  font-size: 16px;

  &:hover {
    cursor: pointer;
    background-color: var(--DropdownHover) !important;
  }

  span {
    color: #373e41;
    font-weight: 400;
  }
}

.filter-select-asset-divider {
  margin: 4px 0;
}

.el-icon-search {
  line-height: 40px;
  color: rgba(0, 0, 0, 0.6);
  font-size: 18px;
  cursor: pointer;
  margin-right: 8px;
  margin-top: -4px;
}

.el-switch-component {
  margin: auto 0 auto auto;
}

.column-button {
  margin-left: auto;
  cursor: pointer;
  font-size: 16px;
  font-family: $font-Roboto-Medium;
  line-height: 19px;
  color: #373e41;

  :hover {
    color: var(--Main);
  }

  > img {
    width: 14px;
    height: 14px;
    margin-right: 8px;
  }
}

.column-checkbox {
  display: block;
  color: #373e41 !important;
}

.el-select-dropdown__item.selected::after {
  display: none;
}

.bigger-list :deep(.el-tag.el-tag--info) {
  max-width: 120px;
}
</style>
