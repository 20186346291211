<script lang="ts" setup>
import { useActiveContext, useContextTypeFromRoute } from '@/auth/context';
import { ContextType } from '@/auth/contextType';
import { useSelectedCustomerInfo } from '@/auth/selectedCustomer';
import { useLogout } from '@/auth/token';
import { useLoggedInUser } from '@/auth/user';
import Sidebar from '@/components/layout/Sidebar.vue';
import Topbar from '@/components/layout/Topbar.vue';
import { useMediaQuery } from '@/composables/responsive';
import { useRoute } from '@/composables/router';
import { useVersionChanged } from '@/composables/version';
import { computed, ref, unref } from 'vue';

const route = useRoute();
const desktop = useMediaQuery('(min-width: 769px)');

const collapsed = ref(!desktop.value);
const context = useActiveContext();
const loggedInUser = useLoggedInUser();
const contextType = useContextTypeFromRoute();
const logout = useLogout();

const { isInitializingSelectedCustomer, selectedCustomer } =
  useSelectedCustomerInfo();

const activeContext = useActiveContext();

const needsImpersonation = computed(() => {
  return (
    loggedInUser.value?.mustImpersonate &&
    contextType.value === ContextType.SelectedCustomer
  );
});

// If we need impersonation (i.e. designated user, and we're on a page that has impersonated content), we
// need to wait until we have a definitive answer from the backend about what customers are actually
// available for selection, otherwise we start rendering widgets with an undefined (or unavailable)
// selected customer, which will cause 400 or other errors.
// TODO Refactor/simplify the logic of deciding when loading of the customer metadata is done, probably wrap all of
// that in AsyncValue instead.
const waitForSelectedCustomer = computed(() => {
  if (!needsImpersonation.value) {
    // If this page doesn't need impersonation: done
    return false;
  }
  if (isInitializingSelectedCustomer.value) {
    // Wait until the list of impersonatable customers is loaded
    return true;
  }
  if (
    selectedCustomer.value?.customerId !== undefined &&
    activeContext.value.primaryOrgTimeZone === undefined
  ) {
    // Additionally, we need to wait until metadata about the selectedCustomer is available (i.e. its primary
    // organization timezone for now).
    return true;
  }
  return false;
});

// TODO Add error handling for when loading info of selectedCustomer fails

// Not all of our components / widgets support updating their contents when the
// context changes. So for now, we'll just force the whole component tree to
// be reinstantiated whenever the context changes.
const key = computed(
  () => `${unref(route).path}${JSON.stringify(context.value)}`
);

function collapse() {
  if (!desktop.value && !collapsed.value) {
    collapsed.value = true;
  }
}

const isNewVersionAvailable = useVersionChanged();

function reload() {
  window.location.reload();
}
</script>

<template>
  <el-container>
    <el-header :height="isNewVersionAvailable ? '100px' : '48px'">
      <div class="header">
        <div v-if="isNewVersionAvailable" class="new-version-available-bar">
          <span>{{ $t('navbar.updateAvailable') }}</span>
          <el-button type="info" @click="reload">{{
            $t('common.update')
          }}</el-button>
        </div>
        <Topbar
          class="top-bar"
          :collapsed="collapsed"
          :is-new-version-available="isNewVersionAvailable"
          @toggle-collapsed="collapsed = !collapsed"
          @logout="logout"
          @reload="reload"
        />
      </div>
    </el-header>
    <el-container class="main-container">
      <div
        :class="['backdrop', collapsed && 'collapsed']"
        @click="collapsed = true"
      />
      <Sidebar
        :collapsed="collapsed"
        :desktop="desktop"
        :can-impersonate="loggedInUser?.canImpersonate === true"
        @collapse="collapse"
        @logout="logout"
      />
      <el-main>
        <transition name="fade-transform" mode="out-in">
          <router-view :key="key" v-if="!waitForSelectedCustomer" />
        </transition>
      </el-main>
    </el-container>
  </el-container>
</template>

<style lang="scss" scoped>
.header {
  height: 100%;
}

.new-version-available-bar {
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  background-color: var(--Main);
  color: #303133;
}

.top-bar {
  height: 48px;
}

.el-header {
  padding: 0;
  color: white;
  background-color: #373e41;
}

.el-main {
  padding-top: 0.5rem;
  min-height: calc(100vh - 60px);
}

.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: black;
  opacity: 0.5;
  transition: opacity 0.25s ease;
  z-index: 2000;

  &.collapsed {
    opacity: 0;
    pointer-events: none;
  }

  @media (min-width: 769px) {
    display: none;
  }
}
</style>
