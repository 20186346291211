import { isEqual } from 'lodash';
import { ref, Ref, watch, WatchOptions } from 'vue';

export type MaybeRef<T> = Ref<T> | T;

export type MaybeRefDeep<T> = MaybeRef<
  T extends Function
    ? T
    : T extends object
    ? {
        [Property in keyof T]: MaybeRefDeep<T[Property]>;
      }
    : T
>;

export type RefOrGetter<T> = Ref<T> | (() => T);

/**
 * Ref's in Vue are already mutable, but only be directly updating their
 * .value. This breaks one-way data-flow and prevents further processing
 * of the update.
 *
 * This type makes mutation of the ref explicit.
 */

export interface MutableRef<T> {
  ref: Ref<Readonly<T>>;
  update(newValue: T): void;
}

/**
 * Convert a ref or a getter into a new ref that is only updated
 * whenever the source value really changes.
 *
 * The watched value is only watched for shallow changes by default
 * (i.e. changes deep inside an object will not be detected).
 * However, when a change is detected (i.e. new object), it *is*
 * checked for equality by the comparator function, which does a
 * deep comparison by default.
 *
 * I.e. for the recommended case of using immutable objects, and
 * only creating new objects whenever anything changes, the defaults
 * will work as expected.
 *
 * @example
 * ```ts
 * // Making sure depending watches etc are only triggered on actual changes:
 * const oftenChanging = getSomeOftenChangingRef();
 * const lessChanging = distinct(oftenChanging);
 * watchEffect(() => console.log(lessChanging.value)); // triggers only when the ref changes (deep-equal)
 *
 * // Selecting only a subset of another ref, and make sure the ref only changes
 * // when that subpart changes (not anything else in the source ref).
 * const bigRef = getSomeBigRef();
 * const subsetRef = distinct(() => bigRef.value.someField);
 * watchEffect(() => console.log(subsetRef.value)); // triggers only when someField changes (deep-equal)
 *
 * // DON'T DO THIS
 * const someObject = { foo: 0 };
 * const someRef = ref(someObject)
 * const distinctRef = distinct(someRef);
 * someObject.foo = 1; // won't trigger an update of someRef, nor an update to distinct
 *
 * // DO THIS instead
 * const someRef = ref({ foo: 0 })
 * const distinctRef = distinct(someRef);
 * someRef.value = { foo: 1 }; // New object assigned, triggers distinct -> update to distinctRef
 * someRef.value = { foo: 1 }; // New object assigned, but (deep) equal to the last -> no update to distinctRef
 *
 * // (Note: you'll need await nextTick in between the above, or Vue will collapse all updates into one)
 * ```
 */
export function distinct<T>(
  refOrGetter: RefOrGetter<T>,
  comparator?: (newValue: T, oldValue: T) => boolean,
  watchOptions?: WatchOptions
): Ref<T> {
  const definedComparator = comparator ?? isEqual;

  const getter =
    typeof refOrGetter === 'function'
      ? refOrGetter
      : () => (refOrGetter as Ref<T>).value;

  const currentValue = ref() as Ref<T>;
  let initialAssignment = true;
  watch(
    getter,
    (newValue) => {
      if (
        initialAssignment ||
        !definedComparator(newValue, currentValue.value)
      ) {
        initialAssignment = false;
        currentValue.value = newValue;
      }
    },
    {
      ...watchOptions,
      immediate: true,
    }
  );
  return currentValue;
}
