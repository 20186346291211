/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon';
icon.register({
  userRoleMgmt: {
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
    data: '<path pid="0" data-name="路径 1441" d="M13.998 14.586v-.836q0-.549-.736-1a5.427 5.427 0 00-1.63-.607 7.2 7.2 0 00-1.632-.21 7.188 7.188 0 00-1.631.212 5.4 5.4 0 00-1.631.607q-.735.453-.735 1v.835h8zm-4-7.058a2.123 2.123 0 00-.992.24 1.918 1.918 0 00-.736.65 1.545 1.545 0 000 1.754 1.916 1.916 0 00.736.649 2.164 2.164 0 001.987 0 1.927 1.927 0 00.735-.649 1.545 1.545 0 000-1.754 1.922 1.922 0 00-.735-.65 2.134 2.134 0 00-1-.24zm0-2.338a.621.621 0 00-.656.579.563.563 0 00.189.423.677.677 0 00.927 0 .57.57 0 00.189-.423.529.529 0 00-.189-.408.672.672 0 00-.464-.167zm4.654 0a1.375 1.375 0 01.946.353 1.076 1.076 0 01.4.835v8.406a1.077 1.077 0 01-.4.835 1.4 1.4 0 01-.946.382H5.347a1.4 1.4 0 01-.946-.381 1.077 1.077 0 01-.4-.835V6.37a1.075 1.075 0 01.4-.835 1.381 1.381 0 01.946-.353h2.782a1.809 1.809 0 01.711-.849 2.187 2.187 0 012.319 0 1.82 1.82 0 01.712.852z" _fill="#373e41"/><g data-name="组件 351 – 2"><g data-name="组件 349 – 6"><g data-name="组件 348 – 34"><path pid="1" data-name="减去 18" d="M14 20H6a6.007 6.007 0 01-6-6V6a6.007 6.007 0 016-6h8a6.007 6.007 0 016 6v8a6.006 6.006 0 01-6 6zM6 1a5.006 5.006 0 00-5 5v8a5.006 5.006 0 005 5h8a5.005 5.005 0 005-5V6a5.006 5.006 0 00-5-5z" _fill="#373e41"/></g></g></g>',
  },
});
