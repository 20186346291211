<script lang="ts">
import DefaultDialog from '@/components/dialog/DefaultDialog.vue';
import AssetHealthFactorList from '@/components/health/AssetHealthFactorList.vue';
import AssetHealthList from '@/components/health/AssetHealthList.vue';
import { AssetType } from '@/utils/assetTypes';
import { WarningType } from '@/utils/workData/lookuptable';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

@Component({
  name: 'health-modal',
  components: {
    AssetHealthFactorList,
    AssetHealthList,
    DefaultDialog,
  },
})
export default class extends Vue {
  @Prop({ default: '1200px' }) popupWidth!: string;
  @Prop({ required: true }) modalVisable!: boolean;
  @Prop({ default: false }) hideFootBtns!: boolean;
  @Prop({ required: true }) title!: string;
  @Prop({ required: true }) assetType!: AssetType;
  @Prop({ required: true }) warningType!: WarningType;

  normalWarning = WarningType.Normal;
  minorWarning = WarningType.Warning;
  alarmWarning = WarningType.Alarm;

  selectedAsset: string | null = null;
  selectedAssetName: string = '';
  healthStatus = this.warningType ?? WarningType.Normal;

  @Watch('warningType')
  onWarningChanged(val: WarningType, oldVal: WarningType) {
    this.healthStatus = val;
  }

  get visible() {
    return this.modalVisable;
  }

  set visible(val) {
    this.$emit('updateVisible', val);
  }

  changeHealthStatus(status: WarningType) {
    this.healthStatus = status;
  }

  created() {}

  mounted() {}

  handleModalCancel() {
    this.visible = false;
  }

  assetSelected(assetId: string, assetName: string) {
    this.selectedAssetName = assetName;
    this.selectedAsset = assetId;
  }

  navigateToAssetsPage() {
    let assetTypeUrl = null;
    switch (this.assetType) {
      case AssetType.MobileCompactor:
        assetTypeUrl = 'mobile-compactors';
        break;

      case AssetType.StaticCompactor:
        assetTypeUrl = 'static-compactors';
        break;

      case AssetType.AlbaStaticCompactor:
        assetTypeUrl = 'alba-static-compactors';
        break;

      case AssetType.TippingVehicle:
        assetTypeUrl = 'tipping-vehicles';
        break;

      case AssetType.RefuseCollectionVehicle:
        assetTypeUrl = 'refuse-collection-vehicles';
        break;
    }

    if (assetTypeUrl === null) return;
    this.$router.push(`/assets/${assetTypeUrl}/${this.selectedAsset}`);
  }
}
</script>

<template>
  <DefaultDialog
    :visible.sync="visible"
    :title="title"
    :width="popupWidth"
    @handle-cancel="handleModalCancel"
  >
    <div class="d-flex dialog-wrapper" style="flex-direction: row">
      <div class="left-component">
        <el-radio-group
          v-model="healthStatus"
          class="radio_area"
          @change="changeHealthStatus"
        >
          <el-radio-button :label="normalWarning" key="1">{{
            $t('healthModal.buttons.good')
          }}</el-radio-button>
          <el-radio-button :label="minorWarning" key="2">{{
            $t('healthModal.buttons.minorDefects')
          }}</el-radio-button>
          <el-radio-button :label="alarmWarning" key="3">{{
            $t('healthModal.buttons.needFix')
          }}</el-radio-button>
        </el-radio-group>

        <AssetHealthList
          :selectedFilter="healthStatus"
          :assetType="assetType"
          @asset-selected="assetSelected"
        />
      </div>

      <div class="right-component">
        <div class="right-component-title" v-if="selectedAsset">
          <div class="title">
            {{ selectedAssetName + ' - ' + $t('assets.healthFactorList') }}
          </div>
          <div class="button-container" @click="navigateToAssetsPage()">
            <div>{{ $t('healthManagementModule.assetsPageLink') }}</div>
            <img
              style="margin-left: 10px"
              src="@/icons/svg/circleRightArrow.svg"
            />
          </div>
        </div>
        <AssetHealthFactorList
          :currentAsset="selectedAsset"
          v-if="selectedAsset"
        />
      </div>
    </div>
  </DefaultDialog>
</template>

<style lang="scss" scoped>
.dialog-wrapper {
  min-height: 475px;
}

.left-component {
  align-items: flex-start;
  min-height: 100%;
  width: 50%;
  display: flex;
  flex-direction: column;
}

.right-component {
  min-height: 100%;
  width: 50%;
  background: rgba(222, 222, 222, 0.4);
}

.radio_area {
  margin-top: 5%;
  margin-left: 5%;
  max-height: 38px;
}

.radio_area :deep(.el-radio-button--medium .el-radio-button__inner) {
  font-size: 16px;
  background: #f3f3f3;
  color: #373e41;
}

.radio_area
  :deep(.el-radio-button__orig-radio:checked + .el-radio-button__inner) {
  background: #373e41;
  border-color: #373e41;
  color: #ffffff;
  box-shadow: -1px 0 0 0 #373e41;
}

.right-component-title {
  font-size: 17px;
  margin: 25px 25px 0 25px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-weight: bold;
}

.button-container {
  font-size: 12px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.title {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
}
</style>
