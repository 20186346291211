<script lang="ts" setup>
import { CommonResult } from '@/api/commonResult';
import {
  getPerformedAppointmentsById,
  MaintenanceItemPart,
  PerformedAppointmentById,
  PerformedMaintenanceAppointments,
} from '@/api/maintenance';
import {
  ContainedPart,
  getProductModelById,
  ProductModel,
} from '@/api/products';
import CusFormItem from '@/components/form/CusFormItem.vue';
import TransferList from '@/components/form/TransferList.vue';
import { AsyncValue, useAsync } from '@/composables/async';
import { disabledPastDate } from '@/utils/date';
import { MAINTENANCE_ITEM_OPERATION_KIND } from '@/utils/workData/lookuptable';
import { MaintenanceModalTriggerSource } from '@/views/maintenance/planner';
import { computed, ref, unref, watchEffect } from 'vue';

interface PartsList {
  replace: ItemPart[];
  check: ItemPart[];
}

interface ItemPart {
  id: string;
  partName: string;
}

const props = defineProps<{
  isModalVisible: boolean;
  title: string;
  appointment?: PerformedMaintenanceAppointments;
  isInViewMode?: boolean;
}>();

const emit = defineEmits<{
  (e: 'close'): void;
}>();

/**
 * Used to retrieve product model by id > contained part > part name for the parts retrieved from applied-item endpoint
 */
const maintenanceItemProductModelId = ref<string>();

const partsList = ref<PartsList | undefined>({
  replace: [],
  check: [],
});

/** Button close event */
function closeDialog() {
  emit('close');
}

/** ------------------- Maintenance item by id data ------------------- */

const getMaintenanceItemByIdFromApi = useAsync(
  computed(
    async (): Promise<CommonResult<PerformedAppointmentById> | undefined> => {
      if (props.appointment?.maintenanceItemId) {
        const response = await getPerformedAppointmentsById(
          props.appointment?.id
        );
        return response;
      }
    }
  )
);

/**
 * Process maintenance item by id data
 */
const maintenanceItemByIdData = computed(
  (): AsyncValue<CommonResult<PerformedAppointmentById>> => {
    const maintenanceItemById = unref(getMaintenanceItemByIdFromApi);
    if (maintenanceItemById.loading || maintenanceItemById.error) {
      return {
        loading: maintenanceItemById.loading,
        error: maintenanceItemById.error,
        data: undefined,
      };
    }
    if (maintenanceItemById.data === undefined) {
      return {
        loading: false,
        error: new Error('Invalid api response, data must be present.'),
        data: undefined,
      };
    }

    return {
      loading: false,
      error: undefined,
      data: maintenanceItemById.data,
    };
  }
);

/**
 * Look over selectedMaintenanceItem
 * If there is a value, fetch maintenance item by id then populate transferDataList for displaying the parts as replace/check
 */
watchEffect(() => {
  const itemData = unref(maintenanceItemByIdData).data;
  if (!itemData) {
    partsList.value = undefined;
    return;
  }

  /** Provide general product model id */
  maintenanceItemProductModelId.value = itemData.data.productModelId!;
  /** Get maintenance item product model by id where part name can be extracted */
  const productModelById = unref(productModelByIdData)?.data;

  /** Populate transfer list (replace/check) with part id and part name */
  partsList.value = itemData.data.maintainedParts?.reduce(
    (accumulator: PartsList, current: MaintenanceItemPart) => {
      const finalData: PartsList = {
        replace: accumulator.replace ? [...accumulator.replace] : [],
        check: accumulator.check ? [...accumulator.check] : [],
      };

      /** Get part name from product model by id > contained parts field */
      const maintenancePartName = productModelById?.data?.containedParts?.find(
        (item: ContainedPart) => item.id === current.containedPartId
      )?.name;

      if (
        current.maintenanceOperationKind ===
        MAINTENANCE_ITEM_OPERATION_KIND.Replace
      ) {
        finalData?.replace?.push({
          id: current.containedPartId,
          partName: maintenancePartName!,
        });
      } else {
        finalData?.check?.push({
          id: current.containedPartId,
          partName: maintenancePartName!,
        });
      }
      return finalData;
    },
    { replace: [], check: [] } as PartsList
  );
});

/**
 * Get product model by id
 */
const getProductModelByIdFromApi = useAsync(
  computed(async (): Promise<CommonResult<ProductModel> | undefined> => {
    if (unref(maintenanceItemProductModelId)) {
      const response = await getProductModelById(
        unref(maintenanceItemProductModelId)!
      );
      return response;
    }
  })
);

/**
 * Process product model by id data
 * From the response will be retrieved parts name
 */
const productModelByIdData = computed(
  (): AsyncValue<CommonResult<ProductModel>> => {
    const productModelById = unref(getProductModelByIdFromApi);
    if (productModelById.loading || productModelById.error) {
      return {
        loading: productModelById.loading,
        error: productModelById.error,
        data: undefined,
      };
    }
    if (productModelById.data === undefined) {
      return {
        loading: false,
        error: new Error('Invalid api response, data must be present.'),
        data: undefined,
      };
    }

    return {
      loading: false,
      error: undefined,
      data: productModelById.data,
    };
  }
);

/**
 * Give to the date picker date options for disabling the dates
 */
const datePickerOptions: { disabledDate: (date: Date) => boolean } = {
  disabledDate: disabledPastDate,
};
</script>

<template>
  <el-dialog
    :visible="isModalVisible"
    class="maintenance-appointment-dialog"
    :width="'700px'"
    :top="'10vh'"
    :title="title"
    @close="closeDialog"
  >
    <div class="content-container">
      <CusFormItem
        class="asset-id-container"
        :required="false"
        :title="'maintenance.assetId'"
      >
        <span class="field-value">{{ appointment?.companyAssetId }}</span>
      </CusFormItem>

      <CusFormItem
        class="maintenance-item-container"
        :required="false"
        :title="'maintenance.item'"
      >
        <span class="field-value">{{ appointment?.maintenanceItemName }}</span>
      </CusFormItem>
      <div class="transfer-list-container">
        <TransferList
          v-loading="
            maintenanceItemByIdData.loading || productModelByIdData.loading
          "
          :isConductMaintenanceActiv="false"
          :triggerSource="MaintenanceModalTriggerSource.Show"
          :transferListData="partsList"
          isViewMode="true"
          isHistory="true"
        />
      </div>
      <CusFormItem :required="false" :title="'maintenance.conductanceDate'">
        <span class="field-value">{{ appointment?.appointmentTime }}</span>
      </CusFormItem>

      <CusFormItem
        class="note-container"
        :required="false"
        :title="'maintenance.note'"
      >
        <span class="field-value">{{ appointment?.note }}</span>
      </CusFormItem>
    </div>
  </el-dialog>
</template>

<style scoped>
.maintenance-appointment-dialog {
  border-radius: 15px;
}

.maintenance-appointment-dialog :deep(.el-dialog__title) {
  font-size: 20px;
  font-family: var(--fontRobotoMedium);
  font-weight: 500;
  line-height: 19px;
  color: #373e41;
  margin: auto;
  text-align: center;
}

.maintenance-appointment-dialog :deep(.el-dialog__header) {
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
}

.maintenance-appointment-dialog :deep(.el-dialog) {
  border-radius: 6px;
}

.maintenance-appointment-dialog :deep(.el-dialog__headerbtn) {
  top: 10px;
  font-size: 30px;
  color: #373d41;
  position: static;
  margin-top: -5px;
}
</style>

<style lang="scss" scoped>
.maintenance-appointment-dialog :deep(.el-dialog__header) {
  padding: 6px;
  padding-bottom: 6px;
  background-color: var(--Main) !important;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 20px;
  padding-right: 16px;
  height: auto;
}

.maintenance-appointment-dialog
  :deep(.el-dialog__headerbtn:hover .el-dialog__close) {
  color: #5f6567;
}

.transfer-list-container {
  width: 32.5rem;
  margin: 10px auto 0px auto;
  min-height: 80px;
}

.content-container {
  min-height: 300px;
  margin-bottom: 20px;
}

.content-container :deep(.item-title) {
  padding-bottom: 0px;
  text-align: left;
  margin-bottom: auto;
  margin-top: 2px;
}

.content-container :deep(.item-content) {
  margin-top: 0;
  margin-bottom: auto;
  margin-right: auto;
}

.note-container {
  word-break: break-word;
}

.content-container :deep(.item) {
  margin-left: auto;
  margin-right: auto;
  margin-top: 0;
  word-break: break-word;
}

.item {
  width: 32.5rem;
  padding: 0px 10px 0px 10px;
}

.field-value {
  color: #373e41;
  font-size: 16px;
  line-height: 20px;
  font-family: $font-Roboto-Medium;
}
</style>
