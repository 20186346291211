<script setup lang="ts">
import { useUnitConversion } from '@/composables/conversion';
import { formatNumber } from '@/utils/number';
import { KPI_UNIT } from '@/utils/units/unitDefinitions';
import { AggregationType } from '@/widgets/utils/Constants';

export interface KpiTargetTableData {
  id: string;
  assetId?: string;
  date?: string;
  payload: number;
  trip: number;
  payloadPerTrip: number;
}

defineProps<{
  tableData: KpiTargetTableData[];
  aggregationType: AggregationType;
}>();

const { currentUserPreferredUnit } = useUnitConversion();
</script>

<template>
  <el-table :data="tableData" height="100%">
    <el-table-column
      :prop="
        aggregationType === AggregationType.AggregateByAsset
          ? 'assetId'
          : 'date'
      "
      :label="
        aggregationType === AggregationType.AggregateByAsset
          ? $t('kpiTarget.table.assetId')
          : $t('UNIT_DAY')
      "
      sortable
    />
    <el-table-column
      prop="trip"
      :label="$t('kpiTarget.table.tripCount')"
      sortable
    />
    <el-table-column
      prop="payload"
      :label="`${$t('kpiTarget.table.payload')} (${$t('UNIT_METRIC_TONNE')})`"
      sortable
    >
      <template v-slot="props">
        {{ formatNumber(props.row.payload) }}
      </template>
    </el-table-column>
    <el-table-column
      prop="payloadPerTrip"
      :label="
        $t('kpiTarget.table.avgPayloadPerTrip', {
          unit: $t(currentUserPreferredUnit(KPI_UNIT.MetricTonne)),
        })
      "
      sortable
    >
      <template v-slot="props">
        {{ formatNumber(props.row.payloadPerTrip) }}
      </template>
    </el-table-column>
  </el-table>
</template>

<style scoped>
.el-table .selected {
  background: #ffeb99;
}

.el-table tbody tr:hover > td {
  /* background-color: rgb(var(--Main), 0.6) !important; */
  background-color: var(--Main) !important;
  /* opacity: 0.5; */
  /* cursor: pointer; */
}

.el-table {
  font-size: 14px !important;
  font-family: var(--fontRobotoRegular);
  line-height: 16px;
  color: #373e41 !important;
  opacity: 1;
}

@media (min-width: 1422px) {
  :deep(.el-table__cell) {
    border-top: 0px;
  }
}
</style>
