import { ALL_CLAIMS_CODES } from '@/utils/workData/lookuptable';
import { RouteConfig } from 'vue-router';

const administration: RouteConfig = {
  path: '/administration',
  component: () =>
    import(/* webpackChunkName: "layout" */ '@/components/layout/Layout.vue'),
  redirect: '/administration/index',
  meta: {
    code: ALL_CLAIMS_CODES.AUTHRSC_MOD_ADMINISTRATION,
  },
  children: [
    {
      path: 'index',
      component: () =>
        import(
          /* webpackChunkName: "administration" */ '@/views/administration/Administration.vue'
        ),
      name: 'Administration',
      meta: {
        title: 'route.administration.index',
        icon: 'administration',
        code: ALL_CLAIMS_CODES.AUTHRSC_MOD_ADMINISTRATION,
      },
    },
    {
      path: 'add-new-geofence',
      component: () =>
        import(
          /* webpackChunkName: "administration" */ '@/views/administration/geofence/CreateNewGeofence.vue'
        ),
      name: 'createNewGeofence',
      meta: {
        claim: ALL_CLAIMS_CODES.AUTHRSC_ACTION_GEOFENCE_CREATE,
        code: ALL_CLAIMS_CODES.AUTHRSC_PAGE_GEOFENCES,
        hidden: true,
      },
    },
    {
      path: 'edit-geofence/:geofenceId',
      name: 'editGeofence',
      component: () =>
        import(
          /* webpackChunkName: "administration" */ '@/views/administration/geofence/EditGeofence.vue'
        ),
      props: true,
      meta: {
        title: 'route.administration.index',
        claim: ALL_CLAIMS_CODES.AUTHRSC_ACTION_GEOFENCE_UPDATE,
        code: ALL_CLAIMS_CODES.AUTHRSC_PAGE_GEOFENCES,
        hidden: true,
      },
    },
    {
      path: 'add-new-user',
      component: () =>
        import(
          /* webpackChunkName: "administration" */ '@/views/administration/userMgmt/AddNewUser.vue'
        ),
      name: 'userAdd',
      meta: {
        title: 'route.administration.addNewUser',
        claim: ALL_CLAIMS_CODES.AUTHRSC_ACTION_USER_CREATE,
        code: ALL_CLAIMS_CODES.AUTHRSC_PAGE_USERMNG,
        hidden: true,
      },
    },
    {
      path: 'edit-user/:userId',
      component: () =>
        import(
          /* webpackChunkName: "administration" */ '@/views/administration/userMgmt/EditUser.vue'
        ),
      name: 'userEdit',
      props: true,
      meta: {
        claim: ALL_CLAIMS_CODES.AUTHRSC_ACTION_USER_UPDATE,
        code: ALL_CLAIMS_CODES.AUTHRSC_PAGE_USERMNG,
        hidden: true,
      },
    },
    {
      path: 'view-user-info/:userId',
      component: () =>
        import(
          /* webpackChunkName: "administration" */ '@/views/administration/userMgmt/ViewUserInfo.vue'
        ),
      name: 'userDetails',
      props: true,
      meta: {
        title: 'route.administration.index',
        code: ALL_CLAIMS_CODES.AUTHRSC_PAGE_USERMNG,
        hidden: true,
      },
    },
    {
      path: 'add-new-org',
      component: () =>
        import(
          /* webpackChunkName: "administration" */ '@/views/administration/orgMgmt/AddNewOrganization.vue'
        ),
      name: 'addNewOrganization',
      meta: {
        title: 'route.administration.addNewOrganization',
        claim: ALL_CLAIMS_CODES.AUTHRSC_ACTION_ORG_CREATE,
        code: ALL_CLAIMS_CODES.AUTHRSC_PAGE_ORGMNG,
        hidden: true,
      },
    },
    {
      path: 'edit-org/:orgId',
      component: () =>
        import(
          /* webpackChunkName: "administration" */ '@/views/administration/orgMgmt/AddNewOrganization.vue'
        ),
      name: 'organizationEdit',
      props: true,
      meta: {
        claim: ALL_CLAIMS_CODES.AUTHRSC_ACTION_ORG_UDPATE,
        code: ALL_CLAIMS_CODES.AUTHRSC_PAGE_ORGMNG,
        hidden: true,
      },
    },
    {
      path: 'add-new-helpdesk-org',
      component: () =>
        import(
          /* webpackChunkName: "administration" */ '@/views/administration/orgMgmt/AddNewHelpdeskOrganization.vue'
        ),
      name: 'helpdeskOrganization',
      meta: {
        title: 'route.administration.index',
        claim: ALL_CLAIMS_CODES.AUTHRSC_ACTION_ORG_CREATE,
        code: ALL_CLAIMS_CODES.AUTHRSC_PAGE_ORGMNG,
        hidden: true,
      },
    },
    {
      path: 'edit-helpdesk-org/:orgId',
      component: () =>
        import(
          /* webpackChunkName: "administration" */ '@/views/administration/orgMgmt/AddNewHelpdeskOrganization.vue'
        ),
      name: 'helpdeskOrganizationDetails',
      props: true,
      meta: {
        claim: ALL_CLAIMS_CODES.AUTHRSC_ACTION_ORG_UDPATE,
        code: ALL_CLAIMS_CODES.AUTHRSC_PAGE_ORGMNG,
        hidden: true,
      },
    },
    {
      path: 'view-org-info/:orgId',
      component: () =>
        import(
          /* webpackChunkName: "administration" */ '@/views/administration/orgMgmt/ViewOrganizationInfo.vue'
        ),
      name: 'organizationDetails',
      props: true,
      meta: {
        title: 'route.administration.index',
        claim: ALL_CLAIMS_CODES.AUTHRSC_PAGE_ORGMNG,
        hidden: true,
      },
    },
    {
      path: 'alarm-settings/edit',
      component: () =>
        import(
          /* webpackChunkName: "alarm" */ '@/views/alarm/components/AlarmConfigurationEditPage.vue'
        ),
      name: 'alarmConfigurationEditPageCustomer',
      meta: {
        title: 'route.alarmConfiguration',
        icon: 'alarmConfiguration',
        claim: ALL_CLAIMS_CODES.AUTHRSC_ACTION_CUST_ALARM_CONFIGURE,
        code: ALL_CLAIMS_CODES.AUTHRSC_PAGE_CUST_ALARM_CONFIGURATION,
        hidden: true,
      },
    },
  ],
};

export default administration;
