<script lang="ts" setup>
import { UUID } from '@/api/common';
import ElSelectTree from 'el-select-tree';
import { computed } from 'vue';
import { ALL_OPTION, OrganizationDropdownItem } from '../layout/select/models';

const props = defineProps<{
  selected: UUID | undefined;
  dropdownItems: OrganizationDropdownItem[];
  filterable?: boolean;
  showAll?: boolean;
}>();
const emit = defineEmits<{
  (event: 'change', id: string | undefined): void;
}>();

const dropdownOptions = computed((): OrganizationDropdownItem[] => {
  const options = [...props.dropdownItems];

  if (props.showAll === true) {
    return [{ label: 'All', value: ALL_OPTION, children: [] }, ...options];
  }

  return options;
});

function onSelectedChange(value: UUID | ALL_OPTION | undefined) {
  emit('change', value === ALL_OPTION ? undefined : value);
}

function onSearch(searchTerm: string, node: OrganizationDropdownItem): boolean {
  return node.label.toLowerCase().includes(searchTerm.toLowerCase());
}
</script>

<template>
  <ElSelectTree
    :value="props.selected ?? (props.showAll ? ALL_OPTION : undefined)"
    :filterable="props.filterable"
    :filter-node-method="onSearch"
    :data="dropdownOptions"
    check-strictly
    default-expand-all
    @change="onSelectedChange"
  />
</template>

<style scoped lang="scss">
.el-select {
  margin: 0;

  & :deep(.el-input__inner) {
    color: #ffffff;
    background-color: #373e41;
    font-weight: 500;
    border: none;

    @media (min-width: 769px) {
      border: 1px solid #ffffff !important;
    }
  }

  & :deep(.el-input__icon) {
    color: white !important;
  }
}
</style>
