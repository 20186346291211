<script lang="ts">
import { ErrorType } from '@/api/types';
import { getUserById, updateUser } from '@/api/users';
import CommonBtn from '@/components/button/CommonBtn.vue';
import CommFormInput from '@/components/form/CommFormInput.vue';
import CusFormItem from '@/components/form/CusFormItem.vue';
import { UserModule } from '@/store/modules/user';
import { promptFailedBox, promptSuccessBox } from '@/utils/prompt';
import { ERROR_CODE_LIST } from '@/utils/workData/lookuptable';
import { Component, Vue, Watch } from 'vue-property-decorator';

interface UserForm {
  username: string;
  firstName: string;
  lastName: string;
  email: string;
  mobilePhone: string;
  note: string;
}

@Component({
  name: 'UserProfile',
  components: {
    'cus-form-item': CusFormItem,
    'comm-form-input': CommFormInput,
    'common-btn': CommonBtn,
  },
})
export default class extends Vue {
  userId = UserModule.id;
  errorCode = ERROR_CODE_LIST;
  errorInfos: ErrorType[] = [
    {
      code: '',
      field: '',
      message: '',
    },
  ];

  nameRegex = /^\S+/;
  userInfo: any = {};
  userSettingForm: UserForm = {
    username: '',
    firstName: '',
    lastName: '',
    email: '',
    mobilePhone: '',
    note: '',
  };
  userSettingsIsLoading: boolean = false;
  saveButtonIsDisabled: boolean = false;
  initialUserSettingsForm: UserForm = {
    username: '',
    firstName: '',
    lastName: '',
    email: '',
    mobilePhone: '',
    note: '',
  };

  /**
   * Check changes to input form compared with initial one to modify the state of the save button
   */
  @Watch('userSettingForm', { deep: true })
  handleUserInputChange() {
    this.saveButtonIsDisabled = !Object.keys(this.userSettingForm).some(
      (field) =>
        this.userSettingForm[field as keyof UserForm] !==
        this.initialUserSettingsForm[field as keyof UserForm]
    );
  }

  get inlineMsg() {
    return false;
  }

  get errNameInfo() {
    let errInfo: string = '';

    if (
      this.errorInfos.find((item) => item.field === this.errorCode.userName)
        ?.code === 'ApiErrorFieldUnique'
    ) {
      errInfo = `${this.$t('userModule.duplicateUser')}`;
    }
    return errInfo;
  }

  validateName1 = (rule: any, value: any, callback: any) => {
    if (value.length < 1 || value.length > 100) {
      callback(new Error(`${this.$t('userSetting.tipName1Form')}`));
    } else {
      callback();
    }
  };

  validateName0 = (rule: any, value: any, callback: any) => {
    if (value && (!this.nameRegex.test(value) || value.length > 100)) {
      callback(new Error(`${this.$t('userSetting.tipName0Form')}`));
    } else {
      callback();
    }
  };

  get rules() {
    const tmpRules = {
      username: [
        {
          required: true,
          message: this.$t('userSetting.tipInputName'),
          pattern: /^\S+/,
          trigger: 'change',
        },
        { validator: this.validateName1, trigger: 'change' },
      ],
      firstName: [{ validator: this.validateName0, trigger: 'change' }],
      lastName: [{ validator: this.validateName0, trigger: 'change' }],
      mobilePhone: [
        // { pattern: /^1[3-9]\d{9}$/, message: this.$t('customerModule.tipInputCorrectPhone') }
        {
          pattern: /^[, 0-9-#+]{1,20}$/,
          message: this.$t('customerModule.tipInputCorrectPhone'),
        },
      ],
    };

    return tmpRules;
  }

  handleInput() {
    this.errorInfos = [];
  }

  saveUserSetting() {
    (this.$refs.userSettingForm as any).validate((valid: any) => {
      if (valid) {
        updateUser(UserModule.id, this.userSettingForm).then((res) => {
          if (res.code === 200) {
            // TODO replace with query mutation
            UserModule.UpdateUserName(this.userSettingForm.username);
            promptSuccessBox(this.$t('common.save') as string);

            if (typeof res.data.errors == 'undefined') {
              this.errorInfos = [];
            }
          } else if (res.code === 400) {
            this.errorInfos = res.data.errors;
            promptFailedBox(this.$t('common.save') as string);
          }
        });
      } else {
      }
    });
  }

  /**
   * Fetch user settings by id
   * - save initial fetched state for check changed in input form to disabled/enable save button
   */
  async fetch() {
    try {
      this.userSettingsIsLoading = true;
      const res = await getUserById(UserModule.id);
      let userForm: UserForm = {
        username: res.data.username ?? '',
        firstName: res.data.firstName ?? '',
        lastName: res.data.lastName ?? '',
        email: res.data.email ?? '',
        mobilePhone: res.data.mobilePhone ?? '',
        note: res.data.note ?? '',
      };

      this.userSettingForm = userForm;
      Object.assign(this.initialUserSettingsForm, this.userSettingForm);
    } catch (error) {
      console.log(error);
    } finally {
      this.userSettingsIsLoading = false;
    }
  }

  created() {
    this.fetch();
  }
}
</script>

<template>
  <div>
    <el-form
      id="user_setting_profile_form"
      ref="userSettingForm"
      :model="userSettingForm"
      :rules="rules"
      :inline-message="inlineMsg"
      class="user-setting-form"
      v-loading="userSettingsIsLoading"
    >
      <cus-form-item :title="'userSetting.userName'" :errContent="errNameInfo">
        <el-form-item prop="username">
          <el-input
            id="user_setting_profile_input_username"
            v-model="userSettingForm.username"
            v-bind:placeholder="$t('userSetting.inputUserName')"
            @input="handleInput"
          ></el-input>
        </el-form-item>
      </cus-form-item>
      <cus-form-item :title="'userSetting.firstName'" :required="false">
        <el-form-item prop="firstName">
          <el-input
            id="user_setting_profile_input_firstName"
            v-model="userSettingForm.firstName"
            v-bind:placeholder="$t('userSetting.inputFirstName')"
          ></el-input>
        </el-form-item>
      </cus-form-item>
      <cus-form-item :title="'userSetting.lastName'" :required="false">
        <el-form-item prop="lastName">
          <el-input
            id="user_setting_profile_input_lastName"
            v-model="userSettingForm.lastName"
            v-bind:placeholder="$t('userSetting.inputLastName')"
          ></el-input>
        </el-form-item>
      </cus-form-item>
      <cus-form-item :title="'userSetting.email'">
        <el-form-item>
          <el-input
            id="user_setting_profile_input_email"
            v-model="userSettingForm.email"
            v-bind:placeholder="$t('userSetting.inputEmail')"
            :disabled="userId ? true : false"
          ></el-input>
        </el-form-item>
      </cus-form-item>
      <cus-form-item :title="'userSetting.mobile'" :required="false">
        <el-form-item prop="mobilePhone">
          <el-input
            id="user_setting_profile_input_mobile"
            v-model="userSettingForm.mobilePhone"
            v-bind:placeholder="$t('userSetting.inputMobile')"
          ></el-input>
        </el-form-item>
      </cus-form-item>
      <cus-form-item :title="'userSetting.note'" :required="false">
        <el-form-item prop="note">
          <el-input
            id="user_setting_profile_input_note"
            type="textarea"
            :rows="6"
            v-model="userSettingForm.note"
            v-bind:placeholder="$t('userSetting.inputNote')"
            class="note-input"
          ></el-input>
        </el-form-item>
      </cus-form-item>
      <el-form-item>
        <div class="form-footer">
          <common-btn
            id="user_setting_profile_btn_save"
            :content="'common.save'"
            @handle-btn="saveUserSetting"
            :disabled="saveButtonIsDisabled"
          />
        </div>
      </el-form-item>
    </el-form>
  </div>
</template>

<style scoped>
.form-item-container :deep(.form-title) {
  margin-right: -60px;
}

.user-setting-form {
  margin-left: 10px;
}

.form-footer {
  margin-left: 440px;
}

.form-footer :deep(.common-button) {
  width: 200px;
}
</style>

<style lang="scss" scoped>
.note-container {
  display: flex;
  margin-top: 20px;

  .note-title {
    flex-shrink: 0;
    // width: $inputTitleWidth;
    width: 210px;
    margin-top: 20px;
    margin-right: -90px;
    // text-align: right;
  }

  .note-input {
    width: 600px;
    font-size: 1.285714rem;
    font-family: $font-Roboto-Regular;
    line-height: 1.357143rem;
    color: #373e41;
    opacity: 1;
  }

  :deep(.el-textarea__inner) {
    width: $inputWidth;
    font-size: 1.285714rem;
    font-family: $font-Roboto-Regular;
    line-height: 1.357143rem;
    color: #373e41;
    opacity: 1;
    border: 1px solid #707070 !important;
  }
}
</style>
