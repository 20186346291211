<script setup lang="ts">
import { AuthorizableResource, AuthorizableResourceCode } from '@/api/users';
import { Tree } from 'element-ui/types/element-ui';
import { TreeData } from 'element-ui/types/tree';
import { computed, ref, unref } from 'vue';
import { authorizableResourcesToTreeData } from './authorizableResourcesToTreeData';

const props = withDefaults(
  defineProps<{
    authorizableResources: AuthorizableResource[];
    defaultChecked: AuthorizableResourceCode[];
    defaultExpand?: boolean;
    showCheckbox?: boolean;
    isEdit: boolean;
    iconClass?: string;
  }>(),
  {
    defaultExpand: true,
    showCheckbox: true,
    iconClass: 'el-icon-caret-bottom',
  }
);

const treeData = computed(() => {
  return authorizableResourcesToTreeData(
    props.authorizableResources,
    !props.isEdit
  );
});

const tree = ref<Tree | null>(null);

const getCheckedNodes = (): TreeData[] => {
  return unref(tree)?.getCheckedNodes(false, true) ?? [];
};

defineExpose({
  getCheckedNodes,
});
</script>

<template>
  <div>
    <el-row>
      <el-tree
        ref="tree"
        node-key="id"
        :data="treeData"
        :default-checked-keys="defaultChecked"
        :default-expand-all="defaultExpand"
        :show-checkbox="showCheckbox"
        :check-strictly="true"
        :icon-class="iconClass"
      ></el-tree>
    </el-row>
  </div>
</template>

<style lang="scss" scoped></style>
