import { ActiveContext } from '@/auth/context';
import { QueryParameter } from '@/model/queryParameters/QueryParameter';
import { AssetType } from '@/utils/assetTypes';
import { getRequest } from '@/utils/request';
import { KpiData } from '@/utils/types/columnCustomizationTypes';
import { DatePeriod, WeekFormat } from '@/utils/types/date';
import { KPI_UNIT } from '@/utils/units/unitDefinitions';
import { UnitValue } from '@/utils/units/unitValue';
import {
  COMPANY_TYPE,
  EventSeverity,
  GPS_ACCURACY,
  TripStatus,
  TripSubActivity,
} from '@/utils/workData/lookuptable';
import { listToQueryParam, UTCTimestamp, UUID } from './common';
import { CommonResultPromise } from './commonResult';

/* ------------- Trip By UUID Response ------------- */
export interface TripByUUIDResponse {
  id: UUID;
  companyId?: UUID;
  organizationId: UUID;
  assetId?: UUID;
  organizationName: string;
  companyName?: string;
  assetType: AssetType;
  companyAssetId: string;
  tripId: UUID;
  timezone?: string;
  isBlackBoxDataAvailable?: boolean;
  blackBoxFilePath?: string | null;
  tripTime: number | string | undefined;
  tripSubActivities?: TripSubActivities[];
  propertyDetails?: TripPropertyDetails[];
  propertyValues?: Record<string, string | number | boolean | null>;
  startPoint?: TripPoint;
  endPoint?: TripPoint;
  events: TripEvent[];
  tripStatus: TripStatus;
  payload?: UnitValue<number | undefined>;
  startTime?: Date | string;
  endTime?: Date | string;
  startPosition?: TripPoint;
  endPosition?: TripPoint;
  summary?: TripSubActivities[];
  kpiData?: KpiData[];

  massLoaded?: string | undefined;
  massUnloaded?: string;
  massLeftover?: string;
  tippingTime?: string;
  sideloadWarning?: string | boolean;
  sideloadDangerWarning?: string | boolean;
  overloadWarning?: string;
  bucklingWarning?: string;
  uphillWarning?: string;
  tripMileage?: string | undefined;
  averageSpeed?: string | undefined;
  startTippingAngle?: string;
  maximumTippingAngle?: string;
  maximumSideloadAngle?: string;
  maximumCylinderPressure?: string;
  maximumReturnFilterPressure?: string;
  massThreshold?: string;
  maxSpeed?: string;
}

export interface TripPropertyDetails {
  code: string;
  unit: string;
}

export interface TripSubActivities {
  subActivityStartTimeStamp: string;
  subActivityKind: string;
  location: {
    alt: number;
    lat: number;
    lgt: number;
    sog: number;
    cog: number;
    acc: typeof GPS_ACCURACY;
  };
}

export interface TripEvent {
  id: UUID;
  severityCode: EventSeverity;
  eventType: string;
  eventTimestamp: string;
  eventName: string;
  eventDescription: string;
  location: TripEventLocation;
}

export interface TripEventLocation {
  alt: number;
  lat: number;
  lgt: number;
  sog: number;
  cog: number;
  acc: number;
}

export interface TripPoint {
  timestamp: string;
  location: TripEventLocation;
  openLocationCode: string;
  addressLabel: string;
  street: string;
  city: string;
  country: string;
}

/* ------------- ^END of: Trip By UUID Response^ ------------- */

export function getTrips(
  data: QueryParameter,
  context?: ActiveContext
): CommonResultPromise<TripsResponse> {
  const request = getRequest();
  const response = request.post<TripsResponse>(
    `/trips`,
    data,
    getRequestConfig(context)
  );
  return response;
}

export function getAssetsWithTrips(
  requestBody: QueryParameter,
  context?: ActiveContext
): CommonResultPromise<AssetWithTripsResponse> {
  const request = getRequest();

  const response = request.post<AssetWithTripsResponse>(
    `/assets-with-trips`,
    requestBody,
    getRequestConfig(context)
  );
  return response;
}

export function getTripTrack(
  tripId: string,
  language?: string,
  context?: ActiveContext
): CommonResultPromise {
  const request = getRequest();
  const response = request.request({
    url: `/trips/${tripId}/track`,
    method: 'get',
    params: {
      i18nCode: language,
      selectedCustomer: context?.impersonatedCompanyId,
    },
  });
  return response;
}

export async function getTripByUUID(
  tripUUID: UUID,
  i18nCode: string,
  context?: ActiveContext
): Promise<TripByUUIDResponse> {
  const request = getRequest();
  const response = await request.get<TripByUUIDResponse>(`/trips/${tripUUID}`, {
    params: {
      selectedCustomer: context?.impersonatedCompanyId,
      i18nCode: i18nCode,
    },
  });

  if (response.code !== 200 || !response.data) {
    throw new Error(
      'invalid response, expected code 200 and data to be defined'
    );
  }

  return response.data;
}

export function getTripById(
  tripId: string,
  language?: string,
  context?: ActiveContext
): CommonResultPromise {
  const request = getRequest();
  const response = request.request({
    url: `/trips/${tripId}`,
    method: 'get',
    params: {
      i18nCode: language,
      selectedCustomer: context?.impersonatedCompanyId,
    },
  });
  return response;
}

export function getTripAssetTypes(
  context?: ActiveContext
): CommonResultPromise {
  const request = getRequest();
  const response = request.request({
    url: `/trips/asset-types`,
    method: 'get',
    params: {
      selectedCustomer: context?.impersonatedCompanyId,
    },
  });
  return response;
}

function getRequestConfig(context: ActiveContext | undefined) {
  return context
    ? {
        params: {
          selectedCustomer: context?.impersonatedCompanyId,
        },
      }
    : {};
}

export interface AssetWithTripsResponse {
  assets: TripAsset[];
  size: number;
  total: number;
}

export interface TripAsset {
  assetId: string;
  companyAssetId: string;
}

export interface TripProperties {
  propertyDetails: { code: string; unit: string }[];
  propertyValues: Record<string, string | number | boolean | null>;
}

export interface TripResponse extends TripProperties {
  assetId: string;
  assetType: AssetType;
  companyAssetId: number;
  companyId: string;
  companyName: string;
  endPoint: Point;
  events: {
    eventDescription: string;
    eventName: string;
    eventTimestamp: string;
    eventType: string;
    id: string;
    location: Location;
    speed: number;
  }[];
  id: string;
  organizationId: string;
  organizationName: string;
  startPoint: Point;
  timezone: string;
  tripStatus: TripStatus;
  tripTime: number | undefined;
  tripSubActivities: {
    location: Location;
    subActivityKind: TripSubActivity;
    subActivityStartTimeStamp: string;
  }[];
}

export type TripsResponse = {
  size: number;
  total: number;
  trips: TripResponse[];
};

type Point = {
  city: string;
  location: Location;
  openLocationCode: string;
  street: string;
  timestamp: string;
};

type Location = {
  lat: number;
  lgt: number;
  alt: number;
  sog: number;
};

/** ------------------------ New Safety Widget scope ------------------------ */

/** ---- Interfaces ---- */

/**
 * Existing dsafety events types
 */
export const enum SafetyEventType {
  SAFETY_EVENT_VEHICLE_OVERLOADED = 'EVNT_SAF-TIP-A-OCTR-EDEAJ03-VEHICLE_OVERLOADED',
  SAFETY_EVENT_SIDE_LOAD_WARNING = 'EVNT_SAF-TIP-A-OCTR-EDEAJ03-SIDE_LOAD_WARNING',
  SAFETY_EVENT_SIDE_LOAD_DANGER = 'EVNT_SAF-TIP-A-OCTR-EDEAJ03-SIDE_LOAD_DANGER',
  SAFETY_EVENT_BUCKLING_WARNING = 'EVNT_SAF-TIP-A-OCTR-EDEAJ03-BUCKLING_WARNING',
}

export const enum SafetySeverityPropertyCode {
  ASSETOverloadPercentage = 'ASSET.OverloadPercentage',
  ASSETSideAngle = 'ASSET.SideAngle',
  ASSETCylinderPressure = 'ASSET.CylinderPressure',
}

export interface AvailableSafetyEventType {
  eventTypeCode: SafetyEventType;
  isDefault: boolean;
  severityPropertyCode: SafetySeverityPropertyCode;
  unitCode: KPI_UNIT;
}

/**
 * Response of available safety event types for specific company type,
 * `api/v1.0/available-safety-event-types/{companyTypeCode}
 *
 * End point (#1)
 * Location: configuration micro service
 */
export type GetAvailableSafetyEventTypesResponse = AvailableSafetyEventType[];

/**
 * Value object representing trip events summary.
 */
export interface TripsWithEventsSummary {
  /**
   * Total number of trips
   */
  numberOfTotalTrips: number;

  /**
   * number of trips with one given event within total number of trips
   */
  numberOfTripsWithEvents: number;

  /**
   * Percentage of numberOfTripsWithEvents from numberOfTotalTrips
   * Ratio: 0.0 -- 1.0
   * Percentage: 0.0 -- 100.0
   */
  ratioPercentage: number;
}

// NOTE: Timezone: local time zone of company. End of trip processing must already map trip end and event timestamp to local timestamp in additional column in table
/**
 * Return the information as time based buckets if data dimension was set to DBDIM_TIME
 * Response of `api/v1.0/trip-events-summary-time/?
 *      &organizationUUIDs={list of organizationUUIDs} // Optional. One of organizationUUIDs or assetUUIDs must be provided, not both
 *      &assetUUIDs={list of assetUUIDs}  // Optional. One of organizationUUIDs or assetUUIDs must be provided, not both
 *  NOTE: or always ask organization id to be provided - what is done now?
 *      &eventTypeCode={eventTypeCode}   // mandatory
 *    //  &dataBucketDimension={dataBucketDimension} // Not needed - Assume DBDIM_TIME
 *      &selectedDatePeriod={selectedDatePeriod}  //mandatory
 *      &datePeriodStartDate={datePeriodStartDate}  //mandatory
 *      &datePeriodEndDate={datePeriodEndDate}  //mandatory
 *      &weekFormat={weekFormat} // Optional - only allowed when DatePeriod is DATP_WEEK. Default: WKFMT_ISO
 * endpoint.
 */
export type GetTripsWithEventsSummaryTimeResponse =
  TripsWithEventsSummaryByTime[];

export interface TripsWithEventsSummaryByTime {
  bucketStartDate: string;
  bucketLabel: string;
  companyAssetId?: string;
  tripsWithEventsSummary: TripsWithEventsSummary;
}

/**
 * Response of `api/v1.0/trip-events-summary-asset/?
 *      &organizationUUIDs={list of organizationUUIDs} // Optional. One of organizationUUIDs or assetUUIDs must be provided, not both
 *      &assets={list of assetUUIDs}  // Optional. One of organizationUUIDs or assetUUIDs must be provided, not both
 *  NOTE: or always ask organization id to be provided - what is done now?
 *      &eventTypeCode={eventTypeCode}
 *      &dataBucketDimension={dataBucketDimension} // Not needed - Assume DBDIM_ASSET
 *      &selectedDatePeriod={selectedDatePeriod}
 *      &datePeriodStartDate={datePeriodStartDate}
 *      &datePeriodEndDate={datePeriodEndDate}
 *      &maxNumberToReturn={maxNumberToReturn}
 * endpoint.
 *
 * Response contains the list of buckets with the information about trips with events per bucket.
 *
 * Sort order: bucketInformationAssetSelected.tripsWithEventsSummary.ratio DESC, bucketInformationAssetSelected.companyAssetId ASC
 * End point (#3a) (#3b)
 *
 * Location: Trip micro service
 */
export type GetTripsWithEventsSummaryAssetResponse =
  TripsWithEventsSummaryByAsset[];

export interface TripsWithEventsSummaryByAsset {
  assetUUID: UUID;
  companyAssetId: string;
  tripsWithEventsSummary: TripsWithEventsSummary;
}

/**
 * Return trip events summary for one single
 * Response of `api/v1.0/asset-trip-events/{assetUUID}?
 *      &eventTypeCode={eventTypeCode}   // mandatory
 *      &datePeriodStartDate={datePeriodStartDate}  //mandatory
 *      &datePeriodEndDate={datePeriodEndDate}  //mandatory
 * endpoint.
 * End point (#5)
 *
 * Location: Trip micro service
 */
export interface AssetTripEventsSummary {
  /**
   * Trip uuid
   */
  tripUUID: UUID;

  /**
   * Trip start time in time zone of the company (top organization)
   */
  tripStartTime: UTCTimestamp;

  /**
   * Trip start time in time zone of the company (top organization)
   */
  tripEndTime: UTCTimestamp;

  /**
   * Total number of safety events of selected event type
   */
  numberOfEvents: number;

  /**
   * Sum of severity paramters
   */
  sumOfSeverityLevel: number;

  /**
   * Average value of severity level for current trip
   */
  averageSeverityLevel: number;

  /**
   * Minimum value of severity level for current trip
   */
  minimumSeverityLevel: number;

  /**
   * Maximum value of severity level for current trip
   */
  maximumSeverityLevel: number;
}

export interface SingleAssetTripEventsRequest {
  assetUUID: string;
  eventTypeCode: string;
  datePeriodStart: string;
  datePeriodEnd: string;
}

export interface EventsDetailedSummary {
  /**
   * Total number of events
   */
  numberOfEvents: number;
  /**
   * Sum of severity paramters
   */
  sumOfSeverityLevel: number;

  /**
   * Average value of severity level for current bucket
   */
  averageSeverityLevel: number;

  /**
   * Minimum value of severity level for current bucket
   */
  minimumSeverityLevel: number;

  /**
   * Maximum value of severity level for current bucket
   */
  maximumSeverityLevel: number;
}

/**
 * 2 hours bucket details
 */
export interface TripsWithEventsSummary2HoursDetails {
  bucketLabel: string;
  tripsWithEventsSummary: TripsWithEventsSummary;
  eventsDetailedSummary: EventsDetailedSummary;
}

/**
 * Return the information as 2h buckets
 * Response of `api/v1.0/trip-events-2hours-details-time/?
 *      &organizationUUIDs={list of organizationUUIDs} // Optional. One of organizationUUIDs or assetUUIDs must be provided, not both
 *      &assetUUIDs={list of assetUUIDs}  // Optional. One of organizationUUIDs or assetUUIDs must be provided, not both
 *  NOTE: or always ask organization id to be provided - what is done now?
 *      &selectedDatePeriod={selectedDatePeriod}  //mandatory
 *      &eventTypeCode={eventTypeCode}   // mandatory
 *      &datePeriodStartDate={datePeriodStartDate}  //mandatory
 *      &datePeriodEndDate={datePeriodEndDate}  //mandatory
 *      &assetTypeCode={assetTypeCode} // Optional
 * endpoint.
 * End point (#4b)
 *
 * Location: Trip micro service
 */
export interface GetTripsWithEventsSummary2HoursDetails {
  numberOfAssetsWithTrips: number;
  tripsWithEventsDetailedSummary: TripsWithEventsSummary2HoursDetails[];
}

/** ---- Endpoints ---- */

/**
 * Get available safety events by company type
 * @param companyTypeCode
 * @returns list of available Safety Event types for this company type
 */
export async function getAvailableSafetyEvents(
  companyTypeCode: COMPANY_TYPE,
  context?: ActiveContext
): Promise<GetAvailableSafetyEventTypesResponse> {
  const request = getRequest();
  const response = await request.get<GetAvailableSafetyEventTypesResponse>(
    `/available-safety-event-types/${companyTypeCode}`,
    {
      params: {
        selectedCustomer: context?.impersonatedCompanyId,
      },
    }
  );

  if (response.code !== 200 || !response.data) {
    throw new Error(
      'invalid response, expected code 200 and data to be defined'
    );
  }

  return response.data;
}

export interface GetTripWithEventsBaseRequest {
  organizationUUIDs?: string[] | string | undefined;
  selectedDatePeriod: DatePeriod;
  eventTypeCode: SafetyEventType;
  datePeriodStartDate: string;
  datePeriodEndDate: string;
  assetTypeCode?: string | undefined;
  assetUUIDs?: string[] | string | undefined;
}

export interface GetTripsWithEventsSummaryTimeRequest
  extends GetTripWithEventsBaseRequest {
  weekFormat?: WeekFormat | undefined;
}

export interface GetTripsWithEventsSummaryAssetRequest
  extends GetTripWithEventsBaseRequest {
  maxNumberToReturn?: number;
}

/**
 * Get trips with events summary by time
 * @returns GetTripsWithEventsSummaryTimeResponse
 */
export async function getTripEventsSummaryByTime(
  params: GetTripsWithEventsSummaryTimeRequest,
  context?: ActiveContext
): Promise<GetTripsWithEventsSummaryTimeResponse> {
  const request = getRequest();
  const response = await request.get<GetTripsWithEventsSummaryTimeResponse>(
    `/trip-events-summary-time`,
    {
      params: {
        ...params,
        organizationUUIDs: listToQueryParam(params.organizationUUIDs),
        assetUUIDs: listToQueryParam(params.assetUUIDs),
        selectedCustomer: context?.impersonatedCompanyId,
      },
    }
  );

  if (response.code !== 200 || !response.data) {
    throw new Error(
      'invalid response, expected code 200 and data to be defined'
    );
  }

  return response.data;
}

/**
 * Get asset trip events
 * @param assetUUID
 * @param eventTypeCode
 * @param datePeriodStart
 * @param datePeriodEnd
 */
export async function getSingleAssetTripEvents(
  assetUUID: string,
  eventTypeCode: string,
  datePeriodStart: string,
  datePeriodEnd: string,
  context?: ActiveContext
): Promise<AssetTripEventsSummary[]> {
  const request = getRequest();
  const response = await request.get<AssetTripEventsSummary[]>(
    `/asset-trip-events/${assetUUID}`,
    {
      params: {
        eventTypeCode: eventTypeCode,
        datePeriodStart: datePeriodStart,
        datePeriodEnd: datePeriodEnd,
        selectedCustomer: context?.impersonatedCompanyId,
      },
    }
  );

  if (response.code !== 200 || !response.data) {
    throw new Error(
      'invalid response, expected code 200 and data to be defined'
    );
  }

  return response.data;
}

export async function getTripEventsSummaryByAsset(
  params: GetTripsWithEventsSummaryAssetRequest,
  context?: ActiveContext
): Promise<GetTripsWithEventsSummaryAssetResponse> {
  const request = getRequest();
  const response = await request.get<GetTripsWithEventsSummaryAssetResponse>(
    `/trip-events-summary-asset`,
    {
      params: {
        ...params,
        organizationUUIDs: listToQueryParam(params.organizationUUIDs),
        assetUUIDs: listToQueryParam(params.assetUUIDs),
        selectedCustomer: context?.impersonatedCompanyId,
      },
    }
  );

  if (response.code !== 200 || !response.data) {
    throw new Error(
      'invalid response, expected code 200 and data to be defined'
    );
  }

  return response.data;
}

export async function getTripEventsSummaryByTimeForTwoHoursBuckets(
  params: GetTripWithEventsBaseRequest,
  context?: ActiveContext
): Promise<GetTripsWithEventsSummary2HoursDetails> {
  const request = getRequest();
  const response = await request.get<GetTripsWithEventsSummary2HoursDetails>(
    `/trip-events-2hours-details-time`,
    {
      params: {
        ...params,
        organizationUUIDs: listToQueryParam(params.organizationUUIDs),
        assetUUIDs: listToQueryParam(params.assetUUIDs),
        selectedCustomer: context?.impersonatedCompanyId,
      },
    }
  );

  if (response.code !== 200 || !response.data) {
    throw new Error(
      'invalid response, expected code 200 and data to be defined'
    );
  }

  return response.data;
}

export async function getTripEventsSummaryByAssetForTwoHoursBuckets(
  params: GetTripWithEventsBaseRequest,
  context?: ActiveContext
): Promise<GetTripsWithEventsSummary2HoursDetails> {
  const request = getRequest();
  const response = await request.get<GetTripsWithEventsSummary2HoursDetails>(
    `/trip-events-2hours-details-asset`,
    {
      params: {
        ...params,
        organizationUUIDs: listToQueryParam(params.organizationUUIDs),
        assetUUIDs: listToQueryParam(params.assetUUIDs),
        selectedCustomer: context?.impersonatedCompanyId,
      },
    }
  );

  if (response.code !== 200 || !response.data) {
    throw new Error(
      'invalid response, expected code 200 and data to be defined'
    );
  }

  return response.data;
}
