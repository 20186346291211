export const PRESET_PARAMETER_COLS = [
  {
    label: 'paramConfig.columns.propertyName',
    prop: 'name',
    required: true,
    visible: true,
    multiLang: true,
    sortable: false,
  },
  {
    label: 'paramConfig.columns.inUseValue',
    prop: 'value',
    required: true,
    visible: true,
    multiLang: true,
    sortable: false,
  },
  {
    label: 'paramConfig.columns.unitCode',
    prop: 'translatedUnit',
    required: true,
    visible: true,
    multiLang: true,
    sortable: false,
  },
];
