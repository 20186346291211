/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon';
icon.register({
  save: {
    width: 15.782,
    height: 15.782,
    viewBox: '0 0 15.782 15.782',
    data: '<path pid="0" data-name="Path 1422" d="M10.364 0a1.973 1.973 0 011.4.585l3.445 3.481a1.973 1.973 0 01.57 1.388v9.094a1.233 1.233 0 01-1.233 1.233H1.233A1.233 1.233 0 010 14.548V1.233A1.233 1.233 0 011.233 0zm0 1.233H1.233v13.316h2.712V9.165a.616.616 0 01.616-.616h6.658a.616.616 0 01.616.616v5.384h2.713V5.455a.74.74 0 00-.159-.458l-.055-.062-3.445-3.482a.74.74 0 00-.525-.22zm.157 8.631h-5.26v4.685h5.26zM5.302 3.576a.124.124 0 01.123.123v.986a.123.123 0 01-.123.123H3.083a.123.123 0 01-.123-.123V3.7a.124.124 0 01.123-.123z" _fill="#373e41"/>',
  },
});
