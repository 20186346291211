<script lang="ts">
import { getOrgById, Organization } from '@/api/organizations';
import { ErrorType } from '@/api/types';
import CommonBtn from '@/components/button/CommonBtn.vue';
import NewCard from '@/components/cusCard/NewCard.vue';
import CusFormItem from '@/components/form/CusFormItem.vue';
import { UserModule } from '@/store/modules/user';
import { filterDeactivatedOrg } from '@/utils/index';
import { customFailedMessage } from '@/utils/prompt';
import { ERROR_CODE_LIST } from '@/utils/workData/lookuptable';
import Treeselect from '@riophae/vue-treeselect';
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
  name: 'userSetting',
  components: {
    Treeselect,
    'new-card': NewCard,
    'cus-form-item': CusFormItem,
    'common-btn': CommonBtn,
  },
})
export default class extends Vue {
  @Prop() id!: string;
  @Prop() userForm!: any;
  @Prop() errorInfo!: ErrorType[];

  /** Local variables */
  userInfo: any = {};
  title: string = '';
  userId = UserModule.id;
  orgs: Organization[] = [];

  errorCode = ERROR_CODE_LIST;
  errorInfos: ErrorType[] = [
    {
      code: '',
      field: '',
      message: '',
    },
  ];

  created() {
    this.fetchOrgMgmtInfos();
  }

  /**
   * Get inline message
   */
  get inlineMsg(): boolean {
    return false;
  }

  get errNameInfo() {
    let errInfo: string = '';

    if (
      this.errorInfos.find((item) => item.field === this.errorCode.userName)
        ?.code === 'ApiErrorFieldUnique'
    ) {
      errInfo = `${this.$t('userModule.duplicateUser')}`;
    }
    return errInfo;
  }

  get rules() {
    const tmpRules = {
      username: [
        {
          required: true,
          message: this.$t('userModule.tipInputUsername'),
          trigger: 'change',
        },
      ],
    };

    return tmpRules;
  }

  /**
   * Send user form event to the parent
   */
  saveUserInfo(): void {
    (this.$refs.userForm as any).validate((valid: any) => {
      if (valid) {
        this.$emit('handle-saveInfos');
      }
    });
  }

  customKey(node: Organization) {
    return {
      id: node.id,
      label: node.name,
      children: node.children?.length > 0 ? node.children : undefined,
    };
  }

  /**
   * Fetch organization by id
   */
  async fetchOrgMgmtInfos(): Promise<void> {
    try {
      const response = await getOrgById(UserModule.organizationId);
      this.orgs = filterDeactivatedOrg([response.data]);
    } catch (error) {
      console.log(error);
      customFailedMessage(this.$t('common.errorWithFetchingData') as string);
    } finally {
    }
  }
}
</script>

<template>
  <el-form
    ref="userForm"
    :rules="rules"
    :model="userForm"
    :inline-message="inlineMsg"
    style="margin-left: 80px"
  >
    <cus-form-item :title="'userModule.userName'" :errContent="errNameInfo">
      <el-form-item prop="username">
        <el-input
          v-model="userForm.username"
          v-bind:placeholder="$t('userSetting.inputUserName')"
        ></el-input>
      </el-form-item>
    </cus-form-item>
    <cus-form-item :title="'userModule.firstName'" :required="false">
      <el-form-item>
        <el-input
          v-model="userForm.firstName"
          v-bind:placeholder="$t('userSetting.inputFirstName')"
        ></el-input>
      </el-form-item>
    </cus-form-item>
    <cus-form-item :title="'userModule.lastName'" :required="false">
      <el-form-item>
        <el-input
          v-model="userForm.lastName"
          v-bind:placeholder="$t('userSetting.inputLastName')"
        ></el-input>
      </el-form-item>
    </cus-form-item>
    <cus-form-item :title="'userModule.email'">
      <el-form-item>
        <el-input
          v-model="userForm.email"
          v-bind:placeholder="$t('userSetting.inputEmail')"
          :disabled="userId ? true : false"
        ></el-input>
      </el-form-item>
    </cus-form-item>
    <cus-form-item :title="'userModule.userRole'" :required="false">
      <el-form-item>
        <el-input
          v-model="userForm.roleName"
          v-bind:placeholder="$t('userSetting.inputMobile')"
          :disabled="userId ? true : false"
        ></el-input>
      </el-form-item>
    </cus-form-item>
    <cus-form-item :title="$t('userModule.organizationName')">
      <el-form-item prop="organizationId">
        <treeselect
          class="cusTreeselect"
          :options="orgs"
          v-model="userForm.organizationId"
          :normalizer="customKey"
          :clearable="false"
          :placeholder="$t('userModule.select')"
        />
      </el-form-item>
    </cus-form-item>
    <cus-form-item :title="'userModule.mobilePhone'" :required="false">
      <el-form-item>
        <el-input
          v-model="userForm.mobilePhone"
          v-bind:placeholder="$t('userSetting.inputMobile')"
        ></el-input>
      </el-form-item>
    </cus-form-item>
    <cus-form-item :title="'userModule.note'" :required="false">
      <el-form-item>
        <el-input
          v-model="userForm.note"
          v-bind:placeholder="$t('userSetting.inputNote')"
        ></el-input>
      </el-form-item>
    </cus-form-item>
    <el-form-item>
      <div class="form-footer">
        <common-btn :content="'common.save'" @handle-btn="saveUserInfo" />
      </div>
    </el-form-item>
  </el-form>
</template>
