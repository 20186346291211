<script lang="ts" setup>
import WidgetLayout from '@/components/layout/widget/WidgetLayout.vue';
import { provideSelectedMaintenanceStatuses } from '../../widgets/maintenance/status';

provideSelectedMaintenanceStatuses();
</script>

/** Notice: Initial "Maintenance Plan List" (table view with maintenance monitor
column) Widget receives the auth code for displaying from IoT > APP-BE:
"WIDGET_MAINTEN_PLAN_LIST", but has been translated to "Maintenance Overview",
therefore there are different codes for permission, but on the UI, the
translation is the same. Component MaintenancePlanList.vue */

<template>
  <WidgetLayout
    page-code="AUTHRSC_PAGE_MAINT_OVERVIEW"
    :hideConfigurable="true"
    class="overview-widget"
  />
</template>

/** AHMAPP-6098 Current style applied for entire Overview page enforce the
default layout to behave as new improvement requirements: - Hight of all widgets
(Maintenance Status, Maintenances Performed, Appointments Overview) should be
smaller to reduce gap - "Widget" button from right side up is not applicable for
this scenario (has been removed) */
<style scoped>
.overview-widget :deep(.widget-card-container) {
  height: 425px !important;
}

.overview-widget :deep(.grid) {
  grid-template-rows: 0 !important;
}
</style>
