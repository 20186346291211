<script lang="ts" setup>
import { fetchAssetsByFlexibleFiltering } from '@/api/assets';
import { useActiveContext } from '@/auth/context';
import Title from '@/components/layout/Title.vue';
import WidgetLayout from '@/components/layout/widget/WidgetLayout.vue';
import { useRoute } from '@/composables/router';
import { FilterOperator } from '@/model/queryParameters/QueryParameter';
import { RouteAssetItem, ROUTE_ASSET_MAP } from '@/utils/workData/utilMap';
import { provideSelectedMaintenanceStatuses } from '@/widgets/maintenance/status';
import { ref, unref, watch } from 'vue';

const route = useRoute();
const activeContext = useActiveContext();
const assetInfo = ref<RouteAssetItem>();
const fetchingAssetsAvailable = ref<boolean>(true);
const assetsAvailable = ref<boolean>(false);
provideSelectedMaintenanceStatuses(new Set(), false);

watch(
  route,
  (next) => (assetInfo.value = ROUTE_ASSET_MAP.get(next.name ?? '')),
  { immediate: true }
);

watch(
  assetInfo,
  async function updateAssetsAvailable(
    newAssetInfo: RouteAssetItem | undefined
  ) {
    if (!newAssetInfo) {
      assetsAvailable.value = false;
      return;
    }
    fetchingAssetsAvailable.value = true;
    assetsAvailable.value = false;
    try {
      const organizationIds = unref(activeContext).organizationIds;
      const assets = await fetchAssetsByFlexibleFiltering(
        {
          pagination: { page: 1, size: 1 },
          filters: [
            {
              name: 'assetType',
              operator: FilterOperator.EQUAL,
              value: [newAssetInfo.assetTypeCode],
            },
            ...(organizationIds
              ? [
                  {
                    name: 'organizationId',
                    operator: FilterOperator.IN,
                    value: organizationIds,
                  },
                ]
              : []),
          ],
        },
        unref(activeContext)?.impersonatedCompanyId
      );
      assetsAvailable.value = assets?.data?.size > 0;
    } catch {
      assetsAvailable.value = false;
    } finally {
      fetchingAssetsAvailable.value = false;
    }
  },
  { immediate: true }
);
</script>

<template>
  <transition name="fade-transform" mode="out-in">
    <WidgetLayout
      v-if="assetInfo && (fetchingAssetsAvailable || assetsAvailable)"
      :page-code="assetInfo.pageCode"
      :hide-configurable="true"
    />
    <div v-else>
      <Title class="title" />
      <div class="no-assets total-statistics">
        <span>{{ $tc('assetMgmt.noAssetsAvailableInThisOrg') }}</span>
      </div>
    </div>
  </transition>
</template>

<style lang="scss" scoped>
.no-assets {
  width: 100%;
  height: 100%;
  text-align: center;
  padding-top: 30px;
  font-size: 20px;
}
</style>
