import { Unit } from '@/api/units';
import { UnitConversion } from '@/utils/units/unitConversionTypes';
import { KPI_UNIT, Quantity } from '@/utils/units/unitDefinitions';

interface KpiConversionDefinition {
  unitCode: KPI_UNIT;
  factor: number;
  offset?: number;
}

export type KpiToUnitConversionMapping = Partial<
  Record<KPI_UNIT, UnitConversion>
>;

export function getUnitConversions(units: Unit[]): KpiToUnitConversionMapping {
  const quantityToUnitMapping: Map<Quantity, KpiConversionDefinition[]> =
    new Map<Quantity, KpiConversionDefinition[]>();

  for (const unit of units) {
    if (unit.quantityKind === undefined || unit.factor === undefined) {
      continue;
    }

    // If quantity is not part of mapping, add it with an empty array
    if (!quantityToUnitMapping.has(unit.quantityKind)) {
      quantityToUnitMapping.set(unit.quantityKind, []);
    }

    // Add unit to the quantity's mapping
    quantityToUnitMapping
      .get(unit.quantityKind)!
      .push(toKpiConversionDefinition(unit));
  }

  // Invert mapping so that the unit's are the keys
  return allConversionsToUnitConversions(quantityToUnitMapping);
}

function allConversionsToUnitConversions(
  allConversions: Map<Quantity, KpiConversionDefinition[]>
): KpiToUnitConversionMapping {
  const result: KpiToUnitConversionMapping = {};

  allConversions.forEach((kpiUnits, quantity) => {
    for (const unit of kpiUnits) {
      result[unit.unitCode] = {
        quantity,
        factor: unit.factor,
        offset: unit.offset,
      };
    }
  });

  return result;
}

function toKpiConversionDefinition(unit: Unit): KpiConversionDefinition {
  if (unit.factor === undefined) {
    throw new Error('Factor not set.');
  }

  return { unitCode: unit.code, offset: unit.offset, factor: unit.factor };
}
