<script lang="ts">
import CommonBtn from '@/components/button/CommonBtn.vue';
import CusFormItem from '@/components/form/CusFormItem.vue';
import {
  ASSET_TYPE_LIST,
  COMPANY_TYPE_LIST,
  PACKAGE_TYPE,
  PACKAGE_TYPE_LIST,
} from '@/utils/workData/lookuptable';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

@Component({
  name: 'General',
  components: {
    'cus-form-item': CusFormItem,
    'common-btn': CommonBtn,
  },
})
export default class extends Vue {
  @Prop({ required: true }) editData!: any;
  @Prop({ required: true }) source!: any;
  @Prop({ required: true }) isEditable!: any;
  @Prop({ required: true }) generalData!: any;
  @Prop({ required: false }) fieldErrors!: any;

  /** Local variables */
  isCustomerType: boolean = false;
  assetTypeList: any = ASSET_TYPE_LIST;
  customerTypeList: any = COMPANY_TYPE_LIST;
  packageTypeList: any = PACKAGE_TYPE_LIST;
  packageNameError: string = '';

  created() {
    if (Object.keys(this.editData).length > 0) {
      this.handlePackageTypeSelection();
    }
  }

  /**
   * Triggered when package type is changed
   */
  handlePackageTypeSelection() {
    if (this.source === 'new' && this.generalData.packageType)
      this.$emit('handle-package-type-features', this.generalData.packageType);

    if (this.generalData.packageType != PACKAGE_TYPE.AssetType) {
      this.generalData.assetType = '';
      this.isCustomerType = true;
      return;
    }

    this.generalData.companyType = '';
    this.isCustomerType = false;
  }

  @Watch('generalData', { deep: true })
  handleDataImput() {
    this.$emit('general-data-update', this.generalData);
  }
}
</script>

<template>
  <div class="general-container">
    <el-form id="generalformcontainer">
      <cus-form-item
        :title="'subscriptionPackages.packageName'"
        :errContent="fieldErrors.packageName"
        :required="true"
      >
        <el-input
          id="packagenameinput"
          :disabled="!isEditable"
          class="util-table-input"
          v-model="generalData.packageName"
          :placeholder="$t('subscriptionPackages.packageName')"
          style="margin-right: 40px"
        />
      </cus-form-item>
      <cus-form-item
        class="package-type-input"
        :title="'subscriptionPackages.packageType'"
        :errContent="fieldErrors.packageType"
        :required="true"
      >
        <el-select
          id="packagetypedropdown"
          v-model="generalData.packageType"
          @change="handlePackageTypeSelection"
          v-bind:placeholder="$t('subscriptionPackages.packageType')"
          :disabled="source === 'edit'"
        >
          <el-option
            v-for="item in packageTypeList"
            :key="item.id"
            :label="$t(`subscriptionPackages.${item.value}`)"
            :value="item.value"
          />
        </el-select>
      </cus-form-item>
      <cus-form-item
        v-if="isCustomerType"
        class="company-type-input"
        :title="'subscriptionPackages.customerType'"
        :errContent="fieldErrors.companyType"
        :required="true"
        :disabled="!isEditable && source !== 'new'"
      >
        <el-select
          id="customertypedropdown"
          :title="
            !generalData.packageType
              ? $t('subscriptionPackages.mustSelectPackageType')
              : ''
          "
          v-model="generalData.companyType"
          v-bind:placeholder="$t('subscriptionPackages.customerType')"
          :disabled="!isEditable || !generalData.packageType"
        >
          <el-option
            v-for="item in customerTypeList"
            :key="item.id"
            :label="$t(item.value)"
            :value="item.id"
          />
        </el-select>
      </cus-form-item>
      <cus-form-item
        v-if="!isCustomerType"
        class="asset-type-input"
        :title="'subscriptionPackages.assetType'"
        :errContent="fieldErrors.assetType"
        :required="true"
      >
        <el-select
          id="customertypedropdown"
          :disabled="
            (!isEditable && source !== 'new') || !generalData.packageType
          "
          :title="
            !generalData.packageType
              ? $t('subscriptionPackages.mustSelectPackageType')
              : ''
          "
          v-model="generalData.assetType"
          v-bind:placeholder="$t('subscriptionPackages.assetType')"
        >
          <el-option
            v-for="item in assetTypeList"
            :key="item.id"
            :label="$t(item.id)"
            :value="item.id"
          />
        </el-select>
      </cus-form-item>
      <cus-form-item
        class="note-container"
        :title="'subscriptionPackages.note'"
        :errContent="fieldErrors.note"
        :required="false"
      >
        <el-form-item>
          <el-input
            id="noteinput"
            :disabled="!isEditable"
            type="textarea"
            :rows="6"
            v-model="generalData.note"
            v-bind:placeholder="$t('subscriptionPackages.writeHereANote')"
            class="note-input"
          ></el-input>
        </el-form-item>
      </cus-form-item>
    </el-form>
  </div>
</template>

<style lang="scss" scoped>
.note-container {
  display: flex;
  margin-top: 20px;

  .note-title {
    flex-shrink: 0;
    width: $inputTitleWidth;
    margin-top: 20px;
    margin-right: 20px;
    text-align: right;
  }

  .note-input {
    width: 440px;
    font-size: 1.285714rem;
    font-family: $font-Roboto-Regular;
    line-height: 1.357143rem;
    color: #373e41;
    opacity: 1;
  }

  :deep(.el-textarea__inner) {
    width: $inputWidth;
    font-size: 1.285714rem;
    font-family: $font-Roboto-Regular;
    line-height: 1.357143rem;
    // color: #373E41;
    opacity: 1;
    border: 1px solid #707070 !important;
  }
}
</style>

<style scoped>
.general-container {
  margin-left: 20px;
}

.general-container :deep(.form-title) {
  margin-right: -20px;
}

.form-item-container :deep(.el-icon-search) {
  margin-right: 40px;
}

.package-name-field-error {
  padding-left: 200px;
  margin: 10px 110px 0 0;
  color: red;
  font-family: var(--fontRobotoRegular);
}

.package-type-input :deep(.el-select--medium) {
  margin-right: 40px;
}

.asset-type-input :deep(.el-select--medium) {
  margin-right: 40px;
}

.company-type-input :deep(.el-select--medium) {
  margin-right: 40px;
}
</style>
