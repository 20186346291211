import { PageModule } from '@/store/modules/page';
import { Ref, watch } from 'vue';
import { useRoute } from './router';

export function useBreadcrumbs(
  crumbs: Ref<undefined | string | string[]>
): void {
  const route = useRoute();

  watch(
    [route, crumbs],
    ([nextRoute, nextCrumbs], [previousRoute]) => {
      if (!nextCrumbs || nextCrumbs == undefined) {
        return;
      }

      const assetCrumb = Array.isArray(nextCrumbs) ? nextCrumbs : [nextCrumbs];

      if (
        (nextRoute.path === previousRoute?.path || !previousRoute?.path) &&
        !PageModule.title.includes(assetCrumb.toString())
      ) {
        PageModule.setTitle([...PageModule.title, ...assetCrumb]);
      }
    },
    { immediate: true }
  );
}
