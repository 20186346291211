<script lang="ts">
import { getKpisForMultipleAssets, KpiDataRequest } from '@/api/kpis';
import {
  getPendingUpdates,
  PendingUpdate,
} from '@/api/systemReleaseManagement';
import { flattenOrganizations } from '@/auth/context';
import { LoggedInUserRef, useLoggedInUser } from '@/auth/user';
import PureTable from '@/components/table/PureTable.vue';
import { useBooleanSystemFeature } from '@/composables/systemFeature';
import { Filter, FilterOperator } from '@/model/queryParameters/QueryParameter';
import { UserModule } from '@/store/modules/user';
import { customWarningMessage } from '@/utils/prompt';
import {
  DEPLOYMENT_PENDING_UPDATES_MANAGEMENT_COLS,
  PENDING_UPDATES_FILTER_OPTIONS,
} from '@/utils/workData/deploymentPendingUpdatesManagementCols';
import {
  COMPANY_TYPE,
  GENERAL_QUERY_OPERATORS,
  KPI_FIELDS,
  SYSTEM_FEATURES,
} from '@/utils/workData/lookuptable';
import { ComputedRef } from 'vue';
import { Component, Vue } from 'vue-property-decorator';

@Component({
  name: 'PendingUpdates',
  components: {
    PureTable,
  },
})
export default class extends Vue {
  /** Local variables */
  cols = DEPLOYMENT_PENDING_UPDATES_MANAGEMENT_COLS.map((item) => ({
    ...item,
    label: this.$t(item.label),
  }));
  assetIds: string[] = [];
  pageTotal = 1;
  pendingUpdatedTableData: PendingUpdate[] = [];
  numberOfReleases = this.pendingUpdatedTableData.length;
  searchInput: string = '';
  filterOptions: { field: string; label?: string; dropdownEnum?: any }[] =
    PENDING_UPDATES_FILTER_OPTIONS;
  isPendingUpdatedTableLoading: boolean = false;
  kpiData: KpiDataRequest = {
    metadata: {
      filter: {
        assetIds: [],
      },
    },
    details: [
      {
        entity: '',
        fields: [
          {
            code: KPI_FIELDS.OperationalStatus,
          },
          {
            code: KPI_FIELDS.LastCommunicationTime,
          },
        ],
      },
    ],
  };
  pagination = {
    page: 1,
    size: UserModule.gridPageSize,
  };
  filter = {
    name: '',
    operator: GENERAL_QUERY_OPERATORS.Equal,
    values: [''],
  };
  queryParameters: {
    filters: any[];
    pagination: { page: number; size: number };
    sorters: { field: string; order: string }[];
  } = {
    filters: [],
    sorters: [],
    pagination: this.pagination,
  };
  loggedInUser!: LoggedInUserRef;
  filterEnum: any = null;
  isDifferentUserThanHyvaadmin: boolean = false;
  remoteSoftwareEntitlement!: ComputedRef<boolean>;

  created() {
    this.remoteSoftwareEntitlement = useBooleanSystemFeature(
      SYSTEM_FEATURES.RemoteSoftwareUpdate
    );
    this.loggedInUser = useLoggedInUser();
    this.isDifferentUserThanHyvaadmin =
      UserModule.companyType !== COMPANY_TYPE.Hyva;
    this.prepareDefaultQueryParameters();
    this.getData();
  }

  /**
   * Prepare default query parameters
   */
  prepareDefaultQueryParameters(): void {
    if (this.isDifferentUserThanHyvaadmin && this.loggedInUser.value) {
      const filterByOrganizations: Filter = {
        name: 'organizationId',
        operator: FilterOperator.IN,
        value: flattenOrganizations(this.loggedInUser.value.organization).map(
          (org) => org.id
        ),
      };
      this.queryParameters.filters = [filterByOrganizations];
    }
  }

  /**
   * Handle sorting change
   * @param sortField
   * @param sortOrder
   */
  async handleSortChange(sortField: string, sortOrder: string): Promise<void> {
    this.queryParameters.sorters = [];
    if (sortOrder) {
      this.queryParameters.sorters.push({
        field: sortField,
        order: sortOrder,
      });
    }
    await this.getData();
  }

  /**
   * Get pending updates response from API
   */
  async getData(): Promise<void> {
    try {
      this.isPendingUpdatedTableLoading = true;
      const response = await getPendingUpdates(this.queryParameters);

      let data = response?.data?.pendingUpdates;
      this.pageTotal = response?.data?.total;

      if (!data || data.length === 0) {
        this.pendingUpdatedTableData = [];
        return;
      }

      const assetIds: string[] = response.data.pendingUpdates.map(
        (update: PendingUpdate) => update.assetUUID
      );
      if (assetIds.length !== 0) {
        this.kpiData.metadata.filter.assetIds = assetIds;
        const kpis = await getKpisForMultipleAssets(this.kpiData);

        /* @ts-expect-error TODO Wrong type */
        if ((kpis.data && kpis.data.errors) || !kpis.data) {
          customWarningMessage(
            this.$t(
              'deployManagementModule.pendingUpdates.errorFetchingData'
            ) as string
          );
          this.pendingUpdatedTableData = this.translateTableData(data);
          return;
        }

        kpis.data.forEach((assetKPI: any) => {
          const assetId = assetKPI.assetId;

          let pendingUpdate = data.find(
            (update: any) => update.assetUUID == assetId
          );
          if (pendingUpdate) {
            pendingUpdate['lastCommunicationTime'] = assetKPI.values.find(
              (v: { code: string; v: any }) =>
                v.code === KPI_FIELDS.LastCommunicationTime
            ).v;
            pendingUpdate['opreationalStatus'] = assetKPI.values.find(
              (v: { code: string; v: any }) =>
                v.code === KPI_FIELDS.OperationalStatus
            ).v;
          }
        });

        this.pendingUpdatedTableData = this.translateTableData(data);
      }
    } catch (err) {
      console.log(err);
    } finally {
      this.isPendingUpdatedTableLoading = false;
    }
  }

  translateTableData(preTranslatedTableData: PendingUpdate[]): PendingUpdate[] {
    return preTranslatedTableData.map((item) => ({
      ...item,
      assetType: this.$t(item.assetType),
      opreationalStatus: item.opreationalStatus
        ? this.$t(item.opreationalStatus)
        : undefined,
    }));
  }

  /**
   * Handle search filter event
   */
  handleSearchFilterSubmit(): void {
    if (this.searchInput.length < 1) this.handleClear();
    if (this.isDifferentUserThanHyvaadmin) this.applyDefaultFilter();
    if (this.searchInput.length > 0 && this.filter.name.length > 0) {
      const selectedFilter: Filter = {
        name: this.filter.name,
        operator: FilterOperator.LIKE,
        value: [this.searchInput],
      };
      this.queryParameters.filters.push(selectedFilter);
      this.getData();
    }
  }

  /**
   * Handle release filter
   */
  handleReleaseFilter(): void {
    this.searchInput = '';
    this.filter.values = [];
    this.handleClear();
  }

  /**
   * Handle clear
   */
  handleClear(): void {
    if (this.isDifferentUserThanHyvaadmin) this.applyDefaultFilter();
    this.filterEnum = this.filterOptions.find(
      (e) => e.field === this.filter.name
    )?.dropdownEnum;
    this.getData();
  }

  /**
   * Handle deployment filter input event
   */
  handleFilterChange(): void {
    this.searchInput = '';
    this.handleClear();
  }

  /**
   * Handle page click event
   * @param page
   */
  handlePage(page: number): void {
    this.pagination.page = page;
    this.getData();
  }

  /**
   * Handle row click
   * @param row
   */
  handleRowClick(row: any): void {
    this.$router.push(
      `pending-updates/details/${row.newSystemReleaseUUID}?assetUUID=${row.assetUUID}`
    );
  }

  /**
   * Batch deploy event
   */
  batchDeployment(): void {
    this.$router.push(`pending-updates/batch-deploy`);
  }

  /**
   * Apply default filter from current logged in user:
   * - current org and sub org levels
   */
  applyDefaultFilter(): void {
    if (!this.loggedInUser.value) return;
    const filterByOrganizations: Filter = {
      name: 'organizationId',
      operator: FilterOperator.IN,
      value: flattenOrganizations(this.loggedInUser.value.organization).map(
        (org) => org.id
      ),
    };
    this.queryParameters.filters = [filterByOrganizations];
  }
}
</script>

<template>
  <div>
    <div class="table-container">
      <div class="filter-container">
        <div class="d-flex ai-center search-select-container">
          <el-select
            class="search-select-field"
            id="deployFilter"
            v-model="filter.name"
            v-bind:placeholder="
              $t('releaseManagementModule.productSystemReleaseId')
            "
            @change="handleFilterChange"
            filterable
          >
            <el-option
              v-for="item in filterOptions"
              :key="item.label"
              :label="$t(`${item.label}`)"
              :value="item.field"
            />
          </el-select>
          <el-input
            v-if="!filterEnum"
            id="input-search-value"
            class="search-input-content"
            :placeholder="$t('common.inputKeywordToSearch')"
            v-model="searchInput"
            @clear="handleClear"
            @keyup.enter.native="handleSearchFilterSubmit"
            clearable
          >
            <i
              slot="suffix"
              class="el-icon-search"
              @click="handleSearchFilterSubmit"
            />
          </el-input>
          <el-select
            v-if="filterEnum"
            @change="handleSearchFilterSubmit"
            v-model="searchInput"
            class="search-input-content"
          >
            <el-option
              class="filter-select-option"
              v-for="item of filterEnum"
              :key="item"
              :label="$t(item)"
              :value="item"
            />
          </el-select>
        </div>
        <el-button
          v-if="remoteSoftwareEntitlement.value"
          class="new-deploy-button"
          id="newDeploy"
          v-permission="['AUTHRSC_ACTION_PENDING_UPDATES_BATCH_DEPLOY']"
          type="plain"
          @click="batchDeployment"
        >
          {{ $t('deployManagementModule.pendingUpdates.batchDeploy') }}
        </el-button>
      </div>
      <PureTable
        v-loading="isPendingUpdatedTableLoading"
        class="release-table"
        :tableList="pendingUpdatedTableData"
        :total="pageTotal"
        :cols="cols"
        :viewPathProperty="'newSystemReleaseUUID'"
        :showTableHeaderOptions="false"
        @handle-page="handlePage"
        @handle-sort-change="handleSortChange"
        @row-click="handleRowClick"
      />
    </div>
  </div>
</template>

<style lang="scss" scoped>
:deep(.release-table) {
  :deep(.el-table__body colgroup col) {
    width: 300px;
  }
}

.new-deploy-button {
  margin: auto 0 auto auto;
}

.add-icon {
  margin: auto 15px auto auto;
}

.filter-container {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
}

.filter-relese {
  margin: 0;
}

.search-input-content {
  width: 400px;
}

.table-container {
  width: 100%;
  padding: 16px;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 3px 6px #c1c1c1;
  display: flex;
  flex-direction: column;
}

.search-select-container {
  :deep(.el-input__inner) {
    height: 40px;
    border: 1px solid #818181;
  }

  .search-select-field :deep(.el-input__inner) {
    width: 252px;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    border-right: transparent !important;
  }

  .search-select-field {
    margin: 0;
  }

  .search-input-content :deep(.el-input__inner) {
    width: 270px;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
  }

  .search-input-content :deep(.el-input__suffix) {
    line-height: 40px;
    color: rgba(0, 0, 0, 0.6);
    font-size: 18px;
    cursor: pointer;
    width: 282px;
  }
}
</style>
