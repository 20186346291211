<script lang="ts" setup>
import { disabledFutureDate } from '@/utils/date';
import {
  DEFAULT_PERIOD_RANGE,
  getFirstDateOfFirstCompleteWeek,
  getLastDateInclusiveOfPreviousCompleteMonth,
  getLastDateInclusiveOfPreviousCompleteWeek,
  getRangePeriod,
  getStartOfDay,
  LOCALDATE_FORMAT,
} from '@/utils/time';
import { DatePeriod, PeriodRange } from '@/utils/types/date';
import { PERIOD_RANGE_OPTIONS } from '@/utils/workData/lookuptable';
import moment from 'moment';
import { computed, Ref, ref, unref, watch, watchEffect } from 'vue';

const props = defineProps<{
  customizable?: boolean;
  dropDownIsDisabled?: Boolean;
  customDate?: PeriodRange;
}>();

const emit = defineEmits<{
  (e: 'select', data: PeriodRange): void;
}>();

// Range option (days/weeks/months)
const selectedDatePeriod: Ref<DatePeriod> = ref(
  DEFAULT_PERIOD_RANGE.datePeriod
);

// Represents start and end (inclusive), i.e. directly as shown to the user
type InternalRange = [Date, Date];
const internalRange: Ref<InternalRange> = ref<InternalRange>([
  moment(DEFAULT_PERIOD_RANGE.start).toDate(),
  moment(DEFAULT_PERIOD_RANGE.end).toDate(),
]);

watchEffect(() => {
  if (props.customDate) {
    internalRange.value = [
      moment(props.customDate.start).toDate(),
      moment(props.customDate.end).toDate(),
    ];
    selectedDatePeriod.value = props.customDate.datePeriod;
  }
});

function toPeriodRange(internalRange: InternalRange): PeriodRange {
  const [start, end] = internalRange;

  return {
    start: moment(start).format(LOCALDATE_FORMAT),
    end: moment(end).format(LOCALDATE_FORMAT),
    endExclusive: moment(end).add(1, 'day').format(LOCALDATE_FORMAT),
    datePeriod: unref(selectedDatePeriod),
  };
}

/**
 * Move range descending or ascending
 * Do not allow moving range forward if the endDate > Date now()
 * @param direction
 */
function moveRange(direction: number) {
  const periodRange = toPeriodRange(unref(internalRange));
  let periodInDays = getRangePeriod(periodRange);
  const nextStartDate = moment(periodRange.start)
    .add(periodInDays * direction, 'days')
    .toDate();
  const nextEndDate = moment(periodRange.end)
    .add(periodInDays * direction, 'days')
    .toDate();

  if (disabledFutureDate(nextEndDate)) {
    return;
  }

  internalRange.value = [nextStartDate, nextEndDate];
}

watch(selectedDatePeriod, () => {
  const periodOption = PERIOD_RANGE_OPTIONS.find(
    (option) => option.datePeriod === unref(selectedDatePeriod)
  );
  if (!periodOption) {
    throw new Error('invalid period option selected');
  }
  let startDateMoment = getStartOfDay();
  let endDate = getStartOfDay().toDate();
  switch (periodOption.datePeriod) {
    case DatePeriod.DATP_DAY:
      endDate = moment().subtract(1, 'days').toDate();
      startDateMoment = startDateMoment.subtract(periodOption.value, 'days');
      break;
    case DatePeriod.DATP_WEEK:
      startDateMoment.subtract(periodOption.value, 'week');
      startDateMoment = getFirstDateOfFirstCompleteWeek(startDateMoment, 1);
      endDate = getLastDateInclusiveOfPreviousCompleteWeek(1).toDate();
      break;
    case DatePeriod.DATP_MONTH:
      endDate = getLastDateInclusiveOfPreviousCompleteMonth().toDate();
      startDateMoment = moment(endDate).subtract(periodOption.value, 'months');
      endDate = moment(endDate).subtract(1, 'day').toDate();
      break;
  }
  const startDate = startDateMoment.toDate();
  internalRange.value = [startDate, endDate];
});

const display = computed(() => {
  const [start, end] = internalRange.value;

  return `${moment(start).format('YYYY/MM/DD')} — ${moment(end).format(
    'YYYY/MM/DD'
  )}`;
});

watch(internalRange, (next) => emit('select', toPeriodRange(next)));

const forwardRangeMoveIsAllowed = computed(() => {
  const periodRange = toPeriodRange(unref(internalRange));
  const periodInDays = getRangePeriod(periodRange);
  const nextEndDate = moment(periodRange.end)
    .add(periodInDays, 'days')
    .toDate();

  return disabledFutureDate(nextEndDate);
});

const backwardRangeMoveIsAllowed = computed(() => {
  return true;
});
</script>

<template>
  <div class="time-select">
    <el-select
      v-model="selectedDatePeriod"
      class="select"
      :disabled="dropDownIsDisabled"
      :class="{
        'dropdown-is-disabled': dropDownIsDisabled,
      }"
    >
      <el-option
        v-for="item in PERIOD_RANGE_OPTIONS"
        :key="item.datePeriod"
        :label="$t(item.datePeriod)"
        :value="item.datePeriod"
      />
    </el-select>
    <el-input :value="display" class="query" :readonly="true">
      <i
        slot="prefix"
        class="el-input__icon el-icon-arrow-left"
        :class="{ 'active-range-move': backwardRangeMoveIsAllowed }"
        @click="moveRange(-1)"
      />
      <i
        slot="suffix"
        class="el-input__icon el-icon-arrow-right"
        :class="{ 'active-range-move': !forwardRangeMoveIsAllowed }"
        @click="moveRange(1)"
      />
    </el-input>
  </div>
</template>

<style lang="scss" scoped>
.time-select {
  position: relative;
  display: flex;

  // Width is determined by making sure the time selector fits on a widget
  // card with single column width, on 1920x1080 at 125% text scale.
  width: 350px;
  min-width: 350px;
  margin: 0;

  @media (max-width: 350px) {
    flex-direction: column;
    align-items: start !important;
  }
}

.el-select {
  margin: 0;
}

.select :deep(.el-input__inner) {
  width: 120px;

  @media (min-width: 350px) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-right: none !important;
  }
}

.query {
  width: 220px;

  &:deep(.el-input__inner) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    text-align: center;
  }
}

.active-range-move {
  color: black;
  font-weight: bold;
}

.dropdown-is-disabled :deep(.el-input__suffix) {
  display: none;
}
</style>
