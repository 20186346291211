<script setup lang="ts">
import { Notification } from '@/api/notifications';
import unreadNotificationMark from '@/icons/svg/newNotificationEllipse.svg';
import NotificationBell from '@/icons/templates/NotificationBell.vue';
import {
  useNotificationsQuery,
  useUpdateNotificationReadStatusMutation,
} from '@/query/notification';
import { formatTimer } from '@/utils/misc';
import { ref } from 'vue';
import NotificationDialog from './NotificationDialog.vue';

const notifications = useNotificationsQuery();
const markAsReadMutation = useUpdateNotificationReadStatusMutation();

async function onMarkAllAsReadClick() {
  if (!notifications.data.value) return;

  const unreadNotifications = notifications.data.value.notifications
    .filter((notification) => !notification.markedAsRead)
    .map((notification) => notification.userNotificationId);

  markAsReadMutation.mutate({ notificationIds: unreadNotifications });
}

const selectedNotification = ref<Notification>();

async function onNotificationClick(notification: Notification) {
  if (!notification.markedAsRead) {
    markAsReadMutation.mutate({
      notificationIds: [notification.userNotificationId],
    });
  }

  selectedNotification.value = notification;
}

function closeNotificationDialog() {
  selectedNotification.value = undefined;
}
</script>

<template>
  <div>
    <el-dropdown class="lg" trigger="click" placement="top-start">
      <div class="bell-bg">
        <NotificationBell class="bell" />
        <span
          v-if="(notifications.data.value?.totalUnread ?? 0) > 0"
          class="notification-count"
          >{{ notifications.data.value?.totalUnread }}</span
        >
      </div>
      <el-dropdown-menu slot="dropdown">
        <div class="dropdown-container" style="padding: 16px">
          <div class="notification-header">
            <div class="notifications-header-title">
              {{ $t('notifications.Notifications') }}
            </div>
            <div
              v-if="(notifications.data.value?.totalUnread ?? 0) > 0"
              class="notifications-header-mark-read"
              @click="onMarkAllAsReadClick"
            >
              {{
                $t('notifications.markAllAsRead', {
                  unreadCount: notifications.data.value?.totalUnread,
                })
              }}
            </div>
          </div>
          <div
            v-if="notifications.data.value?.notifications.length === 0"
            class="no-notifications-container"
          >
            <div class="no-notifications-text">No notifications</div>
          </div>
          <div
            v-for="notification in notifications.data.value?.notifications"
            v-loading="notifications === undefined"
            class="notification-container"
            @click="onNotificationClick(notification)"
          >
            <div class="notification-details">
              <div class="notification-description">
                {{ notification.description }}
              </div>
              <div class="notification-date">
                {{ formatTimer(notification.notificationTime, 'datetime') }}
              </div>
            </div>
            <div v-if="!notification.markedAsRead" class="flex-center">
              <img class="notification-mark" :src="unreadNotificationMark" />
            </div>
          </div>
        </div>
      </el-dropdown-menu>
    </el-dropdown>
    <NotificationDialog
      :notification="selectedNotification"
      @close="closeNotificationDialog"
    />
  </div>
</template>

<style lang="scss" scoped>
.bell-bg {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 6px;
  background-color: #586365;
  border-radius: 50%;
  cursor: pointer;
}

.bell {
  position: relative;
}

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.notification-count {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2px 5px;
  position: absolute;
  right: -2px;
  top: -2px;
  background-color: #eb5757;
  border-radius: 50%;
  font-size: 10px;
  font-weight: bold;
  color: white;
}

.notification-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 8px 8px 0px 8px;
  padding-bottom: 16px;
  border-bottom: 1px solid #bdbdbd;
}

.notifications-header-title {
  font-size: 24px;
  font-weight: 500;
  color: #373e41;
}

.notifications-header-mark-read {
  font-size: 12px;
  font-weight: 500;
  color: #828282;
  text-decoration: underline;
  cursor: pointer;
}

.notification-container {
  padding: 8px;
  margin-top: 16px;
  display: flex;
  justify-content: space-between;
  border-radius: 4px;
  cursor: pointer;
}

.notification-container:hover {
  background-color: #f2f2f2;
}

.notification-container:hover .notification-mark {
  padding: 10px;
  border: 1px solid #e0e0e0;
  border-radius: 50%;
}

.notification-description {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #373e41;
}

.notification-details {
  display: flex;
  flex-direction: column;
  flex-basis: 90%;
  gap: 4px;
}

.notification-date {
  font-weight: 500;
  font-size: 12px;
  color: #828282;
}

.notification-mark {
  padding: 11px;
}

.no-notifications-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 16px;
  margin-bottom: 8px;
}

.no-notifications-text {
  width: 100%;
  padding: 8px;
  text-align: center;
  background-color: #f2f2f2;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 500;
  color: #373e41;
  line-height: 20px;
}

.dropdown-container {
  width: 500px;
  max-height: 80vh;
  overflow-y: auto;
  overflow-x: hidden;
}

.dropdown-container::-webkit-scrollbar {
  width: 12px;
}

.dropdown-container::-webkit-scrollbar-track {
  border-radius: 4px;
}

.dropdown-container::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: #c4c4c4;
}
</style>
