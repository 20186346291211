<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator';

@Component({
  name: 'Status',
})
export default class extends Vue {
  @Prop() status!: any;

  isActive: boolean = false;

  created() {
    this.isActive =
      this.status.value !== 'RELSTAT_OUTDATED' &&
      this.status.value !== 'RELSTAT_DEPRECATED';
  }
}
</script>

<template>
  <span :class="isActive ? 'active' : 'deprecated'">
    {{ $t(status.value) }}
  </span>
</template>

<style lang="scss" scoped>
.active {
  margin: auto auto auto 36px;
  padding: 6px 16px;
  background: #d9efe0;
  color: #2b9847;
  border-radius: 4px;
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
}

.deprecated {
  margin: auto auto auto 36px;
  padding: 6px 16px;
  background: #e0e0e0;
  color: #373e41;
  border-radius: 4px;
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
}
</style>
