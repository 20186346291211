<script setup lang="ts">
import Title from '@/components/layout/Title.vue';
import { useRouter } from '@/composables/router';
import { ref } from 'vue';

export interface Button {
  id: string;
  label: string;
  action: () => void;
}

const props = withDefaults(
  defineProps<{
    visible?: boolean;
    arrowBack?: boolean;
    buttons?: Button[];
    headerTitleClass?: string;
    fitToParent?: boolean;
  }>(),
  {
    visible: true,
    arrowBack: true,
    buttons: () => Array.of<Button>(),
    headerTitleClass: 'header-title',
    fitToParent: false,
  }
);

const emit = defineEmits<{
  (e: 'arrow-click'): void;
}>();

const title = ref('');

const router = useRouter();

function goBack() {
  //the arrow back doesn't always go back in some situations.
  if (!props.arrowBack) {
    emit('arrow-click');
  } else {
    router.go(-1);
  }
}
</script>

<template>
  <div
    class="cus-add-container"
    :style="{ overflow: 'auto', height: fitToParent ? '100%' : 'auto' }"
  >
    <div class="header d-flex ai-center jc-between">
      <div id="new_card_header_title" class="d-flex ai-center">
        <i
          v-if="visible"
          class="el-icon-arrow-left"
          style="font-size: 25px; cursor: Pointer; margin-left: 20px"
          @click="goBack"
        />
        <Title />
        <span :class="headerTitleClass">{{ $t(title) }}</span>
      </div>
      <slot name="header-content"></slot>
      <slot name="button"></slot>
      <div class="button-container" v-if="buttons.length > 0">
        <el-button
          v-for="button in buttons"
          :key="button.label"
          v-on:click="button.action"
        >
          {{ $t(button.label) }}
        </el-button>
      </div>
    </div>

    <div class="body">
      <slot></slot>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.button-container {
  display: flex;
  flex-direction: row;
}

.cus-add-container {
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0px 3px 6px #c1c1c1;
  padding-bottom: 12px;
  display: flex;
  flex-direction: column;
}

.header {
  font-family: $font-Roboto-Regular;
  line-height: 21px;
  border-bottom: 1px solid #dddddd;
  padding: 20px 20px;
  height: 60px;
}

.body {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.el-icon-arrow-down {
  font-size: 12px;
}

@media (max-width: 768px) {
  .viewPageBtn {
    display: none;
  }

  .mobileViewPageBtn {
    display: block;
  }
}
</style>
