/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon';
icon.register({
  assets: {
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
    data: '<defs/><g id="组件_339_1" data-name="组件 339 – 1"><g id="组件_338_3" data-name="组件 338 – 3"><path pid="0" id="减去_15" data-name="减去 15" d="M14 20H6a6.007 6.007 0 01-6-6V6a6.007 6.007 0 016-6h8a6.007 6.007 0 016 6v8a6.006 6.006 0 01-6 6zM6 1a5.006 5.006 0 00-5 5v8a5.006 5.006 0 005 5h8a5.005 5.005 0 005-5V6a5.006 5.006 0 00-5-5z" _fill="#373e41"/></g><g id="物流_卡车" data-name="物流 卡车" transform="translate(2 2)"><path pid="1" id="物流_卡车-2" data-name="物流 卡车" d="M13.883 132.906a.514.514 0 00-.112-.177L11.039 130a.458.458 0 00-.381-.139h-2.3A1.864 1.864 0 006.5 128H1.858A1.864 1.864 0 000 129.858v6.969a.46.46 0 00.465.465h.464a1.858 1.858 0 003.717 0h4.646a1.858 1.858 0 003.717 0h.465a.46.46 0 00.465-.465v-3.717a.375.375 0 00-.056-.204zm-11.1 5.315a.929.929 0 11.929-.929.932.932 0 01-.924.93zm1.6-1.858a1.839 1.839 0 00-3.2 0h3.2zm6.765 1.858a.929.929 0 11.929-.929.932.932 0 01-.926.93zm1.858-1.858h-.26a1.839 1.839 0 00-3.2 0H8.363v-5.575h2.1l2.546 2.546z" transform="translate(.996 -125.611)" _fill="#373e41"/></g></g>',
  },
});
