<script lang="ts">
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import GeneralInfo from '@/components/form/GeneralInfo.vue';

@Component({
  name: 'generalPreview',
  components: {
    'general-info': GeneralInfo,
  },
})
export default class extends Vue {
  @Prop({ required: true }) productModelInfo!: any;
  @Prop({ required: false }) imgAddress: any | undefined;

  created() {}
}
</script>

<template>
  <div style="margin-left: 30px; margin-top: 50px">
    <general-info
      id="model_view_general_info_name"
      :name="$t('prodCata.productModelName')"
      :value="productModelInfo.code"
    />
    <general-info
      id="model_view_general_info_asset_type"
      :name="$t('prodCata.assetType')"
      :value="productModelInfo.assetTypeCode"
    />
    <general-info
      id="model_view_general_info_model_number"
      :name="$t('prodCata.modelNumber')"
      :value="productModelInfo.modelNumber"
    />
    <general-info
      id="model_view_general_info_lifecycle"
      :name="$t('prodCata.status')"
      :value="productModelInfo.lifeCycle"
    />
    <general-info
      id="model_view_general_info_pic"
      :name="$t('prodCata.productImage')"
    >
      <img :src="productModelInfo.picturePath" class="avatar" />
    </general-info>
  </div>
</template>

<style lang="scss" scoped>
:deep(.form-item-container) {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 40px;
  .form-title {
    width: 200px;
    margin-right: 22px;
    text-align: left;
    font-family: $font-Roboto-Regular;
    color: #373e41;
  }
  .show-title {
    font-size: 18px;
  }
}
.avatar {
  width: 320px;
  height: 210px;
  border: 1px solid #707070;
}
</style>
