import { Module } from '@/api/users';
import i18n from '@/lang';
import { ALL_CLAIMS_CODES } from '@/utils/workData/lookuptable';
import { TreeData } from 'element-ui/types/tree';

export interface ModuleTreeData extends TreeData {
  name: string;
  children: ModuleTreeData[];
}

export function modulesToTreeData(modules: Module[]): ModuleTreeData[] {
  const recurse = (parentCode: string) => {
    return modules
      .filter((item) => item.parentCode === parentCode)
      .map((item): ModuleTreeData => {
        return {
          id: item.id,
          label: i18n.tc('moduleTitle.' + item.code),
          name: item.code,
          disabled: item.code == ALL_CLAIMS_CODES.AUTHRSC_MOD_HOME,
          children: recurse(item.code),
        };
      });
  };
  return recurse(''); // The father id of the first level node is an empty string
}
